import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { tKey } from '../../helpers/translate';
import defaultImage from '../../images/default-avatarNew.svg';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import styles from './cropimage.module.scss';

class CropImage extends PureComponent {
  state = {
    src: this.props.src || null,
    crop: {
      unit: '%',
      width: 30,
      aspect: 1,
    },
    selectFile: false,
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({ src: reader.result }));
      reader.readAsDataURL(e.target.files[0]);
    }
    this.setState({ selectFile: true });
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = crop => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const image = await this.getCroppedImg(this.imageRef, crop);
      this.setState({ croppedImage: image });
    }
  }

  onClickDoneCrop = async () => {
    const { handleChange, handleClose } = this.props;
    const { croppedImage } = this.state; // eslint-disable-line
    handleChange(croppedImage);
    handleClose();
    this.setState({ hasCroppedPic: true });
  };

  getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return canvas.toDataURL();
  }

  render() {
    const { crop, croppedImage, src, selectFile } = this.state;
    const { avatar, deleteMode, isMobile = false } = this.props;

    return (
      <div className={styles.crop_image_container}>
        {!selectFile && (
          <div className={styles.crop_image_position}>
            <img
              alt="Crop"
              className={`${styles.avatarMaxWidth} ${styles.crop_image_imgRound}`}
              src={avatar || defaultImage}
            />
          </div>
        )}
        {!deleteMode && (
          <React.Fragment>
            <div className={styles.crop_file_container}>
              {!selectFile && (
                <div className={styles.buttonMargin}>
                  <Button variant="outlined" color="primary" fullwidth component="label" size="large" fullWidth>
                    {tKey('Cambiar Imagen')}
                    <input
                      className={styles.crop_file}
                      id="file-upload"
                      type="file"
                      accept="image/*"
                      onChange={this.onSelectFile}
                    />
                  </Button>
                </div>
              )}
              <div className={styles.crop_image_view}>
                {src && (
                  <ReactCrop
                    className={styles.crop_select}
                    src={src}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                    circularCrop
                  />
                )}
                {/* En Desktop enseña un preview de lo que se esta recortando */}
                {croppedImage && !isMobile && (
                  <div className={styles.crop_result}>
                    <img className={styles.crop_image_end} alt="Crop" style={{ maxWidth: '100%' }} src={croppedImage} />
                  </div>
                )}
              </div>
              {croppedImage && (
                <ButtonGroup>
                  <Button variant="outlined" color="primary" fullWidth onClick={() => this.onClickDoneCrop()}>
                    {tKey('Hecho')}
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </React.Fragment>
        )}
        {/* {deleteMode && (
          <ButtonGroup>
            <Button variant="text" color="primary" fullWidth>
              <DeleteForeverIcon />
              Eliminar Imagen
            </Button>
          </ButtonGroup>
        )} */}
      </div>
    );
  }
}

CropImage.propTypes = {
  handleChange: PropTypes.func.isRequired,
  avatar: PropTypes.string,
};

CropImage.defaultProps = {
  avatar: null,
};

export default CropImage;
