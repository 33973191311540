import { Educator, EducatorId, InternalProjectId } from './Educator.d';
import { EducatorConst } from './action_types';
import {
  AddEducatorAction,
  AddEducatorActionKo,
  AddEducatorActionOk,
  GetEducatorsAction,
  GetEducatorsKoAction,
  GetEducatorsOkAction,
  DeleteEducatorAction,
  DeleteEducatorKoAction,
  DeleteEducatorOkAction,
  UpdateEducatorAction,
  UpdateEducatorOkAction,
  UpdateEducatorKoAction,
} from './definition';

export function addEducator(educator: Educator): AddEducatorAction {
  return {
    type: EducatorConst.ADD_EDUCATOR,
    educator,
  };
}

export function addEducatorOk(educator: Educator): AddEducatorActionOk {
  return {
    type: EducatorConst.ADD_EDUCATOR_OK,
    educator,
  };
}

export function addEducatorKo(): AddEducatorActionKo {
  return {
    type: EducatorConst.ADD_EDUCATOR_KO,
  };
}

export function getEducators(id: InternalProjectId): GetEducatorsAction {
  return {
    type: EducatorConst.GET_EDUCATORS,
    id,
  };
}

export function getEducatorsOk(data: Educator[]): GetEducatorsOkAction {
  return {
    type: EducatorConst.GET_EDUCATORS_OK,
    data: data,
  };
}

export function getEducatorsKo(error: string): GetEducatorsKoAction {
  return {
    type: EducatorConst.GET_EDUCATORS_KO,
    error,
  };
}

export function deleteEducator(internal_project_id: InternalProjectId, educatorId: EducatorId): DeleteEducatorAction {
  return {
    type: EducatorConst.DELETE_EDUCATORS,
    internal_project_id,
    educatorId,
  };
}

export function deleteEducatorOk(
  internal_project_id: InternalProjectId,
  educatorId: EducatorId,
): DeleteEducatorOkAction {
  return {
    type: EducatorConst.DELETE_EDUCATORS_OK,
    internal_project_id,
    educatorId,
  };
}

export function deleteEducatorKo(error: string): DeleteEducatorKoAction {
  return {
    type: EducatorConst.DELETE_EDUCATORS_KO,
    error,
  };
}

export function updateEducator(educator: Educator): UpdateEducatorAction {
  return {
    type: EducatorConst.UPDATE_EDUCATOR,
    educator,
  };
}

export function updateEducatorOk(updatedEducator: Educator): UpdateEducatorOkAction {
  return {
    type: EducatorConst.UPDATE_EDUCATOR_OK,
    updatedEducator,
  };
}

export function updateEducatorKo(error: string): UpdateEducatorKoAction {
  return {
    type: EducatorConst.UPDATE_EDUCATOR_KO,
    error,
  };
}
