export const findAndReturnCurrying = <T extends object, U extends keyof T>({
  data,
  keyReturn,
  keySearch,
}: {
  data: T[];
  keyReturn: U;
  keySearch: U;
}) => (value: T[U]): string => {
  const result = data.findIndex(item => item[keySearch] === value);
  if (result < 0) return '';
  return ((data[result][keyReturn] as unknown) as string).toString();
};
