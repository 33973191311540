import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import React from 'react';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../helpers/translate';
import { IdCodeNamePair } from '../../redux/common/definitions';
import { CodeSectionProject, sectionField } from '../../redux/kpis/definitions';

const hardcored = (section_code: string): IdCodeNamePair[] => {
  const items = [
    { id: 1, code: 'config', name: 'config-campos' },
    { id: 2, code: 'area', name: 'asignación-areas' },
    { id: 3, code: 'subarea', name: 'asignación-subarea' },
  ];
  if (section_code === 'kpis') {
    items.unshift({ id: 1, code: 'kpis_types', name: 'indicator-types' });
  }

  return items;
};

interface SubmenuConfigCustomFieldProps {
  submenu?: string;
  selected?: string;
}
const canAccess = (code: CodeSectionProject) =>
  ![
    'kpis_gf',
    'nota_concepto',
    'diagnostico',
    'linea_base',
    'actividades_modulos',
    'solicitud_ficha_personal',
  ].includes(code);

const SubmenuConfigCustomField: React.FC<SubmenuConfigCustomFieldProps> = ({ submenu, selected }) => {
  const optionMenuFiltered = sectionField.filter(e => canAccess(e.code));
  const optionMenu: SubmenuItem[] = optionMenuFiltered.map(section => ({
    title: tKey(section.name),
    icon: <FeaturedPlayListIcon />,
    type: 'items',
    items: hardcored(section.code).map((item: IdCodeNamePair) => ({
      title: tKey(item.name),
      route: `/configuracion/proyectos/configuracion-campos/${section.code}/${item.code}`,
      type: 'route',
      selected: selected === item.code,
    })),
  }));

  return (
    <Submenu
      titleMenu={tKey('CONFIGURACIÓN DE CAMPOS')}
      optionsMenu={optionMenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
    />
  );
};

export default SubmenuConfigCustomField;
