import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import styles from './messageInfo.module.scss';

interface MessageInfo {
  children: string;
  style?: string;
}

const MessageInfo: React.FC<MessageInfo> = ({ children, style }) => (
  <div className={styles.root}>
    <InfoIcon className={styles.icon} />
    <p className={`${styles.message} ${style}`}>{children}</p>
  </div>
);

export default MessageInfo;
