import { SvgIcon } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { getLogo } from '../../helpers/selectImages';
import { tKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import { ReactComponent as iconBurger } from '../../images/header/iconBurger.svg';
import { ReactComponent as iconClose } from '../../images/header/iconClose.svg';
import { applicationShowMenuMobile } from '../../redux/application/actions';
import { ApplicationDispatch } from '../../redux/application/definitions';
import { selectShowMenuMobile } from '../../redux/application/selectors';
import { AppState } from '../../redux/root-reducer';
import FabButtonNotification from '../Layout/FabButons/FabButtonNotification';
import FabButtonProfile from '../Layout/FabButons/FabButtonProfile';
import GeneralMenuMobile from './GeneralMenuMobile';
import LanguagesSelector from './LanguagesSelector';
import LogoIcon from './LogoIcon';
import ProfileMenuMobile from './ProfileMenuMobile';
import styles from './header.module.scss';

interface HeaderProps {
  imageType?: 'scholarship' | 'project' | 'general';
  isUserCreated?: boolean;
  showMenuMobile: boolean;
  applicationShowMenuMobile: (show: boolean) => void;
}
const Header: React.FC<HeaderProps> = ({
  imageType = 'general',
  isUserCreated,
  showMenuMobile,
  applicationShowMenuMobile,
}) => {
  const srcImageLogo = getLogo(imageType, 'white');
  return (
    <header className={`${styles.root} ${styles[`wrapper_${webStyle}`]}`}>
      {isUserCreated ? (
        <>
          <FabButtonProfile />
          <LogoIcon redirectHome={true} src={srcImageLogo} />
          <FabButtonNotification />
          {showMenuMobile ? (
            <>
              <button
                className={styles.menuButtonClose}
                onClick={() => {
                  applicationShowMenuMobile(false);
                }}
              >
                <span className={styles.menuButtonText}>{tKey('Cerrar')}</span>
                <SvgIcon className={styles.menuButtonCloseIcon} viewBox="0 0 12 12" component={iconClose} />
              </button>
              <nav className={`${styles.menu} ${styles.isOpen}`}>
                <GeneralMenuMobile />
                <ProfileMenuMobile />
                <LanguagesSelector />
              </nav>
            </>
          ) : (
            <button
              className={styles.menuButton}
              onClick={() => {
                applicationShowMenuMobile(true);
              }}
            >
              <SvgIcon className={styles.menuIcon} viewBox="0 0 15 12" component={iconBurger} />
            </button>
          )}
        </>
      ) : (
        <LogoIcon redirectHome={false} src={srcImageLogo} />
      )}
    </header>
  );
};

const mapDispatchToProps = (dispatch: ApplicationDispatch) => ({
  applicationShowMenuMobile: (show: boolean): void => dispatch(applicationShowMenuMobile(show)),
});

const mapStateToProps = (state: AppState) => ({
  showMenuMobile: selectShowMenuMobile(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
