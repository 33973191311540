/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, Select } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBox from '@material-ui/core/Checkbox';
import React from 'react';
import styles from './customTable.module.scss';
import cellStyles from './customTableCell.module.scss';
import { ColumnTableProps } from '../TableEditableRow/TableEditableRow';
import { InternalProjectEnrollment } from '../../../redux/internal-projects/definitions';

const BORDER_RIGT_END = '3px solid #6666661a';
const EMPTY_PROPERTY = '';

const useStyles = makeStyles(() => ({
  fixed: {
    top: 'auto',
    width: '5em',
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));

interface CustomTableCellProps {
  row: any;
  name: string;
  onChangeText: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, row: InternalProjectEnrollment) => void;
  onChangeCheckBox: (e: React.ChangeEvent<HTMLInputElement>, row: InternalProjectEnrollment) => void;
  onChangeSelectOption: (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    row: InternalProjectEnrollment,
  ) => void;
  onClick: () => void;
  index: number;
  editable: any;
  type?: 'Number' | 'Text' | 'Boolean' | 'List';
  color: any;
  fixed: any;
  column: ColumnTableProps;
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({
  row,
  name,
  index,
  editable,
  type,
  onClick,
  onChangeText,
  onChangeCheckBox,
  onChangeSelectOption,
  color,
  fixed,
  column,
}) => {
  const classes = useStyles();

  const { isEditMode } = row;

  const showInputs = (value: string | number | undefined | null, tooltip?: boolean) => {
    if (value?.toString().trim()) {
      if (type === 'Number') {
        const id: number = parseInt(row[name]);
        return id;
      }
      return value;
    }
    if (tooltip) return '';
    return EMPTY_PROPERTY;
  };

  const renderCheckBox: JSX.Element = (
    <CheckBox
      color="primary"
      name={name}
      checked={row[name] === 1 || row[name] === '1'}
      onChange={e => onChangeCheckBox(e, row)}
    />
  );

  const renderSelect: JSX.Element = (
    <Select
      className={styles.selectAssesment}
      value={row[name] ? row[name] : 0}
      name={name}
      onChange={e => {
        onChangeSelectOption(e, row);
      }}
    >
      <MenuItem key={index} value={0}>
        {'-----'}
      </MenuItem>
      {column.options &&
        column.options.map((element: { id: number | string; name: string }) => (
          <MenuItem key={element.id} value={element.id} data-name={element.name}>
            {element.name}
          </MenuItem>
        ))}
    </Select>
  );

  return (
    <TableCell
      align={column.align || 'left'}
      className={index <= fixed?.num ? classes.fixed : cellStyles.tableCell}
      onClick={onClick}
      style={{
        color: color ? color(row) : undefined,
        left: index <= fixed?.num ? fixed?.left * index : undefined,
        borderRight: index === fixed?.num ? BORDER_RIGT_END : undefined,
        padding: '16px 9px',
      }}
    >
      {isEditMode && editable !== 'never' ? (
        <>
          {type === 'Text' ? (
            <Input
              defaultValue={row[name] || ''}
              name={name}
              autoFocus={index === 0}
              onBlur={e => onChangeText(e, row)}
              className={styles.input}
            />
          ) : type === 'Boolean' ? (
            <Tooltip title={''}>{renderCheckBox}</Tooltip>
          ) : type === 'List' ? (
            <Tooltip title={''}>{renderSelect}</Tooltip>
          ) : null}
        </>
      ) : (
        <>
          {type === 'Number' || type === 'Text' ? (
            <Tooltip title={showInputs(row[name], true)} disableHoverListener={!row[name]}>
              <div className={type === 'Number' ? cellStyles.cell_format_number : cellStyles.cell_format}>
                {showInputs(row[name])}
              </div>
            </Tooltip>
          ) : type === 'Boolean' ? (
            <Tooltip title={''}>{renderCheckBox}</Tooltip>
          ) : type === 'List' ? (
            <Tooltip title={''}>{renderSelect}</Tooltip>
          ) : null}
        </>
      )}
    </TableCell>
  );
};

export default CustomTableCell;
