import { useDispatch, useSelector } from 'react-redux';
import { Educator } from '../../../../../../../../redux/internal-projects/educators/Educator';
import { addEducator, updateEducator } from '../../../../../../../../redux/internal-projects/educators/action';
import {
  selectEducatorError,
  selectEducatorLoading,
} from '../../../../../../../../redux/internal-projects/educators/selectors';
import { UUID } from '../../../../../../../../types/Shared/standard';

export function NewEditEducatorViewModel() {
  const dispatch = useDispatch();

  function _save(data: Educator): void {
    dispatch(addEducator(data));
  }
  function _update(id: UUID, data: Educator): void {
    dispatch(
      updateEducator({
        ...data,
        id,
      }),
    );
  }

  const saving: boolean = useSelector(selectEducatorLoading);
  const newServerErrors: string | null = useSelector(selectEducatorError);

  return {
    save: _save,
    update: _update,
    saving: saving,
    serverErrors: newServerErrors,
  };
}

export type ViewModelType = typeof NewEditEducatorViewModel;
