import React from 'react';
import { TouchAppOutlined, MailOutlined, SettingsOutlined, MailOutlineTwoTone } from '@material-ui/icons';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';

interface SubmenuTranslationsProps {
  canConfigure: boolean;
  selected: string;
  submenu: string;
  onClickOption?: () => void;
}

const SubmenuTranslations: React.FC<SubmenuTranslationsProps> = ({
  canConfigure,
  selected,
  submenu,
  onClickOption,
}) => {
  const optionsMenu: SubmenuItem[] = [];
  if (canConfigure) {
    optionsMenu.push({
      title: tKey('Aplicativo'),
      icon: <TouchAppOutlined />,
      type: 'route',
      route: '/configuracion/traducciones/aplicativo',
      selected: selected === 'aplicativo',
    });
    optionsMenu.push({
      title: tKey('Correos'),
      icon: <MailOutlined />,
      type: 'route',
      route: '/configuracion/traducciones/mail',
      selected: selected === 'mail',
    });
    optionsMenu.push({
      title: tKey('Campos configurados'),
      icon: <SettingsOutlined />,
      type: 'route',
      route: '/configuracion/traducciones/custom-fields',
      selected: selected === 'custom-fields',
    });
    optionsMenu.push({
      title: tKey('Correos LOPD'),
      icon: <MailOutlineTwoTone />,
      type: 'route',
      route: '/configuracion/traducciones/lopd-mail',
      selected: selected === 'lopd-mail',
    });
  }

  return (
    <Submenu
      titleMenu={tKey('TRADUCCIONES')}
      optionsMenu={optionsMenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
      onClickOption={onClickOption}
    />
  );
};

export default SubmenuTranslations;
