import { Tune } from '@material-ui/icons';
import React, { useState } from 'react';
import { tKey } from '../../../helpers/translate';
import styles from './directoryHeader.module.scss';

interface DirectoryHeaderProps {
  title: string;
  children: JSX.Element;
}

const DirectoryHeader: React.FC<DirectoryHeaderProps> = ({ title, children }) => {
  const [toggleFilters, setToggleFilters] = useState(false);

  const handleToggleFilters = () => {
    setToggleFilters(!toggleFilters);
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>{title}</h1>
        <button className={styles.toggleFilters} onClick={handleToggleFilters}>
          <Tune /> {toggleFilters ? tKey('Ocultar Filtros') : tKey('Mostrar Filtros')}
        </button>
      </div>
      <div className={`${styles.content} ${toggleFilters || styles.open}`}>{children}</div>
    </div>
  );
};

export default DirectoryHeader;
