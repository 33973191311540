import IconButton from '@material-ui/core/IconButton';
import { Link } from '@material-ui/icons';
import React from 'react';

interface ClickLinkProps {
  disabled?: boolean;
  value: string | undefined;
  onClickLink?: (value: any) => string;
}

const formatHTTPS = (value: string) => {
  if (value.startsWith('http://')) {
    value = value.replace('http://', 'https://');
  }
  if (value.startsWith('www.')) {
    value = value.replace('www.', 'https://');
  }
  if (value.startsWith('https://')) {
    return value;
  }
};

const ClickLink: React.FC<ClickLinkProps> = ({ disabled, value, onClickLink }) => {
  const handleClick = () => {
    if (onClickLink) {
      value = onClickLink(value);
    }
    if (value) {
      window.open(formatHTTPS(value), '_blank');
    }
  };

  return (
    <IconButton disabled={disabled} onClick={handleClick}>
      <Link />
    </IconButton>
  );
};

export default ClickLink;
