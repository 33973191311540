import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import FncButton from '../../../components/FncButton/FncButton';
import ValidatedTextInput from '../../../components/Inputs/ValidatedTextInput/ValidatedTextInput';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';
import { changePassword } from '../../../redux/user/actions';
import { ChangePasswordData, UserDataDispatch } from '../../../redux/user/definitions';
import { selectUserValidationErrors } from '../../../redux/user/selectors';
import { NewPasswordSchema } from '../../../validations/formSchema';
import styles from './changePassword.module.scss';
interface ChangePasswordFormProps {
  userChangePassword?: (data: ChangePasswordData) => void;
  textButton?: string;
  handleChangePassword?: any;
  validationErrors: APIValidationError | null;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  userChangePassword,
  textButton = tKey('CAMBIAR CONTRASEÑA'),
  handleChangePassword,
  validationErrors,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const { register, errors, handleSubmit, setError } = useForm<ChangePasswordData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: NewPasswordSchema,
  });

  useValidatorAPI(validationErrors, setError);

  const onSubmit = (data: ChangePasswordData) => {
    userChangePassword && userChangePassword(data);
    handleChangePassword && handleChangePassword();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <ValidatedTextInput
        id="password"
        size="50"
        autoComplete="new-password"
        label={tFormKey('Contraseña')}
        name="password"
        type={showPassword ? 'text' : 'password'}
        errors={errors}
        schema={NewPasswordSchema}
        register={register}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={event => event.preventDefault()}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ValidatedTextInput
        id="password2"
        size="50"
        autoComplete="new-password"
        label={tFormKey('Repita Contraseña')}
        name="password2"
        type={showPassword2 ? 'text' : 'password'}
        errors={errors}
        schema={NewPasswordSchema}
        register={register}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword2(!showPassword2)}
                onMouseDown={event => event.preventDefault()}
                edge="end"
              >
                {showPassword2 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <FncButton customClass={styles.button} buttonStyle="secondary" type="submit">
        {textButton}
      </FncButton>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  validationErrors: selectUserValidationErrors(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  userChangePassword: (data: ChangePasswordData): void => dispatch(changePassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
