export enum NotificationsConsts {
  READ_NOTIFICATION = 'READ:NOTIFICATION',
  READ_NOTIFICATION_OK = 'READ:NOTIFICATION:OK',
  READ_NOTIFICATION_KO = 'READ:NOTIFICATION:KO',

  NOTIFICATIONS_UPDATE = 'NOTIFICATIONS:UPDATE',
  NOTIFICATIONS_UPDATE_OK = 'NOTIFICATIONS:UPDATE:OK',
  NOTIFICATIONS_UPDATE_KO = 'NOTIFICATIONS:UPDATE:KO',
  NOTIFICATIONS_START_POLLING = 'NOTIFICATIONS:START:POLLING',
  NOTIFICATIONS_STOP_POLLING = 'NOTIFICATIONS:STOP:POLLING',

  NEW_MESSAGE = 'NEW:MESSAGE',
  NEW_MESSAGE_OK = 'NEW:MESSAGE:OK',
  NEW_MESSAGE_KO = 'NEW:MESSAGE:KO',
  REMOVE_PENDING_TASK = 'REMOVE_PENDING_TASK:REMOVE',
  REMOVE_PENDING_TASK_OK = 'REMOVE_PENDING_TASK:OK',
  REMOVE_PENDING_TASK_KO = 'REMOVE_PENDING_TASK:KO',
}
