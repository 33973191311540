import { Button, ButtonProps, InputLabel } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import DAFO from '../../../components/Dafo/Dafo';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import Loading from '../../../components/Loading/Loading';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import { documentationFilter } from '../../../helpers/docFilter';
import { tFormKey, tKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { EntityInfo } from '../../../redux/entity/definitions';
import { AnalisisFNCSchema } from '../../../validations/formSchema';
import styles from '../entityData.module.scss';
import projectStyles from './projectForm.module.scss';
interface AnalisisFNCProps {
  id: number;
  documentation: DocumentationItem[];
  entity: EntityInfo;
  leftButtons?: boolean;
  disabled?: boolean;
  buttonActionSave: ButtonProps;
  loading?: boolean;
  goBack?: () => void;
  onSave: (data: EntityInfo) => void;
  upload: (document_id: number, data: File, entity_id: any, force_add?: boolean | false) => void;
  download: (file_id: number, name: string, entityId: number) => void;
  remove: (file_id: number, entityId: number) => void;
  getFile: (file_id: number, name: string, entityId: number) => void;
  setData: (key: string, value: string | number) => void;
}

const AnalisisFNC: React.FC<AnalisisFNCProps> = ({
  leftButtons,
  documentation,
  entity,
  id,
  loading,
  disabled,
  buttonActionSave,
  onSave,
  upload,
  download,
  remove,
  getFile,
  goBack,
  setData,
}) => {
  const actions = ['checked', 'upload', 'download', 'view', 'remove'];
  const { register, handleSubmit, errors, control } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AnalisisFNCSchema,
  });

  useButtonAction(buttonActionSave, handleSubmit(onSave));

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <form className={styles.form}>
          <FormContainer space title={tKey('Análisis Nouscims')}>
            <DAFO
              control={control}
              schema={AnalisisFNCSchema}
              errors={errors}
              dName="fnc_analisys_dafo_1"
              dValue={entity.fnc_analisys_dafo_1}
              aName="fnc_analisys_dafo_2"
              disabled={disabled}
              aValue={entity.fnc_analisys_dafo_2}
              fName="fnc_analisys_dafo_3"
              fValue={entity.fnc_analisys_dafo_3}
              oName="fnc_analisys_dafo_4"
              oValue={entity.fnc_analisys_dafo_4}
              setData={setData}
            />
            <FncAddDocuments
              genericId={id}
              upload={upload}
              disabled={disabled}
              download={download}
              remove={remove}
              getFile={getFile}
              documents={documentationFilter(documentation, ['EDI'])}
              actions={actions}
            />
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('Indicadores de apoyo.')}</InputLabel>
              <TextInput
                label=""
                register={register}
                disabled={disabled}
                name="fnc_support"
                defaultValue={entity.fnc_support}
                size="100"
                rows="5"
                rowsMax="5"
                errors={errors.fnc_support}
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
          </FormContainer>
          {!leftButtons && (
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" startIcon={<ArrowBack />} color="primary" fullWidth onClick={goBack}>
                  {tKey('VOLVER')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  color="primary"
                  fullWidth
                  onClick={handleSubmit(onSave)}
                >
                  {tKey('Guardar')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default AnalisisFNC;
