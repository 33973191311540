import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { tKey } from '../../../helpers/translate';
import FabButtonGenially from '../FabButons/FabButtonGenially';
import FabButtonMenu from '../FabButons/FabButtonMenu';
import styles from './footer.module.scss';

interface FooterProps {
  menuDisabled?: boolean;
  isUserCreated?: boolean;
  showGeniallyFabButton: boolean;
  role: string;
}

const Footer: React.FC<FooterProps> = ({ menuDisabled, isUserCreated = true, role, showGeniallyFabButton }) => {
  return (
    <footer className={styles.root}>
      <ul className={styles.list}>
        <li className={styles.item}>
          <Link className={styles.link} to="/aviso-legal" target="_blank">
            {tKey('Aviso Legal')}
          </Link>
        </li>
        <li className={styles.item}>
          <Link className={styles.link} to="/politica-de-privacidad" target="_blank">
            {tKey('Política de Privacidad')}
          </Link>
        </li>
        <li className={styles.item}>
          <Link className={styles.link} to="/politica-de-cookies" target="_blank">
            {tKey('Política de Cookies')}
          </Link>
        </li>
      </ul>
      {isUserCreated && (
        <Fragment>
          <FabButtonMenu disabled={menuDisabled} />
          {showGeniallyFabButton && <FabButtonGenially />}
        </Fragment>
      )}
    </footer>
  );
};

export default Footer;
