import { APIError, APIValidationError } from '../../api/api';
import { EventDataConsts } from './action_types';
import {
  EventData,
  EventSubscriptionData,
  GetEventDataAction,
  GetEventDataOkAction,
  GetEventDataKoAction,
  GetEventsAction,
  GetEventsOkAction,
  GetEventsKoAction,
  GetEventSubscriptionsAction,
  GetEventSubscriptionsOkAction,
  GetEventSubscriptionsKoAction,
  GetEventRegistrationsAction,
  GetEventRegistrationsOkAction,
  GetEventRegistrationsKoAction,
  DeleteEventAction,
  DeleteEventOkAction,
  DeleteEventKoAction,
  ResetConfiguratorDataAction,
  EventValidationErrorsAction,
  UpdateEventTableAction,
  UpdateEventTableOkAction,
  UpdateEventTableKoAction,
  UpdateEventFormFields,
  EventCreationDataAction,
  SetEventRegistrationDataAction,
  SetEventAssistanceAction,
  UpdateEventSubscriptionDataAction,
  UpdateEventSubscriptionDataOkAction,
  UpdateEventSubscriptionDataKoAction,
  EventRegistrationData,
  CreateEventRegistrationOkAction,
  CreateEventRegistrationKoAction,
  CreateEventRegistrationAction,
  CreateEventSubscriptionKoAction,
  CreateEventSubscriptionOkAction,
  CreateEventSubscriptionAction,
  RemoveRegistrationAction,
  RemoveRegistrationOkAction,
  RemoveRegistrationKoAction,
  RemoveSubscriptionAction,
  RemoveSubscriptionOkAction,
  RemoveSubscriptionKoAction,
  GetConnectionUrlAction,
  GetConnectionUrlOkAction,
  GetConnectionUrlKoAction,
  UserRegistrationData,
  GetUserRegistrationDataAction,
  GetUserRegistrationDataOkAction,
  GetUserRegistrationDataKoAction,
  UploadDocumentEventAction,
  UploadDocumentEventOkAction,
  UploadDocumentEventKoAction,
  DownloadDocumentEventAction,
  DownloadDocumentOkEventAction,
  DownloadDocumentKoEventAction,
  GetDocumentEventAction,
  GetDocumentKoEventAction,
  GetDocumentOkEventAction,
  RemoveDocumentEventAction,
  RemoveDocumentKoEventAction,
  RemoveDocumentOkEventAction,
  GetUserEventsOkAction,
  GetUserEventsAction,
  GetUserEventsKoAction,
  EventsUser,
} from './definitions';

import { LookUpSubarea } from '../project/definitions';
import { DocumentationItem } from '../common/definitions';

export function getEventData(id: string): GetEventDataAction {
  return {
    type: EventDataConsts.GET_EVENT_DATA,
    id,
  };
}

export function getEventDataOk(eventData: EventData): GetEventDataOkAction {
  return {
    type: EventDataConsts.GET_EVENT_DATA_OK,
    eventData,
  };
}

export function getEventDataKo(error: string): GetEventDataKoAction {
  return {
    type: EventDataConsts.GET_EVENT_DATA_KO,
    error,
  };
}

export function getEvents(role?: string): GetEventsAction {
  return {
    type: EventDataConsts.GET_EVENTS,
    eventData: {} as EventData,
    role,
  };
}

export function getEventsOk(eventsList: EventData[], subareas: LookUpSubarea, role?: string): GetEventsOkAction {
  return {
    type: EventDataConsts.GET_EVENTS_OK,
    eventsList,
    subareas,
    role,
  };
}

export function getEventsKo(error: string): GetEventsKoAction {
  return {
    type: EventDataConsts.GET_EVENTS_KO,
    error,
  };
}

export function getUserEvents(): GetUserEventsAction {
  return {
    type: EventDataConsts.GET_USER_EVENTS,
  };
}

export function getUserEventsOk(eventsUserList: EventsUser[]): GetUserEventsOkAction {
  return {
    type: EventDataConsts.GET_USER_EVENTS_OK,
    eventsUserList,
  };
}

export function getUserEventsKo(error: string): GetUserEventsKoAction {
  return {
    type: EventDataConsts.GET_USER_EVENTS_KO,
    error,
  };
}
export function getEventSubscriptions(eventId: number): GetEventSubscriptionsAction {
  return {
    type: EventDataConsts.GET_EVENT_SUBSCRIPTIONS,
    eventId,
  };
}

export function getEventSubscriptionsOk(
  eventSubscriptionsList: EventSubscriptionData[],
): GetEventSubscriptionsOkAction {
  return {
    type: EventDataConsts.GET_EVENT_SUBSCRIPTIONS_OK,
    eventSubscriptionsList,
  };
}

export function getEventSubscriptionsKo(error: string): GetEventSubscriptionsKoAction {
  return {
    type: EventDataConsts.GET_EVENT_SUBSCRIPTIONS_KO,
    error,
  };
}

export function getEventRegistrations(eventId: number): GetEventRegistrationsAction {
  return {
    type: EventDataConsts.GET_EVENT_REGISTRATIONS,
    eventId,
  };
}

export function getEventRegistrationsOk(
  eventRegistrationsList: EventSubscriptionData[],
): GetEventRegistrationsOkAction {
  return {
    type: EventDataConsts.GET_EVENT_REGISTRATIONS_OK,
    eventRegistrationsList,
  };
}

export function getEventRegistrationsKo(error: string): GetEventRegistrationsKoAction {
  return {
    type: EventDataConsts.GET_EVENT_REGISTRATIONS_KO,
    error,
  };
}

export function createEventRegistration(eventRegistrationData: EventRegistrationData): CreateEventRegistrationAction {
  return {
    type: EventDataConsts.CREATE_EVENT_REGISTRATION,
    eventRegistrationData,
  };
}

export function createEventRegistrationOk(
  eventRegistrationData: EventRegistrationData,
): CreateEventRegistrationOkAction {
  return {
    type: EventDataConsts.CREATE_EVENT_REGISTRATION_OK,
    eventRegistrationData,
  };
}

export function createEventRegistrationKo(error: string): CreateEventRegistrationKoAction {
  return {
    type: EventDataConsts.CREATE_EVENT_REGISTRATION_KO,
    error,
  };
}

export function createEventSubscription(eventSubscriptionData: EventRegistrationData): CreateEventSubscriptionAction {
  return {
    type: EventDataConsts.CREATE_EVENT_SUBSCRIPTION,
    eventSubscriptionData,
  };
}

export function createEventSubscriptionOk(
  eventSubscriptionData: EventRegistrationData,
): CreateEventSubscriptionOkAction {
  return {
    type: EventDataConsts.CREATE_EVENT_SUBSCRIPTION_OK,
    eventSubscriptionData,
  };
}

export function createEventSubscriptionKo(error: string): CreateEventSubscriptionKoAction {
  return {
    type: EventDataConsts.CREATE_EVENT_SUBSCRIPTION_KO,
    error,
  };
}

export function setEventRegistrationData(
  key: string,
  value: string | number | boolean | null,
  id: number,
): SetEventRegistrationDataAction {
  return {
    type: EventDataConsts.SET_EVENT_REGISTRATION_DATA,
    key,
    value,
    id,
  };
}

export function setEventAssistance(attended: string, code: string): SetEventAssistanceAction {
  return {
    type: EventDataConsts.SET_EVENT_ASSISTANCE,
    attended,
    code,
  };
}

export function deleteEvent(id: number): DeleteEventAction {
  return {
    type: EventDataConsts.DELETE_EVENT,
    id,
  };
}

export function deleteEventOk(id: number): DeleteEventOkAction {
  return {
    type: EventDataConsts.DELETE_EVENT_OK,
    id,
  };
}

export function deleteEventKo(error: string): DeleteEventKoAction {
  return {
    type: EventDataConsts.DELETE_EVENT_KO,
    error,
  };
}

export function resetConfiguratorData(): ResetConfiguratorDataAction {
  return {
    type: EventDataConsts.RESET_CONFIGURATOR_DATA,
  };
}

// UPDATE EVENT TABLE
export function updateEventTable(data: UpdateEventFormFields, id: number): UpdateEventTableAction {
  return {
    type: EventDataConsts.UPDATE_EVENT_TABLE,
    id,
    data,
  };
}

export function updateEventTableOk({ data }: { data: EventData }): UpdateEventTableOkAction {
  return {
    type: EventDataConsts.UPDATE_EVENT_TABLE_OK,
    eventData: data,
  };
}

export function updateEventTableKo(error: string): UpdateEventTableKoAction {
  return {
    type: EventDataConsts.UPDATE_EVENT_TABLE_KO,
    error,
  };
}

export function eventCreationData(key: string, value: string | number | null): EventCreationDataAction {
  return {
    type: EventDataConsts.EVENT_CREATION_DATA,
    key,
    value,
  };
}

// VALIDATIONS ERRORS
export function eventValidationErrors(validationErrors: APIValidationError): EventValidationErrorsAction {
  return {
    type: EventDataConsts.EVENT_VALIDATION_ERRORS,
    validationErrors,
  };
}

// UPDATE INSCRIPTION DATA
export function updateInscriptionData(
  eventId: number,
  eventRegistrationsList: EventSubscriptionData[],
): UpdateEventSubscriptionDataAction {
  return {
    type: EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA,
    eventRegistrationsList: eventRegistrationsList,
    eventId,
  };
}

export function updateInscriptionDataOk(): UpdateEventSubscriptionDataOkAction {
  return {
    type: EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA_OK,
  };
}

export function updateInscriptionDataKo(error: APIValidationError): UpdateEventSubscriptionDataKoAction {
  return {
    type: EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA_KO,
    error,
  };
}

//REMOVE REGISTRATION

export function removeRegistration(eventId: number, registrationId: number): RemoveRegistrationAction {
  return {
    type: EventDataConsts.REMOVE_REGISTRATION,
    eventId,
    registrationId,
  };
}

export function removeRegistrationOk(registrationId: number): RemoveRegistrationOkAction {
  return {
    type: EventDataConsts.REMOVE_REGISTRATION_OK,
    registrationId,
  };
}

export function removeRegistrationKo(error: string): RemoveRegistrationKoAction {
  return {
    type: EventDataConsts.REMOVE_REGISTRATION_KO,
    error,
  };
}

// REMOVE SUBSCRIPTION

export function removeSubscription(eventId: number, registrationId: number): RemoveSubscriptionAction {
  return {
    type: EventDataConsts.REMOVE_SUBSCRIPTION,
    eventId,
    registrationId,
  };
}

export function removeSubscriptionOk(registrationId: number): RemoveSubscriptionOkAction {
  return {
    type: EventDataConsts.REMOVE_SUBSCRIPTION_OK,
    registrationId,
  };
}

export function removeSubscriptionKo(error: string): RemoveSubscriptionKoAction {
  return {
    type: EventDataConsts.REMOVE_SUBSCRIPTION_KO,
    error,
  };
}

// GET CONNECTION URL
export function getConnectionUrl(eventId: number, eventType: string): GetConnectionUrlAction {
  return {
    type: EventDataConsts.GET_CONNECTION_URL,
    eventId,
    eventType,
  };
}

export function getConnectionUrlOk(
  url: string,
  startDate: string,
  endDate: string,
  desktopFile: number,
  full: boolean,
): GetConnectionUrlOkAction {
  return {
    type: EventDataConsts.GET_CONNECTION_URL_OK,
    url,
    desktopFile,
    startDate,
    endDate,
    full,
  };
}

export function getConnectionUrlKo(error: string): GetConnectionUrlKoAction {
  return {
    type: EventDataConsts.GET_CONNECTION_URL_KO,
    error,
  };
}

// GET USER REGISTRATION DATA
export function getUserRegistrationData(eventId: number, userId: number): GetUserRegistrationDataAction {
  return {
    type: EventDataConsts.GET_USER_REGISTRATION_DATA,
    eventId,
    userId,
  };
}

export function getUserRegistrationDataOk(registrationData: UserRegistrationData): GetUserRegistrationDataOkAction {
  return {
    type: EventDataConsts.GET_USER_REGISTRATION_DATA_OK,
    registrationData,
  };
}

export function getUserRegistrationDataKo(error: string): GetUserRegistrationDataKoAction {
  return {
    type: EventDataConsts.GET_USER_REGISTRATION_DATA_KO,
    error,
  };
}

//UPLOAD DOCUMENT

export function uploadDocumentEvent(documentationId: number, data: File, eventId: number): UploadDocumentEventAction {
  return {
    type: EventDataConsts.UPLOAD_DOCUMENT_EVENT,
    documentationId,
    eventId,
    data,
  };
}

export function uploadDocumentEventOk(documents: DocumentationItem[]): UploadDocumentEventOkAction {
  return {
    type: EventDataConsts.UPLOAD_DOCUMENT_EVENT_OK,
    documents,
  };
}

export function uploadDocumentEventKo(error: APIError): UploadDocumentEventKoAction {
  return {
    type: EventDataConsts.UPLOAD_DOCUMENT_EVENT_KO,
    error,
  };
}

//DOWNLOAD DOCUMENT

export function downloadDocumentEvent(
  fileId: number,
  name: string,
  eventId: number,
  get = true,
): DownloadDocumentEventAction {
  return {
    type: EventDataConsts.DOWNLOAD_DOCUMENT_EVENT,
    fileId,
    name,
    eventId,
    get,
  };
}

export function downloadDocumentEventOk(data: Blob, name: string, get = true): DownloadDocumentOkEventAction {
  return {
    type: EventDataConsts.DOWNLOAD_DOCUMENT_EVENT_OK,
    data,
    name,
    get,
  };
}

export function downloadDocumentEventKo(error: APIError): DownloadDocumentKoEventAction {
  return {
    type: EventDataConsts.DOWNLOAD_DOCUMENT_EVENT_KO,
    error,
  };
}

//GET DOCUMENT

export function getDocumentEvent(fileId: number, name: string, eventId: number): GetDocumentEventAction {
  return {
    type: EventDataConsts.GET_DOCUMENT_EVENT,
    fileId,
    name,
    eventId,
  };
}

export function getDocumentEventOk(data: Blob, name: string): GetDocumentOkEventAction {
  return {
    type: EventDataConsts.GET_DOCUMENT_EVENT_OK,
    data,
    name,
  };
}

export function getDocumentEventKo(error: APIError): GetDocumentKoEventAction {
  return {
    type: EventDataConsts.GET_DOCUMENT_EVENT_KO,
    error,
  };
}

//REMOVE DOCUMENT

export function removeDocumentEvent(fileId: number, eventId: number): RemoveDocumentEventAction {
  return {
    type: EventDataConsts.REMOVE_DOCUMENT_EVENT,
    fileId,
    eventId,
  };
}

export function removeDocumentEventOk(documents: DocumentationItem[]): RemoveDocumentOkEventAction {
  return {
    type: EventDataConsts.REMOVE_DOCUMENT_EVENT_OK,
    documents,
  };
}

export function removeDocumentEventKo(error: APIError): RemoveDocumentKoEventAction {
  return {
    type: EventDataConsts.REMOVE_DOCUMENT_EVENT_KO,
    error,
  };
}
