import { APIValidationError } from '../../api/api';
import { DocumentationItem, DocUpload } from '../common/definitions';
import { AppState } from '../root-reducer';
import {
  EntityContactData,
  EntityContactRoles,
  EntityContract,
  EntityDataState,
  EntityInfo,
  EntityListItem,
} from './definitions';

export const selectEntity = (state: AppState): EntityDataState => state.entityReducer;
export const selectIsLoading = (state: AppState): boolean => selectEntity(state).loading;
export const selectIsError = (state: AppState): string | null => selectEntity(state).error;
export const selectGetOneEntity = (state: AppState): EntityInfo => selectEntity(state).entityOneData;
export const selectRoleList = (state: AppState): EntityContactRoles[] => selectEntity(state).roleList;
export const selectDeleteOneEntity = (state: AppState): any => selectEntity(state).responseData;
export const selectSearchText = (state: AppState): any => selectEntity(state).searchText;
export const selectPageSize = (state: AppState): number => selectEntity(state).pageSize;
export const selectColumnOrder = (state: AppState): any => selectEntity(state).columnOrder;
//export const selectEntityList = (state: AppState): EntityListItem[] => selectEntity(state).entityList;
export const selectCreationSended = (state: AppState): boolean => selectEntity(state).creationSended;
export const selectTransitionChangeSent = (state: AppState): boolean => selectEntity(state).transitionChangeSent;
export const selectIsAllSuccess = (state: AppState): boolean => selectEntity(state).isAllSuccess;
export const selectChangePage = (state: AppState): boolean => selectEntity(state).changePage;
export const selectDocuments = (state: AppState): DocumentationItem[] => selectEntity(state).documentation;
export const selectDocumentDoesntExist = (state: AppState) => (code: string) =>
  selectEntity(state).documentation.some(val => val.code === code && val.file_id === null);
export const selectEntityAvatar = (state: AppState): string | null => selectEntity(state).avatar;
export const selectDocumentsArr = (state: AppState): DocUpload[] => selectEntity(state).documentsArr;
export const selectValidationErrors = (state: AppState): APIValidationError | null =>
  selectEntity(state).validationErrors;
export const selectContractData = (state: AppState): EntityContract => selectEntity(state).contractData;
export const selectLoadingContract = (state: AppState): boolean => selectEntity(state).loadingContract;
export const selectSendingSage = (state: AppState): boolean => selectEntity(state).sendingSage;
export const selectContractModalView = (state: AppState): boolean => selectEntity(state).contractModalView;
export const selectEntityContacts = (state: AppState): EntityContactData[] => selectEntity(state).entityContactsList;
export const selectContactData = (state: AppState): any => selectEntity(state).contactData;
export const selectIbantData = (state: AppState): any => selectEntity(state).ibanData;
export const selectEntityList = (state: AppState): EntityListItem[] =>
  state.entityReducer.entityList.map(entity => {
    const types_entity = state.entityReducer.entityTypes.filter(({ code }) => entity[code]).map(type => type.code);
    return { ...entity, types_entity };
  });
