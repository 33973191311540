import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { tKey } from '../../helpers/translate';
import { getInterviewsData } from '../../redux/interviews/actions';
import { InterviewDataDispatch, InterviewTableRow } from '../../redux/interviews/definitions';
import { selectIsLoading, selectTableData } from '../../redux/interviews/selectors';
import { AppState } from '../../redux/root-reducer';
import InterviewList from './InterviewList';

interface FinishedInterviewsListProps {
  loading: boolean;
  interviewData: InterviewTableRow[];
  getInterviewsData: () => void;
}

const FinishedInterviewsList: React.FC<FinishedInterviewsListProps> = ({
  loading,
  interviewData,
  getInterviewsData,
}) => {
  useEffect(() => {
    getInterviewsData();
  }, [getInterviewsData]);

  return <InterviewList interviewData={interviewData} loading={loading} title={tKey('Entrevistas Pendientes')} />;
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  interviewData: selectTableData(state),
});

const mapDispatchToProps = (dispatch: InterviewDataDispatch) => ({
  getInterviewsData: (): void => dispatch(getInterviewsData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishedInterviewsList);
