/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import { AddEducatorAction, GetEducatorsAction, DeleteEducatorAction, UpdateEducatorAction } from './definition';
import { showSnackbar } from '../../FeedbackAPI/actions';
import {
  addEducatorKo,
  addEducatorOk,
  getEducatorsKo,
  getEducatorsOk,
  deleteEducatorOk,
  deleteEducatorKo,
  updateEducatorOk,
  updateEducatorKo,
} from './action';
import { tKey } from '../../../helpers/translate';
import { EducatorConst } from './action_types';
import errorMessage from '../../../helpers/errorMessage';
import { postEducator as postEducatorApi } from '../../../api/InternalProject/Educators/postEducator';
import { deleteEducator as deleteEducatorApi } from '../../../api/InternalProject/Educators/deleteEducator';
import { updateEducator as updateEducatorApi } from '../../../api/InternalProject/Educators/updateEducator';
import { EducatorsResponse, getEducatorsFromApi } from '../../../api/InternalProject/Educators/getEducators';
import { Await } from '../../../api/api';
import { tProjectKey } from '../../../helpers/translate';

function* postEducatorFromSagas(action: AddEducatorAction) {
  try {
    yield postEducatorApi(action.educator);
    yield put(showSnackbar(tKey('Nuevo educador añadido.'), 'success', undefined, 3000));
    yield put(addEducatorOk(action.educator));
  } catch (error) {
    yield put(showSnackbar('No se ha podido crear el educador', 'error', undefined, 1500));
    yield put(addEducatorKo());
  }
}

function* getEducatorsFromSagas(action: GetEducatorsAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const educatorList = (yield getEducatorsFromApi(id)) as Await<EducatorsResponse>;
    yield put(getEducatorsOk(educatorList.data));
  } catch (e) {
    yield put(getEducatorsKo(errorMessage(e)));
  }
}

function* deleteEducatorFromSagas(action: DeleteEducatorAction): Generator<any, void, any> {
  try {
    const { internal_project_id, educatorId } = action;
    yield deleteEducatorApi(internal_project_id, educatorId);
    yield put(deleteEducatorOk(internal_project_id, educatorId));
    yield put(showSnackbar('Educador eliminado', 'success', undefined, 3000));
  } catch (e) {
    yield put(showSnackbar('No se ha podido eliminar el educador', 'error', undefined, 1500));
    yield put(deleteEducatorKo(errorMessage(e)));
  }
}

function* updateEducatorFromSagas(action: UpdateEducatorAction) {
  try {
    const { educator } = action;

    yield updateEducatorApi(educator);
    yield put(updateEducatorOk(educator));
    yield put(showSnackbar(tProjectKey('pi.educators.educador_modificado'), 'success'));
  } catch (error) {
    yield put(updateEducatorKo(errorMessage(error)));
  }
}

export default [
  takeLatest<AddEducatorAction>(EducatorConst.ADD_EDUCATOR, postEducatorFromSagas),
  takeLatest<GetEducatorsAction>(EducatorConst.GET_EDUCATORS, getEducatorsFromSagas),
  takeLatest<DeleteEducatorAction>(EducatorConst.DELETE_EDUCATORS, deleteEducatorFromSagas),
  takeLatest<UpdateEducatorAction>(EducatorConst.UPDATE_EDUCATOR, updateEducatorFromSagas),
];
