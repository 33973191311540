import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions, zeroToTenOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { IdNamePair } from '../../../../redux/common/definitions';
import { ScholarshipFollow, ScholarshipMetadata } from '../../../../redux/scholarship/definitions';
import styles from './followForm.module.scss';

interface SocialImpactProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  register?: ReturnType<typeof useForm>['register'];
  setDataFollow: (subKey: string, value: string | number) => void;
}

const AcademyValoration = <T extends FieldValues>({
  scholarshipFollow,
  readOnly,
  scholarshipMetadata,
  errors,
  control,
  schema,
  register,
  setDataFollow,
}: SocialImpactProps<T>): ReactElement => {
  const {
    scholarship_academic_levels_id,
    special_academic_center_reasons,
    scholarship_academy_supports_id,
    scholarship_opportunities_id,
    special_academic_center,
    academic_center_social_risk,
    academic_center_social_risk_reasons,
    recommend_academic_center,
  } = scholarshipFollow;
  const { academicLevels, academicSupports, jobOpportunities } = scholarshipMetadata;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  return (
    <FormContainer title={tScholarshipKey('valoración-centro-estudios')}>
      <SelectController
        control={control}
        name="scholarship_academic_levels_id"
        label={tScholarshipKey('valoración-nivel-centro')}
        defaultValue={scholarship_academic_levels_id}
        size="50"
        disabled={readOnly}
        validator={!readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {academicLevels.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name="scholarship_academy_supports_id"
        label={tScholarshipKey('valoración-apoyo-alumnos')}
        defaultValue={scholarship_academy_supports_id}
        size="50"
        validator={!readOnly}
        disabled={readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {academicSupports.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name="scholarship_opportunities_id"
        label={tScholarshipKey('oportunidades-laborales')}
        defaultValue={scholarship_opportunities_id}
        size="50"
        disabled={readOnly}
        validator={!readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {jobOpportunities.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <div className={styles.containerQuestion}>
        <RadioButtonGroup
          name="special_academic_center"
          errors={errors.special_academic_center}
          disabled={readOnly}
          questionText={tScholarshipKey('centro-dificultades-técnicas')}
          options={yesNoOptions}
          value={special_academic_center?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('special_academic_center', +e.target.value);
          }}
        />
        <TextInputController
          control={control}
          name="special_academic_center_reasons"
          label={tScholarshipKey('consideras-conveniente')}
          defaultValue={special_academic_center_reasons}
          disabled={readOnly || special_academic_center === 1}
          validator={!readOnly && !special_academic_center}
          size="100"
          schema={schema}
          errors={errors}
          multiline
          rows={3}
          rowsMax={3}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            setDataFollow('special_academic_center_reasons', e.target.value)
          }
        />
      </div>
      <div className={styles.containerQuestion}>
        <RadioButtonGroup
          name="academic_center_social_risk"
          errors={errors.academic_center_social_risk}
          disabled={readOnly}
          questionText={tScholarshipKey('centro-adecuado-riesgo')}
          options={yesNoOptions}
          value={academic_center_social_risk?.toString() || '0'}
          register={register}
          onChange={e => {
            setDataFollow('academic_center_social_risk', +e.target.value);
          }}
        />
        <TextInputController
          control={control}
          name="academic_center_social_risk_reasons"
          label={tScholarshipKey(
            'Si la razón no está justificada con las anteriores, ¿por qué consideras que no es conveniente?',
          )}
          defaultValue={academic_center_social_risk_reasons}
          disabled={readOnly || !!academic_center_social_risk}
          validator={!readOnly && !academic_center_social_risk}
          size="100"
          schema={schema}
          errors={errors}
          multiline
          rows={3}
          rowsMax={3}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            setDataFollow('academic_center_social_risk_reasons', e.target.value)
          }
        />
      </div>
      <RadioButtonGroup
        name="recommend_academic_center"
        errors={errors.recommend_academic_center}
        disabled={readOnly}
        questionText={tScholarshipKey('recomendarías-centro')}
        options={zeroToTenOptions}
        value={recommend_academic_center?.toString() || '0'}
        register={register}
        onChange={e => {
          setDataFollow('recommend_academic_center', +e.target.value);
        }}
      />
    </FormContainer>
  );
};

export default AcademyValoration;
