import { ButtonProps } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { uploadScholarshipCreditCardsByDocument } from '../../../api/accounting';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SubTitle from '../../../components/SubTitle/Subtitle';
import Title from '../../../components/Title/Title';
import UploadLocalFileField from '../../../components/UploadLocalFileField/UploadLocalFileField';
import colorPalette from '../../../helpers/colorPalette';
import { tKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './massiveUpload.module.scss';

interface MassiveUploadDNIProps {
  loading: boolean;
}

export interface CreditCardsFile {
  documento: string;
  tarjeta: string;
}

const fileProcessedDefault = {
  errors: [],
  errors_count: 0,
  processed: [],
  processed_count: 0,
};

const MassiveUpload: React.FC<MassiveUploadDNIProps> = ({ loading }) => {
  const [fileProcessed, setFileProcessed] = useState(fileProcessedDefault);
  const [fileToSend, setFileToSend] = useState([] as CreditCardsFile[]);
  const [loadingFile, setLoading] = useState(false);

  const importFile = () => {
    (async function fetchSelectData() {
      setLoading(true);
      setFileProcessed(fileProcessedDefault);
      const { data } = await uploadScholarshipCreditCardsByDocument(fileToSend);
      setFileProcessed(data);
      setLoading(false);
    })();
  };

  const buttonsEdit: ButtonProps[] = [];

  const buttonSave: ButtonProps = {
    children: tKey('Cargar'),
    startIcon: <Save />,
    disableElevation: true,
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
  };

  buttonsEdit.push(buttonSave);

  return (
    <>
      <LayoutForm
        rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
        leftSubmenu={<AccountingSubmenu selected="cargas-masivas" />}
      >
        {loading ? (
          <Loading big />
        ) : (
          <>
            <Title>{tKey('Cargas Masivas')}</Title>
            <SubTitle>{tKey('masiva-dni')}</SubTitle>
            <UploadLocalFileField
              importFile={importFile}
              setFileToSend={setFileToSend}
              buttonActionSave={buttonSave}
              loading={loadingFile}
            />

            <SubTitle>{tKey('Resultados')}</SubTitle>
            <div className={styles.mainContainer}>
              <div className={styles.container}>
                {fileProcessed?.errors.map((e: string) => (
                  <div className={styles.error_line} key={e}>
                    <Clear style={{ color: colorPalette.primary, marginRight: '5px', fontSize: '1em' }} />
                    <span className={styles.errors_count}>{e}</span>
                  </div>
                ))}
              </div>

              <div className={styles.sub_container}>
                <p className={styles.errors_count}>
                  <Clear style={{ color: colorPalette.primary, marginRight: '5px', fontSize: '1em' }} />
                  <strong>{fileProcessed?.errors_count || 0}</strong>
                  {tKey('error/es detectado/s')}
                </p>
                <p className={styles.errors_count}>
                  <CheckIcon style={{ color: colorPalette.greenBubble, marginRight: '5px', fontSize: '1em' }} />
                  <strong>{fileProcessed?.processed_count || 0}</strong>
                  {tKey('documento/s procesado/s')}
                </p>
              </div>
            </div>
          </>
        )}
      </LayoutForm>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MassiveUpload);
