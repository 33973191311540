import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { useTranslation } from 'react-i18next';
import colorPalette from '../../helpers/colorPalette';
import { tErrorKey, tKey } from '../../helpers/translate';
import { ResponseData } from '../../redux/common/definitions';
import styles from './bodyModal.module.scss';

interface BodyModalBase {
  handleClose?: () => void;
}

interface ConfirmModal extends BodyModalBase {
  entityName: string[];
  type: 'confirm';
}

interface ResponseModal extends BodyModalBase {
  type: 'response';
  data: ResponseData;
  itemType: 'scholarship' | 'user' | 'entity';
}

type BodyModalProps = ConfirmModal | ResponseModal;

const BodyModal: React.FC<{ options: BodyModalProps }> = ({ options }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      {options.type === 'confirm' && (
        <div>
          <span>{tKey('¿Seguro que desea eliminar estas entidades?:')}</span>
          <div className={styles.textContainer}>
            {options.entityName.map(ele => (
              <p key={ele}>- {ele}</p>
            ))}
          </div>
        </div>
      )}
      {options.type === 'response' &&
        options.data.data.map(ele =>
          ele.success ? (
            <div className={styles.okay} key={ele.id}>
              <div className={styles.iconMailContainer}>
                <InfoIcon style={{ color: colorPalette.greenBubble, height: '25px', width: '25px' }} />
              </div>
              <div className={styles.textContainer}>
                <span className={styles.nameOkay}>{ele.name}</span>
                <span>
                  {tKey('Se ha eliminado')} {options.itemType === 'user' ? 'el ' : 'la '}
                  {t(`common.${options.itemType}`)}
                </span>
              </div>
            </div>
          ) : (
            <div className={styles.wrong} key={ele.id}>
              <div className={styles.iconMailContainer}>
                <WarningIcon style={{ color: colorPalette.primary80, height: '25px', width: '25px' }} />
              </div>
              <div className={styles.textContainer}>
                <span className={styles.nameWrong}>{ele.name}</span>
                <span>
                  {ele.error
                    ? ele.error
                    : `${tErrorKey('No se ha podido eliminar ')}${options.itemType === 'user' ? 'el' : 'la'} ${t(
                        `common.${options.itemType}`,
                      )}, ${tErrorKey('hay datos asociados que no se pueden eliminar.')}`}
                </span>
              </div>
            </div>
          ),
        )}
    </div>
  );
};

export default BodyModal;
