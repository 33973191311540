import React from 'react';
import { tScholarshipKey } from '../../../helpers/translate';
import styles from './ButtonsMobile.module.scss';
import FncButton from '../../../components/FncButton/FncButton';
import InputSearch from '../../../components/Inputs/InputSearch/InputSearch';

interface Props {
  onHelp: () => void;
  onNew: () => void;
  canCreate?: boolean;
  isNousCims?: boolean;
  onSearch: (value: string) => void;
}

const ButtonsMobile = ({ onHelp, onNew, canCreate, isNousCims, onSearch }: Props) => {
  return (
    <>
      <div className={styles.root}>
        <FncButton
          customClass={styles.button}
          buttonStyle={'secondary'}
          onClick={() => {
            onHelp();
          }}
        >
          {tScholarshipKey('Ayuda')}
        </FncButton>
        {canCreate && (
          <FncButton
            customClass={styles.button}
            onClick={() => {
              onNew();
            }}
          >
            {tScholarshipKey('Nueva beca')}
          </FncButton>
        )}
      </div>
      {!isNousCims && (
        <div className={styles.search}>
          <InputSearch onSearch={onSearch} />
        </div>
      )}
    </>
  );
};

export default ButtonsMobile;
