import Button, { ButtonProps } from '@material-ui/core/Button/Button';
import { InsertDriveFile } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { useButtonAction } from '../../helpers/customHooks/useHookMethod';
import { tErrorKey, tKey } from '../../helpers/translate';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { AppState } from '../../redux/root-reducer';
import FormContainer from '../Forms/FormContainer';
import TextInput from '../Inputs/TextInput';
import Loading from '../Loading/Loading';
import styles from './uploadLocalFileField.module.scss';

interface UploadLocalFileFieldProps {
  buttonActionSave: ButtonProps;
  loading?: boolean;
  importFile: () => void;
  setFileToSend: (data: any[]) => void;
}

type JSONFormat = { [k: string]: string };

const UploadLocalFileField: React.FC<UploadLocalFileFieldProps> = ({
  setFileToSend,
  importFile,
  buttonActionSave,
  loading,
}) => {
  const [,setFileLoad] = useState(false);
  const [fileJson, setFileJson] = useState<any[]>([] as any[]);
  const [fileName, setFileName] = useState('');

  const readFile = (jsonExcel: any) => {
    const f = jsonExcel.file;
    const reader = new FileReader();
    reader.onload = evt => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      //@ts-ignore
      const data = XLSX.utils.sheet_to_json(ws);

      /* Update state */
      if (Array.isArray(data)) setFileJson(data);
    };
    //@ts-ignore
    reader.readAsBinaryString(f);
  };

  useEffect(() => {
    setFileToSend(fileJson);
  }, [fileJson,setFileToSend]);

  const filePathset = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    setFileName(file?.name || '');

    setFileLoad(true);
    try {
      readFile({ file });
      setFileLoad(false);
    } catch (e) {
      showSnackbar(tErrorKey('Ha ocurrido un error, no se ha podido leer el archivo'), 'error', undefined, 1500);
      setFileLoad(false);
    }
  };

  const onImport = () => {
    importFile();
  };

  useButtonAction(buttonActionSave, onImport);

  return (
    <form>
      <FormContainer className={styles.container} title={''}>
        <input type="file" id="file" onChange={filePathset} style={{ display: 'none' }} />
        <div className={styles.textContainer}>
          <TextInput type="text" fullWidth name="file_name" label={tKey('Subir archivo')} value={fileName} disabled />
        </div>
        <div className={styles.buttonContainer}>
          <label htmlFor="file">
            <Button variant="outlined" color="primary" component="span" fullWidth disabled={loading}>
              <InsertDriveFile />
              {tKey('Elegir archivo')}
            </Button>
          </label>
          {loading ? <Loading small /> : null}
        </div>
      </FormContainer>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UploadLocalFileField);
