import { Action } from 'redux';
import { CalendarConsts } from './action_types';

export interface IDate { //eslint-disable-line
  formattedDay: string;
  originalDate: number;
  selected: boolean;
  disabled: boolean;
}

export interface ITime { //eslint-disable-line
  date: number;
  time: string;
  selected: selectedTypes;
  channel: calendarChannels;
}

export interface DatesData {
  dates: IDate[];
  times: ITime[];
  prev: number;
  next: number;
}

export type calendarChannels = 'online' | 'ambas' | 'presencial' | null;
export type selectedTypes = 'not available' | 'free' | 'busy';

export const initialState = {
  loading: false,
  loadingDates: false,
  loadingTimes: false,
  error: null as string | null,
  datesTime: {} as DatesData | null,
  selectedDate: {} as IDate,
  selectedTime: {} as ITime,
  responseSetNotAvailable: null as string | null,
  responseSetFree: null as string | null,
};

export type CalendarState = typeof initialState;

export interface ScheduleHour {
  hour: string;
  scheduled: boolean;
}

export interface GetAllDatesAction extends Action {
  type: CalendarConsts.GET_ALL_DATES;
  data: number;
}

export interface GetAllDatesOkAction extends Action {
  type: CalendarConsts.GET_ALL_DATES_OK;
  datesTime: DatesData;
}

export interface GetAllDatesKoAction extends Action {
  type: CalendarConsts.GET_ALL_DATES_KO;
  error: string;
}

export interface SetTimeToNotAvailableAction extends Action {
  type: CalendarConsts.SET_TIME_TO_NOT_AVAILABLE;
  data: number;
}

export interface SetTimeToNotAvailableOkAction extends Action {
  type: CalendarConsts.SET_TIME_TO_NOT_AVAILABLE_OK;
  responseSetNotAvailable: string;
}

export interface SetTimeToNotAvailableKoAction extends Action {
  type: CalendarConsts.SET_TIME_TO_NOT_AVAILABLE_KO;
  error: string;
}

export interface SetTimeToFreeAction extends Action {
  type: CalendarConsts.SET_TIME_TO_FREE;
  data: number;
  channel: string;
}

export interface SetTimeToFreeOkAction extends Action {
  type: CalendarConsts.SET_TIME_TO_FREE_OK;
  responseSetFree: string;
  channel: string;
}

export interface SetTimeToFreeKoAction extends Action {
  type: CalendarConsts.SET_TIME_TO_FREE_KO;
  error: string;
}

export interface SetSelectedTime extends Action {
  type: CalendarConsts.SET_SELECTED_TIME;
  selectedTime: ITime;
}

export type InterviewDataAction =
  | GetAllDatesAction
  | GetAllDatesOkAction
  | GetAllDatesKoAction
  | SetTimeToNotAvailableAction
  | SetTimeToNotAvailableOkAction
  | SetTimeToNotAvailableKoAction
  | SetTimeToFreeAction
  | SetTimeToFreeOkAction
  | SetTimeToFreeKoAction
  | SetSelectedTime;
