export enum DirectoryDataConsts {
  GET_DIRECTORY = 'GET:DIRECTORY',
  GET_DIRECTORY_OK = 'GET:DIRECTORY:OK',
  GET_DIRECTORY_KO = 'GET:DIRECTORY:KO',

  GET_DIRECTORY_FILTER = 'GET:DIRECTORY:FILTER',
  GET_DIRECTORY_FILTER_OK = 'GET:DIRECTORY:FILTER:OK',
  GET_DIRECTORY_FILTER_KO = 'GET:DIRECTORY:FILTER:KO',

  SEND_SUGGESTION = 'SEND:SUGGESTION',
  SEND_SUGGESTION_OK = 'SEND:SUGGESTION:OK',
  SEND_SUGGESTION_KO = 'SEND:SUGGESTION:KO',

  CLEAN_USER_DATA = 'CLEAN:USER:DATA',

  SET_PAGINATION = 'SET:PAGINATION',
}
