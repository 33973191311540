import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { TransversalCompetenceResponseItem } from '../../../../redux/scholarship/definitions';
import { tScholarshipKey } from '../../../../helpers/translate';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import styles from './transversalCompetencesModal.module.scss';
import { getTransversalCompetencesResponsesFormApi } from '../../../../api/Scholarship/scholarship';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface TransversalCompetencesModalProps {
  title: string;
  view: boolean;
  scholarship_id: number;
  handleClose: () => void;
}

const TransversalCompetencesModal: React.FC<TransversalCompetencesModalProps> = ({
  title,
  view,
  scholarship_id,
  handleClose,
}) => {
  const [responses, setResponses] = useState<TransversalCompetenceResponseItem[]>([]);

  useEffect(() => {
    if (scholarship_id) {
      getTransversalCompetencesResponsesFormApi(scholarship_id)
        .then(data => setResponses(data))
        .catch(console.log);
    }
  }, [scholarship_id]);

  const cellStyle = { minWidth: 120, textAlign: 'center' } as CSSProperties;
  const headerStyle = { textAlign: 'center' } as CSSProperties;

  const tableData: MaterialTableProps<TransversalCompetenceResponseItem> = {
    title: '',
    columns: [
      {
        field: 'question',
        title: tScholarshipKey('responses_question'),
      },
      {
        field: 'first_educator_response',
        title: tScholarshipKey('responses_educator_initial'),
        headerStyle,
        cellStyle,
      },
      {
        field: 'last_educator_response',
        title: tScholarshipKey('responses_educator_final'),
        headerStyle,
        cellStyle,
      },
      {
        field: 'first_student_response',
        title: tScholarshipKey('responses_student_initial'),
        headerStyle,
        cellStyle,
      },
      {
        field: 'last_student_response',
        title: tScholarshipKey('responses_student_final'),
        headerStyle,
        cellStyle,
      },
    ],
    data: responses,
    isLoading: responses.length === 0,
    options: {
      tableLayout: 'auto',
      paging: false,
      sorting: false,
    },
  };

  return (
    <div>
      <Dialog
        open={view}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        closeAfterTransition
        className={styles.dialogForm}
      >
        <div className={styles.title}>
          <DialogTitle classes={{ root: styles.titleRed }} id="alert-dialog-title">
            {title}
          </DialogTitle>
          <IconButton onClick={handleClose} aria-label="delete" className={styles.buttonClose} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <DialogContent>
          <TableWithEdit tableData={{ ...tableData }} noSearch key={JSON.stringify(tableData.data)} permission={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus variant="contained" disableElevation>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TransversalCompetencesModal;
