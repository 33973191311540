import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigation, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { tKey, tProjectKey } from '../../helpers/translate';
import noImage from '../../images/image-not-found.png';
import { AppState } from '../../redux/root-reducer';
import ImageEditionModal from '../Modal/ImageEditionModal/ImageEditionModal';
import TransitionModal from '../Modal/TransitionModal';
import styles from './imageGallery.module.scss';
import 'swiper/swiper.scss';
import 'swiper/modules/navigation/navigation.scss';
import { ResourceImageData } from '../../redux/common/definitions';

interface ImageGalleryProps {
  images: ResourceImageData[];
  editImage: (id: number, name: string, description: string, newImage: string) => void;
  deleteImage: (id: number) => void;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, editImage, deleteImage }) => {
  const currentUserId = useSelector((state: AppState) => state.authReducer).userInfo.user_id;
  const [showEditImageModal, setShowEditImageModal] = useState<boolean>(false);
  const [imageSelected, setImageSelected] = useState<ResourceImageData | null>(null);

  const swiperParams: SwiperOptions = {
    modules: [Navigation],
    parallax: true,
    navigation: true,
    direction: 'horizontal',
    grabCursor: true,
    slidesPerView: 'auto',
  };

  const handleImageClick = (id: number, name: string, src: string, description: string, imgUserId: number) => {
    const img = {
      id: id,
      name: name,
      description: description,
      image: src,
      user_id: imgUserId,
    } as ResourceImageData;
    setImageSelected(img);
    setShowEditImageModal(true);
  };

  const renderSwiperSlideImage = (
    index: number,
    id: number,
    name: string,
    src: string,
    description: string,
    imgUserId: number,
    updated_at: string,
  ): JSX.Element => (
    <SwiperSlide key={index}>
      <>
        <p className={styles.title}>{name}</p>
        <img
          key={id}
          className={styles.image}
          title={description}
          src={src}
          alt={name}
          onClick={() => {
            if (currentUserId === imgUserId) handleImageClick(id, name, src, description, imgUserId);
          }}
        />
        <p className={styles.description}>{`${updated_at}: ${description}`}</p>
      </>
    </SwiperSlide>
  );

  let renderSwiperSlideImages: JSX.Element | JSX.Element[];
  if (images.length === 0) {
    renderSwiperSlideImages = renderSwiperSlideImage(
      0,
      0,
      tKey('No hay imágenes'),
      noImage,
      tKey('No hay imágenes'),
      0,
      '',
    );
  } else {
    renderSwiperSlideImages = images.map((image: ResourceImageData, index: number) =>
      renderSwiperSlideImage(
        index,
        image.id,
        image.name,
        image.image,
        image.description,
        image.user_id,
        image.updated_at,
      ),
    );
  }

  return (
    <div className={styles.imageGalleryContainer}>
      <div className={styles.swiperSection}>
        <Swiper {...swiperParams}>{renderSwiperSlideImages}</Swiper>
      </div>
      <TransitionModal
        view={showEditImageModal}
        handleClose={() => setShowEditImageModal(false)}
        title={tProjectKey('Editar imagen')}
      >
        <ImageEditionModal
          imageSelected={imageSelected}
          editImage={(id: number, name: string, description: string, newImage: string) => {
            editImage(id, name, description, newImage);
            setShowEditImageModal(false);
          }}
          deleteImage={(id: number) => {
            deleteImage(id);
            setShowEditImageModal(false);
          }}
        />
      </TransitionModal>
    </div>
  );
};

export default ImageGallery;
