export enum AboutUsConsts {
  ABOUTUS_METADATA = 'ABOUTUS:METADATA',
  ABOUTUS_METADATA_OK = 'ABOUTUS:METADATA:OK',
  ABOUTUS_METADATA_KO = 'ABOUTUS:METADATA:KO',

  ABOUTUS_RESOURCES = 'ABOUTUS:RESOURCES',
  ABOUTUS_RESOURCES_OK = 'ABOUTUS:RESOURCES:OK',
  ABOUTUS_RESOURCES_KO = 'ABOUTUS:RESOURCES:KO',

  ABOUTUS_SAVE_RESOURCE = 'ABOUTUS:SAVE:RESOURCE',
  ABOUTUS_SAVE_RESOURCE_OK = 'ABOUTUS:SAVE:RESOURCE:OK',
  ABOUTUS_SAVE_RESOURCE_KO = 'ABOUTUS:SAVE:RESOURCE:KO',

  ABOUTUS_NEW_RESOURCE = 'ABOUTUS:NEW:RESOURCE',
  ABOUTUS_NEW_RESOURCE_OK = 'ABOUTUS:NEW:RESOURCE:OK',
  ABOUTUS_NEW_RESOURCE_KO = 'ABOUTUS:NEW:RESOURCE:KO',

  ABOUTUS_ONE_RESOURCES = 'ABOUTUS:ONE:RESOURCES',
  ABOUTUS_ONE_RESOURCES_OK = 'ABOUTUS:ONE:RESOURCES:OK',
  ABOUTUS_ONE_RESOURCES_KO = 'ABOUTUS:ONE:RESOURCES:KO',

  ABOUTUS_INITIAL_RESOURCE = 'ABOUTUS:INITIAL:RESOURCE',
  ABOUTUS_INITIAL_RESOURCE_OK = 'ABOUTUS:INITIAL:RESOURCE:OK',
  ABOUTUS_INITIAL_RESOURCE_KO = 'ABOUTUS:INITIAL:RESOURCE:KO',

  ABOUTUS_NEW_DOCUMENT = 'ABOUTUS:NEW:DOCUMENT',
  ABOUTUS_NEW_DOCUMENT_OK = 'ABOUTUS:NEW:DOCUMENT:OK',
  ABOUTUS_NEW_DOCUMENT_KO = 'ABOUTUS:NEW:DOCUMENT:KO',

  ABOUTUS_DOWNLOAD_DOCUMENT = 'ABOUTUS:DOWNLOAD:DOCUMENT',
  ABOUTUS_DOWNLOAD_DOCUMENT_OK = 'ABOUTUS:DOWNLOAD:DOCUMENT:OK',
  ABOUTUS_DOWNLOAD_DOCUMENT_KO = 'ABOUTUS:DOWNLOAD:DOCUMENT:KO',

  ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE = 'ABOUTUS:DOWNLOAD:DOCUMENT:BY:CODE',
  ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_OK = 'ABOUTUS:DOWNLOAD:DOCUMENT:BY:CODE:OK',
  ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_KO = 'ABOUTUS:DOWNLOAD:DOCUMENT:BY:CODE:KO',

  ABOUTUS_OPEN_DOCUMENT = 'ABOUTUS:OPEN:DOCUMENT',
  ABOUTUS_OPEN_DOCUMENT_OK = 'ABOUTUS:OPEN:DOCUMENT:OK',
  ABOUTUS_OPEN_DOCUMENT_KO = 'ABOUTUS:OPEN:DOCUMENT:KO',

  ABOUTUS_DELETE_DOCUMENT = 'ABOUTUS:DELETE:DOCUMENT',
  ABOUTUS_DELETE_DOCUMENT_OK = 'ABOUTUS:DELETE:DOCUMENT:OK',
  ABOUTUS_DELETE_DOCUMENT_KO = 'ABOUTUS:DELETE:DOCUMENT:KO',

  ABOUTUS_DELETE_DOCUMENT_BY_ID = 'ABOUTUS:DELETE:DOCUMENT:BY:ID',
  ABOUTUS_DELETE_DOCUMENT_BY_ID_OK = 'ABOUTUS:DELETE:DOCUMENT:BY:ID:OK',
  ABOUTUS_DELETE_DOCUMENT_BY_ID_KO = 'ABOUTUS:DELETE:DOCUMENT:BY:ID:KO',
}
