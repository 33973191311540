import React from 'react';
import { tErrorKey, tKey } from '../../../../../../helpers/translate';
import FormContainer from '../../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../../components/Inputs/TextInputController/TextInputController';
import TransitionModal from '../../../../../../components/Modal/TransitionModal';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  FollowUpData,
  InternalProjectTemplateDispatch,
} from '../../../../../../redux/internal-project-templates/definitions';
import { duplicateTemplateFollowUp } from '../../../../../../api/internalProjectTemplates';
import { showSnackbar } from '../../../../../../redux/FeedbackAPI/actions';
import { connect } from 'react-redux';
import { AppState } from '../../../../../../redux/root-reducer';

interface Props {
  followUp: FollowUpData;
  openModal: boolean;
  onCloseModal: () => void;
  onSuccess: () => void;
  showSnackbar: (message: string) => void;
}

const DuplicateFollowUpModal = ({ followUp, openModal, onCloseModal, onSuccess, showSnackbar }: Props) => {
  const DuplicateTemplateFollowUp = yup.object().shape({
    name: yup.string().required(tErrorKey('Este campo es obligatorio')),
  });
  const { errors, control, handleSubmit } = useForm<{ name: string }>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: DuplicateTemplateFollowUp,
  });
  const handleDuplicate = async (data: { name: string }) => {
    await duplicateTemplateFollowUp(followUp.id, data.name)
      .then(response => {
        if (response.data === 'OK') {
          onCloseModal();
          onSuccess();
        }
      })
      .catch(error => {
        showSnackbar(error.message);
      });
  };
  return (
    <>
      <TransitionModal
        view={openModal}
        title={`${tKey('Duplicar seguimiento')} - ${followUp.name}`}
        buttonOk={tKey('Duplicar')}
        buttonKo={tKey('Cancelar')}
        handleClose={onCloseModal}
        handleNo={onCloseModal}
        handleYes={handleSubmit(handleDuplicate)}
      >
        {
          <FormContainer title="" lowMargin={true}>
            <TextInputController
              name="name"
              label={tKey('Nombre del nuevo seguimiento')}
              size="100"
              control={control}
              errors={errors}
              schema={DuplicateTemplateFollowUp}
            />
          </FormContainer>
        }
      </TransitionModal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  showSnackbar: (message: string): void => {
    dispatch(showSnackbar(message, 'error', undefined, 1500));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(DuplicateFollowUpModal);
