import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTableProps } from 'material-table';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormContainer from '../../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../../../components/Select/SelectController';
import TableWithEdit from '../../../../../../components/Tables/TableWithEdit';
import { tFormKey, tKey, tProjectKey } from '../../../../../../helpers/translate';
import { CodeNamePair, IdNamePair } from '../../../../../../redux/common/definitions';
import {
  createInternalProjectTemplateFollowUp,
  removeInternalProjectTemplateFollowUpRoleData,
  setInternalProjectTemplateFollowUpRoleData,
  updateInternalProjectTemplateFollowUpRoles,
} from '../../../../../../redux/internal-project-templates/actions';
import {
  FollowUpData,
  InternalProjectTemplateDispatch,
  RoleWithPermission,
} from '../../../../../../redux/internal-project-templates/definitions';
import { InternalProjectTemplateFollowUpSchema } from '../../../../../../validations/formSchema';
import styles from './internalProjectTemplateFollowUpsEdition.module.scss';

interface InternalProjectTemplateFollowUpsEditionProps {
  followUpTypes: CodeNamePair[];
  followUpData: FollowUpData | null;
  roles: IdNamePair[];
  handleClose: () => void;
  createInternalProjectTemplateFollowUp: (data: FollowUpData) => void;
  setFollowUpRoleData: (followUpId: number, role: RoleWithPermission) => void;
  removeFollowUpRoleData: (formId: number, roleId: number) => void;
  saveInternalProjectTemplateFollowUpFormRoles: (
    internalProjectTemplateId: number,
    followUpId: number,
    roles: RoleWithPermission[],
  ) => void;
}

const InternalProjectTemplateFollowUpsEdition: React.FC<InternalProjectTemplateFollowUpsEditionProps> = ({
  roles,
  followUpTypes,
  followUpData,
  handleClose,
  createInternalProjectTemplateFollowUp,
  setFollowUpRoleData,
  removeFollowUpRoleData,
  saveInternalProjectTemplateFollowUpFormRoles,
}) => {
  const history = useHistory();
  const { control, errors, setValue, handleSubmit } = useForm<FollowUpData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InternalProjectTemplateFollowUpSchema,
  });

  const [createFollowUpData, setCreateFollowUpData] = useState<FollowUpData>({
    roles: [] as RoleWithPermission[],
  } as FollowUpData);
  const name = followUpData?.name;
  const section = followUpData?.section;
  const followUpRoles = followUpData?.roles || createFollowUpData.roles || [];

  const [roleSelected, setRoleSelected] = useState<number | null>(null);
  const [allowEditSelected, setAllowEditSelected] = useState<boolean>(false);

  const tableData: MaterialTableProps<RoleWithPermission> = {
    title: '',
    columns: [
      {
        title: tFormKey('Rol'),
        field: 'name',
      },
      {
        title: tFormKey('Permitir edición'),
        field: 'allow_edit',
        type: 'boolean',
        render: (rowData: RoleWithPermission) => (
          <Checkbox
            color="primary"
            checked={Boolean(rowData.allow_edit)}
            onChange={() => {
              const role = { ...rowData, allow_edit: !rowData.allow_edit };
              if (followUpData) setFollowUpRoleData(followUpData.id, role);
              else {
                const roles = createFollowUpData.roles.map((e: RoleWithPermission) => {
                  if (e.id === rowData.id) e.allow_edit = !e.allow_edit;
                  return e;
                });
                setCreateFollowUpData({ ...createFollowUpData, roles: roles });
              }
            }}
          />
        ),
      },
    ],
    options: {
      selection: false,
      pageSize: 3,
      tableLayout: 'auto',
      showSelectAllCheckbox: true,
      paging: false,
      minBodyHeight: 330,
      maxBodyHeight: 330,
    },
    actions: [
      (rowData: RoleWithPermission) => ({
        icon: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
        onClick: () => {
          if (followUpData) {
            removeFollowUpRoleData(followUpData.id, rowData.id);
          } else {
            const index = createFollowUpData.roles.findIndex((e: RoleWithPermission) => e.id === rowData.id);
            createFollowUpData.roles.splice(index, 1);
            setCreateFollowUpData({ ...createFollowUpData });
          }
        },
        tooltip: tKey('Borrar'),
      }),
    ],
    data: followUpRoles,
  };

  const getModalData = (data: FollowUpData) => {
    return {
      name: data.name,
      section: data.section,
      internal_project_template_id:
        followUpData?.internal_project_template_id || Number(window.location.href.split('/').pop()),
      role_names: followUpData?.role_names,
      roles: followUpData?.roles || createFollowUpData.roles,
      type: followUpData?.type,
    } as FollowUpData;
  };

  const handleCreate = (data: FollowUpData) => {
    const dataToSend = getModalData(data);
    createInternalProjectTemplateFollowUp(dataToSend);
    handleClose();
  };

  const handleAddRole = () => {
    const selectedRole = roles.find((e: IdNamePair) => e.id === roleSelected);
    if (selectedRole) {
      const roleExists = followUpRoles.find((e: RoleWithPermission) => e.id === selectedRole.id);
      if (!roleExists) {
        const role = { ...selectedRole, allow_edit: allowEditSelected };
        if (followUpData) {
          setFollowUpRoleData(followUpData.id, role);
        } else {
          const roles = createFollowUpData.roles.concat(role);
          setCreateFollowUpData({ ...createFollowUpData, roles: roles });
        }
      }
    }
  };

  const handleSave = () => {
    if (followUpData) {
      saveInternalProjectTemplateFollowUpFormRoles(
        followUpData.internal_project_template_id,
        followUpData.id,
        followUpRoles,
      );
    }
    handleClose();
  };

  const onHandleSubmit = (data: FollowUpData) => {
    if (followUpData) handleSave();
    else handleCreate(data);
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">
          {followUpData ? tProjectKey('Editar seguimiento') : tProjectKey('Crear seguimiento')}
        </DialogTitle>
        <IconButton aria-label="delete" size="small" onClick={handleClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        <form onSubmit={e => e.preventDefault()} className={styles.form}>
          <FormContainer title="">
            <TextInputController
              key="follow_up_name"
              name="name"
              label={tKey('Nombre')}
              defaultValue={name}
              size="50"
              disabled={Boolean(followUpData)}
              control={control}
              errors={errors}
              schema={InternalProjectTemplateFollowUpSchema}
            />
            <SelectController
              key="follow_up_section"
              name="section"
              label={tKey('Tipo')}
              defaultValue={section}
              size="50"
              disabled={Boolean(followUpData)}
              control={control}
              errors={errors}
              schema={InternalProjectTemplateFollowUpSchema}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                setValue('section', event.target.value);
              }}
            >
              {followUpTypes.map((element: CodeNamePair) => (
                <MenuItem key={element.code} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>

            <div className={styles.select_role}>
              <FormControl
                variant="outlined"
                classes={{
                  root: styles.select_wrapper,
                }}
              >
                <InputLabel id="rol">{tKey('Rol')}</InputLabel>
                <Select
                  id="roles"
                  name="roles"
                  value={roleSelected || ''}
                  label={tFormKey('Rol')}
                  onChange={event => {
                    if (typeof event.target.value !== 'number') return;
                    setRoleSelected(event.target.value);
                  }}
                >
                  {roles.map((role: IdNamePair) => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={styles.select_edition}>
                <FormControlLabel
                  label={tKey('Permitir edición')}
                  control={
                    <Checkbox
                      color="primary"
                      checked={allowEditSelected}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setAllowEditSelected(event.target.checked)
                      }
                    />
                  }
                />
              </div>
              <Button color="primary" variant="outlined" onClick={handleAddRole}>
                {tKey('Añadir')}
              </Button>
            </div>
          </FormContainer>

          <div className={styles.table_role}>
            <TableWithEdit key="roles" customContainer tableData={tableData}></TableWithEdit>
          </div>

          <div className={styles.btn_create}>
            {followUpData && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  history.push({
                    pathname: `/proyecto-interno/plantilla/asistente-formularios/${followUpData.internal_project_template_id}/${followUpData.section}`,
                    state: {
                      formId: followUpData.id,
                    },
                  });
                }}
              >
                {tKey('Editar plantilla')}
              </Button>
            )}
            <Button type="submit" color="primary" variant="outlined" onClick={handleSubmit(onHandleSubmit)}>
              {followUpData ? tKey('Guardar') : tKey('Crear')}
            </Button>
            <Button color="primary" variant="outlined" onClick={handleClose}>
              {tKey('Cancelar')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  createInternalProjectTemplateFollowUp: (data: FollowUpData): void =>
    dispatch(createInternalProjectTemplateFollowUp(data)),
  setFollowUpRoleData: (followUpId: number, role: RoleWithPermission): void =>
    dispatch(setInternalProjectTemplateFollowUpRoleData(followUpId, role)),
  removeFollowUpRoleData: (formId: number, roleId: number): void =>
    dispatch(removeInternalProjectTemplateFollowUpRoleData(formId, roleId)),
  saveInternalProjectTemplateFollowUpFormRoles: (
    internalProjectTemplateId: number,
    followUpId: number,
    roles: RoleWithPermission[],
  ): void => dispatch(updateInternalProjectTemplateFollowUpRoles(internalProjectTemplateId, followUpId, roles)),
});

export default connect(null, mapDispatchToProps)(InternalProjectTemplateFollowUpsEdition);
