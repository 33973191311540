import { ButtonProps } from '@material-ui/core';
import { FncButtonProps, ButtonColor } from '../components/FncButton/FncButton';

const parseButtonStyle = (buttonProps: ButtonProps): ButtonColor | undefined => {
  if (buttonProps.color === 'secondary') {
    return buttonProps.color;
  }
  return undefined;
};

const parseButtonProps = (buttonProps: ButtonProps[]): FncButtonProps[] => {
  return buttonProps.map((button: ButtonProps) => {
    const buttonStyle = parseButtonStyle(button);
    return {
      buttonStyle,
      onClick: button.onClick,
      children: button.children,
      hidden: button.hidden,
      disabled: button.disabled,
    } as FncButtonProps;
  });
};

export default parseButtonProps;
