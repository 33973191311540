import { MaterialTableProps } from 'material-table';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getUsersByType } from '../../api/user';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import TableLayout from '../../components/Layout/TableLayout';
import MessageInfo from '../../components/MessageInfo/MessageInfo';
import TableWithEdit from '../../components/Tables/TableWithEdit';
import { exportToExcel } from '../../helpers/exportToXslx';
import { tKey } from '../../helpers/translate';
import { AuthUserData } from '../../redux/auth/definitions';
import { selectIsNousCims, selectUserInfo } from '../../redux/auth/selectors';
import { ResponseData } from '../../redux/common/definitions';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import { disableUser, setUserCreated } from '../../redux/user/actions';
import { RoleGroups, UserItemList, UserType } from '../../redux/user/definitions';
import { selectResponseData, selectUserCreated } from '../../redux/user/selectors';
import UserRolesModal from './UserRoles/UserRolesModal';
import styles from './users.module.scss';
import RoleCode from '../../types/Roles/roleCode';
import UserSubscriptionTypeTableColumnRender from './UserSubscriptionTypeTableColumnRender';
import SelectCheckboxController, {
  ListSelectCheckbox,
} from '../../components/Select/SelectCheckboxController/SelectCheckboxController';

interface Props extends RouteComponentProps<{ type?: UserType }> {
  responseData: ResponseData | null;
  canIbeResponsible: boolean;
  canISeeStaff: boolean;
  canISeeStudent: boolean;
  canISeeEntities: boolean;
  canISeeExternal: boolean;
  canDisableUsers: boolean;
  canISeeAcademy: boolean;
  canConfigUserRoles: boolean;
  userInfo: AuthUserData;
  disableUser: (id: number) => void;
  setUserCreated: (userCreated: boolean) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number) => void;
  isNousCims: boolean;
}

const getDefaultGroupType = (type: UserType | 'default') =>
  ({
    staff: RoleGroups.NOUSCIMS,
    student: 'entity',
    entity: RoleGroups.ENTITAT,
    ext: RoleGroups.EXTERNOS,
    academy: RoleGroups.CENTRO_EDUCATIVO,
    default: '',
    mailing: 'mailing',
  }[type]);

const Users: React.FC<Props> = ({
  canIbeResponsible,
  canISeeStaff,
  canISeeStudent,
  canISeeEntities,
  canISeeExternal,
  canConfigUserRoles,
  userInfo,
  canISeeAcademy,
  match,
  disableUser,
  showSnackbar,
  setUserCreated,
  isNousCims,
}) => {
  const {
    params: { type },
  } = match;
  const roleCode = new RoleCode(userInfo.role_code);
  const groupType = roleCode.isResponsableEntidad() ? 'entity' : getDefaultGroupType(type || 'staff');
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const { t } = useTranslation();
  const [userRolesConfig, setUserRolesConfig] = useState({ view: false, user_id: 0 });
  const getDefaultType = (): UserType | undefined => {
    if (type) return type;
    if (canISeeStaff) return 'staff';
    if (canISeeStudent) return 'student';
    if (canISeeEntities) return 'entity';
    if (canISeeExternal) return 'ext';
    if (canISeeAcademy) return 'academy';
    if (isNousCims) return 'mailing';
  };

  const defaultType = getDefaultType();

  const [table, setTable] = useState<MaterialTableProps<UserItemList>>({
    title: defaultType ? tKey(t(`user.types.${defaultType}`)) : 'Usuarios',
    columns: [],
    options: {
      tableLayout: 'auto',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Usuarios`);
      },
    },
    data: [],
  });

  const optionsSubmenu: SubmenuItem[] = [
    {
      title: tKey(t('user.types.staff')),
      route: '/usuarios/staff',
      type: 'route',
      selected: defaultType === 'staff',
      hidden: !canISeeStaff,
    },
    {
      title: tKey(t('user.types.student')),
      route: '/usuarios/student',
      type: 'route',
      selected: defaultType === 'student',
      hidden: !canISeeStudent,
    },
    {
      title: tKey(t('user.types.entity')),
      route: '/usuarios/entity',
      type: 'route',
      selected: defaultType === 'entity',
      hidden: !canISeeEntities,
    },
    {
      title: tKey(t('user.types.ext')),
      route: '/usuarios/ext',
      type: 'route',
      selected: defaultType === 'ext',
      hidden: !canISeeExternal,
    },
    {
      title: tKey('user.types.academy'),
      route: '/usuarios/academy',
      type: 'route',
      selected: defaultType === 'academy',
      hidden: !canISeeAcademy,
    },
    {
      title: tKey('user.types.mailing'),
      route: '/usuarios/mailing',
      type: 'route',
      selected: defaultType === 'mailing',
      hidden: !isNousCims,
    },
  ];

  const subscriptionTypes = [
    { id: 1, code: 'true', name: 'Sí', color: 'green' },
    { id: 2, code: 'false', name: 'No', color: 'red' },
  ];

  const tableColumns = [
    { title: tKey('Nombre'), field: 'name', width: '200px' },
    ...(defaultType !== 'academy' ? [{ title: tKey('Apellidos'), field: 'surname' }] : []),
    ...(defaultType !== 'academy' ? [{ title: tKey('ip.rol'), field: 'role', width: '250px' }] : []),
    { title: tKey('Correo electrónico'), field: 'email' },
    { title: tKey('Teléfono'), field: 'phone', width: '150px' },
    { title: tKey('Doc. identidad'), field: 'document_number', width: '150px' },
    ...(canIbeResponsible ? [{ title: tKey('Estado'), field: 'state' }] : []),
    ...(defaultType === 'academy' ? [{ title: tKey('Centro Educativo'), field: 'academy_name' }] : []),
    ...(defaultType === 'entity' || defaultType === 'student' ? [{ title: tKey('Entidad'), field: 'entity' }] : []),
  ];

  const mailingListTableColumns: (
    | {
        field: string;
        width: string;
        title: string;
      }
    | { field: string; title: string }
    | {
        field: string;
        editable: string;
        width: string;
        title: string;
        align: string;
        customFilterAndSearch?: (types: number[], rowData: UserItemList) => void;
        filterComponent: ({ columnDef, onFilterChanged }: { columnDef: never; onFilterChanged: never }) => JSX.Element;
        render: (rowData: UserItemList) => JSX.Element;
        cellStyle: () => { textAlign: string };
        headerStyle: { textAlign: string };
      }
  )[] = [
    { title: tKey('Nombre'), field: 'name', width: '200px' },
    { title: tKey('Apellidos'), field: 'surname' },
    { title: tKey('ip.rol'), field: 'role', width: '250px' },
    { title: tKey('Correo electrónico'), field: 'email' },
    {
      title: tKey('Suscrito'),
      field: 'subscribed',
      render: (rowData: UserItemList) => <UserSubscriptionTypeTableColumnRender rowData={rowData} />,
      cellStyle: () => ({
        textAlign: 'center',
      }),
      editable: 'never',
      align: 'center',
      headerStyle: {
        textAlign: 'center',
      },
      width: '150px',
      customFilterAndSearch: (types: number[], rowData) => rowData.subscribed.toString() === types.toString(),
      filterComponent: ({ columnDef, onFilterChanged }) => (
        <SelectCheckboxController
          list={subscriptionTypes as ListSelectCheckbox[]}
          valueSend="code"
          valueLabel="name"
          columnDef={columnDef}
          onFilterChanged={onFilterChanged}
          multiple={false}
        />
      ),
    },
  ];

  useEffect(() => {
    (async function fetchData() {
      if (defaultType !== undefined) {
        setTable(previousData => ({ ...previousData, isLoading: true }));
        try {
          const { data } = await getUsersByType(defaultType);
          if (canIbeResponsible) {
            data.forEach(element => {
              element.state = t(`user.states.${element.state}`);
            });
          }
          // @ts-ignore
          setTable(previousData => ({
            ...previousData,
            data,
            isLoading: false,
            title: defaultType ? tKey(t(`user.types.${defaultType}`)) : tKey('Usuarios'),
            columns: defaultType === 'mailing' ? mailingListTableColumns : tableColumns,
          }));
        } catch (e) {
          setTable(previousData => ({
            ...previousData,
            data: [],
            isLoading: false,
            title: defaultType ? tKey(t(`user.types.${defaultType}`)) : tKey('Usuarios'),
          }));
          showSnackbar(tKey('No se han podido traer los usuarios'), 'error');
        }
      }
    })();
  }, [type, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const goToNewUser = () => {
    setUserCreated(false);
    history.push({ pathname: '/usuario/crear', state: { type: groupType } });
  };

  const onDisableOne = (
    event: React.MouseEvent<Element, globalThis.MouseEvent>,
    rowData: UserItemList | UserItemList[],
  ) => {
    if (!Array.isArray(rowData) && rowData) {
      if (userInfo.user_id === rowData.id) {
        return showSnackbar(tKey('No puedes deshabilitar tu usuario'), 'warning', undefined, 1500);
      }
      disableUser(rowData.id);
      setRefresh(!refresh);
    }
  };

  const showRoles = (
    event: React.MouseEvent<Element, globalThis.MouseEvent>,
    rowData: UserItemList | UserItemList[],
  ) => {
    setUserRolesConfig({ view: true, user_id: (rowData as UserItemList).id });
  };

  const onEditOne = (
    event: React.MouseEvent<Element, globalThis.MouseEvent>,
    rowData: UserItemList | UserItemList[],
  ) => {
    if (!Array.isArray(rowData) && rowData) {
      history.push({
        pathname: `/usuario/editar/${rowData.id}`,
        state: { id: rowData.id, type: groupType },
      });
    }
  };

  const details = (rowData: UserItemList) => {
    if (canIbeResponsible) {
      switch (rowData.state) {
        case t(`user.states.CREATED`):
          history.push({
            pathname: `/usuario/detalles/${rowData.id}`,
            state: { id: rowData.id, type: groupType },
          });
          break;
        case t(`user.states.CREATING_REGULAR`):
          history.push({
            pathname: `/usuario/editar/${rowData.id}`,
            state: { id: rowData.id, type: groupType },
          });
          break;
        case t(`user.states.CREATING_EDUCATOR`):
          history.push({
            pathname: `/usuario/detalles/${rowData.id}`,
            state: { id: rowData.id, type: groupType, needsValidation: true },
          });
          break;
        case t(`user.states.DISABLED`):
          history.push({
            pathname: `/usuario/detalles/${rowData.id}`,
            state: { id: rowData.id, type: groupType, needsValidation: true },
          });
          break;
        default:
          history.push({
            pathname: `/usuario/detalles/${rowData.id}`,
            state: { id: rowData.id, type: groupType },
          });
      }
    } else {
      history.push({
        pathname: `/usuario/detalles/${rowData.id}`,
        state: { id: rowData.id, type: groupType },
      });
    }
  };

  return (
    <>
      <TableLayout leftSubmenu={<Submenu titleMenu={tKey('Usuarios')} optionsMenu={optionsSubmenu} defaultOpen />}>
        <div className={styles.messageInfo_wrapper}>
          {roleCode.isResponsableEntidad() && <MessageInfo>{tKey('mensaje responsable entidad')}</MessageInfo>}
        </div>
        <TableWithEdit
          tableData={table}
          exportButton
          filtering
          onRowClick={(rowData: UserItemList) => details(rowData)}
          key={JSON.stringify(table.data)}
          permission={
            (defaultType === 'staff' || defaultType === 'ext' || defaultType === 'academy') &&
            (canIbeResponsible || canISeeStaff)
          }
          optionsNew={{ newButtonText: tKey('NUEVO USUARIO'), onNew: goToNewUser }}
          onEditOne={
            !canIbeResponsible
              ? (event: React.MouseEvent, rowData: UserItemList | UserItemList[]) => onEditOne(event, rowData)
              : undefined
          }
          {...(canConfigUserRoles
            ? {
                showRoles: (event: React.MouseEvent, rowData: UserItemList | UserItemList[]) =>
                  showRoles(event, rowData),
              }
            : {})}
          {...(canISeeStaff && defaultType === 'staff'
            ? {
                onDisableOne: (event: React.MouseEvent, rowData: UserItemList | UserItemList[]) =>
                  onDisableOne(event, rowData),
              }
            : {})}
        />
      </TableLayout>
      <Suspense fallback={<></>}>
        {userRolesConfig.view && (
          <UserRolesModal
            view={userRolesConfig.view}
            user_id={userRolesConfig.user_id}
            handleClose={() => {
              setUserRolesConfig({ view: false, user_id: 0 });
              setRefresh(!refresh);
            }}
          />
        )}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  responseData: selectResponseData(state),
  canIbeResponsible: selectUserCan(state)('action_responsible'),
  canISeeStaff: selectUserCan(state)('get_staff_users'),
  canISeeStudent: selectUserCan(state)('get_student_users'),
  canISeeEntities: selectUserCan(state)('get_entities_users'),
  canISeeExternal: selectUserCan(state)('get_external_users'),
  canISeeAcademy: selectUserCan(state)('get_academy_users'),
  canConfigUserRoles: selectUserCan(state)('allow_config_user_roles'),
  userInfo: selectUserInfo(state),
  userCreated: selectUserCreated(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  disableUser: (id: number): void => dispatch(disableUser(id)),
  setUserCreated: (userCreated: boolean): void => dispatch(setUserCreated(userCreated)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route?: string, time?: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
