import { DeleteOutline } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteInvoiceFromApi } from '../../../../api/accounting';
import { getEntityInvoices } from '../../../../api/Entity/entity';
import TransitionsModal from '../../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../../constants/permissions_definitions';
import colorPalette from '../../../../helpers/colorPalette';
import { GenericObj } from '../../../../helpers/customHooks/useButtons';
import { formatNumber } from '../../../../helpers/formatNumber';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { createInvoiceData, downloadInvoiceDocument } from '../../../../redux/accounting/actions';
import { InvoiceListItem, ListInvoicesStates } from '../../../../redux/accounting/definitions';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import InvoiceForm from '../../../Accounting/Invoices/InvoiceForm';
import styles from './invoice.module.scss';

interface InvoicesProps {
  entityId: number;
  goBack: React.MutableRefObject<() => void>;
  history: ReturnType<typeof useHistory>;
  setButtonProperty: (payload: GenericObj) => void;
  downloadInvoiceDocument: (file_id: number, filename: string) => void;
  createInvoiceData: (entity_id: number) => void;
  userCan: (permission: permissionType) => boolean | undefined;
}
const Invoices: React.FC<InvoicesProps> = ({
  entityId,
  setButtonProperty,
  userCan,
  goBack,
  history,
  downloadInvoiceDocument,
  createInvoiceData,
}) => {
  const modalObj = {
    view: false,
    title: '',
    children: <Fragment />,
  };
  const [invoiceList, setInvoiceList] = useState<InvoiceListItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [createdModal, setCreatedModal] = React.useState(modalObj);
  const [editInvoice, setEditInvoice] = useState<{
    show: boolean;
    invoice_id: number;
  }>({ show: false, invoice_id: 0 });
  const stateFilters = {} as any;

  const canSend = userCan('allow_send_invoice');
  const canAccept = userCan('allow_accept_invoice');

  if (!!Object.entries(ListInvoicesStates).length) {
    Object.values(ListInvoicesStates).forEach(element => (stateFilters[element] = element));
  }

  const fetchInvoces = async () => {
    setLoading(true);
    setInvoiceList(await getEntityInvoices(entityId));
    setLoading(false);
  };

  const handleCreateInvoice = () => {
    createInvoiceData(entityId);
    setEditInvoice({ show: true, invoice_id: 0 });
  };
  const onGoBack = () => {
    setEditInvoice({ show: false, invoice_id: 0 });
    fetchInvoces();
  };

  useEffect(() => {
    goBack.current = editInvoice.show
      ? onGoBack
      : () => {
          history.go(-1);
        };

    return () => {
      goBack.current = () => history.go(-1);
    };
  }, [editInvoice]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchInvoces();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setButtonProperty({ buttonOnCreate: { onClick: handleCreateInvoice, hidden: !(canAccept || canSend) } });
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnEditOne = (rowData: InvoiceListItem) => {
    setEditInvoice({ show: true, invoice_id: rowData.id });
  };

  const handleDeleteFunc = (rowData: any) => {
    deleteInvoiceFromApi(rowData.id).then(() => {
      fetchInvoces();
    });
  };

  const actionIcons = {
    Delete: () => <DeleteOutline style={{ color: colorPalette.secondary80 }} />,
  };

  const tableData: MaterialTableProps<InvoiceListItem> = {
    title: tKey('FACTURAS'),
    columns: [
      {
        field: 'reference_number',
        title: tKey('Factura'),
        //@ts-ignore
        width: '100px',
      },
      {
        field: 'description',
        title: tKey('Descripción'),
        //@ts-ignore
        width: '100px',
      },
      {
        field: 'invoice_date',
        title: tFormKey('Fecha'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) => <div style={{ textAlign: 'center' }}>{rowData.invoice_date}</div>,
        //@ts-ignore
        width: '60px',
      },
      {
        field: 'due_date',
        title: tFormKey('Vencimiento'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) => <div style={{ textAlign: 'center' }}>{rowData.due_date}</div>,
        //@ts-ignore
        width: '60px',
      },
      {
        field: 'total_amount',
        title: tKey('Total'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) => (
          <div style={{ textAlign: 'center' }}>{formatNumber({ value: rowData.total_amount }) + ' €'}</div>
        ),
        //@ts-ignore
        width: '60px',
      },
      {
        field: 'center_cost',
        title: tKey('Centro de coste'),
        //@ts-ignore
        width: '90px',
      },
      {
        field: 'state',
        title: tKey('Estado'),
        //@ts-ignore
        width: '60px',

        lookup: stateFilters,
      },
      {
        field: 'file_id',
        title: tFormKey('Documento'),
        disableClick: true,
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) =>
          rowData.file_id ? (
            <button
              className={styles.downloadButton}
              onClick={e => {
                e.preventDefault();
                downloadInvoiceDocument(rowData.file_id || 0, rowData.filename || '');
              }}
            >
              <DescriptionIcon />
            </button>
          ) : (
            undefined
          ),
        //@ts-ignore
        width: '80px',
      },
    ],
    data: invoiceList,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
    actions: [
      (rowData: InvoiceListItem) => ({
        icon: actionIcons.Delete,
        onClick: () => handleDeleteFunc(rowData),
        hidden: canSend
          ? rowData.state !== 'Pendiente' && rowData.state !== 'En creación'
          : undefined || canAccept
          ? rowData.state !== 'Pendiente'
          : undefined,
      }),
    ],
  };

  tableData.isLoading = loading;

  return (
    <>
      {editInvoice.show ? (
        <InvoiceForm invoice_id={editInvoice.invoice_id} setButtonProperty={setButtonProperty} hidden />
      ) : (
        <TableWithEdit
          tableData={{ ...tableData }}
          key={JSON.stringify(tableData.data)}
          onRowClick={handleOnEditOne}
          permission={true}
          filtering
        />
      )}
      <TransitionsModal
        view={createdModal.view}
        handleClose={() => setCreatedModal(modalObj)}
        title={createdModal.title}
      >
        {createdModal.children}
      </TransitionsModal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  downloadInvoiceDocument: (file_id: number, filename: string) => dispatch(downloadInvoiceDocument(file_id, filename)),
  createInvoiceData: (entity_id: number) => dispatch(createInvoiceData(entity_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
