export enum SearchConsts {
  SEARCH_GET_DATA = 'SEARCH:GET:DATA',
  SEARCH_GET_DATA_OK = 'SEARCH:GET:DATA:OK',
  SEARCH_GET_DATA_KO = 'SEARCH:GET:DATA:KO',

  SEARCH_GET_RELATIONAL_DATA = 'SEARCH:GET:RELATIONAL:DATA',
  SEARCH_GET_RELATIONAL_DATA_OK = 'SEARCH:GET:RELATIONAL:DATA:OK',
  SEARCH_GET_RELATIONAL_DATA_KO = 'SEARCH:GET:RELATIONAL:DATA:KO',

  SEARCH_GET_DETAIL = 'SEARCH:GET:DETAIL',
  SEARCH_GET_DETAIL_OK = 'SEARCH:GET:DETAIL:OK',
  SEARCH_GET_DETAIL_KO = 'SEARCH:GET:DETAIL:KO',
}
