import { apiBaseUrl, apiCall } from '../../api';
import { Participant } from '../../../redux/internal-projects/participants/Participant';

const baseURL = apiBaseUrl;

export const apiUpdateParticipant = async (participant: Participant): Promise<void> => {
  const init = {
    method: 'put',
    auth: true,
    body: JSON.stringify(participant),
  };

  return (await apiCall)<void>(
    `${baseURL}/internal_project/${participant.internal_project_id}/participants/${participant.id}`,
    init,
  );
};
