import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { tKey, tProjectKey } from '../../../helpers/translate';
import { ResourceImageData } from '../../../redux/common/definitions';
import ImageGallery from '../../ImageGallery/ImageGallery';
import Loading from '../../Loading/Loading';
import ImageEditionModal from '../ImageEditionModal/ImageEditionModal';
import TransitionModal from '../TransitionModal';
import styles from './imageGalleryModal.module.scss';

interface ImageGalleryModalProps {
  open: boolean;
  loading: boolean;
  title: string;
  images: ResourceImageData[];
  canAddImage: boolean;
  handleAddImage: (name: string, description: string, image: string) => void;
  handleEditImage: (id: number, name: string, description: string, newImage: string) => void;
  handleDeleteImage: (id: number) => void;
  handleClose: () => void;
}

const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({
  open,
  loading,
  title,
  images,
  canAddImage,
  handleAddImage,
  handleEditImage,
  handleDeleteImage,
  handleClose,
}) => {
  const [showAddImageModal, setShowAddImageModal] = React.useState<boolean>(false);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <IconButton aria-label="delete" size="small" onClick={() => handleClose()}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        {loading ? (
          <Loading big />
        ) : (
          <>
            <div className={styles.bodyContainer}>
              <ImageGallery images={images} editImage={handleEditImage} deleteImage={handleDeleteImage} />
            </div>
            <div className={styles.btn_create}>
              {canAddImage && (
                <Button color="primary" variant="outlined" onClick={() => setShowAddImageModal(true)}>
                  {tKey('Nueva imagen')}
                </Button>
              )}
              <Button color="primary" variant="outlined" onClick={() => handleClose()}>
                {tKey('Ok')}
              </Button>
            </div>
          </>
        )}
        <TransitionModal
          view={showAddImageModal}
          handleClose={() => setShowAddImageModal(false)}
          title={tProjectKey('Añadir imagen a la galería')}
        >
          <ImageEditionModal
            imageSelected={null}
            addImage={(name: string, description: string, image: string) => {
              handleAddImage(name, description, image);
              setShowAddImageModal(false);
            }}
          />
        </TransitionModal>
      </DialogContent>
    </Dialog>
  );
};

export default ImageGalleryModal;
