export enum ServiceDataConsts {
  GET_SERVICES = 'GET:SERVICES',
  GET_SERVICES_OK = 'GET:SERVICES:OK',
  GET_SERVICES_KO = 'GET:SERVICES:KO',

  GET_USER_SERVICES = 'GET:USER:SERVICES',
  GET_USER_SERVICES_OK = 'GET:USER:SERVICES:OK',
  GET_USER_SERVICES_KO = 'GET:USER:SERVICES:KO',

  DELETE_SERVICE = 'DELETE:SERVICE',
  DELETE_SERVICE_OK = 'DELETE:SERVICE:OK',
  DELETE_SERVICE_KO = 'DELETE:SERVICE:KO',

  GET_SERVICE_DATA = 'GET:SERVICE:DATA',
  GET_SERVICE_DATA_OK = 'GET:SERVICE:DATA:OK',
  GET_SERVICE_DATA_KO = 'GET:SERVICE:DATA:KO',

  UPDATE_SERVICE = 'UPDATE:SERVICE',
  UPDATE_SERVICE_OK = 'UPDATE:SERVICE:OK',
  UPDATE_SERVICE_KO = 'UPDATE:SERVICE:KO',

  ADD_USER_TO_SERVICE = 'ADD:USER:TO:SERVICE',
  ADD_USER_TO_SERVICE_OK = 'ADD:USER:TO:SERVICE:OK',
  ADD_USER_TO_SERVICE_KO = 'ADD:USER:TO:SERVICE:KO',

  SERVICE_CREATION_DATA = 'SERVICE:CREATION:DATA',

  SERVICE_VALIDATION_ERRORS = 'SERVICE:VALIDATION:ERRORS',

  GET_SERVICES_REQUESTED = 'GET:SERVICES:REQUESTED',
  GET_SERVICES_REQUESTED_OK = 'GET:SERVICES:REQUESTED:OK',
  GET_SERVICES_REQUESTED_KO = 'GET:SERVICES:REQUESTED:KO',

  SET_SERVICES_REQUESTED = 'SET:SERVICES:REQUESTED',
  SET_SERVICES_ASSISTANCE = 'SET:EVENT:ASSISTANCE',

  UPDATE_SERVICE_ASSISTANCE = 'UPDATE:SERVICE:ASSISTANCE',
  UPDATE_SERVICE_ASSISTANCE_OK = 'UPDATE:SERVICE:ASSISTANCE:OK',
  UPDATE_SERVICE_ASSISTANCE_KO = 'UPDATE:SERVICE:ASSISTANCE:KO',

  REMOVE_SERVICE_REQUESTED = 'REMOVE:SERVICE:REQUESTED',
  REMOVE_SERVICE_REQUESTED_OK = 'REMOVE:SERVICE:REQUESTED:OK',
  REMOVE_SERVICE_REQUESTED_KO = 'REMOVE:SERVICE:REQUESTED:KO',

  UPLOAD_DOCUMENT_SERVICE = 'UPLOAD:DOCUMENT:SERVICE',
  UPLOAD_DOCUMENT_SERVICE_OK = 'UPLOAD:DOCUMENT:SERVICE_OK',
  UPLOAD_DOCUMENT_SERVICE_KO = 'UPLOAD:DOCUMENT:SERVICE:KO',

  DOWNLOAD_DOCUMENT_SERVICE = 'DOWNLOAD:DOCUMENT:SERVICE',
  DOWNLOAD_DOCUMENT_SERVICE_OK = 'DOWNLOAD:DOCUMENT:SERVICE:OK',
  DOWNLOAD_DOCUMENT_SERVICE_KO = 'DOWNLOAD:DOCUMENT:SERVICE:KO',

  GET_DOCUMENT_SERVICE = 'GET:DOCUMENT:SERVICE',
  GET_DOCUMENT_SERVICE_OK = 'GET:DOCUMENT:SERVICE:OK',
  GET_DOCUMENT_SERVICE_KO = 'GET:DOCUMENT:SERVICE:KO',

  REMOVE_DOCUMENT_SERVICE = 'REMOVE:DOCUMENT:SERVICE',
  REMOVE_DOCUMENT_SERVICE_OK = 'REMOVE:DOCUMENT:SERVICE:OK',
  REMOVE_DOCUMENT_SERVICE_KO = 'REMOVE:DOCUMENT:SERVICE:KO',
}
