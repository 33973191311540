import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ResetPassword } from '../../api/resetPassword';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import LayoutLogin from '../../components/Layout/LayoutLogin';
import { tFormKey, tKey } from '../../helpers/translate';
import { NewPasswordSchema } from '../../validations/formSchema';
import styles from './newPassword.module.scss';
import webStyle from '../../helpers/webStyle';
import Language from '../Language/Language';

export interface NewPasswordFields {
  password: string;
  password2: string;
}

interface RouterProps {
  token: string;
}

type NewPasswordProps = RouteComponentProps<RouterProps>;

const NewPassword: React.FC<NewPasswordProps> = ({ match }: RouteComponentProps<RouterProps>) => {
  const { handleSubmit, errors, control, setError, clearError } = useForm<NewPasswordFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: NewPasswordSchema,
  });

  const [tokenRes, setToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorDump, setErrorDump] = useState<string | undefined>(undefined);
  const [newPassStatus, setNewPassStatus] = useState<boolean>(false);

  useEffect(() => {
    const { params } = match;
    const token = params.token;
    setToken(token);
  }, [match]);

  const onSubmit = (data: NewPasswordFields) => {
    clearError();
    setLoading(true);
    ResetPassword(tokenRes, data)
      .then(res => {
        switch (res.type) {
          case 'ok':
            setLoading(false);
            setNewPassStatus(true);
            return;
          case 'validation-error':
            const err = res.value;
            setLoading(false);
            if (err.fields && err.fields.length) {
              setError(err.fields[0].name, err.fields[0].type, err.fields[0].message);
            } else {
              setErrorDump('Error de validación');
            }
            return;
        }
      })
      .catch(err => {
        setLoading(false);
        setErrorDump(err.toString());
      });
  };

  let resultView;

  if (loading) {
    resultView = (
      <div className={styles.spinner}>
        <CircularProgress color="primary" />
      </div>
    );
  } else if (errorDump) {
    resultView = <div>{errorDump}</div>;
  } else if (newPassStatus) {
    resultView = (
      <>
        <p>{tKey('Contraseña cambiada correctamente')}</p>
        <Link to="/">{tKey('Iniciar sesión')}</Link>
      </>
    );
  }

  return (
    <LayoutLogin subtitle={tKey('Nueva contraseña')}>
      <React.Fragment>
        {!newPassStatus && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInputController
              control={control}
              schema={NewPasswordSchema}
              errors={errors}
              name="password"
              label={tFormKey('Contraseña')}
              type="password"
              fullWidth
            />
            <TextInputController
              control={control}
              errors={errors}
              schema={NewPasswordSchema}
              name="password2"
              label={tFormKey('Confirmar contraseña')}
              type="password"
              fullWidth
            />
            <Button variant="contained" color="primary" type="submit" disableElevation>
              {tKey('Guardar')}
            </Button>
            <div className={styles.languageContainer}>
              <Language />
            </div>
          </form>
        )}
        <div className={styles[`change_password_ok_${webStyle}`]}>{resultView}</div>
      </React.Fragment>
    </LayoutLogin>
  );
};

export default NewPassword;
