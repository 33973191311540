import { ButtonProps } from '@material-ui/core';
import { AvTimer, DateRange, Edit } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import { MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getInternalProjectFollowUpAlertFromApi } from '../../../../../api/internalProject';
import TableWithEdit from '../../../../../components/Tables/TableWithEdit';
import Title from '../../../../../components/Title/Title';
import { exportToExcel } from '../../../../../helpers/exportToXslx';
import { tKey } from '../../../../../helpers/translate';
import { selectIsNousCims } from '../../../../../redux/auth/selectors';
import { applyConfigTemplate, updateFollowUpDate } from '../../../../../redux/internal-projects/actions';
import {
  Follows,
  InternalProject,
  InternalProjectFollowUpAlert,
  InternalProjectSections,
} from '../../../../../redux/internal-projects/definitions';
import { formatFollows } from '../../../../../redux/internal-projects/selectors';
import { AppState } from '../../../../../redux/root-reducer';
import InternalProjectLayoutForm from '../../InternalProjectLayoutForm';
import InformationMinimal from '../Information/InformationMinimal';
import BaseCampForm from './FollowsForms/BaseCampForm';
import FollowSectionForm from './FollowsForms/FollowSectionForm';
import StudentEvaluation from './FollowsForms/StudentEvaluation';
import SeguimientoInicialKoaForm from './FollowsForms/SeguimientoInicialKoa/SeguimientoInicialKoaForm';
import { InternalProjectTypeEnum } from '../../../../../redux/subareaInternalProjectType/subareaInternalProjectType.d';
import SeguimientoKoaForm from './FollowsForms/SeguimientoKoa/SeguimientoKoaForm';

const ModalDatePicker = React.lazy(() => import('../../../../../components/Modal/ModalDatePicker/ModalDatePicker'));
const AlertModal = React.lazy(() => import('./Modals/FollowAlertModal'));

interface FollowsFormProps {
  id: number;
  disabled?: boolean;
  follows: Follows[];
  isNousCims: boolean;
  setterData: any;
  setData: any;
  internalProjectData: InternalProject;
  handleGoBack?: any;
  buttonImageGallery: ButtonProps;
  handleChangeSection: (componentId: string) => void;
  updateFollowUpDate: (internalProjectFollowId: number, follow_date: string) => void;
  setHandleGoBack: React.Dispatch<React.SetStateAction<() => void>>;
  applyConfigTemplate: (internalProjectId: number) => void;
}

const actionIcons = (disabled: boolean) => ({
  download: () => <DateRange style={{ color: `rgba(0, 0, 0, ${disabled ? 0.2 : 0.54})` }} />,
  edit: () => <Edit style={{ color: `rgba(0, 0, 0, ${disabled ? 0.2 : 0.54})` }} />,
  alert: () => <AvTimer style={{ color: `rgba(0, 0, 0, ${disabled ? 0.2 : 0.54})` }} />,
});

const FollowsForm: React.FC<FollowsFormProps> = ({
  id,
  disabled,
  follows,
  setterData,
  setData,
  isNousCims,
  internalProjectData,
  handleGoBack,
  buttonImageGallery,
  handleChangeSection,
  setHandleGoBack,
  updateFollowUpDate,
  applyConfigTemplate,
}) => {
  const history = useHistory();

  const [calendarModal, setCalendarModal] = useState({ view: false, followId: 0, follow_date: '' as string | null });
  const [showGenericForm, setShowGenericForm] = useState({
    view: false,
    followId: 0,
    section: '' as string,
    follow_date: '' as string | null,
    completed: false as boolean,
    title: null as string | null,
  });
  const [alertModal, setAlertModal] = useState({
    view: false,
    followUp: {} as Follows,
    data: {} as InternalProjectFollowUpAlert,
  });

  const onCloseGenericForm = () => () =>
    setShowGenericForm({ view: false, followId: 0, section: '', follow_date: null, completed: false, title: '' });

  const buttonProps: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: disabled,
      hidden: showGenericForm.section === 'pi_seguimiento_final_joven' && internalProjectData.has_enrollments,
    },
    {
      children: tKey('Enviar'),
      startIcon: <SendIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: disabled,
      hidden: true,
    },
    {
      children: tKey('Aplicar plantilla'),
      startIcon: <SendIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: false,
      onClick: async () => {
        applyConfigTemplate(internalProjectData.id);
      },
      hidden: false,
    },
  ];

  useEffect(() => {
    if (showGenericForm.view) {
      setHandleGoBack(onCloseGenericForm);
    }
    return () => setHandleGoBack(() => () => history.go(-1));
  }, [showGenericForm]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeDate = (rowData: Follows) => () => {
    if (!Array.isArray(rowData))
      setCalendarModal({ view: true, followId: rowData.id, follow_date: rowData.follow_date });
  };

  const handleOpenFollow = (rowData: Follows | Follows[]) => () => {
    if ('id' in rowData && 'section' in rowData) {
      setShowGenericForm({
        view: true,
        followId: rowData.id,
        section: rowData.section,
        follow_date: rowData.follow_date,
        completed: rowData.completed,
        title: rowData.name,
      });
    }
  };

  const handleProgramAlertFollow = (rowData: Follows) => () => {
    if (!Array.isArray(rowData)) {
      getInternalProjectFollowUpAlertFromApi(rowData.id).then((res: InternalProjectFollowUpAlert) => {
        setAlertModal({ view: true, followUp: rowData, data: res as InternalProjectFollowUpAlert });
      });
    }
  };

  const handleChangeFollowDate = (date: string) => {
    updateFollowUpDate(calendarModal.followId, moment(date).format('DD/MM/YYYY'));
    setCalendarModal({ view: false, followId: 0, follow_date: '' });
  };

  let currentView;

  const showDefaultGenericForm = () => {
    return (
      <FollowSectionForm
        internal_project_id={id}
        section={showGenericForm.section}
        follow_up_id={showGenericForm.followId}
        buttonSave={buttonProps[0]}
        buttonSend={buttonProps[1]}
        disabled={!!disabled}
        setterData={setterData}
        setData={setData}
        follow_date={showGenericForm.follow_date}
        completed={showGenericForm.completed}
      ></FollowSectionForm>
    );
  };

  const showGenericFormPiSeguimientoInicial = (type: string) => {
    if (type === InternalProjectTypeEnum.KOA) {
      return (
        <SeguimientoInicialKoaForm
          internal_project_id={id}
          section={showGenericForm.section}
          follow_up_id={showGenericForm.followId}
          buttonSave={buttonProps[0]}
          buttonSend={buttonProps[1]}
          disabled={!!disabled}
          setterData={setterData}
          setData={setData}
          follow_date={showGenericForm.follow_date}
          completed={showGenericForm.completed}
        />
      );
    }

    if (type === InternalProjectTypeEnum.TALLER_GRUPAL) {
      return (
        <BaseCampForm
          internal_project_id={id}
          section={showGenericForm.section}
          follow_up_id={showGenericForm.followId}
          buttonSave={buttonProps[0]}
          buttonSend={buttonProps[1]}
          disabled={!!disabled}
          setterData={setterData}
          setData={setData}
          follow_date={showGenericForm.follow_date}
          completed={showGenericForm.completed}
        ></BaseCampForm>
      );
    }

    return showDefaultGenericForm();
  };

  const showGenericFormPiSeguimiento = (type: string) => {
    if (type === InternalProjectTypeEnum.KOA) {
      return (
        <SeguimientoKoaForm
          internal_project_id={id}
          section={showGenericForm.section}
          follow_up_id={showGenericForm.followId}
          buttonSave={buttonProps[0]}
          buttonSend={buttonProps[1]}
          disabled={!!disabled}
          setterData={setterData}
          setData={setData}
          follow_date={showGenericForm.follow_date}
          completed={showGenericForm.completed}
        />
      );
    }

    return showDefaultGenericForm();
  };

  const showGenericFormPiSeguimientoFinalJoven = (type: string) => {
    if (type === InternalProjectTypeEnum.TALLER_GRUPAL) {
      return <StudentEvaluation internal_project_id={id} />;
    }

    return showDefaultGenericForm();
  };

  const showGenericFormBySection = (section: string) => {
    if (section === InternalProjectSections.SEGUIMIENTO_INICIAL) {
      return showGenericFormPiSeguimientoInicial(internalProjectData.subarea_internal_project_type);
    }

    if (section === InternalProjectSections.SEGUIMIENTO) {
      return showGenericFormPiSeguimiento(internalProjectData.subarea_internal_project_type);
    }

    if (section === InternalProjectSections.SEGUIMIENTO_FINAL_JOVEN) {
      return showGenericFormPiSeguimientoFinalJoven(internalProjectData.subarea_internal_project_type);
    }

    return showDefaultGenericForm();
  };

  if (showGenericForm.view) {
    const followForm = showGenericFormBySection(showGenericForm.section);

    currentView = (
      <>
        {showGenericForm.title && <Title>{showGenericForm.title}</Title>}
        {followForm}
      </>
    );
  } else {
    const tableData: MaterialTableProps<Follows> = {
      title: tKey('ip.seguimientos'),
      columns: [
        {
          title: tKey('ip.seguimiento'),
          field: 'name',
          //@ts-ignore
        },
        {
          title: tKey('ip.fecha'),
          field: 'follow_date',
          //@ts-ignore
        },
        {
          title: tKey('ip.estado'),
          field: 'completed_label',
        },
      ],
      data: follows,
      actions: [
        (rowData: Follows) => ({
          icon: actionIcons(!!disabled || (!disabled && rowData.completed)).download,
          onClick: handleChangeDate(rowData),
          disabled: !!(disabled || (!disabled && rowData.completed)),
          hidden: !isNousCims,
        }),
        (rowData: Follows) => ({
          icon: actionIcons(!!disabled).edit,
          onClick: handleOpenFollow(rowData),
        }),
        (rowData: Follows) => ({
          icon: actionIcons(!!disabled || (!disabled && rowData.completed)).alert,
          onClick: handleProgramAlertFollow(rowData),
          disabled: !!(disabled || (!disabled && rowData.completed)),
          hidden: !isNousCims,
        }),
      ],
      options: {
        exportButton: true,
        exportCsv: (columns, data) => {
          exportToExcel(columns, data, tKey('ip.seguimientos'));
        },
        selection: false,
        paging: false,
        tableLayout: 'auto',
      },
    };
    currentView = (
      <>
        <Title>{tKey('ip.seguimientos')}</Title>
        <InformationMinimal />
        <TableWithEdit customContainer tableData={{ ...tableData }} filtering permission={!disabled} />
      </>
    );
  }

  return (
    <InternalProjectLayoutForm
      sectionSelected={'seguimientos'}
      actionsButtons={showGenericForm.view ? buttonProps : [buttonImageGallery]}
      handleChangeSection={handleChangeSection}
      handleGoBack={showGenericForm.view ? handleGoBack : () => history.push('/proyectos-internos')}
    >
      <Fragment>
        {currentView}
        <Suspense fallback={<></>}>
          {calendarModal.view ? (
            <ModalDatePicker
              view={calendarModal.view}
              handleClose={() => setCalendarModal({ view: false, followId: 0, follow_date: '' })}
              label={tKey('ip.fecha_seguimiento')}
              title={tKey('ip.planificar_seguimiento')}
              buttonLabelOk={tKey('Guardar')}
              buttonLabelKo={tKey('Cancelar')}
              onApplying={handleChangeFollowDate}
              defaultValue={calendarModal.follow_date}
              required
            />
          ) : alertModal.view ? (
            <AlertModal
              projectName={internalProjectData.name}
              followUp={alertModal.followUp}
              members={internalProjectData.members}
              alertData={alertModal.data}
              handleClose={() =>
                setAlertModal({ view: false, followUp: {} as Follows, data: {} as InternalProjectFollowUpAlert })
              }
            />
          ) : null}
        </Suspense>
      </Fragment>
    </InternalProjectLayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  follows: formatFollows(state),
  isNousCims: selectIsNousCims(state),
  internalProjectData: state.internalProjectReducer.internalProjectData,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateFollowUpDate: (internalProjectFollowId: number, follow_date: string): void =>
    dispatch(updateFollowUpDate(internalProjectFollowId, follow_date)),
  applyConfigTemplate: (internalProjectId: number) => dispatch(applyConfigTemplate(internalProjectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowsForm);
