import { put, takeLatest } from '@redux-saga/core/effects';
import {
  getAccountingMetadata,
  getGenericMetadata,
  getProjectMetadata,
  getResponsbiles,
  getScholarshipMetadata,
  getTeam,
} from '../../api/metadata';
import {
  getAccountingMetadataKo,
  getAccountingMetadataOk,
  getGenericMetadataKo,
  getGenericMetadataOk,
  getProjectMetadataKo,
  getProjectMetadataOk,
  getResponsibleMetadataKo,
  getResponsibleMetadataOk,
  getScholarshipMetadataKo,
  getScholarshipMetadataOk,
  getTeamMetadataKo,
  getTeamMetadataOk,
} from './actions';
import { MetadataConst } from './action_types';
import {
  AccountingMetadataAction,
  GenericMetadataAction,
  ProjectMetadataAction,
  ResponsibleMetadataAction,
  ScholarshipMetadataAction,
  TeamMetadataAction,
} from './definitions';
import errorMessage from '../../helpers/errorMessage';

function* getGenericDataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getGenericMetadata();
    yield put(getGenericMetadataOk(data));
  } catch (e) {
    yield put(getGenericMetadataKo(errorMessage(e)));
  }
}

function* getProjectDataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getProjectMetadata();
    yield put(getProjectMetadataOk(data));
  } catch (e) {
    yield put(getProjectMetadataKo(errorMessage(e)));
  }
}

function* getResponsbilesFromSaga(): Generator<any, void, any> {
  try {
    const { data: responsibles } = yield getResponsbiles();
    yield put(getResponsibleMetadataOk(responsibles));
  } catch (e) {
    yield put(getResponsibleMetadataKo(errorMessage(e)));
  }
}

function* getTeamFromSaga(): Generator<any, void, any> {
  try {
    const { data: team } = yield getTeam();
    yield put(getTeamMetadataOk(team));
  } catch (e) {
    yield put(getTeamMetadataKo(errorMessage(e)));
  }
}

function* getAccountingDataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getAccountingMetadata();
    yield put(getAccountingMetadataOk(data));
  } catch (e) {
    yield put(getAccountingMetadataKo(errorMessage(e)));
  }
}

function* getScholarshipDataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getScholarshipMetadata();
    yield put(getScholarshipMetadataOk(data));
  } catch (e) {
    yield put(getScholarshipMetadataKo(errorMessage(e)));
  }
}

export default [
  takeLatest<GenericMetadataAction>(MetadataConst.GET_GENERIC, getGenericDataFromSaga),
  takeLatest<ProjectMetadataAction>(MetadataConst.GET_PROJECT_METADATA, getProjectDataFromSaga),
  takeLatest<ResponsibleMetadataAction>(MetadataConst.GET_RESPONSIBLES, getResponsbilesFromSaga),
  takeLatest<TeamMetadataAction>(MetadataConst.GET_TEAM, getTeamFromSaga),
  takeLatest<AccountingMetadataAction>(MetadataConst.GET_ACCOUNTING_METADATA, getAccountingDataFromSaga),
  takeLatest<ScholarshipMetadataAction>(MetadataConst.GET_SCHOLARSHIP_METADATA, getScholarshipDataFromSaga),
];
