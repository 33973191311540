export enum AreasConst {
  GET_AREAS = 'GET:AREAS',
  GET_AREAS_OK = 'GET:AREAS:OK',
  GET_AREAS_KO = 'GET:AREAS:KO',
  CANCEL_GET_AREAS = 'CANCEL:GET:AREAS',

  EDIT_AREA = 'EDIT:AREA',
  EDIT_AREA_OK = 'EDIT:AREA:OK',
  EDIT_AREA_KO = 'EDIT:AREA:KO',

  DISABLE_AREA = 'DISABLE:AREA',
  DISABLE_AREA_OK = 'DISABLE:AREA:OK',
  DISABLE_AREA_KO = 'DISABLE:AREA:KO',

  DELETE_SUB_AREA = 'DELETE:SUBAREA',
  DELETE_SUB_AREA_OK = 'DELETE:SUBAREA:OK',
  DELETE_SUB_AREA_KO = 'DELETE:SUBAREA:KO',

  ADD_SUBAREA = 'ADD:SUBAREA',
  ADD_SUBAREA_OK = 'ADD:SUBAREA_OK',
  ADD_SUBAREA_KO = 'ADD:SUBAREA_KO',

  EDIT_SUBAREA = 'EDIT:SUBAREA',
  EDIT_SUBAREA_OK = 'EDIT:SUBAREA:OK',
  EDIT_SUBAREA_KO = 'EDIT:SUBAREA:KO',

  STORE_AREAS = 'STORE:AREAS',
  STORE_AREAS_OK = 'STORE:AREAS:OK',
  STORE_AREAS_KO = 'STORE:AREAS:KO',

  GET_ROLES_AREA = 'GET:ROLES:AREA',
  GET_ROLES_AREA_OK = 'GET:ROLES:AREA:OK',
  GET_ROLES_AREA_KO = 'GET:ROLES:AREA:KO',

  REMOVE_ROLES_AREA = 'REMOVE:ROLES:AREA',
  REMOVE_ROLES_AREA_OK = 'REMOVE:ROLES:AREA:OK',
  REMOVE_ROLES_AREA_KO = 'REMOVE:ROLES:AREA:KO',

  GET_ROLES_LIST = 'GET:ROLES:LIST',
  GET_ROLES_LIST_OK = 'GET:ROLES:LIST:OK',
  GET_ROLES_LIST_KO = 'GET:ROLES:LIST:KO',

  ADD_ROLES_LIST = 'ADD:ROLES:LIST',
  ADD_ROLES_LIST_OK = 'ADD:ROLES:LIST:OK',
  ADD_ROLES_LIST_KO = 'ADD:ROLES:LIST:KO',

  OPEN_AREA_ROLES_MODAL = 'OPEN:AREA:ROLES:MODAL',
  CLOSE_AREA_ROLES_MODAL = 'CLOSE:AREA:ROLES:MODAL',
}
