import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import React from 'react';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';
import { IdCodeNamePair } from '../../../redux/common/definitions';
import { internalProjectsectionField } from '../../../redux/internal-projects/definitions';

const items: IdCodeNamePair[] = [
  { id: 1, code: 'config', name: 'config-campos' },
  { id: 2, code: 'subarea', name: 'asignación-subarea' },
  { id: 3, code: 'type', name: 'asignacion-tipo-proyecto' },
];

interface SubmenuConfigCustomFieldProps {
  submenu?: string;
  selected?: string;
}

const InternalProjectsConfigurationFields: React.FC<SubmenuConfigCustomFieldProps> = ({ submenu, selected }) => {
  const optionMenu: SubmenuItem[] = internalProjectsectionField.map(section => ({
    title: tKey(section.name),
    icon: <FeaturedPlayListIcon />,
    type: 'items',
    items: items.map((item: IdCodeNamePair) => ({
      title: tKey(item.name),
      route: `/configuracion/proyectos-internos/configuracion-campos/${section.code}/${item.code}`,
      type: 'route',
      selected: selected === item.code,
    })),
  }));

  return (
    <Submenu
      titleMenu={tKey('CONFIGURACIÓN DE CAMPOS')}
      optionsMenu={optionMenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
    />
  );
};

export default InternalProjectsConfigurationFields;
