import React from 'react';
import styles from './buttonGroup.module.scss';

interface ButtonProps {
  children: any;
  align?: string;
}

const ButtonGroup: React.FC<ButtonProps> = ({ children, align }) => (
  <div className={align ? styles['container'] : styles['containerLeft']}>
    {React.Children.map(children, (child, i) => {
      if (i < 0 || !child) return;
      return <div className={styles['buttonContainer']}>{child}</div>;
    })}
  </div>
);

export default ButtonGroup;
