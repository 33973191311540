import { InternalProjectId, ParticipantId } from '../Participant';
import { UnSubscribeParticipantConst } from './action_types';
import {
  UnSubscribeParticipantAction,
  UnSubscribeParticipantKoAction,
  UnSubscribeParticipantOkAction,
} from './definition';

export function unSubscribeParticipant(
  internal_project_id: InternalProjectId,
  participantId: ParticipantId,
): UnSubscribeParticipantAction {
  return {
    type: UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT,
    internal_project_id,
    participantId,
  };
}

export function unSubscribeParticipantOk(
  internal_project_id: InternalProjectId,
  participantId: ParticipantId,
  unsubscribed_at: string,
): UnSubscribeParticipantOkAction {
  return {
    type: UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT_OK,
    internal_project_id,
    participantId,
    unsubscribed_at,
  };
}

export function unSubscribeParticipantKo(error: string): UnSubscribeParticipantKoAction {
  return {
    type: UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT_KO,
    error,
  };
}
