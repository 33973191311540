import { ButtonProps, MenuItem } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import Title from '../../../components/Title/Title';
import { tFormKey, tKey } from '../../../helpers/translate';
import {
  deleteDocument,
  deleteDocumentById,
  downloadDocument,
  getInitialResource,
  getMetadata,
  getOneResources,
  newDocument,
  newResource,
  openDocument,
  saveResource,
} from '../../../redux/aboutUs/actions';
import {
  AboutUsDispatch,
  AboutUsMetada,
  AboutUsResource,
  AboutUsTypesMetadata,
} from '../../../redux/aboutUs/definitions';
import { selectDocuments, selectLoading, selectMetadata, selectOneResource } from '../../../redux/aboutUs/selectors';
import { DocumentationItem } from '../../../redux/common/definitions';
import { AppState } from '../../../redux/root-reducer';
import { aboutUsEditionSchema } from '../../../validations/formSchema';

interface AboutUsEditionProps extends RouteComponentProps<{ id: string; code: string }> {
  getOneResource: (id: number) => void;
  uploadNewDocument: (file: File, name: string) => void;
  downloadDocument: (resource_id: number, name: string, file_id: number) => void;
  removeDocument: (resource_id: number, file_id: number) => void;
  openDocument: (resource_id: number, name: string, file_id: number) => void;
  saveResourceData: (resource: AboutUsResource, redirect: string) => void;
  newResourceData: (resource: AboutUsResource, redirect: string) => void;
  getInitialOneResource: () => void;
  deleteDocumentById: (file_id: number, documentation: DocumentationItem[]) => void;
  createMode?: boolean | undefined;
  resource: AboutUsResource;
  metadata: AboutUsMetada;
  loading: boolean;
  documents: DocumentationItem[];
}

function isNotEmpty(obj: Record<string, any>) {
  if (obj) {
    return Object.keys(obj).length > 0;
  }
}

const setSelect = (arr: AboutUsTypesMetadata[], code: string): number => {
  const element = arr.find(e => e.code === code) as AboutUsTypesMetadata;
  return element.id;
};

const AboutUsEdition: React.FC<AboutUsEditionProps> = ({
  getOneResource,
  downloadDocument,
  removeDocument,
  openDocument,
  newResourceData,
  uploadNewDocument,
  saveResourceData,
  getInitialOneResource,
  deleteDocumentById,
  loading,
  createMode,
  metadata,
  resource,
  documents,
  match,
}) => {
  const actions = ['checked', 'download', 'view', 'remove'];
  const { aboutUsTypes } = metadata;
  const { description, code } = resource;
  const {
    params: { id, code: paramsCode },
  } = match;
  const [documentation, setDocumentation] = useState<DocumentationItem[]>([]);
  const [uploadedRightNow, setUploadedRightNow] = useState<boolean>(false);
  const { setValue, errors, getValues, control, register } = useForm<AboutUsResource>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: aboutUsEditionSchema,
  });

  let redirect = match.url.slice(0, match.url.lastIndexOf('/'));
  redirect = redirect.slice(0, redirect.lastIndexOf('/'));

  let title: string;
  id === '0' ? (title = tKey('Creación Sobre Nosotros')) : (title = tKey('Edición Sobre Nosotros'));

  useEffect(() => {
    createMode ? getInitialOneResource() : getOneResource(parseInt(id) || 0);
  }, [createMode, getInitialOneResource, getOneResource, id]);

  useEffect(() => {
    setDocumentation(documents);
  }, [documents, setDocumentation]);

  useEffect(() => {
    setValue('description', resource.description);
    paramsCode && aboutUsTypes && setValue('about_us_type_id', setSelect(aboutUsTypes, paramsCode));
  }, [resource.description, aboutUsTypes, paramsCode, setValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const addDoc = (document: File, description: string) => {
    setUploadedRightNow(true);
    uploadNewDocument(document, description);
  };

  const onSubmit = () => {
    const values = getValues();
    resource.description = values.description;
    resource.code = values.code;
    if (values.monterri === true) resource.code = 'R_MTR';
    if (values.about_us_type_id) resource.about_us_type_id = values.about_us_type_id;
    createMode ? newResourceData(resource, redirect) : saveResourceData(resource, redirect);
  };

  const buttonsActions: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      fullWidth: true,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      onClick: e => {
        e.preventDefault();
        onSubmit();
      },
      startIcon: <SendIcon />,
    },
  ];

  const getMonterri = (code: string | null): number => {
    if (code && code === 'R_MTR') return 1;
    return 0;
  };

  const removeDocumentHelper = (file_id: number, resource_id: number): void => {
    if (uploadedRightNow) {
      deleteDocumentById(file_id, documents);
    } else {
      removeDocument(file_id, resource_id);
    }
  };

  return (
    <LayoutForm rightSubmenu={<ActionsMenu actionsButtons={buttonsActions} />}>
      {loading ? (
        <Loading big />
      ) : isNotEmpty(aboutUsTypes) ? (
        <Fragment>
          <Title>{title}</Title>
          <FormContainer title="">
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={description}
              schema={aboutUsEditionSchema}
              label={tFormKey('Nombre del recurso')}
              name="description"
              fullWidth
              rows="4"
              rowsMax="10"
              multiline
            />
          </FormContainer>

          <SelectController
            name="about_us_type_id"
            defaultValue={code}
            errors={errors}
            schema={aboutUsEditionSchema}
            control={control}
            label={tFormKey('Tipo de recurso')}
            disabled
            size="30"
          >
            {aboutUsTypes.map((e: AboutUsTypesMetadata) => (
              <MenuItem key={e.id} value={e.id}>
                {e.name}
              </MenuItem>
            ))}
          </SelectController>

          {isNotEmpty(resource) ? (
            <CheckboxCompo
              name="monterri"
              questionText={tFormKey('¿El convenio debe ser validado por Monterri?')}
              register={register}
              disabled={false}
              defaultValue={getMonterri(resource.code)}
              form
            />
          ) : null}

          <FncAddDocuments
            documents={documentation}
            title={tFormKey('Documentos')}
            addRow
            actions={actions}
            addDoc={addDoc}
            download={downloadDocument}
            genericId={resource.id}
            remove={removeDocumentHelper}
            getFile={openDocument}
          />
        </Fragment>
      ) : (
        <Fragment />
      )}
    </LayoutForm>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectLoading(state),
  metadata: selectMetadata(state),
  resource: selectOneResource(state),
  documents: selectDocuments(state),
});

const mapDispatchProps = (dispatch: AboutUsDispatch) => ({
  getMetadata: (): void => dispatch(getMetadata()),
  getOneResource: (id: number): void => dispatch(getOneResources(id)),
  getInitialOneResource: (): void => dispatch(getInitialResource()),
  uploadNewDocument: (file: File, name: string): void => dispatch(newDocument(file, name)),
  downloadDocument: (resource_id: number, name: string, file_id: number): void =>
    dispatch(downloadDocument(resource_id, name, file_id)),
  removeDocument: (resource_id: number, file_id: number): void => dispatch(deleteDocument(resource_id, file_id)),
  openDocument: (resource_id: number, name: string, file_id: number): void =>
    dispatch(openDocument(resource_id, name, file_id)),
  saveResourceData: (resource: AboutUsResource, redirect: string) => void dispatch(saveResource(resource, redirect)),
  newResourceData: (resource: AboutUsResource, redirect: string) => void dispatch(newResource(resource, redirect)),
  deleteDocumentById: (file_id: number, documentation: DocumentationItem[]) =>
    void dispatch(deleteDocumentById(file_id, documentation)),
});

export default connect(mapStateProps, mapDispatchProps)(AboutUsEdition);

// eliminar por id
