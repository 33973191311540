import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableLayout from '../../../components/Layout/TableLayout';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { getLastItem } from '../../../helpers/getLastItem';
import { tFormKey, tKey } from '../../../helpers/translate';
import { selectUserRoleCode } from '../../../redux/auth/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { LookUpSubarea } from '../../../redux/project/definitions';
import { selectSubareaList } from '../../../redux/project/selectors';
import { AppState } from '../../../redux/root-reducer';
import { getServices } from '../../../redux/service/actions';
import { CopyService, ServiceData, ServiceDataDispatch } from '../../../redux/service/definitions';
import { selectServicesList } from '../../../redux/service/selectors';
import RoleCode from '../../../types/Roles/roleCode';
import SubmenuEvents from '../../Event/EventTabs/SubmenuEvents';
import styles from './service.module.scss';

interface EventPageProps extends RouteComponentProps {
  loading: boolean;
  servicesList: ServiceData[];
  subareas: LookUpSubarea;
  roleCode: RoleCode;
  userCan: (permission: permissionType) => boolean | undefined;
  getServices: (role?: string) => void;
}

const AddNewServiceModal = React.lazy(() => import('../Modal/CreateService/ModalCreate'));

const Services: React.FC<EventPageProps> = ({
  match,
  loading,
  servicesList,
  subareas,
  roleCode,
  userCan,
  getServices,
}) => {
  const { path } = match;
  const [create, setCreate] = React.useState({
    view: false,
    detail: {} as CopyService,
  });

  const history = useHistory();
  const action_pma = userCan('action_pma');
  const action_dir = userCan('action_dir');

  useEffect(() => {
    getServices(roleCode.value());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tableData: MaterialTableProps<ServiceData> = {
    title: tKey('Lista de Servicios'),
    columns: [
      {
        title: tKey('Fecha inicio'),
        field: 'start_date',
      },
      {
        title: tKey('Fecha fin'),
        field: 'end_date',
      },
      {
        title: tKey('Nombre'),
        field: 'name',
        // @ts-ignore
        width: '250px',
      },

      { title: tFormKey('svc.requested_number'), field: 'requested_number' },
      { title: tFormKey('svc.assistant_number'), field: 'assistant_number' },
      { title: tFormKey('svc.unidentified'), field: 'unidentified' },
      { title: tFormKey('svc.beneficiaries'), field: 'beneficiaries' },

      { title: tKey('Organizador'), field: 'company' },
      {
        title: tKey('Cancelado'),
        field: 'cancelled',
        render: (rowData: ServiceData) => {
          return rowData.cancelled === 1 ? tFormKey('Sí') : tFormKey('No');
        },
      },
      {
        title: tKey('Subárea'),
        field: 'subarea',
        // @ts-ignore
        width: '150px',
      },
      { title: tKey('NPS'), field: 'nps' },
      { title: tKey('Aforo máximo'), field: 'max_capacity' },
    ],
    data: servicesList,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Eventos`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'fixed',
    },
  };

  const goToNew = () => {
    setCreate({ view: true, detail: {} as CopyService });
  };

  const details = (rowData: ServiceData) => {
    const { id } = rowData;
    history.push({
      pathname: `/servicios/detalle/${id}/datos_basicos`,
      state: { id },
    });
  };

  return (
    <TableLayout leftSubmenu={<SubmenuEvents selected={getLastItem(path)} submenu={tKey('Eventos y servicios')} />}>
      <div className={styles.container}>
        <TableWithEdit
          tableData={{ ...tableData, isLoading: loading }}
          permission={action_pma || action_dir}
          exportButton
          filtering
          optionsNew={{
            newButtonText: tKey('Nuevo Servicio'),
            onNew: goToNew,
          }}
          onRowClick={details}
        />
      </div>
      <Suspense fallback={<></>}>
        {create.view ? (
          <AddNewServiceModal
            view={create.view}
            detail={create.detail}
            handleClose={() => setCreate({ view: false, detail: {} as CopyService })}
          />
        ) : (
          <Fragment />
        )}
      </Suspense>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.serviceReducer.loading,
  servicesList: selectServicesList(state),
  userCan: selectUserCan(state),
  subareas: selectSubareaList(state),
  roleCode: selectUserRoleCode(state),
});

const mapDispatchToProps = (dispatch: ServiceDataDispatch) => ({
  getServices: (role?: string): void => dispatch(getServices(role)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
