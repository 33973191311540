import * as yup from 'yup';
import { tErrorKey, tProjectKey } from '../../helpers/translate';
import { CustomFieldDataTypes, CustomFieldElement } from '../../redux/kpis/definitions';
import { FormProjectFields } from '../../redux/project/definitions';

export const addFieldsToSchema = (fields: { elements: (FormProjectFields | CustomFieldElement)[] }) => {
  const objectSchema: { [x: string]: yup.StringSchema<string> | yup.NumberSchema<number> } = {};
  if (!fields || !fields.elements) return objectSchema;
  fields.elements.forEach(element => {
    switch (element.field_data_types) {
      case CustomFieldDataTypes.NUMERICO:
        objectSchema[element.name] = yup.string().matches(/^\d{1,6}(\.\d{0,2})?$/, {
          excludeEmptyString: true,
          message: tProjectKey('Número positivo de hasta 2 decimales'),
        });
        break;
      case CustomFieldDataTypes.RADIO:
      case CustomFieldDataTypes.BLOQUE_DE_TEXTO:
      case CustomFieldDataTypes.TEXTO:
      case CustomFieldDataTypes.POBLACION:
        objectSchema[element.name] = yup.string();
        break;
    }
  });
  return objectSchema;
};

export const addFieldsToSchemaWithRequired = (fields: { elements: (FormProjectFields | CustomFieldElement)[] }) => {
  const requiredText = tErrorKey('Este campo es obligatorio');

  const objectSchema: { [x: string]: yup.StringSchema<string> | yup.NumberSchema<number> } = {};
  if (!fields || !fields.elements) return objectSchema;
  fields.elements.forEach((element: FormProjectFields | CustomFieldElement) => {
    switch (element.field_data_types) {
      case CustomFieldDataTypes.NUMERICO:
        if (element.mandatory) {
          objectSchema[element.name] = yup
            .string()
            .matches(/^\d{1,6}(\.\d{0,2})?$/, {
              excludeEmptyString: true,
              message: tProjectKey('Número positivo de hasta 2 decimales'),
            })
            .required(requiredText);
        } else {
          objectSchema[element.name] = yup.string().matches(/^\d{1,6}(\.\d{0,2})?$/, {
            excludeEmptyString: true,
            message: tProjectKey('Número positivo de hasta 2 decimales'),
          });
        }
        break;
      case CustomFieldDataTypes.RADIO:
      case CustomFieldDataTypes.BLOQUE_DE_TEXTO:
      case CustomFieldDataTypes.TEXTO:
      case CustomFieldDataTypes.POBLACION:
        if (element.mandatory) {
          objectSchema[element.name] = yup
            .string()
            .required(requiredText)
            .typeError(requiredText);
        } else {
          objectSchema[element.name] = yup.string();
        }
        break;
      case CustomFieldDataTypes.LISTA_DE_VALORES:
        if (element.mandatory) {
          objectSchema[element.name] = yup.number().typeError(requiredText);
        }
        break;
    }
  });
  return objectSchema;
};
