import React from 'react';
import { ActionsDocument, DocumentationItem } from '../../redux/common/definitions';
import { tKey } from '../../helpers/translate';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import colorPalette from '../../helpers/colorPalette';
import { Button, Tooltip } from '@material-ui/core';
import AddDocumentActions from './AddDocumentActions';
import MessageInfoRaw from '../MessageInfo/MessageInfoRaw';

interface Props {
  documents: DocumentationItem[];
  actions: ActionsDocument[];
  disabled: boolean;
  addRow: boolean;
  multiDocument: boolean;
  required: boolean;
  requiredMultiDocument: boolean;
  addMoreDocuments: boolean;
  onUpload: (fileFormat: string, file: File, rowData: DocumentationItem) => void;
  onOpenModal: (rowData: DocumentationItem) => void;
  onDownload: (rowData: DocumentationItem) => void;
  onRemoveFile: (rowData: DocumentationItem) => void;
  onGetFile: (rowData: DocumentationItem) => void;
  setShowModal: (open: boolean) => void;
}

const MessageNoDocuments = ({ multiDocument }: { multiDocument: boolean }) => {
  if (multiDocument) {
    return <MessageInfoRaw>{tKey('documentos_requeridos')}</MessageInfoRaw>;
  }
  return <MessageInfoRaw>{tKey('no_hay_documentos')}</MessageInfoRaw>;
};
const FilesListDesktop = (props: Props) => {
  const { documents, required, multiDocument, disabled, setShowModal, addMoreDocuments, addRow } = props;
  return (
    <>
      {addRow && addMoreDocuments && !disabled && (
        <Button
          variant="outlined"
          color="primary"
          component="label"
          disableElevation
          disabled={disabled}
          onClick={() => {
            setShowModal(true);
          }}
        >
          {tKey('Añadir Documento')}
        </Button>
      )}
      <table>
        <thead>
          <tr>
            {required && <th>{tKey('Requerido')}</th>}
            <th>{tKey('Nombre')}</th>
            <th>{tKey('Subido por')}</th>
            <th>{tKey('Fecha de actualización')}</th>
            <th>{tKey('Acciones')}</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((rowData: DocumentationItem, index) => {
            return (
              <tr key={`${rowData.file_id}_${index}`}>
                {required && (
                  <td>
                    {rowData.mandatory && (
                      <FiberManualRecordIcon
                        style={{ color: `${rowData.file_id ? colorPalette.greenBubble : colorPalette.primary80}` }}
                      />
                    )}
                  </td>
                )}
                <td>
                  <Tooltip title={rowData.name}>
                    <span>{rowData.name}</span>
                  </Tooltip>
                </td>
                <td>
                  <Tooltip title={rowData.upload}>
                    <span>{rowData.upload}</span>
                  </Tooltip>
                </td>
                <td>{rowData.update}</td>
                <td>
                  <AddDocumentActions document={rowData} {...props} />
                </td>
              </tr>
            );
          })}
          {!documents.length && (
            <tr>
              <td colSpan={required ? 5 : 4}>
                <MessageNoDocuments multiDocument={multiDocument} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default FilesListDesktop;
