/* eslint-disable @typescript-eslint/no-explicit-any */
import { Edit, Undo } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import TransitionsModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import Title from '../../../components/Title/Title';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { formatNumber } from '../../../helpers/formatNumber';
import { tKey } from '../../../helpers/translate';
import {
  closePaymentModal,
  getOnePendingInvoice,
  getPendingToSendInvoices,
  openPaymentModal,
  removePendingInvoice,
} from '../../../redux/accounting/actions';
import { InvoiceListItem } from '../../../redux/accounting/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import AccountingSubmenu from '../AccountingSubmenu';
import PaymentModificationModal from './PaymentModificationModal';

export interface PendingInvoicesProps {
  loading: boolean;
  paymentViewModal: boolean;
  pendingInvoicesList: InvoiceListItem[];
  getPendingInvoices: () => void;
  openPaymentModal: () => void;
  closePaymentModal: () => void;
  deleteInvoice: (id: number) => void;
  getOneInvoicesDetails: (id: number) => void;
  userCan: (permission: permissionType) => boolean | undefined;
}

const PendingInvoices: React.FC<PendingInvoicesProps> = ({
  loading,
  paymentViewModal,
  pendingInvoicesList,
  userCan,
  deleteInvoice,
  getOneInvoicesDetails,
  getPendingInvoices,
  openPaymentModal,
  closePaymentModal,
}) => {
  const canPay = userCan('allow_send_invoice_pending_to_sage');
  useEffect(() => {
    getPendingInvoices();
  }, [getPendingInvoices]);

  const [paymentModal, setPaymentModal] = React.useState({
    children: <Fragment></Fragment>,
    title: '',
  });

  const handleEditPayment = (data: InvoiceListItem) => {
    getOneInvoicesDetails(data.id);
    setPaymentModal({
      ...paymentModal,
      children: <PaymentModificationModal />,
      title: 'Modificar pago',
    });
    openPaymentModal();
  };

  const actionIcons = {
    GoBack: () => <Undo style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    Edit: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };

  const tableData: MaterialTableProps<InvoiceListItem> = {
    title: '',
    columns: [
      {
        field: 'invoice_number',
        title: tKey('Factura'),
      },
      {
        field: 'due_date',
        title: tKey('Vencimiento'),
      },
      {
        field: 'total_amount',
        title: tKey('Importe'),
        headerStyle: {
          textAlign: 'left',
        },
        cellStyle: {
          textAlign: 'left',
        },
        render: (rowData: InvoiceListItem) => (
          <div style={{ textAlign: 'left' }}>{formatNumber({ value: rowData.total_amount }) + ' €'}</div>
        ),
      },
      {
        field: 'description',
        title: tKey('Comentario'),
        //@ts-ignore
        width: '35%',
      },
      {
        field: 'center_cost',
        title: tKey('Centro de coste'),
      },
    ],
    data: pendingInvoicesList,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, tKey(`Facturas pendientes de envío`));
      },
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    actions: [
      (rowData: InvoiceListItem) => ({
        icon: actionIcons.Edit,
        onClick: () => {
          handleEditPayment(rowData);
        },
        tooltip: tKey('Editar'),
        hidden: !canPay,
      }),
      (rowData: InvoiceListItem) => ({
        icon: actionIcons.GoBack,
        onClick: () => {
          deleteInvoice(rowData.id);
        },
        tooltip: tKey('Retroceder'),
        hidden: !canPay,
      }),
    ],
  };

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu />}
      leftSubmenu={<AccountingSubmenu selected="pending_invoices" submenu={tKey('Facturas servicios')} />}
    >
      <Title>{tKey('Facturas pendientes de envío')}</Title>

      <TableWithEdit
        tableData={{ ...tableData, isLoading: loading }}
        key={JSON.stringify(tableData.data)}
        permission={canPay}
      />

      {loading ? (
        <Loading />
      ) : (
        <TransitionsModal
          view={paymentViewModal}
          handleClose={() => {
            closePaymentModal();
          }}
          title={paymentModal.title}
          bodyModal={<div>{paymentModal.children}</div>}
        />
      )}
    </LayoutForm>
  );
};
const mapStateProps = (state: AppState) => ({
  userCan: selectUserCan(state),
  loading: state.accountingReducer.loading,
  pendingInvoicesList: state.accountingReducer.pendingInvoicesList,
  paymentViewModal: state.accountingReducer.paymentViewModal,
});

const mapDispatchToProps = (dispatch: any) => ({
  getPendingInvoices: (): void => dispatch(getPendingToSendInvoices()),
  getOneInvoicesDetails: (id: number): void => dispatch(getOnePendingInvoice(id)),
  deleteInvoice: (id: number): void => dispatch(removePendingInvoice(id)),
  openPaymentModal: (): void => dispatch(openPaymentModal()),
  closePaymentModal: (): void => dispatch(closePaymentModal()),
});
export default connect(mapStateProps, mapDispatchToProps)(PendingInvoices);
