import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import TableLayout from '../../../components/Layout/TableLayout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { deleteEvent, getEvents, resetConfiguratorData } from '../../../redux/event/actions';
import { CopyEvent, EventData, EventDataDispatch } from '../../../redux/event/definitions';
import { LookUpSubarea } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import styles from './events.module.scss';

import { selectUserRoleCode } from '../../../redux/auth/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { selectLoading, selectSubareaList } from '../../../redux/project/selectors';

import { getLastItem } from '../../../helpers/getLastItem';
import { tFormKey, tKey } from '../../../helpers/translate';
import { selectEventsList } from '../../../redux/event/selectors';
import RoleCode from '../../../types/Roles/roleCode';
import SubmenuEvents from './SubmenuEvents';

interface EventPageProps extends RouteComponentProps {
  loading: boolean;
  eventsList: EventData[];
  subareas: LookUpSubarea;
  roleCode: RoleCode;
  canICopy: boolean;
  userCan: (permission: permissionType) => boolean | undefined;
  getEvents: (role?: string) => void;
  deleteEvent: (id: number) => void;
}

const actionIcons = {
  PostAdd: () => <FileCopyOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  PostAddDis: () => <FileCopyOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
};

const AddNewEventModal = React.lazy(() => import('../Modal/CreateEvent/ModalCreate'));

const Events: React.FC<EventPageProps> = ({
  match,
  loading,
  eventsList,
  subareas,
  roleCode,
  canICopy,
  userCan,
  getEvents,
  deleteEvent,
}) => {
  const { path } = match;
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [create, setCreate] = React.useState({
    view: false,
    copy: false,
    detail: {} as CopyEvent,
  });

  const [arrIds, setArrIds] = React.useState<number[]>([]);
  const history = useHistory();
  const action_pma = userCan('action_pma');
  const action_dir = userCan('action_dir');

  useEffect(() => {
    getEvents(roleCode.value());
    resetConfiguratorData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tableData: MaterialTableProps<EventData> = {
    title: tKey('Lista de Eventos'),
    columns: [
      {
        title: tKey('Fecha inicio'),
        field: 'start_date',
        render: (rowData: EventData) => {
          if (typeof rowData.start_date === 'string') return rowData.start_date.split(' ')[0];
        },
      },
      {
        title: tKey('Título'),
        field: 'title',
        // @ts-ignore
        width: '350px',
      },
      { title: tKey('Provincia'), field: 'province' },
      { title: tFormKey('eve.registered_number'), field: 'registered_number' },
      { title: tFormKey('eve.assistant_number'), field: 'assistant_number' },
      { title: tFormKey('eve.subscribed_number'), field: 'subscribed_number' },
      { title: tFormKey('eve.total_assistants'), field: 'total_assistants' },
      { title: tKey('Tipo de evento'), field: 'type' },
      {
        title: tKey('Subárea'),
        field: 'subarea',
        // @ts-ignore
        width: '200px',
      },
      { title: tKey('Aforo máximo'), field: 'max_capacity' },
      { title: tKey('Año académico'), field: 'academic_year' },
    ],
    data: eventsList,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Eventos`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'fixed',
    },
  };

  if (canICopy) {
    tableData.actions = [
      (rowData: EventData) => ({
        icon: actionIcons.PostAdd,
        tooltip: 'Copiar',
        onClick: () => {
          setCreate({
            view: true,
            copy: true,
            detail: {
              title: rowData.title,
              id: rowData.id,
            },
          });
        },
        hidden: !canICopy,
      }),
    ];
  }

  const goToNew = () => {
    setCreate({ view: true, copy: false, detail: {} as CopyEvent });
  };

  const details = (rowData: EventData) => {
    const { id } = rowData;
    history.push({
      pathname: `/eventos/detalle/${id}/datos_basicos`,
      state: { id },
    });
  };

  return (
    <TableLayout leftSubmenu={<SubmenuEvents selected={getLastItem(path)} submenu={tKey('Eventos y servicios')} />}>
      <div className={styles.container}>
        <TableWithEdit
          tableData={{ ...tableData, isLoading: loading }}
          permission={action_pma || action_dir}
          exportButton
          filtering
          optionsNew={{
            newButtonText: tKey('Nuevo Evento'),
            onNew: goToNew,
          }}
          onRowClick={details}
        />
      </div>
      <TransitionModal
        view={modal.view}
        handleClose={() => {
          setArrIds([]);
          setModal({ view: false, body: [] });
        }}
        bodyModal={tKey('¿Seguro que desea eliminar este evento?')}
        buttonOk="Si"
        buttonKo="No"
        title="Atención!"
        handleYes={() => {
          const [id] = arrIds;
          deleteEvent(id);
          setModal({ view: false, body: [] });
        }}
      />
      <Suspense fallback={<></>}>
        {create.view ? (
          <AddNewEventModal
            view={create.view}
            copy={create.copy}
            detail={create.detail}
            handleClose={() => setCreate({ view: false, copy: false, detail: {} as CopyEvent })}
          />
        ) : (
          <Fragment />
        )}
      </Suspense>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  eventsList: selectEventsList(state),
  userCan: selectUserCan(state),
  subareas: selectSubareaList(state),
  roleCode: selectUserRoleCode(state),
  canICopy: selectUserCan(state)('allow_copy_event'),
});

const mapDispatchToProps = (dispatch: EventDataDispatch) => ({
  getEvents: (role?: string): void => dispatch(getEvents(role)),
  deleteEvent: (id: number): void => dispatch(deleteEvent(id)),
  resetConfiguratorDataF: (): void => dispatch(resetConfiguratorData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
