import { LOPDMail, SendDataLOPD, TranslateData } from '../../redux/translations/definitions';
import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

export interface InfoInputs {
  key: string;
  permision: string;
  role: string;
  allow: boolean;
}
export interface SelectData {
  categories: string[];
}

type Ok = 'Ok';

export const getEnabledScholarhips = async (): Promise<{ data: InfoInputs[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InfoInputs[] }>(`${baseURL}/permissions/scholarship`, init);
};

export const saveEnabledScholarhips = async (permissions: InfoInputs[]): Promise<{ data: Ok }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ permissions }),
  };

  return apiCall<{ data: Ok }>(`${baseURL}/permissions/scholarship`, init);
};

export const getTranslationSelectData = async (): Promise<{ data: SelectData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: SelectData }>(`${baseURL}/translation_keys/metadata`, init);
};

export const sendFilterData = async (data: any): Promise<{ responseData: any }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ responseData: any }>(`${baseURL}/translation_keys`, init);
};

export const sendCustomFieldsFilterData = async (data: any): Promise<{ responseData: any }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ responseData: any }>(`${baseURL}/translation_keys/custom_fields`, init);
};

export const updateTranslationData = async (
  source: 'keys' | 'custom_fields',
  data: TranslateData,
): Promise<{ responseData: any }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  if (source === 'custom_fields') {
    return apiCall<{ responseData: any }>(`${baseURL}/translation_keys/custom_fields/item`, init);
  } else {
    return apiCall<{ responseData: any }>(`${baseURL}/translation_keys/item`, init);
  }
};

export const testMailTranslation = async (mail_code: string, language: string): Promise<{ data: string }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ mail_code, language }),
  };

  return apiCall<{ data: string }>(`${baseURL}/translation_mails/test`, init);
};

export const getLopdMailTranslation = async (): Promise<{ data: LOPDMail }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: LOPDMail }>(`${baseURL}/translation_mails_lopd/list`, init);
};

export const updateLopdMailTranslation = async (mails: SendDataLOPD): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(mails),
  };

  return apiCall<{ data: 'OK' }>(`${baseURL}/translation_mails_lopd/items`, init);
};
