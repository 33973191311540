import {
  ScholarshipApiPayment,
  ScholarshipEntityPaymentApi,
  ScholarshipPayment,
  ScholarshipPaymentTotals,
} from './definitions';
import { ScholarshipPaymentsManager } from './ScholarshipPaymentsManager';

export class EntityScholarshipPaymentsManager {
  private scholarships: ScholarshipEntityPaymentApi[];
  private academic_year: string;
  private serviceScholarshipPayments: ScholarshipPaymentsManager;
  constructor(academic_year: string, scholarshipsEntitiesPayments: ScholarshipEntityPaymentApi[]) {
    this.scholarships = scholarshipsEntitiesPayments;
    this.academic_year = academic_year;
    this.serviceScholarshipPayments = new ScholarshipPaymentsManager(
      academic_year,
      this.convertToScholarshipApiPayment(this.scholarships),
    );
  }

  private convertToScholarshipApiPayment(scholarships: ScholarshipEntityPaymentApi[]): ScholarshipApiPayment[] {
    const parseScholarships: ScholarshipApiPayment[] = [];
    scholarships.forEach((scholarship: ScholarshipEntityPaymentApi) => {
      parseScholarships.push({
        id: '',
        scholarship_id: scholarship.scholarship_id,
        student_name: scholarship.student_name,
        document_number: '',
        provider_code: '',
        agreement_number: '',
        scholarship_state_id: scholarship.scholarship_state_id,
        scholarship_state: scholarship.scholarship_state,
        entity_name: scholarship.entity_name,
        disabled: scholarship.disabled,
        disabled_reason: scholarship.disabled_reason,
        scholarship_type: scholarship.scholarship_type,
        payments: scholarship.payments,
      });
    });
    return parseScholarships;
  }

  getPaymentList(): ScholarshipPayment[] {
    return this.serviceScholarshipPayments.getScholarshipPaymentList();
  }

  getPaymentTotals(): ScholarshipPaymentTotals {
    return this.serviceScholarshipPayments.getScholarshipPaymentTotals();
  }
}
