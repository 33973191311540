import { ButtonProps, MenuItem } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getAreas, getTrackingsAreaById, createTrackings, editTrackings } from '../../../api/Areas/areas';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import Title from '../../../components/Title/Title';
import { tFormKey, tKey } from '../../../helpers/translate';
import { AreasData, Tracking } from '../../../redux/area/definitions';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { TemplateSchema } from '../../../validations/formSchema';

interface TemplateConfigProps extends RouteComponentProps<{ id: string; code: string }> {
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const TemplateConfig: React.FC<TemplateConfigProps> = ({
  match: {
    params: { id, code },
  },
  showSnackbar,
}) => {
  const [areas, setAreas] = useState([] as AreasData[]);
  const [loading, setLoading] = useState(false);

  const { errors, control, reset, watch, handleSubmit } = useForm<Tracking>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: TemplateSchema,
  });

  useEffect(() => {
    setLoading(true);
    if (!id) {
      getAreas()
        .then(({ data: areas }: any) => {
          setAreas(areas);
          setLoading(false);
        })
        .catch(error => {
          showSnackbar(error.message, 'error', undefined, 1500);
          setLoading(false);
        });
      return;
    }
    Promise.all([getAreas(), getTrackingsAreaById(id)])
      .then(([{ data: area }, tracking]: any) => {
        setAreas(area);
        reset(tracking);
        setLoading(false);
      })
      .catch(error => {
        showSnackbar(error.message, 'error', undefined, 1500);
        setLoading(false);
      });
  }, [reset,id,showSnackbar]);

  const handleCreate = async (data: Tracking) => {
    try {
      await createTrackings(data);
      showSnackbar(tKey('Plantilla creada con exito'), 'success', undefined, 1500);
    } catch (error) {
      showSnackbar(error.message, 'error', undefined, 1500);
    }
  };

  const handleEdit = async (data: Tracking) => {
    try {
      await editTrackings(data, id);
      showSnackbar(tKey('Plantilla guardada con exito'), 'success', undefined, 1500);
    } catch (error) {
      showSnackbar(error.message, 'error', undefined, 1500);
    }
  };

  const buttonsActions: ButtonProps[] = [
    {
      children: id ? tKey('Guardar') : tKey('Crear'),
      fullWidth: true,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      onClick: handleSubmit(id ? handleEdit : handleCreate),
      hidden: code === 'detalle',
      startIcon: <Send />,
    },
  ];
  return (
    <LayoutForm rightSubmenu={<ActionsMenu actionsButtons={buttonsActions} />}>
      <Title>{`${code.charAt(0).toUpperCase() + code.slice(1)} ${tFormKey('Plantillas')}`}</Title>
      <>
        {loading ? (
          <Loading big />
        ) : (
          <FormContainer title="">
            <SelectController
              size="50"
              control={control}
              errors={errors}
              schema={TemplateSchema}
              name="area_id"
              label={tFormKey('Áreas')}
              defaultValue={watch('area_id')}
            >
              {areas.map((element: AreasData) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              control={control}
              errors={errors}
              schema={TemplateSchema}
              name="title"
              label={tFormKey('Título')}
              size="50"
              defaultValue={watch('title')}
            ></TextInputController>
            <TextInputController
              control={control}
              errors={errors}
              schema={TemplateSchema}
              name="message"
              label={tFormKey('Mensaje')}
              fullWidth
              multiline
              defaultValue={watch('message')}
              rows={10}
              rowsMax={10}
            ></TextInputController>
          </FormContainer>
        )}
      </>
    </LayoutForm>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(null, mapDispatchToProps)(TemplateConfig);
