import { tKey } from '../../../../../helpers/translate';

export interface FinishesWorkshopOptions {
  name: string;
  id: string;
}

export const finishesWorkshopOptions: FinishesWorkshopOptions[] = [
  { name: tKey('Sí'), id: 'Sí' },
  { name: tKey('No'), id: 'No' },
  { name: tKey('No ha empezado la expedición'), id: 'No ha empezado la expedición' },
];
