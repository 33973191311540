import React from 'react';
import { connect } from 'react-redux';
import { download as downloadDocumentAgreement } from '../../../../api/Entity/entity';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { getDocs } from '../../../../helpers/getDocs';
import { tKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import errorMessage from '../../../../helpers/errorMessage';

interface DocumentAgreementProps {
  agreementDocumentation: DocumentationItem[];
  agreement_number: string;
  agreement_state: string | null;
  entityId: number;
  disabled?: boolean;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const DocumentAgreement: React.FC<DocumentAgreementProps> = ({
  agreementDocumentation,
  entityId,
  disabled,
  agreement_number,
  agreement_state,
  showSnackbar,
}) => {
  const actions = ['download', 'view'];

  const downloadFile = (file_id: number, name: string, download: boolean) => {
    try {
      downloadDocumentAgreement(file_id)
        .then(res => getDocs(res, download, name))
        .catch(error => {
          showSnackbar(error.message, 'error', undefined, 1500);
        });
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const download = (file_id: number, name: string) => {
    downloadFile(file_id, name, true);
  };

  const show = (file_id: number, name: string) => {
    downloadFile(file_id, name, false);
  };

  let agreementState;

  if (agreement_state) {
    agreementState = ` (${tKey('agreementState.'.concat(agreement_state))})`;
  }

  return (
    <div>
      {agreementDocumentation ? (
        <FncAddDocuments
          title={`${tKey('Convenio')}: ${agreement_number}${agreementState}`}
          documents={agreementDocumentation}
          actions={actions}
          genericId={entityId}
          download={download}
          getFile={show}
          required
          disabled={disabled}
        />
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(null, mapDispatchToProps)(DocumentAgreement);
