import { APIBadPlatformError, APIError } from '../../api/api';
import { LoginFields } from '../../components/Login/LoginForm/LoginForm';
import { AuthorizationConsts } from './action_types';
import {
  PlatformTranslation,
  PlatformTranslationKo,
  PlatformTranslationOk,
  AuthGetDetailsOkAction,
  AuthLoginAction,
  AuthLoginKoAction,
  AuthLoginOkAction,
  AuthLoginRedirectAction,
  AuthLogoutAction,
  AuthLogoutKoAction,
  AuthLogoutOkAction,
  AuthUpdateUserStateAction,
  AuthUserAvatarAction,
  AuthUserAvatarKoAction,
  AuthUserAvatarOkAction,
  AuthUserChangeRole,
  AuthUserDataLogin,
  AuthUserGetAvatarAction,
  AuthUserGetAvatarKoAction,
  AuthUserGetAvatarOkAction,
  ClearErrorAction,
  DisableInitAction,
  EnableInitAction,
  EntityActivationAction,
  EntityActivationKoAction,
  EntityActivationOkAction,
  UserActivationAction,
  UserActivationKoAction,
  UserActivationOkAction,
} from './definitions';

export function getPlatformTranslation(languageCode: string): PlatformTranslation {
  return {
    type: AuthorizationConsts.PLATFORM_TRANSLATION,
    languageCode,
  };
}
export function getPlatformTranslationOk(): PlatformTranslationOk {
  return {
    type: AuthorizationConsts.PLATFORM_TRANSLATION_OK,
  };
}
export function getPlatformTranslationKo(error: APIError): PlatformTranslationKo {
  return {
    type: AuthorizationConsts.PLATFORM_TRANSLATION_KO,
    error,
  };
}

export function login(data: LoginFields): AuthLoginAction {
  const { email, password } = data;
  return {
    type: AuthorizationConsts.AUTH_LOGIN,
    email,
    password,
  };
}

export function loginOk(response: AuthUserDataLogin): AuthLoginOkAction {
  return {
    type: AuthorizationConsts.AUTH_LOGIN_OK,
    data: response,
  };
}

export function loginKO(error: APIError): AuthLoginKoAction {
  return {
    type: AuthorizationConsts.AUTH_LOGIN_KO,
    error,
  };
}

export function loginRedirect(error: APIBadPlatformError): AuthLoginRedirectAction {
  return {
    type: AuthorizationConsts.AUTH_LOGIN_REDIRECT,
    error,
  };
}

export function updateUserState(state: string, phone: string | null): AuthUpdateUserStateAction {
  return {
    type: AuthorizationConsts.AUTH_UPDATE_USER_STATE,
    state,
    phone,
  };
}

export function getDetailsOk(response: { data: AuthUserDataLogin }): AuthGetDetailsOkAction {
  return {
    type: AuthorizationConsts.AUTH_GET_DETAILS_OK,
    data: response.data,
  };
}

export function logoutAction(): AuthLogoutAction {
  return {
    type: AuthorizationConsts.AUTH_LOGOUT,
    token: null,
  };
}

export function logoutActionOk(): AuthLogoutOkAction {
  return {
    type: AuthorizationConsts.AUTH_LOGOUT_OK,
  };
}

export function logoutActionKO(): AuthLogoutKoAction {
  return {
    type: AuthorizationConsts.AUTH_LOGOUT_KO,
  };
}

export function userChangeRole(user_id: number, role_id: number): AuthUserChangeRole {
  return {
    type: AuthorizationConsts.AUTH_USER_CHANGE_ROLE,
    user_id,
    role_id,
  };
}

export function userActivationStart(token: string): UserActivationAction {
  return {
    type: AuthorizationConsts.AUTH_USER_ACTIVATION,
    token,
  };
}

export function userActivationOK(data: AuthUserDataLogin): UserActivationOkAction {
  return {
    type: AuthorizationConsts.AUTH_USER_ACTIVATION_OK,
    data,
  };
}

export function userActivationKO(error: APIError): UserActivationKoAction {
  return {
    type: AuthorizationConsts.AUTH_USER_ACTIVATION_KO,
    error,
  };
}

export function setUserProfileAvatar(image: string): AuthUserAvatarAction {
  return {
    type: AuthorizationConsts.AUTH_SET_USER_AVATAR,
    image,
  };
}

export function setUserProfileAvatarOk(avatar: string): AuthUserAvatarOkAction {
  return {
    type: AuthorizationConsts.AUTH_SET_USER_AVATAR_OK,
    avatar: avatar,
  };
}

export function setUserProfileAvatarKo(error: APIError): AuthUserAvatarKoAction {
  return {
    type: AuthorizationConsts.AUTH_SET_USER_AVATAR_KO,
    error: error,
  };
}

export function getUserProfileAvatar(): AuthUserGetAvatarAction {
  return {
    type: AuthorizationConsts.AUTH_GET_USER_AVATAR,
  };
}

export function getUserProfileAvatarOk(avatar: string): AuthUserGetAvatarOkAction {
  return {
    type: AuthorizationConsts.AUTH_GET_USER_AVATAR_OK,
    avatar,
  };
}

export function getUserProfileAvatarKo(error: APIError): AuthUserGetAvatarKoAction {
  return {
    type: AuthorizationConsts.AUTH_GET_USER_AVATAR_KO,
    error: error,
  };
}

export function activationEntity(token: string): EntityActivationAction {
  return {
    type: AuthorizationConsts.ACTIVATION_ENTITY,
    token,
  };
}

export function activationEntityOk(data: AuthUserDataLogin): EntityActivationOkAction {
  return {
    type: AuthorizationConsts.ACTIVATION_ENTITY_OK,
    data,
  };
}

export function activationEntityKo(error: APIError): EntityActivationKoAction {
  return {
    type: AuthorizationConsts.ACTIVATION_ENTITY_KO,
    error,
  };
}

export function enableInit(): EnableInitAction {
  return {
    type: AuthorizationConsts.ENABLE_INIT,
  };
}

export function disableInit(): DisableInitAction {
  return {
    type: AuthorizationConsts.DISABLE_INIT,
  };
}

export function clearError(): ClearErrorAction {
  return {
    type: AuthorizationConsts.CLEAR_ERROR,
  };
}
