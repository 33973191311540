import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../../helpers/customHooks/useIsRequired';
import FncControlErrorMessage from '../FncControlErrorMessage/FncControlErrorMessage';
import FncRadioButton from './FncRadioButton';
import styles from './fncRadioGroup.module.scss';

export interface OptionsRadioButtons {
  value: string;
  label: string;
}

interface Props<T extends FieldValues> {
  name: string;
  control: Control<T>;
  errors: FieldErrors<T>;
  description: string;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  options: OptionsRadioButtons[];
  defaultValue?: string | number;
  vertical?: boolean;
  labelTopPosition?: boolean;
  disabled?: boolean;
  customClass?: string;
  onChangeValue?: (value: string) => void;
}

const FncRadioGroupController = <T extends FieldValues>({
  name,
  description,
  options,
  errors,
  defaultValue,
  control,
  vertical,
  disabled,
  customClass,
  schema,
  onChangeValue,
  ...props
}: Props<T>) => {
  const error = errors[name] as FieldError;
  const required = useIsRequired(name, schema);
  return (
    <div className={`${styles.root} ${customClass ? customClass : ''}`}>
      {description && (
        <p className={styles.description}>
          {description}
          {required && '*'}
        </p>
      )}
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        as={field => {
          return (
            <ul className={`${styles.list}`} {...props}>
              {options.map((option, index) => {
                return (
                  <li className={styles.item} key={index}>
                    <FncRadioButton
                      id={`${name}_${index}`}
                      name={field.name}
                      disabled={disabled}
                      value={option.value}
                      label={option.label}
                      groupValue={field.value}
                      vertical={vertical}
                      onChange={event => {
                        onChangeValue && onChangeValue(event.target.value);
                        field.onChange(event);
                      }}
                      {...props}
                    />
                  </li>
                );
              })}
            </ul>
          );
        }}
      />
      {!!error && <FncControlErrorMessage message={error.message || ''} />}
    </div>
  );
};

export default FncRadioGroupController;
