import { put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import { registerUser as regUser } from '../../api/auth';
import { rolesData } from '../../api/selects/select';
import assertUnreachable from '../../helpers/assertUnreachable';
import {
  getRolesKo,
  getRolesOk,
  RegisterSubmitAction,
  registerSubmitKo,
  registerSubmitOk,
  registerSubmitValidationErrors,
  RequestRolesAction,
} from './actions';
import { RegisterConsts } from './action_types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getRoles(action: RequestRolesAction): Generator<any, void, any> {
  try {
    const { data } = yield rolesData(action.entity);
    yield put(getRolesOk(data));
  } catch (e) {
    yield put(getRolesKo(e.message));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* registerUser(action: RegisterSubmitAction): Generator<any, void, any> {
  try {
    const result = (yield regUser(action.registerFields)) as Await<ReturnType<typeof regUser>>;
    switch (result.type) {
      case 'ok':
        const info = result.value;
        yield put(registerSubmitOk(info));
        return;

      case 'validation-error':
        const error = result.value;
        yield put(registerSubmitValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(registerSubmitKo(e));
  }
}

export default [
  takeLatest<RequestRolesAction>(RegisterConsts.REQUEST_ROLES, getRoles),
  takeLatest<RegisterSubmitAction>(RegisterConsts.REGISTER_SUBMIT, registerUser),
];
