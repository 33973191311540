import { Button, Tooltip } from '@material-ui/core';
import { Check, Close, CloudDownload, CloudUpload, Delete, Pageview } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useState } from 'react';
import colorPalette from '../../../helpers/colorPalette';
import { tErrorKey, tKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import FormContainer from '../../Forms/FormContainer';
import MessageInfoRaw from '../../MessageInfo/MessageInfoRaw';
import TransitionModal from '../../Modal/TransitionModal';
import SnackBar from '../../SnackBar/Snackbar';
import TableWithEdit from '../TableWithEdit';
import MultiDocumentModalPicker from './MultiDocumentModalPicker';
import NewDocumentModal from './NewDocumentModal';
import styles from './addDocuments.module.scss';

export interface AddDocumentsProps {
  documents: DocumentationItem[];
  filesOk?: string[];
  genericId?: any;
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  actions: string[];
  upload?: (documentation_id: number, data: File, id?: any) => void;
  uploadAnonymous?: (file: File, documentation_id: number) => void;
  download?: (file_id: number, name: string, generic_id?: any) => void;
  remove?: (file_id: number, generic_id?: any, fieldName?: string, documentation_id?: number) => void;
  getFile?: (file_id: number, name: string, generic_id?: any) => void;
  addDoc?: (document: File, description: string, genericId?: any, documentationId?: number, fieldName?: string) => void;
  addRow?: boolean;
  multidocument?: boolean;
  multidocumentLabel?: boolean;
  infoMessage?: string;
  required?: boolean;
  requiredMultiDocument?: boolean;
  originalName?: boolean;
  fieldName?: string;
}

const AddDocuments: React.FC<AddDocumentsProps> = ({
  documents,
  actions,
  filesOk,
  genericId,
  upload,
  uploadAnonymous,
  disabled,
  title,
  subtitle,
  download,
  remove,
  getFile,
  addDoc,
  addRow,
  multidocument,
  infoMessage,
  required,
  originalName,
  fieldName,
  multidocumentLabel = true,
  requiredMultiDocument,
}) => {
  const [view, setView] = useState(false);
  const [modal, setModal] = useState(false);
  const [documentationID, setDocumentationID] = useState(0);
  const fileAccepted = filesOk ? filesOk : ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'tiff', 'jpeg', 'odt', 'png', 'jpg'];
  const actionIcons = {
    Visibility: () => <Pageview style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    VisibilityDis: () => <Pageview style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    CloudUploadDis: () => <CloudUpload style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    DeleteDis: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    CloudDownload: () => <CloudDownload style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    CloudDownloadDis: () => <CloudDownload style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    Check: () => <Check style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    Close: () => <Close color={'primary'} />,
  };
  const onUpload = (e: React.ChangeEvent<HTMLInputElement>, rowData: DocumentationItem) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      upload && upload(rowData.documentation_id, file, genericId);
      uploadAnonymous && uploadAnonymous(file, rowData.documentation_id);
      e.target.value = '';
    }
  };

  const tableData: MaterialTableProps<DocumentationItem> = {
    title: '',
    columns: [
      {
        title: tKey('Requerido'),
        field: 'name',
        render: rowData => {
          return rowData.mandatory ? (
            <FiberManualRecordIcon
              style={{ color: `${rowData.file_id ? colorPalette.greenBubble : colorPalette.primary80}` }}
            />
          ) : (
            undefined
          );
        },
        hidden: !required,
        //@ts-ignore
        width: '100px',
      },
      {
        title: tKey('Nombre'),
        field: 'name',
        //@ts-ignore
        width: '100%',
        cellStyle: { minWidth: 20 },
        render: rowData => (
          <Tooltip title={rowData.name}>
            <span>{rowData.name}</span>
          </Tooltip>
        ),
      },
      {
        title: tKey('Subido por'),
        field: 'upload',
        //@ts-ignore
        width: '200px',
        render: rowData => (
          <Tooltip title={rowData.upload}>
            <span>{rowData.upload}</span>
          </Tooltip>
        ),
      },
      {
        title: tKey('Fecha de actualización'),
        field: 'update',
        //@ts-ignore
        width: '210px',
      },
    ],
    data: documents,
    actions: [
      (rowData: DocumentationItem) => ({
        icon: rowData.file_id === null ? actionIcons.Close : actionIcons.Check,
        onClick: () => {
          return;
        },
        hidden: !actions.includes('checked'),
      }),
      (rowData: DocumentationItem) => ({
        tooltip: 'subir',
        icon: disabled
          ? actionIcons.CloudUploadDis
          : () => {
              return multidocument /* && rowData.file_id */ ? (
                <label htmlFor={rowData.name} className={styles.view_file_label}>
                  <CloudUpload
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                    onClick={() => {
                      setDocumentationID(rowData.documentation_id);
                    }}
                  />
                </label>
              ) : (
                <label htmlFor={rowData.name} className={styles.view_file_label}>
                  <CloudUpload style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                  <input
                    type="file"
                    id={rowData.name}
                    key={rowData.documentation_id}
                    name="document"
                    className={styles.view_file}
                    onChange={e => {
                      const fileFormat = e.target.value.split('.');
                      if (fileAccepted.includes(fileFormat[fileFormat.length - 1])) {
                        onUpload(e, rowData);
                      } else {
                        fileFormat.length > 1 && setView(true);
                        e.target.value = '';
                      }
                    }}
                  />
                </label>
              );
            },
        onClick: () => {
          return;
        },
        hidden: !actions.includes('upload'),
      }),
      (rowData: DocumentationItem) => ({
        icon: rowData.file_id !== null ? actionIcons.CloudDownload : actionIcons.CloudDownloadDis,
        tooltip: tKey('Bajar'),
        onClick:
          rowData.file_id !== null
            ? () => {
                if (download) {
                  if (originalName) download(rowData.file_id || 0, rowData.original_name, genericId);
                  else download(rowData.file_id || 0, rowData.filename, genericId);
                }
              }
            : () => {
                return;
              },
        hidden: !actions.includes('download'),
      }),
      (rowData: DocumentationItem) => ({
        icon: rowData.file_id !== null ? actionIcons.Visibility : actionIcons.VisibilityDis,
        tooltip: 'ver',
        onClick:
          rowData.file_id !== null
            ? e => {
                e.stopPropagation();
                getFile && getFile(rowData.file_id || 0, rowData.filename, genericId);
              }
            : () => {
                return;
              },
        hidden: !actions.includes('view'),
      }),

      (rowData: DocumentationItem) => ({
        icon: rowData.file_id !== null && !disabled ? actionIcons.Delete : actionIcons.DeleteDis,
        tooltip: 'eliminar',
        onClick:
          rowData.file_id !== null && !disabled
            ? e => {
                e.stopPropagation();
                remove && remove(rowData.file_id || 0, genericId, fieldName, rowData.documentation_id);
              }
            : () => {
                return;
              },
        hidden: !actions.includes('remove'),
      }),
      {
        icon: () => (
          <Button variant="outlined" color="primary" component="label" disableElevation disabled={disabled}>
            {tKey('Añadir Documento')}
          </Button>
        ),
        hidden: !addDoc,
        isFreeAction: true,
        onClick: () => setModal(true),
        disabled,
      },
    ],
    options: {
      search: false,
      paging: false,
      actionsColumnIndex: -1,
      toolbar: !!addRow,
      tableLayout: 'auto',
    },
    //@ts-ignore
    localization: {
      body: {
        //@ts-ignore
        emptyDataSourceMessage: requiredMultiDocument ? (
          <MessageInfoRaw>{tKey('documentos_requeridos')}</MessageInfoRaw>
        ) : (
          tKey('no_hay_documentos')
        ),
      },
    },
  };
  return (
    <Fragment>
      <FormContainer title={title ? title : ''}>
        {multidocument ? (
          <div style={{ color: '#666666', marginBottom: '10px' }}>
            {multidocumentLabel ? tKey('Subir más archivos') : ''}
          </div>
        ) : null}
        <div className={styles.wrapper}>
          {subtitle && <span className={styles.format_file}>{subtitle}</span>}
          {/* Si es Mobil llama a la tabla de materialsUI */}
          <TableWithEdit tableData={tableData} key={JSON.stringify(documents)} />
        </div>
        <p className={styles.format_file}>
          {tKey('Los formatos soportados son')} : {fileAccepted.map(str => `${str}`).join(', ')}
        </p>
        {infoMessage && (
          <div className={styles.footnote}>
            <span className={styles.docInfo}>* {infoMessage}</span>
          </div>
        )}
      </FormContainer>
      <TransitionModal
        view={modal}
        handleClose={() => setModal(false)}
        title={tKey('Agregar documento')}
        bodyModal=""
        documentMode
      >
        {!multidocument ? (
          <NewDocumentModal
            addDoc={(document, description) =>
              addDoc && addDoc(document, description, genericId, documentationID, fieldName)
            }
            handleClose={() => setModal(false)}
          />
        ) : (
          <MultiDocumentModalPicker
            addDoc={(document, description) =>
              addDoc && addDoc(document, description, genericId, documentationID, fieldName)
            }
            handleClose={() => setDocumentationID(0)}
          />
        )}
      </TransitionModal>
      <TransitionModal
        view={documentationID > 0}
        handleClose={() => setDocumentationID(0)}
        title={tKey('Agregar documento')}
      >
        {addDoc && (
          <MultiDocumentModalPicker
            addDoc={(document, description) => addDoc(document, description, genericId, documentationID, fieldName)}
            handleClose={() => setDocumentationID(0)}
          />
        )}
      </TransitionModal>

      <SnackBar
        open={view}
        handleClose={() => {
          setView(false);
        }}
        message={tErrorKey('Formato de fichero no válido')}
        severity="error"
      />
    </Fragment>
  );
};

export default AddDocuments;
