export enum AuthorizationConsts {
  PLATFORM_TRANSLATION = 'PLATFORM:TRANSLATION',
  PLATFORM_TRANSLATION_OK = 'PLATFORM:TRANSLATION:OK',
  PLATFORM_TRANSLATION_KO = 'PLATFORM:TRANSLATION:KO',

  AUTH_LOGIN = 'AUTH:LOGIN',
  AUTH_LOGIN_OK = 'AUTH:LOGIN:OK',
  AUTH_LOGIN_REDIRECT = 'AUTH:LOGIN:REDIRECT',
  AUTH_LOGIN_KO = 'AUTH:LOGIN:KO',

  AUTH_LOGOUT = 'AUTH:LOGOUT',
  AUTH_LOGOUT_OK = 'AUTH:LOGOUT:OK',
  AUTH_LOGOUT_KO = 'AUTH:LOGOUT:KO',

  AUTH_UPDATE_USER_STATE = 'AUTH:UPDATE:USER:STATE',

  // AUTH_GET_DETAILS = 'AUTH:GET:DETAILS',
  AUTH_GET_DETAILS_OK = 'AUTH:GET:DETAILS:OK',
  // AUTH_GET_DETAILS_KO = 'AUTH:GET:DETAILS:KO',

  AUTH_SET_USER_AVATAR = 'AUTH:SET:USER:AVATAR',
  AUTH_SET_USER_AVATAR_OK = 'AUTH:SET:USER:AVATAR:OK',
  AUTH_SET_USER_AVATAR_KO = 'AUTH:SET:USER:AVATAR:KO',

  AUTH_GET_USER_AVATAR = 'AUTH:GET:USER:AVATAR',
  AUTH_GET_USER_AVATAR_OK = 'AUTH:GET:USER:AVATAR:OK',
  AUTH_GET_USER_AVATAR_KO = 'AUTH:GET:USER:AVATAR:KO',

  AUTH_USER_ACTIVATION = 'AUTH:USER:ACTIVATION',
  AUTH_USER_ACTIVATION_OK = 'AUTH:USER:ACTIVATION:OK',
  AUTH_USER_ACTIVATION_KO = 'AUTH:USER:ACTIVATION:KO',

  AUTH_USER_CHANGE_ROLE = 'AUTH:USER:CHANGE:ROLE',

  ACTIVATION_ENTITY = 'ACTIVATION:ENTITY',
  ACTIVATION_ENTITY_OK = 'ACTIVATION:ENTITY:OK',
  ACTIVATION_ENTITY_KO = 'ACTIVATION:ENTITY:KO',

  ENABLE_INIT = 'ENABLE:INIT',
  DISABLE_INIT = 'DISABLE:INIT',

  CLEAR_ERROR = 'CLEAR:ERROR',
}
