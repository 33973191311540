import { put, takeLatest } from '@redux-saga/core/effects';
import { getSearchResponse, searchRelationalData } from '../../api/Search/search';
import { getRelationalDataKo, getRelationalDataOk, getSearchDataKo, getSearchDataOk } from './actions';
import { SearchConsts } from './action_types';
import { SearchGetDataAction, SearchGetRelationalDataAction } from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getSearchData(action: SearchGetDataAction): Generator<any, void, any> {
  try {
    const { query } = action;
    const { data: searchResponse } = yield getSearchResponse(query);
    yield put(getSearchDataOk(searchResponse));
  } catch (e) {
    yield put(getSearchDataKo(e.message));
  }
}

function* getRelationalData(action: SearchGetRelationalDataAction): Generator<any, void, any> {
  try {
    const { id, queryType } = action;
    const { data: searchResponse } = yield searchRelationalData(queryType, id);
    yield put(getRelationalDataOk(searchResponse));
  } catch (e) {
    yield put(getRelationalDataKo(e.message));
  }
}

export default [
  takeLatest<SearchGetDataAction>(SearchConsts.SEARCH_GET_DATA, getSearchData),
  takeLatest<SearchGetRelationalDataAction>(SearchConsts.SEARCH_GET_RELATIONAL_DATA, getRelationalData),
];
