import { GetFamilyIncomesConst } from './action_types';
import { GetFamilyIncomesDataAction, GetFamilyIncomesState } from './definitions.d';
import assertUnreachable from '../../../../helpers/assertUnreachable';
import { FamilyIncomesInitialState } from '../FamilyIncome.d';

const getFamilyIncomesReducer = (
  state = FamilyIncomesInitialState,
  action: GetFamilyIncomesDataAction,
): GetFamilyIncomesState => {
  switch (action.type) {
    case GetFamilyIncomesConst.GET_FAMILY_INCOMES:
      return {
        ...state,
        loading: true,
        error: null,
        list: [],
      };
    case GetFamilyIncomesConst.GET_FAMILY_INCOMES_OK:
      const { list } = action;

      return {
        ...state,
        loading: false,
        error: null,
        list,
      };
    case GetFamilyIncomesConst.GET_FAMILY_INCOMES_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default getFamilyIncomesReducer;
