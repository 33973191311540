import { ButtonProps, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { getAgreementByYear, getScholarshipYearsAgreements } from '../../../../api/Entity/entity';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../../components/Loading/Loading';
import SelectController from '../../../../components/Select/SelectController';
import Title from '../../../../components/Title/Title';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import errorMessage from '../../../../helpers/errorMessage';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { AccountingDataSchema } from '../../../../validations/formSchema';
import accountStyles from './accountingData.module.scss';
import UploadDocuments from './UploadDocuments';

interface InputsData {
  course: string;
  agreement_number: string | null;
}

interface AccountingDataProps {
  loading?: boolean;
  id: number;
  disabled?: boolean;
  buttonActionSave: ButtonProps;

  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const ResEntAccountingData: React.FC<AccountingDataProps> = ({ id, disabled, buttonActionSave }) => {
  const [agreementDocumentation, setAgreementDocumentation] = useState<DocumentationItem[]>([]);
  const [stateAgreement, setStateAgreement] = useState('');
  const [dates, setDates] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const { errors, control, reset, watch, setValue } = useForm<InputsData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AccountingDataSchema,
  });

  const getAgreement = async () => {
    try {
      const data = await getAgreementByYear(id, watch('course'));
      if (Array.isArray(data)) {
        setValue('agreement_number', '');
        setAgreementDocumentation([]);
        return;
      }
      setValue('agreement_number', data.agreement_number);
      data.documentations.forEach((document: DocumentationItem) => {
        document.mandatory = true;
        document.name = tKey('Documento de Convenio');
      });
      setAgreementDocumentation(data.documentations);
      setStateAgreement(data.agreement_state);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const fetchGetPayment = async () => {
    try {
      const dates = await getScholarshipYearsAgreements(id);
      setDates(dates);
      if (!dates.length) return;
      const { agreement_number, documentations, agreement_state } = await getAgreementByYear(id, dates[0]);

      reset({ course: dates[0], agreement_number });
      if (documentations?.length) {
        documentations.forEach((document: DocumentationItem) => {
          document.mandatory = true;
          document.name = tKey('Documento de Convenio');
        });
        setAgreementDocumentation(documentations);
        setStateAgreement(agreement_state);
      }
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  useButtonAction(buttonActionSave, undefined, { hidden: true });

  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      await fetchGetPayment();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const waiting = 'Waiting' !== stateAgreement;
  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <section className={accountStyles.container}>
          <Title>{tKey('Convenios')}</Title>
          <SelectController
            name="course"
            control={control}
            errors={errors}
            schema={AccountingDataSchema}
            label={tFormKey('Curso')}
            size="20"
            defaultValue={watch('course')}
            onClick={getAgreement}
          >
            {dates.map(course => (
              <MenuItem key={course} value={course}>
                {course}
              </MenuItem>
            ))}
          </SelectController>
          <TextInputController
            size="20"
            schema={AccountingDataSchema}
            control={control}
            name="agreement_number"
            errors={errors}
            defaultValue={watch('agreement_number')}
            label={tFormKey('Nº de convenio')}
            disabled
          />
          <UploadDocuments
            entityId={id}
            agreement_state={stateAgreement}
            agreementDocumentation={agreementDocumentation}
            agreement_number={watch('agreement_number') || ''}
            disabled={disabled || waiting}
          />
        </section>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(null, mapDispatchToProps)(ResEntAccountingData);
