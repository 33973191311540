import { Action } from 'redux';
import { SearchConsts } from './action_types';

// This file contains initialState + definitions for notifications

// INITIAL STATE
export const initialState = {
  loading: false as boolean,
  error: null as string | null,
  searchResponse: [] as SearchItem[],
  searchRelationalData: [] as SearchItem[],
};

export type SearchItemType = 'entity' | 'user' | 'scholarship' | 'project';

export interface SearchItem {
  id: number;
  type: SearchItemType;
  detail: string;
  url: string | null;
  items?: SearchItem[];
}

export type SearchState = typeof initialState;

// TYPESCRIPT DEFINITONS FOR HANDLING ACTIONS

export interface SearchGetDataAction extends Action {
  type: SearchConsts.SEARCH_GET_DATA;
  query: string;
}

export interface SearchGetDataOkAction extends Action {
  type: SearchConsts.SEARCH_GET_DATA_OK;
  searchResponse: SearchItem[];
}

export interface SearchGetDataKoAction extends Action {
  type: SearchConsts.SEARCH_GET_DATA_KO;
  error: string;
}

export interface SearchGetRelationalDataAction extends Action {
  type: SearchConsts.SEARCH_GET_RELATIONAL_DATA;
  queryType: string;
  id: number;
}

export interface SearchGetRelationalDataOkAction extends Action {
  type: SearchConsts.SEARCH_GET_RELATIONAL_DATA_OK;
  searchRelationalData: SearchItem[];
}

export interface SearchGetRelationalDataKoAction extends Action {
  type: SearchConsts.SEARCH_GET_RELATIONAL_DATA_KO;
  error: string;
}

export type SearchAction =
  | SearchGetDataAction
  | SearchGetDataKoAction
  | SearchGetDataOkAction
  | SearchGetRelationalDataAction
  | SearchGetRelationalDataOkAction
  | SearchGetRelationalDataKoAction;

// TYPESCRIPT DEFINITIONS FOR DISPACHING EVENTS

export type SearchDispatch = (action: SearchAction) => void;
