import { Button, MenuItem } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../components/Select/SelectController';
import { tKey, tScholarshipKey } from '../../helpers/translate';
import { CodeNamePair, IdCodeNamePair } from '../../redux/common/definitions';
import { ChangeScholarshipStateModalSchema } from '../../validations/formSchema';
import styles from './changeScholarshipStateModal.module.scss';

interface ChangeScholarshipStateModalProps {
  current_state: CodeNamePair;
  next_states: IdCodeNamePair[];
  handleChange: (current_state: string, next_state: string) => void;
  handleClose: () => void;
}

interface StateProps {
  next_state_code: string;
}

const ChangeScholarshipStateModal: React.FC<ChangeScholarshipStateModalProps> = ({
  current_state,
  next_states,
  handleChange,
  handleClose,
}) => {
  const { errors, handleSubmit, watch, control } = useForm<StateProps>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ChangeScholarshipStateModalSchema,
  });

  const hasSelected = !+watch('next_state_code')?.length;

  const onSubmit = async (data: StateProps) => {
    handleChange(current_state.code?.toLowerCase() || '', data.next_state_code.toLowerCase());
    handleClose();
  };
  return (
    <form className={styles.modalSection} onSubmit={handleSubmit(onSubmit)}>
      <TextInputController
        label={tScholarshipKey('estado-actual')}
        name="current_state_name"
        defaultValue={current_state.name}
        disabled={true}
        size="50"
        schema={ChangeScholarshipStateModalSchema}
        errors={errors}
        control={control}
      />
      <SelectController
        label={tScholarshipKey('cambiar-a')}
        name="next_state_code"
        size="50"
        schema={ChangeScholarshipStateModalSchema}
        control={control}
        errors={errors}
      >
        {next_states.map((e: IdCodeNamePair) => (
          <MenuItem key={e.id} value={e.code}>
            {e.name}
          </MenuItem>
        ))}
      </SelectController>

      <div className={styles.btns}>
        <ButtonGroup>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            disabled={hasSelected}
            color="primary"
            type="submit"
            fullWidth
          >
            {tKey('Ok')}
          </Button>
        </ButtonGroup>
      </div>
    </form>
  );
};

export default ChangeScholarshipStateModal;
