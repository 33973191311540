import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Control, FieldError, NestDataObject } from 'react-hook-form';
import { tFormKey } from '../../helpers/translate';
import FormContainer from '../Forms/FormContainer';
import SelectController from '../Select/SelectController';
import Title from '../Title/Title';
import styles from './dayMonthSelector.module.scss';

interface PropsDayMonth {
  nameDay: string;
  nameMonth: string;
  control: Control;
  errors: NestDataObject<any, FieldError>;
  day: number;
  month: number;
  title: string;
  subTitle: string;
  dayMonthSchema: any;
}

const DayMonthSelector: React.FC<PropsDayMonth> = ({
  nameDay,
  nameMonth,
  control,
  errors,
  day,
  month,
  title,
  subTitle,
  dayMonthSchema,
}) => {
  const currentYear = new Date().getFullYear();
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [days, setDays] = useState<number[]>([]);

  const getMonthDays = (year: number, month: number) => {
    return Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => i + 1);
  };

  const dayIsInMonthsDays = (day: number, month: number[]) => {
    if (month.length !== 0) {
      for (let i = 0; i < month.length; i++) {
        if (month[i] === day) {
          return true;
        }
      }
      return false;
    }
  };

  // No es posible inferir el tipo porque no coincide lo tipado con lo que llega realmente,
  // el problema viene del mismo <SelectController/>
  const handleChangeMonth = (event: any) => {
    const monthDays = getMonthDays(currentYear, event.target.value);
    const dayToControl = control.getValues()[nameDay];

    setDays(monthDays);
    if (dayIsInMonthsDays(dayToControl, monthDays) === false && days.length !== 0) {
      control.setValue(nameDay, 1);
    }
  };

  useEffect(() => {
    const monthDays = getMonthDays(currentYear, month);
    const dayToControl = control.getValues()[nameDay];

    setDays(monthDays);
    if (dayIsInMonthsDays(dayToControl, monthDays) === false && days.length !== 0) {
      control.setValue(nameDay, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, currentYear]);

  useEffect(() => {
    if (days.length === 0) {
      const monthDays = getMonthDays(currentYear, month);
      setDays(monthDays);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [days]);

  return (
    <>
      <Title>{tFormKey(title)}</Title>
      <div className={styles.boxContainerFirstDate}>
        <FormContainer title={tFormKey(subTitle)} className={styles.inputWrapper}>
          <SelectController
            size="15"
            control={control}
            errors={errors}
            schema={dayMonthSchema}
            name={nameDay}
            label={'Dia'}
            defaultValue={day}
          >
            {days.map(type => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </SelectController>
          <SelectController
            size="15"
            control={control}
            errors={errors}
            schema={dayMonthSchema}
            name={nameMonth}
            label={'Mes'}
            defaultValue={month}
            onClick={handleChangeMonth}
          >
            {months.map(type => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </SelectController>
        </FormContainer>
      </div>
    </>
  );
};

export default DayMonthSelector;
