import { AppState } from '../../../root-reducer';
import { ParticipantParticularitiesType } from './definitions';

export const selectParticipantParticularitiesLoading = (state: AppState) =>
  state.participantParticularitiesReducer.loading;

export const selectParticipantParticularitiesList = (state: AppState): ParticipantParticularitiesType[] =>
  state.participantParticularitiesReducer.data;

export const selectParticipantParticularitiesError = (state: AppState) => state.participantParticularitiesReducer.error;
