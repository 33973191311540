import { Button, ButtonProps } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useWindowSize } from '../../../../helpers/customHooks/useHookMethod';
import { tKey } from '../../../../helpers/translate';
import styles from './actionsMenu.module.scss';
import OptionsWrapper from './OptionsWrapper';
import TitleActionsMenu from './TitleActionsMenu';

interface MoreInfoButtonProps {
  moreInfoButtons?: ButtonProps[];
}

const MoreInfoButton: React.FC<MoreInfoButtonProps> = ({ moreInfoButtons = [] }) => {
  const IsMobile = useWindowSize().IsMobile;

  return (
    <Fragment>
      {moreInfoButtons.length > 0 && (
        <Fragment>
          {!IsMobile && <TitleActionsMenu>{tKey('Más info')}</TitleActionsMenu>}
          <OptionsWrapper style={styles.options_wrapper_more_info}>
            <Fragment>
              {moreInfoButtons.map(
                ({ children, hidden, ...props }, i) =>
                  !hidden && (
                    <Button key={i} disableElevation {...props}>
                      {children}
                    </Button>
                  ),
              )}
            </Fragment>
          </OptionsWrapper>
        </Fragment>
      )}
    </Fragment>
  );
};

export default React.memo(MoreInfoButton);
