import { APIValidationError } from '../../api/api';
import { AppState } from '../root-reducer';
import { RolesProps } from './actions';
import { RegisterState } from './reducers';

export const selectRegister = (state: AppState): RegisterState => state.registerReducer;
export const selectRoles = (state: AppState): RolesProps[] => selectRegister(state).roles;
export const selectLoading = (state: AppState): boolean => selectRegister(state).loading;
export const selectLanguage = (state: AppState): string => state.authReducer.language;
export const selectErrorAPI = (state: AppState): any | null => selectRegister(state).error;
export const selectRegistered = (state: AppState): boolean => selectRegister(state).registered;
export const selectRoleId = (state: AppState): number => selectRegister(state).roleId;
export const selectValidationErrors = (state: AppState): APIValidationError | null =>
  selectRegister(state).validationErrors;
