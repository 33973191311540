import React from 'react';
import FormContainer from '../../../components/Forms/FormContainer';
import { ScholarshipData } from '../../../redux/scholarship/definitions';
import { tKey, tScholarshipKey } from '../../../helpers/translate';
import styles from './transversalCompetencesSection.module.scss';

interface TransversalCompetencesSectionProps {
  isFinal: boolean;
  scholarshipData: ScholarshipData;
}

const TransversalCompetencesSection: React.FC<TransversalCompetencesSectionProps> = ({ scholarshipData, isFinal }) => {
  const { initial_transversal_competences_link, final_transversal_competences_link, academic_year } = scholarshipData;

  const getP1 = (): string => {
    if (isFinal) {
      return tScholarshipKey('transversal_competences_educator_final_text1');
    }
    return tScholarshipKey('transversal_competences_educator_initial_text1');
  };

  const getP2 = (): string => {
    if (isFinal) {
      return tScholarshipKey('transversal_competences_educator_final_text2');
    }
    return tScholarshipKey('transversal_competences_educator_initial_text2');
  };

  const getP3 = (): string => {
    if (isFinal) {
      return tScholarshipKey('transversal_competences_educator_final_text3');
    }
    return `${tScholarshipKey('transversal_competences_educator_initial_text3')} 
    ${tScholarshipKey('transversal_competences_educator_initial_text4')}`;
  };

  const getP4 = (): string => {
    if (isFinal) {
      return tScholarshipKey('transversal_competences_educator_final_text4');
    }
    return `${tScholarshipKey('transversal_competences_educator_initial_text5')} 
    ${tScholarshipKey('transversal_competences_educator_initial_text6')}`;
  };

  const p1_text = getP1();
  const p2_text = getP2();
  const p3_text = getP3();
  const p4_text = getP4();

  const survey_link = isFinal ? final_transversal_competences_link : initial_transversal_competences_link;

  if (!survey_link) {
    return null;
  }

  // Deactivate transversal competences for academic_year 2022/2023
  if (academic_year === '2022/2023') {
    return null;
  }

  return (
    <FormContainer title={tScholarshipKey('Competencias transversales')} subtitle={''}>
      <p className={styles.parrafo}>{p1_text}</p>
      <p className={styles.parrafo}>{p2_text}</p>
      <p className={styles.parrafo}>{p3_text}</p>
      <p className={styles.parrafo}>{p4_text}</p>
      <p className={styles.parrafo}>
        {tScholarshipKey('transversal_competences_educator_initial_text7')}&nbsp;
        <a target="_blank" rel="noopener noreferrer" href={survey_link}>
          {tKey('aquí')}
        </a>
      </p>
    </FormContainer>
  );
};

export default TransversalCompetencesSection;
