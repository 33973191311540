import { AppState } from '../root-reducer';
import { CalendarState, IDate } from './definitions';

export const selectCalendar = (state: AppState): CalendarState => state.calendarReducer;

export const selectDates = (state: AppState): any => selectCalendar(state).datesTime;

export const selectIsLoadingTimes = (state: AppState): boolean => selectCalendar(state).loadingTimes;
export const selectIsLoadingDates = (state: AppState): boolean => selectCalendar(state).loadingDates;

export const selectSelectedDate = (state: AppState): IDate => selectCalendar(state).selectedDate;
