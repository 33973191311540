import * as yup from 'yup';
import { requiredNumber, requiredText } from '../../../validations/formSchema';
import { tErrorKey } from '../../../helpers/translate';

export const FamilyIncomeSchema = () =>
  yup.object().shape({
    family_member: yup.string().required(requiredText),
    income_type: yup.string().required(requiredText),
    amount: yup
      .number()
      .required(requiredNumber)
      .typeError(requiredNumber),
    file_id: yup
      .number()
      .typeError(tErrorKey('file.required'))
      .required(tErrorKey('file.required')),
  });
