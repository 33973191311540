export enum CalendarConsts {
  GET_ALL_DATES = 'GET:ALL:DATES',
  GET_ALL_DATES_OK = 'GET:ALL:DATES:OK',
  GET_ALL_DATES_KO = 'GET:ALL:DATES:KO',

  SET_TIME_TO_NOT_AVAILABLE = 'SET:TIME:TO:NOT_AVAILABLE',
  SET_TIME_TO_NOT_AVAILABLE_OK = 'SET:TIME:TO:NOT_AVAILABLE:OK',
  SET_TIME_TO_NOT_AVAILABLE_KO = 'SET:TIME:TO:NOT_AVAILABLE:KO',

  SET_TIME_TO_FREE = 'SET:TIME:TO:FREE',
  SET_TIME_TO_FREE_OK = 'SET:TIME:TO:FREE:OK',
  SET_TIME_TO_FREE_KO = 'SET:TIME:TO:FREE:KO',

  SET_SELECTED_TIME = 'SET:SELECTED:TIME',
}
