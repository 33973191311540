import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { tKey } from '../../helpers/translate';
import styles from './swipeableTabs.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ height: '300px' }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
}));

interface SwipeableTabsProps {
  tabOne: JSX.Element;
  tabTwo: JSX.Element;
  tabOneTitle: string;
  tabTwoTitle: {
    title: string;
    count?: number | null;
  };
  imageMode?: boolean;
  handleClose?: () => void;
}

const SwipeableTabs: React.FC<SwipeableTabsProps> = ({
  tabOne,
  tabTwo,
  tabOneTitle,
  tabTwoTitle,
  imageMode,
  handleClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <div className={classes.root} id="test">
      <AppBar position="static" color="default">
        {imageMode && (
          <div className={styles.imageMode}>
            <span>{tKey('Imagen de Perfil de Usuario')}</span>
            <IconButton aria-label="delete" className={styles.buttonClose} size="small">
              <CloseIcon fontSize="inherit" onClick={handleClose} />
            </IconButton>
          </div>
        )}

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
          className={styles.tab}
        >
          <Tab label={tabOneTitle} {...a11yProps(0)} />
          <Tab
            label={tabTwoTitle.count ? `${tabTwoTitle.title} (${tabTwoTitle.count})` : tabTwoTitle.title}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {tabOne}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {tabTwo}
        </TabPanel>
      </SwipeableViews>
    </div>
  );
};

export default SwipeableTabs;
