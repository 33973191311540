// Components
import { ContentState, convertFromHTML, convertToRaw, EditorState, Modifier } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import './wysiwyg.css';
import styles from './wysiwyg.module.scss';
import draftToHtml from 'draftjs-to-html';
import { connect } from 'react-redux';
import { setData } from '../../../../redux/translations/actions';
import { TypeLang, TypeSetData, WildCards } from '../../../../redux/translations/definitions';

interface MailTranslationsDetailsProps {
  defaultValue: any;
  id: number;
  lang: TypeLang;
  customOptions: WildCards[];
  onlyCustom?: boolean;
  isNotHtmlText?: boolean;
  setDataTranslate: (text: string, setType: TypeSetData, lang: TypeLang, id: number) => void;
}

const WYSIWYGEditor: React.FC<MailTranslationsDetailsProps> = ({
  defaultValue,
  id,
  lang,
  customOptions,
  onlyCustom,
  isNotHtmlText,
  setDataTranslate,
}) => {
  const [editorState, setEditorState] = useState(undefined);

  const onChangeSet = (text: string) => {
    if (id === -1) {
      setDataTranslate(text, 'general', lang, id);
    }
    setDataTranslate(text, 'section', lang, id);
  };

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (isNotHtmlText) {
      // clean HTML tags
      const div = document.createElement('div');
      div.innerHTML = content;
      content = div.textContent || div.innerText || '';
    }
    onChangeSet(content);
  };

  useEffect(() => {
    let editorStateContent;
    if (defaultValue) {
      const blocksFromHTML = convertFromHTML(defaultValue);
      if (blocksFromHTML.contentBlocks === null) {
        editorStateContent = EditorState.createEmpty();
      } else {
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
        editorStateContent = EditorState.createWithContent(state);
      }
    } else {
      editorStateContent = EditorState.createEmpty();
    }
    //@ts-ignore
    setEditorState(editorStateContent);
  }, [defaultValue]);

  const CustomOption: React.FC<any> = ({ onChange, editorState, data }) => {
    const addOption = () => {
      const contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        `{${data.value}}`,
        editorState.getCurrentInlineStyle(),
      );
      onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    };

    return (
      <div className={'rdw-option-wrapper'} onClick={() => addOption()}>
        {data.value}
      </div>
    );
  };

  const customButtons: Array<React.ReactElement<HTMLElement>> = [];
  if (customOptions) {
    customOptions.map(e => customButtons.push(<CustomOption key={e.id} data={e} />));
  }

  return (
    <div className={styles.editorContainer}>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName={styles.editor}
        toolbarClassName={styles.toolbar}
        onEditorStateChange={editorState => {
          onEditorStateChange(editorState);
        }}
        toolbarCustomButtons={customButtons || []}
        toolbar={{
          options: onlyCustom ? [] : ['inline', 'list', 'textAlign', 'colorPicker', 'fontSize', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          textAlign: {
            options: ['left', 'center', 'justify'],
          },
        }}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setDataTranslate: (text: string, setType: TypeSetData, lang: TypeLang, id: number): void =>
    dispatch(setData(text, setType, lang, id)),
});

export default connect(null, mapDispatchToProps)(WYSIWYGEditor);
