import { Button, ButtonGroup, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CheckboxController from '../../../../../../../../components/Checkbox/CheckboxController';
import FormContainer from '../../../../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../../../../components/Inputs/TextInputController/TextInputController';
import TransitionModal from '../../../../../../../../components/Modal/TransitionModal';
import SelectController from '../../../../../../../../components/Select/SelectController';
import { tKey } from '../../../../../../../../helpers/translate';
import { Assistance, MotivoAusencia } from '../../../../../../../../redux/internal-projects/participants/Participant.d';
import styles from './setAssistaneceForm.module.scss';
import { requiredText } from '../../../../../../../../validations/formSchema';

interface AssistanceProps {
  showModal: boolean;
  assistance: Assistance;
  onCloseModal: () => void;
  onSetAssistance: (data: Assistance) => void;
}

const SetAssistanceForm = ({ showModal, assistance, onCloseModal, onSetAssistance }: AssistanceProps) => {
  const schema = yup.object().shape({
    assistance: yup.boolean(),
    reasons: yup.string().when('assistance', {
      is: assistance => !assistance,
      then: yup.string().required(requiredText),
      otherwise: yup.string().nullable(),
    }),
    other_reasons: yup.string().when('reasons', {
      is: reasons => reasons === MotivoAusencia.OTRO,
      then: yup.string().required(requiredText),
      otherwise: yup.string().nullable(),
    }),
  });

  const { control, errors, watch, setValue, handleSubmit } = useForm<Assistance>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schema,
  });

  const onSubmit = (data: Assistance) => {
    const assistanceData = {
      internal_project_participant_id: assistance.internal_project_participant_id,
      internal_project_follow_up_id: assistance.internal_project_follow_up_id,
      type: assistance.type,
      assistance: data.assistance,
      reasons: data.reasons,
      other_reasons: data.other_reasons,
    } as Assistance;
    onSetAssistance(assistanceData);
  };

  return (
    <>
      <TransitionModal
        view={showModal}
        handleClose={onCloseModal}
        title={`${tKey('Asistencia de: ')} ${assistance.user_name}`}
      >
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <FormContainer title={''}>
            <CheckboxController
              control={control}
              defaultValue={!!assistance?.assistance}
              label={tKey('asistencia')}
              name={'assistance'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('assistance', event.target.checked);
                if (event.target.checked) {
                  setValue('reasons', null);
                  setValue('other_reasons', null);
                }
              }}
            />
            <SelectController
              name={'reasons'}
              size={60}
              errors={errors}
              control={control}
              schema={schema}
              label={tKey('asistencia.motivo')}
              defaultValue={assistance.reasons}
            >
              {Object.values(MotivoAusencia).map(motivo => {
                return (
                  <MenuItem key={motivo} value={motivo}>
                    {motivo}
                  </MenuItem>
                );
              })}
            </SelectController>
            <TextInputController
              errors={errors}
              name={'other_reasons'}
              label={tKey('Otros')}
              schema={schema}
              fullWidth
              control={control}
              multiline
              rows={6}
              rowsMax={6}
              defaultValue={assistance.other_reasons}
              disabled={watch('reasons') !== MotivoAusencia.OTRO}
            />
          </FormContainer>
          <div className={styles.btns}>
            <ButtonGroup>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                {tKey('Guardar')}
              </Button>
            </ButtonGroup>
          </div>
        </form>
      </TransitionModal>
    </>
  );
};

export default SetAssistanceForm;
