import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import colorPalette from '../../helpers/colorPalette';
import { tKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import { newDocument, resetDocumentsToSend } from '../../redux/project/action';
import { DocumentsFollowup, ProjectDispatch } from '../../redux/project/definitions';
import { selectLoading } from '../../redux/project/selectors';
import { AppState } from '../../redux/root-reducer';
import styles from './dropdownUpload.module.scss';

interface DrowDownProps {
  disabled?: boolean;
  uploadNewDocument: (file: File, name: string, actor: string) => void;
  documentsUpload: DocumentsFollowup[];
  resetDocuments: () => void;
  helperText: boolean;
  isTableMode?: boolean;
}

const DropdownUpload: React.FC<DrowDownProps> = ({
  disabled,
  uploadNewDocument,
  documentsUpload,
  resetDocuments,
  isTableMode,
}) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [uploadReady, setUploadReady] = useState(false);

  const actorF = isTableMode ? 'tableMode' : 'followUp';

  useEffect(() => {
    resetDocuments();
  }, [resetDocuments]);

  const [highlight, setHighligh] = useState(false);
  const openFileDialog = () => {
    if (disabled) return;
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileListToArray = (list: FileList) => {
    const array = [];
    for (let i = 0; i < list.length; i++) {
      array.push(list);
    }

    const values = Object.values(list);
    values.forEach(e => {
      uploadNewDocument(e, e.name, actorF);
    });
    setUploadReady(true);
    return array;
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    if (event.target) {
      const files: any = event.target.files;
      if (onFilesAdded) {
        const array: any = fileListToArray(files);
        onFilesAdded(array);
      }
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) return;

    setHighligh(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighligh(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (disabled) return;

    if (event.dataTransfer) {
      const files = event.dataTransfer.files;
      if (onFilesAdded) {
        const array: any = fileListToArray(files);
        onFilesAdded(array);
      }
      setHighligh(false);
    }
  };

  return (
    <div
      className={highlight ? styles.Highlight : !disabled ? styles.Dropzone : styles.disabled}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{
        cursor: disabled ? 'default' : 'pointer',
      }}
    >
      <React.Fragment>
        <input
          ref={fileInputRef}
          className={styles.FileInput}
          type="file"
          multiple
          onChange={onFilesAdded}
          accept="application/pdf,image/*,.xlsx,.docx,.doc,.xls"
        />
        {documentsUpload && uploadReady ? (
          <div className={disabled ? styles.itemListDisabled : styles[`itemList_${webStyle}`]}>
            <span>{tKey('Archivos Adjuntados Correctamente')}</span>
            {documentsUpload.map(e => (
              <div className={styles.item} key={e.id}>
                <CheckIcon style={{ color: colorPalette.greenBubble }} />
                <span>{e.name}</span>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles[`uploadContainer_${webStyle}`]}>
            <CloudUploadIcon />
            <span style={{ color: colorPalette.secondary80 }}>
              <span className={styles[`here_${webStyle}`]}>{tKey('Click Aquí')}</span>{' '}
              {tKey('o Arrastra para subir archivos')}
            </span>
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
});

const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  uploadNewDocument: (file: File, name: string, actor: string): void => dispatch(newDocument(file, name, actor)),
  resetDocuments: (): void => dispatch(resetDocumentsToSend()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DropdownUpload);
