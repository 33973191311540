import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import {
  getNotificationsUpdate,
  markNotificationsAsRead,
  newMessage,
  removePendingTaskFromApi,
} from '../../api/notifications';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  getNotificationsUpdateKo,
  getNotificationsUpdateOk,
  markNotificationsAsReadKo,
  markNotificationsAsReadOk,
  newMessageKo,
  newMessageOk,
  removePendingTaskKo,
  removePendingTaskOk,
} from './actions';
import { NotificationsConsts } from './action_types';
import {
  MarkNotificationsAsReadAction,
  NewMessageAction,
  NotificationsStartPollingAction,
  NotificationsUpdateAction,
  RemovePendingTaskAction,
} from './definitions';
import { selectVersion } from './selectors';

export function delay(duration: number) {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getNotificationsUpdateSaga(): Generator<any, void, any> {
  try {
    const version = yield select(selectVersion);
    const info = yield getNotificationsUpdate(version);
    yield put(getNotificationsUpdateOk(info.data));
  } catch (e) {
    yield put(getNotificationsUpdateKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* watchNotificationsSaga(action?: NotificationsStartPollingAction): Generator<any, void, any> {
  let polling = false;
  while (true) {
    if (!polling) {
      polling = true;
      try {
        const version = yield select(selectVersion);
        const info = yield getNotificationsUpdate(version);
        yield put(getNotificationsUpdateOk(info.data));
      } catch (e) {
        yield put(getNotificationsUpdateKo(e));
      }
      polling = false;
      yield call(delay, action?.interval ? action.interval : 45000);
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* markNotificationsAsReadSaga(action: MarkNotificationsAsReadAction): Generator<any, void, any> {
  try {
    yield markNotificationsAsRead(action.notifications);
    yield put(markNotificationsAsReadOk(action.notifications));
  } catch (e) {
    yield put(markNotificationsAsReadKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* newMessageSaga(action: NewMessageAction): Generator<any, void, any> {
  try {
    const { data } = (yield newMessage(action.new_message)) as Await<ReturnType<typeof newMessage>>;
    yield put(newMessageOk(data, action.new_message.isNewThread));
    if (action.new_message.isNewThread) {
      yield put(showSnackbar('Mensaje enviado correctamente', 'success', `/mensajes/conversacion/${data.id}`, 0));
    } else {
      yield put(showSnackbar('Mensaje enviado correctamente', 'success'));
    }
  } catch (e) {
    yield put(showSnackbar('No se ha podido enviar el mensaje', 'error'));
    yield put(newMessageKo(e));
  }
}

function* removePendingTaskSaga(action: RemovePendingTaskAction): Generator<any, void, any> {
  try {
    yield removePendingTaskFromApi(action.notification_id);
    yield put(removePendingTaskOk(action.notification_id));
    yield put(showSnackbar('Notificación eliminada correctamente', 'success'));
  } catch (e) {
    yield put(showSnackbar('No se ha podido eliminar la notificación', 'error'));
    yield put(removePendingTaskKo(e));
  }
}

export default [
  takeLatest<NotificationsUpdateAction>(NotificationsConsts.NOTIFICATIONS_UPDATE, getNotificationsUpdateSaga),
  takeLatest<MarkNotificationsAsReadAction>(NotificationsConsts.READ_NOTIFICATION, markNotificationsAsReadSaga),
  takeLatest<NewMessageAction>(NotificationsConsts.NEW_MESSAGE, newMessageSaga),
  takeLatest<NotificationsStartPollingAction>(NotificationsConsts.NOTIFICATIONS_START_POLLING, watchNotificationsSaga),
  takeLatest<RemovePendingTaskAction>(NotificationsConsts.REMOVE_PENDING_TASK, removePendingTaskSaga),
];
