import { Action } from 'redux';
import { APIError } from '../../api/api';
import { CodeNamePair, IdNamePair } from '../common/definitions';
import { FeedbackAPIAction } from '../FeedbackAPI/definitions';
import { InternalProjectTemplatesConst } from './action_types';
import { InternalProjectTypeData } from '../../types/InternalProjects/InternalProjectTypes';

/************************** GENERAL DEFINITIONS **************************/
export interface InternalProjectTemplate {
  id: number;
  template_name: string;
  subarea_internal_project_type_id: number;
  area_id: number;
  area_name: string;
  subarea_id: number | string;
  subarea_name: string;
  has_daily_follow_up: number;
  has_survey: number;
  has_enrollments: number;
  is_published: boolean;
}

export interface AreaData {
  id: number;
  name: string;
}

export interface SubareaData {
  id: number;
  name: string;
  area_id: number;
}

export interface InternalProjectTemplatesMetadata {
  types: InternalProjectTypeData[];
  subareas: SubareaData[];
  followUpTypes: CodeNamePair[];
}

export interface InternalProjectTemplateRole {
  roles: IdNamePair[];
}

export type RoleWithPermission = IdNamePair & { allow_edit: boolean };

export interface GeneralFormData {
  id: number;
  name: string;
  section: string;
  internal_project_template_id: number;
  role_names: string;
  roles: RoleWithPermission[];
}

export interface InternalProjectTemplateGeneralForm {
  general_forms: GeneralFormData[];
}

export type FollowUpData = GeneralFormData & { type: string };

export interface InternalProjectTemplateFollowUp {
  follow_ups: FollowUpData[];
}

export type InternalProjectTemplateData = InternalProjectTemplate &
  InternalProjectTemplateRole &
  InternalProjectTemplateGeneralForm &
  InternalProjectTemplateFollowUp;

export const initialState = {
  loading: false,
  loadingAlt: false,
  error: null as string | null,
  internalProjectTemplates: [] as InternalProjectTemplate[],
  internalProjectTemplatesMetadata: {
    subareas: [],
    types: [],
    followUpTypes: [],
  } as InternalProjectTemplatesMetadata,
  internalProjectTemplateData: {} as InternalProjectTemplateData,
};

/************************** ACTIONS DEFINITIONS **************************/
export interface GenericKo extends Action {
  type: InternalProjectTemplatesConst.GENERIC_KO;
  apiError: APIError;
}

export interface GetInternalProjectTemplatesMetadataOk extends Action {
  type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_METADATA_OK;
  internalProjectTemplatesMetadata: InternalProjectTemplatesMetadata;
}

export interface GetInternalProjectTemplatesInfo extends Action {
  type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO;
}

export interface GetInternalProjectTemplatesInfoOk extends Action {
  type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO_OK;
  internalProjectTemplatesData: InternalProjectTemplate[];
}

export interface GetInternalProjectTemplatesInfoKo extends Action {
  type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO_KO;
  error: string;
}

export interface SetInternalProjectTemplate extends Action {
  type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE;
  internalProjectTemplates: InternalProjectTemplate[];
}

export interface DeleteInternalProjectTemplate extends Action {
  type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE;
  internalProjectTemplateId: number;
}

export interface DeleteInternalProjectTemplateOk extends Action {
  type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_OK;
  internalProjectTemplates: InternalProjectTemplate[];
}

export interface DeleteInternalProjectTemplateKo extends Action {
  type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_KO;
  error: string;
}

export interface GetInternalProjectTemplateInfo extends Action {
  type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO;
  id: number;
}

export interface GetInternalProjectTemplateInfoOk extends Action {
  type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO_OK;
  internalProjectTemplateData: InternalProjectTemplateData;
}

export interface GetInternalProjectTemplateInfoKo extends Action {
  type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO_KO;
  error: string;
}

export interface SetInternalProjectTemplateData extends Action {
  type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_DATA;
  key: string;
  value: string | number | boolean;
}

export interface UpdateInternalProjectTemplate extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE;
  id: number;
  data: InternalProjectTemplateData;
}

export interface UpdateInternalProjectTemplateOk extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_OK;
  internalProjectTemplateData: InternalProjectTemplateData;
}

export interface UpdateInternalProjectTemplateKo extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_KO;
  error: string;
}

export interface SetInternalProjectTemplateRoleData extends Action {
  type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA;
  role: IdNamePair;
}

export interface RemoveInternalProjectTemplateRoleData extends Action {
  type: InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA;
  roleId: number;
}

export interface SetInternalProjectTemplateGeneralFormRoleData extends Action {
  type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA;
  formId: number;
  role: RoleWithPermission;
}

export interface RemoveInternalProjectTemplateGeneralFormRoleData extends Action {
  type: InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA;
  formId: number;
  roleId: number;
}

export interface UpdateInternalProjectTemplateGeneralFormRoles extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES;
  internalProjectTemplateId: number;
  formId: number;
  roles: RoleWithPermission[];
}

export interface UpdateInternalProjectTemplateGeneralFormRolesOk extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_OK;
  formId: number;
  roles: RoleWithPermission[];
}

export interface UpdateInternalProjectTemplateGeneralFormRolesKo extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_KO;
  error: string;
}

export interface CreateInternalProjectTemplateFollowUp extends Action {
  type: InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP;
  followUp: FollowUpData;
}

export interface CreateInternalProjectTemplateFollowUpOk extends Action {
  type: InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK;
  followUp: FollowUpData[];
}

export interface CreateInternalProjectTemplateFollowUpKo extends Action {
  type: InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO;
  error: string;
}

export interface SetInternalProjectTemplateFollowUpRoleData extends Action {
  type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA;
  followUpId: number;
  role: RoleWithPermission;
}

export interface RemoveInternalProjectTemplateFollowUpRoleData extends Action {
  type: InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA;
  followUpId: number;
  roleId: number;
}

export interface UpdateInternalProjectTemplateFollowUpRoles extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES;
  internalProjectTemplateId: number;
  followUpId: number;
  roles: RoleWithPermission[];
}

export interface UpdateInternalProjectTemplateFollowUpRolesOk extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_OK;
  followUpId: number;
  roles: RoleWithPermission[];
}

export interface UpdateInternalProjectTemplateFollowUpRolesKo extends Action {
  type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_KO;
  error: string;
}

export interface DeleteInternalProjectTemplateFollowUp extends Action {
  type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP;
  internalProjectTemplateId: number;
  followUpId: number;
}

export interface DeleteInternalProjectTemplateFollowUpOk extends Action {
  type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK;
  internalProjectTemplateFollowUps: FollowUpData[];
}

export interface DeleteInternalProjectTemplateFollowUpKo extends Action {
  type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO;
  error: string;
}

export interface PublishInternalProjectTemplate extends Action {
  type: InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE;
  id: number;
}

export interface PublishInternalProjectTemplateOk extends Action {
  type: InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE_OK;
}

export interface PublishInternalProjectTemplateKo extends Action {
  type: InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE_KO;
  error: string;
}

export type InternalProjectTemplateDataAction =
  | GenericKo
  | GetInternalProjectTemplatesMetadataOk
  | GetInternalProjectTemplatesInfo
  | GetInternalProjectTemplatesInfoOk
  | GetInternalProjectTemplatesInfoKo
  | SetInternalProjectTemplate
  | DeleteInternalProjectTemplate
  | DeleteInternalProjectTemplateOk
  | DeleteInternalProjectTemplateKo
  | GetInternalProjectTemplateInfo
  | GetInternalProjectTemplateInfoOk
  | GetInternalProjectTemplateInfoKo
  | SetInternalProjectTemplateData
  | UpdateInternalProjectTemplate
  | UpdateInternalProjectTemplateOk
  | UpdateInternalProjectTemplateKo
  | SetInternalProjectTemplateRoleData
  | RemoveInternalProjectTemplateRoleData
  | SetInternalProjectTemplateGeneralFormRoleData
  | RemoveInternalProjectTemplateGeneralFormRoleData
  | UpdateInternalProjectTemplateGeneralFormRoles
  | UpdateInternalProjectTemplateGeneralFormRolesOk
  | UpdateInternalProjectTemplateGeneralFormRolesKo
  | CreateInternalProjectTemplateFollowUp
  | CreateInternalProjectTemplateFollowUpOk
  | CreateInternalProjectTemplateFollowUpKo
  | SetInternalProjectTemplateFollowUpRoleData
  | RemoveInternalProjectTemplateFollowUpRoleData
  | UpdateInternalProjectTemplateFollowUpRoles
  | UpdateInternalProjectTemplateFollowUpRolesOk
  | UpdateInternalProjectTemplateFollowUpRolesKo
  | DeleteInternalProjectTemplateFollowUp
  | DeleteInternalProjectTemplateFollowUpOk
  | DeleteInternalProjectTemplateFollowUpKo
  | PublishInternalProjectTemplate
  | PublishInternalProjectTemplateOk
  | PublishInternalProjectTemplateKo;

export type InternalProjectTemplateDispatch = (action: InternalProjectTemplateDataAction | FeedbackAPIAction) => void;
