import { getScholarshipPaymentByYears } from '../../../../api/Entity/entity';

export async function getScholarshipByYear(dataRequest: { id: number; academic_year: string }) {
  try {
    const { data } = await getScholarshipPaymentByYears(dataRequest);
    return data;
  } catch (error) {
    throw Error(error);
  }
}
