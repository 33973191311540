import React from 'react';
import styles from './actionsMenu.module.scss';

interface Props {
  children: JSX.Element | JSX.Element[] | string;
  style?: string;
}
const OptionsWrapper: React.FC<Props> = ({ children, style }) => (
  <div className={style ? `${styles.options_wrapper} ${style}` : styles.options_wrapper}>{children}</div>
);
export default OptionsWrapper;
