import { Button, Divider, MenuItem } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/es';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { match } from 'react-router-dom';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import Layout from '../../components/Layout/Layout';
import Loading from '../../components/Loading/Loading';
import AvatarImage from '../../components/MenuImageRound/AvatarImage';
import SelectController from '../../components/Select/SelectController';
import Title from '../../components/Title/Title';
import { tFormKey, tKey } from '../../helpers/translate';
import { AuthUserData } from '../../redux/auth/definitions';
import { selectUserInfo } from '../../redux/auth/selectors';
import { markNotificationsAsRead, newMessage, NewMessage } from '../../redux/notification/actions';
import {
  Notification,
  NotificationDispatch,
  NotificationsRead,
  ThreadItem,
} from '../../redux/notification/definitions';
import { selectIsLoading, selectNotificationsList, selectUserChannels } from '../../redux/notification/selectors';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import { NewMessageSchema } from '../../validations/formSchema';
import styles from './messageThread.module.scss';
import SubmenuNotifications from './SubmenuNotifications';

interface NewMessageFields {
  new_message: string;
  channel: string;
}

interface ThreadProps {
  loading: boolean;
  notifications: Notification | null;
  channels: string[];
  match?: match<{ id: string }>;
  newMessage: (new_message: NewMessage) => void;
  canIgo: boolean;
  userInfo: AuthUserData;
  markNotificationsAsRead: (notifications: NotificationsRead) => void;
}

const MessageThreadDetails: React.FC<ThreadProps> = ({
  match,
  loading,
  notifications,
  channels,
  newMessage,
  canIgo,
  userInfo,
  markNotificationsAsRead,
}) => {
  const selectMessageThread = () =>
    notifications?.message_threads.find(message => message.id.toString() === match?.params.id) || null;

  const [selectedMessageThread, setSelectedMessageThread] = useState(selectMessageThread());

  useEffect(() => {
    if (selectedMessageThread && !selectedMessageThread?.read) {
      markNotificationsAsRead({
        type: 'message_threads',
        notifications: [{ id: selectedMessageThread.id, read: true }],
      });
    }
  }, [selectedMessageThread]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedMessageThread(selectMessageThread());
  }, [notifications, match, setSelectedMessageThread]); // eslint-disable-line react-hooks/exhaustive-deps

  const { handleSubmit, errors, reset, clearError, control } = useForm<NewMessageFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: NewMessageSchema,
  });

  interface ThreadProps {
    thread_item: ThreadItem;
  }
  const Thread = ({ thread_item }: ThreadProps) => {
    const user = selectedMessageThread?.members.find(member => member.id === thread_item.from);
    return (
      <div className={styles.thread_wrapper}>
        {user?.avatar && <AvatarImage image={user.avatar} size="medium" />}
        <div className={styles.content}>
          <div>
            <span className={styles.user}>{user?.name}</span>
            {` · `}
            <span>
              {moment
                .unix(thread_item.date)
                .locale('es')
                // .lang('es')
                .calendar()}
            </span>
          </div>
          <span className={!thread_item.read ? styles.unread_item : undefined}>{thread_item.content}</span>
        </div>
      </div>
    );
  };

  const onSubmit = (data: NewMessageFields) => {
    if (selectedMessageThread) {
      newMessage({
        channel: selectedMessageThread.channel,
        message: data.new_message,
        external_user: selectedMessageThread.external_user.id,
        isNewThread: false,
      });
      clearError();
      reset();
    } else {
      newMessage({
        channel: data.channel,
        message: data.new_message,
        external_user: userInfo.user_id,
        isNewThread: true,
      });
      clearError();
      reset();
    }
  };

  return (
    <Layout leftSubmenu={<SubmenuNotifications selected="messages" />}>
      <Fragment>
        {loading && <Loading big />}
        {!loading && selectedMessageThread && (
          <Fragment>
            <div className={styles.header_wrapper}>
              <Title>{tKey('Mensajes')}</Title>
              <h2>#{selectedMessageThread.channel}</h2>
            </div>
            <Divider />
            <div className={styles.messages}>
              {selectedMessageThread.thread.map((thread, i) => (
                <Thread key={i} thread_item={thread} />
              ))}
            </div>
          </Fragment>
        )}
        {!loading && !selectedMessageThread && canIgo && (
          <div className={styles.channelSelector}>
            <SelectController
              name="channel"
              control={control}
              label={tKey('Canal')}
              size="50"
              errors={errors}
              schema={NewMessageSchema}
            >
              {channels.map(ch => (
                <MenuItem key={ch} value={ch}>
                  {ch}
                </MenuItem>
              ))}
            </SelectController>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form_wrapper}>
          <hr />
          <div className={styles.input_wrapper}>
            <TextInputController
              classes={{
                root: styles.textarea,
              }}
              disabled={loading}
              errors={errors}
              control={control}
              schema={NewMessageSchema}
              name="new_message"
              label={tFormKey('Nuevo mensaje')}
              multiline
              rows="2"
              rowsMax="8"
              fullWidth
            />
            <Button size="large" variant="contained" color="primary" type="submit" disabled={loading} disableElevation>
              {tKey('Enviar')}
            </Button>
          </div>
        </form>
      </Fragment>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  notifications: selectNotificationsList(state),
  channels: selectUserChannels(state),
  canIgo: selectUserCan(state)('action_create_message_thread'),
  userInfo: selectUserInfo(state),
});

const mapDispatchToProps = (dispatch: NotificationDispatch) => ({
  newMessage: (new_message: NewMessage): void => dispatch(newMessage(new_message)),
  markNotificationsAsRead: (notifications: NotificationsRead): void => dispatch(markNotificationsAsRead(notifications)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageThreadDetails);
