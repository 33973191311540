import { Button, MenuItem } from '@material-ui/core';
import React, { FocusEvent, useEffect, useState } from 'react';
import { FieldError, NestDataObject, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tFormKey } from '../../../helpers/translate';
import {
  createNewInvoiceDetail,
  editInvoiceDetail,
  getInvoiceDetail,
  invoiceDetailSetData,
} from '../../../redux/accounting/actions';
import { InvoiceDetail } from '../../../redux/accounting/definitions';
import { selectInvoiceDetail, selectValidationErrors } from '../../../redux/accounting/selectors';
import { CodeNamePair, TaxRetention } from '../../../redux/common/definitions';
import { selectExpenseAccounts, selectGenericMetadata, selectTaxes } from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from './invoiceDetailsForm.module.scss';
import { AllInvoiceDetailsSchema, BaseInvoiceDetailsSchema } from '../../../validations/formSchema';
import { GenericMetadata } from '../../../redux/metadata/definitions';

interface InvoiceDetailsProps {
  invoiceId: number;
  invoiceDetailId?: number;
  disableValues: boolean;
  loading: boolean;
  invoiceDetailData: InvoiceDetail;
  taxes: TaxRetention[];
  expenseAccounts: CodeNamePair[];
  validationErrors: APIValidationError | null;
  isNouscims: boolean;
  genericMetadata: GenericMetadata;
  allowDirectorInvoiceChanges: boolean;
  onSaved?: () => void;
  setData: (key: string, value: string | number) => void;
  getInvoiceDetail: (id: number) => void;
  createNewInvoiceDetail: (invoiceDetail: InvoiceDetail) => void;
  editInvoiceDetail: (invoiceDetail: InvoiceDetail) => void;
}

const InvoiceDetailsForm: React.FC<InvoiceDetailsProps> = ({
  invoiceId,
  invoiceDetailId,
  invoiceDetailData,
  loading,
  taxes,
  expenseAccounts,
  validationErrors,
  isNouscims,
  disableValues,
  allowDirectorInvoiceChanges,
  genericMetadata,
  setData,
  getInvoiceDetail,
  createNewInvoiceDetail,
  editInvoiceDetail,
  onSaved,
}) => {
  let schema = BaseInvoiceDetailsSchema;
  if (!disableValues && isNouscims) {
    schema = AllInvoiceDetailsSchema;
  }

  const { handleSubmit, errors, control, register, setError, clearError } = useForm<InvoiceDetail>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schema,
  });

  const [taxRetentionCodeErrors, setTaxRetentionCodeErrors] = useState({} as NestDataObject<InvoiceDetail, FieldError>);

  useEffect(() => {
    if (errors.tax_retention_code) {
      setTaxRetentionCodeErrors({
        ...errors,
        tax_retention_code: {
          ...errors.tax_retention_code,
          message: errors.tax_retention_code?.isManual ? '' : errors.tax_retention_code.message,
        },
      });
    } else {
      setTaxRetentionCodeErrors({ ...errors });
    }
  }, [errors.tax_retention_code]); // eslint-disable-line react-hooks/exhaustive-deps

  useValidatorAPI(validationErrors, setError);

  const {
    tax_retention_code,
    description,
    base_amount,
    tax_retention_percentage,
    tax_retention_amount,
    expense_account,
    center_cost_area_code,
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_project_code,
  } = invoiceDetailData;

  useEffect(() => {
    if (invoiceDetailId) {
      getInvoiceDetail(invoiceDetailId);
    }
  }, [getInvoiceDetail, invoiceDetailId]);

  const handleSelect = (e: any) => {
    const val = (taxes.find(tax => tax.code === e.target.value)?.percentage || 0) as number;
    const code = (taxes.find(tax => tax.code === e.target.value)?.code || '') as string;
    setData('tax_retention_percentage', val);
    setData('tax_retention_code', code);
  };

  const Save = () => {
    clearError();
    if (invoiceDetailId) {
      editInvoiceDetail(invoiceDetailData);
    } else {
      createNewInvoiceDetail({ ...invoiceDetailData, invoice_id: invoiceId });
    }
    if (onSaved) {
      onSaved();
    }
  };

  const saveButton = (
    <Button variant="outlined" color="primary" style={{ marginLeft: 'auto', width: '120px' }} type="submit">
      {tFormKey('Guardar')}
    </Button>
  );

  let taxRetentionError;

  if (errors && errors.tax_retention_code?.isManual) {
    taxRetentionError = <label className={styles.helperText}>{errors.tax_retention_code.message}</label>;
  }

  if (loading) return <Loading big />;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const renderAccountingInfo = () => {
    if (isNouscims || allowDirectorInvoiceChanges) {
      const { channels, delegations, areas, projects } = genericMetadata;
      return (
        <>
          <SelectController
            size={disableValues ? '50' : '100'}
            control={control}
            errors={errors}
            schema={schema}
            defaultValue={expense_account}
            label={tFormKey('Cuenta de gasto')}
            name="expense_account"
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              setData(e.target.name, e.target.value);
            }}
          >
            {expenseAccounts.map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.name}
              </MenuItem>
            ))}
          </SelectController>
          <SelectController
            size="20"
            control={control}
            errors={errors}
            schema={schema}
            ref={register}
            defaultValue={center_cost_channel_code}
            name="center_cost_channel_code"
            label={tFormKey('Canal')}
            onClick={e => handleSelectChange(e.target)}
          >
            {channels.map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.code}
              </MenuItem>
            ))}
          </SelectController>
          <SelectController
            size="20"
            control={control}
            errors={errors}
            schema={schema}
            ref={register}
            defaultValue={center_cost_delegation_code}
            name="center_cost_delegation_code"
            label={tFormKey('Delegación')}
            onClick={e => handleSelectChange(e.target)}
          >
            {delegations
              ?.filter(e => e.channel_code === center_cost_channel_code || e.channel_code === null)
              .map(type => (
                <MenuItem key={type.code} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
          </SelectController>
          <SelectController
            size="20"
            control={control}
            errors={errors}
            schema={schema}
            ref={register}
            defaultValue={center_cost_area_code}
            name="center_cost_area_code"
            label={tFormKey('Área')}
            onClick={e => handleSelectChange(e.target)}
          >
            {areas
              ?.filter(e => e.delegation_code === center_cost_delegation_code || e.delegation_code === null)
              .map(type => (
                <MenuItem key={type.code || '0'} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
          </SelectController>
          <SelectController
            size="20"
            control={control}
            errors={errors}
            schema={schema}
            ref={register}
            defaultValue={center_cost_project_code || '0'}
            name="center_cost_project_code"
            label={tFormKey('Proyecto')}
            onClick={e => handleSelectChange(e.target)}
          >
            {projects
              ?.filter(e => e.area_code === center_cost_area_code || !e.area_code)
              .map(type => (
                <MenuItem key={type.code || '0'} value={type.code || '0'}>
                  {type.name}
                </MenuItem>
              ))}
          </SelectController>
        </>
      );
    }
    return undefined;
  };

  return (
    <div className={styles.invoiceDetailsContainer}>
      <form onSubmit={handleSubmit(Save)}>
        <FormContainer title="">
          <TextInputController
            control={control}
            errors={errors}
            type="text"
            defaultValue={description}
            schema={schema}
            label={tFormKey('Concepto')}
            name="description"
            size={disableValues ? '50' : '100'}
            onBlur={(e: FocusEvent<HTMLInputElement>) => {
              setData(e.target.name, e.target.value);
            }}
          />
          {!disableValues && (
            <>
              <TextInputController
                control={control}
                errors={errors}
                type="number"
                schema={schema}
                label={tFormKey('Base')}
                name="base_amount"
                defaultValue={base_amount || 0}
                size="20"
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                  setData('base_amount', e.target.value);
                }}
                disabled={disableValues}
              />
              <SelectController
                size="20"
                control={control}
                errors={taxRetentionCodeErrors}
                schema={schema}
                defaultValue={tax_retention_code}
                label={tFormKey('Tipo de IVA')}
                name="tax_retention_code"
                onClick={handleSelect}
                disabled={disableValues}
              >
                {taxes.map(type => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name}
                  </MenuItem>
                ))}
              </SelectController>
              <TextInput
                type="number"
                size="20"
                name="tax_retention_percentage"
                label={tFormKey('Porcentaje')}
                value={tax_retention_percentage || 0}
                disabled
              />
              <TextInput
                type="number"
                size="20"
                name="tax_retention_amount"
                label={tFormKey('Importe de IVA')}
                value={tax_retention_amount || 0}
                disabled
              />
            </>
          )}
          {renderAccountingInfo()}
          {taxRetentionError}
          {saveButton}
        </FormContainer>
      </form>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.accountingReducer.loadingModal,
  invoiceDetailData: selectInvoiceDetail(state),
  validationErrors: selectValidationErrors(state),
  taxes: selectTaxes(state),
  expenseAccounts: selectExpenseAccounts(state),
  isNouscims: selectUserCan(state)('action_nouscims'),
  allowDirectorInvoiceChanges: selectUserCan(state)('allowDirectorInvoiceChanges'),
  genericMetadata: selectGenericMetadata(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(invoiceDetailSetData(key, value)),
  createNewInvoiceDetail: (invoiceDetail: InvoiceDetail) => dispatch(createNewInvoiceDetail(invoiceDetail)),
  editInvoiceDetail: (data: InvoiceDetail) => dispatch(editInvoiceDetail(data)),
  getInvoiceDetail: (id: number) => dispatch(getInvoiceDetail(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetailsForm);
