import { SendToSAGEFields, SendToSAGEFieldsResponse } from '../../redux/accounting/definitions';
import { PaymentDatesReplace, PaymentDatesReplaceResult } from '../../redux/scholarship/definitions';
import { apiBaseUrl, apiCall, apiCallBlob } from '../api';

const baseURL = apiBaseUrl;

export type DatePayments = {
  entity?: { dateSearch: string; dateReplace: string };
  institute?: { dateSearch: string; dateReplace: string };
  replace_paid?: boolean;
};

export const exportPaymentFromApi = async (sageData: SendToSAGEFields, dateToSend: string | null): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ ...sageData, dateToSend }),
  };

  return await apiCallBlob(`${baseURL}/payment/export`, init);
};

export const sendToSageFromApi = async (
  sageData: SendToSAGEFields,
  dateToSend: string | null,
): Promise<SendToSAGEFieldsResponse> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ ...sageData, dateToSend }),
  };

  const { data } = await apiCall<{ data: SendToSAGEFieldsResponse }>(`${baseURL}/payment/export_sage`, init);
  return data;
};

export const replacePaymentsDatesFromApi = async (
  paymentDates: PaymentDatesReplace,
): Promise<PaymentDatesReplaceResult> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(paymentDates),
  };

  const { data } = await apiCall<{ data: PaymentDatesReplaceResult }>(`${baseURL}/configuration/payments_dates`, init);
  return data;
};

export const paymentsDates = async (date: DatePayments): Promise<{ data: { entity: number; institute: number } }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(date),
  };

  return apiCall<{ data: { entity: number; institute: number } }>(`${baseURL}/configuration/payments_dates`, init);
};
