import { ManualFieldError, ValidateResult } from 'react-hook-form';
import * as yup from 'yup';

export const checkValidation = async (
  schema: yup.ObjectSchema<yup.Shape<object, any>>,
  values: object,
  setError: (
    name: ManualFieldError<Record<string, any>>[],
    type?: Record<string, ValidateResult> | string,
    message?: string | undefined,
  ) => void,
): Promise<boolean> => {
  return schema
    .validate(values, { abortEarly: false })
    .then(() => true)
    .catch((err: any) => {
      err.inner.forEach((error: Record<string, any>) => {
        setError(error.path, error.type, error.message);
      });
      return false;
    });
};
