import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { APIError } from '../../../api/api';
import LayoutLogin from '../../../components/Layout/LayoutLogin';
import Loading from '../../../components/Loading/Loading';
import { LoginFields, LoginForm } from '../../../components/Login/LoginForm/LoginForm';
import { login } from '../../../redux/auth/actions';
import { AuthUserData } from '../../../redux/auth/definitions';
import { selectError, selectIsLoading, selectUserInfo } from '../../../redux/auth/selectors';
import { getConnectionUrl, getUserRegistrationData } from '../../../redux/event/actions';
import { RegistrationIntervalData, UserRegistrationData } from '../../../redux/event/definitions';
import { selectConnectionUrl, selectUserRegistrationData } from '../../../redux/event/selectors';
import { AppState } from '../../../redux/root-reducer';
import { getProfile } from '../../../redux/user/actions';
import { UserData } from '../../../redux/user/definitions';
import { selectProfile } from '../../../redux/user/selectors';

interface TypeFormProps extends RouteComponentProps<{ type: string; id: string }> {
  userInfo?: AuthUserData;
  userProfile?: UserData;
  registrationData: UserRegistrationData;
  error: APIError | null;
  loading: boolean;
  registrationIntervalData: RegistrationIntervalData;
  getConnectionUrl: (id: number, type: string) => void;
  login: (data: LoginFields) => void;
  getProfileData: () => void;
  getUserRegistrationData: (eventId: number, userId: number) => void;
}

const EventTypeForm: React.FC<TypeFormProps> = ({
  match,
  userInfo,
  userProfile,
  registrationData,
  error,
  loading,
  registrationIntervalData,
  getConnectionUrl,
  login,
  getProfileData,
  getUserRegistrationData,
}) => {
  const {
    params: { type, id },
  } = match;
  useEffect(() => {
    if (userInfo && userInfo.email) {
      getProfileData();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.email]);

  useEffect(() => {
    if (userProfile && userProfile.user_id) {
      getUserRegistrationData(+id, userProfile.user_id);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.user_id]);

  const typeMap: {
    [key: string]: string;
  } = {
    servicios: 'services',
    eventos: 'events',
  };

  useEffect(() => {
    getConnectionUrl(+id, typeMap[type]);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    userProfile &&
    userProfile.email &&
    registrationIntervalData &&
    registrationIntervalData.url &&
    registrationData &&
    'entity' in registrationData
  ) {
    const fields = ['name', 'surname', 'document_number', 'city', 'phone', 'email', 'document_number', 'city', 'phone'];

    const registrationFields = ['entity', 'academic_year', 'academy', 'education_type', 'education_description'];

    const userString = fields.map(field => 'h' + field + '=' + userProfile[field]).join('&');
    const registrationString = registrationFields.map(field => 'h' + field + '=' + registrationData[field]).join('&');

    let birthdatestring = null;

    if (userProfile.birthdate != null) {
      const birthdate = new Date(+userProfile.birthdate * 1000);
      birthdatestring = birthdate.getDate() + '-' + birthdate.getMonth() + '-' + birthdate.getFullYear();
    }

    window.location.replace(
      `${registrationIntervalData.url}#hid=${id}&huser_id=${userProfile.id}&horigin=platform&${userString}&${registrationString}&hbirth_date=${birthdatestring}`,
    );
  }

  if (userInfo && userInfo.email) {
    return (
      <LayoutLogin subtitle="Accediendo al evento">
        <Loading />
      </LayoutLogin>
    );
  } else {
    return (
      <LayoutLogin subtitle="Acceso a eventos de la plataforma">
        <LoginForm error={error} login={login} loading={loading} />
      </LayoutLogin>
    );
  }
};

const mapStateToProps = (state: AppState) => ({
  userInfo: selectUserInfo(state),
  userProfile: selectProfile(state),
  loading: selectIsLoading(state),
  error: selectError(state),
  registrationIntervalData: selectConnectionUrl(state),
  registrationData: selectUserRegistrationData(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  login: (data: LoginFields): void => dispatch(login(data)),
  getProfileData: (): void => dispatch(getProfile()),
  getConnectionUrl: (id: number, type: string): void => dispatch(getConnectionUrl(id, type)),
  getUserRegistrationData: (eventId: number, userId: number): void =>
    dispatch(getUserRegistrationData(eventId, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventTypeForm);
