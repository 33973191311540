import { AppState } from '../../../root-reducer';
import { GetFamilyIncomesState } from './definitions';
import { FamilyIncome } from '../FamilyIncome';
import { selectScholarship } from '../../selectors';

const selectFamilyIncomes = (state: AppState): GetFamilyIncomesState => selectScholarship(state).familyIncomes;
export const selectFamilyIncomesLoading = (state: AppState): boolean => selectFamilyIncomes(state).loading;

export const selectFamilyIncomesError = (state: AppState): string | null => selectFamilyIncomes(state).error;

export const selectFamilyIncomesList = (state: AppState): FamilyIncome[] => selectFamilyIncomes(state).list;
