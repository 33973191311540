import { ButtonProps, MenuItem } from '@material-ui/core';
import { Payment } from '@material-ui/icons';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { paySage as paySageApi, retrievePayment, setScholarshipPaymentByYears } from '../../../../api/Entity/entity';
import FormContainer from '../../../../components/Forms/FormContainer';
import Loading from '../../../../components/Loading/Loading';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import SelectController from '../../../../components/Select/SelectController';
import TableEditableRow, { ColumnTableProps } from '../../../../components/Tables/TableEditableRow/TableEditableRow';
import { useApiFetch } from '../../../../helpers/customHooks/useApiFetch';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import { debounce } from '../../../../helpers/debounce';
import errorMessage from '../../../../helpers/errorMessage';
import { tErrorKey, tKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectAcademicYears, selectCurrentAcademicYear } from '../../../../redux/metadata/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { PaymentInfo } from '../../../../redux/project/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { EntityScholarshipPaymentError } from '../../../../redux/scholarship/definitions';
import { JointEntitySchema } from '../../../../validations/formSchema';
import PaymentModal from '../../../Project/Modal/PaymentModal/PaymentModal';
import styles from '../projectForm.module.scss';
import { getScholarshipByYear } from './services';

interface PaymentProps {
  id: number;
  academicYears: string[];
  currentAcademicYear: string;
  leftButtons?: boolean;
  buttons?: boolean;
  buttonActionPay: ButtonProps;
  buttonActionCalendar: ButtonProps;
  buttonActionSave: ButtonProps;
  disabledCourse: boolean;
  disabled?: boolean;
  setPaymentInfo: any;
  paymentInfo: any;
  allowPaymentAdjustment: boolean;
  data: any[];
  setData: (data: any) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

export const createData = (data: any) =>
  data.map((element: any, index: number) => {
    return {
      ...element,
      id: index,
      isEditMode: false,
    };
  });

interface Payment {
  course: string;
}

const PaymentProps: React.FC<PaymentProps> = ({
  id,
  disabled,
  currentAcademicYear,
  academicYears,
  buttonActionPay,
  buttonActionCalendar,
  buttonActionSave,
  allowPaymentAdjustment,
  setPaymentInfo,
  paymentInfo,
  data,
  setData,
  showSnackbar,
}) => {
  const [payModal, setPayModal] = useState({ view: false, modal: {} as PaymentInfo });
  const [errorsModal, setErrorsModal] = useState<{ view: boolean; errors: EntityScholarshipPaymentError[] }>({
    view: false,
    errors: [],
  });
  const { errors, control, reset, watch, handleSubmit } = useForm<Payment>({
    mode: 'onSubmit',
    submitFocusError: true,
  });

  const getAlumnList = async (course: string) => {
    try {
      if (!course) return;
      const { payments, payment_available } = await getScholarshipByYear({ id, academic_year: course });

      setData(createData(payments));
      setPaymentInfo(payment_available);
    } catch (error) {
      setData([{}]);
    }
  };
  const fetchGetPayment = async () => {
    try {
      if (!academicYears.length) return;

      const { payments, payment_available } = await getScholarshipByYear({ id, academic_year: currentAcademicYear });
      setData(createData(payments));
      setPaymentInfo(payment_available);
      reset({ course: currentAcademicYear });
    } catch (error) {
      setData([{}]);
    }
  };

  const controlPrice = useCallback(
    debounce(({ value, name, row, rows }: any) => {
      if (!value.length) return;
      if (!+value) {
        showSnackbar(tErrorKey('Este es un campo numérico.'), 'error', undefined, 2500);
        return;
      }
      let total: string | number = 0;
      if (name === 'first_payment_amount') total = +value + +row.second_payment_amount + +row.third_payment_amount;
      if (name === 'second_payment_amount') total = +row.first_payment_amount + +value + +row.third_payment_amount;
      if (name === 'third_payment_amount') total = +row.first_payment_amount + +row.second_payment_amount + +value;
      if (name === 'fourth_payment_amount')
        total = +row.first_payment_amount + +row.second_payment_amount + +row.third_payment_amount + +value;
      total = total.toFixed(2);

      if (+total > row.total_price) {
        showSnackbar(tErrorKey('La suma total de los pagos es superior al importe.'), 'error', undefined, 1500);
      }
      if (+total < row.total_price) {
        showSnackbar(tErrorKey('La suma total de los pagos es inferior al importe.'), 'error', undefined, 1500);
      }
    }, 800),
    [],
  );

  const controlDate = ({ value, name, row }: any) => {
    let control = false;
    if (name === 'first_payment_date') control = value > row.second_payment_date;
    if (name === 'second_payment_date') control = value > row.third_payment_date || value < row.first_payment_date;
    if (name === 'third_payment_date') control = value > row.fourth_payment_date || value < row.second_payment_date;
    if (name === 'fourth_payment_date') control = value < row.third_payment_date;
    if (!control) return;
    showSnackbar(tErrorKey('FechaPagoSuperior'), 'error', undefined, 2500);
  };

  const paymentModal = async () => {
    try {
      const { data } = await retrievePayment({ id, academic_year: watch('course') });
      setPayModal({ view: true, modal: data });
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const paySage = async () => {
    try {
      const data = {
        id,
        account_number: payModal.modal.account_number,
        academic_year: watch('course'),
        payment_date: payModal.modal.payment_date,
      };
      const {
        data: { payments, payment_available },
      } = await paySageApi(data);
      setData(createData(payments));
      setPaymentInfo(payment_available);
      showSnackbar(tKey('Pago realizado'), 'success', undefined, 1500);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const setDate = (target: any) => {
    getAlumnList(target.value);
  };

  const upgradeCalendar = async () => {
    try {
      const presentData = watch('course');
      const {
        data: { payment_available, success, errors },
      } = await setScholarshipPaymentByYears(id, data, presentData);
      setPaymentInfo(payment_available);
      setData(createData(success));
      if (errors.length) {
        setErrorsModal({ view: true, errors });
        return;
      }
      showSnackbar(tKey('Calendario actualizado'), 'success', undefined, 1500);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
    getAlumnList(watch('course'));
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { loading } = useApiFetch(fetchGetPayment, id, () => {});

  useButtonAction(buttonActionPay, handleSubmit(paymentModal), {
    disabled: true,
  });
  useButtonAction(buttonActionCalendar, handleSubmit(upgradeCalendar), {
    disabled: disabled,
  });

  useButtonAction(buttonActionSave, undefined, {
    hidden: true,
  });

  const colorSelect = (can_pay: boolean, entity_scholarship_payments: number | null) => {
    const esp = entity_scholarship_payments ? entity_scholarship_payments.toString() : '';
    if (esp === '-1') {
      return 'orange';
    } else if (!can_pay) {
      return '#00000074';
    }
  };

  const colorValueSelect = (
    value: string | number | null,
    can_pay: boolean,
    entity_scholarship_payments: number | null,
  ) => {
    const green = 'green';
    const esp = entity_scholarship_payments ? entity_scholarship_payments.toString() : '';
    if (!!value) {
      if (esp === '-1') {
        return 'orange';
      } else if (!can_pay) {
        return '#00800074';
      }
      return green;
    }
    return colorSelect(can_pay, entity_scholarship_payments);
  };

  const disabledSelect = (value: string | number | null, can_pay: boolean) => {
    if (disabled) return disabled;

    const notSigned = !can_pay;
    if (notSigned) return notSigned;
    return !!value;
  };

  const columns = [
    {
      title: tKey('Nombre'),
      align: 'left',
      name: 'name',
      editable: 'never',
      color: data => colorSelect(data.can_pay, data.entity_scholarship_payments),
    },
    {
      title: tKey('Importe'),
      name: 'total_price',
      editable: 'never',
      modal: [
        { title: tKey('Valor de matrícula'), name: 'enrollment_confirm', type: 'price' },
        { title: tKey('Valor total mensualidades'), name: 'monthly_price_confirm', type: 'price' },
        { title: tKey('Valor total material'), name: 'materials_confirm', type: 'price' },
        { title: tKey('Transporte'), name: 'transport_price_confirm', type: 'price' },
        { title: tKey('Otros'), name: 'other_costs_confirm', type: 'price' },
        { title: tKey('Ajustes de pago'), name: 'adjustment_amount', type: 'price' },
        { title: tKey('Aportación del/la joven'), name: 'young_contrib_confirm', type: 'price' },
        { title: tKey('Aportación beca MEC'), name: 'mec_confirm', type: 'price' },
        { title: tKey('Familia Profesional'), name: 'professional_group_name', size: 'large' },
      ],

      type: 'price',
      color: data => colorSelect(data.can_pay, data.entity_scholarship_payments),
    },
    {
      title: tKey('Fecha 1'),
      name: 'first_payment_date',
      type: 'Fecha',
      cb: controlDate,
      color: data => colorValueSelect(data.first_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.first_payment_done_date, data.can_pay),
    },
    {
      title: tKey('Pago 1'),
      name: 'first_payment_amount',
      type: 'price',
      cb: controlPrice,
      color: data => colorValueSelect(data.first_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.first_payment_done_date, data.can_pay),
    },
    {
      title: tKey('Fecha 2'),
      name: 'second_payment_date',
      type: 'Fecha',
      cb: controlDate,
      color: data => colorValueSelect(data.second_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.second_payment_done_date, data.can_pay),
    },
    {
      title: tKey('Pago 2'),
      type: 'price',
      name: 'second_payment_amount',
      cb: controlPrice,
      color: data => colorValueSelect(data.second_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.second_payment_done_date, data.can_pay),
    },
    {
      title: tKey('Fecha 3'),
      name: 'third_payment_date',
      type: 'Fecha',
      cb: controlDate,
      color: data => colorValueSelect(data.third_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.third_payment_done_date, data.can_pay),
    },
    {
      title: tKey('Pago 3'),
      type: 'price',
      name: 'third_payment_amount',
      cb: controlPrice,
      color: data => colorValueSelect(data.third_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.third_payment_done_date, data.can_pay),
    },
    {
      title: tKey('Fecha 4'),
      name: 'fourth_payment_date',
      type: 'Fecha',
      cb: controlDate,
      color: data => colorValueSelect(data.fourth_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.fourth_payment_done_date, data.can_pay),
    },
    {
      title: tKey('Pago 4'),
      type: 'price',
      name: 'fourth_payment_amount',
      cb: controlPrice,
      color: data => colorValueSelect(data.fourth_payment_done_date, data.can_pay, data.entity_scholarship_payments),
      disabled: data => disabledSelect(data.fourth_payment_done_date, data.can_pay),
    },
  ] as ColumnTableProps[];

  const options = {
    filter: true,
    fixedColumn: {
      num: 1,
      left: 110,
    },
  };

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <div className={styles.form_payments}>
          {paymentInfo && <MessageInfo>{paymentInfo}</MessageInfo>}
          <FormContainer space title={tKey('Pagos de becas')}>
            <SelectController
              name="course"
              control={control}
              errors={errors}
              schema={JointEntitySchema}
              label={tKey('Curso')}
              size="20"
              defaultValue={watch('course')}
              onClick={e => setDate(e.target)}
            >
              {academicYears.map(course => (
                <MenuItem key={course} value={course}>
                  {course}
                </MenuItem>
              ))}
            </SelectController>
          </FormContainer>
          <TableEditableRow
            title={tKey('Lista de alumnos')}
            columns={columns}
            rows={data}
            setRows={setData}
            option={options}
            exportExcel
            handleOpenModal={allowPaymentAdjustment}
          />
        </div>
      )}
      <TransitionModal
        view={errorsModal.view}
        handleClose={() => setErrorsModal(prevError => ({ ...prevError, view: false }))}
        buttonOk={tKey('Vale')}
        title={tKey('Revisar los datos de las becas')}
        bodyModal={
          <>
            {errorsModal.errors.map((error: any, index: number) => (
              <div key={index} className={styles.errorModal}>
                {tErrorKey(`- En el alumno ${error.name} hay el siguiente error: ${error.message}`)}
              </div>
            ))}
          </>
        }
        handleYes={() => setErrorsModal(prevError => ({ ...prevError, view: false }))}
      />
      <PaymentModal
        view={payModal.view}
        onClose={() => setPayModal(prevModal => ({ ...prevModal, view: false }))}
        onSubmit={paySage}
        inputsData={payModal.modal}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  allowPaymentAdjustment: selectUserCan(state)('allow_payment_adjustment'),
  academicYears: selectAcademicYears(state),
  currentAcademicYear: selectCurrentAcademicYear(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProps);
