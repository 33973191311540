import colorPalette from '../helpers/colorPalette';

export default {
  primary: {
    main: colorPalette.primary,
  },
  secondary: {
    main: colorPalette.secondary20,
  },
};
