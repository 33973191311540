import { Button, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { tErrorKey, tKey } from '../../helpers/translate';
import { IdNamePair } from '../../redux/common/definitions';
import styles from './simpleList.module.scss';

export interface SimpleListProps {
  item: IdNamePair;
  code?: string;
  deleteFunc: (id: number, code?: string) => void;
  editFunc: (id: number, data: string, code?: string) => void;
}

const ItemWithIcon: React.FC<SimpleListProps> = ({ item, code, deleteFunc, editFunc }) => {
  const [enableTextField, setEnableTextField] = useState<boolean>(true);
  const [value, setValue] = useState(item.name);
  const [errorText, setErrorText] = useState('');
  const [error, setError] = useState(false);

  const editAction = (id: number, data: string) => {
    if (data === '') {
      setError(true);
      setErrorText(tErrorKey('El campo no puede estar en blanco'));
    } else if (data === item.name) {
      setError(true);
      setErrorText(tErrorKey('El campo no puede ser el mismo'));
    } else {
      setError(false);
      setErrorText('');
      editFunc(id, data, code);
      setEnableTextField(!enableTextField);
    }
  };
  return (
    <div className={styles.containerItem}>
      <TextField
        defaultValue={item.name}
        onChange={event => setValue(event.target.value)}
        error={error}
        helperText={errorText}
        variant="outlined"
        InputProps={{
          classes: {
            root: styles.rootText,
            input: !enableTextField ? styles.active : '',
          },
        }}
        disabled={enableTextField}
        autoFocus={enableTextField}
      />
      <div className={styles.iconGroup}>
        {enableTextField ? (
          <IconButton edge="end" aria-label="edit" onClick={() => setEnableTextField(!enableTextField)}>
            <Edit />
          </IconButton>
        ) : (
          <Button variant="outlined" color="primary" onClick={() => editAction(item.id, value)} disableElevation>
            {tKey('Guardar')}
          </Button>
        )}
        {enableTextField ? (
          <IconButton edge="end" aria-label="delete" onClick={() => deleteFunc(item.id, code)}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <IconButton edge="end" aria-label="close" onClick={() => setEnableTextField(!enableTextField)}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default ItemWithIcon;
