import { MenuItem, Typography } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import CheckboxController from '../../../components/Checkbox/CheckboxController';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../components/Select/SelectController';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import { useWindowSize } from '../../../helpers/customHooks/useHookMethod';
import { tFormKey } from '../../../helpers/translate';
import { ReactComponent as PinterestIcon } from '../../../images/icons/iconPinterest.svg';
import { DocumentationItem } from '../../../redux/common/definitions';
import { UserData } from '../../../redux/user/definitions';
import { StudentUserSchema } from '../../../validations/formSchema';
import styles from './studentDataForm.module.scss';
import RoleCode from '../../../types/Roles/roleCode';

interface StudentDataFormProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  control: Control<T>;
  profileData: UserData;
  incomesTypes: string[];
  contractTypes: string[];
  readOnly?: boolean;
  actions?: string[];
  documentation?: DocumentationItem[];
  uploadUserDocument?: (document_id: number, data: File) => void;
  downloadUserDocument?: (file_id: number, name: string) => void;
  removeUserDocument?: (file_id: number) => void;
  getUserDocument?: (file_id: number, name: string) => void;
  watch: ReturnType<typeof useForm>['watch'];
}

const StudentDataForm = <T extends FieldValues>({
  errors,
  control,
  profileData,
  incomesTypes,
  contractTypes,
  readOnly,
  actions,
  documentation,
  uploadUserDocument,
  downloadUserDocument,
  removeUserDocument,
  getUserDocument,
  watch,
}: StudentDataFormProps<T>): ReactElement => {
  const {
    instagram,
    twitter,
    facebook,
    linkedin,
    youtube,
    other_social_networks,
    company_name,
    employee_role,
    current_incomes,
    contract_type,
    cv_agreement,
    role_code,
  } = profileData;

  const disableDocs = watch('cv_agreement') === undefined ? !!!cv_agreement : !watch('cv_agreement');
  const isMobile = useWindowSize().IsMobile;
  const roleCode = new RoleCode(role_code);
  let cvSection;
  if (roleCode.isStudent()) {
    let documentsSection;
    if (documentation && actions && documentation.length > 0) {
      const docs = documentation.filter((doc: DocumentationItem) => doc.code === 'SCV') as DocumentationItem[];
      if (docs.length > 0) {
        docs[0].mandatory = !disableDocs;
        documentsSection = (
          <FncAddDocuments
            required={true}
            documents={docs}
            actions={actions}
            upload={uploadUserDocument}
            download={downloadUserDocument}
            remove={removeUserDocument}
            getFile={getUserDocument}
            disabled={disableDocs}
          />
        );
      }
    }
    cvSection = (
      <div className={styles.curriculum}>
        <FormContainer title={tFormKey('Currículum')}>
          <CheckboxController
            size={isMobile ? 'small' : 'medium'}
            name="cv_agreement"
            label={
              <span>
                <Typography>
                  <div className={styles.dataProtect}>{tFormKey('ProtecciónDatosCV')}</div>
                </Typography>
                <Typography>
                  <div className={styles.dataProtect}>
                    {tFormKey('DerechosLOPD')} <a href="mailto:lopd@zingprogramme.com">lopd@zingprogramme.com</a>
                  </div>
                </Typography>
                <Typography>
                  <div className={styles.dataProtect}>
                    {tFormKey('Mas información')}
                    <a
                      href="https://www.zingprogramme.com/politica-de-privacidad/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://www.zingprogramme.com/politica-de-privacidad/
                    </a>
                  </div>
                </Typography>
              </span>
            }
            defaultValue={!!cv_agreement}
            control={control}
            disabled={readOnly}
          />
          {documentsSection}
        </FormContainer>
      </div>
    );
  }

  return (
    <>
      <FormContainer title={tFormKey('Ocupación')}>
        <TextInputController
          control={control}
          size="50"
          label={tFormKey('Nombre de la empresa')}
          name="company_name"
          errors={errors}
          defaultValue={company_name}
          schema={StudentUserSchema}
          disabled={readOnly}
        />
        <TextInputController
          control={control}
          size="50"
          label={tFormKey('Posición dentro de la empresa')}
          name="employee_role"
          errors={errors}
          defaultValue={employee_role}
          schema={StudentUserSchema}
          disabled={readOnly}
        />
        <SelectController
          control={control}
          size="50"
          name="current_incomes"
          defaultValue={current_incomes}
          errors={errors}
          label={tFormKey('Nivel actual de ingresos')}
          disabled={readOnly}
          schema={StudentUserSchema}
        >
          {incomesTypes.map(element => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          size="50"
          name="contract_type"
          defaultValue={contract_type}
          errors={errors}
          label={tFormKey('Tipo de contrato')}
          disabled={readOnly}
          schema={StudentUserSchema}
        >
          {contractTypes.map(element => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
      </FormContainer>
      {cvSection}
      <FormContainer title={tFormKey('Redes sociales')}>
        <TextInputController
          control={control}
          size="50"
          label={tFormKey('Instagram')}
          name="instagram"
          icon={<InstagramIcon color="primary" />}
          errors={errors}
          defaultValue={instagram}
          schema={StudentUserSchema}
          disabled={readOnly}
        />
        <TextInputController
          control={control}
          size="50"
          label={tFormKey('Twitter')}
          name="twitter"
          icon={<TwitterIcon color="primary" />}
          errors={errors}
          defaultValue={twitter}
          schema={StudentUserSchema}
          disabled={readOnly}
        />
        <TextInputController
          control={control}
          size="50"
          label={tFormKey('Facebook')}
          name="facebook"
          errors={errors}
          icon={<FacebookIcon color="primary" />}
          defaultValue={facebook}
          schema={StudentUserSchema}
          disabled={readOnly}
        />
        <TextInputController
          control={control}
          size="50"
          icon={<LinkedInIcon color="primary" />}
          label={tFormKey('Linkedin')}
          name="linkedin"
          errors={errors}
          defaultValue={linkedin}
          schema={StudentUserSchema}
          disabled={readOnly}
        />
        <TextInputController
          control={control}
          size="50"
          label={tFormKey('Youtube')}
          icon={<YouTubeIcon color="primary" />}
          name="youtube"
          errors={errors}
          defaultValue={youtube}
          schema={StudentUserSchema}
          disabled={readOnly}
        />

        <label className={styles.label} id="document_type">
          <PinterestIcon className={`${styles.icon} ${styles.pinterest}`} />
          <span className={styles.labelText}>{tFormKey('RedSocial')}</span>
        </label>

        <TextInputController
          control={control}
          size="50"
          name="other_social_networks"
          errors={errors}
          defaultValue={other_social_networks}
          schema={StudentUserSchema}
          disabled={readOnly}
        />
      </FormContainer>
    </>
  );
};

export default StudentDataForm;
