/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import ActionsMenu from '../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TransitionsModal from '../../components/Modal/TransitionModal';
import TableWithEdit from '../../components/Tables/TableWithEdit';
import Title from '../../components/Title/Title';
import { tKey } from '../../helpers/translate';
import { selectIsNousCims, selectUserIsAcademy } from '../../redux/auth/selectors';
import {
  deleteInternalProject,
  getInternalProjects,
  getInternalProjectsAcademy,
  getInternalProjectsExcel,
  setLatestInternalProjectSection,
} from '../../redux/internal-projects/actions';
import { InternalProjectDispatch, InternalProjectsData } from '../../redux/internal-projects/definitions';
import {
  selectInternalProjectBySubAreaCode,
  selectIpLatestSection,
  selectIpLoading,
  selectLookupCategory,
} from '../../redux/internal-projects/selectors';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import SubmenuInternalProjects from './SubmenuInternalProjects';
import styles from './internalProjects.module.scss';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import MobileTable from '../../components/Tables/MobileTable';
import { useTableWithSearch } from '../../helpers/customHooks/useTableWithSearch';
import InputSearch from '../../components/Inputs/InputSearch/InputSearch';
import SubTitle from '../../components/FncSubtitle/FncSubtitle';
import LayoutMobile from '../../components/Layout/LayoutMobile';

const CreateInternalProjects = React.lazy(() => import('./Modals/CreateInternalProjects/CreateInternalProjects'));

interface InternalProjectsProps {
  loading: boolean;
  canCreate: boolean;
  isNousCims: boolean;
  disabled: boolean;
  is_academy: boolean;
  getInternalProjects: () => void;
  getInternalProjectsExcel: () => void;
  deleteInternalProject: (id: number) => void;
  getInternalProjectsAcademy: () => void;
  lookUpSubArea: (area_code: string) => { [k: string]: string };
  selectInternalProjects: (areaCode: string) => InternalProjectsData[];
  latestInternalProjectSectionCode: (id: number) => string | null;
}

const InternalProjects: React.FC<InternalProjectsProps> = ({
  loading,
  canCreate,
  isNousCims,
  is_academy,
  getInternalProjects,
  getInternalProjectsExcel,
  deleteInternalProject,
  getInternalProjectsAcademy,
  lookUpSubArea,
  selectInternalProjects,
  latestInternalProjectSectionCode,
}) => {
  const isMobile = useWindowSize().IsMobile;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (is_academy) {
      getInternalProjectsAcademy();
    } else {
      getInternalProjects();
    }
  }, [getInternalProjects, getInternalProjectsAcademy, is_academy]);

  const [component, setIdComponent] = useState({ id: 0, code: '', name: '' });
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, id: 0 });
  const [createProjectView, setCreateProjectView] = useState(false);

  const handleComponentId = ({
    componentId,
    subAreaCode,
    name,
  }: {
    componentId: number;
    subAreaCode: string;
    name: string;
  }) => {
    setIdComponent({ id: componentId, code: subAreaCode, name });
  };

  const escuelaRoute = is_academy ? '/centro' : '';
  const tableData: MaterialTableProps<InternalProjectsData> = {
    title: tKey('Lista de proyectos'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'name',
        //@ts-ignore
        width: '40%',
      },
      {
        title: tKey('Tipo de proyecto'),
        field: 'subarea_internal_project_type_name',
        lookup: lookUpSubArea(component.code),
        //@ts-ignore
        width: '15%',
      },
      {
        title: tKey('Entidad / Centro educativo '),
        field: 'academyOrEntityName',
        //@ts-ignore
        width: '35%',
      },
      {
        title: tKey('Fecha'),
        field: 'created',
        //@ts-ignore
        width: '10%',
      },
    ],
    data: selectInternalProjects(component.code),
    isLoading: loading,
    options: {
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: canCreate && isNousCims ? 'fixed' : 'auto',
      exportButton: true,
      exportCsv: () => {
        getInternalProjectsExcel();
      },
    },
  };

  const goToNew = () => setCreateProjectView(true);

  const handleEditInternalProject = (
    event: React.MouseEvent,
    rowData: InternalProjectsData | InternalProjectsData[],
  ) => {
    if ('id' in rowData) {
      const section = latestInternalProjectSectionCode(rowData.id) || 'informacion';
      history.push(`/proyectos-internos${escuelaRoute}/${rowData.id}/editar/${section}`);
    }
  };

  const handleGoDetailInternalProject = (rowData: InternalProjectsData | InternalProjectsData[]) => {
    if ('id' in rowData) {
      const section = latestInternalProjectSectionCode(rowData.id) || 'informacion';
      history.push(`/proyectos-internos${escuelaRoute}/${rowData.id}/detalle/${section}`);
    }
  };

  const handleGoToSeguimientosDiariosInternalProject = (rowData: InternalProjectsData | InternalProjectsData[]) => {
    if ('id' in rowData && rowData.has_daily_follow_up) {
      const section = 'seguimientos_diarios';
      dispatch(setLatestInternalProjectSection(+rowData.id, section));
      history.push(`/proyectos-internos${escuelaRoute}/${rowData.id}/editar/${section}`);
    }
  };

  const handleDeleteModalInternalProject = (rowData: InternalProjectsData | InternalProjectsData[]) => {
    if ('id' in rowData) setDeleteModal({ isOpen: true, id: rowData.id });
  };

  const handleCloseModal = () => {
    setCreateProjectView(false);
    setDeleteModal({ isOpen: false, id: 0 });
  };

  const handleDeleteInternalProject = () => {
    deleteInternalProject(deleteModal.id);
    handleCloseModal();
  };

  const buttonSave: ButtonProps = {
    children: tKey('Guardar'),
    startIcon: <SaveIcon />,
    color: 'primary',
    variant: 'contained',
    fullWidth: true,
    disableElevation: true,
  };

  const propertyExtractor = (item: InternalProjectsData) => {
    return `${item.name.toLowerCase()}`; // podemos poner mas columnas para filtrar: return `${item.name.toLowerCase()} ${item.owner_name.toLowerCase()}`
  };

  const { filteredData, handleSearch } = useTableWithSearch<InternalProjectsData>({
    initialData: selectInternalProjects(component.code),
    propertyExtractor: propertyExtractor,
  });

  return (
    <Layout
      leftSubmenu={
        !isMobile ? (
          <SubmenuInternalProjects onSelectComponentId={handleComponentId} selected={component.id} />
        ) : (
          undefined
        )
      }
      rightSubmenu={!isMobile ? <ActionsMenu actionsButtons={[buttonSave]} /> : undefined}
    >
      <div className={styles.root}>
        {isMobile ? (
          <LayoutMobile onBack={() => history.push('/proyectos-internos')} title={tKey('Proyectos Internos')}>
            <div className={styles.submenuInternalProjects}>
              <SubmenuInternalProjects onSelectComponentId={handleComponentId} selected={component.id} />
            </div>
            <div className={styles.mobileSubtitle}>
              <SubTitle>{tKey('Lista de proyectos') + ': ' + tKey(component.name)}</SubTitle>
            </div>
            <InputSearch onSearch={handleSearch} />
            <MobileTable
              tableData={{ ...tableData, data: filteredData }}
              columnsFilter={['name']}
              onRowClick={handleGoToSeguimientosDiariosInternalProject}
            />
          </LayoutMobile>
        ) : (
          <>
            <Title>{tKey('Proyectos ') + tKey(component.name)}</Title>
            <TableWithEdit
              customContainer
              tableData={tableData}
              filtering
              permission={canCreate}
              optionsNew={
                isNousCims
                  ? {
                      newButtonText: tKey('Nuevo Proyecto Interno'),
                      onNew: goToNew,
                    }
                  : undefined
              }
              onEditOne={handleEditInternalProject}
              deleteFunc={canCreate && isNousCims ? handleDeleteModalInternalProject : undefined}
              onRowClick={handleGoDetailInternalProject}
            />
            <Suspense fallback={<></>}>
              {createProjectView ? (
                <CreateInternalProjects
                  view={createProjectView}
                  handleClose={handleCloseModal}
                  subAreaCode={component.code}
                />
              ) : (
                <Fragment />
              )}
            </Suspense>
            <TransitionsModal
              view={deleteModal.isOpen}
              handleClose={handleCloseModal}
              title={tKey('ip.eliminar_proyecto')}
              bodyModal={tKey('ip.confirmar_eliminar_proyecto')}
              buttonOk={tKey('Sí')}
              buttonKo={tKey('No')}
              handleYes={handleDeleteInternalProject}
            />
          </>
        )}
      </div>
    </Layout>
  );
};
const mapStateToProps = (state: AppState) => ({
  loading: selectIpLoading(state),
  canCreate: selectUserCan(state)('crud_internal_projects'),
  isNousCims: selectIsNousCims(state),
  is_academy: selectUserIsAcademy(state),
  lookUpSubArea: selectLookupCategory(state),
  selectInternalProjects: selectInternalProjectBySubAreaCode(state),
  latestInternalProjectSectionCode: selectIpLatestSection(state),
});

const mapDispatchToProps = (dispatch: InternalProjectDispatch) => ({
  getInternalProjects: (): void => dispatch(getInternalProjects()),
  getInternalProjectsExcel: (): void => dispatch(getInternalProjectsExcel()),
  deleteInternalProject: (internalProjectId: number): void => dispatch(deleteInternalProject(internalProjectId)),
  getInternalProjectsAcademy: (): void => dispatch(getInternalProjectsAcademy()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjects);
