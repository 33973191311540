function descendingComparator(a: any, b: any, orderBy: any) {
  let prev = a[orderBy];
  let next = b[orderBy];

  if (typeof next === 'string' && typeof prev === 'string') {
    prev = a[orderBy].toLocaleLowerCase();
    next = b[orderBy].toLocaleLowerCase();
  }
  if (next < prev) {
    return -1;
  }
  if (next > prev) {
    return 1;
  }
  return 0;
}

export function getComparator(order: any, orderBy: any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export function searchValue(array: any[], value: string, property: string[]) {
  return array.filter((arr: any) => {
    let control = false;
    return !!property.filter(prop => {
      if (!control) {
        control = arr[prop]
          ?.toString()
          ?.toLocaleLowerCase()
          ?.includes(value?.toLocaleLowerCase());
      }
      return control;
    }).length;
  });
}

export function stableSort(array: any[], comparator: any, filters: any, value?: string, property?: string | string[]) {
  if (Object.values(filters).length) {
    array = array.filter(arr => {
      let control = true;
      Object.keys(filters).forEach(filter => {
        if (!filters[filter]?.length || !control) return;
        control = arr[filter]
          ?.toString()
          ?.toLocaleLowerCase()
          ?.includes(filters[filter]?.toLocaleLowerCase());
      });
      return control;
    });
  }
  if (value && Array.isArray(property)) {
    array = searchValue(array, value, property);
  }

  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}
