import { Collapse, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AccountBalance, ArrowDropDown, ArrowDropUp, Person } from '@material-ui/icons';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import assertUnreachable from '../../../helpers/assertUnreachable';
import { SearchItem } from '../../../redux/searchBar/definitions';
import styles from './nestedItem.module.scss';

interface NestedItemProps {
  item: SearchItem;
  handleClick?: (id: number, type: string) => void;
}
const NestedItem: React.FC<NestedItemProps> = ({ item, handleClick }) => {
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();

  const onClick = (id: number, type: string) => {
    setOpen(!open);
    handleClick && handleClick(id, type);
  };

  const subItemClick = (url: string | null) => {
    url && history.push(url);
  };

  const getType = () => {
    switch (item.type) {
      case 'entity':
        return 'entidad';
      case 'user':
        return 'usuario';
      case 'scholarship':
        return 'beca';
      case 'project':
        return 'proyecto';
    }
    assertUnreachable(item.type);
  };

  return (
    <Fragment>
      <ListItem button onClick={() => onClick(item.id, item.type)} className={styles.list_item}>
        <ListItemIcon>{item.type === 'entity' ? <AccountBalance /> : <Person />}</ListItemIcon>
        <div className={styles.item_details}>
          <ListItemText primary={item.detail} />
          <div className={styles.tag_type}>{getType()}</div>
          {handleClick && (open ? <ArrowDropUp /> : <ArrowDropDown />)}
        </div>
      </ListItem>
      {item.items && (
        <Collapse className={styles.subItem} component="li" in={open} timeout="auto" unmountOnExit>
          {item.items.map(e => (
            <div onClick={() => subItemClick(e.url)} key={`{${e.type}_${e.id}}`}>
              <NestedItem item={e} />
            </div>
          ))}
        </Collapse>
      )}
    </Fragment>
  );
};

export default NestedItem;
