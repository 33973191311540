import { DirectoryDataConsts } from './action_types';
import {
  DirectoryData,
  GetDirectoryDataAction,
  GetDirectoryDataKoAction,
  GetDirectoryDataOkAction,
  QueryUsers,
  SendSuggestionDataAction,
  SendSuggestionDataKoAction,
  SendSuggestionDataOkAction,
  CleanUserData,
  SetPaginationData,
} from './definitions';

// GET DIRECTORY

export function getDirectory(query: QueryUsers): GetDirectoryDataAction {
  return {
    type: DirectoryDataConsts.GET_DIRECTORY,
    query,
  };
}

export function getDirectoryOk(data: DirectoryData): GetDirectoryDataOkAction {
  return {
    type: DirectoryDataConsts.GET_DIRECTORY_OK,
    directoryData: data,
  };
}

export function getDirectoryKo(error: string): GetDirectoryDataKoAction {
  return {
    type: DirectoryDataConsts.GET_DIRECTORY_KO,
    error,
  };
}

// SEND SUGGESTION
export function sendSuggestion(suggestion: string): SendSuggestionDataAction {
  return {
    type: DirectoryDataConsts.SEND_SUGGESTION,
    suggestion,
  };
}

export function sendSuggestionOk(): SendSuggestionDataOkAction {
  return {
    type: DirectoryDataConsts.SEND_SUGGESTION_OK,
  };
}

export function sendSuggestionKo(error: string): SendSuggestionDataKoAction {
  return {
    type: DirectoryDataConsts.SEND_SUGGESTION_KO,
    error,
  };
}

export function cleanUserData(): CleanUserData {
  return {
    type: DirectoryDataConsts.CLEAN_USER_DATA,
  };
}

export function setPagination(): SetPaginationData {
  return {
    type: DirectoryDataConsts.SET_PAGINATION,
  };
}
