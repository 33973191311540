import React from 'react';
import FncControlErrorMessage from '../FncControlErrorMessage/FncControlErrorMessage';
import FncLabel from '../FncLabel/FncLabel';
import styles from './fncDatePicker.module.scss';

export interface FncDatePickerProps extends React.ComponentPropsWithoutRef<'input'> {
  customClass?: string;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  errorMessage?: string;
}

const FncDatePicker = ({
  customClass,
  label,
  required = false,
  disabled = false,
  errorMessage,
  ...props
}: FncDatePickerProps) => {
  return (
    <div className={`${styles.root} ${customClass ? customClass : ''} ${errorMessage ? styles.hasError : ''}`}>
      {label && (
        <FncLabel required={required} htmlFor={props.name}>
          {label}
        </FncLabel>
      )}
      <input id={props.name} className={styles.input} type={'date'} disabled={disabled} {...props} />
      {errorMessage && <FncControlErrorMessage message={errorMessage} />}
    </div>
  );
};

export default FncDatePicker;
