import { ParticipantParticularitiesConst } from './action_types';
import {
  GetParticipantParticularities,
  GetParticipantParticularitiesKo,
  GetParticipantParticularitiesOk,
  ParticipantParticularitiesType,
} from './definitions';

export function getParticipantParticularities(): GetParticipantParticularities {
  return {
    type: ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES,
  };
}

export function getParticipantParticularitiesOk(
  data: ParticipantParticularitiesType[],
): GetParticipantParticularitiesOk {
  return {
    type: ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES_OK,
    data,
  };
}

export function getParticipantParticularitiesKO(error: string): GetParticipantParticularitiesKo {
  return {
    type: ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES_KO,
    error,
  };
}
