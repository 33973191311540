import { Assistance } from '../participants/Participant';
import { ParticipantsAssistanceConst } from './action_types';
import {
  GetParticipantsAssistanceAction,
  GetParticipantsAssistanceKoAction,
  GetParticipantsAssistanceOkAction,
  UpdateParticipantsAssistanceAction,
  UpdateParticipantsAssistanceKoAction,
  UpdateParticipantsAssistanceOkAction,
} from './definitions';

export function getParticipantAssistance(followUpId: number): GetParticipantsAssistanceAction {
  return {
    type: ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE,
    followUpId,
  };
}

export function getParticipantAssistanceOk(assistance: Assistance[]): GetParticipantsAssistanceOkAction {
  return {
    type: ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE_OK,
    assistance,
  };
}

export function getParticipantAssistanceKo(error: string): GetParticipantsAssistanceKoAction {
  return {
    type: ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE_KO,
    error,
  };
}

export function updateParticipantAssistance(infoUpdate: Assistance): UpdateParticipantsAssistanceAction {
  return {
    type: ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE,
    infoUpdate,
  };
}

export function updateParticipantAssistanceOk(infoUpdate: Assistance): UpdateParticipantsAssistanceOkAction {
  return {
    type: ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE_OK,
    infoUpdate,
  };
}

export function updateParticipantAssistanceKo(error: string): UpdateParticipantsAssistanceKoAction {
  return {
    type: ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE_KO,
    error,
  };
}
