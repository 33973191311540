import { APIError, _token } from '../../api/api';
import assertUnreachable from '../../helpers/assertUnreachable';
import defaultImage from '../../images/default-avatar.png';
import { LangCodes } from '../translations/definitions';
import { AuthorizationConsts } from './action_types';
import { AuthAction, AuthUserData, AuthUserDataLogin } from './definitions';

export const defaulLanguage = LangCodes.ES;

const initialState = {
  loggedIn: false,
  loading: false,
  activating: false,
  error: null as APIError | null,
  token: _token as string | null,
  avatar: '' as string | '',
  init: false,
  language: defaulLanguage as string,
  userInfo: {} as AuthUserData,
};

const fillAuthUserData = (data: AuthUserDataLogin): AuthUserData => {
  return {
    id: data.id,
    email: data.email,
    phone: data.phone,
    is_nouscims: data.is_nouscims,
    name: data.name,
    surname: data.surname,
    user_id: data.user_id,
    role: data.role,
    role_code: data.role_code,
    role_id: data.role_id,
    role_platform: data.role_platform,
    role_group: data.role_group,
    roles: data.roles,
    userAllowedRoles: data.userAllowedRoles,
    state: data.state,
    can_create_entity_scholarships: data.can_create_entity_scholarships,
    can_create_institute_scholarships: data.can_create_institute_scholarships,
    can_renew_entity_scholarships: data.can_renew_entity_scholarships,
    can_renew_institute_scholarships: data.can_renew_institute_scholarships,
  };
};

export type AuthState = typeof initialState;

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case AuthorizationConsts.PLATFORM_TRANSLATION:
      return {
        ...state,
        language: action.languageCode,
        loading: true,
      };
    case AuthorizationConsts.AUTH_LOGIN:
    case AuthorizationConsts.AUTH_SET_USER_AVATAR:
    case AuthorizationConsts.AUTH_GET_USER_AVATAR:
    case AuthorizationConsts.ACTIVATION_ENTITY:
    case AuthorizationConsts.AUTH_USER_CHANGE_ROLE:
      return {
        ...state,
        loading: true,
        error: null,
        activating: true,
      };

    case AuthorizationConsts.AUTH_USER_ACTIVATION:
      return {
        ...state,
        loading: true,
        error: null,
        activating: true,
      };

    case AuthorizationConsts.PLATFORM_TRANSLATION_OK:
      return {
        ...state,
        loading: false,
      };

    case AuthorizationConsts.AUTH_USER_ACTIVATION_OK:
      return {
        ...state,
        loading: false,
        activating: false,
        loggedIn: true,
        language: action.data.language,
        userInfo: fillAuthUserData(action.data),
        error: null,
      };

    case AuthorizationConsts.AUTH_USER_ACTIVATION_KO:
      return {
        ...state,
        loading: false,
        activating: false,
      };

    case AuthorizationConsts.AUTH_LOGIN_OK:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        token: action.data.token,
        activating: false,
        error: null,
        language: action.data.language,
        userInfo: fillAuthUserData(action.data),
      };

    case AuthorizationConsts.ACTIVATION_ENTITY_OK:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        token: action.data.token,
        language: action.data.language,
        userInfo: {
          ...state.userInfo,
          email: action.data.email,
          is_nouscims: action.data.is_nouscims,
          name: action.data.name,
          surname: action.data.surname,
          user_id: action.data.user_id,
          role: action.data.role,
          role_code: action.data.role_code,
          role_id: action.data.role_id,
          state: action.data.state,
        },
        activating: false,
        error: null,
      };
    case AuthorizationConsts.PLATFORM_TRANSLATION_KO:
    case AuthorizationConsts.AUTH_LOGIN_KO:
    case AuthorizationConsts.ACTIVATION_ENTITY_KO:
    case AuthorizationConsts.AUTH_LOGIN_REDIRECT:
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: action.error,
        activating: false,
      };
    case AuthorizationConsts.AUTH_LOGOUT:
      return {
        ...state,
        loggedIn: false,
        token: null,
      };
    case AuthorizationConsts.AUTH_LOGOUT_OK:
      return {
        ...initialState,
        token: null,
      };
    case AuthorizationConsts.AUTH_LOGOUT_KO:
      return {
        ...state,
        loggedIn: true,
      };
    case AuthorizationConsts.AUTH_UPDATE_USER_STATE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          state: action.state,
          phone: action.phone,
        },
      };
    case AuthorizationConsts.AUTH_SET_USER_AVATAR_OK:
    case AuthorizationConsts.AUTH_GET_USER_AVATAR_OK:
      const { avatar } = action;
      return {
        ...state,
        loading: false,
        avatar,
        error: null,
      };
    case AuthorizationConsts.AUTH_GET_USER_AVATAR_KO:
    case AuthorizationConsts.AUTH_SET_USER_AVATAR_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        avatar: defaultImage,
        error,
      };

    case AuthorizationConsts.AUTH_GET_DETAILS_OK:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        language: action.data.language,
        userInfo: fillAuthUserData(action.data),
      };
    case AuthorizationConsts.ENABLE_INIT:
      return {
        ...state,
        language: defaulLanguage,
        init: true,
      };
    case AuthorizationConsts.DISABLE_INIT:
      return {
        ...state,
        init: false,
      };
    case AuthorizationConsts.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
  }

  assertUnreachable(action);
  return state;
};

export default authReducer;
