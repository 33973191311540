import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Cell, Legend, Pie, PieChart, PieLabelRenderProps } from 'recharts';
import { StatisticsPerState } from '../../api/Scholarship/scholarship';
import colorPalette from '../../helpers/colorPalette';
import { tScholarshipKey } from '../../helpers/translate';
import { PerStateStatisticData, StateCode } from '../../redux/scholarship/definitions';
import styles from './dashboard.module.scss';

// const barData = [
//   {
//     date: '18/05/2020',
//     scholarships: 12,
//   },
//   {
//     date: '19/05/2020',
//     scholarships: 14,
//   },
//   {
//     date: '20/05/2020',
//     scholarships: 30,
//   },
//   {
//     date: '21/05/2020',
//     scholarships: 20,
//   },
//   {
//     date: '22/05/2020',
//     scholarships: 10,
//   },
//   {
//     date: '23/05/2020',
//     scholarships: 50,
//   },
//   {
//     date: '24/05/2020',
//     scholarships: 43,
//   },
// ];

const COLORS = [
  '#0088FE',
  '#f9afaa',
  '#666666',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#000000',
  '#da291c',
  '#af7ac5',
  '#a3e4d7',
];

// const CustomTooltip = ({ active, payload, label }: TooltipProps) =>
//   active ? (
//     <div className={styles.tooltip_wrapper}>
//       <p>{payload ? `${payload[0].value} becas en el sistema` : ''}</p>
//       <p>{`el día ${label} `}</p>
//       <p>Becas totales: 80000</p>
//     </div>
//   ) : null;

interface ScholarshipDashboard {
  type: 'entity' | 'institute' | 'study_center';
  state_code: StateCode;
  academic_years: string[];
  academic_year: string;
}
const ScholarshipDashboard: React.FC<ScholarshipDashboard> = ({ type, state_code, academic_years, academic_year }) => {
  const history = useHistory();
  const [scholarshipsByState, setScholarshipsByState] = useState<{ data: PerStateStatisticData[]; total: number }>({
    data: [],
    total: 0,
  });

  const routeType = { entity: 'entidad', institute: 'instituto', study_center: 'escoles-concertades' };

  useEffect(() => {
    (async function fetchData() {
      const data = await StatisticsPerState({
        type,
        academic_year: academic_year ? academic_year.replace('-', '/') : academic_years[0],
      });
      const total = data.reduce((a, b) => a + b.scholarships, 0);
      setScholarshipsByState({ data, total });
    })();
  }, [academic_year]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <ul className={styles.legend_wrapper}>
        {payload.map((entry: any) => (
          <li
            key={entry.value}
            className={styles.legend_item}
            onClick={() =>
              history.push(
                `/becas-${routeType[type]}/${
                  academic_year ? academic_year.replace('/', '-') : academic_years[0].replace('/', '-')
                }/${entry.payload.payload.code}`,
              )
            }
          >
            <div
              style={{
                backgroundColor: entry.color ? entry.color : colorPalette.primary80,
              }}
              className={styles.legend_color}
            />
            <span>{tScholarshipKey(entry.value)}: </span>
            <span className={styles.bold}>{entry.payload.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <ExpansionPanel defaultExpanded classes={{ root: styles.panel }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>{`${tScholarshipKey('Becas por estado curso')} ${academic_year?.replace('-', '/') ||
          academic_years[0]}`}</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        classes={{
          root: styles.panel_details,
        }}
      >
        <div className={styles.select_dashboard}>
          <FormControl
            variant="outlined"
            classes={{
              root: styles.select_wrapper,
            }}
          >
            <InputLabel>{tScholarshipKey('Curso')}</InputLabel>
            <Select
              onChange={e => history.push(`/becas-${routeType[type]}/${(e.target.value as string).replace('/', '-')}`)}
              label={tScholarshipKey('Curso')}
              value={academic_year ? academic_year.replace('-', '/') : academic_years[0]}
            >
              {academic_years
                .filter(course => course !== '-')
                .map(element => (
                  <MenuItem key={element} value={element}>
                    {element}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <PieChart height={340} width={800}>
            <Pie
              isAnimationActive={false}
              data={scholarshipsByState.data}
              dataKey="scholarships"
              fill="#8884d8"
              label={(entry: PieLabelRenderProps) =>
                entry.percent ? `${(entry.percent * 100).toFixed(1)}%` : undefined
              }
              onClick={(...args: any[]) =>
                history.push(
                  `/becas-${routeType[type]}/${
                    academic_year ? academic_year.replace('/', '-') : academic_years[0].replace('/', '-')
                  }/${args[0].code}`,
                )
              }
            >
              {scholarshipsByState.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} cursor="pointer" />
              ))}
            </Pie>
            <Legend
              content={renderLegend}
              layout="vertical"
              verticalAlign="middle"
              align="right"
              wrapperStyle={{
                right: '-50px',
              }}
            />
          </PieChart>
        </div>
        <p>
          {tScholarshipKey('Total becas curso')} {academic_year || academic_years[0].replace('-', '/')}:&nbsp;
          {scholarshipsByState.total}
        </p>

        {state_code && (
          <div className={styles.see_all}>
            <Button color="primary" variant="contained" onClick={() => history.push(`/becas-${routeType[type]}`)}>
              {tScholarshipKey('Todas las becas')}
            </Button>
          </div>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ScholarshipDashboard;
