import { ButtonProps, MenuItem } from '@material-ui/core';
import { Save, Send } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import CheckboxController from '../../../../../components/Checkbox/CheckboxController';
import FormContainer from '../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Submenu from '../../../../../components/Layout/Menus/Submenu/Submenu';
import Loading from '../../../../../components/Loading/Loading';
import SelectController from '../../../../../components/Select/SelectController';
import Title from '../../../../../components/Title/Title';
import { tKey, tProjectKey } from '../../../../../helpers/translate';
import { showSnackbar } from '../../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../../redux/FeedbackAPI/definitions';
import {
  getInternalProjectTemplateInfo,
  publishInternalProjectTemplate,
  setInternalProjectTemplateData,
  updateInternalProjectTemplate,
} from '../../../../../redux/internal-project-templates/actions';
import {
  FollowUpData,
  GeneralFormData,
  InternalProjectTemplateData,
  InternalProjectTemplateDispatch,
  SubareaData,
} from '../../../../../redux/internal-project-templates/definitions';
import { selectIPTState } from '../../../../../redux/internal-project-templates/selectors';
import { AppState } from '../../../../../redux/root-reducer';
import {
  InternalProjectTypeData,
  InternalProjectTypesEnum,
} from '../../../../../types/InternalProjects/InternalProjectTypes.d';
import { InternalProjectTemplateSchema } from '../../../../../validations/formSchema';
import useInternalProjectTemplate from './../useInternalProjectTemplate';
import InternalProjectTemplateFollowUps from './FollowUps/InternalProjectTemplateFollowUps';
import InternalProjectTemplateGeneralForms from './GeneralForms/InternalProjectTemplateGeneralForms';
import InternalProjectTemplateRoles from './Roles/InternalProjectTemplateRoles';
import styles from './internalProjectTemplateEdition.module.scss';

interface InternalProjectTemplateEditionProps extends RouteComponentProps<{ id: string }> {
  loading: boolean;
  internalProjectTemplateData: InternalProjectTemplateData;
  setData: (key: string, value: string | number | boolean) => void;
  getInternalProjectTemplate: (id: number) => void;
  saveInternalProjectTemplate: (id: number, data: InternalProjectTemplateData) => void;
  publishInternalProjectTemplate: (id: number) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const InternalProjectTemplateEdition: React.FC<InternalProjectTemplateEditionProps> = ({
  match: {
    params: { id },
  },
  loading,
  internalProjectTemplateData,
  setData,
  getInternalProjectTemplate,
  saveInternalProjectTemplate,
  publishInternalProjectTemplate,
  showSnackbar,
}) => {
  const {
    template_name,
    subarea_internal_project_type_id,
    has_daily_follow_up,
    has_enrollments,
    has_survey,
    is_published,
    general_forms,
    follow_ups,
    subarea_id,
    roles,
  } = internalProjectTemplateData;
  const history = useHistory();
  const location = useLocation();

  const { types, followUpTypes, subareas } = useInternalProjectTemplate().metadata;

  const { errors, control, handleSubmit } = useForm<InternalProjectTemplateData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InternalProjectTemplateSchema,
  });

  useEffect(() => {
    getInternalProjectTemplate(+id);
  }, [getInternalProjectTemplate, id]);

  let previousGeneralFormSelected: GeneralFormData | undefined;
  if (location.state && location.state.generalFormId && general_forms) {
    previousGeneralFormSelected = general_forms.find(
      (e: GeneralFormData) => e.id === Number(location.state.generalFormId),
    );
  }
  let previousFollowUoSelected: FollowUpData | undefined;
  if (location.state && location.state.followUpId && follow_ups) {
    previousFollowUoSelected = follow_ups.find((e: FollowUpData) => e.id === Number(location.state.followUpId));
  }

  const handleSave = async (data: InternalProjectTemplateData) => {
    const saveData = {
      has_daily_follow_up: data.has_daily_follow_up === undefined ? has_daily_follow_up : data.has_daily_follow_up,
      has_enrollments: data.has_enrollments === undefined ? has_enrollments : data.has_enrollments,
      has_survey: has_survey,
      roles: internalProjectTemplateData.roles,
    } as InternalProjectTemplateData;

    saveInternalProjectTemplate(+id, saveData);
  };

  const handlePublish = () => {
    if (internalProjectTemplateData.is_published)
      showSnackbar(tProjectKey('La plantilla fue publicada anteriormente'), 'success', undefined, 1500);
    else publishInternalProjectTemplate(+id);
  };

  const buttonsActions: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      fullWidth: true,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      onClick: handleSubmit(handleSave),
      startIcon: <Save />,
    },
    {
      children: tKey('Publicar'),
      hidden: is_published,
      fullWidth: true,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      onClick: handleSubmit(handlePublish),
      startIcon: <Send />,
    },
  ];
  return (
    <LayoutForm
      leftSubmenu={<Submenu titleMenu={tKey('PLANTILLA')} defaultOpen optionsMenu={[]} />}
      rightSubmenu={
        <ActionsMenu
          actionsButtons={buttonsActions}
          goBack
          onGoBack={() => history.push('/configuracion/proyectos-internos/plantillas')}
        />
      }
    >
      <Title>{tKey('Edición de plantilla')}</Title>
      {loading ? (
        <Loading big />
      ) : (
        <>
          <FormContainer title="">
            <TextInputController
              label={tKey('Nombre de la plantilla')}
              name="template_name"
              defaultValue={template_name}
              size="100"
              disabled
              schema={InternalProjectTemplateSchema}
              control={control}
              errors={errors}
            />
            <div className={styles.minimalInfo}>
              <div className={styles.item1}>
                <SelectController
                  label={tProjectKey('Subárea')}
                  name="subarea_id"
                  defaultValue={subarea_id}
                  disabled
                  schema={InternalProjectTemplateSchema}
                  control={control}
                  errors={errors}
                >
                  {subareas.map((element: SubareaData) => (
                    <MenuItem key={element.id} value={element.id}>
                      {element.name}
                    </MenuItem>
                  ))}
                </SelectController>
                <SelectController
                  label={tProjectKey('Tipo de proyecto')}
                  name="subarea_internal_project_type_id"
                  defaultValue={subarea_internal_project_type_id}
                  disabled
                  schema={InternalProjectTemplateSchema}
                  control={control}
                  errors={errors}
                >
                  {types.map((element: InternalProjectTypeData) => (
                    <MenuItem key={element.id} value={element.id}>
                      {InternalProjectTypesEnum[element.name]}
                    </MenuItem>
                  ))}
                </SelectController>
              </div>
              <div className={styles.item2}>
                <CheckboxController
                  label={tProjectKey('Seguimientos diarios')}
                  name="has_daily_follow_up"
                  checked={Boolean(has_daily_follow_up)}
                  control={control}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setData('has_daily_follow_up', Number(event.target.checked))
                  }
                />
                <CheckboxController
                  label={tProjectKey('Gestión de inscripciones')}
                  name="has_enrollments"
                  checked={Boolean(has_enrollments)}
                  control={control}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setData('has_enrollments', Number(event.target.checked))
                  }
                />
              </div>
            </div>
          </FormContainer>
          {roles && roles.length > 0 && <InternalProjectTemplateRoles roles={roles} />}
          <InternalProjectTemplateGeneralForms
            forms={general_forms}
            previousGeneralFormSelected={previousGeneralFormSelected}
            roles={internalProjectTemplateData.roles}
          />
          <InternalProjectTemplateFollowUps
            followUpTypes={followUpTypes}
            followUps={follow_ups}
            reloadInternalProjectTemplateInfo={() => {
              getInternalProjectTemplate(+id);
            }}
            previousFollowUpSelected={previousFollowUoSelected}
            roles={internalProjectTemplateData.roles}
          />
        </>
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIPTState(state).loading,
  internalProjectTemplateData: selectIPTState(state).internalProjectTemplateData,
});

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  getInternalProjectTemplate: (id: number): void => dispatch(getInternalProjectTemplateInfo(id)),
  saveInternalProjectTemplate: (id: number, data: InternalProjectTemplateData): void =>
    dispatch(updateInternalProjectTemplate(id, data)),
  setData: (key: string, value: string | number | boolean): void =>
    dispatch(setInternalProjectTemplateData(key, value)),
  publishInternalProjectTemplate: (id: number): void => dispatch(publishInternalProjectTemplate(id)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectTemplateEdition);
