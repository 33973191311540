import React from 'react';
import Submenu, { SubmenuItem } from '../../../../../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../../../../../helpers/translate';
import {
  CodeSectionInternalProject,
  SectionInternalProject,
} from '../../../../../../../redux/internal-projects/definitions';

interface SubmenuFormsAssistant {
  internalProjectTemplateId: string;
  formAssistantSections: SectionInternalProject[];
  currentSection: CodeSectionInternalProject;
  handleClickOption: () => void;
}
const SubmenuFormsAssistant: React.FC<SubmenuFormsAssistant> = ({
  internalProjectTemplateId,
  currentSection,
  formAssistantSections,
  handleClickOption,
}) => {
  const submenu: SubmenuItem[] = formAssistantSections.map((section: SectionInternalProject) => ({
    title: tKey(section.name),
    type: 'route',
    route: `/proyecto-interno/plantilla/asistente-formularios/${internalProjectTemplateId}/${section.code}`,
    selected: section.code === currentSection,
  }));

  return <Submenu optionsMenu={submenu} defaultOpen onClickOption={handleClickOption} />;
};

export default SubmenuFormsAssistant;
