import { APIValidationError } from '../../api/api';
import { DocumentationItem } from '../common/definitions';
import { LookUpSubarea } from '../project/definitions';
import { ServiceDataConsts } from './action_types';
import {
  AddUserToServiceAction,
  AddUserToServiceKoAction,
  AddUserToServiceOkAction,
  DeleteServiceAction,
  DeleteServiceKoAction,
  DeleteServiceOkAction,
  DownloadDocumentKoServiceAction,
  DownloadDocumentOkServiceAction,
  DownloadDocumentServiceAction,
  GetDocumentKoServiceAction,
  GetDocumentOkServiceAction,
  GetDocumentServiceAction,
  GetServiceDataAction,
  GetServiceDataKoAction,
  GetServiceDataOkAction,
  GetServiceRequestedDataAction,
  GetServiceRequestedDataKoAction,
  GetServiceRequestedDataOkAction,
  GetServicesAction,
  GetServicesKoAction,
  GetServicesOkAction,
  GetUserServicesAction,
  GetUserServicesKoAction,
  GetUserServicesOkAction,
  RemoveServiceRequestedDataAction,
  RemoveServiceRequestedDataKoAction,
  RemoveServiceRequestedDataOkAction,
  RemoveDocumentKoServiceAction,
  RemoveDocumentOkServiceAction,
  RemoveDocumentServiceAction,
  ServiceCreationDataAction,
  ServiceData,
  ServiceRequestData,
  ServicesUser,
  ServiceValidationErrorsAction,
  SetServiceAssistanceAction,
  SetServiceRequestedDataAction,
  UpdateServiceAction,
  UpdateServiceAssistanceDataAction,
  UpdateServiceAssistanceDataKoAction,
  UpdateServiceFormFields,
  UpdateServiceKoAction,
  UpdateServiceOkAction,
  UploadDocumentServiceAction,
  UploadDocumentServiceKoAction,
  UploadDocumentServiceOkAction,
} from './definitions';

export function getServiceData(id: string): GetServiceDataAction {
  return {
    type: ServiceDataConsts.GET_SERVICE_DATA,
    id,
  };
}

export function getServiceDataOk(serviceData: ServiceData): GetServiceDataOkAction {
  return {
    type: ServiceDataConsts.GET_SERVICE_DATA_OK,
    serviceData,
  };
}

export function getServiceDataKo(error: string): GetServiceDataKoAction {
  return {
    type: ServiceDataConsts.GET_SERVICE_DATA_KO,
    error,
  };
}

export function getServices(role?: string): GetServicesAction {
  return {
    type: ServiceDataConsts.GET_SERVICES,
    serviceData: {} as ServiceData,
    role,
  };
}

export function getServicesOk(serviceList: ServiceData[], subareas: LookUpSubarea, role?: string): GetServicesOkAction {
  return {
    type: ServiceDataConsts.GET_SERVICES_OK,
    serviceList,
    subareas,
    role,
  };
}

export function getServicesKo(error: string): GetServicesKoAction {
  return {
    type: ServiceDataConsts.GET_SERVICES_KO,
    error,
  };
}

export function getUserServices(): GetUserServicesAction {
  return {
    type: ServiceDataConsts.GET_USER_SERVICES,
  };
}

export function getUserServicesOk(servicesUserList: ServicesUser[]): GetUserServicesOkAction {
  return {
    type: ServiceDataConsts.GET_USER_SERVICES_OK,
    servicesUserList,
  };
}

export function getUserServicesKo(error: string): GetUserServicesKoAction {
  return {
    type: ServiceDataConsts.GET_USER_SERVICES_KO,
    error,
  };
}

export function deleteService(id: number): DeleteServiceAction {
  return {
    type: ServiceDataConsts.DELETE_SERVICE,
    id,
  };
}

export function deleteServiceOk(id: number): DeleteServiceOkAction {
  return {
    type: ServiceDataConsts.DELETE_SERVICE_OK,
    id,
  };
}

export function deleteServiceKo(error: string): DeleteServiceKoAction {
  return {
    type: ServiceDataConsts.DELETE_SERVICE_KO,
    error,
  };
}

export function updateService(data: UpdateServiceFormFields, id: number): UpdateServiceAction {
  return {
    type: ServiceDataConsts.UPDATE_SERVICE,
    id,
    data,
  };
}

export function updateServiceOk({ data }: { data: ServiceData }): UpdateServiceOkAction {
  return {
    type: ServiceDataConsts.UPDATE_SERVICE_OK,
    serviceData: data,
  };
}

export function updateServiceKo(error: string): UpdateServiceKoAction {
  return {
    type: ServiceDataConsts.UPDATE_SERVICE_KO,
    error,
  };
}

export function addUserToService(id: number, service_id: number): AddUserToServiceAction {
  return {
    type: ServiceDataConsts.ADD_USER_TO_SERVICE,
    id,
    service_id,
  };
}

export function addUserToServiceOk(data: ServiceRequestData): AddUserToServiceOkAction {
  return {
    type: ServiceDataConsts.ADD_USER_TO_SERVICE_OK,
    serviceData: data,
  };
}

export function addUserToServiceKo(error: string): AddUserToServiceKoAction {
  return {
    type: ServiceDataConsts.ADD_USER_TO_SERVICE_KO,
    error,
  };
}

export function serviceCreationData(key: string, value: string | number | null): ServiceCreationDataAction {
  return {
    type: ServiceDataConsts.SERVICE_CREATION_DATA,
    key,
    value,
  };
}

// VALIDATIONS ERRORS
export function serviceValidationErrors(validationErrors: APIValidationError): ServiceValidationErrorsAction {
  return {
    type: ServiceDataConsts.SERVICE_VALIDATION_ERRORS,
    validationErrors,
  };
}

//GET SERVICE REQUESTED
export function getServiceRequestedData(id: number): GetServiceRequestedDataAction {
  return {
    type: ServiceDataConsts.GET_SERVICES_REQUESTED,
    id,
  };
}

export function getServiceRequestedOk(serviceRequests: ServiceRequestData[]): GetServiceRequestedDataOkAction {
  return {
    type: ServiceDataConsts.GET_SERVICES_REQUESTED_OK,
    serviceRequests,
  };
}

export function getServiceRequestedKo(error: string): GetServiceRequestedDataKoAction {
  return {
    type: ServiceDataConsts.GET_SERVICES_REQUESTED_KO,
    error,
  };
}

//SET SERVICES REQUESTED

export function setServiceRequested(
  key: keyof ServiceRequestData,
  value: string | number | boolean,
  id: number,
): SetServiceRequestedDataAction {
  return {
    type: ServiceDataConsts.SET_SERVICES_REQUESTED,
    key,
    value,
    id,
  };
}

export function setServiceAssistance(assistance: string): SetServiceAssistanceAction {
  return {
    type: ServiceDataConsts.SET_SERVICES_ASSISTANCE,
    assistance,
  };
}

//UPDATE ASSISTANCE
export function updateServiceAssistanceData(data: ServiceRequestData[], id: number): UpdateServiceAssistanceDataAction {
  return {
    type: ServiceDataConsts.UPDATE_SERVICE_ASSISTANCE,
    id,
    data,
  };
}

export function updateServiceAssistanceKo(error: string): UpdateServiceAssistanceDataKoAction {
  return {
    type: ServiceDataConsts.UPDATE_SERVICE_ASSISTANCE_KO,
    error,
  };
}

export function removeServiceRequested(requestedId: number): RemoveServiceRequestedDataAction {
  return {
    type: ServiceDataConsts.REMOVE_SERVICE_REQUESTED,
    requestedId,
  };
}

export function removeServiceRequestedOk(requestedId: number): RemoveServiceRequestedDataOkAction {
  return {
    type: ServiceDataConsts.REMOVE_SERVICE_REQUESTED_OK,
    requestedId,
  };
}

export function removeServiceRequestedKo(error: string): RemoveServiceRequestedDataKoAction {
  return {
    type: ServiceDataConsts.REMOVE_SERVICE_REQUESTED_KO,
    error,
  };
}

//UPLOAD DOCUMENT

export function uploadDocumentService(
  documentationId: number,
  data: File,
  serviceId: number,
): UploadDocumentServiceAction {
  return {
    type: ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE,
    documentationId,
    serviceId,
    data,
  };
}

export function uploadDocumentServiceOk(documents: DocumentationItem[]): UploadDocumentServiceOkAction {
  return {
    type: ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE_OK,
    documents,
  };
}

export function uploadDocumentServiceKo(error: string): UploadDocumentServiceKoAction {
  return {
    type: ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE_KO,
    error,
  };
}

//DOWNLOAD DOCUMENT

export function downloadDocumentService(
  fileId: number,
  name: string,
  serviceId: number,
  get = true,
): DownloadDocumentServiceAction {
  return {
    type: ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE,
    fileId,
    name,
    serviceId,
    get,
  };
}

export function downloadDocumentServiceOk(data: Blob, name: string, get = true): DownloadDocumentOkServiceAction {
  return {
    type: ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE_OK,
    data,
    name,
    get,
  };
}

export function downloadDocumentServiceKo(error: string): DownloadDocumentKoServiceAction {
  return {
    type: ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE_KO,
    error,
  };
}

//GET DOCUMENT

export function getDocumentService(fileId: number, name: string, serviceId: number): GetDocumentServiceAction {
  return {
    type: ServiceDataConsts.GET_DOCUMENT_SERVICE,
    fileId,
    name,
    serviceId,
  };
}

export function getDocumentServiceOk(data: Blob, name: string): GetDocumentOkServiceAction {
  return {
    type: ServiceDataConsts.GET_DOCUMENT_SERVICE_OK,
    data,
    name,
  };
}

export function getDocumentServiceKo(error: string): GetDocumentKoServiceAction {
  return {
    type: ServiceDataConsts.GET_DOCUMENT_SERVICE_KO,
    error,
  };
}

//REMOVE DOCUMENT

export function removeDocumentService(fileId: number, serviceId: number): RemoveDocumentServiceAction {
  return {
    type: ServiceDataConsts.REMOVE_DOCUMENT_SERVICE,
    fileId,
    serviceId,
  };
}

export function removeDocumentServiceOk(documents: DocumentationItem[]): RemoveDocumentOkServiceAction {
  return {
    type: ServiceDataConsts.REMOVE_DOCUMENT_SERVICE_OK,
    documents,
  };
}

export function removeDocumentServiceKo(error: string): RemoveDocumentKoServiceAction {
  return {
    type: ServiceDataConsts.REMOVE_DOCUMENT_SERVICE_KO,
    error,
  };
}
