import { ButtonProps } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import OptionsWrapper from '../../../components/Layout/Menus/ActionsMenu/OptionsWrapper';
import TitleActionsMenu from '../../../components/Layout/Menus/ActionsMenu/TitleActionsMenu';
import StepperMenu from '../../../components/StepperMenu/StepperMenu';
import { tProjectKey } from '../../../helpers/translate';
import { ProjectData, StateProject } from '../../../redux/project/definitions';
import styles from './projectMenuRight.module.scss';

const statesStepper = {
  [StateProject.ID_2]: 0,
  [StateProject.EVALUATION_1]: 1,
  [StateProject.EVALUATION_2]: 1,
  [StateProject.AGREEMENT]: 2,
  [StateProject.AGREEMENT_REVIEW]: 3,
  [StateProject.AGREEMENT_SIGNING]: 4,
  [StateProject.SIGNATURE_PENDING]: 5,
  [StateProject.KICK_OFF]: 7,
  [StateProject.TRACKING]: 7,
  [StateProject.TRACKING_VALIDATION]: 7,
  [StateProject.TRACKING_FINISHED]: 7,
  [StateProject.TRACKING_FNC]: 7,
  [StateProject.PAYMENT]: 7,
  [StateProject.FOLLOWUP_EVALUATION_2]: 8,
  [StateProject.FOLLOWUP_EVALUATION_3]: 8,
  [StateProject.FOLLOWUP_EVALUATION_4]: 8,
  [StateProject.END]: 9,
  [StateProject.ARCHIVED]: -1,
};

interface ProjectMenuRightProps {
  actionButtons: ButtonProps[];
  moreInfoButtons?: ButtonProps[];
  project: ProjectData;
}
const ProjectMenuRight: React.FC<ProjectMenuRightProps> = ({ actionButtons, moreInfoButtons = [], project }) => {
  const history = useHistory();

  moreInfoButtons.push({
    children: !project?.state_code ? tProjectKey('Crear entidad') : tProjectKey('Detalle entidad'),
    onClick: !project?.state_code
      ? () => history.push('/entidad/crear')
      : () => history.push(`/entidad/detalles/${project.entity_id}`),
    fullWidth: true,
    color: 'primary',
    disableElevation: true,
  });

  return (
    <div className={styles.container}>
      <TitleActionsMenu>{tProjectKey('Estado')}</TitleActionsMenu>
      <OptionsWrapper>
        <Fragment>
          <StepperMenu activeStep={statesStepper[project.state_code]} state={project.state} totalSteps={10} />
          <span>{project.state_name ? tProjectKey(project.state_name) : tProjectKey('Nuevo proyecto')}</span>
        </Fragment>
      </OptionsWrapper>
      <ActionsMenu actionsButtons={actionButtons} moreInfoButtons={moreInfoButtons} />
    </div>
  );
};

export default ProjectMenuRight;
