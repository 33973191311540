/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { tKey } from '../../helpers/translate';
import { GenericData } from '../../redux/project/definitions';
import CanaryTable, { TableActions } from '../Tables/TableEditableRow/TableEditableRow';
import styles from './genericTable.module.scss';

interface GenericTableProps {
  test?: number;
  genericTableData: GenericData;
  projectID?: number;
  buttons?: ButtonProps[];
  actions?: TableActions[];
  disabled: boolean;
  onSetterData: (data: any) => void;
  handleDeleteClick: (row: any) => void;
}

export const GenericTableDataCreator = (data: any) =>
  data.map((element: any, index: number, arr: any) => {
    if (arr.length - 1 === index && element.id_generated >= 0) {
      return {
        ...element,
        id: element.id,
        isEditMode: true,
      };
    } else {
      return {
        ...element,
        id: element.id,
        isEditMode: false,
      };
    }
  });

const GenericTable: React.FC<GenericTableProps> = ({
  genericTableData,
  buttons,
  actions,
  disabled,
  onSetterData,
  projectID,
  handleDeleteClick,
}) => {
  const [data, setData] = useState([]);
  const { columns } = genericTableData;

  const handleDataSetter = (data: any) => {
    onSetterData(data);
  };

  useEffect(() => {
    setData(GenericTableDataCreator(genericTableData.rows));
  }, [genericTableData]);

  useEffect(() => {
    handleDataSetter(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const options = {
    filter: true,
  };

  return (
    <div className={styles.genericTableContainer}>
      {genericTableData && (
        <CanaryTable
          columns={columns}
          rows={data}
          setRows={setData}
          title={tKey('Participantes')}
          option={options}
          buttons={buttons}
          disabled={disabled}
          actions={actions}
          projectID={projectID}
          handleDeleteClick={handleDeleteClick}
          isTableWithIdGenerated
        />
      )}
    </div>
  );
};

export default GenericTable;
