import React from 'react';
import { connect } from 'react-redux';
import { selectPermissionsList } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import { permissionType } from '../../constants/permissions_definitions';
import { Redirect } from 'react-router-dom';

interface AccountingMenuProps {
  permissions?: permissionType[];
}

const AccountingMenu: React.FC<AccountingMenuProps> = ({ permissions }) => {
  if (permissions && permissions.indexOf('route_accounting') >= 0) {
    return <Redirect to="/contabilidad/enviar-sage" />;
  } else {
    return <Redirect to="/contabilidad/facturas" />;
  }
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
});

export default connect(mapStateToProps, null)(AccountingMenu);
