import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import colorPalette from '../../../helpers/colorPalette';
import { convertFileToBase64 } from '../../../helpers/selectImages';
import { tKey } from '../../../helpers/translate';
import webStyle from '../../../helpers/webStyle';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { FeedbackAPIActionDispatch, SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import styles from './draggableFileInput.module.scss';

interface DraggableImageInputrops {
  image: string | null;
  disabled?: boolean;
  setImage: (image: string) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const DraggableImageInput: React.FC<DraggableImageInputrops> = ({ image, disabled, setImage, showSnackbar }) => {
  const inputImageRef = useRef<HTMLInputElement>(null);
  const [previewImgSrc, setPreviewImgSrc] = useState<string | null>(image);

  const [highlight, setHighligh] = useState(false);

  const openFileDialog = () => {
    if (!disabled && inputImageRef.current) inputImageRef.current.click();
  };

  const isValidImageFile = (file: File): boolean => {
    return Boolean(file.name.match(/.(jpg|jpeg|png|gif|JPEG|JPG|PNG|GIF)$/i));
  };

  const handleOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled && event.target) {
      const { files } = event.target;
      if (files && files?.length > 0) {
        const file = files[0];
        if (isValidImageFile(file)) {
          const img = await convertFileToBase64(file);
          setPreviewImgSrc(img);
          setImage(img);
        } else {
          showSnackbar(tKey('Fichero no compatible'), 'warning', undefined, 2000);
          event.target.value = '';
        }
      }
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!disabled) setHighligh(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighligh(false);
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!disabled && event.dataTransfer) {
      const { files } = event.dataTransfer;
      const file = files[0];
      if (isValidImageFile(file)) {
        const img = await convertFileToBase64(file);
        setPreviewImgSrc(img);
        setImage(img);
      } else showSnackbar(tKey('Fichero no compatible'), 'warning', undefined, 2000);
      setHighligh(false);
    }
  };

  return (
    <div
      className={`${highlight ? styles.highlight : ''} ${disabled ? styles.disabled : styles.dropzone}`}
      onClick={openFileDialog}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <>
        <input
          ref={inputImageRef}
          className={styles.fileInput}
          type="file"
          accept="image/*"
          hidden
          onChange={handleOnChange}
        />
        {previewImgSrc ? (
          <div className={disabled ? styles.fileListDisabled : styles[`fileList_${webStyle}`]}>
            {<img className={styles.previewImage} src={previewImgSrc} alt="" />}
          </div>
        ) : (
          <div className={styles[`uploadContainer_${webStyle}`]}>
            <CloudUploadIcon />
            <span style={{ color: colorPalette.secondary80 }}>
              <span className={styles[`here_${webStyle}`]}>{tKey('Click Aquí')}</span>{' '}
              {tKey('o Arrastra para subir archivos')}
            </span>
          </div>
        )}
      </>
    </div>
  );
};

const mapDispatchToProps = (dispatch: FeedbackAPIActionDispatch) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(null, mapDispatchToProps)(DraggableImageInput);
