import { ScholarshipChangeStateDataConsts } from './action_types';
import {
  AllowedStates,
  GetScholarshipAllowedStatesAction,
  GetScholarshipAllowedStatesOkAction,
  ScholarshipChangeStateAction,
  ScholarshipChangeStateKoAction,
} from './definitions';

export function getScholarshipAllowedStatesAction(scholarship_id: number): GetScholarshipAllowedStatesAction {
  return {
    type: ScholarshipChangeStateDataConsts.SCHOLARSHIP_GET_ALLOWED_STATES,
    scholarship_id,
  };
}

export function getScholarshipAllowedStatesOkAction(allowedStates: AllowedStates): GetScholarshipAllowedStatesOkAction {
  return {
    type: ScholarshipChangeStateDataConsts.SCHOLARSHIP_GET_ALLOWED_STATES_OK,
    allowedStates,
  };
}

export function scholarshipChangeStateAction(
  id: number,
  current_state: string,
  next_state: string,
): ScholarshipChangeStateAction {
  return {
    type: ScholarshipChangeStateDataConsts.SCHOLARSHIP_CHANGE_STATE,
    id,
    current_state,
    next_state,
  };
}

export function scholarshipChangeStateKoAction(error: string): ScholarshipChangeStateKoAction {
  return {
    type: ScholarshipChangeStateDataConsts.SCHOLARSHIP_CHANGE_STATES_KO,
    error,
  };
}
