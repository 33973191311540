import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { tErrorKey, tKey, tProjectKey } from '../../../helpers/translate';
import { ResourceImageData } from '../../../redux/common/definitions';
import DraggableFileInput from '../../Inputs/FileInputController/DraggableFileInput';
import TextInputController from '../../Inputs/TextInputController/TextInputController';
import styles from './imageEditionModal.module.scss';

const requiredText = tErrorKey('Este campo es obligatorio');

export const ImageEditionModalSchema = yup.object().shape({
  name: yup.string().required(requiredText),
  description: yup.string().required(requiredText),
});

interface ImageEditionModalProps {
  imageSelected: ResourceImageData | null;
  disabled?: boolean;
  addImage?: (name: string, description: string, image: string) => void;
  editImage?: (id: number, name: string, description: string, newImage: string) => void;
  deleteImage?: (id: number) => void;
}

const ImageEditionModal: React.FC<ImageEditionModalProps> = ({
  imageSelected,
  disabled = false,
  addImage,
  editImage,
  deleteImage,
}) => {
  const [image, setImage] = useState<string | null>(imageSelected?.image || null);
  const { control, errors, watch, handleSubmit } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ImageEditionModalSchema,
  });

  const showActionButton =
    (watch('name') || imageSelected?.name) && (watch('description') || imageSelected?.description) && image;

  const handleSave = (data: any) => {
    if (image) {
      if (addImage) addImage(data.name, data.description, image);
      else if (editImage) editImage(Number(imageSelected?.id), data.name, data.description, image);
    }
  };

  return (
    <>
      <div className={styles.imageContainer}>
        <div className={styles.leftSide}>
          <TextInputController
            name="name"
            label={tKey('Nombre')}
            defaultValue={imageSelected?.name || ''}
            size="100"
            disabled={disabled}
            schema={ImageEditionModalSchema}
            control={control}
            errors={errors}
          />
          <DraggableFileInput image={image} setImage={setImage} />
        </div>
        <div className={styles.rightSide}>
          <TextInputController
            name="description"
            label={tProjectKey('Descripción')}
            defaultValue={imageSelected?.description || ''}
            size="100"
            disabled={disabled}
            multiline
            rows="12"
            rowsMax="12"
            control={control}
            schema={ImageEditionModalSchema}
            errors={errors}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {deleteImage && (
          <Button variant="outlined" color="primary" onClick={() => deleteImage(Number(imageSelected?.id))}>
            {tKey('Eliminar')}
          </Button>
        )}
        <Button variant="outlined" color="primary" disabled={!showActionButton} onClick={handleSubmit(handleSave)}>
          {tKey('Guardar')}
        </Button>
      </div>
    </>
  );
};

export default ImageEditionModal;
