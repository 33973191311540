import styles from './header.module.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import filteredRoutes from '../../helpers/filteredRoutes';
import { AppState } from '../../redux/root-reducer';
import { selectPermissionsList } from '../../redux/permissions/selectors';
import { connect } from 'react-redux';
import { permissionType } from '../../constants/permissions_definitions';
import { AppRoute } from '../../constants/routes';
import { tKey } from '../../helpers/translate';
import { ApplicationDispatch } from '../../redux/application/definitions';
import { applicationShowMenuMobile } from '../../redux/application/actions';

interface GeneralMenuMobileProps {
  permissions?: permissionType[];
  applicationHideMenuMobile: () => void;
}

const GeneralMenuMobile = ({ permissions, applicationHideMenuMobile }: GeneralMenuMobileProps) => {
  const actualPath = window.location.pathname;
  const filteredOptions: AppRoute[] = filteredRoutes(permissions)
    .filter(
      option => !!option.mobile, //&& option.rol?.includes(userRol),
    )
    .sort((a: AppRoute, b: AppRoute) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      return 0;
    });
  return (
    <ul className={styles.menuList}>
      {filteredOptions.map((route: AppRoute, index: number) => {
        return (
          <li
            key={`${route.path}_${index}`}
            className={`${styles.menuItem} ${actualPath === route.path ? styles.isSelected : ''}`}
          >
            <p className={styles.menuText}>
              <Link to={route.path} className={styles.menuLink} onClick={applicationHideMenuMobile}>
                {tKey(route.title ? route.title : '')} {tKey(route.subtitle ? route.subtitle : '')}
              </Link>
            </p>
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
});

const mapDispatchToProps = (dispatch: ApplicationDispatch) => ({
  applicationHideMenuMobile: (): void => dispatch(applicationShowMenuMobile(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralMenuMobile);
