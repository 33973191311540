import { Action } from 'redux';
import { AreasData } from '../area/definitions';
import { AddressType, CodeNamePair, IdCodeNamePair, TaxRetention, University } from '../common/definitions';
import { MetadataConst } from './action_types';

export interface ResponsibleMetadata {
  id: number;
  name: string;
}

export interface TeamMetadata {
  id: number;
  name: string;
}

export interface TaxType {
  code: string;
  name: string;
}

export interface GenericMetadata {
  loaded: boolean;
  addressTypes: AddressType[];
  countries: string[];
  provinces: string[];
  incomesTypes: string[];
  contractTypes: string[];
  taxRetentions: TaxRetention[];
  areas: Areas[];
  channels: CodeNamePair[];
  delegations: Delegations[];
  projects: Projects[];
  academicYears: string[];
  languages: CodeNamePair[];
  taxTypes: TaxType[];
  currentAcademicYear: string;
  paymentTypes: CodeNamePair[];
}

export interface TransactionCode {
  id: number;
  code: number;
  name: string;
  invoice_type: string;
  default_op_key: string;
  serial: string;
}

export interface OperationKey {
  id: number;
  op_key: string;
  description: string;
}

export type Serial = 'General' | 'INTRA' | 'ISP';
export type InvoiceType = 'Emitida' | 'Recibida' | '';
export interface AccountingMetadata {
  loaded: boolean;
  transaction_codes: TransactionCode[];
  operation_keys: OperationKey[];
  serials: Serial[];
  payment_day: number;
  payment_control_day: number;
  expense_accounts: CodeNamePair[];
}

export interface Academy {
  id: number;
  code: string;
  name: string;
  zip_code: string;
  city: string;
  nature: string;
}

export interface ScholarshipInfoMetadata {
  loaded: boolean;
  academies: Academy[];
  provinces: CodeNamePair[];
  universities: University[];
}

export interface Areas extends CodeNamePair {
  delegation_code?: string | undefined;
}
export interface Delegations extends CodeNamePair {
  channel_code?: string | null;
}
export interface Projects extends CodeNamePair {
  area_code?: string | undefined;
}

export interface ProjectMetadata {
  loaded: boolean;
  customFields_sections: IdCodeNamePair[];
  customFields_inputs: IdCodeNamePair[];
  customFields_sizes: CodeNamePair[];
  customFields_types: string[];
  customFields_radioPosition: IdCodeNamePair[];
  customFields_dependency_conditions: string[];
  areas: AreasData[];
}

export const initialState = {
  error: null as string | null,
  responsibles: [] as ResponsibleMetadata[],
  team: [] as TeamMetadata[],
  generic: {
    loaded: false,
    addressTypes: [],
    countries: [],
    provinces: [],
    incomesTypes: [],
    contractTypes: [],
    taxRetentions: [],
    areas: [],
    channels: [],
    delegations: [],
    projects: [],
    academicYears: [],
    languages: [],
    taxTypes: [],
    currentAcademicYear: '',
    paymentTypes: [],
  } as GenericMetadata,
  project: {
    loaded: false,
    customFields_sections: [],
    customFields_inputs: [],
    customFields_sizes: [],
    customFields_types: [],
    customFields_radioPosition: [],
    customFields_dependency_conditions: [],
    areas: [],
  } as ProjectMetadata,
  accounting: {
    loaded: false,
    payment_day: 25,
    payment_control_day: 10,
    expense_accounts: [],
    transaction_codes: [],
    serials: [],
    operation_keys: [],
  } as AccountingMetadata,
  scholarship: {
    loaded: false,
    academies: [],
    provinces: [],
    universities: [],
  } as ScholarshipInfoMetadata,
};

export type MetadataState = typeof initialState;

export interface GenericMetadataAction extends Action {
  type: MetadataConst.GET_GENERIC;
}

export interface GenericMetadataOkAction extends Action {
  type: MetadataConst.GET_GENERIC_OK;
  genericData: GenericMetadata;
}

export interface GenericMetadataKoAction extends Action {
  type: MetadataConst.GET_GENERIC_KO;
  error: string;
}

export interface ProjectMetadataAction extends Action {
  type: MetadataConst.GET_PROJECT_METADATA;
}

export interface ProjectMetadataOkAction extends Action {
  type: MetadataConst.GET_PROJECT_METADATA_OK;
  projectData: ProjectMetadata;
}

export interface ProjectMetadataKoAction extends Action {
  type: MetadataConst.GET_PROJECT_METADATA_KO;
  error: string;
}

export interface ResponsibleMetadataAction extends Action {
  type: MetadataConst.GET_RESPONSIBLES;
}

export interface ResponsibleMetadataOkAction extends Action {
  type: MetadataConst.GET_RESPONSIBLES_OK;
  responsibles: ResponsibleMetadata[];
}

export interface ResponsibleMetadataKoAction extends Action {
  type: MetadataConst.GET_RESPONSIBLES_KO;
  error: string;
}

export interface TeamMetadataAction extends Action {
  type: MetadataConst.GET_TEAM;
}

export interface TeamMetadataOkAction extends Action {
  type: MetadataConst.GET_TEAM_OK;
  team: TeamMetadata[];
}

export interface TeamMetadataKoAction extends Action {
  type: MetadataConst.GET_TEAM_KO;
  error: string;
}

export interface AccountingMetadataAction extends Action {
  type: MetadataConst.GET_ACCOUNTING_METADATA;
}

export interface AccountingMetadataOkAction extends Action {
  type: MetadataConst.GET_ACCOUNTING_METADATA_OK;
  accountingData: AccountingMetadata;
}

export interface AccountingMetadataKoAction extends Action {
  type: MetadataConst.GET_ACCOUNTING_METADATA_KO;
  error: string;
}

export interface ScholarshipMetadataAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_METADATA;
}

export interface ScholarshipMetadataOkAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_METADATA_OK;
  scholarshipData: ScholarshipInfoMetadata;
}

export interface ScholarshipMetadataKoAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_METADATA_KO;
  error: string;
}

export interface ScholarshipAcademiesMetadataAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA;
}

export interface ScholarshipAcademiesMetadataOkAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA_OK;
  academies: ScholarshipInfoMetadata['academies'];
}

export interface ScholarshipAcademiesMetadataKoAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA_KO;
  error: string;
}

export interface ScholarshipUniversitiesMetadataAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA;
}

export interface ScholarshipUniversitiesMetadataOkAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA_OK;
  universities: ScholarshipInfoMetadata['universities'];
}

export interface ScholarshipUniversitiesMetadataKoAction extends Action {
  type: MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA_KO;
  error: string;
}

export type MetadataAction =
  | GenericMetadataAction
  | GenericMetadataOkAction
  | GenericMetadataKoAction
  | ProjectMetadataAction
  | ProjectMetadataOkAction
  | ProjectMetadataKoAction
  | ResponsibleMetadataAction
  | ResponsibleMetadataOkAction
  | ResponsibleMetadataKoAction
  | TeamMetadataAction
  | TeamMetadataOkAction
  | TeamMetadataKoAction
  | AccountingMetadataAction
  | AccountingMetadataOkAction
  | AccountingMetadataKoAction
  | ScholarshipMetadataAction
  | ScholarshipMetadataOkAction
  | ScholarshipMetadataKoAction
  | ScholarshipAcademiesMetadataAction
  | ScholarshipAcademiesMetadataOkAction
  | ScholarshipAcademiesMetadataKoAction
  | ScholarshipUniversitiesMetadataAction
  | ScholarshipUniversitiesMetadataOkAction
  | ScholarshipUniversitiesMetadataKoAction;

export type MetadataDispatch = (action: MetadataAction) => void;
