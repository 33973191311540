import { put, takeLatest } from '@redux-saga/core/effects';
import {
  ChangeScholarshipPaymentDateAction,
  SendScholarshipPaymentAction,
  SendScholarshipReloadAction,
} from './definitions';
import {
  changeScholarshipPaymentDateFromApi,
  sendScholarshipPaymentDataFromApi,
  sendScholarshipReloadDataFromApi,
  sendScholarshipStudentTransferDataFromApi,
} from '../../../api/Scholarship/scholarshipInstitutePayments';
import {
  changeScholarshipPaymentDateKo,
  changeScholarshipPaymentDateOk,
  sendScholarshipPaymentActionKo,
  sendScholarshipPaymentActionOk,
  sendScholarshipReloadActionKo,
  sendScholarshipReloadActionOk,
  sendScholarshipStudentTransferActionKo,
  sendScholarshipStudentTransferActionOk,
} from './actions';
import { showSnackbar } from '../../FeedbackAPI/actions';
import errorMessage from '../../../helpers/errorMessage';
import { ScholarshipInstitutePaymentsConst } from './action_types';

function* sendScholarshipReloadFromSaga(action: SendScholarshipReloadAction): Generator<any, void, any> {
  try {
    yield sendScholarshipReloadDataFromApi(action.reloadData);
    yield put(sendScholarshipReloadActionOk());
    yield put(showSnackbar('La recarga se ha realizado con éxito', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 1500));
    yield put(sendScholarshipReloadActionKo(errorMessage(e)));
  }
}

function* sendScholarshipPaymentFromSaga(action: SendScholarshipPaymentAction): Generator<any, void, any> {
  try {
    yield sendScholarshipPaymentDataFromApi(action.paymentData);
    yield put(sendScholarshipPaymentActionOk());
    yield put(showSnackbar('El pago se ha realizado con éxito', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 1500));
    yield put(sendScholarshipPaymentActionKo(errorMessage(e)));
  }
}

function* sendScholarshipStudentTransferFromSaga(action: SendScholarshipPaymentAction): Generator<any, void, any> {
  try {
    yield sendScholarshipStudentTransferDataFromApi(action.paymentData);
    yield put(sendScholarshipStudentTransferActionOk());
    yield put(showSnackbar('La transferencia se ha realizado con éxito', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 1500));
    yield put(sendScholarshipStudentTransferActionKo(errorMessage(e)));
  }
}

function* changeScholarshipPaymentDateFromSaga(action: ChangeScholarshipPaymentDateAction): Generator<any, void, any> {
  try {
    yield changeScholarshipPaymentDateFromApi(action.changeDateValues);
    yield put(changeScholarshipPaymentDateOk());
    yield put(showSnackbar('Se ha modificado la fecha correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 1500));
    yield put(changeScholarshipPaymentDateKo(errorMessage(e)));
  }
}

export default [
  takeLatest<SendScholarshipReloadAction>(
    ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_RELOAD,
    sendScholarshipReloadFromSaga,
  ),
  takeLatest<SendScholarshipPaymentAction>(
    ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_PAYMENT,
    sendScholarshipPaymentFromSaga,
  ),
  takeLatest<SendScholarshipPaymentAction>(
    ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_STUDENT_TRANSFER,
    sendScholarshipStudentTransferFromSaga,
  ),
  takeLatest<ChangeScholarshipPaymentDateAction>(
    ScholarshipInstitutePaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE,
    changeScholarshipPaymentDateFromSaga,
  ),
];
