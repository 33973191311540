import styles from './header.module.scss';
import { Link } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';
import { tKey } from '../../helpers/translate';
import { ReactComponent as iconExit } from '../../images/header/iconExit.svg';
import { ReactComponent as iconProfile } from '../../images/header/iconProfile.svg';
import React from 'react';
import { AuthDispatch } from '../../redux/auth/definitions';
import { connect } from 'react-redux';
import { getRoute } from '../../helpers/Routes';
import { applicationShowMenuMobile } from '../../redux/application/actions';
import { logoutAction } from '../../redux/auth/actions';

interface ProfileMenuProps {
  logoutApp: () => void;
  applicationHideMenuMobile: () => void;
}
const ProfileMenuMobile = ({ applicationHideMenuMobile, logoutApp }: ProfileMenuProps) => {
  return (
    <ul className={styles.menuProfile}>
      <li className={styles.menuProfileItem}>
        <p className={styles.menuProfileTextWrapper}>
          <Link
            to={getRoute('mi-perfil')}
            className={`${styles.menuProfileLink} ${styles.hasIcon}`}
            onClick={applicationHideMenuMobile}
          >
            <SvgIcon className={styles.menuProfileIcon} viewBox="0 0 22 22" component={iconProfile} />
            <span className={styles.menuProfileText}>{tKey('Mi perfil')}</span>
          </Link>
        </p>
      </li>
      <li className={styles.menuProfileItem}>
        <p className={styles.menuProfileTextWrapper}>
          <Link
            to={''}
            className={`${styles.menuProfileLink} ${styles.hasIcon}`}
            onClick={() => {
              logoutApp();
            }}
          >
            <SvgIcon className={styles.menuProfileIcon} viewBox="0 0 22 22" component={iconExit} />
            <span className={styles.menuProfileText}>{tKey('Cerrar sesión')}</span>
          </Link>
        </p>
      </li>
    </ul>
  );
};

const mapDispatchToProps = (dispatch: AuthDispatch | any) => ({
  logoutApp: (): void => dispatch(logoutAction()),
  applicationHideMenuMobile: (): void => dispatch(applicationShowMenuMobile(false)),
});

export default connect(null, mapDispatchToProps)(ProfileMenuMobile);
