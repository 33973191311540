import { useState } from 'react';
import { ChangeDateValues, ScholarshipPayment } from '../../../../redux/scholarship/definitions';

type UseScholarshipChangeDateType = {
  showDateModal: boolean;
  changeDateData: ChangeDateValues;
  closeDateModal: () => void;
  setChangeDateInfo: (scholarshipPayment: ScholarshipPayment, isEntity: boolean) => void;
};

const useScholarshipChangeDate = (): UseScholarshipChangeDateType => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [changeDateData, setChangeDateDate] = useState({} as ChangeDateValues);

  const setChangeDateInfo = (scholarshipPayment: ScholarshipPayment, isEntity: boolean) => {
    setChangeDateDate({
      scholarship_id: scholarshipPayment.scholarship_id,
      payment_field: scholarshipPayment.payment_field,
      payment_type: isEntity ? 'entity' : 'institute',
    } as ChangeDateValues);
    setShowDateModal(true);
  };

  const closeDateModal = () => {
    setShowDateModal(false);
  };

  return {
    showDateModal,
    changeDateData,
    closeDateModal,
    setChangeDateInfo,
  };
};

export default useScholarshipChangeDate;
