import { ButtonProps, Checkbox } from '@material-ui/core';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import Delete from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tFormKey, tKey } from '../../../helpers/translate';
import {
  CodeSectionEvent,
  CopyEvent,
  EventData,
  EventRegistrationData,
  EventSubscriptionData,
} from '../../../redux/event/definitions';
import SubmenuEvent from '../CreateEvent/SubmenuEvent';
import styles from './events.module.scss';
import RoleCode from '../../../types/Roles/roleCode';

interface EventSubscriptionPageProps extends RouteComponentProps<{ id: string; code: CodeSectionEvent }> {
  loading: boolean;
  event: EventData;
  eventContactsList: (tipo: string) => EventSubscriptionData[];
  registration: boolean;
  removeRegister: (eventId: number, registrationId: number) => void;
  updateInscriptionData: (eventId: number, eventRegistrationsList: EventSubscriptionData[]) => void;
  userCan: (permission: permissionType) => boolean | undefined;
  setRegistrationData: (key: string, value: string, id: number) => void;
  getEventContacts: (eventId: number) => void;
  setAssistance: (attended: string, code: string) => void;
  createRegistration: (data: EventRegistrationData) => void;
  createSubscription: (data: EventRegistrationData) => void;
  getEvent: (id: string) => void;
}

const AddNewRegistrationModal = React.lazy(() => import('../Modal/CreateRegistration/ModalRegister'));

export const EventContacts: React.FC<EventSubscriptionPageProps> = ({
  loading,
  event,
  eventContactsList,
  registration,
  match,
  removeRegister,
  getEvent,
  updateInscriptionData,
  userCan,
  setRegistrationData,
  getEventContacts,
  setAssistance,
  createRegistration,
  createSubscription,
}) => {
  const {
    params: { id, code },
  } = match;

  const history = useHistory();
  const role_codes = code === 'jovenes' ? [RoleCode.JOVEN_ENTIDAD, RoleCode.JOVEN_INSTITUTO] : [RoleCode.EDUCADOR];
  const submenu = registration ? 'Lista de inscritos' : 'Lista de suscritos';

  const [dataToShow, setData] = useState<EventSubscriptionData[]>([]);

  const [create, setCreate] = useState({
    view: false,
    copy: false,
    detail: {} as CopyEvent,
  });

  const { handleSubmit } = useForm<EventData>({
    mode: 'onSubmit',
    submitFocusError: true,
  });

  useEffect(() => {
    if (!Object.keys(event).length) getEvent(id);
    getEventContacts(+id);
    eventContactsList(code);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (eventContactsList.length > 0) setData(eventContactsList(code));
  }, [eventContactsList]); // eslint-disable-line react-hooks/exhaustive-deps

  const action_pma = userCan('action_pma');
  const action_dir = userCan('action_dir');

  const columnList: {
    title: string;
    field: string;
    render?: any;
  }[] = [
    { title: tKey('Nombre'), field: 'name' },
    { title: tKey('Apellidos'), field: 'surname' },
    { title: tKey('Número de documento'), field: 'document_number' },
    { title: tKey('Email'), field: 'email' },
    { title: tKey('Ciudad'), field: 'city' },
    { title: tKey('Teléfono'), field: 'phone' },
    {
      title: tKey('LOPD'),
      field: 'lopd',
    },
    {
      title: tKey('Zing'),
      field: 'zing_network',
    },
    {
      title: tKey('Manual'),
      field: 'manual',
    },
  ];

  if (registration && code === 'jovenes') {
    columnList.push(
      { title: tKey('Fecha de nacimiento'), field: 'birth_date' },
      { title: tKey('Instituto'), field: 'academy' },
      { title: tKey('Tipo de educación'), field: 'education_type' },
      { title: tKey('Año académico'), field: 'academic_year' },
      { title: tKey('Descripción educación'), field: 'education_description' },
      //@ts-ignore
      {
        title: tKey('Estudiante'),
        field: 'student',
      },
      {
        title: tKey('Asistencia'),
        field: 'assistance',
        //@ts-ignore
        disableClick: true,
        render: (rowData: EventSubscriptionData) => (
          <>
            <span>{rowData.assistance}</span>
            <Checkbox
              className={styles.list__check}
              checked={rowData.assistance !== 'No'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRegistrationData('assistance', e.target.checked ? 'Sí' : 'No', rowData.id)
              }
            />
          </>
        ),
      },
    );
  } else {
    columnList.push({ title: tKey('Entidad'), field: 'entity' });
  }

  if (registration && code === 'educadores') {
    columnList.push(
      {
        title: tKey('Fecha de alta'),
        field: 'created_at',
        render: (rowData: EventSubscriptionData): any => {
          return rowData.created_at.split(' ')[0];
        },
      },
      {
        title: tKey('Asistencia'),
        field: 'assistance',
        //@ts-ignore
        disableClick: true,
        render: (rowData: EventSubscriptionData) => (
          <>
            <span>{rowData.assistance}</span>
            <Checkbox
              className={styles.list__check}
              checked={rowData.assistance !== 'No'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRegistrationData('assistance', e.target.checked ? 'Sí' : 'No', rowData.id)
              }
            />
          </>
        ),
      },
    );
  }

  const actionIcons = {
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };
  const tableData: MaterialTableProps<EventSubscriptionData> = {
    title: registration ? tKey('Listado de inscripciones') : tKey('Listado de suscriptores'),
    columns: columnList,
    data: dataToShow,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, registration ? tKey('Inscripciones en eventos') : tKey('Suscripciones a eventos'));
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    actions: [
      (rowData: EventSubscriptionData) => ({
        icon: actionIcons.Delete,
        onClick: () => removeRegister(rowData.event_id, rowData.id),
        tooltip: 'borrar',
      }),
    ],
  };

  const goToNew = () => {
    setCreate({ view: true, copy: false, detail: { id: event.id } as CopyEvent });
  };

  let buttonsValidations: ButtonProps[] = [];

  if (registration) {
    buttonsValidations = [
      {
        children: tKey('ACTUALIZAR DATOS'),
        onClick: () => updateInscriptionData(+id, dataToShow),
        startIcon: <SaveIcon />,
        color: 'primary',
        variant: 'contained',
        fullWidth: true,
        disableElevation: true,
      },
      {
        children: tKey('MARCAR TODO'),
        onClick: handleSubmit(() => setAssistance('Sí', code)),
        startIcon: <CheckBox />,
        color: 'primary',
        variant: 'contained',
        fullWidth: true,
        disableElevation: true,
      },
      {
        children: tKey('DESMARCAR TODO'),
        onClick: handleSubmit(() => setAssistance('No', code)),
        startIcon: <CheckBoxOutlineBlank />,
        color: 'primary',
        variant: 'contained',
        fullWidth: true,
        disableElevation: true,
      },
    ];
  }

  const buttonTable = (show: boolean) => {
    if (show) {
      return {
        newButtonText: registration ? tFormKey('Nueva inscripción') : tFormKey('Nueva suscripción'),
        onNew: goToNew,
      };
    }
  };

  const goUserDetail = (rowData: EventSubscriptionData) => {
    const { user_id } = rowData;
    user_id &&
      history.push({
        pathname: `/usuario/detalles/${user_id}`,
        state: { id: user_id },
      });
  };

  return (
    <LayoutForm
      leftSubmenu={<SubmenuEvent event={event} current_section={code} submenu={submenu} />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} />}
    >
      <div className={styles.container}>
        <TableWithEdit
          tableData={{ ...tableData, isLoading: loading }}
          permission={action_pma || action_dir}
          exportButton
          filtering
          optionsNew={buttonTable(!+event.cancelled)}
          onRowClick={goUserDetail}
        />
      </div>
      <Suspense fallback={<></>}>
        {create.view ? (
          <AddNewRegistrationModal
            createRegistration={createRegistration}
            createSubscription={createSubscription}
            registration={registration}
            view={create.view}
            copy={create.copy}
            detail={create.detail}
            handleClose={() => setCreate({ view: false, copy: false, detail: {} as CopyEvent })}
            role_codes={role_codes}
            registeredUsers={dataToShow}
          />
        ) : (
          <Fragment />
        )}
      </Suspense>
    </LayoutForm>
  );
};
