import { ButtonProps } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getZingMembersApi, removeZingMemberApi, sendZingMembersApi } from '../../../api/user';
import FormContainer from '../../../components/Forms/FormContainer';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import Title from '../../../components/Title/Title';
import { getLastItem } from '../../../helpers/getLastItem';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { FeedbackAPIActionDispatch, SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { UserZingMember } from '../../../redux/user/definitions';
import SubmenuConfiguration from '../SubmenuConfiguration';
import styles from './joinNetwork.module.scss';

interface InitialState {
  zingMembers: UserZingMember[];
  loading: boolean;
  error: string | null;
  message: string | null;
}

const actionTypes = {
  GET_USER_ZING_MEMBERS: 'GET:USER:ZING:MEMBERS',
  GET_USER_ZING_MEMBERS_OK: 'GET:USER:ZING:MEMBERS:OK',
  DELETE_USER_ZING_MEMBERS: 'DELETE:USER:ZING:MEMBERS',
  DELETE_USER_ZING_MEMBERS_OK: 'DELETE:USER:ZING:MEMBERS:OK',
  SEND_USER_ZING_MEMBERS: 'SEND:USER:ZING:MEMBERS',
  SEND_USER_ZING_MEMBERS_OK: 'SEND:USER:ZING:MEMBERS:OK',
  USER_ZING_MEMBERS_KO: 'USER:ZING:MEMBERS:KO',
};

const initialState: InitialState = {
  zingMembers: [],
  loading: false,
  error: null,
  message: null,
};

const reducer = (state: InitialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_USER_ZING_MEMBERS:
    case actionTypes.DELETE_USER_ZING_MEMBERS:
    case actionTypes.SEND_USER_ZING_MEMBERS: {
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
      };
    }
    case actionTypes.GET_USER_ZING_MEMBERS_OK:
    case actionTypes.SEND_USER_ZING_MEMBERS_OK: {
      const { members, message } = action.data;
      return {
        ...state,
        zingMembers: members,
        message,
        loading: false,
      };
    }
    case actionTypes.DELETE_USER_ZING_MEMBERS_OK: {
      const { id } = action.data;
      return {
        ...state,
        zingMembers: state.zingMembers.filter((e: UserZingMember) => e.id !== id),
        loading: false,
      };
    }
    case actionTypes.USER_ZING_MEMBERS_KO: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};

function getZingMembers(dispatch: any) {
  getZingMembersApi()
    .then(res => {
      dispatch({
        type: actionTypes.GET_USER_ZING_MEMBERS_OK,
        data: {
          members: res,
          message: null,
        },
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.USER_ZING_MEMBERS_KO,
        error: tErrorKey('No se han podido cargar los jóvenes'),
      });
    });
}

function sendZingMembers(selectedMembers: number[], dispatch: any) {
  sendZingMembersApi(selectedMembers)
    .then(res => {
      dispatch({
        type: actionTypes.SEND_USER_ZING_MEMBERS_OK,
        data: {
          members: res,
          message: 'Envío realizado correctamente',
        },
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.USER_ZING_MEMBERS_KO,
        error: tErrorKey('No se han podido enviar los jóvenes'),
      });
    });
}

function deleteZingMember(member_id: number, dispatch: any) {
  dispatch({
    type: actionTypes.DELETE_USER_ZING_MEMBERS,
  });
  removeZingMemberApi(member_id)
    .then(res => {
      if (res) {
        dispatch({
          type: actionTypes.DELETE_USER_ZING_MEMBERS_OK,
          data: { id: member_id },
        });
      } else {
        dispatch({
          type: actionTypes.USER_ZING_MEMBERS_KO,
          error: tErrorKey('No se han borrar el joven'),
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.USER_ZING_MEMBERS_KO,
        error: tErrorKey('No se han borrar el joven'),
      });
    });
}

const tableDataMembers: MaterialTableProps<UserZingMember> = {
  title: '',
  columns: [
    {
      title: tKey('Joven'),
      field: 'student',
    },
    {
      title: tKey('Educador'),
      field: 'educator',
    },
    {
      title: tKey('Curso'),
      field: 'academic_year',
    },
    {
      title: tKey('Estado beca'),
      field: 'state',
    },
  ],
  data: [],
  options: {
    tableLayout: 'auto',
    selection: true,
    exportButton: false,
    pageSize: 50,
    pageSizeOptions: [10, 20, 50],
  },
};

interface JoinNetworkProps extends RouteComponentProps {
  currentAcademicYear: string;
  showSnackbar: (message: string, severity: SeveritySnackbar, redirect?: string, autoHideDuration?: number) => void;
}

const JoinNetwork: React.FC<JoinNetworkProps> = ({ match, currentAcademicYear, showSnackbar }) => {
  const { path } = match;
  const [selectedZingMembers, setSelectedZingMembers] = useState<number[]>([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getZingMembers(dispatch);
  }, []);

  useEffect(() => {
    if (state.message !== null) showSnackbar(state.message ? state.message : '', 'success', undefined, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.message]);

  useEffect(() => {
    if (state.error !== null) showSnackbar(state.error ? state.error : '', 'error', undefined, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error]);

  const buttons: ButtonProps[] = [
    {
      children: tKey('Enviar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      disabled: selectedZingMembers.length === 0,
      onClick: () => sendZingMembers(selectedZingMembers, dispatch),
    },
  ];

  tableDataMembers.title = `Nuevos jóvenes para el curso ${currentAcademicYear}`;
  tableDataMembers.isLoading = state.loading;
  tableDataMembers.data = state.zingMembers;

  const selectedMembers = (members: UserZingMember | UserZingMember[]) => {
    const selected = [] as number[];
    if (Array.isArray(members)) {
      members.forEach(member => {
        selected.push(member.id);
      });
    } else {
      selected.push(members.id);
    }
    setSelectedZingMembers(selected);
  };

  return (
    <LayoutForm
      leftSubmenu={<SubmenuConfiguration selected={getLastItem(path)} submenu={tFormKey('Alta Network')} />}
      rightSubmenu={<ActionsMenu actionsButtons={buttons} />}
    >
      <Title>{tFormKey('Alta Network')}</Title>
      <div className={styles.generalContainer}>
        <FormContainer title="">
          <TableWithEdit
            onSelectionChange={selectedMembers}
            tableData={tableDataMembers}
            deleteFunc={(rowData: UserZingMember) => deleteZingMember(rowData.id, dispatch)}
            filtering
          />
        </FormContainer>
      </div>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  currentAcademicYear: selectGenericMetadata(state).currentAcademicYear,
});

const mapDispatchToProps = (dispatch: FeedbackAPIActionDispatch) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, redirect?: string, autoHideDuration?: number) =>
    dispatch(showSnackbar(message, severity, redirect, autoHideDuration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinNetwork);
