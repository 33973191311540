import { ButtonProps } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import Title from '../../../../components/Title/Title';
import ContainerWYSIWYG from '../../../../components/Wysiwyg/ContainerWYSIWYG';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { CodeNamePair } from '../../../../redux/common/definitions';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import {
  cleanLopdTranslation,
  getLopdTranslation,
  setLopdTranslation,
  updateLopdTranslation,
} from '../../../../redux/translations/actions';
import { LOPDMail, SendDataLOPD, TranslationKeyDispatch } from '../../../../redux/translations/definitions';
import SubmenuTranslations from '../SubmenuTranslations';
import styles from './mailTranslationsDetails.module.scss';

interface MailTranslationsLOPDProps extends RouteComponentProps {
  mails: LOPDMail;
  langs: CodeNamePair[];
  canIconfigTranslations: boolean;
  canIconfigMailTranslations: boolean;
  setLopdTranslation: (key: keyof LOPDMail, value: string) => void;
  cleanLopdTranslation: (mails: LOPDMail) => void;
  getLopdTranslation: () => void;
  updateLopdTranslation: (data: SendDataLOPD) => void;
}

export const MailTranslationsLOPD: React.FC<MailTranslationsLOPDProps> = ({
  match,
  mails,
  langs,
  canIconfigTranslations,
  canIconfigMailTranslations,
  setLopdTranslation,
  cleanLopdTranslation,
  getLopdTranslation,
  updateLopdTranslation,
}) => {
  const history = useHistory();
  const [updated, setUpdated] = useState(false);
  const originalValue = useRef({} as LOPDMail);
  const { path } = match;
  const selectedSection = path.substring(path.lastIndexOf('/') + 1);

  useEffect(() => {
    getLopdTranslation();
  }, [getLopdTranslation]);

  useEffect(() => {
    if (!Object.keys(originalValue.current).length) {
      originalValue.current = mails;
    }
  }, [mails]);

  useEffect(() => {
    return () => {
      originalValue.current = mails;
      cleanLopdTranslation(originalValue.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updated]);

  const submit = () => {
    updateLopdTranslation(mails);
    setUpdated(!updated);
  };
  const onChange = (key: keyof LOPDMail) => (data: string) => setLopdTranslation(key, data);

  const buttons: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      startIcon: <SaveIcon />,
      onClick: submit,
    },
  ];

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuTranslations
          canConfigure={canIconfigTranslations || canIconfigMailTranslations}
          selected={selectedSection}
          submenu={selectedSection}
        />
      }
      rightSubmenu={
        <ActionsMenu actionsButtons={buttons} goBack onGoBack={() => history.push('/configuracion/traducciones')} />
      }
    >
      <form className={styles.form_container}>
        <Title>{tFormKey('Correos LOPD')}</Title>
        {langs.map(({ code, name }) => (
          <Fragment key={code}>
            <SubTitle>{tKey(name || '')}</SubTitle>
            <ContainerWYSIWYG
              defaultValue={mails[`lopd_text_${code}` as keyof LOPDMail]}
              setData={onChange(`lopd_text_${code}` as keyof LOPDMail)}
              label_origin={tKey('Texto origen')}
              label={tKey('Texto')}
              updated={updated}
            />
          </Fragment>
        ))}
      </form>
    </LayoutForm>
  );
};

export default connect(
  (state: AppState) => ({
    mails: state.translationReducer.lopdMails,
    langs: state.metadataReducer.generic.languages,
    canIconfigTranslations: selectUserCan(state)('allow_translation_config'),
    canIconfigMailTranslations: selectUserCan(state)('allow_mail_translation_config'),
  }),
  (dispatch: TranslationKeyDispatch) => ({
    setLopdTranslation: (key: keyof LOPDMail, value: string): void => dispatch(setLopdTranslation(key, value)),
    cleanLopdTranslation: (mails: LOPDMail): void => dispatch(cleanLopdTranslation(mails)),
    getLopdTranslation: (): void => dispatch(getLopdTranslation()),
    updateLopdTranslation: (data: SendDataLOPD): void => dispatch(updateLopdTranslation(data)),
  }),
)(MailTranslationsLOPD);
