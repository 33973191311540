import { ButtonProps } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import GenericFormRefactor, { Fields } from '../../../../components/GenericForm/GenericFormRefactor';
import GenericTable from '../../../../components/GenericForm/GenericTable';
import Loading from '../../../../components/Loading/Loading';
import * as DateConstants from '../../../../constants/date';
import { debounce } from '../../../../helpers/debounce';
import { tProjectKey } from '../../../../helpers/translate';
import {
  addDocumentFollowUp,
  addNewGenericTableRow,
  deleteDocumentsInternalProject,
  deleteRowParticipante,
  downloadDocumentsInternalProject,
  setRegisterGenericTableRow,
} from '../../../../redux/internal-projects/actions';
import { GenericData } from '../../../../redux/project/definitions';
import styles from './internalProjectGeneric.module.scss';

interface InternalProjectGenericProps {
  buttonSave: ButtonProps;
  buttonSend: ButtonProps;
  //genericTableData: GenericData;
  loadingFields?: boolean;
  fieldsData: Fields;
  participante?: GenericData;
  id: number; // fixing reload page
  idFollow?: number;
  section?: string;
  disabled: boolean;
  setterData?: any;
  setSetterData?: any;
  showProjectInfo: boolean;
  onSave: (data: any, rows?: any[]) => void;
  onSend: (data: any, rows?: any[]) => void;
  addDocument: (
    internal_project_id: number,
    follow_up_id: number,
    file: File,
    description: string,
    custom_field_name: string,
    section: string,
  ) => void;
  downloadDocumentFollowUp: (
    file_id: number,
    name: string,
    internal_project_id: number,
    follow_up_id: number,
    get?: boolean,
  ) => void;
  removeDocumentFollow: (file_id: number, internal_project_id: number, follow_up_id: number, inputName: string) => void;
  addNewRow: (setterData?: any) => void;
  setRegisterGenericTableRow: (data: any) => void;
  deleteRowParticipante: (idRow: number | null, section: string, idGenerated: number | null) => void;
}
const InternalProjectGeneric: React.FC<InternalProjectGenericProps> = ({
  buttonSave,
  buttonSend,
  //genericTableData,
  loadingFields,
  disabled,
  fieldsData,
  id,
  idFollow,
  section,
  participante,
  setterData,
  setSetterData,
  showProjectInfo,
  setRegisterGenericTableRow,
  onSave,
  onSend,
  addDocument,
  downloadDocumentFollowUp,
  removeDocumentFollow,
  addNewRow,
  deleteRowParticipante,
}) => {
  const addDoc = (document: File, description: string, genericId?: any, q?: any, custom_field_name?: any) => {
    if (idFollow && section) addDocument(genericId, idFollow, document, description, custom_field_name, section);
  };

  const remove = (file_id: number, _id?: number, name?: string) => {
    if (idFollow && name) removeDocumentFollow(file_id, id, idFollow, name);
  };

  const downloadDocument = (file_id: number, name: string, generic_id?: any) => {
    if (idFollow) downloadDocumentFollowUp(file_id, name, generic_id, idFollow, true);
  };

  const getDocument = (file_id: number, name: string, generic_id?: any) => {
    if (idFollow) downloadDocumentFollowUp(file_id, name, generic_id, idFollow, false);
  };

  const arrangeData = (data: any) => {
    Object.keys(data).forEach(element => {
      if (moment.isMoment(data[element])) {
        if (data[element].isValid()) {
          data[element] = moment(data[element]).format(DateConstants.DATE_FORMAT);
        }
      }
    });
    setRegisterGenericTableRow(setterData);
    return data;
  };

  const handleSave = (data: any) => {
    const sendData = arrangeData(data);
    onSave(sendData, setterData);
  };

  const handleSend = (data: any) => {
    const sendData = arrangeData(data);
    onSend(sendData, setterData);
  };

  const buttonsTable: ButtonProps[] = [
    {
      onClick: () => {
        setRegisterGenericTableRow(setterData);
        addNewRow();
      },
      children: tProjectKey('Añadir Registro'),
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
    },
  ];

  const handleDataSetter = useCallback(
    debounce((data: any) => {
      setSetterData(data);
    }, 500),
    [],
  );

  const handleDeleteClick = async (row: any) => {
    deleteRowParticipante(row.id, section || '', row.id_generated);
  };

  if (loadingFields) {
    return <Loading big />;
  }

  let genericTable;
  if (participante && participante?.columns?.length > 0) {
    genericTable = (
      <GenericTable
        projectID={id}
        disabled={disabled}
        genericTableData={participante}
        buttons={buttonsTable}
        onSetterData={handleDataSetter}
        handleDeleteClick={handleDeleteClick}
      />
    );
  }
  return (
    <div className={styles.genericContainer}>
      <GenericFormRefactor
        idData={id}
        fields={fieldsData}
        disabled={disabled}
        buttonSave={buttonSave}
        buttonSend={buttonSend}
        onHandleSave={handleSave}
        onHandleSend={handleSend}
        addDoc={addDoc}
        download={downloadDocument}
        remove={remove}
        getFile={getDocument}
        showProjectInfo={showProjectInfo}
        actions={['checked', 'download', 'view', 'remove']}
      />
      {genericTable}
    </div>
  );
};

export default connect(null, (dispatch: any) => ({
  addDocument: (
    internal_project_id: number,
    follow_up_id: number,
    file: File,
    description: string,
    custom_field_name: string,
    section: string,
  ) => dispatch(addDocumentFollowUp(internal_project_id, follow_up_id, file, description, custom_field_name, section)),
  downloadDocumentFollowUp: (
    file_id: number,
    name: string,
    internal_project_id: number,
    follow_up_id: number,
    get?: boolean,
  ): void => dispatch(downloadDocumentsInternalProject(file_id, name, internal_project_id, get, follow_up_id)),
  removeDocumentFollow: (file_id: number, internal_project_id: number, follow_up_id: number, inputName: string): void =>
    dispatch(deleteDocumentsInternalProject(internal_project_id, file_id, follow_up_id, inputName)),
  addNewRow: (setterData?: any) => dispatch(addNewGenericTableRow(setterData)),
  setRegisterGenericTableRow: (data: any) => dispatch(setRegisterGenericTableRow(data)),
  deleteRowParticipante: (idRow: number | null, section: string, idGenerated: number | null) =>
    dispatch(deleteRowParticipante(idRow, section, idGenerated)),
}))(InternalProjectGeneric);
