import React from 'react';
import { connect } from 'react-redux';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';
import { selectUserInfo } from '../../../redux/auth/selectors';
import { AppState } from '../../../redux/root-reducer';

interface SubmenuAcademyProps {
  selected: 'datos_generales' | 'datos_contables' | 'gestion_responsable';
  avatar?: string;
  loading: boolean;
  userInfoRole: string;
  onSelectComponent: (componentId: number) => void;
  setImage?: (image: string) => void;
}

const SubmenuAcademy: React.FC<SubmenuAcademyProps> = ({ selected, userInfoRole, onSelectComponent }) => {
  const pmaRole = userInfoRole.includes('pma');
  const adminRole = userInfoRole === 'cdn_adm' || userInfoRole === 'dir_fnc' || userInfoRole === 'adm_sop';
  const optionsMenu: SubmenuItem[] = [
    {
      title: tKey('Datos generales'),
      componentId: 0,
      type: 'component',
      selected: selected === 'datos_generales',
    },
    {
      title: tKey('Datos contables'),
      componentId: 1,
      type: 'component',
      selected: selected === 'datos_contables',
    },
  ];

  if (adminRole || pmaRole) {
    optionsMenu.push({
      title: tKey('Gestión de responsable'),
      componentId: 2,
      type: 'component',
      selected: selected === 'gestion_responsable',
    });
  }

  return (
    <Submenu
      titleMenu={tKey('Datos del proyecto')}
      optionsMenu={optionsMenu}
      onSelectComponentId={onSelectComponent}
      defaultOpen
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  userInfoRole: selectUserInfo(state).role_code,
});

export default connect(mapStateToProps)(SubmenuAcademy);
