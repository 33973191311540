import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import MenuBottom from '../../components/Layout/Menus/MenuBottom/MenuBottom';
import { tKey } from '../../helpers/translate';
import { AuthUserData } from '../../redux/auth/definitions';
import { selectUserInfo } from '../../redux/auth/selectors';
import { AppState } from '../../redux/root-reducer';
import styles from './home.module.scss';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as iconArrow } from '../../images/icons/iconArrow.svg';
import RoleCode from '../../types/Roles/roleCode';

interface HomeProps {
  userInfo: AuthUserData;
}

const Home: React.FC<HomeProps> = ({ userInfo }) => {
  const [moreTextOpen, setMoreTextOpen] = useState(false);
  const roleCode = new RoleCode(userInfo.role_code);
  return (
    <Layout footerMenuDisabled>
      <div className={styles.root}>
        {(roleCode.isJovenInstituto() || roleCode.isEducador()) && (
          <div className={styles.wrapper}>
            <h2 className={styles.title}>{tKey('Bienvenida')}</h2>
            <p className={styles.text}>{tKey('Presentación Zing')},</p>
            <div className={`${styles.readMoreWrapper} ${moreTextOpen ? styles.isOpen : ''}`}>
              <p className={styles.text}>
                {tKey('Primera solicitación').concat(' ')}
                <Link to="/documentos">{tKey('documentos de ayuda')}</Link> {tKey('relativos al curso 2020-2021.')}
              </p>
              <p className={styles.text}>
                {tKey('Contacto Sugerencias')} <br />
                <a href="mailto:becas@zingprogramme.com"> becas@zingprogramme.com</a> <br />
              </p>
            </div>
            <button
              className={`${styles.readMoreButton} ${moreTextOpen ? styles.isOpen : ''}`}
              onClick={() => setMoreTextOpen(!moreTextOpen)}
            >
              {moreTextOpen ? (
                <span className={styles.readMoreText}>{tKey('readLess')}</span>
              ) : (
                <span className={styles.readMoreText}>{tKey('readMore')}</span>
              )}
              <SvgIcon className={styles.readMoreIcon} viewBox="0 0 12 8" component={iconArrow} />
            </button>
          </div>
        )}
        <MenuBottom isHome />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  userInfo: selectUserInfo(state),
});

export default connect(mapStateToProps, null)(Home);
