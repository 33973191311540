import { Tooltip } from '@material-ui/core';
import List from '@material-ui/core/List';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React from 'react';
import { tFormKey } from '../../helpers/translate';
import { IdCodeColor } from '../../redux/common/definitions';
import styles from './iconList.module.scss';

interface IconListProps {
  title: string;
  types: IdCodeColor[];
  codes: string[];
}

const IconList: React.FC<IconListProps> = ({ title, types, codes }) => {
  return (
    <div className={styles.typesWrapper}>
      <h4 className={styles.typesTitles}>{title}</h4>
      {types?.length ? (
        <>
          <List className={styles.typesList}>
            {types?.map(
              (types, index) =>
                codes?.includes(types.code) && (
                  <li className={styles.typesItem}>
                    <Tooltip key={index} title={types.name}>
                      <Brightness1Icon style={{ color: types.color, height: '15px', verticalAlign: 'text-bottom' }} />
                    </Tooltip>
                    <p className={styles.typesText}>{types.name}</p>
                  </li>
                ),
            )}
          </List>
        </>
      ) : (
        <p className={styles.typesText}>{tFormKey('No hay datos')}</p>
      )}
    </div>
  );
};

export default IconList;
