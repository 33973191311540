import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/root-reducer';
import { Material, ScholarshipData } from '../../../redux/scholarship/definitions';
import MaterialsTable from './MaterialsTable/MaterialsTable';
import {
  addScholarshipMaterial,
  removeScholarshipMaterial,
  updateScholarshipMaterial,
} from '../../../redux/scholarship/actions';
import {
  selectGetScholarshipDocumentationPerCode,
  selectLoadingMaterials,
  selectScholarshipMaterials,
  selectTotalMaterialCost,
} from '../../../redux/scholarship/selectors';
import FormContainer from '../../../components/Forms/FormContainer';
import { tScholarshipKey } from '../../../helpers/translate';
import TextInput from '../../../components/Inputs/TextInput';
import { InputAdornment } from '@material-ui/core';
import InfoLimitChip from '../../../components/InfoChip/InfoLimitChip';
import { DocumentationItem } from '../../../redux/common/definitions';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import Documentation from '../../../types/Scholarships/Documentation';

interface MaterialsViewProps {
  loading: boolean;
  scholarshipData: ScholarshipData;
  readOnly: boolean;
  data: Material[];
  isCustomer: boolean;
  totalMaterialsAmount: number;
  documentation: DocumentationItem[];
  addMaterial: (data: Material) => void;
  deleteMaterial: (data: Material) => void;
  updateMaterial: (data: Material) => void;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
}

const MaterialsView: React.FC<MaterialsViewProps> = ({
  loading,
  scholarshipData,
  readOnly,
  data,
  totalMaterialsAmount,
  isCustomer,
  documentation,
  addMaterial,
  deleteMaterial,
  updateMaterial,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
}) => {
  const renderLimitInformation = (): JSX.Element | undefined => {
    if (isCustomer || loading) {
      return undefined;
    }
    return (
      <InfoLimitChip
        text={`${tScholarshipKey('material_limit_info')}:`}
        input={totalMaterialsAmount}
        limit={scholarshipData.material_avg}
        deviation={scholarshipData.material_dev}
        currency
      />
    );
  };

  const renderMaterialDocuments = (): JSX.Element | undefined => {
    if (documentation.length > 0) {
      documentation[0].mandatory = data.length > 0;
      return (
        <FncAddDocuments
          title={tScholarshipKey('Documentos materiales')}
          documents={documentation}
          getFile={getFile}
          disabled={readOnly}
          upload={uploadDocument}
          genericId={scholarshipData.id}
          download={downloadDocument}
          remove={deleteDocument}
          addDoc={addDoc}
          required={scholarshipData.isEntity}
          multidocument
        />
      );
    }
    return undefined;
  };

  return (
    <FormContainer title={tScholarshipKey('material_title')}>
      <MaterialsTable
        scholarshipId={scholarshipData.id}
        materials={data}
        disabled={readOnly}
        loading={loading}
        addMaterial={addMaterial}
        deleteMaterial={deleteMaterial}
        updateMaterial={updateMaterial}
      />
      <TextInput
        type="number"
        disabled
        label={tScholarshipKey('material_total_amount')}
        size="50"
        name={`totalMaterialsAmount_${loading}`}
        value={totalMaterialsAmount}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
      {renderLimitInformation()}
      {renderMaterialDocuments()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  data: selectScholarshipMaterials(state),
  loading: selectLoadingMaterials(state),
  totalMaterialsAmount: selectTotalMaterialCost(state),
  documentation: selectGetScholarshipDocumentationPerCode(state, [Documentation.SOLICITUD_MATERIALES]),
});

const mapDispatchToProps = (dispatch: any) => ({
  addMaterial: (data: Material): void => dispatch(addScholarshipMaterial(data)),
  deleteMaterial: (data: Material): void => dispatch(removeScholarshipMaterial(data.scholarship_id, data.id)),
  updateMaterial: (data: Material): void => dispatch(updateScholarshipMaterial(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialsView);
