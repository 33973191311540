import React, { ReactElement } from 'react';
import { FieldErrors, FieldName, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../../helpers/customHooks/useIsRequired';
import SelectInput from '../SelectInput';
import { BaseSelectInputProps } from '../types';

interface ValidatedSelect<T extends FieldValues> extends BaseSelectInputProps {
  errors: FieldErrors<T>;
  name: FieldName<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  label: string;
  validator?: boolean;
}

const ValidatedSelect = <T extends FieldValues>({
  children,
  label,
  schema,
  errors,
  name,
  validator = false,
  ...props
}: ValidatedSelect<T>): ReactElement => {
  const error = errors[name];
  const required = useIsRequired(name, schema);

  return (
    <SelectInput
      error={error}
      name={name}
      validator={validator}
      label={
        label && required && !validator ? (
          <React.Fragment>
            {label} {'\u00a0*'}
          </React.Fragment>
        ) : (
          label
        )
      }
      {...props}
    >
      {children}
    </SelectInput>
  );
};

export default ValidatedSelect;
