import { put, takeLatest } from '@redux-saga/core/effects';
import {
  getScholarshipAllowedStatesFromApi,
  scholarshipChangeStateFromApi,
} from '../../../api/Scholarship/changeStates';
import { getOneScholarship } from '../../../api/Scholarship/scholarship';
import { Await } from '../../../api/api';
import errorMessage from '../../../helpers/errorMessage';
import { showSnackbar } from '../../FeedbackAPI/actions';
import { getScholarshiptAgreement, scholarshipGetOk } from '../actions';
import { ScholarshipChangeStateDataConsts } from './action_types';
import { scholarshipChangeStateKoAction, getScholarshipAllowedStatesOkAction } from './actions';
import { GetScholarshipAllowedStatesAction, ScholarshipChangeStateAction } from './definitions';

function* getScholarshipAllowedStatesFromSaga(action: GetScholarshipAllowedStatesAction): Generator<any, void, any> {
  try {
    const allowedStates = yield getScholarshipAllowedStatesFromApi(action.scholarship_id);
    yield put(getScholarshipAllowedStatesOkAction(allowedStates));
  } catch (e) {
    yield put(scholarshipChangeStateKoAction(errorMessage(e)));
  }
}

function* scholarshipChangeStateFromSaga(action: ScholarshipChangeStateAction): Generator<any, void, any> {
  try {
    const { id, current_state, next_state } = action;
    yield scholarshipChangeStateFromApi(id, current_state, next_state);
    const { data } = (yield getOneScholarship(id)) as Await<ReturnType<typeof getOneScholarship>>;
    yield put(scholarshipGetOk(data, null));
    yield put(getScholarshiptAgreement(id));
    yield put(showSnackbar(`El estado de la beca se ha sido actualizado correctamente`, 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('Ha ocurrido algún problema', 'error', undefined, 1500));
    yield put(scholarshipChangeStateKoAction(errorMessage(e)));
  }
}

export default [
  takeLatest<GetScholarshipAllowedStatesAction>(
    ScholarshipChangeStateDataConsts.SCHOLARSHIP_GET_ALLOWED_STATES,
    getScholarshipAllowedStatesFromSaga,
  ),
  takeLatest<ScholarshipChangeStateAction>(
    ScholarshipChangeStateDataConsts.SCHOLARSHIP_CHANGE_STATE,
    scholarshipChangeStateFromSaga,
  ),
];
