import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { Search } from '@material-ui/icons';
import React, { useState } from 'react';
import styles from './customTable.module.scss';
const BORDER_RIGT_END = '3px solid #6666661a';

const useStyles = makeStyles(theme => ({
  fixed: {
    top: 'auto',
    width: '5em',
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));
interface InputFieldProps {
  headCell: any;
  filtersInput: any;
  setFilters: any;
  onFilter: (filtersInput: any) => void;
}

const InputField: React.FC<InputFieldProps> = ({ headCell, filtersInput, setFilters, onFilter }) => {
  const filterRows = (property: any) => (event: any) => {
    const value = event.target.value;
    filtersInput[property] = value;
    setFilters((prevFilters: any) => ({ ...prevFilters, [headCell.name]: value }));
    onFilter(filtersInput);
  };

  if (headCell?.hidden) return null;

  return (
    <TextField
      className={styles.textfield_filter}
      value={filtersInput[headCell.name]}
      type="search"
      onChange={filterRows(headCell.name)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};

interface SortTableHeadProps {
  columns: any[];
  option: any;
  actions?: boolean;
  onFilter: (filtersInput: any) => void;
}

const FilterRow: React.FC<SortTableHeadProps> = ({ columns, option, actions, onFilter }) => {
  const [filters, setFilters] = useState({});
  const classes = useStyles();

  return (
    <TableRow>
      {columns.map((headCell: any, index: number) => (
        <TableCell
          className={index <= option?.fixedColumn?.num ? classes.fixed : styles.tableHeadFilter}
          style={{
            left: index <= option?.fixedColumn?.num ? option?.fixedColumn?.left * index : undefined,
            borderRight: index === option?.fixedColumn?.num ? BORDER_RIGT_END : undefined,
          }}
          key={headCell.name}
          align={headCell.align}
        >
          <InputField headCell={headCell} onFilter={onFilter} filtersInput={filters} setFilters={setFilters} />
        </TableCell>
      ))}

      {actions && (
        <TableCell className={styles.tableHeadFilter}>
          <div className={styles.textfield_filter_hidden}></div>
        </TableCell>
      )}
    </TableRow>
  );
};

export default FilterRow;
