import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import Loading from '../../../components/Loading/Loading';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tFormKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { updateEntity } from '../../../redux/entity/actions';
import { EntityInfo } from '../../../redux/entity/definitions';
import { selectGetOneEntity, selectIsLoading, selectValidationErrors } from '../../../redux/entity/selectors';
import { AppState } from '../../../redux/root-reducer';
import { entityFormSchema } from '../../../validations/Entities/entityFormSchema';
import EntityForm from './EntityForm';

interface CreateEntityNCEntityProps {
  loading: boolean;
  validationErrors: APIValidationError | null;
  documentation: DocumentationItem[];
  buttonActionSave: ButtonProps;
  entityData: EntityInfo;
  saveEntity: (data: EntityInfo) => void;
}

const EditEntity: React.FC<CreateEntityNCEntityProps> = ({
  validationErrors,
  loading,
  documentation,
  entityData,
  buttonActionSave,
  saveEntity,
}) => {
  const entitySchema = entityFormSchema(!!entityData.is_visa_provider, !!entityData.no_payments_entity);
  const { register, handleSubmit, setValue, watch, errors, triggerValidation, control, setError, clearError } = useForm<
    EntityInfo
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: entitySchema,
  });

  useValidatorAPI(validationErrors, setError);

  const saveEntityData = () => {
    saveEntity(entityData);
  };

  useButtonAction(buttonActionSave, handleSubmit(saveEntityData));

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <EntityForm
          title={tFormKey('Edición de Entidades')}
          useForm={{ register, setValue, watch, errors, triggerValidation, control, setError, clearError }}
          entitySchema={entitySchema}
          documentation={documentation}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  validationErrors: selectValidationErrors(state),
  entityData: selectGetOneEntity(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  saveEntity: (data: EntityInfo) => dispatch(updateEntity(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEntity);
