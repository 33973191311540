import { InvoiceListItem } from '../../redux/accounting/definitions';
import { AuthUserDataLogin } from '../../redux/auth/definitions';
import { DocumentationItem, FileItem, IdNamePair } from '../../redux/common/definitions';
import {
  EntityAgreementData,
  EntityContactData,
  EntityContactRoles,
  EntityContract,
  EntityIbanData,
  EntityInfo,
  EntityListItem,
} from '../../redux/entity/definitions';
import { PaymentInfo } from '../../redux/project/definitions';
import {
  EntityScholarshipApiPayment,
  EntityScholarshipPaymentError,
  EntityScholarshipPaymentItem,
  GetEntitiesWithScholarshipsPaymentAction,
  ScholarshipEntityPaymentApi,
} from '../../redux/scholarship/definitions';
import { UserData } from '../../redux/user/definitions';
import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from '../api';

const baseURL = apiBaseUrl;

interface DocumentsResponse {
  data: DocumentsData;
}

interface DocumentsData {
  documents: DocumentationItem[];
}

interface GetResult {
  data: any;
}

interface SaveResult {
  data: any;
}

interface SuccessResult {
  data: string;
}

export const getEntityList = async (
  state: string,
): Promise<{
  data: EntityListItem[];
}> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return await apiCall<{
    data: EntityListItem[];
  }>(`${baseURL}/entities/${state}`, init);
};

export const entityFncSend = async (data: EntityInfo): Promise<Result<{ data: EntityInfo }, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };

  return await validatedApiCall<{ data: EntityInfo }>(`${baseURL}/entity/fnc/send/`, init);
};

export const updateEntityData = async (
  entityData: EntityInfo,
): Promise<Result<{ data: EntityInfo }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    body: JSON.stringify(entityData),
    auth: true,
  };
  return await validatedApiCall<{ data: EntityInfo }>(`${baseURL}/entity`, init);
};

export const getOneEntity = async (id: number): Promise<GetResult> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<GetResult>(`${baseURL}/entity/find/${id}`, init);
};

export const activationEntity = async (token: string): Promise<AuthUserDataLogin> => {
  const init = {
    method: 'POST',
  };

  return await apiCall<AuthUserDataLogin>(`${baseURL}/entity/activate/${token}`, init);
};

export const deleteEntity = async (id: number): Promise<SaveResult> => {
  const entities = [id];
  const data = { entities };
  data.entities = entities;
  const init = {
    method: 'DELETE',
    body: JSON.stringify(data),
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/entities/`, init);
};

export const getFormEditEntity = async (id: number): Promise<SaveResult> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/entity/profile/${id}`, init);
};

export const getFormCreateEntity = async (): Promise<SaveResult> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/entity/create-form/`, init);
};

export const getFormUserEntity = async (): Promise<SaveResult> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/entity/activate-form`, init);
};

export const createEntityFnc = async (
  entityOneData: EntityInfo,
): Promise<Result<{ data: number }, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(entityOneData),
    auth: true,
  };

  return await validatedApiCall<{ data: number }>(`${baseURL}/entity/fnc`, init);
};

export const getEntityForUser = async (): Promise<GetResult> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/user/entity/profile`, init);
};

export const uploadDocumentation = async (
  documentation_id: number,
  file: File,
  entity_id?: number | string,
  force_add?: boolean | false,
): Promise<DocumentsData> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const add = force_add ? 'add/' : '';
  const { data } = await apiCall<DocumentsResponse>(
    `${baseURL}/entity/document/${add}${entity_id}/${documentation_id ? documentation_id : 0}`,
    init,
  );
  return data;
};

export const downloadDocumentation = async (entityId: number, file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/entity/document/download/${entityId}/${file_id}`, init);
};

export const deleteDocumentation = async (entityId: number, file_id: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/entity/document/${entityId}/${file_id}`, init);
  return data;
};

export const entityGetDocuments = async (entityId: number): Promise<DocumentsData> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/entity/documentation/${entityId}`, init);
  return data;
};

export const getAvatarEntity = async (id: number): Promise<string> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  try {
    //TODO. Hacerlo bien! Hacer un sagas para getAvatarEntity
    const avatar = await apiCall<string>(`${baseURL}/entity/image/${id ? id : 0}`, init);
    return avatar;
  } catch (error) {
    return '';
  }
};

export const setAvatarEntity = async (image: string): Promise<string> => {
  const data = { image, filename: 'avatar.png' };
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };

  return apiCall<string>(`${baseURL}/entity/image`, init);
};

export const changeResponsibleApi = async (id: number): Promise<EntityInfo> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  return apiCall<EntityInfo>(`${baseURL}/entity/change_responsible/${id}`, init);
};

export type EntityUser = Pick<UserData, 'id' | 'email' | 'name' | 'surname' | 'surname2'> & { position: string } & {
  academic_years: [];
};

export const myEntityUsers = async (): Promise<{ data: EntityUser[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: EntityUser[] }>(`${baseURL}/entity/users`, init);
};

export const getEntityUsers = async (entity_id: number): Promise<{ data: EntityUser[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: EntityUser[] }>(`${baseURL}/entity/users/${entity_id}`, init);
};

export const getScholarshipYears = async (id: number): Promise<string[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return (await apiCall<{ data: string[] }>(`${baseURL}/entity/get_scholarship_payment_years/${id}`, init)).data;
};

export const getScholarshipYearsAgreements = async (id: number): Promise<string[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return (await apiCall<{ data: string[] }>(`${baseURL}/entity/get_scholarship_agreement_years/${id}`, init)).data;
};

export const getScholarshipPaymentByYears = async (data: { id: number; academic_year: string }): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<any>(`${baseURL}/entity/get_scholarship_payments`, init);
};

export const getEntityScholarshipPayment = async (
  action: GetEntitiesWithScholarshipsPaymentAction,
): Promise<{ data: ScholarshipEntityPaymentApi[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const entityId = action.entity_id ? '&entity_id=' + action.entity_id : '';
  return apiCall<any>(`${baseURL}/scholarship/payments/entity?academic_year=${action.academic_year}${entityId}`, init);
};

export const getEntitiesInfo = async (academic_year: string): Promise<{ data: IdNamePair[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<any>(`${baseURL}/entity/payments/entities?academic_year=${academic_year}`, init);
};

export const getEntityDataInfo = async (
  entity_id: number,
  academic_year: string,
): Promise<{ data: EntityScholarshipApiPayment }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<any>(
    `${baseURL}/entity/payments/entity/data?academic_year=${academic_year}&entity_id=${entity_id}`,
    init,
  );
};

export const setScholarshipPaymentByYears = async (
  id: number,
  payments: EntityScholarshipPaymentItem[],
  academic_year: string,
): Promise<{
  data: { payment_available: string; errors: EntityScholarshipPaymentError[]; success: EntityScholarshipPaymentItem[] };
}> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ payments, academic_year }),
  };

  return apiCall<{
    data: {
      payment_available: string;
      errors: EntityScholarshipPaymentError[];
      success: EntityScholarshipPaymentItem[];
    };
  }>(`${baseURL}/entity/set_scholarship_payments/${id}`, init);
};

export const retrievePayment = async (data: { id: number; academic_year: string }): Promise<{ data: PaymentInfo }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: PaymentInfo }>(`${baseURL}/entity/check_scholarship_payments`, init);
};

export const paySage = async (data: {
  id: number;
  account_number: string;
  academic_year: string;
  payment_date: string;
}): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<any>(`${baseURL}/entity/make_scholarship_payments`, init);
};

export const getAgreementByYear = async (id: number, academic_year: string): Promise<EntityAgreementData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id, academic_year }),
  };

  const { data } = await apiCall<{ data: EntityAgreementData }>(`${baseURL}/entity/get_agreement`, init);
  return data;
};

export const setAgreementByYear = async (dataInputs: {
  id: number;
  academic_year: string;
  agreement_number: string;
  center_cost_channel_code: string;
  center_cost_delegation_code: string;
  center_cost_area_code: string;
  center_cost_project_code: string;
  description: string;
  concept: string;
  total_confirm_amount: number;
  total_paid_amount: number;
  total_justified_amount: number;
  total_rejected_and_dropped: number;
  advance_payment: number;
  advance_payment_date: string | null;
  advance_payment_remainder: number;
  admin_management_amount: number;
  admin_management_date: string | null;
}): Promise<EntityAgreementData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(dataInputs),
  };

  const { data } = await apiCall<{ data: EntityAgreementData }>(`${baseURL}/entity/set_agreement`, init);
  return data;
};

export const download = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/file/download/${file_id}`, init);
};

export const downloadOriginal = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/file/downloadOriginal/${file_id}`, init);
};

export const makeProvider = async (entityId: number): Promise<EntityInfo> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  return apiCall<EntityInfo>(`${baseURL}/entity/export/provider/${entityId}`, init);
};

export const checkScholarshipAdvancePayments = async (data: {
  id: number;
  academic_year: string;
  amount: number;
  advance_payment_date: string;
}) => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  return apiCall<any>(`${baseURL}/entity/check_scholarship_advance_payments`, init);
};

export const makeScholarshipAdvancePayments = async (data: {
  id: number;
  academic_year: string;
  amount: number;
  advance_payment_date: string;
  account_number: string;
}) => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  return apiCall<any>(`${baseURL}/entity/make_scholarship_advance_payments`, init);
};

export const changeEntityRemmant = async (
  entity_id: number,
  academic_year: string,
  amount: number,
  comment: string,
): Promise<number> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id, academic_year, amount, comment }),
  };
  const { data } = await apiCall<{ data: number }>(`${baseURL}/entity/remaining_amount/change`, init);
  return data;
};

export const createNewContractFromApi = async (
  entityContract: EntityContract,
): Promise<Result<{ data: EntityContract[] }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(entityContract),
  };
  return await validatedApiCall<{ data: EntityContract[] }>(`${baseURL}/entity_contract`, init);
};

export const getContractFromApi = async (id: number): Promise<EntityContract> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: EntityContract }>(`${baseURL}/entity_contract/${id}`, init);
  return data;
};

export const editContractFromApi = async (
  entityContract: EntityContract,
): Promise<Result<{ data: EntityContract[] }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(entityContract),
  };
  return await validatedApiCall<{ data: EntityContract[] }>(`${baseURL}/entity_contract`, init);
};

export const deleteContractFromApi = async (contractID: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<SuccessResult>(`${baseURL}/entity_contract/${contractID}`, init);
  return data;
};

export const uploadContractDocumentFromApi = async (file: File): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/entity_contract/file/upload`, init);

  return data;
};

export const deleteContractDocumentFromApi = async (file_id: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<SuccessResult>(`${baseURL}/entity_contract/file/remove/${file_id}`, init);
  return data;
};

export const downloadContractDocument = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCallBlob(`${baseURL}/entity_contract/file/download/${file_id}`, init);
};

export const rejectExternalEntity = async (data: { id: number; comment: string }): Promise<EntityInfo> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<EntityInfo>(`${baseURL}/entity/user/reject`, init);
};

export const rejectInternalEntity = async (data: { id: number; comment: string }): Promise<EntityInfo> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<EntityInfo>(`${baseURL}/entity/fnc/reject`, init);
};

export const confirmEntity = async (data: { id: number }): Promise<EntityInfo> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<EntityInfo>(`${baseURL}/entity/confirm`, init);
};

export const approveEntity = async (data: { id: number }): Promise<EntityInfo> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<EntityInfo>(`${baseURL}/entity/approve`, init);
};

export const rejectFromDirector = async (data: { id: number; comment: string }): Promise<EntityInfo> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<EntityInfo>(`${baseURL}/entity/returnToRequested`, init);
};

export const getEntityInvoices = async (entity_id: number): Promise<InvoiceListItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: InvoiceListItem[] }>(`${baseURL}/entity/invoices/${entity_id}`, init);
  return data;
};

export const getEntityContactsList = async (entityId: number): Promise<EntityContactData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: EntityContactData[] }>(`${baseURL}/entity/${entityId}/contact`, init);
  return data;
};
export const getEntityOneContact = async (entity_id: number, contact_id: number): Promise<EntityContactData> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: EntityContactData }>(
    `${baseURL}/entity/${entity_id}/contact/${contact_id}`,
    init,
  );
  return data;
};

export const removeContactEntity = async (entity_id: number, contact_id: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{ data: 'OK' }>(`${baseURL}/entity/${entity_id}/contact/${contact_id}`, init);
  return data;
};

export const getEntityRoleList = async (): Promise<EntityContactRoles[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: EntityContactRoles[] }>(`${baseURL}/entity/contact_role`, init);
  return data;
};

export const createEntityContactFromApi = async (
  contactData: EntityContactData,
  entity_id: number,
): Promise<EntityContactData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(contactData),
  };
  const { data } = await apiCall<{ data: EntityContactData }>(`${baseURL}/entity/${entity_id}/contact/`, init);
  return data;
};
export const entityUpdateContactFromApi = async (dataInfo: any, entity_id: number): Promise<EntityContactData> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(dataInfo),
  };
  const { data } = await apiCall<{ data: EntityContactData }>(
    `${baseURL}/entity/${entity_id}/contact/${dataInfo.id}`,
    init,
  );
  return data;
};

export const getEntityIbansListFromApi = async (entityId: number): Promise<EntityIbanData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: EntityIbanData[] }>(`${baseURL}/entity/${entityId}/iban`, init);
  return data;
};

export const getIbanOneContactFromApi = async (entity_id: number, iban_id: number): Promise<EntityIbanData> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: EntityIbanData }>(`${baseURL}/entity/${entity_id}/iban/${iban_id}`, init);
  return data;
};
export const removeIbanEntityFromApi = async (entity_id: number, iban_id: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{ data: 'OK' }>(`${baseURL}/entity/${entity_id}/iban/${iban_id}`, init);
  return data;
};

export const createIbanContactFromApi = async (
  ibanData: EntityIbanData,
  entity_id: number,
): Promise<Result<{ data: EntityIbanData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(ibanData),
  };
  return await validatedApiCall<{ data: EntityIbanData }>(`${baseURL}/entity/${entity_id}/iban/`, init);
};

export const entityUpdateIbanFromApi = async (dataInfo: any, entity_id: number): Promise<EntityIbanData> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(dataInfo),
  };
  const { data } = await apiCall<{ data: EntityIbanData }>(`${baseURL}/entity/${entity_id}/iban/${dataInfo.id}`, init);
  return data;
};
export const downloadIbanDocumentFromApi = async (file_id: number): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCallBlob(`${baseURL}/file/download/${file_id}`, init);
};

export const uploadIbanEntityDocumentFromApi = async (file: File): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/entity/iban/file/upload`, init);

  return data;
};
export const removeIbanEntityDocumentFromApi = async (file_id: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<SuccessResult>(`${baseURL}/entity/iban/file/remove/${file_id}`, init);
  return data;
};

export const entityProviderInfoChangeFromApi = async (entity_id: number): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/entity/provider_info/change`, init);
  return data === 'OK';
};

export const getEntityScholarshipPaymentLogsFromApi = async (
  entity_id: number,
  academic_year: string,
): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id, academic_year }),
  };

  return await apiCallBlob(`${baseURL}/entity/scholarship/payment/logs`, init);
};

export const payAdminManagementFromApi = async (
  entity_id: number,
  academic_year: string,
  amount: number,
  payment_date: string,
): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id, academic_year, amount, payment_date }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/entity/scholarship/admin_management/pay`, init);
  return data === 'OK';
};

export const cancelEntityAgreementFromApi = async (entityAgreementId: number): Promise<string> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id: entityAgreementId }),
  };
  const { data } = await apiCall<{ data: { agreement_state: string } }>(`${baseURL}/entity/agreement/undo`, init);
  return data.agreement_state;
};
