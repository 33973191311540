import { Button, InputAdornment, ThemeProvider } from '@material-ui/core';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import { DATE_FORMAT } from '../../../constants/date';
import colorPalette from '../../../helpers/colorPalette';
import { tScholarshipKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { AppState } from '../../../redux/root-reducer';
import {
  downloadDocument,
  getDocument,
  getPaymentAdjustment,
  removeDocument,
  scholarshipPaymentAdjustmentSetData,
  sendPaymentAdjustment,
  uploadDocument,
} from '../../../redux/scholarship/actions';
import { PaymentAdjustmentsData } from '../../../redux/scholarship/definitions';
import {
  checkPaymentAdjustmentValues,
  dataAdjustmentPayment,
  selectDocumentationAdjustment,
  selectIsLoadingPayment,
} from '../../../redux/scholarship/selectors';
import { theme } from '../../../ui/muiCustomTheme';
import { PaymentAdjustmentSchema } from '../../../validations/scholarshipSchema';
import style from './paymentAdjustmentForm.module.scss';

interface PaymentAdjustmentFormProps {
  isEntity?: boolean;
  isInstitute?: boolean;
  dataAdjustmentPayment: PaymentAdjustmentsData;
  scholarshipID: number;
  loadingPayment: boolean;
  documentationAdjustment: DocumentationItem[];
  diffMessage: string | undefined;
  handleClose: () => void;
  setData: (key: string, value: string | number) => void;
  getAdjustPaymentData: (id: number) => void;
  sendAdjustmentPayment: (data: PaymentAdjustmentsData) => void;
  uploadDocument: (documentation_id: number, data: File, scholarship_id: number, multiDocument?: boolean) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  removeDocument: (documentation_id: number, scholarship_id: number) => void;
}

const PaymentAdjustmentForm: React.FC<PaymentAdjustmentFormProps> = ({
  isEntity,
  isInstitute,
  dataAdjustmentPayment,
  scholarshipID,
  loadingPayment,
  documentationAdjustment,
  diffMessage,
  handleClose,
  setData,
  getAdjustPaymentData,
  sendAdjustmentPayment,
  uploadDocument,
  downloadDocument,
  getDocument,
  removeDocument,
}) => {
  const { register, errors, handleSubmit, control } = useForm<PaymentAdjustmentsData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: PaymentAdjustmentSchema,
  });

  const [isPayment, setIsPayment] = useState<boolean>(false);

  const actions = ['checked', 'upload', 'download', 'view', 'remove'];

  const addDocument = (document: File, description: string, genericId?: number, documentation_id?: number) => {
    if (documentation_id) {
      uploadDocument(documentation_id, document, scholarshipID, true);
    }
  };

  useEffect(() => {
    if (scholarshipID > 0) {
      getAdjustPaymentData(scholarshipID);
    }
  }, [getAdjustPaymentData, scholarshipID]);

  const {
    first_reload_amount,
    first_reload_date,
    first_reload_done_date,
    second_reload_amount,
    second_reload_date,
    second_reload_done_date,
    third_reload_amount,
    third_reload_date,
    third_reload_done_date,
    additional_reload_amount,
    additional_reload_date,
    additional_reload_done_date,
    first_payment_amount,
    second_payment_amount,
    third_payment_amount,
    fourth_payment_amount,
    first_payment_date,
    first_payment_done_date,
    second_payment_date,
    second_payment_done_date,
    third_payment_date,
    third_payment_done_date,
    fourth_payment_date,
    fourth_payment_done_date,
    scholarship_amount,
    adjustment_comment,
    adjustment_amount,
    total_amount,
  } = dataAdjustmentPayment;

  const changeDate = (name: string, date: moment.Moment | null) => {
    if (date && date.isValid()) {
      setData(name, date.format(DATE_FORMAT));
    }
  };

  const asingDateValue = (date: string | number | null) => {
    return date ? moment(date, DATE_FORMAT) : null;
  };

  const onSubmit = async () => {
    try {
      await sendAdjustmentPayment(dataAdjustmentPayment);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  let instituteView;
  let entityView;

  if (isInstitute) {
    instituteView = (
      <FormContainer title="" smallTitle={tScholarshipKey('Recargas')}>
        <div className={style.extra_padding}>
          <TextInputController
            schema={PaymentAdjustmentSchema}
            control={control}
            size="50"
            label={tScholarshipKey('Primera Recarga')}
            name="first_reload_amount"
            type="number"
            price
            defaultValue={first_reload_amount}
            disabled={!!first_reload_done_date ? !isPayment : false}
            green={!!first_reload_done_date}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('first_reload_amount', e.target.value)}
          />
          <KeyboardDatePicker
            label={tScholarshipKey('Fecha primera recarga')}
            name="first_reload_date"
            error={!!errors.first_reload_date}
            variant="inline"
            inputVariant="outlined"
            placeholder="" // Prevent google to detect this input as a credit card number
            value={asingDateValue(first_reload_date)}
            onChange={(date: MaterialUiPickersDate) => {
              date && changeDate('first_reload_date', date);
            }}
            autoOk
            format={DATE_FORMAT}
            disabled={!!first_reload_done_date}
            className={`${style.size_50} ${!!first_payment_done_date && style.green}`}
          />

          <TextInputController
            schema={PaymentAdjustmentSchema}
            control={control}
            size="50"
            label={tScholarshipKey('Segunda Recarga')}
            name="second_reload_amount"
            type="number"
            price
            defaultValue={second_reload_amount}
            disabled={!!second_reload_done_date ? !isPayment : false}
            green={!!second_reload_done_date}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('second_reload_amount', e.target.value)}
          />
          <KeyboardDatePicker
            label={tScholarshipKey('Fecha segunda recarga')}
            name="second_reload_date"
            error={!!errors.second_reload_date}
            variant="inline"
            inputVariant="outlined"
            placeholder="" // Prevent google to detect this input as a credit card number
            value={asingDateValue(second_reload_date)}
            onChange={(date: MaterialUiPickersDate) => {
              date && changeDate('second_reload_date', date);
            }}
            autoOk
            format={DATE_FORMAT}
            disabled={!!second_reload_done_date}
            className={`${style.size_50} ${!!second_reload_done_date && style.green}`}
          />
          <TextInputController
            schema={PaymentAdjustmentSchema}
            control={control}
            size="50"
            label={tScholarshipKey('Tercera Recarga')}
            name="third_reload_amount"
            type="number"
            price
            defaultValue={third_reload_amount}
            disabled={!!third_reload_done_date ? !isPayment : false}
            green={!!third_reload_done_date}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('third_reload_amount', e.target.value)}
          />
          <KeyboardDatePicker
            label={tScholarshipKey('Fecha tercera recarga')}
            name="third_reload_date"
            error={!!errors.third_reload_date}
            variant="inline"
            inputVariant="outlined"
            placeholder="" // Prevent google to detect this input as a credit card number
            value={asingDateValue(third_reload_date)}
            onChange={(date: MaterialUiPickersDate) => {
              date && changeDate('third_reload_date', date);
            }}
            autoOk
            format={DATE_FORMAT}
            disabled={!!third_reload_done_date}
            className={`${style.size_50} ${!!third_reload_done_date && style.green}`}
          />
          <TextInputController
            schema={PaymentAdjustmentSchema}
            control={control}
            size="50"
            label={tScholarshipKey('Recarga adicional')}
            name="additional_reload_amount"
            type="number"
            price
            defaultValue={additional_reload_amount}
            disabled={!!additional_reload_done_date ? !isPayment : false}
            green={!!additional_reload_done_date}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('additional_reload_amount', e.target.value)}
          />
          <KeyboardDatePicker
            label={tScholarshipKey('Fecha Recarga adicional')}
            name="additional_reload_date"
            error={!!errors.additional_reload_date}
            variant="inline"
            inputVariant="outlined"
            placeholder="" // Prevent google to detect this input as a credit card number
            value={asingDateValue(additional_reload_date)}
            onChange={(date: MaterialUiPickersDate) => {
              date && changeDate('additional_reload_date', date);
            }}
            autoOk
            format={DATE_FORMAT}
            disabled={!!additional_reload_done_date}
            className={`${style.size_50} ${!!additional_reload_done_date && style.green}`}
          />
        </div>
      </FormContainer>
    );
  }

  if (isEntity) {
    entityView = (
      <>
        <TextInputController
          schema={PaymentAdjustmentSchema}
          control={control}
          size="50"
          label={tScholarshipKey('Pago adicional')}
          name="fourth_payment_amount"
          type="number"
          price
          defaultValue={fourth_payment_amount}
          disabled={!!fourth_payment_done_date ? !isPayment : false}
          green={!!fourth_payment_done_date}
          errors={errors}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('fourth_payment_amount', e.target.value)}
        />
        <KeyboardDatePicker
          label={tScholarshipKey('Fecha pago adicional')}
          name="fourth_payment_date"
          error={!!errors.fourth_payment_date}
          variant="inline"
          inputVariant="outlined"
          placeholder="" // Prevent google to detect this input as a credit card number
          value={asingDateValue(fourth_payment_date)}
          onChange={(date: MaterialUiPickersDate) => {
            date && changeDate('fourth_payment_date', date);
          }}
          autoOk
          format={DATE_FORMAT}
          disabled={!!fourth_payment_done_date}
          className={`${style.size_50} ${!!fourth_payment_done_date && style.green}`}
        />
      </>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      {!loadingPayment ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)} className={style.formContainer}>
            <div className={style.mainContainer}>
              <TextInputController
                control={control}
                schema={PaymentAdjustmentSchema}
                type="number"
                errors={errors}
                size="30"
                label={tScholarshipKey('Importe ajuste')}
                name="adjustment_amount"
                price
                defaultValue={adjustment_amount || 0}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('adjustment_amount', e.target.value)}
              />

              <TextInputController
                control={control}
                schema={PaymentAdjustmentSchema}
                type="number"
                errors={errors}
                size="30"
                label={tScholarshipKey('Importe beca')}
                name="scholarship_amount"
                defaultValue={scholarship_amount}
                price
                disabled
              />
              <TextInput
                type="number"
                disabled
                size="30"
                label={tScholarshipKey('Importe final')}
                name="total_amount"
                value={total_amount}
                style={{ fontWeight: 'bolder' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                }}
              />
            </div>
            <TextInputController
              schema={PaymentAdjustmentSchema}
              control={control}
              label={tScholarshipKey('Observaciones ajuste')}
              name="adjustment_comment"
              defaultValue={adjustment_comment}
              errors={errors}
              multiline
              rows="4"
              rowsMax="10"
              size="100"
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('adjustment_comment', e.target.value)}
            />
            <CheckboxCompo
              name="is_payment"
              questionText={tScholarshipKey('Es un abono')}
              defaultValue={isPayment ? 1 : 0}
              register={register}
              onChange={value => setIsPayment(value)}
            />
            <FormContainer title="" smallTitle={tScholarshipKey('Documentos')}>
              <FncAddDocuments
                title={''}
                documents={documentationAdjustment}
                genericId={scholarshipID}
                actions={actions}
                addDoc={addDocument}
                getFile={getDocument}
                upload={uploadDocument}
                download={downloadDocument}
                remove={removeDocument}
                multidocument
              />
            </FormContainer>
            {instituteView}
            <FormContainer title="" smallTitle={tScholarshipKey('Pagos')}>
              <div className={style.extra_padding}>
                <TextInputController
                  schema={PaymentAdjustmentSchema}
                  control={control}
                  size="50"
                  label={tScholarshipKey('Primer Pago')}
                  name="first_payment_amount"
                  type="number"
                  price
                  defaultValue={first_payment_amount}
                  disabled={!!first_payment_done_date ? !isPayment : false}
                  green={!!first_payment_done_date}
                  errors={errors}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('first_payment_amount', e.target.value)}
                />
                <KeyboardDatePicker
                  label={tScholarshipKey('Fecha primer pago')}
                  name="first_payment_date"
                  error={!!errors.first_payment_date}
                  variant="inline"
                  inputVariant="outlined"
                  placeholder="" // Prevent google to detect this input as a credit card number
                  value={asingDateValue(first_payment_date)}
                  onChange={(date: MaterialUiPickersDate) => {
                    date && changeDate('first_payment_date', date);
                  }}
                  autoOk
                  format={DATE_FORMAT}
                  disabled={!!first_payment_done_date}
                  className={`${style.size_50} ${!!first_payment_done_date && style.green}`}
                />
                <TextInputController
                  schema={PaymentAdjustmentSchema}
                  control={control}
                  size="50"
                  label={tScholarshipKey('Segundo Pago')}
                  name="second_payment_amount"
                  type="number"
                  price
                  defaultValue={second_payment_amount}
                  disabled={!!second_payment_done_date ? !isPayment : false}
                  green={!!second_payment_done_date}
                  errors={errors}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('second_payment_amount', e.target.value)}
                />
                <KeyboardDatePicker
                  label={tScholarshipKey('Fecha segundo pago')}
                  name="second_payment_date"
                  error={!!errors.second_payment_date}
                  variant="inline"
                  inputVariant="outlined"
                  placeholder="" // Prevent google to detect this input as a credit card number
                  value={asingDateValue(second_payment_date)}
                  onChange={(date: MaterialUiPickersDate) => {
                    date && changeDate('second_payment_date', date);
                  }}
                  autoOk
                  format={DATE_FORMAT}
                  disabled={!!second_payment_done_date}
                  className={`${style.size_50} ${!!second_payment_done_date && style.green}`}
                />
                <TextInputController
                  schema={PaymentAdjustmentSchema}
                  control={control}
                  size="50"
                  label={tScholarshipKey('Tercer Pago')}
                  name="third_payment_amount"
                  type="number"
                  price
                  defaultValue={third_payment_amount}
                  disabled={!!third_payment_done_date ? !isPayment : false}
                  green={!!third_payment_done_date}
                  errors={errors}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('third_payment_amount', e.target.value)}
                />
                <KeyboardDatePicker
                  label={tScholarshipKey('Fecha tercer pago')}
                  name="third_payment_date"
                  error={!!errors.third_payment_date}
                  variant="inline"
                  inputVariant="outlined"
                  placeholder="" // Prevent google to detect this input as a credit card number
                  value={asingDateValue(third_payment_date)}
                  onChange={(date: MaterialUiPickersDate) => {
                    date && changeDate('third_payment_date', date);
                  }}
                  autoOk
                  format={DATE_FORMAT}
                  disabled={!!third_payment_done_date}
                  className={`${style.size_50} ${!!third_payment_done_date && style.green}`}
                />
                {entityView}
              </div>
            </FormContainer>
            <ButtonGroup>
              <Button
                variant="contained"
                disabled={!!diffMessage}
                startIcon={<EuroSymbolIcon />}
                color="primary"
                type="submit"
                fullWidth
              >
                {tScholarshipKey('Ajustar pago')}
              </Button>
            </ButtonGroup>
            {diffMessage && (
              <div className={style.manualError}>
                <WarningIcon style={{ verticalAlign: 'sub', marginRight: '4px', color: colorPalette.primary80 }} />
                {diffMessage}
              </div>
            )}
          </form>
        </>
      ) : (
        <Loading big text={tScholarshipKey('Por favor, espera...')} />
      )}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadingPayment: selectIsLoadingPayment(state),
  dataAdjustmentPayment: dataAdjustmentPayment(state),
  documentationAdjustment: selectDocumentationAdjustment(state),
  diffMessage: checkPaymentAdjustmentValues(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getAdjustPaymentData: (id: number): void => dispatch(getPaymentAdjustment(id)),
  sendAdjustmentPayment: (data: PaymentAdjustmentsData) => dispatch(sendPaymentAdjustment(data)),
  setData: (key: string, value: string | number): void => dispatch(scholarshipPaymentAdjustmentSetData(key, value)),
  uploadDocument: (documentation_id: number, data: File, scholarship_id: number, multiDocument?: boolean) =>
    dispatch(uploadDocument(documentation_id, data, scholarship_id, multiDocument)),
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(downloadDocument(documentation_id, name, scholarship_id)),
  getDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(getDocument(documentation_id, name, scholarship_id)),
  removeDocument: (documentation_id: number, scholarship_id: number) =>
    dispatch(removeDocument(documentation_id, scholarship_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentAdjustmentForm);
