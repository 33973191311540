import React from 'react';
import styles from './mobileTable.module.scss';
import { Column, MaterialTableProps } from 'material-table';
import Loading from '../Loading/Loading';

interface Props {
  tableData: MaterialTableProps<any>;
  columnsFilter: string[];
  onRowClick?: (rowData: any) => void;
  emptyText?: string;
}

const MobileTable = ({ tableData, columnsFilter, onRowClick, emptyText }: Props) => {
  //filtramos las columnas a mostrar en caso de que tengamos algo en el array de filtros
  const colums = columnsFilter.length
    ? tableData.columns.filter(column => columnsFilter.includes(column.field ? column.field.toString() : ''))
    : tableData.columns;
  const data: object[] = tableData.data as object[];
  /**
   * Renderiza el contenido de la celda según las opciones de la misma
   * @param column
   * @param rowData
   */
  const renderizeTextData = (column: Column<object>, rowData: Record<string, any>) => {
    //si hay una function render se renderizan los datos sino se coge el valor del campo del rowData
    if (column.render && typeof column.render === 'function') {
      return column.render(rowData, 'row');
    }
    if (column.field && rowData.hasOwnProperty(column.field)) {
      return rowData[column.field] || '';
    }
  };
  /**
   * función a realizar al pulsar en una fila
   * @param rowData
   */
  const onClick = (rowData: object) => {
    if (onRowClick) {
      onRowClick(rowData);
    }
  };
  return (
    <>
      {tableData.isLoading ? (
        <Loading big />
      ) : (
        <table className={styles.root}>
          <thead>
            <tr className={styles.row}>
              {colums.map((column, index) => {
                return (
                  <th key={index} className={styles.headingCell}>
                    {column.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((rowData, index) => {
              return (
                <tr
                  key={index}
                  className={styles.row}
                  onClick={() => {
                    onClick(rowData as object);
                  }}
                >
                  {colums.map((column, index) => (
                    <td key={index} className={styles.bodyCell}>
                      {renderizeTextData(column, rowData as Record<string, any>)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default MobileTable;
