import { AppState } from '../../root-reducer';
import { Justification } from './Justification';
import { ScholarshipJustificationsState } from './definitions';

const selectScholarshipJustificationsReducer = (state: AppState): ScholarshipJustificationsState =>
  state.scholarshipJustificationsReducer;

export const selectScholarshipJustificationsLoading = (state: AppState): boolean =>
  selectScholarshipJustificationsReducer(state).loading;
export const selectScholarshipJustificationsError = (state: AppState): string =>
  selectScholarshipJustificationsReducer(state).error;
export const selectScholarshipJustifications = (state: AppState): Justification[] =>
  selectScholarshipJustificationsReducer(state).list;
export const selectScholarshipJustificationsTotalAmount = (state: AppState): number => {
  const justifications = selectScholarshipJustifications(state);
  return Math.round(justifications.reduce((total, { amount }) => total + +amount, 0) * 100) / 100;
};
