import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { tErrorKey } from '../../helpers/translate';
import requiredStyle from '../../helpers/validator.module.scss';
import ClipBoardConnected from '../ClipBoard/ClipBoardConnected';
import styles from './comboSearch.module.scss';

export interface AutocompleteProps {
  options: any[];
  optionLabel: string;
  label: string;
  size?: string;
  control: any;
  fieldToSend: string;
  error?: FieldError | any;
  name: string;
  disabled?: boolean;
  fullWidth?: boolean;
  validator?: boolean;
  defaultValue?: string;
  setValue?: any;
  clipBoard?: boolean;
  onClick?: (e: any) => void;
  onChange?: (e: any) => void;
  onSelect?: (e: any) => void;
  onBlur?: (data: any) => void;
  onClipBoard?: (value: any) => string;
}

const ComboSearch: React.FC<AutocompleteProps> = ({
  label,
  size = '100',
  options,
  control,
  error,
  disabled,
  optionLabel,
  fieldToSend,
  name,
  fullWidth,
  validator,
  defaultValue,
  setValue,
  clipBoard,
  onChange,
  onClick,
  onSelect,
  onBlur,
  onClipBoard,
}) => {
  React.useEffect(() => {
    setValue && setValue(name, defaultValue);
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps
  const handleChange = (data: any) => {
    if (!data) {
      return;
    } else {
      onChange && onChange(data);
      return data[fieldToSend];
    }
  };

  const handleOnBlur = (data: any) => {
    if (onBlur) {
      return data[fieldToSend];
    }
  };

  // const endAdornmentType = () => {
  //   if (clipBoard)
  //     return <ClipBoardConnected value={defaultValue as string} disabled={!defaultValue} onClipBoard={onClipBoard} />;
  //   return null;
  // };

  return (
    <div className={fullWidth ? styles.comboContainerFull : styles.comboContainer}>
      <Controller
        as={
          <Autocomplete
            id="combo-box"
            disabled={disabled}
            options={options}
            getOptionLabel={(option: any) => option[optionLabel]}
            defaultValue={
              defaultValue &&
              options.find(option => option[optionLabel] === defaultValue || option[fieldToSend] === defaultValue)
            }
            classes={{
              root: `${styles.autocomplete} ${!!clipBoard ? styles.hasClipBoard : ''}`,
              inputRoot: validator ? requiredStyle.requiredAutocomplete : styles.input,
            }}
            onClick={onClick}
            renderInput={params => (
              <>
                <TextField
                  {...params}
                  style={{ width: `${size}%` }}
                  label={label}
                  error={!!error}
                  helperText={tErrorKey(error?.message as string)}
                  variant="outlined"
                  onSelect={onSelect}
                />
              </>
            )}
          />
        }
        onChange={([event, data]) => handleChange(data)}
        name={name}
        control={control}
        onBlur={([event, data]) => handleOnBlur(data)}
      />
      {clipBoard && (
        <div className={styles.clipBoard}>
          <ClipBoardConnected value={defaultValue as string} disabled={!defaultValue} onClipBoard={onClipBoard} />
        </div>
      )}
    </div>
  );
};

export default ComboSearch;
