import { Uuid } from '../common/definitions';
import { AreaSubArea } from '../project/definitions';

export type SubAreaId = number;
export type InternalProjectType = 'BIEONC' | 'BIESAL' | 'BIEKOM' | 'VOCGRU' | 'VOCCOA';
export enum InternalProjectTypeEnum {
  DOMUM = 'BIEONC',
  KOA = 'BIESAL',
  KOMTU = 'BIEKOM',
  TALLER_GRUPAL = 'VOCGRU',
  COACHING_INDIVIDUAL = 'VOCCOA',
}
export type SubareaInternalProjectType = {
  id: Uuid;
  subarea_id: SubAreaId;
  subarea: AreaSubArea;
  type: InternalProjectType;
};
