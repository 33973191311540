import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router';
import LayoutForm from '../../components/Layout/LayoutForm';
import Title from '../../components/Title/Title';
import { permissionType } from '../../constants/permissions_definitions';
import { tKey } from '../../helpers/translate';
import { AboutUsMetada } from '../../redux/aboutUs/definitions';
import { selectMetadata } from '../../redux/aboutUs/selectors';
import { IdCodeNamePair } from '../../redux/common/definitions';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import styles from './sectionMenu.module.scss';
import SubmenuConfiguration from './SubmenuConfiguration';

interface SectionMenuProps extends RouteComponentProps {
  metadata: AboutUsMetada;
  userCan: (permission: permissionType) => boolean | undefined;
}
const SectionMenu: React.FC<SectionMenuProps> = ({ match, metadata, userCan }) => {
  const history = useHistory();
  const { path } = match;
  const root = path.split('/').slice(1);
  const rootValue = root[0];
  const section = path.split('/').slice(2);
  const sectionCode = section[0];
  const { aboutUsTypes } = metadata;

  const metadataHardcode = [
    { id: 1, code: 'areas', name: tKey('Áreas') },
    { id: 2, code: 'plantillas', name: tKey('Plantillas') },
  ] as IdCodeNamePair[];

  const metadataTranslationsHardcode = [
    { id: 1, code: 'aplicativo', name: tKey('Aplicativo') },
    { id: 2, code: 'mail', name: tKey('Correos') },
    { id: 3, code: 'custom-fields', name: tKey('Campos configurados') },
    { id: 4, code: 'lopd-mail', name: tKey('Correos LOPD') },
  ] as IdCodeNamePair[];

  const metadataScholarshipHardcode = [
    { id: 1, code: 'activar-becas', name: 'Activar/desactivar becas' },
    { id: 2, code: 'fechas-de-seguimiento', name: 'Configurar fechas de seguimiento' },
    { id: 3, code: 'limpiar-becas', name: 'Limpiar becas por curso' },
  ] as IdCodeNamePair[];

  if (userCan('allow_add_zing_members')) {
    metadataScholarshipHardcode.push({ id: 4, code: 'alta-network', name: 'Alta Network' });
  }

  const metadataProjectsHardcode = [
    { id: 1, code: 'configuracion-campos/informe_seguimiento/config/', name: tKey('Configuración de campos') },
  ] as IdCodeNamePair[];

  const metadataInternalProjectsHardcode = [
    { id: 1, code: 'fecha-fin-curso', name: tKey('Configuración fecha fin de curso') },
    { id: 2, code: 'configuracion-campos/pi_info_general/config', name: tKey('Configuración de campos') },
    { id: 3, code: 'plantillas', name: tKey('Plantillas') },
  ] as IdCodeNamePair[];

  const sectionsValues = (sectionCode: string) => {
    switch (sectionCode) {
      case 'gestion-areas':
        return [...metadataHardcode];
      case 'sobre-nosotros':
        let result;
        if (aboutUsTypes) {
          return (result = [...aboutUsTypes]);
        }
        return result;
      case 'traducciones':
        return [...metadataTranslationsHardcode];
      case 'becas':
        return [...metadataScholarshipHardcode];
      case 'proyectos-internos':
        return [...metadataInternalProjectsHardcode];
      case 'proyectos':
        return [...metadataProjectsHardcode];
      default:
        break;
    }
    return [];
  };

  const sections = sectionsValues(sectionCode);

  const formTitle = section.length > 0 ? tKey(`${sectionCode}-config`) : tKey('Configuración');

  return (
    <LayoutForm leftSubmenu={<SubmenuConfiguration selected={path} submenu={path} />}>
      <Title>{formTitle}</Title>
      <div className={styles.container}>
        {sections?.map((item: IdCodeNamePair) => (
          <button
            key={item.id}
            className={styles.buttonOption}
            onClick={() => {
              history.push(`/${rootValue}/${sectionCode}/${item.code}`);
            }}
          >
            {item.name}
          </button>
        ))}
      </div>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  metadata: selectMetadata(state),
  userCan: selectUserCan(state),
});

export default connect(mapStateToProps, null)(SectionMenu);
