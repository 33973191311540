import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AuthDispatch } from '../../redux/auth/definitions';
import { CodeNamePair } from '../../redux/common/definitions';
import { selectLanguages } from '../../redux/metadata/selectors';
import { AppState } from '../../redux/root-reducer';
import { LangCodes, TypeLang } from '../../redux/translations/definitions';
import { userChangeLanguage } from '../../redux/user/actions';
import { selectUserLanguage } from '../../redux/user/selectors';
import styles from './languagesSelector.module.scss';

interface LanguagesMenuProps {
  languages?: CodeNamePair[];
  changeLanguage: (language: TypeLang) => void;
}
const LanguagesSelector = ({ languages, changeLanguage }: LanguagesMenuProps) => {
  const selectLanguage = (language_code: string | undefined) => {
    const language = language_code || LangCodes.ES;
    changeLanguage(language as TypeLang);
  };

  return (
    <>
      <ul className={styles.root}>
        {languages &&
          languages.map((lang: CodeNamePair) => {
            return (
              <li key={lang.code} className={styles.menuLanguageItem}>
                <p className={styles.menuLanguageText}>
                  <Link
                    to={'#'}
                    className={styles.menuLanguageLink}
                    onClick={() => {
                      selectLanguage(lang.code);
                    }}
                  >
                    {lang.name}
                  </Link>
                </p>
              </li>
            );
          })}
      </ul>
    </>
  );
};

const mapDispatchToProps = (dispatch: AuthDispatch | any) => ({
  changeLanguage: (language: TypeLang): void => dispatch(userChangeLanguage(language)),
});

const mapStateToProps = (state: AppState) => ({
  languages: selectLanguages(state),
  userLanguage: selectUserLanguage(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesSelector);
