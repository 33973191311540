import { Button, ButtonProps, Chip } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ObjectSchema, Shape } from 'yup';
import FileIcon from '../../components/FileIcon/FileIcon';
import FormContainer from '../../components/Forms/FormContainer';
import GenericForm from '../../components/GenericForm/GenericForm';
import GenericTable from '../../components/GenericForm/GenericTable';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../components/Layout/LayoutForm';
import Loading from '../../components/Loading/Loading';
import SubTitle from '../../components/SubTitle/Subtitle';
import Title from '../../components/Title/Title';
import { useHookMethod } from '../../helpers/customHooks/useHookMethod';
import { tKey, tProjectKey } from '../../helpers/translate';
import { KeyButton } from '../../redux/common/definitions';
import { CodeSectionProject, FormAssistantSections, SectionProject } from '../../redux/kpis/definitions';
import {
  addNewGenericTableRow,
  DeleteData,
  deleteFile,
  deleteRowTable,
  download,
  downloadFollowUpDocument,
  GenericFieldUpload,
  getFile,
  getGenericFields,
  projectScheduleSetData,
  RemoveDocData,
  removeFollowUpDocument,
  saveCustomFieldsForm,
  selectProjectScheduleItem,
  setterGenericTableRow,
  updateProjectSchedule,
  uploadFollowUpDocument,
  uploadGenericField,
} from '../../redux/project/action';
import {
  FormProjectFields,
  GenericData,
  ProjectData,
  ScheduledAppointmentItem,
  ScheduledAppointmentState,
} from '../../redux/project/definitions';
import {
  selectCurrentProjectSchedule,
  selectFields,
  selectGenericTableData,
  selectLoading,
  selectLoadingFields,
} from '../../redux/project/selectors';
import { AppState } from '../../redux/root-reducer';
import { projectScheduleItemPhoneCheckSchema } from '../../validations/tracingProjectSchema';
import { addFieldsToSchema } from './generateSchemaGenericForm';
import styles from './projectKpisSection.module.scss';
import ProjectMenuRight from './ProjectMenuRight/ProjectMenuRight';
import SubmenuProject from './SubmenuProject/SubmenuProject';

interface ProjectKpisSectionProps {
  project: ProjectData;
  section: CodeSectionProject;
  buttons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  genericTableData: GenericData;
  loading: boolean;
  loadingFields: boolean;
  fieldsData: { documents: FormProjectFields[]; elements: FormProjectFields[] };
  id_project: number; // fixing reload page
  project_schedule_id: number;
  disabled: boolean;
  currentProjectSchedule: ScheduledAppointmentItem;
  addNewRow: (setterData?: any) => void;
  setSetterGenericTableRow: (setterData: any, finalStep: boolean) => void;
  deleteRow: (deleteData: DeleteData) => void;
  sendDoc: (data: GenericFieldUpload) => void;
  downloadDocument: (file_id: number, name: string) => void;
  getDocument: (file_id: number) => void;
  deleteDocument: (data: RemoveDocData) => void;
  getFieldsData: (project_id: number, project_schedule_id: number, section: CodeSectionProject) => void;
  saveCustomFieldsForm: (
    project_id: number,
    project_schedule_id: number,
    section: CodeSectionProject,
    fields: Record<string, string>,
  ) => void;
  selectProjectScheduleItem: (id: number) => void;
  setScheduleData: (key: string, value: string | number) => void;
  updateScheduleItem: (schedule: ScheduledAppointmentItem) => void;
  uploadFollowUpDocument: (file: File) => void;
  removeFollowUpDocument: (file_id: number) => void;
  downloadFollowUpDocument: (file_id: number, filename: string) => void;
}
const ProjectKpisSection: React.FC<ProjectKpisSectionProps> = ({
  section,
  project,
  buttons,
  moreInfoButtons,
  genericTableData,
  loadingFields,
  disabled,
  fieldsData,
  id_project,
  project_schedule_id,
  currentProjectSchedule,
  loading,
  getDocument,
  setSetterGenericTableRow,
  addNewRow,
  deleteRow,
  downloadDocument,
  sendDoc,
  deleteDocument,
  getFieldsData,
  saveCustomFieldsForm,
  selectProjectScheduleItem,
  setScheduleData,
  updateScheduleItem,
  uploadFollowUpDocument,
  removeFollowUpDocument,
  downloadFollowUpDocument,
}) => {
  const [setterData, setSetterData] = useState({});

  const addDoc = (
    document: File,
    description: string,
    genericId?: any,
    documentationId?: number | undefined,
    fieldName?: string | undefined,
  ) => {
    const addData: GenericFieldUpload = {
      document,
      fieldName: fieldName ? fieldName : '',
      project_id: project.id,
      project_schedule_id: project_schedule_id,
    };
    sendDoc(addData);
  };

  const remove = (file_id: number, generic_id?: any, fieldName?: string) => {
    const deleteFileData: RemoveDocData = {
      project_id: generic_id,
      fieldName: fieldName ? fieldName : '',
      fileId: file_id,
    };

    deleteDocument(deleteFileData);
  };

  const schema: ObjectSchema<Shape<object, any>> = yup.object().shape(addFieldsToSchema(fieldsData));

  const { control, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schema,
  });

  const onSave = async (data: Record<string, string>) => {
    if (currentProjectSchedule.state === ScheduledAppointmentState.FNC_CHECK) {
      updateScheduleItem(currentProjectSchedule);
    } else {
      Object.keys(data).forEach(element => {
        if (moment.isMoment(data[element])) {
          data[element] = moment(data[element]).format('X');
        }
      });
      if (genericTableData.columns?.length > 0) {
        await setSetterGenericTableRow(setterData, true);
      }
      saveCustomFieldsForm(project.id, project_schedule_id, section, data);
    }
  };

  useEffect(() => {
    if (!loading) {
      selectProjectScheduleItem(project_schedule_id);
    }
  }, [loading, project_schedule_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getFieldsData(project.id || id_project, project_schedule_id, section);
  }, [section, project_schedule_id, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  useHookMethod(buttons, [KeyButton.SAVE], [handleSubmit(onSave)]);

  const buttonsTable: ButtonProps[] = [
    {
      onClick: () => {
        setSetterGenericTableRow(setterData, false);
        addNewRow();
      },
      children: tProjectKey('Añadir Registro'),
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
    },
  ];

  const handleDataSetter = (data: any) => {
    setSetterData(data);
  };

  const handleDeleteClick = async (row: any) => {
    const data = {
      project_id: project.id,
      section: section,
      id_row: row.id,
      id_generated: row.id_generated,
    };
    await setSetterGenericTableRow(setterData, true);
    deleteRow(data);
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      uploadFollowUpDocument(file);
    }
  };

  const handleKpisView = (currentProjectScheduleValue: ScheduledAppointmentItem) => {
    if (!loadingFields) {
      if (currentProjectScheduleValue.state === ScheduledAppointmentState.FNC_CHECK) {
        const fileView =
          currentProjectScheduleValue.file_id && currentProjectScheduleValue.filename ? (
            <Chip
              icon={<FileIcon filename={currentProjectScheduleValue.filename} />}
              size="medium"
              label={currentProjectScheduleValue.filename}
              clickable
              onClick={() =>
                downloadFollowUpDocument(
                  currentProjectScheduleValue.file_id || 0,
                  currentProjectScheduleValue.filename || '',
                )
              }
              onDelete={
                currentProjectScheduleValue.complete
                  ? undefined
                  : () => removeFollowUpDocument(currentProjectScheduleValue.file_id || 0)
              }
              className={styles.chip}
            />
          ) : (
            <div className={styles.fileNameContainer}>
              <input
                id="file"
                className={styles.FileInput}
                type="file"
                multiple
                onChange={onUpload}
                accept="application/pdf,image/*,.xlsx,.docx,.doc,.xls"
                style={{ display: 'none' }}
                disabled={!!currentProjectScheduleValue.complete}
              />
              <div className={styles.buttonUpload}>
                {!currentProjectScheduleValue.file_id && (
                  <label htmlFor="file">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      disabled={!!currentProjectScheduleValue.complete}
                      fullWidth
                    >
                      {tKey('Añadir documento')}
                    </Button>
                  </label>
                )}
              </div>
              {errors && errors.file_id && <label className={styles.helperText}>{errors.file_id.message}</label>}
            </div>
          );
        return (
          <FormContainer title="">
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={currentProjectScheduleValue.action_resum}
              schema={projectScheduleItemPhoneCheckSchema}
              label={tProjectKey('Comentario del seguimiento')}
              name="action_resum"
              disabled={disabled}
              fullWidth
              multiline
              rows="8"
              rowsMax="8"
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setScheduleData('action_resum', e.target.value)}
            />
            {fileView}
          </FormContainer>
        );
      } else {
        return (
          <Fragment>
            {fieldsData && !!Object.keys(project).length && (
              <>
                <GenericForm
                  control={control}
                  section={section}
                  project={project}
                  errors={errors}
                  fields={fieldsData}
                  disabled={disabled}
                  schema={schema}
                  addDoc={addDoc}
                  download={downloadDocument}
                  remove={remove}
                  getFile={getDocument}
                  actions={['checked', 'download', 'view', 'remove']}
                />
              </>
            )}
            {genericTableData?.columns?.length > 0 && (
              <GenericTable
                projectID={project.id}
                disabled={disabled}
                genericTableData={genericTableData}
                buttons={buttonsTable}
                onSetterData={handleDataSetter}
                handleDeleteClick={handleDeleteClick}
              />
            )}
          </Fragment>
        );
      }
    } else {
      return <Loading big />;
    }
  };

  let subtitle = `${tProjectKey('Tipo de seguimiento')}: `;

  if (currentProjectSchedule.state === ScheduledAppointmentState.BASE_LINE) {
    subtitle = subtitle.concat(tProjectKey(currentProjectSchedule.state));
  } else {
    subtitle = subtitle.concat(
      `${tProjectKey('periodo_'.concat(currentProjectSchedule.period))} (${currentProjectSchedule.tracking_date})`,
    );
  }

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuProject
          project={project}
          current_section={section}
          buttons={buttons}
          onClickOption={handleSubmit(onSave)}
        />
      }
      rightSubmenu={<ProjectMenuRight actionButtons={buttons} moreInfoButtons={moreInfoButtons} project={project} />}
    >
      <Title>
        {tProjectKey(
          FormAssistantSections.find((projectSection: SectionProject) => projectSection.code === section)?.name || '',
        )}
      </Title>
      {loading ? (
        <Loading big />
      ) : (
        <>
          <SubTitle>{subtitle}</SubTitle>
          {handleKpisView(currentProjectSchedule)}
        </>
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericTableData: selectGenericTableData(state),
  fieldsData: selectFields(state),
  loading: selectLoading(state),
  loadingFields: selectLoadingFields(state),
  currentProjectSchedule: selectCurrentProjectSchedule(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  saveCustomFieldsForm: (
    project_id: number,
    project_schedule_id: number,
    section: CodeSectionProject,
    fields: Record<string, string>,
  ) => dispatch(saveCustomFieldsForm(project_id, project_schedule_id, section, fields)),
  addNewRow: (setterData?: any) => dispatch(addNewGenericTableRow(setterData)),
  setSetterGenericTableRow: (setterData: any, finalStep: boolean) =>
    dispatch(setterGenericTableRow(setterData, finalStep)),
  deleteRow: (deleteData: DeleteData) => dispatch(deleteRowTable(deleteData)),
  getFieldsData: (project_id: number, project_schedule_id: number, section: CodeSectionProject) =>
    dispatch(getGenericFields(project_id, project_schedule_id, section)),
  sendDoc: (data: GenericFieldUpload): void => dispatch(uploadGenericField(data)),
  downloadDocument: (file_id: number, name: string): void => dispatch(download(file_id, name)),
  getDocument: (file_id: number): void => dispatch(getFile(file_id)),
  deleteDocument: (data: RemoveDocData): void => dispatch(deleteFile(data)),
  selectProjectScheduleItem: (id: number) => dispatch(selectProjectScheduleItem(id)),
  setScheduleData: (key: string, value: string | number) => dispatch(projectScheduleSetData(key, value)),
  updateScheduleItem: (schedule: ScheduledAppointmentItem) => dispatch(updateProjectSchedule(schedule)),
  uploadFollowUpDocument: (file: File) => dispatch(uploadFollowUpDocument(file)),
  removeFollowUpDocument: (file_id: number) => dispatch(removeFollowUpDocument(file_id)),
  downloadFollowUpDocument: (file_id: number, filename: string) =>
    dispatch(downloadFollowUpDocument(file_id, filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectKpisSection);
