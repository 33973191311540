import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import HorariosContainer from '../../components/Calendar/CalendarContainer';
import Layout from '../../components/Layout/Layout';
import TransitionsModal from '../../components/Modal/TransitionModal';
import SelectController from '../../components/Select/SelectController';
import { dateNowToS } from '../../helpers/dateHelper';
import { tKey, tScholarshipKey } from '../../helpers/translate';
import { getAllDates, setSelectedTime, setTimeToFree, setTimeToNotAvailable } from '../../redux/calendar/actions';
import { DatesData, IDate, ITime } from '../../redux/calendar/definitions';
import {
  selectDates,
  selectIsLoadingDates,
  selectIsLoadingTimes,
  selectSelectedDate,
} from '../../redux/calendar/selectors';
import { AppState } from '../../redux/root-reducer';
import { UserDataDispatch } from '../../redux/user/definitions';
import { interviewCalendarSchema } from '../../validations/interviewCalendarSchema';
import stylesCalendar from './interviewCalendar.module.scss';
interface InterviewCalendarProps {
  datesTimes: DatesData;
  loadingTimes: boolean;
  loadingDates: boolean;
  selectedDate: IDate;
  channel: string;
  getAllDates: (data: number) => void;
  setTimeToNotAvail: (data: number) => void;
  setTimeFree: (data: number, channel: string) => void;
  setSelectedTime: (data: ITime) => void;
}

const InterviewCalendar: React.FC<InterviewCalendarProps> = ({
  loadingDates,
  datesTimes,
  getAllDates,
  selectedDate,
  setTimeToNotAvail,
  setTimeFree,
  setSelectedTime,
}) => {
  const interviewModality = [
    { name: 'Presencial', value: 'presencial', id: 1 },
    { name: 'Online', value: 'online', id: 2 },
    { name: 'Ambas', value: 'ambas', id: 3 },
  ];
  const [getDate, setGetDate] = useState(dateNowToS());
  const [interviewOptions, setinterviewOptions] = useState({ view: false, item: {} as ITime });
  useEffect(() => {
    getAllDates(getDate);
  }, [getAllDates, getDate]);

  const { errors, control, getValues } = useForm<InterviewCalendarProps>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: interviewCalendarSchema,
  });

  const onSelectDate = (item: IDate) => {
    setGetDate(item.originalDate);
  };

  const onSelectTime = (item: ITime) => {
    setinterviewOptions(() => ({ view: true, item }));
  };

  const onShowMore = () => {
    setGetDate(datesTimes.next);
  };

  const onShowLess = () => {
    setGetDate(datesTimes.prev);
  };

  const handleYes = () => {
    const data = getValues();
    setinterviewOptions(prevState => ({ ...prevState, view: false }));
    setSelectedTime(interviewOptions.item);
    setTimeFree(interviewOptions.item.date, data.channel);
  };
  const handleClose = () => {
    setinterviewOptions(prevState => ({ ...prevState, view: false }));
  };
  const handleNo = () => {
    setSelectedTime(interviewOptions.item);
    setTimeToNotAvail(interviewOptions.item.date);
    handleClose();
  };

  const SelectChannelAndSend = () => {
    return (
      <form onSubmit={e => e.preventDefault()}>
        <div className={stylesCalendar.info}>
          <span>{tScholarshipKey('modalidad-entrevista')}</span>
        </div>
        <SelectController
          name="channel"
          control={control}
          errors={errors}
          schema={interviewCalendarSchema}
          label="canal"
          size="100"
          defaultValue={interviewOptions.item.channel}
        >
          {interviewModality.map(channels => (
            <MenuItem key={channels.id} value={channels.value}>
              {channels.name}
            </MenuItem>
          ))}
        </SelectController>
      </form>
    );
  };

  return (
    <Layout>
      <React.Fragment>
        <HorariosContainer
          dates={datesTimes.dates}
          times={datesTimes.times}
          datesLoading={loadingDates}
          timesLoading={false}
          onSelectDate={onSelectDate}
          onSelectTime={onSelectTime}
          selectedDate={selectedDate}
          onShowLess={onShowLess}
          onShowMore={onShowMore}
        />
      </React.Fragment>
      <TransitionsModal
        view={interviewOptions.view}
        handleClose={handleClose}
        handleYes={() => {
          handleYes();
        }}
        handleNo={handleNo}
        title={tKey('Seleccionar canal')}
        bodyModal={<SelectChannelAndSend />}
        buttonOk={tScholarshipKey('Ok')}
        buttonKo={tScholarshipKey('Anular')}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadingTimes: selectIsLoadingTimes(state),
  loadingDates: selectIsLoadingDates(state),
  datesTimes: selectDates(state),
  selectedDate: selectSelectedDate(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch | any) => ({
  getAllDates: (data: number): void => dispatch(getAllDates(data)),
  setTimeToNotAvail: (data: number): void => dispatch(setTimeToNotAvailable(data)),
  setTimeFree: (data: number, channel: string): void => dispatch(setTimeToFree(data, channel)),
  setSelectedTime: (data: ITime): void => dispatch(setSelectedTime(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewCalendar);
