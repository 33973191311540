import { GetParticipantsConst } from './action_types';
import { GetParticipantsDataAction, GetParticipantsState, initialState } from './definitions.d';
import assertUnreachable from '../../../../helpers/assertUnreachable';

const getParticipantsReducer = (state = initialState, action: GetParticipantsDataAction): GetParticipantsState => {
  switch (action.type) {
    case GetParticipantsConst.GET_PARTICIPANTS:
      return {
        ...state,
        loading: true,
        error: null,
        data: [],
      };
    case GetParticipantsConst.GET_PARTICIPANTS_OK: {
      const { data } = action;

      return {
        ...state,
        loading: false,
        error: null,
        data,
      };
    }
    case GetParticipantsConst.GET_PARTICIPANTS_KO: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      assertUnreachable(action);
      return state;
  }
};

export default getParticipantsReducer;
