import webStyle from './webStyle';

export type ColorPalette = {
  primary: string;
  primary80: string;
  primary40: string;
  primary20: string;
  secondary: string;
  secondary80: string;
  secondary40: string;
  secondary20: string;
  greenBubble: string;
  orangeBubble: string;
};

const fncPalette = {
  primary: '#DA291C',
  primary80: '#E15449',
  primary40: '#EC948E',
  primary20: '#F8D4D2',
  secondary: '#2A2928',
  secondary80: '#5A5957',
  secondary40: '#9A9B9B',
  secondary20: '#D7D8D8',
  greenBubble: '#3A9F1B',
  orangeBubble: '#FEA352',
} as ColorPalette;

const zingPalette = {
  primary: '#D64A45',
  primary80: '#FE5D52',
  primary40: '#F9AFAA',
  primary20: '#F8D4D2',
  secondary: '#1D1D1B',
  secondary80: '#666666',
  secondary40: '#A4A4A4',
  secondary20: '#F7F7F7',
  greenBubble: '#3A9F1B',
  orangeBubble: '#FEA352',
} as ColorPalette;

const selectPalette = (): ColorPalette => {
  return webStyle === 'zing' ? zingPalette : fncPalette;
};

export default selectPalette();
