import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { FieldError, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import styles from './GenericMultiSelectController.module.scss';

export interface OptionsGenericMultiselect {
  id: number | string;
  name: string;
  value: string;
  checked: boolean;
}

export interface GenericMultiSelectProps<T extends FieldValues> {
  label: string;
  name: string;
  value?: string[];
  disabled?: boolean;
  options: OptionsGenericMultiselect[];
  control: Control;
  defaultValue: number[] | string[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: FieldErrors<T>;
  required?: boolean;
}

const GenericMultiSelectController = <T extends FieldValues>({
  name,
  label,
  control,
  options,
  defaultValue,
  errors,
  required,
  disabled,
}: GenericMultiSelectProps<T>) => {
  const [checkedOptions, setCheckedOptions] = useState<any[]>(defaultValue);

  const handleChangeSelected = (selected: any) => {
    setCheckedOptions(selected);
    const selectedOptionsName = options
      .filter(e => {
        return selected.includes(e.id);
      })
      .map(e => e.name);
    return selectedOptionsName.join(', ');
  };
  const error = errors && (errors[name] as FieldError);
  return (
    <>
      <FormControl variant="outlined" className={`${error ? styles.containerError : ''}`}>
        <InputLabel id={name}>
          {label}
          {required && ' *'}
        </InputLabel>
        <Controller
          className={`${error ? styles.controller : ''}`}
          as={
            <Select
              label={label}
              labelId={name}
              id={name}
              name={name}
              disabled={disabled ?? false}
              multiple
              value={checkedOptions}
              renderValue={handleChangeSelected}
            >
              {options.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox checked={checkedOptions.indexOf(option.id) > -1} />
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          }
          name={name}
          control={control}
          defaultValue={defaultValue}
        ></Controller>
        {error && <span className={styles.error}>{error.message}</span>}
      </FormControl>
    </>
  );
};
export default GenericMultiSelectController;
