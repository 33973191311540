import { AppState } from '../../root-reducer';
import { Assistance, ParticipantType } from '../participants/Participant.d';
import { ParticipantsAssistanceState } from './definitions';

const selectParticipantsAssistance = (state: AppState): ParticipantsAssistanceState =>
  state.participantsAssistanceReducer;

export const selectAssistanceLoading = (state: AppState): boolean => selectParticipantsAssistance(state).loading;

export const selectAssintanceError = (state: AppState): string | null => selectParticipantsAssistance(state).error;

export const selectParticipantsAssistanceList = (state: AppState): Assistance[] =>
  selectParticipantsAssistance(state).assistance;

export const selectParticipantAssistance = (state: AppState) => (
  followup_id: number,
  participant_id: string,
): Assistance =>
  selectParticipantsAssistanceList(state).filter(
    (item: Assistance) =>
      item.internal_project_follow_up_id === followup_id && item.internal_project_participant_id === participant_id,
  )[0];

export const selectParticipantsAssistants = (state: AppState): number =>
  selectParticipantsAssistanceList(state).filter(
    (participants: Assistance) => participants.assistance && participants.type === ParticipantType.PARTICIPANT,
  ).length;

export const selectParticipantsAssistantsPercentage = (state: AppState): number => {
  const participantsAssistants = selectParticipantsAssistants(state);
  const participantsTotal = selectParticipantsAssistanceList(state).filter(
    (participants: Assistance) => participants.type === ParticipantType.PARTICIPANT,
  ).length;
  const percentage = (participantsAssistants * 100) / participantsTotal;
  return Math.round(percentage * 100) / 100;
};
