import { Button, MenuItem } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { InterviewEntity } from '../../../../redux/interviews/definitions';
import { SkipInterviewModalSchema } from '../../../../validations/formSchema';
import styles from './skipInterviewModal.module.scss';
import useInterviewEntities from './useInterviewEntities';

interface SkipInterviewModalProps {
  zip_code: string;
  handleSend: (interview_entity: string | null) => void;
  handleClose: () => void;
}

const SkipInterviewModal: React.FC<SkipInterviewModalProps> = ({ zip_code, handleSend, handleClose }) => {
  interface SkilInterview {
    needs_interview: string;
    interview_entity: string | null;
  }

  const { interviewEntities, defaultInterviewEntityId, loading } = useInterviewEntities(zip_code);
  const yesNoOptions = [
    { value: '0', label: tScholarshipKey('No') },
    { value: '1', label: tScholarshipKey('Sí') },
  ];

  const { control, errors, watch, handleSubmit, register } = useForm<SkilInterview>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: SkipInterviewModalSchema,
  });

  const onSubmit = async (data: SkilInterview) => {
    handleSend(data.needs_interview === '1' ? data.interview_entity : null);
    handleClose();
  };

  return (
    <form className={styles.skipInterviewContainer} onSubmit={handleSubmit(onSubmit)}>
      <RadioButtons
        name="needs_interview"
        questionText={tScholarshipKey('entrevista-improving')}
        register={register}
        options={yesNoOptions}
        defaultValue="1"
      />
      <SelectController
        control={control}
        name={loading ? 'interview_entity_loading' : 'interview_entity'}
        label={tScholarshipKey('interview_entity_question')}
        schema={SkipInterviewModalSchema}
        defaultValue={defaultInterviewEntityId}
        fullWidth
        disabled={loading || (watch('needs_interview') || '1') !== '1'}
        errors={errors}
      >
        {interviewEntities.map((entity: InterviewEntity) => (
          <MenuItem key={entity.id} value={entity.id}>
            {entity.alias}
          </MenuItem>
        ))}
      </SelectController>
      <div className={styles.btns}>
        <Button variant="contained" startIcon={<Check />} color="primary" type="submit" fullWidth disabled={loading}>
          {tScholarshipKey('Aceptar')}
        </Button>
      </div>
    </form>
  );
};

export default SkipInterviewModal;
