import { Delete, Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tKey } from '../../../helpers/translate';
import {
  academyContact,
  academyContactsList,
  closeContactModal,
  deleteContactData,
  openContactModal,
  removeContactAcademy,
} from '../../../redux/academy/actions';
import { AcademyContactData } from '../../../redux/academy/definitions';
import { selectAcademyContacts } from '../../../redux/academy/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import CreateModalResponsible from '../Modals/CreateModalResponsible';
import EditModalResponsible from '../Modals/EditModalResponsible';
import styles from '../Modals/modalCreate.module.scss';
interface AcademyConfigProps {
  contactsList: any[];
  academyId: number;
  loading: boolean;

  contactViewModal: boolean;
  getAcademyContacts: (id: number) => void;
  setDelete: (academy_id: number, staff_id: number) => void;
  userCan: (permission: permissionType) => boolean | undefined;
  onSelectComponent: (componentId: number) => void;

  openContactModal: () => void;
  closeContactModal: () => void;
  deleteContactData: () => void;
  getAcademyContact: (academy_id: number, staff_id: number) => void;
}

const UnConnectedAcademyContacts: React.FC<AcademyConfigProps> = ({
  contactsList,
  academyId,
  loading,
  contactViewModal,
  getAcademyContacts,
  setDelete,
  userCan,
  openContactModal,
  closeContactModal,
  deleteContactData,
  getAcademyContact,
}) => {
  useEffect(() => {
    getAcademyContacts(academyId);
  }, [academyId, getAcademyContacts]);

  const [contactModal, setContactModal] = React.useState({
    view: contactViewModal,
    children: <Fragment></Fragment>,
    title: '',
  });

  const onAddContactOne = () => {
    setContactModal({
      ...contactModal,
      children: <CreateModalResponsible />,
      title: tKey('Crear Responsable'),
    });
    openContactModal();
  };

  const onEditContactOne = (rowData: AcademyContactData) => {
    getAcademyContact(academyId, rowData.id);
    setContactModal({
      ...contactModal,
      children: <EditModalResponsible />,
      title: tKey('Editar Responsable'),
    });
    openContactModal();
  };

  const action_pma = userCan('action_pma');
  const action_dir = userCan('action_dir');

  const columnList = [
    { title: tKey('Cargo'), field: 'staff_role' },
    { title: tKey('Nombre'), field: 'name' },
    { title: tKey('Apellidos'), field: 'surname' },
    { title: tKey('Email'), field: 'email' },
    { title: tKey('Teléfono'), field: 'phone' },
    {
      title: tKey('Comentarios'),
      field: 'description',
      render: (rowData: AcademyContactData) => (
        <div style={{ textOverflow: 'ellipsis', width: '200px' }}>{rowData.description}</div>
      ),
    },
  ];

  const actionIcons = {
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    Edit: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };
  const tableData: MaterialTableProps<AcademyContactData> = {
    title: tKey('Listado de responsables'),
    columns: columnList,
    data: contactsList,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, tKey('Listado de responsables'));
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    actions: [
      (rowData: AcademyContactData) => ({
        icon: actionIcons.Edit,
        onClick: () => onEditContactOne(rowData),
        tooltip: tKey('editar'),
      }),
      (rowData: AcademyContactData) => ({
        icon: actionIcons.Delete,
        onClick: () => setDelete(academyId, rowData.id),
        tooltip: tKey('borrar'),
      }),
    ],
  };

  if (loading) return <Loading big />;

  return (
    <>
      <TableWithEdit
        tableData={{ ...tableData }}
        permission={action_pma || action_dir}
        optionsNew={{ newButtonText: tKey('Nuevo responsable'), onNew: onAddContactOne }}
      />
      <TransitionModal
        view={contactViewModal}
        handleClose={() => {
          deleteContactData();
          closeContactModal();
        }}
        title={contactModal.title}
        bodyModal={<div className={styles.modal}>{contactModal.children}</div>}
      />
    </>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: state.academyReducer.loadingContactsList,
  contactsList: selectAcademyContacts(state),
  contactViewModal: state.academyReducer.contactModalView,
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setDelete: (academy_id: number, staff_id: number): void => dispatch(removeContactAcademy(academy_id, staff_id)),
  getAcademyContacts: (id: number): void => dispatch(academyContactsList(id)),
  openContactModal: (): void => dispatch(openContactModal()),
  closeContactModal: (): void => dispatch(closeContactModal()),
  deleteContactData: (): void => dispatch(deleteContactData()),
  getAcademyContact: (academy_id: number, staff_id: number): void => dispatch(academyContact(academy_id, staff_id)),
});
const AcademyContacts = connect(mapStateProps, mapDispatchToProps)(UnConnectedAcademyContacts);

export default AcademyContacts;
