import React from 'react';
import { useSelector } from 'react-redux';
import SubTitle from '../../../../../../../../components/FncSubtitle/FncSubtitle';
import { selectInternalProjectFacilitadores } from '../../../../../../../../redux/internal-projects/selectors';
import styles from './internalPorjectFacilitadores.module.scss';
import MobileTable from '../../../../../../../../components/Tables/MobileTable';
import { MaterialTableProps } from 'material-table';
import { Member } from '../../../../../../../../redux/internal-projects/definitions';
import { tKey, tProjectKey } from '../../../../../../../../helpers/translate';

const InternalProjectFacilitadoresView: React.FC = () => {
  const facilitadores = useSelector(selectInternalProjectFacilitadores);

  const tableData: MaterialTableProps<Member> = {
    title: tProjectKey('pi.lista_de_facilitadores'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'name',
        //@ts-ignore
        width: '50%',
      },
      {
        title: tKey('Email'),
        field: 'email',
        //@ts-ignore
        width: '50%',
      },
    ],
    data: facilitadores,
    isLoading: false,
    options: {
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'fixed',
    },
  };

  return (
    <div className={styles.container}>
      <SubTitle>Facilitadores</SubTitle>
      <MobileTable
        tableData={tableData}
        columnsFilter={['name', 'email']}
        emptyText={tProjectKey('pi.no_hay_facilitadores')}
      />
    </div>
  );
};

export default InternalProjectFacilitadoresView;
