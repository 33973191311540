import { Button, Chip, SvgIcon } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React from 'react';
import { FieldErrors } from 'react-hook-form/dist/types';
import { tKey } from '../../helpers/translate';
import { ReactComponent as excelIcon } from '../../images/icons/excel.svg';
import { ReactComponent as pdfIcon } from '../../images/icons/pdf.svg';
import { ReactComponent as wordIcon } from '../../images/icons/word.svg';
import styles from './uploadFileButton.module.scss';

interface UploadFileButtonProps {
  file_id: number | null;
  filename: string | null;
  readOnly?: boolean;
  errors: FieldErrors<{ file_id: number }>;
  onUploadFile: (file: File) => void;
  downloadFile: (file_id: number, filename: string) => void;
  removeFile: (file_id: number) => void;
  setValue: (name: string, value: any) => void;
}

const UploadFileButton: React.FC<UploadFileButtonProps> = ({
  file_id,
  filename,
  readOnly,
  errors,
  onUploadFile,
  downloadFile,
  removeFile,
}) => {
  const colorize = (name: string) => {
    const res = name.substr(name.lastIndexOf('.') + 1);

    switch (res) {
      case 'pdf':
        return <SvgIcon component={pdfIcon} viewBox="0 0 600 476.6" />;
      case 'docx':
        return <SvgIcon component={wordIcon} viewBox="0 0 600 476.6" />;
      case 'xlsx':
        return <SvgIcon component={excelIcon} viewBox="0 0 600 476.6" />;
      default:
        return <ImageIcon />;
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      onUploadFile(file);
    }
  };

  return file_id && filename ? (
    <Chip
      icon={colorize(filename)}
      size="medium"
      label={filename}
      clickable
      onClick={() => downloadFile(file_id, filename)}
      onDelete={() => removeFile(file_id)}
      className={styles.chip}
      disabled={readOnly}
    />
  ) : (
    <div className={styles.fileNameContainer}>
      <input
        id="file"
        className={styles.FileInput}
        type="file"
        multiple
        onChange={onUpload}
        accept="application/pdf,image/*,.xlsx,.docx,.doc,.xls"
        style={{ display: 'none' }}
        disabled={readOnly}
      />
      <div className={styles.buttonUpload}>
        {!file_id && (
          <label htmlFor="file">
            <Button variant="contained" color="secondary" component="span" disabled={readOnly} fullWidth>
              {tKey('Adjuntar archivo')}
            </Button>
          </label>
        )}
      </div>
      {!errors.file_id || <label className={styles.helperText}>{errors.file_id.message}</label>}
    </div>
  );
};

export default UploadFileButton;
