/* eslint-disable @typescript-eslint/no-explicit-any */
import AlbumIcon from '@material-ui/icons/Album';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import FncSelect, { OptionSelect } from '../../components/Forms/FncSelect/FncSelect';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import { tKey } from '../../helpers/translate';
import { IdCodeNamePair } from '../../redux/common/definitions';
import { getInternalProjectSubareas, setLatestSubArea } from '../../redux/internal-projects/actions';
import { selectInternalProjectSubAreas, selectIpLatestSubArea } from '../../redux/internal-projects/selectors';
import { AppState } from '../../redux/root-reducer';

interface SubmenuInternalProjectsProps {
  selected: number;
  subAreas: IdCodeNamePair[];
  latestSubAreaCode: string | null;
  getSubAreas: () => void;
  setLatestSubArea: (area: string) => null;
  onSelectComponentId: ({
    componentId,
    subAreaCode,
    name,
  }: {
    componentId: number;
    subAreaCode: string;
    name: string;
  }) => void;
}

const SubmenuInternalProjects: React.FC<SubmenuInternalProjectsProps> = ({
  selected,
  subAreas,
  latestSubAreaCode,
  getSubAreas,
  setLatestSubArea,
  onSelectComponentId,
}) => {
  const isMobile = useWindowSize().IsMobile;
  const subAreasLoad = useRef(true);

  useEffect(() => {
    getSubAreas();
  }, [getSubAreas]);

  const handleSelectComponent = (componentId: number) => {
    const subArea = subAreas.find((subArea: IdCodeNamePair) => subArea.id === componentId);
    setLatestSubArea(subArea?.code || '');
    onSelectComponentId({ componentId, subAreaCode: subArea?.code || '', name: subArea?.name || '' });
  };

  useEffect(() => {
    if (subAreas[0] && subAreasLoad.current) {
      subAreasLoad.current = false;
      const subArea = subAreas.find((subArea: IdCodeNamePair) => subArea.code === latestSubAreaCode);
      if (subArea) handleSelectComponent(subArea.id);
      else handleSelectComponent(subAreas[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subAreas]);

  const optionsSubmenu: SubmenuItem[] = subAreas.map((subArea: IdCodeNamePair) => ({
    title: tKey(subArea.name),
    componentId: subArea.id,
    icon: subArea.id % 2 === 0 ? <FiberManualRecordIcon /> : <AlbumIcon />,
    type: 'component',
    selected: subArea.id === selected,
  }));

  const optionsSelect: OptionSelect[] = subAreas.map((subArea: IdCodeNamePair) => ({
    label: tKey(subArea.name),
    value: subArea.id,
  }));

  return isMobile ? (
    <FncSelect options={optionsSelect} onChange={e => handleSelectComponent(parseInt(e.target.value))} />
  ) : (
    <Submenu
      titleMenu={tKey('Proyectos internos')}
      optionsMenu={optionsSubmenu}
      onSelectComponentId={handleSelectComponent}
      defaultOpen
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  subAreas: selectInternalProjectSubAreas(state),
  latestSubAreaCode: selectIpLatestSubArea(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getSubAreas: (): void => dispatch(getInternalProjectSubareas()),
  setLatestSubArea: (area: string) => dispatch(setLatestSubArea(area)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuInternalProjects);
