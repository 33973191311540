import React from 'react';
import { SubmenuComponentId } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tScholarshipKey } from '../../../helpers/translate';
import { ScholarshipData } from '../../../redux/scholarship/definitions';
import styles from './mobileSubmenu.module.scss';

interface MobileSubmenuProps {
  scholarshipData: ScholarshipData;
  menus: SubmenuComponentId[];
  onChangeMenu: (menuId: number) => void;
}

const MobileSubmenu = ({ menus, onChangeMenu }: MobileSubmenuProps) => {
  return (
    <nav className={styles.root}>
      <h2 className={styles.title}>{tScholarshipKey('Datos de beca')}</h2>
      <ul className={styles.list}>
        {menus.map((menu: SubmenuComponentId) => {
          let stylesClass = '';
          switch (menu.colorStyle) {
            case 'red':
              stylesClass = styles.borderRed;
              break;
            case 'yellow':
              stylesClass = styles.borderYellow;
              break;
            case 'green':
              stylesClass = styles.borderGreen;
              break;
          }
          return (
            <li
              key={menu.componentId}
              className={`${styles.item} ${stylesClass}`}
              onClick={() => {
                onChangeMenu(menu.componentId);
              }}
            >
              <div className={styles.icon}>{menu.iconMobile}</div>
              <h3 className={styles.itemTitle}>{menu.title}</h3>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MobileSubmenu;
