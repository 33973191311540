import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styles from './simpleModal.module.scss';

interface SimpleModal {
  children: JSX.Element;
  title: string;
  handleClose?: () => void;
  view: boolean;
}

const SimpleModal: React.FC<SimpleModal> = ({ handleClose, children, title, view }) => {
  return (
    <Dialog
      open={view}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.container}>
        <div className={styles.title}>
          <DialogTitle classes={{ root: styles.titleRed }} id="alert-dialog-title">
            {title}
          </DialogTitle>
          <IconButton onClick={handleClose} aria-label="delete" className={styles.buttonClose} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </Dialog>
  );
};

export default SimpleModal;
