import React from 'react';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import { tScholarshipKey } from '../../../../helpers/translate';
import FollowUpCode from '../../../../types/Scholarships/followUpCode';

interface EconomicalValorationProps {
  followUpCode: FollowUpCode;
  isEntity: boolean;
}

const MessageFollow: React.FC<EconomicalValorationProps> = ({ followUpCode, isEntity }) => {
  const getMessage = (): string => {
    const followNumber = followUpCode.followUpNumber() + (!!isEntity ? 0 : 3);
    const followUpType = followUpCode.isThridFollowUp() ? '' : followUpCode.isBiannual() ? 'S' : 'T';
    return tScholarshipKey(`MessageFollowP${followNumber}${followUpType}`);
  };
  return <MessageInfo>{getMessage()}</MessageInfo>;
};

export default MessageFollow;
