export const isAdult = (dateString: number) => {
  const today = new Date();
  const dateBirth = new Date(dateString);
  let age = today.getFullYear() - dateBirth.getFullYear();
  const diferenceMonth = today.getMonth() - dateBirth.getMonth();
  if (diferenceMonth < 0 || (diferenceMonth === 0 && today.getDate() < dateBirth.getDate())) {
    age--;
  }
  return age >= 18;
};

export const getAge = (dateString: string) => {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
