import { IdTypeNamePair } from '../../../../../redux/common/definitions';
import { ScholarshipAbandonmentReasonsType } from '../../../../../redux/scholarship/definitions';

export function useAcademicResultViewModel() {
  return {
    filterScholarshipAbandonmentReasonsByType: (
      reasons: IdTypeNamePair<ScholarshipAbandonmentReasonsType>[],
      type: ScholarshipAbandonmentReasonsType,
    ) => reasons.filter(reason => reason.type === type),
    scholarshipAbandonmentReasonsForKeepStudying: (
      reasons: IdTypeNamePair<ScholarshipAbandonmentReasonsType>[],
      keep_studying: number,
    ) =>
      reasons.filter(reason =>
        keep_studying === 0
          ? reason.type === ScholarshipAbandonmentReasonsType.NO
          : keep_studying === 2
          ? reason.type !== ScholarshipAbandonmentReasonsType.NO
          : false,
      ),
  };
}
