import { ButtonProps } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { exportScholarshipsToPayFromApi } from '../../../api/Scholarship/scholarship';
import Layout from '../../../components/Layout/Layout';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { getDocs } from '../../../helpers/getDocs';
import { tKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';
import { getScholarshipsToPayInstitute } from '../../../redux/scholarship/actions';
import { ScholarshipPayment } from '../../../redux/scholarship/definitions';
import {
  selectIsLoading,
  selectPaymentAcademicYear,
  selectScholarshipPaymentList,
  selectScholarshipReload,
} from '../../../redux/scholarship/selectors';
import AccountingSubmenu from '../AccountingSubmenu';
import { ScholarshipPaymentsFilterGenerator } from './filters/ScholarshipPaymentsFilterGenerator';
import useScholarshipPaymentFilters from './hooks/useScholarshipPaymentFilters';
import ScholarshipsPaymentFilters from './ScholarshipsPaymentFilters';
import ScholarshipsPaymentList from './ScholarshipsPaymentList';

interface InstituteScholarshipsPaymentsProps extends RouteComponentProps<{ id: string }> {
  reload: boolean;
  loading: boolean;
  selectedAcademicYear: string;
  scholarshipPaymentList: ScholarshipPayment[];
  getScholarshipPayments: (academic_year: string) => void;
}

const InstituteScholarshipsPayments: React.FC<InstituteScholarshipsPaymentsProps> = ({
  reload,
  loading,
  selectedAcademicYear,
  scholarshipPaymentList,
  getScholarshipPayments,
}) => {
  const { filterValues, applyFilters } = useScholarshipPaymentFilters();

  useEffect(() => {
    if (selectedAcademicYear) {
      getScholarshipPayments(selectedAcademicYear);
    }
  }, [selectedAcademicYear, getScholarshipPayments]);

  useEffect(() => {
    if (reload) {
      getScholarshipPayments(selectedAcademicYear);
    }
  }, [selectedAcademicYear, getScholarshipPayments, reload]);

  const getPaymentsList = (): ScholarshipPayment[] => {
    const filterGenerator = new ScholarshipPaymentsFilterGenerator(scholarshipPaymentList, filterValues);
    const scholarshipPayments = filterGenerator.getFilteredList();
    return scholarshipPayments;
  };

  const exportExcel = async () => {
    const file = await exportScholarshipsToPayFromApi(selectedAcademicYear);
    const fileName = `pagos_becas_instituto_${selectedAcademicYear.replace('/', '_')}.xlsx`;
    getDocs(file, true, fileName);
  };

  const actionButtons: ButtonProps[] = [
    {
      children: tKey('Exportar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      startIcon: <GetAppIcon />,
      onClick: () => exportExcel(),
    },
  ];

  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="institute" submenu={tKey('Pagos')} />}
      rightSubmenu={<ActionsMenu actionsButtons={actionButtons} goBack />}
    >
      <ScholarshipsPaymentFilters title={tKey('Becas instituto')} applyFilters={applyFilters} />
      <ScholarshipsPaymentList
        loading={loading}
        isEntity={false}
        title={'Pagos becas instituto'}
        scholarshipPaymentList={getPaymentsList()}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectPaymentAcademicYear(state),
  scholarshipPaymentList: selectScholarshipPaymentList(state),
  reload: selectScholarshipReload(state),
  loading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getScholarshipPayments: (academic_year: string) => dispatch(getScholarshipsToPayInstitute(academic_year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstituteScholarshipsPayments);
