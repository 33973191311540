import { Button, ButtonProps } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Save } from '@material-ui/icons';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getCustomFieldsValueListFromApi } from '../../../api/CustomField/customField';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import ComboSearch from '../../../components/ComboSearch/ComboSearch';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import Title from '../../../components/Title/Title';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { selectUserLang } from '../../../redux/auth/selectors';
import { CodeNamePair, IdCodeNamePair } from '../../../redux/common/definitions';
import { internalProjectsectionField } from '../../../redux/internal-projects/definitions';
import { createCustomfield, editCustomfield, getKpiIndicators, getOneCustomField } from '../../../redux/kpis/action';
import {
  CustomField,
  CustomFieldADispatch,
  CustomFieldDataTypes,
  KpiIndicatorType,
  SectionEnum,
  sectionField,
} from '../../../redux/kpis/definitions';
import {
  selectCustomFieldLoading,
  selectKpiTypes,
  selectListCustomFields,
  selectOneCustomField,
} from '../../../redux/kpis/selectors';
import { getProjectMetadata } from '../../../redux/metadata/actions';
import { ProjectMetadata } from '../../../redux/metadata/definitions';
import { selectLanguages, selectProjectMetadata } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { LangCodes } from '../../../redux/translations/definitions';
import { KPISSchema } from '../../../validations/formSchema';
import InternalProjectsConfigurationFields from '../../InternalProjects/InternalProjectsConfigurationFields/InternalProjectsConfigurationFields';
import SubmenuConfCustomField from '../SubmenuConfCustomField';
import styles from './kpis.module.scss';
import { selectAreasList, selectSubAreasList } from '../../../redux/area/selectors';
import { selectSubareaInternalProjectTypeList } from '../../../redux/subareaInternalProjectType/selectors';
import { getAreas } from '../../../redux/area/action';
import { getAreasSubAreas } from '../../../redux/project/action';
import { getSubareaInternalProjectType } from '../../../redux/subareaInternalProjectType/action';
import { AreasData } from '../../../redux/area/definitions';
import { AreaSubArea } from '../../../redux/project/definitions';
import { SubareaInternalProjectType } from '../../../redux/subareaInternalProjectType/subareaInternalProjectType';
import GenericMultiSelectController, {
  OptionsGenericMultiselect,
} from '../../../components/Select/GenericMultiSelectController/GenericMultiSelectController';
import { InternalProjectTypesEnum } from '../../../types/InternalProjects/InternalProjectTypes.d';

interface CustomFieldFormProps extends RouteComponentProps<{ id: string; code: string }> {
  customField: CustomField;
  disabled?: boolean;
  loadingCF: boolean;
  areas: AreasData[];
  subareas: AreaSubArea[];
  internalProjectType: SubareaInternalProjectType[];
  user_lang: string;
  leftMenu?: boolean;
  languages: CodeNamePair[];
  kpiTypes: KpiIndicatorType[];
  projectMetadata: ProjectMetadata;
  customFieldsInfo: CustomField[];
  getOneCustomField: (code: string, id: string) => void;
  createCustomField: (data: CustomField) => void;
  editCustomField: (data: CustomField, id: string) => void;
  getProjectMetadata: () => void;
  getKpiTypes: () => void;
  getAreas: () => void;
  getSubareas: () => void;
  getSubareaInternalProjectType: () => void;
}

const CustomFieldForm: React.FC<CustomFieldFormProps> = ({
  match,
  customField,
  disabled,
  areas,
  subareas,
  internalProjectType,
  loadingCF,
  leftMenu,
  user_lang,
  languages,
  projectMetadata,
  kpiTypes,
  customFieldsInfo,
  getOneCustomField,
  createCustomField,
  editCustomField,
  getProjectMetadata,
  getKpiTypes,
  getAreas,
  getSubareas,
  getSubareaInternalProjectType,
}) => {
  const [valuesList, setValuesList] = React.useState<IdCodeNamePair[]>(customField.value_list || []);
  const [originValuesList, setOriginValuesList] = React.useState<IdCodeNamePair[]>([]);
  const {
    params: { id, code },
  } = match;
  const [, , field] = match.url.split('/');
  const kpiTypeBool = code === SectionEnum.KPI;
  const metadataLoaded = projectMetadata.loaded;

  const isProjectConfig = field === 'proyectos';

  useEffect(() => {
    if (areas.length === 0) {
      getAreas();
    }
  }, [areas, getAreas]);
  useEffect(() => {
    if (subareas.length === 0) {
      getSubareas();
    }
  }, [subareas, getSubareas]);
  useEffect(() => {
    if (internalProjectType.length === 0) {
      getSubareaInternalProjectType();
    }
  }, [internalProjectType, getSubareaInternalProjectType]);
  useEffect(() => {
    if (kpiTypeBool) {
      getKpiTypes();
    }
  }, [kpiTypeBool, getKpiTypes]);

  useEffect(() => {
    if (!metadataLoaded) {
      getProjectMetadata();
    }
  }, [metadataLoaded, getProjectMetadata]);

  useEffect(() => {
    if (id) getOneCustomField(code, id);
  }, [code, getOneCustomField, id]);

  useEffect(() => {
    if (customField.value_list?.length > 0) {
      setValuesList(customField.value_list || []);
    }
  }, [customField]);

  useEffect(() => {
    if (customField.origin_custom_field_id) {
      (async function fetchData() {
        const values = await getCustomFieldsValueListFromApi(customField.origin_custom_field_id || 0);
        setOriginValuesList(values);
      })();
    }
  }, [customField]); // eslint-disable-line react-hooks/exhaustive-deps

  const { register, handleSubmit, errors, control, getValues, setValue, watch, setError, clearError } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: KPISSchema,
  });

  const optionsAreasMultiSelect: OptionsGenericMultiselect[] = areas.map(area => {
    return {
      id: area.id,
      name: area.name,
      value: '',
      checked: false,
    } as OptionsGenericMultiselect;
  });
  const optionsSubAreasMultiSelect: OptionsGenericMultiselect[] = subareas.map(subarea => {
    return {
      id: subarea.id,
      name: subarea.name,
      value: '',
      checked: false,
    } as OptionsGenericMultiselect;
  });
  const optionsSubareaInternalProjectTypeMultiSelect: OptionsGenericMultiselect[] = internalProjectType.map(type => {
    return {
      id: type.id,
      name: InternalProjectTypesEnum[type.type],
      value: '',
      checked: false,
    } as OptionsGenericMultiselect;
  });
  const getOriginValuesList = async (target: any) => {
    if (target.value) {
      const origin_id = target.value as number;
      const values = await getCustomFieldsValueListFromApi(origin_id);
      setOriginValuesList(values);
      setValue('origin_value', '');
    }
  };

  const handleDelete = (row: any) => {
    const name = row.name;
    setValuesList((prevArray: any) => [...prevArray.filter((prev: any) => prev.name !== name)]);
    setValue('value_list', '');
  };

  const handleAddValue = () => {
    clearError();
    const name = getValues().values;
    const code = getValues().code || getValues().values;
    if (!name?.trim()) {
      setError('values', 'noMatch', tErrorKey('El campo está vacío'));
      return;
    }

    const values = { name, code, id: -1 };

    setValuesList((prevArray: IdCodeNamePair[]) => [...prevArray, values]);
    setValue('values', '');
    setValue('code', '');
  };

  const handleChange = (event: any) => {
    const dataType = event.target.value;
    if (dataType === CustomFieldDataTypes.RADIO) {
      setValue('size', 'Large');
      setValue('custom_field_type', 'Global');
    } else if (
      dataType === CustomFieldDataTypes.ADJUNTO ||
      dataType === CustomFieldDataTypes.ETIQUETA ||
      dataType === CustomFieldDataTypes.MULTISELECT ||
      dataType === CustomFieldDataTypes.SUBTITULO
    ) {
      setValue('custom_field_type', 'Global');
    }
  };

  const onSubmit = (data: CustomField) => {
    clearError();
    data.field = field;
    data.section = isProjectConfig
      ? sectionField?.find(section => section.code === code)?.code || ''
      : internalProjectsectionField?.find(section => section.code === code)?.code || '';
    data.value_list = valuesList?.length ? valuesList : [];
    delete data.values;
    createCustomField(data);
  };
  const onEdit = (data: CustomField) => {
    clearError();
    data.mandatory = +data.mandatory ? 1 : 0;
    data.section = isProjectConfig
      ? sectionField?.find(section => section.code === code)?.code || ''
      : internalProjectsectionField?.find(section => section.code === code)?.code || '';
    data.value_list = valuesList?.length ? valuesList : [];
    delete data.values;
    data.custom_fields = customField.custom_fields;
    editCustomField(data, id);
  };

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onEdit),
      startIcon: <Save />,
      hidden: disabled || !leftMenu,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
    {
      children: tKey('CREAR'),
      hidden: leftMenu,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
      startIcon: <PostAddIcon />,
      onClick: handleSubmit(onSubmit),
    },
  ];

  const section = sectionField?.find(section => section.code === code)?.name;
  const section_pi = internalProjectsectionField?.find(section => section.code === code)?.name;

  const title = () => {
    if (disabled) {
      return `${tKey('Detalle de')} ${tKey(section || '')}`;
    }
    if (!disabled && id) {
      return `${tKey('Edición de')} ${tKey(section || '')}`;
    }
    return `${tKey('Creación de')} ${tKey(section || '')}`;
  };

  let description_lang;
  let description_lang_0;
  let description_lang_1;
  let description_lang_2;
  let field_lang_0;
  let field_lang_1;
  let field_lang_2;
  const label_lang = tFormKey('Descripción');
  let label_lang_0;
  let label_lang_1;
  let label_lang_2;

  const showRealLang = (lan: string) => {
    const language = languages.find((l: CodeNamePair) => l.code === lan);
    const lang_name = language ? language.name?.toLowerCase() : '';
    return label_lang.concat(' ', lang_name || '');
  };

  switch (user_lang) {
    case LangCodes.ES:
      description_lang = customField.description_es;
      description_lang_0 = customField.description_ca;
      description_lang_1 = customField.description_fr;
      description_lang_2 = customField.description_en;
      field_lang_0 = 'description_ca';
      field_lang_1 = 'description_fr';
      field_lang_2 = 'description_en';
      label_lang_0 = showRealLang('ca');
      label_lang_1 = showRealLang('fr');
      label_lang_2 = showRealLang('en');
      break;
    case LangCodes.CA:
      description_lang = customField.description_ca;
      description_lang_0 = customField.description_es;
      description_lang_1 = customField.description_fr;
      description_lang_2 = customField.description_en;
      field_lang_0 = 'description_es';
      field_lang_1 = 'description_fr';
      field_lang_2 = 'description_en';
      label_lang_0 = showRealLang('es');
      label_lang_1 = showRealLang('fr');
      label_lang_2 = showRealLang('en');
      break;
    case LangCodes.FR:
      description_lang = customField.description_fr;
      description_lang_0 = customField.description_es;
      description_lang_1 = customField.description_ca;
      description_lang_2 = customField.description_en;
      field_lang_0 = 'description_es';
      field_lang_1 = 'description_ca';
      field_lang_2 = 'description_en';
      label_lang_0 = showRealLang('es');
      label_lang_1 = showRealLang('ca');
      label_lang_2 = showRealLang('en');
      break;
    case LangCodes.EN:
      description_lang = customField.description_en;
      description_lang_0 = customField.description_es;
      description_lang_1 = customField.description_ca;
      description_lang_2 = customField.description_fr;
      field_lang_0 = 'description_es';
      field_lang_1 = 'description_ca';
      field_lang_2 = 'description_fr';
      label_lang_0 = showRealLang('es');
      label_lang_1 = showRealLang('ca');
      label_lang_2 = showRealLang('fr');
      break;
  }

  let custom_field_title = tFormKey('Tipo de entrada');
  if (customField.code) {
    custom_field_title = custom_field_title.concat(' - ', customField.code);
  }

  let tipo_dato_config;
  switch (watch('field_data_types')) {
    case CustomFieldDataTypes.LISTA_DE_VALORES:
    case CustomFieldDataTypes.MULTISELECT:
      const tableDataList = {
        options: {
          search: false,
          paging: false,
          actionsColumnIndex: -1,
          toolbar: false,
        },
        title: tKey('Lista de valores'),
        columns: [{ field: 'name', title: tKey('Descripción'), width: '650px' }],
        data: valuesList,
      };

      tipo_dato_config = (
        <FormContainer title={tKey('Lista de valores')} className={styles.form}>
          {!disabled && (
            <div className={styles.table_value}>
              <TextInputController
                control={control}
                errors={errors}
                schema={KPISSchema}
                label={tFormKey('Nombre del valor')}
                name="values"
                disabled={disabled}
                classes={{ root: styles.input_style }}
              />
              <Button classes={{ root: styles.add }} variant="contained" color="primary" onClick={handleAddValue}>
                {tKey('Añadir')}
              </Button>
            </div>
          )}
          <div className={styles.table_delete}>
            <TableWithEdit
              tableData={tableDataList}
              key={JSON.stringify(tableDataList.data)}
              deleteFunc={disabled ? undefined : (rowData: any) => handleDelete(rowData)}
            />
          </div>
        </FormContainer>
      );
      break;
    case CustomFieldDataTypes.RADIO:
      const tableData: MaterialTableProps<IdCodeNamePair> = {
        options: {
          search: false,
          paging: false,
          actionsColumnIndex: -1,
          toolbar: false,
          tableLayout: 'auto',
        },
        title: tKey('Lista de opciones'),
        columns: [
          { field: 'name', title: tKey('Nombre de la opción') },
          { field: 'code', title: tKey('Valor') },
        ],
        data: valuesList,
      };

      tipo_dato_config = (
        <FormContainer title={tKey('Lista de opciones')} className={styles.form}>
          {!disabled && (
            <div className={styles.table_value}>
              <TextInputController
                control={control}
                errors={errors}
                schema={KPISSchema}
                label={tFormKey('Nombre de la opción')}
                name="values"
                disabled={disabled}
                classes={{ root: styles.input_style }}
                size="40"
              />
              <TextInputController
                control={control}
                errors={errors}
                schema={KPISSchema}
                label={tFormKey('Valor')}
                name="code"
                disabled={disabled}
                classes={{ root: styles.input_style }}
                size="40"
              />
              <Button classes={{ root: styles.add }} variant="contained" color="primary" onClick={handleAddValue}>
                {tKey('Añadir')}
              </Button>
            </div>
          )}
          <div className={styles.table_delete}>
            <TableWithEdit
              tableData={tableData}
              key={JSON.stringify(tableData.data)}
              deleteFunc={disabled ? undefined : (rowData: any) => handleDelete(rowData)}
            />
          </div>
          <div className={styles.radio_options}>
            <div className={styles.checkbox}>
              <CheckboxCompo
                name="radio_vertically"
                questionText={tFormKey('Mostrar opciones en Vertical')}
                register={register}
                disabled={disabled}
                defaultValue={!customField.id ? 1 : +customField.radio_vertically}
              />
            </div>
            <SelectController
              size="30"
              control={control}
              errors={errors}
              schema={KPISSchema}
              disabled={disabled || !!customField.radio_value_label_position}
              name="field_radio_value_label_position"
              label={tFormKey('Posición del Nombre del Valor')}
              defaultValue={projectMetadata.customFields_radioPosition[1].code}
            >
              {projectMetadata.customFields_radioPosition.map(type => (
                <MenuItem key={type.id} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
            </SelectController>
          </div>
        </FormContainer>
      );
      break;
    default:
      tipo_dato_config = <Fragment />;
      break;
  }

  let renderDepenpency = null;
  let renderDepenpencyValue = null;

  if (!isProjectConfig) {
    const dependentCustomFields = id
      ? customField.custom_fields
      : customFieldsInfo
          .filter(
            c =>
              c.field_data_types !== CustomFieldDataTypes.SUBTITULO &&
              c.field_data_types !== CustomFieldDataTypes.ETIQUETA &&
              c.field_data_types !== CustomFieldDataTypes.ADJUNTO,
          )
          .map(({ id, code, description }) => ({ id, code, name: description } as IdCodeNamePair));

    if (dependentCustomFields && (dependentCustomFields.length === 0 || dependentCustomFields[0].id !== 0))
      dependentCustomFields.unshift({ id: 0, code: 'NULO' } as IdCodeNamePair);

    if (
      projectMetadata.customFields_dependency_conditions.length > 1 &&
      watch('origin_condition') === projectMetadata.customFields_dependency_conditions[1]
    ) {
      if (originValuesList.length > 0) {
        renderDepenpencyValue = (
          <SelectController
            name="origin_value"
            label={tFormKey('Valor')}
            defaultValue={customField.origin_value || ''}
            disabled={disabled || !watch('origin_custom_field_id') || watch('origin_custom_field_id') === 0}
            control={control}
            errors={errors}
            schema={KPISSchema}
          >
            {originValuesList.map((value: IdCodeNamePair) => (
              <MenuItem key={value.id} value={value.name}>
                {value.name}
              </MenuItem>
            ))}
          </SelectController>
        );
      } else {
        renderDepenpencyValue = (
          <TextInputController
            name="origin_value"
            label={tFormKey('Valor')}
            defaultValue={customField.origin_value || ''}
            disabled={disabled || !watch('origin_custom_field_id') || watch('origin_custom_field_id') === 0}
            control={control}
            errors={errors}
            schema={KPISSchema}
          />
        );
      }
    }

    renderDepenpency = (
      <FormContainer title={tFormKey('Dependencia')} className={styles.dependency_section}>
        <SelectController
          name="origin_custom_field_id"
          label={tFormKey('Campo origen')}
          defaultValue={customField.origin_custom_field_id || 0}
          disabled={disabled}
          control={control}
          errors={errors}
          schema={KPISSchema}
          onClick={e => getOriginValuesList(e.target)}
        >
          {dependentCustomFields?.map((cf: IdCodeNamePair) => (
            <MenuItem key={cf.id} value={cf.id}>
              {cf.code} {!!cf.name ? '-' : ''} {cf.name}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          name="origin_condition"
          label={tFormKey('Condición')}
          defaultValue={customField.origin_condition || ''}
          disabled={disabled || !watch('origin_custom_field_id') || watch('origin_custom_field_id') === 0}
          control={control}
          errors={errors}
          schema={KPISSchema}
        >
          {projectMetadata.customFields_dependency_conditions?.map((condition: string, index: number) => (
            <MenuItem key={index} value={condition}>
              {condition}
            </MenuItem>
          ))}
        </SelectController>
        {renderDepenpencyValue}
      </FormContainer>
    );
  }

  const area_ids: number[] = watch('area_ids');
  const subarea_ids: number[] = watch('subarea_ids');
  const subareaInternalProjectType_ids: string[] = watch('subareaInternalProjectType_ids');
  return (
    <LayoutForm
      leftSubmenu={
        isProjectConfig ? (
          <SubmenuConfCustomField submenu={tKey(section || '')} selected={'config'} />
        ) : (
          <InternalProjectsConfigurationFields submenu={tKey(section_pi || '')} selected={'config'} />
        )
      }
      rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
    >
      {loadingCF || !metadataLoaded ? (
        <Loading big />
      ) : (
        <Fragment>
          <Title>{title()}</Title>
          <FormContainer title={tFormKey('Grupo')}>
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={customField.section || section || section_pi}
              schema={KPISSchema}
              label={tFormKey('Sección')}
              name="section"
              size="50"
              disabled
            />

            {kpiTypeBool && (
              <ComboSearch
                options={kpiTypes}
                control={control}
                error={errors.kpi_indicator_type_id}
                label={tFormKey('Tipo de indicador')}
                optionLabel="description"
                fieldToSend="id"
                name="kpi_indicator_type_id"
                disabled={disabled}
                defaultValue={watch('kpi_indicator_type_id') || customField.kpi_indicator_type_id}
              />
            )}
          </FormContainer>
          <FormContainer title={custom_field_title}>
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={description_lang}
              schema={KPISSchema}
              disabled={disabled}
              label={label_lang}
              name={`description_${user_lang}`}
              size="50"
            />
            <SelectController
              size="20"
              control={control}
              errors={errors}
              schema={KPISSchema}
              disabled={disabled || !!customField.field_data_types}
              name="field_data_types"
              label={tFormKey('Tipo de dato')}
              defaultValue={customField.field_data_types || ''}
              onClick={handleChange}
            >
              {projectMetadata.customFields_inputs.map((type, index) => (
                <MenuItem key={index} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              size="20"
              control={control}
              errors={errors}
              schema={KPISSchema}
              defaultValue={customField.custom_field_type || watch('custom_field_type') || ''}
              name="custom_field_type"
              disabled={
                disabled ||
                !!customField.custom_field_type ||
                watch('field_data_types') === CustomFieldDataTypes.ADJUNTO ||
                watch('field_data_types') === CustomFieldDataTypes.ETIQUETA ||
                watch('field_data_types') === CustomFieldDataTypes.RADIO ||
                watch('field_data_types') === CustomFieldDataTypes.MULTISELECT ||
                watch('field_data_types') === CustomFieldDataTypes.SUBTITULO
              }
              label={tFormKey('Ámbito de actuación')}
            >
              {projectMetadata.customFields_types.map(type => (
                <MenuItem selected key={type} value={type}>
                  {tKey(type)}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              size="20"
              control={control}
              errors={errors}
              schema={KPISSchema}
              name="size"
              disabled={disabled || watch('field_data_types') === CustomFieldDataTypes.RADIO}
              label={tFormKey('Tamaño')}
              defaultValue={customField.size || watch('size') || ''}
            >
              {projectMetadata.customFields_sizes.map(type => (
                <MenuItem key={type.code} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              control={control}
              errors={errors}
              schema={KPISSchema}
              label={tFormKey('Posición')}
              name="position"
              disabled={disabled}
              size="20"
              defaultValue={customField.position?.toString()}
            />
            <CheckboxCompo
              name="mandatory"
              questionText={tFormKey('¿Campo obligatorio?')}
              register={register}
              disabled={disabled}
              defaultValue={!customField.id ? 1 : customField.mandatory}
              form
            />{' '}
            <CheckboxCompo
              name="project_default"
              questionText={tFormKey('¿Campo por defecto?')}
              register={register}
              disabled={disabled}
              defaultValue={!customField.id ? 1 : +customField.project_default}
              form
            />
            {!id && (
              <div className={styles.selectors}>
                {isProjectConfig && (
                  <GenericMultiSelectController
                    label={tFormKey('Selecciona una área')}
                    name="area_ids"
                    options={optionsAreasMultiSelect}
                    control={control}
                    errors={errors}
                    disabled={
                      (subarea_ids && subarea_ids.length > 0) ||
                      (subareaInternalProjectType_ids && subareaInternalProjectType_ids.length > 0)
                    }
                    required
                    defaultValue={[]}
                  />
                )}

                <GenericMultiSelectController
                  label={tFormKey('Selecciona una sub área')}
                  name="subarea_ids"
                  options={optionsSubAreasMultiSelect}
                  control={control}
                  errors={errors}
                  required
                  disabled={
                    (area_ids && area_ids.length > 0) ||
                    (subareaInternalProjectType_ids && subareaInternalProjectType_ids.length > 0)
                  }
                  defaultValue={[]}
                />

                {!isProjectConfig && (
                  <GenericMultiSelectController
                    label={tFormKey('Selecciona un tipo proyecto')}
                    name="subareaInternalProjectType_ids"
                    options={optionsSubareaInternalProjectTypeMultiSelect}
                    control={control}
                    errors={errors}
                    required
                    disabled={(area_ids && area_ids.length > 0) || (subarea_ids && subarea_ids.length > 0)}
                    defaultValue={[]}
                  />
                )}
              </div>
            )}
          </FormContainer>
          {renderDepenpency}
          <FormContainer title={tFormKey('Multiidioma')}>
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={description_lang_0}
              schema={KPISSchema}
              disabled={disabled}
              label={label_lang_0}
              name={field_lang_0 || ''}
              size="30"
            />
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={description_lang_1}
              schema={KPISSchema}
              disabled={disabled}
              label={label_lang_1}
              name={field_lang_1 || ''}
              size="30"
            />
            <TextInputController
              control={control}
              errors={errors}
              defaultValue={description_lang_2}
              schema={KPISSchema}
              disabled={disabled}
              label={label_lang_2}
              name={field_lang_2 || ''}
              size="30"
            />
          </FormContainer>
          {tipo_dato_config}
        </Fragment>
      )}
    </LayoutForm>
  );
};

const mapStateProps = (state: AppState) => ({
  customField: selectOneCustomField(state),
  customFieldsInfo: selectListCustomFields(state),
  loadingCF: selectCustomFieldLoading(state),
  user_lang: selectUserLang(state),
  languages: selectLanguages(state),
  projectMetadata: selectProjectMetadata(state),
  kpiTypes: selectKpiTypes(state),
  areas: selectAreasList(state),
  subareas: selectSubAreasList(state),
  internalProjectType: selectSubareaInternalProjectTypeList(state),
});

const mapDispatchProps = (dispatch: CustomFieldADispatch | any) => ({
  getKpiTypes: (): void => dispatch(getKpiIndicators()),
  getProjectMetadata: (): void => dispatch(getProjectMetadata()),
  getOneCustomField: (code: string, id: string): void => dispatch(getOneCustomField(code, id)),
  createCustomField: (data: CustomField): void => dispatch(createCustomfield(data)),
  editCustomField: (data: CustomField, id: string): void => dispatch(editCustomfield(data, id)),
  getAreas: (): void => dispatch(getAreas()),
  getSubareas: (): void => dispatch(getAreasSubAreas()),
  getSubareaInternalProjectType: (): void => dispatch(getSubareaInternalProjectType()),
});

export default connect(mapStateProps, mapDispatchProps)(CustomFieldForm);
