import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { tKey } from '../../../../helpers/translate';
import { FilterInputDirectory } from '../../../../redux/directory/definitions';
import styles from './selectedItems.module.scss';

interface SelectedItemsProps {
  data: FilterInputDirectory;
  onCleanValue: (key?: any) => void;
}

const SelectedItems: React.FC<SelectedItemsProps> = ({ data, onCleanValue }) => {
  if (!Object.values(data).length) return null;
  return (
    <div className={styles.root}>
      <ul className={styles.list}>
        {Object.entries(data).map(([key, obj], index) => {
          if (obj) {
            return (
              <li key={index} className={styles.item}>
                {obj.value} <CloseIcon onClick={() => onCleanValue(key)} />
              </li>
            );
          } else {
            return null;
          }
        })}
        <li className={styles.clearItem}>
          <button className={styles.clearItem__Button} onClick={() => onCleanValue()}>
            {tKey('Borrar todo')}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SelectedItems;
