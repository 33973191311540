import assertUnreachable from '../../helpers/assertUnreachable';
import { InterviewsConsts } from './action_types';
import { initialState, InterviewDataAction, InterviewState } from './definitions';

const entityReducer = (state = initialState, action: InterviewDataAction): InterviewState => {
  switch (action.type) {
    case InterviewsConsts.GET_INTERVIEWS:
    case InterviewsConsts.GET_INTERVIEWS_FINISHED:
      return {
        ...state,
        loading: true,
      };
    case InterviewsConsts.GET_INTERVIEWS_OK:
    case InterviewsConsts.GET_INTERVIEWS_FINISHED_OK:
      const { interviewTableData } = action;
      return {
        ...state,
        loading: false,
        interviewTableData,
      };
    case InterviewsConsts.GET_INTERVIEWS_KO:
    case InterviewsConsts.GET_INTERVIEWS_FINISHED_KO:
      return {
        ...state,
        error: action.error,
      };
  }
  assertUnreachable(action);
  return state;
};

export default entityReducer;
