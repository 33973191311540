import React from 'react';
import { FieldValues } from 'react-hook-form/dist/types';
import styles from './fncRadioButton.module.scss';

interface Props<T extends FieldValues> {
  name: string;
  value: string | number;
  id: string;
  groupValue: string | number;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  vertical?: boolean;
  disabled?: boolean;
}

const FncRadioButton = <T extends FieldValues>({
  name,
  value,
  groupValue,
  label,
  id,
  vertical,
  onChange,
  disabled,
  ...props
}: Props<T>) => {
  const checked = !!groupValue && value.toString() === groupValue.toString();
  return (
    <>
      <input
        disabled={disabled}
        className={styles.input}
        type="radio"
        value={value}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        {...props}
      />
      <label
        className={`${styles.label} 
        ${vertical ? styles.isVertical : ''}`}
        htmlFor={id}
      >
        {label}
      </label>
    </>
  );
};

export default FncRadioButton;
