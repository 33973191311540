import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styles from './transitionModal.module.scss';

interface TransitionsModalProps extends TransitionsModalPropsButton {
  titleModal?: string;
  bodyModal?: string | JSX.Element;
  children?: JSX.Element | undefined;
  view: boolean;
  buttonOk?: string | null;
  buttonKo?: string | null;
  handleClose: (e?: any) => void;
  buttonNums?: string;
  title?: string;
  maxWidth?: boolean;
  helperMode?: boolean;
  documentMode?: boolean;
  followUpMode?: boolean;
  ultraWidth?: boolean;
}

interface TransitionsModalPropsButton {
  handleYes?: (element: any) => any;
  handleNo?: (element: any) => any;
}

const TransitionsModal: React.FC<TransitionsModalProps> = ({
  view,
  handleYes,
  handleNo,
  handleClose,
  bodyModal,
  buttonOk,
  buttonKo,
  title,
  children,
  maxWidth,
  helperMode = false,
  documentMode = false,
  followUpMode = false,
  ultraWidth = false,
}) => {
  return (
    <Dialog
      open={view}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth={maxWidth ? 'sm' : 'xl'}
    >
      {title && (
        <div className={styles.title}>
          <DialogTitle classes={{ root: styles.titleRed }} id="alert-dialog-title">
            {title}
          </DialogTitle>
          <IconButton onClick={handleClose} aria-label="delete" className={styles.buttonClose} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      )}
      {bodyModal && <DialogContent>{bodyModal}</DialogContent>}
      {children && !helperMode && !documentMode && !followUpMode && !ultraWidth && (
        <DialogContent className={styles.dialogContent}>{children}</DialogContent>
      )}
      {children && helperMode && <DialogContent className={styles.dialogHelperContent}>{children}</DialogContent>}
      {children && documentMode && <DialogContent className={styles.documentMode}>{children}</DialogContent>}
      {children && followUpMode && <DialogContent className={styles.followUpMode}>{children}</DialogContent>}
      {children && ultraWidth && <DialogContent className={styles.ultraWidth}>{children}</DialogContent>}
      {(buttonKo || buttonOk) && (
        <DialogActions>
          {buttonKo && (
            <Button onClick={handleNo ? handleNo : handleClose} color="secondary" variant="contained" disableElevation>
              {buttonKo}
            </Button>
          )}
          {buttonOk && (
            <Button onClick={handleYes || handleClose} color="primary" autoFocus variant="contained" disableElevation>
              {buttonOk}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default TransitionsModal;
