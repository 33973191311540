import { MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HorariosContainer from '../../../components/Calendar/CalendarContainer';
import Layout from '../../../components/Layout/Layout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SelectController from '../../../components/Select/SelectController';
import { dateNowToS, unixToDatetime } from '../../../helpers/dateHelper';
import { tScholarshipKey } from '../../../helpers/translate';
import { getFreeInterviewDates, scholarshipAskInterviews } from '../../../redux/calendar-scholarship/actions';
import {
  selectDates,
  selectIsLoadingDates,
  selectIsLoadingTimes,
  selectSelectedDate,
} from '../../../redux/calendar-scholarship/selectors';
import { calendarChannels, DatesData, IDate, ITime } from '../../../redux/calendar/definitions';
import { AppState } from '../../../redux/root-reducer';
import { scholarshipGet } from '../../../redux/scholarship/actions';
import { ScholarshipStateCode, ScholarshipData, ScholarshipMetadata } from '../../../redux/scholarship/definitions';
import { selectGetScholarship, selectGetScholarshipMetadata } from '../../../redux/scholarship/selectors';
import { checkValidation } from '../../../validations/checkValidation';
import { interviewCalendarSchema } from '../../../validations/interviewCalendarSchema';
import styles from './studentCalendar.module.scss';

interface StudentCalendarProps {
  datesTimes: DatesData;
  loadingTimes: boolean;
  loadingDates: boolean;
  selectedDate: IDate;
  scholarship: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  getFreeDates: (scholarship_id: number, data: number) => void;
  scholarshipAskInterviews: (data: number, id: number, redirect: string, channel: string) => void;
  getScholarship: (id: number) => void;
}

const StudentCalendar: React.FC<StudentCalendarProps> = ({
  loadingDates,
  datesTimes,
  scholarship,
  selectedDate,
  scholarshipMetadata,
  getFreeDates,
  scholarshipAskInterviews,
  getScholarship,
}) => {
  const presencial = 'presencial';
  const online = 'online';
  const { t } = useTranslation();
  const {
    location: {
      state: { id },
    },
  } = useHistory();

  const [getDate, setGetDate] = useState(dateNowToS());
  const [modalView, setModalView] = useState({ view: false, idTime: 0, interviewType: null as calendarChannels });

  useEffect(() => {
    getScholarship(id);
  }, [getScholarship]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getFreeDates(id, +getDate.toFixed());
  }, [getFreeDates, getDate, id]);

  const onSelectDate = (item: IDate) => {
    setGetDate(item.originalDate);
  };

  const onSelectTime = (item: ITime) => {
    setModalView(() => ({ view: true, idTime: item.date, interviewType: item.channel }));
  };

  const onShowMore = () => {
    setGetDate(datesTimes.next);
  };

  const onShowLess = () => {
    setGetDate(datesTimes.prev);
  };

  interface StudentCalendar {
    channel: string;
  }

  const { errors, control, getValues, setError } = useForm<StudentCalendar>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: interviewCalendarSchema,
  });

  const onlineChannels = scholarshipMetadata?.interviewChannels?.map((channels: string) => (
    <MenuItem key={channels} value={channels}>
      {tScholarshipKey(t(`scholarship.interviews.studentCalendar.channels.${channels}`))}
    </MenuItem>
  ));
  const allChannels = [
    { name: 'Meet', value: 'meet', id: 1 },
    { name: 'Whatsapp', value: 'whatsapp', id: 2 },
    { name: 'Presencial', value: 'presencial', id: 3 },
  ];
  const bothChannels = allChannels.map(channels => (
    <MenuItem key={channels.id} value={channels.value}>
      {channels.name}
    </MenuItem>
  ));

  const onlineAndBothInfo = (
    <div className={styles.info}>{tScholarshipKey('Por favor, selecciona un canal para la entrevista')}</div>
  );
  const attendedInfo = (
    <div className={styles.info}>
      {tScholarshipKey('La entrevista será presencial. Recibirás un correo con las indicaciones.')}
    </div>
  );

  const SelectChannelAndSend = () => {
    return (
      <React.Fragment>
        <form onSubmit={e => e.preventDefault()}>
          <span className={styles.question}>
            {tScholarshipKey('dia-entrevista')}
            {unixToDatetime(modalView.idTime)}
          </span>
          {modalView.interviewType !== presencial ? onlineAndBothInfo : attendedInfo}
          {modalView.interviewType !== presencial ? (
            <SelectController
              name="channel"
              control={control}
              errors={errors}
              schema={interviewCalendarSchema}
              label={tScholarshipKey('Canal')}
              size="100"
            >
              {modalView.interviewType === online ? onlineChannels : bothChannels}
            </SelectController>
          ) : null}
        </form>
      </React.Fragment>
    );
  };

  const handleYes = async () => {
    let data;
    modalView.interviewType !== presencial ? (data = getValues()) : (data = { channel: 'presencial' });

    const isValid = await checkValidation(interviewCalendarSchema, data, setError);
    isValid && scholarshipAskInterviews(modalView.idTime, id, `becas/institute/detalle/${id}`, data.channel);
    isValid && setModalView(prevState => ({ ...prevState, view: false }));
  };

  return (
    <Layout>
      <React.Fragment>
        {scholarship.state_code === ScholarshipStateCode.PENDING_INTERVIEW && (
          <HorariosContainer
            dates={datesTimes.dates}
            times={datesTimes.times}
            datesLoading={loadingDates}
            timesLoading={false}
            onSelectDate={onSelectDate}
            onSelectTime={onSelectTime}
            selectedDate={selectedDate}
            onShowMore={datesTimes.next ? onShowMore : undefined}
            onShowLess={datesTimes.prev ? onShowLess : undefined}
          />
        )}
      </React.Fragment>
      <TransitionModal
        view={modalView.view}
        handleClose={() => {
          setModalView(prevState => ({ ...prevState, view: false }));
        }}
        handleYes={() => {
          handleYes();
        }}
        title={tScholarshipKey('Aviso')}
        bodyModal={<SelectChannelAndSend />}
        buttonOk={tScholarshipKey('Ok')}
        buttonKo={tScholarshipKey('Cancelar')}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadingTimes: selectIsLoadingTimes(state),
  loadingDates: selectIsLoadingDates(state),
  datesTimes: selectDates(state),
  selectedDate: selectSelectedDate(state),
  scholarship: selectGetScholarship(state),
  scholarshipMetadata: selectGetScholarshipMetadata(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getScholarship: (id: number): void => dispatch(scholarshipGet(id)),
  getFreeDates: (scholarship_id: number, data: number): void => dispatch(getFreeInterviewDates(scholarship_id, data)),
  scholarshipAskInterviews: (data: number, id: number, redirect: string, channel: string): void =>
    dispatch(scholarshipAskInterviews(data, id, redirect, channel)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentCalendar);
