import { ButtonProps } from '@material-ui/core';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import React from 'react';
import { connect } from 'react-redux';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { permissionType } from '../../../constants/permissions_definitions';
import { tProjectKey } from '../../../helpers/translate';
import { selectIsNousCims, selectUserInfo } from '../../../redux/auth/selectors';
import { KeyButton } from '../../../redux/common/definitions';
import { CodeSectionProject, ProjectSections } from '../../../redux/kpis/definitions';
import { selectPermissionsList } from '../../../redux/permissions/selectors';
import { ProjectData } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import styles from './submenuProject.module.scss';

interface SubmenuProjectProps {
  current_section: CodeSectionProject;
  project: ProjectData;
  buttons: ButtonProps[];
  user_id?: number | undefined;
  permissions?: permissionType[] | undefined;
  isNouscims?: boolean | undefined;
  onClickOption: () => void;
}

const SubmenuProject: React.FC<SubmenuProjectProps> = ({
  current_section,
  project,
  buttons,
  user_id,
  permissions,
  isNouscims,
  onClickOption,
}) => {
  const optionsMenuRevamp = ProjectSections({ project, user_id, permissions, isNouscims });

  const submenu: SubmenuItem[] = optionsMenuRevamp.map(section => ({
    title: tProjectKey(section.name),
    type: 'route',
    route: `/proyecto/detalle/${project.id}/${
      (section.code === 'kpis' || section.code === 'informe_seguimiento') && project.active_schedule_item_id
        ? section.code.concat('/', project.active_schedule_item_id.toString())
        : section.code
    }`,
    selected: section.code === current_section,
    icon: !!project?.steps ? (
      //@ts-ignore
      <FiberManualRecordSharpIcon fontSize="small" classes={{ root: styles[project.steps[section.code]?.color] }} />
    ) : (
      undefined
    ),
  }));

  return (
    <Submenu
      titleMenu={tProjectKey('Datos del proyecto')}
      optionsMenu={submenu}
      defaultOpen
      onClickOption={buttons.find(button => button.key === KeyButton.SAVE)?.hidden ? undefined : onClickOption}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
  user_id: selectUserInfo(state).user_id,
  isNouscims: selectIsNousCims(state),
});

export default connect(mapStateToProps, null)(SubmenuProject);
