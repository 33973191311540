import { permissionType } from '../../constants/permissions_definitions';
import { PermissionsConsts } from './action_types';
import { PermissionsListAction, PermissionsListKoAction, PermissionsListOkAction } from './definitions';

export function permissionsList(): PermissionsListAction {
  return {
    type: PermissionsConsts.PERMISSIONS_LIST,
  };
}

export function permissionsListOk(permissions: permissionType[]): PermissionsListOkAction {
  return {
    type: PermissionsConsts.PERMISSIONS_LIST_OK,
    permissions,
  };
}

export function permissionsListKo(error: string): PermissionsListKoAction {
  return {
    type: PermissionsConsts.PERMISSIONS_LIST_KO,
    error,
  };
}
