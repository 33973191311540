/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonProps, Dialog, DialogContent, DialogTitle, IconButton, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { Suspense, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import { createInternalProjectEnrollmentFromApi } from '../../../../api/internalProject';
import { getUserByField } from '../../../../api/user';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import Modal from '../../../../components/Modal/Modal';
import SelectController from '../../../../components/Select/SelectController';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tErrorKey, tFormKey, tKey } from '../../../../helpers/translate';
import { docTypesArray } from '../../../../redux/common/definitions';
import { setNewInternalProjectEnrollment } from '../../../../redux/internal-projects/actions';
import {
  InternalProjectDispatch,
  InternalProjectEnrollment,
  NewEnrollment,
} from '../../../../redux/internal-projects/definitions';
import { selectInternalProjectsState } from '../../../../redux/internal-projects/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { UserData } from '../../../../redux/user/definitions';
import { InternalProjectNewEnrollmentSchema } from '../../../../validations/formSchema';
import { validateIdentificationCard } from '../../../../validations/validatorUser';
import validate from '../../../../validations/validators';
import styles from './newEnrollmentModal.module.scss';

interface NewEnrollmentModalProps {
  view: boolean;
  internal_project_id: number;
  validationErrors: APIValidationError | null;
  handleClose: () => void;
  setNewEnrollment: (new_enrollemnt: InternalProjectEnrollment) => void;
}

const NewEnrollmentModal: React.FC<NewEnrollmentModalProps> = ({
  view,
  internal_project_id,
  validationErrors,
  handleClose,
  setNewEnrollment,
}) => {
  const [showMessage, setShowMessage] = useState({
    show: false,
    new_enrollment: {} as NewEnrollment,
    data: {} as UserData,
  });
  const { errors, handleSubmit, watch, setError, clearError, control } = useForm<NewEnrollment>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InternalProjectNewEnrollmentSchema,
  });

  useValidatorAPI(validationErrors, setError);

  const addUser = async (data: NewEnrollment) => {
    const newEnrollment = await createInternalProjectEnrollmentFromApi({
      ...data,
      internal_project_id,
    });
    if (newEnrollment) {
      setNewEnrollment(newEnrollment);
      handleClose();
    }
  };

  const onSubmit = async (data: NewEnrollment) => {
    if (
      validate.documentIdentification({
        documentType: data.document_type,
        documentNumber: data.document_number,
        country: null,
      })
    ) {
      clearError();

      const user_data = await getUserByField(data.email, data.document_number);
      if (user_data.id > 0) {
        setShowMessage({ show: true, new_enrollment: data, data: user_data });
      } else {
        addUser(data);
      }
    } else {
      setError('document_number', 'invalidDocumentNumber', tErrorKey(`${data.document_type} incorrecto`));
    }
  };

  const closeMessage = () => {
    setShowMessage({ show: false, new_enrollment: {} as NewEnrollment, data: {} as UserData });
  };

  const setUserData = () => {
    const enrollment = {
      name: showMessage.data.name,
      surname: showMessage.data.surname,
      surname2: showMessage.data.surname2,
      email: showMessage.data.email,
      document_type: showMessage.data.document_type,
      document_number: showMessage.data.document_number,
      user_id: showMessage.data.id,
    } as NewEnrollment;
    addUser(enrollment);
    closeMessage();
  };

  const overridesUserData = () => {
    const enrollment = {
      name: showMessage.new_enrollment.name,
      surname: showMessage.new_enrollment.surname,
      surname2: showMessage.new_enrollment.surname2,
      email: showMessage.new_enrollment.email,
      document_type: showMessage.new_enrollment.document_type,
      document_number: showMessage.new_enrollment.document_number,
      user_id: showMessage.data.id,
    } as NewEnrollment;
    addUser(enrollment);
    closeMessage();
  };

  const modalButtons: ButtonProps[] = [
    {
      children: tKey('Cancelar'),
      disableElevation: true,
      onClick: () => closeMessage(),
      color: 'secondary',
      variant: 'contained',
    },
    {
      children: tKey('Sobrescribir'),
      disableElevation: true,
      onClick: () => overridesUserData(),
      color: 'primary',
      variant: 'contained',
    },
    {
      children: tKey('Aceptar'),
      disableElevation: true,
      onClick: () => setUserData(),
      color: 'primary',
      variant: 'contained',
    },
  ];

  return (
    <>
      <Dialog
        open={view}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        closeAfterTransition
        maxWidth="md"
      >
        <div className={styles.title}>
          <DialogTitle id="alert-dialog-title">{tFormKey('Nuevo participante')}</DialogTitle>
          <IconButton aria-label="delete" className={styles.buttonClose} size="small" onClick={() => handleClose()}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <FormContainer className={styles.enrollmentForm} title={''} lowMargin>
          <SelectController
            size="50"
            key="document_type"
            name="document_type"
            defaultValue={'DNI'}
            errors={errors}
            label={tFormKey('Tipo de Documento')}
            control={control}
            schema={InternalProjectNewEnrollmentSchema}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              validateIdentificationCard(
                e.target.value,
                watch('document_number'),
                'document_number',
                setError,
                clearError,
              )
            }
          >
            {docTypesArray.map(docType => (
              <MenuItem key={docType} value={docType}>
                {docType}
              </MenuItem>
            ))}
          </SelectController>
          <TextInputController
            label={tFormKey('Número de documento')}
            size="50"
            name="document_number"
            control={control}
            errors={errors}
            schema={InternalProjectNewEnrollmentSchema}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              validateIdentificationCard(
                watch('document_type'),
                e.target.value,
                'document_number',
                setError,
                clearError,
              )
            }
          />
          <TextInputController
            label={tFormKey('Nombre')}
            name="name"
            size="50"
            control={control}
            errors={errors}
            schema={InternalProjectNewEnrollmentSchema}
            autoFocus
          />
          <TextInputController
            label={tFormKey('Primer apellido')}
            name="surname"
            size="50"
            control={control}
            errors={errors}
            schema={InternalProjectNewEnrollmentSchema}
            autoFocus
          />
          <TextInputController
            label={tFormKey('Segundo apellido')}
            name="surname2"
            size="50"
            control={control}
            errors={errors}
            schema={InternalProjectNewEnrollmentSchema}
            autoFocus
          />
          <TextInputController
            label={tFormKey('Correo')}
            size="50"
            name="email"
            control={control}
            errors={errors}
            schema={InternalProjectNewEnrollmentSchema}
            autoFocus
          />
        </FormContainer>

        <DialogContent className={styles.dialogContent}>
          <div className={styles.btn_create}>
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              {tKey('Cancelar')}
            </Button>
            <Button color="primary" variant="outlined" onClick={handleSubmit(onSubmit)}>
              {tKey('Aceptar')}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Suspense fallback={<></>}>
        <Modal
          title={tFormKey('Joven existente')}
          onClose={() => closeMessage()}
          open={showMessage.show}
          buttons={modalButtons}
        >
          <div className={styles.userExistLayout}>
            <p> {tFormKey('ip.user_exist')}</p>
            <ul className={styles.userExistData}>
              <li>
                <b>{tFormKey('Nombre')}</b>
                {`: ${showMessage.data.name} ${showMessage.data.surname} ${showMessage.data.surname2}`}
              </li>
              <li>
                <b>{tFormKey('Tipo de Documento')}</b>
                {`: ${showMessage.data.document_type} `}
              </li>
              <li>
                <b>{tFormKey('Número de documento')}</b>
                {`: ${showMessage.data.document_number}`}
              </li>
              <li>
                <b>{tFormKey('Correo')}</b>
                {`: ${showMessage.data.email}`}
              </li>
            </ul>
            <ul className={styles.userExistInfo}>
              <li>
                <b>{tKey('Sobrescribir')}</b>
                {`, ${tFormKey('ip.overrides_message')}`}
              </li>
              <li>
                <b>{tKey('Aceptar')}</b>
                {`, ${tFormKey('ip.add_user_message')}`}
              </li>
            </ul>
          </div>
        </Modal>
      </Suspense>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  validationErrors: selectInternalProjectsState(state).validationError,
});

const mapDispatchToProps = (dispatch: InternalProjectDispatch) => ({
  setNewEnrollment: (new_enrollemnt: InternalProjectEnrollment): void =>
    dispatch(setNewInternalProjectEnrollment(new_enrollemnt)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewEnrollmentModal);
