import { put, select, takeLatest } from '@redux-saga/core/effects';
import {
  getLopdMailTranslation,
  getTranslationSelectData,
  testMailTranslation,
  updateLopdMailTranslation,
  updateTranslationData,
} from '../../api/Configuration/configuration';
import { Await } from '../../api/api';
import {
  getFileTranslation,
  getMailDetailsApi,
  getMailsListApi,
  getTranslationKeys,
  sendTranslationImport,
  updateMailDetailsApi,
} from '../../api/translationKeys';
import { tErrorKey, tKey } from '../../helpers/translate';
import { showSnackbar } from '../FeedbackAPI/actions';
import { selectUserLang } from '../auth/selectors';
import { AppState } from '../root-reducer';
import { TranslationConst } from './action_types';
import {
  getCategoriesTkeysOk,
  getFileTranslationsKo,
  getFileTranslationsOk,
  getListMailsKo,
  getListMailsOk,
  getLopdTranslationOk,
  getMailDetailsKo,
  getMailDetailsOk,
  getMetadataTranslationKo,
  getMetadataTranslationOk,
  sendImportTranslationsKo,
  sendImportTranslationsOk,
  updateLopdTranslationOk,
  updateMailDetailsKo,
  updateMailDetailsOk,
  updateTranslatedDataKo,
  updateTranslatedDataOk,
} from './actions';
import {
  GetFileTranslationsAction,
  GetListMailsAction,
  GetLopdTranslationAction,
  GetMailDetailsAction,
  GetMetadataTranslationAction,
  SendImportTranslationsAction,
  TestTranslationMailAction,
  UpdateLopdTranslationAction,
  UpdateMailDetailsAction,
  UpdateTranslatedDataAction,
} from './definitions';
import { selectDetailsMail } from './selectors';
import errorMessage from '../../helpers/errorMessage';

function* getTranslationFileFromSaga(action: GetFileTranslationsAction): Generator<any, void, any> {
  try {
    const info = yield getFileTranslation(action.source);
    yield put(getFileTranslationsOk(action.source, info));
  } catch (e) {
    yield put(getFileTranslationsKo(errorMessage(e)));
  }
}

function* sendTranslationImportFromSaga(action: SendImportTranslationsAction): Generator<any, void, any> {
  try {
    const { source, translationObj } = action;
    const info = yield sendTranslationImport(source, translationObj);
    yield put(sendImportTranslationsOk(info));
    let redirect = '/configuracion/traducciones/custom-fields';
    if (source === 'keys') {
      const state: AppState = yield select();
      const langUser = selectUserLang(state);
      const tKeysData = (yield getTranslationKeys(langUser)) as Await<ReturnType<typeof getTranslationKeys>>;
      redirect = '/configuracion/traducciones/aplicativo';
      yield put(getCategoriesTkeysOk(tKeysData));
    }
    yield put(showSnackbar('Archivo importado correctamente', 'success', redirect, 1500));
  } catch (e) {
    yield put(sendImportTranslationsKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error, no se ha podido subir el archivo', 'error', undefined, 1500));
  }
}

function* getMailsList(): Generator<any, void, any> {
  try {
    const info = yield getMailsListApi();
    yield put(getListMailsOk(info));
  } catch (e) {
    yield put(getListMailsKo(errorMessage(e)));
  }
}

function* getMailDetails(action: GetMailDetailsAction): Generator<any, void, any> {
  try {
    const info = yield getMailDetailsApi(action.mailCode);
    yield put(getMailDetailsOk(info));
  } catch (e) {
    yield put(getMailDetailsKo(errorMessage(e)));
  }
}

function* updateMailDetails(): Generator<any, void, any> {
  try {
    const state: AppState = yield select();
    const mailData = selectDetailsMail(state);
    yield updateMailDetailsApi(mailData);
    yield put(updateMailDetailsOk());
    yield put(showSnackbar('Información guardada correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(updateMailDetailsKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error, no se ha podido guardar', 'error', undefined, 1500));
  }
}

function* updateTranslatedDataFromSaga(action: UpdateTranslatedDataAction): Generator<any, void, any> {
  try {
    const { source, data, opt } = action;

    yield updateTranslationData(source, data);
    if (source === 'keys') {
      yield put(updateTranslatedDataOk(data, opt));
    }
  } catch (e) {
    yield put(updateTranslatedDataKo(errorMessage(e)));
  }
}
function* getMetadataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getTranslationSelectData();
    yield put(getMetadataTranslationOk(data));
  } catch (e) {
    yield put(getMetadataTranslationKo(errorMessage(e)));
  }
}

function* testMailFromSaga(action: TestTranslationMailAction): Generator<any, void, any> {
  try {
    const { data } = yield testMailTranslation(action.mail_code, action.language);
    if (data === 'OK') {
      yield put(showSnackbar(tKey('Correo enviado'), 'success', undefined, 1500));
    } else {
      yield put(showSnackbar(tKey('No se ha podido enviar el correo'), 'error', undefined, 1500));
    }
  } catch (e) {
    yield put(showSnackbar(tErrorKey('Error al enviar el correo'), 'error', undefined, 1500));
  }
}

function* getLopdTranslationFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getLopdMailTranslation();
    yield put(getLopdTranslationOk(data));
  } catch (e) {
    yield put(showSnackbar(tErrorKey('No se han podido encontrar correos'), 'error', undefined, 1500));
  }
}

function* updateLopdTranslationFromSaga(action: UpdateLopdTranslationAction): Generator<any, void, any> {
  try {
    const { data: mails } = action;
    yield updateLopdMailTranslation(mails);
    yield put(updateLopdTranslationOk(mails));
    yield put(showSnackbar(tErrorKey('Correos guardados'), 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(tErrorKey('No se han podido guardar los correos'), 'error', undefined, 1500));
  }
}

export default [
  takeLatest<GetFileTranslationsAction>(TranslationConst.GET_FILE_TRANSLATIONS, getTranslationFileFromSaga),
  takeLatest<SendImportTranslationsAction>(TranslationConst.SEND_IMPORT_TRANSLATIONS, sendTranslationImportFromSaga),
  takeLatest<GetListMailsAction>(TranslationConst.GET_LIST_MAILS, getMailsList),
  takeLatest<GetMailDetailsAction>(TranslationConst.GET_MAIL_DETAILS, getMailDetails),
  takeLatest<UpdateMailDetailsAction>(TranslationConst.UPDATE_MAIL_DETAILS, updateMailDetails),
  takeLatest<UpdateTranslatedDataAction>(TranslationConst.UPDATE_TRANSLATE_DATA, updateTranslatedDataFromSaga),
  takeLatest<GetMetadataTranslationAction>(TranslationConst.GET_METADATA_TRANSLATIONS, getMetadataFromSaga),
  takeLatest<TestTranslationMailAction>(TranslationConst.TEST_TRANSLATION_MAIL, testMailFromSaga),
  takeLatest<GetLopdTranslationAction>(TranslationConst.GET_LOPD_TRANSLATION, getLopdTranslationFromSaga),
  takeLatest<UpdateLopdTranslationAction>(TranslationConst.UPDATE_LOPD_TRANSLATION, updateLopdTranslationFromSaga),
];
