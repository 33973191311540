import {
  getEventRegistrations,
  setEventRegistrationData,
  setEventAssistance,
  createEventRegistration,
  updateInscriptionData,
  removeRegistration,
  getEventData,
} from '../../../redux/event/actions';
import { AppState } from '../../../redux/root-reducer';
import { connect } from 'react-redux';

import { selectUserCan } from '../../../redux/permissions/selectors';
import { selectEventData, selectEventRegistrationList } from '../../../redux/event/selectors';
import { EventDataDispatch, EventRegistrationData, EventSubscriptionData } from '../../../redux/event/definitions';
import { EventContacts } from './EventContacts';

const mapStateToProps = (state: AppState) => ({
  event: selectEventData(state),
  loading: state.eventReducer.loading,
  eventContactsList: selectEventRegistrationList(state),
  registration: true,
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: EventDataDispatch) => ({
  getEvent: (id: string) => dispatch(getEventData(id)),
  getEventContacts: (eventId: number): void => dispatch(getEventRegistrations(eventId)),
  setRegistrationData: (key: string, value: string, id: number): void =>
    dispatch(setEventRegistrationData(key, value, id)),
  setAssistance: (attended: string, code: string): void => dispatch(setEventAssistance(attended, code)),
  createRegistration: (data: EventRegistrationData) => dispatch(createEventRegistration(data)),
  updateInscriptionData: (eventId: number, eventRegistrationsList: EventSubscriptionData[]): void =>
    dispatch(updateInscriptionData(eventId, eventRegistrationsList)),
  removeRegister: (eventId: number, registrationId: number): void =>
    dispatch(removeRegistration(eventId, registrationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventContacts);
