export enum ScholarshipTransitionsConst {
  SCHOLARSHIP_STATE_TRANSITION = 'SCHOLARSHIP:TRANSITION:TO:STATE',
  SCHOLARSHIP_STATE_TRANSITION_OK = 'SCHOLARSHIP:TRANSITION:TO:STATE:OK',

  SCHOLARSHIP_LEAVE = 'SCHOLARSHIP:LEAVE',
  SCHOLARSHIP_LEAVE_OK = 'SCHOLARSHIP:LEAVE:OK',

  SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION = 'SCHOLARSHIP:PENDING:JUSTIFICATION:TRANSITION',
  SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION_OK = 'SCHOLARSHIP:PENDING:JUSTIFICATION:TRANSITION:OK',

  SCHOLARSHIP_TRANSITION_KO = 'SCHOLARSHIP:TRANSITION:KO',
}
