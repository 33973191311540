/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import ComboSearch from '../../../../components/ComboSearch/ComboSearch';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { CodeNamePair, University } from '../../../../redux/common/definitions';
import { Academy } from '../../../../redux/metadata/definitions';
import { selectMetadataScholarshipProvinces } from '../../../../redux/metadata/selectors';
import { AppState } from '../../../../redux/root-reducer';
import {
  selectAcademyInstitute,
  selectProvinceInstituteCode,
  selectUniversity,
} from '../../../../redux/scholarship/selectors';
import { studyCenterEducationalValoration } from '../../../../validations/scholarshipSchema';
import { ScholarshipType } from '../../../../redux/scholarship/definitions';

interface StudyCenterSelectorProps {
  province_academy: string | null;
  scholarship_academy: string | null;
  educational_type_code: string | null;
  readOnly: boolean;
  provinces: CodeNamePair[];
  provinceInstitute?: string;
  academies: Academy[];
  universities: University[];
  scholarshipType: ScholarshipType;
  setScholarshipAcademy: (value: string) => void;
  setScholarshipAcademyCode: (value: string) => void;
}

const StudyCenterSelector: React.FC<StudyCenterSelectorProps> = ({
  province_academy,
  scholarship_academy,
  educational_type_code,
  readOnly,
  provinces,
  provinceInstitute,
  academies,
  universities,
  scholarshipType,
  setScholarshipAcademy,
  setScholarshipAcademyCode,
}) => {
  const isUniversity = educational_type_code === 'UNI';
  const isEntity = scholarshipType === 'entity';
  const [provinceStudyCenter, setProvinceStudyCenter] = useState(provinceInstitute);
  const codeProvincesCat = ['08', '17', '25', '43'];
  const [inputText, setInputText] = useState<boolean>();

  const { control, errors, setValue } = useForm<{
    province_academy: string | null;
    scholarship_academy: string | null;
    scholarship_academy_code: string | null;
  }>({
    submitFocusError: true,
    validationSchema: studyCenterEducationalValoration,
  });

  const universityProvinceCodes = universities.reduce((acc: string[], uni: University) => {
    if (!acc.includes(uni.province_code)) {
      acc.push(uni.province_code);
    }
    return acc;
  }, []);

  const universityList = universities.filter(({ province_id }) => province_id === Number(provinceStudyCenter));

  const renderProvinceAcademy = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        errors={errors}
        schema={studyCenterEducationalValoration}
        size="50"
        name="province_academy"
        defaultValue={province_academy}
        label={tScholarshipKey(isUniversity ? 'university_province' : 'institute_province')}
        disabled={readOnly}
        onClick={(e: any) => {
          setProvinceStudyCenter(e.target.value);
          if (isUniversity) {
            universityProvinceCodes.some(arrVal => Number(arrVal) === Number(e.target.value))
              ? setInputText(true)
              : setInputText(false);
          } else {
            codeProvincesCat.some(arrVal => arrVal === e.target.value) ? setInputText(true) : setInputText(false);
          }
        }}
      >
        {isEntity
          ? provinces.map((element: CodeNamePair) => (
              <MenuItem key={element.code} value={element.code}>
                {element.name}
              </MenuItem>
            ))
          : provinces
              .filter(({ code }) => universityProvinceCodes.includes(code as string))
              .map((element: CodeNamePair) => (
                <MenuItem key={element.code} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
      </SelectController>
    );
  };

  const renderComboSearch = (): JSX.Element => {
    if (isUniversity) {
      return (
        <ComboSearch
          options={universityList}
          control={control}
          label={tScholarshipKey(`${scholarshipType}.scholarship_academy`)}
          optionLabel="name"
          fieldToSend="code"
          setValue={setValue}
          defaultValue={scholarship_academy as string}
          onChange={data => {
            if (data != null && data.name) {
              setScholarshipAcademy(data.name);
              setScholarshipAcademyCode('');
            }
          }}
          disabled={!provinceStudyCenter}
          error={errors.scholarship_academy}
          name="scholarship_academy"
          clipBoard
        />
      );
    }
    return (
      <ComboSearch
        options={academies.filter(({ code }) => code.slice(0, 2) === provinceStudyCenter)}
        control={control}
        label={tScholarshipKey(`${scholarshipType}.scholarship_academy`)}
        optionLabel="name"
        fieldToSend="code"
        setValue={setValue}
        defaultValue={scholarship_academy as string}
        onChange={data => {
          if (data != null && data.name && data.code) {
            setScholarshipAcademy(data.name);
            setScholarshipAcademyCode(data.code);
          }
        }}
        disabled={readOnly || !provinceStudyCenter}
        error={errors.scholarship_academy}
        name="scholarship_academy"
        clipBoard
      />
    );
  };

  const renderInputText = (): JSX.Element => {
    return (
      <TextInputController
        control={control}
        errors={errors}
        schema={studyCenterEducationalValoration}
        size="50"
        name="scholarship_academy"
        disabled={readOnly || !provinceStudyCenter}
        defaultValue={scholarship_academy}
        label={tScholarshipKey(`${scholarshipType}.scholarship_academy`)}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setScholarshipAcademy(e.target.value);
          setScholarshipAcademyCode('');
        }}
        clipBoard
      />
    );
  };

  const renderUniversity = (): JSX.Element => {
    if ((inputText || !isEntity) && universityList.length > 0 && !readOnly) {
      return renderComboSearch();
    }
    return renderInputText();
  };

  const renderAcademy = (): JSX.Element => {
    if ((inputText || !isEntity) && !readOnly) {
      return renderComboSearch();
    }
    return renderInputText();
  };

  const renderStudyCenter = (): JSX.Element => {
    return isUniversity ? renderUniversity() : renderAcademy();
  };

  return (
    <>
      {renderProvinceAcademy()}
      {renderStudyCenter()}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  academies: selectAcademyInstitute(state),
  universities: selectUniversity(state),
  provinceInstitute: selectProvinceInstituteCode(state),
  provinces: selectMetadataScholarshipProvinces(state),
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudyCenterSelector);
