export enum UserDataConsts {
  USER_PROFILE = 'USER:PROFILE',
  USER_PROFILE_OK = 'USER:PROFILE:OK',

  USER_VALIDATION_ERRORS = 'USER:VALIDATION:ERRORS',

  GET_USER_DATA = 'GET:USER:DATA',
  GET_USER_DATA_OK = 'GET:USER:DATA:OK',

  GET_USERS = 'GET:USERS',
  GET_USERS_OK = 'GET:USERS:OK',
  GET_USERS_KO = 'GET:USERS:KO',

  SIGN_LOPD_ACTION = 'SIGN:LOPD:ACTION',
  SIGN_LOPD_ACTION_OK = 'SIGN:LOPD:ACTION:OK',

  UPDATE_USER_PROFILE = 'UPDATE:USER:PROFILE',
  UPDATE_USER_PROFILE_OK = 'UPDATE:USER:PROFILE:OK',

  UPLOAD_USER_DOCUMENT = 'UPLOAD:USER:DOCUMENT',
  UPLOAD_USER_DOCUMENT_OK = 'UPLOAD:USER:DOCUMENT:OK',

  UPLOAD_EDUCATOR_DOCUMENT = 'UPLOAD:EDUCATOR:DOCUMENT',
  UPLOAD_EDUCATOR_DOCUMENT_OK = 'UPLOAD:EDUCATOR:DOCUMENT:OK',

  REMOVE_EDUCATOR_DOCUMENT = 'REMOVE:EDUCATOR:DOCUMENT',
  REMOVE_EDUCATOR_DOCUMENT_OK = 'REMOVE:EDUCATOR:DOCUMENT:OK',

  DOWNLOAD_USER_DOCUMENT = 'DOWNLOAD:USER:DOCUMENT',
  DOWNLOAD_USER_DOCUMENT_OK = 'DOWNLOAD:USER:DOCUMENT:OK',

  GET_USER_DOCUMENT = 'GET:USER:DOCUMENT',
  GET_USER_DOCUMENT_OK = 'GET:USER:DOCUMENT:OK',

  REMOVE_USER_DOCUMENT = 'REMOVE:USER:DOCUMENT',
  REMOVE_USER_DOCUMENT_OK = 'REMOVE:USER:DOCUMENT:OK',

  USER_SET_PAGESIZE_TABLE_OPTIONS = 'USER:SET:PAGESIZE:TABLE:OPTIONS',
  USER_SET_SEARCHTEXT_TABLE_OPTIONS = 'USER:SET:SEARCHTEXT:TABLE:OPIONS',
  USER_SET_COLUMNORDER_TABLE_OPTIONS = 'USER:SET:COLUMNORDER:TABLE:OPTIONS',

  UPDATE_PROFILE_USER_TABLE = 'UPDATE:PROFILE:USER:TABLE',
  UPDATE_PROFILE_USER_TABLE_OK = 'UPDATE:PROFILE:USER:TABLE:OK',

  CREATE_USER = 'CREATE:USER',
  CREATE_USER_OK = 'CREATE:USER:OK',

  CREATE_USER_EDUCATOR = 'CREATE:USER:EDUCATOR',
  CREATE_USER_EDUCATOR_OK = 'CREATE:USER:EDUCATOR:OK',

  GET_USER_DOCUMENTATION = 'GET:USER:DOCUMENTATION',
  GET_USER_DOCUMENTATION_OK = 'GET:USER:DOCUMENTATION:OK',
  GET_USER_DOCUMENTATION_KO = 'GET:USER:DOCUMENTATION:KO',

  INIT_NEW_USER = 'INIT:NEW:USER',
  INIT_NEW_USER_OK = 'INIT:NEW:USER_OK',

  CHANGE_PASSWORD = 'USER:CHANGE:PASSWORD',

  ACCEPT_USER_EDUCATOR = 'ACCEPT:USER:EDUCATOR',
  ACCEPT_USER_EDUCATOR_OK = 'ACCEPT:USER:EDUCATOR:OK',
  ACCEPT_USER_EDUCATOR_KO = 'ACCEPT:USER:EDUCATOR:KO',

  REJECT_USER_EDUCATOR = 'REJECT:USER:EDUCATOR',
  REJECT_USER_EDUCATOR_OK = 'REJECT:USER:EDUCATOR:OK',
  REJECT_USER_EDUCATOR_KO = 'REJECT:USER:EDUCATOR:KO',

  USER_ENTITIES_METADATA = 'USER:ENTITIES:METADATA',
  USER_ENTITIES_METADATA_OK = 'USER:ENTITIES:METADATA:OK',
  USER_ENTITIES_METADATA_KO = 'USER:ENTITIES:METADATA:KO',

  UPDATE_PROFILE_EDUCATOR = 'UPDATE:PROFILE:EDUCATOR',
  UPDATE_PROFILE_EDUCATOR_OK = 'UPDATE:PROFILE:EDUCATOR:OK',

  GET_FOREING_AVATAR = 'GET:FOREING:AVATAR',
  GET_FOREING_AVATAR_OK = 'GET:FOREING:AVATAR:OK',
  GET_FOREING_AVATAR_KO = 'GET:FOREING:AVATAR:KO',

  SET_FOREING_AVATAR = 'SET:FOREING:AVATAR',
  SET_FOREING_AVATAR_OK = 'SET:FOREING:AVATAR:OK',
  SET_FOREING_AVATAR_KO = 'SET:FOREING:AVATAR:KO',

  DISABLE_USER = 'DISABLE:USER',
  DISABLE_USER_OK = 'DISABLE:USER:OK',

  USER_SET_DATA = 'USER:SET:DATA',
  USER_SET_USER_CREATED = 'USER:SET:USERCREATED',

  USER_LINK_ACADEMY = 'USER:LINK:ACADEMY',
  USER_LINK_ACADEMY_OK = 'USER:LINK:ACADEMY:OK',
  USER_LINK_ACADEMY_KO = 'USER:LINK:ACADEMY:KO',

  GET_USER_ROLES_CONFIG = 'GET:USER:ROLES:CONFIG',
  GET_USER_ROLES_CONFIG_OK = 'GET:USER:ROLES:CONFIG:OK',

  CONFIRM_USER_ACTIVE_ROLE = 'CONFIRM:USER:ACTIVE:ROLE',
  CONFIRM_USER_ACTIVE_ROLE_OK = 'CONFIRM:USER:ACTIVE:ROLE:OK',

  USER_ADD_ROLE = 'USER:ADD:ROLE',
  USER_ADD_ROLE_OK = 'USER:ADD:ROLE:OK',

  USER_REMOVE_ROLE = 'USER:REMOVE:ROLE',
  USER_REMOVE_ROLE_OK = 'USER:REMOVE:ROLE_OK',

  SET_USER_PERMISSION = 'SET:USER:PERMISSION',
  SET_USER_PERMISSION_OK = 'SET:USER:PERMISSION:OK',

  USER_ROLE_ACTIVE = 'USER:ROLE:ACTIVE',
  USER_ROLE_ACTIVE_OK = 'USER:ROLE:ACTIVE:OK',

  USER_GENERIC_KO = 'USER:GENERIC:KO',

  USER_CHANGE_LANGUAGE = 'USER:CHANGE:LANGUAGE',
  USER_CHANGE_LANGUAGE_OK = 'USER:CHANGE:LANGUAGE:OK',
}
