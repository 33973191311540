import { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Search } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import React, { useState } from 'react';
import { tKey } from '../../../helpers/translate';
import styles from './customTable.module.scss';
import { exportToExcel } from './logic/exportToXslsx';
import { ColumnTableProps } from './TableEditableRow';

interface InputSearchProps {
  onSearch: (value: string) => void;
}

const InputSearch: React.FC<InputSearchProps> = ({ onSearch }) => {
  const [input, setInput] = useState('');
  const filterRows = (event: any) => {
    const value = event.target.value;
    setInput(value);
    onSearch(value);
  };

  return (
    <div className={styles.textfield_search}>
      <TextField
        onChange={filterRows}
        type="search"
        value={input}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

interface ToolbarProps {
  title: string;
  disabled?: boolean;
  buttons?: ButtonProps[];
  rows: any[];
  columns: ColumnTableProps[];
  exportExcel?: boolean;
  onSearch: (value: string) => void;
  onEditBulk?: boolean;
  setRows?: (data: any) => void;
}

const ToolBar: React.FC<ToolbarProps> = ({
  title,
  disabled,
  buttons,
  rows,
  columns,
  exportExcel,
  onSearch,
  onEditBulk,
  setRows,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const onEditBulkMap = (rows: any) => {
    if (setRows) {
      setRows(() => {
        return rows.map((row: any) => {
          return { ...row, isEditMode: !isEditMode };
        });
      });
      setIsEditMode(!isEditMode);
    }
  };

  return (
    <Toolbar className={styles.toolbar}>
      <Typography variant="h6" id="tableTitle" component="div">
        {title}
      </Typography>
      <div style={{ flex: '1 1 10%' }} />
      <InputSearch onSearch={onSearch} />

      {exportExcel && (
        <IconButton disabled={disabled} aria-label="filter list" onClick={() => exportToExcel(columns, rows, title)}>
          <SaveAltIcon />
        </IconButton>
      )}
      <>
        {buttons?.map(({ children, onClick, ...props }, index) => (
          // <Tooltip key={index} title={button.title}>
          // {button.label ? (
          <Button
            key={index}
            disabled={disabled}
            // variant="contained"
            // color="secondary"
            // aria-label="filter list"
            onClick={onClick}
            // className={styles.button}
            {...props}
          >
            {children}
          </Button>
          //   ) : (
          //     <IconButton
          //       disabled={disabled}
          //       className={classes.button}
          //       aria-label="filter list"
          //       onClick={button.onClick}
          //     >
          //       {button.icon}
          //     </IconButton>
          //   )}
          // </Tooltip>
        ))}

        {/* {onEditBulk && (
          <IconButton onClick={() => onEditBulkMap(rows)}>
            <EditIcon style={{ color: '#fe5d52', paddingLeft: '5px', width: '30px', height: '30px' }} />
          </IconButton>
        )}
        {onEditBulk && <IconButton onClick={() => onEditBulkMap(rows)}>EDITAR TABLA</IconButton>} */}
        {onEditBulk && (
          <Button
            style={{ marginLeft: '5px' }}
            disabled={disabled}
            variant="contained"
            color="secondary"
            className={styles.button}
            onClick={() => onEditBulkMap(rows)}
          >
            <EditIcon style={{ marginRight: '5px' }} />
            {tKey('Editar')}
          </Button>
        )}
      </>
    </Toolbar>
  );
};

export default ToolBar;
