import { TrackingConsts } from './action_types';
import {
  ResourceTracking,
  ResourceTrackingsListAction,
  ResourceTrackingsListKoAction,
  ResourceTrackingsListOkAction,
  ResourceTrackingTypes,
} from './definitions';

export function getResourceTrackingList(id: number, resourceType: ResourceTrackingTypes): ResourceTrackingsListAction {
  return {
    type: TrackingConsts.RESOURCE_TRACKING_LIST,
    id,
    resourceType,
  };
}

export function getResourceTrackingListOk(resourceTracking: ResourceTracking): ResourceTrackingsListOkAction {
  return {
    type: TrackingConsts.RESOURCE_TRACKING_LIST_OK,
    resourceTracking,
  };
}

export function getResourceTrackingListKo(error: string): ResourceTrackingsListKoAction {
  return {
    type: TrackingConsts.RESOURCE_TRACKING_LIST_KO,
    error,
  };
}
