import { APIResponse, ZipCodeResponse } from '../../redux/common/definitions';
import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

export const findZipCode = async (code: string): Promise<ZipCodeResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/zip_code/find/${code}`, init);
  return data;
};
