import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTableProps } from 'material-table';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { tKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';
import { getResourceTrackingList } from '../../../redux/tracking/actions';
import {
  ResourceTracking,
  ResourceTrackingTypes,
  TrackingDispatch,
  TrackingItem,
} from '../../../redux/tracking/definitions';
import { selectResourceTracking, selectTrackingsLoading } from '../../../redux/tracking/selectors';
import Loading from '../../Loading/Loading';
import TableWithEdit from '../../Tables/TableWithEdit';
import styles from './trackingModal.module.scss';

interface TrackingModalProps {
  title: string;
  view: boolean;
  resource_id: number;
  resource_type: ResourceTrackingTypes;
  loading: boolean;
  resourceTracking: ResourceTracking;
  handleClose: () => void;
  getTracking: (resource_id: number, resource_type: ResourceTrackingTypes) => void;
}

const TransitionsModal: React.FC<TrackingModalProps> = ({
  title,
  view,
  resource_id,
  resource_type,
  loading,
  resourceTracking,
  handleClose,
  getTracking,
}) => {
  const { trackingList } = resourceTracking;

  useEffect(() => {
    if (resource_id && view) {
      getTracking(resource_id, resource_type);
    }
  }, [getTracking, resource_id, resource_type, view]);

  const tableData: MaterialTableProps<TrackingItem> = {
    title: '',
    columns: [
      {
        field: 'tracking_date',
        title: tKey('Fecha'),
      },
      {
        field: 'state_from',
        title: tKey('Estado previo'),
      },
      {
        field: 'state_to',
        title: tKey('Nuevo estado'),
      },
      {
        field: 'user',
        title: tKey('Usuario'),
      },
    ],
    data: trackingList,
    isLoading: loading,
    options: {
      tableLayout: 'auto',
      paging: false,
    },
  };

  return (
    <div>
      <Dialog
        open={view}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        closeAfterTransition
        className={styles.dialogForm}
      >
        <div className={styles.title}>
          <DialogTitle classes={{ root: styles.titleRed }} id="alert-dialog-title">
            {title}
          </DialogTitle>
          <IconButton onClick={handleClose} aria-label="delete" className={styles.buttonClose} size="small">
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        {loading ? (
          <Loading medium />
        ) : (
          <>
            <DialogContent className={styles.dialogInfo}>
              <Typography>
                <b>{`${tKey('Estado actual')}: `}</b>
                {resourceTracking.currentState}
              </Typography>
              <Typography>
                <b>{`${tKey('Propietario')}: `}</b>
                {resourceTracking.taskOwner}
              </Typography>
              <Typography>
                <b>{`${tKey('Siguiente estado')}: `}</b>
                {resourceTracking.nextState}
              </Typography>
            </DialogContent>
            <DialogContent>
              <TableWithEdit
                tableData={{ ...tableData }}
                noSearch
                key={JSON.stringify(tableData.data)}
                permission={true}
              />
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus variant="contained" disableElevation>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  resourceTracking: selectResourceTracking(state),
  loading: selectTrackingsLoading(state),
});

const mapDispatchToProps = (dispatch: TrackingDispatch) => ({
  getTracking: (resource_id: number, resource_type: ResourceTrackingTypes) =>
    dispatch(getResourceTrackingList(resource_id, resource_type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransitionsModal);
