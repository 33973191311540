import assertUnreachable from '../../helpers/assertUnreachable';
import { getDocs } from '../../helpers/getDocs';
import { AboutUsConsts } from './action_types';
import { AboutUsAction, AboutUsResource, AboutUsState, initialState } from './definitions';

const AboutUsReducer = (state = initialState, action: AboutUsAction): AboutUsState => {
  switch (action.type) {
    case AboutUsConsts.ABOUTUS_INITIAL_RESOURCE:
    case AboutUsConsts.ABOUTUS_METADATA:
    case AboutUsConsts.ABOUTUS_ONE_RESOURCES:
    case AboutUsConsts.ABOUTUS_SAVE_RESOURCE:
    case AboutUsConsts.ABOUTUS_NEW_RESOURCE:
      return {
        ...state,
        loading: true,
      };
    case AboutUsConsts.ABOUTUS_METADATA_OK:
      return {
        ...state,
        loading: false,
        metadata: action.metadata,
      };
    case AboutUsConsts.ABOUTUS_INITIAL_RESOURCE_KO:
    case AboutUsConsts.ABOUTUS_METADATA_KO:
      return {
        ...state,
        loading: false,
      };
    case AboutUsConsts.ABOUTUS_RESOURCES:
      return {
        ...state,
        loading: true,
        oneResource: {} as AboutUsResource,
      };
    case AboutUsConsts.ABOUTUS_NEW_DOCUMENT:
    case AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT:
    case AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE:
    case AboutUsConsts.ABOUTUS_DELETE_DOCUMENT:
    case AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID:
    case AboutUsConsts.ABOUTUS_OPEN_DOCUMENT:
    case AboutUsConsts.ABOUTUS_SAVE_RESOURCE_OK:
      return {
        ...state,
      };
    case AboutUsConsts.ABOUTUS_RESOURCES_OK:
      return {
        ...state,
        loading: false,
        resources: action.resources,
      };
    case AboutUsConsts.ABOUTUS_RESOURCES_KO:
    case AboutUsConsts.ABOUTUS_ONE_RESOURCES_KO:
    case AboutUsConsts.ABOUTUS_NEW_DOCUMENT_KO:
    case AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_KO:
    case AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_KO:
    case AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_KO:
    case AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID_KO:
    case AboutUsConsts.ABOUTUS_OPEN_DOCUMENT_KO:
    case AboutUsConsts.ABOUTUS_SAVE_RESOURCE_KO:
    case AboutUsConsts.ABOUTUS_NEW_RESOURCE_KO:
    case AboutUsConsts.ABOUTUS_NEW_RESOURCE_OK:
      return {
        ...state,
        loading: false,
      };
    case AboutUsConsts.ABOUTUS_ONE_RESOURCES_OK:
      return {
        ...state,
        loading: false,
        oneResource: action.resource,
        documents: action.resource.files,
      };
    case AboutUsConsts.ABOUTUS_NEW_DOCUMENT_OK:
      const {
        oneResource: { files },
      } = state;

      files.push({
        code: action.data.id.toString(),
        name: action.name,
        documentation_id: 0,
        file_id: action.data.id,
        filename: action.data.filename,
        update: action.data.updated_at,
        original_name: action.data.original_name,
        upload: '',
        id: action.data.id,
      });
      return {
        ...state,
        loading: false,
        documents: files,
        oneResource: {
          ...state.oneResource,
          files,
        },
      };
    case AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_OK:
    case AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_OK:
      const { data, name } = action;
      getDocs(data, true, name);
      return {
        ...state,
        loading: false,
      };
    case AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_OK:
    case AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID_OK:
      const { documents } = action;
      return {
        ...state,
        oneResource: {
          ...state.oneResource,
          files: documents,
        },
        documents,
      };
    case AboutUsConsts.ABOUTUS_OPEN_DOCUMENT_OK:
      getDocs(action.data, false);
      return {
        ...state,
      };
    case AboutUsConsts.ABOUTUS_INITIAL_RESOURCE_OK:
      const { metadata } = action;
      const about_us_type_id = metadata.aboutUsTypes[0].id;
      return {
        ...state,
        metadata,
        loading: false,
        oneResource: {
          id: 0,
          description: '',
          code: '',
          about_us_type_id,
          files: [],
        },
        documents: [],
      };
  }

  assertUnreachable(action);
  return state;
};

export default AboutUsReducer;
