import { Button, ButtonProps, InputLabel } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import CompoundComponent from '../../../components/CompoundComponent/CompoundComponent';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import Loading from '../../../components/Loading/Loading';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import { tFormKey, tKey } from '../../../helpers/translate';
import { EntityInfo } from '../../../redux/entity/definitions';
import { JointEntitySchema } from '../../../validations/formSchema';
import styles from '../entityData.module.scss';
import projectStyles from './projectForm.module.scss';

interface JointProps {
  leftButtons?: boolean;
  buttons?: boolean;
  entity: EntityInfo;
  buttonActionSave: ButtonProps;
  disabled?: boolean;
  loading?: boolean;
  goBack?: () => void;
  onSave: (data: EntityInfo) => void;
  addDoc?: (document: File, description: string, genericId?: any, documentationId?: number) => void;
  setData: (key: string, value: string | number) => void;
}

const Joint: React.FC<JointProps> = ({
  leftButtons,
  entity,
  buttons,
  disabled,
  loading,
  buttonActionSave,
  onSave,
  goBack,
  addDoc,
  setData,
}) => {
  const { register, handleSubmit, errors, control } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: JointEntitySchema,
  });

  useButtonAction(buttonActionSave, handleSubmit(onSave));

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <form className={styles.form}>
          <FormContainer space title={tKey('Articulación')}>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={JointEntitySchema}
                label={tFormKey('organizacion de rango superior')}
                register={register}
                errors={errors}
                radioButtonsName="joint_belongs_other_organization"
                radioButtonsValue={entity.joint_belongs_other_organization}
                textAreaValue={entity.joint_belongs_other_organization_text}
                disabled={disabled}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="joint_belongs_other_organization_text"
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={JointEntitySchema}
                label={tFormKey('redes coordinadoras o federaciones geográficas')}
                errors={errors}
                register={register}
                radioButtonsName="joint_belongs_other_next"
                radioButtonsValue={entity.joint_belongs_other_next}
                textAreaValue={entity.joint_belongs_other_next_text}
                textAreaLabel={tFormKey('Descripción')}
                disabled={disabled}
                textAreaName="joint_belongs_other_next_text"
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('relación con la administración')}</InputLabel>
              <TextInput
                errors={errors.joint_relation_other_organization}
                label=""
                register={register}
                name="joint_relation_other_organization"
                defaultValue={entity.joint_relation_other_organization}
                size="100"
                disabled={disabled}
                rows="5"
                rowsMax="5"
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('organizaciones internacionales')}</InputLabel>
              <TextInput
                label=""
                errors={errors.joint_relation_internatinal_institutions}
                register={register}
                name="joint_relation_internatinal_institutions"
                defaultValue={entity.joint_relation_internatinal_institutions}
                size="100"
                rows="5"
                disabled={disabled}
                rowsMax="5"
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('Partidos políticos, sindicatos o iglesias')}</InputLabel>
              <TextInput
                label=""
                register={register}
                name="joint_relation_other_institutions"
                defaultValue={entity.joint_relation_other_institutions}
                size="100"
                errors={errors.joint_relation_other_institutions}
                rows="5"
                rowsMax="5"
                disabled={disabled}
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('Colaboracion centros educativos')}</InputLabel>
              <TextInput
                label=""
                register={register}
                defaultValue={entity.joint_educational_collaboration}
                name="joint_educational_collaboration"
                errors={errors.joint_educational_collaboration}
                size="100"
                rows="5"
                rowsMax="5"
                disabled={disabled}
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
          </FormContainer>
          {!leftButtons && (
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" startIcon={<ArrowBack />} color="primary" fullWidth onClick={goBack}>
                  {tKey('VOLVER')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  color="primary"
                  fullWidth
                  onClick={handleSubmit(onSave)}
                >
                  {tKey('Guardar')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default Joint;
