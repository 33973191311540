import { Tooltip } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { EntityListItem, EntityTypes } from '../../../redux/entity/definitions';
import { AppState } from '../../../redux/root-reducer';

interface WhatTypeProps {
  entityTypes: EntityTypes[];
  rowData: EntityListItem;
}

const WhatType: React.FC<WhatTypeProps> = ({ rowData, entityTypes }) => {
  return (
    <Fragment>
      {entityTypes?.map(
        (types, index) =>
          rowData.types_entity.includes(types.code as any) && (
            <Tooltip key={index} title={types.name}>
              <Brightness1Icon style={{ color: types.color, height: '15px', verticalAlign: 'text-bottom' }} />
            </Tooltip>
          ),
      )}
    </Fragment>
  );
};

export default connect((state: AppState) => ({
  entityTypes: state.entityReducer.entityTypes,
}))(WhatType);
