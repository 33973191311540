import { RejectScholarshipData, ScholarshipData, ScholarshipId, ScholarshipStateChange } from '../definitions';
import { ScholarshipTransitionsConst } from './action_types';
import {
  LeaveScholarshipAction,
  LeaveScholarshipOkAction,
  PendingJustificationTransitionAction,
  PendingJustificationTransitionOkAction,
  RemainderAmount,
  ScholarshipStateTransitionAction,
  ScholarshipStateTransitionOkAction,
  ScholarshipLeaveModes,
  ScholarshipTransitionKoAction,
  ScholarshipTransitionState,
} from './definitions';

export function scholarshipStateTransitionAction(
  id: number,
  states: ScholarshipStateChange,
  redirect?: string,
  data?: ScholarshipData | RejectScholarshipData,
): ScholarshipStateTransitionAction {
  return {
    type: ScholarshipTransitionsConst.SCHOLARSHIP_STATE_TRANSITION,
    states,
    id,
    redirect,
    data,
  };
}

export function scholarshipStateTransitionOkAction(states: ScholarshipStateChange): ScholarshipStateTransitionOkAction {
  return {
    type: ScholarshipTransitionsConst.SCHOLARSHIP_STATE_TRANSITION_OK,
    states,
  };
}

export function leaveScholarship(
  id: number,
  redirect: string,
  leaveMode: ScholarshipLeaveModes,
  data: RejectScholarshipData,
): LeaveScholarshipAction {
  return {
    type: ScholarshipTransitionsConst.SCHOLARSHIP_LEAVE,
    id,
    redirect,
    leaveMode,
    data,
  };
}

export function leaveScholarshipOk(state: ScholarshipTransitionState): LeaveScholarshipOkAction {
  return {
    type: ScholarshipTransitionsConst.SCHOLARSHIP_LEAVE_OK,
    state,
  };
}

export function pendingJustificationTransitionAction(
  scholarship_id: ScholarshipId,
  remainder_amount: RemainderAmount,
  remainder_remarks: string | null,
): PendingJustificationTransitionAction {
  return {
    type: ScholarshipTransitionsConst.SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION,
    scholarship_id,
    remainder_amount,
    remainder_remarks,
  };
}

export function pendingJustificationTransitionOkAction(
  state: ScholarshipTransitionState,
): PendingJustificationTransitionOkAction {
  return {
    type: ScholarshipTransitionsConst.SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION_OK,
    state,
  };
}

export function scholarshipTransitionKoAction(error: string): ScholarshipTransitionKoAction {
  return {
    type: ScholarshipTransitionsConst.SCHOLARSHIP_TRANSITION_KO,
    error,
  };
}
