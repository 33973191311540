import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import GenericFormRefactor from '../../../../components/GenericForm/GenericFormRefactor';
import Loading from '../../../../components/Loading/Loading';
import { StudentEvaluationInfo } from '../../../../redux/internal-projects/definitions';
import { AppState } from '../../../../redux/root-reducer';

import { getStudentEvaluationInfo } from '../../../../redux/internal-projects/actions';
import styles from './StudentEvaluationModal.module.scss';

interface StudentEvaluationModalProps {
  loading: boolean;
  internal_project_id: number;
  enrollment_id: number;
  data?: { studentName: string; studentSurnames: string };
  studentEvaluationInfo: StudentEvaluationInfo;
  view: boolean;
  getStudentEvaluationEnrollment: (internal_project_id: number, enrollment_id: number) => void;
  handleClose: () => void;
}

const StudentEvaluationModal: React.FC<StudentEvaluationModalProps> = ({
  loading,
  internal_project_id,
  enrollment_id,
  view,
  data,
  studentEvaluationInfo,
  handleClose,
  getStudentEvaluationEnrollment,
}) => {
  useEffect(() => {
    getStudentEvaluationEnrollment(internal_project_id, enrollment_id);
  }, [getStudentEvaluationEnrollment, internal_project_id, enrollment_id]);

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <Dialog
      open={view}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{data?.studentName + ' ' + data?.studentSurnames}</DialogTitle>
        <IconButton aria-label="delete" size="small" onClick={() => handleClose()}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        <GenericFormRefactor
          idData={internal_project_id}
          fields={studentEvaluationInfo.fields}
          disabled={true}
          showProjectInfo={false}
          actions={[]}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectReducer.loadingAlt,
  studentEvaluationInfo: state.internalProjectReducer.studentEvaluationInfo,
});

const mapDispatchToProps = (dispatch: any) => ({
  getStudentEvaluationEnrollment: (internal_project_id: number, enrollment_id: number) =>
    dispatch(getStudentEvaluationInfo(internal_project_id, enrollment_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentEvaluationModal);
