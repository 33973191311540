import { MenuItem } from '@material-ui/core';
import React, { FocusEvent } from 'react';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import ValidatedTextInput from '../../../../components/Inputs/ValidatedTextInput/ValidatedTextInput';
import SelectController from '../../../../components/Select/SelectController';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { validateFields } from '../../../../helpers/validator';
import { docTypesArray } from '../../../../redux/common/definitions';
import { EntityInfo } from '../../../../redux/entity/definitions';
import { validateIdentificationCard } from '../../../../validations/validatorUser';

interface ResponsibleFormProps {
  useForm: any;
  entitySchema: any;
  entity: EntityInfo;
  disabled?: boolean;
  responsible?: boolean;
  responsableData?: boolean;
  setData: (key: string, value: string | number) => void;
}

const ResponsibleForm: React.FC<ResponsibleFormProps> = ({
  useForm,
  entitySchema,
  entity,
  disabled,
  responsible,
  responsableData,
  setData,
}) => {
  const {
    email,
    responsible_document_type,
    responsible_document_number,
    responsible_name,
    responsible_surname,
    responsible_surname2,
    responsible_phone,
    responsible_city,
  } = entity;
  const { register, errors, triggerValidation, control, setError, clearError } = useForm;

  return (
    <FormContainer title={tKey('Datos Responsable Entidad')}>
      <ValidatedTextInput
        size="50"
        label={tFormKey('Nombre')}
        name="responsible_name"
        register={register}
        schema={entitySchema}
        errors={errors}
        defaultValue={responsible_name}
        disabled={disabled || responsableData}
        validator={validateFields(entity, 'responsible_name')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => setData(e.target.name, e.target.value)}
      />
      <ValidatedTextInput
        size="20"
        schema={entitySchema}
        errors={errors}
        label={tFormKey('Primer Apellido')}
        name="responsible_surname"
        register={register}
        defaultValue={responsible_surname}
        disabled={disabled || responsableData}
        validator={validateFields(entity, 'responsible_surname')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => setData(e.target.name, e.target.value)}
      />
      <ValidatedTextInput
        size="20"
        schema={entitySchema}
        errors={errors}
        label={tFormKey('Segundo Apellido')}
        name="responsible_surname2"
        register={register}
        defaultValue={responsible_surname2}
        disabled={disabled || responsableData}
        validator={validateFields(entity, 'responsible_surname2')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => setData(e.target.name, e.target.value)}
      />
      <ValidatedTextInput
        schema={entitySchema}
        errors={errors}
        size="50"
        label={tFormKey('Email')}
        name="email"
        register={register}
        defaultValue={email}
        disabled={disabled || responsible || responsableData}
        validator={validateFields(entity, 'email')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => setData(e.target.name, e.target.value)}
      />
      <SelectController
        size="20"
        key="responsible_document_type"
        name="responsible_document_type"
        defaultValue={responsible_document_type}
        errors={errors}
        label={tFormKey('Tipo de Documento')}
        control={control}
        disabled={disabled}
        schema={entitySchema}
        validator={validateFields(entity, 'responsible_document_type')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          validateIdentificationCard(
            e.target.value,
            responsible_document_number || '',
            'responsible_document_number',
            setError,
            clearError,
          );
          setData(e.target.name, e.target.value);
        }}
      >
        {docTypesArray.map(docType => (
          <MenuItem key={docType} value={docType}>
            {docType}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        control={control}
        size="20"
        label={tFormKey('Nº Documento')}
        name="responsible_document_number"
        value={responsible_document_number}
        defaultValue={responsible_document_number}
        errors={errors}
        disabled={disabled}
        schema={entitySchema}
        validator={validateFields(entity, 'responsible_document_number')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          validateIdentificationCard(
            responsible_document_type || '',
            e.target.value,
            'responsible_document_number',
            setError,
            clearError,
          );
          setData(e.target.name, e.target.value);
        }}
      />
      <TextInputController
        size="50"
        label={tFormKey('Población')}
        name="responsible_city"
        control={control}
        schema={entitySchema}
        errors={errors}
        defaultValue={responsible_city}
        disabled={disabled || responsableData}
        validator={validateFields(entity, 'responsible_city')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          triggerValidation('responsible_city');
          setData(e.target.name, e.target.value);
        }}
      />

      <TextInputController
        size="50"
        label={tFormKey('Teléfono')}
        name="responsible_phone"
        control={control}
        schema={entitySchema}
        errors={errors}
        defaultValue={responsible_phone}
        disabled={disabled || responsableData}
        validator={validateFields(entity, 'responsible_phone')}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          triggerValidation('responsible_phone');
          setData(e.target.name, e.target.value);
        }}
      />
    </FormContainer>
  );
};

export default ResponsibleForm;
