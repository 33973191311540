import moment from 'moment';
import XLSX from 'xlsx';
import * as DateConstants from '../constants/date';

export const exportToExcel = (columns: any, data: any, title?: string) => {
  // determining header labels
  const columnInfo = columns.reduce(
    (acc: any, column: any) => {
      const headerLabel = column.title || column.field;
      acc.header.push(headerLabel);
      acc.map[column.field] = headerLabel;
      return acc;
    },
    { map: {}, header: [] },
  );

  const mappedData = data.map((row: any) =>
    Object.entries(row).reduce((acc: any, [key, value]) => {
      if (columnInfo.map[key]) {
        acc[columnInfo.map[key]] = Array.isArray(value) ? value.join(', ') : value;
      }
      return acc;
    }, {}),
  );

  // https://github.com/SheetJS/sheetjs/issues/817
  const ws = XLSX.utils.json_to_sheet(mappedData, {
    header: columnInfo.header, // garanties the column order same as in material-table
  });

  /* add to workbook */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* generate an XLSX file and triggers the browser to generate the download*/

  XLSX.writeFile(wb, `${title ? title : 'reporte'}_${moment().format(DateConstants.DATE_HOUR_FORMAT_FILE)}.xlsx`);
};
