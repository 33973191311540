import { Action } from 'redux';
import { APIValidationError } from '../../api/api';

import { ColumnOrderData, DocumentationItem, ResponseData } from '../common/definitions';

import { LookUpSubarea, AreaSubArea } from '../project/definitions';
import { ServiceDataConsts } from './action_types';

export interface SuccessInterface {
  success: boolean;
}

export const initialState = {
  loading: false,
  serviceCreated: false,
  servicesList: [] as ServiceData[],
  servicesUserList: [] as ServicesUser[],
  error: null as string | null,
  validationErrors: null as APIValidationError | null,
  areasSubareasList: [] as AreaSubArea[],
  pageSize: 5 as number,
  searchText: null as string | null,
  columnOrder: { orderDirection: 'asc', orderedColumnId: 1 } as ColumnOrderData,
  responseDeleteOne: null as any | null,
  responseData: null as ResponseData | null,
  isAllSuccess: false as boolean,
  responseValidation: {} as SuccessInterface,
  subareas: {} as LookUpSubarea,
  serviceData: {} as ServiceData,
  serviceRequests: [] as ServiceRequestData[],
};

export interface ServiceData {
  id: number;
  service_id: string;
  start_date: number | string;
  end_date: number | string | null;
  created_at: string;

  name: string;
  company: string;
  unidentified: number | null;

  main_contact?: string;
  main_contact_phone?: string;
  main_contact_email?: string;

  provider_contact?: string;
  provider_contact_phone?: string;
  provider_contact_email?: string;

  registration_link?: string;

  subarea_id?: number;
  subarea?: string;
  nps: number;

  cancelled: number;
  max_capacity?: string;

  start_registration_date: number | null;
  end_registration_date: number | null;

  requested_number: number | null;
  assistant_number: number | null;
  beneficiaries: number | null;

  documents: DocumentationItem[];

  desktopFile: number;
  assistance: string;
}

export type UpdateServiceFormFields = Pick<
  ServiceData,
  | 'id'
  | 'service_id'
  | 'start_date'
  | 'end_date'
  | 'created_at'
  | 'name'
  | 'company'
  | 'unidentified'
  | 'main_contact'
  | 'main_contact_phone'
  | 'main_contact_email'
  | 'provider_contact'
  | 'provider_contact_phone'
  | 'provider_contact_email'
  | 'subarea'
  | 'subarea_id'
  | 'cancelled'
  | 'max_capacity'
  | 'start_registration_date'
  | 'end_registration_date'
  | 'requested_number'
  | 'assistant_number'
  | 'beneficiaries'
  | 'registration_link'
  | 'nps'
>;

export type ServiceDataDates = Pick<
  ServiceData,
  'created_at' | 'start_date' | 'end_date' | 'start_registration_date' | 'end_registration_date'
>;

export type ServiceDataDatesKeys = keyof Pick<
  ServiceData,
  'created_at' | 'start_date' | 'end_date' | 'start_registration_date' | 'end_registration_date'
>;

export type CodeSectionService = 'datos_basicos';

export interface CopyService {
  id: number;
  name?: string;
  service_id?: string;
}

export interface ServicesUser {
  name: string;
  start_date: string;
  company: string;
  email: string;
}

export interface ServiceRequestData {
  id: number;
  name: string;
  surname: string;
  email: string;
  document_number?: string;
  city: string;
  entity: string;
  phone: number;
  lopd: boolean;
  birth_date?: string;
  academy?: string;
  created_at: string;
  assistance: string;
  user_id: number;
  manual: boolean;
}

//GET ALL SERVICES
export interface GetServicesAction extends Action {
  type: ServiceDataConsts.GET_SERVICES;
  serviceData: ServiceData;
  role?: string;
}

export interface GetServicesOkAction extends Action {
  type: ServiceDataConsts.GET_SERVICES_OK;
  serviceList: ServiceData[];
  subareas: LookUpSubarea;
  role?: string;
}

export interface GetServicesKoAction extends Action {
  type: ServiceDataConsts.GET_SERVICES_KO;
  error: string;
}

//GET ALL USER SERVICES
export interface GetUserServicesAction extends Action {
  type: ServiceDataConsts.GET_USER_SERVICES;
}

export interface GetUserServicesOkAction extends Action {
  type: ServiceDataConsts.GET_USER_SERVICES_OK;
  servicesUserList: ServicesUser[];
}

export interface GetUserServicesKoAction extends Action {
  type: ServiceDataConsts.GET_USER_SERVICES_KO;
  error: string;
}

//GET ONE SERVICE
export interface GetServiceDataAction extends Action {
  type: ServiceDataConsts.GET_SERVICE_DATA;
  id: string;
}

export interface GetServiceDataOkAction extends Action {
  type: ServiceDataConsts.GET_SERVICE_DATA_OK;
  serviceData: ServiceData;
}

export interface GetServiceDataKoAction extends Action {
  type: ServiceDataConsts.GET_SERVICE_DATA_KO;
  error: string;
}

//UPDATE SERVICE
export interface UpdateServiceAction extends Action {
  type: ServiceDataConsts.UPDATE_SERVICE;
  data: UpdateServiceFormFields;
  id: number;
}

export interface UpdateServiceOkAction extends Action {
  type: ServiceDataConsts.UPDATE_SERVICE_OK;
  serviceData: ServiceData;
}

export interface UpdateServiceKoAction extends Action {
  type: ServiceDataConsts.UPDATE_SERVICE_KO;
  error: string;
}

// ADD USER TO SERVICE
export interface AddUserToServiceAction extends Action {
  type: ServiceDataConsts.ADD_USER_TO_SERVICE;
  id: number;
  service_id: number;
}

export interface AddUserToServiceOkAction extends Action {
  type: ServiceDataConsts.ADD_USER_TO_SERVICE_OK;
  serviceData: ServiceRequestData;
}

export interface AddUserToServiceKoAction extends Action {
  type: ServiceDataConsts.ADD_USER_TO_SERVICE_KO;
  error: string;
}

export interface ServiceCreationDataAction extends Action {
  type: ServiceDataConsts.SERVICE_CREATION_DATA;
  key: string;
  value: string | number | null;
}

//DELETE SERVICE
export interface DeleteServiceAction extends Action {
  type: ServiceDataConsts.DELETE_SERVICE;
  id: number;
}

export interface DeleteServiceOkAction extends Action {
  type: ServiceDataConsts.DELETE_SERVICE_OK;
  id: number;
}

export interface DeleteServiceKoAction extends Action {
  type: ServiceDataConsts.DELETE_SERVICE_KO;
  error: string;
}

// VALIDATIONS ERRORS
export interface ServiceValidationErrorsAction extends Action {
  type: ServiceDataConsts.SERVICE_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

//GET SERVICE REQUESTED
export interface GetServiceRequestedDataAction extends Action {
  type: ServiceDataConsts.GET_SERVICES_REQUESTED;
  id: number;
}

export interface GetServiceRequestedDataOkAction extends Action {
  type: ServiceDataConsts.GET_SERVICES_REQUESTED_OK;
  serviceRequests: ServiceRequestData[];
}

export interface GetServiceRequestedDataKoAction extends Action {
  type: ServiceDataConsts.GET_SERVICES_REQUESTED_KO;
  error: string;
}

// SET SERVICES REQUESTED

export interface SetServiceRequestedDataAction extends Action {
  type: ServiceDataConsts.SET_SERVICES_REQUESTED;
  key: keyof ServiceRequestData;
  value: string | number | boolean;
  id: number;
}

// SET ASSISTANCE
export interface SetServiceAssistanceAction extends Action {
  type: ServiceDataConsts.SET_SERVICES_ASSISTANCE;
  assistance: string;
}

//UPDATE ASSISTANCE
export interface UpdateServiceAssistanceDataAction extends Action {
  type: ServiceDataConsts.UPDATE_SERVICE_ASSISTANCE;
  id: number;
  data: ServiceRequestData[];
}

export interface UpdateServiceAssistanceDataKoAction extends Action {
  type: ServiceDataConsts.UPDATE_SERVICE_ASSISTANCE_KO;
  error: string;
}

//REMOVE SERVICE REQUESTED
export interface RemoveServiceRequestedDataAction extends Action {
  type: ServiceDataConsts.REMOVE_SERVICE_REQUESTED;
  requestedId: number;
}

export interface RemoveServiceRequestedDataOkAction extends Action {
  type: ServiceDataConsts.REMOVE_SERVICE_REQUESTED_OK;
  requestedId: number;
}

export interface RemoveServiceRequestedDataKoAction extends Action {
  type: ServiceDataConsts.REMOVE_SERVICE_REQUESTED_KO;
  error: string;
}
//UPLOAD DOCUMENT

export interface UploadDocumentServiceAction extends Action {
  type: ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE;
  documentationId: number;
  serviceId: number;
  data: File;
}

export interface UploadDocumentServiceOkAction extends Action {
  type: ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE_OK;
  documents: DocumentationItem[];
}

export interface UploadDocumentServiceKoAction extends Action {
  type: ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE_KO;
  error: string;
}

//DOWNLOAD DOCUMENT

export interface DownloadDocumentServiceAction extends Action {
  type: ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE;
  fileId: number;
  name: string;
  serviceId: number;
  get: boolean;
}
export interface DownloadDocumentOkServiceAction extends Action {
  type: ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE_OK;
  data: Blob;
  get: boolean;
  name: string;
}
export interface DownloadDocumentKoServiceAction extends Action {
  type: ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE_KO;
  error: string;
}

//GET DOCUMENT

export interface GetDocumentServiceAction extends Action {
  type: ServiceDataConsts.GET_DOCUMENT_SERVICE;
  fileId: number;
  name: string;
  serviceId: number;
}
export interface GetDocumentOkServiceAction extends Action {
  type: ServiceDataConsts.GET_DOCUMENT_SERVICE_OK;
  data: Blob;
  name: string;
}
export interface GetDocumentKoServiceAction extends Action {
  type: ServiceDataConsts.GET_DOCUMENT_SERVICE_KO;
  error: string;
}

//REMOVE DOCUMENT

export interface RemoveDocumentServiceAction extends Action {
  type: ServiceDataConsts.REMOVE_DOCUMENT_SERVICE;
  fileId: number;
  serviceId: number;
}
export interface RemoveDocumentOkServiceAction extends Action {
  type: ServiceDataConsts.REMOVE_DOCUMENT_SERVICE_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentKoServiceAction extends Action {
  type: ServiceDataConsts.REMOVE_DOCUMENT_SERVICE_KO;
  error: string;
}

export type ServiceDataAction =
  | GetServicesAction
  | GetServicesOkAction
  | GetServicesKoAction
  | UpdateServiceAction
  | UpdateServiceOkAction
  | UpdateServiceKoAction
  | GetServiceDataAction
  | GetServiceDataOkAction
  | GetServiceDataKoAction
  | ServiceValidationErrorsAction
  | ServiceCreationDataAction
  | GetServiceRequestedDataAction
  | GetServiceRequestedDataOkAction
  | GetServiceRequestedDataKoAction
  | AddUserToServiceAction
  | AddUserToServiceOkAction
  | AddUserToServiceKoAction
  | GetUserServicesAction
  | GetUserServicesOkAction
  | GetUserServicesKoAction
  | SetServiceRequestedDataAction
  | SetServiceAssistanceAction
  | UpdateServiceAssistanceDataAction
  | UpdateServiceAssistanceDataKoAction
  | RemoveServiceRequestedDataAction
  | RemoveServiceRequestedDataOkAction
  | RemoveServiceRequestedDataKoAction
  | UploadDocumentServiceAction
  | UploadDocumentServiceOkAction
  | UploadDocumentServiceKoAction
  | DownloadDocumentServiceAction
  | DownloadDocumentOkServiceAction
  | DownloadDocumentKoServiceAction
  | GetDocumentServiceAction
  | GetDocumentOkServiceAction
  | GetDocumentKoServiceAction
  | RemoveDocumentServiceAction
  | RemoveDocumentOkServiceAction
  | RemoveDocumentKoServiceAction;

export type ServiceDataDispatch = (action: ServiceDataAction) => void;
