import { PaymentInfo } from '../../project/definitions';
import { ChangeDateValues, ScholarshipReloadData } from '../definitions';
import { ScholarshipInstitutePaymentsConst } from './action_types';
import {
  ChangeScholarshipPaymentDateAction,
  ChangeScholarshipPaymentDateActionKo,
  ChangeScholarshipPaymentDateActionOk,
  SendScholarshipPaymentAction,
  SendScholarshipPaymentActionKo,
  SendScholarshipPaymentActionOk,
  SendScholarshipReloadAction,
  SendScholarshipReloadActionKo,
  SendScholarshipReloadActionOk,
  SendScholarshipStudentTransferAction,
  SendScholarshipStudentTransferActionKo,
  SendScholarshipStudentTransferActionOk,
} from './definitions';

export function changeScholarshipPaymentDate(changeDateValues: ChangeDateValues): ChangeScholarshipPaymentDateAction {
  return {
    type: ScholarshipInstitutePaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE,
    changeDateValues,
  };
}

export function changeScholarshipPaymentDateOk(): ChangeScholarshipPaymentDateActionOk {
  return {
    type: ScholarshipInstitutePaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_OK,
  };
}

export function changeScholarshipPaymentDateKo(error: string): ChangeScholarshipPaymentDateActionKo {
  return {
    type: ScholarshipInstitutePaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_KO,
    error,
  };
}

export function sendScholarshipReloadAction(reloadData: ScholarshipReloadData): SendScholarshipReloadAction {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_RELOAD,
    reloadData,
  };
}

export function sendScholarshipReloadActionOk(): SendScholarshipReloadActionOk {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_RELOAD_OK,
  };
}

export function sendScholarshipReloadActionKo(error: string): SendScholarshipReloadActionKo {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_RELOAD_KO,
    error,
  };
}

export function sendScholarshipStudentTransferAction(paymentData: PaymentInfo): SendScholarshipStudentTransferAction {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_STUDENT_TRANSFER,
    paymentData,
  };
}

export function sendScholarshipStudentTransferActionOk(): SendScholarshipStudentTransferActionOk {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_STUDENT_TRANSFER_OK,
  };
}

export function sendScholarshipStudentTransferActionKo(error: string): SendScholarshipStudentTransferActionKo {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_STUDENT_TRANSFER_KO,
    error,
  };
}

export function sendScholarshipPaymentAction(paymentData: PaymentInfo): SendScholarshipPaymentAction {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_PAYMENT,
    paymentData,
  };
}

export function sendScholarshipPaymentActionOk(): SendScholarshipPaymentActionOk {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_PAYMENT_OK,
  };
}

export function sendScholarshipPaymentActionKo(error: string): SendScholarshipPaymentActionKo {
  return {
    type: ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_PAYMENT_KO,
    error,
  };
}
