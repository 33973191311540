import { InputLabel } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { EntityInfo } from '../../../../redux/entity/definitions';
import FormContainer from '../../../../components/Forms/FormContainer';
import CheckboxCompo from '../../../../components/Checkbox/CheckboxCompo';
import { tFormKey, tKey } from '../../../../helpers/translate';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { AppState } from '../../../../redux/root-reducer';
import { selectGetOneEntity } from '../../../../redux/entity/selectors';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import {
  downloadDocument,
  downloadPreEntityDocument,
  getDocument,
  getPreEntityDocument,
  removeDocument,
  removePreEntityDocument,
  setMandatoyDocument,
  uploadDocument,
  uploadPreEntityDocument,
} from '../../../../redux/entity/actions';
import styles from '../../entityData.module.scss';

interface EntityDocumentationFormProps {
  useForm: any;
  documentation: DocumentationItem[];
  entity: EntityInfo;
  disabled?: boolean;
  responsible?: boolean;
  isNousCims: boolean;
  getPreEntityDocument: (file_id: number, name: string, entityId: number) => void;
  removePreEntityDocument: (file_id: number, entityId: number) => void;
  downloadPreEntityDocument: (file_id: number, name: string, entityId: number) => void;
  uploadPreEntityDocument: (document_id: number, data: File, force_add?: boolean | false) => void;
  uploadEntityDocument: (document_id: number, data: File, entity_id: any, force_add?: boolean | false) => void;
  downloadEntityDocument: (file_id: number, name: string, entityId: number) => void;
  removeEntityDocument: (file_id: number, entityId: number) => void;
  getEntityDocument: (file_id: number, name: string, entityId: number) => void;
  setMandatoyDocument: (code: string, mandatory: boolean) => void;
}

const spain = 'España';

const EntityDocumentationForm: React.FC<EntityDocumentationFormProps> = ({
  documentation,
  useForm,
  entity,
  disabled,
  responsible,
  getPreEntityDocument,
  removePreEntityDocument,
  uploadPreEntityDocument,
  uploadEntityDocument,
  downloadPreEntityDocument,
  downloadEntityDocument,
  removeEntityDocument,
  getEntityDocument,
  setMandatoyDocument,
}) => {
  const actions = ['checked', 'upload', 'download', 'view', 'remove'];
  const { register } = useForm;

  const { id, country, joined_49_2020 } = entity;

  const addDocument = (document: File, description: string, genericId?: number, documentationId?: number) => {
    if (genericId && documentationId) {
      responsible
        ? uploadPreEntityDocument(documentationId, document, true)
        : uploadEntityDocument(documentationId, document, id, true);
    }
  };

  return (
    <FormContainer title={tKey('Documentos')}>
      {country === spain && (
        <div className={styles.compoundItem}>
          <CheckboxCompo
            name="joined_49_2020"
            questionText={tFormKey('¿Está adherida a la ley 49/2020?')}
            defaultValue={joined_49_2020}
            disabled={disabled}
            register={register}
            onChange={value => setMandatoyDocument('L49', value)}
          />
          <InputLabel className={styles.label}>{tFormKey('NuevoDocAdj')}</InputLabel>
        </div>
      )}
      <FncAddDocuments
        multidocument
        addDoc={addDocument}
        documents={documentation}
        genericId={id}
        actions={actions}
        disabled={disabled}
        upload={responsible ? uploadPreEntityDocument : uploadEntityDocument}
        download={responsible ? downloadPreEntityDocument : downloadEntityDocument}
        remove={responsible ? removePreEntityDocument : removeEntityDocument}
        getFile={responsible ? getPreEntityDocument : getEntityDocument}
        required
      />
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  entity: selectGetOneEntity(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  uploadEntityDocument: (document_id: number, data: File, entityId: any, force_add?: boolean | false): void =>
    dispatch(uploadDocument(document_id, data, entityId, force_add)),
  downloadEntityDocument: (file_id: number, name: string, entityId: number): void =>
    dispatch(downloadDocument(file_id, name, entityId)),
  removeEntityDocument: (file_id: number, entityId: number): void => dispatch(removeDocument(file_id, entityId)),
  getEntityDocument: (file_id: number, name: string, entityId: number): void =>
    dispatch(getDocument(file_id, name, entityId)),
  downloadPreEntityDocument: (file_id: number, name: string, entityId: number): void =>
    dispatch(downloadPreEntityDocument(file_id, name, entityId)),
  uploadPreEntityDocument: (documentation_id: number, data: File, force_add?: boolean | false): void =>
    dispatch(uploadPreEntityDocument(documentation_id, data, force_add)),
  removePreEntityDocument: (documentation_id: number, entityId: number): void =>
    dispatch(removePreEntityDocument(documentation_id, entityId)),
  getPreEntityDocument: (documentation_id: number, name: string, entityId: number): void =>
    dispatch(getPreEntityDocument(documentation_id, name, entityId)),
  setMandatoyDocument: (code: string, mandatory: boolean) => dispatch(setMandatoyDocument(code, mandatory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityDocumentationForm);
