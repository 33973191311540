import { Justification, ScholarshipId } from './Justification';
import { ScholarshipJustificationsConst } from './action_types';
import {
  AddJustificationsAction,
  AddJustificationsOkAction,
  DeleteJustificationsAction,
  DeleteJustificationsOkAction,
  GetJustificationsAction,
  GetJustificationsOkAction,
  JustificationsKoAction,
} from './definitions';

export function getScholarshipJustificationsAction(id: ScholarshipId): GetJustificationsAction {
  return {
    type: ScholarshipJustificationsConst.GET_SCHOLARSHIP_JUSTIFICATIONS,
    id,
  };
}

export function getScholarshipJustificationsActionOk(data: Justification[]): GetJustificationsOkAction {
  return {
    type: ScholarshipJustificationsConst.GET_SCHOLARSHIP_JUSTIFICATIONS_OK,
    list: data,
  };
}

export function addScholarshipJustificationsAction(justification: Justification): AddJustificationsAction {
  return {
    type: ScholarshipJustificationsConst.ADD_SCHOLARSHIP_JUSTIFICATIONS,
    justification,
  };
}

export function addScholarshipJustificationsActionOk(justification: Justification): AddJustificationsOkAction {
  return {
    type: ScholarshipJustificationsConst.ADD_SCHOLARSHIP_JUSTIFICATIONS_OK,
    justification,
  };
}

export function deleteScholarshipJustificationsAction(justification: Justification): DeleteJustificationsAction {
  return {
    type: ScholarshipJustificationsConst.DELETE_SCHOLARSHIP_JUSTIFICATIONS,
    justification,
  };
}

export function deleteScholarshipJustificationsActionOk(justification: Justification): DeleteJustificationsOkAction {
  return {
    type: ScholarshipJustificationsConst.DELETE_SCHOLARSHIP_JUSTIFICATIONS_OK,
    justification,
  };
}

export function scholarshipJustificationsActionKo(error: string): JustificationsKoAction {
  return {
    type: ScholarshipJustificationsConst.SCHOLARSHIP_JUSTIFICATIONS_KO,
    error,
  };
}
