import { Action } from 'redux';
import { DocumentationItem, Uuid } from '../common/definitions';
import { ScholarshipData, ScholarshipType } from '../scholarship/definitions';
import { InterviewsConsts } from './action_types';

export interface InterviewData {
  interview_assessment: string;
  interview_comment: string | null;
  motivation_assessment: string;
  motivation_comment: string | null;
  itinerary_assessment: string;
  itinerary_comment: string | null;
  capacities_assessment: string;
  capacities_comment: string | null;
  interview_mentor: string | null;
}

export interface InterviewTableRow {
  scholarship_id: number;
  date: string;
  channel: string;
  student_name: string;
  student_surname: string;
  student_surname2: string | null;
  student_email: string;
  educator_name: string | null;
  educator_surname: string | null;
  educator_surname2: string | null;
  educator_email: string | null;
  educator_phone: string | null;
  scholarship_origin: string | null;
  scholarship_state: string;
  scholarship_academic_year: string;
  scholarship_type: ScholarshipType;
  interviewer_name: string;
  interviewer_surname: string;
  interviewer_surname2: string | null;
  interviewer_email: string;
}

export interface InterviewEntity {
  id: Uuid;
  entity_id: string;
  alias: string;
  address: string;
  zone: string;
  default: boolean;
}

// export interface UserInterviewData extends ScholarshipData, InterviewData {}
export type UserInterviewData = InterviewData & ScholarshipData;

export const initialState = {
  loading: false,
  error: null as string | null,
  userInterviewData: null as InterviewData | null,
  interviewTableData: [] as InterviewTableRow[],
  documentation: [] as DocumentationItem[],
};

export type InterviewState = typeof initialState;

export interface GetInterviewsDataAction extends Action {
  type: InterviewsConsts.GET_INTERVIEWS;
}

export interface GetInterviewsDataOkAction extends Action {
  type: InterviewsConsts.GET_INTERVIEWS_OK;
  interviewTableData: InterviewTableRow[];
}

export interface GetInterviewsDataKoAction extends Action {
  type: InterviewsConsts.GET_INTERVIEWS_KO;
  error: string;
}

// GET INTERVIEWS FINISHED

export interface GetInterviewsFinishedDataAction extends Action {
  type: InterviewsConsts.GET_INTERVIEWS_FINISHED;
}

export interface GetInterviewsFinishedDataOkAction extends Action {
  type: InterviewsConsts.GET_INTERVIEWS_FINISHED_OK;
  interviewTableData: InterviewTableRow[];
}

export interface GetInterviewsFinishedDataKoAction extends Action {
  type: InterviewsConsts.GET_INTERVIEWS_FINISHED_KO;
  error: string;
}

export type InterviewDataDispatch = (action: InterviewDataAction) => void;

export type InterviewDataAction =
  | GetInterviewsDataAction
  | GetInterviewsDataOkAction
  | GetInterviewsDataKoAction
  | GetInterviewsFinishedDataAction
  | GetInterviewsFinishedDataOkAction
  | GetInterviewsFinishedDataKoAction;
