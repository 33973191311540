import { ScholarshipPayment } from '../../../../redux/scholarship/definitions';
import { ScholarshipPaymentValues } from '../hooks/useScholarshipPaymentFilters';
import { ScholarshipPaymentsDateFilter } from './ScholarshipPaymentsDateFilter';
import { ScholarshipPaymentsShowFilter } from './ScholarshipPaymentsShowFilter';

export class ScholarshipPaymentsFilterGenerator {
  constructor(private scholarshipPaymentList: ScholarshipPayment[], private filterValues: ScholarshipPaymentValues) {}

  getFilteredList(): ScholarshipPayment[] {
    const showFilterGenerator = new ScholarshipPaymentsShowFilter(this.scholarshipPaymentList, this.filterValues.show);
    const dateFilterGenerator = new ScholarshipPaymentsDateFilter(
      showFilterGenerator.getFiltered(),
      this.filterValues.paymentDate,
      this.filterValues.reloadDate,
    );
    return dateFilterGenerator.getFiltered();
  }
}
