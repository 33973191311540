import { convertBase64ImageToFile } from '../../helpers/selectImages';
import { DocumentationItem, FileItem, ResourceImageData } from '../../redux/common/definitions';
import { CodeSectionProject } from '../../redux/kpis/definitions';
import { DeleteData, FilterData, GenericFieldUpload, RemoveDocData } from '../../redux/project/action';
import {
  AnnexesFileProject,
  AreaSubArea,
  ConfiguratorData,
  CustomFieldsForm,
  FollowUp,
  NewScheduleProject,
  OwnersCandidatesResponse,
  ProjectChangeStateKo,
  ProjectChangeStateOk,
  ProjectData,
  ProjectListData,
  ScheduledAppointmentItem,
} from '../../redux/project/definitions';
import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from '../api';
import { ProjectListItem } from '../../redux/accounting/definitions';

const baseURL = apiBaseUrl;
interface DocumentsData {
  documents: DocumentationItem[];
}
interface DocumentsResponse {
  data: DocumentsData;
}

export interface ProjectChangeStateData {
  state_code: string;
  state_name: string;
}

export const retrieveProjects = async (): Promise<ProjectListData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ProjectListData }>(`${baseURL}/project/`, init);
  return data;
};

export const getProjectById = async (id: string): Promise<{ data: ProjectData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ProjectData }>(`${baseURL}/project/${id}`, init);
};

export const createProject = async (
  project: ProjectData,
): Promise<Result<{ data: ProjectData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(project),
  };

  return validatedApiCall<{ data: ProjectData }>(`${baseURL}/project/`, init);
};

export const editProject = async (
  project: ProjectData,
  idProject: number,
): Promise<Result<{ data: ProjectData }, APIValidationError> | any> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(project),
  };

  return validatedApiCall<{ data: ProjectData }>(`${baseURL}/project/${idProject}`, init);
};

export const deleteProjectById = async (
  id: number,
) /* : Promise<Result<{ data: ProjectData }, APIValidationError>> */ => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { value } = await validatedApiCall<{ data: ProjectData }>(`${baseURL}/project/${id}`, init);
  return value;
};

export const agreeDocument = async (file: File): Promise<any> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };

  const { data } = await apiCall<any>(`${baseURL}/project/document`, init);
  return data;
};

export const addDescriptionDocument = async (name: string, idProject: number, file_id: number): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ name, file_id }),
  };

  const { data } = await apiCall<any>(`${baseURL}/project/documentation/${idProject}`, init);
  return data;
};

export const getDocumentationApi = async (id: string, code?: string | undefined): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  if (code) return apiCall<{ data: AreaSubArea }>(`${baseURL}/project/documentation/${id}/${code}`, init);
  return apiCall<{ data: AreaSubArea }>(`${baseURL}/project/documentation/${id}`, init);
};

export const downloadFile = async (idProject: number, file_id: number): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/project/document/download/${idProject}/${file_id}`, init);
};

export const deleteFile = async (idProject: number, file_id: number): Promise<any> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<{ data: AreaSubArea }>(`${baseURL}/project/document/${idProject}/${file_id}`, init);
};

export const getAreasSubAreas = async (): Promise<{ data: AreaSubArea }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: AreaSubArea }>(`${baseURL}/subareas/`, init);
};

export const changeStateFromApi = async (
  id: number,
  states: ProjectChangeStateOk | ProjectChangeStateKo,
  comments?: string,
): Promise<{ data: ProjectChangeStateData }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ comments }),
  };

  return apiCall<{ data: ProjectChangeStateData }>(`${baseURL}/project/change_state/${states}/${id}`, init);
};

export const getOneFollowUp = async (id: number): Promise<FollowUp> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<FollowUp>(`${baseURL}/project/tracking/find/${id}`, init);
};

export const addOneFollowUp = async (data: FollowUp): Promise<FollowUp> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<FollowUp>(`${baseURL}/project/tracking`, init);
};

export const getListFollowUps = async (project_id: number): Promise<FollowUp[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<FollowUp[]>(`${baseURL}/project/tracking/list/${project_id}`, init);
};

export const editFollowup = async (data: FollowUp, id: number): Promise<FollowUp> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<FollowUp>(`${baseURL}/project/tracking/${id}`, init);
};

export const deleteFollowUp = async (id: number): Promise<FollowUp> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<FollowUp>(`${baseURL}/project/tracking/${id}`, init);
};

export const uploadNewDocument = async (file: File, type: string): Promise<{ data: {} }> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name || 'asdf');
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const data = await apiCall<any>(`${baseURL}/project/tracking/document`, init);
  return data;
};

export const uploadAgreement = async (
  documentation_id: number,
  data: File,
  project_id: number,
): Promise<{ data: {} }> => {
  const dataForm = new FormData();
  dataForm.append('document', data, data.name || 'asdf');
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const respose = await apiCall<any>(`${baseURL}/project/document_convenio/${project_id}/${documentation_id}`, init);
  return respose;
};

export const deleteAgreement = async (documentation_id: number, project_id: number): Promise<{ data: {} }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const respose = await apiCall<any>(`${baseURL}/project/document_convenio/${project_id}/${documentation_id}`, init);
  return respose;
};

export const downloadFollowUpDocument = async (project_id: number, file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/project/tracking/document/${project_id}/${file_id}`, init);
};

export const removeFollowupDocument = async (project_id: number, file_id: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(
    `${baseURL}/project/tracking/document/${project_id}/${file_id}`,
    init,
  );
  return data;
};

export const deleteFileFromApi = async (dataF: RemoveDocData): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify(dataF),
  };
  return await apiCall<any>(`${baseURL}/project/custom_fields/global/remove_document`, init);
};

export const getProjectPayment = async (project_id: number): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<any>(`${baseURL}/project/${project_id}/payment`, init);
};

export const makePaymentProject = async (
  project_id: number,
  project_schedule_id: number,
  account_number: string,
): Promise<ProjectData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ project_id, project_schedule_id, account_number }),
  };

  const { data } = await apiCall<{ data: ProjectData }>(`${baseURL}/project/make_payment`, init);
  return data;
};

export const getConfiguratorFieldsApi = (id: number, section: string): Promise<{ data: ConfiguratorData[] }> => {
  const data = {
    project_id: id,
    section,
  };
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: ConfiguratorData[] }>(`${baseURL}/project/custom_fields/load`, init);
};

export const sendConfiguratorFieldsApi = async (
  project_id: number,
  section: string,
  dataF: ConfiguratorData[],
): Promise<any> => {
  const data = {
    project_id,
    section,
    fields: dataF,
  };
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<any>(`${baseURL}/project/custom_fields/assign`, init);
};

export const confirmConfiguratorApi = async (
  id_project: number,
): Promise<Result<{ data: any }, APIValidationError>> => {
  const data = {
    project_id: id_project,
  };
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<any>(`${baseURL}/project/custom_fields/confirm`, init);
};

export const getCustomFieldsForm = async (
  project_id: number,
  project_schedule_id: number,
  section: CodeSectionProject,
): Promise<{ data: CustomFieldsForm }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ project_id, project_schedule_id, section }),
  };

  return apiCall<{ data: CustomFieldsForm }>(`${baseURL}/project/custom_fields/get_project`, init);
};

export const setCustomFieldsForm = async (
  project_id: number,
  project_schedule_id: number,
  section: CodeSectionProject,
  fields: Record<string, string>,
  rows: any,
): Promise<{ data: ProjectData }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ project_id, project_schedule_id, section, fields, rows }),
  };

  return apiCall<{ data: ProjectData }>(`${baseURL}/project/custom_fields/set_project`, init);
};

export const assignDocsTable = async (data: FollowUp): Promise<FollowUp> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<FollowUp>(`${baseURL}/project/custom_fields/set_document`, init);
};

export const downloadDocument = async (file_id: number): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/file/download/${file_id}`, init);
};

export const deleteDocsTable = async (data: any): Promise<FollowUp> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<FollowUp>(`${baseURL}/project/custom_fields/remove_document`, init);
};

export const deleteRowTable = async (data: DeleteData): Promise<FollowUp> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<FollowUp>(`${baseURL}/project/custom_fields/remove_row`, init);
};

export const addDocumentFieldFromApi = async (data: GenericFieldUpload): Promise<any> => {
  const { document, project_id, fieldName } = data;
  const dataForm = new FormData();
  dataForm.append('document', document, document.name || 'asdf');
  dataForm.append('project_id', project_id.toString());
  dataForm.append('fieldName', fieldName);
  dataForm.append('project_schedule_id', data?.project_schedule_id?.toString() || '');
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };

  return apiCall<{ data: any }>(`${baseURL}/project/custom_fields/global/set_document`, init);
};

export const getDirectoryFilterDataFromApi = async (data?: FilterData): Promise<any> => {
  let defaultData;
  if (!data) {
    defaultData = {
      academic_years: '',
      courses: '',
      levels: '',
      poblacion: '',
      profesional: '',
      provinces: '',
      total: 26,
      page: 1,
    };
  }
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data ? data : defaultData),
  };

  return apiCall<any>(`${baseURL}/user/directory`, init);
};

export const getActorsMetadataFromApi = async (project_id: number): Promise<OwnersCandidatesResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: OwnersCandidatesResponse }>(
    `${baseURL}/project/team/candidates/${project_id}`,
    init,
  );
  return data;
};

export const sendActorFromApi = async (project_id: number, user_id: number): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ project_id, user_id }),
  };
  return apiCall<FollowUp>(`${baseURL}/project/team/user`, init);
};

export const deleteActorFromApi = async (project_id: number, user_id: number): Promise<FollowUp> => {
  const init = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify({ project_id, user_id }),
  };

  return apiCall<FollowUp>(`${baseURL}/project/team/user`, init);
};

// WIP

export const changeResponsibleFromApi = async (project_id: number, user_id: number): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ project_id, user_id }),
  };
  return apiCall<FollowUp>(`${baseURL}/project/`, init);
};

export const copyProject = async (project: ProjectData): Promise<{ project_id: number }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(project),
  };

  const { data } = await apiCall<{ data: { project_id: number } }>(`${baseURL}/project/copy`, init);

  return data;
};

export const getProjectSchedules = async (id: number): Promise<ScheduledAppointmentItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ScheduledAppointmentItem[] }>(
    `${baseURL}/project/project_schedules/${id}`,
    init,
  );
  return data;
};

export const getProjectSchedule = async (id: number): Promise<ScheduledAppointmentItem> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ScheduledAppointmentItem }>(`${baseURL}/project_schedule/${id}`, init);
  return data;
};

export const addProjectSchedule = async (
  schedule: ScheduledAppointmentItem,
): Promise<Result<{ data: string }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(schedule),
  };

  return validatedApiCall<{ data: string }>(`${baseURL}/project_schedule`, init);
};

export const updateProjectSchedule = async (
  schedule: ScheduledAppointmentItem,
): Promise<Result<{ data: string }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(schedule),
  };
  return validatedApiCall<{ data: string }>(`${baseURL}/project_schedule`, init);
};

export const deleteProjectSchedule = async (id: number): Promise<boolean> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/project_schedule/${id}`, init);
  return data === 'OK';
};

export const updateProjectSchedules = async (schedules: ScheduledAppointmentItem[]): Promise<boolean> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ schedules }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/project_schedules`, init);
  return data === 'OK';
};

export const uploadPhoneFollowUpDocument = async (file: File): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/project_schedule/file`, init);

  return data;
};

export const deletePhoneFollowUpDocument = async (file_id: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{
    data: string;
  }>(`${baseURL}/project_schedule/file/${file_id}`, init);
  return data;
};

export const downloadPhoneFollowUpDocument = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCallBlob(`${baseURL}/project_schedule/file/${file_id}`, init);
};

export const uploadAnnexDocumentByCode = async (file: File, code: string): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);

  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/project/annexes_file/${code}`, init);

  return data;
};

export const updateProjectFinalDate = async (
  scheduleData: NewScheduleProject,
): Promise<Result<{ data: any }, APIValidationError> | any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(scheduleData),
  };

  const data = await validatedApiCall<{ data: NewScheduleProject }>(`${baseURL}/project/annexes_file_project`, init);
  return data;
};

export const downloadAnnexesFileApi = async (file_id: number, project_id: number): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/project/annexes_file/${file_id}/${project_id}`, init);
};

export const getAnnexesFileApi = async (projectId: number): Promise<DocumentationItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: DocumentationItem[] }>(
    `${baseURL}/project/documents/annexes_file/${projectId}`,
    init,
  );

  return data;
};

export const addDocumentAnnexesFileFromApi = async (data: AnnexesFileProject): Promise<any> => {
  const { file, project_id, name, type } = data;
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  dataForm.append('project_id', project_id.toString());
  dataForm.append('name', name);
  dataForm.append('type', type);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };

  const _data = await apiCall<{ data: any }>(`${baseURL}/project/annexes_file_project`, init);
  return _data;
};

export const deleteDocumentAnnexesFileFromApi = async (annexesId: number) => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: DocumentationItem[] }>(
    `${baseURL}/project/documents/annexes_file/${annexesId}`,
    init,
  );

  return data;
};

export const uploadPaymentFileFromApi = async (file: File): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/project_invoice/file/upload`, init);
  return data;
};

export const removePaymentFileFromApi = async (fileId: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/project_invoice/file/${fileId}`, init);
  return data;
};

export const downloadPaymentFileFromApi = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCallBlob(`${baseURL}/project_invoice/file/download/${file_id}`, init);
};

export const linkPaymentProjectFromApi = async (
  project_id: number,
  project_schedule_id: number,
  invoice_id: number,
  invoice_file_id: number,
): Promise<ProjectData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ project_id, project_schedule_id, invoice_id, invoice_file_id }),
  };

  const { data } = await apiCall<{ data: ProjectData }>(`${baseURL}/project/link_payment`, init);
  return data;
};

export const getProjectImagesFromApi = async (projectId: number): Promise<ResourceImageData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ResourceImageData[] }>(`${baseURL}/project/images/${projectId}`, init);
  return data;
};

export const uploadImageFileFromApi = async (image: string, imageName: string): Promise<FileItem> => {
  const imageFile = convertBase64ImageToFile(image, imageName);
  const dataForm = new FormData();
  dataForm.append('image', imageFile, `${imageName}.${imageFile.type.split('/').pop()}`);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/project/image/upload`, init);
  return data;
};

export const addProjectImageFromApi = async (
  projectId: number,
  name: string,
  description: string,
  imageId: number,
  userId: number,
): Promise<ResourceImageData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ name, description, imageId, userId }),
  };

  return apiCall(`${baseURL}/project/${projectId}/image`, init);
};

export const editProjectImageFromApi = async (
  projectId: number,
  projectImageId: number,
  name: string,
  description: string,
  previousImageId: number,
  imageId: number,
  userId: number,
): Promise<ResourceImageData> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ name, description, previousImageId, imageId, userId }),
  };

  return apiCall(`${baseURL}/project/${projectId}/image/${projectImageId}`, init);
};

export const deleteProjectImageByImageIdFromApi = async (imageId: number): Promise<{ data: string }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall(`${baseURL}/project/image/${imageId}`, init);
};

export const exportProjectCustomFields = async (projectId: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCallBlob(`${baseURL}/project/${projectId}/data/custom_fields`, init);
};

export const getProjectsFromApi = async (): Promise<ProjectListItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: ProjectListItem[] }>(`${baseURL}/project/payments/active`, init);
  return data;
};

export const cancelProjectAgreementFromApi = async (project_id: number): Promise<ProjectChangeStateData> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  const { data } = await apiCall<{ data: ProjectChangeStateData }>(
    `${baseURL}/project/change_state/p10p09/${project_id}`,
    init,
  );
  return data;
};
