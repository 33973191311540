import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIError, APIValidationError } from '../../api/api';
import Actions from '../../components/Layout/Actions';
import LayoutLogin from '../../components/Layout/LayoutLogin';
import Loading from '../../components/Loading/Loading';
import RegisterForm from '../../components/RegisterForm/RegisterForm';
import { tFormKey, tKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import { getRoles, RegisterFields, registerSubmit, RolesProps } from '../../redux/register/actions';
import {
  selectErrorAPI,
  selectLanguage,
  selectLoading,
  selectRegistered,
  selectRoleId,
  selectRoles,
  selectValidationErrors,
} from '../../redux/register/selectors';
import { AppState } from '../../redux/root-reducer';
import { RegisterSchema } from '../../validations/formSchema';
import Language from '../Language/Language';
import styles from './register.module.scss';

interface RegisterProps {
  entity: boolean;
  loading: boolean;
  language: string;
  errorApi: APIError | null;
  registered: boolean;
  idRole: number;
  roles: RolesProps[];
  registerSubmit: (registerFields: RegisterFields) => void;
  getRoles: (entity: boolean) => void;
  validationErrors: APIValidationError | null;
}

const Register: React.FC<RegisterProps> = ({
  loading,
  language,
  entity,
  errorApi,
  registered,
  idRole,
  roles,
  registerSubmit,
  getRoles,
  validationErrors,
}) => {
  const { control, handleSubmit, errors, setError, register } = useForm<RegisterFields>({
    validationSchema: RegisterSchema,
  });
  useEffect(() => {
    getRoles(entity);
  }, [entity, getRoles]);

  const onSubmit = (data: RegisterFields) => {
    entity ? registerSubmit({ ...data, role_id: idRole, language: language }) : registerSubmit(data);
  };

  let content: JSX.Element;
  const renderActions: JSX.Element = (
    <>
      <div className={styles.actions}>
        <Actions />
      </div>
      <div className={styles.languageContainer}>
        <Language />
      </div>
    </>
  );
  if (registered) {
    let message = tKey('confirma_alta');
    if (webStyle === 'zing' && document.location.pathname === '/registro/entidad') {
      if (document.location.pathname === '/registro/entidad') {
        message = tKey('confirma_alta_zing');
      }
    }
    content = (
      <>
        <div>{message}</div>
        {renderActions}
      </>
    );
  } else {
    content = (
      <Fragment>
        <RegisterForm
          setError={setError}
          submitForm={onSubmit}
          errorApi={errorApi}
          errors={errors}
          control={control}
          handleSubmit={handleSubmit}
          entity={entity}
          defaultRole={idRole}
          roles={roles}
          loading={loading}
          validationErrors={validationErrors}
          register={register}
        />
        {renderActions}
      </Fragment>
    );
  }

  return (
    <LayoutLogin subtitle={tFormKey('formulario_registro')}>
      <div className={styles[`registerContainer__${webStyle}`]}>{loading ? <Loading big /> : content}</div>
    </LayoutLogin>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  language: selectLanguage(state),
  errorApi: selectErrorAPI(state),
  registered: selectRegistered(state),
  roles: selectRoles(state),
  idRole: selectRoleId(state),
  validationErrors: selectValidationErrors(state),
});

/* RegisterDispatch and AboutUsDispatch */
const mapDispatchToProps = (dispatch: any) => ({
  registerSubmit: (registerFields: RegisterFields) => dispatch(registerSubmit(registerFields)),
  getRoles: (entity: boolean) => dispatch(getRoles(entity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
