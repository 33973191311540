import { InputAdornment, OutlinedTextFieldProps, TextField } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError, FieldErrors, FieldName, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../../helpers/customHooks/useIsRequired';
import { tErrorKey } from '../../../helpers/translate';
import requiredStyle from '../../../helpers/validator.module.scss';
import ClipBoardConnected from '../../ClipBoard/ClipBoardConnected';
import styles from '../inputs.module.scss';
import { InputSize } from '../types';

export interface TextInputControllerProps<T extends FieldValues>
  extends Omit<OutlinedTextFieldProps, 'size' | 'variant'> {
  errors: FieldErrors<T>;
  name: FieldName<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  validator?: boolean;
  disabled?: boolean;
  control: Control<T>;
  size?: InputSize;
  visible?: boolean;
  icon?: JSX.Element;
  green?: boolean;
  price?: boolean;
  clipBoard?: boolean;
  textAreaResize?: boolean;
  onClipBoard?: (value: any) => string;
}

const TextInputController = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errors,
  schema,
  rows = '1',
  rowsMax = '1',
  validator,
  label,
  size,
  type = 'text',
  green = false,
  visible = true,
  icon,
  price,
  clipBoard,
  textAreaResize,
  onClipBoard,
  ...props
}: TextInputControllerProps<T>): ReactElement => {
  const error = errors[name] as FieldError;
  const required = useIsRequired(name, schema);

  const endAdornmentType = () => {
    if (price) return <InputAdornment position="end">€</InputAdornment>;
    if (clipBoard)
      return <ClipBoardConnected value={defaultValue as string} disabled={!defaultValue} onClipBoard={onClipBoard} />;
    return null;
  };

  return (
    <React.Fragment>
      <Controller
        as={
          <TextField
            className={!visible ? styles['hidden'] : size && styles[`size_${size}`]}
            // We change the label directly intead of passing the required prop
            // because we don't want Material's validation stuff to kick in.
            label={
              label && required && !validator ? (
                <React.Fragment>
                  {label} {'\u00a0*'}
                </React.Fragment>
              ) : (
                label
              )
            }
            error={!!error}
            classes={{
              root: `${!!validator ? requiredStyle.required : green ? requiredStyle.green : ''}
              ${!!clipBoard ? styles.hasClipBoard : ''} ${!!textAreaResize ? styles.textArea : ''}`,
            }}
            helperText={tErrorKey(error?.message as string)}
            type={type}
            rows={rows}
            rowsMax={rowsMax}
            variant="outlined"
            InputProps={{
              startAdornment: icon ? <InputAdornment position="end">{icon}</InputAdornment> : null,
              endAdornment: endAdornmentType(),
            }}
            {...props}
          ></TextField>
        }
        name={name}
        control={control}
        defaultValue={defaultValue || ''}
      >
        {clipBoard && (
          <div className={styles.clipBoard}>
            <ClipBoardConnected value={defaultValue as string} onClipBoard={onClipBoard} />
          </div>
        )}
      </Controller>
    </React.Fragment>
  );
};

export default TextInputController;
