import { Tooltip } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React, { Fragment } from 'react';
import {
  ScholarshipListItem,
  ScholarshipTransversalCompetenceFields,
  transversalCompetenceTypes,
} from '../../../redux/scholarship/definitions';

interface ShowTypesProps {
  rowData: ScholarshipListItem;
}

const ShowTypes: React.FC<ShowTypesProps> = ({ rowData }) => {
  return (
    <Fragment>
      {transversalCompetenceTypes?.map(
        (type, index) =>
          rowData.transversal_competences.includes(type.code as ScholarshipTransversalCompetenceFields) && (
            <Tooltip key={index} title={type.name}>
              <Brightness1Icon style={{ color: type.color, height: '15px', verticalAlign: 'text-bottom' }} />
            </Tooltip>
          ),
      )}
    </Fragment>
  );
};

export default ShowTypes;
