import { ButtonProps } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import DragAndDropCustomFields, {
  DragAndDropCustomFieldsRef,
} from '../../../../../../../components/DragAndDrop/DragAndDropCustomFields/DragAndDropCustomFields';
import LayoutForm from '../../../../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { tKey, tProjectKey } from '../../../../../../../helpers/translate';
import { showSnackbar } from '../../../../../../../redux/FeedbackAPI/actions';
import { FeedbackAPIActionDispatch, SeveritySnackbar } from '../../../../../../../redux/FeedbackAPI/definitions';
import { ConfiguratorData } from '../../../../../../../redux/project/definitions';
import CustomFieldsPreview from '../CustomFieldsPreview/CustomFieldsPreview';
import SubmenuFormsAssistant from './SubmenuFormsAssistant';
import {
  CodeSectionInternalProject,
  FormAssistantFollowUpSections,
  FormAssistantGeneralFormSections,
  SectionInternalProject,
} from '../../../../../../../redux/internal-projects/definitions';

interface FormsAssistantProps
  extends RouteComponentProps<{ internal_project_template_id: string; section: CodeSectionInternalProject }> {
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const FormsAssistant: React.FC<FormsAssistantProps> = ({ match, showSnackbar }) => {
  const {
    params: { internal_project_template_id, section },
  } = match;
  const location = useLocation();
  const history = useHistory();
  const dragAndDropRef = useRef<DragAndDropCustomFieldsRef>(null);
  const [showPreview, setShowPreview] = useState({
    view: false,
    data: [] as ConfiguratorData[],
  });

  let currentSectionIndex;
  let FormAssistantSections;
  const isGenralForm = FormAssistantGeneralFormSections.findIndex((fs: SectionInternalProject) => fs.code === section);
  if (isGenralForm !== -1) {
    currentSectionIndex = isGenralForm;
    FormAssistantSections = FormAssistantGeneralFormSections;
  } else {
    currentSectionIndex = FormAssistantFollowUpSections.findIndex((fs: SectionInternalProject) => fs.code === section);
    FormAssistantSections = FormAssistantFollowUpSections;
  }
  const handleSave = () => {
    dragAndDropRef.current?.saveData();
  };

  const handlePreview = () => {
    if (dragAndDropRef.current) {
      const data = dragAndDropRef.current.getData();
      setShowPreview({ view: true, data: data });
    }
  };

  const handleOk = () => {
    dragAndDropRef.current?.saveData();
    history.push({
      pathname: `/configuracion/proyectos-internos/plantilla/editar/${internal_project_template_id}`,
      state: {
        generalFormId: isGenralForm !== -1 ? location.state?.formId : null,
        followUpId: isGenralForm !== -1 ? null : location.state?.formId,
      },
    });
  };

  const buttons: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      fullWidth: true,
      disableElevation: true,
      onClick: () => {
        handleSave();
        showSnackbar(tKey('Los datos se han guardado correctamente'), 'success', undefined, 1500);
      },
      color: 'primary',
      variant: 'contained',
    },
    {
      children: tKey('Ver'),
      fullWidth: true,
      disableElevation: true,
      onClick: handlePreview,
      color: 'primary',
      variant: 'contained',
    },
    {
      children: tProjectKey('OK'),
      fullWidth: true,
      disableElevation: true,
      onClick: handleOk,
      color: 'primary',
      variant: 'contained',
      hidden: currentSectionIndex !== FormAssistantSections.length - 1,
    },
  ];

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuFormsAssistant
          internalProjectTemplateId={internal_project_template_id}
          formAssistantSections={FormAssistantSections}
          currentSection={section}
          handleClickOption={handleSave}
        />
      }
      rightSubmenu={
        <ActionsMenu
          actionsButtons={buttons}
          goBack
          onGoBack={() =>
            history.push({
              pathname: `/configuracion/proyectos-internos/plantilla/editar/${internal_project_template_id}`,
              state: {
                generalFormId: isGenralForm !== -1 ? location.state?.formId : null,
                followUpId: isGenralForm !== -1 ? null : location.state?.formId,
              },
            })
          }
        />
      }
    >
      <>
        <DragAndDropCustomFields ref={dragAndDropRef} />
        {showPreview.view && (
          <CustomFieldsPreview
            view={true}
            formData={showPreview.data}
            handleClose={() => setShowPreview({ view: false, data: [] })}
          />
        )}
      </>
    </LayoutForm>
  );
};

const mapDispatchToProps = (dispatch: FeedbackAPIActionDispatch) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(null, mapDispatchToProps)(FormsAssistant);
