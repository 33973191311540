import { ButtonProps, MenuItem } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  createElementCenterCostApi,
  editElementCenterCostApi,
  findElementOfCenterCostApi,
  getCenterCostListByCodeApi,
} from '../../../api/CenterCost';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import Title from '../../../components/Title/Title';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { getGenericMetadata } from '../../../redux/metadata/actions';
import { selectAreas, selectChannels, selectDelegations, selectProjects } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { CostCenterSchema } from '../../../validations/formSchema';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './costCenter.module.scss';
import { CostCenter, CostCenterCode } from './SwitchCostCenter';

interface EditCenterCost extends RouteComponentProps<{ id: string; section: CostCenterCode }> {
  getGenericMetadata: () => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const EditCenterCost: React.FC<EditCenterCost> = ({ match, getGenericMetadata, showSnackbar }) => {
  const { handleSubmit, errors, control } = useForm<CostCenter>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: CostCenterSchema,
  });
  const {
    params: { id, section },
  } = match;

  const [costCenter, setCostCenter] = React.useState<CostCenter>({
    id: 0,
    code: '',
    name: '',
    delegation_code: null,
    channel_code: null,
    area_code: null,
  });
  const [dataSelect, setDataSelect] = React.useState<CostCenter[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const selectSection = (section: string | undefined): CostCenterCode => {
    switch (section) {
      case 'departamento':
        return 'delegacion';
      case 'delegacion':
        return 'canal';
      case 'proyecto':
        return 'departamento';
      default:
        return 'proyecto';
    }
  };

  useEffect(() => {
    setLoading(true);
    (async function fetchData() {
      const { data } = await findElementOfCenterCostApi(parseInt(id), section);
      setCostCenter(data);
      const origin_section = selectSection(section);
      if (origin_section !== 'proyecto') {
        const { data } = await getCenterCostListByCodeApi(origin_section);
        setDataSelect(data);
      }
      setLoading(false);
    })();
  }, [id, section]);

  const title = () => {
    const t_key_section = tKey(section);
    const section_name = t_key_section.charAt(0).toUpperCase() + t_key_section.slice(1);
    return id === '0'
      ? `${tKey('Creación de centro de coste')}: ${section_name}`
      : `${tKey('Edición de centro de coste')}: ${section_name}`;
  };

  const edit = async (data: CostCenter) => {
    try {
      await editElementCenterCostApi(parseInt(id), section, data);
      showSnackbar(
        tKey('Formulario actualizado correctamente'),
        'success',
        `/contabilidad/centro-coste/${section}`,
        1000,
      );
    } catch (e) {
      showSnackbar(tErrorKey('Error al actualizar el formulario'), 'error', undefined, 1000);
    }
  };

  const create = async (data: CostCenter) => {
    try {
      await createElementCenterCostApi(section, data);
      getGenericMetadata();
      showSnackbar(tKey('Recurso creado correctamente'), 'success', `/contabilidad/centro-coste/${section}`, 1000);
    } catch (e) {
      showSnackbar(tErrorKey('Error al crear el recurso'), 'error', undefined, 1000);
    }
  };

  const onSave = (data: CostCenter) => {
    id === '0' ? create(data) : edit(data);
  };

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSave),
      // onClick: () => console.log(getValues()),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];

  let center_costs_select;

  if (dataSelect.length > 0) {
    let center_cost_name = 'area_code';
    let center_cost_label =
      tKey('Departamento')
        .charAt(0)
        .toUpperCase() + 'departamento'.slice(1);
    let center_cost_value = costCenter.area_code;
    switch (section) {
      case 'departamento':
        center_cost_name = 'delegation_code';
        center_cost_label =
          tKey('Delegación')
            .charAt(0)
            .toUpperCase() + 'delegacion'.slice(1);
        center_cost_value = costCenter.delegation_code;
        break;
      case 'delegacion':
        center_cost_name = 'channel_code';
        center_cost_label =
          tKey('Canal')
            .charAt(0)
            .toUpperCase() + 'canal'.slice(1);
        center_cost_value = costCenter.channel_code;
        break;
    }

    center_costs_select = (
      <SelectController
        size="50"
        control={control}
        errors={errors}
        schema={CostCenterSchema}
        defaultValue={center_cost_value}
        name={center_cost_name}
        label={center_cost_label}
      >
        {dataSelect.map((type: CostCenter) => (
          <MenuItem key={type.code} value={type.code}>
            {type.code}
          </MenuItem>
        ))}
      </SelectController>
    );
  }
  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
      leftSubmenu={<AccountingSubmenu selected={section} submenu={tKey('Centro de coste')} />}
    >
      <Title>{title()}</Title>
      {loading ? (
        <Loading big />
      ) : (
        <form onSubmit={handleSubmit(onSave)} className={styles.form_costCenter}>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={costCenter.code}
            schema={CostCenterSchema}
            label={tFormKey('Código')}
            name="code"
            size="50"
          />
          {center_costs_select}
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={costCenter.name}
            schema={CostCenterSchema}
            label={tFormKey('Descripción')}
            name="name"
            multiline
            rows="2"
            rowsMax="3"
            fullWidth
          />
        </form>
      )}
    </LayoutForm>
  );
};

const mapStateProps = (state: AppState) => ({
  areas: selectAreas(state),
  channels: selectChannels(state),
  delegations: selectDelegations(state),
  projects: selectProjects(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  getGenericMetadata: (): void => dispatch(getGenericMetadata()),
});
export default connect(mapStateProps, mapDispatchToProps)(EditCenterCost);
