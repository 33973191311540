import AppsIcon from '@material-ui/icons/Apps';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SchoolIcon from '@material-ui/icons/School';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import Loading from '../../components/Loading/Loading';
import { permissionType } from '../../constants/permissions_definitions';
import { tKey } from '../../helpers/translate';
import { getMetadata } from '../../redux/aboutUs/actions';
import { AboutUsDispatch, AboutUsMetada } from '../../redux/aboutUs/definitions';
import { selectIsLoadingAboutUs, selectMetadata } from '../../redux/aboutUs/selectors';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';

interface SubmenuConfigurationProps {
  loading: boolean;
  metadata: AboutUsMetada;
  selected: string;
  submenu: string;
  canIconfigTranslations: boolean;
  canIconfigMailTranslations: boolean;
  userCan: (permission: permissionType) => boolean | undefined;
  getMetadata: () => void;
  onClickOption?: () => void;
}

const SubmenuConfiguration: React.FC<SubmenuConfigurationProps> = ({
  loading,
  metadata,
  selected,
  submenu,
  canIconfigTranslations,
  canIconfigMailTranslations,
  userCan,
  getMetadata,
  onClickOption,
}) => {
  const optionsMenu: SubmenuItem[] = [] as SubmenuItem[];
  const canIconfigAreas = userCan('action_areas_config');
  const canIconfigAboutUs = userCan('action_admin_about_us');
  const canIconfigEnableScholarship = userCan('action_scholarships_permissions');
  const canIconfigInternalProjects = userCan('route_config_internal_projects');
  const canIcaonfigPorjectFields = userCan('route_config_custom_fields');
  const { aboutUsTypes } = metadata;

  useEffect(() => {
    if (!aboutUsTypes || aboutUsTypes.length === 0) {
      getMetadata();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Loading />;

  if (canIconfigAreas) {
    optionsMenu.push({
      title: tKey('Gestión de areas'),
      icon: <AppsIcon />,
      type: 'route',
      route: '/configuracion/gestion-areas',
      selected: selected === '/configuracion/gestion-areas',
    });
  }
  if (canIconfigTranslations || canIconfigMailTranslations) {
    optionsMenu.push({
      title: tKey('Traducciones'),
      icon: <GTranslateIcon />,
      type: 'route',
      route: '/configuracion/traducciones',
      selected: selected === '/configuracion/traducciones',
    });
  }
  if (canIconfigEnableScholarship)
    optionsMenu.push({
      title: tKey('Becas'),
      icon: <SchoolIcon />,
      type: 'route',
      route: '/configuracion/becas',
      selected: selected === '/configuracion/becas',
    });
  if (canIcaonfigPorjectFields) {
    optionsMenu.push({
      title: tKey('Proyectos'),
      icon: <FolderIcon />,
      type: 'route',
      route: '/configuracion/proyectos',
      selected: selected === '/configuracion/proyectos',
    });
  }
  if (canIconfigInternalProjects) {
    optionsMenu.push({
      title: tKey('Proyectos internos'),
      icon: <FolderOpenIcon />,
      type: 'route',
      route: '/configuracion/proyectos-internos',
      selected: selected === '/configuracion/proyectos-internos',
    });
  }
  if (aboutUsTypes && aboutUsTypes.length > 0 && canIconfigAboutUs) {
    optionsMenu.push({
      title: tKey('Sobre Nosotros'),
      icon: <LibraryBooksIcon />,
      type: 'route',
      route: '/configuracion/sobre-nosotros',
      selected: selected === '/configuracion/sobre-nosotros',
    });
  }

  return (
    <Submenu
      titleMenu={tKey('CONFIGURACIÓN')}
      optionsMenu={optionsMenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
      onClickOption={onClickOption}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoadingAboutUs(state),
  metadata: selectMetadata(state),
  canIconfigTranslations: selectUserCan(state)('allow_translation_config'),
  canIconfigMailTranslations: selectUserCan(state)('allow_mail_translation_config'),
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: AboutUsDispatch) => ({
  getMetadata: (): void => dispatch(getMetadata()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuConfiguration);
