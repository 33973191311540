import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import requiredStyle from '../../helpers/validator.module.scss';
import styles from './comboSearch.module.scss';

export interface AutocompleteProps {
  options: any[];
  optionLabel: string;
  label: string;
  size?: string;
  control: any;
  fieldToSend: string;
  error?: FieldError | any;
  name: string;
  disabled?: boolean;
  fullWidth?: boolean;
  validator?: boolean;
  defaultValue?: string;
  setValue?: any;
  onChange: (e: any) => void;
}

const ComboClick: React.FC<AutocompleteProps> = ({
  label,
  size = '100',
  options,
  control,
  error,
  disabled,
  optionLabel,
  fieldToSend,
  name,
  fullWidth,
  validator,
  defaultValue,
  setValue,
  onChange,
}) => {
  React.useEffect(() => {
    setValue && setValue(name, defaultValue);
  }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={fullWidth ? styles.comboContainerFull : styles.comboContainer}>
      <Controller
        as={
          <Autocomplete
            id="combo-box"
            disableCloseOnSelect
            disabled={disabled}
            options={options}
            getOptionLabel={(option: any) => option[optionLabel]}
            defaultValue={
              defaultValue &&
              options.find(
                option =>
                  option[optionLabel]?.toLocaleLowerCase() === defaultValue?.toLocaleLowerCase() ||
                  option[fieldToSend] === defaultValue,
              )
            }
            classes={{
              root: styles.autocomplete,
              inputRoot: validator ? requiredStyle.requiredAutocomplete : styles.input,
            }}
            renderInput={params => (
              <TextField
                {...params}
                style={{ width: `${size}%` }}
                label={label}
                error={!!error}
                helperText={error?.message}
                variant="outlined"
              />
            )}
          />
        }
        onChange={([event, data]) => onChange(data)}
        name={name}
        control={control}
      />
    </div>
  );
};

export default ComboClick;
