import assertUnreachable from '../../../helpers/assertUnreachable';
import { Educator } from './Educator';
import { EducatorConst } from './action_types';
import { EducatorAction, EducatorState, initialState } from './definition.d';

const educatorReducer = (state = initialState, action: EducatorAction): EducatorState => {
  switch (action.type) {
    case EducatorConst.ADD_EDUCATOR:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EducatorConst.ADD_EDUCATOR_OK: {
      const { educator } = action;
      state.data.push(educator);
      return {
        ...state,
        loading: false,
      };
    }
    case EducatorConst.ADD_EDUCATOR_KO:
      return {
        ...state,
        loading: false,
      };
    case EducatorConst.GET_EDUCATORS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EducatorConst.GET_EDUCATORS_OK: {
      const { data } = action;

      return {
        ...state,
        loading: false,
        error: null,
        data,
      };
    }
    case EducatorConst.GET_EDUCATORS_KO: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case EducatorConst.DELETE_EDUCATORS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EducatorConst.DELETE_EDUCATORS_OK:
      const data = state.data.filter(
        (educator: Educator) =>
          educator.id !== action.educatorId && educator.internal_project_id === action.internal_project_id,
      );
      return {
        ...state,
        loading: false,
        error: null,
        data,
      };
    case EducatorConst.DELETE_EDUCATORS_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    case EducatorConst.UPDATE_EDUCATOR:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EducatorConst.UPDATE_EDUCATOR_OK: {
      const { updatedEducator } = action;

      const educators = state.data.map(educator => {
        if (
          educator.internal_project_id === updatedEducator.internal_project_id &&
          educator.id === updatedEducator.id
        ) {
          educator.user_name = updatedEducator.user_name;
          educator.email = updatedEducator.email;
        }
        return { ...educator };
      });
      return {
        ...state,
        loading: false,
        data: educators,
      };
    }
    case EducatorConst.UPDATE_EDUCATOR_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default educatorReducer;
