/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import { getOneScholarship } from '../../../api/Scholarship/scholarship';
import {
  changeStateFromPendingJustifyFromApi,
  leaveScholarshipFromApi,
  scholarshipStateTransitionFromApi,
} from '../../../api/Scholarship/transitions';
import { APICustomMessageError, Await } from '../../../api/api';
import errorMessage from '../../../helpers/errorMessage';
import { showSnackbar } from '../../FeedbackAPI/actions';
import { getNotificationsUpdate } from '../../notification/actions';
import { scholarshipGetOk } from '../actions';
import { ScholarshipTransitionsConst } from './action_types';
import {
  leaveScholarshipOk,
  pendingJustificationTransitionOkAction,
  scholarshipStateTransitionOkAction,
  scholarshipTransitionKoAction,
} from './actions';
import {
  LeaveScholarshipAction,
  PendingJustificationTransitionAction,
  ScholarshipStateTransitionAction,
  ScholarshipTransitionState,
} from './definitions';

const transitionMessage = (stateData: ScholarshipTransitionState, remainder_amount?: number) => {
  const message = `Beca en estado ${stateData.state_name}`;
  if (!!remainder_amount) {
    return message + `. La beca ha generado un remanente de ${remainder_amount?.toLocaleString()} €`;
  }
  return message;
};

const messageChangeState = (state: string) => {
  switch (state) {
    case 's01s02':
    case 's12s24':
    case 's13s25':
    case 's14s26':
    case 's15s22':
    case 's16s23':
    case 'to_admin_review':
      return 'enviada';
    case 's02s03':
      return 'aceptada';
    case 's02s01':
      return 'rechazada';
    case 's02s07':
      return 'desestimada';
    case 's04s05':
      return 'entrevistada';
    case 's05s06':
      return 'aprobada';
    case 's05s07':
      return 'desestimada';
    case 's05s08':
      return 'valorada';
    case 's08s07':
      return 'desestimada';
    case 's08s06':
      return 'aprstateData: anyobada';
    case 's08s09':
      return 'contestada, se necesitan corregir campos';
    case 's09s08':
      return 'corregida';
    case 's06s10':
      return 'confirmada y/o asignada';
    case 's11s17':
      return 'abandonada';
    case 's11s18':
    case 'renounce':
      return 'renunciada';
    case 'abandonment':
      return 'abandonada';
    case 'close':
    case 's11s19':
      return 'cerrada';
    case 'reject_pending_justify':
      return 'pendiente de justificar';
    case 'pending_justify_reject':
      return 'justificada';
    case 'admin_review_confirmation':
      return 'confirmada';
  }
};

function* scholarshipStateTransitionFromSaga(action: ScholarshipStateTransitionAction): Generator<any, void, any> {
  try {
    const { id, states, redirect } = action;

    // if (states === 's06s10') {
    //   yield put(
    //     showSnackbar(
    //       `En esto momentos no es posible confirmar la beca. El periodo de confirmación está cerrado.`,
    //       'warning',
    //       undefined,
    //       5000,
    //     ),
    //   );
    // } else {
    yield scholarshipStateTransitionFromApi(id, states, action?.data);
    const { data } = (yield getOneScholarship(action.id)) as Await<ReturnType<typeof getOneScholarship>>;
    yield put(scholarshipGetOk(data, null));
    yield put(getNotificationsUpdate());
    yield put(scholarshipStateTransitionOkAction(states));
    const msg =
      states !== 'reject_pending_justify'
        ? `La beca ha sido ${messageChangeState(states)}`
        : `La beca ha pasado al estado ${messageChangeState(states)} ya que los importes deben ser justificados`;
    yield put(showSnackbar(`${msg}.`, 'success', redirect, 1500));
    // }
  } catch (e) {
    if (e instanceof APICustomMessageError) {
      yield put(showSnackbar(e.message, 'error', undefined, 3000));
    } else {
      yield put(showSnackbar('Ha ocurrido algún problema', 'error', undefined, 1500));
    }
    yield put(scholarshipTransitionKoAction(errorMessage(e)));
  }
}

function* pendingJustificationTransitionFromSaga(
  action: PendingJustificationTransitionAction,
): Generator<any, void, any> {
  try {
    const { scholarship_id, remainder_amount, remainder_remarks } = action;
    const stateData = yield changeStateFromPendingJustifyFromApi(scholarship_id, remainder_amount, remainder_remarks);
    yield put(pendingJustificationTransitionOkAction(stateData));
    if (remainder_amount > 0) {
    }
    yield put(showSnackbar(transitionMessage(stateData, remainder_amount), 'success', '', 3000));
  } catch (e) {
    yield put(scholarshipTransitionKoAction(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 3000));
  }
}

function* leaveScholarshipTransitionFromSaga(action: LeaveScholarshipAction): Generator<any, void, any> {
  const timeout_miliseconds = 3000;
  try {
    const { id, redirect, leaveMode } = action;
    const stateData = yield leaveScholarshipFromApi(id, leaveMode, action?.data);
    yield put(leaveScholarshipOk(stateData));
    yield put(showSnackbar(transitionMessage(stateData), 'success', redirect, timeout_miliseconds));
  } catch (e) {
    yield put(scholarshipTransitionKoAction(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, timeout_miliseconds));
  }
}

export default [
  takeLatest<ScholarshipStateTransitionAction>(
    ScholarshipTransitionsConst.SCHOLARSHIP_STATE_TRANSITION,
    scholarshipStateTransitionFromSaga,
  ),
  takeLatest<PendingJustificationTransitionAction>(
    ScholarshipTransitionsConst.SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION,
    pendingJustificationTransitionFromSaga,
  ),
  takeLatest<LeaveScholarshipAction>(ScholarshipTransitionsConst.SCHOLARSHIP_LEAVE, leaveScholarshipTransitionFromSaga),
];
