import { ButtonProps } from '@material-ui/core';
import { useEffect, useState } from 'react';

export const useHookMethod = (buttons: ButtonProps[], keys: any[], cb: any[]) => {
  if (buttons.length) {
    keys.forEach((key, indexKey) => {
      const index = buttons.findIndex(button => button.key === key);
      if (index !== -1) {
        buttons[index].onClick = cb[indexKey];
      }
    });
  }
};

export const useButtonAction = (buttonAction: ButtonProps | undefined, action: any, params?: ButtonProps) => {
  if (buttonAction) {
    buttonAction.onClick = action;

    if (params) {
      for (const key in params) {
        buttonAction[key as keyof ButtonProps] = params[key as keyof ButtonProps];
      }
    }
  }
};

export const useButtonActions = (buttonAction: ButtonProps | undefined, action: any, params?: ButtonProps) => {
  if (buttonAction) {
    if (buttonAction.onClick) {
      const existingOnClick = buttonAction.onClick;
      buttonAction.onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        existingOnClick(event);
        action(event);
      };
    } else {
      buttonAction.onClick = action;
    }

    if (params) {
      for (const key in params) {
        buttonAction[key as keyof ButtonProps] = params[key as keyof ButtonProps];
      }
    }
  }
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: undefined | number;
    height: undefined | number;
    IsMobile: boolean;
  }>({
    width: undefined,
    height: undefined,
    IsMobile: false,
  });
  useEffect(() => {
    function handleResize() {
      let mobile = false;
      if (window.innerWidth < 1024) {
        mobile = true;
      }

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        IsMobile: mobile,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
