import { put, takeLatest } from '@redux-saga/core/effects';
import {
  getParticipantsWithAssistance,
  updateParticipantsAssistanceApi,
} from '../../../api/InternalProject/Participants/getParticipants';
import errorMessage from '../../../helpers/errorMessage';
import { showSnackbar } from '../../FeedbackAPI/actions';
import {
  getParticipantAssistanceKo,
  getParticipantAssistanceOk,
  updateParticipantAssistanceKo,
  updateParticipantAssistanceOk,
} from './action';
import { GetParticipantsAssistanceAction, UpdateParticipantsAssistanceAction } from './definitions';
import { ParticipantsAssistanceConst } from './action_types';

function* getParticipantsAssistanceFromSaga(action: GetParticipantsAssistanceAction): Generator<any, void, any> {
  try {
    const assistants = yield getParticipantsWithAssistance(action.followUpId);
    yield put(getParticipantAssistanceOk(assistants.data));
  } catch (e) {
    const errorText = errorMessage(e);
    yield put(getParticipantAssistanceKo(errorText));
    yield put(showSnackbar(errorText, 'error', undefined, 1500));
  }
}

function* updateParticipantsAssistance(action: UpdateParticipantsAssistanceAction): Generator<any, void, any> {
  try {
    yield updateParticipantsAssistanceApi(action.infoUpdate);
    yield put(updateParticipantAssistanceOk(action.infoUpdate));
    yield put(showSnackbar('Asistencia actualizada', 'success', undefined, 3000));
  } catch (e) {
    const errorText = errorMessage(e);
    yield put(updateParticipantAssistanceKo(errorText));
    yield put(showSnackbar(errorText, 'error', undefined, 1500));
  }
}

export default [
  takeLatest<GetParticipantsAssistanceAction>(
    ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE,
    getParticipantsAssistanceFromSaga,
  ),
  takeLatest<UpdateParticipantsAssistanceAction>(
    ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE,
    updateParticipantsAssistance,
  ),
];
