import { Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import React from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { unixToDatetime } from '../../../helpers/dateHelper';
import { tProjectKey } from '../../../helpers/translate';
import { changeCreateModalVisible, changeEditDeleteModalVisible } from '../../../redux/project/action';
import { FollowUp, ProjectDispatch } from '../../../redux/project/definitions';
import { selectEditDeleteModalView, selectLoadingListFollowUp } from '../../../redux/project/selectors';
import { AppState } from '../../../redux/root-reducer';
import CreateEditFollowUp from './CreateEditFollowUp';
import styles from './followUpList.module.scss';

interface FollowUpListProps {
  followUpsList: FollowUp[];
  loading: boolean;
  editDeleteView: boolean;
  changeCreateVisible: (createView: boolean) => void;
  changeEditDeleteVisible: (editDelteView: boolean) => void;
}

const FollowUpList: React.FC<FollowUpListProps> = ({
  followUpsList,
  loading,
  changeEditDeleteVisible,
  editDeleteView,
  changeCreateVisible,
}) => {
  const [editModal, setEditModal] = React.useState({
    view: editDeleteView,
    children: <CreateEditFollowUp projectID={0} />,
    title: tProjectKey('Nueva Nota de Seguimiento'),
  });
  const itemSubst = (string: string, length: number) => {
    return string.length > length ? string.substring(0, length) + '...' : string;
  };

  const sendMeTo = (item: FollowUp) => {
    if (item) {
      setEditModal(prevState => ({
        ...prevState,
        title: tProjectKey('Nota de Seguimiento'),
        children: <CreateEditFollowUp editMode followUpSelected={item} createdId={item.id} />,
      }));
      changeEditDeleteVisible(true);
    }
  };

  const tableData = {
    data: followUpsList,
    title: '',
    columns: [
      {
        field: 'created_at',
        title: tProjectKey('Fecha'),
        render: (rowData: FollowUp) => <div>{unixToDatetime(rowData.created_at)}</div>,
      },
      { field: 'full_name', title: tProjectKey('Subido por') },
      {
        field: 'title',
        title: tProjectKey('Título'),
        render: (rowData: FollowUp) => <div>{itemSubst(rowData.title, 20)}</div>,
      },
      {
        field: 'description',
        title: tProjectKey('Descripcion'),
        render: (rowData: FollowUp) => <div>{itemSubst(rowData.description, 20)}</div>,
      },
      {
        field: 'documents',
        title: tProjectKey('Adjuntos'),
        render: (rowData: FollowUp) =>
          rowData.documents.length > 0 ? (
            <div style={{ textAlign: 'center', width: '50%' }}>
              <DescriptionIcon />
            </div>
          ) : (
            <span>{tProjectKey('No hay archivos')}</span>
          ),
      },
    ],
  };

  return (
    <div className={styles.followUpListContainer}>
      {loading && <Loading big />}
      {!loading && (
        <React.Fragment>
          <div className={styles.followUpInfo}>
            <span className={styles.followUpText}>
              {tProjectKey(`Notas de seguimiento disponibles`)}: {followUpsList.length}
            </span>
            <Button variant="outlined" color="primary" onClick={() => changeCreateVisible(true)}>
              {tProjectKey('Nueva Nota')}
            </Button>
          </div>
          <TableWithEdit noSearch tableData={tableData} onRowClick={row => sendMeTo(row)} />
        </React.Fragment>
      )}
      <TransitionModal
        view={editDeleteView}
        handleClose={() => changeEditDeleteVisible(false)}
        title={editModal.title}
        bodyModal=" "
      >
        {editModal.children}
      </TransitionModal>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoadingListFollowUp(state),
  editDeleteView: selectEditDeleteModalView(state),
});
const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  changeCreateVisible: (createView: boolean): void => dispatch(changeCreateModalVisible(createView)),
  changeEditDeleteVisible: (editDelteView: boolean): void => dispatch(changeEditDeleteModalVisible(editDelteView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpList);
