import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import { permissionType } from '../constants/permissions_definitions';
import { AppRoute } from '../constants/routes';
import checkUrl from '../helpers/checkUrl';
import filteredRoutes from '../helpers/filteredRoutes';
import {
  selectIsActivating,
  selectIsInitializing,
  selectIsLoading,
  selectIsLoggedIn,
  selectUserState,
} from '../redux/auth/selectors';
import { getNotificationsUpdate } from '../redux/notification/actions';
import { selectLoadingPermissionsList, selectPermissionsList } from '../redux/permissions/selectors';
import { AppState } from '../redux/root-reducer';
import routes from '../routes';

const NO_REDIRECT = 0;
const REDIRECT_LOGGED = 1;
const REDIRECT_NO_LOGGED = 2;
const LOCK_TO_CREATING_USER = 3;
const LOCK_TO_CREATING_ENTITY = 4;
const LOCK_TO_CREATING_EDUCATOR = 5;
const REDIRECT_TYPEFORM = 6;
const REDIRECT_LOPD = 7;

const redirector = (where: number) => {
  if (where === REDIRECT_LOGGED) return <Redirect to="/home" />;
  if (where === REDIRECT_NO_LOGGED) return <Redirect to="/" />;
  if (where === LOCK_TO_CREATING_USER) return <Redirect to="/registro-datos" />;
  if (where === LOCK_TO_CREATING_ENTITY) return <Redirect to="/entidad/solicitud" />;
  if (where === LOCK_TO_CREATING_EDUCATOR) return <Redirect to="/registro-educador" />;
  if (where === REDIRECT_LOPD) return <Redirect to="/lopd-terms" />;
  if (where === REDIRECT_TYPEFORM) return <Redirect to={window.location} />;
};

interface AppProps {
  permissions?: permissionType[];
  loadingPermissions?: boolean;
  userState?: string;
  activating?: boolean;
  loadingAuth?: boolean;
  initializing?: boolean;
  loggedIn?: boolean;
}

const App: React.FC<AppProps> = ({
  permissions,
  loadingPermissions,
  userState,
  loadingAuth,
  activating,
  initializing,
  loggedIn,
}) => {
  const [redirect_destination, setRedirectDestination] = useState(NO_REDIRECT);

  useEffect(() => {
    if (initializing) return;
    if (loadingPermissions || activating || loadingAuth) {
      setRedirectDestination(NO_REDIRECT);
      return;
    }
    let destination = NO_REDIRECT;
    const path = window.location.pathname;
    const loggedInLocal = loggedIn || !!(permissions && permissions.length > 0);

    if (userState === 'CREATING_REGULAR') {
      setRedirectDestination(LOCK_TO_CREATING_USER);
      return;
    }

    if (userState === 'CREATING_ENTITY') {
      setRedirectDestination(LOCK_TO_CREATING_ENTITY);
      return;
    }

    if (userState === 'CREATING_EDUCATOR') {
      setRedirectDestination(LOCK_TO_CREATING_EDUCATOR);
      return;
    }

    if (userState === 'DISABLED') {
      setRedirectDestination(REDIRECT_NO_LOGGED);
      return;
    }

    if (userState === 'ACCEPTING_LOPD') {
      setRedirectDestination(REDIRECT_LOPD);
      return;
    }

    const routeToAccess = routes.find((r: AppRoute) => checkUrl(r.path, path));

    if (routeToAccess === undefined) {
      destination = loggedInLocal ? REDIRECT_LOGGED : REDIRECT_NO_LOGGED;
      setRedirectDestination(destination);
    } else {
      if (routeToAccess.name === 'event-typeform-auth' || routeToAccess.name === 'event-login') {
        setRedirectDestination(REDIRECT_TYPEFORM);
        return;
      }
      if (!!routeToAccess.fullAccess) {
        setRedirectDestination(NO_REDIRECT);
        return;
      }
      if (!!routeToAccess.anonymousAccess === loggedInLocal) {
        destination = loggedInLocal ? REDIRECT_LOGGED : REDIRECT_NO_LOGGED;
        setRedirectDestination(destination);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializing, loadingPermissions, activating, loadingAuth]);

  if (initializing) return <Loading big />;
  return (
    <React.Fragment>
      {redirect_destination !== NO_REDIRECT && redirector(redirect_destination)}
      {filteredRoutes(permissions).map((r: AppRoute) => (
        <Route key={r.path} exact {...r} />
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
  loadingPermissions: selectLoadingPermissionsList(state),
  userState: selectUserState(state),
  activating: selectIsActivating(state),
  loadingAuth: selectIsLoading(state),
  loggedIn: selectIsLoggedIn(state),
  initializing: selectIsInitializing(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getNotifications: (): void => dispatch(getNotificationsUpdate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
