import { Tooltip } from '@material-ui/core';
import { CalendarToday, CheckCircle, Payment, RadioButtonUnchecked } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import { MaterialTableProps } from 'material-table';
import React, { CSSProperties, Suspense, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getScholarshipPaymentSettings } from '../../../api/Scholarship/scholarship';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { formatNumber } from '../../../helpers/formatNumber';
import { tFormKey, tKey, tScholarshipKey } from '../../../helpers/translate';
import { PaymentInfo } from '../../../redux/project/definitions';
import { ChangeDateValues, ScholarshipPayment, ScholarshipReloadData } from '../../../redux/scholarship/definitions';
import useScholarshipChangeDate from './hooks/useScholarshipChangeDate';
import useScholarshipPaymentInfo from './hooks/useScholarshipPaymentInfo';
import useScholarshipReloadInfo from './hooks/useScholarshipReloadInfo';
import PaymentType from '../../../types/Accounting/Scholarship/PaymentType';
import {
  changeScholarshipPaymentDate,
  sendScholarshipPaymentAction,
  sendScholarshipReloadAction,
  sendScholarshipStudentTransferAction,
} from '../../../redux/scholarship/institutePayments/actions';

const ModalDatePicker = React.lazy(() => import('../../../components/Modal/ModalDatePicker/ModalDatePicker'));
const PaymentModal = React.lazy(() => import('../../Project/Modal/PaymentModal/PaymentModal'));
const RechargeScholarshipModal = React.lazy(() =>
  import('../../Scholarship/formLayouts/institutRegistration/RechargeScholarshipModal'),
);
const PaymentAdjustmentFormModal = React.lazy(() => import('../../Scholarship/Modals/PaymentAdjustmentFormModal'));

interface ScholarshipsPaymentListProps {
  loading: boolean;
  title: string;
  isEntity: boolean;
  scholarshipPaymentList: ScholarshipPayment[];
  sendScholarshipReload: (reloadData: ScholarshipReloadData) => void;
  sendScholarshipPayment: (paymentData: PaymentInfo) => void;
  sendScholarshipStudentTransfer: (paymentData: PaymentInfo) => void;
  changePaymentDate: (changeDateValues: ChangeDateValues) => void;
}

const ScholarshipsPaymentList: React.FC<ScholarshipsPaymentListProps> = ({
  loading,
  title,
  isEntity,
  scholarshipPaymentList,
  sendScholarshipReload,
  sendScholarshipPayment,
  sendScholarshipStudentTransfer,
  changePaymentDate,
}) => {
  const history = useHistory();

  const [scholarshipIdToAdjust, setScholarshipIdToAdjust] = useState<number>(0);

  const { showDateModal, changeDateData, closeDateModal, setChangeDateInfo } = useScholarshipChangeDate();
  const {
    showPaymentModal,
    paymentData,
    isStudentTransfer,
    closePaymentModal,
    setPaymentInfo,
  } = useScholarshipPaymentInfo();
  const { showReloadModal, reloadData, closeReloadModal, setReloadInfo } = useScholarshipReloadInfo();

  const disabledPaidCell: CSSProperties = {
    color: '#00800074',
  };

  const disabledCell: CSSProperties = {
    color: '#00000074',
  };

  const paidCell: CSSProperties = {
    color: 'green',
  };

  const tableIcons = {
    Paid: () => <CheckCircle style={{ color: 'green' }} />,
    NoPaid: () => <RadioButtonUnchecked />,
    Pay: () => <Payment style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    PayDisabled: () => <Payment style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    Date: () => <CalendarToday style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    DateDisabled: () => <CalendarToday style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    Adjust: () => <SettingsIcon style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    AdjustDisabled: () => <SettingsIcon style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  };

  const selectScholarshipCellCSS = (paid: boolean, disabled: boolean) => {
    if (paid && disabled) {
      return disabledPaidCell;
    }
    if (paid) {
      return paidCell;
    }
    if (disabled) {
      return disabledCell;
    }
    return { color: 'black' } as CSSProperties;
  };

  const doScholarshipReload = (rowData: ScholarshipPayment) => {
    setReloadInfo(rowData);
  };

  const doScholarshipPayment = async (rowData: ScholarshipPayment) => {
    const payemntSettings = await getScholarshipPaymentSettings(rowData.agreement_number);
    setPaymentInfo(rowData, payemntSettings);
  };

  const onPayClickAction = (rowData: ScholarshipPayment) => {
    const paymentType = new PaymentType(rowData.payment_type);
    if (rowData.disabled) {
      return;
    }
    if (paymentType.isRecarga()) {
      doScholarshipReload(rowData);
      return;
    }
    doScholarshipPayment(rowData);
  };

  const onAdjustmentClickAction = (rowData: ScholarshipPayment) => {
    if (rowData.disabled) {
      return;
    }
    setScholarshipIdToAdjust(rowData.scholarship_id);
  };

  const rowDataActive = (rowData: ScholarshipPayment): boolean => !rowData.disabled && !rowData.paid;
  const onDateClickAction = (rowData: ScholarshipPayment) => {
    if (!rowDataActive(rowData)) {
      return;
    }
    setChangeDateInfo(rowData, isEntity);
  };

  const tableActions = [
    (rowData: ScholarshipPayment) => ({
      icon: rowDataActive(rowData) ? tableIcons.Date : tableIcons.DateDisabled,
      tooltip: tFormKey('Modificar fecha de pago'),
      onClick: () => onDateClickAction(rowData),
    }),
    (rowData: ScholarshipPayment) => ({
      icon: rowDataActive(rowData) ? tableIcons.Adjust : tableIcons.AdjustDisabled,
      tooltip: tScholarshipKey('Ajuste de pagos'),
      onClick: () => (rowDataActive(rowData) ? onAdjustmentClickAction(rowData) : undefined),
    }),
  ];

  if (!isEntity) {
    tableActions.push((rowData: ScholarshipPayment) => ({
      icon: rowDataActive(rowData) ? tableIcons.Pay : tableIcons.PayDisabled,
      hidden: isEntity,
      tooltip: tFormKey('Pagar'),
      onClick: () => (rowDataActive(rowData) ? onPayClickAction(rowData) : undefined),
    }));
  }

  let columnType;
  let columnInfo;

  if (isEntity) {
    columnType = {
      field: 'scholarship_type',
      title: tFormKey('Tipo'),
      headerStyle: { width: '100px', maxWidth: '100px' },
      cellStyle: { width: '100px', maxWidth: '100px' },
      render: (rowData: ScholarshipPayment) => (
        <Tooltip title={rowData.scholarship_type}>
          <span>{rowData.scholarship_type}</span>
        </Tooltip>
      ),
    };
    columnInfo = {
      field: 'entity_name',
      title: tKey('Entidad'),
      headerStyle: { width: '160px', maxWidth: '160px' },
      cellStyle: { width: '160px', maxWidth: '160px' },
      render: (rowData: ScholarshipPayment) => (
        <Tooltip title={rowData.entity_name}>
          <span>{rowData.entity_name}</span>
        </Tooltip>
      ),
    };
  } else {
    columnType = {
      field: 'payment_type',
      title: tFormKey('Tipo'),
      headerStyle: { textAlign: 'center' },
      cellStyle: { textAlign: 'center' },
      //@ts-ignore
      width: '60px',
    };
    columnInfo = {
      field: 'account_number',
      title: tKey('IBAN / Tarjeta'),
      headerStyle: { width: '180px', maxWidth: '180px' },
      cellStyle: { width: '180px', maxWidth: '180px' },
    };
  }

  const tableData: MaterialTableProps<ScholarshipPayment> = {
    title: tKey(title),
    columns: [
      {
        field: 'paid',
        title: tFormKey('Pagado'),
        headerStyle: { textAlign: 'center', width: '70px', maxWidth: '70px' },
        cellStyle: { textAlign: 'center', width: '70px', maxWidth: '70px' },
        render: (rowData: ScholarshipPayment) => (rowData.paid ? tableIcons.Paid() : tableIcons.NoPaid()),
      },
      {
        field: 'student_name',
        title: tKey('Joven'),
        cellStyle: (data: ScholarshipPayment[], rowData: ScholarshipPayment) =>
          selectScholarshipCellCSS(rowData.paid, rowData.disabled),
        render: (rowData: ScholarshipPayment) => (
          <Tooltip title={rowData.disabled_reason}>
            <span>{rowData.student_name}</span>
          </Tooltip>
        ),
        //@ts-ignore
        width: '220px',
      },
      {
        field: 'payment_date',
        title: tKey('Fecha'),
        headerStyle: { textAlign: 'center' },
        cellStyle: { textAlign: 'center' },
        //@ts-ignore
        width: '90px',
      },
      {
        field: 'payment_amount',
        title: tFormKey('Importe'),
        headerStyle: { textAlign: 'center', maxWidth: '90px' },
        cellStyle: { textAlign: 'right', maxWidth: '90px' },
        render: (rowData: ScholarshipPayment) => <div>{formatNumber({ value: rowData.payment_amount }) + ' €'}</div>,
        //@ts-ignore
        width: '90px',
      },
      columnType,
      {
        field: 'scholarship_state',
        title: tKey('Estado'),
        headerStyle: { width: '100px', maxWidth: '100px' },
        cellStyle: { width: '100px', maxWidth: '100px' },
        render: (rowData: ScholarshipPayment) => (
          <Tooltip title={rowData.scholarship_state}>
            <span>{rowData.scholarship_state}</span>
          </Tooltip>
        ),
      },
      columnInfo,
    ],
    actions: tableActions,
    options: {
      tableLayout: 'auto',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
  };
  tableData.data = scholarshipPaymentList;
  tableData.isLoading = loading;

  const goToScholarship = (rowData: ScholarshipPayment) => {
    const { scholarship_id } = rowData;
    const scholarship_type = isEntity ? 'entity' : 'institute';
    history.push({
      pathname: `/becas/${scholarship_type}/detalle/${scholarship_id}`,
    });
  };

  const onSubmitRecharge = async (formData: ScholarshipReloadData) => {
    sendScholarshipReload(formData);
  };

  const onSubmitPayment = async (formData: PaymentInfo) => {
    isStudentTransfer ? sendScholarshipStudentTransfer(formData) : sendScholarshipPayment(formData);
  };

  const onSubmitChangeDate = async (date: string) => {
    changePaymentDate({ ...changeDateData, payment_date: date });
    closeDateModal();
  };

  const closeAdjustmentModal = async () => {
    setScholarshipIdToAdjust(0);
  };

  return (
    <>
      <TableWithEdit
        onRowClick={goToScholarship}
        tableData={{ ...tableData }}
        filtering
        key={JSON.stringify(tableData.data)}
        permission={true}
      />
      <Suspense fallback={<></>}>
        {showDateModal ? (
          <ModalDatePicker
            view={showDateModal}
            handleClose={() => closeDateModal()}
            label={tKey('Nueva fecha de pago')}
            title={tKey('Modificar fecha de pago')}
            buttonLabelOk={tKey('Ok')}
            buttonLabelKo={tKey('Cancelar')}
            onApplying={onSubmitChangeDate}
            required
          />
        ) : null}
        {scholarshipIdToAdjust !== 0 ? (
          <PaymentAdjustmentFormModal
            open={scholarshipIdToAdjust !== 0}
            handleClose={() => closeAdjustmentModal()}
            scholarship_id={scholarshipIdToAdjust}
            isInstitute={!isEntity}
          />
        ) : null}
        {showReloadModal ? (
          <RechargeScholarshipModal
            open={showReloadModal}
            handleClose={() => closeReloadModal()}
            data={reloadData}
            onSubmit={onSubmitRecharge}
          />
        ) : null}
        {showPaymentModal ? (
          <PaymentModal
            view={showPaymentModal}
            onClose={() => closePaymentModal()}
            onSubmit={onSubmitPayment}
            inputsData={paymentData}
          />
        ) : null}
      </Suspense>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  sendScholarshipReload: (reloadData: ScholarshipReloadData): void => dispatch(sendScholarshipReloadAction(reloadData)),
  sendScholarshipPayment: (paymentData: PaymentInfo): void => dispatch(sendScholarshipPaymentAction(paymentData)),
  sendScholarshipStudentTransfer: (paymentData: PaymentInfo): void =>
    dispatch(sendScholarshipStudentTransferAction(paymentData)),
  changePaymentDate: (changeDateValues: ChangeDateValues): void =>
    dispatch(changeScholarshipPaymentDate(changeDateValues)),
});

export default connect(undefined, mapDispatchToProps)(ScholarshipsPaymentList);
