import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import styles from './newParticipant.module.scss';
import { Button } from '@material-ui/core';
import { ParticipantParticularitiesType } from '../../../../../../../../redux/internal-projects/participants/particularities/definitions';
import { Participant } from '../../../../../../../../redux/internal-projects/participants/Participant';
import { ViewModelType } from './NewEditParticipantViewModel';
import { NewParticipantSchema } from '../../../../../../../../validations/formSchema';
import { generateUuid } from '../../../../../../../../helpers/uuidGenerator';
import GenericMultiSelectController, {
  OptionsGenericMultiselect,
} from '../../../../../../../../components/Select/GenericMultiSelectController/GenericMultiSelectController';
import TransitionModal from '../../../../../../../../components/Modal/TransitionModal';
import { tKey } from '../../../../../../../../helpers/translate';
import TextInputController from '../../../../../../../../components/Inputs/TextInputController/TextInputController';
import FormContainer from '../../../../../../../../components/Forms/FormContainer';
import CheckboxController from '../../../../../../../../components/Checkbox/CheckboxController';
import ButtonGroup from '../../../../../../../../components/ButtonGroup/ButtonGroup';
import { AppState } from '../../../../../../../../redux/root-reducer';
import { selectParticipantParticularitiesList } from '../../../../../../../../redux/internal-projects/participants/particularities/selectors';
import { getParticipantParticularities } from '../../../../../../../../redux/internal-projects/participants/particularities/actions';

interface Props {
  internal_project_id: number;
  showModal: boolean;
  onCloseModal: () => void;
  onAddNewParticipant: () => void;
  particularities: ParticipantParticularitiesType[];
  getParticularities: () => void;
  participant: Participant | null;
  viewModel: ViewModelType;
}

const NewParticipant = ({
  internal_project_id,
  showModal,
  onCloseModal,
  onAddNewParticipant,
  particularities,
  getParticularities,
  participant,
  viewModel,
}: Props) => {
  const { save, update } = viewModel();

  useEffect(() => {
    getParticularities();
  }, [getParticularities]);

  const { control, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: NewParticipantSchema,
  });
  const onSubmit = (data: any) => {
    participant
      ? update(participant.id, { ...data, internal_project_id })
      : save({ ...data, id: generateUuid(), internal_project_id });
    onAddNewParticipant();
  };

  const optionsParticularities: OptionsGenericMultiselect[] = particularities.map(particularity => {
    return {
      id: particularity,
      name: particularity,
      value: particularity,
      checked: false,
    } as OptionsGenericMultiselect;
  });

  return (
    <>
      <TransitionModal
        view={showModal}
        handleClose={onCloseModal}
        title={participant ? tKey('Modificar participante') : tKey('Añadir participante')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer title={''}>
            <div className={styles.selectors}>
              <TextInputController
                label={tKey('Nombre adolescente')}
                name="user_name"
                schema={NewParticipantSchema}
                errors={errors}
                control={control}
                defaultValue={participant?.user_name}
              />
              <GenericMultiSelectController
                label={tKey('Particularidades')}
                name={'particularities'}
                options={optionsParticularities}
                control={control}
                errors={errors}
                required
                defaultValue={participant?.particularities ? participant?.particularities.split(',') : []}
              />
            </div>
            <CheckboxController control={control} name={'lopd'} defaultValue={!!participant?.lopd} label={'LOPD'} />
            <div className={styles.textarea}>
              <TextInputController
                label={tKey('Otros')}
                name="additional_info"
                rowsMax={6}
                rows={6}
                fullWidth
                schema={NewParticipantSchema}
                errors={errors}
                control={control}
                multiline
                defaultValue={participant?.additional_info}
              />
            </div>
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  {participant ? tKey('Modificar') : tKey('Añadir')}
                </Button>
              </ButtonGroup>
            </div>
          </FormContainer>
        </form>
      </TransitionModal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  particularities: selectParticipantParticularitiesList(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getParticularities: () => dispatch(getParticipantParticularities()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewParticipant);
