import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { APIError } from '../../api/api';
import { userActivationStart } from '../../redux/auth/actions';
import { selectError, selectIsLoading, selectIsLoggedIn, selectToken } from '../../redux/auth/selectors';
import { AppState } from '../../redux/root-reducer';
export interface LoginFields {
  email: string;
  password: string;
}

interface LoginFormProps {
  error: APIError | null;
  login: (token: string) => void;
  loading: boolean;
  match: any;
  loggedIn: boolean;
}
const AuthThroughMail: React.FC<LoginFormProps> = ({ login, match, loggedIn }) => {
  const history = useHistory();

  const redirectMe = (id: number, url: string) => {
    history.push({
      pathname: `/${url}`,
      state: { id: id, redirect: '/home' },
    });
  };

  useEffect(() => {
    const rememberToken = match.url.split('&');
    const url = rememberToken[0].split('/');
    if (loggedIn === false) {
      login(rememberToken[1]);
    } else {
      redirectMe(rememberToken[2], url[2]);
    }
  }, [loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return <React.Fragment></React.Fragment>;
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  error: selectError(state),
  token: selectToken(state),
  loggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  login: (token: string): void => dispatch(userActivationStart(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthThroughMail);
