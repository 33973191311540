import { MetadataConst } from './action_types';
import {
  AccountingMetadata,
  AccountingMetadataAction,
  AccountingMetadataKoAction,
  AccountingMetadataOkAction,
  GenericMetadata,
  GenericMetadataAction,
  GenericMetadataKoAction,
  GenericMetadataOkAction,
  ProjectMetadata,
  ProjectMetadataAction,
  ProjectMetadataKoAction,
  ProjectMetadataOkAction,
  ResponsibleMetadata,
  ResponsibleMetadataAction,
  ResponsibleMetadataKoAction,
  ResponsibleMetadataOkAction,
  ScholarshipAcademiesMetadataAction,
  ScholarshipAcademiesMetadataKoAction,
  ScholarshipAcademiesMetadataOkAction,
  ScholarshipInfoMetadata,
  ScholarshipMetadataAction,
  ScholarshipMetadataKoAction,
  ScholarshipMetadataOkAction,
  ScholarshipUniversitiesMetadataAction,
  ScholarshipUniversitiesMetadataKoAction,
  ScholarshipUniversitiesMetadataOkAction,
  TeamMetadata,
  TeamMetadataAction,
  TeamMetadataKoAction,
  TeamMetadataOkAction,
} from './definitions';

export function getGenericMetadata(): GenericMetadataAction {
  return {
    type: MetadataConst.GET_GENERIC,
  };
}

export function getGenericMetadataOk(genericData: GenericMetadata): GenericMetadataOkAction {
  return {
    type: MetadataConst.GET_GENERIC_OK,
    genericData,
  };
}

export function getGenericMetadataKo(error: string): GenericMetadataKoAction {
  return {
    type: MetadataConst.GET_GENERIC_KO,
    error,
  };
}

export function getProjectMetadata(): ProjectMetadataAction {
  return {
    type: MetadataConst.GET_PROJECT_METADATA,
  };
}

export function getProjectMetadataOk(projectData: ProjectMetadata): ProjectMetadataOkAction {
  return {
    type: MetadataConst.GET_PROJECT_METADATA_OK,
    projectData,
  };
}

export function getProjectMetadataKo(error: string): ProjectMetadataKoAction {
  return {
    type: MetadataConst.GET_PROJECT_METADATA_KO,
    error,
  };
}

export function getResponsibleMetadata(): ResponsibleMetadataAction {
  return {
    type: MetadataConst.GET_RESPONSIBLES,
  };
}

export function getResponsibleMetadataOk(responsibles: ResponsibleMetadata[]): ResponsibleMetadataOkAction {
  return {
    type: MetadataConst.GET_RESPONSIBLES_OK,
    responsibles,
  };
}

export function getResponsibleMetadataKo(error: string): ResponsibleMetadataKoAction {
  return {
    type: MetadataConst.GET_RESPONSIBLES_KO,
    error,
  };
}

export function getTeamMetadata(): TeamMetadataAction {
  return {
    type: MetadataConst.GET_TEAM,
  };
}

export function getTeamMetadataOk(team: TeamMetadata[]): TeamMetadataOkAction {
  return {
    type: MetadataConst.GET_TEAM_OK,
    team,
  };
}

export function getTeamMetadataKo(error: string): TeamMetadataKoAction {
  return {
    type: MetadataConst.GET_TEAM_KO,
    error,
  };
}

export function getAccountingMetadata(): AccountingMetadataAction {
  return {
    type: MetadataConst.GET_ACCOUNTING_METADATA,
  };
}

export function getAccountingMetadataOk(accountingData: AccountingMetadata): AccountingMetadataOkAction {
  return {
    type: MetadataConst.GET_ACCOUNTING_METADATA_OK,
    accountingData,
  };
}

export function getAccountingMetadataKo(error: string): AccountingMetadataKoAction {
  return {
    type: MetadataConst.GET_ACCOUNTING_METADATA_KO,
    error,
  };
}

export function getScholarshipMetadata(): ScholarshipMetadataAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_METADATA,
  };
}

export function getScholarshipMetadataOk(scholarshipData: ScholarshipInfoMetadata): ScholarshipMetadataOkAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_METADATA_OK,
    scholarshipData,
  };
}

export function getScholarshipMetadataKo(error: string): ScholarshipMetadataKoAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_METADATA_KO,
    error,
  };
}

export function getScholarshipAcademiesMetadata(): ScholarshipAcademiesMetadataAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA,
  };
}

export function getScholarshipAcademiesMetadataOk(
  academies: ScholarshipInfoMetadata['academies'],
): ScholarshipAcademiesMetadataOkAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA_OK,
    academies,
  };
}

export function getScholarshipAcademiesMetadataKo(error: string): ScholarshipAcademiesMetadataKoAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA_KO,
    error,
  };
}

export function getScholarshipUniversitiesMetadata(): ScholarshipUniversitiesMetadataAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA,
  };
}

export function getScholarshipUniversitiesMetadataOk(
  universities: ScholarshipInfoMetadata['universities'],
): ScholarshipUniversitiesMetadataOkAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA_OK,
    universities,
  };
}

export function getScholarshipUniversitiesMetadataKo(error: string): ScholarshipUniversitiesMetadataKoAction {
  return {
    type: MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA_KO,
    error,
  };
}
