import { Fields, GenericFromRequest } from '../components/GenericForm/GenericFormRefactor';
import { convertBase64ImageToFile } from '../helpers/selectImages';
import { DocumentationItem, FileItem, IdCodeNamePair, ResourceImageData } from '../redux/common/definitions';
import {
  BaseCampForm,
  DailyFollows,
  DailyFollowsForms,
  DailyFollowsTable,
  Follows,
  FollowUpSixMonthsForm,
  InternalProject,
  InternalProjectCreate,
  InternalProjectEnrollment,
  InternalProjectEnrollmentMetadata,
  InternalProjectFollowUpAlert,
  InternalProjectForms,
  InternalProjectsData,
  InternalProjectSettings,
  InternalProjectsTemplate,
  InternalProjectSubareaType,
  Member,
  MetadataDailyFollow,
  NewEnrollment,
  ParticipantesFinalFollowUpForm,
  ParticipantesFollowUpList,
  StudentEvaluationFieldsData,
  StudentEvaluationForm,
  StudentEvaluationInfo,
} from '../redux/internal-projects/definitions';
import { GenericData } from '../redux/project/definitions';
import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from './api';

const baseURL = apiBaseUrl;

export const getInternalProjectSubareasFromApi = async (): Promise<{ data: IdCodeNamePair[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: IdCodeNamePair[] }>(`${baseURL}/internal_projects/subareas/list`, init);
};

export const getInternalProjectSettingsFromApi = async (): Promise<{ data: InternalProjectSettings }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InternalProjectSettings }>(`${baseURL}/internal_projects_settings`, init);
};

export const updateInternalProjectSettingsFromApi = async (
  settings: InternalProjectSettings,
): Promise<{ data: InternalProjectSettings }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(settings),
  };

  return apiCall<{ data: InternalProjectSettings }>(`${baseURL}/internal_projects_settings`, init);
};

export const getInternalProjectsFromApi = async (): Promise<{ data: InternalProjectsData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InternalProjectsData }>(`${baseURL}/internal_projects`, init);
};

export const getInternalProjectsTemplateByProjectTypeFromApi = async (
  project_type_id: string,
): Promise<{ data: InternalProjectsTemplate[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InternalProjectsTemplate[] }>(
    `${baseURL}/internal_projects_template/project_type/${project_type_id}`,
    init,
  );
};

export const createInternalProjectApi = async (
  data: InternalProjectCreate,
): Promise<Result<{ data: InternalProject }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await validatedApiCall<{ data: InternalProject }>(`${baseURL}/internal_projects`, init);
};

export const updateInternalProject = async (
  data: Partial<InternalProjectForms>,
  id: number,
): Promise<Result<{ data: InternalProject }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return await validatedApiCall<{ data: InternalProject }>(`${baseURL}/internal_projects/${id}`, init);
};

export const getInternalProjectFromApi = async (id: number): Promise<{ data: InternalProject }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InternalProject }>(`${baseURL}/internal_projects/${id}`, init);
};

export const addMemberFromApi = async (user_id: number, internal_project_id: number): Promise<{ data: Member }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ user_id, internal_project_id }),
  };

  return apiCall<{ data: Member }>(`${baseURL}/internal_project_member`, init);
};

export const getUsersCanBeMembersFromApi = async (internal_project_id: number): Promise<{ data: Member[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: Member[] }>(`${baseURL}/internal_project_member/members/list/${internal_project_id}`, init);
};

export const deleteMemberFromApi = async (user_id: number, internal_project_id: number): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify({ user_id, internal_project_id }),
  };

  return apiCall<{ data: 'OK' }>(`${baseURL}/internal_project_member`, init);
};

export const uploadDocumentAgreementFromApi = async (
  internal_project_id: number,
  file: File,
): Promise<{ data: DocumentationItem[] }> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };

  return apiCall<{ data: DocumentationItem[] }>(
    `${baseURL}/internal_project_agreement/upload/agreement/${internal_project_id}`,
    init,
  );
};

export const downloadDocumentAgreementFromApi = async (internal_project_id: number, file_id: number): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ internal_project_id, file_id }),
  };

  const blob = apiCallBlob(`${baseURL}/internal_project_agreement/download/agreement`, init);

  return blob;
};

export const removeDocumentAgreementFromApi = async (
  internal_project_id: number,
  file_id: number,
): Promise<{ data: DocumentationItem[] }> => {
  const init = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify({ internal_project_id, file_id }),
  };

  return apiCall<{ data: DocumentationItem[] }>(`${baseURL}/internal_project_agreement/remove/agreement`, init);
};

export const getDocumentsFromApi = async (internal_project_id: number): Promise<{ data: DocumentationItem[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: DocumentationItem[] }>(`${baseURL}/internal_project/documents/${internal_project_id}`, init);
};

export const uploadDocumentsFromApi = async (
  internal_project_id: number,
  file: File,
  description: string,
  code: string,
): Promise<{ data: DocumentationItem[] }> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  dataForm.append('description', description);
  dataForm.append('code', code);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };

  return apiCall<{ data: DocumentationItem[] }>(
    `${baseURL}/internal_project/documents/${internal_project_id}/add`,
    init,
  );
};

export const removeDocumentFromApi = async (
  internal_project_id: number,
  file_id: number,
  follow_up_id?: number,
): Promise<{ data: DocumentationItem[] }> => {
  const init = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify({ internal_project_id, file_id, follow_up_id }),
  };

  return apiCall<{ data: DocumentationItem[] }>(`${baseURL}/internal_project/documents`, init);
};

export const downloadDocumentFromApi = async (
  internal_project_id: number,
  file_id: number,
  follow_up_id?: number,
): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ internal_project_id, file_id, follow_up_id }),
  };

  const blob = apiCallBlob(`${baseURL}/internal_project/documents`, init);

  return blob;
};

export const getInternalProjectsDailyFollowsFromApi = async (
  internal_project_id: number,
): Promise<{ data: DailyFollowsTable[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: DailyFollowsTable[] }>(
    `${baseURL}/internal_projects_daily_follows/internal_project/${internal_project_id}`,
    init,
  );
};

export const getInternalProjectsListExcel = async (): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  return await apiCallBlob(`${baseURL}/internal_project/export`, init);
};
//internal_projects_daily_follows/1

export const getInternalProjectDailyFollowFromApi = async (
  dailyFollowsId: number,
): Promise<{ data: DailyFollowsForms }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: DailyFollowsForms }>(`${baseURL}/internal_projects_daily_follows/${dailyFollowsId}`, init);
};

export const addInternalProjectDailyFollowDocumentsFromApi = async (
  dailyFollowsId: number,
  file: File,
  description: string,
): Promise<{ data: DocumentationItem[] }> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  dataForm.append('description', description);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };

  return apiCall<{ data: DocumentationItem[] }>(
    `${baseURL}/internal_projects_daily_follows/documents/${dailyFollowsId}/add`,
    init,
  );
};

export const removeDailyFollowDocumentsFromApi = async (
  dailyFollowsId: number,
  file_id: number,
): Promise<{ data: DocumentationItem[] }> => {
  const init = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify({ internal_project_daily_follow_id: dailyFollowsId, file_id }),
  };

  return apiCall<{ data: DocumentationItem[] }>(`${baseURL}/internal_projects_daily_follows/documents/delete`, init);
};

export const downloadDailyFollowDocumentsFromApi = async (dailyFollowsId: number, file_id: number): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ internal_project_daily_follow_id: dailyFollowsId, file_id }),
  };

  const blob = apiCallBlob(`${baseURL}/internal_projects_daily_follows/documents`, init);

  return blob;
};

export const getInternalProjectDailyFollowMetadataFromApi = async (
  subarea_internal_project_type_id?: string | null,
): Promise<{ data: MetadataDailyFollow[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const baseUrl = `${baseURL}/internal_projects_daily_follows/metadata/info`;
  const dataUrl = subarea_internal_project_type_id
    ? `?subarea_internal_project_type_id=${subarea_internal_project_type_id}`
    : '';
  return apiCall<{ data: MetadataDailyFollow[] }>(`${baseUrl}${dataUrl}`, init);
};

export const createInternalProjectDailyFollowApi = async (
  data: DailyFollows,
): Promise<Result<
  { data: { internal_project_daily_follow_id: number; data: DailyFollowsTable[] } },
  APIValidationError
>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await validatedApiCall<{ data: { internal_project_daily_follow_id: number; data: DailyFollowsTable[] } }>(
    `${baseURL}/internal_projects_daily_follows`,
    init,
  );
};

export const deleteDailyFollowFromApi = async (dailyFollowsId: number): Promise<void> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  apiCall<void>(`${baseURL}/internal_projects_daily_follows/${dailyFollowsId}`, init);
};

export const deleteInternalProjectFromApi = async (
  internal_project_id: number,
): Promise<{ data: InternalProject[] }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<{ data: InternalProject[] }>(`${baseURL}/internal_project/remove/${internal_project_id}`, init);
};

export const getInternalProjectDailyFollowsExcel = async (internalProjectId: number): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ internal_project_id: internalProjectId }),
  };
  return await apiCallBlob(`${baseURL}/internal_projects_daily_follows/export`, init);
};

export const getInternalProjectsAcademyFromApi = async (): Promise<{ data: InternalProject }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InternalProject }>(`${baseURL}/internal_project/all/academy`, init);
};

export const getInternalProjectAcademyFromApi = async (
  internalProjectId: number,
): Promise<{ data: InternalProject }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InternalProject }>(`${baseURL}/internal_project/academy/${internalProjectId}`, init);
};

export const getInternalProjectFollowsFromApi = async (internalProjectId: number): Promise<{ data: Follows[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: Follows[] }>(`${baseURL}/internal_projects_follow_up/${internalProjectId}`, init);
};

export const getParticipantesFollowsListFromApi = async (
  internalProjectId: number,
): Promise<{ data: ParticipantesFollowUpList[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ParticipantesFollowUpList[] }>(
    `${baseURL}/internal_projects_follow_ups_entity/by_project_id/${internalProjectId}`,
    init,
  );
};

export const getFollowUpSixMonthsFormFromApi = async (
  enrollmentId: number,
): Promise<{ data: FollowUpSixMonthsForm }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: FollowUpSixMonthsForm }>(
    `${baseURL}/internal_projects_follow_ups_entity/six_months/${enrollmentId}`,
    init,
  );
};

export const updateFollowUpSixMonthsFormFromApi = async (
  enrollmentId: number,
  followUpSixMonthsData: FollowUpSixMonthsForm,
): Promise<FollowUpSixMonthsForm> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(followUpSixMonthsData),
  };

  const { data } = await apiCall<{ data: FollowUpSixMonthsForm }>(
    `${baseURL}/internal_projects_follow_ups_entity/six_months/${enrollmentId}`,
    init,
  );
  return data;
};

export const getParticipantesFinalFollowsFormFromApi = async (
  id: number,
): Promise<{ data: ParticipantesFinalFollowUpForm }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: ParticipantesFinalFollowUpForm }>(
    `${baseURL}/internal_projects_follow_ups_entity/final/${id}`,
    init,
  );
};
export const updateParticipantesFinalFollowUpFormFromApi = async (
  id: number,
  participanteFinalFormData: Partial<ParticipantesFinalFollowUpForm>,
): Promise<{ data: ParticipantesFinalFollowUpForm }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(participanteFinalFormData),
  };
  return apiCall(`${baseURL}/internal_projects_follow_ups_entity/final/${id}`, init);
};
export const updateFollowUpDateFromApi = async (
  internalProjectFollowId: number,
  followDate: string,
): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ internal_project_follow_id: internalProjectFollowId, follow_date: followDate }),
  };

  return apiCall<{ data: 'OK' }>(`${baseURL}/internal_projects_follow_up/update/follow_date`, init);
};

export const getSectionTemplateFromApi = async (
  internalProjectId: number,
  section: string,
  followUpId: number,
): Promise<{ data: { fields: Fields; participante: GenericData } }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: { fields: Fields; participante: GenericData } }>(
    `${baseURL}/internal_project/show/follow_up/${internalProjectId}/${section}/${followUpId}`,
    init,
  );
};

export const updateInternalFollowUpFromApi = async (
  data: GenericFromRequest,
): Promise<{ data: { fields: Fields; participante: GenericData } }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ ...data }),
  };
  return apiCall<{ data: { fields: Fields; participante: GenericData } }>(
    `${baseURL}/internal_projects/update/section`,
    init,
  );
};

export const sendInternalFollowUpFromApi = async (
  data: GenericFromRequest,
): Promise<{ data: { fields: Fields; participante: GenericData } }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ ...data }),
  };

  return apiCall<{ data: { fields: Fields; participante: GenericData } }>(
    `${baseURL}/internal_projects/send/section`,
    init,
  );
};

export const addDocumentsFollowUpFromApi = async (
  internal_project_id: number,
  follow_up_id: number,
  file: File,
  description: string,
  custom_field_name: string,
  section: string,
): Promise<{ data: GenericFromRequest }> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  dataForm.append('follow_up_id', follow_up_id.toString());
  dataForm.append('custom_field_name', custom_field_name);
  dataForm.append('section', section);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };

  return apiCall<{ data: GenericFromRequest }>(
    `${baseURL}/internal_project/documents/${internal_project_id}/add/pi_info_general`,
    init,
  );
};

export const deleteRowParticipanteFromApi = async (idRow: number, section: string) => {
  const init = {
    method: 'DELETE',
    auth: true,
    body: JSON.stringify({ id: idRow, section }),
  };

  return apiCall<{ data: GenericFromRequest }>(
    `${baseURL}/internal_projects_follow_up/delete/follow/participante`,
    init,
  );
};

export const getBaseCampFromApi = async (internalProjectId: number, follow_up_id: number): Promise<BaseCampForm> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: BaseCampForm }>(
    `${baseURL}/internal_project/show/basecamp/${internalProjectId}/${follow_up_id}`,
    init,
  );
  return data;
};

export const updateEnrollmentFromApi = async (
  enrollment: InternalProjectEnrollment,
): Promise<InternalProjectEnrollment> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(enrollment),
  };

  const { data } = await apiCall<{ data: InternalProjectEnrollment }>(
    `${baseURL}/internal_projects_enrollments/${enrollment.id}`,
    init,
  );
  return data;
};

export const deleteInternalProjectEnrollmentFromApi = async (enrollment_id: number): Promise<boolean> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/internal_projects_enrollments/${enrollment_id}`, init);
  return data === 'OK';
};

export const createInternalProjectEnrollmentFromApi = async (
  newEnrollment: NewEnrollment,
): Promise<InternalProjectEnrollment> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(newEnrollment),
  };

  const { data } = await apiCall<{ data: InternalProjectEnrollment }>(`${baseURL}/internal_projects_enrollments`, init);
  return data;
};

export const getInternalProjectEnrollmentFromApi = async (
  internalProjectId: number,
): Promise<InternalProjectEnrollment[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: InternalProjectEnrollment[] }>(
    `${baseURL}/internal_projects_enrollments/by_project_id/${internalProjectId}`,
    init,
  );
  return data;
};

export const getInternalProjectStudentEvaluationFromApi = async (
  project_key: string,
): Promise<StudentEvaluationForm> => {
  const init = {
    method: 'GET',
    auth: false,
  };

  const { data } = await apiCall<{ data: StudentEvaluationForm }>(
    `${baseURL}/internal_project/project_key/${project_key}`,
    init,
  );
  return data;
};

export const getInternalProjectEnrollmentMetadataFromApi = async (): Promise<InternalProjectEnrollmentMetadata> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: InternalProjectEnrollmentMetadata }>(
    `${baseURL}/internal_projects_enrollments/metadata/list`,
    init,
  );
  return data;
};

export const updateInternalProjectEnrollmentsFromApi = async (
  internal_project_id: number,
  enrollments: InternalProjectEnrollment[],
): Promise<InternalProjectEnrollment[]> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ data: enrollments }),
  };

  const { data } = await apiCall<{ data: InternalProjectEnrollment[] }>(
    `${baseURL}/internal_projects_enrollments/update_all/${internal_project_id}`,
    init,
  );
  return data;
};

export const sendInternalProjectStudentEvaluationFromApi = async (
  fields: StudentEvaluationFieldsData,
): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: false,
    body: JSON.stringify(fields),
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/internal_project/project_key`, init);
  return data === 'OK';
};

export const getStudentEvaluationInfoFromApi = async (
  internal_project_id: number,
  enrollment_id: number,
): Promise<StudentEvaluationInfo> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: StudentEvaluationInfo }>(
    `${baseURL}/internal_project/show/student_evaluation/${internal_project_id}/${enrollment_id}`,
    init,
  );

  return data;
};

export const getInternalProjectFollowUpAlertFromApi = async (
  internal_project_follow_up_id: number,
): Promise<InternalProjectFollowUpAlert> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: InternalProjectFollowUpAlert }>(
    `${baseURL}/internal_project_follow_up/${internal_project_follow_up_id}/alert`,
    init,
  );

  return data;
};

export const setInternalProjectFollowUpAlertFromApi = async (
  data: InternalProjectFollowUpAlert,
): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: 'OK' }>(`${baseURL}/internal_project_follow_up/alert`, init);
};

export const getInternalProjectImagesFromApi = async (projectId: number): Promise<ResourceImageData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ResourceImageData[] }>(
    `${baseURL}/internal_project/images/${projectId}`,
    init,
  );
  return data;
};

export const uploadInternalProjectImageFileFromApi = async (image: string, imageName: string): Promise<FileItem> => {
  const imageFile = convertBase64ImageToFile(image, imageName);
  const dataForm = new FormData();
  dataForm.append('image', imageFile, `${imageName}.${imageFile.type.split('/').pop()}`);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: any }>(`${baseURL}/internal_project/image/upload`, init);
  return data;
};

export const addInternalProjectImageFromApi = async (
  projectId: number,
  name: string,
  description: string,
  imageId: number,
  userId: number,
): Promise<{ data: ResourceImageData }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ name, description, imageId, userId }),
  };

  return apiCall(`${baseURL}/internal_project/${projectId}/image`, init);
};

export const editInternalProjectImageFromApi = async (
  projectId: number,
  projectImageId: number,
  name: string,
  description: string,
  previousImageId: number,
  imageId: number,
  userId: number,
): Promise<{ data: ResourceImageData }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ name, description, previousImageId, imageId, userId }),
  };
  return apiCall(`${baseURL}/internal_project/${projectId}/image/${projectImageId}`, init);
};

export const deleteInternalProjectImageByImageIdFromApi = async (imageId: number): Promise<{ data: string }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall(`${baseURL}/internal_project/image/${imageId}`, init);
};

export const getInternalProjectSubAreaTypesByCodeFromApi = async (
  subarea_code: string,
): Promise<InternalProjectSubareaType[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: InternalProjectSubareaType[] }>(
    `${baseURL}/internal_projects/project_types/subarea/${subarea_code}`,
    init,
  );
  return data;
};

export const applyConfigInternalProjectTemplateFromApi = async (
  internalProjectId: number,
): Promise<{ data: string }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall(`${baseURL}/internal_project/apply/template/${internalProjectId}`, init);
};
