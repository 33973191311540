export enum ScholarshipDataConsts {
  SCHOLARSHIP_GET = 'SCHOLARSHIP:GET',
  SCHOLARSHIP_GET_OK = 'SCHOLARSHIP:GET:OK',
  SCHOLARSHIP_GET_KO = 'SCHOLARSHIP:GET:KO',

  SCHOLARSHIP_VALIDATION_ERRORS = 'SCHOLARSHIP:VALIDATION:ERRORS',

  SCHOLARSHIP_GET_DOCUMENTATION = 'SCHOLARSHIP:GET:DOCUMENTATION',
  SCHOLARSHIP_GET_DOCUMENTATION_OK = 'SCHOLARSHIP:GET:DOCUMENTATION:OK',
  SCHOLARSHIP_GET_DOCUMENTATION_KO = 'SCHOLARSHIP:GET:DOCUMENTATION:KO',

  SCHOLARSHIP_GET_METADATA = 'SCHOLARSHIP:GET:METADATA',
  SCHOLARSHIP_GET_METADATA_OK = 'SCHOLARSHIP:GET:METADATA:OK',
  SCHOLARSHIP_GET_METADATA_KO = 'SCHOLARSHIP:GET:METADATA:KO',

  SCHOLARSHIP_SUBMIT_INFORMATION = 'SCHOLARSHIP:SUBMIT:INFORMATION',
  SCHOLARSHIP_SUBMIT_INFORMATION_OK = 'SCHOLARSHIP:SUBMIT:INFORMATION:OK',
  SCHOLARSHIP_SUBMIT_INFORMATION_KO = 'SCHOLARSHIP:SUBMIT:INFORMATION:KO',

  UPLOAD_SCHOLARSHIP_DOCUMENT = 'UPLOAD:SCHOLARSHIP:DOCUMENT',
  UPLOAD_SCHOLARSHIP_DOCUMENT_OK = 'UPLOAD:SCHOLARSHIP:DOCUMENT:OK',
  UPLOAD_SCHOLARSHIP_DOCUMENT_KO = 'UPLOAD:SCHOLARSHIP:DOCUMENT:KO',

  SCHOLARSHIP_DOWNLOAD_DOCUMENT = 'SCHOLARSHIP:DOWNLOAD:DOCUMENT',
  SCHOLARSHIP_DOWNLOAD_DOCUMENT_OK = 'SCHOLARSHIP:DOWNLOAD:DOCUMENT:OK',
  SCHOLARSHIP_DOWNLOAD_DOCUMENT_KO = 'SCHOLARSHIP:DOWNLOAD:DOCUMENT:KO',

  GET_SCHOLARSHIP_DOCUMENT = 'GET:SCHOLARSHIP:DOCUMENT',
  GET_SCHOLARSHIP_DOCUMENT_OK = 'GET:SCHOLARSHIP:DOCUMENT:OK',
  GET_SCHOLARSHIP_DOCUMENT_KO = 'GET:SCHOLARSHIP:DOCUMENT:KO',

  REMOVE_SCHOLARSHIP_DOCUMENT = 'REMOVE:SCHOLARSHIP:DOCUMENT',
  REMOVE_SCHOLARSHIP_DOCUMENT_OK = 'REMOVE:SCHOLARSHIP:DOCUMENT:OK',
  REMOVE_SCHOLARSHIP_DOCUMENT_KO = 'REMOVE:SCHOLARSHIP:DOCUMENT:KO',

  SCHOLARSHIP_CLEAN_DATA = 'SCHOLARSHIP:CLEAN:DATA',

  SCHOLARSHIP_SET_DATA = 'SCHOLARSHIP:SET:DATA',
  SCHOLARSHIP_SET_DATA_PERSONAL_INFO = 'SCHOLARSHIP:SET:DATA:PERSONAL:INFO',
  SCHOLARSHIP_SET_DATA_MOTIVATIONAL_INFO = 'SCHOLARSHIP:SET:DATA:MOTIVATIONAL:INFO',
  SCHOLARSHIP_SET_DATA_SITUATION_INFO = 'SCHOLARSHIP:SET:DATA:SITUATION:INFO',
  SCHOLARSHIP_SET_DATA_EDUCATIONAL_INFO = 'SCHOLARSHIP:SET:DATA:EDUCATIONAL:INFO',
  SCHOLARSHIP_SET_DATA_ECONOMICAL_INFO = 'SCHOLARSHIP:SET:DATA:ECONOMICAL:INFO',
  SCHOLARSHIP_SET_DATA_COST_INFO = 'SCHOLARSHIP:SET:DATA:COST:INFO',
  SCHOLARSHIP_SET_DATA_INTERVIEW_INFO = 'SCHOLARSHIP:SET:DATA:INTERVIEW:INFO',
  SCHOLARSHIP_SET_DATA_ASSESSMENT_INFO = 'SCHOLARSHIP:SET:DATA:ASSESSMENT:INFO',
  SCHOLARSHIP_SET_DATA_APPROVAL_INFO = 'SCHOLARSHIP:SET:DATA:APPROVAL:INFO',
  SCHOLARSHIP_AGREEMENT_SET_DATA = 'SCHOLARSHIP:AGREEMENT:SET:DATA',
  SCHOLARSHIP_PAYMENT_ADJUSTMENT_SET_DATA = 'SCHOLARSHIP:PAYMENT:ADJUSTMENT:SET:DATA',

  SCHOLARSHIP_SET_DATA_FOLLOW = 'SCHOLARSHIP:SET:DATA:FOLLOW',

  GET_SCHOLARSHIP_AGREEMENT = 'GET:SCHOLARSHIP:AGREEMENT',
  GET_SCHOLARSHIP_AGREEMENT_OK = 'GET:SCHOLARSHIP:AGREEMENT:OK',
  GET_SCHOLARSHIP_AGREEMENT_KO = 'GET:SCHOLARSHIP:AGREEMENT:KO',

  SAVE_AGREEMENT = 'SAVE:AGREEMENT',
  SAVE_AGREEMENT_OK = 'SAVE:AGREEMENT:OK',
  SAVE_AGREEMENT_KO = 'SAVE:AGREEMENT:KO',

  UPDATE_AGREEMENT_CALENDAR = 'UPDATE:AGREEMENT:CALENDAR',
  UPDATE_AGREEMENT_CALENDAR_OK = 'UPDATE:AGREEMENT:CALENDAR:OK',
  UPDATE_AGREEMENT_CALENDAR_KO = 'UPDATE:AGREEMENT:CALENDAR:KO',

  SEND_PROVIDER_INFO = 'SEND:PROVIDER:INFO',
  SEND_PROVIDER_INFO_OK = 'SEND:PROVIDER:INFO:OK',
  SEND_PROVIDER_INFO_KO = 'SEND:PROVIDER:INFO:KO',

  SEND_CONFIGURATION_FOLLOWUP = 'SEND:CONFIGURATION:FOLLOWUP',
  SEND_CONFIGURATION_FOLLOWUP_OK = 'SEND:CONFIGURATION:FOLLOWUP:OK',
  SEND_CONFIGURATION_FOLLOWUP_KO = 'SEND:CONFIGURATION:FOLLOWUP:KO',

  RUN_CONFIGURATION_FOLLOWUP = 'RUN:CONFIGURATION:FOLLOWUP',
  RUN_CONFIGURATION_FOLLOWUP_OK = 'RUN:CONFIGURATION:FOLLOWUP:OK',
  RUN_CONFIGURATION_FOLLOWUP_KO = 'RUN:CONFIGURATION:FOLLOWUP:KO',

  GET_CONFIGURATION_FOLLOWUP = 'GET:CONFIGURATION:FOLLOWUP',
  GET_CONFIGURATION_FOLLOWUP_OK = 'GET:CONFIGURATION:FOLLOWUP:OK',
  GET_CONFIGURATION_FOLLOWUP_KO = 'GET:CONFIGURATION:FOLLOWUP:KO',

  GET_PAYMENT_ADJUSTMENT = 'GET:PAYMENT:ADJUSTMENT',
  GET_PAYMENT_ADJUSTMENT_OK = 'GET:PAYMENT:ADJUSTMENT:OK',
  GET_PAYMENT_ADJUSTMENT_KO = 'GET:PAYMENT:ADJUSTMENT:KO',

  SEND_PAYMENT_ADJUSTMENT = 'SEND:PAYMENT:ADJUSTMENT',
  SEND_PAYMENT_ADJUSTMENT_OK = 'SEND:PAYMENT:ADJUSTMENT:OK',
  SEND_PAYMENT_ADJUSTMENT_KO = 'SEND:PAYMENT:ADJUSTMENT:KO',

  GET_PAYMENT_ADJUSTMENT_DOCUMENTS = 'GET:PAYMENT:ADJUSTMENT:DOCUMENTS',
  GET_PAYMENT_ADJUSTMENT_DOCUMENTS_OK = 'GET:PAYMENT:ADJUSTMENT:DOCUMENTS:OK',
  GET_PAYMENT_ADJUSTMENT_DOCUMENTS_KO = 'GET:PAYMENT:ADJUSTMENT:DOCUMENTS:KO',

  SAVE_FOLLOW_UP = 'SAVE:FOLLOW_UP',

  SWITCH_BLOCK_PAYMENT = 'SWITCH:BLOCK:PAYMENT',
  SWITCH_BLOCK_PAYMENT_OK = 'SWITCH:BLOCK:PAYMENT:OK',
  SWITCH_BLOCK_PAYMENT_KO = 'SWITCH:BLOCK:PAYMENT:KO',

  SCHOLARSHIP_ADD_MATERIAL = 'SCHOLARSHIP:ADD:MATERIAL',
  SCHOLARSHIP_REMOVE_MATERIAL = 'SCHOLARSHIP:REMOVE:MATERIAL',
  SCHOLARSHIP_UPDATE_MATERIAL = 'SCHOLARSHIP:UPDATE:MATERIAL',
  SCHOLARSHIP_ADD_MATERIAL_OK = 'SCHOLARSHIP:ADD:MATERIAL:OK',
  SCHOLARSHIP_REMOVE_MATERIAL_OK = 'SCHOLARSHIP:REMOVE:MATERIAL:OK',
  SCHOLARSHIP_UPDATE_MATERIAL_OK = 'SCHOLARSHIP:UPDATE:MATERIAL:OK',
  SCHOLARSHIP_MATERIAL_KO = 'SCHOLARSHIP:MATERIAL:KO',

  GET_SCHOLARSHIPS_TO_PAY_INSTITUTE = 'GET:SCHOLARSHIPS:TO:PAY:INSTITUTE',
  GET_SCHOLARSHIPS_TO_PAY_INSTITUTE_OK = 'GET:SCHOLARSHIPS:TO:PAY:INSTITUTE:OK',
  GET_SCHOLARSHIPS_TO_PAY_INSTITUTE_KO = 'GET:SCHOLARSHIPS:TO:PAY:INSTITUTE:KO',

  GET_SCHOLARSHIPS_TO_PAY_ENTITY = 'GET_SCHOLARSHIPS_TO_PAY_ENTITY',
  GET_SCHOLARSHIPS_TO_PAY_ENTITY_OK = 'GET_SCHOLARSHIPS_TO_PAY_ENTITY_OK',
  GET_SCHOLARSHIPS_TO_PAY_ENTITY_KO = 'GET_SCHOLARSHIPS_TO_PAY_ENTITY_KO',

  GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY = 'GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY',
  GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_OK = 'GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_OK',
  GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_KO = 'GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_KO',
  GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT = 'GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT',
  GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_OK = 'GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_OK',
  GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_KO = 'GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_KO',

  EXPORT_SCHOLARSHIP_REPORTS = 'EXPORT:SCHOLARSHIPS:REPORTS',
  EXPORT_SCHOLARSHIP_REPORTS_OK = 'EXPORT:SCHOLARSHIPS:REPORTS:OK',
  EXPORT_SCHOLARSHIP_REPORTS_KO = 'EXPORT:SCHOLARSHIPS:REPORTS:KO',

  UPLOAD_SCHOLARSHIP_DOCUMENT_TRACKING = 'UPLOAD:SCHOLARSHIP:DOCUMENT:TRACKING',

  SET_PAYMENTS_ACADEMIC_YEAR = 'SET:PAYMENTS:ACADEMIC:YEAR',
  SET_ENTITY_PAYMENT_DATE_FILTER = 'SET:ENTITY:PAYMENT:DATE:FILTER',

  GET_SCHOLARSHIP_ACADEMIES_METADATA = 'GET:SCHOLARSHIP:ACADEMIES:METADATA',
  GET_SCHOLARSHIP_METADATA_ACADEMIES_OK = 'GET:SCHOLARSHIP:METADATA:ACADEMIES:OK',
  GET_SCHOLARSHIP_METADATA_ACADEMIES_KO = 'GET:SCHOLARSHIP:METADATA:ACADEMIES:KO',

  CANCEL_SCHOLARSHIP_AGREEMENT = 'CANCEL:SCHOLARSHIP:AGREEMENT',
  CANCEL_SCHOLARSHIP_AGREEMENT_OK = 'CANCEL:SCHOLARSHIP:AGREEMENT_OK',
  CANCEL_SCHOLARSHIP_AGREEMENT_KO = 'CANCEL:SCHOLARSHIP:AGREEMENT_KO',

  REPLACE_SCHOLARSHIPS_PAYMENT_DATES = 'REPLACE:SCHOLARSHIPS:PAYMENT:DATES',
  REPLACE_SCHOLARSHIPS_PAYMENT_DATES_OK = 'REPLACE:SCHOLARSHIPS:PAYMENT:OK',
  REPLACE_SCHOLARSHIPS_PAYMENT_DATES_KO = 'REPLACE:SCHOLARSHIPS:PAYMENT:KO',

  SET_SCHOLARSHIP_STEP = 'SET:SCHOLARSHIP:STEP',
}
