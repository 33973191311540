import { DocumentationItem } from '../redux/common/definitions';
import {
  EventData,
  EventRegistrationData,
  EventSubscriptionData,
  EventsUser,
  UpdateEventFormFields,
  UserRegistrationData,
} from '../redux/event/definitions';

import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from './api';

const baseURL = apiBaseUrl;

export const createEvent = async (event: EventData): Promise<Result<{ data: EventData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({
      ...event,
      reminder: false,
      cancelled: false,
      max_capacity: 30,
    }),
  };

  return validatedApiCall<{ data: EventData }>(`${baseURL}/events/`, init);
};

export const copyEvent = async (event: EventData): Promise<{ event_id: number }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(event),
  };

  const { data } = await apiCall<{ data: { event_id: number } }>(`${baseURL}/events/copy`, init);

  return data;
};

export const getEventData = async (id: string): Promise<{ data: EventData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: EventData }>(`${baseURL}/events/${id || 0}`, init);
};

export const getEvents = async (role?: string): Promise<EventData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<EventData[]>(`${baseURL}/events/`, init);
};

export const getUserEvents = async (): Promise<EventsUser[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<EventsUser[]>(`${baseURL}/events/user/assist`, init);
};

export const createEventRegistration = async (
  data: EventRegistrationData,
): Promise<Result<{ data: EventRegistrationData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: EventRegistrationData }>(
    `${baseURL}/events/${data.id}/registrations/${data.user_id}`,
    init,
  );
};

export const createEventSubscription = async (
  data: EventRegistrationData,
): Promise<Result<{ data: EventSubscriptionData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: EventSubscriptionData }>(
    `${baseURL}/events/${data.id}/subscriptions/${data.user_id}`,
    init,
  );
};

export const getEventSubscriptions = async (eventId: number): Promise<EventSubscriptionData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<EventSubscriptionData[]>(`${baseURL}/events/${eventId}/subscriptions`, init);
};

export const getEventRegistrations = async (eventId: number): Promise<EventSubscriptionData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<EventSubscriptionData[]>(`${baseURL}/events/${eventId}/registrations`, init);
};

export const updateEventTable = async (
  data: UpdateEventFormFields,
  id: number,
): Promise<Result<{ data: EventData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: EventData }>(`${baseURL}/events/${id}`, init);
};

export const updateEventSubscription = async (
  eventId: number,
  eventRegistrationsList: EventSubscriptionData[],
): Promise<Result<{ data: EventData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(eventRegistrationsList),
  };

  return validatedApiCall<{ data: EventData }>(`${baseURL}/events/${eventId}/registrations`, init);
};

export const removeRegistration = async (eventId: number, registrationId: number): Promise<'OK'> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{ data: 'OK' }>(`${baseURL}/events/${eventId}/registrations/${registrationId}`, init);
  return data;
};

export const removeSubscription = async (eventId: number, registrationId: number): Promise<'OK'> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{ data: 'OK' }>(`${baseURL}/events/${eventId}/subscriptions/${registrationId}`, init);
  return data;
};

export const uploadEventDocument = async (
  documentationId: number,
  eventId: number,
  file: File,
): Promise<DocumentationItem[]> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const {
    data: { documents },
  } = await apiCall<{ data: { documents: DocumentationItem[] } }>(
    `${baseURL}/events/document/${documentationId}/${eventId}`,
    init,
  );
  return documents;
};

export const downloadEventDocument = async (fileId: number, eventId: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const blob = await apiCallBlob(`${baseURL}/events/document/${eventId}/${fileId}`, init);
  return blob;
};

export const removeEventDocument = async (fileId: number, eventId: number): Promise<DocumentationItem[]> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const {
    data: { documents },
  } = await apiCall<{ data: { documents: DocumentationItem[] } }>(
    `${baseURL}/events/document/${eventId}/${fileId}`,
    init,
  );
  return documents;
};

export const getConnectionUrl = async (eventId: number, eventType: string): Promise<{ data: { url: string } }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: { url: string } }>(`${baseURL}/${eventType}/${eventId}/registrationUrl`, init);
};

export const getUserRegistrationData = async (
  eventId: number,
  userId: number,
): Promise<{ data: UserRegistrationData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: UserRegistrationData }>(`${baseURL}/events/${eventId}/registrationData/${userId}`, init);
};
