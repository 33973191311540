import React from 'react';
import styles from './feedback.module.scss';

interface FeedbackProp {
  backroundColor?: string;
  text: string;
  color?: string;
  icon?: JSX.Element;
  urlOnclick?: string;
}

const Feedback: React.FC<FeedbackProp> = ({ backroundColor, text, color, icon, urlOnclick }) => {
  if (urlOnclick) {
    return (
      <button
        onClick={() => (document.location.href = urlOnclick)}
        className={styles.format_button}
        style={{ backgroundColor: backroundColor || 'none', color: color }}
      >
        {icon}
        {text}
      </button>
    );
  } else {
    return (
      <span className={styles.format} style={{ backgroundColor: backroundColor || 'none', color: color }}>
        {icon}
        {text}
        {urlOnclick}
      </span>
    );
  }
};

export default Feedback;
