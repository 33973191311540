import React from 'react';
import moment from 'moment';
import { AppState } from '../../../redux/root-reducer';
import { connect } from 'react-redux';
import { InvoiceData, InvoiceStates } from '../../../redux/accounting/definitions';
import { selectInvoice } from '../../../redux/accounting/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import styles from './InvoiceModificationModal.module.scss';
import { Button, MenuItem } from '@material-ui/core';
import { tFormKey } from '../../../helpers/translate';
import { useForm } from 'react-hook-form';
import { ModifyInvoiceSchema } from '../../../validations/formSchema';
import SelectController from '../../../components/Select/SelectController';
import { CodeNamePair } from '../../../redux/common/definitions';
import FormContainer from '../../../components/Forms/FormContainer';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { editInvoiceData, invoiceSetData } from '../../../redux/accounting/actions';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import { permissionType } from '../../../constants/permissions_definitions';

interface Props {
  onCloseModal: () => void;
  onSubmitOk?: () => void;
  invoiceData: InvoiceData;
  genericMetadata: GenericMetadata;
  editInvoiceData: (invoice: InvoiceData) => void;
  setData: (key: string, value: string | number) => void;
  userCan: (permission: permissionType) => boolean | undefined;
}

const InvoiceModificationModal = ({
  invoiceData,
  genericMetadata,
  editInvoiceData,
  onCloseModal,
  onSubmitOk,
  setData,
  userCan,
}: Props) => {
  const {
    description,
    reference_number,
    invoice_date,
    due_date,
    contract_number,
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_area_code,
    center_cost_project_code,
    state_code,
  } = invoiceData;

  const { channels, delegations, areas, projects } = genericMetadata;
  const { handleSubmit, errors, control } = useForm<InvoiceData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ModifyInvoiceSchema,
  });

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const onSubmit = (data: InvoiceData) => {
    const invoice = { ...invoiceData, ...data };
    invoice.due_date = data.due_date ? parseInt(moment.utc(data.due_date || '0').format('X')) : 0;
    editInvoiceData(invoice);
    if (onSubmitOk) {
      onSubmitOk();
    }
  };

  const invoiceNotSentStates = [InvoiceStates.PENDIENTE_ENVIO_SAGE.toString(), InvoiceStates.PAGADA.toString()];
  const canChangeInvoice = userCan('allowInvoiceChanges') && !invoiceNotSentStates.includes(state_code);

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer title={''} className={styles.formModalContainer}>
          <div className={styles.subContainer}>
            <TextInputController
              control={control}
              schema={ModifyInvoiceSchema}
              label={tFormKey('Número de factura')}
              name=""
              defaultValue={reference_number}
              className={styles.float_20}
              errors={errors}
              disabled
            />
            <KeyboardDatePickerController
              label={tFormKey('Fecha factura')}
              name=""
              control={control}
              schema={ModifyInvoiceSchema}
              className={styles.float_30}
              defaultValue={invoice_date}
              errors={errors}
              disabled
            />
            <KeyboardDatePickerController
              label={tFormKey('Fecha vencimiento')}
              name="due_date"
              control={control}
              schema={ModifyInvoiceSchema}
              className={styles.float_30}
              defaultValue={due_date}
              errors={errors}
              disabled={!canChangeInvoice}
            />
            <TextInputController
              control={control}
              schema={ModifyInvoiceSchema}
              label={tFormKey('Contrato')}
              name=""
              className={styles.float_20}
              defaultValue={contract_number}
              errors={errors}
              disabled
            />
          </div>
          <div className={styles.subContainer}>
            <TextInputController
              className={styles.textarea}
              name={'description'}
              schema={ModifyInvoiceSchema}
              control={control}
              errors={errors}
              multiline
              rowsMax={6}
              rows={6}
              textAreaResize
              defaultValue={description}
            />
          </div>
          <div className={styles.subContainer}>
            <SelectController
              control={control}
              name="center_cost_channel_code"
              label={tFormKey('Canal')}
              defaultValue={center_cost_channel_code}
              size="20"
              schema={ModifyInvoiceSchema}
              onClick={e => handleSelectChange(e.target)}
              errors={errors}
            >
              {channels?.map((element: CodeNamePair, index: number) => (
                <MenuItem key={`${element.code}_${index}`} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              control={control}
              name="center_cost_delegation_code"
              label={tFormKey('Delegación')}
              defaultValue={center_cost_delegation_code}
              size="20"
              schema={ModifyInvoiceSchema}
              errors={errors}
            >
              {delegations
                ?.filter(e => e.channel_code === center_cost_channel_code || e.channel_code === null)
                ?.map((element: CodeNamePair, index: number) => (
                  <MenuItem key={`${element.code}_${index}`} value={element.code}>
                    {element.name}
                  </MenuItem>
                ))}
            </SelectController>
            <SelectController
              control={control}
              name="center_cost_area_code"
              label={tFormKey('Área')}
              defaultValue={center_cost_area_code}
              size="20"
              schema={ModifyInvoiceSchema}
              errors={errors}
            >
              {areas
                ?.filter(e => e.delegation_code === center_cost_delegation_code || e.delegation_code === null)
                ?.map((element: CodeNamePair, index: number) => (
                  <MenuItem key={`${element.code}_${index}`} value={element.code}>
                    {element.name}
                  </MenuItem>
                ))}
            </SelectController>
            <SelectController
              control={control}
              name="center_cost_project_code"
              label={tFormKey('Proyecto')}
              defaultValue={center_cost_project_code || '0'}
              size="20"
              schema={ModifyInvoiceSchema}
              errors={errors}
            >
              {projects
                .filter(e => e.area_code === center_cost_area_code || !e.area_code)
                ?.map((element: CodeNamePair, index: number) => (
                  <MenuItem key={`${element.code}_${index}` || '0'} value={element.code || '0'}>
                    {element.name}
                  </MenuItem>
                ))}
            </SelectController>
          </div>
        </FormContainer>
        <div className={styles.buttons}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: '10px', width: '120px' }}
            onClick={onCloseModal}
          >
            {tFormKey('Cancelar')}
          </Button>
          <Button variant="outlined" color="primary" style={{ marginLeft: '10px', width: '120px' }} type="submit">
            {tFormKey('Modificar')}
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  invoiceData: selectInvoice(state),
  genericMetadata: selectGenericMetadata(state),
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(invoiceSetData(key, value)),
  editInvoiceData: (invoice: InvoiceData) => dispatch(editInvoiceData(invoice)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceModificationModal);
