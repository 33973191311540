import * as H from 'history';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../../components/Loading/Loading';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { exportToExcel } from '../../../../helpers/exportToXslx';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { getAreas } from '../../../../redux/area/action';
import { SubAreaInfo } from '../../../../redux/area/definitions';
import { selectSubAreasList } from '../../../../redux/area/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { ServiceData, ServiceDataDispatch } from '../../../../redux/service/definitions';
import { UserData } from '../../../../redux/user/definitions';
import SubmenuStudent from '../../SubmenuStudent/SubmenuStudent';
import styles from './scholarshipList.module.scss';

interface ServicesProps {
  id: string;
  readOnly: boolean;
  loading: boolean;
  subList: SubAreaInfo[];
  location: H.Location<{ needsValidation: boolean }>;
  avatar: string;
  changeAvatar: boolean;
  profileData: UserData;
  getAreas: () => void;
  handleSetImageById: (image: string) => void;
  onSelectComponent: (componentId: number) => void;
}

const ServicesList: React.FC<ServicesProps> = ({
  avatar,
  profileData,
  loading,
  subList,
  getAreas,
  changeAvatar,
  handleSetImageById,
  onSelectComponent,
}) => {
  const [, setSubareas] = useState<SubAreaInfo[]>([]);
  const history = useHistory();

  useEffect(() => {
    getAreas();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (subList.length > 0) {
      setSubareas(subList);
    }
  }, [subList]);

  const initialData = profileData.services.map(service => {
    typeof service.assistance === 'number'
      ? (service.assistance_label = service.assistance ? tKey('Sí') : tKey('No'))
      : (service.assistance_label = '-');
    service.manual_label = service.manual ? tKey('Sí') : tKey('No');
    return service;
  });

  const serviceDetails = (rowData: ServiceData) => {
    const { id } = rowData;
    history.push({
      pathname: `/servicios/detalle/${id}/datos_basicos`,
      state: { id },
    });
  };

  const tableData: MaterialTableProps<ServiceData> = {
    title: tKey('Lista de Servicios'),
    columns: [
      {
        title: tKey('Fecha inicio'),
        field: 'start_date',
        render: (rowData: ServiceData) => {
          if (typeof rowData.start_date === 'string') return rowData.start_date.split(' ')[0];
        },
      },
      {
        title: tKey('Nombre'),
        field: 'name',
        // @ts-ignore
        width: '400px',
      },
      {
        title: tKey('Subárea'),
        field: 'subarea',
        // @ts-ignore
        width: '330px',
      },
      {
        title: tKey('Aforo máximo'),
        field: 'max_capacity',
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        title: tKey('Manual'),
        field: 'manual_label',
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
      },
      {
        title: tKey('Asistencia'),
        field: 'assistance_label',
        render: (rowData: ServiceData): any => {
          return rowData.assistance ? tFormKey('Sí') : tFormKey('No');
        },
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
      },
    ],
    data: initialData,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Servicios`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'fixed',
    },
  };

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu />}
      leftSubmenu={
        <SubmenuStudent
          selected="services"
          avatar={avatar}
          loading={loading}
          onSelectComponent={onSelectComponent}
          setImage={changeAvatar ? handleSetImageById : undefined}
        />
      }
    >
      <React.Fragment>
        {!loading ? (
          <div className={styles.list}>
            <TableWithEdit tableData={{ ...tableData, isLoading: loading }} onRowClick={serviceDetails} />
          </div>
        ) : (
          <Loading big />
        )}
      </React.Fragment>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  subList: selectSubAreasList(state),
});

const mapDispatchToProps = (dispatch: ServiceDataDispatch | any) => ({
  getAreas: (): void => dispatch(getAreas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServicesList);
