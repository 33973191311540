import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { AboutUsState } from './aboutUs/definitions';
import aboutUs from './aboutUs/reducers';
import { AreasState } from './area/definitions';
import areasReducer from './area/reducers';
import authReducer, { AuthState } from './auth/reducers';
import { StudentCalendarState } from './calendar-scholarship/definitions';
import calendarInterviewReducer from './calendar-scholarship/reducers';
import { CalendarState } from './calendar/definitions';
import calendarReducer from './calendar/reducers';
import { EntityDataState } from './entity/definitions';
import entityReducer from './entity/reducers';
import { FeedbackAPIState } from './FeedbackAPI/definitions';
import feedbackAPI from './FeedbackAPI/reducers';
import { InterviewState } from './interviews/definitions';
import interviewsReducer from './interviews/reducers';
import { CustomFieldState } from './kpis/definitions';
import customFieldReducer from './kpis/reducer';
import { MetadataState } from './metadata/definitions';
import metadataReducer from './metadata/reducers';
import { NotificationsState } from './notification/definitions';
import notificationsReducer from './notification/reducers';
import { PermissionsState } from './permissions/definitions';
import permissions from './permissions/reducers';
import { ProjectState } from './project/definitions';
import projectReducer from './project/reducers';
import registerReducer, { RegisterState } from './register/reducers';
import { ScholarshipDataState } from './scholarship/definitions';
import scholarshipReducer from './scholarship/reducers';
import { SearchState } from './searchBar/definitions';
import searchReducer from './searchBar/reducers';
import userReducer, { UserDataState } from './user/reducers';
import eventReducer, { EventDataState } from './event/reducers';
import serviceReducer, { ServiceDataState } from './service/reducers';
import { TranslationState } from './translations/definitions';
import translationReducer from './translations/reducers';
import { AccountingState } from './accounting/definitions';
import accountingReducer from './accounting/reducers';
import { DirectoryDataState } from './directory/reducers';
import directoryReducer from './directory/reducers';
import { TrackingState } from './tracking/definitions';
import trackingReducer from './tracking/reducers';
import academyReducer from './academy/reducers';
import { AcademyDataState } from './academy/reducers';
import internalProjectReducer, { InternalProjectsState } from './internal-projects/reducers';
import internalProjectTemplateReducer, { InternalProjectTemplatesState } from './internal-project-templates/reducers';
import applicationReducer, { ApplicationState } from './application/reducers';
import { SubareaInternalProjectTypeState } from './subareaInternalProjectType/definition.d';
import subareaInternalProjectTypeReducer from './subareaInternalProjectType/reducers';
import participantParticularitiesReducer from './internal-projects/participants/particularities/reducers';
import { GetParticipantsState } from './internal-projects/participants/list/definitions';
import deleteParticipantReducer from './internal-projects/participants/delete/reducers';
import { DeleteParticipantState } from './internal-projects/participants/delete/definition';
import { UpdateParticipantState } from './internal-projects/participants/update/definitions';
import updateParticipantReducer from './internal-projects/participants/update/reducers';
import { CreateParticipantState } from './internal-projects/participants/create/definitions';
import createParticipantReducer from './internal-projects/participants/create/reducers';
import { UnSubscribeParticipantState } from './internal-projects/participants/unsubscribe/definition';
import unSubscribeParticipantReducer from './internal-projects/participants/unsubscribe/reducers';
import getParticipantsReducer from './internal-projects/participants/list/reducers';
import { ParticipantParticularitiesState } from './internal-projects/participants/particularities/definitions';
import { EducatorState } from './internal-projects/educators/definition';
import educatorReducer from './internal-projects/educators/reducers';
import { ParticipantsAssistanceState } from './internal-projects/assistance/definitions';
import participantsAssistanceReducer from './internal-projects/assistance/reducers';
import { SignatureDocumentState } from './signature-document/definitions';
import signatureDocumentReducer from './signature-document/reducers';
import { ScholarshipJustificationsState } from './scholarship/justifications/definitions';
import scholarshipJustificationsReducer from './scholarship/justifications/reducers';

export interface AppState {
  applicationReducer: ApplicationState;
  authReducer: AuthState;
  userReducer: UserDataState;
  entityReducer: EntityDataState;
  scholarshipReducer: ScholarshipDataState;
  notificationsReducer: NotificationsState;
  searchReducer: SearchState;
  registerReducer: RegisterState;
  permissions: PermissionsState;
  interviewsReducer: InterviewState;
  calendarReducer: CalendarState;
  feedbackAPI: FeedbackAPIState;
  calendarInterviewReducer: StudentCalendarState;
  areasReducer: AreasState;
  projectReducer: ProjectState;
  aboutUs: AboutUsState;
  metadataReducer: MetadataState;
  customFieldReducer: CustomFieldState;
  translationReducer: TranslationState;
  eventReducer: EventDataState;
  accountingReducer: AccountingState;
  serviceReducer: ServiceDataState;
  directoryReducer: DirectoryDataState;
  trackingReducer: TrackingState;
  academyReducer: AcademyDataState;
  internalProjectReducer: InternalProjectsState;
  internalProjectTemplateReducer: InternalProjectTemplatesState;
  subareaInternalProjectTypeReducer: SubareaInternalProjectTypeState;
  getParticipantsReducer: GetParticipantsState;
  deleteParticipantReducer: DeleteParticipantState;
  updateParticipantReducer: UpdateParticipantState;
  createParticipantReducer: CreateParticipantState;
  unSubscribeParticipantReducer: UnSubscribeParticipantState;
  participantParticularitiesReducer: ParticipantParticularitiesState;
  educatorReducer: EducatorState;
  participantsAssistanceReducer: ParticipantsAssistanceState;
  signatureDocumentReducer: SignatureDocumentState;
  scholarshipJustificationsReducer: ScholarshipJustificationsState;
}

const createRootReducer = <S>(history: History<S>) =>
  combineReducers({
    router: connectRouter(history),
    applicationReducer,
    authReducer,
    userReducer,
    entityReducer,
    scholarshipReducer,
    notificationsReducer,
    registerReducer,
    permissions,
    searchReducer,
    interviewsReducer,
    calendarReducer,
    feedbackAPI,
    calendarInterviewReducer,
    areasReducer,
    projectReducer,
    aboutUs,
    metadataReducer,
    customFieldReducer,
    translationReducer,
    eventReducer,
    accountingReducer,
    serviceReducer,
    directoryReducer,
    trackingReducer,
    academyReducer,
    internalProjectReducer,
    internalProjectTemplateReducer,
    subareaInternalProjectTypeReducer,
    participantParticularitiesReducer,
    getParticipantsReducer,
    deleteParticipantReducer,
    updateParticipantReducer,
    createParticipantReducer,
    unSubscribeParticipantReducer,
    educatorReducer,
    participantsAssistanceReducer,
    signatureDocumentReducer,
    scholarshipJustificationsReducer,
  });

export default createRootReducer;
