/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputLabel, MenuItem } from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { connect } from 'react-redux';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions } from '../../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../../helpers/translate';
import { validateFields } from '../../../../../helpers/validator';
import { IdCodeNamePair } from '../../../../../redux/common/definitions';
import { AppState } from '../../../../../redux/root-reducer';
import { scholarshipSetEducationalInfoData } from '../../../../../redux/scholarship/actions';
import {
  ScholarshipData,
  ScholarshipMetadata,
  ScholarshipSteps,
  ScholarshipType,
} from '../../../../../redux/scholarship/definitions';
import { selectGetScholarshipMetadata } from '../../../../../redux/scholarship/selectors';
import StudyCenterSelector from '../StudyCenterSelector';
import styles from '../requestForms.module.scss';
import EducationalValorationCourseForm from './EducationalValorationCourseForm';

interface EducationalValorationLastCourseFormProps<T extends FieldValues> {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  errors: FieldErrors<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  control: Control<T>;
  readOnly: boolean;
  register: ((instance: any) => void) | undefined;
  setValue: (name: any, value: any, shouldValidate?: boolean) => void;
  setData: (key: string, value: string | number) => void;
}

const EducationalValorationLastCourseForm = <T extends FieldValues>({
  scholarshipData,
  scholarshipMetadata,
  errors,
  schema,
  control,
  readOnly,
  register,
  setValue,
  setData,
}: EducationalValorationLastCourseFormProps<T>) => {
  const validatorInfo = {
    data: scholarshipData,
    step: 'educationalValoration' as keyof ScholarshipSteps,
  };

  const {
    has_formal_studies_done,
    isEntity,
    finished_educational_type_year,
    last_academic_year_remarks,
    finished_educational_type_id,
    scholarshipType,
    formal_studies_completed,
    province_academy,
    finished_educational_type_code,
    formal_studies_academy_name,
  } = scholarshipData;

  const { educationalTypes } = scholarshipMetadata;

  const finishedStudiesYearList = (): number[] => {
    const currentYear = new Date().getFullYear();
    let year = 2015;
    const yearList = [];
    while (year < currentYear) {
      yearList.push(year);
      year++;
    }
    return yearList;
  };

  const educationalTypesFiltered = (): IdCodeNamePair[] =>
    educationalTypes.filter((et: IdCodeNamePair) => et.code !== 'PRI' && et.code !== 'IN2' && et.code !== 'EE');

  const renderHasFormalStudiesDone = (): JSX.Element | undefined => {
    if (!isEntity) {
      return undefined;
    }
    return (
      <div className={styles.size_100}>
        <RadioButtonGroup
          name="has_formal_studies_done"
          disabled={readOnly}
          questionText={tScholarshipKey('has_formal_studies_done')}
          options={yesNoOptions}
          register={register}
          value={has_formal_studies_done !== null ? has_formal_studies_done.toString() : ''}
          validator={validateFields(scholarshipData, 'has_formal_studies_done', validatorInfo.step)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData('has_formal_studies_done', e.target.value === '1' ? 1 : 0);
          }}
        />
      </div>
    );
  };

  const handleFinishedEducationalTypeSelectChange = (target: any) => {
    setData(target.name, target.value);
    const educationalTypeCode = educationalTypes.find((et: IdCodeNamePair) => et.id === target.value)?.code;
    setData('finished_educational_type_code', educationalTypeCode || '');
  };

  const lastCourseFinishedWithoutFormalStudies = (): JSX.Element => {
    return (
      <>
        <SelectController
          control={control}
          schema={schema}
          errors={errors}
          size="50"
          key="finished_educational_type_id"
          name="finished_educational_type_id"
          defaultValue={finished_educational_type_id}
          label={tScholarshipKey('finished_educational_type_id')}
          validator={validateFields(scholarshipData, 'finished_educational_type_id', validatorInfo.step)}
          disabled={readOnly}
          onClick={e => handleFinishedEducationalTypeSelectChange(e.target)}
        >
          {educationalTypesFiltered().map((element: IdCodeNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          errors={errors}
          schema={schema}
          size="50"
          name="finished_educational_type_year"
          defaultValue={finished_educational_type_year}
          label={tScholarshipKey('finished_educational_type_year')}
          disabled={readOnly}
          onClick={(e: any) => {
            setData('finished_educational_type_year', e.target.value);
          }}
        >
          {finishedStudiesYearList().map((item: number) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </SelectController>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey(`last_academic_year_remarks`)}
          </InputLabel>
          <TextInputController
            control={control}
            errors={errors}
            schema={schema}
            className={styles.size_100}
            size="50"
            disabled={readOnly}
            name="last_academic_year_remarks"
            defaultValue={last_academic_year_remarks}
            rows="4"
            rowsMax="10"
            multiline
            validator={validateFields(scholarshipData, 'last_academic_year_remarks', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('last_academic_year_remarks', e.target.value)}
          />
        </div>
      </>
    );
  };

  const lastCourseFinishedWithFormalStudies = (): JSX.Element => {
    const formalStudiesCompletedList: string[] = [
      'Sí',
      'No, le falta alguna asignatura y/o las prácticas',
      'No, repetirá curso',
      'No, deja este curso y cambia de estudios',
    ];

    return (
      <>
        <EducationalValorationCourseForm
          scholarshipData={scholarshipData}
          isLastCourse
          readOnly={readOnly}
          control={control}
          errors={errors}
          schema={schema}
          setValue={setValue}
        />

        <SelectController
          control={control}
          errors={errors}
          schema={schema}
          size="50"
          name="formal_studies_completed"
          defaultValue={formal_studies_completed}
          label={tScholarshipKey('formal_studies_completed')}
          disabled={readOnly}
          onClick={(e: any) => {
            setData('formal_studies_completed', e.target.value);
          }}
        >
          {formalStudiesCompletedList.map((item: string) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </SelectController>
        <StudyCenterSelector
          readOnly={readOnly}
          province_academy={province_academy}
          educational_type_code={finished_educational_type_code}
          scholarship_academy={formal_studies_academy_name}
          scholarshipType={scholarshipType as ScholarshipType}
          setScholarshipAcademy={(value: string) => {
            setValue('formal_studies_academy_name', value);
            setData('formal_studies_academy_name', value);
          }}
          setScholarshipAcademyCode={(value: string) => {
            setValue('formal_studies_academy_name_code', value);
            setData('formal_studies_academy_name_code', value);
          }}
        />
      </>
    );
  };

  const renderEntityLastCourseFinishedFields = (): JSX.Element | undefined => {
    if (has_formal_studies_done === null) {
      return undefined;
    }
    if (has_formal_studies_done !== 1) {
      return lastCourseFinishedWithoutFormalStudies();
    }
    return lastCourseFinishedWithFormalStudies();
  };

  const renderLastCourseFinished = (): JSX.Element | undefined => {
    if (!isEntity) {
      return (
        <EducationalValorationCourseForm
          scholarshipData={scholarshipData}
          isLastCourse
          readOnly={readOnly}
          control={control}
          errors={errors}
          schema={schema}
          setValue={setValue}
        />
      );
    }
    return renderEntityLastCourseFinishedFields();
  };

  return (
    <FormContainer title={tScholarshipKey('Último curso finalizado')}>
      {renderHasFormalStudiesDone()}
      {renderLastCourseFinished()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetEducationalInfoData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EducationalValorationLastCourseForm);
