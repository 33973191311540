import { useEffect } from 'react';
import { ManualFieldError, ValidateResult } from 'react-hook-form/dist/types';
import { APIValidationError } from '../../api/api';

export default function useValidatorAPI(
  validationErrors: APIValidationError | null,
  setError: (
    name: ManualFieldError<Record<string, any>>[],
    type?: Record<string, ValidateResult> | string,
    message?: string | undefined,
  ) => void,
) {
  useEffect(() => {
    if (validationErrors instanceof APIValidationError) {
      setError(validationErrors.fields);
    }
  }, [setError, validationErrors]);
}
