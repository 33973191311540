import { InputSize } from '../../components/Inputs/types';

export enum UserStates {
  CREATING_REGULAR = 'CREATING_REGULAR',
  CREATING_ENTITY = 'CREATING_ENTITY',
  CREATING_EDUCATOR = 'CREATING_EDUCATOR',
  CREATING_ENTITY_PENDING = 'CREATING_ENTITY_PENDING',
  CREATED = 'CREATED',
  DISABLED = 'DISABLED',
  ACCEPTING_LOPD = 'ACCEPTING_LOPD',
}

export enum DocumentationTypes {
  CONVENIO = 'CON',
}
export interface DocumentationItem {
  documentation_id: number;
  code: string;
  name: string;
  file_id: number | null;
  filename: string;
  original_name: string;
  upload: string;
  update: string;
  mandatory?: boolean;
  id: number;
  type?: string;
  amount?: number;
  requires_follow_up?: string | number | boolean;
  agreement_number?: number | string;
  tracking_id?: number;
}

export type ActionsDocument = 'checked' | 'upload' | 'download' | 'view' | 'remove';

export interface DocUpload {
  id: number;
  filename: string;
}

export type AddressData = {
  address_type?: AddressType;
  address?: string | null;
  flat?: string | null;
  floor?: string | null;
  room?: string | null;
  staircase?: string | null;
  zip_code?: string | null;
  city?: string | null;
  province?: string | null;
  country?: string | null;
};

export type OptionalAddressNames = {
  address_type_name: string;
  address_name: string;
  flat_name: string;
  floor_name: string;
  room_name: string;
  staircase_name: string;
  zip_code_name: string;
  city_name: string;
  province_name: string;
  country_name: string;
};

export type OptionalAddressSizes = {
  address_type_size: InputSize;
  address_size: InputSize;
  flat_size: InputSize;
  floor_size: InputSize;
  room_size: InputSize;
  staircase_size: InputSize;
  zip_code_size: InputSize;
  city_size: InputSize;
  province_size: InputSize;
  country_size: InputSize;
};

export type OrderDirection = 'asc' | 'desc';

export interface ColumnOrderData {
  orderedColumnId: number;
  orderDirection: OrderDirection;
}

export interface ResponseDataInfo {
  success: boolean;
  error: string;
  id: number;
  name: string;
}

export interface ResponseData {
  data: ResponseDataInfo[];
}

export interface APIResponse {
  data: any;
}

export interface KeyValuePair {
  key: number;
  value: string;
}

export interface FileItem {
  filename: string;
  original_name: string;
  updated_at: string;
  id: number;
}

export type Genders = 'Hombre' | 'Mujer' | 'No contesta';

export const gendersArray: Genders[] = ['Hombre', 'Mujer', 'No contesta'];

export type DocType = 'DNI' | 'NIF' | 'NIE' | 'Pasaporte';

export const docTypesArray: DocType[] = ['DNI', 'NIF', 'NIE', 'Pasaporte'];

export type LegalNatures = 'Asociación' | 'Fundación' | 'Cooperativa' | 'Otros';

export const legalNaturesArray: LegalNatures[] = ['Asociación', 'Fundación', 'Cooperativa', 'Otros'];

export type AddressType =
  | 'Avenida'
  | 'Calle'
  | 'Camino'
  | 'Carretera'
  | 'Paseo'
  | 'Plaza'
  | 'Rambla'
  | 'Ronda'
  | 'Travesía'
  | 'Vía';

export type Uuid = string;

export interface UuidNamePair {
  id: Uuid;
  name: string;
}

export interface UuidCodeNamePair extends UuidNamePair {
  code: string;
}

export interface IdNamePair {
  id: number;
  name: string;
}

export interface IdCodeNamePair extends IdNamePair {
  code: string;
}

export interface IdTypeNamePair<T> extends IdNamePair {
  type: T;
}

export interface IdCodeColor extends IdCodeNamePair {
  color: string;
}

export interface CodeNamePair {
  code: string | undefined;
  name: string | undefined;
}

export interface ZipCodeNamePair extends IdCodeNamePair {
  zip_code: string;
  city: string;
}

export interface University {
  province_id: number;
  province_code: string;
  nature: string;
  name: string;
}

export interface MetadataEntity {
  id: number;
  name: string;
  creation_date: string;
}

export interface ZipCodeResponse {
  zip_code: string;
  town: string | null;
  towns: string[];
  province: string | null;
  provinces: string[];
  country: string | null;
  countries: (string | null)[];
}

export interface ModalProps {
  view: boolean;
  body: string;
  title: string;
  yes?: () => void;
  ko?: string;
  ok?: string;
  path?: string;
  children?: JSX.Element;
}

export enum KeyButton {
  CREATE = 'create',
  SAVE = 'save',
  FOLLOW = 'follow',
  MODIFY = 'modify',
  SEND = 'send',
  SEND_INTERVIEW = 'send_interview',
  SEND_ASSESSMENT = 'send_assessment',
  REFUSE = 'refuse',
  REVIEW = 'review',
  VALIDATE = 'validate',
  CANCEL_SIGNATURE = 'cancelSignature',
  APPROVE = 'approve',
  PAY = 'pay',
  LINK_PAYMENT = 'link_payment',
  IMAGE_GALLERY = 'image_gallery',
  HELP = 'help',
  RENOUNCE = 'renounce',
  CLOSE = 'close',
  CONFIRM = 'confirm',
  CANCEL_AGREEMENT = 'cancel_agreement',
  SEND_FINAL_COMPETENCES = 'send_final_competences',
  TRACKING = 'tracking',
  ZINGER = 'zinger',
  EDIT = 'edit',
  SEND_INITIAL_COMPETENCES = 'send_initial_competences',
  SEND_REQUEST = 'send_request',
  CHANGE_STATE = 'change_state',
  ACCEPT = 'accept',
  REJECT = 'reject',
  NO_VALIDATE = 'no_validate',
  ASK_DATE = 'ask_date',
  SEND_CORRECTED_INFORMATION = 'send_corrected_information',
  GET_INFO = 'get_info',
  DENY = 'deny',
  ABANDONMENT = 'abandonment',
  SAVE_AGREEMENT = 'save_agreement',
  UPDATE_SCHEDULE = 'update_schedule',
  SEND_PROVIDER = 'send_provider',
  PAYMENT_ADJUSTMENT = 'payment_adjustment',
  SAVE_FOLLOW = 'save_follow',
  SEND_FOLLOW = 'send_follow',
  TRANSVERSAL_COMPETENCES = 'transversal_competences',
  BLOCK_PAYMENT = 'block_payment',
  SEND_JUSTIFICATIONS = 'send_justifications',
}

export interface ColumnItem {
  field: string;
  title: string;
  lookup?: any;
  editable?: string;
}

export interface TaxRetention {
  id: number;
  code: string;
  name: string;
  percentage: number;
  payment_account: string | null;
  tax_type: string;
  transaction_code: string;
  op_key: string;
  serial: string;
}

export interface ResourceImageData {
  id: number;
  name: string;
  description: string;
  image_id: number;
  image: string;
  user_id: number;
  updated_at: string;
}

export type ExtractKeys<T extends {}, K extends keyof T> = K;

export interface IdBooleanNamePair extends IdNamePair {
  code: number;
}
