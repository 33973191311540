import { ButtonProps } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import DayMonthSelector from '../../../components/DayMonthSelector/dayMonthSelector';
import FormContainer from '../../../components/Forms/FormContainer';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import Title from '../../../components/Title/Title';
import { DATE_FORMAT } from '../../../constants/date';
import { getLastItem } from '../../../helpers/getLastItem';
import { tFormKey, tKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';
import {
  getConfigurationFollowup,
  runConfigurationFollowup,
  sendConfigurationFollowup,
} from '../../../redux/scholarship/actions';
import { selectConfiguratorFollowup, selectIsLoading } from '../../../redux/scholarship/selectors';
import { FollowUpScholarship } from '../../../validations/followUpScholarship';
import SubmenuConfiguration from '../SubmenuConfiguration';
import styles from './followupDates.module.scss';

interface FollowupDatesProps extends RouteComponentProps {
  sendConfigurationFollowup: (data: any) => void;
  getConfigurationFollowup: () => void;
  runConfiturationFollowup: () => void;
  configurationFollowupData: any;
  loading: boolean;
}

const FollowupDates: React.FC<FollowupDatesProps> = ({
  match,
  sendConfigurationFollowup,
  runConfiturationFollowup,
  getConfigurationFollowup,
  configurationFollowupData,
  loading,
}) => {
  const { path } = match;

  const [confirmModal, setConfirmModal] = React.useState({
    view: false,
    children: <span />,
    title: tKey('¡Atención!'),
  });

  const {
    t1_tracking_date,
    t2_tracking_date,
    tfinal_tracking_date,
    s1_tracking_date,
    sfinal_tracking_date,
    start_follow_up_day,
    start_follow_up_month,
  } = configurationFollowupData;

  const { control, errors, handleSubmit, watch, getValues } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: FollowUpScholarship,
  });

  useEffect(() => {
    getConfigurationFollowup();
  }, [getConfigurationFollowup]);

  const onSave = async () => {
    const data = getValues();

    data.t1_tracking_date =
      data.t1_tracking_date !== null ? moment.utc(data.t1_tracking_date, DATE_FORMAT).format('X') : null;
    data.t2_tracking_date =
      data.t2_tracking_date !== null ? moment.utc(data.t2_tracking_date, DATE_FORMAT).format('X') : null;
    data.tfinal_tracking_date =
      data.tfinal_tracking_date !== null ? moment.utc(data.tfinal_tracking_date, DATE_FORMAT).format('X') : null;
    data.s1_tracking_date =
      data.s1_tracking_date !== null ? moment.utc(data.s1_tracking_date, DATE_FORMAT).format('X') : null;
    data.sfinal_tracking_date =
      data.sfinal_tracking_date !== null ? moment.utc(data.sfinal_tracking_date, DATE_FORMAT).format('X') : null;

    sendConfigurationFollowup(data);
  };

  const buttons: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      onClick: handleSubmit(onSave),
    },
    {
      children: tKey('Ejecutar seguimientos'),
      disableElevation: true,
      fullWidth: true,
      color: 'secondary',
      variant: 'contained',
      onClick: () => setConfirmModal(prevState => ({ ...prevState, view: true })),
    },
  ];

  const watchDate = (name: string) => {
    const parsed = parseFloat(moment.utc(watch(name), DATE_FORMAT).format('X'));
    return moment(parsed * 1000).format();
  };

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuConfiguration selected={getLastItem(path)} submenu={tFormKey('Configurar fechas de seguimiento')} />
      }
      rightSubmenu={<ActionsMenu actionsButtons={buttons} />}
    >
      <Title>{tFormKey('Configuración fechas de seguimiento')}</Title>
      {!loading ? (
        <div className={styles.generalContainer}>
          <div className={styles.firstRow}>
            <div className={styles.boxContainer}>
              <FormContainer title={tFormKey('Seguimiento Trimestral')}>
                <div className={styles.containerWithPadding}>
                  <KeyboardDatePickerController
                    label={tFormKey('Seguimiento 1T')}
                    name="t1_tracking_date"
                    control={control}
                    schema={FollowUpScholarship}
                    defaultValue={t1_tracking_date}
                    errors={errors}
                    size="20"
                  />
                  <KeyboardDatePickerController
                    label={tFormKey('Seguimiento 2T')}
                    name="t2_tracking_date"
                    control={control}
                    minDate={
                      watchDate('t1_tracking_date') === undefined
                        ? moment(t1_tracking_date * 1000).format()
                        : watchDate('t1_tracking_date')
                    }
                    schema={FollowUpScholarship}
                    defaultValue={t2_tracking_date}
                    errors={errors}
                    size="20"
                  />
                  <KeyboardDatePickerController
                    label={tFormKey('Seguimiento Final')}
                    name="tfinal_tracking_date"
                    control={control}
                    minDate={
                      watchDate('t2_tracking_date') === undefined
                        ? moment(t2_tracking_date * 1000).format()
                        : watchDate('t2_tracking_date')
                    }
                    schema={FollowUpScholarship}
                    defaultValue={tfinal_tracking_date}
                    errors={errors}
                    size="20"
                  />
                </div>
              </FormContainer>
            </div>
            <div className={styles.boxContainer}>
              <FormContainer title={tFormKey('Seguimiento Semestral')}>
                <div className={styles.containerWithPadding}>
                  <KeyboardDatePickerController
                    label={tFormKey('Seguimiento 1S')}
                    name="s1_tracking_date"
                    control={control}
                    schema={FollowUpScholarship}
                    defaultValue={s1_tracking_date}
                    errors={errors}
                    size="20"
                  />
                  <KeyboardDatePickerController
                    label={tFormKey('Seguimiento Final')}
                    name="sfinal_tracking_date"
                    control={control}
                    minDate={
                      watchDate('s1_tracking_date') === undefined
                        ? moment(s1_tracking_date * 1000).format()
                        : watchDate('s1_tracking_date')
                    }
                    schema={FollowUpScholarship}
                    defaultValue={sfinal_tracking_date}
                    errors={errors}
                    size="20"
                  />
                </div>
              </FormContainer>
            </div>
          </div>
          <DayMonthSelector
            dayMonthSchema={FollowUpScholarship}
            control={control}
            errors={errors}
            day={start_follow_up_day}
            month={start_follow_up_month}
            title={'Inicio de seguimientos'}
            nameDay={'start_follow_up_day'}
            nameMonth={'start_follow_up_month'}
            subTitle={''}
          />
          <TransitionModal
            view={confirmModal.view}
            documentMode
            handleClose={() => {
              setConfirmModal(prevState => ({ ...prevState, view: false }));
            }}
            handleYes={() => {
              runConfiturationFollowup();
              setConfirmModal(prevState => ({ ...prevState, view: false }));
            }}
            buttonOk={tKey('Ejectuar')}
            buttonKo={tKey('Cancelar')}
            title={confirmModal.title}
            bodyModal={tKey('FollowupDatesP1')}
          >
            {confirmModal.children}
          </TransitionModal>
        </div>
      ) : (
        <Loading big />
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  configurationFollowupData: selectConfiguratorFollowup(state),
  loading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  sendConfigurationFollowup: (data: any) => dispatch(sendConfigurationFollowup(data)),
  runConfiturationFollowup: () => dispatch(runConfigurationFollowup()),
  getConfigurationFollowup: () => dispatch(getConfigurationFollowup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowupDates);
