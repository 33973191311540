export enum InternalProjectTemplatesConst {
  GET_INTERNAL_PROJECT_TEMPLATES_METADATA = 'GET:INTERNAL:PROJECT:TEMPLATES:METADATA',
  GET_INTERNAL_PROJECT_TEMPLATES_METADATA_OK = 'GET:INTERNAL:PROJECT:TEMPLATES:METADATA:OK',
  GET_INTERNAL_PROJECT_TEMPLATES_METADATA_KO = 'GET:INTERNAL:PROJECT:TEMPLATES:METADATA:KO',

  GET_INTERNAL_PROJECT_TEMPLATES_INFO = 'GET:INTERNAL:PROJECT:TEMPLATES:INFO',
  GET_INTERNAL_PROJECT_TEMPLATES_INFO_OK = 'GET:INTERNAL:PROJECT:TEMPLATES:INFO:OK',
  GET_INTERNAL_PROJECT_TEMPLATES_INFO_KO = 'GET:INTERNAL:PROJECT:TEMPLATES:INFO:KO',

  SET_INTERNAL_PROJECT_TEMPLATE = 'SET:INTERNAL:PROJECT:TEMPLATE',

  DELETE_INTERNAL_PROJECT_TEMPLATE = 'DELETE:INTERNAL:PROJECT:TEMPLATE',
  DELETE_INTERNAL_PROJECT_TEMPLATE_OK = 'DELETE:INTERNAL:PROJECT:TEMPLATE:OK',
  DELETE_INTERNAL_PROJECT_TEMPLATE_KO = 'DELETE:INTERNAL:PROJECT:TEMPLATE:KO',

  GET_INTERNAL_PROJECT_TEMPLATE_INFO = 'GET:INTERNAL:PROJECT:TEMPLATE:INFO',
  GET_INTERNAL_PROJECT_TEMPLATE_INFO_OK = 'GET:INTERNAL:PROJECT:TEMPLATE:INFO:OK',
  GET_INTERNAL_PROJECT_TEMPLATE_INFO_KO = 'GET:INTERNAL:PROJECT:TEMPLATE:INFO:KO',

  SET_INTERNAL_PROJECT_TEMPLATE_DATA = 'SET:INTERNAL:PROJECT:TEMPLATE:DATA',

  UPDATE_INTERNAL_PROJECT_TEMPLATE = 'UPDATE:INTERNAL:PROJECT:TEMPLATE',
  UPDATE_INTERNAL_PROJECT_TEMPLATE_OK = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:OK',
  UPDATE_INTERNAL_PROJECT_TEMPLATE_KO = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:KO',

  SET_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA = 'SET:INTERNAL:PROJECT:TEMPLATE:ROLE:DATA',
  REMOVE_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA = 'REMOVE:INTERNAL:PROJECT:TEMPLATE:ROLE:DATA',

  SET_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA = 'SET:INTERNAL:PROJECT:TEMPLATE:GENERAL:FORM:ROLE:DATA',
  REMOVE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA = 'REMOVE:INTERNAL:PROJECT:TEMPLATE:GENERAL:FORM:ROLE:DATA',

  UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:GENERAL:FORM:ROLES',
  UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_OK = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:GENERAL:FORM:ROLES:OK',
  UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_KO = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:GENERAL:FORM:ROLES:KO',

  CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP = 'CREATE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP',
  CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK = 'CREATE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:OK',
  CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO = 'CREATE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:KO',

  SET_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA = 'SET:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:ROLE:DATA',
  REMOVE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA = 'REMOVE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:ROLE:DATA',

  UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:ROLES',
  UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_OK = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:ROLES:OK',
  UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_KO = 'UPDATE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:ROLES:KO',

  DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP = 'DELETE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP',
  DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK = 'DELETE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:OK',
  DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO = 'DELETE:INTERNAL:PROJECT:TEMPLATE:FOLLOW:UP:KO',

  PUBLISH_INTERNAL_PROJECT_TEMPLATE = 'PUBLISH:INTERNAL:PROJECT:TEMPLATE',
  PUBLISH_INTERNAL_PROJECT_TEMPLATE_OK = 'PUBLISH:INTERNAL:PROJECT:TEMPLATE:OK',
  PUBLISH_INTERNAL_PROJECT_TEMPLATE_KO = 'PUBLISH:INTERNAL:PROJECT:TEMPLATE:KO',

  GENERIC_KO = 'INTERNAL:PROJECT:TEMPLATES:GENERIC:KO',
}
