import { ButtonProps } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CustomSimpleEditableTable, {
  ColumnTableProps,
} from '../../../../../components/Tables/CustomSimpleEditableTable/CustomSimpleEditableTable';
import Title from '../../../../../components/Title/Title';
import { tFormKey, tKey } from '../../../../../helpers/translate';
import {
  setEnrollmentDropDownData,
  setEnrollmentNumericData,
  setEnrollmentTextData,
  updateInternalProjectEnrollments,
} from '../../../../../redux/internal-projects/actions';
import {
  Follows,
  InternalProjectDispatch,
  InternalProjectEnrollment,
  InternalProjectEnrollmentMetadata,
  InternalProjectSections,
} from '../../../../../redux/internal-projects/definitions';
import { selectKeepGoingEnrollemts } from '../../../../../redux/internal-projects/selectors';
import { AppState } from '../../../../../redux/root-reducer';
import InternalProjectLayoutForm from '../../InternalProjectLayoutForm';
import InformationMinimal from '../Information/InformationMinimal';
import styles from './StudentFollows.module.scss';
import { finishesWorkshopOptions } from './StudentsFollowsListOptions';

interface StudentFollowsProps {
  id: number;
  disabled?: boolean;
  enrollments: InternalProjectEnrollment[];
  loading: boolean;
  follows: Follows[];
  keepGoingEnrollments: InternalProjectEnrollment[];
  enrollmentMetadata: InternalProjectEnrollmentMetadata;
  handleGoBack?: () => void;
  handleChangeSection: (componentId: string) => void;
  setTextData: (enrollment_id: number, property: string, value: string | null) => void;
  setNumericData: (enrollment_id: number, property: string, value: number | null) => void;
  setDropDownData: (enrollment_id: number, property: string, value: number) => void;
  updateInternalProjectEnrollments: (internal_project_id: number, data: InternalProjectEnrollment[]) => void;
}

export const createData = (data: InternalProjectEnrollment[]) =>
  data.map((element: InternalProjectEnrollment) => {
    return {
      ...element,
      isEditMode: false,
    };
  });

const StudentFollows: React.FC<StudentFollowsProps> = ({
  id,
  disabled,
  enrollments,
  loading,
  follows,
  enrollmentMetadata,
  keepGoingEnrollments,
  handleGoBack,
  handleChangeSection,
  setTextData,
  setNumericData,
  setDropDownData,
  updateInternalProjectEnrollments,
}) => {
  const { unfinished_reasons, enrolled_plan, training_types } = enrollmentMetadata;
  const [dataToShow, setDataToShow] = useState([] as InternalProjectEnrollment[]);

  const handleSaveEnrollments = () => {
    updateInternalProjectEnrollments(id, enrollments);
  };

  useEffect(() => {
    setDataToShow(createData(keepGoingEnrollments));
  }, [keepGoingEnrollments]);

  const buttonProps: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: disabled,
      onClick: handleSaveEnrollments,
    },
  ];
  interface AttendanceReasonOptionItem {
    name: string;
    id: string;
  }

  const attendance_reasonOptions: AttendanceReasonOptionItem[] = [
    { name: tKey('Sí'), id: 'Sí' },
    {
      name: tKey('ip.no_justificado_motivos_enfermedad_visita médica'),
      id: 'No, justificado (enfermedad/visita médica)',
    },
    {
      name: tKey('ip.no_justificado_motivos_familiares_personales'),
      id: 'No, justificado (motivos familiares/personales)',
    },

    {
      name: tKey('ip.no_justificado_motivos_laborales_formativos'),
      id: 'No, justificado (motivos laborales/formativos)',
    },
    {
      name: tKey('ip.no_justificado_no_motivo'),
      id: 'No, justificado (no sabemos el motivo)',
    },
    {
      name: tKey('ip.no_injustificado'),
      id: 'No, justificado',
    },
    {
      name: tKey('ip.no_continua'),
      id: 'No, continua',
    },
  ];

  const attendace_reason = [] as ColumnTableProps[];
  follows.forEach((f: Follows, index: number) => {
    if (f.section === InternalProjectSections.SEGUIMIENTO) {
      attendace_reason.push({
        title: tFormKey('ip.tabla_participantes_asistencia_campo') + ' ' + index,
        name: 'attendance_reason' + index,
        align: 'left',
        width: '200px',
        type: 'List',
        options: attendance_reasonOptions,
      } as ColumnTableProps);
    }
  });
  const columns = [
    { title: tKey('Nombre'), name: 'name', type: 'Text', editable: 'never' },
    { title: tKey('Apellidos'), name: 'surnames', type: 'Text', editable: 'never' },
    {
      title: tFormKey('ip.tabla_participantes_LOPD'),
      name: 'link_provided',
      align: 'center',
      width: '120px',
      type: 'Boolean',
    },
    {
      title: tFormKey('ip.tabla_participantes_fotos_videos'),
      name: 'photos_videos_accepted',
      align: 'center',
      width: '120px',
      type: 'Boolean',
    },
    {
      title: tFormKey('ip.tabla_participantes_grupo_whatsapp'),
      name: 'whatsapp_group_accepted',
      align: 'center',
      width: '135px',
      type: 'Boolean',
    },
    ...attendace_reason,
    {
      title: tFormKey('ip.tabla_participantes_taller'),
      name: 'finishes_workshop',
      width: '220px',
      type: 'List',
      options: finishesWorkshopOptions,
    },
    {
      title: tFormKey('ip.tabla_participantes_motivo_taller'),
      name: 'reason_unfinished_id',
      width: '300px',
      type: 'List',
      options: unfinished_reasons,
    },

    {
      title: tFormKey('ip.tabla_participantes_plan_joven'),
      name: 'enrolled_plan_id',
      width: '200px',
      type: 'List',
      options: enrolled_plan,
    },
    {
      title: tFormKey('ip.tabla_participantes_tipo_formacion') + ' 1',
      name: 'training_type_id1',
      width: '150px',
      type: 'List',
      options: training_types,
    },
    {
      title: tFormKey('ip.tabla_participantes_tipo_formacion_opcion') + ' 1',
      name: 'training_type_option1',
      width: '200px',
      type: 'Text',
    },
    {
      title: tFormKey('ip.tabla_participantes_tipo_formacion') + '2',
      name: 'training_type_id2',
      width: '200px',
      type: 'List',
      options: training_types,
    },
    {
      title: tFormKey('ip.tabla_participantes_tipo_formacion_opcion') + '2',
      name: 'training_type_option2',
      width: '200px',
      type: 'Text',
    },

    { title: tFormKey('ip.tabla_participantes_estado_beca'), name: 'deserves_grant', align: 'center', type: 'Boolean' },
    { title: tFormKey('ip.tabla_participantes_joven_top'), name: 'is_top', align: 'center', type: 'Boolean' },
    {
      title: tFormKey('ip.tabla_participantes_motivo_beca'),
      name: 'reason_grant_approved_rejected',
      width: '500px',
      type: 'Text',
    },
  ] as ColumnTableProps[];

  const options = {
    filter: false,
    loading,
    fixedColumn: { num: 1, left: 110 },
  };

  const onToggleEditMode = (rowi: InternalProjectEnrollment) => {
    setDataToShow(() => {
      return dataToShow.map(row => {
        if (row.id === rowi.id && !row.isEditMode) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        if (row.id !== rowi.id && row.isEditMode) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChangeText = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    row: InternalProjectEnrollment,
  ) => {
    const { value, name } = e.target;
    const { id } = row;
    setTextData(id, name, value);
  };

  const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>, row: InternalProjectEnrollment) => {
    const { checked, name } = e.target;
    const { id } = row;
    setNumericData(id, name, checked ? 1 : 0);
  };

  const onChangeSelectOption = (
    e: React.ChangeEvent<{ name: string; value: number }>,
    row: InternalProjectEnrollment,
  ) => {
    const { value, name } = e.target;
    const { id } = row;
    setDropDownData(id, name, value);
  };

  return (
    <InternalProjectLayoutForm
      sectionSelected={'panel_control'}
      actionsButtons={buttonProps}
      handleChangeSection={handleChangeSection}
      handleGoBack={handleGoBack}
    >
      <Fragment>
        <Title>{tKey('ip.seguimientos_participantes')}</Title>
        <InformationMinimal />
        <div className={styles.tableContainer}>
          <CustomSimpleEditableTable
            columns={columns}
            rows={dataToShow}
            title={tFormKey('ip.seguimientos_tabla_participantes')}
            option={options}
            exportExcel={true}
            onToggleEditMode={onToggleEditMode}
            onChangeText={onChangeText}
            onChangeCheckBox={onChangeCheckBox}
            onChangeSelectOption={onChangeSelectOption}
          />
        </div>
      </Fragment>
    </InternalProjectLayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectReducer.loading,
  follows: state.internalProjectReducer.follows,
  keepGoingEnrollments: selectKeepGoingEnrollemts(state),
  enrollmentMetadata: state.internalProjectReducer.enrollmentMetadata,
});

const mapDispatchToProps = (dispatch: InternalProjectDispatch) => ({
  setTextData: (enrollment_id: number, property: string, value: string | null): void =>
    dispatch(setEnrollmentTextData(enrollment_id, property, value)),
  setNumericData: (enrollment_id: number, property: string, value: number | null): void =>
    dispatch(setEnrollmentNumericData(enrollment_id, property, value)),
  setDropDownData: (enrollment_id: number, property: string, value: number): void =>
    dispatch(setEnrollmentDropDownData(enrollment_id, property, value)),
  updateInternalProjectEnrollments: (internal_project_id: number, data: InternalProjectEnrollment[]) =>
    dispatch(updateInternalProjectEnrollments(internal_project_id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentFollows);
