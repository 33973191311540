import { ButtonProps } from '@material-ui/core';
import React from 'react';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import styles from './entityMenuRight.module.scss';

interface Props {
  actionButtons: ButtonProps[];
  moreInfoButtons?: ButtonProps[];
  goBack?: boolean;
  onGoBack?: any;
}
const EntityMenuRight: React.FC<Props> = ({ actionButtons, moreInfoButtons = [], goBack, onGoBack }) => {
  return (
    <div className={styles.container}>
      <ActionsMenu
        actionsButtons={actionButtons}
        moreInfoButtons={moreInfoButtons}
        goBack={goBack}
        onGoBack={onGoBack}
      />
    </div>
  );
};

export default EntityMenuRight;
