import { put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import {
  assigmentPoint,
  createCustomfieldApi,
  createKpiIndicatorFromApi,
  deactivateCustomfieldApi,
  deleteCustomfieldApi,
  deleteKpiIndicatorFromApi,
  editCustomfieldApi,
  editKpiIndicatorFromApi,
  getCustomFieldsApi,
  getKpiIndicatorFromApi,
  getKpiIndicatorsFromApi,
  getOneCustomFieldsApi,
  unAssigmentPoint,
} from '../../api/CustomField/customField';
import assertUnreachable from '../../helpers/assertUnreachable';
import errorMessage from '../../helpers/errorMessage';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  assigmentOk,
  createCustomfieldOk,
  createKpiIndicatorOk,
  customFieldsGenericKo,
  customFieldValidationErrors,
  deactivateCustomFieldKo,
  deactivateCustomFieldOk,
  deleteCustomfieldDeactivate,
  deleteCustomfieldOk,
  editCustomfieldOk,
  getCustomFieldListOk,
  getKpiIndicatorOk,
  getKpiIndicatorsOk,
  getOneCustomFieldOK,
  removeKpiIndicatorOk,
  unassigmentOk,
  updateKpiIndicatorOk,
} from './action';
import { CustomFieldsConst } from './action_types';
import {
  Assigment,
  CreateCustomfield,
  CreateKpiIndicatorAction,
  DeactivateCustomFieldAction,
  DeleteCustomfieldAction,
  EditCustomfield,
  GetCustomFieldList,
  GetKpiIndicatorAction,
  GetKpiIndicatorsAction,
  GetOneCustomField,
  RemoveKpiIndicatorAction,
  Unassigment,
  UpdateKpiIndicatorAction,
} from './definitions';

function* getCustomFieldListFromSaga(action: GetCustomFieldList): Generator<any, void, any> {
  try {
    const { code } = action;
    const { data: listCustomFields } = yield getCustomFieldsApi(code);
    yield put(getCustomFieldListOk(listCustomFields));
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}
function* getOneCustomFieldFromSaga(action: GetOneCustomField): Generator<any, void, any> {
  try {
    // yield put(getCustomFieldList('kpi'));
    const { code, id } = action;
    const { data: customFields } = yield getOneCustomFieldsApi(code, id);
    yield put(getOneCustomFieldOK(customFields));
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}

function* createCustomFieldFromSaga(action: CreateCustomfield): Generator<any, void, any> {
  try {
    const { data } = action;
    const { section, field } = data;
    const result = (yield createCustomfieldApi(data)) as Await<ReturnType<typeof createCustomfieldApi>>;
    switch (result.type) {
      case 'ok':
        yield put(createCustomfieldOk(data));
        yield put(
          showSnackbar(
            'El campo se ha creado correctamente',
            'success',
            `/configuracion/${field}/configuracion-campos/${section}/config`,
            1000,
          ),
        );
        return;
      case 'validation-error':
        const error = result.value;
        yield put(customFieldValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}

function* editCustomFieldFromSaga(action: EditCustomfield): Generator<any, void, any> {
  try {
    const { data, id } = action;
    const result = (yield editCustomfieldApi(data, id)) as Await<ReturnType<typeof editCustomfieldApi>>;
    switch (result.type) {
      case 'ok':
        yield put(editCustomfieldOk(data));
        yield put(showSnackbar('El campo se ha guardado correctamente', 'success', undefined, 1000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(customFieldValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}

function* deleteCustomFieldFromSaga(action: DeleteCustomfieldAction): Generator<any, void, any> {
  try {
    const result = yield deleteCustomfieldApi(action.id);
    if (result === 'OK') {
      yield put(deleteCustomfieldOk(action.id));
      yield put(showSnackbar('El campo se ha eliminado correctamente', 'success'));
    } else if (result === 'deactivate') {
      yield put(deleteCustomfieldDeactivate(action.id));
    }
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* deactivateCustomFieldFromSaga(action: DeactivateCustomFieldAction): Generator<any, void, any> {
  try {
    const result = yield deactivateCustomfieldApi(action.id);
    if (result === 'OK') {
      yield put(deactivateCustomFieldOk(action.id));
      yield put(showSnackbar('El campo se ha desactivado correctamente', 'success'));
    }
  } catch (e) {
    yield put(deactivateCustomFieldKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* assigmentFromSaga(action: Assigment): Generator<any, void, any> {
  try {
    const { point, data } = action;
    yield assigmentPoint(point, data);
    yield put(assigmentOk());
    yield put(showSnackbar(`El campo se ha asignado correctamente`, 'success', undefined, 1000));
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}

function* unassigmentFromSaga(action: Unassigment): Generator<any, void, any> {
  try {
    const { point, data, section } = action;
    yield unAssigmentPoint(point, data);
    const { data: listCustomFields } = yield getCustomFieldsApi(section);
    yield put(getCustomFieldListOk(listCustomFields));
    yield put(unassigmentOk());
    yield put(showSnackbar(`El campo se ha desasignado correctamente`, 'success', undefined, 1000));
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}

function* getKpisIndicatorsFromSaga(): Generator<any, void, any> {
  try {
    const kpiTypes = yield getKpiIndicatorsFromApi();
    yield put(getKpiIndicatorsOk(kpiTypes));
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}

function* getKpiIndicatorFromSaga(action: GetKpiIndicatorAction): Generator<any, void, any> {
  try {
    const kpiType = yield getKpiIndicatorFromApi(action.id);
    yield put(getKpiIndicatorOk(kpiType));
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
  }
}

function* createKpiIndicatorFromSaga(action: CreateKpiIndicatorAction): Generator<any, void, any> {
  try {
    const result = (yield createKpiIndicatorFromApi(action.kpiType)) as Await<
      ReturnType<typeof createKpiIndicatorFromApi>
    >;
    switch (result.type) {
      case 'ok':
        yield put(createKpiIndicatorOk(result.value.data));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(customFieldValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* updateKpiIndicatorFromSaga(action: UpdateKpiIndicatorAction): Generator<any, void, any> {
  try {
    const result = (yield editKpiIndicatorFromApi(action.kpiType)) as Await<ReturnType<typeof editKpiIndicatorFromApi>>;
    switch (result.type) {
      case 'ok':
        yield put(updateKpiIndicatorOk(result.value.data));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(customFieldValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* removeKpiIndicatorFromSaga(action: RemoveKpiIndicatorAction): Generator<any, void, any> {
  try {
    yield deleteKpiIndicatorFromApi(action.id);
    yield put(removeKpiIndicatorOk(action.id));
  } catch (e) {
    yield put(customFieldsGenericKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

export default [
  takeLatest<GetCustomFieldList>(CustomFieldsConst.GET_CUSTOMFIELDS_LIST, getCustomFieldListFromSaga),
  takeLatest<GetOneCustomField>(CustomFieldsConst.GET_ONE_CUSTOMFIELDS, getOneCustomFieldFromSaga),
  takeLatest<CreateCustomfield>(CustomFieldsConst.CREATE_CUSTOMFIELD, createCustomFieldFromSaga),
  takeLatest<EditCustomfield>(CustomFieldsConst.EDIT_CUSTOMFIELD, editCustomFieldFromSaga),
  takeLatest<DeleteCustomfieldAction>(CustomFieldsConst.DELETE_CUSTOMFIELD, deleteCustomFieldFromSaga),
  takeLatest<Assigment>(CustomFieldsConst.ASSIGMENTS, assigmentFromSaga),
  takeLatest<Unassigment>(CustomFieldsConst.UNASSIGMENTS, unassigmentFromSaga),
  takeLatest<GetKpiIndicatorsAction>(CustomFieldsConst.GET_KPI_INIDICATORS, getKpisIndicatorsFromSaga),
  takeLatest<GetKpiIndicatorAction>(CustomFieldsConst.GET_KPI_INIDICATOR, getKpiIndicatorFromSaga),
  takeLatest<CreateKpiIndicatorAction>(CustomFieldsConst.CREATE_KPI_INIDICATOR, createKpiIndicatorFromSaga),
  takeLatest<UpdateKpiIndicatorAction>(CustomFieldsConst.UPDATE_KPI_INIDICATOR, updateKpiIndicatorFromSaga),
  takeLatest<RemoveKpiIndicatorAction>(CustomFieldsConst.REMOVE_KPI_INIDICATOR, removeKpiIndicatorFromSaga),
  takeLatest<DeactivateCustomFieldAction>(CustomFieldsConst.DEACTIVATE_CUSTOMFIELD, deactivateCustomFieldFromSaga),
];
