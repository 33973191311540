import React from 'react';
import webStyle from '../../helpers/webStyle';
import styles from './subtitle.module.scss';

interface SubTitleProps {
  children?: string;
  style?: object;
}
const SubTitle: React.FC<SubTitleProps> = ({ children, style }) => (
  <h2 style={style} className={`${styles.root} ${styles[`title_${webStyle}`]}`}>
    {children}
  </h2>
);

export default SubTitle;
