import { Action } from 'redux';
import { APIError } from '../../api/api';
import { DocumentationItem, FileItem } from '../common/definitions';
import { AboutUsConsts } from './action_types';

export interface AboutUsTypesMetadata {
  id: number;
  code: string;
  name: string;
}

export interface ShortDocumentationItem {
  documentation_id: number;
  name: string;
  file_id: number;
}

export interface AboutUsMetada {
  [x: string]: any;
  aboutUsTypes: AboutUsTypesMetadata[];
}

export interface AboutUsResource {
  id: number;
  description: string;
  code: string;
  about_us_type_id: number;
  files: DocumentationItem[];
  monterri?: boolean;
  originalName?: string;
}

export interface AboutUsTypesWithResources {
  resources: AboutUsResource[];
  id: number;
  name: string;
}

// INITIAL STATE
export const initialState = {
  loading: false,
  error: null as APIError | null,
  metadata: {} as AboutUsMetada,
  resources: [] as AboutUsResource[],
  oneResource: {} as AboutUsResource,
  documents: [] as DocumentationItem[],
};

export type AboutUsState = typeof initialState;

// TYPESCRIPT DEFINITONS FOR HANDLING ACTIONS

export interface GetMetadataAction extends Action {
  type: AboutUsConsts.ABOUTUS_METADATA;
}

export interface GetMetadataOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_METADATA_OK;
  metadata: AboutUsMetada;
}

export interface GetMetadataKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_METADATA_KO;
  error: APIError;
}

export interface GetResourcesAction extends Action {
  type: AboutUsConsts.ABOUTUS_RESOURCES;
  code: string;
}

export interface GetResourcesOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_RESOURCES_OK;
  resources: AboutUsResource[];
}

export interface GetResourcesKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_RESOURCES_KO;
  error: APIError;
}

export interface GetOneResourcesAction extends Action {
  type: AboutUsConsts.ABOUTUS_ONE_RESOURCES;
  id: number;
}

export interface GetOneResourcesOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_ONE_RESOURCES_OK;
  resource: AboutUsResource;
}

export interface GetOneResourcesKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_ONE_RESOURCES_KO;
  error: APIError;
}

export interface GetInitialResourceAction extends Action {
  type: AboutUsConsts.ABOUTUS_INITIAL_RESOURCE;
}

export interface GetInitialResourceOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_INITIAL_RESOURCE_OK;
  metadata: AboutUsMetada;
}

export interface GetInitialResourceKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_INITIAL_RESOURCE_KO;
  error: APIError;
}

export interface NewDocumentAction extends Action {
  type: AboutUsConsts.ABOUTUS_NEW_DOCUMENT;
  file: File;
  name: string;
}

export interface NewDocumentOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_NEW_DOCUMENT_OK;
  data: FileItem;
  name: string;
}

export interface NewDocumentKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_NEW_DOCUMENT_KO;
  error: APIError;
}

export interface DownloadDocumentAction extends Action {
  type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT;
  documentation_id: number;
  file_id: number;
  name: string;
}

export interface DownloadDocumentOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_OK;
  data: Blob;
  name: string;
}

export interface DownloadDocumentKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_KO;
  error: APIError;
}

export interface DownloadDocumentByCodeAction extends Action {
  type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE;
  code: string;
  name: string;
}

export interface DownloadDocumentByCodeOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_OK;
  data: Blob;
  name: string;
}

export interface DownloadDocumentByCodeKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_KO;
  error: APIError;
}

export interface RemoveDocumentAction extends Action {
  type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT;
  file_id: number;
  resource_id: number;
}
export interface RemoveDocumentOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_KO;
  error: APIError;
}

export interface OpenDocumentAction extends Action {
  type: AboutUsConsts.ABOUTUS_OPEN_DOCUMENT;
  file_id: number;
  name: string;
  resource_id: number;
}
export interface OpenDocumentOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_OPEN_DOCUMENT_OK;
  data: Blob;
}
export interface OpenDocumentKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_OPEN_DOCUMENT_KO;
  error: APIError;
}

export interface SaveResourceAction extends Action {
  type: AboutUsConsts.ABOUTUS_SAVE_RESOURCE;
  resource: AboutUsResource;
  redirect: string;
}
export interface SaveResourceOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_SAVE_RESOURCE_OK;
  resource: AboutUsResource;
}
export interface SaveResourceKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_SAVE_RESOURCE_KO;
  error: APIError;
}

export interface NewResourceAction extends Action {
  type: AboutUsConsts.ABOUTUS_NEW_RESOURCE;
  resource: AboutUsResource;
  redirect: string;
}
export interface NewResourceOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_NEW_RESOURCE_OK;
}
export interface NewResourceKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_NEW_RESOURCE_KO;
  error: APIError;
}

// REMOVE DOCUMENT BY ID

export interface RemoveDocumentByIdAction extends Action {
  type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID;
  file_id: number;
  documents: DocumentationItem[];
}
export interface RemoveDocumentByIdOkAction extends Action {
  type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentByIdKoAction extends Action {
  type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID_KO;
  error: APIError;
}

export type AboutUsAction =
  | GetMetadataAction
  | GetMetadataOkAction
  | GetMetadataKoAction
  | GetResourcesAction
  | GetResourcesOkAction
  | GetResourcesKoAction
  | GetOneResourcesAction
  | GetOneResourcesOkAction
  | GetOneResourcesKoAction
  | NewDocumentAction
  | NewDocumentOkAction
  | NewDocumentKoAction
  | DownloadDocumentAction
  | DownloadDocumentOkAction
  | DownloadDocumentKoAction
  | DownloadDocumentByCodeAction
  | DownloadDocumentByCodeOkAction
  | DownloadDocumentByCodeKoAction
  | RemoveDocumentAction
  | RemoveDocumentOkAction
  | RemoveDocumentKoAction
  | OpenDocumentAction
  | OpenDocumentOkAction
  | OpenDocumentKoAction
  | SaveResourceAction
  | SaveResourceOkAction
  | SaveResourceKoAction
  | NewResourceAction
  | NewResourceOkAction
  | NewResourceKoAction
  | GetInitialResourceAction
  | GetInitialResourceOkAction
  | GetInitialResourceKoAction
  | RemoveDocumentByIdAction
  | RemoveDocumentByIdOkAction
  | RemoveDocumentByIdKoAction;

// TYPESCRIPT DEFINITIONS FOR DISPACHING EVENTS

export type AboutUsDispatch = (action: AboutUsAction) => void;
