import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { CSSProperties, Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DatePayments, paymentsDates } from '../../../api/Payment/Payment';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import Layout from '../../../components/Layout/Layout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import * as DateConstants from '../../../constants/date';
import { permissionType } from '../../../constants/permissions_definitions';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { ColumnOrderData, OrderDirection } from '../../../redux/common/definitions';
import { setColumnOrder, setSearchText } from '../../../redux/entity/actions';
import { selectColumnOrder, selectSearchText } from '../../../redux/entity/selectors';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectAcademicYears } from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import {
  getEntitiesWithScholarshipsToPay,
  setEntityPaymentDateFilter,
  setPaymentsAcademicYear,
} from '../../../redux/scholarship/actions';
import { EntitiesScholarships } from '../../../redux/scholarship/definitions';
import {
  selectEntitiesWithScholarshipPayments,
  selectEntityScholarshipPaymentDateFilter,
  selectIsLoading,
  selectPaymentAcademicYear,
} from '../../../redux/scholarship/selectors';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './listEntitiesPayment.module.scss';

const normalCell: CSSProperties = {
  textAlign: 'justify',
};

const disabledCell: CSSProperties = {
  color: '#00000074',
  textAlign: 'justify',
};

const centerCell: CSSProperties = {
  textAlign: 'center',
  width: '200px',
};

const centerDisabledCell: CSSProperties = {
  color: '#00000074',
  textAlign: 'center',
  width: '200px',
};

const selectCSSCell = (active: number, agreement: number) =>
  active === 0 || agreement === 0 ? disabledCell : normalCell;
const selectCSSCenteredCell = (active: number, agreement: number) =>
  active === 0 || agreement === 0 ? centerDisabledCell : centerCell;

const tableData: MaterialTableProps<EntitiesScholarships> = {
  title: tKey('Becas entidades pendientes de pago'),
  columns: [
    {
      title: tKey('Entidad'),
      field: 'entity_name',
      cellStyle: (data: EntitiesScholarships[], rowData: EntitiesScholarships) =>
        selectCSSCell(rowData.active_scholarship, rowData.agreement),
    },
    {
      title: tKey('Becas activas'),
      field: 'active_scholarship',
      headerStyle: {
        textAlign: 'center',
      },
      //@ts-ignore
      width: '200px',
      render: (rowiData: EntitiesScholarships) => {
        return rowiData.active_scholarship === 1 ? <Check /> : <Clear color="error" />;
      },
      cellStyle: (data: EntitiesScholarships[], rowData: EntitiesScholarships) =>
        selectCSSCenteredCell(rowData.active_scholarship, rowData.agreement),
    },
    {
      title: tKey('Pagos mes actual'),
      field: 'payment_in_month',
      headerStyle: {
        textAlign: 'center',
      },
      //@ts-ignore
      width: '200px',
      render: (rowiData: EntitiesScholarships) => {
        return rowiData.payment_in_month === 1 ? <Check /> : <Clear color="error" />;
      },
      cellStyle: (data: EntitiesScholarships[], rowData: EntitiesScholarships) =>
        selectCSSCenteredCell(rowData.payment_in_month, rowData.agreement),
    },
    {
      title: tKey('Convenio confirmado'),
      field: 'agreement',
      headerStyle: {
        textAlign: 'center',
      },
      //@ts-ignore
      width: '200px',
      render: (rowiData: EntitiesScholarships) => {
        return rowiData.agreement === 1 ? <Check /> : <Clear color="error" />;
      },
      cellStyle: (data: EntitiesScholarships[], rowData: EntitiesScholarships) =>
        selectCSSCenteredCell(rowData.active_scholarship, rowData.agreement),
    },
  ],
  data: [],
  options: {
    tableLayout: 'auto',
    selection: false,
    filtering: false,
    pageSize: 100,
    pageSizeOptions: [20, 50, 100],
  },
};

interface ScholarshipsPaymentEntityProps {
  entitiesWithPayments: EntitiesScholarships[];
  academicYears: string[];
  selectedAcademicYear: string;
  entityPaymentDate: moment.Moment | null;
  loading: boolean;
  getEntitiesWithPayments: (academic_year: string, payment_date: string | null) => void;
  setSelectedAcademicYear: (academic_year: string) => void;
  setSearchText: (searchText: string) => void;
  setColumnOrder: (columnOrder: ColumnOrderData) => void;
  userCan: (permission: permissionType) => boolean | undefined;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  setPaymentDateFilter: (paymentDate: moment.Moment | null) => void;
}

export const ListEntitiesPayment: React.FC<ScholarshipsPaymentEntityProps> = ({
  entitiesWithPayments,
  academicYears,
  selectedAcademicYear,
  entityPaymentDate,
  loading,
  userCan,
  setSearchText,
  setColumnOrder,
  getEntitiesWithPayments,
  showSnackbar,
  setSelectedAcademicYear,
  setPaymentDateFilter,
}) => {
  const canIgo = userCan('action_scholarships_permissions');
  const history = useHistory();
  const [replaceDates, setReplaceDates] = useState<{
    find: moment.Moment | null;
    find_error: string | null;
    replace: moment.Moment | null;
    replace_error: string | null;
  }>({ find: null, find_error: null, replace: null, replace_error: null });
  const [paymentDate, setPaymentDate] = React.useState<moment.Moment | null>(null);
  const [hideDisabled, setHideDisabled] = React.useState<boolean>(false);
  const [replaceModal, setReplaceModal] = React.useState(false);

  useEffect(() => {
    if (selectedAcademicYear) {
      const payment_date = entityPaymentDate ? entityPaymentDate.format(DateConstants.DATE_FORMAT) : null;
      getEntitiesWithPayments(selectedAcademicYear, payment_date);
    }
  }, [selectedAcademicYear, getEntitiesWithPayments, entityPaymentDate]);

  const details = (rowData: EntitiesScholarships) => {
    if (rowData.active_scholarship > 0 && rowData.agreement > 0) {
      history.push({
        pathname: `/contabilidad/pago-beca/entity/${rowData.id}`,
        state: { id: rowData.id },
      });
    } else {
      history.push({
        pathname: `/entidad/editar/${rowData.id}`,
        state: { id: rowData.id },
      });
    }
  };

  tableData.isLoading = loading;
  tableData.data = hideDisabled
    ? entitiesWithPayments.filter((e: EntitiesScholarships) => e.active_scholarship === 1 && e.agreement === 1)
    : entitiesWithPayments;

  const findAndReplace = async (replace: boolean) => {
    const error_message = tErrorKey('La fecha no es correcta o está vacía');
    if (replaceDates.find && replaceDates.find.isValid()) {
      setReplaceDates(replaceDates => ({ ...replaceDates, find_error: null }));
    } else {
      setReplaceDates(replaceDates => ({
        ...replaceDates,
        find_error: error_message,
      }));
      return;
    }
    if (replaceDates.replace && replaceDates.replace.isValid()) {
      setReplaceDates(replaceDates => ({ ...replaceDates, replace_error: null }));
    } else {
      setReplaceDates(replaceDates => ({
        ...replaceDates,
        replace_error: error_message,
      }));
      return;
    }

    const replaceData = {
      entity: {
        dateSearch: replaceDates.find?.format('YYYY-MM-DD'),
        dateReplace: replaceDates.replace?.format('YYYY-MM-DD'),
      },
      academic_year: selectedAcademicYear,
      replace_paid: replace,
    } as DatePayments;
    const {
      data: { entity },
    } = await paymentsDates(replaceData);
    showSnackbar(entity.toString().concat(' ', tKey('fechas reemplazadas')), 'success', undefined, 1500);
  };

  return (
    <Layout leftSubmenu={<AccountingSubmenu selected="entity" submenu={tKey('Pagos')} />}>
      <Fragment>
        <div className={styles.academic_year_select}>
          <CheckboxCompo
            name="hide_disabled"
            questionText="Ocultar pagos inactivos"
            defaultValue={hideDisabled ? 1 : 0}
            onChange={value => setHideDisabled(value)}
            form
          />
          <FormControl className={styles.selector} variant="outlined">
            <InputLabel id="academic_course">{tFormKey('Curso académico')}</InputLabel>
            {selectedAcademicYear && (
              <Select
                label={tFormKey('Curso académico')}
                value={selectedAcademicYear}
                onChange={e => setSelectedAcademicYear(e.target.value as string)}
                variant="outlined"
              >
                {academicYears.map(element => (
                  <MenuItem key={element} value={element}>
                    {element}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <FormControl className={styles.findreplace} variant="outlined">
            <KeyboardDatePicker
              name="find_date"
              label={tFormKey('Buscar')}
              format={DateConstants.DATE_FORMAT}
              variant="inline"
              inputVariant="outlined"
              placeholder="" // Prevent google to detect this input as a credit card number
              helperText={replaceDates.find_error}
              value={replaceDates.find}
              className={styles.datePickers}
              onChange={(date: MaterialUiPickersDate) => {
                setReplaceDates(replaceDates => ({ ...replaceDates, find: date }));
              }}
              // disabled={userCan}
              autoOk
            />
            <KeyboardDatePicker
              name="replace_date"
              label={tFormKey('Remplazar por')}
              format={DateConstants.DATE_FORMAT}
              variant="inline"
              inputVariant="outlined"
              placeholder="" // Prevent google to detect this input as a credit card number
              helperText={replaceDates.replace_error}
              value={replaceDates.replace}
              className={styles.datePickers}
              onChange={(date: MaterialUiPickersDate) => {
                setReplaceDates(replaceDates => ({ ...replaceDates, replace: date }));
              }}
              // disabled={userCan}
              autoOk
            />
            <Button
              className={styles.buttonReplace}
              color="primary"
              variant="contained"
              onClick={() => setReplaceModal(true)}
              disabled={!replaceDates.find || !replaceDates.replace}
            >
              {tKey('Reemplazar')}
            </Button>
          </FormControl>
        </div>
        <div className={styles.payment_filter}>
          <FormControl className={styles.filterForm} variant="outlined">
            <KeyboardDatePicker
              name="payment_date"
              label={tFormKey('Fecha de pago')}
              format={DateConstants.DATE_FORMAT}
              variant="inline"
              inputVariant="outlined"
              size="medium"
              placeholder="" // Prevent google to detect this input as a credit card number
              value={paymentDate}
              className={styles.datePickers}
              onChange={(date: MaterialUiPickersDate) => {
                setPaymentDate(date);
              }}
              // disabled={userCan}
              autoOk
            />
            <Button
              className={styles.buttonFilter}
              color="primary"
              variant="contained"
              onClick={() => {
                if (paymentDate && paymentDate.isValid()) {
                  setPaymentDateFilter(paymentDate);
                } else if (!paymentDate) {
                  setPaymentDateFilter(null);
                }
              }}
            >
              {tKey('Buscar')}
            </Button>
          </FormControl>
        </div>
        <TableWithEdit
          tableData={tableData}
          getTextSearch={(textSearch: string) => setSearchText(textSearch)}
          getOrderColumns={(orderedColumnId: number, orderDirection: OrderDirection) =>
            setColumnOrder({ orderedColumnId, orderDirection })
          }
          permission={canIgo}
          filtering
          onRowClick={details}
        />
      </Fragment>

      <TransitionModal
        documentMode
        view={replaceModal}
        handleClose={() => {
          setReplaceModal(false);
        }}
        title={tKey('Actualización de fechas de pago')}
        bodyModal={
          <div style={{ padding: '20px' }}>{tKey('¿Quieres modificar las fechas de pago que están ya pagadas?')}</div>
        }
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        handleYes={() => {
          findAndReplace(true);
          setReplaceModal(false);
        }}
        handleNo={() => {
          findAndReplace(false);
          setReplaceModal(false);
        }}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  entitiesWithPayments: selectEntitiesWithScholarshipPayments(state),
  columnOrder: selectColumnOrder(state),
  searchText: selectSearchText(state),
  userCan: selectUserCan(state),
  academicYears: selectAcademicYears(state),
  selectedAcademicYear: selectPaymentAcademicYear(state),
  entityPaymentDate: selectEntityScholarshipPaymentDateFilter(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setPaymentDateFilter: (paymentDate: moment.Moment | null): void => dispatch(setEntityPaymentDateFilter(paymentDate)),
  setSelectedAcademicYear: (academic_year: string): void => dispatch(setPaymentsAcademicYear(academic_year)),
  getEntitiesWithPayments: (academic_year: string, payment_date: string | null): void =>
    dispatch(getEntitiesWithScholarshipsToPay(academic_year, payment_date)),
  setSearchText: (searchText: string): void => dispatch(setSearchText(searchText)),
  setColumnOrder: (data: ColumnOrderData): void => dispatch(setColumnOrder(data)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ListEntitiesPayment);
