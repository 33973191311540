import { Action } from 'redux';
import { DirectoryDataConsts } from './action_types';

export const initialState = {
  error: null as string | null,
  directoryData: {} as DirectoryData,
  userData: [] as ProfileInfo[],
  loading: false,
  pagination: {
    page: 1,
    total: 6,
  },
};
export interface LastScholarshipData {
  company_name: string;
  company_role: string;
  current_situation: string;
  scholarship_academy: string;
  academic_year: string;
  level: string;
  educational_type: string;
  code: string;
  enitity: number;
  course: string;
}

export interface ProfessionalInfo {
  academic_year: string;
  company_name: string;
  employee_role: string;
}

export interface ProfileInfo {
  name: string;
  avatar: string;
  avatar_file: string;
  id: number;
  email: string;
  birthdate: string;
  city: string;
  country_origin: string;
  last_scholarship_data: LastScholarshipData;
  professional_info: ProfessionalInfo[];
}

export interface PaginationData {
  current_page: number;
  from: null | number;
  last_page: number;
  per_page: number;
  to: null | number;
  total: number;
}

export interface DirectoryData {
  pagination: PaginationData;
  usersData: ProfileInfo[];
}
export interface Pagination {
  total: number;
  page: number;
}

type FilterObject = {
  value: string;
  fieldToSend: string | number;
};

export type InputDirectory =
  | 'age'
  | 'poblation'
  | 'country_origin'
  | 'academic_course'
  | 'educational_level'
  | 'educational_type'
  | 'scholarship_course'
  | 'scholarship_academy'
  | 'entity_id'
  | InputFilterDirectory;

export type InputFilterDirectory = 'name' | 'company_name' | 'employee_role';

export type FilterInputDirectory = Partial<Record<InputDirectory, FilterObject>>;
export type FilterInputDirectorySend = Record<InputDirectory, string | number>;
export type FilterInputTextField = Partial<Record<InputFilterDirectory, string>>;
export type QueryUsers = FilterInputDirectorySend;

export interface ItemMetadata {
  id: number;
  code: string;
  value: string;
}
export interface DirectoryMetadata {
  poblacion: ItemMetadata[];
  countries: ItemMetadata[];
  academic_years: ItemMetadata[];
  educational_types: ItemMetadata[];
  courses: ItemMetadata[];
  educationals_levels: ItemMetadata[];
  profesional: ItemMetadata[];
  provinces: ItemMetadata[];
  academies: ItemMetadata[];
  scholarship_courses: ItemMetadata[];
  entities: ItemMetadata[];
}

//GET DIRECTORY
export interface GetDirectoryDataAction extends Action {
  type: DirectoryDataConsts.GET_DIRECTORY;
  query: QueryUsers;
}

export interface GetDirectoryDataOkAction extends Action {
  type: DirectoryDataConsts.GET_DIRECTORY_OK;
  directoryData: DirectoryData;
}

export interface GetDirectoryDataKoAction extends Action {
  type: DirectoryDataConsts.GET_DIRECTORY_KO;
  error: string;
}

//GET DIRECTORY FILTERED
export interface GetDirectoryFilterDataAction extends Action {
  type: DirectoryDataConsts.GET_DIRECTORY_FILTER;
  query: QueryUsers;
}

export interface GetDirectoryFilterDataOkAction extends Action {
  type: DirectoryDataConsts.GET_DIRECTORY_FILTER_OK;
  directoryData: DirectoryData;
}

export interface GetDirectoryFilterDataKoAction extends Action {
  type: DirectoryDataConsts.GET_DIRECTORY_FILTER_KO;
  error: string;
}

//SEND SUGGESTION
export interface SendSuggestionDataAction extends Action {
  type: DirectoryDataConsts.SEND_SUGGESTION;
  suggestion: string;
}

export interface SendSuggestionDataOkAction extends Action {
  type: DirectoryDataConsts.SEND_SUGGESTION_OK;
}

export interface SendSuggestionDataKoAction extends Action {
  type: DirectoryDataConsts.SEND_SUGGESTION_KO;
  error: string;
}

export interface CleanUserData extends Action {
  type: DirectoryDataConsts.CLEAN_USER_DATA;
}

export interface SetPaginationData extends Action {
  type: DirectoryDataConsts.SET_PAGINATION;
}

export type DirectoryDataAction =
  | GetDirectoryDataAction
  | GetDirectoryDataOkAction
  | GetDirectoryDataKoAction
  | GetDirectoryFilterDataAction
  | GetDirectoryFilterDataOkAction
  | GetDirectoryFilterDataKoAction
  | SendSuggestionDataAction
  | SendSuggestionDataOkAction
  | SendSuggestionDataKoAction
  | CleanUserData
  | SetPaginationData;

export type DirectoryDataDispatch = (action: DirectoryDataAction) => void;
