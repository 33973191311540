import moment from 'moment';
import { getDocs } from '../../helpers/getDocs';
import { checkStepsErrorFields, removeStepsErrorField } from '../../helpers/validator';
import { DocumentationItem } from '../common/definitions';
import { EntityScholarshipPaymentsManager } from './EntityScholarshipPaymentsManager';
import { ScholarshipPaymentsManager } from './ScholarshipPaymentsManager';
import { ScholarshipDataConsts } from './action_types';
import { ScholarshipChangeStateDataConsts } from './changeState/action_types';
import { ScholarshipChangeStateAction } from './changeState/definitions';
import {
  Material,
  ScholarshipAgreementCode,
  ScholarshipContribCodes,
  ScholarshipCurrentSituationCodes,
  ScholarshipData,
  ScholarshipDataAction,
  ScholarshipDataState,
  Transportation,
  initialState,
} from './definitions';
import { FamilyIncomesDataAction } from './familyIncomes/FamilyIncome';
import { CreateFamilyIncomeConst } from './familyIncomes/create/action_types';
import { CreateFamilyIncomeDataAction } from './familyIncomes/create/definitions';
import createFamilyIncomeReducer from './familyIncomes/create/reducers';
import { DeleteFamilyIncomeConst } from './familyIncomes/delete/action_types';
import { DeleteFamilyIncomeDataAction } from './familyIncomes/delete/definitions';
import deleteFamilyIncomeReducer from './familyIncomes/delete/reducers';
import { GetFamilyIncomesConst } from './familyIncomes/list/action_types';
import { GetFamilyIncomesDataAction } from './familyIncomes/list/definitions';
import getFamilyIncomesReducer from './familyIncomes/list/reducers';
import { UpdateFamilyIncomeConst } from './familyIncomes/update/action_types';
import { UpdateFamilyIncomeDataAction } from './familyIncomes/update/definitions';
import updateFamilyIncomeReducer from './familyIncomes/update/reducers';
import { ScholarshipInstitutePaymentsConst } from './institutePayments/action_types';
import { ScholarshipInstitutePaymentsDataAction } from './institutePayments/definitions';
import { ScholarshipTransitionsConst } from './transitions/action_types';
import { ScholarshipTransitionDataAction } from './transitions/definitions';
import scholarshipTransitionReducer from './transitions/reducers';
import scholarshipChangeStateReducer from './changeState/reducers';

const scholarshipReducer = (
  state = initialState,
  action:
    | ScholarshipDataAction
    | FamilyIncomesDataAction
    | ScholarshipInstitutePaymentsDataAction
    | ScholarshipTransitionDataAction
    | ScholarshipChangeStateAction,
): ScholarshipDataState => {
  switch (action.type) {
    case ScholarshipDataConsts.SCHOLARSHIP_GET:
      return {
        ...state,
        loading: true,
        loadingScholarshipUser: true,
        error: null,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_GET_OK:
      let scholarshipMetadata = state.scholarshipMetadata;
      if (action.metadata) {
        scholarshipMetadata = action.metadata;
      }
      return {
        ...state,
        loading: false,
        loadingScholarshipUser: false,
        error: null,
        scholarshipMetadata,
        scholarshipData: action.scholarshipData,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_OK:
      return {
        ...state,
        loading: false,
        error: null,
        scholarshipData: action.scholarshipData,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.validationErrors,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_OK:
      return {
        ...state,
        loading: false,
        scholarshipMetadata: action.scholarshipMetadata,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION:
    case ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT:
    case ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_TRACKING:
    case ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT:
    case ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT:
    case ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT:
    case ScholarshipDataConsts.REPLACE_SCHOLARSHIPS_PAYMENT_DATES:
      return {
        ...state,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIP_ACADEMIES_METADATA:
      return {
        ...state,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIP_METADATA_ACADEMIES_OK:
      return {
        ...state,
        scholarshipAcademies: action.scholarshipAcademies,
      };
    case ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_OK:
    case ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_OK:
      const { documents } = action;
      const adjustmentDocumentation = documents.filter(
        (element: DocumentationItem) => element.code === 'CON' || element.code === 'PAB',
      );
      return {
        ...state,
        loading: false,
        documentation: documents,
        adjustmentDocumentation,
      };
    case ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_KO:
    case ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_KO:
    case ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_KO:
    case ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_KO:
    case ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_KO:
    case ScholarshipDataConsts.SCHOLARSHIP_GET_KO:
    case ScholarshipDataConsts.GET_SCHOLARSHIP_METADATA_ACADEMIES_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        loadingScholarshipUser: false,
        error,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION:
      return {
        ...state,
        validationErrors: null,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_OK:
      const { documentation } = action;
      return {
        ...state,
        documentation,
        validationErrors: null,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_KO:
      return { ...state };
    case ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_OK:
      const { data, name } = action;
      getDocs(data, true, name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_OK:
      const { dataDoc } = action;
      getDocs(dataDoc, false, action.name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_CLEAN_DATA:
      const emptyData = {};
      return {
        ...state,
        scholarshipData: emptyData as ScholarshipData,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA:
      const { key } = action;
      let { value } = action;

      switch (key) {
        case 'enrollment_confirm':
        case 'monthly_price_confirm':
        case 'materials_confirm':
        case 'transport_price_confirm':
        case 'other_costs_confirm':
          value = value ? parseFloat(value.toString()) : 0.0;
          break;
      }

      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [key]: value,
        },
      };

    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_PERSONAL_INFO:
      const { key: pi_key, value: pi_value } = action;
      const pi_step = state.scholarshipData.steps.personalInformation;
      if (pi_key === 'student_birthdate') {
        if (!state.scholarshipData.isEntity && pi_value) {
          const {
            scholarshipData: { tutor_name, tutor_phone, tutor_document_number, tutor_document_type },
            documentation,
          } = state;
          const needsTutor = moment().diff(moment.unix(pi_value as number), 'years') < 18;
          const index = documentation?.findIndex(docu => docu.code === 'TNF');
          if (index > -1) {
            documentation[index].mandatory = needsTutor;
          }
          if (needsTutor) {
            pi_step.errorFields = checkStepsErrorFields(pi_step.errorFields, 'tutor_name', tutor_name);
            pi_step.errorFields = checkStepsErrorFields(pi_step.errorFields, 'tutor_phone', tutor_phone);
            pi_step.errorFields = checkStepsErrorFields(
              pi_step.errorFields,
              'tutor_document_number',
              tutor_document_number,
            );
            pi_step.errorFields = checkStepsErrorFields(
              pi_step.errorFields,
              'tutor_document_type',
              tutor_document_type,
            );
          } else {
            pi_step.errorFields = removeStepsErrorField(pi_step.errorFields, 'tutor_name');
            pi_step.errorFields = removeStepsErrorField(pi_step.errorFields, 'tutor_phone');
            pi_step.errorFields = removeStepsErrorField(pi_step.errorFields, 'tutor_document_number');
            pi_step.errorFields = removeStepsErrorField(pi_step.errorFields, 'tutor_document_type');
          }
          pi_step.color = pi_step.errorFields.length > 0 ? 'red' : 'green';
        }
      }
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [pi_key]: pi_value,
          steps: {
            ...state.scholarshipData.steps,
            personalInformation: pi_step,
          },
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_MOTIVATIONAL_INFO:
      const { key: mot_key, value: mot_value } = action;
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [mot_key]: mot_value,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_SITUATION_INFO:
      const { key: csit_key, value: csit_value } = action;
      if (csit_key === 'current_situation') {
        const cs_step = state.scholarshipData.steps.currentSituation;
        let { company_name, employee_role, contract_type, current_incomes } = state.scholarshipData;
        if (
          csit_value === ScholarshipCurrentSituationCodes.ESTUDIA_TRABAJA ||
          csit_value === ScholarshipCurrentSituationCodes.TRABAJA
        ) {
          cs_step.errorFields = checkStepsErrorFields(cs_step.errorFields, 'company_name', company_name);
          cs_step.errorFields = checkStepsErrorFields(cs_step.errorFields, 'employee_role', employee_role);
          cs_step.errorFields = checkStepsErrorFields(cs_step.errorFields, 'current_incomes', current_incomes);
          cs_step.errorFields = checkStepsErrorFields(cs_step.errorFields, 'contract_type', contract_type);
        } else {
          company_name = null;
          employee_role = null;
          contract_type = null;
          current_incomes = null;
          cs_step.errorFields = removeStepsErrorField(cs_step.errorFields, 'company_name');
          cs_step.errorFields = removeStepsErrorField(cs_step.errorFields, 'employee_role');
          cs_step.errorFields = removeStepsErrorField(cs_step.errorFields, 'current_incomes');
          cs_step.errorFields = removeStepsErrorField(cs_step.errorFields, 'contract_type');
        }
        cs_step.color = cs_step.errorFields.length > 0 ? 'red' : 'green';

        return {
          ...state,
          scholarshipData: {
            ...state.scholarshipData,
            current_situation: csit_value as string,
            company_name,
            employee_role,
            contract_type,
            current_incomes,
            steps: {
              ...state.scholarshipData.steps,
              currentSituation: cs_step,
            },
          },
        };
      }
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [csit_key]: csit_value,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_EDUCATIONAL_INFO:
      const { value: edu_value } = action;
      let { key: edu_key } = action;
      let {
        vocational_workshop,
        individual_coaching,
        educational_level_id_confirm,
        educational_type_id_confirm,
        professional_group_id_confirm,
        scholarship_course_id_confirm,
        scholarship_academy_confirm,
        scholarship_academy_confirm_code,
      } = state.scholarshipData;
      switch (edu_key) {
        case 'educational_level_id':
          educational_level_id_confirm = edu_value ? parseInt(edu_value.toString()) : null;
          break;
        case 'educational_type_id':
          educational_type_id_confirm = edu_value ? parseInt(edu_value.toString()) : null;
          break;
        case 'professional_group_id':
          professional_group_id_confirm = edu_value ? parseInt(edu_value.toString()) : null;
          break;
        case 'scholarship_course_id':
          scholarship_course_id_confirm = edu_value ? parseInt(edu_value.toString()) : null;
          break;
        case 'scholarship_academy_text':
          edu_key = 'scholarship_academy';
          break;
        case 'scholarship_academy':
          scholarship_academy_confirm = edu_value ? edu_value.toString() : null;
          break;
        case 'scholarship_academy_code':
          scholarship_academy_confirm_code = edu_value ? edu_value.toString() : null;
          break;
        case 'motivational_program':
          if (edu_value === 0) {
            vocational_workshop = 0;
            individual_coaching = 0;
          }
          break;
        case 'vocational_workshop':
          vocational_workshop = 1;
          individual_coaching = 0;
          break;
        case 'individual_coaching':
          individual_coaching = 1;
          vocational_workshop = 0;
          break;
      }
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          vocational_workshop,
          individual_coaching,
          educational_level_id_confirm,
          educational_type_id_confirm,
          professional_group_id_confirm,
          scholarship_course_id_confirm,
          scholarship_academy_confirm,
          scholarship_academy_confirm_code,
          [edu_key]: edu_value,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ECONOMICAL_INFO:
      const { key: eco_key } = action;
      let { value: eco_value } = action;
      if (
        eco_key === 'father_incomes' ||
        eco_key === 'mother_incomes' ||
        eco_key === 'other_incomes' ||
        eco_key === 'student_incomes' ||
        eco_key === 'alternative_incomes' ||
        eco_key === 'living_place_cost'
      ) {
        eco_value = eco_value ? parseFloat(eco_value.toString()) : 0.0;
      } else if (eco_key === 'extra_costs') {
        const ev_step = state.scholarshipData.steps.economicalValoration;
        const {
          scholarshipData: { extra_costs_resume },
        } = state;
        if (eco_value === 1) {
          ev_step.errorFields = checkStepsErrorFields(ev_step.errorFields, 'extra_costs_resume', extra_costs_resume);
        } else {
          ev_step.errorFields = removeStepsErrorField(ev_step.errorFields, 'extra_costs_resume');
        }
      }
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [eco_key]: eco_value,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_COST_INFO:
      const { key: cost_key } = action;
      let { value: cost_value } = action;
      const {
        scholarshipMetadata: { transports },
      } = state;
      let {
        scholarshipData: {
          transportation_cost,
          scholarship_contrib,
          scholarship_contrib_comm,
          enrollment_approval,
          monthly_price_approval,
          transport_price_approval,
          other_costs_approval,
          young_contrib_approval,
          enrollment_confirm,
          monthly_price_confirm,
          transport_price_confirm,
          other_costs_confirm,
          mec_approval,
          mec_confirm,
        },
      } = state;
      const sc_step = state.scholarshipData.steps.scholarshipCost;

      switch (cost_key) {
        case 'enrollment_cost':
          cost_value = cost_value ? parseFloat(cost_value.toString()) : 0.0;
          // set defalut values for next states
          if (enrollment_approval === 0) {
            enrollment_approval = cost_value;
          }
          if (enrollment_confirm === 0) {
            enrollment_confirm = cost_value;
          }
          break;
        case 'monthly_enrollment_cost':
          cost_value = cost_value ? parseFloat(cost_value.toString()) : 0.0;
          // set defalut values for next states
          if (monthly_price_approval === 0) {
            monthly_price_approval = cost_value;
          }
          if (monthly_price_confirm === 0) {
            monthly_price_confirm = cost_value;
          }
          break;
        case 'transportation_cost':
          cost_value = cost_value ? parseFloat(cost_value.toString()) : 0.0;
          // set defalut values for next states
          if (transport_price_approval === 0) {
            transport_price_approval = cost_value;
          }
          if (transport_price_confirm === 0) {
            transport_price_confirm = cost_value;
          }
          transportation_cost = cost_value;
          break;
        case 'other_costs_amount':
          cost_value = cost_value ? parseFloat(cost_value.toString()) : 0.0;
          // set defalut values for next states
          if (other_costs_approval === 0) {
            other_costs_approval = cost_value;
          }
          if (other_costs_confirm === 0) {
            other_costs_confirm = cost_value;
          }
          break;
        case 'able_to_pay':
          const { how_to_pay } = state.scholarshipData;
          cost_value = cost_value ? parseFloat(cost_value.toString()) : 0.0;
          if (cost_value > 0.0) {
            // set defalut values for next states
            if (young_contrib_approval === 0) {
              young_contrib_approval = cost_value;
            }
            scholarship_contrib = ScholarshipContribCodes.YES;
            if (!scholarship_contrib_comm) {
              scholarship_contrib_comm = how_to_pay
                ? cost_value.toString().concat(' ', how_to_pay.toString())
                : cost_value.toString();
            }
            sc_step.errorFields = checkStepsErrorFields(sc_step.errorFields, 'how_to_pay', how_to_pay);
          } else {
            scholarship_contrib = ScholarshipContribCodes.NO;
            sc_step.errorFields = removeStepsErrorField(sc_step.errorFields, 'how_to_pay');
          }
          break;
        case 'how_to_pay':
          const { able_to_pay } = state.scholarshipData;
          if (!scholarship_contrib_comm) {
            scholarship_contrib_comm = able_to_pay.toString().concat(' ', cost_value ? cost_value.toString() : '');
          }
          break;
        case 'transportation_id':
          const { transport_type_quantity } = state.scholarshipData;
          const t_cost = transports.find((t: Transportation) => t.id === (cost_value as number))?.cost;
          if (t_cost && t_cost > 0) {
            transportation_cost = Math.round(t_cost * transport_type_quantity * 100) / 100;
          } else {
            transportation_cost = 0.0;
          }
          break;
        case 'transport_type_quantity':
          const { transportation_id } = state.scholarshipData;
          const t_cost_q = transports.find((t: Transportation) => t.id === transportation_id)?.cost;
          if (t_cost_q && t_cost_q > 0) {
            transportation_cost = Math.round(t_cost_q * (cost_value as number) * 100) / 100;
          }
          break;
        case 'state_scholarship':
          const { why_not_renewal } = state.scholarshipData;
          if (cost_value === 0) {
            sc_step.errorFields = checkStepsErrorFields(sc_step.errorFields, 'why_not_renewal', why_not_renewal);
          } else {
            sc_step.errorFields = removeStepsErrorField(sc_step.errorFields, 'why_not_renewal');
          }
          break;
        case 'mec_amount':
          if (cost_value) {
            mec_approval = +mec_approval ? mec_approval : +cost_value;
            mec_confirm = +mec_confirm ? mec_confirm : +cost_value;
          }
          break;
      }

      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [cost_key]: cost_value,
          transportation_cost,
          scholarship_contrib,
          scholarship_contrib_comm,
          enrollment_approval,
          monthly_price_approval,
          transport_price_approval,
          other_costs_approval,
          young_contrib_approval,
          mec_approval,
          mec_confirm,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_INTERVIEW_INFO:
      const { key: int_key, value: int_value } = action;
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [int_key]: int_value,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ASSESSMENT_INFO:
      const { key: ass_key, value: ass_value } = action;
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [ass_key]: ass_value,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_APPROVAL_INFO:
      const { key: app_key } = action;
      let { value: app_value } = action;
      let {
        scholarshipData: {
          enrollment_confirm: enrollment_confirm_app,
          monthly_price_confirm: monthly_price_confirm_app,
          materials_confirm: materials_confirm_app,
          transport_price_confirm: transport_price_confirm_app,
          other_costs_confirm: other_costs_confirm_app,
          mec_confirm: mec_confirm_app,
        },
      } = state;
      switch (app_key) {
        case 'enrollment_approval':
          app_value = app_value ? parseFloat(app_value.toString()) : 0.0;
          // set defalut values for next states
          if (enrollment_confirm_app === 0) {
            enrollment_confirm_app = app_value;
          }
          break;
        case 'monthly_price_approval':
          app_value = app_value ? parseFloat(app_value.toString()) : 0.0;
          // set defalut values for next states
          if (monthly_price_confirm_app === 0) {
            monthly_price_confirm_app = app_value;
          }
          break;
        case 'transport_price_approval':
          app_value = app_value ? parseFloat(app_value.toString()) : 0.0;
          // set defalut values for next states
          if (transport_price_confirm_app === 0) {
            transport_price_confirm_app = app_value;
          }
          break;
        case 'other_costs_approval':
          app_value = app_value ? parseFloat(app_value.toString()) : 0.0;
          // set defalut values for next states
          if (other_costs_confirm_app === 0) {
            other_costs_confirm_app = app_value;
          }
          break;
        case 'materials_approval':
          app_value = app_value ? parseFloat(app_value.toString()) : 0.0;
          // set defalut values for next states
          if (materials_confirm_app === 0) {
            materials_confirm_app = app_value;
          }
          break;
        case 'mec_approval':
          if (app_value) {
            mec_confirm_app = +app_value;
          }
          break;
      }
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [app_key]: app_value,
          enrollment_confirm: enrollment_confirm_app,
          monthly_price_confirm: monthly_price_confirm_app,
          materials_confirm: materials_confirm_app,
          transport_price_confirm: transport_price_confirm_app,
          other_costs_confirm: other_costs_confirm_app,
          mec_confirm: mec_confirm_app,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_AGREEMENT_SET_DATA:
      const { key: agr_key } = action;
      let { value: agr_value } = action;
      switch (agr_key) {
        case 'first_payment_amount':
        case 'second_payment_amount':
        case 'third_payment_amount':
        case 'additional_payment_amount':
        case 'first_reload_amount':
        case 'second_reload_amount':
        case 'third_reload_amount':
        case 'additional_reload_amount':
          agr_value = agr_value ? parseFloat(agr_value.toString()) : 0.0;
          break;
        case 'payment_account_number':
          let {
            first_payment_account_number,
            second_payment_account_number,
            third_payment_account_number,
          } = state.scholarshipAgreementData;
          if (agr_value) {
            if (!first_payment_account_number) {
              first_payment_account_number = agr_value as string;
            }
            if (!second_payment_account_number) {
              second_payment_account_number = agr_value as string;
            }
            if (!third_payment_account_number) {
              third_payment_account_number = agr_value as string;
            }
          }
          return {
            ...state,
            scholarshipAgreementData: {
              ...state.scholarshipAgreementData,
              first_payment_account_number,
              second_payment_account_number,
              third_payment_account_number,
              payment_account_number: agr_value as string | null,
            },
          };
      }

      return {
        ...state,
        scholarshipAgreementData: {
          ...state.scholarshipAgreementData,
          [agr_key]: agr_value,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_PAYMENT_ADJUSTMENT_SET_DATA:
      const { key: pay_key } = action;
      let { value: pay_value } = action;
      let { total_amount } = state.dataAdjustmentPayment;
      switch (pay_key) {
        case 'first_payment_amount':
        case 'second_payment_amount':
        case 'third_payment_amount':
        case 'additional_payment_amount':
        case 'first_reload_amount':
        case 'second_reload_amount':
        case 'third_reload_amount':
        case 'additional_reload_amount':
          pay_value = pay_value ? parseFloat(pay_value.toString()) : 0.0;
          break;
        case 'adjustment_amount':
          const { scholarship_amount } = state.dataAdjustmentPayment;
          pay_value = pay_value ? parseFloat(pay_value.toString()) : 0.0;
          const total = pay_value * 1 + scholarship_amount * 1;
          total_amount = Math.round(total * 100) / 100;
          break;
      }
      return {
        ...state,
        dataAdjustmentPayment: {
          ...state.dataAdjustmentPayment,
          [pay_key]: pay_value,
          total_amount,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_FOLLOW:
      const { key: _key, value: _value, subKey } = action;
      if (subKey === 'keep_studying') {
        return {
          ...state,
          scholarshipData: {
            ...state.scholarshipData,
            [_key]: {
              // @ts-ignore
              ...state.scholarshipData[_key],
              keep_studying: _value,
            },
          },
        };
      }

      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          [_key]: {
            // @ts-ignore
            ...state.scholarshipData[_key],
            // @ts-ignore
            [subKey]: _value,
          },
        },
      };

    case ScholarshipDataConsts.GET_SCHOLARSHIP_AGREEMENT:
      return {
        ...state,
        loadingAgreement: true,
        loadingPayment: true,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIP_AGREEMENT_OK:
      const { scholarshipAgreementData } = action;
      return {
        ...state,
        loadingAgreement: false,
        loadingPayment: false,
        scholarshipAgreementData,
        scholarshipData: {
          ...state.scholarshipData,
          agreement_state: scholarshipAgreementData.agreement_state as ScholarshipAgreementCode,
        },
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIP_AGREEMENT_KO:
      return {
        ...state,
        loadingAgreement: false,
        loadingPayment: false,
        error: action.error,
      };
    case ScholarshipDataConsts.SAVE_AGREEMENT:
      return {
        ...state,
      };
    case ScholarshipDataConsts.SAVE_AGREEMENT_OK:
      return {
        ...state,
      };
    case ScholarshipDataConsts.SAVE_AGREEMENT_KO:
      return {
        ...state,
        error: action.error,
      };
    case ScholarshipDataConsts.UPDATE_AGREEMENT_CALENDAR:
      return {
        ...state,
      };
    case ScholarshipDataConsts.UPDATE_AGREEMENT_CALENDAR_OK:
      return {
        ...state,
      };
    case ScholarshipDataConsts.UPDATE_AGREEMENT_CALENDAR_KO:
      return {
        ...state,
        error: action.error,
      };

    case ScholarshipDataConsts.SEND_PROVIDER_INFO:
      return {
        ...state,
        providerLoading: true,
      };
    case ScholarshipDataConsts.SEND_PROVIDER_INFO_OK:
      const { provider_code, payable_account, expense_account } = action.providerData;
      return {
        ...state,
        scholarshipData: {
          ...state.scholarshipData,
          student_provider_code: provider_code,
          student_payable_account: payable_account,
          student_expense_account: expense_account,
        },
        scholarshipAgreementData: {
          ...state.scholarshipAgreementData,
          provider_code: provider_code,
          payable_account: payable_account,
          expense_account: expense_account,
        },
        providerLoading: false,
      };
    case ScholarshipDataConsts.SEND_PROVIDER_INFO_KO:
      return {
        ...state,
        error: action.error,
        providerLoading: false,
      };
    case ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP:
    case ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP:
      return {
        ...state,
      };
    case ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_OK:
    case ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_OK:
      return {
        ...state,
      };
    case ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_KO:
    case ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_KO:
    case ScholarshipDataConsts.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_KO:
      return {
        ...state,
        error: action.error,
      };
    case ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP:
      return {
        ...state,
        loading: true,
      };
    case ScholarshipDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT:
      return {
        ...state,
      };
    case ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT:
      return {
        ...state,
        loadingPayment: true,
      };
    case ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_OK:
      return {
        ...state,
        loadingPayment: false,
        dataAdjustmentPayment: action.dataAdjustmentPayment,
      };
    case ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_KO:
      return {
        ...state,
        loadingPayment: false,
      };
    case ScholarshipDataConsts.SEND_PAYMENT_ADJUSTMENT:
      return {
        ...state,
        loading: true,
      };
    case ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_OK:
      const objToFloat = {
        t1_tracking_date: parseFloat(action.configurationFollowupData.t1_tracking_date),
        t2_tracking_date: parseFloat(action.configurationFollowupData.t2_tracking_date),
        tfinal_tracking_date: parseFloat(action.configurationFollowupData.tfinal_tracking_date),
        s1_tracking_date: parseFloat(action.configurationFollowupData.s1_tracking_date),
        sfinal_tracking_date: parseFloat(action.configurationFollowupData.sfinal_tracking_date),
        start_follow_up_day: action.configurationFollowupData.start_follow_up_day,
        start_follow_up_month: action.configurationFollowupData.start_follow_up_month,
      };
      return {
        ...state,
        loading: false,
        configuratorFollowupData: objToFloat,
      };
    case ScholarshipDataConsts.SEND_PAYMENT_ADJUSTMENT_OK:
      const { responseData } = action;
      if (!responseData.isEntity) {
        state.scholarshipAgreementData = {
          ...state.scholarshipAgreementData,
          first_payment_amount: responseData.first_payment_amount,
          first_payment_date: responseData.first_payment_date,
          first_payment_done_date: responseData.first_payment_done_date,
          second_payment_amount: responseData.second_payment_amount,
          second_payment_date: responseData.second_payment_date,
          second_payment_done_date: responseData.second_payment_done_date,
          third_payment_amount: responseData.third_payment_amount,
          third_payment_date: responseData.third_payment_date,
          third_payment_done_date: responseData.third_payment_done_date,
          first_reload_amount: responseData.first_reload_amount,
          first_reload_date: responseData.first_reload_date,
          first_reload_done_date: responseData.first_reload_done_date,
          second_reload_amount: responseData.second_reload_amount,
          second_reload_date: responseData.second_reload_date,
          second_reload_done_date: responseData.second_reload_done_date,
          third_reload_amount: responseData.third_reload_amount,
          third_reload_date: responseData.third_reload_date,
          third_reload_done_date: responseData.third_reload_done_date,
          additional_reload_amount: responseData.additional_reload_amount,
          additional_reload_date: responseData.additional_reload_date,
          additional_reload_done_date: responseData.additional_reload_done_date,
        };
      }
      return {
        ...state,
        loading: false,
        reload: true,
        scholarshipData: {
          ...state.scholarshipData,
          adjustment_amount: responseData.adjustment_amount,
          adjustment_comment: responseData.adjustment_comment,
        },
      };
    case ScholarshipDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_OK:
      const { scholarshipData } = action;
      return {
        ...state,
        scholarshipData,
      };
    case ScholarshipDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_KO:
      return {
        ...state,
        error: action.error,
      };
    case ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_KO:
    case ScholarshipDataConsts.SEND_PAYMENT_ADJUSTMENT_KO:
    case ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };
    case ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_DOCUMENTS_OK:
      return {
        ...state,
        loading: false,
        adjustmentDocumentation: action.adjustmentDocumentation,
      };
    case ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_DOCUMENTS_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL:
    case ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL:
    case ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL:
      return {
        ...state,
        loadingMaterials: true,
      };
    case ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT:
    case ScholarshipDataConsts.SAVE_FOLLOW_UP:
    case ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS:
      return {
        ...state,
      };
    case ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_OK:
      return {
        ...state,
        scholarshipData: { ...state.scholarshipData, payment_blocked: action.response },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_MATERIAL_KO:
      return {
        ...state,
        loadingMaterials: false,
      };
    case ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_KO:
      return {
        ...state,
      };
    case ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL_OK:
      const { materials: add_materials } = state.scholarshipData;

      add_materials.push(action.material);
      return {
        ...state,
        loadingMaterials: false,
        scholarshipData: {
          ...state.scholarshipData,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL_OK:
      const { material } = action;

      const materialsUpdated = state.scholarshipData.materials.map((item: Material) => {
        if (item.id === material.id) {
          return material;
        }
        return item;
      });

      return {
        ...state,
        loadingMaterials: false,
        scholarshipData: {
          ...state.scholarshipData,
          materials: materialsUpdated,
        },
      };
    case ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL_OK:
      const { material_id: removed_id } = action;
      const { materials: remove_materials } = state.scholarshipData;
      const index = remove_materials.findIndex((e: Material) => e.id === removed_id);
      if (index >= 0) {
        remove_materials.splice(index, 1);
      }

      return {
        ...state,
        loadingMaterials: false,
        scholarshipData: {
          ...state.scholarshipData,
        },
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_INSTITUTE:
      return {
        ...state,
        loading: true,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_INSTITUTE_OK:
      const scholarshipPayment = new ScholarshipPaymentsManager(action.academic_year, action.scholarshipApiPayments);
      return {
        ...state,
        loading: false,
        reload: false,
        scholarshipPaymentList: scholarshipPayment.getScholarshipPaymentList(),
        scholarshipPaymentTotals: scholarshipPayment.getScholarshipPaymentTotals(),
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_INSTITUTE_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_ENTITY:
      return {
        ...state,
        loading: true,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_ENTITY_OK:
      return {
        ...state,
        loading: false,
        scholarshipsToPayEntity: action.scholarshipsToPayEntity,
      };
    case ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_ENTITY_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY:
      return {
        ...state,
        loading: true,
      };
    case ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_OK:
      return {
        ...state,
        loading: false,
        getEntitiesToPay: action.getEntitiesToPay,
      };
    case ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_OK:
      const { data: download, source, scholarship_type } = action;
      const filename = `${source === 'committee' ? 'comite' : 'zing_programme'}_${scholarship_type}_${moment(
        new Date(),
      ).format('DD/MM/YYYY')}.xlsx`;
      getDocs(download, true, filename);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ScholarshipDataConsts.SET_PAYMENTS_ACADEMIC_YEAR:
      return {
        ...state,
        paymentsAcademicYear: action.value,
      };
    case ScholarshipDataConsts.SET_ENTITY_PAYMENT_DATE_FILTER:
      return {
        ...state,
        entityScholarshipPaymentDateFilter: action.paymentDate,
      };
    case ScholarshipDataConsts.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_OK:
      return {
        ...state,
        reload: action.paymentDatesResult.entity + action.paymentDatesResult.institute > 0,
      };
    case ScholarshipDataConsts.SET_SCHOLARSHIP_STEP:
      return {
        ...state,
        formStep: action.step,
      };
    case ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_OK:
      const EntityScholarshipPayment = new EntityScholarshipPaymentsManager(action.academic_year, action.scholarships);
      return {
        ...state,
        loading: false,
        reload: false,
        scholarshipPaymentList: EntityScholarshipPayment.getPaymentList(),
        scholarshipPaymentTotals: EntityScholarshipPayment.getPaymentTotals(),
      };

    case GetFamilyIncomesConst.GET_FAMILY_INCOMES:
    case GetFamilyIncomesConst.GET_FAMILY_INCOMES_OK:
    case GetFamilyIncomesConst.GET_FAMILY_INCOMES_KO:
      return {
        ...state,
        familyIncomes: getFamilyIncomesReducer(state.familyIncomes, action as GetFamilyIncomesDataAction),
      };
    case CreateFamilyIncomeConst.ADD_FAMILY_INCOME:
    case CreateFamilyIncomeConst.ADD_FAMILY_INCOME_KO:
      return {
        ...state,
        familyIncomes: createFamilyIncomeReducer(state.familyIncomes, action as CreateFamilyIncomeDataAction),
      };
    case CreateFamilyIncomeConst.ADD_FAMILY_INCOME_OK:
      const errorFields = state.scholarshipData.steps.economicalValoration.errorFields.filter(
        field => field !== 'family_incomes',
      );
      state.scholarshipData.steps.economicalValoration.color = errorFields.length > 0 ? 'red' : 'green';
      state.scholarshipData.steps.economicalValoration.errorFields = errorFields;
      return {
        ...state,
        familyIncomes: createFamilyIncomeReducer(state.familyIncomes, action as CreateFamilyIncomeDataAction),
      };
    case DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME:
    case DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME_KO:
      return {
        ...state,
        familyIncomes: deleteFamilyIncomeReducer(state.familyIncomes, action as DeleteFamilyIncomeDataAction),
      };
    case DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME_OK:
      const familyIncomes = deleteFamilyIncomeReducer(state.familyIncomes, action as DeleteFamilyIncomeDataAction);
      if (familyIncomes.list.length === 0) {
        state.scholarshipData.steps.economicalValoration.color = 'red';
        state.scholarshipData.steps.economicalValoration.errorFields.push('family_incomes');
      }
      return {
        ...state,
        familyIncomes,
      };
    case UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME:
    case UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME_OK:
    case UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME_KO:
      return {
        ...state,
        familyIncomes: updateFamilyIncomeReducer(state.familyIncomes, action as UpdateFamilyIncomeDataAction),
      };
    case ScholarshipInstitutePaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_PAYMENT:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_RELOAD:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_STUDENT_TRANSFER:
      return {
        ...state,
        error: null,
      };
    case ScholarshipInstitutePaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_OK:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_PAYMENT_OK:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_RELOAD_OK:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_STUDENT_TRANSFER_OK:
      return {
        ...state,
        reload: true,
      };
    case ScholarshipInstitutePaymentsConst.CHANGE_SCHOLARSHIP_PAYMENT_DATE_KO:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_PAYMENT_KO:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_RELOAD_KO:
    case ScholarshipInstitutePaymentsConst.SEND_SCHOLARSHIP_STUDENT_TRANSFER_KO:
      return {
        ...state,
        error: action.error,
      };
    case ScholarshipTransitionsConst.SCHOLARSHIP_STATE_TRANSITION:
    case ScholarshipTransitionsConst.SCHOLARSHIP_STATE_TRANSITION_OK:
    case ScholarshipTransitionsConst.SCHOLARSHIP_LEAVE:
    case ScholarshipTransitionsConst.SCHOLARSHIP_LEAVE_OK:
    case ScholarshipTransitionsConst.SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION:
    case ScholarshipTransitionsConst.SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION_OK:
    case ScholarshipTransitionsConst.SCHOLARSHIP_TRANSITION_KO:
      return scholarshipTransitionReducer(state, action);
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_GET_ALLOWED_STATES:
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_CHANGE_STATE:
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_GET_ALLOWED_STATES_OK:
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_CHANGE_STATES_KO:
      return scholarshipChangeStateReducer(state, action);
  }

  return state;
};

export default scholarshipReducer;
