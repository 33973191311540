import { APIValidationError } from '../../api/api';
import { AppState } from '../root-reducer';
import { CustomField, CustomFieldState, KpiIndicatorType } from './definitions';

export const selectCustomField = (state: AppState): CustomFieldState => state.customFieldReducer;
export const selectLoading = (state: AppState): boolean => selectCustomField(state).loading;
export const selectCustomFieldLoading = (state: AppState): boolean => selectCustomField(state).customFieldLoading;
export const selectListCustomFields = (state: AppState): CustomField[] => selectCustomField(state).listCustomFields;
export const selectOneCustomField = (state: AppState): CustomField => selectCustomField(state).customField;
export const selectKpiTypes = (state: AppState): KpiIndicatorType[] => selectCustomField(state).kpiTypes;
export const selectValidationErrors = (state: AppState): APIValidationError | null =>
  selectCustomField(state).validationErrors;
export const selectCurrentKpiType = (state: AppState): KpiIndicatorType => selectCustomField(state).currentKpiType;
export const selectShowModal = (state: AppState): boolean => selectCustomField(state).showModal;
export const selectIdCustomFieldDeactivating = (state: AppState): number =>
  selectCustomField(state).idCustomFieldDeactivating;
