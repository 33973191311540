/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps } from '@material-ui/core';
import React, { CSSProperties, useState } from 'react';
import { connect } from 'react-redux';
import { getProjectPayment } from '../../../../api/Projects/projects';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import Loading from '../../../../components/Loading/Loading';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import Title from '../../../../components/Title/Title';
import { useHookMethod } from '../../../../helpers/customHooks/useHookMethod';
import { formatNumber } from '../../../../helpers/formatNumber';
import { tFormKey, tKey, tProjectKey } from '../../../../helpers/translate';
import { KeyButton } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { projectPayment, setPaymentData } from '../../../../redux/project/action';
import {
  PaymentInfo,
  ProjectData,
  ProjectDispatch,
  ScheduledAppointmentItem,
} from '../../../../redux/project/definitions';
import {
  selectLoading,
  selectProjectPaymentFollowUps,
  selectProjectPayments,
} from '../../../../redux/project/selectors';
import { AppState } from '../../../../redux/root-reducer';
import LinkPaymentModal from '../../Modal/PaymentModal/LinkPaymentModal';
import PaymentModal from '../../Modal/PaymentModal/PaymentModal';
import ProjectMenuRight from '../../ProjectMenuRight/ProjectMenuRight';
import SubmenuProject from '../../SubmenuProject/SubmenuProject';
import styles from './followAndPayment.module.scss';

interface FollowAndPaymentProps {
  buttons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  project: ProjectData;
  readOnly: boolean;
  loading: boolean;
  payments: ScheduledAppointmentItem[];
  paymentData: PaymentInfo;
  followUps: ScheduledAppointmentItem[];
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  projectPay: (project_id: number, project_schedule_id: number, numberAccount: string) => void;
  setPaymentData: (data: PaymentInfo) => void;
}

const greenCell: CSSProperties = {
  color: 'green',
};

const activeGreenCell: CSSProperties = {
  color: 'green',
  fontWeight: 'bold',
};

const activeCell: CSSProperties = {
  fontWeight: 'bold',
};
const selectCSSCell = (pay: number | boolean, active: number | boolean) =>
  active ? (pay ? activeGreenCell : activeCell) : pay ? greenCell : null;

const FollowAndPayment: React.FC<FollowAndPaymentProps> = ({
  buttons,
  moreInfoButtons,
  project,
  loading,
  payments,
  paymentData,
  followUps,
  showSnackbar,
  projectPay,
  setPaymentData,
}) => {
  const [viewModal, setViewModal] = useState({ viewPayment: false, viewLinkPayment: false });

  const filterColumnsTable = (payment: boolean) => {
    const result = [
      {
        field: 'tracking_date',
        title: tProjectKey('Fecha'),
        editable: 'never',
        width: '150px',
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) =>
          selectCSSCell(rowData.complete, rowData.payment_active || 0),
      },
    ] as any;

    if (!payment) {
      result.push({
        title: tProjectKey('Periodo'),
        field: 'period',
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) =>
          selectCSSCell(rowData.complete, rowData.payment_active || 0),
        render: (rowData: ScheduledAppointmentItem): any => {
          return tProjectKey('periodo_'.concat(rowData.period));
        },
        //@ts-ignore
        width: '250px',
      });
    }
    result.push({
      field: 'complete',
      title: tProjectKey('Completado'),
      render: (rowData: ScheduledAppointmentItem) => (
        <div style={{ textAlign: 'left' }}>{rowData.complete ? tFormKey('Sí') : tFormKey('No')}</div>
      ),
      cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) =>
        selectCSSCell(rowData.complete, rowData.payment_active || 0),
    });

    if (payment) {
      result.push({
        field: 'partial_amount',
        title: tProjectKey('Pago'),
        width: '150px',
        render: (rowData: ScheduledAppointmentItem) => (
          <div style={{ textAlign: 'left' }}>{formatNumber({ value: rowData.partial_amount }) + ' €'}</div>
        ),
        cellStyle: (data: ScheduledAppointmentItem[], rowData: ScheduledAppointmentItem) =>
          selectCSSCell(rowData.complete, rowData.payment_active || 0),
      });
    }
    return result;
  };

  const followUpTableData = {
    title: tProjectKey('Seguimientos'),
    columns: filterColumnsTable(false),
    data: followUps,
    localization: {
      body: {
        editTooltip: tProjectKey('Editar'),
        editRow: {
          saveTooltip: tKey('Guardar'),
        },
      },
    },
    options: { pageSize: 100, pageSizeOptions: [20, 50, 100] },
  };

  const paymentTableData = {
    columns: filterColumnsTable(true),
    options: {
      actionsColumnIndex: -1,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
    title: tProjectKey('Pagos'),
    data: payments,
  };

  const handlePaymentModal = async (modalName: string) => {
    try {
      const { data } = await getProjectPayment(project.id);
      setPaymentData({ ...data, file_id: paymentData.file_id, file_name: paymentData.file_name });
    } catch (error) {
      showSnackbar((error as Error).message, 'error', undefined, 1500);
    }
    setViewModal({
      viewPayment: modalName === KeyButton.PAY,
      viewLinkPayment: modalName === KeyButton.LINK_PAYMENT,
    });
  };

  useHookMethod(
    buttons,
    [KeyButton.PAY, KeyButton.LINK_PAYMENT],
    [
      () => {
        handlePaymentModal(KeyButton.PAY);
      },
      () => {
        handlePaymentModal(KeyButton.LINK_PAYMENT);
      },
    ],
  );

  const makePaymentProject = async (data: any) => {
    const { id, payment_field_id, account_number } = data;
    projectPay(id, payment_field_id, account_number);
  };

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuProject
          current_section="seguimiento_pago"
          project={project}
          buttons={buttons}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClickOption={() => {}}
        />
      }
      rightSubmenu={<ProjectMenuRight actionButtons={buttons} moreInfoButtons={moreInfoButtons} project={project} />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <>
          <Title>{tProjectKey('Seguimiento de Pagos')}</Title>
          <div className={`${styles.tablesContainer} ${project.untracked_project ? styles.fullWidth : ''}`}>
            {!project.untracked_project && <TableWithEdit tableData={followUpTableData} key="1" />}
            <TableWithEdit tableData={paymentTableData} key="2" />
          </div>

          <PaymentModal
            inputsData={paymentData}
            view={viewModal.viewPayment}
            onClose={() => setViewModal({ viewPayment: false, viewLinkPayment: false })}
            onSubmit={makePaymentProject}
          />

          <LinkPaymentModal
            entity={project.entity_name || ''}
            inputsData={paymentData}
            view={viewModal.viewLinkPayment}
            onClose={() => setViewModal({ viewPayment: false, viewLinkPayment: false })}
          />
        </>
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  payments: selectProjectPayments(state),
  paymentData: state.projectReducer.paymentData,
  followUps: selectProjectPaymentFollowUps(state),
});

const mapDispatchToProps = (dispatch: ProjectDispatch | any) => ({
  projectPay: (project_id: number, project_schedule_id: number, numberAccount: string) =>
    dispatch(projectPayment(project_id, project_schedule_id, numberAccount)),
  setPaymentData: (data: PaymentInfo) => dispatch(setPaymentData(data)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowAndPayment);
