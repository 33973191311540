export enum ProjectConst {
  GET_PROJECTS = 'GET:PROJECTS',
  GET_PROJECTS_OK = 'GET:PROJECTS:OK',
  GET_PROJECTS_KO = 'GET:PROJECTS:KO',

  GET_ONE_PROJECT = 'GET:ONE:PROJECT',
  GET_ONE_PROJECT_OK = 'GET:ONE:PROJECT:OK',
  GET_ONE_PROJECT_KO = 'GET:ONE:PROJECT:KO',

  CREATE_PROJECT = 'CREATE:PROJECT',
  CREATE_PROJECT_OK = 'CREATE:PROJECT:OK',
  CREATE_PROJECT_KO = 'CREATE:PROJECT:KO',

  EDIT_PROJECT = 'EDIT:PROJECT',
  EDIT_PROJECT_OK = 'EDIT:PROJECT:OK',
  EDIT_PROJECT_KO = 'EDIT:PROJECT:KO',

  DELETE_PROJECT = 'DELETE:PROJECT',
  DELETE_PROJECT_OK = 'DELETE:PROJECT:OK',
  DELETE_PROJECT_KO = 'DELETE:PROJECT:KO',

  GET_AREAS_SUBAREAS = 'GET:AREAS:SUBAREAS',
  GET_AREAS_SUBAREAS_OK = 'GET:AREAS:SUBAREAS:OK',
  GET_AREAS_SUBAREAS_KO = 'GET:AREAS:SUBAREAS:KO',

  ADD_DOCUMENT = 'ADD:DOCUMENT',
  ADD_DOCUMENT_OK = 'ADD:DOCUMENT:OK',
  ADD_DOCUMENT_KO = 'ADD:DOCUMENT:KO',

  GET_DOCUMENTATION = 'GET:DOCUMENTATION',
  GET_DOCUMENTATION_OK = 'GET:DOCUMENTATION:OK',
  GET_DOCUMENTATION_KO = 'GET:DOCUMENTATION:KO',

  GET_PROJECT_SCHEDULES = 'GET:PROJECT:SCHEDULES',
  GET_PROJECT_SCHEDULES_OK = 'GET:PROJECT:SCHEDULES:OK',

  DOWNLOAD_DOCUMENT = 'DOWNLOAD:DOCUMENT',
  DOWNLOAD_DOCUMENT_OK = 'DOWNLOAD:DOCUMENT:OK',
  DOWNLOAD_DOCUMENT_KO = 'DOWNLOAD:DOCUMENT:KO',

  GET_DOCUMENT = 'GET:DOCUMENT',
  GET_DOCUMENT_OK = 'GET:DOCUMENT:OK',
  GET_DOCUMENT_KO = 'GET:DOCUMENT:KO',

  DELETE_DOCUMENT = 'DELETE:DOCUMENT',
  DELETE_DOCUMENT_OK = 'DELETE:DOCUMENT:OK',
  DELETE_DOCUMENT_KO = 'DELETE:DOCUMENT:KO',

  UPLOAD_AGREEMENT = 'UPLOAD:AGREEMENT',
  UPLOAD_AGREEMENT_OK = 'UPLOAD:AGREEMENT:OK',
  UPLOAD_AGREEMENT_KO = 'UPLOAD:AGREEMENT:KO',

  DELETE_AGREEMENT = 'DELETE:AGREEMENT',
  DELETE_AGREEMENT_OK = 'DELETE:AGREEMENT:OK',
  DELETE_AGREEMENT_KO = 'DELETE:AGREEMENT:KO',

  PROJECT_CHANGE_STATE = 'PROJECT:CHANGE:TO:STATE',
  PROJECT_CHANGE_STATE_OK = 'PROJECT:CHANGE:TO:STATE:OK',
  PROJECT_CHANGE_STATE_KO = 'PROJECT:CHANGE:TO:STATE:KO',

  PROJECT_VALIDATION_ERRORS = 'PROJECT:VALIDATION:ERRORS',

  GET_FOLLOWUP = 'GET:FOLLOWUP',
  GET_FOLLOWUP_OK = 'GET:FOLLOWUP:OK',
  GET_FOLLOWUP_KO = 'GET:FOLLOWUP:KO',

  ADD_FOLLOWUP = 'ADD:FOLLOWUP',
  ADD_FOLLOWUP_OK = 'ADD:FOLLOWUP:OK',
  ADD_FOLLOWUP_KO = 'ADD:FOLLOWUP:KO',

  EDIT_FOLLOWUP = 'EDIT:FOLLOWUP',
  EDIT_FOLLOWUP_OK = 'EDIT:FOLLOWUP:OK',
  EDIT_FOLLOWUP_KO = 'EDIT:FOLLOWUP:KO',

  DELETE_FOLLOWUP = 'DELETE:FOLLOWUP',
  DELETE_FOLLOWUP_OK = 'DELETE:FOLLOWUP:OK',
  DELETE_FOLLOWUP_KO = 'DELETE:FOLLOWUP:KO',

  GET_LIST_FOLLOWUPS = 'GET:LIST:FOLLOWUPS',
  GET_LIST_FOLLOWUPS_OK = 'GET:LIST:FOLLOWUPS:OK',
  GET_LIST_FOLLOWUPS_KO = 'GET:LIST:FOLLOWUPS:KO',

  CHANGE_CREATE_MODAL_VISIBLE = 'CHANGE:CREATE:MODAL:VISIBLE',
  CHANGE_EDITDELETE_MODAL_VISIBLE = 'CHANGE:EDITDELETE:MODAL:VISIBLE',

  UPLOAD_FOLLOWUP_DOCUMENT = 'UPLOAD:FOLLOWUP:DOCUMENT',
  UPLOAD_FOLLOWUP_DOCUMENT_OK = 'UPLOAD:FOLLOWUP:DOCUMENT:OK',
  UPLOAD_FOLLOWUP_DOCUMENT_KO = 'UPLOAD:FOLLOWUP:DOCUMENT:KO',

  DOWNLOAD_FOLLOWUP_DOCUMENT = 'DOWNLOAD:FOLLOWUP:DOCUMENT',
  DOWNLOAD_FOLLOWUP_DOCUMENT_OK = 'DOWNLOAD:FOLLOWUP:DOCUMENT:OK',
  DOWNLOAD_FOLLOWUP_DOCUMENT_KO = 'DOWNLOAD:FOLLOWUP:DOCUMENT:KO',

  DELETE_FOLLOWUP_DOCUMENT = 'DELETE:FOLLOWUP:DOCUMENT',
  DELETE_FOLLOWUP_DOCUMENT_OK = 'DELETE:FOLLOWUP:DOCUMENT:OK',
  DELETE_FOLLOWUP_DOCUMENT_KO = 'DELETE:FOLLOWUP:DOCUMENT:KO',

  RESET_DOCUMENTS = 'RESET:DOCUMENTS',

  CONFIRM_CONFIGURATOR = 'CONFIRM:CONFIGURATOR',
  CONFIRM_CONFIGURATOR_OK = 'CONFIRM:CONFIGURATOR:OK',
  CONFIRM_CONFIGURATOR_KO = 'CONFIRM:CONFIGURATOR:KO',

  RESET_CONFIGURATOR_DATA = 'RESET:CONFIGURATOR:DATA',

  SAVE_CUSTOM_FIELDS_FORM = 'SAVE:CUSTOM:FIELDS:FORM',

  ADD_NEW_GENERIC_TABLE_ROW = 'ADD:NEW:GENERIC:TABLE:ROW',

  SET_SETTER_GENERIC_TABLE_ROW = 'SET:SETTER:GENERIC:TABLE:ROW',

  ASSIGN_DOCS_TO_ROW = 'ASSIGN:DOCS:TO:ROW',
  ASSIGN_DOCS_TO_ROW_OK = 'ASSIGN:DOCS:TO:ROW:OK',
  ASSIGN_DOCS_TO_ROW_KO = 'ASSIGN:DOCS:TO:ROW:KO',

  DELETE_DOCS_ROW = 'DELETE:DOCS:ROW',
  DELETE_DOCS_ROW_OK = 'DELETE:DOCS:ROW:OK',
  DELETE_DOCS_ROW_KO = 'DELETE:DOCS:ROW:KO',

  DELETE_ROW_TABLE = 'DELETE:ROW:TABLE',
  DELETE_ROW_TABLE_OK = 'DELETE:ROW:TABLE:OK',
  DELETE_ROW_TABLE_KO = 'DELETE:ROW:TABLE:KO',

  GENERIC_FORM_UPLOAD_FIELD = 'GENERIC:FORM:UPLOAD:FIELD',
  GENERIC_FORM_UPLOAD_FIELD_OK = 'GENERIC:FORM:UPLOAD:FIELD:OK',
  GENERIC_FORM_UPLOAD_FIELD_KO = 'GENERIC:FORM:UPLOAD:FIELD:KO',

  GENERIC_FORM_DELETE_FIELD = 'GENERIC:FORM:DELETE:FIELD',
  GENERIC_FORM_DELETE_FIELD_OK = 'GENERIC:FORM:DELETE:FIELD:OK',
  GENERIC_FORM_DELETE_FIELD_KO = 'GENERIC:FORM:DELETE:FIELD:KO',

  GENERIC_FORM_DOWNLOAD_FILE = 'GENERIC:FORM:DOWNLOAD:FILE',
  GENERIC_FORM_DOWNLOAD_FILE_OK = 'GENERIC:FORM:DOWNLOAD:FILE:OK',
  GENERIC_FORM_DOWNLOAD_FILE_KO = 'GENERIC:FORM:DOWNLOAD:FILE:KO',

  GENERIC_FORM_GET_FILE = 'GENERIC:FORM:GET:FILE',
  GENERIC_FORM_GET_FILE_OK = 'GENERIC:FORM:GET:FILE:OK',
  GENERIC_FORM_GET_FILE_KO = 'GENERIC:FORM:GET:FILE:KO',

  GENERIC_FORM_DELETE_FILE = 'GENERIC:FORM:DELETE:FILE',
  GENERIC_FORM_DELETE_FILE_OK = 'GENERIC:FORM:DELETE:FILE:OK',
  GENERIC_FORM_DELETE_FILE_KO = 'GENERIC:FORM:DELETE:FILE:KO',

  GENERIC_FORM_GET_FIELDS = 'GENERIC:FORM:GET:FIELDS',
  GENERIC_FORM_GET_FIELDS_OK = 'GENERIC:FORM:GET:FIELDS:OK',
  GENERIC_FORM_GET_FIELDS_KO = 'GENERIC:FORM:GET:FIELDS:KO',

  GET_DIRECTORY_DATA_FILTER = 'GET:DIRECTORY:DATA:FILTER',
  GET_DIRECTORY_DATA_FILTER_OK = 'GET:DIRECTORY:DATA:FILTER:OK',
  GET_DIRECTORY_DATA_FILTER_KO = 'GET:DIRECTORY:DATA:FILTER:KO',

  GET_ACTORS_METADATA = 'GET:ACTORS:METADATA',
  GET_ACTORS_METADATA_OK = 'GET:ACTORS:METADATA:OK',

  SEND_ACTOR = 'SEND:ACTOR',
  SEND_ACTOR_OK = 'SEND:ACTOR:OK',

  DELETE_ACTOR = 'DELETE:ACTOR',
  DELETE_ACTOR_OK = 'DELETE:ACTOR:OK',

  SET_PROJECT_DATA = 'SET:PROJECT:DATA',
  SET_PROJECT_SCHEDULE_DATA = 'SET:PROJECT:SCHEDULE:DATA',

  CREATE_PROJECT_SCHEDULE = 'CREATE:PROJECT:SCHEDULE',

  UPDATE_PROJECT_SCHEDULE = 'UPDATE:PROJECT:SCHEDULE',

  DELETE_PROJECT_SCHEDULE = 'DELETE:PROJECT:SCHEDULE',

  UPDATE_PROJECT_CALENDAR = 'UPDATE:PROJECT:CALENDAR',

  EDIT_PROJECT_SCHEDULE_ITEM = 'EDIT:PROJECT:SCHEDULE:ITEM',
  EDIT_PROJECT_SCHEDULE_ITEM_OK = 'EDIT:PROJECT:SCHEDULE:ITEM:OK',

  SELECT_PROJECT_SCHEDULE_ITEM = 'SELECT:PROJECT:SCHEDULE:ITEM',

  PROJECT_GENERIC_KO = 'PROJECT:GENERIC:KO',

  UPLOAD_FOLLOW_UP_DOCUMENT = 'UPLOAD:FOLLOW_UP',
  UPLOAD_FOLLOW_UP_DOCUMENT_OK = 'UPLOAD:FOLLOW_UP:OK',
  UPLOAD_FOLLOW_UP_DOCUMENT_KO = 'UPLOAD:FOLLOW_UP:KO',

  REMOVE_FOLLOW_UP_DOCUMENT = 'REMOVE:FOLLOW_UP:DOCUMENT',
  REMOVE_FOLLOW_UP_DOCUMENT_OK = 'REMOVE:FOLLOW_UP:DOCUMENT:OK',
  REMOVE_FOLLOW_UP_DOCUMENT_KO = 'REMOVE:FOLLOW_UP:DOCUMENT:KO',

  DOWNLOAD_FOLLOW_UP = 'DOWNLOAD:FOLLOW_UP',
  DOWNLOAD_FOLLOW_UP_OK = 'DOWNLOAD:FOLLOW_UP:OK ',
  DOWNLOAD_FOLLOW_UP_KO = 'DOWNLOAD:FOLLOW_UP:KO',

  ADD_SCHEDULE = 'ADD:SCHEDULE',

  UPLOAD_SCHEDULE_DOCUMENT = 'UPLOAD:SCHEDULE:DOCUMENT',
  UPLOAD_SCHEDULE_DOCUMENT_OK = 'UPLOAD:SCHEDULE:DOCUMENT:OK',
  UPLOAD_SCHEDULE_DOCUMENT_KO = 'UPLOAD:SCHEDULE:DOCUMENT:KO',

  SET_SCHEDULE_DATA = 'SET:SCHEDULE:DATA',

  CREATE_NEW_SCHEDULE = 'CREATE:NEW:SCHEDULE',
  CREATE_NEW_SCHEDULE_OK = 'CREATE:NEW:SCHEDULE:OK',
  CREATE_NEW_SCHEDULE_KO = 'CREATE:NEW:SCHEDULE:KO',

  PROJECT_PAYMENT = 'PROJECT:PAYMENT',

  OPEN_PROJECT_SCHEDULE = 'OPEN:PROJECT:SCHEDULE',
  CLOSE_PROJECT_SCHEDULE = 'CLOSE:PROJECT:SCHEDULE',

  SET_PAYMENT_DATA = 'SET:PAYMENT:DATA',

  UPLOAD_PAYMENT_FILE = 'UPLOAD:PAYMENT:FILE',
  UPLOAD_PAYMENT_FILE_OK = 'UPLOAD:PAYMENT:FILE:OK',
  UPLOAD_PAYMENT_FILE_KO = 'UPLOAD:PAYMENT:FILE:KO',

  REMOVE_PAYMENT_FILE = 'REMOVE:PAYMENT:FILE',
  REMOVE_PAYMENT_FILE_OK = 'REMOVE:PAYMENT:FILE:OK',
  REMOVE_PAYMENT_FILE_KO = 'REMOVE:PAYMENT:FILE:KO',

  LINK_PAYMENT = 'LINK:PAYMENT',

  GET_PROJECT_IMAGES = 'GET:PROJECT:IMAGES',
  GET_PROJECT_IMAGES_OK = 'GET:PROJECT:IMAGES:OK',
  ADD_PROJECT_IMAGE = 'ADD:PROJECT:IMAGE',
  ADD_PROJECT_IMAGE_OK = 'ADD:PROJECT:IMAGE:OK',
  EDIT_PROJECT_IMAGE = 'EDIT:PROJECT:IMAGE',
  EDIT_PROJECT_IMAGE_OK = 'EDIT:PROJECT:IMAGE:OK',
  DELETE_PROJECT_IMAGE = 'DELETE:PROJECT:IMAGE',
  DELETE_PROJECT_IMAGE_OK = 'DELETE:PROJECT:IMAGE:OK',
  PROJECT_IMAGES_KO = 'PROJECT:IMAGES:KO',

  PROJECT_CANCEL_AGREEMENT = 'PROJECT:CANCEL:AGREEMENT',
  PROJECT_CANCEL_AGREEMENT_OK = 'PROJECT:CANCEL:AGREEMENT:OK',
  PROJECT_CANCEL_AGREEMENT_KO = 'PROJECT:CANCEL:AGREEMENT:KO',
}
