import { ButtonProps } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import Title from '../../../components/Title/Title';
import { tFormKey, tKey } from '../../../helpers/translate';
import { createExpenseAccount, editExpenseAccount, getOneExpenseAccount } from '../../../redux/accounting/actions';
import { ExpenseAccount } from '../../../redux/accounting/definitions';
import { selectExpense, selectLoading } from '../../../redux/accounting/selectors';
import { AppState } from '../../../redux/root-reducer';
import { ExpenseAccountsSchema } from '../../../validations/formSchema';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './expenseAccounts.module.scss';

interface ExpenseAccountProps extends RouteComponentProps<{ id: string }> {
  createExpenseAccount: (data: ExpenseAccount) => void;
  editExpenseAccount: (data: ExpenseAccount, id: number) => void;
  expenseAccount: ExpenseAccount;
  loading: boolean;
  getOneExpenseAccount: (id: number) => void;
  detailsMode?: boolean;
}

const ExpenseAccountsConfig: React.FC<ExpenseAccountProps> = ({
  getOneExpenseAccount,
  expenseAccount,
  loading,
  match,
  detailsMode,
  createExpenseAccount,
  editExpenseAccount,
}) => {
  const { handleSubmit, errors, control, register } = useForm<ExpenseAccount>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ExpenseAccountsSchema,
  });
  const {
    params: { id },
  } = match;

  useEffect(() => {
    if (id) {
      getOneExpenseAccount(parseFloat(id));
    }
  }, [getOneExpenseAccount, id]);

  const title = () =>
    id === undefined
      ? tKey('Nueva cuenta de gasto')
      : detailsMode || false
      ? 'Detalle de cuenta de gasto'
      : tKey('Edición de cuenta de gasto');
  const onSave = (data: ExpenseAccount) => {
    if (id === undefined) {
      createExpenseAccount(data);
    } else {
      editExpenseAccount(data, expenseAccount.id);
    }
  };

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      hidden: detailsMode || false,
      onClick: handleSubmit(onSave),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
      leftSubmenu={<AccountingSubmenu selected="expenses" />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <>
          <Title>{title()}</Title>
          {expenseAccount === undefined ? (
            ''
          ) : (
            <FormContainer title="">
              <TextInputController
                disabled={detailsMode || false}
                control={control}
                errors={errors}
                defaultValue={expenseAccount.code || undefined}
                schema={ExpenseAccountsSchema}
                label={tFormKey('Código')}
                name="code"
                size="50"
              />
              <TextInputController
                disabled={detailsMode || false}
                control={control}
                errors={errors}
                schema={ExpenseAccountsSchema}
                defaultValue={expenseAccount.description || undefined}
                label={tFormKey('Descripción')}
                name="description"
                size="50"
              />
              <div className={styles.activeCheckBox}>
                <CheckboxCompo
                  name="active"
                  disabled={detailsMode || false}
                  questionText={tFormKey('Activo')}
                  defaultValue={expenseAccount.active ? 1 : 0 || undefined}
                  register={register}
                />
              </div>
            </FormContainer>
          )}
        </>
      )}
    </LayoutForm>
  );
};
const mapStateToProps = (state: AppState) => ({
  expenseAccount: selectExpense(state),
  loading: selectLoading(state),
});
const mapDispatchToProps = (dispatch: any) => ({
  createExpenseAccount: (data: ExpenseAccount) => dispatch(createExpenseAccount(data)),
  editExpenseAccount: (data: ExpenseAccount, id: number) => dispatch(editExpenseAccount(data, id)),
  getOneExpenseAccount: (id: number) => dispatch(getOneExpenseAccount(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAccountsConfig);
