import * as H from 'history';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { APIValidationError } from '../../api/api';
import Loading from '../../components/Loading/Loading';
import { DocumentationItem } from '../../redux/common/definitions';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import { getAvatarById, getUserData, setAvatarById } from '../../redux/user/actions';
import { UserData, UserDataDispatch } from '../../redux/user/definitions';
import {
  selectDocumentation,
  selectError,
  selectIsLoading,
  selectProfile,
  selectUserAvatar,
  selectUserValidationErrors,
} from '../../redux/user/selectors';
import NormalUser from './NormalUser/NormalUser';
import StudentUser from './StudentUser/StudentUser';
import RoleCode from '../../types/Roles/roleCode';

interface EditUserProps extends RouteComponentProps<{ id: string }> {
  readOnly: boolean;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  documents: DocumentationItem[];
  location: H.Location<{ needsValidation: boolean; type: string }>;
  profileData: UserData;
  validationErrors: APIValidationError | null;
  getUserData: (id: number) => void;
  getUserAvatar: (id: number) => void;
  setAvatar: (userAvatar: string, id: number) => void;
}

const EditUser: React.FC<EditUserProps> = ({
  readOnly,
  location,
  match,
  profileData,
  avatar,
  documents,
  loading,
  changeAvatar,
  getUserData,
  getUserAvatar,
  setAvatar,
}) => {
  const {
    params: { id },
  } = match;

  const roleCode = new RoleCode(profileData.role_code);

  const handleSetImageById = (image: string) => {
    setAvatar(image, parseInt(id));
  };
  useEffect(() => {
    getUserData(+id);
  }, [getUserData, id]);

  useEffect(() => {
    getUserAvatar(+id);
  }, [getUserAvatar, id]);

  return (
    <>
      {loading ? (
        <Loading big />
      ) : roleCode.isStudent() ? (
        <StudentUser
          readOnly={readOnly}
          id={id}
          location={location}
          profileData={profileData}
          avatar={avatar}
          documents={documents}
          loading={loading}
          changeAvatar={changeAvatar}
          handleSetImageById={handleSetImageById}
        />
      ) : (
        <NormalUser
          readOnly={readOnly}
          id={id}
          location={location}
          profileData={profileData}
          avatar={avatar}
          documents={documents}
          loading={loading}
          changeAvatar={changeAvatar}
          handleSetImageById={handleSetImageById}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  error: selectError(state),
  validationErrors: selectUserValidationErrors(state),
  avatar: selectUserAvatar(state),
  profileData: selectProfile(state),
  documents: selectDocumentation(state),
  changeAvatar: selectUserCan(state)('action_set_avatar'),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  getUserData: (id: number): void => dispatch(getUserData(id)),
  getUserAvatar: (id: number): void => dispatch(getAvatarById(id)),
  setAvatar: (userAvatar: string, id: number) => dispatch(setAvatarById(userAvatar, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
