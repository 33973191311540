import { Cancel, Delete, Pageview, Send, ArrowForward } from '@material-ui/icons';
import React from 'react';
import { SignatureDocument } from '../../redux/signature-document/definitions';
import styles from './signatureDocuments.module.scss';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import { UUID } from '../../types/Shared/standard';
import { tKey } from '../../helpers/translate';
import { SignatureDocumentStatus } from '../../redux/signature-document/SignatureDocumentStatus';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

interface SignatureDocumentRowProps {
  signatureDocument: SignatureDocument;
  isNousCims: boolean;
  onViewDocument: (file_id: number, name: string) => void;
  onRemoveDocument: (signature_document_id: string) => void;
  onSendDocument: (signature_document_id: string) => void;
  onCancelDocument: (signature_document_id: string) => void;
  sending: UUID | null;
}

const SignatureDocumentRow: React.FC<SignatureDocumentRowProps> = ({
  signatureDocument,
  isNousCims,
  onViewDocument,
  onRemoveDocument,
  onSendDocument,
  onCancelDocument,
  sending,
}) => {
  const iconColor = (disabled: boolean) => {
    const opacity = disabled ? 0.2 : 0.54;
    return { color: `rgba(0, 0, 0, ${opacity})` };
  };

  const isMobile = useWindowSize().IsMobile;

  const renderActions = () => {
    return (
      <td className={`${styles.documentCellRow}  ${styles.documentRowActions}`}>
        {isMobile ? (
          <ArrowForward
            style={iconColor(false)}
            onClick={() => onViewDocument(signatureDocument.file_id, signatureDocument.filename)}
          />
        ) : (
          <Tooltip title="Ver">
            <IconButton aria-label="ver">
              <Pageview
                style={iconColor(false)}
                onClick={() => onViewDocument(signatureDocument.file_id, signatureDocument.filename)}
              />
            </IconButton>
          </Tooltip>
        )}
        {isNousCims && !isMobile && (
          <>
            <Tooltip title="Eliminar">
              <IconButton aria-label="eliminar">
                <Delete
                  style={iconColor(!!signatureDocument.signature_id)}
                  onClick={() => onRemoveDocument(signatureDocument.id)}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Enviar">
              <IconButton aria-label="enviar">
                <Send
                  style={iconColor(!!signatureDocument.signature_id)}
                  onClick={() => onSendDocument(signatureDocument.id)}
                />
              </IconButton>
            </Tooltip>
            {signatureDocument.status !== SignatureDocumentStatus.completado().toString() && (
              <Tooltip title="Cancelar">
                <IconButton aria-label="cancelar">
                  <Cancel
                    style={iconColor(!signatureDocument.signature_id)}
                    onClick={() => onCancelDocument(signatureDocument.id)}
                  />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
      </td>
    );
  };

  return (
    <tr
      className={styles.documentTableRow}
      onClick={() => isMobile && onViewDocument(signatureDocument.file_id, signatureDocument.filename)}
    >
      <td className={`${styles.documentCellRow}  ${styles.documentFileCell}`}>{signatureDocument.filename}</td>
      <td className={`${styles.documentCellRow}  ${styles.documentStatusCell}`}>
        {sending === signatureDocument.id ? tKey('signature.sending') : signatureDocument.status}
      </td>
      {!isMobile && (
        <td className={`${styles.documentCellRow}  ${styles.documentDateCell}`}>{signatureDocument.updated_at}</td>
      )}
      {renderActions()}
    </tr>
  );
};

export default SignatureDocumentRow;
