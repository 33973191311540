import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DeleteOutline } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import { tKey } from '../../helpers/translate';
import { removePendingTask } from '../../redux/notification/actions';
import { Notification, PendingTask } from '../../redux/notification/definitions';
import { selectIsLoading, selectNotificationsList } from '../../redux/notification/selectors';
import { AppState } from '../../redux/root-reducer';
import styles from './notifications.module.scss';
import SubmenuNotifications from './SubmenuNotifications';

const getLink = (pending_task: PendingTask, id: number): string => {
  switch (pending_task.type) {
    case 'Entity':
      switch (pending_task.action) {
        case 'Validate':
        case 'Check':
          return `/entidad/solicitada/${id}`;
        case 'Approve':
          return `/entidad/confirmada/${id}`;
        default:
          return `/entidad/editar/${id}`;
      }
    case 'Entity-IBAN':
      return `/entidad/editar/${id}`;
    case 'Project':
      return `/proyecto/detalle/${id}/datos_basicos`;
    case 'Scholarship-Entity':
      return `/becas/entity/detalle/${id}`;
    case 'Scholarship-Institute':
      return `/becas/institute/detalle/${id}`;
    case 'Invoice':
      return `/contabilidad/facturas/editar/${id}`;
    case 'Internal-Project':
      switch (pending_task.action) {
        case 'Check-Follow-up-alert':
          return `/proyectos-internos/${id}/editar/seguimientos`;
        default:
          return `/proyectos-internos/${id}/editar/informacion`;
      }
    default:
      return '';
  }
};

interface NotificationProps {
  pending_task: PendingTask;
  removePendingTask: (id: number) => void;
}
const NotificationPanel = ({ pending_task, removePendingTask }: NotificationProps) => {
  const { t } = useTranslation();

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <p>
          {tKey('Tienes')} <strong>{pending_task.count}</strong> {tKey(t(`pending_tasks.type.${pending_task.type}`))}{' '}
          {tKey('pendiente/s de')} {tKey(t(`pending_tasks.action.${pending_task.action}`)).toLocaleLowerCase()}
        </p>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <List component="nav" className={styles.navList}>
          {pending_task.items.map((item, index) => (
            <ListItem key={index}>
              <Link
                to={getLink(pending_task, item.link_id)}
                key={item.foreing_id.toString().concat('_', index.toString())}
              >
                <ListItemText primary={item.description} />
              </Link>
              <div className={styles.buttons}>
                <DeleteOutline
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  onClick={() => {
                    removePendingTask(item.id);
                  }}
                />
              </div>
            </ListItem>
          ))}
        </List>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

interface PendingTasksProps {
  loading: boolean;
  notifications: Notification | null;
  removePendingTask: (id: number) => void;
}

const PendingTasks: React.FC<PendingTasksProps> = ({ loading, notifications, removePendingTask }) => (
  <Layout leftSubmenu={<SubmenuNotifications selected="pending-tasks" />}>
    <div className={styles.pending_tasks_container}>
      {loading && <Loading big />}
      {!loading && (
        <React.Fragment>
          <Title>{tKey('Tareas pendientes')}</Title>
          {notifications?.pending_tasks.map((pending_task, index) => (
            <NotificationPanel key={index} pending_task={pending_task} removePendingTask={removePendingTask} />
          ))}
          {notifications?.pending_tasks.length === 0 && <p>{tKey('Felicidades tareas pendientes')}</p>}
        </React.Fragment>
      )}
    </div>
  </Layout>
);

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  notifications: selectNotificationsList(state),
});
const mapDispatchToProps = (dispatch: any) => ({
  removePendingTask: (id: number) => {
    dispatch(removePendingTask(id));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PendingTasks);
