import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import colorPalette from '../../helpers/colorPalette';
import { getLogo } from '../../helpers/selectImages';
import webStyle from '../../helpers/webStyle';
import styles from './layoutLogin.module.scss';
import { theme, loginTheme } from '../../ui/muiCustomTheme';
import { tKey } from '../../helpers/translate';
interface LayoutLoginProps {
  children: JSX.Element | JSX.Element[];
  subtitle: string;
  dis_space?: boolean;
}

const LayoutLogin: React.FC<LayoutLoginProps> = ({ children, subtitle, dis_space }) => {
  return (
    <ThemeProvider theme={webStyle === 'fnc' ? theme : loginTheme}>
      <div className={`${styles.root} ${styles[`is_${webStyle}`]}`}>
        <img src={getLogo('general', 'black')} className={styles.logo} alt="logo" />
        <div className={`${styles.container} ${dis_space ? styles[`container_dis_${webStyle}`] : ''}`}>
          <h1 className={`${styles.title} ${styles[`maintitle_${webStyle}`]}`}>
            Intranet <br />
            {webStyle === 'fnc' ? 'Nous Cims' : 'ZING Programme'}
          </h1>

          <h2 className={`${styles.subtitle} ${styles[`subtitle_${webStyle}`]}`}>{subtitle}</h2>
          <div className={styles.loginForm}>{children}</div>
          <CookieConsent
            enableDeclineButton
            location="bottom"
            buttonText="Aceptar"
            cookieName="session"
            style={{
              background: webStyle === 'fnc' ? colorPalette.primary : colorPalette.secondary,
              display: 'flex',
              padding: '5px',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '15px',
            }}
            contentStyle={{ alignSelf: 'start' }}
            buttonClasses={`${styles.button} ${styles[`button_${webStyle}`]}`}
            declineButtonClasses={styles[`decline_button_${webStyle}`]}
            expires={150}
            declineButtonText="Denegar"
          >
            <div className={styles.cookieMessage}>
              {tKey('ini.cookies')}
              <Link className={`${styles.link} ${styles.linkMasInformacion}`} to="/politica-de-cookies">
                &nbsp;{tKey('Más información')}
              </Link>
            </div>
          </CookieConsent>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default LayoutLogin;
