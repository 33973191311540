import { Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import TableWithEdit from '../../../../../../components/Tables/TableWithEdit';
import { tFormKey, tKey } from '../../../../../../helpers/translate';
import { IdNamePair } from '../../../../../../redux/common/definitions';
import { GeneralFormData } from '../../../../../../redux/internal-project-templates/definitions';
import InternalProjectTemplateGeneralFormRoles from './InternalProjectTemplateGeneralFormRoles';
import styles from './internalProjectTemplateGeneralForms.module.scss';

interface InternalProjectTemplateGeneralFormsProps {
  forms: GeneralFormData[];
  previousGeneralFormSelected: GeneralFormData | undefined;
  roles: IdNamePair[];
}

const InternalProjectTemplateGeneralForms: React.FC<InternalProjectTemplateGeneralFormsProps> = ({
  forms,
  previousGeneralFormSelected,
  roles,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [modal, setModal] = useState<{ show: boolean; data: GeneralFormData }>({
    show: Boolean(previousGeneralFormSelected),
    data: previousGeneralFormSelected ? previousGeneralFormSelected : ({} as GeneralFormData),
  });
  const tableData: MaterialTableProps<GeneralFormData> = {
    title: '',
    columns: [
      {
        title: tFormKey('Pantalla'),
        field: 'name',
      },
      {
        title: tFormKey('Rol'),
        field: 'role_names',
      },
    ],
    options: {
      selection: false,
      showSelectAllCheckbox: true,
      paging: false,
    },
    actions: [
      (rowData: GeneralFormData) => ({
        icon: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
        onClick: () => setModal({ show: true, data: rowData }),
        tooltip: tKey('Editar'),
      }),
    ],
    data: forms,
  };

  return (
    <>
      <h1 className={styles.title}>{tKey('Pantallas generales')}</h1>
      <TableWithEdit key="general_forms" tableData={tableData}></TableWithEdit>
      {modal.show && (
        <InternalProjectTemplateGeneralFormRoles
          roles={roles}
          formData={modal.data}
          handleClose={() => {
            history.replace({ ...location, state: undefined });
            setModal({ show: false, data: {} as GeneralFormData });
          }}
        />
      )}
    </>
  );
};

export default InternalProjectTemplateGeneralForms;
