import { Button, ButtonProps, InputLabel } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import CompoundComponent from '../../../components/CompoundComponent/CompoundComponent';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import Loading from '../../../components/Loading/Loading';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import { documentationFilter } from '../../../helpers/docFilter';
import { tFormKey, tKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { EntityInfo } from '../../../redux/entity/definitions';
import { EconomicDataEntitySchema } from '../../../validations/formSchema';
import styles from '../entityData.module.scss';
import projectStyles from './projectForm.module.scss';
interface EconomicFormProps {
  leftButtons?: boolean;
  documentation: DocumentationItem[];
  id: number;
  buttonActionSave: ButtonProps;
  disabled?: boolean;
  entity: EntityInfo;
  loading?: boolean;
  goBack?: () => void;
  onSave: (data: EntityInfo) => void;
  upload: (document_id: number, data: File, entity_id: any, force_add?: boolean | false) => void;
  download: (file_id: number, name: string, entityId: number) => void;
  remove: (file_id: number, entityId: number) => void;
  getFile: (ffile_id: number, name: string, entityId: number) => void;
  addDoc?: (document: File, description: string, genericId?: any, documentationId?: number) => void;
  setData: (key: string, value: string | number) => void;
}

const EconomicForm: React.FC<EconomicFormProps> = ({
  leftButtons,
  id,
  documentation,
  entity,
  loading,
  buttonActionSave,
  disabled,
  addDoc,
  setData,
  goBack,
  getFile,
  onSave,
  upload,
  download,
  remove,
}) => {
  const actions = ['checked', 'upload', 'download', 'view', 'remove'];
  const { register, handleSubmit, errors, control } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EconomicDataEntitySchema,
  });

  useButtonAction(buttonActionSave, handleSubmit(onSave));

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <form className={styles.form}>
          <FormContainer space title={tKey('Datos Económicos')}>
            <div className={projectStyles.compoundItem}>
              <InputLabel>{tFormKey('PresupuestoMonetario')}</InputLabel>
              <TextInput
                label=""
                type="number"
                register={register}
                name="economic_data_budget_five_years"
                defaultValue={entity.economic_data_budget_five_years}
                size="60"
                disabled={disabled}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <FncAddDocuments
              genericId={id}
              upload={upload}
              download={download}
              remove={remove}
              getFile={getFile}
              documents={documentationFilter(documentation, ['EPS', 'EBA'])}
              disabled={disabled}
              actions={actions}
            />
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={EconomicDataEntitySchema}
                label={tFormKey('¿Dispone de una auditoría contable externa?')}
                register={register}
                genericId={id}
                errors={errors}
                radioButtonsName="economic_data_external_accounting_audit"
                radioButtonsValue={entity.economic_data_external_accounting_audit}
                textAreaLabel={tFormKey('Descripción')}
                textAreaValue={entity.economic_data_external_accounting_audit_text}
                textAreaName="economic_data_external_accounting_audit_text"
                docs={documentationFilter(documentation, ['EAE'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                disabled={disabled}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={EconomicDataEntitySchema}
                label={tFormKey('Bienes muebles e inmuebles')}
                register={register}
                errors={errors}
                genericId={id}
                radioButtonsName="economic_data_real_state"
                radioButtonsValue={entity.economic_data_real_state}
                textAreaValue={entity.economic_data_real_state_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="economic_data_real_state_text"
                docs={documentationFilter(documentation, ['ERS'])}
                disabled={disabled}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.compoundDoc}>
              <InputLabel disabled={disabled}>{tFormKey('Fuentes de financiación')}</InputLabel>
            </div>

            <FncAddDocuments
              disabled={disabled}
              genericId={id}
              upload={upload}
              download={download}
              remove={remove}
              getFile={getFile}
              documents={documentationFilter(documentation, ['EFF'])}
              actions={actions}
            />
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={EconomicDataEntitySchema}
                label={tFormKey('¿Dispone de una tabla salarial?')}
                register={register}
                disabled={disabled}
                errors={errors}
                genericId={id}
                radioButtonsName="economic_data_salary_table"
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="economic_data_salary_table_text"
                radioButtonsValue={entity.economic_data_salary_table}
                textAreaValue={entity.economic_data_salary_table_text}
                docs={documentationFilter(documentation, ['ETS'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={EconomicDataEntitySchema}
                label={tFormKey('Retribuciones Miembros')}
                register={register}
                disabled={disabled}
                errors={errors}
                genericId={id}
                radioButtonsName="economic_data_salary_retributions"
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="economic_data_salary_retributions_text"
                radioButtonsValue={entity.economic_data_salary_retributions}
                textAreaValue={entity.economic_data_salary_retributions_text}
                docs={documentationFilter(documentation, ['ERO'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
          </FormContainer>
          {!leftButtons && (
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" startIcon={<ArrowBack />} color="primary" fullWidth onClick={goBack}>
                  {tKey('VOLVER')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  color="primary"
                  fullWidth
                  onClick={handleSubmit(onSave)}
                >
                  {tKey('Guardar')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default EconomicForm;
