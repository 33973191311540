import { DocumentationItem } from '../common/definitions';
import { AppState } from '../root-reducer';
import { AboutUsMetada, AboutUsResource, AboutUsState } from './definitions';

export const selectAboutUs = (state: AppState): AboutUsState => state.aboutUs;

export const selectIsLoadingAboutUs = (state: AppState): boolean => selectAboutUs(state).loading;
export const selectMetadata = (state: AppState): AboutUsMetada => selectAboutUs(state).metadata;
export const selectResources = (state: AppState): AboutUsResource[] => selectAboutUs(state).resources;
export const selectOneResource = (state: AppState): AboutUsResource => selectAboutUs(state).oneResource;
export const selectDocuments = (state: AppState): DocumentationItem[] => selectAboutUs(state).documents;
export const selectLoading = (state: AppState): boolean => selectAboutUs(state).loading;
