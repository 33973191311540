import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { ApprovalModalSchema } from '../../../../validations/formSchema';
import styles from './approvalModal.module.scss';

interface ApprovalModalProps {
  handleSend: (message?: string) => void;
  handleClose: () => void;
  path?: string;
  isDestimate?: boolean;
}

const ApprovalModal: React.FC<ApprovalModalProps> = ({ handleSend, handleClose, path, isDestimate = false }) => {
  const history = useHistory();

  interface MessageProps {
    message: string;
  }

  const { errors, handleSubmit, control } = useForm<MessageProps>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ApprovalModalSchema,
  });

  const onSubmit = async (data: MessageProps) => {
    handleSend(data.message);
    handleClose();
    path && history.push(path);
  };

  const defaultMail = tKey(`Texto adicional que se adjuntará en el correo de aprobación`);

  return (
    <form className={styles.refuse} onSubmit={handleSubmit(onSubmit)}>
      <TextInputController
        name="message"
        control={control}
        multiline
        rows="10"
        rowsMax="30"
        errors={errors}
        schema={ApprovalModalSchema}
        placeholder={isDestimate ? undefined : defaultMail}
      />
      <div className={styles.btns}>
        <ButtonGroup>
          <Button variant="contained" startIcon={<SendIcon />} color="primary" type="submit" fullWidth>
            {tScholarshipKey('Ok')}
          </Button>
        </ButtonGroup>
      </div>
    </form>
  );
};

export default ApprovalModal;
