import { Action } from 'redux';
import { ApplicationConsts } from './action_types';

export const initialState = {
  showMenu: false as boolean,
  showMenuMobile: false as boolean,
};

export interface ApplicationShowMenuAction extends Action {
  type: ApplicationConsts.SHOW_MENU;
  show: boolean;
}

export interface ApplicationShowMenuMobileAction extends Action {
  type: ApplicationConsts.SHOW_MENU_MOBILE;
  show: boolean;
}

export type ApplicationAction = ApplicationShowMenuAction | ApplicationShowMenuMobileAction;

export type ApplicationDispatch = (action: ApplicationAction) => void;
