import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { NewEntityRequest, addNewScholarshipEntity, checkNewUser } from '../../../api/Scholarship/scholarship';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import ValidatedTextInput from '../../../components/Inputs/ValidatedTextInput/ValidatedTextInput';
import { yesNoOptions } from '../../../components/RadioButtons/RadioButtonOptions';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';
import { tScholarshipKey } from '../../../helpers/translate';
import { selectUserInfo } from '../../../redux/auth/selectors';
import { AppState } from '../../../redux/root-reducer';
import { ScholarshipUserCheck } from '../../../redux/scholarship/definitions';
import { AddNewScholarshipModalSchema, ForgotPasswordSchema } from '../../../validations/formSchema';
import styles from './addNewScholarshipModal.module.scss';

interface AddNewScholarhipModalProps {
  view: boolean;
  institute: boolean;
  authEmail: string;
  authPhone: string | null;
  student?: null | ScholarshipUserCheck;
  canRenewal?: boolean;
  handleClose: () => void;
  goToScholarshipDetail: (scholaship_id: number) => void;
}

const AddNewScholarhipModal: React.FC<AddNewScholarhipModalProps> = ({
  view,
  institute,
  authEmail,
  authPhone,
  student,
  canRenewal,
  handleClose,
  goToScholarshipDetail,
}) => {
  interface NewScholarshipProps {
    email: string;
    privacy_policy: number;
    send_information: number;
    image_use: number;
    whatsapp_aggregation: number;
    phone: string;
  }

  const [checkResult, setCheckResult] = React.useState<ScholarshipUserCheck | null>(null);
  const { register, errors, handleSubmit, watch, setError, setValue, clearError, control } = useForm<
    NewScholarshipProps
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    reValidateMode: 'onChange',
    validationSchema: AddNewScholarshipModalSchema,
  });
  const haveMail = !+watch('email')?.length && !!watch('privacy_policy');
  const controlError =
    !(!!+watch('send_information') && !!+watch('image_use') && !!+watch('whatsapp_aggregation')) &&
    !(watch('send_information') === undefined);

  const createExistingUserCommon = async (commonProps: {
    email: string;
    user_id: number;
    scholarship_id: number;
  }): Promise<void> => {
    try {
      const formData: NewEntityRequest = {
        email: commonProps.email,
        student_phone: watch('phone'),
        user_id: commonProps.user_id,
        privacy_policy: +watch('privacy_policy') ? 1 : 0,
        send_information: +watch('send_information') ? 1 : 0,
        image_use: +watch('image_use') ? 1 : 0,
        whatsapp_aggregation: +watch('whatsapp_aggregation') ? 1 : 0,
        scholarship_id: commonProps.scholarship_id,
      };

      const scholarship_id = institute
        ? await addNewScholarshipEntity(formData, 'institute')
        : await addNewScholarshipEntity(formData, 'entity');

      if (scholarship_id) {
        goToScholarshipDetail(scholarship_id);
      }
    } catch (error) {
      setError('email', 'notMatch', tScholarshipKey('error-crear-beca'));
    }
  };

  const createExistingUser = async () => {
    if (checkResult) {
      await createExistingUserCommon(checkResult);
    }
  };

  const createExistingUserRenewal = async () => {
    if (student) {
      await createExistingUserCommon(student);
    }
  };

  const closeMe = () => {
    setCheckResult(null);
    // setIsRenewal(false);
    clearError();
    setValue('phone', undefined);
    setValue('privacy_policy', undefined);
    setValue('send_information', undefined);
    setValue('image_use', undefined);
    setValue('whatsapp_aggregation', undefined);
    handleClose();
  };

  const handleCheckBox = (e: React.ChangeEvent<{ checked: boolean }>, name: any): void => {
    register({ name });
    e.target.checked ? setValue(name, 1) : setValue(name, 0);
  };

  const onSubmit = async (data: NewScholarshipProps) => {
    try {
      const checkResult = await checkNewUser(data.email, institute ? 'institute' : 'user');

      if (checkResult.scholarship_id === 0 && checkResult.is_allowed) {
        await createExistingUserCommon({
          email: data.email,
          user_id: checkResult.user_id,
          scholarship_id: checkResult.scholarship_id,
        });
      } else {
        setCheckResult(checkResult);
      }
    } catch (error) {
      setError('email', 'notMatch', tScholarshipKey('error-comprobar-beca'));
    }
  };

  let infoSection;
  let dialogActions;
  //will never execute this because doesn't perform setCheckResult(checkResult);
  if (checkResult) {
    if (checkResult.scholarship_id > 0 || true) {
      if (checkResult.current) {
        // User exists and have scholarship in current academic year. Warning to user.
        infoSection = checkResult.is_allowed ? (
          <div className={styles.message}>
            <Typography>{tScholarshipKey('zinger-existente')}</Typography>
            <Typography>´{tScholarshipKey('eliminar-beca-existente')}</Typography>
          </div>
        ) : (
          <div className={styles.message}>
            <Typography>{tScholarshipKey('usuario-no-pertenece')}</Typography>
            <Typography>{tScholarshipKey('correo-no-pertenece')}</Typography>
          </div>
        );

        dialogActions = (
          <DialogActions>
            <Button onClick={() => closeMe()} color="primary" autoFocus variant="outlined">
              {tScholarshipKey('Ok')}
            </Button>
          </DialogActions>
        );
      } else if (checkResult.is_renewal) {
        if (!institute) {
          dialogActions = (
            <DialogActions>
              <Button onClick={() => closeMe()} color="primary">
                {tScholarshipKey('Cerrar')}
              </Button>
              <Button
                onClick={() => createExistingUser()}
                color="primary"
                autoFocus
                variant="outlined"
                disabled={
                  !watch('phone') ||
                  !watch('privacy_policy') ||
                  !+watch('send_information') ||
                  !+watch('image_use') ||
                  !+watch('whatsapp_aggregation')
                }
              >
                {tScholarshipKey('Crear')}
              </Button>
            </DialogActions>
          );
        }
      } else {
        // User exists without previous scholarship. Inform to user and allow create scholarship.
        infoSection = (
          <div className={styles.message}>
            <Typography>{tScholarshipKey('zinger-existente-sistema')}</Typography>
          </div>
        );
        dialogActions = (
          <DialogActions>
            <Button onClick={() => closeMe()} color="primary">
              {tScholarshipKey('Cerrar')}
            </Button>
            <Button onClick={() => createExistingUser()} color="primary" autoFocus variant="outlined">
              {tScholarshipKey('Ok')}
            </Button>
          </DialogActions>
        );
      }
    }
  }
  //will never execute this because doesn't perform setCheckResult(checkResult);
  if (institute && !checkResult?.current) {
    dialogActions = (
      <DialogActions>
        <Button
          onClick={!!checkResult ? () => createExistingUser() : handleSubmit(onSubmit)}
          color="primary"
          autoFocus
          variant="outlined"
          disabled={
            !watch('phone') ||
            !watch('privacy_policy') ||
            !+watch('send_information') ||
            !+watch('image_use') ||
            !+watch('whatsapp_aggregation')
          }
        >
          {tScholarshipKey('Crear')}
        </Button>
      </DialogActions>
    );
  }
  if (student) {
    dialogActions = (
      <DialogActions>
        <Button onClick={() => closeMe()} color="primary">
          {tScholarshipKey('Cerrar')}
        </Button>
        <Button
          onClick={() => createExistingUserRenewal()}
          color="primary"
          autoFocus
          variant="outlined"
          disabled={
            !watch('phone') ||
            !watch('privacy_policy') ||
            !+watch('send_information') ||
            !+watch('image_use') ||
            !+watch('whatsapp_aggregation')
          }
        >
          {tScholarshipKey('Crear')}
        </Button>
      </DialogActions>
    );
  }

  return (
    <div>
      <Dialog
        open={view}
        onClose={closeMe}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        closeAfterTransition
        maxWidth="xl"
      >
        <div className={styles.title}>
          <DialogTitle id="alert-dialog-title">{tScholarshipKey('Email del Zinger')}</DialogTitle>
          <IconButton aria-label="delete" className={styles.buttonClose} size="small" onClick={() => closeMe()}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <DialogContent className={styles.dialogContent}>
          <form className={styles.newscholarship} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.email}>
              <TextInputController
                label={tScholarshipKey('Introduce la dirección de email')}
                name="email"
                control={control}
                className={styles.input_email}
                rows="3"
                errors={errors}
                schema={ForgotPasswordSchema}
                autoFocus
                defaultValue={institute ? authEmail : student?.email}
                disabled={institute || canRenewal}
              />
              {!institute && !canRenewal && (
                <Button
                  variant="outlined"
                  disableElevation
                  disabled={
                    haveMail || !+watch('send_information') || !+watch('image_use') || !+watch('whatsapp_aggregation')
                  }
                  color="primary"
                  type="submit"
                >
                  {tScholarshipKey('Crear')}
                </Button>
              )}
            </div>
            <>
              <ValidatedTextInput
                label={tScholarshipKey('Introduce un número de teléfono')}
                name="phone"
                register={register}
                schema={AddNewScholarshipModalSchema}
                className={styles.input_student_phone}
                rows="3"
                errors={errors}
                defaultValue={institute ? authPhone : student?.phone}
                disabled={false}
              />
            </>
            {infoSection}
            <FormGroup row className={styles.block}>
              <div className={styles.consentContainer}>
                <div className={styles.consentBlock}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        color="primary"
                        name="privacy_policy"
                        onChange={e => handleCheckBox(e, 'privacy_policy')}
                        checked={watch('privacy_policy') > 0}
                      />
                    }
                    label={
                      <span>
                        {tScholarshipKey('consentimiento-datos')}{' '}
                        <Link to="/politica-de-privacidad" target="_blank">
                          {tScholarshipKey('política de privacidad')}
                        </Link>
                      </span>
                    }
                  />
                  <div className={styles.consent}>
                    {watch('privacy_policy') === 0 && (
                      <span className={styles.helperText}>
                        {tScholarshipKey('Es necesario que aceptes la politica de privacidad')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <RadioButtons
                name="send_information"
                questionText={tScholarshipKey('consentimiento-envio-información')} // Nos   da   su   consentimiento   para   enviarle   información comercial periódica sobre nuestros productos y/o servicios
                register={register}
                options={yesNoOptions}
                optionModal
                defaultValue={'0'}
              />
              <RadioButtons
                name="image_use"
                questionText={tScholarshipKey('consentimiento-imagenes')} // Deseo que mis imágenes sean usadas con la finalidad comentada
                register={register}
                options={yesNoOptions}
                optionModal
                defaultValue={'0'}
              />
              <RadioButtons
                name="whatsapp_aggregation"
                questionText={tScholarshipKey('consentimiento-contacto')} //Deseo  que  la  FUNDACIÓ   PRIVADA   NOUS   CIMS  pueda contactar conmigo por Whatsapp.
                register={register}
                options={yesNoOptions}
                optionModal
                defaultValue={'0'}
              />

              {controlError && <span className={styles.helperText}>{tScholarshipKey('mostrar-conformidad')}</span>}
            </FormGroup>
          </form>
        </DialogContent>
        <div className={styles.buttonActions}>{dialogActions}</div>
      </Dialog>
    </div>
  );
};

const mapStateProps = (state: AppState) => ({
  authEmail: selectUserInfo(state).email,
  authPhone: selectUserInfo(state).phone,
});

export default connect(mapStateProps)(AddNewScholarhipModal);
