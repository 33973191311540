import { Delete, Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import TransitionsModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tKey } from '../../../helpers/translate';
import { selectIsNousCims } from '../../../redux/auth/selectors';
import { closeContactModal, openContactModal } from '../../../redux/entity/actions';
import {
  entityContactRoles,
  entityContactsList,
  entityOneContact,
  removeContactEntity,
} from '../../../redux/entity/actions';
import { EntityContactData, EntityContactRoles } from '../../../redux/entity/definitions';
import { selectEntityContacts, selectRoleList } from '../../../redux/entity/selectors';
import { AppState } from '../../../redux/root-reducer';
import GenericModalEntityContact from '../Modal/GenericModalEntityContact';

interface EntityContactListProps {
  entityId: number;
  loading: boolean;
  loadingModal: boolean;
  contactsList: EntityContactData[];
  rolesList: EntityContactRoles[];
  contactViewModal: boolean;
  isNousCims: boolean;
  setDelete: (id: number, userid: number) => void;
  getEntityContactsList: (id: number) => void;
  getEntityOneContact: (id: number, userid: number) => void;
  getcontactRoles: () => void;
  openContactModal: () => void;
  closeContactModal: () => void;
}

const EntityContactList: React.FC<EntityContactListProps> = ({
  entityId,
  contactsList,
  rolesList,
  contactViewModal,
  loading,
  loadingModal,
  isNousCims,
  setDelete,
  getcontactRoles,
  getEntityContactsList,
  openContactModal,
  closeContactModal,
  getEntityOneContact,
}) => {
  useEffect(() => {
    getEntityContactsList(entityId);
    getcontactRoles();
  }, [entityId, getEntityContactsList, getcontactRoles]);

  const [contactModal, setContactModal] = React.useState({
    children: <Fragment></Fragment>,
    title: '',
  });
  const actionIcons = {
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    Edit: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };

  const onEditContactOne = (rowData: EntityContactData) => {
    getEntityOneContact(rowData.id, entityId);
    setContactModal({
      ...contactModal,
      children: (
        <GenericModalEntityContact
          isUpdate={true}
          roleList={rolesList}
          entityId={entityId}
          closeContactModal={closeContactModal}
        />
      ),
      title: tKey('modificar_contacto'),
    });
    openContactModal();
  };

  const showCreationModal = () => {
    getcontactRoles();
    setContactModal({
      ...contactModal,
      children: (
        <GenericModalEntityContact
          isUpdate={false}
          roleList={rolesList}
          entityId={entityId}
          closeContactModal={closeContactModal}
        />
      ),
      title: tKey('crear_contacto'),
    });
    openContactModal();
  };

  const columns = [
    {
      title: tKey('Cargo'),
      field: 'contact_role_name',
    },
    {
      title: tKey('Nombre'),
      field: 'name',
    },
    {
      title: tKey('Apellidos'),
      field: 'surname',
    },
    {
      title: tKey('Email'),
      field: 'email',
    },
    {
      title: tKey('Teléfono'),
      field: 'phone',
    },
  ];
  if (isNousCims) {
    columns.push({
      title: tKey('Comentarios'),
      field: 'description',
    });
  }

  const tableData: MaterialTableProps<EntityContactData> = {
    title: tKey('contactos_entidad'),
    columns,
    data: contactsList,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    actions: [
      (rowData: EntityContactData) => ({
        icon: actionIcons.Edit,
        onClick: () => onEditContactOne(rowData),
        tooltip: tKey('editar'),
      }),
      (rowData: EntityContactData) => ({
        icon: actionIcons.Delete,
        onClick: () => setDelete(entityId, rowData.id),
        tooltip: tKey('borrar'),
      }),
    ],
  };
  if (loading) return <Loading big />;
  return (
    <>
      <TableWithEdit
        tableData={{ ...tableData }}
        exportButton
        optionsNew={{
          newButtonText: tKey('NUEVO_CONTACTO'),
          onNew: showCreationModal,
        }}
        permission={true}
      />
      {loadingModal ? (
        <Loading big />
      ) : (
        <TransitionsModal
          view={contactViewModal}
          handleClose={() => {
            closeContactModal();
          }}
          title={contactModal.title}
          bodyModal={<div>{contactModal.children}</div>}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.entityReducer.loadingContactList,
  loadingModal: state.entityReducer.loadingModal,
  contactViewModal: state.entityReducer.contactModalView,
  isNousCims: selectIsNousCims(state),
  contactsList: selectEntityContacts(state),
  rolesList: selectRoleList(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setDelete: (entity_id: number, user_id: number): void => dispatch(removeContactEntity(entity_id, user_id)),
  getEntityOneContact: (user_id: number, entity_id: number): void => dispatch(entityOneContact(user_id, entity_id)),
  getEntityContactsList: (id: number): void => dispatch(entityContactsList(id)),
  getcontactRoles: (): void => dispatch(entityContactRoles()),
  openContactModal: (): void => dispatch(openContactModal()),
  closeContactModal: (): void => dispatch(closeContactModal()),
});
export default connect(mapStateToProps, mapDispatchToProps)(EntityContactList);
