import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInternalProjectTemplatesMetadata } from '../../../../api/internalProjectTemplates';
import { tKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { getMetadataOk } from '../../../../redux/internal-project-templates/actions';
import { InternalProjectTemplatesMetadata } from '../../../../redux/internal-project-templates/definitions';
import { AppState } from '../../../../redux/root-reducer';

const useInternalProjectTemplate = () => {
  const dispatch = useDispatch();
  const iptReducer = useSelector((state: AppState) => state.internalProjectTemplateReducer);
  const [metadata, setMetadata] = useState<InternalProjectTemplatesMetadata>(
    iptReducer.internalProjectTemplatesMetadata,
  );

  useEffect(() => {
    if (metadata.types.length === 0) {
      getInternalProjectTemplatesMetadata()
        .then((res: InternalProjectTemplatesMetadata) => {
          setMetadata(res);
          dispatch(getMetadataOk(res));
        })
        .catch(() => {
          showSnackbar(tKey('No se ha podido cargar los metadatos'), 'error', undefined, 1500);
        });
    }
  }, [dispatch, metadata]);

  return { metadata: metadata };
};

export default useInternalProjectTemplate;
