import { DatesData } from '../calendar/definitions';
import { CalendarInterviewsConst } from './action_types';
import {
  GetFreeInterviewDatesAction,
  GetFreeInterviewDatesKoAction,
  GetFreeInterviewDatesOkAction,
  ScholarshipAskInterviewsAction,
  ScholarshipAskInterviewsKoAction,
  ScholarshipAskInterviewsOkAction,
} from './definitions';

export function getFreeInterviewDates(scholarship_id: number, date: number): GetFreeInterviewDatesAction {
  return {
    type: CalendarInterviewsConst.GET_INTERVIEWS_DATES,
    date,
    scholarship_id,
  };
}

export function getFreeInterviewDatesOk(data: DatesData): GetFreeInterviewDatesOkAction {
  return {
    type: CalendarInterviewsConst.GET_INTERVIEWS_DATES_OK,
    datesTime: data,
  };
}

export function getFreeInterviewDatesKo(error: string): GetFreeInterviewDatesKoAction {
  return {
    type: CalendarInterviewsConst.GET_INTERVIEWS_DATES_KO,
    error,
  };
}

export function scholarshipAskInterviews(
  date: number,
  id: number,
  redirect: string,
  channel: string,
): ScholarshipAskInterviewsAction {
  return {
    type: CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS,
    date,
    id,
    redirect,
    channel,
  };
}

export function scholarshipAskInterviewsOk(): ScholarshipAskInterviewsOkAction {
  return {
    type: CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS_OK,
  };
}

export function scholarshipAskInterviewsKo(error: string): ScholarshipAskInterviewsKoAction {
  return {
    type: CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS_KO,
    error,
  };
}
