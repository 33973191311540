import { ButtonProps } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { ObjectSchema, Shape } from 'yup';
import GenericForm from '../../components/GenericForm/GenericForm';
import GenericTable from '../../components/GenericForm/GenericTable';
import LayoutForm from '../../components/Layout/LayoutForm';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import { useHookMethod } from '../../helpers/customHooks/useHookMethod';
import { tProjectKey } from '../../helpers/translate';
import { KeyButton } from '../../redux/common/definitions';
import { CodeSectionProject, FormAssistantSections, SectionProject } from '../../redux/kpis/definitions';
import {
  addNewGenericTableRow,
  DeleteData,
  deleteFile,
  deleteRowTable,
  download,
  GenericFieldUpload,
  getFile,
  getGenericFields,
  RemoveDocData,
  saveCustomFieldsForm,
  setterGenericTableRow,
  uploadGenericField,
} from '../../redux/project/action';
import { FormProjectFields, GenericData, ProjectData } from '../../redux/project/definitions';
import { selectFields, selectGenericTableData, selectLoadingFields } from '../../redux/project/selectors';
import { AppState } from '../../redux/root-reducer';
import { addFieldsToSchema } from './generateSchemaGenericForm';
import ProjectMenuRight from './ProjectMenuRight/ProjectMenuRight';
import SubmenuProject from './SubmenuProject/SubmenuProject';

interface ProjectGenericSectionProps {
  project: ProjectData;
  section: CodeSectionProject;
  buttons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  genericTableData: GenericData;
  loadingFields: boolean;
  fieldsData: { documents: FormProjectFields[]; elements: FormProjectFields[] };
  id_project: number; // fixing reload page
  disabled: boolean;
  project_schedule_id: number;
  addNewRow: (setterData?: any) => void;
  setSetterGenericTableRow: (setterData: any, finalStep: boolean) => void;
  deleteRow: (deleteData: DeleteData) => void;
  sendDoc: (data: GenericFieldUpload) => void;
  downloadDocument: (file_id: number, name: string) => void;
  getDocument: (file_id: number) => void;
  deleteDocument: (data: RemoveDocData) => void;
  getFieldsData: (project_id: number, project_schedule_id: number, section: CodeSectionProject) => void;
  saveCustomFieldsForm: (
    project_id: number,
    project_schedule_id: number,
    section: CodeSectionProject,
    fields: Record<string, string>,
  ) => void;
}
const ProjectGenericSection: React.FC<ProjectGenericSectionProps> = ({
  section,
  project,
  buttons,
  moreInfoButtons,
  genericTableData,
  loadingFields,
  disabled,
  fieldsData,
  id_project,
  project_schedule_id,
  getDocument,
  setSetterGenericTableRow,
  addNewRow,
  deleteRow,
  downloadDocument,
  sendDoc,
  deleteDocument,
  getFieldsData,
  saveCustomFieldsForm,
}) => {
  const [setterData, setSetterData] = useState({});

  const addDoc = (
    document: File,
    description: string,
    genericId?: any,
    documentationId?: number | undefined,
    fieldName?: string | undefined,
  ) => {
    const addData: GenericFieldUpload = {
      document,
      fieldName: fieldName ? fieldName : '',
      project_id: project.id,
    };
    sendDoc(addData);
  };

  const remove = (file_id: number, generic_id?: any, fieldName?: string) => {
    const deleteFileData: RemoveDocData = {
      project_id: generic_id,
      fieldName: fieldName ? fieldName : '',
      fileId: file_id,
    };

    deleteDocument(deleteFileData);
  };

  const schema: ObjectSchema<Shape<object, any>> = yup.object().shape(addFieldsToSchema(fieldsData));

  const { control, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schema,
  });

  const onSave = async (data: Record<string, string>) => {
    Object.keys(data).forEach(element => {
      if (moment.isMoment(data[element])) {
        data[element] = moment(data[element]).format('X');
      }
    });
    if (genericTableData?.columns?.length > 0) {
      await setSetterGenericTableRow(setterData, true);
    }

    // checkValidation(scherma, data);

    saveCustomFieldsForm(project.id, project_schedule_id, section, data);
  };

  useEffect(() => {
    getFieldsData(project.id || id_project, project_schedule_id, section);
  }, [section]); // eslint-disable-line react-hooks/exhaustive-deps

  useHookMethod(buttons, [KeyButton.SAVE], [handleSubmit(onSave)]);

  const buttonsTable: ButtonProps[] = [
    {
      onClick: () => {
        setSetterGenericTableRow(setterData, false);
        addNewRow();
      },
      children: tProjectKey('Añadir Registro'),
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
    },
  ];

  const handleDataSetter = (data: any) => {
    setSetterData(data);
  };

  const handleDeleteClick = async (row: any) => {
    const data = {
      project_id: project.id,
      section: section,
      id_row: row.id,
      id_generated: row.id_generated,
    };
    await setSetterGenericTableRow(setterData, true);
    deleteRow(data);
  };

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuProject
          project={project}
          current_section={section}
          buttons={buttons}
          onClickOption={handleSubmit(onSave)}
        />
      }
      rightSubmenu={<ProjectMenuRight actionButtons={buttons} moreInfoButtons={moreInfoButtons} project={project} />}
    >
      <Title>
        {tProjectKey(
          FormAssistantSections.find((projectSection: SectionProject) => projectSection.code === section)?.name || '',
        )}
      </Title>
      <Fragment>
        {loadingFields && <Loading big />}
        {/* <div className={loading ? styles.hidden : undefined}> */}
        {!loadingFields && fieldsData && !!Object.keys(project).length && (
          <>
            <GenericForm
              control={control}
              section={section}
              project={project}
              errors={errors}
              fields={fieldsData}
              disabled={disabled}
              schema={schema}
              addDoc={addDoc}
              download={downloadDocument}
              remove={remove}
              getFile={getDocument}
              actions={['checked', 'download', 'view', 'remove']}
            />
          </>
        )}
        {genericTableData?.columns?.length > 0 && !loadingFields && (
          <GenericTable
            projectID={project.id}
            disabled={disabled}
            genericTableData={genericTableData}
            buttons={buttonsTable}
            onSetterData={handleDataSetter}
            handleDeleteClick={handleDeleteClick}
          />
        )}
        {/* </div> */}
      </Fragment>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericTableData: selectGenericTableData(state),
  fieldsData: selectFields(state),
  loadingFields: selectLoadingFields(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  saveCustomFieldsForm: (
    project_id: number,
    project_schedule_id: number,
    section: CodeSectionProject,
    fields: Record<string, string>,
  ) => dispatch(saveCustomFieldsForm(project_id, project_schedule_id, section, fields)),
  addNewRow: (setterData?: any) => dispatch(addNewGenericTableRow(setterData)),
  setSetterGenericTableRow: (setterData: any, finalStep: boolean) =>
    dispatch(setterGenericTableRow(setterData, finalStep)),
  deleteRow: (deleteData: DeleteData) => dispatch(deleteRowTable(deleteData)),
  getFieldsData: (project_id: number, project_schedule_id: number, section: CodeSectionProject) =>
    dispatch(getGenericFields(project_id, project_schedule_id, section)),
  sendDoc: (data: GenericFieldUpload): void => dispatch(uploadGenericField(data)),
  downloadDocument: (file_id: number, name: string): void => dispatch(download(file_id, name)),
  getDocument: (file_id: number): void => dispatch(getFile(file_id)),
  deleteDocument: (data: RemoveDocData): void => dispatch(deleteFile(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectGenericSection);
