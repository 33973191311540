import * as yup from 'yup';

export const sixMonthsFollowsSchema = yup.object().shape({
  enrolled_plan_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  // has_gotten_zing_grant: yup.boolean(),
  // training_brings_closer_to_vocation: yup.boolean(),
  training_type_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  training: yup.string(),
  // is_vocational_job: yup.boolean(),
  job: yup.string(),
  reason_no_study_or_job_id: yup
    .number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  another_reason_no_study_or_job: yup.string(),
});
