import { PaymentTypes } from '../accounting/definitions';
import { CodeNamePair, TaxRetention } from '../common/definitions';
import { AppState } from '../root-reducer';
import {
  Areas,
  Delegations,
  GenericMetadata,
  MetadataState,
  Projects,
  ResponsibleMetadata,
  TaxType,
  TeamMetadata,
  ProjectMetadata,
  ScholarshipInfoMetadata,
  TransactionCode,
  OperationKey,
  Serial,
} from './definitions';

export const selectMetadata = (state: AppState): MetadataState => state.metadataReducer;
export const selectError = (state: AppState): string | null => selectMetadata(state).error;
export const selectResponsibles = (state: AppState): ResponsibleMetadata[] => selectMetadata(state).responsibles;
export const selectTeam = (state: AppState): TeamMetadata[] => selectMetadata(state).team;
export const selectGenericMetadata = (state: AppState): GenericMetadata => selectMetadata(state).generic;
export const selectAcademicYears = (state: AppState): string[] => selectMetadata(state).generic.academicYears;
export const selectProjectMetadata = (state: AppState): ProjectMetadata => selectMetadata(state).project;
export const selectLanguages = (state: AppState): CodeNamePair[] => selectMetadata(state).generic.languages;
export const selectCountries = (state: AppState): string[] => selectMetadata(state).generic.countries;
export const selectAreas = (state: AppState): Areas[] => selectMetadata(state).generic.areas;
export const selectChannels = (state: AppState): CodeNamePair[] => selectMetadata(state).generic.channels;
export const selectDelegations = (state: AppState): Delegations[] => selectMetadata(state).generic.delegations;
export const selectProjects = (state: AppState): Projects[] => selectMetadata(state).generic.projects;
export const selectScholarship = (state: AppState): ScholarshipInfoMetadata => selectMetadata(state).scholarship;
export const selectMetadataScholarshipLoaded = (state: AppState): boolean => selectMetadata(state).scholarship.loaded;
export const selectMetadataScholarshipProvinces = (state: AppState): CodeNamePair[] =>
  selectMetadata(state).scholarship.provinces;
export const selectTaxTypes = (state: AppState): TaxType[] => selectMetadata(state).generic.taxTypes;
export const selectAccountingMetadataLoaded = (state: AppState): boolean => selectMetadata(state).accounting.loaded;
export const selectPaymentControlDay = (state: AppState): number =>
  selectMetadata(state).accounting.payment_control_day;
export const selectPaymentDay = (state: AppState): number => selectMetadata(state).accounting.payment_day;
export const selectExpenseAccounts = (state: AppState): CodeNamePair[] =>
  selectMetadata(state).accounting.expense_accounts;
export const selectTransactionCodes = (state: AppState): TransactionCode[] =>
  selectMetadata(state).accounting.transaction_codes;
export const selectOperationKeys = (state: AppState): OperationKey[] => selectMetadata(state).accounting.operation_keys;
export const selectSerials = (state: AppState): Serial[] => selectMetadata(state).accounting.serials;
export const selectTaxes = (state: AppState): TaxRetention[] =>
  selectGenericMetadata(state).taxRetentions.filter((t: TaxRetention) => t.tax_type === 'I');
export const selectCurrentAcademicYear = (state: AppState): string => selectMetadata(state).generic.currentAcademicYear;
export const selectPaymentTypeCodes = (state: AppState): CodeNamePair[] => {
  const excludedTypes = [PaymentTypes.CLIENTES, PaymentTypes.RECARGA] as string[];
  return selectMetadata(state).generic.paymentTypes.filter(
    (paymentType: CodeNamePair) => !excludedTypes.includes(paymentType.code || ''),
  );
};
