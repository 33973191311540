import { put, takeLatest } from '@redux-saga/core/effects';
import { getOneScholarship, scholarshipAsk } from '../../api/Scholarship/scholarship';
import { showSnackbar } from '../FeedbackAPI/actions';
import { scholarshipGetOk } from '../scholarship/actions';
import {
  getFreeInterviewDatesOk,
  getFreeInterviewDatesKo,
  scholarshipAskInterviewsKo,
  scholarshipAskInterviewsOk,
} from './actions';
import { CalendarInterviewsConst } from './action_types';
import { GetFreeInterviewDatesAction, ScholarshipAskInterviewsAction } from './definitions';
import errorMessage from '../../helpers/errorMessage';
import { getFreeInterviewSlotsFromApi } from '../../api/Interview/interview';

function* getAllElegibleDatesFromSaga(action: GetFreeInterviewDatesAction): Generator<any, void, any> {
  try {
    const info = yield getFreeInterviewSlotsFromApi(action.scholarship_id, action.date);
    yield put(getFreeInterviewDatesOk(info));
  } catch (e) {
    yield put(getFreeInterviewDatesKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* scholarshipAskInterviewsFromSaga(action: ScholarshipAskInterviewsAction): Generator<any, void, any> {
  try {
    const { date, id, redirect, channel } = action;
    yield scholarshipAsk(date, id, channel);
    const { data } = yield getOneScholarship(action.id);
    yield put(scholarshipAskInterviewsOk());
    yield put(scholarshipGetOk(data, null));
    yield put(showSnackbar('Has reservado la cita.', 'success', redirect, 1000));
  } catch (e) {
    yield put(scholarshipAskInterviewsKo(errorMessage(e)));
  }
}

export default [
  takeLatest<GetFreeInterviewDatesAction>(CalendarInterviewsConst.GET_INTERVIEWS_DATES, getAllElegibleDatesFromSaga),
  takeLatest<ScholarshipAskInterviewsAction>(
    CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS,
    scholarshipAskInterviewsFromSaga,
  ),
];
