import { put, takeLatest } from '@redux-saga/core/effects';
import { getResourceTrackingsFromApi } from '../../api/tracking';
import errorMessage from '../../helpers/errorMessage';

import { getResourceTrackingListKo, getResourceTrackingListOk } from './actions';
import { TrackingConsts } from './action_types';
import { ResourceTrackingsListAction } from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getResourceTrackingSaga(action: ResourceTrackingsListAction): Generator<any, void, any> {
  try {
    const { id, resourceType } = action;
    const resourceTracking = yield getResourceTrackingsFromApi(id, resourceType);
    yield put(getResourceTrackingListOk(resourceTracking));
  } catch (e) {
    yield put(getResourceTrackingListKo(errorMessage(e)));
  }
}

export default [
  takeLatest<ResourceTrackingsListAction>(TrackingConsts.RESOURCE_TRACKING_LIST, getResourceTrackingSaga),
];
