import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface CheckboxController extends Omit<CheckboxProps, 'name' | 'defaultValue'> {
  control: Control;
  name: string;
  defaultValue?: boolean;
  label: string | React.ReactNode;
}

const CheckboxController = ({ control, name, defaultValue, label, ...props }: CheckboxController) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      as={<FormControlLabel control={<Checkbox {...props} color="primary" />} label={label} />}
    />
  );
};

export default CheckboxController;
