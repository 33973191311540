export enum ScholarshipJustificationsConst {
  GET_SCHOLARSHIP_JUSTIFICATIONS = 'GET:SCHOLARSHIP:JUSTIFICATIONS',
  GET_SCHOLARSHIP_JUSTIFICATIONS_OK = 'GET:SCHOLARSHIP:JUSTIFICATIONS:OK',

  ADD_SCHOLARSHIP_JUSTIFICATIONS = 'ADD:SCHOLARSHIP:JUSTIFICATIONS',
  ADD_SCHOLARSHIP_JUSTIFICATIONS_OK = 'ADD:SCHOLARSHIP:JUSTIFICATIONS:OK',

  DELETE_SCHOLARSHIP_JUSTIFICATIONS = 'DELETE:SCHOLARSHIP:JUSTIFICATIONS',
  DELETE_SCHOLARSHIP_JUSTIFICATIONS_OK = 'DELETE:SCHOLARSHIP:JUSTIFICATIONS:OK',

  SCHOLARSHIP_JUSTIFICATIONS_KO = 'SCHOLARSHIP:JUSTIFICATIONS:KO',
}
