import moment from 'moment';
import React, { Fragment } from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import * as DateConstants from '../../../constants/date';
import { tFormKey } from '../../../helpers/translate';
import { UserData } from '../../../redux/user/definitions';
import ChangePasswordForm from './ChangePasswordForm';

interface AccessDataFormProps {
  createMode?: boolean;
  profileData: UserData;
  errors: FieldErrors<any>;
  readOnly?: boolean;
  control: Control<any>;
  schema: ObjectSchema<Shape<object, any>>;
  isMobile?: boolean;
}

const AccessDataForm: React.FC<AccessDataFormProps> = ({
  createMode,
  profileData,
  errors,
  readOnly,
  control,
  schema,
  isMobile,
}) => {
  const { email, created_at } = profileData;
  return (
    <FormContainer title={tFormKey('Datos de acceso')}>
      <TextInputController
        size="50"
        label={tFormKey('Email')}
        name="email"
        errors={errors}
        control={control}
        disabled={createMode === false || readOnly}
        defaultValue={email}
        schema={schema}
      />
      <Fragment>
        {!createMode ? (
          <TextInputController
            size="50"
            label={tFormKey('Fecha de Registro')}
            name="created_at"
            errors={errors}
            control={control}
            disabled
            schema={schema}
            defaultValue={created_at && moment.unix(created_at).format(DateConstants.DATE_FORMAT)}
          />
        ) : null}
      </Fragment>
      {/* Si es mobil incluye los campos de cambiar pass dentro del formContainer */}
      {isMobile && <ChangePasswordForm />}
    </FormContainer>
  );
};

export default AccessDataForm;
