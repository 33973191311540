import colorPalette from '../helpers/colorPalette';

export default {
  MuiFab: {
    root: {
      position: 'absolute',
      width: '58px',
      height: '58px',
      zIndex: '1202',
    },
    secondary: {
      backgroundColor: colorPalette.secondary20,
      '&:hover': {
        backgroundColor: colorPalette.secondary20,
        opacity: '1',
      },
    },
  },
  MuiGrid: {
    root: {
      maxWidth: 'none!important',
    },
  },
  MuiButton: {
    root: {
      minHeight: 'initial',
      fontWeight: 'bold',
      padding: '8px 20px',
    },
    text: {
      padding: '8px 20px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    textPrimary: {
      backgroundColor: 'transparent',
      //textTransform: 'none',
      fontWeight: 'bold',
      color: colorPalette.secondary80,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    outlinedPrimary: {
      backgroundColor: colorPalette.primary,
      color: 'white',
      fontWeight: 'bold',
      opacity: '0.85',
      '&:hover': {
        backgroundColor: colorPalette.primary,
        opacity: '1',
      },
    },
    outlinedSecondary: {
      backgroundColor: colorPalette.secondary,
      color: 'white',
      fontWeight: 'bold',
      opacity: '0.85',
    },
    containedPrimary: {
      color: 'white',
      backgroundColor: colorPalette.primary,
      '&:hover': {
        backgroundColor: colorPalette.primary80,
      },
      '&.Mui-disabled': {
        opacity: '.5',
        color: colorPalette.primary20,
        backgroundColor: colorPalette.primary40,
      },
    },
    containedSecondary: {
      color: colorPalette.secondary,
      backgroundColor: colorPalette.secondary40,
      '&:hover': {
        backgroundColor: colorPalette.secondary20,
      },
      '&.Mui-disabled': {
        opacity: '.5',
        color: colorPalette.secondary40,
        backgroundColor: colorPalette.secondary20,
      },
    },
  },
  MuiDrawer: {
    root: {
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    paper: {
      top: 'auto',
    },
  },
  MuiOutlinedInput: {
    root: {
      '& $notchedOutline': {
        border: '1px solid white',
        opacity: '0.3',
        color: 'white',
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: 'white',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: 'white',
        },
        color: 'white',
      },
      '&$focused $notchedOutline': {
        borderColor: 'white',
        borderWidth: 2,
        opacity: 1,
        color: 'white',
      },
      '&$error $notchedOutline': {
        borderColor: 'red',
        borderWidth: 2,
        opacity: 1,
      },
    },
    input: {
      padding: '16px 14px',
      color: 'white',
    },
    notchedOutline: {
      color: 'white',
    },
  },
  MuiInput: {
    underline: {
      '&:before': {
        borderBottom: '1px solid white',
        color: 'white',
      },
      '&:after': {
        borderBottom: '1px solid white',
        color: 'white',
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 16px) scale(1)',
      color: 'white',
    },
  },
  MuiFormLabel: {
    root: {
      color: 'white',
      '&$focused': {
        color: 'white',
      },
    },
  },
  MuiFormHelperText: {
    contained: {
      marginLeft: '14px',
      marginRight: '14px',
      position: 'absolute',
      bottom: '2px',
    },
  },
  MuiInputBase: {
    root: {
      marginBottom: '22px',
    },
  },
  MuiTableCell: {
    head: {
      fontWeight: 'bold',
    },
  },
  MuiBadge: {
    badge: {
      color: 'red',
      backgroundColor: 'white',
      fontSize: '1em',
      fontWeight: 'bold',
      margin: '-18%',
    },
  },
  MuiMenu: {
    paper: {
      marginTop: 40,
    },
  },
  MuiTypography: {
    root: {
      color: 'white',
    },
    colorPrimary: {
      color: 'grey',
    },
  },
  MuiTabs: {
    flexContainerVertical: {
      height: '50vh',
      justifyContent: 'space-around',
    },
  },
  MuiTab: {
    wrapper: {
      fontWeight: 'blod',
      fontSize: '1.3em',
      marginLeft: '1%',
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },
  MuiListItem: {
    root: {
      paddingTop: '3px',
      paddingBottom: '3px',
      //justifyContent: 'center',
    },
    button: {
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  MuiListItemIcon: {
    root: {
      //minWidth: 'initial',
    },
  },
  MuiListItemText: {
    root: {
      marginTop: '0px',
      marginBottom: '0px',
    },
  },
  MuiRadio: {
    root: {
      color: 'white',
    },
  },
  MuiCheckbox: {
    root: {
      color: 'white',
    },
  },
};
