import { MaterialTableProps } from 'material-table';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import TableWithEdit from '../../components/Tables/TableWithEdit';
import { exportToExcel } from '../../helpers/exportToXslx';
import { tKey } from '../../helpers/translate';
import { InterviewTableRow } from '../../redux/interviews/definitions';
import { Tooltip } from '@material-ui/core';

interface InterviewListProps {
  interviewData: InterviewTableRow[];
  loading: boolean;
  title: string;
}

const userFullName = (name: string | null, surname: string | null): string => {
  if (!name) return '';
  return `${name} ${surname}`;
};

const InterviewList: React.FC<InterviewListProps> = ({ interviewData, title, loading }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const tableData: MaterialTableProps<InterviewTableRow> = {
    title,
    columns: [
      {
        title: tKey('Nombre'),
        field: 'student_name',
        render: (rowData: InterviewTableRow) => (
          <div>{userFullName(rowData.student_name, rowData.student_surname)}</div>
        ),
        customFilterAndSearch: (term, rowData: InterviewTableRow) =>
          userFullName(rowData.student_name, rowData.student_surname).indexOf(term) !== -1,
      },
      {
        title: tKey('Fecha + hora'),
        field: 'date',
        //@ts-ignore
        width: '160px',
      },
      {
        title: tKey('Entidad/Instituto'),
        field: 'scholarship_origin',
        render: rowData => (
          <Tooltip title={rowData.scholarship_origin}>
            <span>{rowData.scholarship_origin}</span>
          </Tooltip>
        ),
      },
      {
        title: tKey('Educador/a'),
        field: 'educator_name',
        render: (rowData: InterviewTableRow) => (
          <div>{userFullName(rowData.educator_name, rowData.educator_surname)}</div>
        ),
        customFilterAndSearch: (term, rowData: InterviewTableRow) =>
          userFullName(rowData.educator_name, rowData.educator_surname).indexOf(term) !== -1,
      },
      {
        title: tKey('Correo educador/a'),
        field: 'educator_email',
        render: rowData => (
          <Tooltip title={rowData.educator_email}>
            <span>{rowData.educator_email}</span>
          </Tooltip>
        ),
      },
      {
        title: tKey('Curso'),
        field: 'scholarship_academic_year',
        //@ts-ignore
        width: '120px',
      },
      {
        title: tKey('Entrevistador/a'),
        field: 'interviewer_name',
        render: (rowData: InterviewTableRow) => (
          <div>{userFullName(rowData.interviewer_name, rowData.interviewer_surname)}</div>
        ),
        customFilterAndSearch: (term, rowData: InterviewTableRow) =>
          userFullName(rowData.interviewer_name, rowData.interviewer_surname).indexOf(term) !== -1,
      },
      {
        title: tKey('Canal'),
        field: 'channel',
        render: (rowData: InterviewTableRow) => (
          <div> {t(`scholarship.interviews.studentCalendar.channels.${rowData.channel}`)}</div>
        ),
      },
    ],
    data: interviewData,
    isLoading: loading,

    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, tKey(`Entrevistas`));
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
  };

  const details = (rowData: InterviewTableRow) => {
    history.push({
      pathname: `/becas/${rowData.scholarship_type}/detalle/${rowData.scholarship_id}`,
    });
  };

  return (
    <Layout>
      <React.Fragment>
        <TableWithEdit tableData={tableData} exportButton filtering onRowClick={details} />
      </React.Fragment>
    </Layout>
  );
};

export default InterviewList;
