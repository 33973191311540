import React from 'react';
import Submenu, { SubmenuItem } from '../../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../../helpers/translate';

type ComponentsListCode =
  | 'informacion'
  | 'datos_generales'
  | 'seguimientos'
  | 'panel_control'
  | 'evaluacion_entidad'
  | 'seguimientos_diarios';

interface SubmenuInternalProjectEditionProps {
  selected: string;
  hasDailyFollowUp: boolean;
  hasEnrollments: boolean;
  isNousCims: boolean;
  isEntityUser: boolean;
  isSherpaUser: boolean;
  onSelectComponent: (componentId: string) => void;
}

const SubmenuInternalProjectEdition: React.FC<SubmenuInternalProjectEditionProps> = ({
  selected,
  hasDailyFollowUp,
  hasEnrollments,
  isNousCims,
  isEntityUser,
  isSherpaUser,
  onSelectComponent,
}) => {
  const optionsMenu: SubmenuItem<ComponentsListCode>[] = [
    {
      title: tKey('ip.informacion'),
      componentCode: 'informacion',
      type: 'component_code',
      selected: selected === 'informacion',
    },
    {
      title: tKey('ip.datos_generales'),
      componentCode: 'datos_generales',
      type: 'component_code',
      selected: selected === 'datos_generales',
    },
    {
      title: tKey('ip.seguimientos'),
      componentCode: 'seguimientos',
      type: 'component_code',
      selected: selected === 'seguimientos',
    },
  ];

  if (hasEnrollments) {
    if (isNousCims || isSherpaUser) {
      optionsMenu.push({
        title: tKey('ip.seguimientos_participantes'),
        componentCode: 'panel_control',
        type: 'component_code',
        selected: selected === 'panel_control',
      });
    }
    if (isNousCims || isEntityUser) {
      optionsMenu.push({
        title: tKey('ip.seguimientos_entidad'),
        componentCode: 'evaluacion_entidad',
        type: 'component_code',
        selected: selected === 'evaluacion_entidad',
      });
    }
  }

  if (hasDailyFollowUp && isNousCims) {
    optionsMenu.push({
      title: tKey('ip.seguimientos_diarios'),
      componentCode: 'seguimientos_diarios',
      type: 'component_code',
      selected: selected === 'seguimientos_diarios',
    });
  }

  return (
    <Submenu
      titleMenu={tKey('ip.proyectos_internos')}
      optionsMenu={optionsMenu}
      onSelectComponentCode={onSelectComponent}
      defaultOpen
    />
  );
};

export default SubmenuInternalProjectEdition;
