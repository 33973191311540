import { Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableWithEdit from '../../../../../../../../components/Tables/TableWithEdit';
import { tKey, tProjectKey } from '../../../../../../../../helpers/translate';
import { Assistance, MotivoAusencia } from '../../../../../../../../redux/internal-projects/participants/Participant.d';
import {
  getParticipantAssistance,
  updateParticipantAssistance,
} from '../../../../../../../../redux/internal-projects/assistance/action';
import {
  selectParticipantsAssistanceList,
  selectParticipantsAssistants,
  selectParticipantsAssistantsPercentage,
} from '../../../../../../../../redux/internal-projects/assistance/selectors';
import { AppState } from '../../../../../../../../redux/root-reducer';
import SetAssistanceForm from './SetAssistanceForm';
import FncInputText from '../../../../../../../../components/Forms/FncInputs/FncInputText';
import styles from './followUpParticipantAssistance.module.scss';

interface Props {
  followUpId: number;
  participantAssistants: number;
  participantAssistantsPercentage: number;
  assists: Assistance[];
  getParticipantAssistance: (followUpId: number) => void;
  setAssistance: (infoUpdate: Assistance) => void;
}

const FollowUpParticipantAssistance = ({
  followUpId,
  assists,
  participantAssistants,
  participantAssistantsPercentage,
  getParticipantAssistance,
  setAssistance,
}: Props) => {
  const editAssistanceClosed = {
    show: false,
    assitance: {} as Assistance,
  };

  const [editAssistance, setEditAssistance] = useState<{
    show: boolean;
    assitance: Assistance;
  }>(editAssistanceClosed);

  useEffect(() => {
    followUpId && getParticipantAssistance(followUpId);
  }, [followUpId, getParticipantAssistance]);

  const actionIcons = {
    Edit: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };

  const tableData: MaterialTableProps<Assistance> = {
    title: tKey('asistencia.participantes'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'user_name',
        //@ts-ignore
      },
      {
        title: tKey('asistencia'),
        field: 'assistance',
        //@ts-ignore
        render: rowData => {
          return <span>{!!rowData.assistance ? 'Sí' : 'No'}</span>;
        },
      },
      {
        title: tKey('asistencia.motivo'),
        field: 'reasons',
        render: rowData => (
          <Tooltip title={rowData.reasons === MotivoAusencia.OTRO ? rowData.other_reasons : rowData.reasons}>
            <span>{rowData.reasons}</span>
          </Tooltip>
        ),
      },
      {
        title: tKey('Tipo'),
        field: 'type',
        render: rowData => {
          return <span>{tProjectKey(rowData.type)}</span>;
        },
      },
    ],
    data: assists,
    actions: [
      (rowData: Assistance) => ({
        icon: actionIcons.Edit,
        onClick: () => {
          setEditAssistance({
            show: true,
            assitance: rowData,
          });
        },
        tooltip: tKey('editar'),
        disabled: false,
      }),
    ],
    options: {
      selection: false,
      paging: false,
      tableLayout: 'auto',
    },
  };

  const onSetAssistance = (data: Assistance) => {
    setAssistance(data);
    setEditAssistance(editAssistanceClosed);
  };

  return (
    <>
      <TableWithEdit customContainer tableData={{ ...tableData }} filtering permission />
      <div className={styles.summary}>
        <FncInputText disabled label={tKey('Asistentes')} type={`number`} value={participantAssistants} />
        <FncInputText
          disabled
          label={tKey('Porcentaje de asistencia')}
          type={`number`}
          value={participantAssistantsPercentage}
        />
      </div>
      <SetAssistanceForm
        showModal={editAssistance.show}
        assistance={editAssistance.assitance}
        onCloseModal={() => setEditAssistance(editAssistanceClosed)}
        onSetAssistance={(data: Assistance) => onSetAssistance(data)}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  assists: selectParticipantsAssistanceList(state),
  participantAssistants: selectParticipantsAssistants(state),
  participantAssistantsPercentage: selectParticipantsAssistantsPercentage(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getParticipantAssistance: (followUpId: number) => dispatch(getParticipantAssistance(followUpId)),
  setAssistance: (infoUpdate: Assistance) => dispatch(updateParticipantAssistance(infoUpdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpParticipantAssistance);
