import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import Title from '../../../components/Title/Title';
import * as DateConstants from '../../../constants/date';
import { tFormKey, tKey, tScholarshipKey } from '../../../helpers/translate';
import { IdNamePair } from '../../../redux/common/definitions';
import { selectAcademicYears } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { setPaymentsAcademicYear } from '../../../redux/scholarship/actions';
import {
  EntityScholarshipApiPayment,
  paymentFilters,
  paymentFiltersCodes,
  ScholarshipPaymentTotals,
} from '../../../redux/scholarship/definitions';
import { selectPaymentAcademicYear, selectScholarshipPaymentTotals } from '../../../redux/scholarship/selectors';
import useScholarshipPaymentReplaceDates from './hooks/useScholarshipPaymentReplaceDates';
import ReplaceDatesModal from './modals/ReplaceDatesModal';
import styles from './scholarshipsPaymentFilters.module.scss';
import { getEntityDataInfo } from '../../../api/Entity/entity';

interface ScholarshipsPaymentFiltersProps {
  academicYears: string[];
  isEntity?: boolean;
  selectedAcademicYear: string;
  title?: string;
  scholarshipPaymentTotals: ScholarshipPaymentTotals;
  entities?: IdNamePair[];
  entity_id?: number | null;
  onChangeEntity?: (entity_id: number) => void;
  applyFilters: (payment: moment.Moment | null, reload: moment.Moment | null, show: string) => void;
  setSelectedAcademicYear: (academic_year: string) => void;
}

const ScholarshipsPaymentFilters: React.FC<ScholarshipsPaymentFiltersProps> = ({
  academicYears,
  isEntity,
  selectedAcademicYear,
  title,
  scholarshipPaymentTotals,
  entities,
  entity_id,
  onChangeEntity,
  applyFilters,
  setSelectedAcademicYear,
}) => {
  const [filters, setFilters] = useState<{
    payment: moment.Moment | null;
    reload: moment.Moment | null;
    show: string;
  }>({ payment: null, reload: null, show: paymentFiltersCodes.PENDING });
  const [infoEntity, setInfoEntity] = useState<EntityScholarshipApiPayment>();

  const {
    replaceModal,
    replaceDates,
    openReplaceModal,
    closeReplaceModal,
    setFindDate,
    setReplaceDate,
  } = useScholarshipPaymentReplaceDates();
  let titleView;
  if (title) {
    titleView = <Title>{title}</Title>;
  }

  useEffect(() => {
    if (entity_id) {
      getEntityDataInfo(entity_id, selectedAcademicYear).then(response => {
        setInfoEntity(response.data);
      });
    } else {
      setInfoEntity(undefined);
    }
  }, [entity_id, selectedAcademicYear]);

  const onChangeEntitySelect = (entity_id: string) => {
    if (onChangeEntity) {
      onChangeEntity(parseInt(entity_id));
    }
  };

  let entityItems;
  if (!!entities?.length) {
    entityItems = entities.map(element => (
      <MenuItem key={element.id} value={element.id}>
        {element.name}
      </MenuItem>
    ));
  }

  return (
    <>
      <FormContainer className={styles.form_payments} title="" lowMargin>
        {titleView}
        <div className={styles.upper_section}>
          <div className={styles.section_container}>
            <div className={styles.section_between}>
              <div className={`${styles.section_start} ${styles.container_selector}`}>
                <div>
                  <InputLabel id="academic_course">{tFormKey('Curso académico')}</InputLabel>
                  {selectedAcademicYear && (
                    <Select
                      value={selectedAcademicYear}
                      onChange={e => setSelectedAcademicYear(e.target.value as string)}
                      variant="outlined"
                    >
                      {academicYears.map(element => (
                        <MenuItem key={element} value={element}>
                          {element}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </div>
              </div>
              <div className={styles.container_inputs_right}>
                <div className={styles.section_end}>
                  <TextInput
                    type="number"
                    disabled
                    size="20"
                    label={tFormKey('Total pagado')}
                    value={scholarshipPaymentTotals.total_paid || 0}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                  />
                  <TextInput
                    type="number"
                    disabled
                    size="20"
                    label={tFormKey('Total pendiente')}
                    value={scholarshipPaymentTotals.total_pending || 0}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                  />
                </div>
                <div className={styles.section_end}>
                  <TextInput
                    type="number"
                    disabled
                    size="20"
                    label={tFormKey('Próximo pago')}
                    value={scholarshipPaymentTotals.next_payment_amount || 0}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                  />
                  <TextInput
                    style={{ textAlign: 'end' }}
                    disabled
                    size="20"
                    label={tScholarshipKey('Fecha próximo pago')}
                    value={scholarshipPaymentTotals.next_payment_date || ''}
                  />
                </div>
              </div>
            </div>
            {isEntity && (
              <div className={styles.section_between}>
                <div className={`${styles.section_start} ${styles.container_selector}`}>
                  <div>
                    <InputLabel id="entities">{tFormKey('Entidades')}</InputLabel>
                    <Select
                      onChange={e => onChangeEntitySelect(e.target.value as string)}
                      value={entity_id}
                      className={styles.select_entidades}
                      variant="outlined"
                    >
                      <MenuItem key={'todas'} value={0}>
                        {tKey('Todas')}
                      </MenuItem>
                      {entityItems}
                    </Select>
                  </div>
                </div>
                {infoEntity && (
                  <div className={styles.container_inputs_right}>
                    <div className={styles.section_end}>
                      <TextInput disabled size="20" label={tFormKey('Convenio')} value={infoEntity.agreement_number} />
                      <TextInput
                        disabled
                        style={{ width: '49.5%' }}
                        label={tFormKey('IBAN')}
                        value={infoEntity.account_number}
                      />
                    </div>
                    <div className={styles.section_end}>
                      <TextInput
                        type="number"
                        disabled
                        size="20"
                        label={tFormKey('Remanente')}
                        value={infoEntity.remaining_amount | 0}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                      />
                      <TextInput
                        type="number"
                        disabled
                        size="20"
                        label={tFormKey('Anticipo restante')}
                        value={infoEntity.advance_payment_remainder | 0}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className={styles.replacements_section}>
          <div className={styles.section_container}>
            <div className={styles.section_row}>
              <KeyboardDatePicker
                name="find_date"
                label={tFormKey('Buscar')}
                format={DateConstants.DATE_FORMAT}
                variant="inline"
                inputVariant="outlined"
                placeholder="" // Prevent google to detect this input as a credit card number
                helperText={replaceDates.find_error}
                value={replaceDates.find}
                className={styles.datePickers}
                onChange={(date: MaterialUiPickersDate) => {
                  setFindDate(date);
                }}
                autoOk
              />
              <KeyboardDatePicker
                name="replace_date"
                label={tFormKey('Remplazar por')}
                format={DateConstants.DATE_FORMAT}
                variant="inline"
                inputVariant="outlined"
                placeholder="" // Prevent google to detect this input as a credit card number
                helperText={replaceDates.replace_error}
                value={replaceDates.replace}
                className={styles.datePickers}
                onChange={(date: MaterialUiPickersDate) => {
                  setReplaceDate(date);
                }}
                autoOk
              />
              <Button
                className={styles.buttonReplace}
                color="primary"
                variant="contained"
                onClick={() => openReplaceModal()}
                disabled={
                  !replaceDates.find ||
                  !replaceDates.replace ||
                  !!replaceDates.find_error ||
                  !!replaceDates.replace_error
                }
              >
                {tKey('Reemplazar')}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.filters_section}>
          <div className={styles.show_payments_select}>
            <FormControl className={styles.selector} variant="outlined">
              <InputLabel id="show_payments">{tFormKey('Mostrar')}</InputLabel>
              <Select
                label={tFormKey('Mostrar')}
                value={filters.show}
                onChange={e => setFilters(filterDates => ({ ...filterDates, show: e.target.value as string }))}
                variant="outlined"
              >
                {paymentFilters.map(element => (
                  <MenuItem key={element.code} value={element.code}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={styles.section_container}>
            <div className={styles.section_row}>
              <KeyboardDatePicker
                name="filter_payment_date"
                label={tFormKey('Fecha pago centro')}
                format={DateConstants.DATE_FORMAT}
                variant="inline"
                inputVariant="outlined"
                size="medium"
                placeholder="" // Prevent google to detect this input as a credit card number
                value={filters.payment}
                className={styles.datePickers}
                onChange={(date: MaterialUiPickersDate) => {
                  setFilters(filterDates => ({ ...filterDates, payment: date }));
                }}
                // disabled={userCan}
                autoOk
              />
              {!isEntity && (
                <KeyboardDatePicker
                  name="filter_reload_date"
                  label={tFormKey('Fecha recarga/pago becado')}
                  format={DateConstants.DATE_FORMAT}
                  variant="inline"
                  inputVariant="outlined"
                  size="medium"
                  placeholder="" // Prevent google to detect this input as a credit card number
                  value={filters.reload}
                  className={styles.datePickers}
                  onChange={(date: MaterialUiPickersDate) => {
                    setFilters(filterDates => ({ ...filterDates, reload: date }));
                  }}
                  // disabled={userCan}
                  autoOk
                />
              )}

              <Button
                className={styles.buttonFilter}
                color="primary"
                variant="contained"
                onClick={() => applyFilters(filters.payment, filters.reload, filters.show)}
              >
                {tKey('Filtrar')}
              </Button>
            </div>
          </div>
        </div>
      </FormContainer>
      <ReplaceDatesModal
        isEntity={isEntity}
        show={replaceModal}
        replaceDates={replaceDates}
        closeReplaceModal={closeReplaceModal}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  academicYears: selectAcademicYears(state),
  selectedAcademicYear: selectPaymentAcademicYear(state),
  scholarshipPaymentTotals: selectScholarshipPaymentTotals(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedAcademicYear: (academic_year: string): void => dispatch(setPaymentsAcademicYear(academic_year)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipsPaymentFilters);
