import { Edit, DeleteOutline, Block } from '@material-ui/icons';
import React from 'react';

export const ActionIcons = {
  EditIcon: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  EditIconDis: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  BlockIcon: () => <Block style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  BlockIconDis: () => <Block style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  DeleteIcon: () => <DeleteOutline style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  DeleteIconDis: () => <DeleteOutline style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
};
