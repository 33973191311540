import { Action } from 'redux';
import { permissionType } from '../../constants/permissions_definitions';
import { PermissionsConsts } from './action_types';

// INITIAL STATE
export const initialState = {
  error: null as string | null,
  permissions: [] as permissionType[],
  fetchingPermissions: false,
};

export type PermissionsState = typeof initialState;

// TYPESCRIPT DEFINITONS FOR HANDLING ACTIONS

export interface PermissionsListAction extends Action {
  type: PermissionsConsts.PERMISSIONS_LIST;
}

export interface PermissionsListOkAction extends Action {
  type: PermissionsConsts.PERMISSIONS_LIST_OK;
  permissions: permissionType[];
}

export interface PermissionsListKoAction extends Action {
  type: PermissionsConsts.PERMISSIONS_LIST_KO;
  error: string;
}

export type PermissionsAction = PermissionsListAction | PermissionsListOkAction | PermissionsListKoAction;

// TYPESCRIPT DEFINITIONS FOR DISPACHING EVENTS

export type PermissionsDispatch = (action: PermissionsAction) => void;
