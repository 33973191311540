import CameraFrontIcon from '@material-ui/icons/CameraFront';
import PeopleIcon from '@material-ui/icons/People';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import { unixToMonth } from '../../helpers/dateHelper';
import { tKey } from '../../helpers/translate';
import { IDate, ITime } from '../../redux/calendar/definitions';
import Loading from '../Loading/Loading';
import styles from './calendarContainer.module.scss';
import { Tooltip } from '@material-ui/core';

interface Props {
  dates: IDate[];
  times: ITime[];
  datesLoading: boolean;
  timesLoading: boolean;
  onSelectDate: (item: IDate) => void;
  onSelectTime: (item: ITime) => void;
  onShowMore?: () => void;
  onShowLess?: () => void;
  selectedDate: IDate;
}
export default function HorariosContainer({
  dates,
  times,
  datesLoading,
  timesLoading,
  onSelectDate,
  selectedDate,
  onSelectTime,
  onShowMore,
  onShowLess,
}: Props) {
  const online = 'online';
  const both = 'ambas';
  const attended = 'presencial';

  const monthText = (dates: IDate[]) => {
    const monthsArr: string[] = [];
    dates.map(ele => monthsArr.push(unixToMonth(ele.originalDate)));
    const totalMonths = monthsArr.filter((item, index) => monthsArr.indexOf(item) === index);

    if (totalMonths.length === 1) {
      return (
        <div className={styles.monthTitle}>
          {tKey('Calendario')} <span>{totalMonths[0]}</span>
        </div>
      );
    } else {
      return (
        <div className={styles.monthTitle}>
          {tKey('Calendario')}
          <span>{totalMonths[0]}</span> - <span className={styles.capitalize}>{totalMonths[1]}</span>
        </div>
      );
    }
  };
  return (
    <div className={styles.tabs_container}>
      {dates && monthText(dates)}

      <div className={styles.top_tabs_container}>
        {dates && onShowLess ? (
          <div className={styles.show_less_icon} onClick={() => onShowLess()}>
            <ArrowBackIcon />
          </div>
        ) : (
          <div className={styles.show_whitout_icon} />
        )}
        {/* Mobile */}
        <div className={styles.top_tabs_mobile}>
          {dates &&
            dates.map(item => (
              <div
                key={item.originalDate}
                onClick={!item.disabled ? () => onSelectDate(item) : undefined}
                className={
                  item.originalDate === selectedDate.originalDate
                    ? styles.date_box_active
                    : item.disabled
                    ? styles.date_box_disabled
                    : styles.date_box
                }
              >
                <div className={styles.date_box_day_bold}>{item.formattedDay.split(' ')[0].slice(0, 3)}</div>
                <div className={styles.date_box_day}>
                  <div>{item.formattedDay.split(' ')[1]}</div>
                </div>
              </div>
            ))}
        </div>
        {dates && onShowMore ? (
          <div className={styles.show_more_icon} onClick={() => onShowMore()}>
            <ArrowForwardIcon />
          </div>
        ) : (
          <div className={styles.show_whitout_icon}></div>
        )}
      </div>
      <div>
        {times && times.length ? (
          <div className={styles.franjas}>
            {times.length} {tKey(`franjas de horarios disponibles ${selectedDate.formattedDay}`)}
          </div>
        ) : null}
      </div>
      <div className={styles.tab_mobile_bar}></div>
      {!datesLoading ? (
        <div className={styles.main_content}>
          {times && !times.length && !timesLoading ? (
            <p className={styles.franjas_no_disponible}>{tKey('No hay horarios disponibles.')}</p>
          ) : null}
          {timesLoading ? (
            <Loading big />
          ) : (
            <div className={styles.available_times_container}>
              {times &&
                times.map(item => (
                  <div
                    key={`${item.date} ${item.time}`}
                    onClick={() => onSelectTime(item)}
                    className={
                      item.selected === 'busy'
                        ? styles.busy
                        : item.selected === 'not available'
                        ? styles.notavailable
                        : !!item.channel && !!item.selected
                        ? styles[item.channel.replace(/ /g, '')]
                        : styles.notavailable
                    }
                  >
                    <div className={styles.available_times_content}>
                      {item.time}
                      <div className={styles.available_times_icons}>
                        {(item.channel === attended || item.channel === both) && item.selected !== 'not available' ? (
                          <Tooltip disableFocusListener title={tKey('entrevista-presencial')}>
                            <PeopleIcon fontSize="small" aria-labelledby="Presencial"></PeopleIcon>
                          </Tooltip>
                        ) : null}
                        {(item.channel === online || item.channel === both) && item.selected !== 'not available' ? (
                          <Tooltip disableFocusListener title={tKey('entrevista-online')}>
                            <CameraFrontIcon fontSize="small" aria-label="online" />
                          </Tooltip>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      ) : (
        <Loading big />
      )}
    </div>
  );
}
