import TextField from '@material-ui/core/TextField';
import React from 'react';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import { tKey } from '../../../../helpers/translate';
import { ScholarshipUsers } from '../../../../redux/user/definitions';
import styles from './modaltool.module.scss';

interface PaymentModalProps {
  detail: ScholarshipUsers;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ detail }) => {
  return (
    <div className={styles.container}>
      <SubTitle>{tKey('Desglose de pago')}</SubTitle>
      <div className={styles.dialogContent}>
        <TextField
          label={tKey('Fecha de pago 1')}
          defaultValue={detail.payment_first_date ? detail.payment_first_date : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Pago 1')}
          defaultValue={detail.payment_first ? detail.payment_first + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Fecha de pago 2')}
          defaultValue={detail.payment_second_date ? detail.payment_second_date : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Pago 2')}
          defaultValue={detail.payment_second ? detail.payment_second + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Fecha de pago 3')}
          defaultValue={detail.payment_third_date ? detail.payment_third_date : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Pago 3')}
          defaultValue={detail.payment_third ? detail.payment_third + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Fecha de pago 4')}
          defaultValue={detail.payment_fourth_date ? detail.payment_fourth_date : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Pago 4')}
          defaultValue={detail.payment_fourth ? detail.payment_fourth + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
      </div>
      <SubTitle>{tKey('Desglose de importes')}</SubTitle>
      <div className={styles.dialogContent}>
        <TextField
          label={tKey('Total matriculación')}
          defaultValue={detail.enrollment_confirm ? detail.enrollment_confirm + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Total materiales')}
          defaultValue={detail.materials_confirm ? detail.materials_confirm + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Transportes')}
          defaultValue={detail.transport_price_confirm ? detail.transport_price_confirm + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Otros importes')}
          defaultValue={detail.other_costs_confirm ? detail.other_costs_confirm + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Aportación')}
          defaultValue={detail.young_contrib_approval ? detail.young_contrib_approval + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
        <TextField
          label={tKey('Importe ajuste')}
          defaultValue={detail.adjustment_amount ? detail.adjustment_amount + ' €' : 'No hay datos'}
          variant="outlined"
          disabled
        />
      </div>
    </div>
  );
};

export default PaymentModal;
