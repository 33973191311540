import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { recoverEmail } from '../../../api/forgotPassword';
import { tErrorKey, tFormKey } from '../../../helpers/translate';
import { ForgotPasswordSchema } from '../../../validations/formSchema';
import TextInputController from '../../Inputs/TextInputController/TextInputController';
import Loading from '../../Loading/Loading';
import styles from './forgotPasswordForm.module.scss';
import webStyle from '../../../helpers/webStyle';
import { Alert, AlertTitle } from '@material-ui/lab';

export interface ForgotPasswordFields {
  email: string;
}

const ForgotPasswordForm: React.FC = () => {
  const [error, setErr] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, errors, control } = useForm<ForgotPasswordFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ForgotPasswordSchema,
  });

  const [emailSent, setEmailSent] = useState(null as string | null);

  const onSubmit = (data: ForgotPasswordFields) => {
    setErr(undefined);
    setLoading(true);
    setEmailSent(null);
    recoverEmail(data.email)
      .then(res => {
        setLoading(false);
        setEmailSent(data.email);
      })
      .catch(err => {
        setLoading(false);
        setErr(tFormKey('mail_incorrecto'));
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles[`forgot_form_${webStyle}`]}>
      {loading ? (
        <Loading big />
      ) : (
        <>
          {emailSent ? (
            <p className={styles.text}>
              {tFormKey('enviado_mail')} <span className={styles.mail}>{emailSent}</span>
            </p>
          ) : (
            <>
              <TextInputController
                control={control}
                schema={ForgotPasswordSchema}
                errors={errors}
                name="email"
                label={tFormKey('Email')}
                fullWidth
              />
              <Button variant="contained" color="primary" type="submit" fullWidth disableElevation>
                {tFormKey('recuperar')}
              </Button>
            </>
          )}
          {error && (
            <div className={styles.errorMsg}>
              <Alert severity="error">
                <AlertTitle>{tErrorKey('Error')}</AlertTitle>
                <strong>{error}</strong>
              </Alert>
            </div>
          )}
        </>
      )}
    </form>
  );
};

export default ForgotPasswordForm;
