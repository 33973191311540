import { esES } from '@material-ui/core/locale';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import loginOverrides from './loginOverrides';
import overrides from './overrides';
import uiTheme from './theme';
import themeTableOverrides from './themeTableOverrides';
import typography from './typography';
import uiTableTheme from './uiTableTheme';

export const theme = createMuiTheme(
  {
    typography: typography,
    palette: {
      primary: uiTheme.primary,
      secondary: uiTheme.secondary,
    },
    overrides: overrides,
  } as ThemeOptions,
  esES,
);

export const tableTheme = createMuiTheme(
  {
    typography: typography,
    palette: {
      primary: uiTableTheme.primary,
      secondary: uiTableTheme.secondary,
    },
    overrides: themeTableOverrides,
  } as ThemeOptions,
  esES,
);

export const loginTheme = createMuiTheme(
  {
    typography: typography,
    palette: {
      primary: uiTheme.primary,
      secondary: uiTheme.secondary,
    },
    overrides: loginOverrides,
  } as ThemeOptions,
  esES,
);
