import { ButtonProps } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { exportCustomFieldsAreas } from '../../../api/CustomField/customField';
import Layout from '../../../components/Layout/Layout';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { getDocs } from '../../../helpers/getDocs';
import { tKey, tProjectKey } from '../../../helpers/translate';
import { selectUserLang } from '../../../redux/auth/selectors';
import { internalProjectsectionField } from '../../../redux/internal-projects/definitions';
import {
  clearCustomFieldDeactivation,
  deactivateCustomField,
  deleteCustomfield,
  getCustomFieldList,
} from '../../../redux/kpis/action';
import { CustomField, CustomFieldADispatch, sectionField } from '../../../redux/kpis/definitions';
import { selectIdCustomFieldDeactivating, selectListCustomFields, selectLoading } from '../../../redux/kpis/selectors';
import { ProjectMetadata } from '../../../redux/metadata/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import InternalProjectsConfigurationFields from '../../InternalProjects/InternalProjectsConfigurationFields/InternalProjectsConfigurationFields';
import SubmenuConfCustomField from '../SubmenuConfCustomField';

const TransitionModal = React.lazy(() => import('../../../components/Modal/TransitionModal'));
interface KPISProps extends RouteComponentProps<{ code: string }> {
  loading: boolean;
  listCustomFields: CustomField[];
  userCan: boolean;
  projectMetadata: ProjectMetadata;
  user_lang: string;
  idCustomFieldDeactivating: number;
  getCustomFieldList: (code: string) => void;
  deleteCustomfield: (id: number) => void;
  deactivateCustomfield: (id: number) => void;
  clearDeactivation: () => void;
}

const KPIS: React.FC<KPISProps> = ({
  loading,
  userCan,
  match,
  user_lang,
  listCustomFields,
  idCustomFieldDeactivating,
  deleteCustomfield,
  getCustomFieldList,
  deactivateCustomfield,
  clearDeactivation,
}) => {
  const history = useHistory();
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [id, setId] = React.useState<number>(0);

  const [, , field, , code] = match.url.split('/');

  useEffect(() => {
    getCustomFieldList(code);
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  const idItem = (id: number) => {
    setId(id);
    setModal({ view: true, body: [] });
  };

  const getTitle = (): string => sectionField.find(type => type.code === code)?.name || '';
  const getTitle_ip = (): string => internalProjectsectionField.find(type => type.code === code)?.name || '';

  const goToNew = () => {
    history.push(`/configuracion/${field}/configuracion-campos/${code}/creacion/0`);
  };

  const onEditOne = (event: React.MouseEvent<Element, globalThis.MouseEvent>, rowData: any) =>
    history.push(`/configuracion/${field}/configuracion-campos/${code}/edicion/${rowData.id}`);

  const onRemoveOne = (id: number) => deleteCustomfield(id);

  const details = (rowData: CustomField) => {
    const { id } = rowData;
    history.push({
      pathname: `/configuracion/${field}/configuracion-campos/${code}/detalle/${id}`,
    });
  };

  const tableData: MaterialTableProps<CustomField> = {
    title: field === 'proyectos' ? tKey(getTitle()) : tKey(getTitle_ip()),
    columns: [
      {
        field: 'code',
        title: tKey('Código'),
        //@ts-ignore
        width: '100px',
      },
      {
        field: 'description_'.concat(user_lang),
        title: tKey('Descripción'),
      },
      {
        field: 'field_data_types',
        title: tKey('Tipo de dato'),
        //@ts-ignore
        width: '200px',
      },
    ],
    data: listCustomFields,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
    isLoading: loading,
  };

  const onExport = async () => {
    const csv = await exportCustomFieldsAreas();
    const fileName = `campos_proyectos_${moment().format('DDMMYYYY')}.xlsx`;
    getDocs(csv, true, fileName);
  };

  const buttonExport: ButtonProps[] = [
    {
      children: tKey('Exportar'),
      onClick: onExport,
      startIcon: <GetApp />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];
  return (
    <Layout
      leftSubmenu={
        field === 'proyectos' ? (
          <SubmenuConfCustomField submenu={tKey(getTitle())} selected={'config'} />
        ) : (
          <InternalProjectsConfigurationFields submenu={tKey(getTitle())} selected={'config'} />
        )
      }
      rightSubmenu={<ActionsMenu actionsButtons={buttonExport} />}
    >
      <TableWithEdit
        tableData={tableData}
        key={JSON.stringify(tableData.data)}
        onEditOne={onEditOne}
        deleteFunc={userCan ? (rowData: CustomField) => idItem(rowData.id) : undefined /* onRemoveOne */}
        permission={userCan}
        optionsNew={{
          newButtonText: tKey('Nuevo campo'),
          onNew: goToNew,
        }}
        onRowClick={details}
      />
      <Suspense fallback={<></>}>
        <TransitionModal
          view={modal.view}
          handleClose={() => {
            setId(0);
            setModal({ view: false, body: [] });
          }}
          bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
          buttonOk={tKey('Sí')}
          buttonKo={tKey('No')}
          title={tKey('Atención!')}
          handleYes={() => {
            onRemoveOne(id);
            setModal({ view: false, body: [] });
          }}
        />
        <TransitionModal
          view={idCustomFieldDeactivating > 0}
          handleClose={() => {
            clearDeactivation();
          }}
          bodyModal={tProjectKey('custom-field-deactivate')}
          buttonOk={tKey('Sí')}
          buttonKo={tKey('No')}
          title={tProjectKey('Campo en uso')}
          handleYes={() => {
            deactivateCustomfield(id);
          }}
        />
      </Suspense>
    </Layout>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectLoading(state),
  userCan: selectUserCan(state)('allow_create_custom_field'),
  listCustomFields: selectListCustomFields(state),
  user_lang: selectUserLang(state),
  idCustomFieldDeactivating: selectIdCustomFieldDeactivating(state),
});

const mapDispatchToProps = (dispatch: CustomFieldADispatch) => ({
  getCustomFieldList: (code: string): void => dispatch(getCustomFieldList(code)),
  deleteCustomfield: (id: number): void => dispatch(deleteCustomfield(id)),
  deactivateCustomfield: (id: number): void => dispatch(deactivateCustomField(id)),
  clearDeactivation: (): void => dispatch(clearCustomFieldDeactivation()),
});

export default connect(mapStateProps, mapDispatchToProps)(KPIS);
