import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TableLayout from '../../../components/Layout/TableLayout';
import Loading from '../../../components/Loading/Loading';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { tKey } from '../../../helpers/translate';
import { getMetadata, getResources } from '../../../redux/aboutUs/actions';
import { AboutUsDispatch, AboutUsMetada, AboutUsResource } from '../../../redux/aboutUs/definitions';
import { selectIsLoadingAboutUs, selectMetadata, selectResources } from '../../../redux/aboutUs/selectors';
import { DocumentationItem } from '../../../redux/common/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from './aboutUs.module.scss';
import SubmenuAboutUs from './SubmenuAboutUs';

interface DetailRowProps {
  file: DocumentationItem;
}
const DetailRow: React.FC<DetailRowProps> = ({ file }) => (
  <div className={styles.panel_detail_row}>
    <div className={styles.space_reserved_space_buttons} />
    <div>{file.name}</div>
    <div>{file.upload}</div>
    <div>{file.update}</div>
  </div>
);

interface AboutUsProps extends RouteComponentProps<{ code: string }> {
  loading: boolean;
  resources: AboutUsResource[];
  metadata: AboutUsMetada;
  getMetadata: () => void;
  getResources: (code: string) => void;
  userCan: (permission: permissionType) => boolean | undefined;
}

const AboutUs: React.FC<AboutUsProps> = ({
  match,
  loading,
  resources,
  metadata,
  getMetadata,
  getResources,
  userCan,
}) => {
  const {
    params: { code },
  } = match;
  const history = useHistory();
  const canIconfigAboutUs = userCan('action_admin_about_us');

  useEffect(() => {
    if (!metadata.aboutUsTypes) getMetadata();
    getResources(code);
  }, [code, getMetadata, getResources, metadata]);

  const getTitle = (): string =>
    (metadata.aboutUsTypes && metadata.aboutUsTypes.find(type => type.code === code)?.name) || '';

  const goToNew = () => {
    history.push(`/configuracion/sobre-nosotros/${code}/creacion/0`);
  };

  const onEditOne = (
    event: React.MouseEvent<Element, globalThis.MouseEvent>,
    rowData: AboutUsResource | AboutUsResource[],
  ) => {
    if (!Array.isArray(rowData) && rowData) {
      history.push({
        pathname: `/configuracion/sobre-nosotros/${code}/edicion/${rowData.id}`,
        state: { id: rowData.id },
      });
    }
  };

  const [tableData, setTableData] = useState<MaterialTableProps<AboutUsResource>>({
    title: getTitle(),
    columns: [
      {
        field: 'description',
        title: tKey('Descripción'),
      },
    ],
    data: [],
    options: {
      pageSize: 10,
    },
    detailPanel: (rowData: AboutUsResource) =>
      rowData.files.map(file => <DetailRow file={file} key={file.file_id || Math.random()} />),
  });

  useEffect(() => {
    setTableData({
      ...tableData,
      title: tKey(getTitle()),
      data: resources,
    });
  }, [resources]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!metadata.aboutUsTypes) return <Loading big />;

  return (
    <TableLayout
      leftSubmenu={
        <SubmenuAboutUs canConfigure={Boolean(canIconfigAboutUs)} selected={code} submenu={code} metadata={metadata} />
      }
      rightSubmenu={<ActionsMenu goBack onGoBack={() => history.push('/configuracion/sobre-nosotros')} />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <div className={styles.container}>
          <TableWithEdit
            tableData={{ ...tableData, isLoading: loading }}
            key={JSON.stringify(tableData.data)}
            onEditOne={onEditOne}
            permission={canIconfigAboutUs}
            optionsNew={{
              newButtonText: tKey('Nuevo recurso'),
              onNew: goToNew,
            }}
          />
        </div>
      )}
    </TableLayout>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectIsLoadingAboutUs(state),
  resources: selectResources(state),
  metadata: selectMetadata(state),
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: AboutUsDispatch) => ({
  getResources: (code: string): void => dispatch(getResources(code)),
  getMetadata: (): void => dispatch(getMetadata()),
});

export default connect(mapStateProps, mapDispatchToProps)(AboutUs);
