import { TranslationConst } from './action_types';
import {
  CategoriesTKeysAction,
  CategoriesTKeysKoAction,
  CategoriesTKeysOkAction,
  CleanLopdTranslationAction,
  GetFileTranslationsAction,
  GetFileTranslationsKoAction,
  GetFileTranslationsOkAction,
  GetListMailsAction,
  GetListMailsKoAction,
  GetListMailsOkAction,
  GetLopdTranslationAction,
  GetLopdTranslationKoAction,
  GetLopdTranslationOkAction,
  GetMailDetailsAction,
  GetMailDetailsKoAction,
  GetMailDetailsOkAction,
  GetMetadataTranslationAction,
  GetMetadataTranslationKoAction,
  GetMetadataTranslationOkAction,
  ListMails,
  LOPDMail,
  MailDetails,
  SendDataLOPD,
  SendImportTranslationsAction,
  SendImportTranslationsKoAction,
  SendImportTranslationsOkAction,
  SetCategoryFilter,
  SetDataAction,
  SetDataMailAction,
  SetLopdTranslationAction,
  TestTranslationMailAction,
  TranslateData,
  TranslationCategories,
  TranslationKeyData,
  TranslationMetadata,
  TypeLang,
  TypeSetData,
  UpdateLopdTranslationAction,
  UpdateLopdTranslationOkAction,
  UpdateMailDetailsAction,
  UpdateMailDetailsKoAction,
  UpdateMailDetailsOkAction,
  UpdateTranslatedDataAction,
  UpdateTranslatedDataKoAction,
  UpdateTranslatedDataOkAction,
} from './definitions';

export function getCategoriesTkeys(): CategoriesTKeysAction {
  return {
    type: TranslationConst.GET_CATEGORIES_TRANSLATION,
  };
}

export function getCategoriesTkeysOk(keyValues: TranslationKeyData[]): CategoriesTKeysOkAction {
  return {
    type: TranslationConst.GET_CATEGORIES_TRANSLATION_OK,
    keyValues,
  };
}

export function getCategoriesTkeysKo(error: string): CategoriesTKeysKoAction {
  return {
    type: TranslationConst.GET_CATEGORIES_TRANSLATION_KO,
    error,
  };
}

export function getFileTranslations(source: 'keys' | 'custom_fields'): GetFileTranslationsAction {
  return {
    type: TranslationConst.GET_FILE_TRANSLATIONS,
    source,
  };
}

export function getFileTranslationsOk(source: 'keys' | 'custom_fields', data: Blob): GetFileTranslationsOkAction {
  return {
    type: TranslationConst.GET_FILE_TRANSLATIONS_OK,
    data,
    source,
  };
}

export function getFileTranslationsKo(error: string): GetFileTranslationsKoAction {
  return {
    type: TranslationConst.GET_FILE_TRANSLATIONS_KO,
    error,
  };
}

// IMPORT

export function sendImportTranslations(
  source: 'keys' | 'custom_fields',
  translationObj: any,
): SendImportTranslationsAction {
  return {
    type: TranslationConst.SEND_IMPORT_TRANSLATIONS,
    source,
    translationObj,
  };
}

export function sendImportTranslationsOk(response: string): SendImportTranslationsOkAction {
  return {
    type: TranslationConst.SEND_IMPORT_TRANSLATIONS_OK,
    response,
  };
}

export function sendImportTranslationsKo(error: string): SendImportTranslationsKoAction {
  return {
    type: TranslationConst.SEND_IMPORT_TRANSLATIONS_KO,
    error,
  };
}

// LIST MAILS

export function getListMails(): GetListMailsAction {
  return {
    type: TranslationConst.GET_LIST_MAILS,
  };
}

export function getListMailsOk(listMails: ListMails[]): GetListMailsOkAction {
  return {
    type: TranslationConst.GET_LIST_MAILS_OK,
    listMails,
  };
}

export function getListMailsKo(error: string): GetListMailsKoAction {
  return {
    type: TranslationConst.GET_LIST_MAILS_KO,
    error,
  };
}

//GET MAIL DETAILS

export function getMailDetails(mailCode: string): GetMailDetailsAction {
  return {
    type: TranslationConst.GET_MAIL_DETAILS,
    mailCode,
  };
}

export function getMailDetailsOk(mailDetails: MailDetails): GetMailDetailsOkAction {
  return {
    type: TranslationConst.GET_MAIL_DETAILS_OK,
    mailDetails,
  };
}

export function getMailDetailsKo(error: string): GetMailDetailsKoAction {
  return {
    type: TranslationConst.GET_MAIL_DETAILS_KO,
    error,
  };
}

//SET DATA

export function setData(text: string, setType: TypeSetData, lang: TypeLang, id?: number): SetDataAction {
  return {
    type: TranslationConst.SET_DATA,
    text,
    setType,
    lang,
    id,
  };
}

export function setDataMail(key: string, value: string | number): SetDataMailAction {
  return {
    type: TranslationConst.SET_DATA_MAIL,
    key,
    value,
  };
}

//UPDATE MAIL DETAILS

export function updateMailDetails(): UpdateMailDetailsAction {
  return {
    type: TranslationConst.UPDATE_MAIL_DETAILS,
  };
}

export function updateMailDetailsOk(): UpdateMailDetailsOkAction {
  return {
    type: TranslationConst.UPDATE_MAIL_DETAILS_OK,
  };
}

export function updateMailDetailsKo(error: string): UpdateMailDetailsKoAction {
  return {
    type: TranslationConst.UPDATE_MAIL_DETAILS_KO,
    error,
  };
}

//TEST MAILS

export function testTranslationMail(mail_code: string, language: TypeLang): TestTranslationMailAction {
  return {
    type: TranslationConst.TEST_TRANSLATION_MAIL,
    mail_code,
    language,
  };
}

export function updateTranslatedData(
  source: 'keys' | 'custom_fields',
  data: TranslateData,
  opt: { category: TranslationCategories; key_label: string },
): UpdateTranslatedDataAction {
  return {
    type: TranslationConst.UPDATE_TRANSLATE_DATA,
    source,
    data,
    opt,
  };
}

export function updateTranslatedDataOk(
  data: TranslateData,
  opt: { category: TranslationCategories; key_label: string },
): UpdateTranslatedDataOkAction {
  return {
    type: TranslationConst.UPDATE_TRANSLATE_DATA_OK,
    opt,
    data,
  };
}
//GET METADATA TRANSLATIONS

export function getMetadataTranslation(): GetMetadataTranslationAction {
  return {
    type: TranslationConst.GET_METADATA_TRANSLATIONS,
  };
}

export function getMetadataTranslationOk(data: TranslationMetadata): GetMetadataTranslationOkAction {
  return {
    type: TranslationConst.GET_METADATA_TRANSLATIONS_OK,
    data,
  };
}

export function updateTranslatedDataKo(error: string): UpdateTranslatedDataKoAction {
  return {
    type: TranslationConst.UPDATE_TRANSLATE_DATA_KO,
    error,
  };
}
export function getMetadataTranslationKo(error: string): GetMetadataTranslationKoAction {
  return {
    type: TranslationConst.GET_METADATA_TRANSLATIONS_KO,
    error,
  };
}

export function getLopdTranslation(): GetLopdTranslationAction {
  return {
    type: TranslationConst.GET_LOPD_TRANSLATION,
  };
}

export function getLopdTranslationOk(data: LOPDMail): GetLopdTranslationOkAction {
  return {
    type: TranslationConst.GET_LOPD_TRANSLATION_OK,
    data,
  };
}

export function getLopdTranslationKo(error: string): GetLopdTranslationKoAction {
  return {
    type: TranslationConst.GET_LOPD_TRANSLATION_KO,
    error,
  };
}

export function setLopdTranslation(key: keyof LOPDMail, value: string): SetLopdTranslationAction {
  return {
    type: TranslationConst.SET_LOPD_TRANSLATION,
    key,
    value,
  };
}

export function cleanLopdTranslation(mails: LOPDMail): CleanLopdTranslationAction {
  return {
    type: TranslationConst.CLEAN_LOPD_TRANSLATION,
    mails,
  };
}

export function updateLopdTranslation(data: SendDataLOPD): UpdateLopdTranslationAction {
  return {
    type: TranslationConst.UPDATE_LOPD_TRANSLATION,
    data,
  };
}

export function updateLopdTranslationOk(data: SendDataLOPD): UpdateLopdTranslationOkAction {
  return {
    type: TranslationConst.UPDATE_LOPD_TRANSLATION_OK,
    data,
  };
}

export function setCategoryFilter(category: string): SetCategoryFilter {
  return {
    type: TranslationConst.SET_CATEGORY_FILTER,
    category,
  };
}
