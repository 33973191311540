import { ButtonProps, InputLabel, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../components/Select/SelectController';
import Title from '../../../../components/Title/Title';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tScholarshipKey } from '../../../../helpers/translate';
import { validateFields } from '../../../../helpers/validator';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetEconomicalInfoData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import { ScholarshipData, ScholarshipMetadata, ScholarshipSteps } from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipMetadata,
  selectMonthlyIncomes,
  selectScholarshipValidationErrors,
} from '../../../../redux/scholarship/selectors';
import { schoolEconomicalValoration } from '../../../../validations/scholarshipSchema';
import FamilyIncomesView from '../../FamilyIncomes/FamilyIncomesView';
import styles from './requestForms.module.scss';

interface EconomicalValorationFormProps {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  validationErrors: APIValidationError | null;
  monthlyIncomes: number;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
}

const EconomicalValorationForm: React.FC<EconomicalValorationFormProps> = ({
  scholarshipData,
  scholarshipMetadata,
  readOnly,
  buttonActionSave,
  validationErrors,
  setData,
  saveData,
}) => {
  const {
    number_people_coexists,
    legal_situation,
    was_protected,
    family_info,
    living_place_type,
    living_place_cost,
    extra_costs,
    extra_costs_resume,
    has_economic_changes,
    economic_changes_comments,
    is_renewal,
    alternative_incomes_resume,
    is_protected,
    has_dependent_children,
    has_disability,
    disability_percentage,
    disability_details,
    has_special_needs,
    special_needs_details,
    scholarshipType,
    isEntity,
  } = scholarshipData;

  const { livingPlaceTypes } = scholarshipMetadata;

  const { handleSubmit, register, errors, setError, control, setValue } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schoolEconomicalValoration,
  });

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const handleLivingPlaceTypeSelectChange = (target: any) => {
    handleSelectChange(target);

    if (target.value === 'Propiedad') {
      setData('living_place_cost', 0);
      setValue('living_place_cost', 0);
    }
  };

  useValidatorAPI(validationErrors, setError);

  const saveEconomicalValorationInfo = () => {
    saveData(scholarshipData);
  };

  useButtonAction(buttonActionSave, handleSubmit(saveEconomicalValorationInfo));

  const validatorInfo = {
    data: scholarshipData,
    step: 'economicalValoration' as keyof ScholarshipSteps,
  };

  let lastYearInfo;
  let familyInfo;
  let livingPlaceInfo;
  if (is_renewal) {
    lastYearInfo = (
      <>
        <FormContainer title={tScholarshipKey('Respecto al año pasado')}>
          <RadioButtonGroup
            name="has_economic_changes"
            disabled={readOnly}
            questionText={tScholarshipKey(`${scholarshipType}.has_economic_changes`)}
            options={yesNoOptions}
            register={register}
            value={has_economic_changes.toString()}
            validator={validateFields(scholarshipData, 'has_economic_changes', validatorInfo.step)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setData('has_economic_changes', e.target.value === '1' ? 1 : 0);
            }}
          />

          <div className={styles.compoundItem}>
            <InputLabel disabled={readOnly}>{tScholarshipKey('situación-afecta')}</InputLabel>
            <TextInputController
              control={control}
              errors={errors}
              disabled={readOnly}
              size="100"
              rows="4"
              rowsMax="10"
              schema={schoolEconomicalValoration}
              multiline
              validator={validateFields(scholarshipData, 'economic_changes_comments', validatorInfo.step)}
              name="economic_changes_comments"
              defaultValue={economic_changes_comments}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('economic_changes_comments', e.target.value)}
            />
          </div>
        </FormContainer>
      </>
    );
  }

  if (isEntity) {
    familyInfo = (
      <div className={styles.compoundItem}>
        <InputLabel>{tScholarshipKey('visión-estudios')}</InputLabel>
        <TextInputController
          control={control}
          errors={errors}
          disabled={readOnly}
          size="60"
          rows="4"
          rowsMax="10"
          schema={schoolEconomicalValoration}
          multiline
          validator={validateFields(scholarshipData, 'family_info', validatorInfo.step)}
          name="family_info"
          defaultValue={family_info}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('family_info', e.target.value)}
        />
      </div>
    );
  } else {
    livingPlaceInfo = (
      <div className={styles.wrapper}>
        <SelectController
          control={control}
          errors={errors}
          schema={schoolEconomicalValoration}
          size="50"
          name="living_place_type"
          label={tScholarshipKey('Tipo de vivienda')}
          defaultValue={living_place_type}
          validator={validateFields(scholarshipData, 'living_place_type', validatorInfo.step)}
          disabled={readOnly}
          onClick={e => handleLivingPlaceTypeSelectChange(e.target)}
        >
          {livingPlaceTypes.map(element => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
        <TextInputController
          control={control}
          schema={schoolEconomicalValoration}
          type="number"
          errors={errors}
          disabled={readOnly || living_place_type === 'Propiedad'}
          label={tScholarshipKey('importe-alquiler-hipoteca')}
          size="50"
          name="living_place_cost"
          price
          defaultValue={living_place_cost}
          validator={validateFields(scholarshipData, 'living_place_cost', validatorInfo.step)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('living_place_cost', e.target.value)}
        />
        <RadioButtonGroup
          name="extra_costs"
          disabled={readOnly}
          questionText={tScholarshipKey('gasto-significativo')}
          options={yesNoOptions}
          value={extra_costs?.toString() || '0'}
          register={register}
          validator={validateFields(scholarshipData, 'extra_costs', validatorInfo.step)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData('extra_costs', e.target.value === '1' ? 1 : 0);
          }}
        />
        <div className={styles.compoundItem}>
          <InputLabel disabled={readOnly}>{tScholarshipKey('importe-gasto')}</InputLabel>
          <TextInputController
            control={control}
            errors={errors}
            disabled={readOnly}
            size="50"
            rows="4"
            rowsMax="10"
            schema={schoolEconomicalValoration}
            multiline
            validator={validateFields(scholarshipData, 'extra_costs_resume', validatorInfo.step)}
            name="extra_costs_resume"
            defaultValue={extra_costs_resume}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('extra_costs_resume', e.target.value)}
          />
        </div>
      </div>
    );
  }

  let disability_details_view;

  if (has_disability === 1) {
    disability_details_view = (
      <>
        <TextInputController
          errors={errors}
          size="15"
          type="number"
          label={tScholarshipKey('disability_percentage')}
          name="disability_percentage"
          control={control}
          schema={schoolEconomicalValoration}
          defaultValue={disability_percentage || 0}
          disabled={readOnly}
          autoFocus
          validator={validateFields(scholarshipData, 'disability_percentage', validatorInfo.step)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('disability_percentage', e.target.value)}
        />

        <div className={styles.compoundItem}>
          <InputLabel disabled={readOnly}>{tScholarshipKey('disability_details')}</InputLabel>
          <TextInputController
            control={control}
            errors={errors}
            disabled={readOnly}
            fullWidth
            rows="4"
            rowsMax="10"
            schema={schoolEconomicalValoration}
            multiline
            validator={validateFields(scholarshipData, 'disability_details', validatorInfo.step)}
            name="disability_details"
            defaultValue={disability_details}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('disability_details', e.target.value)}
          />
        </div>
      </>
    );
  }

  let special_needs_details_view;
  if (has_special_needs === 1) {
    special_needs_details_view = (
      <div className={styles.compoundItem}>
        <InputLabel disabled={readOnly}>{tScholarshipKey('special_needs_details')}</InputLabel>
        <TextInputController
          control={control}
          errors={errors}
          disabled={readOnly}
          fullWidth
          rows="4"
          rowsMax="10"
          schema={schoolEconomicalValoration}
          multiline
          validator={validateFields(scholarshipData, 'special_needs_details', validatorInfo.step)}
          name="special_needs_details"
          defaultValue={special_needs_details}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('special_needs_details', e.target.value)}
        />
      </div>
    );
  }

  const disability_view = (
    <div className={styles.wrapper}>
      <RadioButtonGroup
        name="has_disability"
        disabled={readOnly}
        questionText={tScholarshipKey(`${scholarshipType}.has_disability`)}
        options={yesNoOptions}
        register={register}
        value={has_disability.toString()}
        validator={validateFields(scholarshipData, 'has_disability', validatorInfo.step)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setData('has_disability', e.target.value === '1' ? 1 : 0);
        }}
      />
      {disability_details_view}
    </div>
  );

  const special_needs_view = (
    <div className={styles.wrapper}>
      <RadioButtonGroup
        name="has_special_needs"
        disabled={readOnly}
        questionText={tScholarshipKey(`${scholarshipType}.has_special_needs`)}
        options={yesNoOptions}
        register={register}
        value={has_special_needs.toString()}
        validator={validateFields(scholarshipData, 'has_special_needs', validatorInfo.step)}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setData('has_special_needs', e.target.value === '1' ? 1 : 0);
        }}
      />
      {special_needs_details_view}
    </div>
  );

  return (
    <form onSubmit={e => e.preventDefault()} className={styles.form}>
      <Title>{tScholarshipKey('SOLICITUD DE BECA - VALORACIÓN ECONÓMICA')}</Title>
      {lastYearInfo}
      <FormContainer title="Datos Económicos">
        <div className={styles.compoundItem}>
          <InputLabel disabled={readOnly}>
            {tScholarshipKey(isEntity ? 'documentación-actual' : 'indica-documentación-actual')}{' '}
          </InputLabel>
          <InputLabel disabled={readOnly}>
            {tScholarshipKey(isEntity ? 'NOTA-aprobación' : 'NOTA-tipo-documentación')}
          </InputLabel>
          <TextInputController
            control={control}
            schema={schoolEconomicalValoration}
            errors={errors}
            disabled={readOnly}
            size="100"
            rows="4"
            rowsMax="10"
            multiline
            name="legal_situation"
            defaultValue={legal_situation}
            validator={validateFields(scholarshipData, 'legal_situation', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('legal_situation', e.target.value)}
          />
        </div>
        {disability_view}
        {special_needs_view}
        <div className={`${styles.wrapper} ${styles.protectedFormContainer}`}>
          <RadioButtonGroup
            name="was_protected"
            disabled={readOnly}
            questionText={tScholarshipKey(`${scholarshipType}.was_protected`)}
            options={yesNoOptions}
            register={register}
            value={was_protected.toString()}
            validator={validateFields(scholarshipData, 'was_protected', validatorInfo.step)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setData('was_protected', e.target.value === '1' ? 1 : 0);
            }}
          />
          <RadioButtonGroup
            name="is_protected"
            disabled={readOnly}
            questionText={tScholarshipKey(`${scholarshipType}.is_protected`)}
            options={yesNoOptions}
            register={register}
            value={is_protected.toString()}
            validator={validateFields(scholarshipData, 'is_protected', validatorInfo.step)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setData('is_protected', e.target.value === '1' ? 1 : 0);
            }}
          />
        </div>
        <div className={`${styles.wrapper} ${styles.protectedFormContainer}`}>
          <SelectController
            control={control}
            errors={errors}
            schema={schoolEconomicalValoration}
            size="50"
            name="number_people_coexists"
            label={tScholarshipKey(isEntity ? 'convivientes' : 'convientes-contigo')}
            defaultValue={number_people_coexists}
            validator={validateFields(scholarshipData, 'number_people_coexists', validatorInfo.step)}
            disabled={readOnly}
            onClick={e => handleSelectChange(e.target)}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((element: number) => (
              <MenuItem key={element} value={element}>
                {element}
              </MenuItem>
            ))}
          </SelectController>
          <div className={styles.wrapper}>
            <RadioButtonGroup
              name="has_dependent_children"
              disabled={readOnly}
              questionText={tScholarshipKey(`${scholarshipType}.has_dependent_children`)}
              options={yesNoOptions}
              register={register}
              value={has_dependent_children.toString()}
              validator={validateFields(scholarshipData, 'has_dependent_children', validatorInfo.step)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('has_dependent_children', e.target.value === '1' ? 1 : 0);
              }}
            />
            {familyInfo}
          </div>
        </div>
      </FormContainer>

      <FormContainer title="Situación económica">
        <FamilyIncomesView scholarshipData={scholarshipData} readOnly={readOnly} />
        {livingPlaceInfo}
        <div className={styles.compoundItem}>
          <InputLabel disabled={readOnly}>{tScholarshipKey('explica-situación-económica')}</InputLabel>
          <TextInputController
            control={control}
            errors={errors}
            disabled={readOnly}
            fullWidth
            rows="4"
            rowsMax="10"
            schema={schoolEconomicalValoration}
            multiline
            validator={validateFields(scholarshipData, 'alternative_incomes_resume', validatorInfo.step)}
            name="alternative_incomes_resume"
            defaultValue={alternative_incomes_resume}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('alternative_incomes_resume', e.target.value)}
          />
        </div>
      </FormContainer>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  validationErrors: selectScholarshipValidationErrors(state),
  monthlyIncomes: selectMonthlyIncomes(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetEconomicalInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EconomicalValorationForm);
