import { Action } from 'redux';
import { IdNamePair } from '../common/definitions';
import { AreasConst } from './action_types';

export type AreaCode = 'OCU' | 'DEV' | 'BIE' | 'KTU';

export interface AreasData {
  id: number;
  code: string;
  name: string;
  reserved: number | boolean;
  subareas: SubAreaInfo[];
}
export interface AreasRoleData {
  area_id: number;
  area_name: string;
  role_id: number;
  role_name: string;
}

export interface Tracking {
  id: number;
  title: string;
  message: string;
  area_id: number;
}

export interface SubAreaInfo {
  id: number;
  code?: string;
  name: string;
  area_id: number;
  codeArea?: string;
}

export const initialState = {
  loading: false,
  error: null as string | null,
  areasDataList: [] as AreasData[],
  subAreaList: [] as SubAreaInfo[],
  areasRoleList: [] as AreasRoleData[],
  roleList: [] as IdNamePair[],
  modalView: false,
};

export type AreasState = typeof initialState;

// GET AREAS

export interface GetAreasAction extends Action {
  type: AreasConst.GET_AREAS | AreasConst.CANCEL_GET_AREAS;
}

export interface GetAreasOkAction extends Action {
  type: AreasConst.GET_AREAS_OK;
  areasDataList: AreasData[];
}

export interface GetAreasKoAction extends Action {
  type: AreasConst.GET_AREAS_KO;
  error: string;
}

export interface CancelGetAreasAction extends Action {
  type: AreasConst.CANCEL_GET_AREAS;
}

//DISABLED AREA
export interface DisableAreaAction extends Action {
  type: AreasConst.DISABLE_AREA;
  a_id: number;
}
export interface DisableAreaOkAction extends Action {
  type: AreasConst.DISABLE_AREA_OK;
  a_id: number;
}

//GET AREAS ROLES
export interface GetAreasRolesAction extends Action {
  type: AreasConst.GET_ROLES_AREA;
}
export interface GetAreasRolesOkAction extends Action {
  type: AreasConst.GET_ROLES_AREA_OK;
  areasRoleList: AreasRoleData[];
}
export interface GetAreasRolesKoAction extends Action {
  type: AreasConst.GET_ROLES_AREA_KO;
  error: string;
}
//REMOVE AREA ROLE

export interface RemoveAreasRolesAction extends Action {
  type: AreasConst.REMOVE_ROLES_AREA;
  area_id: number;
  role_id: number;
}
export interface RemoveAreasRolesOkAction extends Action {
  type: AreasConst.REMOVE_ROLES_AREA_OK;
  area_id: number;
  role_id: number;
}
export interface RemoveAreasRolesKoAction extends Action {
  type: AreasConst.REMOVE_ROLES_AREA_KO;
  error: string;
}

//GET ROLES
export interface GetRolesAreasAction extends Action {
  type: AreasConst.GET_ROLES_LIST;
}
export interface GetRolesAreasOkAction extends Action {
  type: AreasConst.GET_ROLES_LIST_OK;
  roleList: IdNamePair[];
}
export interface GetRolesAreasKoAction extends Action {
  type: AreasConst.GET_ROLES_LIST_KO;
  error: string;
}
//ADD AREA ROLE

export interface AddRoleAreaAction extends Action {
  type: AreasConst.ADD_ROLES_LIST;
  area_id: number;
  role_id: number;
}
export interface AddRoleAreaOkAction extends Action {
  type: AreasConst.ADD_ROLES_LIST_OK;
  data: AreasRoleData[];
}
export interface AddRoleAreaKoAction extends Action {
  type: AreasConst.ADD_ROLES_LIST_KO;
  error: string;
}

export interface DisableAreaKoAction extends Action {
  type: AreasConst.DISABLE_AREA_KO;
  error: string;
}
// DELETE SUBAREA

export interface DeleteSubAreaAction extends Action {
  type: AreasConst.DELETE_SUB_AREA;
  id: number;
}

export interface DeleteSubAreaOkAction extends Action {
  type: AreasConst.DELETE_SUB_AREA_OK;
  id: number;
}

export interface DeleteSubAreaKoAction extends Action {
  type: AreasConst.DELETE_SUB_AREA_KO;
  error: string;
}

// ADD SUBAREA

export interface AddSubareaAction extends Action {
  type: AreasConst.ADD_SUBAREA;
  id: number;
  data: string;
}

export interface AddSubareaOkAction extends Action {
  type: AreasConst.ADD_SUBAREA_OK;
  responseAdd: SubAreaInfo;
}

export interface AddSubareaKoAction extends Action {
  type: AreasConst.ADD_SUBAREA_KO;
  error: string;
}
//EDIT AREA
export interface EditAreaAction extends Action {
  type: AreasConst.EDIT_AREA;
  id: number;
  data: AreasData;
}

export interface EditAreaOkAction extends Action {
  type: AreasConst.EDIT_AREA_OK;
  id: number;
  data: AreasData;
}

export interface EditAreaKoAction extends Action {
  type: AreasConst.EDIT_AREA_KO;
  error: string;
}
// EDIT SUBAREA

export interface EditSubAreaAction extends Action {
  type: AreasConst.EDIT_SUBAREA;
  id: number;
  data: string;
}

export interface EditSubAreaOkAction extends Action {
  type: AreasConst.EDIT_SUBAREA_OK;
  id: number;
  data: string;
}

export interface EditSubAreaKoAction extends Action {
  type: AreasConst.EDIT_SUBAREA_KO;
  error: string;
}

export interface StoreAreasAction extends Action {
  type: AreasConst.STORE_AREAS;
  name: string;
}

export interface StoreAreasOkAction extends Action {
  type: AreasConst.STORE_AREAS_OK;
  areasData: AreasData;
}

export interface StoreAreasKoAction extends Action {
  type: AreasConst.STORE_AREAS_KO;
  error: string;
}

export interface OpenAreaRoleModalAction extends Action {
  type: AreasConst.OPEN_AREA_ROLES_MODAL;
}

export interface CloseAreaRoleModalAction extends Action {
  type: AreasConst.CLOSE_AREA_ROLES_MODAL;
}

export type AreasAction =
  | GetAreasAction
  | GetAreasOkAction
  | GetAreasKoAction
  | DeleteSubAreaAction
  | DeleteSubAreaOkAction
  | DeleteSubAreaKoAction
  | AddSubareaAction
  | AddSubareaOkAction
  | AddSubareaKoAction
  | EditSubAreaAction
  | EditSubAreaOkAction
  | EditSubAreaKoAction
  | CancelGetAreasAction
  | StoreAreasAction
  | StoreAreasOkAction
  | StoreAreasKoAction
  | EditAreaAction
  | EditAreaOkAction
  | EditAreaKoAction
  | DisableAreaAction
  | DisableAreaOkAction
  | DisableAreaKoAction
  | GetAreasRolesAction
  | GetAreasRolesOkAction
  | GetAreasRolesKoAction
  | RemoveAreasRolesAction
  | RemoveAreasRolesOkAction
  | RemoveAreasRolesKoAction
  | GetRolesAreasAction
  | GetRolesAreasOkAction
  | GetRolesAreasKoAction
  | AddRoleAreaAction
  | AddRoleAreaOkAction
  | AddRoleAreaKoAction
  | CloseAreaRoleModalAction
  | OpenAreaRoleModalAction;

export type AreasDispatch = (action: AreasAction) => void;
