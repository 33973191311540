class FollowUpType {
  static BIANNUAL = 'S';
  static QUATERLY = 'T';

  constructor(private code: string) {}

  public isBiannual(): boolean {
    return this.code === FollowUpType.BIANNUAL;
  }

  public isQuaterly(): boolean {
    return this.code === FollowUpType.QUATERLY;
  }

  public value(): string {
    return this.code;
  }

  public static biannual(): FollowUpType {
    return new FollowUpType(FollowUpType.BIANNUAL);
  }

  public static quaterly(): FollowUpType {
    return new FollowUpType(FollowUpType.QUATERLY);
  }
}

export default FollowUpType;
