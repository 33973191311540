import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './navTabs.module.scss';

interface NavTabsProps {
  tabsInfo: {
    id: number;
    title: string;
    url: string;
  }[];
  defaultTab?: number;
}
const NavTabs: React.FC<NavTabsProps> = ({ defaultTab, tabsInfo }) => (
  <div className={styles.wrapper}>
    <Tabs
      // TabIndicatorProps={{ style: { width: '300px', background: 'red' } }}
      indicatorColor="primary"
      variant="fullWidth"
      value={defaultTab || 0}
      aria-label="nav tabs"
      className={styles.tabs}
    >
      {tabsInfo.map(tab => (
        <Tab
          key={tab.id}
          label={tab.title}
          component={Link}
          to={tab.url}
          className={defaultTab === tab.id ? styles.tab_selected : undefined}
        />
      ))}
    </Tabs>
  </div>
);

export default NavTabs;
