import { MaterialTableProps } from 'material-table';
import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SelectCheckboxController from '../../../components/Select/SelectCheckboxController/SelectCheckboxController';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tKey } from '../../../helpers/translate';
import { getAcademicsCenters, importAcademies } from '../../../redux/academy/actions';
import { AcademyDataDispatch, AcademyDataList } from '../../../redux/academy/definitions';
import { selectTypeList } from '../../../redux/academy/selectors';
import { AuthUserData } from '../../../redux/auth/definitions';
import { selectUserInfo } from '../../../redux/auth/selectors';
import { IdCodeColor } from '../../../redux/common/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { scholarshipGetMetadata } from '../../../redux/scholarship/actions';
import SubmenuEntities from '../../Entities/SubmenuList/SubmenuEntities';
import WhatType from './AcademicCenterTypes';
interface AcademicCenterList {
  userInfo: AuthUserData;
  loading: boolean;
  academicCenterList: AcademyDataList[];
  loaded: boolean;
  educationalTypes: IdCodeColor[];
  userCan: (permission: permissionType) => boolean | undefined;
  getAcademicsCenters: () => void;
  scholarshipGetMetadata: () => void;
  importAcademies: (user_id: number) => void;
}

export const AcademicCenterListComponent: React.FC<AcademicCenterList> = ({
  loading,
  userInfo,
  academicCenterList,
  loaded,
  educationalTypes,
  userCan,
  getAcademicsCenters,
  scholarshipGetMetadata,
  importAcademies,
}) => {
  const history = useHistory();
  useEffect(() => {
    getAcademicsCenters();
    loaded || scholarshipGetMetadata();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { user_id } = userInfo;

  const [importing, setImporting] = useState(false);

  const normalCell: CSSProperties = {
    minWidth: '130px',
    textAlign: 'left',
  };

  const action_import = userCan('allow_edit_academies');

  const disabledCell: CSSProperties = {
    color: '#00000074',
    minWidth: '130px',
    textAlign: 'left',
  };

  const tableData: MaterialTableProps<AcademyDataList> = {
    title: tKey('Centros académicos'),
    columns: [
      {
        title: tKey('Codigo de centro'),
        field: 'code',
        cellStyle: (academies: AcademyDataList[], rowData: AcademyDataList) =>
          rowData.enabled ? normalCell : disabledCell,
      },
      {
        title: tKey('Nombre'),
        field: 'name',
        cellStyle: (academies: AcademyDataList[], rowData: AcademyDataList) =>
          rowData.enabled ? normalCell : disabledCell,
      },

      {
        title: tKey('Población'),
        field: 'town',
        cellStyle: (academies: AcademyDataList[], rowData: AcademyDataList) =>
          rowData.enabled ? normalCell : disabledCell,
      },
      {
        title: tKey('Código postal'),
        field: 'zip_code',
        cellStyle: (academies: AcademyDataList[], rowData: AcademyDataList) =>
          rowData.enabled ? normalCell : disabledCell,
      },
      {
        title: tKey('Tipo de centro'),
        field: 'educational_types',
        render: (rowData: AcademyDataList) => <WhatType rowData={rowData} />,
        customFilterAndSearch: (types, rowData) => {
          if (Array.isArray(types)) return types.every((type: string) => rowData.educational_types.includes(type));
          if (typeof types === 'string') return rowData.educational_types.includes(types);
          return false;
        },
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <SelectCheckboxController
            list={educationalTypes}
            valueSend="code"
            valueLabel="name"
            columnDef={columnDef}
            onFilterChanged={onFilterChanged}
          />
        ),
      },
    ],
    data: [],
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Centros educativos`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  const details = (rowData: AcademyDataList) => {
    history.push({
      pathname: `/instituto/detalles/${rowData.id}`,
      state: { id: rowData.id },
    });
  };

  const showImporting = () => {
    setImporting(true);
  };

  return (
    <Layout leftSubmenu={<SubmenuEntities selected="centros-educativos" submenu={tKey('Centros Educativos')} />}>
      <TableWithEdit
        tableData={{ ...tableData, data: academicCenterList, isLoading: loading || !loaded }}
        exportButton
        filtering
        onRowClick={details}
        permission
        optionsNew={
          action_import
            ? {
                newButtonText: tKey('Importar Centros'),
                onNew: showImporting,
              }
            : undefined
        }
      />
      <TransitionModal
        view={importing}
        handleClose={() => {
          setImporting(false);
        }}
        bodyModal={tKey('Importar centro BBDD')}
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        title={tKey('Importación de centros educativos')}
        handleYes={() => {
          importAcademies(user_id);
          setImporting(false);
        }}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.academyReducer.loading,
  academicCenterList: state.academyReducer.academyList,
  loaded: !!Object.keys(state.scholarshipReducer.scholarshipMetadata).length,
  educationalTypes: selectTypeList(state),
  userInfo: selectUserInfo(state),
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: AcademyDataDispatch | any) => ({
  getAcademicsCenters: (): void => dispatch(getAcademicsCenters()),
  scholarshipGetMetadata: (): void => dispatch(scholarshipGetMetadata()),
  importAcademies: (user_id: number): void => dispatch(importAcademies(user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcademicCenterListComponent);
