import React from 'react';
import { AppRoute } from '../../../../constants/routes';
import { Link } from 'react-router-dom';
import styles from './menuBottom.module.scss';
import { tKey } from '../../../../helpers/translate';
import webStyle from '../../../../helpers/webStyle';
import AboutUs from '../../../../images/menu/aboutus.jpg';
import Configuracion from '../../../../images/menu/configuracion.jpg';
import ConfiguracionCampos from '../../../../images/menu/configuracion_campos.jpg';
import Contabilidad from '../../../../images/menu/contabilidad.jpg';
import Convenio from '../../../../images/menu/convenios.jpg';
import Directory from '../../../../images/menu/directory.jpg';
import Events from '../../../../images/menu/events.jpg';
import Services from '../../../../images/menu/services.jpg';
import Encuestas from '../../../../images/menu/encuestas.jpg';
import Entidades from '../../../../images/menu/entidades.jpg';
import Institut from '../../../../images/menu/institut.jpg';
import KPIs from '../../../../images/menu/kpis.jpg';
import Mensajes from '../../../../images/menu/mensajes.jpg';
import Proyectos from '../../../../images/menu/proyectos.jpg';
import SobreNousCims from '../../../../images/menu/sobre_nous_cims.jpg';
import ProyectosInternos from '../../../../images/menu/internal-projects.jpg';
import EscolesConcertades from '../../../../images/menu/escoles-concertades.jpg';
import Usuarios from '../../../../images/menu/usuarios.jpg';
import Zing from '../../../../images/menu/zing_2x2.jpg';

import ZingNetwork from '../../../../images/menu/zing_network.jpg';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const images: Record<string, string> = {
  proyectos: Proyectos,
  convenios: Convenio,
  encuestas: Encuestas,
  messages: Mensajes,
  entidades: Entidades,
  usuarios: Usuarios,
  kpis: KPIs,
  accounting: Contabilidad,
  directory: Directory,
  events: Events,
  'config-menu': Configuracion,
  'eventos-usuario': Events,
  'services-usuario': Services,
  'sobre-nous-cims': SobreNousCims,
  'becas-instituto': Institut,
  'mis-becas': Institut,
  'becas-entidad': Zing,
  'becas-escoles-concertades': EscolesConcertades,
  'about-us-view': AboutUs,
  'mi-entidad': Entidades,
  'documents-help': Configuracion,
  'solicitudes-entrevista': Entidades,
  'entrevistas-realizadas': AboutUs,
  'calendario-entrevistador': ConfiguracionCampos,
  'mis-proyectos': Proyectos,
  'config-enable-scholarship': ConfiguracionCampos,
  'config-fields': ConfiguracionCampos,
  'zing-network-events': ZingNetwork,
  'internal-projects': ProyectosInternos,
  'internal-projects-academy': ProyectosInternos,
};

const icons: Record<string, JSX.Element> = {
  'tareas-pendientes': <AssignmentOutlinedIcon key={0} />,
  reuniones: <PeopleAltIcon key={0} />,
  notificaciones: <NotificationsNoneOutlinedIcon key={0} />,
};

export interface BoxMenuItem {
  item: AppRoute;
}
export const BoxMenuItem = ({ item }: BoxMenuItem) => {
  const link = item.path.indexOf('/:') > 0 ? item.path.substring(0, item.path.indexOf('/:')) : item.path;
  return (
    <Link
      to={link}
      key={item.name}
      className={`${styles.item} ${item.empty ? styles.empty : ''}`}
      style={{
        backgroundImage: images[item.name]
          ? `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${images[item.name]})`
          : '',
      }}
    >
      <div className={styles[`content_${webStyle}`]}>
        <div className={styles.layer}></div>
        {icons[item.name] && <span>{icons[item.name]} </span>}
        <h2 className={styles.title}>
          <span className={styles.isLight}>{tKey(item.title || '')} </span>
          {item.subtitle && <strong>{tKey(item.subtitle || '')}</strong>}
        </h2>
      </div>
    </Link>
  );
};
