export enum ScholarshipInstitutePaymentsConst {
  CHANGE_SCHOLARSHIP_PAYMENT_DATE = 'CHANGE:SCHOLARSHIP:PAYMENT:DATE',
  CHANGE_SCHOLARSHIP_PAYMENT_DATE_OK = 'CHANGE:SCHOLARSHIP:PAYMENT:DATE:OK',
  CHANGE_SCHOLARSHIP_PAYMENT_DATE_KO = 'CHANGE:SCHOLARSHIP:PAYMENT:DATE:KO',

  SEND_SCHOLARSHIP_RELOAD = 'SEND:SCHOLARSHIP:RELOAD',
  SEND_SCHOLARSHIP_RELOAD_OK = 'SEND:SCHOLARSHIP:RELOAD:OK',
  SEND_SCHOLARSHIP_RELOAD_KO = 'SEND:SCHOLARSHIP:RELOAD:KO',

  SEND_SCHOLARSHIP_STUDENT_TRANSFER = 'SEND:SCHOLARSHIP:STUDENT:TRANSFER',
  SEND_SCHOLARSHIP_STUDENT_TRANSFER_OK = 'SEND:SCHOLARSHIP:STUDENT:TRANSFER:OK',
  SEND_SCHOLARSHIP_STUDENT_TRANSFER_KO = 'SEND:SCHOLARSHIP:STUDENT:TRANSFER:KO',

  SEND_SCHOLARSHIP_PAYMENT = 'SEND:SCHOLARSHIP:PAYMENT',
  SEND_SCHOLARSHIP_PAYMENT_OK = 'SEND:SCHOLARSHIP:PAYMENT:OK',
  SEND_SCHOLARSHIP_PAYMENT_KO = 'SEND:SCHOLARSHIP:PAYMENT:KO',
}
