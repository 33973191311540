import { Button, FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React, { FormEvent, Fragment, useState } from 'react';
import { Control, FieldErrors, ManualFieldError, ValidateResult } from 'react-hook-form/dist/types';
import { Link } from 'react-router-dom';
import { APIError, APIValidationError } from '../../api/api';
import useValidatorAPI from '../../helpers/customHooks/useValidatorAPI';
import { tFormKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import { RegisterFields, RolesProps } from '../../redux/register/actions';
import { RegisterSchema } from '../../validations/formSchema';
import Feedback from '../Feedback/Feedback';
import TextInputController from '../Inputs/TextInputController/TextInputController';
import Loading from '../Loading/Loading';
import RadioButtons from '../RadioButtons/RadioButtons';
import styles from './registerForm.module.scss';

interface Props {
  submitForm: (data: RegisterFields) => void;
  control: Control<RegisterFields>;
  handleSubmit: (
    submitForm: (data: RegisterFields) => void,
  ) => ((event: FormEvent<HTMLFormElement>) => void) | undefined;
  entity: boolean;
  loading: boolean;
  roles: RolesProps[];
  errorApi: APIError | null;
  errors: FieldErrors<RegisterFields>;
  defaultRole: number;
  setError: (
    name: ManualFieldError<Record<string, any>>[],
    type?: Record<string, ValidateResult> | string,
    message?: string | undefined,
  ) => void;
  validationErrors: APIValidationError | null;
  register: (instance: any) => void;
}

const RegisterForm = ({
  submitForm,
  entity,
  loading,
  roles,
  errorApi,
  errors,
  control,
  defaultRole,
  handleSubmit,
  setError,
  validationErrors,
  register,
}: Props) => {
  const [checked, setChecked] = useState(true);
  useValidatorAPI(validationErrors, setError);

  const valOptions: { value: string; label: string }[] = [];
  roles.forEach(type => {
    valOptions.push({ value: type.id.toString(), label: type.name });
  });
  let errorMessage = '';
  if (errorApi && errorApi instanceof Error) {
    errorMessage = errorApi.message;
  }
  return (
    <form className={styles[`register_form_${webStyle}`]} onSubmit={handleSubmit(submitForm)}>
      <Fragment>
        {errorApi && <Feedback backroundColor="red" text={errorMessage} color="white" icon={<ErrorOutlineIcon />} />}
        <Fragment>
          <TextInputController
            errors={errors}
            schema={RegisterSchema}
            control={control}
            label={tFormKey('Email')}
            name="email"
            fullWidth
          />
          <TextInputController
            control={control}
            label={tFormKey('Contraseña')}
            type="password"
            name="password"
            fullWidth
            errors={errors}
            schema={RegisterSchema}
          />
          <TextInputController
            errors={errors}
            control={control}
            label={tFormKey('repetir_contraseña')}
            type="password"
            name="password2"
            fullWidth
            schema={RegisterSchema}
          />
        </Fragment>
        {roles && !entity && (
          <RadioButtons
            name="role_id"
            questionText={tFormKey('tipo_usuario')}
            disabled={loading}
            options={valOptions}
            defaultValue={defaultRole.toString()}
            register={register}
          />
        )}
        <div className={styles.downloadLink}>
          <FormControlLabel
            control={<Checkbox onChange={() => setChecked(!checked)} color="primary" />}
            label={
              <span>
                {tFormKey('consentir_datos')}
                <Link to="/politica-de-privacidad" target="_blank">
                  {tFormKey('politica_privacidad')}
                </Link>
              </span>
            }
          />
        </div>
        {!loading ? (
          <div className={styles.marginOk}>
            <Button variant="contained" color="primary" fullWidth type="submit" disabled={checked} disableElevation>
              {tFormKey('Registro')}
            </Button>
          </div>
        ) : (
          <Loading small />
        )}
      </Fragment>
    </form>
  );
};

export default RegisterForm;
