import { SubmenuItemTypeOption } from '../../../redux/scholarship/definitions';
import styles from './modals.module.scss';
import React, { useState } from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { tScholarshipKey } from '../../../helpers/translate';
import colorPalette from '../../../helpers/colorPalette';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { KeyButton } from '../../../redux/common/definitions';
import { getMoreInfoButtonType } from '../ButtonActions/MoreInfoButtonActions';

interface Props {
  componentType: SubmenuItemTypeOption;
}
const ModalHelp = ({ componentType }: Props) => {
  const [modal, setModal] = useState(false);
  const buttonHelp = getMoreInfoButtonType(KeyButton.HELP);
  buttonHelp.onClick = () => {
    setModal(true);
  };
  return (
    <>
      <TransitionModal
        view={modal}
        handleClose={() => {
          setModal(false);
        }}
        title={tScholarshipKey('Ayuda')}
        helperMode={true}
      >
        <div className={styles.modalHelp}>
          <p>
            <KeyboardArrowRightIcon style={{ color: colorPalette.primary80, verticalAlign: 'top' }} />
            {tScholarshipKey('click-guardar')}
          </p>
          <p>
            <KeyboardArrowRightIcon style={{ color: colorPalette.primary80, verticalAlign: 'top' }} />
            {tScholarshipKey('campos-destacados')}
          </p>
          <div>
            {componentType === SubmenuItemTypeOption.VALORACION_MOTIVACIONAL && (
              <p>
                <KeyboardArrowRightIcon style={{ color: colorPalette.primary80, verticalAlign: 'top' }} />
                {tScholarshipKey(
                  'Es totalmente necesario adjuntar la carta de motivación hecha a mano por el joven que opta a recibir la beca (ortografía propia del joven). El no envío de la carta, puede ser motivo de no revisión de la beca.',
                )}
              </p>
            )}
            {componentType === SubmenuItemTypeOption.VALORACION_EDUCATIVA && (
              <>
                <p>
                  <KeyboardArrowRightIcon style={{ color: colorPalette.primary80, verticalAlign: 'top' }} />
                  {tScholarshipKey('parrafo')}
                </p>
                <p>
                  <KeyboardArrowRightIcon style={{ color: colorPalette.primary80, verticalAlign: 'top' }} />
                  {tScholarshipKey('info-adjuntar')}
                </p>
              </>
            )}

            {componentType === SubmenuItemTypeOption.IMPORTE_BECA && (
              <p>
                <KeyboardArrowRightIcon style={{ color: colorPalette.primary80, verticalAlign: 'top' }} />
                {tScholarshipKey('importe-beca-solicitada')}
                <a href="becas@zingprogramme.com">becas@zingprogramme.com</a>
              </p>
            )}
          </div>
        </div>
      </TransitionModal>
    </>
  );
};

export default ModalHelp;
