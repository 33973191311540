import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './containers/App';
import webStyle from './helpers/webStyle';
import './i18next';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store, { history } from './store';
import { theme } from './ui/muiCustomTheme';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ConnectedRouter history={history}>
          <div className={webStyle}>
            <App />
          </div>
        </ConnectedRouter>
      </MuiPickersUtilsProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
