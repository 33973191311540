import { apiBaseUrl, apiCallNoResponse } from '../../api';
import { Educator } from '../../../redux/internal-projects/educators/Educator';

const baseURL = apiBaseUrl;

export const updateEducator = async (educator: Educator): Promise<void> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(educator),
  };

  return apiCallNoResponse(
    `${baseURL}/internal_project/${educator.internal_project_id}/participants/educator/${educator.id}`,
    init,
  );
};
