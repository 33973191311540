import { CodeSectionProject } from '../redux/kpis/definitions';
import { ScholarshipSteps } from '../redux/scholarship/definitions';

export const validateFields = (
  data: any,
  fieldset: string,
  step?: keyof ScholarshipSteps | CodeSectionProject,
): boolean => {
  if (!step) {
    return data?.required?.errorFields.includes(fieldset) || false;
  }
  if (step !== 'complete' && data) {
    return data.steps[step].errorFields?.includes(fieldset);
  } else {
    return false;
  }
};

export const checkStepsErrorFields = (
  errorFields: string[],
  fieldName: string,
  value: string | number | null,
): string[] => {
  if (value) {
    const index = errorFields.indexOf(fieldName);
    if (index > -1) {
      errorFields.splice(index, 1);
    }
  } else {
    if (!errorFields.includes(fieldName)) {
      errorFields.push(fieldName);
    }
  }
  return errorFields;
};

export const removeStepsErrorField = (errorFields: string[], fieldName: string): string[] => {
  return checkStepsErrorFields(errorFields, fieldName, 1);
};
