import { SubareaInternalProjectTypeConst } from './action_types';
import { SubareaInternalProjectTypeAction, initialState, SubareaInternalProjectTypeState } from './definition.d';

const subareaInternalProjectTypeReducer = (
  state = initialState,
  action: SubareaInternalProjectTypeAction,
): SubareaInternalProjectTypeState => {
  switch (action.type) {
    case SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE_OK:
      const { data } = action;

      return {
        ...state,
        loading: false,
        error: null,
        data,
      };
    case SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
  }
  return state;
};

export default subareaInternalProjectTypeReducer;
