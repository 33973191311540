import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableLayout from '../../../components/Layout/TableLayout';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tKey } from '../../../helpers/translate';
import { AppState } from '../../../redux/root-reducer';
import { getUserServices } from '../../../redux/service/actions';
import { ServicesUser } from '../../../redux/service/definitions';
import styles from './service.module.scss';

interface UserServicesPageProps {
  servicesUserList: ServicesUser[];
  getUserServices: () => void;
}

const UserServices: React.FC<UserServicesPageProps> = ({ servicesUserList, getUserServices }) => {
  const [data, setData] = useState<ServicesUser[]>([]);

  useEffect(() => {
    getUserServices();
  }, [getUserServices]);

  useEffect(() => {
    if (servicesUserList.length > 0) setData(servicesUserList);
  }, [servicesUserList]);

  const tableData: MaterialTableProps<ServicesUser> = {
    title: tKey('Lista de Servicios'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'name',
        cellStyle: {
          maxWidth: '600px',
        },
      },
      {
        title: tKey('Fecha'),
        field: 'start_date',
        render: (rowData: ServicesUser) => rowData.start_date.split(' ')[0],
      },
      { title: tKey('Organizador'), field: 'provider_contact' },
      { title: tKey('Email de contacto'), field: 'provider_contact_email' },
      { title: tKey('Teléfono de contacto'), field: 'provider_contact_phone' },
    ],
    data: data,
    options: {
      exportButton: false,
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  return (
    <TableLayout>
      <div className={styles.container}>
        <TableWithEdit tableData={tableData} filtering />
      </div>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  servicesUserList: state.serviceReducer.servicesUserList,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserServices: (): void => dispatch(getUserServices()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserServices);
