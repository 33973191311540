import { ScholarshipStateChange, ScholarshipData } from '../../../redux/scholarship/definitions';
import { tScholarshipKey } from '../../../helpers/translate';
import React, { useState } from 'react';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SkipInterviewModal from './SkipInterview/SkipInterviewModal';
import { KeyButton } from '../../../redux/common/definitions';
import { getButtonType } from '../ButtonActions/ScholarshipButtonActions';

interface Props {
  scholarshipData: ScholarshipData;
  handleChangeState: (states: ScholarshipStateChange, dataValues?: ScholarshipData | undefined) => void;
}
const AcceptScholarshipModal = ({ scholarshipData, handleChangeState }: Props) => {
  const [modal, setModal] = useState(false);

  const ButtonAccept = getButtonType(KeyButton.ACCEPT);
  ButtonAccept.onClick = () => {
    setModal(true);
  };
  return (
    <>
      <TransitionModal
        view={modal}
        handleClose={() => {
          setModal(false);
        }}
        title={tScholarshipKey('Aceptar')}
        helperMode={true}
      >
        <SkipInterviewModal
          zip_code={scholarshipData.student_zip_code}
          handleClose={() => {
            setModal(false);
          }}
          handleSend={interview_entity =>
            handleChangeState('s02s03', { ...scholarshipData, interview_entity: interview_entity })
          }
        />
      </TransitionModal>
    </>
  );
};

export default AcceptScholarshipModal;
