import { Tooltip } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React from 'react';
import { connect } from 'react-redux';
import NavTabs from '../../../components/Tabs/NavTabs';
import { tFormKey, tKey } from '../../../helpers/translate';
import { IdCodeColor } from '../../../redux/common/definitions';
import { EntityListItem } from '../../../redux/entity/definitions';
import { AppState } from '../../../redux/root-reducer';

const proyecto = tFormKey('Proyectos');
const becas = tFormKey('Becas');
const fisica = tFormKey('Persona física');
const juridica = tFormKey('Persona jurídica');
const no_payments = tKey('Sin pagos');
const no_data = tKey('No hay datos');

export const whatType = (rowData: EntityListItem) => {
  const isEmpty =
    rowData.has_project === 0 &&
    rowData.has_scholarship === 0 &&
    rowData.legal_person === 0 &&
    rowData.natural_person === 0 &&
    rowData.no_payments_entity === 0;

  return (
    <>
      {rowData.has_project === 1 && (
        <Tooltip title={proyecto}>
          <Brightness1Icon style={{ color: '#5696fc', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.has_scholarship === 1 && (
        <Tooltip title={becas}>
          <Brightness1Icon style={{ color: '#ffd787', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.legal_person === 1 && (
        <Tooltip title={juridica}>
          <Brightness1Icon style={{ color: '#fabc93', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.no_payments_entity === 1 && (
        <Tooltip title={no_payments}>
          <Brightness1Icon style={{ color: '#faaB42', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.natural_person === 1 && (
        <Tooltip title={fisica}>
          <Brightness1Icon style={{ color: '#b593fa', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {isEmpty === true ? <span>{no_data}</span> : null}
    </>
  );
};

interface WhatTypeProps {
  entityTypes: IdCodeColor[];
  rowData: EntityListItem;
}

const WhatType: React.FC<WhatTypeProps> = ({ rowData, entityTypes }) => {
  const isEmpty =
    rowData.has_project === 0 &&
    rowData.has_scholarship === 0 &&
    rowData.legal_person === 0 &&
    rowData.natural_person === 0 &&
    rowData.no_payments_entity === 0;

  return (
    <>
      {rowData.has_project === 1 && (
        <Tooltip title={proyecto}>
          <Brightness1Icon style={{ color: '#5696fc', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.has_scholarship === 1 && (
        <Tooltip title={becas}>
          <Brightness1Icon style={{ color: '#ffd787', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.legal_person === 1 && (
        <Tooltip title={juridica}>
          <Brightness1Icon style={{ color: '#fabc93', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.no_payments_entity === 1 && (
        <Tooltip title={no_payments}>
          <Brightness1Icon style={{ color: '#faaB42', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {rowData.natural_person === 1 && (
        <Tooltip title={fisica}>
          <Brightness1Icon style={{ color: '#b593fa', height: '15px', verticalAlign: 'text-bottom' }} />
        </Tooltip>
      )}
      {isEmpty === true ? <span>{no_data}</span> : null}
    </>
  );
};

export default connect((state: AppState) => ({
  entityType: state.entityReducer.entityTypes,
}))(WhatType);

export const EntityTabs = (index: number) => (
  <NavTabs
    defaultTab={index}
    tabsInfo={[
      {
        id: 0,
        title: tKey('Entidades activas'),
        url: '/entidades',
      },
      {
        id: 1,
        title: tKey('Solicitadas'),
        url: '/entidades/solicitadas',
      },
      {
        id: 2,
        title: tKey('Confirmadas'),
        url: '/entidades/confirmadas',
      },
      {
        id: 3,
        title: tKey('En creación'),
        url: '/entidades/nuevas',
      },
    ]}
  />
);
