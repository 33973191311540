import { FamilyIncome } from '../FamilyIncome';
import { DeleteFamilyIncomeConst } from './action_types';
import { RemoveFamilyIncomeAction, RemoveFamilyIncomeActionKo, RemoveFamilyIncomeActionOk } from './definitions';

export function removeFamilyIncome(income: FamilyIncome): RemoveFamilyIncomeAction {
  return {
    type: DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME,
    income,
  };
}

export function removeFamilyIncomeOk(income: FamilyIncome): RemoveFamilyIncomeActionOk {
  return {
    type: DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME_OK,
    income,
  };
}

export function removeFamilyIncomeKo(error: string): RemoveFamilyIncomeActionKo {
  return {
    type: DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME_KO,
    error,
  };
}
