import storeProvider from '../../storeProvider';
import { SubareaInternalProjectTypeConst } from './action_types';
import {
  CancelGetSubareaInternalProjectTypeAction,
  GetSubareaInternalProjectTypeAction,
  GetSubareaInternalProjectTypeKoAction,
  GetSubareaInternalProjectTypeOkAction,
} from './definition';
import { SubareaInternalProjectType } from './subareaInternalProjectType';

export function getSubareaInternalProjectType(): GetSubareaInternalProjectTypeAction {
  const internalProjectType = storeProvider.getState().subareaInternalProjectTypeReducer.data;
  if (!internalProjectType.length) {
    return {
      type: SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE,
    };
  } else {
    return {
      type: SubareaInternalProjectTypeConst.CANCEL_GET_SUBAREAINTERNALPROJECTTYPE,
    };
  }
}

export function getSubareaInternalProjectTypeOk({
  data,
}: {
  data: SubareaInternalProjectType[];
}): GetSubareaInternalProjectTypeOkAction {
  return {
    type: SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE_OK,
    data: data,
  };
}

export function getSubareaInternalProjectTypeKo(error: string): GetSubareaInternalProjectTypeKoAction {
  return {
    type: SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE_KO,
    error,
  };
}

export function cancelSubareaInternalProjectTypeOk(): CancelGetSubareaInternalProjectTypeAction {
  return {
    type: SubareaInternalProjectTypeConst.CANCEL_GET_SUBAREAINTERNALPROJECTTYPE,
  };
}
