import assertUnreachable from '../../helpers/assertUnreachable';
import { NotificationsConsts } from './action_types';
import {
  Alert,
  initialState,
  MessageThread,
  Notification,
  NotificationAction,
  NotificationsState,
  NotificationsUpdatePayload,
  PendingTask,
  PendingTaskItem,
} from './definitions';

const sortByDate = (x: Alert | MessageThread, y: Alert | MessageThread) => {
  if (x.date < y.date) {
    return 1;
  }
  if (x.date > y.date) {
    return -1;
  }
  return 0;
};

const sortAlerts = (alerts: Alert[]) => {
  return alerts.sort((x, y) => {
    if (x.date === y.date) {
      return x.title > y.title ? 1 : -1;
    }
    return sortByDate(x, y);
  });
};

const sortMessageThread = (message_threads: MessageThread[]) => {
  return message_threads.sort((x, y) => {
    if (x.date === y.date) {
      return x.channel > y.channel ? 1 : -1;
    }
    return sortByDate(x, y);
  });
};

export const mergeNotificacions = (state: NotificationsState, updates: NotificationsUpdatePayload): Notification => {
  const notifications: Notification = {
    message_threads: [],
    pending_tasks: [],
    alerts: [],
  };

  // additions
  notifications.message_threads = [...state.notifications.message_threads, ...updates.additions.message_threads];
  notifications.alerts = [...state.notifications.alerts, ...updates.additions.alerts];

  // updates
  const updatable_message_threads = updates.updates.message_threads.map(x => x.id);
  const clean_message_threads = notifications.message_threads.filter(x => !updatable_message_threads.includes(x.id));
  notifications.message_threads = [...clean_message_threads, ...updates.updates.message_threads];

  const updatable_alerts = updates.updates.alerts.map(x => x.id);
  const clean_alerts = notifications.alerts.filter(x => !updatable_alerts.includes(x.id));
  notifications.alerts = [...clean_alerts, ...updates.updates.alerts];

  // deletes
  const removed_alerts = notifications.alerts.filter(x => !updates.deletes.alerts.includes(x.id));
  notifications.alerts = [...removed_alerts];
  const removed_message_threads = notifications.message_threads.filter(
    x => !updates.deletes.message_threads.includes(x.id),
  );

  notifications.message_threads = [...removed_message_threads];

  notifications.pending_tasks = updates.pending_tasks;

  // Sorting

  notifications.alerts = sortAlerts(notifications.alerts);
  notifications.message_threads = sortMessageThread(notifications.message_threads);
  return notifications;
};

const notificationsReducer = (state = initialState, action: NotificationAction): NotificationsState => {
  switch (action.type) {
    case NotificationsConsts.NOTIFICATIONS_UPDATE:
      return {
        ...state,
      };
    case NotificationsConsts.NOTIFICATIONS_UPDATE_OK:
      if (action.updates.version === state.version) {
        return {
          ...state,
          channels: action.updates.channels,
          notifications: {
            ...state.notifications,
            pending_tasks: action.updates.pending_tasks,
          },
          unread_pending_tasks: action.updates.unread_pending_tasks,
        };
      }
      return {
        ...state,
        notifications: mergeNotificacions(state, action.updates),
        version: action.updates.version,
        unread_alerts: action.updates.unread_alerts,
        unread_message_threads: action.updates.unread_message_threads,
        unread_pending_tasks: action.updates.unread_pending_tasks,
        channels: action.updates.channels,
      };
    case NotificationsConsts.NOTIFICATIONS_UPDATE_KO:
      return {
        ...state,
        error: action.error,
      };

    case NotificationsConsts.NOTIFICATIONS_START_POLLING:
      return {
        ...state,
        polling: true,
      };

    case NotificationsConsts.NOTIFICATIONS_STOP_POLLING:
      return {
        ...state,
        polling: false,
      };
    case NotificationsConsts.READ_NOTIFICATION:
      const { notifications } = action;
      switch (notifications.type) {
        case 'alerts':
          const alerts = [...state.notifications.alerts];
          notifications.notifications.forEach(notification => {
            const index = state.notifications.alerts.findIndex(alert => alert.id === notification.id);
            if (index >= 0) {
              alerts[index].read = notification.read ? 1 : 0;
            }
          });

          return {
            ...state,
            notifications: {
              ...state.notifications,
              alerts,
            },
          };
        case 'message_threads':
          let unread_message_threads = state.unread_message_threads;
          notifications.notifications.forEach(notification => {
            unread_message_threads = notification.read ? unread_message_threads - 1 : unread_message_threads + 1;
          });
          return {
            ...state,
            unread_message_threads,
          };
      }
      return {
        ...state,
      };
    case NotificationsConsts.READ_NOTIFICATION_OK:
      return {
        ...state,
      };
    case NotificationsConsts.READ_NOTIFICATION_KO:
      return {
        ...state,
        error: action.error,
      };
    case NotificationsConsts.NEW_MESSAGE:
      return {
        ...state,
        error: initialState.error,
      };
    case NotificationsConsts.NEW_MESSAGE_OK:
      let new_message_threads;
      if (action.isNewThread) {
        return {
          ...state,
          error: initialState.error,
          notifications: {
            ...state.notifications,
            message_threads: [...state.notifications.message_threads, action.message_thread],
          },
        };
      } else {
        new_message_threads = state.notifications.message_threads.map(item =>
          item.id === action.message_thread.id
            ? {
                ...item,
                thread: action.message_thread.thread,
              }
            : item,
        );

        return {
          ...state,
          error: initialState.error,
          notifications: {
            ...state.notifications,
            message_threads: new_message_threads,
          },
        };
      }
    case NotificationsConsts.NEW_MESSAGE_KO:
      return {
        ...state,
        error: action.error,
      };
    case NotificationsConsts.REMOVE_PENDING_TASK:
      return {
        ...state,
        loading: true,
      };
    case NotificationsConsts.REMOVE_PENDING_TASK_KO:
      return {
        ...state,
        loading: false,
      };
    case NotificationsConsts.REMOVE_PENDING_TASK_OK:
      const pending_tasks = state.notifications.pending_tasks
        .map((task: PendingTask) => {
          //eliminamos el item con el notification_id que nos pasan
          task.items = task.items.filter((item: PendingTaskItem) => item.id !== action.notification_id);
          task.count = task.items.length;
          return task;
        })
        .filter((task: PendingTask) => task.items.length > 0);
      return {
        ...state,
        loading: false,
        notifications: {
          ...state.notifications,
          pending_tasks,
        },
      };
  }
  assertUnreachable(action);
  return state;
};

export default notificationsReducer;
