import { Drawer, Fab } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import React, { useState } from 'react';
import MenuRightNotifications from '../Menus/MenuRightNotifications/MenuRightNotifications';
import styles from './fabButtons.module.scss';
import useUserNotificationsUpdate from '../../Notifications/useUserNotificationsUpdate';

const FabButtonNotification: React.FC = () => {
  const [state, setState] = useState(false);

  const { unread_pending_tasks, unread_alerts, unread_message_threads } = useUserNotificationsUpdate();

  return (
    <React.Fragment>
      <Fab color="primary" className={`${styles.top} ${styles.right}`} onClick={() => setState(!state)}>
        <Badge badgeContent={unread_pending_tasks + unread_alerts + unread_message_threads}>
          <NotificationImportantIcon />
        </Badge>
      </Fab>
      <Drawer key="notifications" open={state} onClose={() => setState(!state)} anchor="right">
        <MenuRightNotifications
          unread_pending_tasks={unread_pending_tasks}
          unread_alerts={unread_alerts}
          unread_message_threads={unread_message_threads}
          onClose={() => () => {
            setState(!state);
          }}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default React.memo(FabButtonNotification);
