import React, { useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Loading from '../../../../../components/Loading/Loading';
import { AppState } from '../../../../../redux/root-reducer';
import Title from '../../../../../components/Title/Title';
import FormContainer from '../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../../components/Select/SelectController';
import CheckboxCompo from '../../../../../components/Checkbox/CheckboxCompo';
import { tFormKey, tKey, tProjectKey } from '../../../../../helpers/translate';
import { sixMonthsFollowsSchema } from '../../../../../validations/evaluationFollowUpSchema';
import {
  FollowUpSixMonthsForm,
  FollowUpSixMonthsFormMetadata,
} from '../../../../../redux/internal-projects/definitions';
import { getFollowUpSixMonthsForm, updateFollowUpSixMonthsForm } from '../../../../../redux/internal-projects/actions';
import styles from './SixMonthsFollows.module.scss';
import InformationMinimal from '../Information/InformationMinimal';

interface SixMonthsFollowsProps {
  loading: boolean;
  form_id: number;
  view: boolean;
  form_data: FollowUpSixMonthsForm;
  handleClose: () => void;
  getSixMonthsFormData: (form_id: number) => void;
  updateSixMonthsFormData: (form_id: number, data: FollowUpSixMonthsForm) => void;
}

const SixMonthsFollows: React.FC<SixMonthsFollowsProps> = ({
  loading,
  form_id,
  view,
  form_data,
  handleClose,
  getSixMonthsFormData,
  updateSixMonthsFormData,
}) => {
  const { control, handleSubmit, register, errors } = useForm<FollowUpSixMonthsForm>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: sixMonthsFollowsSchema,
  });

  useEffect(() => {
    getSixMonthsFormData(form_id);
  }, [form_id, getSixMonthsFormData]);

  if (loading) {
    return <Loading></Loading>;
  }

  const handleSaveForm = (sixMonthsFormData: FollowUpSixMonthsForm) => {
    updateSixMonthsFormData(form_id, sixMonthsFormData);
    handleClose();
  };

  const handleSendForm = (sixMonthsFormData: FollowUpSixMonthsForm) => {
    updateSixMonthsFormData(form_id, { ...sixMonthsFormData, follow_up_six_months_done: true });
  };

  const {
    name,
    surname,
    surname2,
    enrolled_plan_id,
    enrolled_plans,
    has_gotten_zing_grant,
    training_brings_closer_to_vocation,
    training_type_id,
    training_types,
    training,
    is_vocational_job,
    job,
    reason_no_study_or_job_id,
    reasons_no_study_or_job,
    another_reason_no_study_or_job,
  } = form_data;

  return (
    <Dialog
      open={view}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{tFormKey('ip.evaluacion_seis_meses')}</DialogTitle>
        <IconButton aria-label="delete" size="small" onClick={() => handleClose()}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        <form onSubmit={e => e.preventDefault()} className={styles.form}>
          <Title>{tFormKey('ip.seguimientos_entidad_seis_meses')}</Title>
          <InformationMinimal />
          <FormContainer title={tFormKey('ip.datos_del_participante')}>
            <div className={styles.form}>
              <TextInputController
                label={tKey('Nombre')}
                name="name"
                defaultValue={name || ''}
                size="50"
                disabled
                schema={sixMonthsFollowsSchema}
                control={control}
                errors={errors}
              />
            </div>
            <TextInputController
              label={tFormKey('ip.primer_apellido')}
              name="surname"
              defaultValue={surname}
              size="50"
              disabled
              schema={sixMonthsFollowsSchema}
              control={control}
              errors={errors}
            />
            <TextInputController
              label={tFormKey('ip.segundo_apellido')}
              name="surname2"
              defaultValue={surname2 || ''}
              size="50"
              disabled
              schema={sixMonthsFollowsSchema}
              control={control}
              errors={errors}
            />
          </FormContainer>
          <FormContainer title={tFormKey('ip.progreso_del_joven')}>
            <SelectController
              key="enrolled_plan_id"
              label={tFormKey('ip.estado_joven_a_dia_de_hoy')}
              name="enrolled_plan_id"
              defaultValue={enrolled_plan_id || null}
              size="50"
              schema={sixMonthsFollowsSchema}
              control={control}
              errors={errors}
            >
              {enrolled_plans?.map((element: FollowUpSixMonthsFormMetadata) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.value}
                </MenuItem>
              ))}
            </SelectController>
            <div className={styles.checkboxSection}>
              <p className={styles.checkboxSectionText}>{tFormKey('ip.tiene_beca_zing')}</p>
              <CheckboxCompo
                name="has_gotten_zing_grant"
                questionText=""
                register={register}
                defaultValue={+has_gotten_zing_grant}
              />
            </div>
            <div className={styles.checkboxSection}>
              <p className={styles.checkboxSectionText}>{tFormKey('ip.formacion_acerca_a_su_vocacion')}</p>
              <CheckboxCompo
                name="training_brings_closer_to_vocation"
                questionText=""
                register={register}
                defaultValue={+training_brings_closer_to_vocation}
              />
            </div>
            <SelectController
              key="training_type_id"
              label={tFormKey('ip.nivel_de_estudio')}
              name="training_type_id"
              defaultValue={training_type_id || null}
              size="50"
              schema={sixMonthsFollowsSchema}
              control={control}
              errors={errors}
            >
              {training_types?.map((element: FollowUpSixMonthsFormMetadata) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.value}
                </MenuItem>
              ))}
            </SelectController>
            <div className={styles.form}>
              <TextInputController
                label={tFormKey('ip.indicar_estudios')}
                name="training"
                defaultValue={training || ''}
                size="100"
                schema={sixMonthsFollowsSchema}
                control={control}
                errors={errors}
              />
            </div>
            <div className={styles.checkboxSection}>
              <p className={styles.checkboxSectionText}>{tFormKey('ip.es_trabajo_vocacional')}</p>
              <CheckboxCompo
                name="is_vocational_job"
                questionText=""
                register={register}
                defaultValue={+is_vocational_job}
              />
            </div>
            <div className={styles.form}>
              <TextInputController
                label={tFormKey('ip.que_trabajo_realiza')}
                name="job"
                defaultValue={job || ''}
                size="100"
                schema={sixMonthsFollowsSchema}
                control={control}
                errors={errors}
              />
            </div>
            <SelectController
              key="reason_no_study_or_job_id"
              label={tFormKey('ip.motivo_de_no_trabajar_ni_estudiar')}
              name="reason_no_study_or_job_id"
              defaultValue={reason_no_study_or_job_id || null}
              size="50"
              schema={sixMonthsFollowsSchema}
              control={control}
              errors={errors}
            >
              {reasons_no_study_or_job?.map((element: FollowUpSixMonthsFormMetadata) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.value}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              label={tFormKey('ip.otros_motivos_de_no_trabajar_ni_estudiar')}
              name="another_reason_no_study_or_job"
              defaultValue={another_reason_no_study_or_job || ''}
              size="50"
              schema={sixMonthsFollowsSchema}
              control={control}
              errors={errors}
            />
          </FormContainer>
          <div className={styles.btn_create}>
            <Button color="primary" variant="outlined" onClick={handleSubmit(handleSaveForm)}>
              {tProjectKey('Guardar')}
            </Button>
            {!form_data.follow_up_six_months_done && (
              <Button color="primary" variant="outlined" onClick={handleSubmit(handleSendForm)}>
                {tFormKey('ip.enviar')}
              </Button>
            )}
            <Button color="primary" variant="outlined" onClick={handleClose}>
              {tProjectKey('Cancelar')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectReducer.loadingAlt,
  form_data: state.internalProjectReducer.followUpSixMonthsData,
});

const mapDispatchToProps = (dispatch: any) => ({
  getSixMonthsFormData: (form_id: number) => dispatch(getFollowUpSixMonthsForm(form_id)),
  updateSixMonthsFormData: (form_id: number, data: FollowUpSixMonthsForm) =>
    dispatch(updateFollowUpSixMonthsForm(form_id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SixMonthsFollows);
