import { DatesData } from '../../redux/calendar/definitions';
import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

export const getAllApiDates = async (data: number): Promise<DatesData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ date: data }),
  };

  return await apiCall<DatesData>(`${baseURL}/interview/calendar`, init);
};

export const setTimesToNotAvailableApi = async (data: number): Promise<{ data: string }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ slot: data }),
  };

  return await apiCall<{ data: string }>(`${baseURL}/interview/calendar/set_state_not_available`, init);
};

export const setTimesToFreeApi = async (data: number, channel: string): Promise<{ data: string }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ slot: data, channel }),
  };

  return await apiCall<{ data: string }>(`${baseURL}/interview/calendar/set_state_free`, init);
};
