/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, MenuItem, Select } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as DateConstants from '../../../constants/date';
import { DATE_FORMAT } from '../../../constants/date';
import { formatNumber } from '../../../helpers/formatNumber';
import { tKey } from '../../../helpers/translate';
import CreateEditFollowUp from '../../../routes/Project/FollowUp/CreateEditFollowUp';
import TransitionModal from '../../Modal/TransitionModal';
import styles from './customTable.module.scss';
import cellStyles from './customTableCell.module.scss';
import { ColumnTableProps } from './TableEditableRow';

const BORDER_RIGT_END = '3px solid #6666661a';
const EMPTY_PROPERTY = 'No hay datos';

const useStyles = makeStyles(theme => ({
  fixed: {
    top: 'auto',
    width: '5em',
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'white',
  },
}));
interface CustomTableCellProps {
  row: any;
  name: string;
  modal: any;
  onModal: (modal: any) => void;
  onChange: (e: any, row: any, cb?: any) => void;
  onChangeDate: (value: any, row: any, name: any, cb?: any) => void;
  onChangeSelect?: (select: any, row: any, name: any) => void;
  onClick: () => void;
  index: number;
  editable: any;
  type: 'date' | 'price' | 'Adjunto' | 'Numerico' | 'Fecha' | 'Lista_de_Valores' | 'Bloque_de_texto' | 'Link';
  color: any;
  cb: any;
  fixed: any;
  disabled: boolean;
  column: ColumnTableProps;
  projectID?: number;
  entireTable?: any;
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({
  row,
  name,
  onChange,
  onClick,
  index,
  editable,
  modal,
  onModal,
  type,
  onChangeDate,
  onChangeSelect,
  color,
  cb,
  fixed,
  column,
  disabled = false,
  projectID,
  entireTable,
}) => {
  const [date, setDate] = useState<any>();
  const [select, setSelect] = useState();
  const [, setOpen] = React.useState(false);

  const classes = useStyles();

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const { isEditMode } = row;
  const handleModal = () => {
    const _modal = modal.map((element: any) => ({
      title: element.title,
      value: row[element.name],
      size: element.size,
      type: element.type,
      color: element.color,
      row: row,
    }));
    onModal(_modal);
  };
  const [uploadModal, setUploadModal] = React.useState({
    view: false,
    children: <CreateEditFollowUp />,
    title: tKey('Nueva Nota de Seguimiento'),
  });
  const handelChangeDate = (e: any) => {
    setDate(moment(e).format(DateConstants.DATE_FORMAT));
  };

  const handleChangeSelect = (e: any, row: any, name: any) => {
    setSelect(e);
  };

  useEffect(() => {
    if (select && onChangeSelect) {
      onChangeSelect(select, row, name);
    }
  }, [select]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (date) {
      onChangeDate(date, row, name, cb);
    }
  }, [date]); // eslint-disable-line react-hooks/exhaustive-deps

  const showInputs = (value: string | number | undefined | null, tooltip?: boolean) => {
    if (value?.toString().trim()) {
      if (type === 'date' || type === 'Fecha') {
        return row[name];
      } else if (type === 'Numerico' || type === 'price') {
        const num_value = formatNumber({ value: parseFloat(row[name]) });
        if (type === 'price' && num_value) {
          return num_value.concat(' €');
        }
        return num_value;
      }
      return value;
    }
    if (tooltip) return '';
    return EMPTY_PROPERTY;
  };

  const handleCloseModal = () => {
    setUploadModal({ view: false, children: <div></div>, title: '' });
  };

  const StyledBadge = withStyles(theme => ({
    badge: {
      right: '-7px',
      border: `2px solid white`,
      padding: '0 4px',
      fontSize: '12px',
      top: '60%',
      transform: 'translateY(-50%)',
    },
  }))(Badge);

  return (
    <TableCell
      align={column.align || 'left'}
      className={index <= fixed?.num ? classes.fixed : cellStyles.tableCell}
      onClick={modal ? handleModal : onClick}
      style={{
        color: color ? color(row) : undefined,
        left: index <= fixed?.num ? fixed?.left * index : undefined,
        borderRight: index === fixed?.num ? BORDER_RIGT_END : undefined,
        padding: '16px 9px',
      }}
    >
      {isEditMode && editable !== 'never' && !disabled ? (
        <>
          {type !== 'date' &&
            type !== 'Fecha' &&
            type !== 'Lista_de_Valores' &&
            type !== 'Adjunto' &&
            type !== 'Bloque_de_texto' &&
            type !== 'price' &&
            type !== 'Numerico' && (
              <Input
                value={row[name]}
                name={name}
                autoFocus={index === 0}
                onChange={e => onChange(e, row, cb)}
                className={styles.input}
              />
            )}
          {(type === 'Numerico' || type === 'price') && (
            <Input
              className={cellStyles.cell_format_number_input}
              value={row[name]}
              autoFocus={index === 0}
              name={name}
              type="number"
              onChange={e => onChange(e, row, cb)}
              placeholder="0"
            />
          )}
          {type === 'Adjunto' && (
            <>
              <IconButton aria-label="cart">
                <StyledBadge badgeContent={row[name].length} color="primary">
                  <DescriptionIcon
                    style={{ color: '#666666' }}
                    onClick={() => {
                      setUploadModal(prevState => ({
                        ...prevState,
                        view: true,
                        title: tKey('Archivos Adjuntos'),
                        children: (
                          <CreateEditFollowUp
                            fieldName={column.name}
                            idRow={row.id}
                            isTableMode
                            projectID={projectID}
                            rowTable={row}
                            handleCloseModal={handleCloseModal}
                            tableData={entireTable}
                          />
                        ),
                      }));
                    }}
                  />
                </StyledBadge>
              </IconButton>
            </>
          )}
          {(type === 'date' || type === 'Fecha') && (
            <DatePicker
              value={row[name] ? +moment(row[name], DateConstants.DATE_FORMAT).format('X') * 1000 : date || null}
              defaultValue={
                row[name] !== ''
                  ? +moment.utc(row[name], DateConstants.DATE_FORMAT).format('X') * 1000
                  : new Date(Date.now())
              }
              onChange={handelChangeDate}
              autoFocus={index === 0}
              /* disableToolbar */
              autoOk
              variant="inline"
              className={styles.date}
              format={DATE_FORMAT}
            />
          )}
          {type === 'Lista_de_Valores' && (
            // @ts-ignore: Unreachable code error
            <Select
              label=""
              defaultValue={row[name]}
              name={row[name]}
              onChange={(e: any, column) => {
                handleChangeSelect(e, row, name);
              }}
              className={styles.selectAssesment}
            >
              {column.options.map((element: any) => (
                <MenuItem key={element.id} value={element.id} data-name={element.name}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          )}
          {type === 'Bloque_de_texto' && (
            // @ts-ignore: Unreachable code error
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={styles.container_textarea}>
                <div onClick={handleClick}>{row[name] || '...'}</div>
                <Input
                  value={row[name]}
                  name={name}
                  autoFocus={index === 0}
                  onChange={e => onChange(e, row, cb)}
                  className={styles.textarea}
                  multiline
                  rows={3}
                />
              </div>
            </ClickAwayListener>
          )}
        </>
      ) : (
        <>
          {type !== 'date' &&
            type !== 'Lista_de_Valores' &&
            type !== 'Fecha' &&
            type !== 'Adjunto' &&
            type !== 'Numerico' && (
              // @ts-ignore: Unreachable code error
              <Tooltip title={showInputs(row[name], true)} disableHoverListener={!row[name]}>
                <div className={modal ? cellStyles.cell_format_modal : cellStyles.cell_format}>
                  {type === 'Link' ? (
                    <Link href={`https://${showInputs(row[name])}`} target="_blank" underline="hover">
                      {showInputs(row[name])}
                    </Link>
                  ) : (
                    showInputs(row[name])
                  )}
                </div>
              </Tooltip>
            )}
          {type === 'Numerico' && (
            // @ts-ignore: Unreachable code error
            <Tooltip title={showInputs(row[name], true)} disableHoverListener={!row[name]}>
              <div className={modal ? cellStyles.cell_format_modal : cellStyles.cell_format_number}>
                {showInputs(row[name])}
              </div>
            </Tooltip>
          )}
          {type === 'Adjunto' && (
            <>
              <IconButton aria-label="cart">
                <StyledBadge badgeContent={row[name].length} color="primary">
                  <DescriptionIcon
                    style={{ color: '#666666' }}
                    onClick={() => {
                      setUploadModal(prevState => ({
                        ...prevState,
                        view: true,
                        title: tKey('Archivos Adjuntos'),
                        children: (
                          <CreateEditFollowUp
                            fieldName={column.name}
                            idRow={row.id}
                            isTableMode
                            projectID={projectID}
                            rowTable={row}
                            handleCloseModal={handleCloseModal}
                            tableData={entireTable}
                          />
                        ),
                      }));
                    }}
                  />
                </StyledBadge>
              </IconButton>
            </>
          )}
          {(type === 'date' || type === 'Fecha') && (
            // @ts-ignore: Unreachable code error
            <Tooltip title={showInputs(row[name], true)} disableHoverListener={!row[name]}>
              <div className={modal ? cellStyles.cell_format_modal : cellStyles.cell_format}>
                {showInputs(row[name])}
              </div>
            </Tooltip>
          )}
          {type === 'Lista_de_Valores' && (
            //@ts-ignore: Unreachable code error
            <Select
              label=""
              defaultValue={row[name]}
              name={row[name]}
              disabled={true}
              disableUnderline={true}
              onChange={(e: any) => {
                handleChangeSelect(e, row, name);
              }}
            >
              {column.options.map((element: any) => (
                <MenuItem key={element.id} value={element.id} data-name={element.name}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </>
      )}
      <TransitionModal
        view={uploadModal.view}
        handleClose={() => setUploadModal({ view: false, children: <div></div>, title: '' })}
        title={uploadModal.title}
        bodyModal=" "
      >
        {uploadModal.children}
      </TransitionModal>
    </TableCell>
  );
};

export default CustomTableCell;
