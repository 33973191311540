import { ButtonProps } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { confirmConfiguratorApi } from '../../../api/Projects/projects';
import DragAndDropCustomFields, {
  DragAndDropCustomFieldsRef,
} from '../../../components/DragAndDrop/DragAndDropCustomFields/DragAndDropCustomFields';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Modal from '../../../components/Modal/Modal';
import { tKey, tProjectKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { CodeSectionProject, FormAssistantSections } from '../../../redux/kpis/definitions';
import { confirmConfiguratorOk, getOneProject } from '../../../redux/project/action';
import { ProjectData, ProjectDispatch } from '../../../redux/project/definitions';
import { selectLoading, selectProjectData } from '../../../redux/project/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuFormsAssistant from './SubmenuFormsAssistant';

interface FormsAssistantProps extends RouteComponentProps<{ id_project: string; section: CodeSectionProject }> {
  project: ProjectData;
  getOneProject: (id: string) => void;
  confirmation: () => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const FormsAssistant: React.FC<FormsAssistantProps> = ({
  match,
  project,
  getOneProject,
  confirmation,
  showSnackbar,
}) => {
  const {
    params: { id_project, section },
  } = match;
  const dragAndDropRef = useRef<DragAndDropCustomFieldsRef>(null);
  const currentSectionIndex = FormAssistantSections.findIndex(sectionF => sectionF.code === section);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  useEffect(() => {
    getOneProject(id_project);
  }, [id_project, getOneProject]);

  const handleSave = () => {
    dragAndDropRef.current?.saveData();
  };

  const handleConfirm = () => {
    dragAndDropRef.current?.saveData();
    confirmConfiguratorApi(Number(id_project)).then(() => {
      confirmation();
      setOpenConfirmModal(false);
    });
  };

  const buttons: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      fullWidth: true,
      disableElevation: true,
      onClick: () => {
        handleSave();
        showSnackbar(tKey('La confirmación se ha realizado correctamente'), 'success', undefined, 1500);
      },
      color: 'primary',
      variant: 'contained',
    },
    {
      children: tProjectKey('Confirmar'),
      fullWidth: true,
      disableElevation: true,
      onClick: () => setOpenConfirmModal(true),
      color: 'primary',
      variant: 'contained',
      hidden: currentSectionIndex !== FormAssistantSections.length - 1,
    },
  ];

  const modalButtons: ButtonProps[] = [
    {
      children: tProjectKey('Cancelar'),
      disableElevation: true,
      onClick: () => setOpenConfirmModal(false),
      color: 'secondary',
      variant: 'contained',
    },
    {
      children: tProjectKey('Confirmar'),
      disableElevation: true,
      onClick: handleConfirm,
      color: 'primary',
      variant: 'contained',
    },
  ];

  return project.forms_done === 1 ? (
    <Redirect to={`/proyecto/detalle/${project.id}/datos_basicos`} />
  ) : (
    <LayoutForm
      leftSubmenu={
        <SubmenuFormsAssistant id_project={id_project} current_section={section} onClickOption={handleSave} />
      }
      rightSubmenu={<ActionsMenu actionsButtons={buttons} />}
    >
      <DragAndDropCustomFields ref={dragAndDropRef} />
      <Modal
        title={tProjectKey('Confirmación')}
        onClose={() => setOpenConfirmModal(false)}
        open={openConfirmModal}
        buttons={modalButtons}
      >
        <div>{tProjectKey('TextoFinalForm')}</div>
      </Modal>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  project: selectProjectData(state),
});

const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  getOneProject: (id: string): void => dispatch(getOneProject(id)),
  confirmation: (): void => dispatch(confirmConfiguratorOk()),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormsAssistant);
