import { put, takeLatest } from '@redux-saga/core/effects';
import { ParticipantsResponse, getParticipants } from '../../../../api/InternalProject/Participants/getParticipants';
import { Await } from '../../../../api/api';
import errorMessage from '../../../../helpers/errorMessage';
import { getParticipantsKo, getParticipantsOk } from './action';
import { GetParticipantsConst } from './action_types';
import { GetParticipantsAction } from './definitions.d';

function* getParticipantsFromSaga(action: GetParticipantsAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const ParticipantData = (yield getParticipants(id)) as Await<ParticipantsResponse>;
    yield put(getParticipantsOk(ParticipantData.data));
  } catch (e) {
    yield put(getParticipantsKo(errorMessage(e)));
  }
}

export default [takeLatest<GetParticipantsAction>(GetParticipantsConst.GET_PARTICIPANTS, getParticipantsFromSaga)];
