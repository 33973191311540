/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { CalendarToday, Payment } from '@material-ui/icons';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  getScholarshipYears,
  paySage as paySageApi,
  retrievePayment,
  setScholarshipPaymentByYears,
} from '../../../api/Entity/entity';
import Layout from '../../../components/Layout/Layout';
import MessageInfo from '../../../components/MessageInfo/MessageInfo';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableEditableRow, { ColumnTableProps } from '../../../components/Tables/TableEditableRow/TableEditableRow';
import Title from '../../../components/Title/Title';
import { debounce } from '../../../helpers/debounce';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { PaymentInfo } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import { getScholarshipsToPayEntity, setPaymentsAcademicYear } from '../../../redux/scholarship/actions';
import {
  EntityScholarshipPaymentError,
  EntityScholarshipPaymentItem,
  EntityScholarshipPayments,
} from '../../../redux/scholarship/definitions';
import { selectEntityScholarships, selectPaymentAcademicYear } from '../../../redux/scholarship/selectors';
import EntityMenuRight from '../../Entities/EntityMenuRight/EntityMenuRight';
import PaymentModal from '../../Project/Modal/PaymentModal/PaymentModal';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './scholarshipsEntityPayment.module.scss';

interface ScholarshipsEntityPaymentProps extends RouteComponentProps<{ id: string }> {
  entityScholarships: EntityScholarshipPayments;
  disabled?: boolean;
  allowPaymentAdjustment: boolean;
  selectedAcademicYear: string;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  getEntityScholarships: (id: number, academic_year: string) => void;
  setSelectedAcademicYear: (academic_year: string) => void;
}

export const createData = (data: EntityScholarshipPaymentItem[]) =>
  data.map((element: any, index: number) => {
    return {
      ...element,
      id: index,
      isEditMode: false,
    };
  });

const ScholarshipsEntityPayment: React.FC<ScholarshipsEntityPaymentProps> = ({
  entityScholarships,
  disabled,
  allowPaymentAdjustment,
  selectedAcademicYear,
  showSnackbar,
  getEntityScholarships,
  setSelectedAcademicYear,
  match,
}) => {
  const [payModal, setPayModal] = useState({ view: false, modal: {} as PaymentInfo });
  const [errorsModal, setErrorsModal] = useState<{ view: boolean; errors: EntityScholarshipPaymentError[] }>({
    view: false,
    errors: [],
  });
  const [dataToShow, setDataToShow] = useState<any[]>([]);
  const [academicYears, setAcademicYears] = useState<string[]>([]);
  const { payments, payment_available } = entityScholarships;
  const {
    params: { id },
  } = match;

  useEffect(() => {
    if (selectedAcademicYear) {
      getEntityScholarships(parseInt(id), selectedAcademicYear);
    }
  }, [selectedAcademicYear]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (payments) {
      setDataToShow(createData(payments));
    }
  }, [payments]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getScholarshipYears(+id)
      .then(setAcademicYears)
      .catch(console.log);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const controlPrice = useCallback(
    debounce(({ value, name, row }: any) => {
      if (!value.length) return;
      if (!+value) {
        showSnackbar(tErrorKey('Este es un campo numérico.'), 'error', undefined, 2500);
        return;
      }
      let total: string | number = 0;
      if (name === 'first_payment_amount') total = +value + +row.second_payment_amount + +row.third_payment_amount;
      if (name === 'second_payment_amount') total = +row.first_payment_amount + +value + +row.third_payment_amount;
      if (name === 'third_payment_amount') total = +row.first_payment_amount + +row.second_payment_amount + +value;
      if (name === 'fourth_payment_amount')
        total = +row.first_payment_amount + +row.second_payment_amount + +row.third_payment_amount + +value;
      total = total.toFixed(2);

      if (+total > row.total_price) {
        showSnackbar(tErrorKey('La suma total de los pagos es superior al importe.'), 'error', undefined, 1500);
      }
      if (+total < row.total_price) {
        showSnackbar(tErrorKey('La suma total de los pagos es inferior al importe.'), 'error', undefined, 1500);
      }
    }, 800),
    [],
  );

  // const dateToUnix = (date: any) => +moment.utc(date, DateConstants.DATE_FORMAT).format('X');

  // const controlDate = ({ date, name, row }: any) => {
  //   let control = false;
  //   if (name === 'first_payment_date') control = date > dateToUnix(row.second_payment_date);
  //   if (name === 'second_payment_date')
  //     control = date > dateToUnix(row.third_payment_date) || date < dateToUnix(row.first_payment_date);
  //   if (name === 'third_payment_date') control = date < dateToUnix(row.second_payment_date);
  //   if (name === 'third_payment_date' && row.fourth_payment_date)
  //     control = date > dateToUnix(row.fourth_payment_date) || date < dateToUnix(row.second_payment_date);
  //   if (name === 'fourth_payment_date') control = date < dateToUnix(row.third_payment_date);
  //   if (!control) return;
  //   showSnackbar(tErrorKey('FechaPagoSuperior'), 'error', undefined, 2500);
  // };

  const controlDate = ({ value, name, row }: any) => {
    let control = false;
    if (name === 'first_payment_date') control = value > row.second_payment_date;
    if (name === 'second_payment_date') control = value > row.third_payment_date || value < row.first_payment_date;
    if (name === 'third_payment_date') control = value > row.fourth_payment_date || value < row.second_payment_date;
    if (name === 'fourth_payment_date') control = value < row.third_payment_date;
    if (!control) return;
    showSnackbar(tErrorKey('FechaPagoSuperior'), 'error', undefined, 2500);
  };

  const paymentModal = async () => {
    try {
      const { data } = await retrievePayment({
        id: parseInt(id),
        academic_year: selectedAcademicYear,
      });
      setPayModal({ view: true, modal: data });
    } catch (error) {
      const e = error as ErrorEvent;
      showSnackbar(e.message, 'error', undefined, 1500);
    }
  };

  const paySage = async (dataForm: PaymentInfo) => {
    try {
      const data = {
        id: parseInt(id),
        account_number: dataForm.account_number,
        academic_year: selectedAcademicYear,
        payment_date: dataForm.payment_date,
      };
      const {
        data: { payments },
      } = await paySageApi(data);
      setDataToShow(createData(payments));
      showSnackbar(tKey('Pago realizado'), 'success', undefined, 1500);
    } catch (error) {
      const e = error as ErrorEvent;
      showSnackbar(e.message, 'error', undefined, 1500);
    }
  };

  const upgradeCalendar = async () => {
    try {
      const {
        data: { success, errors },
      } = await setScholarshipPaymentByYears(parseInt(id), dataToShow, selectedAcademicYear);
      setDataToShow(createData(success));
      if (errors.length) {
        setErrorsModal({ view: true, errors });
        return;
      }
      showSnackbar(tKey('Calendario actualizado'), 'success', undefined, 1500);
    } catch (error) {
      const e = error as ErrorEvent;
      showSnackbar(e.message, 'error', undefined, 1500);
    }
  };

  const button: ButtonProps[] = [
    {
      children: tKey('PAGAR'),
      onClick: paymentModal,
      startIcon: <Payment />,
      fullWidth: true,
      variant: 'contained',
      color: 'primary',
      disabled: disabled || payment_available !== null,
    },
    {
      children: tKey('Actualizar Calendario'),
      onClick: upgradeCalendar,
      startIcon: <CalendarToday />,
      fullWidth: true,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      disabled: disabled,
    },
  ];

  const colorSelect = (can_pay: boolean, entity_scholarship_payments: number | null) => {
    const esp = entity_scholarship_payments ? entity_scholarship_payments.toString() : '';
    if (esp === '-1') {
      return 'orange';
    } else if (!can_pay) {
      return '#00000074';
    }
  };

  const colorValueSelect = (
    value: string | number | null,
    can_pay: boolean,
    entity_scholarship_payments: number | null,
  ) => {
    const green = 'green';
    const esp = entity_scholarship_payments ? entity_scholarship_payments.toString() : '';
    if (!!value) {
      if (esp === '-1') {
        return 'orange';
      } else if (!can_pay) {
        return '#00800074';
      }
      return green;
    }
    return colorSelect(can_pay, entity_scholarship_payments);
  };

  const disabledSelect = (value: string | number | null, can_pay: boolean) => {
    if (disabled) return disabled;

    const notSigned = !can_pay;
    if (notSigned) return notSigned;
    return !!value;
  };

  const columns = [
    {
      title: tKey('Nombre'),
      align: 'left',
      name: 'name',
      editable: 'never',
      color: (dataToShow: { can_pay: boolean; entity_scholarship_payments: number | null }) =>
        colorSelect(dataToShow.can_pay, dataToShow.entity_scholarship_payments),
    },
    {
      title: tKey('Importe'),
      name: 'total_price',
      editable: 'never',
      modal: [
        { title: tKey('Valor de matrícula'), name: 'enrollment_confirm', type: 'price' },
        { title: tKey('Valor total mensualidades'), name: 'monthly_price_confirm', type: 'price' },
        { title: tKey('Valor total material'), name: 'materials_confirm', type: 'price' },
        { title: tKey('Transporte'), name: 'transport_price_confirm', type: 'price' },
        { title: tKey('Otros'), name: 'other_costs_confirm', type: 'price' },
        { title: tKey('Ajustes de pago'), name: 'adjustment_amount', type: 'price' },
        { title: tKey('Aportación del/la joven'), name: 'young_contrib_confirm', type: 'price' },
        { title: tKey('Aportación beca MEC'), name: 'mec_confirm', type: 'price' },
        { title: tKey('Familia Profesional'), name: 'professional_group_name', size: 'large' },
      ],

      type: 'price',
      color: (dataToShow: { can_pay: boolean; entity_scholarship_payments: number | null }) =>
        colorSelect(dataToShow.can_pay, dataToShow.entity_scholarship_payments),
    },
    {
      title: tKey('Fecha 1'),
      name: 'first_payment_date',
      type: 'Fecha',
      cb: controlDate,
      color: (dataToShow: {
        first_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.first_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { first_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.first_payment_done_date, dataToShow.can_pay),
    },
    {
      title: tKey('Pago 1'),
      name: 'first_payment_amount',
      type: 'price',
      cb: controlPrice,
      color: (dataToShow: {
        first_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.first_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { first_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.first_payment_done_date, dataToShow.can_pay),
    },
    {
      title: tKey('Fecha 2'),
      name: 'second_payment_date',
      type: 'Fecha',
      cb: controlDate,
      color: (dataToShow: {
        second_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.second_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { second_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.second_payment_done_date, dataToShow.can_pay),
    },
    {
      title: tKey('Pago 2'),
      type: 'price',
      name: 'second_payment_amount',
      cb: controlPrice,
      color: (dataToShow: {
        second_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.second_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { second_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.second_payment_done_date, dataToShow.can_pay),
    },
    {
      title: tKey('Fecha 3'),
      name: 'third_payment_date',
      type: 'Fecha',
      cb: controlDate,
      color: (dataToShow: {
        third_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.third_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { third_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.third_payment_done_date, dataToShow.can_pay),
    },
    {
      title: tKey('Pago 3'),
      type: 'price',
      name: 'third_payment_amount',
      cb: controlPrice,
      color: (dataToShow: {
        third_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.third_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { third_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.third_payment_done_date, dataToShow.can_pay),
    },
    {
      title: tKey('Fecha 4'),
      name: 'fourth_payment_date',
      cb: controlDate,
      type: 'Fecha',
      color: (dataToShow: {
        fourth_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.fourth_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { fourth_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.fourth_payment_done_date, dataToShow.can_pay),
    },
    {
      title: tKey('Pago 4'),
      type: 'price',
      name: 'fourth_payment_amount',
      cb: controlPrice,
      color: (dataToShow: {
        fourth_payment_done_date: string | number | null;
        can_pay: boolean;
        entity_scholarship_payments: number | null;
      }) =>
        colorValueSelect(
          dataToShow.fourth_payment_done_date,
          dataToShow.can_pay,
          dataToShow.entity_scholarship_payments,
        ),
      disabled: (dataToShow: { fourth_payment_done_date: string | number | null; can_pay: boolean }) =>
        disabledSelect(dataToShow.fourth_payment_done_date, dataToShow.can_pay),
    },
  ] as ColumnTableProps[];

  const options = {
    filter: true,
    fixedColumn: {
      num: 1,
      left: 110,
    },
  };

  const handleAdjustmentsClose = () => {
    getEntityScholarships(parseInt(id), selectedAcademicYear);
  };

  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="entity" />}
      rightSubmenu={<EntityMenuRight actionButtons={button} />}
    >
      <Fragment>
        <div className={styles.academic_year_select}>
          <Title>{entityScholarships.entity_name}</Title>
          <FormControl className={styles.selector} variant="outlined">
            <InputLabel id="academic_course">{tFormKey('Curso académico')}</InputLabel>
            <Select
              label={tFormKey('Curso académico')}
              onChange={e => setSelectedAcademicYear(e.target.value as string)}
              value={selectedAcademicYear}
              variant="outlined"
            >
              {academicYears.map(element => (
                <MenuItem key={element} value={element}>
                  {element}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {payment_available && <MessageInfo>{payment_available}</MessageInfo>}
        </div>
        <div className={styles.form_payments}>
          <TableEditableRow
            columns={columns}
            rows={dataToShow}
            setRows={setDataToShow}
            title={'Lista de alumnos'}
            option={options}
            exportExcel
            handleOpenModal={allowPaymentAdjustment}
            handleClose={handleAdjustmentsClose}
          />
        </div>
        <TransitionModal
          view={errorsModal.view}
          handleClose={() => setErrorsModal(prevError => ({ ...prevError, view: false }))}
          buttonOk={tKey('Vale')}
          title={tKey('Revisar los datos de las becas')}
          bodyModal={
            <>
              {errorsModal.errors.map((error: any, index: number) => (
                <div key={index} className={styles.errorModal}>
                  {tErrorKey(`- En el alumno ${error.name} hay el siguiente error: ${error.message}`)}
                </div>
              ))}
            </>
          }
          handleYes={() => setErrorsModal(prevError => ({ ...prevError, view: false }))}
        />
        <PaymentModal
          view={payModal.view}
          onClose={() => setPayModal(prevModal => ({ ...prevModal, view: false }))}
          onSubmit={paySage}
          inputsData={payModal.modal}
        />
      </Fragment>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  entityScholarships: selectEntityScholarships(state),
  allowPaymentAdjustment: selectUserCan(state)('allow_payment_adjustment'),
  selectedAcademicYear: selectPaymentAcademicYear(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedAcademicYear: (academic_year: string): void => dispatch(setPaymentsAcademicYear(academic_year)),
  getEntityScholarships: (id: number, academic_year: string): void =>
    dispatch(getScholarshipsToPayEntity(id, academic_year)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipsEntityPayment);
