import { AddParticipantConst } from './action_types';
import { AddParticipantAction, AddParticipantActionKo, AddParticipantActionOk } from './definitions';
import { Participant } from '../Participant';

export function addNewParticipant(participant: Participant): AddParticipantAction {
  return {
    type: AddParticipantConst.ADD_PARTICIPANT,
    participant,
  };
}

export function addNewParticipantOk(): AddParticipantActionOk {
  return {
    type: AddParticipantConst.ADD_PARTICIPANT_OK,
  };
}

export function addNewParticipantKo(error: string): AddParticipantActionKo {
  return {
    type: AddParticipantConst.ADD_PARTICIPANT_KO,
    error,
  };
}
