import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema } from 'yup';
import { CustomFieldChildDependency, CustomFieldDataTypes, CustomFieldElement } from '../../redux/kpis/definitions';
import ClickLink from '../ClickLink/ClickLink';
import FncSubtitle from '../FncSubtitle/FncSubtitle';
import FncDatePickerController from '../Forms/FncDatePicker/FncDatePickerController';
import FncInputTextController from '../Forms/FncInputs/FncInputTextController';
import FncLabel from '../Forms/FncLabel/FncLabel';
import FncRadioGroupController from '../Forms/FncRadioButton/FncRadioGroupController';
import FncSelectController from '../Forms/FncSelect/FncSelectController';
import FncTextAreaController from '../Forms/FncTextArea/FncTextAreaController';
import TextInputController from '../Inputs/TextInputController/TextInputController';
import GenericMultiSelectController from '../Select/GenericMultiSelectController/GenericMultiSelectController';
import styles from './genericFormCustomField.module.scss';
import PoblacionCustomField from './CustomFields/Poblacion/PoblacionCustomField';

type GenericFormCustomFieldsProps<T extends FieldValues> = {
  customFields: CustomFieldElement[];
  disabled?: boolean;
  control: Control<any>;
  errors: FieldErrors<any>;
  schema: ObjectSchema<any>;
  clearError: (name: string) => void;
  setCustomFields: (customFields: CustomFieldElement[]) => void;
  setValue?: (name: string, value: any) => void;
};

const validatorCalc = (value: string | number | null, mandatory: boolean) => {
  if (mandatory) {
    return !value;
  }
  return false;
};
const GenericFormCustomFields = <T extends FieldValues, K>({
  customFields,
  disabled,
  control,
  errors,
  schema,
  clearError,
  setCustomFields,
  setValue,
}: GenericFormCustomFieldsProps<T>) => {
  const findDependency = (
    cf1: CustomFieldElement,
    child_dependencies: {
      id: number;
      name: string;
      condition: string;
      value: string | null;
      mandatory: boolean;
    }[],
  ) => {
    return child_dependencies.find(
      (cf2: { id: number; name: string; condition: string; value: string | null; mandatory: boolean }) =>
        cf2.id === cf1.id,
    );
  };

  const controlSingleDependency = (
    cf1: CustomFieldElement,
    child_dependency: CustomFieldChildDependency,
    inputValue: string,
  ) => {
    //cf1 is child
    if (child_dependency.condition === 'Existe') {
      cf1.read_only = !inputValue;
    } else if (child_dependency.condition === 'Es igual a') {
      cf1.read_only = inputValue !== child_dependency.value;
    }
    // check mandatory
    if (child_dependency.mandatory) {
      if (inputValue) cf1.mandatory = true;
      else {
        cf1.mandatory = false;
        clearError(cf1.name);
      }
    }
    return cf1;
  };
  const controlDependencyFields = (inputValue: string, child_dependencies: CustomFieldChildDependency[]) => {
    //check child dependencies
    const newCustomFields = customFields.map((cf1: CustomFieldElement) => {
      const child = findDependency(cf1, child_dependencies);
      if (child) return controlSingleDependency(cf1, child, inputValue);
      return cf1;
    });

    setCustomFields(newCustomFields);
  };

  const renderTextAreaField = (customField: CustomFieldElement) => {
    return (
      <FncTextAreaController
        key={customField.id}
        control={control}
        errors={errors}
        name={customField.name}
        schema={schema}
        label={customField.label}
        disabled={disabled || (!disabled && customField.read_only)}
        defaultValue={customField.value || ''}
        customClass={`${styles.field} ${styles[customField.size]}`}
      />
    );
  };

  const renderTextField = (customField: CustomFieldElement) => {
    return (
      <FncInputTextController
        key={customField.id}
        control={control}
        errors={errors}
        name={customField.name}
        schema={schema}
        label={customField.label}
        disabled={disabled || (!disabled && customField.read_only)}
        defaultValue={customField.value || ''}
        customClass={`${styles.field} ${styles.inputText} ${styles[customField.size]}`}
      />
    );
  };

  const renderRadioField = (customField: CustomFieldElement) => {
    if (!customField.options || customField.options.length === 0) return null;
    return (
      <FncRadioGroupController
        key={customField.id}
        name={customField.name}
        control={control}
        options={customField.options}
        errors={errors}
        description={customField.label}
        vertical={true}
        defaultValue={customField.value || ''}
        disabled={disabled}
        schema={schema}
        customClass={`${styles.field} ${styles.radio} ${styles[customField.size]}`}
      />
    );
  };

  const renderMultiSelect = (customField: CustomFieldElement) => {
    if (!customField.options || customField.options.length === 0) return null;
    return (
      <div className={`${styles.field} ${styles.multiselect} ${styles[customField.size]}`} key={customField.id}>
        <GenericMultiSelectController
          name={customField.name}
          label={customField.label}
          control={control}
          options={customField.options}
          defaultValue={customField.selectedValues}
        />
      </div>
    );
  };

  const renderValueList = (customField: CustomFieldElement) => {
    if (!customField.options || customField.options.length === 0) return null;
    return (
      <FncSelectController
        key={customField.id}
        name={customField.name}
        schema={schema}
        control={control}
        errors={errors}
        label={customField.label}
        disabled={disabled || (!disabled && customField.read_only)}
        defaultValue={customField.value?.toString()}
        options={customField.options}
        customClass={`${styles.field} ${styles.select} ${styles[customField.size]}`}
      />
    );
  };

  const renderNumeric = (customField: CustomFieldElement) => {
    return (
      <FncInputTextController
        key={customField.id}
        control={control}
        errors={errors}
        name={customField.name}
        schema={schema}
        label={customField.label}
        disabled={disabled || (!disabled && customField.read_only)}
        defaultValue={customField.value || ''}
        type={'number'}
        customClass={`${styles.field} ${styles.inputText} ${styles[customField.size]}`}
      />
    );
  };

  const renderDatePicker = (customField: CustomFieldElement) => {
    return (
      <FncDatePickerController
        key={customField.id}
        name={customField.name}
        control={control}
        errors={errors}
        label={customField.label}
        schema={schema}
        disabled={disabled || (!disabled && customField.read_only)}
        defaultValue={customField.value || ''}
        customClass={`${styles.field} ${styles.datePicker} ${styles[customField.size]}`}
      />
    );
  };

  const renderSubtitle = (customField: CustomFieldElement) => {
    if (!customField.value) return null;
    return <FncSubtitle key={customField.id}>{customField.value}</FncSubtitle>;
  };

  const renderFormLabel = (customField: CustomFieldElement) => {
    if (!customField.label) return null;
    return (
      <p className={styles.description} key={customField.id}>
        {customField.label}
      </p>
    );
  };

  const renderLink = (customField: CustomFieldElement) => {
    return (
      <div className={`${styles.field} ${styles.link} ${styles[customField.size]}`} key={customField.id}>
        {customField.label && <FncLabel>{customField.label}</FncLabel>}
        <TextInputController
          name={customField.name}
          validator={validatorCalc(customField.value, customField.mandatory)}
          schema={schema}
          disabled={disabled || (!disabled && customField.read_only)}
          control={control}
          errors={errors}
          fullWidth
          className={styles.inputLink}
          defaultValue={customField.value}
          InputProps={{
            endAdornment: <ClickLink value={control.getValues()[customField.name] || customField.value} />,
          }}
          onBlur={(evt: React.FocusEvent<HTMLInputElement>) => {
            controlDependencyFields(evt.target.value, customField.child_dependencies);
          }}
        />
      </div>
    );
  };

  const renderPoblacion = (customField: CustomFieldElement) => {
    return (
      <div key={customField.id} className={styles.poblacion}>
        <PoblacionCustomField
          customField={customField}
          disabled={disabled}
          control={control}
          errors={errors}
          schema={schema}
          setValue={setValue}
        />
      </div>
    );
  };

  const renderCustomField = (customField: CustomFieldElement) => {
    switch (customField.field_data_types) {
      case CustomFieldDataTypes.TEXTO:
        return renderTextField(customField);
      case CustomFieldDataTypes.BLOQUE_DE_TEXTO:
        return renderTextAreaField(customField);
      case CustomFieldDataTypes.RADIO:
        return renderRadioField(customField);
      case CustomFieldDataTypes.MULTISELECT:
        return renderMultiSelect(customField);
      case CustomFieldDataTypes.LISTA_DE_VALORES:
        return renderValueList(customField);
      case CustomFieldDataTypes.FECHA:
        return renderDatePicker(customField);
      case CustomFieldDataTypes.SUBTITULO:
        return renderSubtitle(customField);
      case CustomFieldDataTypes.ETIQUETA:
        return renderFormLabel(customField);
      case CustomFieldDataTypes.LINK:
        return renderLink(customField);
      case CustomFieldDataTypes.NUMERICO:
        return renderNumeric(customField);
      case CustomFieldDataTypes.POBLACION:
        return renderPoblacion(customField);
      default:
        return null;
    }
  };

  return (
    <div className={styles.root}>
      {customFields && customFields.map((customField: CustomFieldElement) => renderCustomField(customField))}
    </div>
  );
};

export default React.memo(GenericFormCustomFields);
