import { InterviewsConsts } from './action_types';
import {
  GetInterviewsDataAction,
  GetInterviewsDataKoAction,
  GetInterviewsDataOkAction,
  GetInterviewsFinishedDataAction,
  GetInterviewsFinishedDataKoAction,
  GetInterviewsFinishedDataOkAction,
  InterviewTableRow,
} from './definitions';

export function getInterviewsData(): GetInterviewsDataAction {
  return {
    type: InterviewsConsts.GET_INTERVIEWS,
  };
}

export function getInterviewsDataOk(interviewTableData: InterviewTableRow[]): GetInterviewsDataOkAction {
  return {
    type: InterviewsConsts.GET_INTERVIEWS_OK,
    interviewTableData,
  };
}

export function getInterviewsDataKo(error: string): GetInterviewsDataKoAction {
  return {
    type: InterviewsConsts.GET_INTERVIEWS_KO,
    error,
  };
}

// GET INTERVIEWS FINISHED DATA

export function getInterviewsFinishedData(): GetInterviewsFinishedDataAction {
  return {
    type: InterviewsConsts.GET_INTERVIEWS_FINISHED,
  };
}

export function getInterviewsFinishedDataOk(
  interviewTableData: InterviewTableRow[],
): GetInterviewsFinishedDataOkAction {
  return {
    type: InterviewsConsts.GET_INTERVIEWS_FINISHED_OK,
    interviewTableData,
  };
}

export function getInterviewsFinishedDataKo(error: string): GetInterviewsFinishedDataKoAction {
  return {
    type: InterviewsConsts.GET_INTERVIEWS_FINISHED_KO,
    error,
  };
}
