import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import resetEnhancer from './redux/logout';
import createRootReducer from './redux/root-reducer';
import rootSaga from './redux/root-saga';
import storeProvider from './storeProvider';
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = createStore(
  resetEnhancer(createRootReducer(history)),
  composeWithDevTools(applyMiddleware(...middleware, routerMiddleware(history))),
);

/* const store = createStore(globalReducer, composeWithDevTools(applyMiddleware(...middleware)));
 */
sagaMiddleware.run(rootSaga);

storeProvider.init(store);

export default storeProvider.getStore();
