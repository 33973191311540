import { AddParticipantConst } from './action_types';
import { CreateParticipantDataAction, CreateParticipantState, initialState } from './definitions.d';
import assertUnreachable from '../../../../helpers/assertUnreachable';

const createParticipantReducer = (
  state = initialState,
  action: CreateParticipantDataAction,
): CreateParticipantState => {
  switch (action.type) {
    case AddParticipantConst.ADD_PARTICIPANT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AddParticipantConst.ADD_PARTICIPANT_OK:
      return {
        ...state,
        loading: false,
      };
    case AddParticipantConst.ADD_PARTICIPANT_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default createParticipantReducer;
