import { InputLabel, MenuItem as SelectMenuItem, Select } from '@material-ui/core';
import { ExitToApp, SupervisedUserCircle } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { routes_definition } from '../../../../constants/routes';
import { tKey } from '../../../../helpers/translate';
import { logoutAction, userChangeRole } from '../../../../redux/auth/actions';
import { AuthDispatch } from '../../../../redux/auth/definitions';
import { selectIsUserCreated } from '../../../../redux/auth/selectors';
import { IdCodeNamePair } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import styles from './leftSide.module.scss';
import MenuItem from './MenuItems';
import LanguagesSelector from '../../../Header/LanguagesSelector';
import { applicationShowMenu } from '../../../../redux/application/actions';

interface LeftSideMenuPorps {
  isUserCreated?: boolean;
  userRoles: IdCodeNamePair[];
  userRoleId: number;
  userId: number;
  userName: string | null;
  logoutApp: () => void;
  applicationHideMenu: () => void;
  userChangeRole: (user_id: number, role_id: number) => void;
}

const LeftSideMenu: React.FC<LeftSideMenuPorps> = ({
  isUserCreated,
  userRoles,
  userRoleId,
  userId,
  userName,
  logoutApp,
  userChangeRole,
  applicationHideMenu,
}) => {
  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    userChangeRole(userId, event.target.value as number);
  };

  let roleSection;
  if (userRoles.length > 1) {
    roleSection = (
      <>
        <InputLabel id="role">
          <div className={styles.roleLabel}>
            <SupervisedUserCircle />
            <span>Rol actual</span>
          </div>
        </InputLabel>

        <Select
          variant="outlined"
          id="role-select"
          value={userRoleId}
          onChange={event => handleChange(event)}
          fullWidth
        >
          {userRoles.map((userRole: IdCodeNamePair) => (
            <SelectMenuItem key={userRole.id} value={userRole.id}>
              {userRole.name}
            </SelectMenuItem>
          ))}
        </Select>
      </>
    );
  }

  return (
    <div role="presentation" className={styles.wrapper}>
      <InputLabel className={styles.saluteLabel} id="salute">
        {`${tKey('Hola')} ${userName}!`}
      </InputLabel>
      {roleSection}
      {isUserCreated &&
        routes_definition.map(
          item =>
            item.display === 'leftsidebar' && <MenuItem key={item.name} item={item} onClick={applicationHideMenu} />,
        )}
      <MenuItem
        item={{
          name: '',
          path: '',
          icon: <ExitToApp />,
          title: tKey('Cerrar sesión'),
          platform: 'common',
        }}
        onClick={() => logoutApp()}
      />
      <LanguagesSelector />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isUserCreated: selectIsUserCreated(state),
  userRoles: state.authReducer.userInfo.roles,
  userRoleId: state.authReducer.userInfo.role_id,
  userId: state.authReducer.userInfo.user_id,
  userName: state.authReducer.userInfo.name,
});

const mapDispatchToProps = (dispatch: AuthDispatch | any) => ({
  logoutApp: (): void => dispatch(logoutAction()),
  userChangeRole: (user_id: number, role_id: number): void => dispatch(userChangeRole(user_id, role_id)),
  applicationHideMenu: (): void => dispatch(applicationShowMenu(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideMenu);
