import { Drawer, Fab } from '@material-ui/core';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import React, { useState } from 'react';
import MenuBottom from '../Menus/MenuBottom/MenuBottom';
import styles from './fabButtons.module.scss';

interface FabButtonMenuProps {
  disabled?: boolean;
}

const FabButtonMenu: React.FC<FabButtonMenuProps> = ({ disabled }) => {
  const [state, setState] = useState(false);

  const FabButton = () => (
    <Fab
      disabled={disabled}
      color="primary"
      style={{ background: !disabled && !!state ? '#141618' : undefined }}
      onClick={() => setState(!state)}
      className={`${styles.bottom} ${styles.left}`}
    >
      <ViewQuiltIcon />
    </Fab>
  );

  return (
    <React.Fragment>
      <FabButton />
      <Drawer key="menubottom" open={state} onClose={() => setState(!state)} anchor="bottom">
        <MenuBottom />
        <FabButton />
      </Drawer>
    </React.Fragment>
  );
};

export default FabButtonMenu;
