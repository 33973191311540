import { ButtonProps } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import * as H from 'history';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import CheckboxController from '../../../../components/Checkbox/CheckboxController';
import FormContainer from '../../../../components/Forms/FormContainer';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../../components/Loading/Loading';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import { yesNoOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import Title from '../../../../components/Title/Title';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import {
  acceptUserEducator,
  downloadDocument,
  getDocument,
  rejectUserEducator,
  removeDocument,
  updateProfileUserTable,
  uploadDocument,
} from '../../../../redux/user/actions';
import { UpdateUserFormFields, UserData, UserDataDispatch } from '../../../../redux/user/definitions';
import { selectError, selectUserValidationErrors } from '../../../../redux/user/selectors';
import { EditUserSchema } from '../../../../validations/formSchema';
import styles from '../../editUser.module.scss';
import SubmenuStudent from '../../SubmenuStudent/SubmenuStudent';
import lopdStyles from './lopd.module.scss';

interface LOPDInfoProps {
  readOnly: boolean;
  id: string;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  documents: DocumentationItem[];
  location: H.Location<{ needsValidation: boolean }>;
  profileData: UserData;
  validationErrors: APIValidationError | null;
  acceptEducator: (id: number) => void;
  rejectEducator: (id: number) => void;
  updateTableUser: (data: UpdateUserFormFields, id: number) => void;
  uploadUserDocument: (document_id: number, data: File) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
  handleSetImageById: (image: string) => void;
  onSelectComponent: (componentId: number) => void;
}

const LOPDInfo: React.FC<LOPDInfoProps> = ({
  readOnly,
  avatar,
  profileData,
  loading,
  location,
  id,
  validationErrors,
  changeAvatar,
  updateTableUser,
  handleSetImageById,
  onSelectComponent,
}) => {
  const { errors, handleSubmit, setError, control, register } = useForm<UpdateUserFormFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EditUserSchema,
  });

  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: 'Ayuda',
    body: <div className={styles.modalHelp}>{tKey('mensaje usuario')}</div>,
    startIcon: <HelpOutline />,
  });
  useValidatorAPI(validationErrors, setError);

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('ACTUALIZAR DATOS'),
      onClick: handleSubmit((data: UpdateUserFormFields) => {
        if (!Object.keys(errors).length) {
          updateTableUser(data, +id);
        } else {
          for (const e of Object.keys(errors)) {
            // @ts-ignore
            setError(e, errors[e].types, errors[e].message);
          }
        }
      }),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      hidden: readOnly,
    },
  ];

  const moreInfoButtons: ButtonProps[] = [
    {
      children: tKey('AYUDA'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      onClick: () =>
        setCreatedModal(prevState => ({
          ...prevState,
          view: true,
        })),
      startIcon: <HelpOutline />,
      hidden: location.state.needsValidation && profileData.state !== 'DISABLED' ? false : true,
    },
  ];

  const title = readOnly ? tKey('DETALLES  DE USUARIO') : tKey('EDICIÓN DE USUARIO');
  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} moreInfoButtons={moreInfoButtons} />}
      leftSubmenu={
        <SubmenuStudent
          selected="lopd"
          avatar={avatar}
          loading={loading}
          onSelectComponent={onSelectComponent}
          setImage={changeAvatar ? handleSetImageById : undefined}
        />
      }
    >
      <React.Fragment>
        {!loading ? (
          <form className={lopdStyles.form}>
            <Title>{title}</Title>
            <FormContainer title={tFormKey('LOPD')}>
              <CheckboxController
                label={(tFormKey('consentimiento-datos'), tFormKey('política de privacidad'))}
                control={control}
                name={'privacy_policy'}
                defaultValue={!!profileData.privacy_policy}
                disabled
              />
              <RadioButtons
                name="send_information"
                questionText={tFormKey('consentimiento-envio-información')} // Nos   da   su   consentimiento   para   enviarle   información comercial periódica sobre nuestros productos y/o servicios
                register={register}
                options={yesNoOptions}
                optionModal
                defaultValue={profileData.send_information?.toString()}
                disabled
              />
              <RadioButtons
                name="image_use"
                questionText={tFormKey('consentimiento-imagenes')} // Nos   da   su   consentimiento   para   enviarle   información comercial periódica sobre nuestros productos y/o servicios
                register={register}
                options={yesNoOptions}
                optionModal
                defaultValue={profileData.image_use?.toString()}
                disabled
              />
              <RadioButtons
                name="whatsapp_aggregation"
                questionText={tFormKey('consentimiento-contacto')} // Nos   da   su   consentimiento   para   enviarle   información comercial periódica sobre nuestros productos y/o servicios
                register={register}
                options={yesNoOptions}
                optionModal
                defaultValue={profileData.whatsapp_aggregation?.toString()}
                disabled
              />
            </FormContainer>
          </form>
        ) : (
          <Loading big />
        )}
      </React.Fragment>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        maxWidth={true}
        bodyModal={createdModal.body}
      >
        {createdModal.children}
      </TransitionModal>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  error: selectError(state),
  validationErrors: selectUserValidationErrors(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  updateTableUser: (data: UpdateUserFormFields, id: number): void => dispatch(updateProfileUserTable(data, id)),
  uploadUserDocument: (document_id: number, data: File): void => dispatch(uploadDocument(document_id, data)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
  acceptEducator: (id: number): void => dispatch(acceptUserEducator(id)),
  rejectEducator: (id: number): void => dispatch(rejectUserEducator(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LOPDInfo);
