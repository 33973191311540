import { InternalProjectId, ParticipantId, Participant } from './Participant';
import { ParticipantConst } from './action_types';
import { Action } from 'redux';

export interface DeleteParticipantAction extends Action {
  type: ParticipantConst.DELETE_PARTICIPANTS;
  internal_project_id: InternalProjectId;
  participantId: ParticipantId;
}

export interface DeleteParticipantOkAction extends Action {
  type: ParticipantConst.DELETE_PARTICIPANTS_OK;
  internal_project_id: InternalProjectId;
  participantId: ParticipantId;
}

export interface DeleteParticipantKoAction extends Action {
  type: ParticipantConst.DELETE_PARTICIPANTS_KO;
  error: string;
}

export interface CancelDeleteParticipantAction extends Action {
  type: ParticipantConst.CANCEL_DELETE_PARTICIPANTS;
}

export type ParticipantAction = DeleteParticipantAction | DeleteParticipantOkAction | DeleteParticipantKoAction;

export const initialState = {
  loading: false,
  error: null as string | null,
  data: [] as Participant[],
};
export type DeleteParticipantState = typeof initialState;
