import { useState } from 'react';
import { EntityInfo } from '../../../redux/entity/definitions';

export type EntityTypeRadios = {
  has_project: number;
  has_scholarship: number;
  is_provider: number;
  is_visa_provider: number;
  legal_person: number;
  natural_person: number;
  no_payments_entity: number;
};

export type typeName =
  | 'has_project'
  | 'has_scholarship'
  | 'is_provider'
  | 'is_visa_provider'
  | 'legal_person'
  | 'natural_person'
  | 'no_payments_entity';

type UseEntityTypeData = {
  typeRadios: EntityTypeRadios;
  setTypeValue: (name: typeName, value: boolean) => void;
  defaultTypes: (entity: EntityInfo) => void;
  isDefaultSetted: () => boolean;
};

const useEntityType = (): UseEntityTypeData => {
  const [defaultSetted, setDefaultSetted] = useState<boolean>(false);
  const [typeRadios, setTypeRadios] = useState<EntityTypeRadios>({
    has_project: 0,
    has_scholarship: 0,
    is_provider: 0,
    is_visa_provider: 0,
    legal_person: 0,
    natural_person: 0,
    no_payments_entity: 0,
  });

  const defaultTypes = (entity: EntityInfo) => {
    setDefaultSetted(true);
    setTypeRadios({
      has_project: entity.has_project,
      has_scholarship: entity.has_scholarship,
      is_provider: entity.is_provider,
      is_visa_provider: entity.is_visa_provider,
      legal_person: entity.legal_person,
      natural_person: entity.natural_person,
      no_payments_entity: entity.no_payments_entity,
    });
  };

  const isDefaultSetted = () => {
    return defaultSetted;
  };

  const setIsProvider = (value: number) => {
    setTypeRadios({
      ...typeRadios,
      legal_person: value,
      is_provider: value,
      natural_person: 0,
      is_visa_provider: 0,
    });
  };

  const setIsNaturalPerson = (value: number) => {
    setTypeRadios({
      ...typeRadios,
      natural_person: value,
      legal_person: value === 1 ? 0 : 1,
    });
  };

  const setIsLegalPerson = (value: number) => {
    setTypeRadios({
      ...typeRadios,
      legal_person: value,
      natural_person: value === 1 ? 0 : 1,
    });
  };

  const setTypeValue = (name: typeName, value: boolean) => {
    const numValue = value ? 1 : 0;
    switch (name) {
      case 'is_provider':
        setIsProvider(numValue);
        break;
      case 'natural_person':
        setIsNaturalPerson(numValue);
        break;
      case 'legal_person':
        setIsLegalPerson(numValue);
        break;
      default:
        setTypeRadios({ ...typeRadios, [name]: numValue });
    }
  };

  return {
    typeRadios,
    setTypeValue,
    defaultTypes,
    isDefaultSetted,
  };
};

export default useEntityType;
