import { Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import { tKey } from '../../helpers/translate';
import { RefuseEntityModalSchema } from '../../validations/formSchema';
import styles from './refuseEntityModal.module.scss';

interface RefuseEntityModalProps {
  handleSend: (message?: string) => void;
  handleClose: () => void;
  rows?: number;
  path?: string;
  isVisibleWithoutReasonButton?: boolean;
}

const RefuseEntityModal: React.FC<RefuseEntityModalProps> = ({
  handleSend,
  handleClose,
  rows,
  path,
  isVisibleWithoutReasonButton = true,
}) => {
  const history = useHistory();

  interface MessageProps {
    message: string;
  }

  const { errors, handleSubmit, watch, control } = useForm<MessageProps>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: RefuseEntityModalSchema,
  });
  const haveMessage = !+watch('message')?.length;

  const onCancel = () => {
    handleSend(undefined);
    handleClose();
    path && history.push(path);
  };

  const onSubmit = async (data: MessageProps) => {
    handleSend(data.message);
    handleClose();
    path && history.push(path);
  };
  return (
    <form className={styles.refuse} onSubmit={handleSubmit(onSubmit)}>
      <TextInputController
        name="message"
        control={control}
        multiline
        rows={rows || '3'}
        rowsMax={rows || '5'}
        errors={errors}
        schema={RefuseEntityModalSchema}
      />
      <div className={styles.btns}>
        <ButtonGroup>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            disabled={haveMessage}
            color="primary"
            type="submit"
            fullWidth
          >
            {tKey('Ok')}
          </Button>
          {isVisibleWithoutReasonButton && (
            <Button variant="contained" startIcon={<SendIcon />} color="primary" onClick={() => onCancel()} fullWidth>
              {tKey('Sin motivo')}
            </Button>
          )}
        </ButtonGroup>
      </div>
    </form>
  );
};

export default RefuseEntityModal;
