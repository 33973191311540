import { ParticipantConst } from './action_types';
import { initialState, DeleteParticipantState, ParticipantAction } from './definition.d';

const deleteParticipantReducer = (state = initialState, action: ParticipantAction): DeleteParticipantState => {
  switch (action.type) {
    case ParticipantConst.DELETE_PARTICIPANTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ParticipantConst.DELETE_PARTICIPANTS_OK: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case ParticipantConst.DELETE_PARTICIPANTS_KO: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};

export default deleteParticipantReducer;
