class Documentation {
  static DNI = 'NIF';
  static CARTA_MOTIVACION = 'MOT';
  static EXPEDIENTE_ACADEMICO = 'EXP';
  static ENTREVISTA = 'ENT';
  static NOMINA = 'NOM';
  static RESOLUCION_BECA_CONCEDIDA = 'RES';
  static NIF_TUTOR = 'TNF';
  static CARTA_RECOMENDACION_INSTITUTO = 'CRI';
  static DECLARACION_RENTA = 'REN';
  static DOC_COMPLEMENTARIA = 'CMP';
  static INGRESOS_ALTERNATIVOS = 'ALT';
  static DOC_RECHAZO_ABANDONO = 'REA';
  static PROBLEMATICA_AJUSTE_BECA = 'PAB';
  static ULTIMAS_NOTAS = 'UN';
  static JUSTIFICACION_MENSUALIDADES = 'MEN';
  static JUSTIFICACION_MATRICULA = 'MAT';
  static JUSTIFICACION_MATERIALES = 'MTE';
  static JUSTIFICACION_TRANSPORTE = 'TRA';
  static IRPF = 'IRP';
  static CERTIFICADO_IBAN = 'IBB';
  static SOLICITUD_MATERIALES = 'SMA';
  static CERTIFICADO_MATERIALES = 'CMA';
  static HOJA_DE_MATRICULA = 'HMT';
  static CERTIFICADO_IBAN_CENTRO = 'CIC';
  static CONVENIO = 'CON';
  static DOCUMENTO_BANCO_CUENTA_ENTIDAD = 'CTA';

  constructor(private code: string) {}
}

export default Documentation;
