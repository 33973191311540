import { GetFamilyIncomesConst } from './action_types';
import { GetFamilyIncomesAction, GetFamilyIncomesKoAction, GetFamilyIncomesOkAction } from './definitions';
import { FamilyIncome, ScholarshipId } from '../FamilyIncome';

export function actionGetFamilyIncomes(id: ScholarshipId): GetFamilyIncomesAction {
  return {
    type: GetFamilyIncomesConst.GET_FAMILY_INCOMES,
    id,
  };
}

export function actionGetFamilyIncomesOk(data: FamilyIncome[]): GetFamilyIncomesOkAction {
  return {
    type: GetFamilyIncomesConst.GET_FAMILY_INCOMES_OK,
    list: data,
  };
}

export function actionGetFamilyIncomesKo(error: string): GetFamilyIncomesKoAction {
  return {
    type: GetFamilyIncomesConst.GET_FAMILY_INCOMES_KO,
    error,
  };
}
