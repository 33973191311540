export enum TranslationConst {
  GET_CATEGORIES_TRANSLATION = 'GET:CATEGORIES:TRANSLATION',
  GET_CATEGORIES_TRANSLATION_OK = 'GET:CATEGORIES:TRANSLATION:OK',
  GET_CATEGORIES_TRANSLATION_KO = 'GET:CATEGORIES:TRANSLATION:KO',

  GET_FILE_TRANSLATIONS = 'GET:FILE:TRANSLATIONS',
  GET_FILE_TRANSLATIONS_OK = 'GET:FILE:TRANSLATIONS:OK',
  GET_FILE_TRANSLATIONS_KO = 'GET:FILE:TRANSLATIONS:KO',

  SEND_IMPORT_TRANSLATIONS = 'SEND:IMPORT:TRANSLATIONS',
  SEND_IMPORT_TRANSLATIONS_OK = 'SEND:IMPORT:TRANSLATIONS:OK',
  SEND_IMPORT_TRANSLATIONS_KO = 'SEND:IMPORT:TRANSLATIONS:KO',

  GET_LIST_MAILS = 'GET:LIST:MAILS',
  GET_LIST_MAILS_OK = 'GET:LIST:MAILS:OK',
  GET_LIST_MAILS_KO = 'GET:LIST:MAILS:KO',

  GET_MAIL_DETAILS = 'GET:MAIL:DETAILS',
  GET_MAIL_DETAILS_OK = 'GET:MAIL:DETAILS:OK',
  GET_MAIL_DETAILS_KO = 'GET:MAIL:DETAILS:KO',

  UPDATE_MAIL_DETAILS = 'UPDATE:MAIL:DETAILS',
  UPDATE_MAIL_DETAILS_OK = 'UPDATE:MAIL:DETAILS:OK',
  UPDATE_MAIL_DETAILS_KO = 'UPDATE:MAIL:DETAILS:KO',

  TEST_TRANSLATION_MAIL = 'TEST:TRANSLATION:MAIL',

  SET_DATA = 'SET:DATA',

  SET_DATA_MAIL = 'SET:DATA_MAIL',

  UPDATE_TRANSLATE_DATA = 'UPDATE:TRANSLATE:DATA',
  UPDATE_TRANSLATE_DATA_OK = 'UPDATE:TRANSLATE:DATA:OK',
  UPDATE_TRANSLATE_DATA_KO = 'UPDATE:TRANSLATE:DATA:KO',

  GET_METADATA_TRANSLATIONS = 'GET:METADATA:TRANSLATIONS',
  GET_METADATA_TRANSLATIONS_OK = 'GET:METADATA:TRANSLATIONS:OK',
  GET_METADATA_TRANSLATIONS_KO = 'GET:METADATA:TRANSLATIONS:KO',

  SET_LOPD_TRANSLATION = 'SET:LOPD:TRANSLATION',

  CLEAN_LOPD_TRANSLATION = 'CLEAN:LOPD:TRANSLATION',

  GET_LOPD_TRANSLATION = 'GET_LOPD_TRANSLATION',
  GET_LOPD_TRANSLATION_OK = 'GET:LOPD:TRANSLATION:OK',
  GET_LOPD_TRANSLATION_KO = 'GET:LOPD:TRANSLATION:KO',

  UPDATE_LOPD_TRANSLATION = 'UPDATE:LOPD:TRANSLATION',
  UPDATE_LOPD_TRANSLATION_OK = 'UPDATE:LOPD:TRANSLATION:OK',
  UPDATE_LOPD_TRANSLATION_KO = 'UPDATE:LOPD:TRANSLATION:KO',

  SET_CATEGORY_FILTER = 'SET:CATEGORY:FILTER',
}
