import React from 'react';
import FncButton from '../../../../components/FncButton/FncButton';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import MaterialsRow from './MaterialsRow';
import MaterialsTableModal from './MaterialsTableModal';
import styles from './materialsTable.module.scss';
import { Material } from '../../../../redux/scholarship/definitions';

interface MaterialsTableProps {
  scholarshipId: number;
  materials: Material[];
  disabled: boolean;
  info_message?: string;
  error_message?: string;
  loading: boolean;
  addMaterial: (material: Material) => void;
  deleteMaterial: (material: Material) => void;
  updateMaterial: (material: Material) => void;
}

const MaterialsTable: React.FC<MaterialsTableProps> = ({
  scholarshipId,
  materials,
  disabled = false,
  info_message,
  error_message,
  loading,
  addMaterial,
  deleteMaterial,
  updateMaterial,
}) => {
  const materialModalDefault = {
    view: false,
    title: '',
    children: <></>,
  };
  const [materialModal, setMaterialModal] = React.useState(materialModalDefault);

  const closeModal = () => {
    setMaterialModal(materialModalDefault);
  };

  const renderModal = (material: Material, onSave: (material: Material) => void): JSX.Element => {
    return <MaterialsTableModal closeModal={() => closeModal()} material={material} onSave={onSave} />;
  };

  const addOne = () => {
    const newMaterial = {
      id: 0,
      scholarship_id: scholarshipId,
      name: '',
      amount: 0,
    } as Material;
    setMaterialModal({
      view: true,
      title: tScholarshipKey('add_material'),
      children: renderModal(newMaterial, addMaterial),
    });
  };

  const editOne = (material: Material) => {
    setMaterialModal({
      view: true,
      title: tScholarshipKey('edit_material'),
      children: renderModal(material, updateMaterial),
    });
  };

  const renderAddNew = () => {
    return (
      <div className={styles.addNewMaterial}>
        <FncButton type="button" color="primary" disabled={disabled} onClick={() => addOne()}>
          {tScholarshipKey('add_material')}
        </FncButton>
      </div>
    );
  };

  const renderMaterials = () => {
    if (materials.length > 0) {
      return materials.map((material: Material) => {
        return (
          <MaterialsRow
            key={material.id}
            material={material}
            disabled={disabled}
            onRemove={deleteMaterial}
            onEdit={editOne}
          />
        );
      });
    }
    return (
      <tr className={styles.materialsTableRow}>
        <td colSpan={4} className={`${styles.materialsCellRow} ${styles.materialsEmptyCellRow}`}>
          {loading ? tKey('Cargando...') : tKey('No hay materiales')}
        </td>
      </tr>
    );
  };

  const renderMessages = (): JSX.Element => {
    if (error_message) {
      return <span className={styles.materialsTableError}>{error_message}</span>;
    }
    return <span className={styles.familyIncomeInfo}>{info_message}</span>;
  };

  const renderMessageArea = (): JSX.Element | undefined => {
    if (error_message || info_message) {
      return <div className={styles.tableMessageArea}>{renderMessages()}</div>;
    }
    return undefined;
  };

  return (
    <>
      <div className={styles.materialsTableContainer}>
        <div className={styles.tableTitle}>
          <SubTitle>{tScholarshipKey('scholarship_materials')}</SubTitle>
          {renderAddNew()}
        </div>
        <table className={styles.materialsTable}>
          <thead className={styles.materialsTableHeader}>
            <tr>
              <th className={styles.materialsCellHeader}>{tScholarshipKey('material_name')}</th>
              <th className={styles.materialsCellHeader}>{tScholarshipKey('material_amount')}</th>
              <th className={styles.materialsCellHeader}></th>
            </tr>
          </thead>
          <tbody>{renderMaterials()}</tbody>
        </table>
        {renderMessageArea()}
      </div>
      <TransitionModal
        view={materialModal.view}
        handleClose={() => {
          closeModal();
        }}
        title={materialModal.title}
        bodyModal={materialModal.children}
      />
    </>
  );
};

export default MaterialsTable;
