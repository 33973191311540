import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { APIError } from '../../api/api';
import LayoutLogin from '../../components/Layout/LayoutLogin';
import Loading from '../../components/Loading/Loading';
import Subtitle from '../../components/SubTitle/Subtitle';
import { tErrorKey, tKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import { activationEntity, logoutAction } from '../../redux/auth/actions';
import { AuthDispatch } from '../../redux/auth/definitions';
import { selectError, selectIsLoading, selectToken } from '../../redux/auth/selectors';
import { getGenericMetadata } from '../../redux/metadata/actions';
import { GenericMetadata } from '../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../redux/metadata/selectors';
import { AppState } from '../../redux/root-reducer';
import styles from './entityActivation.module.scss';

interface EntityActivationProps {
  registerOk: boolean | undefined;
  loading?: boolean;
  error: APIError | null;
  token?: null | string;
  genericMetadata: GenericMetadata;
  activationEntity?: (token: string) => void;
  logoutApp: () => void;
  getGenericMetadata: () => void;
}

const EntityActivation: React.FC<EntityActivationProps> = ({
  loading,
  error,
  registerOk,
  genericMetadata,
  activationEntity,
  logoutApp,
  getGenericMetadata,
}) => {
  const history = useHistory();
  const [, , , urlToken] = history.location.pathname.split('/');

  useEffect(() => {
    if (!genericMetadata.loaded) getGenericMetadata();
    if (!registerOk) {
      activationEntity && activationEntity(urlToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activationEntity, urlToken, registerOk]);

  return (
    <LayoutLogin dis_space subtitle="">
      {!registerOk ? (
        <div>
          {!loading && <Subtitle>{!error ? tKey('¡Cuenta activada!') : tErrorKey('Algo ha ido mal')}</Subtitle>}
          {loading ? (
            <Loading big />
          ) : (
            <React.Fragment>
              {!error && (
                <div className={styles.container}>
                  <p className={styles.parraf}>
                    {tKey('Haz clic aquí para')}
                    {
                      <Link className={styles[`access_${webStyle}`]} to="/mi-entidad">
                        {tKey('acceder a la plataforma')}
                      </Link>
                    }
                  </p>
                </div>
              )}
              {error && (
                <div className={styles.container}>
                  <p className={styles.parraf}>{tKey('Póngase en contacto con el administrador.')}</p>
                  <p className={styles.parraf}>
                    {tKey('Haz clic')}
                    <a
                      href="/#"
                      className={styles[`access_${webStyle}`]}
                      onClick={() => {
                        logoutApp();
                      }}
                    >
                      {tKey('aquí')}
                    </a>
                    {tKey('para ir a login.')}
                  </p>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      ) : (
        <div>
          {!loading && <Subtitle>{tKey('Registro de entidad')}</Subtitle>}{' '}
          {loading ? (
            <Loading big />
          ) : (
            <div className={styles.container}>
              <p className={styles.parraf}>{tKey('Email activación del registro')}</p>
              <p className={styles.parraf}>
                {tKey('Para ir a login haz clic')}
                <a
                  href="/#"
                  className={styles[`access_${webStyle}`]}
                  onClick={() => {
                    logoutApp();
                  }}
                >
                  {tKey('aquí')}
                </a>
              </p>
            </div>
          )}
        </div>
      )}
    </LayoutLogin>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  error: selectError(state),
  token: selectToken(state),
  genericMetadata: selectGenericMetadata(state),
});

const mapDispatchToProps = (dispatch: AuthDispatch | any) => ({
  activationEntity: (token: string): void => dispatch(activationEntity(token)),
  logoutApp: (): void => dispatch(logoutAction()),
  getGenericMetadata: (): void => dispatch(getGenericMetadata()),
});
export default connect(mapStateProps, mapDispatchToProps)(EntityActivation);
