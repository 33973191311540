import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { tKey } from '../../../helpers/translate';
import styles from './userRolesModalTabs.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
interface ModalTabsProps {
  tabOne: JSX.Element;
  tabTwo: JSX.Element | undefined;
}
const ModalTabs: React.FC<ModalTabsProps> = ({ tabOne, tabTwo }) => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  let tabTwoView;
  let tabTwoPanel;
  if (tabTwo) {
    tabTwoView = (
      <Tab
        label={tKey('Permisos')}
        {...tabProps(1)}
        className={value === 1 ? styles.tab_selected : styles.tab_unselected}
      />
    );
    tabTwoPanel = (
      <TabPanel value={value} index={1}>
        {tabTwo}
      </TabPanel>
    );
  }

  return (
    <Box style={{ width: 'auto', margin: '10px' }}>
      <Box>
        <Tabs value={value} onChange={handleChange} className={styles.tabs} indicatorColor="primary">
          <Tab
            label={tKey('Roles')}
            {...tabProps(0)}
            className={value === 0 ? styles.tab_selected : styles.tab_unselected}
          />
          {tabTwoView}
        </Tabs>
      </Box>
      <div className={styles.tabPanels}>
        <TabPanel value={value} index={0}>
          {tabOne}
        </TabPanel>
        {tabTwoPanel}
      </div>
    </Box>
  );
};

export default ModalTabs;
