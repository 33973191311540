import { put, takeLatest } from '@redux-saga/core/effects';
import { getAllApiDates, setTimesToFreeApi, setTimesToNotAvailableApi } from '../../api/Calendar/calendar';
import errorMessage from '../../helpers/errorMessage';
import {
  setTimeToFreeKo,
  setTimeToFreeOk,
  setTimeToNotAvailableKo,
  setTimeToNotAvailableOk,
} from '../calendar/actions';
import { showSnackbar } from '../FeedbackAPI/actions';
import { getAllDatesKo, getAllDatesOk } from './actions';
import { CalendarConsts } from './action_types';
import { GetAllDatesAction, SetTimeToFreeAction, SetTimeToNotAvailableAction } from './definitions';

function* getAllElegibleDatesFromSaga(action: GetAllDatesAction): Generator<any, void, any> {
  try {
    const info = yield getAllApiDates(action.data);
    yield put(getAllDatesOk(info));
  } catch (e) {
    yield put(getAllDatesKo(errorMessage(e)));
  }
}

function* setTimeToNotAvailableFromSaga(action: SetTimeToNotAvailableAction): Generator<any, void, any> {
  try {
    const info = yield setTimesToNotAvailableApi(action.data);
    yield put(setTimeToNotAvailableOk(info));
  } catch (e) {
    yield put(setTimeToNotAvailableKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error', 'error'));
  }
}

function* setTimeToFreeFromSaga(action: SetTimeToFreeAction): Generator<any, void, any> {
  const { data, channel } = action;
  try {
    const info = yield setTimesToFreeApi(data, channel);
    yield put(setTimeToFreeOk(info, channel));
  } catch (e) {
    yield put(setTimeToFreeKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error', 'error'));
  }
}

export default [
  takeLatest<GetAllDatesAction>(CalendarConsts.GET_ALL_DATES, getAllElegibleDatesFromSaga),
  takeLatest<SetTimeToNotAvailableAction>(CalendarConsts.SET_TIME_TO_NOT_AVAILABLE, setTimeToNotAvailableFromSaga),
  takeLatest<SetTimeToFreeAction>(CalendarConsts.SET_TIME_TO_FREE, setTimeToFreeFromSaga),
];
