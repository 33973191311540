// GET AREAS

import { SubareaInternalProjectTypeConst } from './action_types';
import { SubareaInternalProjectType } from './subareaInternalProjectType.d';

export interface GetSubareaInternalProjectTypeAction extends Action {
  type:
    | SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE
    | SubareaInternalProjectTypeConst.CANCEL_GET_SUBAREAINTERNALPROJECTTYPE;
}

export interface GetSubareaInternalProjectTypeOkAction extends Action {
  type: SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE_OK;
  data: SubareaInternalProjectType[];
}

export interface GetSubareaInternalProjectTypeKoAction extends Action {
  type: SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE_KO;
  error: string;
}

export interface CancelGetSubareaInternalProjectTypeAction extends Action {
  type: SubareaInternalProjectTypeConst.CANCEL_GET_SUBAREAINTERNALPROJECTTYPE;
}

export type SubareaInternalProjectTypeAction =
  | GetSubareaInternalProjectTypeAction
  | GetSubareaInternalProjectTypeOkAction
  | GetSubareaInternalProjectTypeKoAction;

export const initialState = {
  loading: false,
  error: null as string | null,
  data: [] as SubareaInternalProjectType[],
};
export type SubareaInternalProjectTypeState = typeof initialState;
