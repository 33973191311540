import React, { useState } from 'react';
import StepperMenu from '../../../components/StepperMenu/StepperMenu';
import { useWindowSize } from '../../../helpers/customHooks/useHookMethod';
import { tScholarshipKey } from '../../../helpers/translate';
import { ReactComponent as IconArrowOpen } from '../../../images/icons/iconAccordeon.svg';
import { ScholarshipStateCode, ScholarshipData } from '../../../redux/scholarship/definitions';
import styles from './stateMenu.module.scss';
const statesStepper = {
  [ScholarshipStateCode.CREATION]: 0,
  [ScholarshipStateCode.REQUEST]: 1,
  [ScholarshipStateCode.PENDING_INTERVIEW]: 2,
  [ScholarshipStateCode.PENDING_INTERVIEW_VALORATION]: 3,
  [ScholarshipStateCode.PENDING_VALUATION]: 4,
  [ScholarshipStateCode.VALUATED]: 5,
  [ScholarshipStateCode.PENDING_INFO]: 6,
  [ScholarshipStateCode.APPROVED]: 7,
  [ScholarshipStateCode.MIGRATED]: 7,
  [ScholarshipStateCode.ADMIN_REVIEW]: 7,
  [ScholarshipStateCode.CONFIRMATION]: 8,
  [ScholarshipStateCode.ASSIGNED]: 9,
  [ScholarshipStateCode.FOLLOW_S1]: 10,
  [ScholarshipStateCode.FOLLOW_S2]: 10,
  [ScholarshipStateCode.FOLLOW_T1]: 10,
  [ScholarshipStateCode.FOLLOW_T2]: 10,
  [ScholarshipStateCode.FOLLOW_T3]: 10,
  [ScholarshipStateCode.END_EVALUATION_S1]: 10,
  [ScholarshipStateCode.END_EVALUATION_S2]: 10,
  [ScholarshipStateCode.END_EVALUATION_T1]: 10,
  [ScholarshipStateCode.END_EVALUATION_T2]: 10,
  [ScholarshipStateCode.END_EVALUATION_T3]: 10,
  [ScholarshipStateCode.END_EVALUATION]: 10,
  [ScholarshipStateCode.ABANDONMENT]: 11,
  [ScholarshipStateCode.RENUNCIATION]: 12,
  [ScholarshipStateCode.CLOSURE]: 13,
  [ScholarshipStateCode.COMPLETED]: 13,
  [ScholarshipStateCode.DESTIMATED]: -1,
  [ScholarshipStateCode.PENDING_JUSTIFICATION]: 14,
};

type visibilityStateMenu = 'hideDesktop' | 'hideMobile';
interface Props {
  scholarshipData: ScholarshipData;
  visibility?: visibilityStateMenu;
  style?: string;
  disabledDesktop?: boolean;
  isInitialOpen?: boolean;
}

const visibilityClass = (visibility?: visibilityStateMenu): string => {
  switch (visibility) {
    case 'hideDesktop':
      return styles.isHideDesktop;
    case 'hideMobile':
      return styles.isHideMobile;
    default:
      return '';
  }
};
const StateMenu = ({ scholarshipData, visibility, style, disabledDesktop = false, isInitialOpen = true }: Props) => {
  const isMobile = useWindowSize().IsMobile;
  const [isOpen, setIsOpen] = useState(isInitialOpen);
  const handleIsOpen = () => {
    if (disabledDesktop && !isMobile) {
      return false;
    }
    setIsOpen(!isOpen);
  };
  return (
    <div
      className={`${styles.root} 
      ${visibilityClass(visibility)} 
      ${!isOpen ? styles.isClose : ''}`}
    >
      <div
        className={styles.title}
        onClick={() => {
          handleIsOpen();
        }}
      >
        <h3 className={styles.titleText}>{tScholarshipKey('Estado')}</h3>
        <IconArrowOpen className={`${styles.icon} ${isOpen ? styles.isOpen : ''}`} />
      </div>
      <div className={`${styles.content} ${style ? ` ${style}` : ''}`}>
        <div className={styles.animationWrapper}>
          <div className={styles.stepperWrapper}>
            <StepperMenu
              activeStep={statesStepper[scholarshipData.state_code]}
              state={scholarshipData.state}
              totalSteps={15}
              isRenewal={scholarshipData.is_renewal}
            />
            <div className={`${styles.tag}`}>
              {scholarshipData.is_renewal ? tScholarshipKey('Renovación') : tScholarshipKey('Nueva beca')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StateMenu;
