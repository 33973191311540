import { put, select, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { getTrackingsByAreaCode } from '../../api/Areas/areas';
import { downloadOriginal } from '../../api/Entity/entity';
import {
  addDescriptionDocument,
  addDocumentFieldFromApi,
  addOneFollowUp,
  addProjectImageFromApi,
  addProjectSchedule,
  agreeDocument,
  assignDocsTable,
  cancelProjectAgreementFromApi,
  changeStateFromApi,
  confirmConfiguratorApi,
  createProject,
  deleteActorFromApi,
  deleteAgreement,
  deleteDocsTable,
  deleteFile,
  deleteFileFromApi,
  deleteFollowUp,
  deletePhoneFollowUpDocument,
  deleteProjectById,
  deleteProjectImageByImageIdFromApi,
  deleteProjectSchedule,
  deleteRowTable,
  downloadDocument,
  downloadFile,
  downloadFollowUpDocument,
  downloadPhoneFollowUpDocument,
  editFollowup,
  editProject,
  editProjectImageFromApi,
  getActorsMetadataFromApi,
  getAreasSubAreas,
  getCustomFieldsForm,
  getDirectoryFilterDataFromApi,
  getDocumentationApi,
  getListFollowUps,
  getOneFollowUp,
  getProjectById,
  getProjectImagesFromApi,
  linkPaymentProjectFromApi,
  makePaymentProject,
  removeFollowupDocument,
  removePaymentFileFromApi,
  retrieveProjects,
  sendActorFromApi,
  sendConfiguratorFieldsApi,
  setCustomFieldsForm,
  updateProjectFinalDate,
  updateProjectSchedule,
  updateProjectSchedules,
  uploadAgreement,
  uploadAnnexDocumentByCode,
  uploadImageFileFromApi,
  uploadNewDocument,
  uploadPaymentFileFromApi,
  uploadPhoneFollowUpDocument,
} from '../../api/Projects/projects';
import { Await } from '../../api/api';
import assertUnreachable from '../../helpers/assertUnreachable';
import errorMessage from '../../helpers/errorMessage';
import { tKey, tProjectKey } from '../../helpers/translate';
import { showSnackbar } from '../FeedbackAPI/actions';
import { ResourceImageData } from '../common/definitions';
import {
  ProjectImagestKo,
  addDocumentKo,
  addDocumentOk,
  addOneFollowUpKo,
  addOneFollowUpOk,
  addProjectImageOk,
  assignDocsToRowKo,
  assignDocsToRowOk,
  changeCreateModalVisible,
  changeEditDeleteModalVisible,
  changeStateKo,
  changeStateOk,
  confirmConfiguratorKo,
  confirmConfiguratorOk,
  createNewScheduleProjectKo,
  createNewScheduleProjectOk,
  createProjectKo,
  createProjectOk,
  deleteActorOk,
  deleteAgreementKo,
  deleteDocumentKo,
  deleteDocumentOk,
  deleteFileKo,
  deleteFileOk,
  deleteFollowUpKo,
  deleteFollowUpOk,
  deleteFollowupDocumentKo,
  deleteFollowupDocumentOk,
  deleteProjectImageOk,
  deleteProjectKo,
  deleteProjectOk,
  deleteRowTableKo,
  deleteRowTableOk,
  downloadDocumentKo,
  downloadDocumentOk,
  downloadFollowUpDocumentKo,
  downloadFollowUpDocumentOk,
  downloadFollowupDocumentKo,
  downloadFollowupDocumentOk,
  downloadKo,
  downloadOk,
  editFollowupKo,
  editFollowupOk,
  editProjectImageOk,
  editProjectKo,
  editProjectOk,
  editProjectScheduleItemOk,
  getActorsMetadataOk,
  getAreasSubAreasKo,
  getAreasSubAreasOk,
  getDirectoryDataFilterKo,
  getDirectoryDataFilterOk,
  getDocumentKo,
  getDocumentOk,
  getDocumentationOk,
  getFileKo,
  getFileOk,
  getGenericFieldsKo,
  getGenericFieldsOk,
  getListFollowUpsKo,
  getListFollowUpsOk,
  getOneFollowUpKo,
  getOneFollowUpOk,
  getOneProjectKo,
  getOneProjectOk,
  getProjectImagestOk,
  getProjectsKo,
  getProjectsOk,
  newDocumentKo,
  newDocumentOk,
  projectCancelAgreementKo,
  projectCancelAgreementOk,
  projectGenericKo,
  projectValidationErrors,
  removeDocsTableKo,
  removeDocsTableOk,
  removeFollowUpDocumentKo,
  removeFollowUpDocumentOk,
  removePaymentFileKo,
  removePaymentFileOk,
  sendActorOk,
  uploadAgreementKo,
  uploadFollowUpDocumentKo,
  uploadFollowUpDocumentOk,
  uploadGenericFieldKo,
  uploadGenericFieldOk,
  uploadPaymentFileKo,
  uploadPaymentFileOk,
  uploadScheduleDocumentKo,
  uploadScheduleDocumentOk,
} from './action';
import { ProjectConst } from './action_type';
import {
  AddDocumentAction,
  AddFollowUpAction,
  AddProjectImageAction,
  AssignDocsToRowAction,
  ConfirmConfiguratorAction,
  CreateNewScheduleProjectAction,
  CreateProjectAction,
  CreateProjectScheduleAction,
  DeleteActorAction,
  DeleteAgreementAction,
  DeleteFileAction,
  DeleteFollowUpAction,
  DeleteGenericFileAction,
  DeleteProjectAction,
  DeleteProjectImageAction,
  DeleteProjectScheduleAction,
  DeleteRowTableAction,
  DownloadDocumentFollowupAction,
  DownloadFileAction,
  DownloadFollowUpDocumentAction,
  DownloadGenericFileAction,
  EditFollowUpAction,
  EditProjectAction,
  EditProjectImageAction,
  EditProjectScheduleItemAction,
  GenericFieldUploadAction,
  GetActorsMetadataAction,
  GetAreasSubAreasAction,
  GetDirectoryDataFilterAction,
  GetFileAction,
  GetFollowUpAction,
  GetGenericFieldsAction,
  GetGenericFileAction,
  GetListFollowUpsAction,
  GetOneProjectAction,
  GetProjectImagesAction,
  GetProjectsAction,
  LinkPaymentAction,
  ProjectCancelAgreementAction,
  ProjectChangeStateAction,
  ProjectChangeStateKo,
  ProjectChangeStateOk,
  ProjectPaymentAction,
  RemoveDocsTableAction,
  RemoveFollowUpDocumentAction,
  RemoveFollowupDocumentAction,
  RemovePaymentFileAction,
  SaveCustomFieldsFormAction,
  SendActorAction,
  UpdateProjectCalendarAction,
  UpdateProjectScheduleAction,
  UploadAgreementAction,
  UploadDocumentationAction,
  UploadFollowUpDocumentAction,
  UploadPaymentFileAction,
  UploadScheduleDocumentAction,
} from './definitions';
import { selectGenericTableData } from './selectors';

function* getProjectsFromSaga(action: GetProjectsAction): Generator<any, void, any> {
  try {
    const {
      projects,
      resume: { states, subareas },
    } = yield retrieveProjects();
    yield put(getProjectsOk(projects, states, subareas));
  } catch (e) {
    yield put(getProjectsKo(errorMessage(e)));
  }
}

function* getOneProjectFromSaga(action: GetOneProjectAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const { data: projectData } = (yield getProjectById(id)) as Await<ReturnType<typeof getProjectById>>;
    if (projectData.forms_done === 0) {
      yield put(getOneProjectOk(projectData));
    } else {
      const { data: documentation } = yield getDocumentationApi(id);
      yield put(getOneProjectOk(projectData));
      yield put(getDocumentationOk(documentation));
    }
  } catch (e) {
    yield put(getOneProjectKo(errorMessage(e)));
  }
}

function* createProjectFormSaga(action: CreateProjectAction): Generator<any, void, any> {
  try {
    const result = (yield createProject(action.projectData)) as Await<ReturnType<typeof createProject>>;
    switch (result.type) {
      case 'ok':
        const { data: project } = result.value;
        const { data: documentation } = yield getDocumentationApi(project.id.toString());
        yield put(getDocumentationOk(documentation));
        yield put(createProjectOk(project));
        yield put(showSnackbar('Los datos se han guardado correctamente', 'success', undefined, 1500));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(projectValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(createProjectKo(errorMessage(e)));
  }
}

function* editProjectFormSaga(action: EditProjectAction): Generator<any, void, any> {
  try {
    const result = yield editProject(
      action.projectData,
      action.idProject,
    ); /* as Await<ReturnType<typeof editProject>>; */
    yield put(editProjectOk(result.value.data));
    yield put(showSnackbar('Los datos se han guardado correctamente', 'success'));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(editProjectKo(errorMessage(e), action.projectData));
  }
}

function* deleteProjectFromSaga(action: DeleteProjectAction): Generator<any, void, any> {
  try {
    const { id } = action;
    yield deleteProjectById(id);
    yield put(deleteProjectOk(id));
  } catch (e) {
    yield put(deleteProjectKo(errorMessage(e)));
  }
}

function* getAreasSubAreasFromSaga(): Generator<any, void, any> {
  try {
    const info = yield getAreasSubAreas();
    yield put(getAreasSubAreasOk(info));
  } catch (e) {
    yield put(getAreasSubAreasKo(errorMessage(e)));
  }
}

function* addDocumentFromSaga(action: AddDocumentAction): Generator<any, void, any> {
  try {
    const { description, idProject, file } = action;
    const { id } = yield agreeDocument(file);
    const { documents } = yield addDescriptionDocument(description, idProject, id);
    yield put(addDocumentOk(documents));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
    yield put(addDocumentKo(errorMessage(e)));
  }
}
function* deleteDocumentFromSaga(action: DeleteFileAction): Generator<any, void, any> {
  try {
    const { id, file_id } = action;
    const {
      data: { documents },
    } = yield deleteFile(id, file_id);
    yield put(deleteDocumentOk(documents));
    yield put(showSnackbar('El documento se ha eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('Ha ocurrido algún problema al eliminar el documento', 'error', undefined, 1500));
    yield put(deleteDocumentKo(errorMessage(e)));
  }
}

function* downloadDocumentFromSaga(action: DownloadFileAction): Generator<any, void, any> {
  try {
    const { id, file_id, name } = action;
    const info = yield downloadFile(id, file_id);
    yield put(downloadDocumentOk(info, name));
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al descargar el documento', 'error', undefined, 1500));
  }
}

function* getDocumentFromSaga(action: GetFileAction): Generator<any, void, any> {
  try {
    const { id, file_id } = action;
    const info = yield downloadFile(id, file_id);
    yield put(getDocumentOk(info));
  } catch (e) {
    yield put(getDocumentKo(errorMessage(e)));
  }
}

const projectsRedirect = (states: ProjectChangeStateOk | ProjectChangeStateKo) => {
  if (states === 'p13p14') {
    return undefined;
  }
  return '/proyectos';
};

function* changeStateFromSaga(action: ProjectChangeStateAction): Generator<any, void, any> {
  try {
    const { id, states } = action;
    const {
      data: { state_code, state_name },
    } = yield changeStateFromApi(id, states, action?.comments);
    yield put(changeStateOk(state_code, state_name));

    yield put(showSnackbar(`Operación realizada con exito`, 'success', projectsRedirect(states), 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(changeStateKo(errorMessage(e)));
  }
}

function* getFollowUpFromSaga(action: GetFollowUpAction): Generator<any, void, any> {
  try {
    const info = yield getOneFollowUp(action.id);
    yield put(getOneFollowUpOk(info));
  } catch (e) {
    yield put(getOneFollowUpKo(errorMessage(e)));
  }
}

function* addFollowUpFromSaga(action: AddFollowUpAction): Generator<any, void, any> {
  try {
    const info = yield addOneFollowUp(action.followUpData);
    yield put(addOneFollowUpOk(info));
    yield put(showSnackbar('Nota añadida correctamente', 'success', undefined, 1000));
    yield put(changeCreateModalVisible(false));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(addOneFollowUpKo(errorMessage(e)));
  }
}

function* getFollowUpsListFromSaga(action: GetListFollowUpsAction): Generator<any, void, any> {
  try {
    const info = yield getListFollowUps(action.projectId);
    yield put(getListFollowUpsOk(info));
  } catch (e) {
    yield put(getListFollowUpsKo(errorMessage(e)));
  }
}

function* editFollowUpFromSaga(action: EditFollowUpAction): Generator<any, void, any> {
  try {
    const info = yield editFollowup(action.followUpData, action.id);
    yield put(editFollowupOk(info));
    yield put(showSnackbar('Nota editada correctamente', 'success', undefined, 1000));
    yield put(changeEditDeleteModalVisible(false));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(editFollowupKo(errorMessage(e)));
  }
}

function* deleteFollowUpFromSaga(action: DeleteFollowUpAction): Generator<any, void, any> {
  try {
    yield deleteFollowUp(action.id);
    yield put(deleteFollowUpOk(action.id));
    yield put(changeEditDeleteModalVisible(false));
    yield put(showSnackbar('Nota borrada correctamente', 'success', undefined, 1000));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(deleteFollowUpKo(errorMessage(e)));
  }
}

function* newDocumentFromSaga(action: UploadDocumentationAction): Generator<any, void, any> {
  try {
    const { file, name, actor } = action;
    const { data } = yield uploadNewDocument(file, actor); /* as Await<ReturnType<typeof object>>; */
    yield put(newDocumentOk(data, name));
    yield put(showSnackbar('Documento/s adjuntados correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('No se ha podido crear el documento', 'error', undefined, 1500));
    yield put(newDocumentKo(errorMessage(e)));
  }
}

function* uploadAgreementDocumentation(action: UploadAgreementAction): Generator<any, void, any> {
  try {
    const { documentation_id, data, project_id } = action;
    yield uploadAgreement(documentation_id, data, project_id);
    const { data: documentation } = yield getDocumentationApi(project_id.toString());
    yield put(showSnackbar('Documento/s adjuntados correctamente', 'success', undefined, 1500));
    yield put(getDocumentationOk(documentation));
  } catch (e) {
    yield put(
      showSnackbar(
        'Error en la creación, asegúrate de que el nombre coincida con el del convenio',
        'error',
        undefined,
        2000,
      ),
    );
    yield put(uploadAgreementKo(errorMessage(e)));
  }
}

function* deleteAgreementDocumentation(action: DeleteAgreementAction): Generator<any, void, any> {
  try {
    const { documentation_id, project_id } = action;
    yield deleteAgreement(documentation_id, project_id);
    const { data: documentation } = yield getDocumentationApi(project_id.toString());
    yield put(showSnackbar('Documento eliminado correctamente', 'success', undefined, 1500));
    yield put(getDocumentationOk(documentation));
  } catch (e) {
    yield put(showSnackbar('No se ha podido eliminar el documento', 'error', undefined, 1500));
    yield put(deleteAgreementKo(errorMessage(e)));
  }
}

function* downloadDocumentFollowupFromSaga(action: DownloadDocumentFollowupAction): Generator<any, void, any> {
  try {
    const { project_id, name, file_id, typeTable } = action;
    if (typeTable) {
      const data = yield downloadDocument(file_id);
      yield put(downloadFollowupDocumentOk(data, name));
    } else {
      const data = yield downloadFollowUpDocument(project_id, file_id);
      yield put(downloadFollowupDocumentOk(data, name));
    }
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('No se ha podido descargar el documento', 'error', undefined, 1500));
    yield put(downloadFollowupDocumentKo(errorMessage(e)));
  }
}

function* deleteFollowupDocumentFromSaga(action: RemoveFollowupDocumentAction): Generator<any, void, any> {
  try {
    const { project_id, file_id } = action;
    yield removeFollowupDocument(project_id, file_id);
    yield put(deleteFollowupDocumentOk(file_id));
    yield put(showSnackbar('Documento eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('No se ha podido eliminar el documento', 'error', undefined, 1500));
    yield put(deleteFollowupDocumentKo(errorMessage(e)));
  }
}

function* confirmConfiguratorFromSaga(action: ConfirmConfiguratorAction): Generator<any, void, any> {
  try {
    const { id_project, section, data } = action;
    yield sendConfiguratorFieldsApi(id_project, section, data);
    const result = (yield confirmConfiguratorApi(id_project)) as Await<ReturnType<typeof confirmConfiguratorApi>>;
    switch (result.type) {
      case 'ok':
        yield put(confirmConfiguratorOk());
        yield put(showSnackbar('Se ha confirmado correctamente', 'success', undefined, 1000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(projectValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(confirmConfiguratorKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 4000));
  }
}

function* saveCustomFieldsFormSaga(action: SaveCustomFieldsFormAction): Generator<any, void, any> {
  try {
    const tableData = yield select(selectGenericTableData);
    const { data } = (yield setCustomFieldsForm(
      action.project_id,
      action.project_schedule_id,
      action.section,
      action.fields,
      tableData.rows,
    )) as Await<ReturnType<typeof setCustomFieldsForm>>;

    yield put(getOneProjectOk(data));
    yield put(showSnackbar('Se han guardado los cambios correctamente', 'success'));
  } catch (e) {
    yield put(showSnackbar('No se han podido guardar los datos', 'error'));
  }
}

function* assignDocFromSaga(action: AssignDocsToRowAction): Generator<any, void, any> {
  try {
    const info = yield assignDocsTable(action.tableDocsData);
    yield put(assignDocsToRowOk(info, action.tableDocsData));
    yield put(showSnackbar('Archivos Adjuntas correctamente', 'success', undefined, 1000));
    yield put(changeCreateModalVisible(false));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(assignDocsToRowKo(errorMessage(e)));
  }
}

function* deleteDocFromSaga(action: RemoveDocsTableAction): Generator<any, void, any> {
  try {
    const info = yield deleteDocsTable(action.tableDocsData);
    yield put(removeDocsTableOk(info, action.tableDocsData));
    yield put(showSnackbar('Archivo Eliminado Correctamente', 'success', undefined, 1000));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(removeDocsTableKo(errorMessage(e)));
  }
}

function* deleteRowFromSaga(action: DeleteRowTableAction): Generator<any, void, any> {
  try {
    const info = yield deleteRowTable(action.deleteData);
    yield put(deleteRowTableOk(info, action.deleteData.id_generated, action.deleteData.id_row));
    yield put(showSnackbar('Campo Eliminado Correctamente', 'success', undefined, 1000));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(deleteRowTableKo(errorMessage(e)));
  }
}

function* addDocumentFieldFromSaga(action: GenericFieldUploadAction): Generator<any, void, any> {
  try {
    const data = yield addDocumentFieldFromApi(action.uploadData);
    yield put(uploadGenericFieldOk(data, action.uploadData.fieldName));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
    yield put(uploadGenericFieldKo(errorMessage(e)));
  }
}

function* downloadFieldDocumentFromSaga(action: DownloadGenericFileAction): Generator<any, void, any> {
  try {
    const { file_id, name } = action;
    const info = yield downloadOriginal(file_id);
    yield put(downloadOk(info, name));
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al descargar el documento', 'error', undefined, 1500));
  }
}

function* getFieldDocumentFromSaga(action: GetGenericFileAction): Generator<any, void, any> {
  try {
    const { file_id } = action;
    const info = yield downloadOriginal(file_id);
    yield put(getFileOk(info));
  } catch (e) {
    yield put(getFileKo(errorMessage(e)));
  }
}

function* deleteFieldDocumentFromSaga(action: DeleteGenericFileAction): Generator<any, void, any> {
  try {
    const { data } = action;
    yield deleteFileFromApi(data);
    yield put(deleteFileOk(data));
    yield put(showSnackbar('El documento se ha borrado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(deleteFileKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al borrar el documento', 'error', undefined, 1500));
  }
}

function* getFieldsData(action: GetGenericFieldsAction): Generator<any, void, any> {
  try {
    const { project_id, project_schedule_id, section } = action;
    const { data } = (yield getCustomFieldsForm(project_id, project_schedule_id, section)) as Await<
      ReturnType<typeof getCustomFieldsForm>
    >;
    yield put(getGenericFieldsOk(data));
  } catch (e) {
    yield put(getGenericFieldsKo(errorMessage(e)));
  }
}

function* getDirectoryFilterData(action: GetDirectoryDataFilterAction): Generator<any, void, any> {
  try {
    const info = yield getDirectoryFilterDataFromApi(action.filterData);
    yield put(getDirectoryDataFilterOk(info));
  } catch (e) {
    yield put(getDirectoryDataFilterKo(errorMessage(e)));
  }
}

function* getActorsMetadata(action: GetActorsMetadataAction): Generator<any, void, any> {
  try {
    const info = yield getActorsMetadataFromApi(action.project_id);
    yield put(getActorsMetadataOk(info));
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* sendActorSaga(action: SendActorAction): Generator<any, void, any> {
  try {
    const info = yield sendActorFromApi(action.project_id, action.user_id);
    yield put(sendActorOk(info));
    yield put(showSnackbar('Usuario Añadido Correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* deleteActorSaga(action: DeleteActorAction): Generator<any, void, any> {
  try {
    const info = yield deleteActorFromApi(action.project_id, action.user_id);
    yield put(deleteActorOk(info));
    yield put(showSnackbar('Usuario Eliminado Correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* createProjectScheduleSaga(action: CreateProjectScheduleAction): Generator<any, void, any> {
  try {
    const { schedule } = action;
    const result = (yield addProjectSchedule(schedule)) as Await<ReturnType<typeof addProjectSchedule>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        if (data === 'OK') {
          const { data: projectData } = yield getProjectById(schedule.project_id.toString());
          yield put(getOneProjectOk(projectData));
        } else {
          yield put(showSnackbar('No se ha podido crear el seguimiento', 'error', undefined, 1500));
        }
        return;
      case 'validation-error':
        const error = result.value;
        yield put(projectValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* updateProjectScheduleSaga(action: UpdateProjectScheduleAction): Generator<any, void, any> {
  try {
    const { schedule } = action;
    const result = (yield updateProjectSchedule(schedule)) as Await<ReturnType<typeof updateProjectSchedule>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        if (data === 'OK') {
          const { data: projectData } = yield getProjectById(schedule.project_id.toString());
          yield put(getOneProjectOk(projectData));
        } else {
          yield put(showSnackbar('No se ha podido actualizar el seguimiento', 'error', undefined, 1500));
        }
        return;
      case 'validation-error':
        const error = result.value;
        yield put(projectValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* deleteProjectScheduleSaga(action: DeleteProjectScheduleAction): Generator<any, void, any> {
  try {
    const ok = yield deleteProjectSchedule(action.id);
    if (ok) {
      const { data: projectData } = yield getProjectById(action.project_id.toString());
      yield put(getOneProjectOk(projectData));
    } else {
      yield put(showSnackbar('No se ha podido eliminar el seguimiento', 'error', undefined, 1500));
    }
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* updateProjectSchedulesSaga(action: UpdateProjectCalendarAction): Generator<any, void, any> {
  try {
    const ok = yield updateProjectSchedules(action.schedules);
    if (ok) {
      const { data: projectData } = yield getProjectById(action.project_id.toString());
      yield put(getOneProjectOk(projectData));
    } else {
      yield put(showSnackbar('No se han podido actualizar los seguimientos', 'error', undefined, 1500));
    }
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* getProjectTrackingTemplatesSaga(action: EditProjectScheduleItemAction): Generator<any, void, any> {
  try {
    const tracking = yield getTrackingsByAreaCode(action.code);
    yield put(editProjectScheduleItemOk(tracking));
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
  }
}

function* uploadFollowUpDocumentFromSaga(action: UploadFollowUpDocumentAction): Generator<any, void, any> {
  try {
    const { file } = action;
    const fileData = yield uploadPhoneFollowUpDocument(file);
    yield put(uploadFollowUpDocumentOk(fileData));
    yield put(showSnackbar(tKey('Documento subido correctamente'), 'success'));
  } catch (e) {
    yield put(uploadFollowUpDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el archivo', 'error'));
  }
}

function* removeFollowUpDocumentFromSaga(action: RemoveFollowUpDocumentAction): Generator<any, void, any> {
  try {
    const { file_id } = action;
    yield deletePhoneFollowUpDocument(file_id);
    yield put(removeFollowUpDocumentOk());
  } catch (e) {
    yield put(removeFollowUpDocumentKo(errorMessage(e)));
    yield put(showSnackbar(tKey('Ha habido un error al borrar el archivo'), 'error'));
  }
}

function* downloadFollowUpDocumentFromSaga(action: DownloadFollowUpDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, filename } = action;
    const fileData = yield downloadPhoneFollowUpDocument(file_id);
    yield put(downloadFollowUpDocumentOk(fileData, filename));
  } catch (e) {
    yield put(downloadFollowUpDocumentKo(errorMessage(e)));
    yield put(showSnackbar(tKey('Ha habido un error al descargar el archivo'), 'error'));
  }
}

function* uploadNewScheduleDocumentFromSaga(action: UploadScheduleDocumentAction): Generator<any, void, any> {
  try {
    const { file, code } = action;
    const fileInfo = yield uploadAnnexDocumentByCode(file, code);
    yield put(uploadScheduleDocumentOk(fileInfo));
    yield put(showSnackbar('Documento subido correctamente', 'success'));
  } catch (e) {
    yield put(uploadScheduleDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el archivo', 'error'));
  }
}

function* createNewScheduleProjectFromSaga(action: CreateNewScheduleProjectAction): Generator<any, void, any> {
  try {
    const result = (yield updateProjectFinalDate(action.newScheduleData)) as Await<
      ReturnType<typeof updateProjectFinalDate>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(createNewScheduleProjectOk(data));
        yield put(showSnackbar('Contrato guardado correctamente', 'success', undefined, 3000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(projectValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(createNewScheduleProjectKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* projectPaymentFromSaga(action: ProjectPaymentAction): Generator<any, void, any> {
  try {
    const { project_schedule_id, project_id, account_number } = action;
    const projectData = yield makePaymentProject(project_id, project_schedule_id, account_number);
    yield put(getOneProjectOk(projectData));
    yield put(showSnackbar(tProjectKey('Pago realizado'), 'success'));
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* uploadPaymentFileFromSaga(action: UploadPaymentFileAction): Generator<any, void, any> {
  try {
    const { file } = action;
    const fileData = yield uploadPaymentFileFromApi(file);
    yield put(uploadPaymentFileOk(fileData));
  } catch (e) {
    yield put(uploadPaymentFileKo(errorMessage(e)));
  }
}

function* removePaymentFileFromSaga(action: RemovePaymentFileAction): Generator<any, void, any> {
  try {
    yield removePaymentFileFromApi(action.fileId);
    yield put(removePaymentFileOk());
  } catch (e) {
    yield put(removePaymentFileKo(errorMessage(e)));
  }
}

function* linkPaymentFromSaga(action: LinkPaymentAction): Generator<any, void, any> {
  try {
    const { project_id, project_schedule_id, invoice_id, invoice_file_id } = action;
    const projectData = yield linkPaymentProjectFromApi(project_id, project_schedule_id, invoice_id, invoice_file_id);
    yield put(getOneProjectOk(projectData));
    yield put(showSnackbar(tProjectKey('Factura vinculada'), 'success'));
  } catch (e) {
    yield put(projectGenericKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* getProjectImagesFromSaga(action: GetProjectImagesAction): Generator<any, void, any> {
  try {
    const projectImages = yield getProjectImagesFromApi(action.projectId);
    yield put(getProjectImagestOk(projectImages));
  } catch (e) {
    showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500);
    yield put(ProjectImagestKo((e as Error).message));
  }
}

function* addProjectImageFromSaga(action: AddProjectImageAction): Generator<any, void, any> {
  try {
    const imageUploaded = yield uploadImageFileFromApi(action.image, action.name);
    const projectImage = yield addProjectImageFromApi(
      action.projectId,
      action.name,
      action.description,
      imageUploaded.id,
      action.userId,
    );
    const image = {
      id: projectImage.id,
      name: projectImage.name,
      description: projectImage.description,
      image_id: projectImage.image_id,
      image: action.image,
      user_id: projectImage.user_id,
    } as ResourceImageData;
    yield put(addProjectImageOk(image));
  } catch (e) {
    showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500);
    yield put(ProjectImagestKo(errorMessage(e)));
  }
}

function* editProjectImageFromSaga(action: EditProjectImageAction): Generator<any, void, any> {
  try {
    let imageId = 0;
    if (action.projectImage.image !== action.newImage)
      imageId = (yield uploadImageFileFromApi(action.newImage, action.name)).id;

    const projectImage = yield editProjectImageFromApi(
      action.projectId,
      action.projectImage.id,
      action.name,
      action.description,
      action.projectImage.image_id, // previous image id
      imageId,
      action.userId,
    );
    const image = {
      id: projectImage.id,
      name: projectImage.name,
      description: projectImage.description,
      image_id: projectImage.image_id,
      image: action.newImage,
      user_id: projectImage.user_id,
    } as ResourceImageData;
    yield put(editProjectImageOk(image));
  } catch (e) {
    showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500);
    yield put(ProjectImagestKo((e as Error).message));
  }
}

function* deleteProjectImageFromSaga(action: DeleteProjectImageAction): Generator<any, void, any> {
  try {
    yield deleteProjectImageByImageIdFromApi(action.projectImage.image_id);
    yield put(deleteProjectImageOk(action.projectImage.id));
  } catch (e) {
    showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500);
    yield put(ProjectImagestKo((e as Error).message));
  }
}

function* projectCancelAgreementFromSaga(action: ProjectCancelAgreementAction): Generator<any, void, any> {
  try {
    const { project_id } = action;
    const { state_code, state_name } = yield cancelProjectAgreementFromApi(project_id);
    yield put(projectCancelAgreementOk(state_code, state_name));
    yield put(showSnackbar(`Convenio cancelado`, 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(projectCancelAgreementKo(errorMessage(e)));
  }
}

export default [
  takeLatest<GetProjectsAction>(ProjectConst.GET_PROJECTS, getProjectsFromSaga),
  takeLatest<GetOneProjectAction>(ProjectConst.GET_ONE_PROJECT, getOneProjectFromSaga),
  takeLatest<CreateProjectAction>(ProjectConst.CREATE_PROJECT, createProjectFormSaga),
  takeLatest<EditProjectAction>(ProjectConst.EDIT_PROJECT, editProjectFormSaga),
  takeLatest<DeleteProjectAction>(ProjectConst.DELETE_PROJECT, deleteProjectFromSaga),
  takeLatest<GetAreasSubAreasAction>(ProjectConst.GET_AREAS_SUBAREAS, getAreasSubAreasFromSaga),
  takeLatest<AddDocumentAction>(ProjectConst.ADD_DOCUMENT, addDocumentFromSaga),
  takeLatest<DeleteFileAction>(ProjectConst.DELETE_DOCUMENT, deleteDocumentFromSaga),
  takeLatest<GetFileAction>(ProjectConst.GET_DOCUMENT, getDocumentFromSaga),
  takeLatest<ProjectChangeStateAction>(ProjectConst.PROJECT_CHANGE_STATE, changeStateFromSaga),
  takeLatest<DownloadFileAction>(ProjectConst.DOWNLOAD_DOCUMENT, downloadDocumentFromSaga),
  takeLatest<GetFollowUpAction>(ProjectConst.GET_FOLLOWUP, getFollowUpFromSaga),
  takeLatest<AddFollowUpAction>(ProjectConst.ADD_FOLLOWUP, addFollowUpFromSaga),
  takeLatest<GetListFollowUpsAction>(ProjectConst.GET_LIST_FOLLOWUPS, getFollowUpsListFromSaga),
  takeLatest<EditFollowUpAction>(ProjectConst.EDIT_FOLLOWUP, editFollowUpFromSaga),
  takeLatest<DeleteFollowUpAction>(ProjectConst.DELETE_FOLLOWUP, deleteFollowUpFromSaga),
  takeLatest<DownloadDocumentFollowupAction>(ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT, downloadDocumentFollowupFromSaga),
  takeLatest<RemoveFollowupDocumentAction>(ProjectConst.DELETE_FOLLOWUP_DOCUMENT, deleteFollowupDocumentFromSaga),
  takeEvery<UploadDocumentationAction>(ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT, newDocumentFromSaga),
  takeLatest<UploadAgreementAction>(ProjectConst.UPLOAD_AGREEMENT, uploadAgreementDocumentation),
  takeLatest<DeleteAgreementAction>(ProjectConst.DELETE_AGREEMENT, deleteAgreementDocumentation),
  takeLatest<ConfirmConfiguratorAction>(ProjectConst.CONFIRM_CONFIGURATOR, confirmConfiguratorFromSaga),
  takeLatest<SaveCustomFieldsFormAction>(ProjectConst.SAVE_CUSTOM_FIELDS_FORM, saveCustomFieldsFormSaga),
  takeLatest<AssignDocsToRowAction>(ProjectConst.ASSIGN_DOCS_TO_ROW, assignDocFromSaga),
  takeLatest<RemoveDocsTableAction>(ProjectConst.DELETE_DOCS_ROW, deleteDocFromSaga),
  takeLatest<DeleteRowTableAction>(ProjectConst.DELETE_ROW_TABLE, deleteRowFromSaga),
  takeLatest<GenericFieldUploadAction>(ProjectConst.GENERIC_FORM_UPLOAD_FIELD, addDocumentFieldFromSaga),
  takeLatest<DownloadGenericFileAction>(ProjectConst.GENERIC_FORM_DOWNLOAD_FILE, downloadFieldDocumentFromSaga),
  takeLatest<GetGenericFileAction>(ProjectConst.GENERIC_FORM_GET_FILE, getFieldDocumentFromSaga),
  takeLatest<DeleteGenericFileAction>(ProjectConst.GENERIC_FORM_DELETE_FILE, deleteFieldDocumentFromSaga),
  takeLatest<GetGenericFieldsAction>(ProjectConst.GENERIC_FORM_GET_FIELDS, getFieldsData),
  takeLatest<GetDirectoryDataFilterAction>(ProjectConst.GET_DIRECTORY_DATA_FILTER, getDirectoryFilterData),
  takeLatest<GetActorsMetadataAction>(ProjectConst.GET_ACTORS_METADATA, getActorsMetadata),
  takeLatest<SendActorAction>(ProjectConst.SEND_ACTOR, sendActorSaga),
  takeLatest<DeleteActorAction>(ProjectConst.DELETE_ACTOR, deleteActorSaga),
  takeLatest<CreateProjectScheduleAction>(ProjectConst.CREATE_PROJECT_SCHEDULE, createProjectScheduleSaga),
  takeLatest<UpdateProjectScheduleAction>(ProjectConst.UPDATE_PROJECT_SCHEDULE, updateProjectScheduleSaga),
  takeLatest<DeleteProjectScheduleAction>(ProjectConst.DELETE_PROJECT_SCHEDULE, deleteProjectScheduleSaga),
  takeLatest<UpdateProjectCalendarAction>(ProjectConst.UPDATE_PROJECT_CALENDAR, updateProjectSchedulesSaga),
  takeLatest<EditProjectScheduleItemAction>(ProjectConst.EDIT_PROJECT_SCHEDULE_ITEM, getProjectTrackingTemplatesSaga),
  takeLatest<UploadFollowUpDocumentAction>(ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT, uploadFollowUpDocumentFromSaga),
  takeLatest<RemoveFollowUpDocumentAction>(ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT, removeFollowUpDocumentFromSaga),
  takeLatest<DownloadFollowUpDocumentAction>(ProjectConst.DOWNLOAD_FOLLOW_UP, downloadFollowUpDocumentFromSaga),
  takeLatest<UploadScheduleDocumentAction>(ProjectConst.UPLOAD_SCHEDULE_DOCUMENT, uploadNewScheduleDocumentFromSaga),
  takeLatest<CreateNewScheduleProjectAction>(ProjectConst.CREATE_NEW_SCHEDULE, createNewScheduleProjectFromSaga),
  takeLatest<ProjectPaymentAction>(ProjectConst.PROJECT_PAYMENT, projectPaymentFromSaga),
  takeLatest<UploadPaymentFileAction>(ProjectConst.UPLOAD_PAYMENT_FILE, uploadPaymentFileFromSaga),
  takeLatest<RemovePaymentFileAction>(ProjectConst.REMOVE_PAYMENT_FILE, removePaymentFileFromSaga),
  takeLatest<LinkPaymentAction>(ProjectConst.LINK_PAYMENT, linkPaymentFromSaga),
  takeLatest<GetProjectImagesAction>(ProjectConst.GET_PROJECT_IMAGES, getProjectImagesFromSaga),
  takeLatest<AddProjectImageAction>(ProjectConst.ADD_PROJECT_IMAGE, addProjectImageFromSaga),
  takeLatest<EditProjectImageAction>(ProjectConst.EDIT_PROJECT_IMAGE, editProjectImageFromSaga),
  takeLatest<DeleteProjectImageAction>(ProjectConst.DELETE_PROJECT_IMAGE, deleteProjectImageFromSaga),
  takeLatest<ProjectCancelAgreementAction>(ProjectConst.PROJECT_CANCEL_AGREEMENT, projectCancelAgreementFromSaga),
];
