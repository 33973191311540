import { apiBaseUrl, apiCallBlob, FetchOptions } from '../api/api';
import { AboutUsMetada, AboutUsResource, AboutUsTypesWithResources } from '../redux/aboutUs/definitions';
import { DocumentationItem } from '../redux/common/definitions';
import { apiCall } from './api';

const baseURL = apiBaseUrl;

interface DocumentsData {
  documents: DocumentationItem[];
}

interface DocumentsResponse {
  data: DocumentsData;
}

export const getAboutUsMetada = async (): Promise<{ data: AboutUsMetada }> => {
  const init: FetchOptions = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: AboutUsMetada }>(`${baseURL}/about_us/metadata`, init);
};

export const getResourcesByCode = async (code: string): Promise<{ data: AboutUsResource[] }> => {
  const init: FetchOptions = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: AboutUsResource[] }>(`${baseURL}/about_us/type/${code}`, init);
};

export const getResourcesView = async (): Promise<{ data: AboutUsTypesWithResources[] }> => {
  const init: FetchOptions = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: AboutUsTypesWithResources[] }>(`${baseURL}/about_us/view`, init);
};

export const getOneResourcesById = async (id: number): Promise<{ data: AboutUsResource }> => {
  const init: FetchOptions = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: AboutUsResource }>(`${baseURL}/about_us/resource/${id}`, init);
};

export const uploadNewDocument = async (file: File): Promise<{ data: {} }> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name || 'asdf');
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const data = await apiCall<any>(`${baseURL}/about_us/document`, init);
  return data;
};

export const downloadDocument = async (resource_id: number, file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/about_us/document/download/${resource_id}/${file_id}`, init);
};

export const downloadDocumentByCode = async (code: string): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/about_us/document/${code}`, init);
};

export const removeDocument = async (resource_id: number, file_id: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/about_us/document/${resource_id}/${file_id}`, init);
  return data;
};

export const removeDocumentById = async (file_id: number): Promise<{ data: {} }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  return await apiCall<DocumentsResponse>(`${baseURL}/file/${file_id}`, init);
};

export const saveDataResource = async (resource: AboutUsResource): Promise<{ data: AboutUsResource }> => {
  const init: FetchOptions = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(resource),
  };

  return apiCall<{ data: AboutUsResource }>(`${baseURL}/about_us/${resource.id}`, init);
};

export const newDataResource = async (resource: AboutUsResource): Promise<{ data: AboutUsResource }> => {
  const init: FetchOptions = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(resource),
  };

  return apiCall<{ data: AboutUsResource }>(`${baseURL}/about_us`, init);
};
