import { InsertLink, Payment } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getProjectPayment, getProjectsFromApi } from '../../../api/Projects/projects';
import Layout from '../../../components/Layout/Layout';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { formatNumber } from '../../../helpers/formatNumber';
import { tFormKey, tKey, tScholarshipKey } from '../../../helpers/translate';
import { ProjectListItem } from '../../../redux/accounting/definitions';
import { projectPayment, setPaymentData } from '../../../redux/project/action';
import { PaymentInfo, ProjectDispatch } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import LinkPaymentModal from '../../Project/Modal/PaymentModal/LinkPaymentModal';
import PaymentModal from '../../Project/Modal/PaymentModal/PaymentModal';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './ProjectPayments.module.scss';

interface ProjectPaymentsProps extends RouteComponentProps<{ id: string }> {
  paymentData: PaymentInfo;
  projectPay: (project_id: number, project_schedule_id: number, numberAccount: string) => void;
  setPaymentData: (data: PaymentInfo) => void;
}

const ProjectPayments: React.FC<ProjectPaymentsProps> = ({ paymentData, projectPay, setPaymentData }) => {
  const [projectsData, setProjectsData] = useState<ProjectListItem[]>([]);
  const [viewPayment, setViewPayment] = useState(false);
  const [viewLink, setViewLink] = useState(false);
  const [projectSelected, setProjectSelected] = useState<ProjectListItem>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();

  const goToEditProject = (rowData: ProjectListItem) => {
    const { project_id } = rowData;
    history.push({
      pathname: `/proyecto/detalle/${project_id}/seguimiento_pago`,
      state: { project_id },
    });
  };

  const handlePaymentModal = async (project: ProjectListItem) => {
    const { data } = await getProjectPayment(project.project_id);
    setPaymentData(data);
    setProjectSelected(project);
    setViewPayment(true);
  };

  const handleLinkModal = async (project: ProjectListItem) => {
    const { data } = await getProjectPayment(project.project_id);
    setPaymentData(data);
    setProjectSelected(project);
    setViewLink(true);
  };
  /**
   *  eliminamos el proyecto pagado de la lista
   * @param id
   */
  const deleteProjectFromList = (id: number) => {
    const projects = projectsData.filter((project: ProjectListItem) => project.project_id !== id) as ProjectListItem[];
    setProjectsData(projects);
  };

  const makePaymentProject = async (data: any) => {
    const { id, payment_field_id, account_number } = data;
    projectPay(id, payment_field_id, account_number);
    deleteProjectFromList(id);
  };

  const makeLinkPayment = (paymentData: PaymentInfo) => {
    const { id } = paymentData;
    deleteProjectFromList(id);
  };

  useEffect(() => {
    (async () => {
      setProjectsData(await getProjectsFromApi());
      setLoading(false);
    })();
  }, []);

  const tableIcons = {
    Pay: () => <Payment style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    PayDisabled: () => <Payment style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
    InsertLink: () => <InsertLink style={{ color: 'rgba(0, 0, 0, 0.8)' }} />,
    InsertLinkDisabled: () => <InsertLink style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  };

  const tableActions = [
    (rowData: ProjectListItem) => ({
      icon: rowData.check_date ? tableIcons.PayDisabled : tableIcons.Pay,
      tooltip: tFormKey('Pagar'),
      onClick: () => (!rowData.check_date ? handlePaymentModal(rowData) : null),
    }),
    (rowData: ProjectListItem) => ({
      icon: rowData.check_date ? tableIcons.InsertLinkDisabled : tableIcons.InsertLink,
      tooltip: tScholarshipKey('Vincular factura'),
      onClick: () => (!rowData.check_date ? handleLinkModal(rowData) : null),
    }),
  ];

  const tableData: MaterialTableProps<ProjectListItem> = {
    title: tKey('Proyectos'),
    actions: tableActions,
    columns: [
      {
        field: 'project_name',
        title: tKey('Proyecto'),
        //@ts-ignore
        width: '250px',
        render: (rowData: ProjectListItem) => (
          <div className={rowData.check_date ? styles.font_red : ''}>{rowData.project_name}</div>
        ),
      },
      {
        field: 'entity_name',
        title: tKey('Entidad'),
        //@ts-ignore
        width: '200px',
        render: (rowData: ProjectListItem) => (
          <div className={rowData.check_date ? styles.font_red : ''}>{rowData.entity_name}</div>
        ),
      },
      {
        field: 'payment_date',
        title: tKey('Fecha'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        //@ts-ignore
        width: '120px',
        render: (rowData: ProjectListItem) => (
          <div className={rowData.check_date ? styles.font_red : ''}>{rowData.payment_date}</div>
        ),
      },
      {
        field: 'payment_amount',
        title: tKey('Importe'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        type: 'numeric',
        render: (rowData: ProjectListItem) => (
          <div className={rowData.check_date ? styles.font_red : ''}>
            {formatNumber({ value: rowData.payment_amount }) + ' €'}
          </div>
        ),
        //@ts-ignore
        width: '100px',
      },
    ],
    data: projectsData,
    options: {
      tableLayout: 'fixed',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
  };
  tableData.isLoading = loading;
  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="project" submenu={tKey('Pagos')} />}
      rightSubmenu={<ActionsMenu goBack />}
    >
      <TableWithEdit
        onRowClick={goToEditProject}
        tableData={{ ...tableData }}
        filtering
        key={JSON.stringify(tableData.data)}
        permission={true}
      />
      <PaymentModal
        inputsData={paymentData}
        view={viewPayment}
        onClose={() => setViewPayment(false)}
        onSubmit={makePaymentProject}
      />
      <LinkPaymentModal
        entity={projectSelected?.entity_name || ''}
        inputsData={paymentData}
        view={viewLink}
        onSubmit={makeLinkPayment}
        onClose={() => setViewLink(false)}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  paymentData: state.projectReducer.paymentData,
});

const mapDispatchToProps = (dispatch: ProjectDispatch | any) => ({
  projectPay: (project_id: number, project_schedule_id: number, numberAccount: string) =>
    dispatch(projectPayment(project_id, project_schedule_id, numberAccount)),
  setPaymentData: (data: PaymentInfo) => dispatch(setPaymentData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPayments);
