import { Assistance } from '../participants/Participant';
import { ParticipantsAssistanceConst } from './action_types';
import { Action } from 'redux';

export interface GetParticipantsAssistanceAction extends Action {
  type: ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE;
  followUpId: number;
}

export interface GetParticipantsAssistanceKoAction extends Action {
  type: ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE_KO;
  error: string;
}

export interface GetParticipantsAssistanceOkAction extends Action {
  type: ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE_OK;
  assistance: Assistance[];
}

export interface UpdateParticipantsAssistanceAction extends Action {
  type: ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE;
  infoUpdate: Assistance;
}

export interface UpdateParticipantsAssistanceKoAction extends Action {
  type: ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE_KO;
  error: string;
}

export interface UpdateParticipantsAssistanceOkAction extends Action {
  type: ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE_OK;
  infoUpdate: Assistance;
}

export type ParticipantsAssistanceDataAction =
  | GetParticipantsAssistanceAction
  | GetParticipantsAssistanceOkAction
  | GetParticipantsAssistanceKoAction
  | UpdateParticipantsAssistanceAction
  | UpdateParticipantsAssistanceOkAction
  | UpdateParticipantsAssistanceKoAction;

export const initialState = {
  loading: false,
  error: null as string | null,
  assistance: [] as Assistance[],
};
export type ParticipantsAssistanceState = typeof initialState;
