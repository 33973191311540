import assertUnreachable from '../../../helpers/assertUnreachable';
import { ScholarshipDataState } from '../definitions';
import { ScholarshipChangeStateDataConsts } from './action_types';
import { ScholarshipChangeStateDataAction } from './definitions';

const scholarshipChangeStateReducer = (
  state: ScholarshipDataState,
  action: ScholarshipChangeStateDataAction,
): ScholarshipDataState => {
  switch (action.type) {
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_GET_ALLOWED_STATES:
      return {
        ...state,
        scholarshipAllowedStates: [],
        error: null,
      };
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_GET_ALLOWED_STATES_OK:
      return {
        ...state,
        scholarshipAllowedStates: action.allowedStates,
        error: null,
      };
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_CHANGE_STATE:
      return {
        ...state,
        error: null,
      };
    case ScholarshipChangeStateDataConsts.SCHOLARSHIP_CHANGE_STATES_KO:
      return {
        ...state,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default scholarshipChangeStateReducer;
