import { ButtonProps } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../api/api';
import LayoutForm from '../../components/Layout/LayoutForm';
import ActionsMenu from '../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import MenuImageRound from '../../components/MenuImageRound/MenuImageRound';
import Title from '../../components/Title/Title';
import FncAddDocuments from '../../components/UploadFile/FncAddDocuments';
import * as DateConstants from '../../constants/date';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../helpers/translate';
import { setUserProfileAvatar } from '../../redux/auth/actions';
import { AuthState } from '../../redux/auth/reducers';
import { selectAuth, selectUserAvatar, selectUserLang } from '../../redux/auth/selectors';
import { DocumentationItem, IdCodeNamePair } from '../../redux/common/definitions';
import { GenericMetadata } from '../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../redux/metadata/selectors';
import { AppState } from '../../redux/root-reducer';
import {
  downloadDocument,
  getDocument,
  getProfile,
  getUserDocumentation,
  removeDocument,
  updateProfile,
  uploadDocument,
} from '../../redux/user/actions';
import { UpdateUserFormFields, UserData, UserDataDispatch } from '../../redux/user/definitions';
import {
  selectDocumentation,
  selectError,
  selectIsLoading,
  selectProfile,
  selectUserByRoleGroup,
  selectUserCreated,
  selectUserValidationErrors,
} from '../../redux/user/selectors';
import { EditUserSchema } from '../../validations/formSchema';
import ButtonsActionsMobile from '../Scholarship/Components/ButtonsActionsMobile';
import AccessDataForm from './FormLayouts/AccessDataForm';
import ChangePasswordForm from './FormLayouts/ChangePasswordForm';
import PersonalDataForm from './FormLayouts/PersonalDataForm';
import StudentDataForm from './FormLayouts/StudentDataForm';
import RoleCode from '../../types/Roles/roleCode';

interface MyProfileProps {
  avatar: string | null;
  loading: boolean;
  documents: DocumentationItem[];
  profileData: UserData;
  roles: IdCodeNamePair[];
  genericMetadata: GenericMetadata;
  validationErrors: APIValidationError | null;
  auth: AuthState;
  defaultLang: string;
  setUserProfileAvatar: (image: string) => void;
  getProfileData: () => void;
  getUserDocumentation: (id: number) => void;
  updateProfileUser: (data: UpdateUserFormFields) => void;
  uploadUserDocument: (document_id: number, data: File) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
}

const MyProfile: React.FC<MyProfileProps> = ({
  profileData,
  validationErrors,
  avatar,
  loading,
  documents,
  auth,
  genericMetadata,
  defaultLang,
  roles,
  getProfileData,
  updateProfileUser,
  setUserProfileAvatar,
  uploadUserDocument,
  downloadUserDocument,
  removeUserDocument,
  getUserDocument,
  getUserDocumentation,
}) => {
  const { errors, handleSubmit, watch, clearError, setValue, setError, triggerValidation, control } = useForm<
    UpdateUserFormFields
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EditUserSchema,
  });

  useValidatorAPI(validationErrors, setError);
  const role_code = auth.userInfo.role_code;
  const roleCode = new RoleCode(role_code);

  const actions = ['checked', 'upload', 'download', 'view', 'remove'];

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  useEffect(() => {
    profileData.id > 0 && getUserDocumentation(profileData.id);
  }, [profileData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isMobile = useWindowSize().IsMobile;

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(data => {
        if (!Object.keys(errors).length) {
          if (data.birthdate) {
            data.birthdate = moment.utc(data.birthdate || '0', DateConstants.DATE_FORMAT).format('X');
          }
          updateProfileUser(data);
        } else {
          for (const e of Object.keys(errors)) {
            // @ts-ignore
            setError(e, errors[e].types, errors[e].message);
          }
        }
      }),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      disableElevation: true,
    },
  ];

  const [isOpenAvatar, setIsOpen] = useState(false);

  let studentInfo;
  let filteredDocuments;
  if (roleCode.isStudent()) {
    studentInfo = (
      <StudentDataForm
        errors={errors}
        control={control}
        profileData={profileData}
        incomesTypes={genericMetadata.incomesTypes}
        contractTypes={genericMetadata.contractTypes}
        actions={actions}
        documentation={documents}
        uploadUserDocument={uploadUserDocument}
        downloadUserDocument={downloadUserDocument}
        removeUserDocument={removeUserDocument}
        getUserDocument={getUserDocument}
        watch={watch}
      />
    );
    filteredDocuments = documents.filter((doc: DocumentationItem) => doc.code !== 'SCV');
  } else {
    filteredDocuments = documents;
  }

  return (
    <LayoutForm
      // Solo monta los menus izq y derecho en Desktop
      rightSubmenu={<Fragment>{!isMobile && <ActionsMenu actionsButtons={buttonsValidations} />}</Fragment>}
      leftSubmenu={
        <Fragment>
          {!isMobile && <MenuImageRound imageLink={avatar} setImage={setUserProfileAvatar} setIsOpen={setIsOpen} />}
        </Fragment>
      }
    >
      <React.Fragment>
        {!loading && (
          <Fragment>
            {<Title>{tKey('Mi perfil')}</Title>}
            {/*Si es movil el el layout central pinta el avatar, se le pasa setisOpen y desde Avatar2Imagev2 se controla el "modal"  */}
            {isMobile && (
              <MenuImageRound
                isOpenAvatar={isOpenAvatar}
                setIsOpen={setIsOpen}
                imageLink={avatar}
                setImage={setUserProfileAvatar}
                isMobile={true}
              />
            )}
            {/* Si NO estamos en modo edicion del avatar muestra los formularios tanto en movil como desktop*/}
            {!isOpenAvatar && (
              <Fragment>
                <AccessDataForm
                  isMobile={isMobile}
                  profileData={profileData}
                  schema={EditUserSchema}
                  control={control}
                  errors={errors}
                />
                {/* Si es Desktop muestra el form de cambio de pass, si es movil lo pinta <AccesDataForm> */}
                {!isMobile && <ChangePasswordForm />}
                {/* Datos personales */}
                <PersonalDataForm
                  setError={setError}
                  clearError={clearError}
                  profileData={profileData}
                  genericMetadata={genericMetadata}
                  errors={errors}
                  roles={roles}
                  setValue={setValue}
                  schema={EditUserSchema}
                  triggerValidation={triggerValidation}
                  control={control}
                  watch={watch}
                  isInterviewer={roleCode.isEntrevistador()}
                  defaultLang={defaultLang}
                />
                {/* Ocupacion  y documentos */}
                {studentInfo}
                {filteredDocuments?.length > 0 && (
                  <FncAddDocuments
                    documents={filteredDocuments}
                    actions={actions}
                    title={tFormKey('Documentos')}
                    upload={uploadUserDocument}
                    download={downloadUserDocument}
                    remove={removeUserDocument}
                    getFile={getUserDocument}
                  />
                )}
                {/* Si es Mobile pinta los botones de guardar cancelar */}
                {/*{isMobile && <ActionsMenu actionsButtons={buttonsValidations} />} */}
                {isMobile && <ButtonsActionsMobile butonsActions={buttonsValidations} />}
              </Fragment>
            )}
          </Fragment>
        )}
      </React.Fragment>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  error: selectError(state),
  avatar: selectUserAvatar(state),
  profileData: selectProfile(state),
  documents: selectDocumentation(state),
  roles: selectUserByRoleGroup(state),
  userCreated: selectUserCreated(state),
  validationErrors: selectUserValidationErrors(state),
  genericMetadata: selectGenericMetadata(state),
  auth: selectAuth(state),
  defaultLang: selectUserLang(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch | any) => ({
  setUserProfileAvatar: (image: string): void => dispatch(setUserProfileAvatar(image)),
  getUserDocumentation: (id: number): void => dispatch(getUserDocumentation(id)),
  getProfileData: (): void => dispatch(getProfile()),
  updateProfileUser: (data: UpdateUserFormFields): void => dispatch(updateProfile(data)),
  uploadUserDocument: (document_id: number, data: File): void => dispatch(uploadDocument(document_id, data)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
