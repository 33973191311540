import { ButtonProps } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  addDocumentAnnexesFileFromApi,
  deleteDocumentAnnexesFileFromApi,
  downloadAnnexesFileApi,
  editProject as editProjectApi,
  getAnnexesFileApi,
} from '../../../../api/Projects/projects';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import Loading from '../../../../components/Loading/Loading';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import Title from '../../../../components/Title/Title';
import { useHookMethod } from '../../../../helpers/customHooks/useHookMethod';
import { documentationFilter } from '../../../../helpers/docFilter';
import errorMessage from '../../../../helpers/errorMessage';
import { getDocs } from '../../../../helpers/getDocs';
import { tKey, tProjectKey } from '../../../../helpers/translate';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { DocumentationItem, KeyButton } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import {
  changeState,
  deleteAgreement,
  downloadDocument as downloadDocumentForm,
  editProject,
  getDocument,
  uploadAgreement,
} from '../../../../redux/project/action';
import {
  AnnexesFileProject,
  buttonsDatas,
  PaymentSettingsData,
  ProjectChangeStateKo,
  ProjectChangeStateOk,
  ProjectData,
} from '../../../../redux/project/definitions';
import { selectLoading, selectProjectData } from '../../../../redux/project/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { agreementSchema } from '../../../../validations/tracingProjectSchema';
import ProjectMenuRight from '../../ProjectMenuRight/ProjectMenuRight';
import SubmenuProject from '../../SubmenuProject/SubmenuProject';
import PaymentSettingsForm from '../PaymentSettingsForm/PaymentSettingsForm';
import styles from './agreementDateForm.module.scss';

interface AgreementDataFormProps {
  buttons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  loading: boolean;
  documentation: DocumentationItem[];
  readOnly: boolean;
  project: ProjectData;
  canIUp: boolean;
  canIUpAdmin: boolean;
  isNouscims: boolean;
  genericMetadata: GenericMetadata;
  uploadDocument: (documentation_id: number, data: File, project_id: number) => void;
  downloadDocument: (documentation_id: number, name: string, project_id: number) => void;
  getDocument: (documentation_id: number, name: string, project_id: number) => void;
  removeDocument: (documentation_id: number, project_id: number) => void;
  editProject: (data: ProjectData, idProject: number) => void;
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const AgreementDataForm: React.FC<AgreementDataFormProps> = ({
  project,
  buttons,
  moreInfoButtons,
  loading,
  documentation,
  readOnly,
  canIUp,
  isNouscims,
  genericMetadata,
  canIUpAdmin,
  uploadDocument,
  downloadDocument,
  getDocument,
  removeDocument,
  editProject,
  changeState,
  showSnackbar,
}) => {
  const [agreementDocumentation, setAgreementDocumentation] = useState<DocumentationItem[]>([]);
  const [annexesDocumentation, setAnnexesDocumentation] = useState<DocumentationItem[]>([]);
  const actions = ['checked', 'upload', 'download', 'view', 'remove'];
  const [, , ...addActions] = actions;

  const getAnnexesDocuments = (id: number) =>
    getAnnexesFileApi(id)
      .then(res => {
        setAnnexesDocumentation(res);
      })
      .catch(error => {
        console.error(errorMessage(error));
      });

  useEffect(() => {
    if (project.id) {
      (async function fetchData() {
        const res = await getAnnexesFileApi(project.id);
        setAnnexesDocumentation(res);
      })();
    }
  }, [project.id]);

  useEffect(() => {
    const auxDocumentation = documentation.filter((docu: DocumentationItem) => docu.type === 'CON');
    auxDocumentation.forEach((ele: DocumentationItem) => {
      ele.name = tProjectKey('Documento convenio');
      ele.mandatory = true;
      if (!ele.file_id) {
        ele.filename = '';
        ele.update = '';
        ele.upload = '';
      }
    });
    setAgreementDocumentation([...auxDocumentation]);
  }, [documentation, setAgreementDocumentation]);

  const handleSave = (data: any) => {
    const paymentSettingsObj = {
      center_cost_channel_code: data.center_cost_channel_code,
      center_cost_delegation_code: data.center_cost_delegation_code,
      center_cost_area_code: data.center_cost_area_code,
      center_cost_project_code: data.center_cost_project_code,
      agreement_number: data.agreement_number,
      description: data.description,
      concept: data.concept,
    } as PaymentSettingsData;
    const resultObj = {
      amount: data.amount,
      agreement_number: paymentSettingsObj.agreement_number,
      requires_follow_up: data.requires_follow_up,
      payment_settings: paymentSettingsObj,
    } as ProjectData;
    editProject(resultObj, project.id);
  };

  const handleSend = async (data: any) => {
    try {
      const paymentSettingsObj = {
        center_cost_channel_code: data.center_cost_channel_code,
        center_cost_delegation_code: data.center_cost_delegation_code,
        center_cost_area_code: data.center_cost_area_code,
        center_cost_project_code: data.center_cost_project_code,
        agreement_number: data.agreement_number,
        description: data.description,
        concept: data.concept,
      } as PaymentSettingsData;
      const resultObj = {
        amount: data.amount,
        agreement_number: paymentSettingsObj.agreement_number,
        requires_follow_up: data.requires_follow_up,
        payment_settings: paymentSettingsObj,
      } as ProjectData;
      await editProjectApi(resultObj, project.id);
      changeState(project.id, buttonsDatas.getTransition(project.state_code, 'send'));
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const { handleSubmit, watch, control, errors } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: agreementSchema,
  });

  useHookMethod(buttons, [KeyButton.SAVE, KeyButton.SEND], [handleSubmit(handleSave), handleSubmit(handleSend)]);

  // if (loading || !project.id) return <Loading big />;

  const { payment_settings, can_pay } = project;
  const { amount } = loading || !project.id ? { amount: 0 } : project.documents[0];

  const addDocument = (document: File, description: string, genericId?: number) => {
    if (genericId) {
      uploadDocument(genericId, document, project.id);
    }
  };

  const uploadAgreement = (documentation_id: number, data: File, project_id: number): void => {
    uploadDocument(parseInt(agreementDocumentation[0].code), data, project_id);
  };

  const removeAgreement = (documentation_id: number, project_id: number): void => {
    removeDocument(parseInt(agreementDocumentation[0].code), project_id);
  };

  const downloadAnnexesFile = async (file_id: number, name: string, project_id: number, view = true) => {
    try {
      const info = await downloadAnnexesFileApi(file_id, project_id);
      getDocs(info, view, name);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const addDocumentAnnexes = async (document: File, description: string) => {
    try {
      const data: AnnexesFileProject = { project_id: project.id, name: description, file: document, type: 'ANX' };
      await addDocumentAnnexesFileFromApi(data);
      getAnnexesDocuments(project.id);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const removeDocumentAnnexes = async (
    file_id: number,
    project_id: number,
    fieldName?: string,
    documentation_id?: any,
  ) => {
    try {
      const data = await deleteDocumentAnnexesFileFromApi(documentation_id);
      setAnnexesDocumentation(data);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const getAnnexesFile = (view: boolean) => async (file_id: number, name: string, project_id: number) =>
    downloadAnnexesFile(file_id, name, project_id, view);

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuProject
          current_section="firma_convenio"
          project={project}
          buttons={buttons}
          onClickOption={handleSubmit(handleSave)}
        />
      }
      rightSubmenu={<ProjectMenuRight actionButtons={buttons} moreInfoButtons={moreInfoButtons} project={project} />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <Fragment>
          <Title>{tProjectKey('Asistente de convenios')}</Title>
          <section className={styles.containerAbout}>
            {!can_pay && <MessageInfo>{tKey('p.no_hay_iban')}</MessageInfo>}
            <TextInputController
              control={control}
              type="number"
              schema={agreementSchema}
              size="40"
              label={tProjectKey('Importe del convenio')}
              name="amount"
              defaultValue={amount}
              errors={errors}
              disabled={readOnly || !isNouscims || !can_pay}
              price
            />

            <FormContainer title={tProjectKey('Área contable')}>
              <PaymentSettingsForm
                watch={watch}
                control={control}
                errors={errors}
                genericMetadata={genericMetadata}
                paymentSettingsData={payment_settings}
                disabled={readOnly || !isNouscims}
              />
            </FormContainer>

            <div className={styles.documentationContainer}>
              {agreementDocumentation ? (
                <FncAddDocuments
                  title={tProjectKey('Sube el convenio')}
                  documents={agreementDocumentation}
                  getFile={getDocument}
                  actions={actions}
                  disabled={(readOnly && !canIUp) || !isNouscims}
                  upload={uploadAgreement}
                  genericId={project.id}
                  download={downloadDocument}
                  remove={removeAgreement}
                  addDoc={addDocument}
                  required
                  originalName
                />
              ) : null}
            </div>
            {!documentationFilter(annexesDocumentation, ['EXT']) || (
              <FormContainer title={tProjectKey('Adendas')}>
                <FncAddDocuments
                  documents={documentationFilter(annexesDocumentation, ['EXT'])}
                  getFile={getAnnexesFile(false)}
                  actions={['checked', 'download', 'view']}
                  disabled={readOnly}
                  genericId={project.id}
                  download={downloadAnnexesFile}
                  originalName
                />
              </FormContainer>
            )}
            {!documentationFilter(annexesDocumentation, ['ANX']) || (
              <FormContainer title={tProjectKey('Anexos al convenio')}>
                <FncAddDocuments
                  documents={documentationFilter(annexesDocumentation, ['ANX'])}
                  getFile={getAnnexesFile(false)}
                  actions={addActions}
                  disabled={!canIUpAdmin}
                  genericId={project.id}
                  download={downloadAnnexesFile}
                  originalName
                  addRow
                  remove={removeDocumentAnnexes}
                  addDoc={addDocumentAnnexes}
                />
              </FormContainer>
            )}
          </section>
        </Fragment>
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  canIUp: selectUserCan(state)('agreement_upload'),
  canIUpAdmin:
    selectUserCan(state)('action_admin') || selectUserCan(state)('action_dir') || selectUserCan(state)('action_pma'),
  genericMetadata: selectGenericMetadata(state),
  isNouscims: selectIsNousCims(state),
  project: selectProjectData(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  uploadDocument: (documentation_id: number, data: File, project_id: number) =>
    dispatch(uploadAgreement(documentation_id, data, project_id)),
  downloadDocument: (documentation_id: number, name: string, project_id: number) =>
    dispatch(downloadDocumentForm(documentation_id, name, project_id)),
  getDocument: (documentation_id: number, name: string, project_id: number) =>
    dispatch(getDocument(documentation_id, name, project_id)),
  removeDocument: (documentation_id: number, project_id: number) =>
    dispatch(deleteAgreement(documentation_id, project_id)),
  editProject: (data: ProjectData, idProject: number) => dispatch(editProject(data, idProject)),
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string): void =>
    dispatch(changeState(id, states, comments)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreementDataForm);
