import { TimePicker, TimePickerProps } from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError, FieldErrors, FieldName, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema } from 'yup';
import * as DateConstants from '../../constants/date';
import { tErrorKey } from '../../helpers/translate';
import requiredStyle from '../../helpers/validator.module.scss';
import { InputSize } from '../Inputs/types';
import styles from './timePickerController.module.scss';

export interface TimePickerControllerProps<T extends FieldValues>
  extends Omit<TimePickerProps, 'value' | 'onChange' | 'size'> {
  control: Control<T>;
  errors: FieldErrors<T>;
  name: FieldName<T>;
  schema: ObjectSchema<T> | ObjectSchema;
  defaultValue?: string | number | null;
  validator?: boolean;
  visible?: boolean;
  size?: InputSize;
  green?: boolean;
}

const TimePickerController = <T extends FieldValues>({
  control,
  errors,
  name,
  schema,
  validator,
  visible = true,
  defaultValue,
  label,
  size,
  green = false,
  ...props
}: TimePickerControllerProps<T>): ReactElement | null => {
  const error = errors[name] as FieldError;

  let defaultDate: Moment | null = null;

  if (typeof defaultValue === 'number' && defaultValue !== 0) {
    defaultDate = moment.unix(defaultValue);
  }

  const returnClass = () => {
    if (validator) {
      if (error?.message) {
        return `${styles[`size_${size}`]} ${requiredStyle.required} ${styles.error}`;
      }
      return `${styles[`size_${size}`]} ${requiredStyle.required}`;
    }

    if (green && !validator) {
      if (error?.message) {
        return `${styles[`size_${size}`]} ${styles.green} ${styles.error}`;
      }
      return `${styles[`size_${size}`]} ${styles.green}`;
    }

    if (!validator && !green) {
      if (error?.message) {
        return `${styles[`size_${size}`]} ${styles.error}`;
      } else {
        return styles[`size_${size}`];
      }
    }
  };

  return visible ? (
    <Controller
      as={
        <TimePicker
          placeholder="" // Prevent google to detect this input as a credit card number
          inputVariant="outlined"
          label={label}
          format={DateConstants.TIME_FORMAT}
          onChange={() => {}}
          value={() => {}}
          helperText={tErrorKey(error?.message as string)}
          style={error?.message ? { color: 'red' } : undefined}
          variant="inline"
          error={!!error}
          autoOk
          className={returnClass()}
          {...props}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultDate}
    />
  ) : null;
};

export default TimePickerController;
