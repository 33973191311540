import { UUID } from '../../../types/Shared/standard';
import { GetFamilyIncomesDataAction } from './list/definitions';
import { CreateFamilyIncomeDataAction } from './create/definitions';
import { UpdateFamilyIncomeAction } from './update/definitions';

export type FamilyIncomeId = UUID;
export type ScholarshipId = number;
export type FileId = number | null;
export type FileName = string | null;
export type FamilyMember = 'Zinger' | 'Padre' | 'Madre' | 'Hermano/a' | 'Otros familiares';
export type IncomeType = 'Salario' | 'Pensión' | 'Rentas' | 'Paro' | 'Ingresos alternativos';

export interface FamilyIncome {
  id: FamilyIncomeId;
  scholarship_id: ScholarshipId;
  family_member: FamilyMember;
  income_type: IncomeType;
  amount: number;
  file_id: FileId;
  filename: FileName;
}

export type FamilyIncomesDataAction =
  | GetFamilyIncomesDataAction
  | CreateFamilyIncomeDataAction
  | DeleteFamilyIncomeDataAction
  | UpdateFamilyIncomeAction;

export const FamilyIncomesInitialState = {
  loading: false as boolean,
  error: null as string | null,
  list: [] as FamilyIncome[],
};
