import moment from 'moment';
import assertUnreachable from '../../helpers/assertUnreachable';
import { getCategoryKey } from '../../helpers/getCategoryKey';
import { getDocs } from '../../helpers/getDocs';
import { TranslationConst } from './action_types';
import {
  initialState,
  MailDetails,
  TranslationCategories,
  TranslationKeyAction,
  TranslationKeyData,
  TranslationState,
} from './definitions';

const translationReducer = (state = initialState, action: TranslationKeyAction): TranslationState => {
  switch (action.type) {
    case TranslationConst.GET_CATEGORIES_TRANSLATION:
    case TranslationConst.GET_FILE_TRANSLATIONS:
    case TranslationConst.GET_LOPD_TRANSLATION:
    case TranslationConst.SEND_IMPORT_TRANSLATIONS:
    case TranslationConst.UPDATE_LOPD_TRANSLATION:
      return {
        ...state,
        responseImport: false,
      };
    case TranslationConst.UPDATE_TRANSLATE_DATA:
      return {
        ...state,
      };
    case TranslationConst.UPDATE_TRANSLATE_DATA_OK:
      const { key_label, category } = action.opt;
      const key = getCategoryKey(category);
      //@ts-ignore
      state[key].keys.set(key_label, action.data.value);

      return {
        ...state,
      };
    case TranslationConst.GET_FILE_TRANSLATIONS_OK:
      const { data: download, source } = action;
      const filename = `${source === 'keys' ? 'traducciones' : 'campos_configurados'}_${moment(new Date()).format(
        'DD/MM/YYYY',
      )}.xlsx`;
      getDocs(download, true, filename);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TranslationConst.GET_FILE_TRANSLATIONS_KO:
    case TranslationConst.SEND_IMPORT_TRANSLATIONS_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TranslationConst.GET_CATEGORIES_TRANSLATION_KO:
    case TranslationConst.GET_LOPD_TRANSLATION_KO:
    case TranslationConst.UPDATE_TRANSLATE_DATA_KO:
      const { error } = action;
      return {
        ...state,
        error,
      };
    case TranslationConst.SEND_IMPORT_TRANSLATIONS_OK:
      return {
        ...state,
        responseImport: true,
      };
    case TranslationConst.GET_CATEGORIES_TRANSLATION_OK:
      const { keyValues } = action;
      const g_keys = new Map();
      const s_keys = new Map();
      const p_keys = new Map();
      const e_keys = new Map();
      const f_keys = new Map();
      keyValues.forEach((k: TranslationKeyData) => {
        switch (k.category) {
          case TranslationCategories.GENERAL:
            g_keys.set(k.code, k.value);
            break;
          case TranslationCategories.BECAS:
            s_keys.set(k.code, k.value);
            break;
          case TranslationCategories.PROYECTOS:
            p_keys.set(k.code, k.value);
            break;
          case TranslationCategories.ERRORES:
            e_keys.set(k.code, k.value);
            break;
          case TranslationCategories.FORMS:
            f_keys.set(k.code, k.value);
            break;
        }
      });
      return {
        ...state,
        generic: {
          ...state.generic,
          keys: g_keys,
        },
        scholarship: {
          ...state.scholarship,
          keys: s_keys,
        },
        project: {
          ...state.project,
          keys: p_keys,
        },
        forms: {
          ...state.forms,
          keys: f_keys,
        },
        errors: {
          ...state.errors,
          keys: e_keys,
        },
        loaded: true,
      };
    case TranslationConst.GET_LIST_MAILS:
      return {
        ...state,
        loadingMailList: true,
      };
    case TranslationConst.GET_LIST_MAILS_OK:
      return {
        ...state,
        listMails: action.listMails,
        mailDetails: {} as MailDetails,
        loadingMailList: false,
      };
    case TranslationConst.GET_LIST_MAILS_KO:
      return {
        ...state,
        error: action.error,
        loadingMailList: false,
      };
    case TranslationConst.GET_MAIL_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case TranslationConst.GET_MAIL_DETAILS_OK:
      return {
        ...state,
        mailDetails: action.mailDetails,
        loading: false,
      };
    case TranslationConst.GET_MAIL_DETAILS_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TranslationConst.UPDATE_MAIL_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case TranslationConst.UPDATE_MAIL_DETAILS_OK:
      return {
        ...state,
        loading: false,
      };
    case TranslationConst.UPDATE_MAIL_DETAILS_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TranslationConst.TEST_TRANSLATION_MAIL:
      return {
        ...state,
      };
    case TranslationConst.SET_DATA_MAIL:
      return {
        ...state,
        mailDetails: {
          ...state.mailDetails,
          [action.key]: action.value,
        },
      };
    case TranslationConst.SET_DATA:
      switch (action.setType) {
        case 'section':
          state.mailDetails.sections.map(e => {
            if (e.id === action.id) {
              e[`content_${action.lang}`] = action.text;
            }
            return null;
          });
          break;
        case 'general':
          if (action.id === -1) {
            const newText = action.text.replace('<p>', '');
            const finalText = newText.replace('</p>', '').slice(0, -1);
            state.mailDetails[`subject_${action.lang}`] = finalText;
          } else {
            state.mailDetails[`subject_${action.lang}`] = action.text;
          }
          break;
      }
      return {
        ...state,
      };
    case TranslationConst.GET_METADATA_TRANSLATIONS:
      return {
        ...state,
        loading: true,
      };
    case TranslationConst.GET_METADATA_TRANSLATIONS_OK:
      return {
        ...state,
        loading: false,
        translationMetadata: action.data,
      };
    case TranslationConst.GET_METADATA_TRANSLATIONS_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case TranslationConst.SET_LOPD_TRANSLATION: {
      const { key, value } = action;
      return {
        ...state,
        lopdMails: {
          ...state.lopdMails,
          [key]: value,
        },
      };
    }

    case TranslationConst.CLEAN_LOPD_TRANSLATION: {
      const {
        mails: { lopd_text_ca, lopd_text_en, lopd_text_es, lopd_text_fr },
      } = action;

      return {
        ...state,
        lopdMails: { lopd_text_ca, lopd_text_en, lopd_text_es, lopd_text_fr },
      };
    }

    case TranslationConst.GET_LOPD_TRANSLATION_OK: {
      const { data } = action;

      return {
        ...state,
        lopdMails: data,
      };
    }

    case TranslationConst.UPDATE_LOPD_TRANSLATION_OK: {
      const { data } = action;

      return {
        ...state,
        lopdMails: data,
      };
    }

    case TranslationConst.SET_CATEGORY_FILTER: {
      const { category } = action;

      return {
        ...state,
        categoyFilter: category,
      };
    }
  }
  assertUnreachable(action);
  return state;
};

export default translationReducer;
