/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import { tKey } from '../../../helpers/translate';
import { deleteContactData } from '../../../redux/academy/actions';
import { selectIsNousCims } from '../../../redux/auth/selectors';
import { createEntityContact, entityContactSetData, entityUpdateContact } from '../../../redux/entity/actions';
import { EntityContactData, EntityContactRoles } from '../../../redux/entity/definitions';
import { selectContactData } from '../../../redux/entity/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from '../Modal/genericModalEntityContact.module.scss';
import { EntityContactSchema } from '../../../validations/Entities/entityFormSchema';

interface ModalCreateContactProps {
  entityId: number;
  loadingModal: boolean;
  isUpdate: boolean;
  isNousCims: boolean;
  contactData: EntityContactData;
  roleList: EntityContactRoles[];
  onSave: (contactData: EntityContactData, entity_id: number) => void;
  setData: (key: string, value: string | number) => void;
  onUpdate: (contactData: EntityContactData, entity_id: number) => void;
  clearModal: () => void;
  closeContactModal: () => void;
}
const GenericModalEntityContact: React.FC<ModalCreateContactProps> = ({
  entityId,
  loadingModal,
  isUpdate,
  isNousCims,
  contactData,
  roleList,
  setData,
  onSave,
  onUpdate,
  clearModal,
  closeContactModal,
}) => {
  const { errors, handleSubmit, control } = useForm<ModalCreateContactProps>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EntityContactSchema,
  });

  const onSubmit = (data: any) => {
    const totalInfo = { ...data, id: contactData.id };

    isUpdate ? onUpdate(totalInfo, entityId) : onSave(data, entityId);
    clearModal();
    closeContactModal();
  };

  if (loadingModal)
    return (
      <div style={{ minHeight: '430px' }}>
        <Loading big />
      </div>
    );

  let contactComments;
  if (isNousCims) {
    contactComments = (
      <div className={styles.newcontact}>
        <TextInputController
          size="Large"
          label={tKey('Comentarios')}
          name="description"
          defaultValue={contactData?.description}
          control={control}
          errors={errors}
          schema={EntityContactSchema}
          rowsMax={5}
          rows={5}
          multiline
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('description', e.target.value)}
        />
      </div>
    );
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.newcontact}>
        <SelectController
          control={control}
          size="Large"
          key="contact_role_code"
          name="contact_role_code"
          defaultValue={contactData?.contact_role_code}
          errors={errors}
          schema={EntityContactSchema}
          label={tKey('Cargo')}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('contact_role_code', e.target.value)}
        >
          {roleList &&
            roleList?.map((e: any) => (
              <MenuItem key={e.name} value={e.code}>
                {e.name}
              </MenuItem>
            ))}
        </SelectController>
      </div>
      <div className={styles.newcontact}>
        <TextInputController
          size="50"
          label={tKey('Nombre')}
          name="name"
          defaultValue={contactData?.name}
          control={control}
          errors={errors}
          schema={EntityContactSchema}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('name', e.target.value)}
        />
        <TextInputController
          size="50"
          label={tKey('Apellidos')}
          name="surname"
          defaultValue={contactData?.surname}
          control={control}
          errors={errors}
          schema={EntityContactSchema}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('surname', e.target.value)}
        />
      </div>
      <div className={styles.newcontact}>
        <TextInputController
          size="50"
          label={tKey('Email')}
          name="email"
          defaultValue={contactData?.email}
          control={control}
          errors={errors}
          schema={EntityContactSchema}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('email', e.target.value)}
        />
        <TextInputController
          size="50"
          label={tKey('Teléfono móvil')}
          name="phone"
          defaultValue={contactData?.phone}
          control={control}
          errors={errors}
          schema={EntityContactSchema}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('phone', e.target.value)}
        />
      </div>
      {contactComments}
      <div className={styles.btn_create}>
        {isUpdate ? (
          <Button color="primary" variant="outlined" type="submit">
            {tKey('Modificar')}
          </Button>
        ) : (
          <Button color="primary" variant="outlined" type="submit">
            {tKey('Agregar')}
          </Button>
        )}
      </div>
    </form>
  );
};
const mapStateToProps = (state: AppState) => ({
  loadingModal: state.entityReducer.loadingModal,
  contactData: selectContactData(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(entityContactSetData(key, value)),
  clearModal: (): void => dispatch(deleteContactData()),
  onUpdate: (contactData: any, entity_id: number): void => dispatch(entityUpdateContact(contactData, entity_id)),
  onSave: (contactData: any, entity_id: number): void => dispatch(createEntityContact(contactData, entity_id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GenericModalEntityContact);
