/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, InputAdornment, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../components/Select/SelectController';
import * as DateConstants from '../../../constants/date';
import { tFormKey } from '../../../helpers/translate';
import { closePaymentModal, setOnePendingInvoice, updatePendingInvoice } from '../../../redux/accounting/actions';
import { PendingInvoiceData } from '../../../redux/accounting/definitions';
import { CodeNamePair } from '../../../redux/common/definitions';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { PendingInvoiceSchema } from '../../../validations/formSchema';
import styles from './paymentModificationModal.module.scss';
import UploadFileInvoice from '../Invoices/UploadFileInvoice';

export interface PaymentModificationModalProps {
  genericMetadata: GenericMetadata;
  invoiceDetails: PendingInvoiceData;
  closePaymentModal: () => void;
  updateInvoice: (invoice: PendingInvoiceData) => void;
  setData: (key: string, value: string | number) => void;
}

const PaymentModificationModal: React.FC<PaymentModificationModalProps> = ({
  genericMetadata,
  invoiceDetails,
  closePaymentModal,
  updateInvoice,
  setData,
}) => {
  const {
    due_date,
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_area_code,
    center_cost_project_code,
    total_amount,
    reference_number,
    entity_name,
    account_number,
    account_numbers,
    is_visa_provider,
  } = invoiceDetails;

  const invoiceSchema = PendingInvoiceSchema(is_visa_provider);

  const { handleSubmit, errors, control, register } = useForm<PendingInvoiceData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: invoiceSchema,
  });

  const morethanOneIban = 1;

  const { channels, delegations, areas, projects } = genericMetadata;

  const asingDateValue = (date: string | number | null) => {
    return date ? moment(date, DateConstants.DATE_FORMAT) : null;
  };

  const changeDates = (name: string, date: moment.Moment | null) => {
    if (date && date.isValid()) {
      setData(name, date.format(DateConstants.DATE_FORMAT));
    }
  };
  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  let ibanNumber;
  if (account_numbers?.length > morethanOneIban) {
    ibanNumber = (
      <SelectController
        control={control}
        name="account_number"
        label={tFormKey('Iban')}
        defaultValue={account_number}
        size="50"
        schema={invoiceSchema}
        errors={errors}
      >
        {account_numbers?.map((iban: string, index: number) => (
          <MenuItem key={`${iban}_${index}`} value={iban}>
            {iban}
          </MenuItem>
        ))}
      </SelectController>
    );
  } else {
    ibanNumber = (
      <TextInputController
        size="50"
        control={control}
        errors={errors}
        type="text"
        name="account_number"
        disabled={true}
        schema={invoiceSchema}
        defaultValue={account_number}
        label={tFormKey('Iban')}
      />
    );
  }
  const onSubmit = (data: PendingInvoiceData) => {
    const allData = { ...data, due_date, id: invoiceDetails.id };
    updateInvoice(allData);
    closePaymentModal();
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer title={''} className={styles.formModalContainer}>
          <TextInputController
            size="100"
            control={control}
            errors={errors}
            type="text"
            name="entity_name"
            disabled={true}
            schema={invoiceSchema}
            defaultValue={entity_name}
            label={tFormKey('Entidad')}
          />
          <div className={styles.subContainer}>
            <TextInputController
              size="50"
              control={control}
              errors={errors}
              type="text"
              name="reference_number"
              disabled={true}
              schema={invoiceSchema}
              defaultValue={reference_number}
              label={tFormKey('Factura')}
            />
            <TextInputController
              size="50"
              control={control}
              errors={errors}
              type="number"
              name="total_amount"
              disabled={true}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              schema={invoiceSchema}
              defaultValue={total_amount}
              label={tFormKey('Importe')}
            />
          </div>
          <div className={styles.subContainer}>
            <KeyboardDatePicker
              size="medium"
              name="due_date"
              label={tFormKey('Fecha de Vencimiento')}
              error={!!errors.due_date}
              format={DateConstants.DATE_FORMAT}
              variant="inline"
              inputVariant="outlined"
              helperText={errors.due_date}
              className={styles.size_50}
              placeholder="" // Prevent google to detect this input as a credit card number
              value={asingDateValue(due_date)}
              onChange={(date: MaterialUiPickersDate) => {
                changeDates('due_date', date);
              }}
              autoOk
              inputRef={register}
              onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (!e.target.value) setData(e.target.name, '');
              }}
            />

            {ibanNumber}
          </div>
          <div className={styles.subContainer}>
            <SelectController
              control={control}
              name="center_cost_channel_code"
              label={tFormKey('Canal')}
              defaultValue={center_cost_channel_code}
              size="20"
              schema={invoiceSchema}
              errors={errors}
              onClick={e => handleSelectChange(e.target)}
            >
              {channels?.map((element: CodeNamePair, index: number) => (
                <MenuItem key={`${element.code}_${index}`} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              control={control}
              name="center_cost_delegation_code"
              label={tFormKey('Delegación')}
              defaultValue={center_cost_delegation_code}
              size="20"
              schema={invoiceSchema}
              errors={errors}
              onClick={e => handleSelectChange(e.target)}
            >
              {delegations
                ?.filter(e => e.channel_code === center_cost_channel_code || e.channel_code === null)
                ?.map((element: CodeNamePair, index: number) => (
                  <MenuItem key={`${element.code}_${index}`} value={element.code}>
                    {element.name}
                  </MenuItem>
                ))}
            </SelectController>
            <SelectController
              control={control}
              name="center_cost_area_code"
              label={tFormKey('Área')}
              defaultValue={center_cost_area_code}
              size="20"
              schema={invoiceSchema}
              errors={errors}
              onClick={e => handleSelectChange(e.target)}
            >
              {areas
                ?.filter(e => e.delegation_code === center_cost_delegation_code || e.delegation_code === null)
                ?.map((element: CodeNamePair, index: number) => (
                  <MenuItem key={`${element.code}_${index}`} value={element.code}>
                    {element.name}
                  </MenuItem>
                ))}
            </SelectController>
            <SelectController
              control={control}
              name="center_cost_project_code"
              label={tFormKey('Proyecto')}
              defaultValue={center_cost_project_code || '0'}
              size="20"
              schema={invoiceSchema}
              errors={errors}
              onClick={e => handleSelectChange(e.target)}
            >
              {projects
                .filter(e => e.area_code === center_cost_area_code || !e.area_code)
                ?.map((element: CodeNamePair, index: number) => (
                  <MenuItem key={`${element.code}_${index}` || '0'} value={element.code || '0'}>
                    {element.name}
                  </MenuItem>
                ))}
            </SelectController>
          </div>
          <div className={styles.subContainer}>
            <UploadFileInvoice invoice_id={invoiceDetails.invoice_id} readOnly={false} errors={errors} />
          </div>
        </FormContainer>

        <div className={styles.buttons}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginLeft: '10px', width: '120px' }}
            onClick={closePaymentModal}
          >
            {tFormKey('Cancelar')}
          </Button>
          <Button variant="outlined" color="primary" style={{ marginLeft: '10px', width: '120px' }} type="submit">
            {tFormKey('Modificar')}
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateProps = (state: AppState) => ({
  genericMetadata: selectGenericMetadata(state),
  invoiceDetails: state.accountingReducer.pendingInvoice,
});

const mapDispatchToProps = (dispatch: any) => ({
  closePaymentModal: (): void => dispatch(closePaymentModal()),
  setData: (key: string, value: string | number): void => dispatch(setOnePendingInvoice(key, value)),
  updateInvoice: (data: PendingInvoiceData): void => dispatch(updatePendingInvoice(data)),
});
export default connect(mapStateProps, mapDispatchToProps)(PaymentModificationModal);
