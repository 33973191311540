import React from 'react';
import Layout from '../../components/Layout/Layout';
import styles from './legalAdvice.module.scss';

const LegalAdvice: React.FC<{}> = () => (
  <Layout layoutDisabled={true}>
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          AVISO <br /> LEGAL
        </h1>
        <section className={styles.content}>
          <p className={styles.subtitles}>
            <strong>FUNDACIÓN PRIVADA NOUS CIMS</strong>, en adelante “Nous Cims”, le da la bienvenida al Sitio Web de
            Nous Cims (el “Sitio Web”). Nous Cims es una fundación privada sin ánimo de lucro con domicilio social en
            c/Entença, 332-334, 7ª planta, 08029 Barcelona, España, número de CIF G-66621350. Puede contactar con
            nosotros en el teléfono 677 92 02 50 o en la dirección de correo electrónico hola@nouscims.com
          </p>
          <p>
            Nous Cims está inscrita en el Registro de Fundaciones de la Generalitat de Catalunya (Dirección General de
            Derecho y de Entidades Jurídicas) con el número de inscripción 2937.
          </p>
          <p>
            <strong>1.- OBJETO</strong>
          </p>
          <p>
            Nous Cims, como responsable del Sitio Web, pone a disposición de los usuarios el presente documento que
            regula el uso de www.nouscims.com, con el que pretendemos dar cumplimiento a las obligaciones que dispone la
            Ley 34/2002, de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSICE o LSSI), así
            como informar a todos los usuarios del sitio web respecto de cuáles son sus condiciones de uso.
          </p>
          <p>
            A través de la web, Nous Cims facilita a los usuarios el acceso y utilización de diferentes servicios y
            contenidos sobre la Fundación y su actividad puestos a disposición a través del Sitio Web. El acceso y uso
            de algunos servicios puede requerir el registro previo como miembro del Sitio Web.
          </p>
          <p>
            Toda persona que acceda a esta web asume el papel de usuario (en adelante el “usuario”), e implica la
            aceptación total y sin reservas de todas y cada una de las disposiciones incluidas en este aviso legal, así
            como a cualesquiera otras disposiciones legales que fueran de aplicación.
          </p>
          <p>
            Como usuarios, tienen que leer atentamente este Aviso Legal en cualquier de las ocasiones que entren a la
            web, pues ésta puede sufrir modificaciones ya que el prestador se reserva el derecho a modificar cualquier
            tipo de información que pudiera aparecer en la web, sin que exista la obligación de pre-avisar o poner en
            conocimiento de los usuarios estas obligaciones, siendo suficiente la publicación en el sitio web del
            prestador.
          </p>
          <p>Este aviso legal se actualizó por última vez el 4 de diciembre de 2019.</p>
          <p>
            <strong>2.- CONDICIONES DE ACCESO Y USO DE LA WEB</strong>
          </p>
          <p>
            <strong>2.1. Carácter gratuito del acceso y uso de la web</strong>
          </p>
          <p>
            El acceso y utilización de la parte pública del Sitio Web es de carácter gratuito para todos los usuarios,
            no requiriendo para su visualización suscripción previa o registro alguno. No obstante, la presentación de
            determinados servicios por parte de la Fundación puede requerir el registro del Usuario en los términos que
            se describen más adelante, y previa aceptación de determinadas condiciones específicas que desarrollan y
            complementan este Aviso Legal.
          </p>
          <p>
            <strong>2.2. Registro de Usuario</strong>
          </p>
          <p>
            Con carácter general la prestación de los Servicios no exige la previa suscripción o registro de los
            Usuarios. Aun así, Nous Cims condiciona el uso de algunos de los servicios a la previa complementación del
            correspondiente registro de Usuario. Este registro se efectuará en la forma expresamente indicada en la
            propia sección del servicio.
          </p>
          <p>
            <strong>2.3. Veracidad de la información</strong>
          </p>
          <p>
            Toda la información que facilita el Usuario tiene que ser veraz. A estos efectos, el Usuario garantiza la
            autenticidad de los datos comunicados a través de los formularios para la suscripción de los Servicios. Será
            responsabilidad del Usuario mantener toda la información facilitada a Nous Cims permanentemente actualizada
            de forma que responda, en cada momento, a su situación real. En todo caso, el Usuario será el único
            responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause al prestador
            o a terceros.
          </p>
          <p>
            <strong>2.4. Menores de edad</strong>
          </p>
          <p>
            Para el uso de los servicios, los menores de edad tienen que obtener siempre previamente el consentimiento
            de los padres, tutores o representantes legales, responsables últimos de todos los actos realizados por los
            menores a su cargo. La responsabilidad en la determinación de contenidos concretos a los cuales acceden los
            menores corresponde a aquellos, es por eso que, si acceden a contenidos no apropiados por Internet, se
            tendrán que establecer en sus ordenadores mecanismos, en particular programas informáticos, filtros y
            bloqueos, que permitan limitar los contenidos disponibles y, a pesar de que no sean infalibles, son de
            especial utilidad para controlar y restringir los materiales a los que pueden acceder los menores.
          </p>
          <p>
            <strong>2.5. Obligación de hacer un uso correcto de la web</strong>
          </p>
          <p>
            El Usuario se compromete a utilizar la Web de conformidad a la Ley y al presente Aviso Legal, así como a la
            moral y a buenas costumbres. A tal efecto, el Usuario se abstendrá de utilizar la página con finalidades
            ilícitas o prohibidas, lesivas de derechos e intereses de terceros, o que de cualquier forma puedan dañar,
            inutilizar, sobrecargar, deteriorar o impedir la normal utilización de equipos informáticos o documentos,
            archivos y toda clase de contenidos almacenados en cualquier equipo informático del prestador.
          </p>
          <p>
            En particular, y a título indicativo, pero no exhaustivo, el Usuario se compromete a no transmitir, difundir
            o poner a disposición de terceros informaciones, datos, contenidos, mensajes, gráficos, dibujos, archivos de
            sonido o imagen, fotografías, grabaciones, software y, en general, cualquier clase de material que:
          </p>
          <ol>
            <li>
              sea contraria, desprecie o atente contra los derechos fundamentales y las libertades públicas reconocidas
              constitucionalmente, en tratados internacionales y otras normas vigentes;
            </li>
            <li>
              induzca, incite o promueva actuaciones delictivas, denigrantes, difamatorias, violentas o, en general,
              contrarias a la ley, a la moral y al orden público;
            </li>
            <li>
              induzca, incite o promueva actuaciones, actitudes o pensamientos discriminatorios por razón de sexo, raza,
              religión, creencias, edad o condición;
            </li>
            <li>
              sea contrario al derecho al honor, a la intimidad personal o familiar o a la propia imagen de las
              personas;
            </li>
            <li>de cualquier manera perjudique la credibilidad del prestador o de terceros; y</li>
            <li>constituya publicidad ilícita, engañosa o desleal.</li>
          </ol>
          <p>
            <strong>3.- EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</strong>
          </p>
          <p>
            Nous Cims se exime de cualquier tipo de responsabilidad derivada de la información publicada en nuestro
            sitio web, siempre que esta información haya sido manipulada o introducida por un tercero ajeno.
          </p>
          <p>
            Esta web ha sido revisada y probada para que funcione correctamente. En principio, se puede garantizar el
            correcto funcionamiento los 365 días del año, 24 horas al día. Sin embargo, Nous Cims no descarta la
            posibilidad que existan determinados errores de programación, o que sucedan causas de fuerza mayor,
            catástrofes naturales, huelgas, o circunstancias parecidas que hagan imposible el acceso a la página web.
          </p>
          <p>
            Nous Cims otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios de
            cualquier naturaleza que puedan salir a colación de la falta de disponibilidad, mantenimiento y efectivo
            funcionamiento de la web o de sus servicios y contenidos; de la existencia de virus, programas maliciosos o
            lesivos en los contenidos; del uso ilícito, negligente, fraudulento o contrario a este Aviso Legal y
            condiciones de uso; o de la falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los
            servicios prestados por terceros y ademanes a disposición de los usuarios en el sitio web.
          </p>
          <p>
            El prestador no se hace responsable bajo ningún concepto de los daños que puedan dimanar del uso ilegal o
            indebido de la presente página web.
          </p>
          <p>
            <strong>4.- COOKIES</strong>
          </p>
          <p>
            El sitio web del prestador puede utilizar cookies (pequeños archivos de información que el servidor envía al
            ordenador de quien accede en la página) para llevar a cabo determinadas funciones que son consideradas
            imprescindibles para el correcto funcionamiento y visualización del lugar. Las cookies utilizadas tienen, en
            todo caso, carácter temporal, con la única finalidad de hacer más eficaz la navegación, y desaparecen al
            acabar la sesión del usuario.
          </p>
          <p>
            En ningún caso se utilizarán las cookies para recoger información de carácter personal. Para más
            información, ver nuestra Política de Cookies.
          </p>
          <p>
            <strong>5.- ENLACES (LINKS)</strong>
          </p>
          <p>
            Desde el sitio web es posible que se redirija a contenidos de terceras webs. Dado que desde el Sitio Web no
            podemos controlar siempre los contenidos introducidos por los terceros, Nous Cims no asume ningún tipo de
            responsabilidad respecto a estos contenidos. En todo caso, el prestador manifiesta que procederá a la
            retirada inmediata de cualquier contenido que pudiera contravenir la legislación nacional o internacional,
            la moral o el orden público, procediendo a la retirada inmediata de la re-dirección a estas webs, poniendo
            en conocimiento de las autoridades competentes el contenido en cuestión.
          </p>
          <p>
            Nous Cims no se responsabiliza de la información y contenidos almacenados, a título enunciativo, pero no
            limitante, en foros, chats, generadores de blogs, comentarios, redes sociales o cualquiera otro medio que
            permita a terceros publicar contenidos de forma independiente a la página web de la Fundación. Sin embargo y
            en cumplimiento de lo que dispone el art. 11 y 16 de la LSSICE, Nous Cims se pone a disposición de todos los
            usuarios, autoridades y fuerzas de seguridad, y colaborando de forma activa en la retirada o, cuando sea
            necesario, el bloqueo de todos aquellos contenidos que puedan afectar o contravenir la legislación nacional,
            o internacional, derechos de terceros o la moral y el orden público. En caso de que el usuario considere que
            puede existir algún contenido que pudiera ser susceptible de esta clasificación, se ruega lo notifique de
            forma inmediata al administrador del sitio web.
          </p>
          <p>
            <strong>6.- PROTECCIÓN DE DATOS PERSONALES</strong>
          </p>
          <p>
            El prestador está profundamente comprometido con el cumplimiento de la normativa de protección de datos
            personales y garantiza el cumplimiento íntegro de las obligaciones dispuestas, así como la implementación de
            las medidas de seguridad dispuestas al Reglamento Europeo de Protección de datos y la normativa española de
            protección de datos. Para más información, ver nuestra Política de Privacidad.
          </p>
          <p>
            <strong>7.- PROPIEDAD INTELECTUAL E INDUSTRIAL</strong>
          </p>
          <p>
            El sitio web, incluyendo a título enunciativo, pero no limitativo la programación, edición, compilación y
            otros elementos necesarios para su funcionamiento, los diseños, logotipos, textos, fotografías y/o gráficos
            son propiedad de Nous Cims o, en caso de que fuera necesario, dispone de la licencia o autorización expresa
            por parte de los autores. Todos los contenidos del sitio web se encuentran debidamente protegidos por la
            normativa de propiedad intelectual e industrial.
          </p>
          <p>
            Independientemente de la finalidad por la que fueran destinados, la reproducción total o parcial, uso,
            distribución y comunicación pública requieren de la autorización escrita previa por parte del prestador.
            Cualquier uso no autorizado previamente por parte de la Fundación será considerado un incumplimiento grave
            de los derechos de propiedad intelectual o industrial del autor.
          </p>
          <p>
            Los diseños, logotipos, textos y/o gráficos ajenos al prestador y que puedan aparecer en el sitio web,
            pertenecen a sus respectivos propietarios, siendo ellos mismos los responsables de cualquier posible
            controversia que pudiera acontecer respecto a los mismos. En todo caso, el prestador cuenta con la
            autorización expresa y previa por parte de los mismos.
          </p>
          <p>
            El prestador reconoce a favor de sus titulares los correspondientes derechos de propiedad industrial e
            intelectual, no implicando la mención o aparición en el sitio web, de la existencia de derechos o ninguna
            responsabilidad del prestador sobre los mismos, como tampoco respaldo, patrocinio o recomendación por parte
            del mismo.
          </p>
          <p>
            Para realizar cualquier tipo de observación respecto a posibles incumplimientos de los derechos de propiedad
            intelectual o industrial, así como sobre cualquiera de los contenidos del sitio web, puede hacerlo a través
            del correo electrónico arriba reseñado.
          </p>
          <p>
            <strong>8.- LEY APLICABLE Y JURISDICCIÓN</strong>
          </p>
          <p>
            Para la resolución de las controversias o cuestiones relacionadas con la presente página web o de las
            actividades en esta desarrolladas, será de aplicación la legislación española, a la que se someten
            expresamente las partes, siendo competentes por la resolución de todos los conflictos derivados o
            relacionados con su uso los Juzgados y Tribunales de Barcelona.
          </p>
        </section>
      </div>
    </div>
  </Layout>
);
export default LegalAdvice;
