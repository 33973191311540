import { Button, ButtonProps, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import Title from '../../../components/Title/Title';
import { tFormKey, tKey } from '../../../helpers/translate';
import { closeInvoices, editAccountingSettings, getAccountingSettings } from '../../../redux/accounting/actions';
import { AccountingDispatch, AccountingSettings } from '../../../redux/accounting/definitions';
import { selectAccountingSettings, selectLoading } from '../../../redux/accounting/selectors';
import { AppState } from '../../../redux/root-reducer';
import { AccountSettingsSchema } from '../../../validations/formSchema';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './configuration.module.scss';
import { selectUserCan } from '../../../redux/permissions/selectors';

interface ConfigurationProps {
  accountingSettings: AccountingSettings;
  showCloseAccounting: boolean;
  loading: boolean;
  editAccountingSettings: (data: AccountingSettings) => void;
  getAccountingSettings: () => void;
  closeInvoicesByYear: (closing_year: number) => void;
}

const Configuration: React.FC<ConfigurationProps> = ({
  accountingSettings,
  showCloseAccounting,
  loading,
  getAccountingSettings,
  editAccountingSettings,
  closeInvoicesByYear,
}) => {
  const { payment_control_day, payment_day, accounting_years } = accountingSettings;
  const [closingYear, setClosingYear] = useState<number | null>(null);

  const { handleSubmit, errors, control } = useForm<AccountingSettings>({
    mode: 'onChange',
    submitFocusError: true,
    validationSchema: AccountSettingsSchema,
  });

  useEffect(() => {
    getAccountingSettings();
  }, [getAccountingSettings]);

  const onSave = (data: AccountingSettings) => {
    editAccountingSettings(data);
  };

  let closingYearSection;
  if (showCloseAccounting && accounting_years) {
    if (!closingYear) {
      setClosingYear(accounting_years[0]);
    }

    closingYearSection = (
      <FormContainer className={styles.closingYear} title={tFormKey('Cierre de ejercicio')}>
        <FormControl className={styles.closingYearSelector} variant="outlined">
          <InputLabel id="accounting_year">{tFormKey('Ejercicio')}</InputLabel>
          <Select
            label={tFormKey('Ejercicio')}
            value={closingYear}
            onChange={e => setClosingYear(e.target.value as number)}
            variant="outlined"
          >
            {accounting_years.map(element => (
              <MenuItem key={element} value={element}>
                {element}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          className={styles.closingYearButton}
          color="primary"
          variant="contained"
          onClick={() => closeInvoicesByYear(closingYear || 0)}
        >
          {tKey('Cerrar')}
        </Button>
      </FormContainer>
    );
  }
  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSave),
      startIcon: <Save />,
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
    },
  ];

  return (
    <>
      <LayoutForm
        rightSubmenu={<ActionsMenu actionsButtons={buttonsEdit} />}
        leftSubmenu={<AccountingSubmenu selected="configuration" />}
      >
        {loading ? (
          <Loading big />
        ) : (
          <>
            <Title>{tKey('Configuración')}</Title>
            <FormContainer title={tKey('Pagos')}>
              <TextInputController
                control={control}
                errors={errors}
                schema={AccountSettingsSchema}
                defaultValue={payment_control_day}
                label={tFormKey('Control de pago')}
                name="payment_control_day"
                size="50"
                type="number"
              />
              <TextInputController
                control={control}
                errors={errors}
                type="number"
                schema={AccountSettingsSchema}
                defaultValue={payment_day}
                label={tFormKey('Fecha de pago')}
                name="payment_day"
                size="50"
              />
            </FormContainer>
            {closingYearSection}
          </>
        )}
      </LayoutForm>
      ;)
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  accountingSettings: selectAccountingSettings(state),
  loading: selectLoading(state),
  showCloseAccounting: selectUserCan(state)('allowCloseAccountingYear'),
});

const mapDispatchToProps = (dispatch: AccountingDispatch) => ({
  closeInvoicesByYear: (closing_year: number) => dispatch(closeInvoices(closing_year)),
  getAccountingSettings: () => dispatch(getAccountingSettings()),
  editAccountingSettings: (data: AccountingSettings) => dispatch(editAccountingSettings(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configuration);
