import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';
import { getMetadata } from '../../../redux/aboutUs/actions';
import { AboutUsDispatch } from '../../../redux/aboutUs/definitions';
import { selectMetadata } from '../../../redux/aboutUs/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';

interface Props {
  selected: string;
  submenu: string;
}

const SubmenuConfiguration: React.FC<Props> = ({ selected, submenu }) => {
  const { t } = useTranslation();

  const optionsSubmenu: SubmenuItem[] = [
    {
      title: tKey(t('Eventos')),
      route: '/eventos',
      type: 'route',
      selected: selected === 'eventos',
    },
    {
      title: tKey(t('Servicios')),
      route: '/servicios',
      type: 'route',
      selected: selected === 'servicios',
    },
  ];

  return (
    <Submenu
      titleMenu={tKey('Eventos y Servicios')}
      optionsMenu={optionsSubmenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  userCan: selectUserCan(state),
  canIconfigTranslations: selectUserCan(state)('allow_translation_config'),
  canIconfigMailTranslations: selectUserCan(state)('allow_mail_translation_config'),
  metadata: selectMetadata(state),
});

const mapDispatchToProps = (dispatch: AboutUsDispatch) => ({
  getMetadata: (): void => dispatch(getMetadata()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuConfiguration);
