import React from 'react';
import FncLabel from '../../Forms/FncLabel/FncLabel';
import styles from './fncTextArea.module.scss';
import FncControlErrorMessage from '../FncControlErrorMessage/FncControlErrorMessage';

export interface FncTextAreaProps extends React.ComponentPropsWithoutRef<'textarea'> {
  customClass?: string;
  label?: string;
  required?: boolean;
  errorMessage?: string;
}

const FncTextArea = ({ customClass, label, required = false, errorMessage, ...props }: FncTextAreaProps) => {
  return (
    <div className={`${styles.root} ${customClass ? customClass : ''} ${errorMessage ? styles.hasError : ''}`}>
      {label && (
        <FncLabel required={required} htmlFor={props.name}>
          {label}
        </FncLabel>
      )}
      <textarea id={props.name} className={styles.textarea} {...props}></textarea>
      {errorMessage && <FncControlErrorMessage message={errorMessage} />}
    </div>
  );
};

export default FncTextArea;
