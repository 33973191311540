import storeProvider from '../../storeProvider';
import { IdNamePair } from '../common/definitions';
import { AreasConst } from './action_types';
import {
  AddRoleAreaAction,
  AddRoleAreaKoAction,
  AddRoleAreaOkAction,
  AddSubareaAction,
  AddSubareaKoAction,
  AddSubareaOkAction,
  AreasData,
  AreasRoleData,
  CancelGetAreasAction,
  CloseAreaRoleModalAction,
  DeleteSubAreaAction,
  DeleteSubAreaKoAction,
  DeleteSubAreaOkAction,
  DisableAreaAction,
  DisableAreaKoAction,
  DisableAreaOkAction,
  EditAreaAction,
  EditAreaKoAction,
  EditAreaOkAction,
  EditSubAreaAction,
  EditSubAreaKoAction,
  EditSubAreaOkAction,
  GetAreasAction,
  GetAreasKoAction,
  GetAreasOkAction,
  GetAreasRolesAction,
  GetAreasRolesKoAction,
  GetAreasRolesOkAction,
  GetRolesAreasAction,
  GetRolesAreasKoAction,
  GetRolesAreasOkAction,
  OpenAreaRoleModalAction,
  RemoveAreasRolesAction,
  RemoveAreasRolesKoAction,
  RemoveAreasRolesOkAction,
  StoreAreasAction,
  StoreAreasKoAction,
  StoreAreasOkAction,
  SubAreaInfo,
} from './definitions';

// GET AREAS

export function getAreas(): GetAreasAction {
  const areasField = storeProvider.getState().areasReducer.areasDataList;
  if (!areasField.length) {
    return {
      type: AreasConst.GET_AREAS,
    };
  } else {
    return {
      type: AreasConst.CANCEL_GET_AREAS,
    };
  }
}

export function getAreasOk({ data }: { data: AreasData[] }): GetAreasOkAction {
  return {
    type: AreasConst.GET_AREAS_OK,
    areasDataList: data,
  };
}

export function getAreasKo(error: string): GetAreasKoAction {
  return {
    type: AreasConst.GET_AREAS_KO,
    error,
  };
}

export function cancelGetAreasOk(): CancelGetAreasAction {
  return {
    type: AreasConst.CANCEL_GET_AREAS,
  };
}
//EDIT AREAS

export function editArea(id: number, data: AreasData): EditAreaAction {
  return {
    type: AreasConst.EDIT_AREA,
    id,
    data,
  };
}

export function editAreaOk(id: number, data: AreasData): EditAreaOkAction {
  return {
    type: AreasConst.EDIT_AREA_OK,
    id,
    data,
  };
}

export function editAreaKo(error: string): EditAreaKoAction {
  return {
    type: AreasConst.EDIT_AREA_KO,
    error,
  };
}
//DISABLE AREA
export function disableArea(a_id: number): DisableAreaAction {
  return {
    type: AreasConst.DISABLE_AREA,
    a_id,
  };
}

export function disableAreaOk(a_id: number): DisableAreaOkAction {
  return {
    type: AreasConst.DISABLE_AREA_OK,
    a_id,
  };
}

export function disableAreaKo(error: string): DisableAreaKoAction {
  return {
    type: AreasConst.DISABLE_AREA_KO,
    error,
  };
}
//GET AREAS ROLES
export function getAreasRoles(): GetAreasRolesAction {
  return {
    type: AreasConst.GET_ROLES_AREA,
  };
}

export function getAreasRolesOk(data: AreasRoleData[]): GetAreasRolesOkAction {
  return {
    type: AreasConst.GET_ROLES_AREA_OK,
    areasRoleList: data,
  };
}

export function getAreasRolesKo(error: string): GetAreasRolesKoAction {
  return {
    type: AreasConst.GET_ROLES_AREA_KO,
    error,
  };
}
//REMOVE AREA ROLES
export function removeAreasRoles(area_id: number, role_id: number): RemoveAreasRolesAction {
  return {
    type: AreasConst.REMOVE_ROLES_AREA,
    area_id,
    role_id,
  };
}

export function removeAreasRolesOk(area_id: number, role_id: number): RemoveAreasRolesOkAction {
  return {
    type: AreasConst.REMOVE_ROLES_AREA_OK,
    area_id,
    role_id,
  };
}

export function removeAreasRolesKo(error: string): RemoveAreasRolesKoAction {
  return {
    type: AreasConst.REMOVE_ROLES_AREA_KO,
    error,
  };
}
//GET ROLES

export function getRolesAreas(): GetRolesAreasAction {
  return {
    type: AreasConst.GET_ROLES_LIST,
  };
}

export function getRolesAreasOk(roleList: IdNamePair[]): GetRolesAreasOkAction {
  return {
    type: AreasConst.GET_ROLES_LIST_OK,
    roleList,
  };
}

export function getRolesAreasKo(error: string): GetRolesAreasKoAction {
  return {
    type: AreasConst.GET_ROLES_LIST_KO,
    error,
  };
}
//ADD ROLE AREA
export function addRoleArea(area_id: number, role_id: number): AddRoleAreaAction {
  return {
    type: AreasConst.ADD_ROLES_LIST,
    area_id,
    role_id,
  };
}

export function addRoleAreaOk(data: AreasRoleData[]): AddRoleAreaOkAction {
  return {
    type: AreasConst.ADD_ROLES_LIST_OK,
    data,
  };
}

export function addRoleAreaKo(error: string): AddRoleAreaKoAction {
  return {
    type: AreasConst.ADD_ROLES_LIST_KO,
    error,
  };
}

// DELETE SUBAREA

export function deleteSubArea(id: number): DeleteSubAreaAction {
  return {
    type: AreasConst.DELETE_SUB_AREA,
    id,
  };
}

export function deleteSubAreaOk(id: number): DeleteSubAreaOkAction {
  return {
    type: AreasConst.DELETE_SUB_AREA_OK,
    id,
  };
}

export function deleteSubAreaKo(error: string): DeleteSubAreaKoAction {
  return {
    type: AreasConst.DELETE_SUB_AREA_KO,
    error,
  };
}

// ADD SUBAREA

export function addSubarea(id: number, data: string): AddSubareaAction {
  return {
    type: AreasConst.ADD_SUBAREA,
    id,
    data,
  };
}

export function addSubareaOk({ data }: { data: SubAreaInfo }): AddSubareaOkAction {
  return {
    type: AreasConst.ADD_SUBAREA_OK,
    responseAdd: data,
  };
}

export function addSubareaKo(error: string): AddSubareaKoAction {
  return {
    type: AreasConst.ADD_SUBAREA_KO,
    error,
  };
}

// EDIT SUBAREA

export function editSubArea(id: number, data: string): EditSubAreaAction {
  return {
    type: AreasConst.EDIT_SUBAREA,
    id,
    data,
  };
}

export function editSubAreaOk(id: number, data: string): EditSubAreaOkAction {
  return {
    type: AreasConst.EDIT_SUBAREA_OK,
    id,
    data,
  };
}

export function editSubAreaKo(error: string): EditSubAreaKoAction {
  return {
    type: AreasConst.EDIT_SUBAREA_KO,
    error,
  };
}

export function storeAreas(name: string): StoreAreasAction {
  return {
    type: AreasConst.STORE_AREAS,
    name,
  };
}

export function storeAreasOk({ data }: { data: AreasData }): StoreAreasOkAction {
  return {
    type: AreasConst.STORE_AREAS_OK,
    areasData: data,
  };
}

export function storeAreasKo(error: string): StoreAreasKoAction {
  return {
    type: AreasConst.STORE_AREAS_KO,
    error,
  };
}

export function openAreaRoleModal(): OpenAreaRoleModalAction {
  return {
    type: AreasConst.OPEN_AREA_ROLES_MODAL,
  };
}

export function closeAreaRoleModal(): CloseAreaRoleModalAction {
  return {
    type: AreasConst.CLOSE_AREA_ROLES_MODAL,
  };
}
