import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { tKey } from '../../helpers/translate';
import requiredStyle from '../../helpers/validator.module.scss';
import { RadioOptions } from './RadioButtonOptions';
import styles from './radioButtons.module.scss';

interface RadioButtonGroupProps {
  questionText?: string;
  name: string;
  options: RadioOptions[];
  disabled?: boolean;
  validator?: boolean;
  value: string | number | null;
  optionModal?: boolean;
  errors?: FieldError;
  row?: boolean;
  checked?: boolean;
  register?: (instance: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  questionText,
  name,
  options,
  disabled,
  value,
  validator,
  optionModal,
  errors,
  register,
  onChange,
  ...props
}) => {
  const optionsFiltered = options.filter((radioOption: RadioOptions) => !radioOption.hiddenT);
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <div className={optionModal ? styles.optionsModal : styles.questionText}>
          {!!validator && (
            <FiberManualRecordSharpIcon fontSize="small" classes={{ root: requiredStyle.requiredRadio }} />
          )}
          {questionText}
          {errors && <FormHelperText error>{errors.message}</FormHelperText>}
        </div>
      </FormLabel>
      <RadioGroup
        aria-label="position"
        name={name}
        value={value}
        onChange={event => {
          onChange && onChange(event);
        }}
        row
      >
        {optionsFiltered.map(option => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              className={optionModal ? styles.optionsModal : styles.options}
              checked={option.checked}
              disabled={disabled}
              inputRef={register}
              control={<Radio color={option.color || 'primary'} />}
              label={tKey(option.label)}
              labelPlacement={option.labelPlacement || 'end'}
              {...props}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonGroup;
