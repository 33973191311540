import React from 'react';
import Layout from '../../components/Layout/Layout';
import styles from './commercialCommunications.module.scss';

const CommercialCommunications: React.FC<{}> = () => (
  <Layout layoutDisabled={true}>
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>
          Comunicación <br /> Comercial
        </h1>
        <section className={styles.content}>
          <p>
            De acuerdo con lo que dispone el <i>Reglamento (UE) 2016/679 de 27 de abril de 2016 (RGPD)</i> y demás
            normativa vigente en materia de protección de datos personales, les informamos que los datos que figuran en
            la presente comunicación están incluidos en un fichero de tratamiento de datos propiedad de
            <strong> FUNDACIÓ PRIVADA NOUS CIMS</strong>. Dichos datos serán utilizados exclusivamente para el envío de
            comunicaciones que pueden ser de su interés. Sus datos no se cederán a terceros, salvo por obligación legal,
            y se mantendrán mientras no solicite su cancelación u oposición. La presente comunicación comercial se envía
            bajo el amparo de la
            <i> Ley 34/2002, de Servicios de la Sociedad de la información y de Comercio Electrónico</i>.
          </p>
          <p>
            Si desea dejar de recibir comunicaciones comerciales, revocar su consentimiento o ejercer los derechos de
            acceso, rectificación, portabilidad, oposición, limitación o cancelación, puede solicitar, en cualquier
            momento, enviando un correo electrónico a la dirección{' '}
            <span className={styles.highlight}>info@nouscims.com</span> indicando en el asunto &quot;Baja comunicaciones
            comerciales&quot;, o comunicándolo por escrito, siempre indicando sus datos personales a C/Entença nº332
            08029, BARCELONA. Si considera que el tratamiento de datos personales no se ajusta a la normativa vigente,
            puede presentar una reclamación ante la{' '}
            <a href="www.aepd.es" target="_blank" rel="noopener noreferrer">
              Autoridad de control (www.aepd.es).
            </a>
          </p>
        </section>
      </div>
    </div>
  </Layout>
);

export default CommercialCommunications;
