import { ButtonProps } from '@material-ui/core';
import {
  HelpOutline,
  Save as SaveIcon,
  AddCircleOutline as AddIcon,
  RemoveCircleOutline as RemoveIcon,
} from '@material-ui/icons';
import * as H from 'history';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import { addUserToZingNetworkFromApi, removeUserFromZingNetwork } from '../../../../api/user';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../../components/Loading/Loading';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import Title from '../../../../components/Title/Title';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tErrorKey, tKey } from '../../../../helpers/translate';
import { selectUserLang } from '../../../../redux/auth/selectors';
import { DocumentationItem, IdCodeNamePair } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import {
  acceptUserEducator,
  downloadDocument,
  getDocument,
  rejectUserEducator,
  removeDocument,
  updateProfileUserTable,
  uploadDocument,
  userSetData,
} from '../../../../redux/user/actions';
import { UpdateUserFormFields, UserData, UserDataDispatch } from '../../../../redux/user/definitions';
import { selectError, selectUserByRoleGroup, selectUserValidationErrors } from '../../../../redux/user/selectors';
import { EditUserSchema } from '../../../../validations/formSchema';
import styles from '../../editUser.module.scss';
import AccessDataForm from '../../FormLayouts/AccessDataForm';
import PersonalDataForm from '../../FormLayouts/PersonalDataForm';
import StudentDataForm from '../../FormLayouts/StudentDataForm';
import SubmenuStudent from '../../SubmenuStudent/SubmenuStudent';
import RoleCode from '../../../../types/Roles/roleCode';

interface PersonalDataProps {
  readOnly: boolean;
  id: string;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  documents: DocumentationItem[];
  location: H.Location<{ needsValidation: boolean }>;
  profileData: UserData;
  genericMetadata: GenericMetadata;
  validationErrors: APIValidationError | null;
  roles: IdCodeNamePair[];
  defaultLang: string;
  hasZingNetworkPermission: boolean;
  acceptEducator: (id: number) => void;
  rejectEducator: (id: number) => void;
  updateTableUser: (data: UpdateUserFormFields, id: number) => void;
  uploadUserDocument: (document_id: number, data: File) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
  handleSetImageById: (image: string) => void;
  onSelectComponent: (componentId: number) => void;
  setUserData: (key: string, value: boolean) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const PersonalData: React.FC<PersonalDataProps> = ({
  readOnly,
  avatar,
  profileData,
  loading,
  documents,
  location,
  id,
  roles,
  genericMetadata,
  validationErrors,
  changeAvatar,
  defaultLang,
  hasZingNetworkPermission,
  updateTableUser,
  uploadUserDocument,
  downloadUserDocument,
  removeUserDocument,
  getUserDocument,
  handleSetImageById,
  onSelectComponent,
  setUserData,
  showSnackbar,
}) => {
  const { errors, handleSubmit, watch, clearError, setValue, setError, triggerValidation, control } = useForm<
    UpdateUserFormFields
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EditUserSchema,
  });
  const roleCode = new RoleCode(profileData.role_code);
  const actions = ['checked', 'download', 'view', 'remove'];
  const [createdModal, setCreatedModal] = useState({
    view: false,
    children: <Fragment />,
    title: tKey('Ayuda'),
    body: <div className={styles.modalHelp}>{tKey('mensaje personal')}</div>,
    startIcon: <HelpOutline />,
  });
  const [showZingNetworkModal, setShowZingNetworkModal] = useState<boolean>(false);
  useValidatorAPI(validationErrors, setError);

  const buttonsValidations: ButtonProps[] = [
    {
      children: profileData.zing_network ? tKey('delete-from-zing-network') : tKey('add-zing-network'),
      onClick: () => {
        if (profileData.zing_network) {
          removeUserFromZingNetwork(profileData.id)
            .then(() => {
              setUserData('zing_network', false);
              showSnackbar(tKey('successful-remove-user-from-zing-network'), 'success', undefined, 1500);
            })
            .catch(() => showSnackbar(tErrorKey('Error'), 'error', undefined, 1500));
        } else {
          setShowZingNetworkModal(true);
        }
      },
      startIcon: profileData.zing_network ? <RemoveIcon /> : <AddIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      hidden: readOnly && !hasZingNetworkPermission,
    },
    {
      children: tKey('ACTUALIZAR DATOS '),
      onClick: handleSubmit((data: UpdateUserFormFields) => {
        if (!Object.keys(errors).length) {
          if (data.birthdate) {
            //@ts-ignore
            data.birthdate = data.birthdate.valueOf() / 1000;
          }
          updateTableUser(data, +id);
        } else {
          for (const e of Object.keys(errors)) {
            // @ts-ignore
            setError(e, errors[e].types, errors[e].message);
          }
        }
      }),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      hidden: readOnly,
    },
  ];

  const moreInfoButtons: ButtonProps[] = [
    {
      children: tKey('AYUDA'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      onClick: () =>
        setCreatedModal(prevState => ({
          ...prevState,
          view: true,
        })),
      startIcon: <HelpOutline />,
      hidden: location.state.needsValidation && profileData.state !== 'DISABLED' ? false : true,
    },
  ];

  let studentInfo;
  if (roleCode.isStudent()) {
    studentInfo = (
      <StudentDataForm
        errors={errors}
        control={control}
        profileData={profileData}
        incomesTypes={genericMetadata.incomesTypes}
        contractTypes={genericMetadata.contractTypes}
        readOnly={readOnly}
        watch={watch}
      />
    );
  }

  const handleAddToZingNewteork = (sendWelcomeMail: boolean) => {
    addUserToZingNetworkFromApi(profileData.id, sendWelcomeMail)
      .then(() => {
        setUserData('zing_network', true);
        showSnackbar(tKey('successful-add-user-to-zing-network'), 'success', undefined, 1500);
      })
      .catch(() => showSnackbar(tErrorKey('Error'), 'error', undefined, 1500));
    setShowZingNetworkModal(false);
  };

  const title = readOnly ? tKey('DETALLES  DE USUARIO') : tKey('EDICIÓN DE USUARIO');
  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} moreInfoButtons={moreInfoButtons} />}
      leftSubmenu={
        <SubmenuStudent
          selected="datos_personales"
          avatar={avatar}
          loading={loading}
          onSelectComponent={onSelectComponent}
          setImage={changeAvatar ? handleSetImageById : undefined}
        />
      }
    >
      <>
        {!loading ? (
          <form>
            <Title>{title}</Title>
            <AccessDataForm
              profileData={profileData}
              control={control}
              schema={EditUserSchema}
              errors={errors}
              readOnly={readOnly}
            />
            <PersonalDataForm
              setError={setError}
              clearError={clearError}
              profileData={profileData}
              genericMetadata={genericMetadata}
              errors={errors}
              setValue={setValue}
              roles={roles}
              schema={EditUserSchema}
              readOnly={readOnly}
              triggerValidation={triggerValidation}
              control={control}
              watch={watch}
              defaultLang={defaultLang}
            />
            {studentInfo}
            {documents?.length > 0 && (
              <FncAddDocuments
                documents={documents}
                actions={actions}
                title={tKey('Documentos')}
                upload={uploadUserDocument}
                download={downloadUserDocument}
                remove={removeUserDocument}
                getFile={getUserDocument}
              />
            )}
          </form>
        ) : (
          <Loading big />
        )}
      </>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        maxWidth={true}
        bodyModal={createdModal.body}
      >
        {createdModal.children}
      </TransitionModal>

      <TransitionModal
        view={showZingNetworkModal}
        title={tKey('add-zing-network')}
        bodyModal=""
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        handleClose={() => setShowZingNetworkModal(false)}
        handleYes={() => handleAddToZingNewteork(true)}
        handleNo={() => handleAddToZingNewteork(false)}
      >
        <p style={{ padding: '0 10px' }}>{tKey('confirmation-msg-add-user-to-zing-network')}</p>
      </TransitionModal>
    </LayoutForm>
  );
};
interface OwnProps {
  location: H.Location<{ type: string }>;
}

const mapStateToProps = (
  state: AppState,
  {
    location: {
      state: { type },
    },
  }: OwnProps,
) => ({
  error: selectError(state),
  validationErrors: selectUserValidationErrors(state),
  roles: selectUserByRoleGroup(state, type),
  genericMetadata: selectGenericMetadata(state),
  defaultLang: selectUserLang(state),
  hasZingNetworkPermission: selectUserCan(state)('allow_register_user_network'),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  updateTableUser: (data: UpdateUserFormFields, id: number): void => dispatch(updateProfileUserTable(data, id)),
  uploadUserDocument: (document_id: number, data: File): void => dispatch(uploadDocument(document_id, data)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
  acceptEducator: (id: number): void => dispatch(acceptUserEducator(id)),
  rejectEducator: (id: number): void => dispatch(rejectUserEducator(id)),
  setUserData: (key: string, value: boolean) => dispatch(userSetData(key, value)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
