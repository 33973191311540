import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Moment } from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { tErrorKey, tFormKey } from '../../../helpers/translate';
import KeyboardDatePickerController from '../../DatePicker/KeyboardDatePickerController';
import styles from './modalDatePicker.module.scss';

interface ModalSearchProps {
  view: boolean;
  title: string;
  buttonLabelOk: string;
  buttonLabelKo: string;
  label: string;
  defaultValue?: string | number | Moment | null;
  required?: boolean;
  onApplying: (data: string) => void;
  handleClose: () => void;
}

export type ModalApplicationFields = {
  date: string;
};
const requiredText = tErrorKey('campo-obligatorio');

const RequiredModalDatePickerSchema = yup.object().shape({
  date: yup.string().typeError(requiredText),
});

const ModalDatePickerSchema = yup.object().shape({
  date: yup.string().nullable(),
});

const UnConnectedModalDatePicker: React.FC<ModalSearchProps> = ({
  view,
  title,
  buttonLabelOk,
  buttonLabelKo,
  label,
  defaultValue,
  required,
  onApplying,
  handleClose,
}) => {
  const validationSchema = required ? RequiredModalDatePickerSchema : ModalDatePickerSchema;
  const { errors, handleSubmit, control } = useForm<ModalApplicationFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema,
  });

  const handleApplying = (data: ModalApplicationFields) => {
    onApplying(data.date);
  };

  return (
    <div>
      <Dialog
        open={view}
        onClose={handleClose}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        closeAfterTransition
      >
        <div className={styles.title}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <IconButton aria-label="delete" size="small" onClick={() => handleClose()}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
        <DialogContent className={styles.dialogContent}>
          <form className={styles.form}>
            <KeyboardDatePickerController
              label={tFormKey(label)}
              name="date"
              control={control}
              schema={validationSchema}
              errors={errors}
              defaultValue={defaultValue}
              size="50"
            />
            <div className={styles.btn_create}>
              <Button color="primary" variant="outlined" onClick={handleSubmit(handleApplying)}>
                {buttonLabelOk}
              </Button>
              <Button color="primary" variant="outlined" onClick={() => handleClose()}>
                {buttonLabelKo}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UnConnectedModalDatePicker;
