import { Action } from 'redux';
import { EducatorConst } from './action_types';
import { Educator } from './Educator.d';

export interface AddEducatorAction extends Action {
  type: EducatorConst.ADD_EDUCATOR;
  educator: Educator;
}

export interface AddEducatorActionOk extends Action {
  type: EducatorConst.ADD_EDUCATOR_OK;
  educator: Educator;
}

export interface AddEducatorActionKo extends Action {
  type: EducatorConst.ADD_EDUCATOR_KO;
}

export interface GetEducatorsAction extends Action {
  type: EducatorConst.GET_EDUCATORS;
  id: InternalProjectId;
}

export interface GetEducatorsOkAction extends Action {
  type: EducatorConst.GET_EDUCATORS_OK;
  data: Educator[];
}

export interface GetEducatorsKoAction extends Action {
  type: EducatorConst.GET_EDUCATORS_KO;
  error: string;
}

export interface DeleteEducatorAction extends Action {
  type: EducatorConst.DELETE_EDUCATORS;
  internal_project_id: InternalProjectId;
  educatorId: EducatorId;
}

export interface DeleteEducatorOkAction extends Action {
  type: EducatorConst.DELETE_EDUCATORS_OK;
  internal_project_id: InternalProjectId;
  educatorId: EducatorId;
}

export interface DeleteEducatorKoAction extends Action {
  type: EducatorConst.DELETE_EDUCATORS_KO;
  error: string;
}

export interface UpdateEducatorAction extends Action {
  type: EducatorConst.UPDATE_EDUCATOR;
  educator: Educator;
}

export interface UpdateEducatorOkAction extends Action {
  type: EducatorConst.UPDATE_EDUCATOR_OK;
  updatedEducator: Educator;
}

export interface UpdateEducatorKoAction extends Action {
  type: EducatorConst.UPDATE_EDUCATOR_KO;
  error: string;
}

export type EducatorAction =
  | AddEducatorAction
  | AddEducatorActionOk
  | AddEducatorActionKo
  | GetEducatorsAction
  | GetEducatorsOkAction
  | GetEducatorsKoAction
  | DeleteEducatorAction
  | DeleteEducatorOkAction
  | DeleteEducatorKoAction
  | UpdateEducatorAction
  | UpdateEducatorOkAction
  | UpdateEducatorKoAction;

export const initialState = {
  loading: false,
  error: null as string | null,
  data: [] as Educator[],
};
export type EducatorState = typeof initialState;
