import React, { useState } from 'react';
import { AppState } from '../../../redux/root-reducer';
import { connect } from 'react-redux';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { InvoiceData, InvoiceDetail, InvoiceStates } from '../../../redux/accounting/definitions';
import { MaterialTableProps } from 'material-table';
import { tFormKey, tKey } from '../../../helpers/translate';
import { formatNumber } from '../../../helpers/formatNumber';
import InvoiceDetailsForm from './InvoiceDetailsForm';
import { deleteInvoiceDetail, resetInvoiceDetail } from '../../../redux/accounting/actions';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { selectInvoice } from '../../../redux/accounting/selectors';

export type columnsInvoiceTableDetails = 'concepto' | 'base' | 'tipo_iva' | 'importe_iva' | 'importe' | 'gasto';
const createTableData = (
  columns: columnsInvoiceTableDetails[],
  invoice_details: InvoiceDetail[],
): MaterialTableProps<InvoiceDetail> => {
  return {
    title: tFormKey('Detalle de factura'),
    columns: [
      {
        hidden: !columns.includes('concepto'),
        field: 'description',
        title: tFormKey('Concepto'),
      },
      {
        field: 'base_amount',
        title: tFormKey('Base'),
        type: 'numeric',
        render: (rowData: InvoiceDetail) => <div>{formatNumber({ value: rowData.base_amount })}</div>,
        //@ts-ignore
        width: '150px',
        hidden: !columns.includes('base'),
      },
      {
        hidden: !columns.includes('tipo_iva'),
        field: 'tax_retention_code',
        title: tFormKey('Tipo de IVA'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceDetail) => (
          <div
            style={{ textAlign: 'center' }}
          >{`${rowData.tax_retention_code} (${rowData.tax_retention_percentage}%)`}</div>
        ),
        //@ts-ignore
        width: '200px',
      },
      {
        hidden: !columns.includes('importe_iva'),
        field: 'tax_retention_amount',
        title: tFormKey('Importe de IVA'),
        type: 'numeric',
        render: (rowData: InvoiceDetail) => <div>{formatNumber({ value: rowData.tax_retention_amount })}</div>,
        //@ts-ignore
        width: '180px',
      },
      {
        hidden: !columns.includes('importe'),
        field: 'detail_amount',
        title: tFormKey('Importe'),
        type: 'numeric',
        render: (rowData: InvoiceDetail) => <div>{formatNumber({ value: rowData.detail_amount })}</div>,
        //@ts-ignore
        width: '150px',
      },
      {
        hidden: !columns.includes('gasto'),
        field: 'expense_account_detail',
        title: tFormKey('Cuenta de gasto'),
      },
    ],
    data: invoice_details,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  } as MaterialTableProps<InvoiceDetail>;
};
interface Props {
  readOnly: boolean;
  disableInvoice: boolean;
  canModifyInvoice: boolean;
  columns?: columnsInvoiceTableDetails[];
  invoiceData: InvoiceData;
  onSubmitOk?: () => void;
  deleteInvoiceDetail: (invoiceDetail: InvoiceDetail) => void;
  clearInvoiceDetail: () => void;
}

const InvoiceTableDetails = ({
  readOnly,
  disableInvoice,
  canModifyInvoice,
  columns,
  invoiceData,
  onSubmitOk,
  deleteInvoiceDetail,
  clearInvoiceDetail,
}: Props) => {
  const [invoiceDetailsModal, setInvoiceDetailsModal] = useState<{
    show: boolean;
    invoice_detail_id: number | undefined;
  }>({ show: false, invoice_detail_id: undefined });

  const columnsToShow: columnsInvoiceTableDetails[] = columns
    ? columns
    : ['concepto', 'base', 'tipo_iva', 'importe_iva', 'importe', 'gasto'];
  const { invoice_details, state_code } = invoiceData;

  const tableData = createTableData(columnsToShow, invoice_details);

  let optionsNew;
  let onEditOne;
  let deleteFunc;

  if (!readOnly) {
    optionsNew = {
      newButtonText: tFormKey('Nueva línea'),
      onNew: () => {
        clearInvoiceDetail();
        setInvoiceDetailsModal({ show: true, invoice_detail_id: undefined });
      },
    };

    if (state_code === InvoiceStates.CREACION || state_code === InvoiceStates.PENDIENTE) {
      deleteFunc = (rowData: InvoiceDetail) => deleteInvoiceDetail(rowData);
    }
  }

  if (canModifyInvoice || !readOnly) {
    onEditOne = (event: React.MouseEvent<Element, globalThis.MouseEvent>, rowData: any) => {
      const { id } = rowData;
      setInvoiceDetailsModal({ show: true, invoice_detail_id: parseInt(id) });
    };
  }

  const onSavedInvoiceDetail = () => {
    setInvoiceDetailsModal({ ...invoiceDetailsModal, show: false });
    if (onSubmitOk) {
      onSubmitOk();
    }
  };

  const onCloseModal = () => {
    setInvoiceDetailsModal({ ...invoiceDetailsModal, show: false });
  };

  return (
    <>
      <TableWithEdit
        customContainer
        tableData={{ ...tableData }}
        key={JSON.stringify(tableData.data)}
        deleteFunc={deleteFunc}
        optionsNew={optionsNew}
        onEditOne={onEditOne}
        permission
      />
      <TransitionModal
        view={invoiceDetailsModal.show}
        handleClose={onCloseModal}
        bodyModal=" "
        title={tKey('Nueva línea')}
      >
        <InvoiceDetailsForm
          invoiceId={invoiceData.id}
          invoiceDetailId={invoiceDetailsModal.invoice_detail_id}
          disableValues={disableInvoice}
          onSaved={onSavedInvoiceDetail}
        />
      </TransitionModal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  invoiceData: selectInvoice(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  deleteInvoiceDetail: (invoiceDetail: InvoiceDetail) => dispatch(deleteInvoiceDetail(invoiceDetail)),
  clearInvoiceDetail: () => dispatch(resetInvoiceDetail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceTableDetails);
