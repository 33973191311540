import { ButtonProps } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import * as H from 'history';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { APIValidationError } from '../../../../api/api';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../../components/Loading/Loading';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import Title from '../../../../components/Title/Title';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import {
  acceptUserEducator,
  downloadDocument,
  getDocument,
  rejectUserEducator,
  removeDocument,
  updateProfileUserTable,
  uploadDocument,
} from '../../../../redux/user/actions';
import { UpdateUserFormFields, UserData, UserDataDispatch } from '../../../../redux/user/definitions';
import { selectError, selectUserValidationErrors } from '../../../../redux/user/selectors';
import { EditUserSchema } from '../../../../validations/formSchema';
import styles from '../../editUser.module.scss';
import SubmenuStudent from '../../SubmenuStudent/SubmenuStudent';
import RoleCode from '../../../../types/Roles/roleCode';

interface PersonalDataProps {
  readOnly: boolean;
  id: string;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  documents: DocumentationItem[];
  location: H.Location<{ needsValidation: boolean }>;
  profileData: UserData;
  validationErrors: APIValidationError | null;
  acceptEducator: (id: number) => void;
  rejectEducator: (id: number) => void;
  updateTableUser: (data: UpdateUserFormFields, id: number) => void;
  uploadUserDocument: (document_id: number, data: File) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
  handleSetImageById: (image: string) => void;
  onSelectComponent: (componentId: number) => void;
}

const PersonalData: React.FC<PersonalDataProps> = ({
  readOnly,
  avatar,
  profileData,
  loading,
  location,
  id,
  validationErrors,
  changeAvatar,
  updateTableUser,
  handleSetImageById,
  onSelectComponent,
}) => {
  const history = useHistory();
  const { errors, handleSubmit, setError, control } = useForm<UpdateUserFormFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EditUserSchema,
  });
  const roleCode = new RoleCode(profileData.role_code);
  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: 'Ayuda',
    body: (
      <div className={styles.modalHelp}>
        {tKey(
          'En este apartado podrás visualizar a todos los usuarios de tu entidad. Para que puedan acceder a la gestión de becas, estos usuarios deben ser validados por el responsable de la entidad.',
        )}
      </div>
    ),
    startIcon: <HelpOutline />,
  });
  useValidatorAPI(validationErrors, setError);

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('ACTUALIZAR DATOS'),
      onClick: handleSubmit((data: UpdateUserFormFields) => {
        if (!Object.keys(errors).length) {
          updateTableUser(data, +id);
        } else {
          for (const e of Object.keys(errors)) {
            // @ts-ignore
            setError(e, errors[e].types, errors[e].message);
          }
        }
      }),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      hidden: readOnly,
    },
  ];

  const moreInfoButtons: ButtonProps[] = [
    {
      children: tKey('Ver Entidad'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      onClick: () => history.push(`/entidad/detalles/${profileData.entity_id}`),
      hidden: !profileData.entity_id,
    },
    {
      children: tKey('AYUDA'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      onClick: () =>
        setCreatedModal(prevState => ({
          ...prevState,
          view: true,
        })),
      startIcon: <HelpOutline />,
      hidden: location.state.needsValidation && profileData.state !== 'DISABLED' ? false : true,
    },
  ];
  const title = readOnly ? tKey('DETALLES  DE USUARIO') : tKey('EDICIÓN DE USUARIO');
  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} moreInfoButtons={moreInfoButtons} />}
      leftSubmenu={
        <SubmenuStudent
          selected="entity"
          avatar={avatar}
          loading={loading}
          onSelectComponent={onSelectComponent}
          setImage={changeAvatar ? handleSetImageById : undefined}
        />
      }
    >
      <React.Fragment>
        {!loading ? (
          <form>
            <Title>{title}</Title>
            <FormContainer title={roleCode.isJovenEntidad() ? 'Entidad' : 'Instituto'}>
              {roleCode.isJovenEntidad() && (
                <>
                  <TextInputController
                    disabled
                    name="entity_name"
                    label={tFormKey('Nombre')}
                    schema={EditUserSchema}
                    control={control}
                    errors={errors}
                    size="50"
                    defaultValue={profileData.entity_name}
                  />
                  <TextInputController
                    disabled
                    name="edu"
                    label={tFormKey('Educador')}
                    schema={EditUserSchema}
                    control={control}
                    errors={errors}
                    size="50"
                    defaultValue={profileData.educator_name}
                  />
                  <TextInputController
                    disabled
                    name="email_edu"
                    label={tFormKey('Email del educador')}
                    schema={EditUserSchema}
                    control={control}
                    errors={errors}
                    size="50"
                    defaultValue={profileData.educator_mail}
                  />
                </>
              )}
              {roleCode.isJovenInstituto() && (
                <TextInputController
                  disabled
                  name="institut"
                  label={tFormKey('Instituto')}
                  schema={EditUserSchema}
                  control={control}
                  errors={errors}
                  size="50"
                  defaultValue={profileData.entity_name}
                />
              )}
            </FormContainer>
          </form>
        ) : (
          <Loading big />
        )}
      </React.Fragment>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        maxWidth={true}
        bodyModal={createdModal.body}
      >
        {createdModal.children}
      </TransitionModal>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  error: selectError(state),
  validationErrors: selectUserValidationErrors(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  updateTableUser: (data: UpdateUserFormFields, id: number): void => dispatch(updateProfileUserTable(data, id)),
  uploadUserDocument: (document_id: number, data: File): void => dispatch(uploadDocument(document_id, data)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
  acceptEducator: (id: number): void => dispatch(acceptUserEducator(id)),
  rejectEducator: (id: number): void => dispatch(rejectUserEducator(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
