export enum MetadataConst {
  GET_GENERIC = 'GET:GENERIC',
  GET_GENERIC_OK = 'GET:GENERIC:OK',
  GET_GENERIC_KO = 'GET:GENERIC:KO',

  GET_PROJECT_METADATA = 'GET:PROJECT:METADATA',
  GET_PROJECT_METADATA_OK = 'GET:PROJECT:METADATA:OK',
  GET_PROJECT_METADATA_KO = 'GET:PROJECT:METADATA:KO',

  GET_RESPONSIBLES = 'GET:RESPONSIBLES',
  GET_RESPONSIBLES_OK = 'GET:RESPONSIBLES:OK',
  GET_RESPONSIBLES_KO = 'GET:RESPONSIBLES:KO',

  GET_TEAM = 'GET:TEAM',
  GET_TEAM_OK = 'GET:TEAM:OK',
  GET_TEAM_KO = 'GET:TEAM:KO',

  GET_ACCOUNTING_METADATA = 'GET:ACCOUNTING:METADATA',
  GET_ACCOUNTING_METADATA_OK = 'GET:ACCOUNTING:METADATA:OK',
  GET_ACCOUNTING_METADATA_KO = 'GET:ACCOUNTING:METADATA:KO',

  GET_SCHOLARSHIP_METADATA = 'GET:SCHOLARSHIP:METADATA',
  GET_SCHOLARSHIP_METADATA_OK = 'GET:SCHOLARSHIP:METADATA:OK',
  GET_SCHOLARSHIP_METADATA_KO = 'GET:SCHOLARSHIP:METADATA:KO',

  GET_SCHOLARSHIP_ACADEMIES_METADATA = 'GET:SCHOLARSHIP:ACADEMIES:METADATA',
  GET_SCHOLARSHIP_ACADEMIES_METADATA_OK = 'GET:SCHOLARSHIP:METADATA:ACADEMIES:OK',
  GET_SCHOLARSHIP_ACADEMIES_METADATA_KO = 'GET:SCHOLARSHIP:METADATA:ACADEMIES:KO',

  GET_SCHOLARSHIP_UNIVERSITIES_METADATA = 'GET:SCHOLARSHIP:UNIVERSITIES:METADATA',
  GET_SCHOLARSHIP_UNIVERSITIES_METADATA_OK = 'GET:SCHOLARSHIP:METADATA:UNIVERSITIES:OK',
  GET_SCHOLARSHIP_UNIVERSITIES_METADATA_KO = 'GET:SCHOLARSHIP:METADATA:UNIVERSITIES:KO',
}
