import { put, takeLatest } from '@redux-saga/core/effects';
import { deleteFamilyIncomes } from '../../../../api/Scholarship/familyIncomes';
import errorMessage from '../../../../helpers/errorMessage';
import { tKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../FeedbackAPI/actions';
import { DeleteFamilyIncomeConst } from './action_types';
import { removeFamilyIncomeOk, removeFamilyIncomeKo } from './actions';
import { RemoveFamilyIncomeAction } from './definitions';

function* deleteFamilyIncomeFromSaga(action: RemoveFamilyIncomeAction) {
  try {
    const { income } = action;
    yield deleteFamilyIncomes(income);
    yield put(removeFamilyIncomeOk(income));
    yield put(showSnackbar(tKey('Ingreso eliminado.'), 'success', undefined, 1500));
  } catch (error) {
    yield put(showSnackbar(tKey(errorMessage(error)), 'error', undefined, 1500));
    yield put(removeFamilyIncomeKo(errorMessage(error)));
  }
}

export default takeLatest<RemoveFamilyIncomeAction>(
  DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME,
  deleteFamilyIncomeFromSaga,
);
