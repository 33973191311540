import { getDocs } from '../../helpers/getDocs';
import { EventDataConsts } from './action_types';
import { initialState, EventDataAction, EventSubscriptionData } from './definitions';

export type EventDataState = typeof initialState;

const registrationsReducer = (state: EventSubscriptionData, action: EventDataAction): EventSubscriptionData => {
  switch (action.type) {
    case EventDataConsts.SET_EVENT_ASSISTANCE:
      if (action.code === 'jovenes' && state.student !== 'No') {
        return {
          ...state,
          assistance: action.attended,
        };
      } else if (action.code !== 'jovenes' && state.student === 'No') {
        return {
          ...state,
          assistance: action.attended,
        };
      } else {
        return state;
      }

    case EventDataConsts.SET_EVENT_REGISTRATION_DATA:
      const { key: reg_key, value: reg_value, id } = action;

      if (state.id === id) {
        return {
          ...state,
          [reg_key]: reg_value,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

const eventReducer = (state = initialState, action: EventDataAction): EventDataState => {
  switch (action.type) {
    case EventDataConsts.UPDATE_EVENT_TABLE:
    case EventDataConsts.GET_EVENT_DATA:
    case EventDataConsts.GET_EVENT_REGISTRATIONS:
      return {
        ...state,
        loading: true,
        validationErrors: null,
      };
    case EventDataConsts.UPLOAD_DOCUMENT_EVENT_OK:
    case EventDataConsts.REMOVE_DOCUMENT_EVENT_OK:
      const { documents } = action;
      return {
        ...state,
        eventData: {
          ...state.eventData,
          documents,
        },
      };

    case EventDataConsts.GET_EVENT_DATA_OK:
      const { eventData } = action;

      return {
        ...state,
        loading: false,
        eventData: eventData,
      };
    case EventDataConsts.UPDATE_EVENT_TABLE_OK:
      const { eventData: _eventData } = action;
      return {
        ...state,
        loading: false,
        eventData: {
          ..._eventData,
          documents: state.eventData.documents,
        },
      };

    case EventDataConsts.EVENT_CREATION_DATA:
      const { key: ass_key, value: ass_value } = action;
      return {
        ...state,
        eventData: {
          ...state.eventData,
          [ass_key]: ass_value,
        },
      };

    case EventDataConsts.UPDATE_EVENT_TABLE_KO:
    case EventDataConsts.GET_EVENT_DATA_KO:
    case EventDataConsts.GET_EVENTS_KO:
    case EventDataConsts.GET_USER_EVENTS_KO:
    case EventDataConsts.GET_CONNECTION_URL_KO:
    case EventDataConsts.GET_USER_REGISTRATION_DATA_KO:
    case EventDataConsts.GET_EVENT_SUBSCRIPTIONS_KO:
    case EventDataConsts.GET_EVENT_REGISTRATIONS_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };

    case EventDataConsts.GET_CONNECTION_URL_OK:
      const { url, startDate, endDate, desktopFile, full } = action;
      return {
        ...state,
        registrationIntervalData: {
          url,
          desktopFile,
          startDate,
          endDate,
          full,
        },
      };

    case EventDataConsts.GET_USER_REGISTRATION_DATA_OK:
      const { registrationData } = action;
      return {
        ...state,
        userRegistrationData: registrationData,
      };

    case EventDataConsts.GET_EVENTS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        subareas: action.subareas,
        eventsList: action.eventsList,
      };
    case EventDataConsts.GET_USER_EVENTS_OK:
      return {
        ...state,
        loading: false,
        eventsUserList: action.eventsUserList,
      };

    case EventDataConsts.GET_EVENT_SUBSCRIPTIONS_OK:
      return {
        ...state,
        eventSubscriptions: action.eventSubscriptionsList,
      };

    case EventDataConsts.GET_EVENT_REGISTRATIONS_OK:
      return {
        ...state,
        loading: false,
        eventRegistrations: action.eventRegistrationsList,
      };
    case EventDataConsts.DOWNLOAD_DOCUMENT_EVENT_OK:
      const { data, name, get } = action;
      getDocs(data, get, name);
      return {
        ...state,
      };
    case EventDataConsts.SET_EVENT_ASSISTANCE:
    case EventDataConsts.SET_EVENT_REGISTRATION_DATA:
      return {
        ...state,
        eventRegistrations: state.eventRegistrations.map(r => registrationsReducer(r, action)),
      };
    case EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA_OK:
    case EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA:
      return {
        ...state,
      };
    case EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA_KO:
      return {
        ...state,
        loading: false,
        validationErrors: action.error,
      };

    case EventDataConsts.CREATE_EVENT_REGISTRATION:
      return {
        ...state,
      };
    case EventDataConsts.CREATE_EVENT_REGISTRATION_OK:
      state.eventRegistrations.push({
        ...action.eventRegistrationData,
        lopd: +action.eventRegistrationData.lopd,
      } as EventSubscriptionData);
      return {
        ...state,
      };
    case EventDataConsts.CREATE_EVENT_REGISTRATION_KO:
      return {
        ...state,
        error,
      };
    case EventDataConsts.CREATE_EVENT_SUBSCRIPTION:
    case EventDataConsts.REMOVE_REGISTRATION:
    case EventDataConsts.REMOVE_SUBSCRIPTION:
    case EventDataConsts.UPLOAD_DOCUMENT_EVENT:
    case EventDataConsts.DOWNLOAD_DOCUMENT_EVENT:
    case EventDataConsts.REMOVE_DOCUMENT_EVENT:
      return {
        ...state,
      };
    case EventDataConsts.CREATE_EVENT_SUBSCRIPTION_OK:
      state.eventSubscriptions.push({
        ...action.eventSubscriptionData,
        lopd: +action.eventSubscriptionData.lopd,
      } as EventSubscriptionData);
      return {
        ...state,
      };
    case EventDataConsts.CREATE_EVENT_SUBSCRIPTION_KO:
    case EventDataConsts.REMOVE_SUBSCRIPTION_KO:
      return {
        ...state,
        error,
      };
    case EventDataConsts.REMOVE_REGISTRATION_OK:
      return {
        ...state,
        eventRegistrations: state.eventRegistrations.filter(
          eventRegistration => eventRegistration.id !== action.registrationId,
        ),
      };
    case EventDataConsts.REMOVE_SUBSCRIPTION_OK:
      return {
        ...state,
        eventSubscriptions: state.eventSubscriptions.filter(
          eventSubscription => eventSubscription.id !== action.registrationId,
        ),
      };
    default:
      return state;
  }
};

export default eventReducer;
