import { FormControlLabel, Switch, SwitchProps } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

interface SwitchControllerProps extends Omit<SwitchProps, 'name' | 'defaultValue' | 'onFocus'> {
  name: string;
  label: string;
  control: Control;
  defaultValue?: boolean;
  disabled?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const SwitchController = ({
  name,
  control,
  defaultValue,
  label,
  onFocus,
  ...props
}: SwitchControllerProps): ReactElement => (
  <Controller
    as={<FormControlLabel label={label} control={<Switch color="primary" {...props} />} />}
    name={name}
    control={control}
    defaultValue={defaultValue}
    onFocus={onFocus}
  />
);

export default SwitchController;
