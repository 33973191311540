import { Button } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from '../../../../helpers/customHooks/useHookMethod';
import { tKey } from '../../../../helpers/translate';
import styles from './actionsMenu.module.scss';
import OptionsWrapper from './OptionsWrapper';

interface ActionMenuGoBackProps {
  onGoBack?: () => void;
}

const ActionMenuGoBack: React.FC<ActionMenuGoBackProps> = ({ onGoBack }) => {
  const history = useHistory();

  const IsMobile = useWindowSize().IsMobile;

  return (
    <OptionsWrapper style={styles.options_wrapper_more_info}>
      <Fragment>
        <Button
          key="goBackButton"
          disableElevation
          startIcon={!IsMobile ? <ArrowBack /> : ''}
          onClick={() => (onGoBack ? onGoBack() : history.go(-1))}
          color={'primary'}
          fullWidth
        >
          <div className={styles.goBackButton}>{tKey('Volver')}</div>
        </Button>
      </Fragment>
    </OptionsWrapper>
  );
};

export default React.memo(ActionMenuGoBack);
