import assertUnreachable from '../../helpers/assertUnreachable';
import { SignatureDocumentDataConsts } from './action_types';
import { SignatureDocumentState, initialState, SignatureDocumentsDataAction } from './definitions';
import { getDocs } from '../../helpers/getDocs';
import { SignatureDocumentStatus } from './SignatureDocumentStatus';

const signatureDocumentReducer = (
  state = initialState,
  action: SignatureDocumentsDataAction,
): SignatureDocumentState => {
  switch (action.type) {
    case SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET:
    case SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT:
    case SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT:
    case SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT:
      return {
        ...state,
        loading: true,
        error: null,
        sending: null,
      };
    case SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET_OK:
      return {
        ...state,
        loading: false,
        error: null,
        sending: null,
        signatureDocuments: action.signatureDocuments,
      };
    case SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_OK:
      const { document } = action;
      return {
        ...state,
        loading: false,
        error: null,
        sending: null,
        signatureDocuments: [...state.signatureDocuments, ...document],
      };
    case SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT_OK:
      return {
        ...state,
        signatureDocuments: state.signatureDocuments.filter(item => item.id !== action.id),
        loading: false,
        error: null,
        sending: null,
      };
    case SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET_KO:
    case SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_KO:
    case SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT_KO:
    case SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT_KO:
    case SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT_KO:
    case SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
        sending: null,
      };
    case SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT_OK:
      const { data } = action;
      getDocs(data, false, action.name);
      return {
        ...state,
        loading: false,
        error: null,
        sending: null,
      };
    case SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT:
    case SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT:
      const { id } = action;
      return {
        ...state,
        loading: true,
        error: null,
        sending: id,
      };
    case SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT_OK:
      const { signature_id } = action;
      const updatedDocuments = state.signatureDocuments.map(item =>
        item.id === action.id
          ? { ...item, status: SignatureDocumentStatus.pendiente().toString(), signature_id: signature_id }
          : item,
      );
      return {
        ...state,
        loading: false,
        error: null,
        sending: null,
        signatureDocuments: updatedDocuments,
      };
    case SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT_OK:
      const updatedCancelDocuments = state.signatureDocuments.map(item =>
        item.id === action.id
          ? { ...item, status: SignatureDocumentStatus.cancelado().toString(), signature_id: null }
          : item,
      );
      return {
        ...state,
        loading: false,
        error: null,
        sending: null,
        signatureDocuments: updatedCancelDocuments,
      };
  }

  assertUnreachable(action);
  return state;
};

export default signatureDocumentReducer;
