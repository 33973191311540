import { connect } from 'react-redux';
import {
  createEntityUser,
  preEntityUpdate,
  getDocument,
  getEntitiesPreCreatedUser,
  setAvatarPreEntity,
} from '../../../redux/entity/actions';
import { EntityInfo } from '../../../redux/entity/definitions';
import {
  selectCreationSended,
  selectDocuments,
  selectEntityAvatar,
  selectGetOneEntity,
  selectIsLoading,
} from '../../../redux/entity/selectors';
import { selectValidationErrors } from '../../../redux/entity/selectors';
import { getGenericMetadata } from '../../../redux/metadata/actions';
import { selectCountries } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { CreateEntityGeneric } from './CreateEntityGeneric';
import { logoutAction } from '../../../redux/auth/actions';

const mapStateToProps = (state: AppState) => ({
  entityData: selectGetOneEntity(state),
  avatar: selectEntityAvatar(state),
  countries: selectCountries(state),
  loading: selectIsLoading(state),
  isExternal: true,
  documentation: selectDocuments(state),
  validationErrors: selectValidationErrors(state),
  creationSended: selectCreationSended(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getPreEntityUserData: (): void => dispatch(getEntitiesPreCreatedUser()),
  updateEntity: (preEntityData: EntityInfo) => dispatch(preEntityUpdate(preEntityData)),
  setAvatarPreEntity: (avatar: string): void => dispatch(setAvatarPreEntity(avatar)),
  sendUserEntity: (data: EntityInfo) => dispatch(createEntityUser(data)),
  getEntityDocument: (file_id: number, name: string, entityId: number): void =>
    dispatch(getDocument(file_id, name, entityId)),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  getEntityData: () => {},
  logoutApp: (): void => dispatch(logoutAction()),
  getMetadata: (): void => dispatch(getGenericMetadata()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEntityGeneric);
