import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTableProps } from 'material-table';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tFormKey, tKey } from '../../../helpers/translate';
import { selectIsNousCims, selectUserInfo } from '../../../redux/auth/selectors';
import { AppState } from '../../../redux/root-reducer';
import {
  confirmUserActiveRole,
  getUserRolesConfig,
  setUserPermission,
  userAddRole,
  userRemoveRole,
  userRoleActive,
} from '../../../redux/user/actions';
import {
  Role,
  RolePermission,
  UserDataDispatch,
  UserPermission,
  UserRole,
  UserRoleConfig,
} from '../../../redux/user/definitions';
import { selectAvailableRoles, selectRoleActive } from '../../../redux/user/selectors';
import styles from './userRolesModal.module.scss';
import UserRolesModalTabs from './UserRolesModalTabs';
interface UserRolesModalProps {
  view: boolean;
  user_id: number;
  availableRoles: Role[];
  userRolesConfig: UserRoleConfig;
  activeRole: UserRole | undefined;
  auth_user_id: number;
  loading: boolean;
  user_permissions: UserPermission[];
  loadingRolePermissions: boolean;
  isNousCims: boolean;
  handleClose: () => void;
  getUserRolesConfig: (user_id: number) => void;
  userAddRole: (user_id: number, role: UserRole) => void;
  userRemoveRole: (user_id: number, role_id: number) => void;
  userRoleActive: (is_active: boolean, id: number) => void;
  confirmUserActiveRole: (user_id: number, role_id: number) => void;
  setPermission: (user_id: number, permission_id: number, value: boolean) => void;
}

const UserRolesModal: React.FC<UserRolesModalProps> = ({
  view,
  user_id,
  availableRoles,
  userRolesConfig,
  activeRole,
  auth_user_id,
  user_permissions,
  loading,
  loadingRolePermissions,
  isNousCims,
  handleClose,
  getUserRolesConfig,
  confirmUserActiveRole,
  userAddRole,
  userRoleActive,
  userRemoveRole,
  setPermission,
}) => {
  const minLength = 1;
  const [newRole, setNewRole] = useState<string>('');

  useEffect(() => {
    getUserRolesConfig(user_id);
  }, [getUserRolesConfig, user_id]);

  const handleNewRole = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const selectedRole = availableRoles.find(e => e.id.toString() === newRole);
    if (selectedRole) {
      setNewRole('');
      userAddRole(user_id, { ...selectedRole, is_active: false });
    }
  };
  const handleActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    userRoleActive(event.target.checked, Number(event.target.id));
  };

  const handleSetPermission = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPermission(user_id, Number(event.target.id), event.target.checked);
  };

  const handleSubmit = () => {
    // El usuario no puede cambiar su propio rol activo desde esta opción
    if (activeRole && user_id !== auth_user_id) {
      confirmUserActiveRole(user_id, activeRole.id);
    }
    handleClose();
  };

  const actionIcons = {
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    DeleteDisabled: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  };

  const tableActions = [];
  if (activeRole) {
    tableActions.push((rowData: UserRole) => ({
      icon: rowData.id !== activeRole.id ? actionIcons.Delete : actionIcons.DeleteDisabled,
      onClick: () => (rowData.id !== activeRole.id ? userRemoveRole(user_id, rowData.id) : undefined),
      tooltip: 'Eliminar',
    }));
  }

  const tableData: MaterialTableProps<UserRole> = {
    title: tKey('Roles asignados'),
    columns: [
      {
        title: tFormKey('Rol'),
        field: 'name',
      },
      {
        title: tFormKey('Rol actual'),
        field: 'is_active',
        type: 'boolean',
        disableClick: true,
        render: (rowData: UserRole) => (
          <>
            <span>{rowData.is_active}</span>
            <Checkbox
              id={`${rowData.id}`}
              className={styles.list__check}
              checked={rowData.is_active}
              onChange={handleActive}
              disabled={user_id === auth_user_id}
            />
          </>
        ),
      },
    ],
    data: userRolesConfig.user_roles,
    isLoading: loading,
    options: {
      search: false,
      selection: false,
      pageSize: 3,
      tableLayout: 'auto',
      showSelectAllCheckbox: true,
      paging: false,
      maxBodyHeight: 200,
    },
    actions: tableActions,
  };

  let role_permission_view;

  if (loadingRolePermissions || !userRolesConfig.active_role_permissions) {
    role_permission_view = <Loading medium />;
  } else {
    role_permission_view = userRolesConfig.active_role_permissions.map((permission: RolePermission) => (
      <div key={permission.id}>
        <ListItem key={permission.id}>
          <ListItemText primary={permission.description} />
        </ListItem>
        {userRolesConfig.active_role_permissions.length > minLength ? (
          <Divider component="li" variant="fullWidth" />
        ) : null}
      </div>
    ));
  }

  const tabOne = (
    <>
      <div className={styles.select_role}>
        <FormControl
          variant="outlined"
          classes={{
            root: styles.select_wrapper,
          }}
        >
          <InputLabel>{tKey('Rol')}</InputLabel>
          <Select
            name="roles"
            labelId="select-roles"
            id="select"
            label={tFormKey('Rol')}
            value={newRole}
            onChange={event => {
              if (typeof event.target.value !== 'number') return;
              setNewRole(event.target.value.toString());
            }}
          >
            {availableRoles.map(role => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button color="primary" variant="contained" onClick={handleNewRole}>
          {tKey('Añadir')}
        </Button>
      </div>
      <div className={styles.table_role}>
        <TableWithEdit customContainer key={JSON.stringify(tableData.data)} tableData={tableData} />
      </div>
      <div className={styles.active_role_permissions}>
        <h4>{tKey('Permisos rol actual')}</h4>
        <List className={styles.role_permission_list}>{role_permission_view}</List>
      </div>
    </>
  );

  let user_permissions_view;

  if (!loading && user_permissions) {
    user_permissions_view = user_permissions.map((permission: UserPermission) => (
      <div key={permission.id}>
        <ListItem key={permission.id}>
          <Checkbox
            id={`${permission.id}`}
            checked={permission.selected || !permission.selectable}
            onChange={handleSetPermission}
            disabled={!permission.selectable}
          />
          <ListItemText primary={permission.description} />
        </ListItem>
        {user_permissions.length > minLength ? <Divider component="li" variant="fullWidth" /> : null}
      </div>
    ));
  }

  let tabTwo;
  if (isNousCims) {
    tabTwo = (
      <div className={styles.active_role_permissions}>
        <h4>{tKey('Permisos por usuario y rol activo')}</h4>
        <List className={styles.user_permission_list}>{user_permissions_view}</List>
      </div>
    );
  }

  return (
    <Dialog
      open={view}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
      className={styles.dialog_root}
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{userRolesConfig.user_name || ''}</DialogTitle>
        <IconButton aria-label="delete" className={styles.buttonClose} size="small" onClick={() => handleClose()}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent>
        <UserRolesModalTabs tabOne={tabOne} tabTwo={tabTwo} />
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <div className={styles.btn_create}>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            {tKey('OK')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  availableRoles: selectAvailableRoles(state),
  userRolesConfig: state.userReducer.userRolesConfig,
  activeRole: selectRoleActive(state),
  isNousCims: selectIsNousCims(state),
  auth_user_id: selectUserInfo(state).user_id,
  loading: state.userReducer.loading,
  loadingRolePermissions: state.userReducer.loadingRolePermissions,
  user_permissions: state.userReducer.userRolesConfig.user_permissions,
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  getUserRolesConfig: (user_id: number): void => dispatch(getUserRolesConfig(user_id)),
  confirmUserActiveRole: (user_id: number, role_id: number): void => dispatch(confirmUserActiveRole(user_id, role_id)),
  userAddRole: (user_id: number, role: UserRole): void => dispatch(userAddRole(user_id, role)),
  userRemoveRole: (user_id: number, role_id: number): void => dispatch(userRemoveRole(user_id, role_id)),
  userRoleActive: (is_active: boolean, id: number): void => dispatch(userRoleActive(id, is_active)),
  setPermission: (user_id: number, permission_id: number, value: boolean): void =>
    dispatch(setUserPermission(user_id, permission_id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRolesModal);
