import React, { useState } from 'react';
import { Control, FieldErrors } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import { DocumentationItem } from '../../redux/common/definitions';
import TextInputController from '../Inputs/TextInputController/TextInputController';
import { yesNoOptions } from '../RadioButtons/RadioButtonOptions';
import RadioButtons from '../RadioButtons/RadioButtons';
import FncAddDocuments from '../UploadFile/FncAddDocuments';
import styles from './compoundComponent.module.scss';

interface CompoundComponentProps {
  label: string;
  docs?: DocumentationItem[];
  radioButtonsName?: string;
  radioButtonsValue?: number | null;
  textAreaName?: string;
  textAreaValue?: string | null;
  textAreaLabel?: string;
  actions?: string[] | undefined;
  genericId?: number;
  control: Control<any>;
  errors: FieldErrors<any>;
  schema: ObjectSchema<any> | ObjectSchema<Shape<object, any>>;
  disabled?: boolean;
  getFile?: (file_id: number, name: string, generic_id?: any) => void;
  addDoc?: (document: File, description: string, genericId?: any, documentationId?: number) => void;
  register: (instance: any) => void;
  uploadDocument?: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument?: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument?: (documentation_id: number, scholarship_id: number) => void;
  setData: (key: string, value: string | number) => void;
}

const CompoundComponent: React.FC<CompoundComponentProps> = ({
  control,
  label,
  radioButtonsName,
  radioButtonsValue,
  textAreaName,
  textAreaValue,
  textAreaLabel,
  docs,
  actions,
  genericId,
  disabled,
  errors,
  schema,
  getFile,
  uploadDocument,
  downloadDocument,
  deleteDocument,
  addDoc,
  register,
  setData,
}) => {
  const bool = radioButtonsValue ? true : false;
  const [radioButtonsTrue, setRadioButtonsTrue] = useState<boolean>(bool);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p className={styles.label}>{label}</p>
        {radioButtonsName ? (
          <RadioButtons
            name={radioButtonsName}
            questionText=""
            disabled={disabled}
            register={register}
            options={yesNoOptions}
            defaultValue={(radioButtonsValue && radioButtonsValue.toString()) || '0'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRadioButtonsTrue(e.target.value === '1');
              setData(radioButtonsName, e.target.value);
            }}
          />
        ) : null}
      </div>
      {textAreaName && textAreaLabel ? (
        <TextInputController
          schema={schema}
          label={textAreaLabel}
          name={textAreaName}
          control={control}
          defaultValue={textAreaValue || ''}
          disabled={disabled}
          errors={errors}
          visible={radioButtonsTrue}
          multiline
          rows="5"
          rowsMax="5"
          size="100"
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData(e.target.name, e.target.value)}
        ></TextInputController>
      ) : null}
      {docs && actions && radioButtonsTrue ? (
        <FncAddDocuments
          documents={docs}
          getFile={getFile}
          actions={actions}
          disabled={disabled}
          upload={uploadDocument}
          genericId={genericId}
          download={downloadDocument}
          remove={deleteDocument}
          multidocument
          addDoc={addDoc}
        />
      ) : null}
    </div>
  );
};

export default CompoundComponent;
