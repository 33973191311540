import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { Column, MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TableLayout from '../../../components/Layout/TableLayout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tProjectKey } from '../../../helpers/translate';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { deleteProject, getProjects, resetConfiguratorData } from '../../../redux/project/action';
import { LookUpState, LookUpSubarea, ProjectDispatch, ProjectListItem } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import styles from './projects.module.scss';
import {
  selectLoading,
  selectProjectsList,
  selectStateList,
  selectSubareaList,
} from '../../../redux/project/selectors';
import { selectIsNousCims } from '../../../redux/auth/selectors';

interface CopyProject {
  name: string;
  description: string;
  id: number;
}
interface ProjectListProps {
  loading: boolean;
  projectsList: ProjectListItem[];
  states: LookUpState;
  subareas: LookUpSubarea;
  canICopy: boolean;
  permission: boolean;
  isNousCims: boolean;
  getProjects: () => void;
  resetConfiguratorDataF: () => void;
  deleteProject: (id: number) => void;
}

const actionIcons = {
  PostAdd: () => <FileCopyOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  PostAddDis: () => <FileCopyOutlinedIcon style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
};

const AddNewProjectModal = React.lazy(() => import('../Modal/CreateProject/ModalCreate'));

const ProjectList: React.FC<ProjectListProps> = ({
  loading,
  states,
  projectsList,
  subareas,
  canICopy,
  permission,
  isNousCims,
  getProjects,
  resetConfiguratorDataF,
  deleteProject,
}) => {
  const statesTranslation = {} as any;

  if (!!Object.entries(states).length) {
    Object.keys(states).forEach(element => (statesTranslation[element] = tProjectKey(element)));
  }

  useEffect(() => {
    getProjects();
    resetConfiguratorDataF();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [create, setCreate] = React.useState({
    view: false,
    copy: false,
    detail: {} as CopyProject,
  });
  const [arrIds, setArrIds] = React.useState<number[]>([]);
  const history = useHistory();

  const tableActions = () => {
    if (canICopy) {
      return [
        (rowData: ProjectListItem) => ({
          icon: actionIcons.PostAdd,
          tooltip: tProjectKey('Copiar'),
          onClick: () => {
            setCreate({
              view: true,
              copy: true,
              detail: {
                name: rowData.name,
                description: rowData.description,
                id: rowData.id,
              },
            });
          },
          hidden: !canICopy,
        }),
      ];
    }
    return undefined;
  };

  const defineColumns = (): Column<ProjectListItem>[] => {
    const columns = [
      { title: tProjectKey('Proyecto'), field: 'name' },
      { title: tProjectKey('Entidad'), field: 'entity_name' },
      { title: tProjectKey('Subarea'), field: 'subarea', lookup: subareas },
      { title: tProjectKey('Estado'), field: 'state_name', lookup: statesTranslation },
    ] as Column<ProjectListItem>[];
    if (isNousCims) {
      columns.push({
        title: tProjectKey('Proyecto LAB'),
        field: 'lab_project',
        cellStyle: { textAlign: 'center' },
        //@ts-ignore
        width: '150px',
      });
    }
    return columns;
  };

  const tableData: MaterialTableProps<ProjectListItem> = {
    title: tProjectKey('Lista de proyectos'),
    columns: defineColumns(),
    data: projectsList,
    actions: tableActions(),
    isLoading: loading,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Proyectos`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  const goToNew = () => {
    setCreate({ view: true, copy: false, detail: {} as CopyProject });
  };

  const details = (rowData: ProjectListItem) => {
    const { id, forms_done } = rowData;
    let url = '';
    if (forms_done) url = `/proyecto/detalle/${id}/datos_basicos`;
    else url = `/proyecto/asistente-formularios/${id}/logica_proyecto`;
    history.push({
      pathname: url,
      state: { id },
    });
  };

  return (
    <TableLayout>
      <div className={styles.container}>
        <TableWithEdit
          tableData={tableData}
          permission={permission}
          exportButton
          filtering
          optionsNew={{
            newButtonText: tProjectKey('Nuevo proyecto'),
            onNew: goToNew,
          }}
          onRowClick={details}
        />
      </div>
      <TransitionModal
        view={modal.view}
        handleClose={() => {
          setArrIds([]);
          setModal({ view: false, body: [] });
        }}
        bodyModal={tProjectKey('¿Seguro que deseas eliminar este proyecto?')}
        buttonOk={tProjectKey('Si')}
        buttonKo={tProjectKey('No')}
        title={tProjectKey('¡Atención!')}
        handleYes={() => {
          const [id] = arrIds;
          deleteProject(id);
          setModal({ view: false, body: [] });
        }}
      />
      <Suspense fallback={<></>}>
        {create.view ? (
          <AddNewProjectModal
            view={create.view}
            copy={create.copy}
            detail={create.detail}
            handleClose={() => setCreate({ view: false, copy: false, detail: {} as CopyProject })}
          />
        ) : (
          <Fragment />
        )}
      </Suspense>
    </TableLayout>
  );
};
const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  projectsList: selectProjectsList(state),
  states: selectStateList(state),
  subareas: selectSubareaList(state),
  isNousCims: selectIsNousCims(state),
  canICopy: selectUserCan(state)('allow_copy_project'),
});

const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  getProjects: (): void => dispatch(getProjects()),
  resetConfiguratorDataF: (): void => dispatch(resetConfiguratorData()),
  deleteProject: (id: number): void => dispatch(deleteProject(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
