import { RejectScholarshipData, ScholarshipData, ScholarshipStateChange } from '../../redux/scholarship/definitions';
import { ScholarshipLeaveModes, ScholarshipTransitionState } from '../../redux/scholarship/transitions/definitions';
import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

export const scholarshipStateTransitionFromApi = async (
  id: number,
  states: ScholarshipStateChange,
  scholarshipData?: ScholarshipData | RejectScholarshipData,
): Promise<ScholarshipTransitionState> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(scholarshipData),
  };

  const { data } = await apiCall<{ data: ScholarshipTransitionState }>(
    `${baseURL}/scholarship/change_state/${states}/${id}`,
    init,
  );
  return data;
};

export const changeStateFromPendingJustifyFromApi = async (
  scholarship_id: number,
  remainder_amount: number,
  remainder_remarks: string | null,
): Promise<ScholarshipTransitionState> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ remainder_amount, remainder_remarks }),
  };

  const { data } = await apiCall<{ data: ScholarshipTransitionState }>(
    `${baseURL}/scholarships/${scholarship_id}/change_state/pending_justify`,
    init,
  );
  return data;
};

export const leaveScholarshipFromApi = async (
  id: number,
  leaveMode: ScholarshipLeaveModes,
  rejectScholarshipData: RejectScholarshipData,
): Promise<ScholarshipTransitionState> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(rejectScholarshipData),
  };

  const { data } = await apiCall<{ data: ScholarshipTransitionState }>(
    `${baseURL}/scholarships/${id}/change_state/${leaveMode}`,
    init,
  );
  return data;
};
