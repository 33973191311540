import { useState } from 'react';
import { ScholarshipPayment, ScholarshipReloadData } from '../../../../redux/scholarship/definitions';

type UseScholarshipReloadInfoType = {
  showReloadModal: boolean;
  reloadData: ScholarshipReloadData;
  closeReloadModal: () => void;
  setReloadInfo: (scholarshipPayment: ScholarshipPayment) => void;
};

const useScholarshipReloadInfo = (): UseScholarshipReloadInfoType => {
  const [showReloadModal, setShowReloadModal] = useState(false);
  const [reloadData, setReloadData] = useState({} as ScholarshipReloadData);

  const setReloadInfo = (scholarshipPayment: ScholarshipPayment) => {
    setReloadData({
      id: scholarshipPayment.scholarship_id,
      card_number: scholarshipPayment.account_number || '',
      provider_code: scholarshipPayment.provider_code,
      name: scholarshipPayment.student_name,
      amount: scholarshipPayment.payment_amount,
      payment_date: scholarshipPayment.payment_date,
      payment_field: scholarshipPayment.payment_field,
    } as ScholarshipReloadData);
    setShowReloadModal(true);
  };

  const closeReloadModal = () => {
    setShowReloadModal(false);
  };

  return {
    showReloadModal,
    reloadData,
    closeReloadModal,
    setReloadInfo,
  };
};

export default useScholarshipReloadInfo;
