import {
  RejectScholarshipData,
  ScholarshipStateChange,
  ScholarshipStateCode,
  ScholarshipData,
} from '../../../redux/scholarship/definitions';
import { tScholarshipKey } from '../../../helpers/translate';
import React, { useState } from 'react';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { KeyButton } from '../../../redux/common/definitions';
import RejectScholarshipModal from '../../Entities/RejectScholarshipModal';
import { getRejectScholarshipInfo } from '../../../api/Scholarship/scholarship';
import { getButtonType } from '../ButtonActions/ScholarshipButtonActions';

interface Props {
  scholarshipData: ScholarshipData;
  handleChangeState: (states: ScholarshipStateChange, dataValues?: ScholarshipData | undefined) => void;
}
const NoValidateAndDenyScholarshipModal = ({ scholarshipData, handleChangeState }: Props) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState<RejectScholarshipData>();
  const [state, setState] = useState<ScholarshipStateChange>('s02s07');
  const initModal = async () => {
    const data: RejectScholarshipData = await getRejectScholarshipInfo(
      ScholarshipStateCode.DESTIMATED,
      scholarshipData.id,
    );
    setData(data);
    setModal(true);
  };

  const buttonNoValidate = getButtonType(KeyButton.NO_VALIDATE);
  buttonNoValidate.onClick = async e => {
    e.preventDefault();
    await initModal();
    setState('s02s07');
  };
  const buttonDeny = getButtonType(KeyButton.DENY);
  buttonDeny.onClick = async e => {
    e.preventDefault();
    await initModal();
    setState('s08s07');
  };
  return (
    <>
      <TransitionModal
        view={modal}
        handleClose={() => {
          setModal(false);
        }}
        title={tScholarshipKey('Desestimar')}
        helperMode={true}
      >
        <RejectScholarshipModal
          title={tScholarshipKey('motivo-de-desestimacion')}
          rejectData={data as RejectScholarshipData}
          handleClose={() => {
            setModal(false);
          }}
          handleSend={modalData => {
            handleChangeState(state, {
              ...scholarshipData,
              renounce_comment: modalData.renounce_comment,
              reject_reason_id: modalData.reject_reason_id,
            });
          }}
        />
      </TransitionModal>
    </>
  );
};

export default NoValidateAndDenyScholarshipModal;
