import React from 'react';
import { ReactComponent as IconBack } from '../../images/icons/iconBack.svg';
import styles from './layoutMobile.module.scss';
import FncIconButton from '../FncIconButton/FncIconButton';
import { tKey } from '../../helpers/translate';
import Title from '../Title/Title';

export interface LayoutMobileProps {
  children: JSX.Element | JSX.Element[];
  title?: string;
  onBack?: () => void;
}

const LayoutMobile: React.FC<LayoutMobileProps> = ({ children, title, onBack }) => {
  return (
    <>
      <FncIconButton icon={<IconBack />} onClick={onBack}>
        {tKey('Volver')}
      </FncIconButton>
      <div className={styles.root}>
        <Title>{title}</Title>
        <div className={styles.content}>{children}</div>
      </div>
    </>
  );
};

export default LayoutMobile;
