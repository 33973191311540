import { AccountBalance, AttachMoney, Payment, Publish, Send } from '@material-ui/icons';
import ReceiptIcon from '@material-ui/icons/Receipt';
import SettingsIcon from '@material-ui/icons/Settings';
import TableChartIcon from '@material-ui/icons/TableChart';
import React from 'react';
import { connect } from 'react-redux';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import { permissionType } from '../../constants/permissions_definitions';
import { tKey } from '../../helpers/translate';
import { selectPermissionsList } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import { CostCenterCode, sections } from './CostCenter/SwitchCostCenter';

interface AccountinSubMenuItem {
  code: string;
  name: string;
  route: string;
}

const scholarshipPaymentSubmenu = [
  { route: '/contabilidad/pago-becas/institute', code: 'institute', name: tKey('Becas instituto') },
  { route: '/contabilidad/pago-becas/entidades', code: 'entity', name: tKey('Becas entidad') },
  { route: '/contabilidad/pago/facturas', code: 'invoice', name: tKey('Servicios') },
  { route: '/contabilidad/pago/proyectos', code: 'project', name: tKey('Proyectos') },
] as AccountinSubMenuItem[];

const taxesSubmenu = [
  { route: '/contabilidad/impuestos', code: 'tipo_impuestos', name: 'Tipo de impuestos' },
  { route: '/contabilidad/impuestos/transaction-code', code: 'transaction_code', name: 'Código de transacción' },
  { route: '/contabilidad/impuestos/operation-key', code: 'key_operation', name: 'Clave de operación' },
] as AccountinSubMenuItem[];

const scholarshipInvoicesSubmenu = [
  { route: '/contabilidad/facturas', code: 'current_invoices', name: tKey('Activas') },
  { route: '/contabilidad/facturas-pendientes-envio', code: 'pending_invoices', name: tKey('Pendientes de envío') },
  { route: '/contabilidad/facturas/pagadas', code: 'paid_invoices', name: tKey('Pagadas') },
] as AccountinSubMenuItem[];

interface AccountingSubmenuProps {
  selected:
    | 'sage'
    | 'institute'
    | 'retentions'
    | 'expenses'
    | 'configuration'
    | 'invoices'
    | 'project'
    | 'current_invoices'
    | 'paid_invoices'
    | CostCenterCode
    | 'entity'
    | 'tipo_impuestos'
    | 'transaction_code'
    | 'key_operation'
    | 'invoice'
    | 'cargas-masivas'
    | 'pending_invoices';
  permissions?: permissionType[];
  submenu?: string;
}

const AccountingSubmenu: React.FC<AccountingSubmenuProps> = ({ selected, permissions, submenu }) => {
  let optionsSubmenuAccounting: SubmenuItem[];

  const sendToSage: SubmenuItem = {
    title: tKey('Enviar a SAGE'),
    icon: <Send />,
    route: '/contabilidad/enviar-sage',
    type: 'route',
    selected: selected === 'sage',
  };

  const scholarshipPayment: SubmenuItem = {
    title: tKey('Pagos'),
    type: 'items',
    icon: <Payment />,
    items: scholarshipPaymentSubmenu.map(metadata => ({
      title: tKey(metadata.name),
      route: metadata.route,
      type: 'route',
      selected: selected === metadata.code,
    })),
  };

  // const invoices: SubmenuItem = {
  //   title: tKey('Facturas servicios'),
  //   icon: <ReceiptIcon />,
  //   route: '/contabilidad/facturas',
  //   type: 'route',
  //   selected: selected === 'invoices',
  // };

  const invoices: SubmenuItem = {
    title: tKey('Facturas servicios'),
    icon: <ReceiptIcon />,
    type: 'items',
    items: scholarshipInvoicesSubmenu.map(metadata => ({
      title: tKey(metadata.name),
      route: metadata.route,
      type: 'route',
      selected: selected === metadata.code,
    })),
  };

  const costCenters: SubmenuItem = {
    title: tKey('Centro de coste'),
    icon: <AttachMoney />,
    type: 'items',
    items: sections.map(section => ({
      title: tKey(section.name),
      route: `/contabilidad/centro-coste/${section.code}`,
      type: 'route',
      selected: selected === section.code,
    })),
  };

  const taxes: SubmenuItem = {
    title: tKey('Impuestos'),
    type: 'items',
    icon: <AccountBalance />,
    items: taxesSubmenu.map(metadata => ({
      title: tKey(metadata.name),
      route: metadata.route,
      type: 'route',
      selected: selected === metadata.code,
    })),
  };

  const expenseAccounts: SubmenuItem = {
    title: tKey('Cuentas de gasto'),
    icon: <TableChartIcon />,
    route: '/contabilidad/cuentas-de-gasto',
    type: 'route',
    selected: selected === 'expenses',
  };

  const configuration: SubmenuItem = {
    title: tKey('Configuración'),
    icon: <SettingsIcon />,
    route: '/contabilidad/configuracion',
    type: 'route',
    selected: selected === 'configuration',
  };

  const massiveUpload: SubmenuItem = {
    title: tKey('Cargas masivas'),
    icon: <Publish />,
    route: '/contabilidad/cargas-masivas',
    type: 'route',
    selected: selected === 'cargas-masivas',
  };

  if (permissions && permissions.indexOf('route_accounting') >= 0) {
    optionsSubmenuAccounting = [
      sendToSage,
      scholarshipPayment,
      invoices,
      costCenters,
      taxes,
      expenseAccounts,
      configuration,
      massiveUpload,
    ];
  } else {
    optionsSubmenuAccounting = [invoices];
  }

  return (
    <Submenu
      titleMenu={tKey('Contabilidad')}
      optionsMenu={optionsSubmenuAccounting}
      defaultOpen
      defaultOpenSubmenu={submenu}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
});

export default connect(mapStateToProps, null)(AccountingSubmenu);
