import { MaterialTableProps } from 'material-table';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import BodyModal from '../../../components/Modal/BodyModal';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SelectCheckboxController, {
  ListSelectCheckbox,
} from '../../../components/Select/SelectCheckboxController/SelectCheckboxController';
import SnackBar from '../../../components/SnackBar/Snackbar';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tKey } from '../../../helpers/translate';
import { ColumnOrderData, OrderDirection, ResponseData } from '../../../redux/common/definitions';
import {
  deleteOne,
  entityGetList,
  resetAllSuccess,
  resetResponseData,
  setColumnOrder,
  setSearchText,
} from '../../../redux/entity/actions';
import {
  EntityGetAllDispatch,
  EntityListItem,
  EntityTypes,
  ListEntitiesState,
} from '../../../redux/entity/definitions';
import {
  selectColumnOrder,
  selectDeleteOneEntity,
  selectEntityList,
  selectIsAllSuccess,
  selectIsLoading,
  selectSearchText,
} from '../../../redux/entity/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuEntities from '../SubmenuList/SubmenuEntities';
import styles from './entities.module.scss';
import { EntityTabs } from './EntitiesTabs';
import WhatType from './WhatType';

interface EntityPageProps {
  loading: boolean;
  isAllSuccess: boolean | null;
  entityList: EntityListItem[];
  responseData: ResponseData;
  entityTypes: EntityTypes[];
  searchText: string;
  columnOrder: ColumnOrderData;
  resetResponseData: () => void;
  resetIsAllSuccess: () => void;
  userCan: (permission: permissionType) => boolean | undefined;
  setSearchText: (searchText: string) => void;
  setColumnOrder: (columnOrder: ColumnOrderData) => void;
  getEntitiesCreated: () => void;
  deleteOneEntity: (id: any) => void;
  selectDeleteOneEntity: () => void;
}

const Entities: React.FC<EntityPageProps> = ({
  loading,
  isAllSuccess,
  entityList,
  responseData,
  searchText,
  entityTypes,
  columnOrder,
  resetResponseData,
  resetIsAllSuccess,
  userCan,
  setSearchText,
  setColumnOrder,
  getEntitiesCreated,
  deleteOneEntity,
}) => {
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [arrIds, setArrIds] = React.useState<number[]>([]);
  const history = useHistory();
  const [open, setOpen] = React.useState(true);
  const canIgo = userCan('route_entity_create');

  useEffect(() => {
    getEntitiesCreated();
  }, [getEntitiesCreated]);

  const getCities = (entityData?: EntityListItem[]) => {
    const cities: Record<string, string> = {};
    entityData?.map(ele => (ele.city ? (cities[ele.city] = `${ele.city}`) : null));
    return cities;
  };

  const onEditOne = (
    event: React.MouseEvent<Element, globalThis.MouseEvent>,
    rowData: EntityListItem | EntityListItem[],
  ) => {
    if (!Array.isArray(rowData) && rowData) {
      history.push({
        pathname: `/entidad/editar/${rowData.id}`,
        state: { id: rowData.id },
      });
    }
  };
  const tableData: MaterialTableProps<EntityListItem> = {
    title: tKey('Entidades activas'),
    columns: [
      {
        title: tKey('CIF'),
        field: 'cif',
        //@ts-ignore
        width: '150px',
      },
      {
        title: tKey('Nombre'),
        field: 'name',
      },
      {
        title: tKey('Ciudad'),
        field: 'city',
        lookup: getCities(entityList),
        //@ts-ignore
        width: '300px',
      },
      {
        title: tKey('Teléfono'),
        field: 'phone',
        //@ts-ignore
        width: '150px',
      },
      {
        title: tKey('Email Responsable'),
        field: 'email',
      },
      {
        title: tKey('Tipo Entidad'),
        field: 'types_entity', //forced
        render: (rowData: EntityListItem) => <WhatType rowData={rowData} />,
        customFilterAndSearch: (types, rowData) => types.every((type: string) => rowData.types_entity.includes(type)),
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <SelectCheckboxController
            list={entityTypes as ListSelectCheckbox[]}
            valueSend="code"
            valueLabel="name"
            columnDef={columnDef}
            onFilterChanged={onFilterChanged}
          />
        ),

        //@ts-ignore
        width: '150px',
      },
      {
        title: 'joined',
        field: 'joined_49_2020',
        hidden: true,
      },
    ],
    data: entityList,
    isLoading: loading,
    options: {
      searchText,
      exportButton: true,
      exportCsv: (columns, data) => {
        entityTypes.forEach(type => {
          data.forEach(_d => {
            const index = _d.types_entity.indexOf(type.code);
            if (index >= 0) {
              _d.types_entity[index] = type.name;
            }
          });
        });
        exportToExcel(columns, data, `Entidades`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  const details = (rowData: EntityListItem) => {
    history.push({
      pathname: `/entidad/detalles/${rowData.id}`,
      state: { id: rowData.id },
    });
  };

  return (
    <Layout leftSubmenu={<SubmenuEntities selected="entidades" submenu={tKey('Entidades')} />}>
      <div className={styles.container}>
        {EntityTabs(0)}
        <TableWithEdit
          tableData={tableData}
          // deleteFunc={(rowData: EntityListItem) => idItem(rowData.id)}
          getTextSearch={(textSearch: string) => setSearchText(textSearch)}
          getOrderColumns={(orderedColumnId: number, orderDirection: OrderDirection) =>
            setColumnOrder({ orderedColumnId, orderDirection })
          }
          columnOrder={columnOrder}
          permission={canIgo}
          exportButton
          filtering
          // optionsNew={{ newButtonText: tKey('Nueva Entidad'), onNew: goToNewEntity }}
          onRowClick={details}
          onEditOne={(event: React.MouseEvent, rowData: EntityListItem | EntityListItem[]) => onEditOne(event, rowData)}
        />
        <div className={styles.errors}>
          {responseData && !isAllSuccess && (
            <TransitionModal
              view={!open ? false : true}
              handleClose={() => resetResponseData()}
              buttonOk={tKey('Vale')}
              title={tKey('Resultado')}
              bodyModal={<BodyModal options={{ type: 'response', data: responseData, itemType: 'entity' }} />}
              handleYes={() => {
                resetResponseData();
              }}
            />
          )}
          {responseData && isAllSuccess && (
            <SnackBar
              open={open}
              handleClose={() => {
                setOpen(false);
                resetIsAllSuccess();
              }}
              message={tKey('Entidades eliminadas correctamente')}
            />
          )}

          <TransitionModal
            view={modal.view}
            handleClose={() => {
              setArrIds([]);
              setModal({ view: false, body: [] });
            }}
            bodyModal={
              arrIds.length > 0 ? (
                <BodyModal options={{ type: 'confirm', entityName: modal.body }} />
              ) : (
                tKey('¿Seguro que desea eliminar esta entidad')
              )
            }
            buttonOk={tKey('Sí')}
            buttonKo={tKey('No')}
            title={tKey('¡Atención!')}
            handleYes={() => {
              deleteOneEntity(arrIds);
              setOpen(true);
              setModal({ view: false, body: [] });
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  entityList: selectEntityList(state),
  responseData: selectDeleteOneEntity(state),
  columnOrder: selectColumnOrder(state),
  searchText: selectSearchText(state),
  isAllSuccess: selectIsAllSuccess(state),
  entityTypes: state.entityReducer.entityTypes,
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: EntityGetAllDispatch) => ({
  resetResponseData: (): void => dispatch(resetResponseData()),
  resetIsAllSuccess: (): void => dispatch(resetAllSuccess()),
  getEntitiesCreated: (): void => dispatch(entityGetList(ListEntitiesState.ACTIVAS)),
  deleteOneEntity: (id: number): void => dispatch(deleteOne(id)),
  setSearchText: (searchText: string): void => dispatch(setSearchText(searchText)),
  setColumnOrder: (data: ColumnOrderData): void => dispatch(setColumnOrder(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Entities);
