import React from 'react';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import AvatarImagev2 from '../../../components/MenuImageRound/MenuImageRound';
import { tKey } from '../../../helpers/translate';

interface SubmenuStudentProps {
  selected: string;
  avatar: string;
  loading: boolean;
  onSelectComponent: (componentId: number) => void;
  setImage?: (image: string) => void;
}

const SubmenuStudent: React.FC<SubmenuStudentProps> = ({ selected, avatar, loading, setImage, onSelectComponent }) => {
  const optionsMenu: SubmenuItem[] = [
    {
      title: tKey('Datos personales'),
      componentId: 0,
      type: 'component',
      selected: selected === 'datos_personales',
    },
    {
      title: tKey('Entidad/Instituto'),
      componentId: 1,
      type: 'component',
      selected: selected === 'entity',
    },

    {
      title: tKey('Histórico'),
      componentId: 2,
      type: 'component',
      selected: selected === 'history',
    },
    {
      title: tKey('Resumen de becas'),
      componentId: 3,
      type: 'component',
      selected: selected === 'scholarship_resume',
    },
    {
      title: tKey('LOPD'),
      componentId: 4,
      type: 'component',
      selected: selected === 'lopd',
    },
    {
      title: tKey('Eventos'),
      componentId: 5,
      type: 'component',
      selected: selected === 'events',
    },
    {
      title: tKey('Servicios'),
      componentId: 6,
      type: 'component',
      selected: selected === 'services',
    },
  ];
  return (
    <Submenu
      titleMenu={tKey('Datos del proyecto')}
      optionsMenu={optionsMenu}
      onSelectComponentId={onSelectComponent}
      defaultOpen
      sectionAvatar={<AvatarImagev2 imageLink={avatar} loading={loading} setImage={setImage} />}
    />
  );
};

export default SubmenuStudent;
