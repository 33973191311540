import React from 'react';
import styles from './fncIconButton.module.scss';
import { ButtonHTMLAttributes } from 'react';

interface FncIconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  customClass?: string;
  icon?: React.ReactNode;
}

const FncIconButton: React.FC<FncIconButtonProps> = ({ children, customClass, icon, ...props }) => (
  <button className={`${styles.root} ${customClass ? customClass : ''}`} {...props}>
    <span className={styles.icon}>{icon}</span>
    <span className={styles.text}>{children}</span>
  </button>
);

export default FncIconButton;
