import React from 'react';
import FncButton from '../../../../components/FncButton/FncButton';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { generateUuid } from '../../../../helpers/uuidGenerator';
import { FamilyIncome, ScholarshipId } from '../../../../redux/scholarship/familyIncomes/FamilyIncome';
import FamilyIncomeTableModal from './FamilyIncomeTableModal';
import FamilyIncomesRow from './FamilyIncomesRow';
import styles from './familyIncomesTable.module.scss';
import { downloadFileFromApi } from '../../../../api/file';
import { getDocs } from '../../../../helpers/getDocs';

interface FamilyIncomesTableProps {
  scholarshipId: ScholarshipId;
  familyIncomes: FamilyIncome[];
  disabled: boolean;
  info_message?: string;
  error_message?: string;
  loading: boolean;
  addFamilyIncome: (familyIncome: FamilyIncome) => void;
  deleteFamilyIncome: (familyIncome: FamilyIncome) => void;
  updateFamilyIncome: (familyIncome: FamilyIncome) => void;
  setFamilyIncome: (data: FamilyIncome) => void;
}

const FamilyIncomesTable: React.FC<FamilyIncomesTableProps> = ({
  scholarshipId,
  familyIncomes,
  disabled = false,
  info_message,
  error_message,
  loading,
  addFamilyIncome,
  deleteFamilyIncome,
  updateFamilyIncome,
  setFamilyIncome,
}) => {
  const incomeModalDefault = {
    view: false,
    title: '',
    children: <></>,
  };
  const [incomeModal, setIncomeModal] = React.useState(incomeModalDefault);

  const closeModal = () => {
    setIncomeModal(incomeModalDefault);
  };

  const downloadFile = async (file_id: number, filename: string) => {
    const blobFile = await downloadFileFromApi(file_id);
    getDocs(blobFile, true, filename);
  };

  const renderModal = (familyIncome: FamilyIncome, onSave: (familyIncome: FamilyIncome) => void): JSX.Element => {
    return (
      <FamilyIncomeTableModal
        closeModal={() => closeModal()}
        familyIncome={familyIncome}
        onSave={onSave}
        onDownloadFile={downloadFile}
        setFamilyIncome={setFamilyIncome}
      />
    );
  };

  const addOne = () => {
    const newFamilyIncome = {
      id: generateUuid(),
      scholarship_id: scholarshipId,
      family_member: 'Zinger',
      income_type: 'Salario',
      amount: 0,
    } as FamilyIncome;
    setIncomeModal({
      view: true,
      title: tScholarshipKey('Crear ingreso'),
      children: renderModal(newFamilyIncome, addFamilyIncome),
    });
  };

  const editOne = (familyIncome: FamilyIncome) => {
    setIncomeModal({
      view: true,
      title: tScholarshipKey('Modificar ingreso'),
      children: renderModal(familyIncome, updateFamilyIncome),
    });
  };

  const renderAddNew = () => {
    return (
      <div className={styles.addNewFamilyIncome}>
        <FncButton color="primary" disabled={disabled} onClick={() => addOne()}>
          {tScholarshipKey('Añadir ingreso')}
        </FncButton>
      </div>
    );
  };

  const renderFamilyIncomes = () => {
    if (familyIncomes.length > 0) {
      return familyIncomes.map((familyIncome: FamilyIncome) => {
        return (
          <FamilyIncomesRow
            key={familyIncome.id}
            familyIncome={familyIncome}
            disabled={disabled}
            onRemove={deleteFamilyIncome}
            onDownload={downloadFile}
            onEdit={editOne}
          />
        );
      });
    }
    return (
      <tr className={styles.familyIncomeTableRow}>
        <td colSpan={4} className={`${styles.familyIncomeCellRow} ${styles.familyIncomeEmptyCellRow}`}>
          {loading ? tKey('Cargando...') : tKey('No hay ingresos')}
        </td>
      </tr>
    );
  };

  const renderMessages = (): JSX.Element => {
    if (error_message) {
      return <span className={styles.familyIncomeError}>{error_message}</span>;
    }
    return <span className={styles.familyIncomeInfo}>{info_message}</span>;
  };

  const renderMessageArea = (): JSX.Element | undefined => {
    if (error_message || info_message) {
      return <div className={styles.tableMessageArea}>{renderMessages()}</div>;
    }
    return undefined;
  };

  return (
    <>
      <div className={styles.familyIncomeTableContainer}>
        <div className={styles.tableTitle}>
          <SubTitle>{tScholarshipKey('Ingresos familiares')}</SubTitle>
          {renderAddNew()}
        </div>
        <table className={styles.familyIncomeTable}>
          <thead className={styles.familyIncomeTableHeader}>
            <tr>
              <th className={styles.familyIncomeCellHeader}>{tScholarshipKey('family_incomes_family_member')}</th>
              <th className={styles.familyIncomeCellHeader}>{tScholarshipKey('family_incomes_income_type')}</th>
              <th className={styles.familyIncomeCellHeader}>{tScholarshipKey('family_incomes_amount')}</th>
              <th className={styles.familyIncomeCellHeader}></th>
            </tr>
          </thead>
          <tbody>{renderFamilyIncomes()}</tbody>
        </table>
        {renderMessageArea()}
      </div>
      <TransitionModal
        view={incomeModal.view}
        handleClose={() => {
          closeModal();
        }}
        title={incomeModal.title}
        bodyModal={incomeModal.children}
      />
    </>
  );
};

export default FamilyIncomesTable;
