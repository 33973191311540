import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import { tErrorKey } from '../../helpers/translate';
import requiredStyle from '../../helpers/validator.module.scss';
import styles from './inputs.module.scss';
import { BaseTextInputProps } from './types';

export interface TextInputProps extends BaseTextInputProps {
  errors?: FieldError | any;
  validator?: boolean;
}
const TextInput: React.FC<TextInputProps> = ({
  type = 'text',
  register,
  errors,
  size,
  visible = true,
  icon,
  validator,
  rows,
  rowsMax,
  ...props
}) => {
  return (
    <TextField
      className={!visible ? styles['hidden'] : size && styles[`size_${size}`]}
      inputRef={register}
      error={!!errors}
      classes={{
        root: !!validator ? requiredStyle.required : '',
      }}
      helperText={tErrorKey(errors?.message)}
      type={type}
      rows={rows}
      rowsMax={rowsMax}
      variant="outlined"
      InputProps={{
        startAdornment: icon ? <InputAdornment position="end">{icon}</InputAdornment> : null,
      }}
      {...props}
    />
  );
};

export default TextInput;
