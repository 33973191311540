import { put, takeLatest } from '@redux-saga/core/effects';
import errorMessage from '../../../../helpers/errorMessage';
import { addNewFamilyIncomeKo, addNewFamilyIncomeOk } from './actions';
import { AddFamilyIncomeAction } from './definitions';
import { showSnackbar } from '../../../FeedbackAPI/actions';
import { tKey } from '../../../../helpers/translate';
import { CreateFamilyIncomeConst } from './action_types';
import { createFamilyIncomes } from '../../../../api/Scholarship/familyIncomes';

function* createFamilyIncomeFromSaga(action: AddFamilyIncomeAction) {
  try {
    const { income } = action;
    yield createFamilyIncomes(income);
    yield put(addNewFamilyIncomeOk(income));
    yield put(showSnackbar(tKey('Ingreso añadido.'), 'success', undefined, 1500));
  } catch (error) {
    yield put(showSnackbar(tKey(errorMessage(error)), 'error', undefined, 1500));
    yield put(addNewFamilyIncomeKo(errorMessage(error)));
  }
}

export default takeLatest<AddFamilyIncomeAction>(CreateFamilyIncomeConst.ADD_FAMILY_INCOME, createFamilyIncomeFromSaga);
