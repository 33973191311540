/**
 * IMPORTANTE --> Si se usa dentro de un componente de react pasarlo por un useCallback para que no pierda
 * la referencia entre renderizados.
 *
 * @param fn callback que se va a ejecutar
 * @param delay tiempo de espera entre cambios
 */

export function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
  let timeoutID = 0;
  return function(this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}
