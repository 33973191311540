import { AuthLogoutAction } from './auth/definitions';
import { AuthorizationConsts } from './auth/action_types';

//reducer
const resetEnhancer = (rootReducer: any) => (state: any, action: AuthLogoutAction) => {
  if (action.type !== AuthorizationConsts.AUTH_LOGOUT) return rootReducer(state, action);
  const newState = rootReducer(undefined, {});
  newState.router = state.router;
  return newState;
};

export default resetEnhancer;
