import { ButtonProps, ThemeProvider } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import React, { Fragment } from 'react';
import { tableTheme } from '../../../ui/muiCustomTheme';
import CustomTableCell from './CustomTableCell';
import { getComparator, stableSort } from '../TableEditableRow/logic';
import SortTableHead from '../TableEditableRow/TableHeadSortLabel';
import ToolBar from '../TableEditableRow/ToolBar';
import { InternalProjectEnrollment } from '../../../redux/internal-projects/definitions';

interface OptionsTable {
  style?: {
    maxHeight?: string;
  };
  filter?: boolean;
  fixedColumn?: {
    num?: number;
    left?: number;
  };
  stickyHeader?: boolean;
}

export interface ColumnTableProps {
  title: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  name: string;
  options: any[];
  editable?: 'never' | string;
  type?: 'Number' | 'Text' | 'Boolean' | 'List';
  width?: string;
  disabled?: boolean | ((data: any) => boolean);
  color?: string | ((data: any) => string);
}

export interface ButtonsProps {
  title: string;
  label?: string;
  onClick: () => void;
  icon?: JSX.Element;
}

export type TableActions = {
  title: string;
  icon: JSX.Element;
  onClick: (row: any) => void;
  disabled?: boolean;
  hidden?: boolean;
};

interface EditableTableProps {
  columns: ColumnTableProps[];
  rows: InternalProjectEnrollment[];
  title: string;
  buttons?: ButtonProps[];
  disabled?: boolean;
  option?: OptionsTable;
  actions?: TableActions[];
  projectID?: number;
  handleOpenModal?: boolean;
  exportExcel?: boolean;
  onEditBulk?: boolean;
  onToggleEditMode: (data: InternalProjectEnrollment) => void;
  onChangeText: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, row: InternalProjectEnrollment) => void;
  onChangeCheckBox: (e: React.ChangeEvent<HTMLInputElement>, row: InternalProjectEnrollment) => void;
  onChangeSelectOption: (e: any, row: InternalProjectEnrollment) => void;
}

const EditableTable: React.FC<EditableTableProps> = ({
  title,
  columns,
  rows,
  disabled,
  option,
  buttons,
  actions,
  projectID,
  exportExcel,
  onEditBulk,
  onToggleEditMode,
  onChangeText,
  onChangeCheckBox,
  onChangeSelectOption,
}) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState<string | string[]>('');
  const [value, setValue] = React.useState<string>('');

  const handleSearch = (value: string) => {
    const columnName = columns.map((column: ColumnTableProps) => column.name);
    setOrderBy(columnName);
    setValue(value);
  };

  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Fragment>
      <ThemeProvider theme={tableTheme}>
        <Paper>
          <ToolBar
            onSearch={handleSearch}
            title={title}
            rows={rows}
            columns={columns}
            exportExcel={exportExcel}
            disabled={disabled}
            buttons={buttons}
            onEditBulk={onEditBulk}
          />
          <TableContainer style={{ maxHeight: option?.style?.maxHeight || undefined }}>
            <Table>
              <TableHead>
                <SortTableHead
                  onRequestSort={handleRequestSort}
                  order={order}
                  orderBy={orderBy}
                  columns={columns}
                  option={option}
                  actions={!!actions}
                />
              </TableHead>

              <TableBody>
                {stableSort(rows, getComparator(order, orderBy), {}, value, orderBy).map(
                  (row: InternalProjectEnrollment, index: number) => (
                    <TableRow key={index}>
                      <>
                        {columns.map((column: ColumnTableProps, index: number) => (
                          <CustomTableCell
                            key={index}
                            {...{
                              index,
                              projectID: projectID,
                              row,
                              entireTable: rows,
                              editable: column.editable,
                              name: column.name,
                              type: column.type,
                              column: column,
                              color: column?.color,
                              fixed: option?.fixedColumn,
                              onClick: () => {
                                onToggleEditMode(row);
                              },
                              onChangeText,
                              onChangeCheckBox,
                              onChangeSelectOption,
                            }}
                          />
                        ))}
                      </>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
};

export default EditableTable;
