import moment from 'moment';
import * as DateConstants from '../constants/date';

export const unixToDatetime = (date: number | undefined) => {
  return moment.utc(date, 'X').format(DateConstants['DATE_HOUR_FORMAT']);
};

export const unixToDate = (date: number | string | undefined) => {
  if (date) {
    return moment.utc(date, 'X').format(DateConstants['DATE_FORMAT']);
  }
};

export const dateAmericanFormat = (date: number | string | undefined) => {
  if (date) {
    return moment(date, DateConstants['DATE_FORMAT']).format(DateConstants['DATE_FORMAT_AMERICAN']);
  }
};

export const unixToDateAmericanFormat = (date: number | string | undefined) => {
  if (date) {
    return moment.utc(date, 'X').format(DateConstants['DATE_FORMAT_AMERICAN']);
  }
};

export const unixToMonth = (date: number) => {
  return moment.utc(date, 'X').format(DateConstants['MONTH_FORMAT']);
};

export const dateNowToS = () => {
  return Date.now() / 1000;
};

export const dbDateToDate = (date: string): string => {
  return moment(date, DateConstants['DATE_FORMAT_DB']).format(DateConstants['DATE_FORMAT']);
};
