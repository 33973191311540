import { IdCodeNamePair } from '../../redux/common/definitions';
import { CustomField, Group, KpiIndicatorType, Point } from '../../redux/kpis/definitions';
import { InternalProjectType } from '../../types/InternalProjects/InternalProjectTypes';
import { APIValidationError, Result, apiBaseUrl, apiCall, apiCallBlob, validatedApiCall } from '../api';

const baseURL = apiBaseUrl;

export const getCustomFieldsApi = async (section: string): Promise<CustomField[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<CustomField[]>(`${baseURL}/custom_fields/list/${section}`, init);
};

export const getCustomFieldsAreaApi = async <CustomFieldArea>(
  section: string,
  code_group: string,
  group: Group | string,
): Promise<CustomFieldArea[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: CustomFieldArea[] }>(
    `${baseURL}/custom_fields/by_area/${section}/${code_group}/${group}`,
    init,
  );
  return data;
};

export const getCustomFieldsTypeApi = async <CustomFieldArea>(
  section: string,
  code: InternalProjectType,
): Promise<CustomFieldArea[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: CustomFieldArea[] }>(
    `${baseURL}/custom_fields/types/${section}/${code}`,
    init,
  );
  return data;
};

export const getOneCustomFieldsApi = async (code: string, id: string): Promise<CustomField> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<CustomField>(`${baseURL}/custom_fields/find/${code}/${id}`, init);
};

export const createCustomfieldApi = async (
  data: CustomField,
): Promise<Result<{ data: CustomField }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await validatedApiCall<{ data: CustomField }>(`${baseURL}/custom_fields/create`, init);
};

export const editCustomfieldApi = async (
  data: CustomField,
  id: string,
): Promise<Result<{ data: CustomField }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await validatedApiCall<{ data: CustomField }>(`${baseURL}/custom_fields/edit/${id}`, init);
};

export const deleteCustomfieldApi = async (id: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/custom_fields/${id}`, init);
  return data;
};

export const deactivateCustomfieldApi = async (id: number): Promise<string> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/custom_fields/deactivate/${id}`, init);
  return data;
};

export const assigmentPoint = async (point: Point, data: any): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<CustomField>(`${baseURL}/custom_fields/assign_${point}`, init);
};

export const unAssigmentPoint = async (point: Point, data: any): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<CustomField>(`${baseURL}/custom_fields/unassign_areas`, init);
};

export const exportCustomFieldsAreas = async (): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCallBlob(`${baseURL}/custom_fields/areas/export`, init);
};

export const getKpiIndicatorFromApi = async (id: number): Promise<KpiIndicatorType> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: KpiIndicatorType }>(`${baseURL}/kpi_indicator/${id}`, init);
  return data;
};

export const getKpiIndicatorsFromApi = async (): Promise<KpiIndicatorType[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: KpiIndicatorType[] }>(`${baseURL}/kpi_indicator`, init);
  return data;
};

export const createKpiIndicatorFromApi = async (
  kpiIndicatorType: KpiIndicatorType,
): Promise<Result<{ data: KpiIndicatorType[] }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(kpiIndicatorType),
  };
  return await validatedApiCall<{ data: KpiIndicatorType[] }>(`${baseURL}/kpi_indicator`, init);
};

export const editKpiIndicatorFromApi = async (
  kpiIndicatorType: KpiIndicatorType,
): Promise<Result<{ data: KpiIndicatorType[] }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(kpiIndicatorType),
  };
  return await validatedApiCall<{ data: KpiIndicatorType[] }>(`${baseURL}/kpi_indicator`, init);
};

export const deleteKpiIndicatorFromApi = async (id: number): Promise<boolean> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/kpi_indicator/${id}`, init);
  return data === 'OK';
};

export const getCustomFieldsValueListFromApi = async (id: number): Promise<IdCodeNamePair[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: IdCodeNamePair[] }>(`${baseURL}/custom_fields/value_list/${id}`, init);
  return data;
};
