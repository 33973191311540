import { ManualFieldError } from 'react-hook-form/dist/types';
import storeProvider from '../storeProvider';

export type Await<T> = T extends {
  then(onfulfilled?: (value: infer U) => unknown): unknown;
}
  ? U
  : T;

export type FetchOptions = RequestInit & { auth?: true; error?: string; defaultContentType?: boolean };

export let _token = localStorage.getItem('token') || null;

export function setToken(token: string | null) {
  _token = token;
  if (token === null) {
    localStorage.removeItem('token');
  } else {
    localStorage.setItem('token', token);
  }
}

export const apiBaseUrl = ((): string => {
  const fallback = process.env.REACT_APP_API_URL || 'https://backend-plataforma.nouscims.com/api';

  // prod -> plataforma.nouscims.com -> backend-plataforma.nouscims.com
  //      -> plataforma.zingprogramme.com -> backend-
  // staging -> staging.env.plataforma.nouscims.com -> backend.
  // development -> testing.env.plataforma.nouscims.com -> backend.
  // local -> localhost:3000 ¿? -> backend.nouscims.local
  //       -> plataforma.nouscims.local -> backend.plataforma.nouscims.local
  //       -> plataforma.zing.local -> backend.plataforma.zing.local

  if (typeof document !== 'object') {
    return fallback;
  }
  if (typeof document.location !== 'object') {
    return fallback;
  }
  const host = document.location.hostname;
  if (host === 'plataforma.nouscims.com' || host === 'plataforma.zingprogramme.com') {
    return `//backend-${document.location.hostname}/api`;
  }

  return `//backend.${document.location.hostname}/api`;
})();

const processOptions = (options?: FetchOptions): FetchOptions => {
  if (!options) {
    return {};
  }
  let opts = {
    ...options,
  };
  opts = {
    ...opts,
    headers: {
      Accept: 'application/json',
      ...opts.headers,
    },
    credentials: 'include',
  };
  // defaultContentType is true, no set 'Content-Type'
  if (!opts.defaultContentType && opts.method && opts.method.toUpperCase() !== 'GET') {
    opts = {
      ...opts,
      headers: {
        ...opts.headers,
        'Content-Type': 'application/json; charset=utf-8',
      },
    };
  }
  // remove flag defaultContentType, used when upload files
  delete opts.defaultContentType;
  return opts;
};

export class APIValidationError extends Error {
  public readonly fields: ManualFieldError<Record<string, any>>[];

  constructor(message: string, fields: ManualFieldError<Record<string, any>>[]) {
    super(message);
    this.fields = fields;
  }
}

export class APIBadPlatformError extends Error {
  public readonly alternativePlatform: string;
  public readonly token: string;
  public readonly url: string;
  constructor(alternativePlatform: string, token: string) {
    super('No tiene acceso a la plataforma actual. Haga clic aquí para ir a la plataforma correcta.');
    this.alternativePlatform = alternativePlatform;
    this.token = token;
    this.url = alternativePlatform.concat('?token=', token);
  }
}

export class APIServerError extends Error {}
export class APIUnauthorizedError extends Error {}
export class APICustomMessageError extends Error {}
export class APIForbiddenError extends Error {}
export class APIUnprocessableRequest extends Error {}
export class APINotFoundError extends Error {}
export type APIError =
  | APIValidationError
  | APIServerError
  | APIUnauthorizedError
  | APIForbiddenError
  | APIUnprocessableRequest
  | APIBadPlatformError
  | APICustomMessageError
  | APINotFoundError
  | Error
  | unknown;

const _apiCall = async (input: RequestInfo, init: RequestInit): Promise<Response | undefined> => {
  const response = await fetch(input, processOptions(init));

  if (response.status === 200) {
    return response;
  }

  if (response.ok) {
    return;
  }

  if (response.status === 500) {
    throw new APIServerError('Error de red');
  }
  if (response.status === 403) {
    const { data } = await response.json();
    throw new APIForbiddenError(data.message || data || 'Acceso Restringido');
  }
  if (response.status === 404) {
    const { data } = await response.json();
    throw new APIForbiddenError(data.message || data || 'El servidor no puede encontrar el recurso solicitado');
  }
  if (response.status === 422) {
    const { data } = await response.json();
    if (data.error_no === '800') {
      throw new APIValidationError(data.message, data.validation);
    }
    if (data.error_no === '100') {
      throw new Error('Hay errores en el formulario');
    }
    throw new APIUnprocessableRequest(data.message || 'No se ha podido procesar la solicitud');
  }
  if (response.status === 400) {
    const { data, error_no } = await response.json();
    if (error_no === '105') {
      // when custom message is thrown from back
      throw new APICustomMessageError(data.message || data || 'Petición incorrecta');
    } else {
      throw new APIUnauthorizedError(data.message || data || 'Petición incorrecta');
    }
  }

  if (response.status === 401) {
    const { data, message } = await response.json();
    if (message === 'Unauthenticated.') {
      storeProvider.getState().authReducer.loggedIn = false;
      // Evita posible redirección infinita si al llegar a '/' se vuelve a recibir un message === 'Unauthenticated.'
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
      // Si se recibió message === 'Unauthenticated.' debemos estar en la pantalla de login, así que retornamos para que no lance la siguiente excepción
      return;
    }
    throw new APIUnauthorizedError((data && data.message) || data || 'Inicie sesión de nuevo');
  }

  throw new Error('Error');
};

export type Result<T, V> =
  | {
      type: 'ok';
      value: T;
    }
  | {
      type: 'validation-error';
      value: V;
    };

export const apiCall = async <T>(input: RequestInfo, init: RequestInit): Promise<T> => {
  const response = await _apiCall(input, init);
  if (response) {
    return response.json();
  }
  return {} as T;
};

export const apiCallData = async <T>(input: RequestInfo, init: RequestInit): Promise<T> => {
  const { data } = await apiCall<{ data: T }>(input, init);
  return data;
};

export const apiCallSuccess = async <T>(input: RequestInfo, init: RequestInit): Promise<boolean> => {
  const { data } = await apiCall<{ data: string }>(input, init);
  return data === 'OK';
};

export const apiCallBlob = async (input: RequestInfo, init: RequestInit): Promise<Blob> => {
  const response = await _apiCall(input, init);
  if (response) {
    return response.blob();
  }
  return new Blob(undefined);
};

export const apiCallNoResponse = async (input: RequestInfo, init: RequestInit): Promise<void> => {
  await _apiCall(input, init);
};

export const validatedApiCall = async <T>(
  input: RequestInfo,
  init: RequestInit,
): Promise<Result<T, APIValidationError>> => {
  try {
    const response = await apiCall<T>(input, init);
    return {
      type: 'ok',
      value: response,
    };
  } catch (e) {
    if (e instanceof APIValidationError) {
      return {
        type: 'validation-error',
        value: e,
      };
    }
    throw e;
  }
};
