import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getEntitiesInfo } from '../../../../api/Entity/entity';
import { tKey } from '../../../../helpers/translate';
import { IdNamePair } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { getEntitiesWithScholarshipsPayments } from '../../../../redux/scholarship/actions';
import { ScholarshipPayment } from '../../../../redux/scholarship/definitions';
import {
  selectIsLoading,
  selectPaymentAcademicYear,
  selectScholarshipPaymentList,
  selectScholarshipReload,
} from '../../../../redux/scholarship/selectors';
import ScholarshipsPaymentFilters from '../ScholarshipsPaymentFilters';
import ScholarshipsPaymentList from '../ScholarshipsPaymentList';
import { ScholarshipPaymentsFilterGenerator } from '../filters/ScholarshipPaymentsFilterGenerator';
import useScholarshipPaymentFilters from '../hooks/useScholarshipPaymentFilters';

interface Props {
  reload: boolean;
  loading: boolean;
  entityId?: number;
  showEntities?: boolean;
  selectedAcademicYear: string;
  scholarshipPaymentList: ScholarshipPayment[];
  selectedEntity?: (entity_id: number) => void;
  getEntitiesWithScholarshipsPayments: (academic_year: string, entity_id: number | null) => void;
}

const EntitiesScholarshipPaymentContainer = ({
  reload,
  loading,
  entityId,
  showEntities,
  selectedAcademicYear,
  scholarshipPaymentList,
  getEntitiesWithScholarshipsPayments,
  selectedEntity,
}: Props) => {
  const { filterValues, applyFilters } = useScholarshipPaymentFilters();
  const [entity_id, setEntity_id] = useState<number>(entityId ? entityId : 0);
  const [entities, setEntities] = useState<IdNamePair[]>();

  useEffect(() => {
    if (selectedAcademicYear || reload) {
      getEntitiesWithScholarshipsPayments(selectedAcademicYear, entity_id);
    }
  }, [selectedAcademicYear, getEntitiesWithScholarshipsPayments, reload, entity_id]);

  useEffect(() => {
    if (selectedAcademicYear && showEntities) {
      setEntity_id(0);
      getEntitiesInfo(selectedAcademicYear)
        .then(response => {
          setEntities(response.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [selectedAcademicYear, showEntities]);

  const getPaymentsList = (): ScholarshipPayment[] => {
    const filterGenerator = new ScholarshipPaymentsFilterGenerator(scholarshipPaymentList, filterValues);
    return filterGenerator.getFilteredList();
  };
  const onChangeEntity = (entity_id: number) => {
    setEntity_id(entity_id);
    if (selectedEntity) {
      selectedEntity(entity_id);
    }
  };

  return (
    <>
      <ScholarshipsPaymentFilters
        onChangeEntity={onChangeEntity}
        entity_id={entity_id}
        entities={entities}
        isEntity
        title={tKey('Becas de entidad')}
        applyFilters={applyFilters}
      />
      <ScholarshipsPaymentList
        loading={loading}
        isEntity
        title={'Pagos becas entidades'}
        scholarshipPaymentList={getPaymentsList()}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectPaymentAcademicYear(state),
  scholarshipPaymentList: selectScholarshipPaymentList(state),
  reload: selectScholarshipReload(state),
  loading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getEntitiesWithScholarshipsPayments: (academic_year: string, entity_id: number | null) =>
    dispatch(getEntitiesWithScholarshipsPayments(academic_year, entity_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesScholarshipPaymentContainer);
