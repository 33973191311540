import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import style from './checkBoxStyle.module.scss';

interface CheckboxController extends Omit<CheckboxProps, 'name' | 'defaultValue'> {
  control: Control;
  name: string;
  defaultValue?: boolean;
  label: string | React.ReactNode;
  altLabel?: string | number | boolean;
}

const CheckboxStyle = ({ control, name, defaultValue, label, altLabel, ...props }: CheckboxController) => {
  return (
    <div className={style.container}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        as={
          <FormControlLabel
            className={style.container__checkbox}
            control={<Checkbox {...props} color="primary" />}
            label=""
          />
        }
      />
      <div className={style.container__label__box}>
        <h3 className={style.container__label__title}>{label}</h3>
      </div>
      {!!altLabel && (
        <div className={style.container__altLabel__box}>
          <h4 className={style.container__altLabel__title}>{altLabel}</h4>
        </div>
      )}
    </div>
  );
};

export default CheckboxStyle;
