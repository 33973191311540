/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import {
  addDocumentsFollowUpFromApi,
  addInternalProjectDailyFollowDocumentsFromApi,
  addInternalProjectImageFromApi,
  addMemberFromApi,
  applyConfigInternalProjectTemplateFromApi,
  createInternalProjectApi,
  createInternalProjectDailyFollowApi,
  deleteDailyFollowFromApi,
  deleteInternalProjectEnrollmentFromApi,
  deleteInternalProjectFromApi,
  deleteInternalProjectImageByImageIdFromApi,
  deleteMemberFromApi,
  deleteRowParticipanteFromApi,
  downloadDailyFollowDocumentsFromApi,
  downloadDocumentAgreementFromApi,
  downloadDocumentFromApi,
  editInternalProjectImageFromApi,
  getBaseCampFromApi,
  getDocumentsFromApi,
  getFollowUpSixMonthsFormFromApi,
  getInternalProjectAcademyFromApi,
  getInternalProjectDailyFollowFromApi,
  getInternalProjectDailyFollowMetadataFromApi,
  getInternalProjectDailyFollowsExcel,
  getInternalProjectEnrollmentFromApi,
  getInternalProjectEnrollmentMetadataFromApi,
  getInternalProjectFollowsFromApi,
  getInternalProjectFromApi,
  getInternalProjectImagesFromApi,
  getInternalProjectsAcademyFromApi,
  getInternalProjectsDailyFollowsFromApi,
  getInternalProjectSettingsFromApi,
  getInternalProjectsFromApi,
  getInternalProjectsListExcel,
  getInternalProjectsTemplateByProjectTypeFromApi,
  getInternalProjectStudentEvaluationFromApi,
  getInternalProjectSubareasFromApi,
  getInternalProjectSubAreaTypesByCodeFromApi,
  getParticipantesFinalFollowsFormFromApi,
  getParticipantesFollowsListFromApi,
  getSectionTemplateFromApi,
  getStudentEvaluationInfoFromApi,
  getUsersCanBeMembersFromApi,
  removeDailyFollowDocumentsFromApi,
  removeDocumentAgreementFromApi,
  removeDocumentFromApi,
  sendInternalFollowUpFromApi,
  sendInternalProjectStudentEvaluationFromApi,
  updateEnrollmentFromApi,
  updateFollowUpDateFromApi,
  updateFollowUpSixMonthsFormFromApi,
  updateInternalFollowUpFromApi,
  updateInternalProject,
  updateInternalProjectEnrollmentsFromApi,
  updateInternalProjectSettingsFromApi,
  updateParticipantesFinalFollowUpFormFromApi,
  uploadDocumentAgreementFromApi,
  uploadDocumentsFromApi,
  uploadInternalProjectImageFileFromApi,
} from '../../api/internalProject';
import assertUnreachable from '../../helpers/assertUnreachable';
import errorMessage from '../../helpers/errorMessage';
import { tErrorKey, tKey } from '../../helpers/translate';
import { showSnackbar } from '../FeedbackAPI/actions';
import { InternalProjectsConst } from './action_types';
import {
  addDocumentDailyFollowKo,
  addDocumentDailyFollowOk,
  addDocumentFollowUpKo,
  addDocumentFollowUpOk,
  addInternalProjectImageOk,
  addMembersKo,
  addMembersOk,
  applyConfigTemplateFinish,
  createInternalProjectOk,
  createInternalProjectsKo,
  createInternalProjectValidationErrors,
  deleteDailyFollowKo,
  deleteDailyFollowOk,
  deleteDocumentsFollowUp,
  deleteDocumentsInternalProjectKo,
  deleteDocumentsInternalProjectOk,
  deleteInternalProjectEnrollmentOk,
  deleteInternalProjectImageOk,
  deleteInternalProjectKo,
  deleteInternalProjectOk,
  deleteMembersKo,
  deleteMembersOk,
  deleteRowParticipanteKo,
  deleteRowParticipanteOk,
  downloadDocumentAgreementKo,
  downloadDocumentAgreementOk,
  downloadDocumentDailyFollowKo,
  downloadDocumentDailyFollowOk,
  downloadDocumentsInternalProjectKo,
  downloadDocumentsInternalProjectOk,
  editInternalProjectImageOk,
  getDocumentsInternalProjectKo,
  getDocumentsInternalProjectOk,
  getFollowUpSixMonthsDataKo,
  getFollowUpSixMonthsFormOk,
  getInternalProjectAcademyKo,
  getInternalProjectAcademyOk,
  getInternalProjectDailyFollowKo,
  getInternalProjectDailyFollowOk,
  getInternalProjectDailyFollowsExcelKo,
  getInternalProjectDailyFollowsExcelOk,
  getInternalProjectDailyFollowsKo,
  getInternalProjectDailyFollowsOk,
  getInternalProjectEnrollmentOk,
  getInternalProjectFollowsOk,
  getInternalProjectImagestOk,
  getInternalProjectKo,
  getInternalProjectOk,
  getInternalProjectsAcademyKo,
  getInternalProjectsAcademyOk,
  getInternalProjectSettingsOk,
  getInternalProjectsExcelKo,
  getInternalProjectsExcelOk,
  getInternalProjectsKo,
  getInternalProjectsOk,
  getInternalProjectsTemplateKo,
  getInternalProjectsTemplateOk,
  getInternalProjectSubareasKo,
  getInternalProjectSubareasOk,
  getIpStudentEvaluationOk,
  getMetadataKo,
  getMetadataOk,
  getParticipantesFinalFollowUpFormKo,
  getParticipantesFinalFollowUpFormOk,
  getParticipantesFollowUpListKo,
  getParticipantesFollowUpListOk,
  getProjectBaseCampOk,
  getProjectTypeBySubareaCodeKo,
  getProjectTypeBySubareaCodeOk,
  getSectionTemplateKo,
  getSectionTemplateOk,
  getStudentEvaluationInfoOk,
  getUsersCanBeMembersOk,
  internalProjectsGenericKo,
  removeDocumentInternalProjectDailyFollowKo,
  removeDocumentInternalProjectDailyFollowOk,
  removeDocumentInternalProjectKo,
  removeDocumentInternalProjectOk,
  sendFollowUpKo,
  sendFollowUpOk,
  storeDailyFollowOk,
  updateEnrollmentOk,
  updateFollowUpDateKo,
  updateFollowUpDateOk,
  updateFollowUpKo,
  updateFollowUpOk,
  updateFollowUpSixMonthsFormKo,
  updateFollowUpSixMonthsFormOk,
  updateInternalProjectEnrollmentsOk,
  updateInternalProjectOk,
  updateInternalProjectSettingsOk,
  updateInternalProjectsKo,
  updateParticipantesFinalFollowUpFormKo,
  updateParticipantesFinalFollowUpFormOk,
  uploadDocumentKo,
  uploadDocumentOk,
  uploadDocumentsInternalProjectKo,
  uploadDocumentsInternalProjectOk,
} from './actions';
import {
  AddDocumentFollowUp,
  AddDocumentInternalProjectDailyFollowAction,
  AddInternalProjectImageAction,
  AddMembers,
  ApplyConfigTemplateAction,
  CreateInternalProjects,
  DeleteDailyFollow,
  DeleteDocumentstInternalProjectAction,
  DeleteInternalProject,
  DeleteInternalProjectEnrollment,
  DeleteInternalProjectImageAction,
  DeleteMembers,
  DeleteRowParticipante,
  DownloadDocumentInternalDailyFollowProjecttAction,
  DownloadDocumentInternalProjecttAction,
  DownloadDocumentstInternalProjectAction,
  EditInternalProjectImageAction,
  GetDocumentstInternalProjectAction,
  GetFollowUpSixMonthsForm,
  GetInternalProject,
  GetInternalProjectAcademy,
  GetInternalProjectDailyFollow,
  GetInternalProjectDailyFollows,
  GetInternalProjectDailyFollowsExcel,
  GetInternalProjectEnrollment,
  GetInternalProjectFollows,
  GetInternalProjectImagesAction,
  GetInternalProjects,
  GetInternalProjectsAcademy,
  GetInternalProjectSettings,
  GetInternalProjectsExcel,
  GetInternalProjectsTemplateList,
  GetInternalProjectSubareas,
  GetIpStudentEvaluation,
  GetMetadata,
  GetParticipantesFinalFollowUpForm,
  GetParticipantesFollowUpList,
  GetProjectBaseCamp,
  GetProjectTypeBySubareaCodeAction,
  GetSectionTemplate,
  GetStudentEvaluationInfo,
  GetUsersCanBeMembers,
  RemoveDocumentInternalProjectAction,
  RemoveDocumentInternalProjectDailyFollowAction,
  SendFollowUp,
  SendIpStudentEvaluation,
  StoreDailyFollow,
  UpdateEnrollment,
  UpdateFollowUp,
  UpdateFollowUpDate,
  UpdateFollowUpSixMonthsForm,
  UpdateInternalProjectEnrollments,
  UpdateInternalProjects,
  UpdateInternalProjectSettings,
  UpdateParticipantesFinalFollowUpForm,
  UploadDocumentInternalProjectAction,
  UploadDocumentstInternalProjectAction,
} from './definitions';

function* getInternalProjectSubareasFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectSubareasFromApi();
    yield put(getInternalProjectSubareasOk(data));
  } catch (e) {
    yield put(getInternalProjectSubareasKo(errorMessage(e)));
  }
}

function* getInternalProjectSettingsSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectSettingsFromApi();
    yield put(getInternalProjectSettingsOk(data));
  } catch (e) {
    yield put(getInternalProjectsAcademyKo(errorMessage(e)));
  }
}

function* getInternalProjectsFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectsFromApi();
    yield put(getInternalProjectsOk(data));
  } catch (e) {
    yield put(getInternalProjectsKo(errorMessage(e)));
  }
}

function* getInternalProjectsTemplatesFromSaga(action: GetInternalProjectsTemplateList): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectsTemplateByProjectTypeFromApi(action.project_type_id);
    yield put(getInternalProjectsTemplateOk(data));
  } catch (e) {
    yield put(getInternalProjectsTemplateKo(errorMessage(e)));
  }
}

function* createInternalProjectFromSaga(action: CreateInternalProjects): Generator<any, void, any> {
  try {
    const result = (yield createInternalProjectApi(action.internalProject)) as Await<
      ReturnType<typeof createInternalProjectApi>
    >;
    switch (result.type) {
      case 'ok':
        const { data: project } = result.value;
        yield put(createInternalProjectOk(project));
        return;

      case 'validation-error':
        const error = result.value;
        yield put(createInternalProjectValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(createInternalProjectsKo(errorMessage(e)));
    yield put(showSnackbar(tErrorKey('error_crear_proyecto'), 'error'));
  }
}

function* updateInternalProjectFromSaga(action: UpdateInternalProjects): Generator<any, void, any> {
  try {
    const result = (yield updateInternalProject(action.internalProject, action.id)) as Await<
      ReturnType<typeof updateInternalProject>
    >;
    switch (result.type) {
      case 'ok':
        const { data: info } = result.value;
        yield put(updateInternalProjectOk(info));
        yield put(showSnackbar(tKey('proyecto-editado'), 'success'));

        return;

      case 'validation-error':
        const error = result.value;
        yield put(createInternalProjectValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(updateInternalProjectsKo(errorMessage(e)));
    yield put(showSnackbar(tErrorKey('error_crear_proyecto'), 'error'));
  }
}

function* getInternalProjectFromSaga(action: GetInternalProject): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectFromApi(action.id);
    yield put(getInternalProjectOk(data));
  } catch (e) {
    yield put(getInternalProjectKo(errorMessage(e)));
  }
}

function* addMemberFromSaga(action: AddMembers): Generator<any, void, any> {
  try {
    const { data } = yield addMemberFromApi(action.user_id, action.internal_project_id);
    yield put(addMembersOk(data));
  } catch (e) {
    yield put(addMembersKo(errorMessage(e)));
  }
}

function* getUsersCanBeMembersFromSaga(action: GetUsersCanBeMembers): Generator<any, void, any> {
  try {
    const { data } = yield getUsersCanBeMembersFromApi(action.internal_project_id);
    yield put(getUsersCanBeMembersOk(data));
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
  }
}

function* deleteMemberFromSaga(action: DeleteMembers): Generator<any, void, any> {
  try {
    yield deleteMemberFromApi(action.user_id, action.internal_project_id);
    yield put(deleteMembersOk(action.user_id));
  } catch (e) {
    yield put(deleteMembersKo(errorMessage(e)));
  }
}

function* uploadDocumentInternalProjectFromSaga(
  action: UploadDocumentInternalProjectAction,
): Generator<any, void, any> {
  try {
    const data = yield uploadDocumentAgreementFromApi(action.internalProjectId, action.data);
    yield put(uploadDocumentOk(data));
    yield put(showSnackbar(tKey('documento-subido'), 'success', undefined, 1500));
  } catch (e) {
    yield put(uploadDocumentKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error', undefined, 1500));
  }
}

function* downloadDocumentInternalProjectFromSaga(
  action: DownloadDocumentInternalProjecttAction,
): Generator<any, void, any> {
  try {
    const data = yield downloadDocumentAgreementFromApi(action.internal_project_id, action.file_id);
    yield put(downloadDocumentAgreementOk(data, action.name, action.get));
    yield put(showSnackbar(tKey('documento-descargado'), 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadDocumentAgreementKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error', undefined, 1500));
  }
}

function* removeDocumentFromSaga(action: RemoveDocumentInternalProjectAction): Generator<any, void, any> {
  try {
    const { file_id, internal_project_id } = action;
    const document = yield removeDocumentAgreementFromApi(internal_project_id, file_id);
    yield put(removeDocumentInternalProjectOk(document));
    yield put(showSnackbar('Documento eliminado correctamente', 'success'));
  } catch (error) {
    const e = error as ErrorEvent;
    yield put(removeDocumentInternalProjectKo(errorMessage(error)));
    yield put(showSnackbar(e.message, 'error'));
  }
}

function* getDocumentsFromSaga(action: GetDocumentstInternalProjectAction): Generator<any, void, any> {
  try {
    const { internal_project_id } = action;
    const document = yield getDocumentsFromApi(internal_project_id);
    yield put(getDocumentsInternalProjectOk(document));
  } catch (e) {
    yield put(getDocumentsInternalProjectKo(errorMessage(e)));
  }
}

function* uploadDocumentsFromSaga(action: UploadDocumentstInternalProjectAction): Generator<any, void, any> {
  try {
    const { internal_project_id, description, file, code } = action;
    const document = yield uploadDocumentsFromApi(internal_project_id, file, description, code);
    yield put(uploadDocumentsInternalProjectOk(document));
    yield put(showSnackbar(tKey('documento-subido'), 'success'));
  } catch (e) {
    yield put(uploadDocumentsInternalProjectKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error'));
  }
}

function* removeDocumentIPFromSaga(action: DeleteDocumentstInternalProjectAction): Generator<any, void, any> {
  try {
    const { file_id, internal_project_id } = action;
    const document = yield removeDocumentFromApi(internal_project_id, file_id, action?.follow_up_id);
    if (action?.follow_up_id && action.inputName) {
      yield put(deleteDocumentsFollowUp(internal_project_id, file_id, action.follow_up_id, action.inputName));
    } else {
      yield put(deleteDocumentsInternalProjectOk(document));
    }
    yield put(showSnackbar(tKey('documento-borrado'), 'success'));
  } catch (error) {
    const e = error as ErrorEvent;
    yield put(deleteDocumentsInternalProjectKo(errorMessage(error)));
    yield put(showSnackbar(e.message, 'error'));
  }
}

function* downloadDocumentFromSaga(action: DownloadDocumentstInternalProjectAction): Generator<any, void, any> {
  try {
    const data = yield downloadDocumentFromApi(action.internal_project_id, action.file_id, action?.follow_up_id);
    yield put(downloadDocumentsInternalProjectOk(data, action.name, action?.get));
    yield put(showSnackbar(tKey('documento-descargado'), 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadDocumentsInternalProjectKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error', undefined, 1500));
  }
}

function* getInternalProjectDailyFollowsFromSaga(action: GetInternalProjectDailyFollows): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectsDailyFollowsFromApi(action.internal_project_id);
    yield put(getInternalProjectDailyFollowsOk(data));
  } catch (e) {
    yield put(getInternalProjectDailyFollowsKo(errorMessage(e)));
  }
}

function* getInternalProjectsExcelFromSaga(): Generator<any, void, any> {
  try {
    const data = yield getInternalProjectsListExcel();
    yield put(getInternalProjectsExcelOk(data));
  } catch (e) {
    yield put(getInternalProjectsExcelKo(errorMessage(e)));
  }
}

function* getInternalProjectDailyFollowFromSaga(action: GetInternalProjectDailyFollow): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectDailyFollowFromApi(action.dailyFollowsId);

    yield put(getInternalProjectDailyFollowOk(data));
  } catch (e) {
    yield put(getInternalProjectDailyFollowKo(errorMessage(e)));
  }
}

function* addDocumentsDailyFollowFromSaga(
  action: AddDocumentInternalProjectDailyFollowAction,
): Generator<any, void, any> {
  try {
    const { dailyFollowsId, description, data } = action;
    if (!action.dailyFollow) {
      const document = yield addInternalProjectDailyFollowDocumentsFromApi(dailyFollowsId, data, description);
      yield put(addDocumentDailyFollowOk(document));
      yield put(showSnackbar(tKey('documento-subido'), 'success'));
    } else {
      try {
        const result = (yield createInternalProjectDailyFollowApi(action.dailyFollow)) as Await<
          ReturnType<typeof createInternalProjectDailyFollowApi>
        >;
        switch (result.type) {
          case 'ok':
            const {
              data: { internal_project_daily_follow_id, data: project },
            } = result.value;
            yield put(storeDailyFollowOk(project, internal_project_daily_follow_id));
            const document = yield addInternalProjectDailyFollowDocumentsFromApi(
              internal_project_daily_follow_id,
              data,
              description,
            );
            yield put(addDocumentDailyFollowOk(document));
            yield put(showSnackbar(tKey('documento-subido'), 'success'));
            return;

          case 'validation-error':
            const error = result.value;
            yield put(createInternalProjectValidationErrors(error));
            yield put(showSnackbar(tErrorKey('ip.seguimiento_error'), 'error', undefined, 1500));
            return;
        }
        assertUnreachable(result);
      } catch (e) {
        yield put(createInternalProjectsKo(errorMessage(e)));
        yield put(showSnackbar(tErrorKey('ip.seguimiento_error'), 'error'));
      }
    }
  } catch (e) {
    yield put(addDocumentDailyFollowKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error'));
  }
}

function* removeDocumentDailyFollowromSaga(
  action: RemoveDocumentInternalProjectDailyFollowAction,
): Generator<any, void, any> {
  try {
    const { file_id, dailyFollowsId } = action;
    const document = yield removeDailyFollowDocumentsFromApi(dailyFollowsId, file_id);
    yield put(removeDocumentInternalProjectDailyFollowOk(document));
    yield put(showSnackbar(tKey('documento-borrado'), 'success'));
  } catch (e) {
    yield put(removeDocumentInternalProjectDailyFollowKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error'));
  }
}

function* downloadDocumentDailyFollowFromSaga(
  action: DownloadDocumentInternalDailyFollowProjecttAction,
): Generator<any, void, any> {
  try {
    const data = yield downloadDailyFollowDocumentsFromApi(action.dailyFollowsId, action.file_id);
    yield put(downloadDocumentDailyFollowOk(data, action.name, action?.get));
    yield put(showSnackbar(tKey('documento-descargado'), 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadDocumentDailyFollowKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error', undefined, 1500));
  }
}

function* getMetadataFromSaga(action: GetMetadata): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectDailyFollowMetadataFromApi(action.subarea_internal_project_type_id);
    yield put(getMetadataOk(data));
  } catch (e) {
    yield put(getMetadataKo(errorMessage(e)));
  }
}

function* createInternalProjectDailyFollowFromSaga(action: StoreDailyFollow): Generator<any, void, any> {
  try {
    const result = (yield createInternalProjectDailyFollowApi(action.dailyFollow)) as Await<
      ReturnType<typeof createInternalProjectDailyFollowApi>
    >;
    switch (result.type) {
      case 'ok':
        const {
          data: { internal_project_daily_follow_id, data: project },
        } = result.value;
        yield put(storeDailyFollowOk(project, internal_project_daily_follow_id));
        yield put(showSnackbar(tKey('ip.seguimiento_creado'), 'success', undefined, 1500));
        return;

      case 'validation-error':
        const error = result.value;
        yield put(createInternalProjectValidationErrors(error));
        yield put(showSnackbar(tErrorKey('ip.seguimiento_error'), 'error', undefined, 1500));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(createInternalProjectsKo(errorMessage(e)));
    yield put(showSnackbar(tErrorKey('ip.seguimiento_error'), 'error'));
  }
}

function* deleteDailyFollowFromSaga(action: DeleteDailyFollow): Generator<any, void, any> {
  try {
    yield deleteDailyFollowFromApi(action.dailyFollowId);
    yield put(deleteDailyFollowOk(action.dailyFollowId));
  } catch (e) {
    yield put(deleteDailyFollowKo(errorMessage(e)));
  }
}

function* deleteInternalProjectFromSaga(action: DeleteInternalProject): Generator<any, void, any> {
  try {
    const { data } = yield deleteInternalProjectFromApi(action.projectId);
    yield put(deleteInternalProjectOk(data));
    yield put(showSnackbar(tKey('ip.borrado'), 'success'));
  } catch (e) {
    yield put(deleteInternalProjectKo(errorMessage(e)));
    yield put(showSnackbar(tKey('ip.error'), 'error'));
  }
}

function* getInternalProjectDailyFollowExcelFromSaga(
  action: GetInternalProjectDailyFollowsExcel,
): Generator<any, void, any> {
  try {
    const data = yield getInternalProjectDailyFollowsExcel(action.internalProjectId);
    yield put(getInternalProjectDailyFollowsExcelOk(data));
  } catch (e) {
    yield put(getInternalProjectDailyFollowsExcelKo(errorMessage(e)));
  }
}

function* getInternalProjectsAcademyFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectsAcademyFromApi();
    yield put(getInternalProjectsAcademyOk(data));
  } catch (e) {
    yield put(getInternalProjectsAcademyKo(errorMessage(e)));
  }
}

function* getInternalProjectAcademyFromSaga(action: GetInternalProjectAcademy): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectAcademyFromApi(action.id);
    yield put(getInternalProjectAcademyOk(data));
  } catch (e) {
    yield put(getInternalProjectAcademyKo(errorMessage(e)));
  }
}
function* getParticipantesFollowsListFromSaga(action: GetParticipantesFollowUpList): Generator<any, void, any> {
  try {
    const { data } = yield getParticipantesFollowsListFromApi(action.internalProjectId);
    yield put(getParticipantesFollowUpListOk(data));
  } catch (e) {
    yield put(getParticipantesFollowUpListKo(errorMessage(e)));
  }
}

function* getFollowUpSixMonthsFormFromSaga(action: GetFollowUpSixMonthsForm): Generator<any, void, any> {
  try {
    const { data } = yield getFollowUpSixMonthsFormFromApi(action.enrollmentId);
    yield put(getFollowUpSixMonthsFormOk(data));
  } catch (e) {
    yield put(getFollowUpSixMonthsDataKo(errorMessage(e)));
  }
}

function* UpdateFollowUpSixMonthsFormFromSaga(action: UpdateFollowUpSixMonthsForm): Generator<any, void, any> {
  try {
    const followUpSixMonthsData = yield updateFollowUpSixMonthsFormFromApi(
      action.enrollmentId,
      action.followUpSixMonthsData,
    );
    yield put(updateFollowUpSixMonthsFormOk(followUpSixMonthsData));
    yield put(showSnackbar(tKey('ip.exito_formulario_registrado'), 'success'));
  } catch (e) {
    yield put(updateFollowUpSixMonthsFormKo(errorMessage(e)));
    yield put(showSnackbar(tKey('ip.error_formulario_no_registrado'), 'error'));
  }
}

function* getParticipantesFinalFollowsFormFromSaga(
  action: GetParticipantesFinalFollowUpForm,
): Generator<any, void, any> {
  try {
    const { data } = yield getParticipantesFinalFollowsFormFromApi(action.internal_project_enrollment_id);
    yield put(getParticipantesFinalFollowUpFormOk(data));
  } catch (e) {
    yield put(getParticipantesFinalFollowUpFormKo(errorMessage(e)));
  }
}
function* updateParticipantesFinalFollowUpFormFromSaga(
  action: UpdateParticipantesFinalFollowUpForm,
): Generator<any, void, any> {
  try {
    const { data } = yield updateParticipantesFinalFollowUpFormFromApi(
      action.internal_project_enrollment_id,
      action.participanteFinalFormData,
    );
    yield put(updateParticipantesFinalFollowUpFormOk(data, action.internal_project_enrollment_id));
    yield put(showSnackbar(tKey('ip.exito_formulario_registrado'), 'success'));
  } catch (e) {
    yield put(updateParticipantesFinalFollowUpFormKo(errorMessage(e)));
    yield put(showSnackbar(tKey('ip.error_formulario_no_registrado'), 'error'));
  }
}
function* getInternalProjectFollowsFromSaga(action: GetInternalProjectFollows): Generator<any, void, any> {
  try {
    const { data } = yield getInternalProjectFollowsFromApi(action.internalProjectId);
    yield put(getInternalProjectFollowsOk(data));
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
  }
}

function* updateFollowUpDateFromSaga(action: UpdateFollowUpDate): Generator<any, void, any> {
  try {
    yield updateFollowUpDateFromApi(action.internalProjectFollowId, action.follow_date);
    yield put(updateFollowUpDateOk(action.internalProjectFollowId, action.follow_date));
  } catch (e) {
    yield put(updateFollowUpDateKo(errorMessage(e)));
  }
}

function* updateInternalProjectSettingsFromSaga(action: UpdateInternalProjectSettings): Generator<any, void, any> {
  try {
    yield updateInternalProjectSettingsFromApi(action.settings);
    yield put(updateInternalProjectSettingsOk(action.settings));
    yield put(showSnackbar(tKey('Fecha registrada'), 'success'));
  } catch (e) {
    yield put(updateFollowUpDateKo(errorMessage(e)));
  }
}

function* getSectionTemplateFromSaga(action: GetSectionTemplate): Generator<any, void, any> {
  try {
    const { data } = yield getSectionTemplateFromApi(action.internalProjectId, action.section, action.followUpId);
    yield put(getSectionTemplateOk(data.fields, data.participante));
  } catch (e) {
    yield put(getSectionTemplateKo(errorMessage(e)));
  }
}

function* getUpdateFollowUpFromSaga(action: UpdateFollowUp): Generator<any, void, any> {
  try {
    const { data } = yield updateInternalFollowUpFromApi(action.data);
    yield put(updateFollowUpOk(data.fields, data.participante));
    yield put(showSnackbar(tKey('ip.seguimiento-editado'), 'success'));
  } catch (e) {
    yield put(updateFollowUpKo(errorMessage(e)));
    yield put(showSnackbar(tErrorKey('ip.seguimiento-editado'), 'error'));
  }
}

function* getSendFollowUpFromSaga(action: SendFollowUp): Generator<any, void, any> {
  try {
    yield sendInternalFollowUpFromApi(action.data);
    yield put(sendFollowUpOk(action.data.internal_project_follow_up_id));
    yield put(showSnackbar(tKey('ip.seguimiento-enviado'), 'success'));
  } catch (error) {
    const e = error as ErrorEvent;
    yield put(sendFollowUpKo(errorMessage(e)));
    yield put(showSnackbar(e.message, 'error'));
  }
}

function* addDocumentInternalProjectFromSaga(action: AddDocumentFollowUp): Generator<any, void, any> {
  try {
    const {
      data: {
        fields: { documents },
      },
    } = yield addDocumentsFollowUpFromApi(
      action.internal_project_id,
      action.follow_up_id,
      action.file,
      action.description,
      action.custom_field_name,
      action.section,
    );
    yield put(addDocumentFollowUpOk(documents, action.custom_field_name));
    yield put(showSnackbar(tKey('documento-subido'), 'success', undefined, 1500));
  } catch (e) {
    yield put(addDocumentFollowUpKo(errorMessage(e)));
    yield put(showSnackbar(tKey('documento-error'), 'error', undefined, 1500));
  }
}

function* deleteRowParticipanteFromSaga(action: DeleteRowParticipante): Generator<any, void, any> {
  try {
    if (action.idRow) yield deleteRowParticipanteFromApi(action.idRow, action.section);
    yield put(deleteRowParticipanteOk(action.idRow, action.idGenerated));
  } catch (e) {
    yield put(deleteRowParticipanteKo(errorMessage(e)));
    yield put(showSnackbar(tErrorKey('ip.participante_borrado'), 'error', undefined, 1500));
  }
}

function* getProjectBaseCampFromSaga(action: GetProjectBaseCamp): Generator<any, void, any> {
  try {
    const baseCamp = yield getBaseCampFromApi(action.project_id, action.follow_up_id);
    yield put(getProjectBaseCampOk(baseCamp));
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
  }
}

function* updateEnrollmentFromSaga(action: UpdateEnrollment): Generator<any, void, any> {
  try {
    const enrollment = yield updateEnrollmentFromApi(action.enrollment);
    yield put(updateEnrollmentOk(enrollment));
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
  }
}

function* deleteEnrollmentFromSaga(action: DeleteInternalProjectEnrollment): Generator<any, void, any> {
  try {
    const ok = yield deleteInternalProjectEnrollmentFromApi(action.enrollment_id);
    if (ok) {
      yield put(deleteInternalProjectEnrollmentOk(action.enrollment_id));
    }
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
  }
}

function* getInternalProjectEnrollmentFromSaga(action: GetInternalProjectEnrollment): Generator<any, void, any> {
  try {
    const enrollments = yield getInternalProjectEnrollmentFromApi(action.internal_project_id);
    const metadata = yield getInternalProjectEnrollmentMetadataFromApi();
    yield put(getInternalProjectEnrollmentOk(enrollments, metadata));
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
  }
}

function* getIpStudentEvaluationFromSaga(action: GetIpStudentEvaluation): Generator<any, void, any> {
  try {
    const studentEvaluation = yield getInternalProjectStudentEvaluationFromApi(action.project_key);
    yield put(getIpStudentEvaluationOk(studentEvaluation));
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
    document.location.replace('/not-found/404');
  }
}

function* updateInternalProjectEnrollmentsFromSaga(
  action: UpdateInternalProjectEnrollments,
): Generator<any, void, any> {
  try {
    const enrollments = yield updateInternalProjectEnrollmentsFromApi(action.internal_project_id, action.enrollments);
    yield put(updateInternalProjectEnrollmentsOk(enrollments));
    yield put(showSnackbar(tKey('ip.exito_tabla_de_participantes_actualizada'), 'success'));
  } catch (e) {
    yield put(updateFollowUpDateKo(errorMessage(e)));
    yield put(showSnackbar(tKey('ip.error_tabla_de_participantes_no_actualizada'), 'error'));
  }
}

function* sendIpStudentEvaluationFromSaga(action: SendIpStudentEvaluation): Generator<any, void, any> {
  try {
    const ok = yield sendInternalProjectStudentEvaluationFromApi(action.data);
    if (ok) {
      yield put(showSnackbar(tKey('La evaluación se ha enviado correctamente'), 'success', '/', 3000));
    }
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 3000));
  }
}

function* getStudentEvaluationInfoFromSaga(action: GetStudentEvaluationInfo): Generator<any, void, any> {
  try {
    const studentEvalutation = yield getStudentEvaluationInfoFromApi(action.project_id, action.follow_up_id);
    yield put(getStudentEvaluationInfoOk(studentEvalutation));
  } catch (e) {
    yield put(internalProjectsGenericKo(e));
  }
}

function* getInternalProjectImagesFromSaga(action: GetInternalProjectImagesAction): Generator<any, void, any> {
  try {
    const projectImages = yield getInternalProjectImagesFromApi(action.id);
    yield put(getInternalProjectImagestOk(projectImages));
  } catch (e) {
    yield put(showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500));
    yield put(internalProjectsGenericKo(e));
  }
}

function* addInternalProjectImageFromSaga(action: AddInternalProjectImageAction): Generator<any, void, any> {
  try {
    const imageUploaded = yield uploadInternalProjectImageFileFromApi(action.image, action.name);
    const { data } = yield addInternalProjectImageFromApi(
      action.id,
      action.name,
      action.description,
      imageUploaded.id,
      action.userId,
    );
    yield put(addInternalProjectImageOk(data));
  } catch (e) {
    yield put(showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500));
    yield put(internalProjectsGenericKo(e));
  }
}

function* editInternalProjectImageFromSaga(action: EditInternalProjectImageAction): Generator<any, void, any> {
  try {
    let imageId = 0;
    if (action.resourceImage.image !== action.newImage)
      imageId = (yield uploadInternalProjectImageFileFromApi(action.newImage, action.name)).id;

    const { data } = yield editInternalProjectImageFromApi(
      action.id,
      action.resourceImage.id,
      action.name,
      action.description,
      action.resourceImage.image_id, // previous image id
      imageId,
      action.userId,
    );
    yield put(editInternalProjectImageOk(data));
  } catch (e) {
    yield put(showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500));
    yield put(internalProjectsGenericKo(e));
  }
}

function* deleteInternalProjectImageFromSaga(action: DeleteInternalProjectImageAction): Generator<any, void, any> {
  try {
    yield deleteInternalProjectImageByImageIdFromApi(action.resourceImage.image_id);
    yield put(deleteInternalProjectImageOk(action.resourceImage.id));
  } catch (e) {
    yield put(showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500));
    yield put(internalProjectsGenericKo(e));
  }
}

function* getInternalProjectSubAreaTypesByCodeFromSaga(
  action: GetProjectTypeBySubareaCodeAction,
): Generator<any, void, any> {
  try {
    const project_types = yield getInternalProjectSubAreaTypesByCodeFromApi(action.subAreaCode);
    yield put(getProjectTypeBySubareaCodeOk(project_types));
  } catch (e) {
    yield put(getProjectTypeBySubareaCodeKo(errorMessage(e)));
  }
}

function* applyConfigTemplate(action: ApplyConfigTemplateAction): Generator<any, void, any> {
  try {
    yield applyConfigInternalProjectTemplateFromApi(action.internalProjectId);
    yield put(showSnackbar(tKey('Proyecto actualizado'), 'success', undefined, 3000));

    const internalProjectData = yield getInternalProjectFromApi(action.internalProjectId);
    yield put(getInternalProjectOk(internalProjectData.data));

    const internalProjectFollowsData = yield getInternalProjectFollowsFromApi(action.internalProjectId);
    yield put(getInternalProjectFollowsOk(internalProjectFollowsData.data));
    yield put(applyConfigTemplateFinish());
  } catch (e) {
    yield put(showSnackbar(tKey('Ha habido un error'), 'error', undefined, 1500));
    yield put(applyConfigTemplateFinish());
  }
}

export default [
  takeLatest<GetInternalProjectSubareas>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS,
    getInternalProjectSubareasFromSaga,
  ),
  takeLatest<GetInternalProjectSettings>(InternalProjectsConst.GET_SETTINGS, getInternalProjectSettingsSaga),
  takeLatest<GetInternalProjects>(InternalProjectsConst.GET_INTERNAL_PROJECTS, getInternalProjectsFromSaga),
  takeLatest<GetInternalProjectsTemplateList>(
    InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE,
    getInternalProjectsTemplatesFromSaga,
  ),
  takeLatest<CreateInternalProjects>(InternalProjectsConst.CREATE_INTERNAL_PROJECTS, createInternalProjectFromSaga),
  takeLatest<UpdateInternalProjects>(InternalProjectsConst.UPDATE_INTERNAL_PROJECT, updateInternalProjectFromSaga),
  takeLatest<GetInternalProject>(InternalProjectsConst.GET_INTERNAL_PROJECT, getInternalProjectFromSaga),
  takeLatest<AddMembers>(InternalProjectsConst.ADD_MEMBERS, addMemberFromSaga),
  takeLatest<GetUsersCanBeMembers>(InternalProjectsConst.GET_PROJECT_ALLOWED_USERS, getUsersCanBeMembersFromSaga),
  takeLatest<DeleteMembers>(InternalProjectsConst.DELETE_MEMBERS, deleteMemberFromSaga),
  takeLatest<UploadDocumentInternalProjectAction>(
    InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT,
    uploadDocumentInternalProjectFromSaga,
  ),
  takeLatest<DownloadDocumentInternalProjecttAction>(
    InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT,
    downloadDocumentInternalProjectFromSaga,
  ),
  takeLatest<RemoveDocumentInternalProjectAction>(
    InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT,
    removeDocumentFromSaga,
  ),
  takeLatest<GetDocumentstInternalProjectAction>(
    InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT,
    getDocumentsFromSaga,
  ),
  takeLatest<UploadDocumentstInternalProjectAction>(
    InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT,
    uploadDocumentsFromSaga,
  ),
  takeLatest<DeleteDocumentstInternalProjectAction>(
    InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT,
    removeDocumentIPFromSaga,
  ),
  takeLatest<DownloadDocumentstInternalProjectAction>(
    InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT,
    downloadDocumentFromSaga,
  ),
  takeLatest<GetInternalProjectDailyFollows>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS,
    getInternalProjectDailyFollowsFromSaga,
  ),
  takeLatest<GetInternalProjectsExcel>(
    InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL,
    getInternalProjectsExcelFromSaga,
  ),
  takeLatest<GetInternalProjectDailyFollow>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW,
    getInternalProjectDailyFollowFromSaga,
  ),
  takeLatest<AddDocumentInternalProjectDailyFollowAction>(
    InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW,
    addDocumentsDailyFollowFromSaga,
  ),
  takeLatest<RemoveDocumentInternalProjectDailyFollowAction>(
    InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW,
    removeDocumentDailyFollowromSaga,
  ),
  takeLatest<DownloadDocumentInternalDailyFollowProjecttAction>(
    InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW,
    downloadDocumentDailyFollowFromSaga,
  ),
  takeLatest<GetMetadata>(InternalProjectsConst.GET_METADATA, getMetadataFromSaga),
  takeLatest<StoreDailyFollow>(InternalProjectsConst.STORE_DAILY_FOLLOW, createInternalProjectDailyFollowFromSaga),
  takeLatest<DeleteDailyFollow>(InternalProjectsConst.DELETE_DAILY_FOLLOW, deleteDailyFollowFromSaga),
  takeLatest<DeleteInternalProject>(InternalProjectsConst.DELETE_INTERNAL_PROJECT, deleteInternalProjectFromSaga),
  takeLatest<GetInternalProjectDailyFollowsExcel>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL,
    getInternalProjectDailyFollowExcelFromSaga,
  ),
  takeLatest<GetInternalProjectsAcademy>(
    InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY,
    getInternalProjectsAcademyFromSaga,
  ),
  takeLatest<GetInternalProjectAcademy>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY,
    getInternalProjectAcademyFromSaga,
  ),
  takeLatest<GetInternalProjectFollows>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_FOLLOWS,
    getInternalProjectFollowsFromSaga,
  ),
  takeLatest<UpdateFollowUpDate>(InternalProjectsConst.UPDATE_FOLLOW_UP_DATE, updateFollowUpDateFromSaga),
  takeLatest<GetSectionTemplate>(InternalProjectsConst.GET_SECTION_TEMPLATE, getSectionTemplateFromSaga),
  takeLatest<UpdateFollowUp>(InternalProjectsConst.UPDATE_FOLLOW_UP, getUpdateFollowUpFromSaga),
  takeLatest<AddDocumentFollowUp>(InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP, addDocumentInternalProjectFromSaga),
  takeLatest<SendFollowUp>(InternalProjectsConst.SEND_FOLLOW_UP, getSendFollowUpFromSaga),
  takeLatest<UpdateInternalProjectSettings>(
    InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS,
    updateInternalProjectSettingsFromSaga,
  ),
  takeLatest<DeleteRowParticipante>(InternalProjectsConst.DELETE_ROW_PARTICIPANTE, deleteRowParticipanteFromSaga),
  takeLatest<GetParticipantesFollowUpList>(
    InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST,
    getParticipantesFollowsListFromSaga,
  ),
  takeLatest<GetFollowUpSixMonthsForm>(
    InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM,
    getFollowUpSixMonthsFormFromSaga,
  ),
  takeLatest<UpdateFollowUpSixMonthsForm>(
    InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM,
    UpdateFollowUpSixMonthsFormFromSaga,
  ),
  takeLatest<GetParticipantesFinalFollowUpForm>(
    InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM,
    getParticipantesFinalFollowsFormFromSaga,
  ),
  takeLatest<UpdateParticipantesFinalFollowUpForm>(
    InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM,
    updateParticipantesFinalFollowUpFormFromSaga,
  ),
  takeLatest<GetProjectBaseCamp>(InternalProjectsConst.GET_BASE_CAMP, getProjectBaseCampFromSaga),
  takeLatest<UpdateEnrollment>(InternalProjectsConst.UPDATE_ENROLLMENT, updateEnrollmentFromSaga),
  takeLatest<DeleteInternalProjectEnrollment>(InternalProjectsConst.DELETE_ENROLLMENT, deleteEnrollmentFromSaga),
  takeLatest<GetInternalProjectEnrollment>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_ENROLLMENTS,
    getInternalProjectEnrollmentFromSaga,
  ),
  takeLatest<GetIpStudentEvaluation>(InternalProjectsConst.GET_IP_STUDENT_EVALUATION, getIpStudentEvaluationFromSaga),
  takeLatest<SendIpStudentEvaluation>(
    InternalProjectsConst.SEND_IP_STUDENT_EVALUATION,
    sendIpStudentEvaluationFromSaga,
  ),
  takeLatest<UpdateInternalProjectEnrollments>(
    InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS,
    updateInternalProjectEnrollmentsFromSaga,
  ),
  takeLatest<GetStudentEvaluationInfo>(
    InternalProjectsConst.GET_STUDENT_EVALUATION_INFO,
    getStudentEvaluationInfoFromSaga,
  ),
  takeLatest<GetInternalProjectImagesAction>(
    InternalProjectsConst.GET_INTERNAL_PROJECT_IMAGES,
    getInternalProjectImagesFromSaga,
  ),
  takeLatest<AddInternalProjectImageAction>(
    InternalProjectsConst.ADD_INTERNAL_PROJECT_IMAGE,
    addInternalProjectImageFromSaga,
  ),
  takeLatest<EditInternalProjectImageAction>(
    InternalProjectsConst.EDIT_INTERNAL_PROJECT_IMAGE,
    editInternalProjectImageFromSaga,
  ),
  takeLatest<DeleteInternalProjectImageAction>(
    InternalProjectsConst.DELETE_INTERNAL_PROJECT_IMAGE,
    deleteInternalProjectImageFromSaga,
  ),
  takeLatest<GetProjectTypeBySubareaCodeAction>(
    InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE,
    getInternalProjectSubAreaTypesByCodeFromSaga,
  ),
  takeLatest<ApplyConfigTemplateAction>(InternalProjectsConst.APPLY_CONFIG_TEMPLATE, applyConfigTemplate),
];
