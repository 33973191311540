/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, InputLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { connect } from 'react-redux';
import * as yup from 'yup';
import { useButtonActions } from '../../helpers/customHooks/useHookMethod';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import { CodeSectionProject, CustomFieldDocument, CustomFieldElement } from '../../redux/kpis/definitions';
import InformationMinimal from '../../routes/InternalProjects/InternalProjectEdition/Forms/Information/InformationMinimal';
import { addFieldsToSchemaWithRequired } from '../../routes/Project/generateSchemaGenericForm';
import FncAddDocuments, { AddDocumentsProps } from '../UploadFile/FncAddDocuments';
import GenericFormCustomFields from './GenericFormCustomFields';
import styles from './genericFormRefactor.module.scss';

export type Fields = {
  documents: CustomFieldDocument[];
  elements: CustomFieldElement[];
};

export type GenericRows = {
  id: number | null;
  id_generated: number | null;
  [key: string]: string | number | null;
};

export type GenericFromRequest = {
  fields: { [key: string]: string };
  internal_project_id: number;
  internal_project_follow_up_id: number;
  section: string;
  rows: GenericRows[];
};

type GenericForm<T extends FieldValues> = {
  idData: number;
  fields: { documents: CustomFieldDocument[]; elements: CustomFieldElement[] } | null;
  data?: any;
  section?: CodeSectionProject;
  disabled?: boolean;
  showProjectInfo: boolean;
  buttonSave?: ButtonProps;
  buttonSend?: ButtonProps;
  onHandleSave?: (data: any) => void;
  onHandleSend?: (data: any) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar) => void;
} & Pick<AddDocumentsProps, 'actions' | 'upload' | 'download' | 'remove' | 'getFile' | 'addDoc'>;

const GenericForm = <T extends FieldValues, K>({
  idData,
  fields,
  actions,
  disabled,
  showProjectInfo,
  buttonSave,
  buttonSend,
  onHandleSave,
  onHandleSend,
  download,
  upload,
  remove,
  getFile,
  addDoc,
}: GenericForm<T>) => {
  // const [fieldsSchema, setFieldsSchema] = useState(addFieldsToSchemaWithRequired(fieldsData));

  const [customFields, setCustomFields] = useState<CustomFieldElement[]>([]);
  const [documentFields, setDocumentFields] = useState<CustomFieldDocument[]>([]);

  useEffect(() => {
    if (fields) {
      const { documents, elements } = fields;
      const sortedDocuments = documents && documents.sort((a, b) => (a.position < b.position ? -1 : 1));
      setDocumentFields(sortedDocuments);
      const sortedFields = elements && elements.sort((a, b) => (a.position < b.position ? -1 : 1));
      setCustomFields(sortedFields);
    }
  }, [fields]);

  const schema: yup.ObjectSchema<yup.Shape<object, any>> = yup.object().shape(
    addFieldsToSchemaWithRequired({
      elements: customFields,
    }),
  );

  const { control, errors, handleSubmit, clearError, setValue } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: schema,
  });

  const handleSave = (data: any) => {
    if (onHandleSave) onHandleSave(data);
  };

  const handleSend = (data: any) => {
    if (onHandleSend) onHandleSend(data);
  };

  useButtonActions(buttonSave, handleSubmit(handleSave));
  useButtonActions(buttonSend, handleSubmit(handleSend));

  const getArrayDocs = (sortedDocuments: CustomFieldDocument[], nameT: string) => {
    const arrDocs = sortedDocuments
      .filter(e => e.name === nameT)
      .map(f => {
        return f.value_file;
      });

    return arrDocs[0];
  };

  const informationMinimal = showProjectInfo ? <InformationMinimal /> : null;

  let customFieldsForm;
  if (customFields && customFields.length > 0) {
    customFieldsForm = (
      <GenericFormCustomFields
        customFields={customFields}
        control={control}
        disabled={disabled}
        errors={errors}
        schema={schema}
        clearError={clearError}
        setCustomFields={setCustomFields}
        setValue={setValue}
      />
    );
  }
  return (
    <div className={styles.container}>
      {informationMinimal}
      {customFieldsForm}
      {documentFields &&
        documentFields.map(({ label, name, id, size, mandatory, read_only }) => {
          return (
            <div className={`${styles.input_document} ${styles[`size_${size}`]}`} key={id}>
              <InputLabel className={styles.label_document}>{label}</InputLabel>
              <FncAddDocuments
                documents={getArrayDocs(documentFields, name)}
                genericId={idData}
                actions={actions}
                multidocumentLabel={false}
                addDoc={addDoc}
                upload={upload}
                getFile={getFile}
                download={download}
                remove={remove}
                originalName
                disabled={disabled || (!disabled && read_only)}
                addRow
                multidocument
                requiredMultiDocument={mandatory}
                fieldName={name}
              />
            </div>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar): void => dispatch(showSnackbar(message, severity)),
});

export default connect(null, mapDispatchToProps)(GenericForm);
