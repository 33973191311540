import moment from 'moment';
import { Action } from 'redux';
import { NumberFormat } from 'xlsx/types';
import { APIValidationError } from '../../api/api';
import { FileItem } from '../common/definitions';
import { AccountingConsts } from './action_types';

export const initialState = {
  loading: false as boolean,
  loadingModal: false as boolean,
  expenseAccountList: [] as ExpenseAccount[],
  error: null as string | null,
  expenseAccount: {} as ExpenseAccount,
  accountingSettings: {} as AccountingSettings,
  invoiceData: {} as InvoiceData,
  pendingInvoice: {} as PendingInvoiceData,
  pendingInvoicesList: [] as InvoiceListItem[],
  validationErrors: null as APIValidationError | null,
  invoiceDetail: {} as InvoiceDetail,
  invoicesDetailList: [] as InvoiceDetail[],
  transitionChangeSent: false as boolean,
  modalView: false as boolean,
  paymentViewModal: false as boolean,
  scholarshipSetting: { id: 1 } as ScholarshipSetting,
  instituteScholarshipPaymentDateFilter: null as moment.Moment | null,
  instituteScholarshipReloadDateFilter: null as moment.Moment | null,
};

export interface ScholarshipSetting {
  id: number;
  t1_tracking_date: number;
  t2_tracking_date: number;
  tfinal_tracking_date: number;
  s1_tracking_date: number;
  sfinal_tracking_date: number;
  payment_control_limit: number;
  ent_center_cost_channel_code: string;
  ent_center_cost_delegation_code: string;
  ent_center_cost_area_code: string;
  ent_center_cost_project_code: string;
  ins_center_cost_channel_code: string;
  ins_center_cost_delegation_code: string;
  ins_center_cost_area_code: string;
  ins_center_cost_project_code: string;
  adm_man_center_cost_channel_code: string;
  adm_man_center_cost_delegation_code: string;
  adm_man_center_cost_area_code: string;
  adm_man_center_cost_project_code: string;
  allow_create_entity_scholarships: number;
  allow_create_institute_scholarships: number;
  allow_renew_entity_scholarships: number;
  allow_renew_institute_scholarships: number;
  start_follow_up_day: number;
  start_follow_up_month: number;
  start_course_day: number;
  start_course_month: number;
}

export enum ListInvoicesStates {
  EN_CREACION = 'En creación',
  PENDIENTE = 'Pendiente',
  ACEPTADA = 'Aceptada',
  RECHAZADA = 'Rechazada',
  CONFIRMADA = 'Confirmada',
  A_PAGAR = 'A pagar',
  AUTORIZADA = 'Autorizada',
  PENDIENTE_ENVIO_SAGE = 'Pendiente envío a SAGE',
  PAGADA = 'Pagada',
}

export enum InvoiceCode {
  ISP = 'ISP',
  INTRA = 'INTRA',
}

export enum PaymentTypes {
  ACREEODR = '14',
  DONACION = '15',
  CLIENTES = '17',
  VISA = '18',
  DOMICILIACION = '19',
  RECARGA = '20',
}

export interface ExpenseAccount {
  id: number;
  code: string;
  description: string;
  active: boolean;
}

export interface AccountingSettings {
  payment_control_day: number;
  payment_day: number;
  accounting_years: number[];
}

export interface InvoiceData {
  id: number;
  entity_id: number;
  state_id: number;
  invoice_number: string;
  reference_number: string | null;
  invoice_date: number | null;
  due_date: number | null;
  closing_date: string | null;
  contract_number: string | null;
  account_number: string | null;
  account_numbers: string[];
  center_cost_channel_code: string | null;
  center_cost_delegation_code: string | null;
  center_cost_area_code: string | null;
  center_cost_project_code: string | null;
  retention_code: string | null;
  retention_name: string | null;
  retention_percentage: number | null;
  description: string | null;
  total_amount: number;
  filename: string | null;
  file_id: number | null;
  entity_contracts: string[];
  invoice_details: InvoiceDetail[];
  state_code: string;
  state_name: string;
  entity_name: string;
  supplier_code: string | null;
  is_credit: number;
  transaction_code: number;
  serial: string;
  payment_type_code: string;
}

export interface PendingInvoiceData {
  id: NumberFormat;
  invoice_id: number;
  entity_name: string;
  reference_number: string;
  due_date: string;
  center_cost_channel_code: string;
  center_cost_delegation_code: string;
  center_cost_area_code: string;
  center_cost_project_code: string;
  total_amount: number;
  account_number: string;
  account_numbers: string[];
  is_visa_provider: boolean;
}

export interface InvoiceDetail {
  id: NumberFormat | null;
  invoice_id: number;
  description: string;
  base_amount: number;
  tax_retention_code: string | null;
  tax_retention_amount: number | null;
  tax_retention_percentage: number | null;
  expense_account: string;
  detail_amount: number;
  order: number;
  serial: string;
  center_cost_channel_code: string;
  center_cost_delegation_code: string;
  center_cost_area_code: string;
  center_cost_project_code: string;
}

export interface InvoiceListItem {
  [x: string]: string | number | null | undefined;
  id: number;
  invoice_number: string;
  reference_number: string;
  total_amount: number;
  file_id: number;
  entity_id: number;
  invoice_date: string;
  due_date: string;
  closing_date: string | null;
  center_cost: string;
  state: string;
  entity_name: string;
  description: string;
  state_id: string;
  state_code: string;
  filename: string;
}

export interface ProjectListItem {
  project_id: number;
  project_schedule_id: number;
  project_name: string;
  entity_name: string;
  payment_date: string;
  payment_amount: number;
  check_date: boolean;
}

export interface ForecastItem {
  instituteScholarship: number;
  entityScholarship: number;
  projects: number;
  invoices: number;
  providers: number | undefined;
}

export interface SendToSAGEFields {
  providers: boolean;
  instituteScholarship: boolean;
  entityScholarship: boolean;
  projects: boolean;
  invoices: boolean;
}

export interface SendToSAGEFieldsResponse {
  proveedores: number | null;
  becas_entidad_pagos: number | null;
  becas_entidad_facturas: number | null;
  becas_instituto_pagos: number | null;
  becas_instituto_facturas: number | null;
  proyectos_pagos: number | null;
  proyectos_facturas: number | null;
  fapr_pagos: number | null;
  fapr_facturas: number | null;
}

export enum InvoiceStates {
  CREACION = 'I01',
  PENDIENTE = 'I02',
  ACEPTADA = 'I03',
  RECHAZADA = 'I04',
  CONFIRMADA = 'I05',
  APAGAR = 'I06',
  AUTORIZADA = 'I07',
  REVISAR = 'I08',
  PENDIENTE_ENVIO_SAGE = 'I09',
  PAGADA = 'I10',
}

export interface StateTransitions {
  [index: string]: {
    prev: string;
    labelOk: string;
    labelKo: string;
  };
}

export const stateActions: StateTransitions = {
  I02: { prev: 'reject', labelOk: 'rechazado', labelKo: 'rechazar' },
  I03: { prev: 'return', labelOk: 'revisado', labelKo: 'revisar' },
  I05: { prev: 'reject', labelOk: 'rechazado', labelKo: 'rechazar' },
};

export type AccountingState = typeof initialState;

// GET EXPENSE ACCOUNTS LIST
export interface GetExpenseAccountListAction extends Action {
  type: AccountingConsts.GET_EXPENSE_ACCOUNT_LIST;
}

export interface GetExpenseAccountListOkAction extends Action {
  type: AccountingConsts.GET_EXPENSE_ACCOUNT_LIST_OK;
  expenseAccountList: ExpenseAccount[];
}
export interface GetExpenseAccountListKoAction extends Action {
  type: AccountingConsts.GET_EXPENSE_ACCOUNT_LIST_KO;
  error: string;
}

// CREATE EXPENSE ACCOUNT
export interface CreateExpenseAccountAction extends Action {
  type: AccountingConsts.CREATE_EXPENSE_ACCOUNT;
  newExpenseAccount: ExpenseAccount;
}

export interface CreateExpenseAccountOkAction extends Action {
  type: AccountingConsts.CREATE_EXPENSE_ACCOUNT_OK;
}

export interface CreateExpenseAccountKoAction extends Action {
  type: AccountingConsts.CREATE_EXPENSE_ACCOUNT_KO;
  error: string;
}

// DELETE EXPENSE ACCOUNT
export interface DeleteExpenseAccountAction extends Action {
  type: AccountingConsts.DELETE_EXPENSE_ACCOUNT;
  id: number;
}

export interface DeleteExpenseAccountOkAction extends Action {
  type: AccountingConsts.DELETE_EXPENSE_ACCOUNT_OK;
  id: number;
}
export interface DeleteExpenseAccountKoAction extends Action {
  type: AccountingConsts.DELETE_EXPENSE_ACCOUNT_KO;
  error: string;
}

// GET ONE EXPENSE ACCOUNT

export interface GetOneExpenseAccountAction extends Action {
  type: AccountingConsts.GET_ONE_EXPENSE_ACCOUNT;
  id: number;
}

export interface GetOneExpenseAccountOkAction extends Action {
  type: AccountingConsts.GET_ONE_EXPENSE_ACCOUNT_OK;
  expenseAccount: ExpenseAccount;
}

export interface GetOneExpenseAccountKoAction extends Action {
  type: AccountingConsts.GET_ONE_EXPENSE_ACCOUNT_KO;
  error: string;
}

// EDIT EXPENSE ACCOUNT
export interface EditExpenseAccountAction extends Action {
  type: AccountingConsts.EDIT_EXPENSE_ACCOUNT;
  expenseAccount: ExpenseAccount;
  id: number;
}

export interface EditExpenseAccountOkAction extends Action {
  type: AccountingConsts.EDIT_EXPENSE_ACCOUNT_OK;
  expenseAccount: ExpenseAccount;
}

export interface EditExpenseAccountKoAction extends Action {
  type: AccountingConsts.EDIT_EXPENSE_ACCOUNT_KO;
  error: string;
}

// GET ACCOUNT SETTINGS

export interface GetAccountingSettingsAction extends Action {
  type: AccountingConsts.GET_ACCOUNTING_SETTINGS;
}

export interface GetAccountingSettingsOkAction extends Action {
  type: AccountingConsts.GET_ACCOUNTING_SETTINGS_OK;
  accountingSettings: AccountingSettings;
}

export interface GetAccountingSettingsKoAction extends Action {
  type: AccountingConsts.GET_ACCOUNTING_SETTINGS_KO;
  error: string;
}

// EDIT ACCOUNTING SETTINGS

export interface EditAccountingSettingsAction extends Action {
  type: AccountingConsts.EDIT_ACCOUNTING_SETTINGS;
  accountingSettings: AccountingSettings;
}

export interface EditAccountingSettingsOkAction extends Action {
  type: AccountingConsts.EDIT_ACCOUNTING_SETTINGS_OK;
}

export interface EditAccountingSettingsKoAction extends Action {
  type: AccountingConsts.EDIT_ACCOUNTING_SETTINGS_KO;
  error: string;
}

//GET PENDING TO SEND INVOICES

export interface GetPendingToSendInvoicesAction extends Action {
  type: AccountingConsts.GET_PENDING_INVOICES;
}

export interface GetPendingToSendInvoicesOkAction extends Action {
  type: AccountingConsts.GET_PENDING_INVOICES_OK;
  pendingInvoicesList: InvoiceListItem[];
}

export interface GetPendingToSendInvoicesKoAction extends Action {
  type: AccountingConsts.GET_PENDING_INVOICES_KO;
  error: string;
}

//GET ONE PENDING INVOICES

export interface GetOnePendingInvoiceAction extends Action {
  type: AccountingConsts.GET_ONE_PENDING_INVOICE;
  pendingInvoice_id: number;
}

export interface GetOnePendingInvoiceOkAction extends Action {
  type: AccountingConsts.GET_ONE_PENDING_INVOICE_OK;
  pendingInvoice: PendingInvoiceData;
}

export interface GetOnePendingInvoiceKoAction extends Action {
  type: AccountingConsts.GET_ONE_PENDING_INVOICE_KO;
  error: string;
}

//UPDATE ONE PENDING INVOICES

export interface UpdatePendingInvoiceAction extends Action {
  type: AccountingConsts.UPDATE_PENDING_INVOICE;
  invoice: PendingInvoiceData;
}

export interface UpdatePendingInvoiceKoAction extends Action {
  type: AccountingConsts.UPDATE_PENDING_INVOICE_KO;
  error: string;
}

//SET ONE INVOICE
export interface SetOnePendingInvoiceAction extends Action {
  type: AccountingConsts.SET_ONE_PENDING_INVOICE;
  key_i: string;
  value_i: string | number;
}

//REMOVE PENDING INVOICE
export interface RemovePendingInvoiceAction extends Action {
  type: AccountingConsts.REMOVE_PENDING_INVOICE;
  pendingInvoice_id: number;
}

export interface RemovePendingInvoiceKoAction extends Action {
  type: AccountingConsts.REMOVE_PENDING_INVOICE_KO;
  error: string;
}
export interface GetInvoiceDataAction extends Action {
  type: AccountingConsts.GET_INVOICE_DATA;
  id: number;
}
export interface GetInvoiceDataOkAction extends Action {
  type: AccountingConsts.GET_INVOICE_DATA_OK;
  invoice: InvoiceData;
}
export interface GetInvoiceDataKoAction extends Action {
  type: AccountingConsts.GET_INVOICE_DATA_KO;
  error: string;
}
export interface EditInvoiceDataAction extends Action {
  type: AccountingConsts.EDIT_INVOICE_DATA;
  invoice: InvoiceData;
}
export interface EditInvoiceDataOkAction extends Action {
  type: AccountingConsts.EDIT_INVOICE_DATA_OK;
  invoice: InvoiceData;
}
export interface EditInvoiceDataKoAction extends Action {
  type: AccountingConsts.EDIT_INVOICE_DATA_KO;
  error: string;
}

export interface DeleteInvoiceDataAction extends Action {
  type: AccountingConsts.DELETE_INVOICE_DATA;
  id: number;
}
export interface DeleteInvoiceDataOkAction extends Action {
  type: AccountingConsts.DELETE_INVOICE_DATA_OK;
  // invoice: InvoiceData;
}
export interface DeleteInvoiceDataKoAction extends Action {
  type: AccountingConsts.DELETE_INVOICE_DATA_KO;
  error: string;
}

export interface InvoiceSetDataAction extends Action {
  type: AccountingConsts.INVOICE_SET_DATA;
  key: string;
  value: string | number;
}

export interface InvoiceDetailSetDataAction extends Action {
  type: AccountingConsts.INVOICE_DETAIL_SET_DATA;
  key_detail: string;
  value_detail: string | number;
}

export interface InvoiceValidationErrorsAction extends Action {
  type: AccountingConsts.INVOICE_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

export interface UploadInvoiceDocumentAction extends Action {
  type: AccountingConsts.UPLOAD_INVOICE_DOCUMENT;
  invoice_id: number;
  file: File;
}

export interface UploadInvoiceDocumentOkAction extends Action {
  type: AccountingConsts.UPLOAD_INVOICE_DOCUMENT_OK;
  fileData: FileItem;
}

export interface UploadInvoiceDocumentKoAction extends Action {
  type: AccountingConsts.UPLOAD_INVOICE_DOCUMENT_KO;
  error: string;
}

export interface RemoveInvoiceDocumentAction extends Action {
  type: AccountingConsts.REMOVE_INVOICE_DOCUMENT;
  file_id: number;
}

export interface RemoveInvoiceDocumentOkAction extends Action {
  type: AccountingConsts.REMOVE_INVOICE_DOCUMENT_OK;
}

export interface RemoveInvoiceDocumentKoAction extends Action {
  type: AccountingConsts.REMOVE_INVOICE_DOCUMENT_KO;
  error: string;
}

export interface DownloadInvoiceDocumentAction extends Action {
  type: AccountingConsts.DOWNLOAD_INVOICE;
  file_id: number;
  filename: string;
}

export interface DownloadInvoiceDocumentOkAction extends Action {
  type: AccountingConsts.DOWNLOAD_INVOICE_OK;
  data: Blob;
  name: string;
}

export interface DownloadInvoiceDocumentKoAction extends Action {
  type: AccountingConsts.DOWNLOAD_INVOICE_KO;
  error: string;
}

// CREATE INVOICE

export interface CreateInvoiceAction extends Action {
  type: AccountingConsts.CREATE_INVOICE;
  entity_id: number;
}

export interface CreateInvoiceOkAction extends Action {
  type: AccountingConsts.CREATE_INVOICE_OK;
  invoice: InvoiceData;
}

export interface CreateInvoiceKoAction extends Action {
  type: AccountingConsts.CREATE_INVOICE_KO;
  error: string;
}

// CREATE NEW INVOICE DETAIL

export interface CreateNewInvoiceDetailAction extends Action {
  type: AccountingConsts.CREATE_NEW_INVOICE_DETAIL;
  newInvoiceDetail: InvoiceDetail;
}

export interface CreateNewInvoiceDetailOkAction extends Action {
  type: AccountingConsts.CREATE_NEW_INVOICE_DETAIL_OK;
  invoice_details: InvoiceDetail[];
}

export interface CreateNewInvoiceDetailKoAction extends Action {
  type: AccountingConsts.CREATE_NEW_INVOICE_DETAIL_KO;
  error: string;
}
// GET INVOICE_DETAIL
export interface GetInvoiceDetailAction extends Action {
  type: AccountingConsts.GET_INVOICE_DETAIL;
  id: number;
}

export interface GetInvoiceDetailOkAction extends Action {
  type: AccountingConsts.GET_INVOICE_DETAIL_OK;
  invoiceDetail: InvoiceDetail;
}

export interface GetInvoiceDetailKoAction extends Action {
  type: AccountingConsts.GET_INVOICE_DETAIL_KO;
  error: string;
}

// GET INVOICES_DETAIL LIST
export interface GetInvoicesDetailListAction extends Action {
  type: AccountingConsts.GET_INVOICES_DETAIL_LIST;
  invoice_id: number;
}

export interface GetInvoicesDetailListOkAction extends Action {
  type: AccountingConsts.GET_INVOICES_DETAIL_LIST_OK;
  invoicesDetailList: InvoiceDetail[];
}

export interface GetInvoicesDetailListKoAction extends Action {
  type: AccountingConsts.GET_INVOICES_DETAIL_LIST_KO;
  error: string;
}

// EDIT INVOICE_DETAIL
export interface EditInvoiceDetailAction extends Action {
  type: AccountingConsts.EDIT_INVOICE_DETAIL;
  invoiceDetail: InvoiceDetail;
}

export interface EditInvoiceDetailOkAction extends Action {
  type: AccountingConsts.EDIT_INVOICE_DETAIL_OK;
  invoice_details: InvoiceDetail[];
}
export interface EditInvoiceDetailKoAction extends Action {
  type: AccountingConsts.EDIT_INVOICE_DETAIL_KO;
  error: string;
}

// DELETE INVOICE_DETAIL

export interface DeleteInvoiceDetailAction extends Action {
  type: AccountingConsts.DELETE_INVOICE_DETAIL;
  invoiceDetail: InvoiceDetail;
}

export interface DeleteInvoiceDetailOkAction extends Action {
  type: AccountingConsts.DELETE_INVOICE_DETAIL_OK;
  invoiceDetail: InvoiceDetail;
}

export interface DeleteInvoiceDetailKoAction extends Action {
  type: AccountingConsts.DELETE_INVOICE_DETAIL_KO;
  error: string;
}

export interface ResetInvoiceDetailAction extends Action {
  type: AccountingConsts.RESET_INVOICE_DETAIL;
}

// CHANGE STATE

export interface ChangeState extends Action {
  type: AccountingConsts.CHANGE_STATE;
  invoiceID: number;
  comments: string;
  state: string;
}

export interface ChangeStateOk extends Action {
  type: AccountingConsts.CHANGE_STATE_OK;
  invoiceData: InvoiceData;
}

export interface ChangeStateKo extends Action {
  type: AccountingConsts.CHANGE_STATE_KO;
  error: string;
}

// MOVE BACK INVOICE TO ACCEPTED
export interface MoveBackInvoiceAction extends Action {
  type: AccountingConsts.MOVE_BACK_INVOICE;
  invoice_id: number;
}

export interface MoveBackInvoiceOkAction extends Action {
  type: AccountingConsts.MOVE_BACK_INVOICE_OK;
  invoiceData: InvoiceData;
}

export interface MoveBackInvoiceKoAction extends Action {
  type: AccountingConsts.MOVE_BACK_INVOICE_KO;
  error: string;
}

export interface SendInvoiceAction extends Action {
  type: AccountingConsts.SEND_INVOICE;
  invoiceData: InvoiceData;
}

export interface SendInvoiceOkAction extends Action {
  type: AccountingConsts.SEND_INVOICE_OK;
  invoiceData: InvoiceData;
}

export interface SendInvoiceKoAction extends Action {
  type: AccountingConsts.SEND_INVOICE_KO;
  error: string;
}

export interface AcceptInvoiceAction extends Action {
  type: AccountingConsts.ACCEPT_INVOICE;
  invoiceData: InvoiceData;
}

export interface AcceptInvoiceOkAction extends Action {
  type: AccountingConsts.ACCEPT_INVOICE_OK;
  invoiceData: InvoiceData;
}

export interface AcceptInvoiceKoAction extends Action {
  type: AccountingConsts.ACCEPT_INVOICE_KO;
  error: string;
}

export interface AuthorizeInvoiceAction extends Action {
  type: AccountingConsts.AUTORIZE_INVOICE;
  invoice_id: number;
}

export interface AuthorizeInvoiceOkAction extends Action {
  type: AccountingConsts.AUTORIZE_INVOICE_OK;
  invoiceData: InvoiceData;
}

export interface AuthorizeInvoiceKoAction extends Action {
  type: AccountingConsts.AUTORIZE_INVOICE_KO;
  error: string;
}

export interface ConfirmInvoiceAction extends Action {
  type: AccountingConsts.CONFIRM_INVOICE;
  invoiceData: InvoiceData;
}

export interface ConfirmInvoiceOkAction extends Action {
  type: AccountingConsts.CONFIRM_INVOICE_OK;
  invoiceData: InvoiceData;
}

export interface ConfirmInvoiceKoAction extends Action {
  type: AccountingConsts.CONFIRM_INVOICE_KO;
  error: string;
}

export interface ToPayInvoiceAction extends Action {
  type: AccountingConsts.TO_PAY_INVOICE;
  id: number;
}

export interface ToPayInvoiceOkAction extends Action {
  type: AccountingConsts.TO_PAY_INVOICE_OK;
  invoiceData: InvoiceData;
}

export interface ToPayInvoiceKoAction extends Action {
  type: AccountingConsts.TO_PAY_INVOICE_KO;
  error: string;
}

export interface SendInvoicesToPayAction extends Action {
  type: AccountingConsts.TO_PAY_INVOICES;
}

export interface SendInvoicesToPayOkAction extends Action {
  type: AccountingConsts.TO_PAY_INVOICES_OK;
}

export interface PayInvoicesAction extends Action {
  type: AccountingConsts.PAY_INVOICES;
}

export interface PayInvoicesOkAction extends Action {
  type: AccountingConsts.PAY_INVOICES_OK;
}

export interface OpenModalAction extends Action {
  type: AccountingConsts.OPEN_MODAL;
}

export interface CloseModalAction extends Action {
  type: AccountingConsts.CLOSE_MODAL;
}

export interface GetScholarshipSettingAction extends Action {
  type: AccountingConsts.GET_SCHOLARSHIP_SETTING;
}
export interface GetScholarshipSettingOkAction extends Action {
  type: AccountingConsts.GET_SCHOLARSHIP_SETTING_OK;
  value: ScholarshipSetting;
}
export interface GetScholarshipSettingKoAction extends Action {
  type: AccountingConsts.GET_SCHOLARSHIP_SETTING_KO;
  error: string;
}

export interface UpdateScholarshipSettingAction extends Action {
  type: AccountingConsts.UPDATE_SCHOLARSHIP_SETTING;
  value: ScholarshipSetting;
}
export interface UpdateScholarshipSettingOkAction extends Action {
  type: AccountingConsts.UPDATE_SCHOLARSHIP_SETTING_OK;
  value: ScholarshipSetting;
}
export interface UpdateScholarshipSettingKoAction extends Action {
  type: AccountingConsts.UPDATE_SCHOLARSHIP_SETTING_KO;
  error: string;
}

export interface ScholarshipSettingValidationErrorsAction extends Action {
  type: AccountingConsts.SCHOLARSHIP_SETTING_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

export interface ScholarshipSetDataSettingsAction extends Action {
  type: AccountingConsts.SCHOLARSHIP_SET_DATA_SETTING;
  key: string;
  value: string | number;
  subKey?: string;
}

export interface OpenPaymentModalAction extends Action {
  type: AccountingConsts.OPEN_PAYMENT_MODAL;
}

export interface ClosePaymentModalAction extends Action {
  type: AccountingConsts.CLOSE_PAYMENT_MODAL;
}

export interface MarkInvoiceAsPaidAction extends Action {
  type: AccountingConsts.MARK_INVOICE_AS_PAID;
  invoice_id: number;
}

export interface MarkInvoiceAsPaidOkAction extends Action {
  type: AccountingConsts.MARK_INVOICE_AS_PAID_OK;
  invoice: InvoiceData;
}

export interface MarkInvoiceAsPaidKoAction extends Action {
  type: AccountingConsts.MARK_INVOICE_AS_PAID_KO;
  error: string;
}

export interface SetInsScholarshipsPaymentDateFilterAction extends Action {
  type: AccountingConsts.SET_INS_SCHOLARSHIPS_PAYMENT_DATE_FILTER;
  paymentDate: moment.Moment | null;
  reloadDate: moment.Moment | null;
}

export interface CloseInvoicesAction extends Action {
  type: AccountingConsts.CLOSE_INVOICES;
  closing_year: number;
}

export interface CloseInvoicesOkAction extends Action {
  type: AccountingConsts.CLOSE_INVOICES_OK;
}
export interface CloseInvoicesKoAction extends Action {
  type: AccountingConsts.CLOSE_INVOICES_KO;
  error: string;
}

export type AccountingDataAction =
  | GetExpenseAccountListAction
  | GetExpenseAccountListOkAction
  | GetExpenseAccountListKoAction
  | CreateExpenseAccountAction
  | CreateExpenseAccountOkAction
  | CreateExpenseAccountKoAction
  | DeleteExpenseAccountAction
  | DeleteExpenseAccountOkAction
  | DeleteExpenseAccountKoAction
  | GetOneExpenseAccountAction
  | GetOneExpenseAccountOkAction
  | GetOneExpenseAccountKoAction
  | EditExpenseAccountAction
  | EditExpenseAccountOkAction
  | EditExpenseAccountKoAction
  | GetAccountingSettingsAction
  | GetAccountingSettingsOkAction
  | GetAccountingSettingsKoAction
  | EditAccountingSettingsAction
  | EditAccountingSettingsOkAction
  | EditAccountingSettingsKoAction
  | GetInvoiceDataAction
  | GetInvoiceDataOkAction
  | GetInvoiceDataKoAction
  | EditInvoiceDataAction
  | EditInvoiceDataOkAction
  | EditInvoiceDataKoAction
  | DeleteInvoiceDataAction
  | DeleteInvoiceDataOkAction
  | DeleteInvoiceDataKoAction
  | InvoiceSetDataAction
  | InvoiceValidationErrorsAction
  | RemoveInvoiceDocumentAction
  | UploadInvoiceDocumentAction
  | UploadInvoiceDocumentOkAction
  | UploadInvoiceDocumentKoAction
  | RemoveInvoiceDocumentOkAction
  | RemoveInvoiceDocumentKoAction
  | DownloadInvoiceDocumentAction
  | DownloadInvoiceDocumentOkAction
  | DownloadInvoiceDocumentKoAction
  | CreateInvoiceAction
  | CreateInvoiceOkAction
  | CreateInvoiceKoAction
  | CreateNewInvoiceDetailAction
  | CreateNewInvoiceDetailOkAction
  | CreateNewInvoiceDetailKoAction
  | GetInvoiceDetailAction
  | GetInvoiceDetailOkAction
  | GetInvoiceDetailKoAction
  | EditInvoiceDetailAction
  | EditInvoiceDetailOkAction
  | EditInvoiceDetailKoAction
  | DeleteInvoiceDetailAction
  | DeleteInvoiceDetailOkAction
  | DeleteInvoiceDetailKoAction
  | ResetInvoiceDetailAction
  | GetInvoicesDetailListAction
  | GetInvoicesDetailListOkAction
  | GetInvoicesDetailListKoAction
  | InvoiceDetailSetDataAction
  | ChangeState
  | ChangeStateOk
  | ChangeStateKo
  | MoveBackInvoiceAction
  | MoveBackInvoiceOkAction
  | MoveBackInvoiceKoAction
  | SendInvoiceAction
  | SendInvoiceOkAction
  | SendInvoiceKoAction
  | AcceptInvoiceAction
  | AcceptInvoiceOkAction
  | AcceptInvoiceKoAction
  | ConfirmInvoiceAction
  | ConfirmInvoiceOkAction
  | ConfirmInvoiceKoAction
  | ToPayInvoiceAction
  | ToPayInvoiceOkAction
  | ToPayInvoiceKoAction
  | PayInvoicesAction
  | PayInvoicesOkAction
  | SendInvoicesToPayAction
  | SendInvoicesToPayOkAction
  | OpenModalAction
  | CloseModalAction
  | GetScholarshipSettingAction
  | GetScholarshipSettingOkAction
  | GetScholarshipSettingKoAction
  | UpdateScholarshipSettingAction
  | UpdateScholarshipSettingOkAction
  | UpdateScholarshipSettingKoAction
  | ScholarshipSettingValidationErrorsAction
  | ScholarshipSetDataSettingsAction
  | GetPendingToSendInvoicesAction
  | GetPendingToSendInvoicesOkAction
  | GetPendingToSendInvoicesKoAction
  | OpenPaymentModalAction
  | ClosePaymentModalAction
  | RemovePendingInvoiceAction
  | RemovePendingInvoiceKoAction
  | GetOnePendingInvoiceAction
  | GetOnePendingInvoiceOkAction
  | GetOnePendingInvoiceKoAction
  | SetOnePendingInvoiceAction
  | UpdatePendingInvoiceAction
  | UpdatePendingInvoiceKoAction
  | MarkInvoiceAsPaidAction
  | MarkInvoiceAsPaidOkAction
  | MarkInvoiceAsPaidKoAction
  | SetInsScholarshipsPaymentDateFilterAction
  | AuthorizeInvoiceAction
  | AuthorizeInvoiceOkAction
  | AuthorizeInvoiceKoAction
  | CloseInvoicesAction
  | CloseInvoicesOkAction
  | CloseInvoicesKoAction;
export type AccountingDispatch = (action: AccountingDataAction) => void;
