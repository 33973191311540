import { CircularProgress, LinearProgress } from '@material-ui/core';
import React from 'react';
import styles from './loading.module.scss';

interface loadingProps {
  big?: boolean;
  small?: boolean;
  medium?: boolean;
  text?: string;
}

const Loading: React.FC<loadingProps> = ({ big, small, medium, text }) => (
  <React.Fragment>
    <div>
      {!!big && (
        <div className={styles.wrapper_big}>
          <CircularProgress size={80} />
          {text && <p className={styles.wrapper_p}>{text}</p>}
        </div>
      )}
      {!!medium && (
        <div className={styles.wrapper_medium}>
          <CircularProgress size={50} />
          {text && <p className={styles.wrapper_p}>{text}</p>}
        </div>
      )}
    </div>
    <div className={styles.wrapper_small}>
      {!!small && (
        // <CircularProgress size={30} />
        <LinearProgress color="primary" />
      )}
    </div>
  </React.Fragment>
);

export default Loading;
