import { ButtonProps } from '@material-ui/core';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Fields,
  GenericFromRequest,
  GenericRows,
} from '../../../../../../../components/GenericForm/GenericFormRefactor';
import { useButtonAction } from '../../../../../../../helpers/customHooks/useHookMethod';
import { selectIsNousCims } from '../../../../../../../redux/auth/selectors';
import { getSectionTemplate, sendFollowUp, updateFollowUp } from '../../../../../../../redux/internal-projects/actions';
import { GenericData } from '../../../../../../../redux/project/definitions';
import { AppState } from '../../../../../../../redux/root-reducer';
import InternalProjectGeneric from '../../../../InternalProjectGeneric/InternalProjectGeneric';
import InformationMinimal from '../../../Information/InformationMinimal';
import InternalProjectFacilitadoresView from './Facilitadores/InternalProjectFacilitadoresView';
import SeguimientoInicialKoaParticipantes from './SeguimientoInicialKoaParticipantes';
import SeguimientoInicialKoaEducadores from './SeguimientoInicialKoaEducadores';

interface Props {
  loading: boolean;
  internal_project_id: number;
  section: string;
  follow_up_id: number;
  buttonSave: ButtonProps;
  buttonSend: ButtonProps;
  disabled: boolean;
  fields: Fields;
  participante: GenericData;
  setterData: any;
  setData: any;
  isNousCims: boolean;
  follow_date: string | null;
  completed: boolean;
  getSectionTemplate: (internalProjectFollowId: number, section: string, followUpId: number) => void;
  updateFollowUp: (data: GenericFromRequest) => void;
  sendFollowUp: (data: GenericFromRequest) => void;
}

const SeguimientoInicialKoaForm = ({
  loading,
  internal_project_id,
  section,
  follow_up_id,
  buttonSave,
  buttonSend,
  disabled,
  fields,
  participante,
  setterData,
  setData,
  isNousCims,
  follow_date,
  completed,
  getSectionTemplate,
  updateFollowUp,
  sendFollowUp,
}: Props) => {
  useEffect(() => {
    getSectionTemplate(internal_project_id, section, follow_up_id);
  }, [follow_up_id, getSectionTemplate, internal_project_id, section]);

  const handleEditFollow = (data: any, rows?: GenericRows[]) => {
    const _data = {
      fields: data,
      internal_project_id,
      internal_project_follow_up_id: follow_up_id,
      section,
      rows: rows || [],
    };
    updateFollowUp(_data);
  };

  const handleSendFollow = (data: any, rows?: GenericRows[]) => {
    const _data = {
      fields: data,
      internal_project_id,
      internal_project_follow_up_id: follow_up_id,
      section,
      rows: rows || [],
    };
    sendFollowUp(_data);
  };

  useButtonAction(buttonSave, undefined, { hidden: false });
  useButtonAction(buttonSend, undefined, {
    hidden: !isNousCims,
    disabled: completed || !follow_date,
  });

  return (
    <>
      <Fragment>
        <InformationMinimal />
        <InternalProjectFacilitadoresView />
        <InternalProjectGeneric
          id={internal_project_id}
          buttonSave={buttonSave}
          buttonSend={buttonSend}
          fieldsData={fields}
          section={section}
          disabled={disabled}
          onSave={handleEditFollow}
          onSend={handleSendFollow}
          loadingFields={loading}
          participante={participante}
          setSetterData={setData}
          setterData={setterData}
          showProjectInfo={false}
        />
        <SeguimientoInicialKoaEducadores internal_project_id={internal_project_id} />
        <SeguimientoInicialKoaParticipantes internal_project_id={internal_project_id} />
      </Fragment>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectReducer.loadingAlt,
  fields: state.internalProjectReducer.fields,
  participante: state.internalProjectReducer.participante,
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getSectionTemplate: (internalProjectId: number, section: string, followUpId: number): void =>
    dispatch(getSectionTemplate(internalProjectId, section, followUpId)),
  updateFollowUp: (data: GenericFromRequest) => dispatch(updateFollowUp(data)),
  sendFollowUp: (data: GenericFromRequest) => dispatch(sendFollowUp(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeguimientoInicialKoaForm);
