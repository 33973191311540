import React, { FocusEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import Address from '../../../components/Address/Address';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import ValidatedTextInput from '../../../components/Inputs/ValidatedTextInput/ValidatedTextInput';
import MessageInfo from '../../../components/MessageInfo/MessageInfo';
import Title from '../../../components/Title/Title';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { validateFields } from '../../../helpers/validator';
import { selectIsNousCims } from '../../../redux/auth/selectors';
import { DocumentationItem } from '../../../redux/common/definitions';
import { entitySetData } from '../../../redux/entity/actions';
import { EntityInfo, EntityStateCodes } from '../../../redux/entity/definitions';
import { selectGetOneEntity } from '../../../redux/entity/selectors';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import validate from '../../../validations/validators';
import useEntityType, { typeName } from '../Hooks/useEntityTypes';
import styles from '../entityData.module.scss';
import EntityDocumentationForm from './DocumentationData/EntityDocumentationForm';
import EntityType from './EntityType/EntityType';
import LegalData from './LegalData/LegalForm';
import ProviderEntityForm from './ProviderData/ProviderEntityForm';
import ResponsibleForm from './ResponsibleData/ResponsibleForm';

interface EntityFormProps {
  useForm: any;
  entitySchema: any;
  title: string;
  documentation: DocumentationItem[];
  genericMetadata: GenericMetadata;
  entity: EntityInfo;
  responsible?: boolean;
  responsableData?: boolean;
  disabled?: boolean;
  userInfo?: string | null;
  isNousCims: boolean;
  showVisaProvider: boolean;
  setData: (key: string, value: string | number) => void;
}

const EntityForm: React.FC<EntityFormProps> = ({
  documentation,
  responsableData,
  useForm,
  entitySchema,
  genericMetadata,
  title,
  responsible,
  entity,
  disabled,
  userInfo,
  isNousCims,
  showVisaProvider,
  setData,
}) => {
  const { typeRadios, setTypeValue, defaultTypes, isDefaultSetted } = useEntityType();

  const { register, setValue, watch, errors, triggerValidation, control, setError, clearError } = useForm;

  useEffect(() => {
    defaultTypes(entity);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    cif,
    name,
    phone,
    account_number,
    address,
    flat,
    floor,
    room,
    staircase,
    zip_code,
    address_type,
    country,
    province,
    city,
    is_provider,
    legal_person,
    natural_person,
    state_code,
  } = entity;

  const addressData = { address, flat, floor, room, staircase, zip_code, address_type, country, province, city };

  const checkAccountNumber = (number: string) => {
    if (!validate.accountNumber(number, country || '')) {
      setError('account_number', 'invalidAccountNumber', tErrorKey('IBAN incorrecto'));
      setData('account_number', number);
    } else {
      clearError('account_number');
      setData('account_number', number);
    }
  };

  const entityTypeMessage = (): string | undefined => {
    if (errors && errors.has_scholarship) {
      return errors.has_scholarship.message as string;
    }
    return undefined;
  };

  const setEntityTypeValue = (name: typeName, value: boolean): void => {
    setTypeValue(name, value);
    setData(name as string, value ? 1 : 0);
  };

  const renderAccountNumber = () => {
    if (!entity.is_visa_provider && !entity.no_payments_entity) {
      return (
        <TextInputController
          control={control}
          schema={entitySchema}
          size="50"
          label={tFormKey('Número de Cuenta')}
          name="account_number"
          errors={errors}
          defaultValue={account_number}
          disabled={disabled}
          validator={validateFields(entity, 'account_number')}
          onBlur={(_e: FocusEvent<HTMLInputElement>) => {
            triggerValidation('account_number');
            checkAccountNumber(_e.target.value);
          }}
        />
      );
    }
    return undefined;
  };

  const renderLegalForm = () => {
    if (!entity.is_visa_provider) {
      return <LegalData entity={entity} setData={setData} disabled={disabled} />;
    }
    return undefined;
  };

  const renderResponsibleForm = () => {
    if (!entity.is_visa_provider) {
      return (
        <ResponsibleForm
          useForm={{ register, errors, triggerValidation, control, setError, clearError }}
          entitySchema={entitySchema}
          entity={entity}
          setData={setData}
          responsible={responsible}
          responsableData={responsableData}
          disabled={disabled}
        />
      );
    }
    return undefined;
  };

  const renderEntityType = () => {
    if (isDefaultSetted() && (isNousCims || state_code === EntityStateCodes.SOLICITUD_ENTIDAD)) {
      return (
        <EntityType
          disabled={state_code === EntityStateCodes.SOLICITUD_ENTIDAD ? false : disabled || !showVisaProvider}
          typeRadios={typeRadios}
          showVisaProvider={showVisaProvider}
          setValue={setEntityTypeValue}
          errorMessage={entityTypeMessage()}
        />
      );
    }
    return undefined;
  };

  const renderProviderDataForm = () => {
    if (
      is_provider === 1 &&
      state_code !== EntityStateCodes.SOLICITUD_ENTIDAD &&
      state_code !== EntityStateCodes.SOLICITUD_FNC
    ) {
      return <ProviderEntityForm useForm={{ errors, control }} entitySchema={entitySchema} disabled={disabled} />;
    }
    return undefined;
  };

  const renderDocumentationForm = () => {
    if (!!documentation.length) {
      return (
        <EntityDocumentationForm
          documentation={documentation}
          useForm={{ register }}
          responsible={responsible}
          disabled={disabled}
        />
      );
    }
    return undefined;
  };

  return (
    <form className={styles.form}>
      <Title>{title}</Title>
      {userInfo && <MessageInfo style={styles.messageInfo}>{userInfo}</MessageInfo>}
      <FormContainer title={tKey('Datos entidad')}>
        <ValidatedTextInput
          schema={entitySchema}
          size="50"
          label={natural_person ? tFormKey('NIF') : tFormKey('CIF')}
          name="cif"
          validator={validateFields(entity, 'cif')}
          register={register}
          defaultValue={cif}
          errors={errors}
          disabled={disabled}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            clearError();
            if (legal_person) {
              triggerValidation('cif');
            }
            setData(e.target.name, e.target.value);
          }}
        />
        <ValidatedTextInput
          schema={entitySchema}
          size="50"
          label={tFormKey('Nombre de entidad')}
          name="name"
          register={register}
          defaultValue={name}
          errors={errors}
          disabled={disabled}
          validator={validateFields(entity, 'name')}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            triggerValidation('name');
            setData(e.target.name, e.target.value);
          }}
        />
        <Address
          errors={errors}
          genericMetadata={genericMetadata}
          setValue={setValue}
          disabled={disabled}
          addressData={addressData}
          addressSchema={entitySchema}
          triggerValidation={triggerValidation}
          control={control}
          validator={{
            data: entity,
          }}
          checkAccountNumber={checkAccountNumber}
          accountNumber={watch('account_number') || account_number}
          setData={setData}
          setError={setError}
        />
        <ValidatedTextInput
          size="50"
          schema={entitySchema}
          label={tFormKey('Tel. Entidad')}
          name="phone"
          errors={errors}
          register={register}
          defaultValue={phone}
          disabled={disabled}
          validator={validateFields(entity, 'phone')}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            triggerValidation('phone');
            setData(e.target.name, e.target.value);
          }}
        />
        {renderAccountNumber()}
      </FormContainer>
      {renderLegalForm()}
      {renderResponsibleForm()}
      {renderEntityType()}
      {renderProviderDataForm()}
      {renderDocumentationForm()}
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericMetadata: selectGenericMetadata(state),
  entity: selectGetOneEntity(state),
  isNousCims: selectIsNousCims(state),
  showVisaProvider: selectUserCan(state)('allowVisaProviderEntity'),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(entitySetData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityForm);
