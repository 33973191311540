import React from 'react';
import { Control, FieldError } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import { tKey } from '../../helpers/translate';
import TextInputController from '../Inputs/TextInputController/TextInputController';
import styles from './dafo.module.scss';

interface DafoProps {
  dName: string;
  dValue: string | null;
  aName: string;
  aValue: string | null;
  fName: string;
  fValue: string | null;
  oName: string;
  oValue: string | null;
  errors?: FieldError | any;
  disabled?: boolean;
  control: Control<any>;
  schema: ObjectSchema<any> | ObjectSchema<Shape<object, any>>;
  setData: (key: string, value: string | number) => void;
}

const Dafo: React.FC<DafoProps> = ({
  errors,
  dName,
  dValue,
  aName,
  aValue,
  fName,
  fValue,
  oName,
  oValue,
  disabled,
  schema,
  control,
  setData,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{tKey('Análisis DAFO')}</p>
      <div className={styles.area}>
        <section className={styles.dItem}>
          <p className={styles.subtitle}>{tKey('Debilidades')}</p>
          <TextInputController
            schema={schema}
            control={control}
            multiline
            rows="8"
            rowsMax="8"
            size="95"
            label=""
            name={dName}
            defaultValue={dValue}
            disabled={disabled}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              setData(e.target.name, e.target.value);
            }}
          ></TextInputController>
        </section>
        <section className={styles.aItem}>
          <p className={styles.subtitle}>{tKey('Amenazas')}</p>
          <TextInputController
            schema={schema}
            control={control}
            multiline
            rows="8"
            rowsMax="8"
            size="95"
            label=""
            name={aName}
            defaultValue={aValue}
            disabled={disabled}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              setData(e.target.name, e.target.value);
            }}
          ></TextInputController>
        </section>
        <section className={styles.fItem}>
          <p className={styles.subtitle}>{tKey('Fortalezas')}</p>
          <TextInputController
            schema={schema}
            control={control}
            multiline
            rows="8"
            rowsMax="8"
            size="95"
            label=""
            name={fName}
            defaultValue={fValue}
            disabled={disabled}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              setData(e.target.name, e.target.value);
            }}
          ></TextInputController>
        </section>
        <section className={styles.oItem}>
          <p className={styles.subtitle}>{tKey('Oportunidades')}</p>
          <TextInputController
            schema={schema}
            control={control}
            multiline
            rows="8"
            rowsMax="8"
            size="95"
            label=""
            name={oName}
            defaultValue={oValue}
            disabled={disabled}
            errors={errors}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              setData(e.target.name, e.target.value);
            }}
          ></TextInputController>
        </section>
      </div>
    </div>
  );
};

export default Dafo;
