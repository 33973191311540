import { ExpandMore } from '@material-ui/icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FilterInputDirectory, ItemMetadata } from '../../../../redux/directory/definitions';
import styles from './selectAutocomplete.module.scss';
import SelectAutocompleteResultList from './SelectAutocompleteResultList';
import SearchIcon from '@material-ui/icons/Search';

interface SelectAutocompleteProps {
  label: string;
  name: keyof FilterInputDirectory;
  metadata: ItemMetadata[];
  selected: boolean;
  propertyToSend?: keyof ItemMetadata;
  onSelectItem: (key: keyof FilterInputDirectory, value: string, fieldToSend: string | number) => void;
}

const SelectAutocomplete: React.FC<SelectAutocompleteProps> = ({
  label,
  name,
  metadata,
  selected,
  propertyToSend = 'value',
  onSelectItem,
}) => {
  const [userInput, setUserInput] = useState('');
  const [viewState, setViewState] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      setUserInput('');
      inputRef.current.value = '';
      inputRef.current.focus();
    }
  }, [viewState]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.target); //TODO?
  };

  const handleOnSelectItem = (value: string, propertyToSend: string | number) =>
    onSelectItem(name, value, propertyToSend);

  return (
    <Fragment>
      <div
        className={`${styles.root} ${viewState ? 'open' : ''}`}
        onMouseOver={() => setViewState(true)}
        onMouseLeave={() => setViewState(false)}
      >
        <label className={selected ? styles.title__active : styles.title}>
          {label} <ExpandMore />
        </label>
        <div className={styles.searchBox}>
          <div className={styles.inputSearch}>
            <div className={styles.inputWrapper}>
              <input
                name={name}
                className={styles.input}
                ref={inputRef}
                type="text"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <SearchIcon />
            </div>
          </div>
          <SelectAutocompleteResultList
            metadata={metadata}
            onSelect={handleOnSelectItem}
            searchData={userInput}
            propertyToSend={propertyToSend}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default SelectAutocomplete;
