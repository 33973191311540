import { getDirectoryFilter, sendSuggestionDataApi } from '../../api/Directory/directory';
import { getDirectoryKo, getDirectoryOk, sendSuggestionKo, sendSuggestionOk } from './action';
import { DirectoryDataConsts } from './action_types';
import { GetDirectoryDataAction, SendSuggestionDataAction } from './definitions';
import { put, takeLatest } from '@redux-saga/core/effects';
import { showSnackbar } from '../FeedbackAPI/actions';
import { tErrorKey, tKey } from '../../helpers/translate';

function* getDirectoryDataFromSaga(action: GetDirectoryDataAction): Generator<any, void, any> {
  try {
    const { query } = action;
    const { data } = yield getDirectoryFilter(query);
    yield put(getDirectoryOk(data));
  } catch (e) {
    yield put(getDirectoryKo(e.message));
    yield put(showSnackbar(tKey(`Hubo un error en la búsqueda.`), 'error', undefined, 1500));
  }
}

function* sendSuggestionDataFromSaga(action: SendSuggestionDataAction): Generator<any, void, any> {
  try {
    yield sendSuggestionDataApi(action);
    yield put(sendSuggestionOk());
    yield put(showSnackbar(tKey(`Queja enviada correctamente.`), 'success', undefined, 1500));
  } catch (e) {
    yield put(sendSuggestionKo(e.message));
    yield put(
      showSnackbar(tErrorKey('Hubo un error al enviar tu queja').concat(': ', e.message), 'error', undefined, 1500),
    );
  }
}

export default [
  takeLatest<GetDirectoryDataAction>(DirectoryDataConsts.GET_DIRECTORY, getDirectoryDataFromSaga),
  takeLatest<SendSuggestionDataAction>(DirectoryDataConsts.SEND_SUGGESTION, sendSuggestionDataFromSaga),
];
