import assertUnreachable from '../../helpers/assertUnreachable';
import errorMessage from '../../helpers/errorMessage';
import { IdNamePair } from '../common/definitions';
import { InternalProjectTemplatesConst } from './action_types';
import {
  FollowUpData,
  GeneralFormData,
  initialState,
  InternalProjectTemplate,
  InternalProjectTemplateDataAction,
  RoleWithPermission,
} from './definitions';

export type InternalProjectTemplatesState = typeof initialState;

/* Helper functions */
const getRoleNames = (roles: RoleWithPermission[]): string => {
  let role_names = '';
  roles.forEach((r: RoleWithPermission) => {
    if (role_names.length === 0) role_names = r.name;
    else role_names += `, ${r.name}`;
  });
  return role_names;
};

const internalProjectTemplateReducer = (
  state = initialState,
  action: InternalProjectTemplateDataAction,
): InternalProjectTemplatesState => {
  let generalForms;
  let followUps;
  switch (action.type) {
    /************************** SETTER **************************/
    case InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_DATA:
      return {
        ...state,
        internalProjectTemplateData: { ...state.internalProjectTemplateData, [action.key]: action.value },
      };
    case InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE:
      return {
        ...state,
        internalProjectTemplates: action.internalProjectTemplates,
      };
    case InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA:
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          roles: [...state.internalProjectTemplateData.roles, action.role],
        },
      };
    case InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA:
      const templateRoles = state.internalProjectTemplateData.roles.filter((e: IdNamePair) => e.id !== action.roleId);
      //also remove from general forms and followups
      generalForms = state.internalProjectTemplateData.general_forms.map((generalForm: GeneralFormData) => {
        const generalFormRoles = generalForm.roles.filter((r: RoleWithPermission) => r.id !== action.roleId);
        generalForm.roles = generalFormRoles;
        generalForm.role_names = getRoleNames(generalFormRoles);
        return generalForm;
      });
      followUps = followUps = state.internalProjectTemplateData.follow_ups.map((fu: FollowUpData) => {
        const followUpRoles = fu.roles.filter((r: RoleWithPermission) => r.id !== action.roleId);
        fu.roles = followUpRoles;
        fu.role_names = getRoleNames(followUpRoles);
        return fu;
      });

      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          roles: templateRoles,
          general_forms: generalForms,
          follow_ups: followUps,
        },
      };
    case InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA:
      generalForms = state.internalProjectTemplateData.general_forms.map((generalForm: GeneralFormData) => {
        if (generalForm.id === action.formId) {
          const role = generalForm.roles.findIndex((r: RoleWithPermission) => r.id === action.role.id);
          //if exist, update values
          if (role !== -1) generalForm.roles[role] = action.role;
          else generalForm.roles.push(action.role);
        }
        return generalForm;
      });
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          general_forms: generalForms,
        },
      };
    case InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA:
      generalForms = state.internalProjectTemplateData.general_forms.map((generalForm: GeneralFormData) => {
        if (generalForm.id === action.formId) {
          generalForm.roles = generalForm.roles.filter((e: RoleWithPermission) => e.id !== action.roleId);
        }
        return generalForm;
      });
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          general_forms: generalForms,
        },
      };
    case InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA:
      followUps = state.internalProjectTemplateData.follow_ups.map((fu: FollowUpData) => {
        if (fu.id === action.followUpId) {
          const role = fu.roles.findIndex((r: RoleWithPermission) => r.id === action.role.id);
          //if exist, update values
          if (role !== -1) fu.roles[role] = action.role;
          else fu.roles.push(action.role);
        }
        return fu;
      });
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          follow_ups: followUps,
        },
      };
    case InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA:
      followUps = state.internalProjectTemplateData.follow_ups.map((fu: FollowUpData) => {
        if (fu.id === action.followUpId) fu.roles = fu.roles.filter((e: RoleWithPermission) => e.id !== action.roleId);
        return fu;
      });
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          follow_ups: followUps,
        },
      };

    /************************** START UP **************************/
    case InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO:
    case InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE:
    case InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO:
    case InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP:
      return {
        ...state,
        loading: true,
      };
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE:
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES:
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES:
    case InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE:
      return {
        ...state,
      };
    case InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP:
      return {
        ...state,
        loadingAlt: true,
      };
    /************************** OKAY **************************/
    case InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_METADATA_OK:
      return { ...state, internalProjectTemplatesMetadata: action.internalProjectTemplatesMetadata };
    case InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO_OK:
      return {
        ...state,
        loading: false,
        internalProjectTemplates: action.internalProjectTemplatesData,
        error: null,
      };
    case InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_OK:
      return {
        ...state,
        loading: false,
        internalProjectTemplates: action.internalProjectTemplates,
      };
    case InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO_OK:
      return {
        ...state,
        loading: false,
        internalProjectTemplateData: action.internalProjectTemplateData,
      };
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_OK:
      const iptListNew = state.internalProjectTemplates;
      const index = state.internalProjectTemplates.findIndex(
        (e: InternalProjectTemplate) => e.id === state.internalProjectTemplateData.id,
      );
      if (index !== -1) {
        iptListNew[index] = {
          ...iptListNew[index],
          template_name: action.internalProjectTemplateData.template_name,
        } as InternalProjectTemplate;
      }
      return {
        ...state,
        internalProjectTemplates: iptListNew,
      };
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_OK:
      generalForms = state.internalProjectTemplateData.general_forms.map((generalForm: GeneralFormData) => {
        if (generalForm.id === action.formId) {
          generalForm.role_names = getRoleNames(action.roles);
        }
        return generalForm;
      });
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          general_forms: generalForms,
        },
      };
    case InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK:
      return {
        ...state,
        loading: false,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          follow_ups: action.followUp,
        },
      };
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_OK:
      followUps = state.internalProjectTemplateData.follow_ups.map((fu: FollowUpData) => {
        if (fu.id === action.followUpId) {
          let role_names = '';
          action.roles.forEach((r: RoleWithPermission) => {
            if (role_names.length === 0) role_names = r.name;
            else role_names += `, ${r.name}`;
          });
          fu.role_names = role_names;
        }
        return fu;
      });
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          follow_ups: followUps,
        },
      };
    case InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK:
      return {
        ...state,
        loadingAlt: false,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          follow_ups: action.internalProjectTemplateFollowUps,
        },
      };
    case InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE_OK:
      return {
        ...state,
        internalProjectTemplateData: {
          ...state.internalProjectTemplateData,
          is_published: true,
        },
      };

    /************************** ERROR **************************/
    case InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO_KO:
    case InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_KO:
    case InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO_KO:
    case InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_KO:
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_KO:
    case InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_KO:
    case InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE_KO:
      return {
        ...state,
        error: action.error,
      };
    case InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO:
      return {
        ...state,
        loadingAlt: false,
        error: action.error,
      };
    case InternalProjectTemplatesConst.GENERIC_KO:
      const { apiError } = action;
      return {
        ...state,
        loading: false,
        error: errorMessage(apiError),
      };

    default:
      assertUnreachable(action);
      return state;
  }
};

export default internalProjectTemplateReducer;
