import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { getAcademyData } from '../../../redux/academy/actions';
import { AcademyDataDispatch } from '../../../redux/academy/definitions';
import { selectAcademyContact, selectAcademyContacts, selectAcademyData } from '../../../redux/academy/selectors';
import { AppState } from '../../../redux/root-reducer';
import AcademyContacts from '../AcademyContacts/AcademyContacts';
import AcademyDetails from '../AcademyDetails/AcademyDetails';
import IbanList from '../IbanTabs/IbanList';
import SubmenuAcademy from '../SubmenuAcademy/SubmenuAcademy';

interface AcademyLayoutProps extends RouteComponentProps<{ id: string }> {
  loading: boolean;
  getAcademyData: (id: number) => void;
}

const AcademyLayout: React.FC<AcademyLayoutProps> = ({ loading, match, getAcademyData }) => {
  const [idComponent, setIdComponent] = useState(0);
  const id = match.params.id ? parseInt(match.params.id) : 0;

  useEffect(() => {
    getAcademyData(id);
  }, [id, getAcademyData]);

  const handleComponentId = (componentId: number) => {
    setIdComponent(componentId);
  };

  const selectedMenu = () => {
    switch (idComponent) {
      case 0:
        return 'datos_generales';
      case 1:
        return 'datos_contables';
      case 2:
        return 'gestion_responsable';
      default:
        return 'datos_generales';
    }
  };

  const componentsList = [
    <AcademyDetails key={0} onSelectComponent={handleComponentId} />,
    <IbanList key={1} academy_id={+id} />,
    <AcademyContacts academyId={id} key={2} onSelectComponent={handleComponentId} />,
  ];
  const correctPanel = componentsList[idComponent];

  return (
    <Fragment>
      <LayoutForm
        leftSubmenu={<SubmenuAcademy selected={selectedMenu()} loading={false} onSelectComponent={handleComponentId} />}
        rightSubmenu={<ActionsMenu goBack />}
      >
        {correctPanel}
      </LayoutForm>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.academyReducer.loading,
  selectAcademyData: selectAcademyData(state),
  contactsList: selectAcademyContacts(state),
  contactData: selectAcademyContact(state),
  academyIbans: state.academyReducer.academyIbans,
});

const mapDispatchToProps = (dispatch: AcademyDataDispatch) => ({
  getAcademyData: (id: number): void => dispatch(getAcademyData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AcademyLayout);
