import FollowUpType from './followUpType';

class FollowUpCode {
  static T1 = 'T1';
  static T2 = 'T2';
  static T3 = 'T3';
  static S1 = 'S1';
  static S2 = 'S2';

  constructor(private code: string) {}

  public static create(followNumber: number, isBiannual: boolean): FollowUpCode {
    switch (followNumber) {
      case 1:
        return isBiannual ? new FollowUpCode(FollowUpCode.S1) : new FollowUpCode(FollowUpCode.T1);
      case 2:
        return isBiannual ? new FollowUpCode(FollowUpCode.S2) : new FollowUpCode(FollowUpCode.T2);
      default:
        return new FollowUpCode(FollowUpCode.T3);
    }
  }

  public isT1(): boolean {
    return this.code === FollowUpCode.T1;
  }

  public isT2(): boolean {
    return this.code === FollowUpCode.T2;
  }

  public isT3(): boolean {
    return this.code === FollowUpCode.T3;
  }

  public isS1(): boolean {
    return this.code === FollowUpCode.S1;
  }

  public isS2(): boolean {
    return this.code === FollowUpCode.S2;
  }

  public isBiannual(): boolean {
    return this.isS1() || this.isS2();
  }

  public isQuaterly(): boolean {
    return !this.isBiannual();
  }

  public isFirstFollowUp(): boolean {
    return this.isS1() || this.isT1();
  }

  public isSecondFollowUp(): boolean {
    return this.isS2() || this.isT2();
  }

  public isThridFollowUp(): boolean {
    return this.isT3();
  }

  public isLastFollowUp(): boolean {
    return this.isT3() || this.isS2();
  }

  public value(): string {
    return this.code;
  }

  public followUpNumber(): number {
    return Number(this.code.substring(1));
  }

  public followUpType(): FollowUpType {
    if (this.isBiannual()) {
      return FollowUpType.biannual();
    }
    return FollowUpType.quaterly();
  }
}

export default FollowUpCode;
