import { Language } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableLayout from '../../../components/Layout/TableLayout';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tKey } from '../../../helpers/translate';
import { getUserEvents } from '../../../redux/event/actions';
import { EventsUser } from '../../../redux/event/definitions';
import { AppState } from '../../../redux/root-reducer';
import styles from './events.module.scss';

interface UserEventsPageProps {
  eventsUserList: EventsUser[];
  getUserEvents: () => void;
}

const actionIcons = {
  Link: () => <Language style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
};

const UserEvents: React.FC<UserEventsPageProps> = ({ eventsUserList, getUserEvents }) => {
  const [data, setData] = useState<EventsUser[]>([]);

  useEffect(() => {
    getUserEvents();
  }, [getUserEvents]);

  useEffect(() => {
    if (eventsUserList.length > 0) setData(eventsUserList);
  }, [eventsUserList]);

  const tableData: MaterialTableProps<EventsUser> = {
    title: tKey('Lista de Eventos'),
    columns: [
      { title: tKey('Nombre'), field: 'title' },
      {
        title: tKey('Fecha'),
        field: 'start_date',
        render: (rowData: EventsUser) => rowData.start_date.split(' ')[0],
      },
      { title: tKey('Tipo de evento'), field: 'type' },
    ],
    actions: [
      rowData => ({
        icon: actionIcons.Link,
        tooltip: tKey('Link'),
        onClick: e => {
          e.stopPropagation();
          window.open(rowData.connection_link, '_blank');
        },
        hidden: !rowData.connection_link,
      }),
    ],
    data: data,
    options: {
      exportButton: false,
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  return (
    <TableLayout>
      <div className={styles.container}>
        <TableWithEdit tableData={tableData} filtering />
      </div>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  eventsUserList: state.eventReducer.eventsUserList,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserEvents: (): void => dispatch(getUserEvents()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEvents);
