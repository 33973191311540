import { connect } from 'react-redux';
import {
  academyUpdateContact,
  closeContactModal,
  contactSetInfoData,
  deleteContactData,
} from '../../../redux/academy/actions';
import { AppState } from '../../../redux/root-reducer';
import UnconnectedGenericModalResponsible from './GenericModalResponsible';

const EditModalResponsible = connect(
  (state: AppState) => ({
    loading: state.academyReducer.loadingModal,
    contactData: state.academyReducer.contactData,
    academyId: state.academyReducer.academyData.id,
  }),
  (dispatch: any) => ({
    setData: (key: string, value: string | number): void => dispatch(contactSetInfoData(key, value)),
    onSave: (contactData: any, academy_id: number): void => dispatch(academyUpdateContact(contactData, academy_id)),
    deleteContactData: (): void => dispatch(deleteContactData()),
    closeContactModal: (): void => dispatch(closeContactModal()),
  }),
)(UnconnectedGenericModalResponsible);

export default EditModalResponsible;
