import { Fields } from '../../components/GenericForm/GenericFormRefactor';
import { tKey } from '../../helpers/translate';
import { IdCodeNamePair, IdNamePair } from '../common/definitions';
import { AppState } from '../root-reducer';
import {
  BaseCampForm,
  Follows,
  InternalProject,
  InternalProjectEnrollment,
  InternalProjectsData,
  InternalProjectSettings,
  InternalProjectSubareaType,
  Member,
  ParticipantesFollowUpList,
  ProjectAllowedUser,
} from './definitions';
import { InternalProjectsState } from './reducers';

export const selectInternalProjectsState = (state: AppState): InternalProjectsState => state.internalProjectReducer;
export const selectInternalProjectBySubAreaCode = (state: AppState) => (
  subAreaCode: string,
): InternalProjectsData[] => {
  const result = state.internalProjectReducer.internalProjects
    .filter(project => project.subarea_code === subAreaCode)
    .map(project => {
      project.academyOrEntityName = project.academy_name ?? project.entity_name;
      return project;
    }) as InternalProjectsData[];
  return result;
};
export const selectLookupCategory = (state: AppState) => (subarea_code: string): { [k: string]: string } =>
  Array.from(
    new Set(
      state.internalProjectReducer.internalProjects
        .filter(pr => pr.subarea_code === subarea_code)
        .map(pr => pr.subarea_internal_project_type_name),
    ),
  ).reduce((acc, curr) => {
    return { ...acc, [curr]: curr };
  }, {});

export const selectMembersUsersList = (state: AppState): { id: number; info: string }[] | [] => {
  const usersList = state.internalProjectReducer.projectAllowedUsers
    .filter(
      user => !state.internalProjectReducer.internalProjectData.members.some(member => member.user_id === user.id),
    )
    .map(user => ({
      id: user.id,
      info: `${user.name} (${user.origin})`,
    }));
  return usersList;
};

export const formatFollows = (state: AppState): Follows[] =>
  state.internalProjectReducer.follows.map(follow => ({
    ...follow,
    completed_label: follow.completed ? tKey('Sí') : tKey('No'),
  }));

export const selectInternalProject = (state: AppState): InternalProject =>
  selectInternalProjectsState(state).internalProjectData;

export const selectIpLoading = (state: AppState): boolean => selectInternalProjectsState(state).loading;
export const selectIpLatestSubArea = (state: AppState): string => {
  return selectInternalProjectsState(state).latestInternalProjectSubArea;
};
export const selectIpLatestSection = (state: AppState) => (internalProjectId: number): string | null => {
  const index = selectInternalProjectsState(state).latestInternalProjectSection.findIndex(
    (e: IdNamePair) => e.id === internalProjectId,
  );
  if (index !== -1) return selectInternalProjectsState(state).latestInternalProjectSection[index].name;
  return null;
};
export const selectHasDailyFollowUps = (state: AppState): boolean => selectInternalProject(state).has_daily_follow_up;
export const selectHasEnrollments = (state: AppState): boolean => selectInternalProject(state).has_enrollments;

export const selectInternalProjectSubAreas = (state: AppState): IdCodeNamePair[] =>
  selectInternalProjectsState(state).internalProjectSubAreas;

export const selectInternalProjectSettings = (state: AppState): InternalProjectSettings =>
  selectInternalProjectsState(state).internalProjectSettings;

export const selectBaseCampData = (state: AppState): BaseCampForm => selectInternalProjectsState(state).baseCampData;

export const selectProjectLink = (state: AppState): string => {
  const baseCampData = selectBaseCampData(state);
  return document.location.origin.concat('/proyectos-internos/evaluacion/', baseCampData.project_key);
};

export const selectNouscimsUsers = (state: AppState): IdNamePair[] => {
  return selectInternalProjectsState(state)
    .projectAllowedUsers.filter((e: ProjectAllowedUser) => e.is_nouscims === true)
    .map((e: ProjectAllowedUser) => ({ id: e.id, name: e.name }));
};

export const selectStudentEvaluationFields = (state: AppState): Fields => {
  const studentEvaluation = selectInternalProjectsState(state).studentEvaluationData;
  if (studentEvaluation.pi_seguimiento_final_joven) {
    return studentEvaluation.pi_seguimiento_final_joven.fields;
  }
  return {} as Fields;
};

export const selectKeepGoingEnrollemts = (state: AppState): InternalProjectEnrollment[] => {
  const keepGoing = 1;
  const selectKeepGoingEnrollemts = state.internalProjectReducer.enrollments.filter(e => e.keep_going === keepGoing);
  return selectKeepGoingEnrollemts;
};

export const selectKeepGoingParticipantesFollowUpList = (state: AppState): ParticipantesFollowUpList[] => {
  const participantesFollowUpList = state.internalProjectReducer.participantesFollowUpList;
  const keepGoingEnrollments = selectKeepGoingEnrollemts(state);
  const keepGoingParticipantesFollowUpList = participantesFollowUpList.filter(user =>
    keepGoingEnrollments.some(keepGoingUser => user.internal_project_enrollment_id === keepGoingUser.id),
  );

  return keepGoingParticipantesFollowUpList;
};

export const selectInternalProjectSubareaTypes = (state: AppState): InternalProjectSubareaType[] => {
  return selectInternalProjectsState(state).internalProjectSubareaTypes;
};

export const selectInternalProjectFacilitadores = (state: AppState): Member[] => {
  const members = selectInternalProjectsState(state).internalProjectData.members;
  return members.filter(member => !member.is_nouscims);
};
