/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIValidationError } from '../../api/api';
import { ColumnOrderData, DocumentationItem, FileItem, DocUpload, ResponseData } from '../common/definitions';
import { EntityDataConsts } from './action_types';
import {
  AddContractAction,
  ApproveEntityDirectorAction,
  ApproveEntityDirectorKoAction,
  ApproveEntityDirectorOkAction,
  ChangeResponsibleAction,
  ChangeResponsibleKoAction,
  ChangeStateEntity,
  ClearNavigationAction,
  ConfirmEntityAction,
  ConfirmEntityKoAction,
  ConfirmEntityOkAction,
  CreateNewContractAction,
  CreateNewContractKoAction,
  CreateNewContractOkAction,
  DeleteContractAction,
  DeleteContractKoAction,
  DeleteContractOkAction,
  DownloadContractDocumentAction,
  DownloadContractDocumentKoAction,
  DownloadContractDocumentOkAction,
  DownloadDocumentEntityAction,
  DownloadDocumentEntityKoAction,
  DownloadDocumentEntityOkAction,
  DownloadDocumentPreEntityAction,
  DownloadDocumentPreEntityKoAction,
  DownloadDocumentPreEntityOkAction,
  EditContractAction,
  EditContractKoAction,
  EditContractOkAction,
  EntitiesChangeAction,
  EntityChangeStateAction,
  EntityChangeStateKoAction,
  EntityChangeStateOkAction,
  EntityContract,
  EntityDeleteOneAction,
  EntityDeleteOneActionKo,
  EntityDeleteOneActionOk,
  EntityUpdateAction,
  EntityUpdateKoAction,
  EntityUpdateOkAction,
  EntityFncSendAction,
  EntityFncSendKoAction,
  EntityFncSendOkAction,
  CreateEntityFncAction,
  EntityGetDocumentsAction,
  EntityGetDocumentsKoAction,
  EntityGetDocumentsOkAction,
  EntityGetForUser,
  EntityGetForUserKo,
  EntityGetForUserOk,
  EntityGetListAction,
  EntityGetListKoAction,
  EntityGetListOkAction,
  EntityGetOneAction,
  EntityGetOneActionKo,
  EntityGetOneActionOk,
  EntityGetPreCreatedUserAction,
  EntityGetPreCreatedUserKoAction,
  EntityGetPreCreatedUserOkAction,
  EntityInfo,
  EntityListItem,
  EntitySetColumnOrderTableAction,
  EntitySetDataAction,
  EntitySetPageSizeTableAction,
  EntitySetSearchTextTableAction,
  EntityUserCreateAction,
  EntityUserCreateKoAction,
  EntityUserCreateOkAction,
  EntityValidationErrorsAction,
  GetAvatarEntityAction,
  GetAvatarEntityKoAction,
  GetAvatarEntityOkAction,
  GetAvatarPreEntityAction,
  GetAvatarPreEntityKoAction,
  GetAvatarPreEntityOkAction,
  GetContractAction,
  GetContractKoAction,
  GetContractOkAction,
  GetDocumentEntityAction,
  GetDocumentEntityKoAction,
  GetDocumentEntityOkAction,
  GetDocumentPreEntityAction,
  GetDocumentPreEntityKoAction,
  GetDocumentPreEntityOkAction,
  GetEntityPreCreatedIdAction,
  GetEntityPreCreatedIdActionKoAction,
  GetEntityPreCreatedIdActionOkAction,
  ListEntitiesState,
  MakeProvider,
  MakeProviderKo,
  MakeProviderOk,
  PreEntityUpdateAction,
  PreEntityUpdateKoAction,
  PreEntityUpdateOkAction,
  RejectEntityDirectorAction,
  RejectEntityDirectorKoAction,
  RejectEntityDirectorOkAction,
  RejectExternalEntityAction,
  RejectExternalEntityKoAction,
  RejectExternalEntityOkAction,
  RejectInternalEntityAction,
  RejectInternalEntityKoAction,
  RejectInternalEntityOkAction,
  RemoveContractDocumentAction,
  RemoveContractDocumentKoAction,
  RemoveContractDocumentOkAction,
  RemoveDocumentEntityAction,
  RemoveDocumentEntityKoAction,
  RemoveDocumentEntityOkAction,
  RemoveDocumentPreEntityAction,
  RemoveDocumentPreEntityKoAction,
  RemoveDocumentPreEntityOkAction,
  ResetAllSuccessAction,
  ResetContractAction,
  ResetDocumentsAction,
  ResetResponseDataAction,
  SetAvatarEntityAction,
  SetAvatarEntityKoAction,
  SetAvatarEntityOkAction,
  SetAvatarPreEntityAction,
  SetAvatarPreEntityKoAction,
  SetAvatarPreEntityOkAction,
  SetContractDataAction,
  SetMandatoryDocument,
  UploadContractDocumentAction,
  UploadContractDocumentKoAction,
  UploadContractDocumentOkAction,
  UploadDocumentEntityAction,
  UploadDocumentEntityKoAction,
  UploadDocumentEntityOkAction,
  UploadDocumentPreEntityAction,
  UploadDocumentPreEntityKoAction,
  UploadDocumentPreEntityOkAction,
  OpenContractModalAction,
  CloseContractModalAction,
  EntityContactsListAction,
  EntityContactsListOkAction,
  EntityContactsListKoAction,
  EntityContactData,
  EntityOneContactAction,
  EntityOneContactOkAction,
  EntityOneContactKoAction,
  RemoveContactEntityAction,
  RemoveContactEntityOkAction,
  RemoveContactEntityKoAction,
  EntityContactRolesAction,
  EntityContactRolesOkAction,
  EntityContactRolesKoAction,
  CreateEntityContactAction,
  CreateEntityContactOkAction,
  CreateEntityContactKoAction,
  EntityUpdateContactKoAction,
  EntityUpdateContactOkAction,
  EntityUpdateContactAction,
  DeleteContactDataAction,
  OpenContactModalAction,
  CloseContactModalAction,
  DeleteIbanDataAction,
  OpenIbanModalAction,
  CloseIbanModalAction,
  ListEntityIbansAction,
  ListEntityIbansOkAction,
  ListEntityIbansKoAction,
  EntityIbanData,
  EntityOneIbanAction,
  EntityOneIbanOkAction,
  EntityOneIbanKoAction,
  RemoveIbanEntityAction,
  RemoveIbanEntityOkAction,
  RemoveIbanEntityKoAction,
  CreateEntityIbanOkAction,
  CreateEntityIbanKoAction,
  EntityUpdateIbanAction,
  EntityUpdateIbanOkAction,
  EntityUpdateIbanKoAction,
  CreateEntityIbantAction,
  DownloadIbanDocumentAction,
  DownloadIbanDocumentOkAction,
  DownloadIbanDocumentKoAction,
  UploadIbanEntityDocumentAction,
  UploadIbanEntityDocumentOkAction,
  UploadIbanEntityDocumentKoAction,
  RemoveIbanEntityDocumentAction,
  RemoveIbanEntityDocumentOkAction,
  RemoveIbanEntityDocumentKoAction,
  EntitySetIbanDataAction,
  EntityContactSetDataAction,
} from './definitions';

export function entityGetList(state: ListEntitiesState): EntityGetListAction {
  return {
    type: EntityDataConsts.ENTITY_GET_LIST,
    state,
  };
}

export function entityGetListOk(entityList: EntityListItem[]): EntityGetListOkAction {
  return {
    type: EntityDataConsts.ENTITY_GET_LIST_OK,
    entityList,
  };
}

export function entityGetListKo(error: string): EntityGetListKoAction {
  return {
    type: EntityDataConsts.ENTITY_GET_LIST_KO,
    error,
  };
}

export function entityValidationErrors(validationErrors: APIValidationError): EntityValidationErrorsAction {
  return {
    type: EntityDataConsts.ENTITY_VALIDATION_ERRORS,
    validationErrors,
  };
}

export function entityFncSend(entityOneData: EntityInfo): EntityFncSendAction {
  return {
    type: EntityDataConsts.ENTITY_FNC_SEND,
    entityOneData,
  };
}

export function entityFncSendOk(entityOneData: EntityInfo): EntityFncSendOkAction {
  return {
    type: EntityDataConsts.ENTITY_FNC_SEND_OK,
    entityOneData,
  };
}

export function entityFncSendKo(error: any): EntityFncSendKoAction {
  return {
    type: EntityDataConsts.ENTITY_FNC_SEND_KO,
    error,
  };
}

// USER ENTITY CREATE  - SEND NOTIFICATION

export function createEntityUser(entityOneData: EntityInfo): EntityUserCreateAction {
  return {
    type: EntityDataConsts.PRE_ENTITY_SEND,
    entityOneData,
  };
}

export function createEntityUserOk(entityOneData: EntityInfo): EntityUserCreateOkAction {
  return {
    type: EntityDataConsts.PRE_ENTITY_SEND_OK,
    entityOneData,
  };
}

export function createEntityUserKo(error: string): EntityUserCreateKoAction {
  return {
    type: EntityDataConsts.PRE_ENTITY_SEND_KO,
    error,
  };
}

// ENTITY FNC SAVE

export function createEntityFnc(entityOneData: EntityInfo): CreateEntityFncAction {
  return {
    type: EntityDataConsts.CREATE_ENTITY_FNC,
    entityOneData,
  };
}

// ENTITY USER SAVE

export function preEntityUpdate(entityOneData: EntityInfo): PreEntityUpdateAction {
  return {
    type: EntityDataConsts.PRE_ENTITY_UPDATE,
    entityOneData,
  };
}

export function preEntityUpdateOk(entityOneData: EntityInfo): PreEntityUpdateOkAction {
  return {
    type: EntityDataConsts.PRE_ENTITY_UPDATE_OK,
    entityOneData,
  };
}

export function preEntityUpdateKo(error: any): PreEntityUpdateKoAction {
  return {
    type: EntityDataConsts.PRE_ENTITY_UPDATE_KO,
    error,
  };
}

// ENTITY EDIT

export function updateEntity(data: EntityInfo): EntityUpdateAction {
  return {
    type: EntityDataConsts.ENTITY_UPDATE,
    data,
  };
}

export function updateEntityOk(entityOneData: EntityInfo): EntityUpdateOkAction {
  return {
    type: EntityDataConsts.ENTITY_UPDATE_OK,
    entityOneData,
  };
}

export function updateEntityKo(error: string): EntityUpdateKoAction {
  return {
    type: EntityDataConsts.ENTITY_UPDATE_KO,
    error,
  };
}

// ENTITY GETONE

export function entityGetOne(id: number): EntityGetOneAction {
  return {
    type: EntityDataConsts.ENTITY_GET_ONE,
    id,
  };
}

export function entityGetOneOk(
  entityOneData: EntityInfo,
  documentation: DocumentationItem[],
  avatar?: string,
): EntityGetOneActionOk {
  return {
    type: EntityDataConsts.ENTITY_GET_ONE_OK,
    entityOneData,
    documentation,
    avatar,
  };
}

export function entityGetOneKo(error: string): EntityGetOneActionKo {
  return {
    type: EntityDataConsts.ENTITY_GET_ONE_KO,
    error,
  };
}

// ENTITY DELETE ONE

export function deleteOne(id: number): EntityDeleteOneAction {
  return {
    type: EntityDataConsts.ENTITY_DELETE_ONE,
    id,
  };
}

export function deleteOneOk(responseData: ResponseData): EntityDeleteOneActionOk {
  return {
    type: EntityDataConsts.ENTITY_DELETE_ONE_OK,
    responseData,
  };
}

export function deleteOneKo(error: string): EntityDeleteOneActionKo {
  return {
    type: EntityDataConsts.ENTITY_DELETE_ONE_KO,
    error,
  };
}

// ENTITY SET PAGESIZE

export function setPageSize(pageSize: number): EntitySetPageSizeTableAction {
  return {
    type: EntityDataConsts.ENTITY_SET_PAGESIZE_TABLE_OPTIONS,
    pageSize,
  };
}

export function setSearchText(searchText: string): EntitySetSearchTextTableAction {
  return {
    type: EntityDataConsts.ENTITY_SET_SEARCHTEXT_TABLE_OPTIONS,
    searchText,
  };
}

export function setColumnOrder(columnOrder: ColumnOrderData): EntitySetColumnOrderTableAction {
  return {
    type: EntityDataConsts.ENTITY_SET_COLUMNORDER_TABLE_OPTIONS,
    columnOrder,
  };
}

// RESET RESPONSEDATA

export function resetResponseData(): ResetResponseDataAction {
  return {
    type: EntityDataConsts.ENTITY_RESET_RESPONSEDATA,
  };
}

export function resetAllSuccess(): ResetAllSuccessAction {
  return {
    type: EntityDataConsts.ENTITY_RESET_ALLSUCCESS,
  };
}

// GET ENTITIES USER PRE CREATED

export function getEntitiesPreCreatedUser(): EntityGetPreCreatedUserAction {
  return {
    type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER,
  };
}

export function getEntitiesPreCreatedUserOk(
  data: EntityInfo,
  documentation: DocumentationItem[],
  avatar?: string,
): EntityGetPreCreatedUserOkAction {
  return {
    type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_OK,
    entitiesData: data,
    documentation,
    avatar,
  };
}

export function getEntitiesPreCreatedUserKo(error: any): EntityGetPreCreatedUserKoAction {
  return {
    type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_KO,
    error,
  };
}

// ENTITY GET BY ID

export function getEntityPreCreatedId(id: number): GetEntityPreCreatedIdAction {
  return {
    type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID,
    id,
  };
}

export function getEntityPreCreatedIdActionOk(
  data: EntityInfo,
  documentation: DocumentationItem[],
  avatar?: string,
): GetEntityPreCreatedIdActionOkAction {
  return {
    type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID_OK,
    entityOneData: data,
    documentation,
    avatar,
  };
}

export function getEntityPreCreatedIdActionKo(error: string): GetEntityPreCreatedIdActionKoAction {
  return {
    type: EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID_KO,
    error,
  };
}

// GET ENTITY FOR USER

export function entityGetForUser(): EntityGetForUser {
  return {
    type: EntityDataConsts.ENTITY_GET_USER,
  };
}

export function entityGetForUserOk(
  data: EntityInfo,
  documentation: DocumentationItem[],
  avatar?: string,
): EntityGetForUserOk {
  return {
    type: EntityDataConsts.ENTITY_GET_USER_OK,
    entityOneData: data,
    documentation,
    avatar,
  };
}

export function entityGetForUserKo(error: string): EntityGetForUserKo {
  return {
    type: EntityDataConsts.ENTITY_GET_USER_KO,
    error,
  };
}

export function entitiesChange(): EntitiesChangeAction {
  return {
    type: EntityDataConsts.ENTITIES_CHANGE,
    changePage: false,
  };
}

// DOCUMENTATION
export function uploadDocument(
  documentation_id: number,
  data: any,
  entityId?: number | string,
  force_add?: boolean | false,
): UploadDocumentEntityAction {
  return {
    type: EntityDataConsts.UPLOAD_ENTITY_DOCUMENT,
    documentation_id,
    data,
    entityId,
    force_add,
  };
}

export function uploadDocumentOk({ documents }: { documents: DocumentationItem[] }): UploadDocumentEntityOkAction {
  return {
    type: EntityDataConsts.UPLOAD_ENTITY_DOCUMENT_OK,
    documents,
  };
}

export function uploadDocumentKo(error: string): UploadDocumentEntityKoAction {
  return {
    type: EntityDataConsts.UPLOAD_ENTITY_DOCUMENT_KO,
    error,
  };
}

export function removeDocument(file_id: number, entityId: number): RemoveDocumentEntityAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_DOCUMENT,
    file_id,
    entityId,
  };
}

export function removeDocumentOk({ documents }: { documents: DocumentationItem[] }): RemoveDocumentEntityOkAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_DOCUMENT_OK,
    documents,
  };
}

export function removeDocumentKo(error: string): RemoveDocumentEntityKoAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_DOCUMENT_KO,
    error,
  };
}

export function resetDocumentsToSend(docToUpload?: DocUpload[]): ResetDocumentsAction {
  return {
    type: EntityDataConsts.RESET_DOCUMENTS_ENTITY,
    docToUpload,
  };
}

export function downloadDocument(file_id: number, name: string, entityId: number): DownloadDocumentEntityAction {
  return {
    type: EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT,
    file_id,
    name,
    entityId,
  };
}

export function downloadDocumentOk(data: Blob, name: string): DownloadDocumentEntityOkAction {
  return {
    type: EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadDocumentKo(error: string): DownloadDocumentEntityKoAction {
  return {
    type: EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT_KO,
    error,
  };
}

export function getDocument(file_id: number, name: string, entityId: number): GetDocumentEntityAction {
  return {
    type: EntityDataConsts.GET_ENTITY_DOCUMENT,
    file_id,
    name,
    entityId,
  };
}

export function getDocumentOk(dataDoc: Blob, name: string): GetDocumentEntityOkAction {
  return {
    type: EntityDataConsts.GET_ENTITY_DOCUMENT_OK,
    dataDoc,
    name,
  };
}

export function getDocumentKo(error: string): GetDocumentEntityKoAction {
  return {
    type: EntityDataConsts.GET_ENTITY_DOCUMENT_KO,
    error,
  };
}

export function getEntityDocuments(entityId: number): EntityGetDocumentsAction {
  return {
    type: EntityDataConsts.ENTITY_GET_DOCUMENTS,
    entityId,
  };
}

export function getEntityDocumentsOk(documentation: DocumentationItem[]): EntityGetDocumentsOkAction {
  return {
    type: EntityDataConsts.ENTITY_GET_DOCUMENTS_OK,
    documentation,
  };
}

export function getEntityDocumentsKo(error: string): EntityGetDocumentsKoAction {
  return {
    type: EntityDataConsts.ENTITY_GET_DOCUMENTS_KO,
    error,
  };
}

export function uploadPreEntityDocument(
  documentation_id: number,
  data: File,
  force_add?: boolean | false,
): UploadDocumentPreEntityAction {
  return {
    type: EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT,
    documentation_id,
    data,
    force_add,
  };
}

export function uploadPreEntityDocumentOk({
  documents,
}: {
  documents: DocumentationItem[];
}): UploadDocumentPreEntityOkAction {
  return {
    type: EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT_OK,
    documents,
  };
}

export function uploadPreEntityDocumentKo(error: string): UploadDocumentPreEntityKoAction {
  return {
    type: EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT_KO,
    error,
  };
}

export function downloadPreEntityDocument(
  file_id: number,
  name: string,
  entityId: number,
): DownloadDocumentPreEntityAction {
  return {
    type: EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT,
    file_id,
    name,
    entityId,
  };
}

export function downloadPreEntityDocumentOk(data: Blob, name: string): DownloadDocumentPreEntityOkAction {
  return {
    type: EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadPreEntityDocumentKo(error: string): DownloadDocumentPreEntityKoAction {
  return {
    type: EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT_KO,
    error,
  };
}

export function getPreEntityDocument(file_id: number, name: string, entityId: number): GetDocumentPreEntityAction {
  return {
    type: EntityDataConsts.PREENTITY_GET_DOCUMENT,
    file_id,
    name,
    entityId,
  };
}

export function getPreEntityDocumentOk(dataDoc: Blob, name: string): GetDocumentPreEntityOkAction {
  return {
    type: EntityDataConsts.PREENTITY_GET_DOCUMENT_OK,
    dataDoc,
    name,
  };
}

export function getPreEntityDocumentKo(error: string): GetDocumentPreEntityKoAction {
  return {
    type: EntityDataConsts.PREENTITY_GET_DOCUMENT_KO,
    error,
  };
}

export function removePreEntityDocument(file_id: number, entityId: number): RemoveDocumentPreEntityAction {
  return {
    type: EntityDataConsts.PREENTITY_REMOVE_DOCUMENT,
    file_id,
    entityId,
  };
}

export function removePreEntityDocumentOk({
  documents,
}: {
  documents: DocumentationItem[];
}): RemoveDocumentPreEntityOkAction {
  return {
    type: EntityDataConsts.PREENTITY_REMOVE_DOCUMENT_OK,
    documents,
  };
}

export function removePreEntityDocumentKo(error: string): RemoveDocumentPreEntityKoAction {
  return {
    type: EntityDataConsts.PREENTITY_REMOVE_DOCUMENT_KO,
    error,
  };
}

export function setAvatarPreEntity(avatar: string): SetAvatarPreEntityAction {
  return {
    type: EntityDataConsts.PREENTITY_SET_AVATAR,
    avatar,
  };
}

export function setAvatarPreEntityOk(avatar: string): SetAvatarPreEntityOkAction {
  return {
    type: EntityDataConsts.PREENTITY_SET_AVATAR_OK,
    avatar,
  };
}

export function setAvatarPreEntityKo(error: string): SetAvatarPreEntityKoAction {
  return {
    type: EntityDataConsts.PREENTITY_SET_AVATAR_KO,
    error,
  };
}

export function setAvatarEntity(avatar: string): SetAvatarEntityAction {
  return {
    type: EntityDataConsts.ENTITY_SET_AVATAR,
    avatar,
  };
}

export function setAvatarEntityOk(avatar: string): SetAvatarEntityOkAction {
  return {
    type: EntityDataConsts.ENTITY_SET_AVATAR_OK,
    avatar,
  };
}

export function setAvatarEntityKo(error: string): SetAvatarEntityKoAction {
  return {
    type: EntityDataConsts.ENTITY_SET_AVATAR_KO,
    error,
  };
}

export function getterAvatarPreEntity(id: number): GetAvatarPreEntityAction {
  return {
    type: EntityDataConsts.PREENTITY_GET_AVATAR,
    id,
  };
}

export function getAvatarPreEntityOk(avatar: string): GetAvatarPreEntityOkAction {
  return {
    type: EntityDataConsts.PREENTITY_GET_AVATAR_OK,
    avatar,
  };
}

export function getAvatarPreEntityKo(error: string): GetAvatarPreEntityKoAction {
  return {
    type: EntityDataConsts.PREENTITY_GET_AVATAR_KO,
    error,
  };
}

export function getterAvatarEntity(id: number): GetAvatarEntityAction {
  return {
    type: EntityDataConsts.ENTITY_GET_AVATAR,
    id,
  };
}

export function getAvatarEntityOk(avatar: string): GetAvatarEntityOkAction {
  return {
    type: EntityDataConsts.ENTITY_GET_AVATAR_OK,
    avatar,
  };
}

export function getAvatarEntityKo(error: string): GetAvatarEntityKoAction {
  return {
    type: EntityDataConsts.ENTITY_GET_AVATAR_KO,
    error,
  };
}
//CHANGE STATE

export function changeState(id: number, states: ChangeStateEntity, comments?: string): EntityChangeStateAction {
  return {
    type: EntityDataConsts.CHANGE_STATE_ENTITY,
    states,
    id,
    comments,
  };
}

export function changeStateOk(): EntityChangeStateOkAction {
  return {
    type: EntityDataConsts.CHANGE_STATE_ENTITY_OK,
  };
}

export function changeStateKo(error: string): EntityChangeStateKoAction {
  return {
    type: EntityDataConsts.CHANGE_STATE_ENTITY_KO,
    error,
  };
}

export function entitySetData(key: string, value: string | number): EntitySetDataAction {
  return {
    type: EntityDataConsts.ENTITY_SET_DATA,
    key,
    value,
  };
}

export function entityContactSetData(co_key: string, co_value: string | number): EntityContactSetDataAction {
  return {
    type: EntityDataConsts.ENTITY_CONTACT_SET_DATA,
    co_key,
    co_value,
  };
}

// CHANGE RESPONSIBLE

export function changeResponsible(id: number): ChangeResponsibleAction {
  return {
    type: EntityDataConsts.ENTITY_CHANGE_RESPONSIBLE,
    id,
  };
}

export function changeResponsibleKo(error: string): ChangeResponsibleKoAction {
  return {
    type: EntityDataConsts.ENTITY_CHANGE_RESPONSIBLE_KO,
    error,
  };
}

// MAKE PROVIDER

export function makeProvider(id: number): MakeProvider {
  return {
    type: EntityDataConsts.MAKE_PROVIDER,
    id,
  };
}

export function makeProviderOk(entity: EntityInfo): MakeProviderOk {
  return {
    type: EntityDataConsts.MAKE_PROVIDER_OK,
    entity,
  };
}

export function makeProviderKo(error: string): MakeProviderKo {
  return {
    type: EntityDataConsts.MAKE_PROVIDER_KO,
    error,
  };
}

export function setMandatoyDocument(code: string, mandatory: boolean): SetMandatoryDocument {
  return {
    type: EntityDataConsts.SET_MANDATORY_DOCUMENT,
    code,
    mandatory,
  };
}

//NEW CONTRACT

export function createNewContract(newContract: EntityContract): CreateNewContractAction {
  return {
    type: EntityDataConsts.CREATE_NEW_CONTRACT,
    newContract,
  };
}

export function createNewContractOk(entity_contracts: EntityContract[]): CreateNewContractOkAction {
  return {
    type: EntityDataConsts.CREATE_NEW_CONTRACT_OK,
    entity_contracts,
  };
}

export function createNewContractKo(error: string): CreateNewContractKoAction {
  return {
    type: EntityDataConsts.CREATE_NEW_CONTRACT_KO,
    error,
  };
}

export function setContractData(key: string, value: string | number): SetContractDataAction {
  return {
    type: EntityDataConsts.SET_CONTRACT_DATA,
    key,
    value,
  };
}

export function addContract(entity_id: number): AddContractAction {
  return {
    type: EntityDataConsts.ADD_CONTRACT,
    entity_id,
  };
}

//GET CONTRACT

export function getContract(contractId: number): GetContractAction {
  return {
    type: EntityDataConsts.GET_CONTRACT,
    contractId,
  };
}

export function getContractOk(contract: EntityContract): GetContractOkAction {
  return {
    type: EntityDataConsts.GET_CONTRACT_OK,
    contract,
  };
}

export function getContractKo(error: string): GetContractKoAction {
  return {
    type: EntityDataConsts.GET_CONTRACT_KO,
    error,
  };
}

// EDIT CONTRACT

export function editContract(contractData: EntityContract): EditContractAction {
  return {
    type: EntityDataConsts.EDIT_CONTRACT,
    contractData,
  };
}

export function editContractOk(entity_contracts: EntityContract[]): EditContractOkAction {
  return {
    type: EntityDataConsts.EDIT_CONTRACT_OK,
    entity_contracts,
  };
}

export function editContractKo(error: string): EditContractKoAction {
  return {
    type: EntityDataConsts.EDIT_CONTRACT_KO,
    error,
  };
}

//DELETE CONTRACT

export function deleteContract(contractData: EntityContract): DeleteContractAction {
  return {
    type: EntityDataConsts.DELETE_CONTRACT,
    contractData,
  };
}

export function deleteContractOk(contractData: EntityContract): DeleteContractOkAction {
  return {
    type: EntityDataConsts.DELETE_CONTRACT_OK,
    contractData,
  };
}

export function deleteContractKo(error: string): DeleteContractKoAction {
  return {
    type: EntityDataConsts.DELETE_CONTRACT_KO,
    error,
  };
}

//RESET CONTRACT

export function resetContract(): ResetContractAction {
  return {
    type: EntityDataConsts.RESET_CONTRACT,
  };
}

// UPLOAD CONTRACT DOCUMENT

export function uploadContractDocument(file: File): UploadContractDocumentAction {
  return {
    type: EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT,
    file,
  };
}

export function uploadContractDocumentOk(fileData: FileItem): UploadContractDocumentOkAction {
  return {
    type: EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT_OK,
    fileData,
  };
}

export function uploadContractDocumentKo(error: string): UploadContractDocumentKoAction {
  return {
    type: EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT_KO,
    error,
  };
}
// REJECT ENTITIES
export function rejectInternalEntity(id: number, comment: string): RejectInternalEntityAction {
  return {
    type: EntityDataConsts.REJECT_INTERNAL_ENTITY,
    id,
    comment,
  };
}

export function rejectInternalEntityOk(entityData: EntityInfo): RejectInternalEntityOkAction {
  return {
    type: EntityDataConsts.REJECT_INTERNAL_ENTITY_OK,
    entityData,
  };
}

export function rejectInternalEntityKo(error: string): RejectInternalEntityKoAction {
  return {
    type: EntityDataConsts.REJECT_INTERNAL_ENTITY_KO,
    error,
  };
}

export function rejectExternalEntity(id: number, comment: string): RejectExternalEntityAction {
  return {
    type: EntityDataConsts.REJECT_EXTERNAL_ENTITY,
    id,
    comment,
  };
}

export function rejectExternalEntityOk(entityData: EntityInfo): RejectExternalEntityOkAction {
  return {
    type: EntityDataConsts.REJECT_EXTERNAL_ENTITY_OK,
    entityData,
  };
}

export function rejectExternalEntityKo(error: string): RejectExternalEntityKoAction {
  return {
    type: EntityDataConsts.REJECT_EXTERNAL_ENTITY_KO,
    error,
  };
}

// REMOVE CONTRACT DOCUMENT

export function removeContractDocument(file_id: number): RemoveContractDocumentAction {
  return {
    type: EntityDataConsts.REMOVE_CONTRACT_DOCUMENT,
    file_id,
  };
}

export function removeContractDocumentOk(): RemoveContractDocumentOkAction {
  return {
    type: EntityDataConsts.REMOVE_CONTRACT_DOCUMENT_OK,
  };
}

export function removeContractDocumentKo(error: string): RemoveContractDocumentKoAction {
  return {
    type: EntityDataConsts.REMOVE_CONTRACT_DOCUMENT_KO,
    error,
  };
}

export function rejectEntityDirector(id: number, comment: string): RejectEntityDirectorAction {
  return {
    type: EntityDataConsts.REJECT_ENTITY_DIRECTOR,
    id,
    comment,
  };
}

export function rejectEntityDirectorOk(entityData: EntityInfo): RejectEntityDirectorOkAction {
  return {
    type: EntityDataConsts.REJECT_ENTITY_DIRECTOR_OK,
    entityData,
  };
}

export function rejectEntityDirectorKo(error: string): RejectEntityDirectorKoAction {
  return {
    type: EntityDataConsts.REJECT_ENTITY_DIRECTOR_KO,
    error,
  };
}

export function approveEntityDirector(id: number): ApproveEntityDirectorAction {
  return {
    type: EntityDataConsts.APPROVE_ENTITY_DIRECTOR,
    id,
  };
}

export function approveEntityDirectorOk(entityData: EntityInfo): ApproveEntityDirectorOkAction {
  return {
    type: EntityDataConsts.APPROVE_ENTITY_DIRECTOR_OK,
    entityData,
  };
}

export function approveEntityDirectorKo(error: string): ApproveEntityDirectorKoAction {
  return {
    type: EntityDataConsts.APPROVE_ENTITY_DIRECTOR_KO,
    error,
  };
}

// DOWNLOAD CONTRACT DOCUMENT

export function downloadContractDocument(file_id: number, filename: string): DownloadContractDocumentAction {
  return {
    type: EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT,
    file_id,
    filename,
  };
}

export function downloadContractDocumentOk(data: Blob, name: string): DownloadContractDocumentOkAction {
  return {
    type: EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadContractDocumentKo(error: string): DownloadContractDocumentKoAction {
  return {
    type: EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT_KO,
    error,
  };
}

export function confirmEntity(id: number): ConfirmEntityAction {
  return {
    type: EntityDataConsts.CONFIRM_ENTITY,
    id,
  };
}

export function clearNavigation(): ClearNavigationAction {
  return {
    type: EntityDataConsts.CLEAR_NAVIGATION,
  };
}

export function confirmEntityOk(entityData: EntityInfo): ConfirmEntityOkAction {
  return {
    type: EntityDataConsts.CONFIRM_ENTITY_OK,
    entityData,
  };
}

export function confirmEntityKo(error: string): ConfirmEntityKoAction {
  return {
    type: EntityDataConsts.CONFIRM_ENTITY_KO,
    error,
  };
}

export function openContractModal(): OpenContractModalAction {
  return {
    type: EntityDataConsts.OPEN_CONTRACT_MODAL,
  };
}

export function closeContractModal(): CloseContractModalAction {
  return {
    type: EntityDataConsts.CLOSE_CONTRACT_MODAL,
  };
}
// GET ENTITY CONTACTS LIST

export function entityContactsList(entityId: number): EntityContactsListAction {
  return {
    type: EntityDataConsts.GET_ENTITY_CONTACTS,
    entityId,
  };
}

export function entityContactsListOk(entityContactsList: any): EntityContactsListOkAction {
  return {
    type: EntityDataConsts.GET_ENTITY_CONTACTS_OK,
    entityContactsList,
  };
}

export function entityContactsListKo(error: string): EntityContactsListKoAction {
  return {
    type: EntityDataConsts.GET_ENTITY_CONTACTS_KO,
    error,
  };
}
// GET ENTITY CONTACT

export function entityOneContact(contact_id: number, entity_id: number): EntityOneContactAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ONE_CONTACT,
    entity_id,
    contact_id,
  };
}

export function entityOneContactOk(contactData: EntityContactData): EntityOneContactOkAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ONE_CONTACT_OK,
    contactData,
  };
}

export function entityOneContactKo(error: string): EntityOneContactKoAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ONE_CONTACT_KO,
    error,
  };
}
// DELETE ENTITY CONTACT

export function removeContactEntity(entity_id: number, contact_id: number): RemoveContactEntityAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_CONTACT,
    entity_id,
    contact_id,
  };
}

export function removeContactEntityOk(contact_id: number): RemoveContactEntityOkAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_CONTACT_OK,
    contact_id,
  };
}

export function removeContactEntityKo(error: string): RemoveContactEntityKoAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_CONTACT_KO,
    error,
  };
}
//GET ENTITY ROLES

export function entityContactRoles(): EntityContactRolesAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ROLES,
  };
}

export function entityContactRolesOk(roleList: any): EntityContactRolesOkAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ROLES_OK,
    roleList,
  };
}

export function entityContactRolesKo(error: string): EntityContactRolesKoAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ROLES_KO,
    error,
  };
}
// CREATE ENTITY CONTACT

export function createEntityContact(contactData: any, entity_id: number): CreateEntityContactAction {
  return {
    type: EntityDataConsts.ADD_ENTITY_CONTACT,
    contactData,
    entity_id,
  };
}

export function createEntityContactOk(contactData: any): CreateEntityContactOkAction {
  return {
    type: EntityDataConsts.ADD_ENTITY_CONTACT_OK,
    contactData,
  };
}

export function createEntityContactKo(error: string): CreateEntityContactKoAction {
  return {
    type: EntityDataConsts.ADD_ENTITY_CONTACT_KO,
    error,
  };
}

// UPDATE ENTITY CONTACT

export function entityUpdateContact(contactInfo: any, entity_id: number): EntityUpdateContactAction {
  return {
    type: EntityDataConsts.UPDATE_ENTITY_CONTACT,
    contactInfo,
    entity_id,
  };
}

export function entityUpdateContactOk(contactData: any): EntityUpdateContactOkAction {
  return {
    type: EntityDataConsts.UPDATE_ENTITY_CONTACT_OK,
    contactData,
  };
}

export function entityUpdateContactKo(error: string): EntityUpdateContactKoAction {
  return {
    type: EntityDataConsts.UPDATE_ENTITY_CONTACT_KO,
    error,
  };
}

// DELETE CONTACT MODAL DATA
export function deleteContactData(): DeleteContactDataAction {
  return {
    type: EntityDataConsts.DELETE_CONTACT_DATA,
  };
}
//OPEN & CLOSE CONTACT MODAL
export function openContactModal(): OpenContactModalAction {
  return {
    type: EntityDataConsts.OPEN_CONTACT_MODAL,
  };
}

export function closeContactModal(): CloseContactModalAction {
  return {
    type: EntityDataConsts.CLOSE_CONTACT_MODAL,
  };
}

// DELETE IBAN MODAL DATA
export function deleteIbanData(): DeleteIbanDataAction {
  return {
    type: EntityDataConsts.DELETE_IBAN_DATA,
  };
}
//OPEN & CLOSE IBAN MODAL
export function openIbanModal(): OpenIbanModalAction {
  return {
    type: EntityDataConsts.OPEN_IBAN_MODAL,
  };
}

export function closeIbanModal(): CloseIbanModalAction {
  return {
    type: EntityDataConsts.CLOSE_IBAN_MODAL,
  };
}
// GET ENTITY IBANS
export function listEntityIbans(entityId: number): ListEntityIbansAction {
  return {
    type: EntityDataConsts.GET_ENTITY_IBAN,
    entityId,
  };
}

export function listEntityIbansOk(ibanList: EntityIbanData[]): ListEntityIbansOkAction {
  return {
    type: EntityDataConsts.GET_ENTITY_IBAN_OK,
    ibanList,
  };
}

export function listEntityIbansKo(error: string): ListEntityIbansKoAction {
  return {
    type: EntityDataConsts.GET_ENTITY_IBAN_KO,
    error,
  };
}
// GET IBAN CONTACT

export function entityOneIban(entity_id: number, iban_id: number): EntityOneIbanAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ONE_IBAN,
    entity_id,
    iban_id,
  };
}

export function entityOneIbanOk(ibanData: EntityIbanData): EntityOneIbanOkAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ONE_IBAN_OK,
    ibanData,
  };
}

export function entityOneIbanKo(error: string): EntityOneIbanKoAction {
  return {
    type: EntityDataConsts.GET_ENTITY_ONE_IBAN_KO,
    error,
  };
}

// DELETE IBAN CONTACT

export function removeIbanEntity(entity_id: number, iban_id: number): RemoveIbanEntityAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_IBAN,
    entity_id,
    iban_id,
  };
}

export function removeIbanEntityOk(iban_id: number): RemoveIbanEntityOkAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_IBAN_OK,
    iban_id,
  };
}

export function removeIbanEntityko(error: string): RemoveIbanEntityKoAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_IBAN_KO,
    error,
  };
}

// CREATE ENTITY IBAN

export function createEntityIban(ibanData: any, entity_id: number): CreateEntityIbantAction {
  return {
    type: EntityDataConsts.ADD_ENTITY_IBAN,
    ibanData,
    entity_id,
  };
}

export function createEntityIbanOk(ibanData: any): CreateEntityIbanOkAction {
  return {
    type: EntityDataConsts.ADD_ENTITY_IBAN_OK,
    ibanData,
  };
}

export function createEntityIbanKo(error: string): CreateEntityIbanKoAction {
  return {
    type: EntityDataConsts.ADD_ENTITY_IBAN_KO,
    error,
  };
}

//UPDATE ENTITY IBAN

export function entityUpdateIban(ibanInfo: any, entity_id: number): EntityUpdateIbanAction {
  return {
    type: EntityDataConsts.UPDATE_ENTITY_IBAN,
    ibanInfo,
    entity_id,
  };
}

export function entityUpdateIbanOk(ibanData: any): EntityUpdateIbanOkAction {
  return {
    type: EntityDataConsts.UPDATE_ENTITY_IBAN_OK,
    ibanData,
  };
}

export function entityUpdateIbanKo(error: string): EntityUpdateIbanKoAction {
  return {
    type: EntityDataConsts.UPDATE_ENTITY_IBAN_KO,
    error,
  };
}
//DOWNLOAD DOCUMENT IBAN
export function downloadIbanEntityDocument(file_id: number, filename: string): DownloadIbanDocumentAction {
  return {
    type: EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT,
    file_id,
    filename,
  };
}

export function downloadIbanEntityDocumentOk(data: Blob, name: string): DownloadIbanDocumentOkAction {
  return {
    type: EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadIbanEntityDocumentKo(error: string): DownloadIbanDocumentKoAction {
  return {
    type: EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT_KO,
    error,
  };
}

//UPLOAD IBAN DOCUMENT
export function uploadIbanEntityDocument(file: File): UploadIbanEntityDocumentAction {
  return {
    type: EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT,
    file,
  };
}

export function uploadIbanEntityDocumentOk(fileData: FileItem): UploadIbanEntityDocumentOkAction {
  return {
    type: EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT_OK,
    fileData,
  };
}

export function uploadIbanEntityDocumentKo(error: string): UploadIbanEntityDocumentKoAction {
  return {
    type: EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT_KO,
    error,
  };
}

//REMOVE IBAN DOCUMENT

export function removeIbanEntityDocument(file_id: number): RemoveIbanEntityDocumentAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT,
    file_id,
  };
}

export function removeIbanEntityDocumentOk(): RemoveIbanEntityDocumentOkAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT_OK,
  };
}

export function removeIbanEntityDocumentKo(error: string): RemoveIbanEntityDocumentKoAction {
  return {
    type: EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT_KO,
    error,
  };
}

//SET IBAN DATA
export function entitySetIbanData(s_key: string, s_value: string | number): EntitySetIbanDataAction {
  return {
    type: EntityDataConsts.ENTITY_SET_IBAN_DATA,
    s_key,
    s_value,
  };
}
