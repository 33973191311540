import { Action } from 'redux';
import { SignatureDocumentDataConsts } from './action_types';
import { UUID } from '../../types/Shared/standard';

export type SignatureDocumentType = 'scholarships' | 'entity_agreements';

export interface SignatureDocument {
  id: string;
  signature_id: UUID | null;
  file_id: number;
  filename: string;
  status: string;
  updated_at: string;
}

export const initialState = {
  loading: false as boolean,
  signatureDocuments: [] as SignatureDocument[],
  infoMessage: null as string | null,
  error: null as string | null,
  sending: null as string | null,
};

export type SignatureDocumentState = typeof initialState;

export interface GetSignatureDocumentsAction extends Action {
  type: SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET;
  source_type: SignatureDocumentType;
  type_id: number;
}

export interface GetSignatureDocumentsOkAction extends Action {
  type: SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET_OK;
  signatureDocuments: SignatureDocument[];
}

export interface GetSignatureDocumentsKoAction extends Action {
  type: SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET_KO;
  error: string;
}

export interface UploadSignatureDocumentAction extends Action {
  type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT;
  uuid: UUID;
  type_id: number;
  document_type: SignatureDocumentType;
  file: File;
}
export interface UploadSignatureDocumentOkAction extends Action {
  type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_OK;
  document: SignatureDocument[];
}
export interface UploadSignatureDocumentKoAction extends Action {
  type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_KO;
  error: string;
}

export interface RemoveSignatureDocumentAction extends Action {
  type: SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT;
  id: string;
}
export interface RemoveSignatureDocumentOkAction extends Action {
  type: SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT_OK;
  id: string;
}
export interface RemoveSignatureDocumentKoAction extends Action {
  type: SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT_KO;
  error: string;
}

export interface UploadSignatureDocumentOkAction extends Action {
  type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_OK;
  document: SignatureDocument[];
}

export interface UploadSignatureDocumentKoAction extends Action {
  type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_KO;
  error: string;
}

export interface DownloadSignatureDocumentAction extends Action {
  type: SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT;
  file_id: number;
  name: string;
}
export interface DownloadSignatureDocumentOkAction extends Action {
  type: SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT_OK;
  data: Blob;
  name: string;
}
export interface DownloadSignatureDocumentKoAction extends Action {
  type: SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT_KO;
  error: string;
}

export interface SendSignatureDocumentAction extends Action {
  type: SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT;
  id: UUID;
}
export interface SendSignatureDocumentOkAction extends Action {
  type: SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT_OK;
  id: UUID;
  signature_id: UUID;
}
export interface SendSignatureDocumentKoAction extends Action {
  type: SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT_KO;
  error: string;
}

export interface CancelSignatureDocumentAction extends Action {
  type: SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT;
  id: UUID;
}
export interface CancelSignatureDocumentOkAction extends Action {
  type: SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT_OK;
  id: UUID;
  signature_id: UUID;
}
export interface CancelSignatureDocumentKoAction extends Action {
  type: SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT_KO;
  error: string;
}
export type SignatureDocumentsDataAction =
  | GetSignatureDocumentsAction
  | GetSignatureDocumentsOkAction
  | GetSignatureDocumentsKoAction
  | UploadSignatureDocumentAction
  | UploadSignatureDocumentOkAction
  | UploadSignatureDocumentKoAction
  | RemoveSignatureDocumentAction
  | RemoveSignatureDocumentOkAction
  | RemoveSignatureDocumentKoAction
  | DownloadSignatureDocumentAction
  | DownloadSignatureDocumentOkAction
  | DownloadSignatureDocumentKoAction
  | SendSignatureDocumentAction
  | SendSignatureDocumentOkAction
  | SendSignatureDocumentKoAction
  | CancelSignatureDocumentAction
  | CancelSignatureDocumentOkAction
  | CancelSignatureDocumentKoAction;
