import { ButtonProps, Grid, InputAdornment, MenuItem } from '@material-ui/core';
import moment from 'moment';
import { default as React, FocusEvent, Fragment, Suspense, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { RadioOptions } from '../../../components/RadioButtons/RadioButtonOptions';
import RadioButtons from '../../../components/RadioButtons/RadioButtons';
import SelectController from '../../../components/Select/SelectController';
import { DATE_FORMAT } from '../../../constants/date';
import { permissionType } from '../../../constants/permissions_definitions';
import { GenericObj } from '../../../helpers/customHooks/useButtons';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey, tProjectKey } from '../../../helpers/translate';
import {
  acceptInvoice,
  authorizeInvoice,
  changeState,
  closeModal,
  confirmInvoice,
  editInvoiceData,
  getInvoiceData,
  invoiceSetData,
  moveBackInvoice,
  sendInvoice,
  toPayInvoice,
} from '../../../redux/accounting/actions';
import { InvoiceData, InvoiceStates, stateActions } from '../../../redux/accounting/definitions';
import {
  selectInvoice,
  selectInvoicesCalculatedTotal,
  selectInvoicesDetailBaseTotal,
  selectInvoicesDetailRetentionTotal,
  selectInvoicesDetailTotal,
  selectInvoicesRetentionTotal,
  selectLoading,
  selectValidationErrors,
} from '../../../redux/accounting/selectors';
import { TaxRetentionCodes } from '../../../redux/entity/definitions';
import { getAccountingMetadata } from '../../../redux/metadata/actions';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import {
  selectAccountingMetadataLoaded,
  selectGenericMetadata,
  selectPaymentControlDay,
  selectPaymentDay,
  selectPaymentTypeCodes,
} from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { InvoiceFncSchema, InvoiceSchema } from '../../../validations/formSchema';
import RefuseEntityModal from '../../Entities/RefuseEntityModal';
import styles from './invoiceForm.module.scss';
import { CodeNamePair } from '../../../redux/common/definitions';
import UploadFileInvoice from './UploadFileInvoice';
import InvoiceModificationModal from './InvoiceModificationModal';
import InvoiceTableDetails from './InvoiceTableDetails';

const TrackingModal = React.lazy(() => import('../../../components/Modal/TrackingModal/TrackingModal'));
interface InvoiceFormProps {
  invoice_id: number;
  loading: boolean;
  invoiceData: InvoiceData;
  validationErrors: APIValidationError | null;
  genericMetadata: GenericMetadata;
  accountingMetadataLoaded: boolean;
  paymentTypeCodesMetadata: CodeNamePair[];
  paymentControlDay: number;
  paymentDay: number;
  hidden?: boolean;
  otherHidden?: boolean;
  invoiceDetailBaseTotal: number;
  invoicesDetailRetentionTotal: number;
  invoicesDetailTotal: number;
  selectInvoicesRetentionTotal: number;
  selectInvoicesCalculatedTotal: number;
  error: string | null;
  modalView: boolean;
  setButtonProperty: (payload: GenericObj) => void;
  setData: (key: string, value: string | number) => void;
  editInvoiceData: (invoice: InvoiceData) => void;
  getAccountingMetadata: () => void;
  sendInvoice: (invoice: InvoiceData) => void;
  acceptInvoice: (invoice: InvoiceData) => void;
  confirmInvoice: (invoice: InvoiceData) => void;
  authorizeInvoice: (invoice_id: number) => void;
  moveBackInvoice: (invoice_id: number) => void;
  getInvoiceData: (id: number) => void;
  userCan: (permission: permissionType) => boolean | undefined;
  changeState: (invoiceId: number, comments: string, state: string) => void;
  toPayInvoice: (id: number) => void;
  closeModal: () => void;
}

const modalObj = {
  view: false,
  title: '',
  children: <Fragment />,
};

const cleanButtons = {
  buttonOnSave: { hidden: true },
  buttonOnSend: { hidden: true },
  buttonOnAccept: { hidden: true },
  buttonOnReject: { hidden: true },
  buttonOnMoveBack: { hidden: true },
  buttonOnReview: { hidden: true },
  buttonOnConfirm: { hidden: true },
  buttonOnCreate: { hidden: true },
  buttonOnPay: { hidden: true },
  buttonOnTracking: { hidden: true },
  buttonAuthorize: { hidden: true },
};
const InvoiceForm: React.FC<InvoiceFormProps> = ({
  invoice_id,
  invoiceData,
  loading,
  validationErrors,
  genericMetadata,
  accountingMetadataLoaded,
  paymentTypeCodesMetadata,
  hidden,
  otherHidden,
  invoiceDetailBaseTotal,
  invoicesDetailRetentionTotal,
  invoicesDetailTotal,
  selectInvoicesRetentionTotal,
  selectInvoicesCalculatedTotal,
  error,
  paymentControlDay,
  modalView,
  setData,
  setButtonProperty,
  editInvoiceData,
  getAccountingMetadata,
  sendInvoice,
  changeState,
  acceptInvoice,
  confirmInvoice,
  getInvoiceData,
  userCan,
  toPayInvoice,
  closeModal,
  authorizeInvoice,
  moveBackInvoice,
}) => {
  const {
    invoice_number,
    reference_number,
    invoice_date,
    due_date,
    account_number,
    account_numbers,
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_area_code,
    center_cost_project_code,
    retention_code,
    retention_name,
    description,
    total_amount,
    supplier_code,
    entity_name,
    entity_contracts,
    contract_number,
    state_code,
    state_name,
    is_credit,
    payment_type_code,
  } = invoiceData;

  const numberDay = new Date().getDate();

  const stateCanMoveBack = [InvoiceStates.CONFIRMADA, InvoiceStates.APAGAR, InvoiceStates.AUTORIZADA].includes(
    state_code as InvoiceStates,
  );
  const userCanMoveBack = userCan('allowInvoiceChanges');
  const canMoveBack = stateCanMoveBack && userCanMoveBack;
  const canSend = userCan('allow_send_invoice');
  const canAccept = userCan('allow_accept_invoice');
  const canConfirm = userCan('allow_confirm_invoice');
  const canToPay = userCan('allow_to_pay_invoice');
  const canToSaveRes = userCan('action_responsible');
  const canAuthorize = userCan('allow_auth_invoice_send_to_sage');
  const isNouscims = userCan('action_nouscims');
  const canChangeInvoice = userCan('allowInvoiceChanges');
  const allowDirectorInvoiceChanges = userCan('allowDirectorInvoiceChanges');

  const { errors, setError, control, handleSubmit, clearError, triggerValidation, register, reset } = useForm<
    InvoiceData
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: isNouscims ? InvoiceFncSchema : InvoiceSchema,
    defaultValues: useMemo(() => {
      return invoiceData;
    }, [invoiceData]),
  });

  const [createdModal, setCreatedModal] = React.useState(modalObj);
  const [trackingModal, setTrackingModal] = React.useState(false);
  const [readOnly, setReadOnly] = React.useState(true);

  const disableValuesStates = [
    InvoiceStates.ACEPTADA,
    InvoiceStates.RECHAZADA,
    InvoiceStates.CONFIRMADA,
    InvoiceStates.APAGAR,
    InvoiceStates.AUTORIZADA,
    InvoiceStates.REVISAR,
    InvoiceStates.PENDIENTE_ENVIO_SAGE,
    InvoiceStates.PAGADA,
  ] as string[];

  const disableInvoice = disableValuesStates.includes(state_code);

  const radioOptions: RadioOptions[] = [
    { value: '0', label: tKey('Cargo') },
    { value: '1', label: tKey('Abono') },
  ];

  const buttonOnCreate: ButtonProps = {
    hidden: true,
  };
  const onCloseModal = () => {
    clearError();
    closeModal();
  };

  const handleSaveInvoice = () => {
    clearError();
    editInvoiceData(invoiceData);
  };

  const handleSendInvoice = () => {
    clearError();
    setButtonProperty({ ...cleanButtons, buttonOnCreate });
    sendInvoice(invoiceData);
  };

  const buttonOnSave: ButtonProps = {
    hidden: !(state_code === InvoiceStates.CREACION || (canAccept && state_code === InvoiceStates.PENDIENTE)),
    onClick: handleSaveInvoice,
  };

  const buttonOnTracking: ButtonProps = {
    hidden: false,
    onClick: () => {
      setTrackingModal(true);
    },
  };

  const buttonOnSend: ButtonProps = {
    hidden: !canSend,
    onClick: handleSubmit(handleSendInvoice),
  };

  const buttonOnAccept: ButtonProps = {
    hidden: !canAccept,
    onClick: () => {
      acceptInvoice(invoiceData);
      setButtonProperty({ ...cleanButtons, buttonOnCreate });
    },
  };

  const buttonOnConfirm: ButtonProps = {
    hidden: !canConfirm,
    onClick: () => {
      setButtonProperty({ ...cleanButtons, buttonOnCreate });
      confirmInvoice(invoiceData);
    },
  };

  const buttonOnPay: ButtonProps = {
    hidden: !canToPay,
    onClick: () => toPayInvoice(invoiceData.id),
  };

  const buttonAuthorize: ButtonProps = {
    hidden: !canAuthorize && invoiceData.state_code === InvoiceStates.APAGAR,
    onClick: () => authorizeInvoice(invoiceData.id),
  };

  const buttonOnMoveBack: ButtonProps = {
    hidden: !canMoveBack,
    onClick: () => moveBackInvoice(invoiceData.id),
  };

  const buttonOnReject: ButtonProps = {
    hidden: !(
      (canAccept && state_code === InvoiceStates.PENDIENTE) ||
      (canConfirm && state_code === InvoiceStates.ACEPTADA)
    ),
    onClick: () =>
      setCreatedModal({
        view: true,
        title: tKey('Motivo del rechazo'),
        children: (
          <RefuseEntityModal
            handleClose={() => setCreatedModal(modalObj)}
            handleSend={comments => {
              setButtonProperty({ ...cleanButtons, buttonOnCreate });
              changeState(invoiceData.id, comments || '', stateActions[invoiceData.state_code].prev);
            }}
          />
        ),
      }),
  };

  const canModifyInvoice =
    (canChangeInvoice || (allowDirectorInvoiceChanges && InvoiceStates.CONFIRMADA === state_code)) &&
    disableInvoice &&
    !invoiceData.closing_date;

  const buttonModify: ButtonProps = {
    hidden: !canModifyInvoice,
    onClick: () =>
      setCreatedModal({
        view: true,
        title: tKey('Modificar factura'),
        children: (
          <InvoiceModificationModal
            onSubmitOk={() => {
              setCreatedModal(modalObj);
            }}
            onCloseModal={() => {
              setCreatedModal(modalObj);
              onCloseModal();
            }}
          />
        ),
      }),
  };

  useEffect(() => {
    reset(invoiceData);
  }, [invoiceData, reset]);

  useEffect(() => {
    setButtonProperty({
      buttonOnCreate,
    });
    switch (state_code) {
      case InvoiceStates.CREACION:
      case InvoiceStates.RECHAZADA:
        if (!otherHidden) {
          setReadOnly(!canSend);
          setButtonProperty({
            buttonOnSave: {
              hidden: !canToSaveRes,
              onClick: handleSaveInvoice,
            },
            buttonOnSend,
            buttonOnCreate,
            buttonOnTracking,
            buttonOnMoveBack: { hidden: true },
            buttonModify,
          });
        }
        break;
      case InvoiceStates.PENDIENTE:
      case InvoiceStates.REVISAR:
        setReadOnly(!canAccept);
        setButtonProperty({
          buttonOnSave,
          buttonOnAccept,
          buttonOnReject,
          buttonOnCreate,
          buttonOnTracking,
          buttonModify,
        });
        break;
      case InvoiceStates.ACEPTADA:
        setReadOnly(true);
        if (!hidden) {
          setButtonProperty({
            buttonOnSave,
            buttonOnTracking,
            buttonOnConfirm,
            buttonOnReject,
            buttonOnCreate,
            buttonOnMoveBack: { hidden: true },
            buttonOnPay: { hidden: true },
            buttonModify,
          });
        }
        break;
      case InvoiceStates.CONFIRMADA:
        setReadOnly(true);
        if (!hidden) {
          setButtonProperty({
            buttonOnPay,
            buttonOnTracking,
            buttonOnCreate,
            buttonOnConfirm: { hidden: true },
            buttonOnReject,
            buttonOnMoveBack,
            buttonModify,
          });
        }
        break;
      case InvoiceStates.APAGAR:
        setReadOnly(true);
        setButtonProperty({
          buttonOnTracking,
          buttonAuthorize,
          buttonOnMoveBack,
          buttonOnSave: { hidden: true },
          buttonOnAccept: { hidden: true },
          buttonOnPay: { hidden: true },
          buttonOnCreate: { hidden: true },
          buttonOnConfirm: { hidden: true },
          buttonOnReject: { hidden: true },
          buttonModify,
        });
        break;
      case InvoiceStates.AUTORIZADA:
        setReadOnly(true);
        setButtonProperty({
          buttonOnTracking,
          buttonOnMoveBack,
          buttonAuthorize: { hidden: true },
          buttonOnSave: { hidden: true },
          buttonOnAccept: { hidden: true },
          buttonOnPay: { hidden: true },
          buttonOnCreate: { hidden: true },
          buttonOnConfirm: { hidden: true },
          buttonOnReject: { hidden: true },
          buttonModify,
        });
        break;
      case InvoiceStates.PENDIENTE_ENVIO_SAGE:
      case InvoiceStates.PAGADA:
        setReadOnly(true);
        setButtonProperty({
          buttonOnTracking,
          buttonOnSave: { hidden: true },
          buttonOnAccept: { hidden: true },
          buttonOnPay: { hidden: true },
          buttonOnCreate: { hidden: true },
          buttonOnConfirm: { hidden: true },
          buttonOnReject: { hidden: true },
          buttonOnMoveBack: { hidden: true },
          buttonModify,
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_code, invoiceData, setReadOnly, validationErrors, error]);

  useEffect(() => {
    return () => {
      setButtonProperty(cleanButtons);
      setData('state_code', '');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useValidatorAPI(validationErrors, setError);

  useEffect(() => {
    if (!accountingMetadataLoaded) {
      getAccountingMetadata();
    }
  }, [getAccountingMetadata, accountingMetadataLoaded]);

  useEffect(() => {
    if (invoice_id !== 0) {
      getInvoiceData(invoice_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice_id]);

  const [invoiceDetailsModal, setInvoiceDetailsModal] = React.useState({
    view: false,
    body: [''],
    children: <Fragment />,
    title: '',
  });

  useEffect(() => {
    if (!modalView) {
      setInvoiceDetailsModal(prevState => ({
        ...prevState,
        view: false,
      }));
    }
  }, [modalView]);

  if (loading) return <Loading big />;

  const changeDate = (name: string, date: moment.Moment | null) => {
    if (date) {
      setData(name, moment.utc(date || '0', DATE_FORMAT).format('X'));
    } else {
      setData(name, 0);
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const asingDateValue = (date: any) => {
    return date ? moment.unix(date) : null;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const { channels, delegations, areas, projects } = genericMetadata;

  let oneIbanAccount;
  let accountIbanList;
  let paymentTypeSelector;

  if (account_numbers && account_numbers.length > 1) {
    accountIbanList = (
      <SelectController
        size="50"
        control={control}
        errors={errors}
        schema={InvoiceSchema}
        ref={register}
        defaultValue={account_number}
        disabled={readOnly}
        name="account_number"
        label={tProjectKey('Número de cuenta')}
        onClick={e => handleSelectChange(e.target)}
      >
        {account_numbers?.map((iban: string, index: number) => (
          <MenuItem key={`${iban}_${index}`} value={iban}>
            {iban}
          </MenuItem>
        ))}
      </SelectController>
    );
  } else {
    oneIbanAccount = (
      <TextInputController
        errors={errors}
        size="50"
        label={tProjectKey('Número de cuenta')}
        name="account_number"
        control={control}
        schema={InvoiceSchema}
        defaultValue={account_number}
        disabled
      />
    );
  }

  if (isNouscims) {
    paymentTypeSelector = (
      <SelectController
        control={control}
        errors={errors}
        size="20"
        schema={InvoiceSchema}
        name="payment_type_code"
        disabled={readOnly}
        ref={register}
        defaultValue={payment_type_code}
        label={tFormKey('Tipo de efecto')}
        onClick={e => handleSelectChange(e.target)}
      >
        {paymentTypeCodesMetadata.map(type => (
          <MenuItem key={type.code} value={type.code}>
            {type.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  }
  return (
    <div className={styles.invoiceFormContainer}>
      <FormContainer
        title={`${tKey('Datos Factura')}: ${invoice_number || tKey('Número pendiente')}`}
        subtitle={
          paymentControlDay < numberDay && !due_date
            ? tKey('Factura entrada fuera de plazo. El pago se efectuará en el mes siguiente.')
            : undefined
        }
      >
        <Grid container justify="flex-start">
          <Grid item>
            <RadioButtons
              name="is_credit"
              questionText=""
              disabled={readOnly}
              options={radioOptions}
              defaultValue={is_credit ? is_credit.toString() : '0'}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('is_credit', e.target.value === '1' ? 1 : 0);
              }}
            />
          </Grid>
        </Grid>

        <TextInputController
          control={control}
          schema={InvoiceSchema}
          size="20"
          label={tFormKey('Número de factura')}
          name="reference_number"
          ref={register}
          defaultValue={reference_number}
          errors={errors}
          disabled={readOnly}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setData(e.target.name, e.target.value);
            triggerValidation('reference_number');
          }}
        />
        <KeyboardDatePickerController
          className={'inputs_size_20'}
          size="20"
          label={tFormKey('Fecha factura')}
          name="invoice_date"
          control={control}
          schema={InvoiceSchema}
          ref={register}
          defaultValue={asingDateValue(invoice_date)?.format(DATE_FORMAT)}
          errors={errors}
          disabled={readOnly}
          onAccept={date => {
            date && changeDate('invoice_date', date);
          }}
        />
        <KeyboardDatePickerController
          className={'inputs_size_20'}
          size="20"
          label={tFormKey('Fecha de vencimiento')}
          name="due_date"
          control={control}
          schema={InvoiceSchema}
          inputValue={asingDateValue(due_date)?.format('DD/MM/YYYY')}
          errors={errors}
          disabled
        />
        <TextInput size="20" defaultValue={state_name} name="state_name" label={tFormKey('Estado')} disabled />
        <TextInput
          size="20"
          defaultValue={supplier_code}
          name="supplier_code"
          label={tFormKey('Código proveedor')}
          disabled
        />
        <TextInput size="50" defaultValue={entity_name} name="entity_name" label={tFormKey('Razón social')} disabled />
        {readOnly ? (
          <TextInput
            size="20"
            defaultValue={contract_number}
            name="contract_number"
            label={tFormKey('Contrato')}
            disabled
          />
        ) : (
          <SelectController
            size="20"
            control={control}
            errors={errors}
            schema={InvoiceSchema}
            ref={register}
            defaultValue={contract_number}
            name="contract_number"
            label={tFormKey('Contrato')}
            disabled={readOnly}
            onClick={e => handleSelectChange(e.target)}
          >
            {entity_contracts?.map((v, i) => (
              <MenuItem key={`${v}_${i}`} value={v}>
                {v}
              </MenuItem>
            ))}
          </SelectController>
        )}
        {oneIbanAccount}
        {accountIbanList}
        {paymentTypeSelector}
        <TextInputController
          control={control}
          schema={InvoiceSchema}
          label={tFormKey('Descripción')}
          name="description"
          ref={register}
          defaultValue={description}
          errors={errors}
          multiline
          rows="5"
          rowsMax="5"
          size="100"
          disabled={readOnly}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setData(e.target.name, e.target.value);
            triggerValidation('description');
          }}
        />
        {(isNouscims || allowDirectorInvoiceChanges) && (
          <>
            <SelectController
              size="20"
              control={control}
              errors={errors}
              schema={InvoiceSchema}
              ref={register}
              defaultValue={center_cost_channel_code}
              name="center_cost_channel_code"
              label={tFormKey('Canal')}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {channels.map(type => (
                <MenuItem key={type.code} value={type.code}>
                  {type.code}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              size="20"
              control={control}
              errors={errors}
              schema={InvoiceSchema}
              ref={register}
              defaultValue={center_cost_delegation_code}
              name="center_cost_delegation_code"
              label={tFormKey('Delegación')}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {delegations
                ?.filter(e => e.channel_code === center_cost_channel_code || e.channel_code === null)
                .map(type => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name}
                  </MenuItem>
                ))}
            </SelectController>
            <SelectController
              size="20"
              control={control}
              errors={errors}
              schema={InvoiceSchema}
              ref={register}
              defaultValue={center_cost_area_code}
              name="center_cost_area_code"
              label={tFormKey('Área')}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {areas
                ?.filter(e => e.delegation_code === center_cost_delegation_code || e.delegation_code === null)
                .map(type => (
                  <MenuItem key={type.code || '0'} value={type.code}>
                    {type.name}
                  </MenuItem>
                ))}
            </SelectController>
            <SelectController
              size="20"
              control={control}
              errors={errors}
              schema={InvoiceSchema}
              ref={register}
              defaultValue={center_cost_project_code || '0'}
              name="center_cost_project_code"
              label={tFormKey('Proyecto')}
              disabled={readOnly}
              onClick={e => handleSelectChange(e.target)}
            >
              {projects
                ?.filter(e => e.area_code === center_cost_area_code || !e.area_code)
                .map(type => (
                  <MenuItem key={type.code || '0'} value={type.code || '0'}>
                    {type.name}
                  </MenuItem>
                ))}
            </SelectController>
          </>
        )}
        <TextInputController
          size="20"
          type="number"
          control={control}
          schema={InvoiceSchema}
          label={tFormKey('Total a pagar')}
          name="total_amount"
          ref={register}
          defaultValue={total_amount}
          errors={errors}
          disabled={readOnly || disableInvoice}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            setData(e.target.name, e.target.value);
            triggerValidation('total_amount');
          }}
        />
        <TextInput
          type="number"
          size="20"
          value={invoiceDetailBaseTotal}
          name="invoiceDetailBaseTotal"
          label={tFormKey('Total base')}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          disabled
        />
        <TextInput
          type="number"
          size="20"
          value={invoicesDetailRetentionTotal}
          name="invoicesDetailRetentionTotal"
          label={tFormKey('Total impuestos')}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          disabled
        />
        <TextInput
          type="number"
          size="20"
          value={invoicesDetailTotal}
          name="invoicesDetailTotal"
          label={tFormKey('Total detalle')}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          disabled
        />
        <Grid container justify="flex-end" spacing={3}>
          {retention_code && retention_code !== TaxRetentionCodes.SIN_RETENCION ? (
            <Fragment>
              <Grid item xs={3}>
                <TextInput
                  size="100"
                  defaultValue={retention_name}
                  name="retention_name"
                  label={tFormKey('Tipo de retención')}
                  hidden={!retention_code}
                  disabled
                />
              </Grid>
              <Grid item xs={3}>
                <TextInput
                  type="number"
                  size="100"
                  value={selectInvoicesRetentionTotal}
                  name="selectInvoicesRetentionTotal"
                  label={tFormKey('Total Retención')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                  disabled
                />
              </Grid>
            </Fragment>
          ) : null}
          <Grid item xs={3}>
            <TextInput
              type="number"
              size="100"
              value={selectInvoicesCalculatedTotal}
              name="selectInvoicesRetentionTotal"
              label={tFormKey('Total Factura')}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              disabled
            />
          </Grid>
        </Grid>
        <div className={styles.invoiceFormContainer}>
          <UploadFileInvoice invoice_id={invoiceData.id} readOnly={readOnly} errors={errors} />
        </div>
        <div className={styles.invoiceDetailsContainer}>
          <InvoiceTableDetails
            canModifyInvoice={!!canModifyInvoice}
            readOnly={readOnly}
            disableInvoice={disableInvoice}
          />
        </div>
      </FormContainer>
      <TransitionModal
        view={invoiceDetailsModal.view}
        handleClose={onCloseModal}
        bodyModal=" "
        title={tKey('Nueva línea')}
      >
        {invoiceDetailsModal.children}
      </TransitionModal>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => setCreatedModal(modalObj)}
        title={createdModal.title}
      >
        {createdModal.children}
      </TransitionModal>

      <Suspense fallback={<></>}>
        <TrackingModal
          view={trackingModal}
          resource_id={invoiceData.id}
          resource_type={'Invoice'}
          title={tKey('Facturas de servicios').concat(' ', invoiceData.reference_number || '')}
          handleClose={() => setTrackingModal(false)}
        />
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  invoiceData: selectInvoice(state),
  validationErrors: selectValidationErrors(state),
  error: state.accountingReducer.error,
  genericMetadata: selectGenericMetadata(state),
  accountingMetadataLoaded: selectAccountingMetadataLoaded(state),
  paymentTypeCodesMetadata: selectPaymentTypeCodes(state),
  paymentControlDay: selectPaymentControlDay(state),
  paymentDay: selectPaymentDay(state),
  userCan: selectUserCan(state),
  invoiceDetailBaseTotal: selectInvoicesDetailBaseTotal(state),
  invoicesDetailRetentionTotal: selectInvoicesDetailRetentionTotal(state),
  invoicesDetailTotal: selectInvoicesDetailTotal(state),
  selectInvoicesRetentionTotal: selectInvoicesRetentionTotal(state),
  selectInvoicesCalculatedTotal: selectInvoicesCalculatedTotal(state),
  modalView: state.accountingReducer.modalView,
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(invoiceSetData(key, value)),
  editInvoiceData: (invoice: InvoiceData) => dispatch(editInvoiceData(invoice)),
  getAccountingMetadata: () => dispatch(getAccountingMetadata()),
  sendInvoice: (invoice: InvoiceData) => dispatch(sendInvoice(invoice)),
  acceptInvoice: (invoice: InvoiceData) => dispatch(acceptInvoice(invoice)),
  authorizeInvoice: (invoice_id: number) => dispatch(authorizeInvoice(invoice_id)),
  moveBackInvoice: (invoice_id: number) => dispatch(moveBackInvoice(invoice_id)),
  confirmInvoice: (invoice: InvoiceData) => dispatch(confirmInvoice(invoice)),
  getInvoiceData: (id: number) => dispatch(getInvoiceData(id)),
  toPayInvoice: (id: number) => dispatch(toPayInvoice(id)),
  changeState: (invoiceId: number, comments: string, state: string) =>
    dispatch(changeState(invoiceId, comments, state)),
  closeModal: () => dispatch(closeModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForm);
