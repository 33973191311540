import { Chip, SvgIcon } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React from 'react';
import { ReactComponent as excelIcon } from '../../images/icons/excel.svg';
import { ReactComponent as pdfIcon } from '../../images/icons/pdf.svg';
import { ReactComponent as wordIcon } from '../../images/icons/word.svg';
import styles from './downloadFile.module.scss';

interface DownloadFileButtonProps {
  file_id: number | null;
  filename: string | null;
  readOnly?: boolean;
  downloadFile: (file_id: number, filename: string) => void;
}

const DownloadFile: React.FC<DownloadFileButtonProps> = ({
  file_id,
  filename,
  readOnly,

  downloadFile,
}) => {
  const colorize = (name: string) => {
    const res = name.substr(name.lastIndexOf('.') + 1);

    switch (res) {
      case 'pdf':
        return <SvgIcon component={pdfIcon} viewBox="0 0 600 476.6" />;
      case 'docx':
        return <SvgIcon component={wordIcon} viewBox="0 0 600 476.6" />;
      case 'xlsx':
        return <SvgIcon component={excelIcon} viewBox="0 0 600 476.6" />;
      default:
        return <ImageIcon />;
    }
  };

  return filename && file_id ? (
    <Chip
      icon={colorize(filename)}
      size="medium"
      label={filename}
      clickable
      onClick={() => downloadFile(file_id, filename)}
      className={styles.chip}
      disabled={readOnly}
    />
  ) : null;
};

export default DownloadFile;
