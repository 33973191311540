import React from 'react';
import { connect } from 'react-redux';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';

interface Props {
  selected: string;
  submenu: string;
  allowAcademyCenter: boolean;
}

const SubmenuEntities: React.FC<Props> = ({ selected, submenu, allowAcademyCenter }) => {
  const optionsSubmenu: SubmenuItem[] = [
    {
      title: tKey('Entidades'),
      route: `/entidades`,
      type: 'route',
      selected: 'entidades' === selected,
    },
  ];

  if (allowAcademyCenter) {
    optionsSubmenu.push({
      title: tKey('Centros Educativos'),
      route: '/centros-academicos',
      type: 'route',
      selected: selected === 'centros-educativos',
    });
  }

  return (
    <Submenu
      titleMenu={tKey('Entidades y Centros educativos')}
      optionsMenu={optionsSubmenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
    />
  );
};

export default connect((state: AppState) => ({
  allowAcademyCenter: selectUserCan(state)('allow_list_academies'),
}))(SubmenuEntities);
