import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import useButtons from '../../../helpers/customHooks/useButtons';
import { InvoiceDetail, InvoiceListItem } from '../../../redux/accounting/definitions';
import { buttonsInvoice } from '../../Entities/projectForm/Invoices/invoiceButtons';
import AccountingSubmenu from '../AccountingSubmenu';
import InvoiceForm from './InvoiceForm';

interface InvoiceConfigProps extends RouteComponentProps<{ id: string }> {
  invoice: InvoiceListItem;
  invoiceDetails: InvoiceDetail;
}

const InvoiceConfig: React.FC<InvoiceConfigProps> = ({ match }) => {
  const { stateButtons, setButtonProperty } = useButtons(buttonsInvoice);

  const invoice_id = parseInt(match.params.id);

  const actionButtons: ButtonProps[] = [
    stateButtons.buttonOnSave,
    stateButtons.buttonModify,
    stateButtons.buttonOnAccept,
    stateButtons.buttonOnConfirm,
    stateButtons.buttonOnPay,
    stateButtons.buttonOnReject,
    stateButtons.buttonOnMoveBack,
    stateButtons.buttonOnTracking,
    stateButtons.buttonAuthorize,
  ];
  useEffect(() => {
    setButtonProperty({
      buttonOnPay: { hidden: true },
      buttonOnCreate: { hidden: true },
      buttonOnConfirm: { hidden: true },
      buttonOnReject: { hidden: true },
      buttonOnMoveBack: { hidden: true },
      buttonOnTracking: { hidden: true },
      buttonAuthorize: { hidden: true },
      buttonModify: { hidden: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={actionButtons} />}
      leftSubmenu={<AccountingSubmenu selected="invoices" />}
    >
      <InvoiceForm invoice_id={invoice_id} setButtonProperty={setButtonProperty} otherHidden />
    </LayoutForm>
  );
};

export default InvoiceConfig;
