import { AppState } from './redux/root-reducer';

let store: any;

export default {
  init(configureStore: any) {
    store = configureStore;
  },
  getStore() {
    return store;
  },
  getState(): AppState {
    return store && store.getState();
  },
};
