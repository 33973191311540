import React from 'react';
import { ActionsDocument, DocumentationItem } from '../../redux/common/definitions';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import FilesListDesktop from './FilesListDesktop';
import FilesListMobile from './FilesListMobile';

interface Props {
  documents: DocumentationItem[];
  actions: ActionsDocument[];
  disabled: boolean;
  addRow: boolean;
  multiDocument: boolean;
  required: boolean;
  requiredMultiDocument: boolean;
  addMoreDocuments: boolean;
  onUpload: (fileFormat: string, file: File, rowData: DocumentationItem) => void;
  onOpenModal: (rowData: DocumentationItem) => void;
  onDownload: (rowData: DocumentationItem) => void;
  onRemoveFile: (rowData: DocumentationItem) => void;
  onGetFile: (rowData: DocumentationItem) => void;
  setShowModal: (open: boolean) => void;
}

const FilesList = (props: Props) => {
  const isMobile = useWindowSize().IsMobile;

  return isMobile ? <FilesListMobile {...props} /> : <FilesListDesktop {...props} />;
};
export default FilesList;
