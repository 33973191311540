import assertUnreachable from '../../helpers/assertUnreachable';
import { TrackingConsts } from './action_types';
import { initialState, TrackingAction, TrackingState } from './definitions';

const trackingReducer = (state = initialState, action: TrackingAction): TrackingState => {
  switch (action.type) {
    case TrackingConsts.RESOURCE_TRACKING_LIST:
      return {
        ...state,
        loading: true,
      };
    case TrackingConsts.RESOURCE_TRACKING_LIST_OK:
      return {
        ...state,
        resourceTracking: action.resourceTracking,
        loading: false,
      };
    case TrackingConsts.RESOURCE_TRACKING_LIST_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
  }
  assertUnreachable(action);
  return state;
};

export default trackingReducer;
