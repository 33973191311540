import { ButtonProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { duplicateInternalProjectTemplateOfArea } from '../../../../api/internalProjectTemplates';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TableLayout from '../../../../components/Layout/TableLayout';
import CanaryTable, { ColumnTableProps } from '../../../../components/Tables/TableEditableRow/TableEditableRow';
import Title from '../../../../components/Title/Title';
import { tErrorKey, tKey, tProjectKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import {
  deleteInternalProjectTemplate,
  getInternalProjectTemplatesInfo,
  setInternalProjectTemplate,
} from '../../../../redux/internal-project-templates/actions';
import {
  InternalProjectTemplate,
  InternalProjectTemplateDispatch,
} from '../../../../redux/internal-project-templates/definitions';
import { selectIPTState } from '../../../../redux/internal-project-templates/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import SubmenuInternalProjects from '../SubmenuInternalProjects';
import TransitionModal from './../../../../components/Modal/TransitionModal';
import CreateInternalProjectTemplate from './CreateInternalProjectTemplate';
import styles from './internalProjectTemplates.module.scss';

interface InternalProjectTemplatesProps extends RouteComponentProps {
  loading: boolean;
  internalProjecTemplates: InternalProjectTemplate[];
  canIconfigInternalProjects: boolean;
  getInternalProjectTemplates: () => void;
  deleteInternalProjectTemplate: (id: number) => void;
  setInternalProjectTemplate: (data: InternalProjectTemplate[]) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const InternalProjectTemplates: React.FC<InternalProjectTemplatesProps> = ({
  match: { path },
  loading,
  internalProjecTemplates,
  canIconfigInternalProjects,
  getInternalProjectTemplates,
  deleteInternalProjectTemplate,
  setInternalProjectTemplate,
  showSnackbar,
}) => {
  const history = useHistory();
  const selectedSection = path.substring(path.lastIndexOf('/') + 1);
  const [showCreateTemplate, setShowCreateTemplate] = useState<boolean>(false);
  const [duplicateTemplateModal, setDuplicateTemplateModal] = useState<{
    show: boolean;
    data: InternalProjectTemplate | null;
  }>({ show: false, data: null });

  const DuplicateTemplateModalSchema = yup.object().shape({
    name: yup.string().required(tErrorKey('Este campo es obligatorio')),
  });

  const { errors, control, handleSubmit } = useForm<{ name: string }>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: DuplicateTemplateModalSchema,
  });

  useEffect(() => {
    if (internalProjecTemplates.length === 0) getInternalProjectTemplates();
  }, [getInternalProjectTemplates, internalProjecTemplates]);

  const columns = [
    { title: tKey('Plantilla'), name: 'template_name', editable: 'never', align: 'left' },
    { title: tKey('Área'), name: 'area_name', editable: 'never', align: 'left' },
    { title: tKey('Subárea'), name: 'subarea_name', editable: 'never', align: 'left' },
  ] as ColumnTableProps[];

  const buttonsTable: ButtonProps[] = [
    {
      onClick: () => setShowCreateTemplate(true),
      children: tKey('Nueva'),
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
    },
  ];

  const handleDuplicate = async (data: { name: string }) => {
    if (duplicateTemplateModal.data) {
      try {
        const res = await duplicateInternalProjectTemplateOfArea(data.name, duplicateTemplateModal.data.id);
        setDuplicateTemplateModal({ show: false, data: null });
        setInternalProjectTemplate(res);
      } catch (error) {
        const errorMsg = (error as Error).message;
        if (errorMsg.toLowerCase().includes('ya existe'))
          showSnackbar(tProjectKey('Ya existe una plantilla con el mismo nombre'), 'warning', undefined, 4000);
        else showSnackbar(errorMsg, 'error', undefined, 1500);
      }
    }
  };

  return (
    <TableLayout
      leftSubmenu={
        <SubmenuInternalProjects
          canConfigure={canIconfigInternalProjects}
          selected={selectedSection}
          submenu={selectedSection}
        />
      }
      rightSubmenu={<ActionsMenu goBack onGoBack={() => history.push('/configuracion/proyectos-internos')} />}
    >
      <div className={styles.container}>
        <Title>{tKey('Plantillas proyectos internos')}</Title>
        <CanaryTable
          isLoading={loading}
          title={tKey('Plantillas')}
          buttons={buttonsTable}
          columns={columns}
          rows={internalProjecTemplates}
          setRows={() => undefined}
          option={{ filter: true }}
          handleEditClick={(row: InternalProjectTemplate) => {
            history.push(`/configuracion/proyectos-internos/plantilla/editar/${row.id}`);
          }}
          handleDeleteClick={(row: InternalProjectTemplate) => {
            deleteInternalProjectTemplate(row.id);
          }}
          handleDuplicateClick={(row: InternalProjectTemplate) => {
            setDuplicateTemplateModal({ show: true, data: row });
          }}
        />
        {showCreateTemplate && <CreateInternalProjectTemplate handleClose={() => setShowCreateTemplate(false)} />}
        {duplicateTemplateModal.show && (
          <TransitionModal
            view={duplicateTemplateModal.show}
            handleClose={() => setDuplicateTemplateModal({ show: false, data: null })}
            title={`${tKey('Duplicar plantilla')} - ${duplicateTemplateModal.data?.template_name}`}
            buttonOk={tKey('Duplicar')}
            buttonKo={tKey('Cancelar')}
            handleYes={handleSubmit(handleDuplicate)}
            handleNo={() => setDuplicateTemplateModal({ show: false, data: null })}
          >
            {
              <FormContainer title="" lowMargin={true}>
                <TextInputController
                  name="name"
                  label={tKey('Nombre de la plantilla')}
                  size="100"
                  control={control}
                  errors={errors}
                  schema={DuplicateTemplateModalSchema}
                />
              </FormContainer>
            }
          </TransitionModal>
        )}
      </div>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIPTState(state).loading,
  internalProjecTemplates: selectIPTState(state).internalProjectTemplates,
  canIconfigInternalProjects: selectUserCan(state)('route_internal_projects'),
});

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  getInternalProjectTemplates: (): void => dispatch(getInternalProjectTemplatesInfo()),
  deleteInternalProjectTemplate: (id: number): void => dispatch(deleteInternalProjectTemplate(id)),
  setInternalProjectTemplate: (data: InternalProjectTemplate[]): void => dispatch(setInternalProjectTemplate(data)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectTemplates);
