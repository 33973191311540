import { DeleteFamilyIncomeConst } from './action_types';
import { DeleteFamilyIncomeDataAction, DeleteFamilyIncomeState } from './definitions.d';
import assertUnreachable from '../../../../helpers/assertUnreachable';
import { FamilyIncome, FamilyIncomesInitialState } from '../FamilyIncome.d';

const deleteFamilyIncomeReducer = (
  state = FamilyIncomesInitialState,
  action: DeleteFamilyIncomeDataAction,
): DeleteFamilyIncomeState => {
  switch (action.type) {
    case DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME_OK:
      const { income } = action;
      const listResumed = state.list.filter((item: FamilyIncome) => item.id !== income.id);
      return {
        ...state,
        list: listResumed,
        loading: false,
      };
    case DeleteFamilyIncomeConst.REMOVE_FAMILY_INCOME_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      assertUnreachable(action);
      return state;
  }
};

export default deleteFamilyIncomeReducer;
