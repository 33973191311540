import { Checkbox } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableLayout from '../../components/Layout/TableLayout';
import TableWithEdit from '../../components/Tables/TableWithEdit';
import * as DateConstants from '../../constants/date';
import { tKey } from '../../helpers/translate';
import { getNotificationsUpdate, markNotificationsAsRead } from '../../redux/notification/actions';
import { Alert, Notification, NotificationDispatch, NotificationsRead } from '../../redux/notification/definitions';
import { selectIsLoading, selectNotificationsList, selectVersion } from '../../redux/notification/selectors';
import { AppState } from '../../redux/root-reducer';
import SubmenuNotifications from './SubmenuNotifications';

const emptyValue = tKey('No hay datos');
const showDate = (date: number) => <div>{moment.unix(date).format(DateConstants.DATE_FORMAT)}</div>;
const showTime = (date: number) => <div>{moment.unix(date).format(DateConstants.TIME_FORMAT)}</div>;

interface AlertsProps {
  loading: boolean;
  notifications: Notification;
  markNotificationsAsRead: (notifications: NotificationsRead) => void;
  version: number;
  getNotificationsUpdate: () => void;
}
const Alerts: React.FC<AlertsProps> = ({
  loading,
  notifications,
  markNotificationsAsRead,
  version,
  getNotificationsUpdate,
}) => {
  const [tableData, setTableData] = useState<MaterialTableProps<Alert>>({
    title: tKey('Lista de alertas'),
    data: [],
    columns: [
      {
        title: tKey('Título'),
        field: 'title',
        emptyValue,
        filtering: false,
      },
      {
        title: tKey('Descripción'),
        field: 'description',
        emptyValue,
        filtering: false,
      },
      {
        title: tKey('Leída'),
        field: 'read',
        lookup: {
          '1': tKey('Leída'),
          '0': tKey('No leída'),
        },
        render: (rowData: Alert) => (
          <Checkbox
            checked={!!rowData.read}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              markNotificationsAsRead({
                type: 'alerts',
                notifications: [{ id: rowData.id, read: e.target.checked }],
              })
            }
          />
        ),
        emptyValue,
      },
      {
        title: tKey('Fecha'),
        field: 'date',
        render: (rowData: Alert) => showDate(rowData.date),
        emptyValue,
        filtering: false,
      },
      {
        title: tKey('Hora'),
        field: 'date',
        render: (rowData: Alert) => showTime(rowData.date),
        emptyValue,
        filtering: false,
      },
    ],
    options: {
      selection: true,
      pageSize: 20,
      pageSizeOptions: [20, 50, 100],
    },
  });
  const [versionState, setVersionStaste] = useState(0);

  const updateAlerts = (alertsBulk: Alert | Alert[], read: boolean) => {
    const alerts: NotificationsRead = { type: 'alerts', notifications: [] };
    if (Array.isArray(alertsBulk)) {
      alertsBulk.forEach(alertBulk => {
        alerts.notifications.push({ id: alertBulk.id, read });
      });
    }
    markNotificationsAsRead(alerts);
  };

  useEffect(() => {
    if (notifications) {
      setTableData({ ...tableData, title: tKey('Lista de alertas'), data: [...notifications.alerts] });
      setVersionStaste(version);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version, setTableData, setVersionStaste]);

  useEffect(() => {
    getNotificationsUpdate();
  }, [getNotificationsUpdate]);

  return (
    <TableLayout leftSubmenu={<SubmenuNotifications selected="alerts" />}>
      <Fragment>
        <TableWithEdit
          filtering
          // key is used to force reloads, when it changes the table gets reloaded
          key={JSON.stringify(versionState)}
          tableData={{ ...tableData, isLoading: loading }}
          markAsReadBulk={updateAlerts}
          markAsUnReadBulk={updateAlerts}
          rowStyle={(rowData: Alert) => ({
            backgroundColor: !rowData.read ? '#EEE' : undefined,
            fontWeight: !rowData.read ? 'bold' : undefined,
          })}
        />
      </Fragment>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  notifications: selectNotificationsList(state),
  version: selectVersion(state),
});

const mapDispatchToProps = (dispatch: NotificationDispatch) => ({
  markNotificationsAsRead: (notifications: NotificationsRead): void => dispatch(markNotificationsAsRead(notifications)),
  getNotificationsUpdate: (): void => dispatch(getNotificationsUpdate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
