import { paymentFiltersCodes, ScholarshipPayment } from '../../../../redux/scholarship/definitions';

export class ScholarshipPaymentsShowFilter {
  constructor(private scholarshipPaymentList: ScholarshipPayment[], private show: string) {}

  private getPaidScholarshipPaymentList(): ScholarshipPayment[] {
    return this.scholarshipPaymentList.filter((payment: ScholarshipPayment) => payment.payment_done_date !== null);
  }

  private getActiveScholarshipPaymentList(): ScholarshipPayment[] {
    return this.scholarshipPaymentList.filter((payment: ScholarshipPayment) => !payment.disabled);
  }

  private getPendingScholarshipPaymentList(): ScholarshipPayment[] {
    return this.scholarshipPaymentList.filter((payment: ScholarshipPayment) => payment.payment_done_date === null);
  }

  private getPendingScholarshipActivePaymentList(): ScholarshipPayment[] {
    return this.scholarshipPaymentList.filter(
      (payment: ScholarshipPayment) => payment.payment_done_date === null && !payment.disabled,
    );
  }

  getFiltered(): ScholarshipPayment[] {
    if (this.show === paymentFiltersCodes.PENDING_ACTIVES) {
      return this.getPendingScholarshipActivePaymentList();
    }
    if (this.show === paymentFiltersCodes.PENDING) {
      return this.getPendingScholarshipPaymentList();
    }
    if (this.show === paymentFiltersCodes.PAID) {
      return this.getPaidScholarshipPaymentList();
    }
    if (this.show === paymentFiltersCodes.ACTIVES) {
      return this.getActiveScholarshipPaymentList();
    }
    return this.scholarshipPaymentList;
  }
}
