import { ButtonProps, FormControl, InputLabel, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import { Column, MaterialTableProps } from 'material-table';
import React, { useEffect, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getCreatedScholarships, removeCreatedScholarships } from '../../../api/Scholarship/scholarship';
import FormContainer from '../../../components/Forms/FormContainer';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import Title from '../../../components/Title/Title';
import { getLastItem } from '../../../helpers/getLastItem';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { FeedbackAPIActionDispatch, SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { ScholarshipCreatedData, ScholarshipCreatedDataItem } from '../../../redux/scholarship/definitions';
import SubmenuConfiguration from '../SubmenuConfiguration';
import styles from './removeScholarships.module.scss';

interface InitialState {
  entityScholarships: ScholarshipCreatedData;
  instituteScholarships: ScholarshipCreatedData;
  loadingEntity: boolean;
  loadingInstitute: boolean;
  error: string | null;
  message: string | null;
}

const actions = {
  GET_SCHOLARSHIPS: 'GET_SCHOLARSHIPS',
  GET_SCHOLARSHIPS_ENT_OK: 'GET_SCHOLARSHIPS_ENT_OK',
  GET_SCHOLARSHIPS_INS_OK: 'GET_SCHOLARSHIPS_INS_OK',
  GET_SCHOLARSHIPS_ENT_KO: 'GET_SCHOLARSHIPS_ENT_KO',
  GET_SCHOLARSHIPS_INS_KO: 'GET_SCHOLARSHIPS_INS_KO',
  REMOVE_SCHOLARSHIPS_ENT: 'REMOVE_SCHOLARSHIPS_ENT',
  REMOVE_SCHOLARSHIPS_ENT_OK: 'REMOVE_SCHOLARSHIPS_ENT_OK',
  REMOVE_SCHOLARSHIPS_ENT_KO: 'REMOVE_SCHOLARSHIPS_ENT_KO',
  REMOVE_SCHOLARSHIPS_INS: 'REMOVE_SCHOLARSHIPS_INS',
  REMOVE_SCHOLARSHIPS_INS_OK: 'REMOVE_SCHOLARSHIPS_INS_OK',
  REMOVE_SCHOLARSHIPS_INS_KO: 'REMOVE_SCHOLARSHIPS_INS_KO',
};

const initialState: InitialState = {
  entityScholarships: {
    scholarships: [],
    count: 0,
  } as ScholarshipCreatedData,
  instituteScholarships: {
    scholarships: [],
    count: 0,
  } as ScholarshipCreatedData,
  loadingEntity: false,
  loadingInstitute: false,
  error: null,
  message: null,
};

const reducer = (state: InitialState, action: any) => {
  switch (action.type) {
    case actions.GET_SCHOLARSHIPS: {
      return {
        ...state,
        loadingEntity: true,
        loadingInstitute: true,
        error: null,
        message: null,
      };
    }
    case actions.REMOVE_SCHOLARSHIPS_ENT: {
      return {
        ...state,
        loadingEntity: true,
        error: null,
        message: null,
      };
    }
    case actions.REMOVE_SCHOLARSHIPS_INS: {
      return {
        ...state,
        loadingInstitute: true,
        error: null,
        message: null,
      };
    }
    case actions.GET_SCHOLARSHIPS_ENT_OK: {
      const entityScholarships = action.data;
      return {
        ...state,
        entityScholarships,
        loadingEntity: false,
      };
    }
    case actions.GET_SCHOLARSHIPS_INS_OK: {
      const instituteScholarships = action.data;
      return {
        ...state,
        instituteScholarships,
        loadingInstitute: false,
      };
    }
    case actions.REMOVE_SCHOLARSHIPS_ENT_OK: {
      const entityScholarships = {
        scholarships: [],
        count: 0,
      } as ScholarshipCreatedData;

      const message =
        action.data === 1
          ? `${tKey('Se ha eliminado 1 beca')}`
          : `${tKey('Se han eliminado')} ${action.data} ${'becas'}`;
      return {
        ...state,
        entityScholarships,
        message,
      };
    }
    case actions.REMOVE_SCHOLARSHIPS_INS_OK: {
      const instituteScholarships = {
        scholarships: [],
        count: 0,
      } as ScholarshipCreatedData;
      const message =
        action.data === 1
          ? `${tKey('Se ha eliminado 1 beca')}`
          : `${tKey('Se han eliminado ')}${action.data} ${tKey('becas')}`;
      return {
        ...state,
        instituteScholarships,
        message,
      };
    }
    case actions.REMOVE_SCHOLARSHIPS_ENT_KO:
    case actions.GET_SCHOLARSHIPS_ENT_KO: {
      const { error } = action;
      return {
        ...state,
        loadingEntity: false,
        error,
      };
    }
    case actions.REMOVE_SCHOLARSHIPS_INS_KO:
    case actions.GET_SCHOLARSHIPS_INS_KO: {
      const { error } = action;
      return {
        ...state,
        loadingInstitute: false,
        error,
      };
    }
    default:
      return state;
  }
};

function getEntityScholarshipCreated(academicYear: string, dispatch: any) {
  getCreatedScholarships(academicYear, true)
    .then(res => {
      const { data } = res;
      dispatch({
        type: actions.GET_SCHOLARSHIPS_ENT_OK,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_SCHOLARSHIPS_ENT_KO,
        error: tErrorKey('No se ha podido cargar becas'),
      });
    });
}

function getInstituteScholarshipCreated(academicYear: string, dispatch: any) {
  getCreatedScholarships(academicYear, false)
    .then(res => {
      const { data } = res;
      dispatch({
        type: actions.GET_SCHOLARSHIPS_INS_OK,
        data,
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_SCHOLARSHIPS_INS_KO,
        error: tErrorKey('No se ha podido cargar becas'),
      });
    });
}

function getScholarshipCreated(academicYear: string, dispatch: any) {
  dispatch({
    type: actions.GET_SCHOLARSHIPS,
  });
  getEntityScholarshipCreated(academicYear, dispatch);
  getInstituteScholarshipCreated(academicYear, dispatch);
}

function removeEntityScholarships(academicYear: string, scholarships: number[], dispatch: any) {
  dispatch({
    type: actions.REMOVE_SCHOLARSHIPS_ENT,
  });
  removeCreatedScholarships(academicYear, scholarships, true)
    .then(res => {
      const { data } = res;
      dispatch({
        type: actions.REMOVE_SCHOLARSHIPS_ENT_OK,
        data,
      });
      getEntityScholarshipCreated(academicYear, dispatch);
    })
    .catch(() => {
      dispatch({
        type: actions.REMOVE_SCHOLARSHIPS_ENT_KO,
        error: tErrorKey('No se ha podido cargar becas'),
      });
    });
}

function removeInstitueScholarships(academicYear: string, scholarships: number[], dispatch: any) {
  dispatch({
    type: actions.REMOVE_SCHOLARSHIPS_INS,
  });
  removeCreatedScholarships(academicYear, scholarships, false)
    .then(res => {
      const { data } = res;
      dispatch({
        type: actions.REMOVE_SCHOLARSHIPS_INS_OK,
        data,
      });
      getInstituteScholarshipCreated(academicYear, dispatch);
    })
    .catch(() => {
      dispatch({
        type: actions.REMOVE_SCHOLARSHIPS_INS_KO,
        error: tErrorKey('No se ha podido cargar becas'),
      });
    });
}

const tableColumns = (isEntity: boolean) => {
  const cols = [
    {
      title: tKey('Nombre'),
      field: 'student_name',
    },
    {
      title: tKey('Apellidos'),
      field: 'student_surname',
      render: (rowiData: ScholarshipCreatedDataItem) => (
        <div>{`${rowiData.student_surname} ${rowiData.student_surname2 || ''}`}</div>
      ),
      customFilterAndSearch: (term, rowiData: ScholarshipCreatedDataItem) =>
        (rowiData.student_surname + ' ' + rowiData.student_surname2).indexOf(term) !== -1,
    },
    {
      title: tKey('Correo electrónico'),
      field: 'student_email',
    },
    {
      title: tKey('Documento identidad'),
      field: 'student_document_number',
    },
  ] as Column<ScholarshipCreatedDataItem>[];
  if (isEntity) {
    cols.push({
      title: tKey('Entidad'),
      field: 'entity',
    });
  } else {
    cols.push({
      title: tKey('Academia'),
      field: 'academy',
    });
  }
  return cols;
};

const tableDataEnt: MaterialTableProps<ScholarshipCreatedDataItem> = {
  title: '',
  columns: tableColumns(true),
  data: [],
  options: {
    tableLayout: 'auto',
    selection: true,
    exportButton: false,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  },
};

const tableDataIns: MaterialTableProps<ScholarshipCreatedDataItem> = {
  title: '',
  columns: tableColumns(false),
  data: [],
  options: {
    tableLayout: 'auto',
    selection: true,
    exportButton: false,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  },
};

interface RemoveScholarshipsProps extends RouteComponentProps {
  academicYears: string[];
  showSnackbar: (message: string, severity: SeveritySnackbar, redirect?: string, autoHideDuration?: number) => void;
}

const RemoveScholarships: React.FC<RemoveScholarshipsProps> = ({ match, academicYears, showSnackbar }) => {
  const { path } = match;
  const history = useHistory();
  const [selectedYear, setSelectedYear] = useState<string>(academicYears[0]);
  const [selectedEntityScholarships, setSelectedEntityScholarships] = useState<number[]>([]);
  const [selectedInstituteScholarships, setSelectedInstituteScholarships] = useState<number[]>([]);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [confirmModal, setConfirmModal] = useState({
    view: false,
    isEntity: false,
    children: <span />,
    title: tKey('¡Atención!'),
  });

  useEffect(() => {
    getScholarshipCreated(selectedYear, dispatch);
  }, [selectedYear]);

  useEffect(() => {
    if (state.message !== null) showSnackbar(state.message ? state.message : '', 'success', undefined, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.message]);

  useEffect(() => {
    if (state.error !== null) showSnackbar(state.error ? state.error : '', 'error', undefined, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.error]);

  const buttons: ButtonProps[] = [
    {
      children: tKey('Eliminar becas entidad'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      disabled: selectedEntityScholarships.length === 0,
      onClick: () => setConfirmModal(prevState => ({ ...prevState, view: true, isEntity: true })),
    },
    {
      children: tKey('Eliminar becas instituto'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      disabled: selectedInstituteScholarships.length === 0,
      onClick: () => setConfirmModal(prevState => ({ ...prevState, view: true, isEntity: false })),
    },
  ];

  tableDataEnt.title = `${tKey('Becas entidad en creación ')}(${state.entityScholarships.count})`;
  tableDataIns.title = `${tKey('Becas instituto en creación ')}(${state.instituteScholarships.count})`;
  tableDataEnt.isLoading = state.loadingEntity;
  tableDataIns.isLoading = state.loadingInstitute;
  tableDataEnt.data = state.entityScholarships.scholarships;
  tableDataIns.data = state.instituteScholarships.scholarships;

  const selectedScholarships = (scholarships: ScholarshipCreatedDataItem | ScholarshipCreatedDataItem[]) => {
    const selected = [] as number[];
    if (Array.isArray(scholarships)) {
      scholarships.forEach(scholarship => {
        selected.push(scholarship.id);
      });
    } else {
      selected.push(scholarships.id);
    }
    return selected;
  };

  const selectEntityScholarships = (scholarships: ScholarshipCreatedDataItem | ScholarshipCreatedDataItem[]) => {
    const selected = selectedScholarships(scholarships);
    setSelectedEntityScholarships(selected);
  };

  const selectInstituteScholarships = (scholarships: ScholarshipCreatedDataItem | ScholarshipCreatedDataItem[]) => {
    const selected = selectedScholarships(scholarships);
    setSelectedInstituteScholarships(selected);
  };

  const detailsEntity = (rowData: ScholarshipCreatedDataItem) => {
    history.push({ pathname: `/becas/entity/detalle/${rowData.id}` });
  };

  const detailsInstitute = (rowData: ScholarshipCreatedDataItem) => {
    history.push({ pathname: `/becas/institute/detalle/${rowData.id}` });
  };

  if (!selectedYear && academicYears.length > 0) {
    setSelectedYear(academicYears[0]);
  }
  return (
    <LayoutForm
      leftSubmenu={<SubmenuConfiguration selected={getLastItem(path)} submenu={tFormKey('Limpiar becas por curso')} />}
      rightSubmenu={<ActionsMenu actionsButtons={buttons} />}
    >
      <Title>{tFormKey('Limpiar becas por curso')}</Title>
      <div className={styles.generalContainer}>
        <FormContainer title="">
          {selectedYear && (
            <FormControl
              variant="outlined"
              classes={{
                root: styles.select_wrapper,
              }}
            >
              <InputLabel>{tFormKey('Curso')}</InputLabel>
              <Select
                onChange={e => setSelectedYear(e.target.value as string)}
                label={tFormKey('Curso')}
                value={selectedYear}
              >
                {academicYears.map(element => (
                  <MenuItem key={element} value={element}>
                    {element}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TableWithEdit
            onSelectionChange={selectEntityScholarships}
            tableData={tableDataEnt}
            onRowClick={detailsEntity}
            filtering
          />
          <TableWithEdit
            onSelectionChange={selectInstituteScholarships}
            tableData={tableDataIns}
            onRowClick={detailsInstitute}
            filtering
          />
        </FormContainer>
      </div>
      <TransitionModal
        view={confirmModal.view}
        documentMode
        handleClose={() => {
          setConfirmModal(prevState => ({ ...prevState, view: false }));
        }}
        handleYes={() => {
          if (confirmModal.isEntity) {
            removeEntityScholarships(selectedYear, selectedEntityScholarships, dispatch);
            setSelectedEntityScholarships([]);
          } else {
            removeInstitueScholarships(selectedYear, selectedInstituteScholarships, dispatch);
            setSelectedInstituteScholarships([]);
          }
          setConfirmModal(prevState => ({ ...prevState, view: false }));
        }}
        buttonOk={tKey('Confirmar')}
        buttonKo={tKey('Cancelar')}
        title={confirmModal.title}
        bodyModal={`${tKey('Esta acción eliminará ')}${
          confirmModal.isEntity ? selectedEntityScholarships.length : selectedInstituteScholarships.length
        } ${tKey('beca/s de ')}${confirmModal.isEntity ? 'entidad' : 'instituto'} ${tKey('en creación')}`}
      >
        {confirmModal.children}
      </TransitionModal>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  academicYears: selectGenericMetadata(state).academicYears,
});

const mapDispatchToProps = (dispatch: FeedbackAPIActionDispatch) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, redirect?: string, autoHideDuration?: number) =>
    dispatch(showSnackbar(message, severity, redirect, autoHideDuration)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RemoveScholarships);
