/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';

import errorMessage from '../../../../helpers/errorMessage';
import { deleteParticipantKo, deleteParticipantOk } from './action';
import { ParticipantConst } from './action_types';
import { DeleteParticipantAction } from './definition';
import { deleteParticipant } from '../../../../api/InternalProject/Participants/deleteParticipant';
import { showSnackbar } from '../../../FeedbackAPI/actions';

function* deleteParticipantFromSaga(action: DeleteParticipantAction): Generator<any, void, any> {
  try {
    const { internal_project_id, participantId } = action;
    yield deleteParticipant(internal_project_id, participantId);
    yield put(deleteParticipantOk(internal_project_id, participantId));
    yield put(showSnackbar('Participante eliminado', 'success', undefined, 3000));
  } catch (e) {
    yield put(showSnackbar('No se ha podido eliminar el participante', 'error', undefined, 1500));
    yield put(deleteParticipantKo(errorMessage(e)));
  }
}

export default takeLatest<DeleteParticipantAction>(ParticipantConst.DELETE_PARTICIPANTS, deleteParticipantFromSaga);
