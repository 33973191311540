import { Button, ButtonProps, Chip, InputAdornment, MenuItem, SvgIcon } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { downloadPaymentFileFromApi } from '../../../../api/Projects/projects';
import TextInput from '../../../../components/Inputs/TextInput';
import Modal from '../../../../components/Modal/Modal';
import SelectController from '../../../../components/Select/SelectController';
import { getDocs } from '../../../../helpers/getDocs';
import { tKey, tProjectKey } from '../../../../helpers/translate';
import { ReactComponent as excelIcon } from '../../../../images/icons/excel.svg';
import { ReactComponent as pdfIcon } from '../../../../images/icons/pdf.svg';
import { ReactComponent as wordIcon } from '../../../../images/icons/word.svg';
import * as yup from 'yup';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { linkPayment, removePaymentFile, setPaymentData, uploadPaymentFile } from '../../../../redux/project/action';
import { PaymentInfo } from '../../../../redux/project/definitions';
import styles from './linkPaymentModal.module.scss';

interface LinkPaymentModalProps {
  entity: string;
  view: boolean;
  inputsData: PaymentInfo;
  onClose: () => void;
  linkPayment: (project_id: number, project_schedule_id: number, invoice_id: number, invoice_file_id: number) => void;
  uploadPaymentFile: (file: File) => void;
  removePaymentFile: (file_id: number) => void;
  setPaymentData: (data: PaymentInfo) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  onSubmit?: (paymentData: PaymentInfo) => void;
}

const LinkPaymentModal: React.FC<LinkPaymentModalProps> = ({
  entity,
  view,
  inputsData,
  onClose,
  onSubmit,
  linkPayment,
  uploadPaymentFile,
  removePaymentFile,
  setPaymentData,
  showSnackbar,
}) => {
  const LinkPaymentModalSchema = yup.object().shape({});
  const { errors, handleSubmit, control } = useForm<PaymentInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: LinkPaymentModalSchema,
  });
  const { name, invoice_id, invoices, file_id, file_name } = inputsData;
  const invoicesFiltered = [{ id: 0, reference_number: '0' }];
  if (invoices) invoicesFiltered.push(...invoices);

  const payment = () => {
    const { id, payment_field_id } = inputsData;
    linkPayment(id, Number(payment_field_id), Number(invoice_id), Number(invoice_id) === 0 ? Number(file_id) : 0);
    if (onSubmit) {
      onSubmit(inputsData);
    }
    onClose();
  };

  const canSend = (invoice_id && Number(invoice_id) !== 0) || (Number(invoice_id) === 0 && file_id);
  const buttons: ButtonProps[] = [
    {
      children: tProjectKey('Cancelar'),
      onClick: onClose,
      color: 'secondary',
      variant: 'contained',
      disableElevation: true,
    },
    {
      onClick: handleSubmit(payment),
      children: tProjectKey('Ok'),
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
      disabled: !canSend,
    },
  ];

  const colorize = (name: string) => {
    const res = name.substr(name.lastIndexOf('.') + 1);
    switch (res) {
      case 'pdf':
        return <SvgIcon component={pdfIcon} viewBox="0 0 600 476.6" />;
      case 'docx':
        return <SvgIcon component={wordIcon} viewBox="0 0 600 476.6" />;
      case 'xlsx':
        return <SvgIcon component={excelIcon} viewBox="0 0 600 476.6" />;
      default:
        return <ImageIcon />;
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      uploadPaymentFile(file);
    }
  };

  const handleDownload = (file_id: number, file_name: string) => {
    downloadPaymentFileFromApi(file_id)
      .then(data => {
        getDocs(data, true, file_name);
      })
      .catch(error => showSnackbar((error as Error).message, 'error', undefined, 1500));
  };

  const handleInvoiceId = (e: React.MouseEvent<HTMLElement>) => {
    const selectedValue = Number((e.target as HTMLInputElement).value);
    if (!isNaN(selectedValue) && invoice_id !== selectedValue)
      setPaymentData({ ...inputsData, invoice_id: selectedValue });
  };

  return (
    <Modal
      className={styles.modalContainer}
      title={tProjectKey('Vincular factura')}
      onClose={onClose}
      open={view}
      buttons={buttons}
    >
      <form className={styles.formContainer}>
        <TextInput type="text" label={tProjectKey('Entidad')} defaultValue={entity} size="100" disabled />
        <TextInput type="text" label={tProjectKey('Nombre')} defaultValue={name} size="50" disabled />
        <TextInput
          type="number"
          label={tProjectKey('Importe de pago')}
          defaultValue={inputsData.amount || '0.00'}
          size="50"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
        <SelectController
          key="invoice_id"
          label={tProjectKey('Factura')}
          name="invoice_id"
          defaultValue={invoice_id}
          size="60"
          schema={LinkPaymentModalSchema}
          control={control}
          errors={errors}
          onClick={handleInvoiceId}
        >
          {invoicesFiltered.map((element: { id: number; reference_number: string }) => (
            <MenuItem key={element.id} value={+element.id}>
              {element.id === 0 ? tKey('Adjuntar archivo') : `FAC-${element.reference_number}`}
            </MenuItem>
          ))}
        </SelectController>
        {invoice_id === 0 ? (
          <div className={styles.attachmentContainer}>
            {file_id && file_name ? (
              <Chip
                className={styles.chip}
                icon={colorize(file_name)}
                size="medium"
                label={file_name}
                clickable
                onClick={() => handleDownload(file_id, file_name)}
                onDelete={() => removePaymentFile(file_id)}
              />
            ) : (
              <div className={styles.buttonContainer}>
                <input
                  id="file"
                  type="file"
                  multiple
                  onChange={handleUpload}
                  accept="application/pdf,image/*,.xlsx,.docx,.doc,.xls"
                />
                <div>
                  {!file_id && (
                    <label htmlFor="file">
                      <Button variant="contained" color="secondary" component="span" fullWidth disabled={false}>
                        {tKey('Adjuntar archivo')}
                      </Button>
                    </label>
                  )}
                </div>
              </div>
            )}
            {errors?.file_id && <label className={styles.helperText}>{errors.file_id.message}</label>}
          </div>
        ) : null}
      </form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  uploadPaymentFile: (file: File) => dispatch(uploadPaymentFile(file)),
  removePaymentFile: (file_id: number) => dispatch(removePaymentFile(file_id)),
  linkPayment: (project_id: number, project_schedule_id: number, invoice_id: number, invoice_file_id: number) =>
    dispatch(linkPayment(project_id, project_schedule_id, invoice_id, invoice_file_id)),
  setPaymentData: (data: PaymentInfo) => dispatch(setPaymentData(data)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(null, mapDispatchToProps)(LinkPaymentModal);
