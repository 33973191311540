import React from 'react';
import styles from './fncLabel.module.scss';

export interface FncLabelProps extends React.ComponentPropsWithoutRef<'label'> {
  required?: boolean;
}

const FncLabel = ({ children, required = false, ...props }: FncLabelProps) => {
  return (
    <label className={`${styles.label}  ${required ? styles.required : ''}`} {...props}>
      {children}
    </label>
  );
};

export default FncLabel;
