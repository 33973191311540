import { ParticipantConst } from './action_types';
import {
  CancelDeleteParticipantAction,
  DeleteParticipantAction,
  DeleteParticipantKoAction,
  DeleteParticipantOkAction,
} from './definition';
import { InternalProjectId, ParticipantId } from './Participant';

export function deleteParticipant(
  internal_project_id: InternalProjectId,
  participantId: ParticipantId,
): DeleteParticipantAction {
  return {
    type: ParticipantConst.DELETE_PARTICIPANTS,
    internal_project_id,
    participantId,
  };
}

export function deleteParticipantOk(
  internal_project_id: InternalProjectId,
  participantId: ParticipantId,
): DeleteParticipantOkAction {
  return {
    type: ParticipantConst.DELETE_PARTICIPANTS_OK,
    internal_project_id,
    participantId,
  };
}

export function deleteParticipantKo(error: string): DeleteParticipantKoAction {
  return {
    type: ParticipantConst.DELETE_PARTICIPANTS_KO,
    error,
  };
}

export function cancelDeleteParticipant(): CancelDeleteParticipantAction {
  return {
    type: ParticipantConst.CANCEL_DELETE_PARTICIPANTS,
  };
}
