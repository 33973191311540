import { useForm } from 'react-hook-form';
import { tErrorKey } from '../helpers/translate';
import validate from './validators';

const NIF_REGEX = /^(\d{8})([A-Z])$/;
//const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
// const PASSPORT_REGEX = /^[a-z]{3}[0-9]{6}[a-z]?$/i;

const NIF_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';

export const nif_validator = (value: string): boolean => {
  try {
    if (!NIF_REGEX.test(value)) return false;
    const numbers = parseInt(value.substring(0, 8));
    const letter = value.substring(8, 9).toUpperCase();
    return NIF_LETTERS[numbers % 23] === letter;
  } catch (e) {
    return false;
  }
};

export const passport_validator = (value: string): boolean => {
  return value.length <= 20;
};

export const validateIdentificationCard = (
  documentType: string,
  documentNumber: string,
  fieldError: string,
  setError: ReturnType<typeof useForm>['setError'],
  clearError: ReturnType<typeof useForm>['clearError'],
  country?: string | null,
) => {
  !validate.documentIdentification({ documentType, documentNumber, country })
    ? setError(fieldError, 'invalidDocumentNumber', tErrorKey(`${documentType} incorrecto`))
    : clearError(fieldError);
};
