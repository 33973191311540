export const getDocs = (data: Blob, download: boolean, filename?: string) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  const docs = ['doc', 'odt', 'docx'];
  if ((download && filename) || (filename && docs.includes(filename?.split('.')[1]))) {
    a.download = filename;
    a.click();
    return;
  }
  window.open(url, '_blank');
};
