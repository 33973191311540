import { APIError, APIValidationError } from '../../api/api';
import { Fields, GenericFromRequest } from '../../components/GenericForm/GenericFormRefactor';
import { DocumentationItem, IdCodeNamePair, ResourceImageData } from '../common/definitions';
import { CustomFieldDocument } from '../kpis/definitions';
import { GenericData } from '../project/definitions';
import { InternalProjectsConst } from './action_types';
import {
  AddDocumentFollowUp,
  AddDocumentFollowUpKo,
  AddDocumentFollowUpOk,
  AddDocumentInternalProjectDailyFollowAction,
  AddDocumentInternalProjectDailyFollowKoAction,
  AddDocumentInternalProjectDailyFollowOkAction,
  AddInternalProjectImageAction,
  AddInternalProjectImageOkAction,
  AddMembers,
  AddMembersKo,
  AddMembersOk,
  AddNewGenericTableRowAction,
  ApplyConfigTemplateAction,
  ApplyConfigTemplateActionFinish,
  BaseCampForm,
  ClearDailyFollow,
  CreateInternalProjects,
  CreateInternalProjectsKo,
  CreateInternalProjectsOk,
  CreateInternalProjectValidationErrorsAction,
  DailyFollows,
  DailyFollowsForms,
  DailyFollowsTable,
  DeleteDailyFollow,
  DeleteDailyFollowKo,
  DeleteDailyFollowOk,
  DeleteDocumentstInternalProjectAction,
  DeleteDocumentstKoInternalProjectAction,
  DeleteDocumentstOkFollowUp,
  DeleteDocumentstOkInternalProjectAction,
  DeleteInternalProject,
  DeleteInternalProjectEnrollment,
  DeleteInternalProjectEnrollmentOk,
  DeleteInternalProjectImageAction,
  DeleteInternalProjectImageOkAction,
  DeleteInternalProjectKo,
  DeleteInternalProjectOk,
  DeleteMembers,
  DeleteMembersKo,
  DeleteMembersOk,
  DeleteRowParticipante,
  DeleteRowParticipanteKo,
  DeleteRowParticipanteOk,
  DownloadDocumentInternalDailyFollowProjecttAction,
  DownloadDocumentInternalProjectDailyFollowKoAction,
  DownloadDocumentInternalProjectDailyFollowOkAction,
  DownloadDocumentInternalProjecttAction,
  DownloadDocumentKoInternalProjecttAction,
  DownloadDocumentOkInternalProjecttAction,
  DownloadDocumentstInternalProjectAction,
  DownloadDocumentstKoInternalProjectAction,
  DownloadDocumentstOkInternalProjectAction,
  EditInternalProjectImageAction,
  EditInternalProjectImageOkAction,
  Follows,
  FollowUpSixMonthsForm,
  GenericKo,
  GetDocumentstInternalProjectAction,
  GetDocumentstKoInternalProjectAction,
  GetDocumentstOkInternalProjectAction,
  GetFollowUpSixMonthsForm,
  GetFollowUpSixMonthsFormKo,
  GetFollowUpSixMonthsFormOk,
  GetInternalProject,
  GetInternalProjectAcademy,
  GetInternalProjectAcademyKo,
  GetInternalProjectAcademyOk,
  GetInternalProjectDailyFollow,
  GetInternalProjectDailyFollowKo,
  GetInternalProjectDailyFollowOk,
  GetInternalProjectDailyFollows,
  GetInternalProjectDailyFollowsExcel,
  GetInternalProjectDailyFollowsExcelKo,
  GetInternalProjectDailyFollowsExcelOk,
  GetInternalProjectDailyFollowsKo,
  GetInternalProjectDailyFollowsOk,
  GetInternalProjectEnrollment,
  GetInternalProjectEnrollmentOk,
  GetInternalProjectFollows,
  GetInternalProjectFollowsOk,
  GetInternalProjectImagesAction,
  GetInternalProjectImagesOkAction,
  GetInternalProjectKo,
  GetInternalProjectOk,
  GetInternalProjects,
  GetInternalProjectsAcademy,
  GetInternalProjectsAcademyKo,
  GetInternalProjectsAcademyOk,
  GetInternalProjectSettings,
  GetInternalProjectSettingsKo,
  GetInternalProjectSettingsOk,
  GetInternalProjectsExcel,
  GetInternalProjectsExcelKo,
  GetInternalProjectsExcelOk,
  GetInternalProjectsKo,
  GetInternalProjectsOk,
  GetInternalProjectsTemplateList,
  GetInternalProjectsTemplateListKo,
  GetInternalProjectsTemplateListOk,
  GetInternalProjectSubareas,
  GetInternalProjectSubareasKo,
  GetInternalProjectSubareasOk,
  GetIpStudentEvaluation,
  GetIpStudentEvaluationOk,
  GetMetadata,
  GetMetadataKo,
  GetMetadataOk,
  GetParticipantesFinalFollowUpForm,
  GetParticipantesFinalFollowUpFormKo,
  GetParticipantesFinalFollowUpFormOk,
  GetParticipantesFollowUpList,
  GetParticipantesFollowUpListKo,
  GetParticipantesFollowUpListOk,
  GetProjectBaseCamp,
  GetProjectBaseCampOk,
  GetProjectTypeBySubareaCodeAction,
  GetProjectTypeBySubareaCodeKoAction,
  GetProjectTypeBySubareaCodeOkAction,
  GetSectionTemplate,
  GetSectionTemplateKo,
  GetSectionTemplateOk,
  GetStudentEvaluationInfo,
  GetStudentEvaluationInfoOk,
  GetUsersCanBeMembers,
  GetUsersCanBeMembersOk,
  InternalProject,
  InternalProjectCreate,
  InternalProjectEnrollment,
  InternalProjectEnrollmentMetadata,
  InternalProjectForms,
  InternalProjectsData,
  InternalProjectSettings,
  InternalProjectsTemplate,
  InternalProjectSubareaType,
  Member,
  MetadataDailyFollow,
  ParticipantesFinalFollowUpForm,
  ParticipantesFollowUpList,
  ProjectAllowedUser,
  RemoveDocumentInternalProjectAction,
  RemoveDocumentInternalProjectDailyFollowAction,
  RemoveDocumentInternalProjectDailyFollowKoAction,
  RemoveDocumentInternalProjectDailyFollowOkAction,
  RemoveDocumentKoInternalProjectAction,
  RemoveDocumentOkInternalProjectAction,
  SendFollowUp,
  SendFollowUpKo,
  SendFollowUpOk,
  SendIpStudentEvaluation,
  SetEnrollmentDropDownData,
  SetEnrollmentNumericData,
  SetEnrollmentTextData,
  SetInternalProjectData,
  SetLatestArea,
  SetLatestInternalProjectSection,
  SetNewInternalProjectEnrollment,
  SetRegisterTableAction,
  StoreDailyFollow,
  StoreDailyFollowKo,
  StoreDailyFollowOk,
  StudentEvaluationFieldsData,
  StudentEvaluationForm,
  StudentEvaluationInfo,
  UpdateEnrollment,
  UpdateEnrollmentOk,
  UpdateFollowUp,
  UpdateFollowUpDate,
  UpdateFollowUpDateKo,
  UpdateFollowUpDateOk,
  UpdateFollowUpKo,
  UpdateFollowUpOk,
  UpdateFollowUpSixMonthsForm,
  UpdateFollowUpSixMonthsFormKo,
  UpdateFollowUpSixMonthsFormOk,
  UpdateInternalProjectEnrollments,
  UpdateInternalProjectEnrollmentsKo,
  UpdateInternalProjectEnrollmentsOk,
  UpdateInternalProjects,
  UpdateInternalProjectSettings,
  UpdateInternalProjectSettingsKo,
  UpdateInternalProjectSettingsOk,
  UpdateInternalProjectsKo,
  UpdateInternalProjectsOk,
  UpdateParticipantesFinalFollowUpForm,
  UpdateParticipantesFinalFollowUpFormKo,
  UpdateParticipantesFinalFollowUpFormOk,
  UploadDocumentInternalProjectAction,
  UploadDocumentInternalProjectKoAction,
  UploadDocumentInternalProjectOkAction,
  UploadDocumentstInternalProjectAction,
  UploadDocumentstKoInternalProjectAction,
  UploadDocumentstOkInternalProjectAction,
} from './definitions';

export function getInternalProjectSubareas(): GetInternalProjectSubareas {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS,
  };
}

export function getInternalProjectSubareasOk(internalProjectSubAreas: IdCodeNamePair[]): GetInternalProjectSubareasOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS_OK,
    internalProjectSubAreas,
  };
}

export function getInternalProjectSubareasKo(error: string): GetInternalProjectSubareasKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS_KO,
    error,
  };
}

export function setLatestSubArea(setterData: string): SetLatestArea {
  return {
    type: InternalProjectsConst.SET_LATEST_SUBAREA,
    setterData,
  };
}

export function getInternalProjects(): GetInternalProjects {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS,
  };
}

export function getInternalProjectsOk(internalProject: InternalProjectsData[]): GetInternalProjectsOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_OK,
    internalProject,
  };
}

export function getInternalProjectsKo(error: string): GetInternalProjectsKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_KO,
    error,
  };
}

export function setLatestInternalProjectSection(
  internal_project_id: number,
  section: string,
): SetLatestInternalProjectSection {
  return {
    type: InternalProjectsConst.SET_LATEST_INTERNAL_PROJECT_SECTION,
    internal_project_id,
    section,
  };
}

export function getInternalProjectsTemplate(project_type_id: string): GetInternalProjectsTemplateList {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE,
    project_type_id,
  };
}

export function getInternalProjectsTemplateOk(
  templates: InternalProjectsTemplate[],
): GetInternalProjectsTemplateListOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE_OK,
    templates,
  };
}

export function getInternalProjectsTemplateKo(error: string): GetInternalProjectsTemplateListKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE_KO,
    error,
  };
}

export function setInternalProjects(
  key: keyof InternalProjectsData,
  value: InternalProjectsData[keyof InternalProjectsData],
): SetInternalProjectData {
  return {
    type: InternalProjectsConst.SET_INTERNAL_PROJECT,
    key,
    value,
  };
}

export function createInternalProject(internalProject: InternalProjectCreate): CreateInternalProjects {
  return {
    type: InternalProjectsConst.CREATE_INTERNAL_PROJECTS,
    internalProject,
  };
}

export function createInternalProjectOk(internalProject: InternalProject): CreateInternalProjectsOk {
  return {
    type: InternalProjectsConst.CREATE_INTERNAL_PROJECTS_OK,
    internalProject,
  };
}

export function createInternalProjectsKo(error: string): CreateInternalProjectsKo {
  return {
    type: InternalProjectsConst.CREATE_INTERNAL_PROJECTS_KO,
    error,
  };
}

export function createInternalProjectValidationErrors(
  validationErrors: APIValidationError,
): CreateInternalProjectValidationErrorsAction {
  return {
    type: InternalProjectsConst.CREATE_INTERNAL_PROJECT_VALIDATION_ERRORS,
    validationErrors,
  };
}

export function updateInternalProject(internalProject: InternalProjectForms, id: number): UpdateInternalProjects {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT,
    internalProject,
    id,
  };
}

export function updateInternalProjectOk(internalProject: InternalProject): UpdateInternalProjectsOk {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_OK,
    internalProject,
  };
}

export function updateInternalProjectsKo(error: string): UpdateInternalProjectsKo {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_KO,
    error,
  };
}

export function getInternalProject(id: number): GetInternalProject {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT,
    id,
  };
}

export function getInternalProjectOk(internalProjectData: InternalProject): GetInternalProjectOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_OK,
    internalProjectData,
  };
}

export function getInternalProjectKo(error: string): GetInternalProjectKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_KO,
    error,
  };
}

export function addMembers(user_id: number, internal_project_id: number): AddMembers {
  return {
    type: InternalProjectsConst.ADD_MEMBERS,
    user_id,
    internal_project_id,
  };
}

export function addMembersOk(member: Member): AddMembersOk {
  return {
    type: InternalProjectsConst.ADD_MEMBERS_OK,
    member,
  };
}

export function addMembersKo(error: string): AddMembersKo {
  return {
    type: InternalProjectsConst.ADD_MEMBERS_KO,
    error,
  };
}

export function getUsersCanBeMembers(internal_project_id: number): GetUsersCanBeMembers {
  return {
    type: InternalProjectsConst.GET_PROJECT_ALLOWED_USERS,
    internal_project_id,
  };
}

export function getUsersCanBeMembersOk(projectAllowedUsers: ProjectAllowedUser[]): GetUsersCanBeMembersOk {
  return {
    type: InternalProjectsConst.GET_PROJECT_ALLOWED_USERS_OK,
    projectAllowedUsers,
  };
}

export function deleteMembers(user_id: number, internal_project_id: number): DeleteMembers {
  return {
    type: InternalProjectsConst.DELETE_MEMBERS,
    user_id,
    internal_project_id,
  };
}

export function deleteMembersOk(user_id: number): DeleteMembersOk {
  return {
    type: InternalProjectsConst.DELETE_MEMBERS_OK,
    user_id,
  };
}

export function deleteMembersKo(error: string): DeleteMembersKo {
  return {
    type: InternalProjectsConst.DELETE_MEMBERS_KO,
    error,
  };
}

export function uploadDocument(internalProjectId: number, data: File): UploadDocumentInternalProjectAction {
  return {
    type: InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT,
    data,
    internalProjectId,
  };
}

export function uploadDocumentOk(documents: DocumentationItem[]): UploadDocumentInternalProjectOkAction {
  return {
    type: InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT_OK,
    documents,
  };
}

export function uploadDocumentKo(error: string): UploadDocumentInternalProjectKoAction {
  return {
    type: InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT_KO,
    error,
  };
}

export function downloadDocumentAgreement(
  file_id: number,
  name: string,
  internal_project_id: number,
  get = true,
): DownloadDocumentInternalProjecttAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT,
    file_id,
    name,
    internal_project_id,
    get,
  };
}

export function downloadDocumentAgreementOk(
  data: Blob,
  name: string,
  get = true,
): DownloadDocumentOkInternalProjecttAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT_OK,
    data,
    name,
    get,
  };
}

export function downloadDocumentAgreementKo(error: string): DownloadDocumentKoInternalProjecttAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT_KO,
    error,
  };
}

export function removeDocumentInternalProject(
  file_id: number,
  internal_project_id: number,
): RemoveDocumentInternalProjectAction {
  return {
    type: InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT,
    file_id,
    internal_project_id,
  };
}

export function removeDocumentInternalProjectOk(documents: DocumentationItem[]): RemoveDocumentOkInternalProjectAction {
  return {
    type: InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT_OK,
    documents,
  };
}

export function removeDocumentInternalProjectKo(error: string): RemoveDocumentKoInternalProjectAction {
  return {
    type: InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT_KO,
    error,
  };
}

export function getDocumentsInternalProject(internal_project_id: number): GetDocumentstInternalProjectAction {
  return {
    type: InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT,
    internal_project_id,
  };
}

export function getDocumentsInternalProjectOk(documents: DocumentationItem[]): GetDocumentstOkInternalProjectAction {
  return {
    type: InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT_OK,
    documents,
  };
}

export function getDocumentsInternalProjectKo(error: string): GetDocumentstKoInternalProjectAction {
  return {
    type: InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT_KO,
    error,
  };
}

export function uploadDocumentsInternalProject(
  internal_project_id: number,
  description: string,
  file: File,
  code: string,
): UploadDocumentstInternalProjectAction {
  return {
    type: InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT,
    internal_project_id,
    description,
    file,
    code,
  };
}

export function uploadDocumentsInternalProjectOk(
  documents: DocumentationItem[],
): UploadDocumentstOkInternalProjectAction {
  return {
    type: InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT_OK,
    documents,
  };
}

export function uploadDocumentsInternalProjectKo(error: string): UploadDocumentstKoInternalProjectAction {
  return {
    type: InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT_KO,
    error,
  };
}

export function deleteDocumentsInternalProject(
  internal_project_id: number,
  file_id: number,
  follow_up_id?: number,
  inputName?: string,
): DeleteDocumentstInternalProjectAction {
  return {
    type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT,
    internal_project_id,
    file_id,
    follow_up_id,
    inputName,
  };
}

export function deleteDocumentsFollowUp(
  internal_project_id: number,
  file_id: number,
  follow_up_id: number,
  inputName: string,
): DeleteDocumentstOkFollowUp {
  return {
    type: InternalProjectsConst.DELETE_DOCUMENTS_FOLLOW_UP_OK,
    internal_project_id,
    file_id,
    follow_up_id,
    inputName,
  };
}

export function deleteDocumentsInternalProjectOk(
  documents: DocumentationItem[],
): DeleteDocumentstOkInternalProjectAction {
  return {
    type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_OK,
    documents,
  };
}

export function deleteDocumentsInternalProjectKo(error: string): DeleteDocumentstKoInternalProjectAction {
  return {
    type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_KO,
    error,
  };
}

export function downloadDocumentsInternalProject(
  file_id: number,
  name: string,
  internal_project_id: number,
  get = true,
  follow_up_id?: number,
): DownloadDocumentstInternalProjectAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT,
    internal_project_id,
    file_id,
    name,
    get,
    follow_up_id,
  };
}

export function downloadDocumentsInternalProjectOk(
  data: Blob,
  name: string,
  get: boolean,
): DownloadDocumentstOkInternalProjectAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_OK,
    data,
    get,
    name,
  };
}

export function downloadDocumentsInternalProjectKo(error: string): DownloadDocumentstKoInternalProjectAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_KO,
    error,
  };
}

export function getInternalProjectDailyFollows(internal_project_id: number): GetInternalProjectDailyFollows {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS,
    internal_project_id,
  };
}

export function getInternalProjectDailyFollowsOk(dailyFollows: DailyFollowsTable[]): GetInternalProjectDailyFollowsOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS_OK,
    dailyFollows,
  };
}

export function getInternalProjectDailyFollowsKo(error: string): GetInternalProjectDailyFollowsKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS_KO,
    error,
  };
}

export function getInternalProjectsExcel(): GetInternalProjectsExcel {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL,
  };
}

export function getInternalProjectsExcelOk(data: Blob): GetInternalProjectsExcelOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL_OK,
    data,
  };
}

export function getInternalProjectsExcelKo(error: string): GetInternalProjectsExcelKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL_KO,
    error,
  };
}
export function getInternalProjectDailyFollow(dailyFollowsId: number): GetInternalProjectDailyFollow {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW,
    dailyFollowsId,
  };
}

export function getInternalProjectDailyFollowOk(dailyFollow: DailyFollowsForms): GetInternalProjectDailyFollowOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_OK,
    dailyFollow,
  };
}

export function getInternalProjectDailyFollowKo(error: string): GetInternalProjectDailyFollowKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_KO,
    error,
  };
}

export function addDocumentDailyFollow(
  data: File,
  dailyFollowsId: number,
  description: string,
  dailyFollow?: DailyFollows,
): AddDocumentInternalProjectDailyFollowAction {
  return {
    type: InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW,
    data,
    dailyFollowsId,
    description,
    dailyFollow,
  };
}

export function addDocumentDailyFollowOk(
  documents: DocumentationItem[],
): AddDocumentInternalProjectDailyFollowOkAction {
  return {
    type: InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK,
    documents,
  };
}

export function addDocumentDailyFollowKo(error: string): AddDocumentInternalProjectDailyFollowKoAction {
  return {
    type: InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO,
    error,
  };
}

export function downloadDocumentDailyFollow(
  file_id: number,
  name: string,
  dailyFollowsId: number,
  get = true,
): DownloadDocumentInternalDailyFollowProjecttAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW,
    file_id,
    name,
    dailyFollowsId,
    get,
  };
}

export function downloadDocumentDailyFollowOk(
  data: Blob,
  name: string,
  get = true,
): DownloadDocumentInternalProjectDailyFollowOkAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK,
    data,
    name,
    get,
  };
}

export function downloadDocumentDailyFollowKo(error: string): DownloadDocumentInternalProjectDailyFollowKoAction {
  return {
    type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO,
    error,
  };
}

export function removeDocumentInternalProjectDailyFollow(
  file_id: number,
  dailyFollowsId: number,
): RemoveDocumentInternalProjectDailyFollowAction {
  return {
    type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW,
    file_id,
    dailyFollowsId,
  };
}

export function removeDocumentInternalProjectDailyFollowOk(
  documents: DocumentationItem[],
): RemoveDocumentInternalProjectDailyFollowOkAction {
  return {
    type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK,
    documents,
  };
}

export function removeDocumentInternalProjectDailyFollowKo(
  error: string,
): RemoveDocumentInternalProjectDailyFollowKoAction {
  return {
    type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO,
    error,
  };
}

export function getMetadata(subarea_internal_project_type_id: string | null): GetMetadata {
  return {
    type: InternalProjectsConst.GET_METADATA,
    subarea_internal_project_type_id,
  };
}

export function getMetadataOk(metadata: MetadataDailyFollow): GetMetadataOk {
  return {
    type: InternalProjectsConst.GET_METADATA_OK,
    metadata,
  };
}

export function getMetadataKo(error: string): GetMetadataKo {
  return {
    type: InternalProjectsConst.GET_METADATA_KO,
    error,
  };
}

export function storeDailyFollow(dailyFollow: DailyFollows): StoreDailyFollow {
  return {
    type: InternalProjectsConst.STORE_DAILY_FOLLOW,
    dailyFollow,
  };
}

export function storeDailyFollowOk(dailyFollows: DailyFollowsTable[], dailyFollowsId: number): StoreDailyFollowOk {
  return {
    type: InternalProjectsConst.STORE_DAILY_FOLLOW_OK,
    dailyFollows,
    dailyFollowsId,
  };
}

export function storeDailyFollowKo(error: string): StoreDailyFollowKo {
  return {
    type: InternalProjectsConst.STORE_DAILY_FOLLOW_KO,
    error,
  };
}

export function clearDailyFollow(): ClearDailyFollow {
  return {
    type: InternalProjectsConst.CLEAR_DAILY_FOLLOW,
  };
}

export function deleteDailyFollow(dailyFollowId: number): DeleteDailyFollow {
  return {
    type: InternalProjectsConst.DELETE_DAILY_FOLLOW,
    dailyFollowId,
  };
}

export function deleteDailyFollowOk(dailyFollowId: number): DeleteDailyFollowOk {
  return {
    type: InternalProjectsConst.DELETE_DAILY_FOLLOW_OK,
    dailyFollowId,
  };
}

export function deleteDailyFollowKo(error: string): DeleteDailyFollowKo {
  return {
    type: InternalProjectsConst.DELETE_DAILY_FOLLOW_KO,
    error,
  };
}

export function deleteInternalProject(projectId: number): DeleteInternalProject {
  return {
    type: InternalProjectsConst.DELETE_INTERNAL_PROJECT,
    projectId,
  };
}

export function deleteInternalProjectOk(internalProject: InternalProjectsData[]): DeleteInternalProjectOk {
  return {
    type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_OK,
    internalProject,
  };
}

export function deleteInternalProjectKo(error: string): DeleteInternalProjectKo {
  return {
    type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_KO,
    error,
  };
}

export function getInternalProjectDailyFollowsExcel(internalProjectId: number): GetInternalProjectDailyFollowsExcel {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL,
    internalProjectId,
  };
}

export function getInternalProjectDailyFollowsExcelOk(data: File): GetInternalProjectDailyFollowsExcelOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_OK,
    data,
  };
}

export function getInternalProjectDailyFollowsExcelKo(error: string): GetInternalProjectDailyFollowsExcelKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_KO,
    error,
  };
}

export function getInternalProjectsAcademy(): GetInternalProjectsAcademy {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY,
  };
}

export function getInternalProjectsAcademyOk(internalProject: InternalProjectsData[]): GetInternalProjectsAcademyOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY_OK,
    internalProject,
  };
}

export function getInternalProjectsAcademyKo(error: string): GetInternalProjectsAcademyKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY_KO,
    error,
  };
}

export function getInternalProjectAcademy(id: number): GetInternalProjectAcademy {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY,
    id,
  };
}

export function getInternalProjectAcademyOk(internalProjectData: InternalProject): GetInternalProjectAcademyOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY_OK,
    internalProjectData,
  };
}

export function getInternalProjectAcademyKo(error: string): GetInternalProjectAcademyKo {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY_KO,
    error,
  };
}

export function getInternalProjectFollows(internalProjectId: number): GetInternalProjectFollows {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_FOLLOWS,
    internalProjectId,
  };
}

export function getInternalProjectFollowsOk(follows: Follows[]): GetInternalProjectFollowsOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_FOLLOWS_OK,
    follows,
  };
}

export function getParticipantesFollowUpList(internalProjectId: number): GetParticipantesFollowUpList {
  return {
    type: InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST,
    internalProjectId,
  };
}
export function getParticipantesFollowUpListOk(
  participantesFollowUpList: ParticipantesFollowUpList[],
): GetParticipantesFollowUpListOk {
  return {
    type: InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST_OK,
    participantesFollowUpList,
  };
}

export function getParticipantesFollowUpListKo(error: string): GetParticipantesFollowUpListKo {
  return {
    type: InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST_KO,
    error,
  };
}

export function getFollowUpSixMonthsForm(enrollmentId: number): GetFollowUpSixMonthsForm {
  return {
    type: InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM,
    enrollmentId,
  };
}
export function getFollowUpSixMonthsFormOk(followUpSixMonthsData: FollowUpSixMonthsForm): GetFollowUpSixMonthsFormOk {
  return {
    type: InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM_OK,
    followUpSixMonthsData,
  };
}

export function getFollowUpSixMonthsDataKo(error: string): GetFollowUpSixMonthsFormKo {
  return {
    type: InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM_KO,
    error,
  };
}

export function updateFollowUpSixMonthsForm(
  enrollmentId: number,
  followUpSixMonthsData: FollowUpSixMonthsForm,
): UpdateFollowUpSixMonthsForm {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM,
    enrollmentId,
    followUpSixMonthsData,
  };
}

export function updateFollowUpSixMonthsFormOk(
  followUpSixMonthsData: FollowUpSixMonthsForm,
): UpdateFollowUpSixMonthsFormOk {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM_OK,
    followUpSixMonthsData,
  };
}

export function updateFollowUpSixMonthsFormKo(error: string): UpdateFollowUpSixMonthsFormKo {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM_KO,
    error,
  };
}

export function updateFollowUpDate(internalProjectFollowId: number, follow_date: string): UpdateFollowUpDate {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOW_UP_DATE,
    internalProjectFollowId,
    follow_date,
  };
}

export function updateFollowUpDateOk(internalProjectFollowId: number, follow_date: string): UpdateFollowUpDateOk {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOW_UP_DATE_OK,
    internalProjectFollowId,
    follow_date,
  };
}

export function updateFollowUpDateKo(error: string): UpdateFollowUpDateKo {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOW_UP_DATE_KO,
    error,
  };
}

export function getSectionTemplate(internalProjectId: number, section: string, followUpId: number): GetSectionTemplate {
  return {
    type: InternalProjectsConst.GET_SECTION_TEMPLATE,
    internalProjectId,
    section,
    followUpId,
  };
}

export function getSectionTemplateOk(fields: Fields, participante: GenericData): GetSectionTemplateOk {
  return {
    type: InternalProjectsConst.GET_SECTION_TEMPLATE_OK,
    fields,
    participante,
  };
}

export function getSectionTemplateKo(error: string): GetSectionTemplateKo {
  return {
    type: InternalProjectsConst.GET_SECTION_TEMPLATE_KO,
    error,
  };
}

export function updateFollowUp(data: GenericFromRequest): UpdateFollowUp {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOW_UP,
    data,
  };
}

export function updateFollowUpOk(fields: Fields, participante: GenericData): UpdateFollowUpOk {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOW_UP_OK,
    fields,
    participante,
  };
}

export function updateFollowUpKo(error: string): UpdateFollowUpKo {
  return {
    type: InternalProjectsConst.UPDATE_FOLLOW_UP_KO,
    error,
  };
}

export function sendFollowUp(data: GenericFromRequest): SendFollowUp {
  return {
    type: InternalProjectsConst.SEND_FOLLOW_UP,
    data,
  };
}

export function sendFollowUpOk(follow_up_id: number): SendFollowUpOk {
  return {
    type: InternalProjectsConst.SEND_FOLLOW_UP_OK,
    follow_up_id,
  };
}

export function sendFollowUpKo(error: string): SendFollowUpKo {
  return {
    type: InternalProjectsConst.SEND_FOLLOW_UP_KO,
    error,
  };
}

export function addDocumentFollowUp(
  internal_project_id: number,
  follow_up_id: number,
  file: File,
  description: string,
  custom_field_name: string,
  section: string,
): AddDocumentFollowUp {
  return {
    type: InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP,
    internal_project_id,
    follow_up_id,
    file,
    description,
    custom_field_name,
    section,
  };
}

export function addDocumentFollowUpOk(
  documents: CustomFieldDocument[],
  custom_field_name: string,
): AddDocumentFollowUpOk {
  return {
    type: InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP_OK,
    documents,
    custom_field_name,
  };
}

export function addDocumentFollowUpKo(error: string): AddDocumentFollowUpKo {
  return {
    type: InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP_KO,
    error,
  };
}

export function addNewGenericTableRow(setterData?: any): AddNewGenericTableRowAction {
  return {
    type: InternalProjectsConst.ADD_NEW_REGISTER,
    setterData,
  };
}

export function setRegisterGenericTableRow(setterData?: any): SetRegisterTableAction {
  return {
    type: InternalProjectsConst.SET_REGISTER,
    setterData,
  };
}
export function getInternalProjectSettings(): GetInternalProjectSettings {
  return {
    type: InternalProjectsConst.GET_SETTINGS,
  };
}
export function getInternalProjectSettingsOk(
  internalProjectSettings: InternalProjectSettings,
): GetInternalProjectSettingsOk {
  return {
    type: InternalProjectsConst.GET_SETTINGS_OK,
    internalProjectSettings,
  };
}

export function getInternalProjectSettingsKo(error: string): GetInternalProjectSettingsKo {
  return {
    type: InternalProjectsConst.GET_SETTINGS_KO,
    error,
  };
}

export function updateInternalProjectSettings(settings: InternalProjectSettings): UpdateInternalProjectSettings {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS,
    settings,
  };
}
export function updateInternalProjectSettingsOk(settings: InternalProjectSettings): UpdateInternalProjectSettingsOk {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS_OK,
    settings,
  };
}
export function updateInternalProjectSettingsKo(error: string): UpdateInternalProjectSettingsKo {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS_KO,
    error,
  };
}

export function getParticipantesFinalFollowUpForm(
  internal_project_enrollment_id: number,
): GetParticipantesFinalFollowUpForm {
  return {
    type: InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM,
    internal_project_enrollment_id,
  };
}
export function getParticipantesFinalFollowUpFormOk(
  participanteFinalForm: ParticipantesFinalFollowUpForm,
): GetParticipantesFinalFollowUpFormOk {
  return {
    type: InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK,
    participanteFinalForm,
  };
}
export function getParticipantesFinalFollowUpFormKo(error: string): GetParticipantesFinalFollowUpFormKo {
  return {
    type: InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO,
    error,
  };
}
export function updateParticipantesFinalFollowUpForm(
  internal_project_enrollment_id: number,
  participanteFinalFormData: Partial<ParticipantesFinalFollowUpForm>,
): UpdateParticipantesFinalFollowUpForm {
  return {
    type: InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM,
    participanteFinalFormData,
    internal_project_enrollment_id,
  };
}

export function updateParticipantesFinalFollowUpFormOk(
  participanteFinalFormData: ParticipantesFinalFollowUpForm,
  internal_project_enrollment_id: number,
): UpdateParticipantesFinalFollowUpFormOk {
  return {
    type: InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK,
    participanteFinalFormData,
    internal_project_enrollment_id,
  };
}

export function updateParticipantesFinalFollowUpFormKo(error: string): UpdateParticipantesFinalFollowUpFormKo {
  return {
    type: InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO,
    error,
  };
}
export function deleteRowParticipante(
  idRow: number | null,
  section: string,
  idGenerated: number | null,
): DeleteRowParticipante {
  return {
    type: InternalProjectsConst.DELETE_ROW_PARTICIPANTE,
    idRow,
    section,
    idGenerated,
  };
}

export function deleteRowParticipanteOk(idRow: number | null, idGenerated: number | null): DeleteRowParticipanteOk {
  return {
    type: InternalProjectsConst.DELETE_ROW_PARTICIPANTE_OK,
    idRow,
    idGenerated,
  };
}

export function deleteRowParticipanteKo(error: string): DeleteRowParticipanteKo {
  return {
    type: InternalProjectsConst.DELETE_ROW_PARTICIPANTE_KO,
    error,
  };
}

export function getProjectBaseCamp(project_id: number, follow_up_id: number): GetProjectBaseCamp {
  return {
    type: InternalProjectsConst.GET_BASE_CAMP,
    project_id,
    follow_up_id,
  };
}

export function getProjectBaseCampOk(baseCamp: BaseCampForm): GetProjectBaseCampOk {
  return {
    type: InternalProjectsConst.GET_BASE_CAMP_OK,
    baseCamp,
  };
}
export function getStudentEvaluationInfo(project_id: number, follow_up_id: number): GetStudentEvaluationInfo {
  return {
    type: InternalProjectsConst.GET_STUDENT_EVALUATION_INFO,
    project_id,
    follow_up_id,
  };
}

export function getStudentEvaluationInfoOk(studentEvaluationInfo: StudentEvaluationInfo): GetStudentEvaluationInfoOk {
  return {
    type: InternalProjectsConst.GET_STUDENT_EVALUATION_INFO_OK,
    studentEvaluationInfo,
  };
}

export function updateEnrollment(enrollment: InternalProjectEnrollment): UpdateEnrollment {
  return {
    type: InternalProjectsConst.UPDATE_ENROLLMENT,
    enrollment,
  };
}

export function updateEnrollmentOk(enrollment: InternalProjectEnrollment): UpdateEnrollmentOk {
  return {
    type: InternalProjectsConst.UPDATE_ENROLLMENT_OK,
    enrollment,
  };
}

export function deleteInternalProjectEnrollment(enrollment_id: number): DeleteInternalProjectEnrollment {
  return {
    type: InternalProjectsConst.DELETE_ENROLLMENT,
    enrollment_id,
  };
}

export function deleteInternalProjectEnrollmentOk(enrollment_id: number): DeleteInternalProjectEnrollmentOk {
  return {
    type: InternalProjectsConst.DELETE_ENROLLMENT_OK,
    enrollment_id,
  };
}

export function setNewInternalProjectEnrollment(
  new_enrollment: InternalProjectEnrollment,
): SetNewInternalProjectEnrollment {
  return {
    type: InternalProjectsConst.SET_NEW_ENROLLMENT,
    new_enrollment,
  };
}

export function getInternalProjectEnrollment(internal_project_id: number): GetInternalProjectEnrollment {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_ENROLLMENTS,
    internal_project_id,
  };
}

export function getInternalProjectEnrollmentOk(
  enrollments: InternalProjectEnrollment[],
  metadata: InternalProjectEnrollmentMetadata,
): GetInternalProjectEnrollmentOk {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_ENROLLMENTS_OK,
    enrollments,
    metadata,
  };
}

export function setEnrollmentTextData(
  enrollment_id: number,
  property: string,
  value: string | null,
): SetEnrollmentTextData {
  return {
    type: InternalProjectsConst.SET_ENROLLMENT_TEXT_DATA,
    enrollment_id,
    property,
    value,
  };
}

export function setEnrollmentNumericData(
  enrollment_id: number,
  property: string,
  value: number | null,
): SetEnrollmentNumericData {
  return {
    type: InternalProjectsConst.SET_ENROLLMENT_NUMERIC_DATA,
    enrollment_id,
    property,
    value,
  };
}

export function setEnrollmentDropDownData(
  enrollment_id: number,
  property: string,
  value: number,
): SetEnrollmentDropDownData {
  return {
    type: InternalProjectsConst.SET_ENROLLMENT_DROP_DOWN_DATA,
    enrollment_id,
    property,
    value,
  };
}

export function updateInternalProjectEnrollments(
  internal_project_id: number,
  enrollments: InternalProjectEnrollment[],
): UpdateInternalProjectEnrollments {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS,
    internal_project_id,
    enrollments,
  };
}

export function updateInternalProjectEnrollmentsOk(
  enrollments: InternalProjectEnrollment[],
): UpdateInternalProjectEnrollmentsOk {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS_OK,
    enrollments,
  };
}

export function updateInternalProjectEnrollmentsKo(error: string): UpdateInternalProjectEnrollmentsKo {
  return {
    type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS_KO,
    error,
  };
}

export function getIpStudentEvaluation(project_key: string): GetIpStudentEvaluation {
  return {
    type: InternalProjectsConst.GET_IP_STUDENT_EVALUATION,
    project_key,
  };
}

export function getIpStudentEvaluationOk(student_evaluation: StudentEvaluationForm): GetIpStudentEvaluationOk {
  return {
    type: InternalProjectsConst.GET_IP_STUDENT_EVALUATION_OK,
    student_evaluation,
  };
}

export function sendIpStudentEvaluation(data: StudentEvaluationFieldsData): SendIpStudentEvaluation {
  return {
    type: InternalProjectsConst.SEND_IP_STUDENT_EVALUATION,
    data,
  };
}

export function getInternalProjectImages(id: number): GetInternalProjectImagesAction {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_IMAGES,
    id,
  };
}

export function getInternalProjectImagestOk(resourceImages: ResourceImageData[]): GetInternalProjectImagesOkAction {
  return {
    type: InternalProjectsConst.GET_INTERNAL_PROJECT_IMAGES_OK,
    resourceImages,
  };
}

export function addInternalProjectImage(
  id: number,
  name: string,
  description: string,
  image: string,
  userId: number,
): AddInternalProjectImageAction {
  return {
    type: InternalProjectsConst.ADD_INTERNAL_PROJECT_IMAGE,
    id,
    name,
    description,
    image,
    userId,
  };
}

export function addInternalProjectImageOk(resourceImage: ResourceImageData): AddInternalProjectImageOkAction {
  return {
    type: InternalProjectsConst.ADD_INTERNAL_PROJECT_IMAGE_OK,
    resourceImage,
  };
}

export function editInternalProjectImage(
  id: number,
  resourceImage: ResourceImageData,
  name: string,
  description: string,
  newImage: string,
  userId: number,
): EditInternalProjectImageAction {
  return {
    type: InternalProjectsConst.EDIT_INTERNAL_PROJECT_IMAGE,
    id,
    resourceImage,
    name,
    description,
    newImage,
    userId,
  };
}

export function editInternalProjectImageOk(resourceImage: ResourceImageData): EditInternalProjectImageOkAction {
  return {
    type: InternalProjectsConst.EDIT_INTERNAL_PROJECT_IMAGE_OK,
    resourceImage,
  };
}

export function deleteInternalProjectImage(resourceImage: ResourceImageData): DeleteInternalProjectImageAction {
  return {
    type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_IMAGE,
    resourceImage,
  };
}

export function deleteInternalProjectImageOk(projectImageId: number): DeleteInternalProjectImageOkAction {
  return {
    type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_IMAGE_OK,
    projectImageId,
  };
}

export function internalProjectsGenericKo(apiError: APIError): GenericKo {
  return {
    type: InternalProjectsConst.GENERIC_KO,
    apiError,
  };
}

export function getProjectTypeBySubareaCode(subAreaCode: string): GetProjectTypeBySubareaCodeAction {
  return {
    type: InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE,
    subAreaCode,
  };
}

export function getProjectTypeBySubareaCodeOk(
  internalProjectSubareaTypes: InternalProjectSubareaType[],
): GetProjectTypeBySubareaCodeOkAction {
  return {
    type: InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE_OK,
    internalProjectSubareaTypes,
  };
}

export function getProjectTypeBySubareaCodeKo(error: string): GetProjectTypeBySubareaCodeKoAction {
  return {
    type: InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE_KO,
    error,
  };
}

export function applyConfigTemplate(internalProjectId: number): ApplyConfigTemplateAction {
  return {
    type: InternalProjectsConst.APPLY_CONFIG_TEMPLATE,
    internalProjectId,
  };
}

export function applyConfigTemplateFinish(): ApplyConfigTemplateActionFinish {
  return {
    type: InternalProjectsConst.APPLY_CONFIG_TEMPLATE_OK,
  };
}
