import { connect } from 'react-redux';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import ClipBoard from './ClipBoard';

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar): void => dispatch(showSnackbar(message, severity)),
});

export default connect(null, mapDispatchToProps)(ClipBoard);
