import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

interface SaveResult {
  data: any;
}

interface Data {
  entities: number;
}

export const deleteEntity = async (id: number): Promise<SaveResult> => {
  const data: Data = {
    entities: id,
  };
  const init = {
    method: 'DELETE',
    body: JSON.stringify(data),
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/entities/`, init);
};
