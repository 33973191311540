import { CategoryKeys, TranslationCategories } from '../redux/translations/definitions';

export function getCategoryKey(payload: TranslationCategories): CategoryKeys {
  switch (payload) {
    case TranslationCategories.GENERAL:
      return 'generic';
    case TranslationCategories.BECAS:
      return 'scholarship';
    case TranslationCategories.PROYECTOS:
      return 'project';
    case TranslationCategories.FORMS:
      return 'forms';
    case TranslationCategories.ERRORES:
      return 'errors';

    default:
      return 'default';
  }
}
