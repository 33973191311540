import { useState } from 'react';
import { ScholarshipStateChange } from '../../../redux/scholarship/definitions';

type UseRemainderModalDataType = {
  showModal: boolean;
  remainderAmount: number;
  stateChange: ScholarshipStateChange | undefined;
  closeModal: () => void;
  setRemainderModalData: (remainderAmount: number, stateChange: ScholarshipStateChange | undefined) => void;
};

const useRemainderModalData = (): UseRemainderModalDataType => {
  const defalutValues = {
    show: false,
    remainderAmount: 0,
    stateChange: undefined,
  };

  const [modalData, setModalData] = useState<{
    show: boolean;
    remainderAmount: number;
    stateChange: ScholarshipStateChange | undefined;
  }>(defalutValues);

  const setRemainderModalData = (remainderAmount: number, stateChange: ScholarshipStateChange | undefined) => {
    setModalData({
      show: true,
      remainderAmount,
      stateChange,
    });
  };

  const closeModal = () => {
    setModalData(defalutValues);
  };

  return {
    showModal: modalData.show,
    remainderAmount: modalData.remainderAmount,
    stateChange: modalData.stateChange,
    setRemainderModalData,
    closeModal,
  };
};

export default useRemainderModalData;
