import { APIError } from '../../api/api';
import RoleCode from '../../types/Roles/roleCode';
import { UserStates } from '../common/definitions';
import { AppState } from '../root-reducer';
import { AuthUserData } from './definitions';
import { AuthState } from './reducers';

export const selectAuth = (state: AppState): AuthState => state.authReducer;
export const selectIsLoggedIn = (state: AppState): boolean => selectAuth(state).loggedIn;
export const selectIsActivating = (state: AppState): boolean => selectAuth(state).activating;
export const selectIsLoading = (state: AppState): boolean => selectAuth(state).loading;
export const selectIsInitializing = (state: AppState): boolean => selectAuth(state).init;
export const selectError = (state: AppState): APIError | null => selectAuth(state).error;
export const selectUserState = (state: AppState): string => selectAuth(state).userInfo.state;
export const selectIsUserCreated = (state: AppState): boolean =>
  selectAuth(state).userInfo.state === UserStates.CREATED;
export const selectToken = (state: AppState): string | null => selectAuth(state).token;
export const selectUserAvatar = (state: AppState): string => selectAuth(state).avatar;
export const selectUserInfo = (state: AppState): AuthUserData => selectAuth(state).userInfo;
export const selectIsNousCims = (state: AppState): boolean => selectAuth(state).userInfo.is_nouscims;
export const selectUserRoleCode = (state: AppState): RoleCode => {
  const role_code = selectAuth(state).userInfo.role_code;
  return new RoleCode(role_code);
};
export const selectUserLang = (state: AppState): string => selectAuth(state).language;
export const selectUserIsAcademy = (state: AppState): boolean => selectUserRoleCode(state).isPersonalEscuelas();
export const selectUserIsSherpa = (state: AppState): boolean => selectUserRoleCode(state).isSherpa();
export const selectUserIsEducador = (state: AppState): boolean => selectUserRoleCode(state).isEducador();
export const selectUserIsEntity = (state: AppState): boolean => selectAuth(state).userInfo.role_group === 'entity';
export const selectUserCanCreateScholarships = (state: AppState): boolean => {
  const userInfo = selectUserInfo(state);
  return userInfo.can_create_entity_scholarships || userInfo.can_create_institute_scholarships;
};
export const selectUserCanRenewScholarships = (state: AppState): boolean => {
  const userInfo = selectUserInfo(state);
  return userInfo.can_renew_entity_scholarships || userInfo.can_renew_institute_scholarships;
};
