import React from 'react';
import { tKey } from '../../../../helpers/translate';
import FilterItem from './FilterItem';
import styles from './filterList.module.scss';

export interface FilteredData {
  category: string;
  id: number;
  key_label: string;
  value_ca: string;
  value_en: string;
  value_es: string;
  value_fr: string;
  [key: string]: any;
}

interface FilterListProps {
  lang: string;
  data: FilteredData[];
  langLabel?: string;
  source: 'keys' | 'custom_fields';
}

const FilterList: React.FC<FilterListProps> = ({ lang, data, langLabel, source }) => {
  return (
    <div className={styles.filterListContainer}>
      {data.length > 0 && (
        <div className={styles.textContainer}>
          <div className={styles.title}>{tKey('Texto origen')}</div>
          <div className={styles.title}>{`${tKey('Texto')} ${langLabel}`}</div>
        </div>
      )}
      {data.map(subItem => (
        <FilterItem key={subItem.id} lang={lang} item={subItem} source={source} />
      ))}
      {data.length === 0 && <div className={styles.noResults}>{tKey('No hay resultados a mostrar')}</div>}
    </div>
  );
};

export default FilterList;
