import { Column } from 'material-table';
import { APIResponse } from '../redux/common/definitions';
import { apiBaseUrl, apiCall } from './api';

const baseURL = apiBaseUrl;

export interface ResponseExport {
  columns: Column<any>[];
  data: any;
}

export const getExportDataFromApi = async (endpoint: string, dataPost?: any): Promise<ResponseExport> => {
  const init = {
    method: 'POST',
    auth: true,
    body: dataPost ? JSON.stringify(dataPost) : null,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}${endpoint}`, init);
  return data;
};
