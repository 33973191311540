import React, { useState } from 'react';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { tScholarshipKey } from '../../../helpers/translate';
import { CodeNamePair, IdCodeNamePair, KeyButton } from '../../../redux/common/definitions';
import { ScholarshipData } from '../../../redux/scholarship/definitions';
import ChangeScholarshipStateModal from '../../Entities/ChangeScholarshipStateModal';
import { getButtonType } from '../ButtonActions/ScholarshipButtonActions';

interface Props {
  scholarshipData: ScholarshipData;
  allowedStates: IdCodeNamePair[];
  handleChangeScholarshipState: (current_state: string, next_state: string) => void;
}
const ChangeStateScholarshipModal = ({ scholarshipData, allowedStates, handleChangeScholarshipState }: Props) => {
  const [modal, setModal] = useState(false);

  const buttonChangeScholarshipState = getButtonType(KeyButton.CHANGE_STATE);
  buttonChangeScholarshipState.onClick = () => {
    setModal(true);
  };

  return (
    <>
      <TransitionModal
        view={modal}
        handleClose={() => {
          setModal(false);
        }}
        title={tScholarshipKey('cambiar-estado')}
        helperMode={true}
      >
        <ChangeScholarshipStateModal
          current_state={{ name: scholarshipData.state, code: scholarshipData.state_code } as CodeNamePair}
          next_states={allowedStates}
          handleChange={(current_state, next_state) => {
            handleChangeScholarshipState(current_state, next_state);
          }}
          handleClose={() => {
            setModal(false);
          }}
        />
      </TransitionModal>
    </>
  );
};
export default ChangeStateScholarshipModal;
