import { Visibility, VisibilityOff } from '@material-ui/icons';

import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useState } from 'react';
import { connect } from 'react-redux';
import TableWithEdit from '../../../../../../components/Tables/TableWithEdit';
import { tFormKey, tKey } from '../../../../../../helpers/translate';
import {
  InternalProjectDispatch,
  InternalProjectEnrollment,
} from '../../../../../../redux/internal-projects/definitions';
import { AppState } from '../../../../../../redux/root-reducer';
import NewEnrollmentModal from '../../../../Modals/NewEnrollmentModal/NewEnrollmentModal';
import InformationMinimal from '../../Information/InformationMinimal';
import styles from '../internalProjectFollows.module.scss';
import StudentEvaluationModal from '../../../../Modals/StudentEvaluationModal/StudentEvaluationModal';

interface StudentEvaluationProps {
  internal_project_id: number;
  enrollments: InternalProjectEnrollment[];
}

const StudentEvaluation: React.FC<StudentEvaluationProps> = ({ internal_project_id, enrollments }) => {
  const [showCreateEnrollment, setShowCreateEnrollment] = useState(false);

  const [studentEnrollmentModal, setStudentEnrollmentModal] = useState<{
    enrollment_id: number;
    view: boolean;
    data?: { studentName: string; studentSurnames: string };
  }>({ enrollment_id: 0, view: false });

  const actionIcons = {
    Visibility: () => <Visibility style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    VisibilityOff: () => <VisibilityOff style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };

  const showStudentEvaluation = (
    enrollment_id: number,
    has_evaluation: boolean,
    studentName: string,
    studentSurnames: string,
  ) => {
    if (has_evaluation) {
      setStudentEnrollmentModal({ enrollment_id, view: true, data: { studentName, studentSurnames } });
    }
  };

  const tableData: MaterialTableProps<InternalProjectEnrollment> = {
    title: tKey('Participantes'),
    columns: [
      {
        title: tFormKey('Nombre'),
        field: 'name',
      },
      {
        title: tFormKey('Apellidos'),
        field: 'surnames',
      },
      {
        title: tFormKey('Tipo doc.'),
        field: 'document_type',
      },
      {
        title: tFormKey('Nº documento'),
        field: 'document_number',
      },
    ],
    data: enrollments,
    options: {
      selection: false,
      pageSize: 10,
      tableLayout: 'auto',
      showSelectAllCheckbox: true,
      paging: false,
    },
    actions: [
      (rowData: InternalProjectEnrollment) => ({
        icon: rowData.has_evaluation ? actionIcons.Visibility : actionIcons.VisibilityOff,
        onClick: () => showStudentEvaluation(rowData.id, rowData.has_evaluation, rowData.name, rowData.surnames),
        tooltip: tKey('Ver'),
      }),
    ],
  };

  return (
    <Fragment>
      <InformationMinimal />
      <div className={styles.tableContainer}>
        <TableWithEdit customContainer key={JSON.stringify(tableData.data)} tableData={tableData} permission={true} />
      </div>
      <Suspense fallback={<></>}>
        {showCreateEnrollment ? (
          <NewEnrollmentModal
            view={showCreateEnrollment}
            internal_project_id={internal_project_id}
            handleClose={() => setShowCreateEnrollment(false)}
          />
        ) : studentEnrollmentModal.view ? (
          <StudentEvaluationModal
            internal_project_id={internal_project_id}
            enrollment_id={studentEnrollmentModal.enrollment_id}
            view={studentEnrollmentModal.view}
            data={studentEnrollmentModal.data}
            handleClose={() => setStudentEnrollmentModal({ enrollment_id: 0, view: false })}
          />
        ) : null}
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  enrollments: state.internalProjectReducer.enrollments,
});

const mapDispatchToProps = (dispatch: InternalProjectDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(StudentEvaluation);
