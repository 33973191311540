import { Link } from 'react-router-dom';
import styles from './header.module.scss';
import React from 'react';

interface LogoIconProps {
  src: string;
  redirectHome: boolean;
}

const LogoIcon = ({ src, redirectHome }: LogoIconProps) => {
  const logoImage = <img src={src} className={styles.logo} alt="logo" />;
  if (!redirectHome) {
    return logoImage;
  }
  return <Link to={'/home'}>{logoImage}</Link>;
};

export default LogoIcon;
