import { Button, MenuItem } from '@material-ui/core';
import { ChangeHistory, Search } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { EntityUser, getEntityUsers } from '../../../../api/Entity/entity';
import { changeEducatorScholarships, getEducatorScholarship } from '../../../../api/Scholarship/scholarship';
import FormContainer from '../../../../components/Forms/FormContainer';
import Loading from '../../../../components/Loading/Loading';
import SelectController from '../../../../components/Select/SelectController';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { tErrorKey, tKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { EducatorScholarshipAcademicYear } from '../../../../redux/scholarship/definitions';
import { ChangeEntityEducatorSchema } from '../../../../validations/formSchema';
import style from './changeEntityEducator.module.scss';

interface ChangeEntityEducatorProps {
  entityId: number;
  showSnackbar: (message: string, severity: SeveritySnackbar) => void;
}

interface ChangeEntityEducatorInfo {
  current_educator_id: number;
  course: string;
  new_educator_id: number;
}

const ChangeEntityEducator: React.FC<ChangeEntityEducatorProps> = ({ entityId, showSnackbar }) => {
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [defaultValue, setDefaultValue] = useState({ educatorId: '', newEduacatorId: '' });
  const [entityEducators, setEntityEducators] = useState([] as EntityUser[]);
  const [academicYears, setAcademicYears] = useState([] as { academic_year: string }[]);
  const [tableData, setTableData] = useState<MaterialTableProps<{}>>({
    title: tKey('lista-de-becas'),
    data: [],
    columns: [
      {
        title: tKey('joven'),
        field: 'student_fullname',
        editable: 'never',
      },
      {
        title: tKey('educador'),
        field: 'educator_fullname',
        editable: 'never',
      },
      {
        title: tKey('Curso'),
        field: 'academy_year',
        editable: 'never',
      },
      {
        title: tKey('Estado'),
        field: 'scholarship_state',
        editable: 'never',
      },
    ],
    options: {
      selection: true,
      pageSize: 20,
      pageSizeOptions: [20, 50, 100],
    },
  });
  const [scholarshipSelected, setScholarshipSelected] = useState([] as number[]);
  const { errors, control, watch, setValue, setError, clearError } = useForm<ChangeEntityEducatorInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ChangeEntityEducatorSchema,
  });

  useEffect(() => {
    setLoading(true);
    getEntityUsers(entityId).then((users: { data: EntityUser[] }) => {
      setEntityEducators(users.data.filter((user: EntityUser) => user.position === 'educator'));
      setLoading(false);
    });
  }, [entityId, refresh]);

  if (loading) return <Loading big />;

  const onSearch = () => {
    if (!watch('current_educator_id')) setError('current_educator_id', 'required', tErrorKey('campo-obligatorio'));
    if (!watch('course')) setError('course', 'required', tErrorKey('campo-obligatorio'));

    if (!Object.keys(errors).includes('current_educator_id') && !Object.keys(errors).includes('course')) {
      setLoadingTable(true);
      getEducatorScholarship(watch('current_educator_id'), watch('course').replace('/', '-')).then(
        (res: { data: EducatorScholarshipAcademicYear[] }) => {
          const newEducatorScholarship = res.data.map((element: EducatorScholarshipAcademicYear) => {
            return {
              scholarship_id: element.scholarship_id,
              student_fullname: `${element.student_name} ${element.student_surname} ${element.student_surname2}`,
              educator_fullname: `${element.educator_name} ${element.educator_surname} ${element.educator_surname2}`,
              academy_year: element.academic_year,
              scholarship_state: element.state_name,
            };
          });
          setTableData({ ...tableData, data: newEducatorScholarship });
          setLoadingTable(false);
        },
      );
    }
  };

  const changeEducator = (data: any) => {
    const scholarship_selected = data.map((e: any) => e.scholarship_id);
    setScholarshipSelected(scholarship_selected);
  };

  const onChange = () => {
    if (!watch('current_educator_id')) setError('current_educator_id', 'required', tErrorKey('campo-obligatorio'));
    if (!watch('new_educator_id')) setError('new_educator_id', 'required', tErrorKey('campo-obligatorio'));

    if (
      !Object.keys(errors).includes('current_educator_id') &&
      !Object.keys(errors).includes('new_educator_id') &&
      scholarshipSelected.length > 0
    ) {
      changeEducatorScholarships(scholarshipSelected, watch('new_educator_id'))
        .then(() => {
          showSnackbar('El cambio de educador se ha efectuado correctamente', 'success');

          const updateEducatorScholarship = (tableData.data as []).filter(
            (element: any) =>
              watch('current_educator_id') === watch('new_educator_id') ||
              !scholarshipSelected.includes(element.scholarship_id),
          );
          setScholarshipSelected([]);
          setTableData({ ...tableData, data: updateEducatorScholarship });
          setDefaultValue({
            educatorId: '' + watch('current_educator_id'),
            newEduacatorId: '' + watch('new_educator_id'),
          });

          setRefresh(!refresh);
        })
        .catch(() => showSnackbar('Ha ocurrido un error', 'error'));
    }
  };

  return (
    <div className={style.formContainer}>
      <FormContainer title={tKey('cambio-de-educador')}>
        <div className={style.searchContainer}>
          <SelectController
            name="current_educator_id"
            label={tKey('educador')}
            defaultValue={watch('current_educator_id') || defaultValue.educatorId}
            schema={ChangeEntityEducatorSchema}
            control={control}
            errors={errors}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              const filteredAcademicYears = entityEducators.find(
                (user: EntityUser) => user.id === +(e.target as HTMLInputElement).value,
              );
              setAcademicYears(filteredAcademicYears?.academic_years || []);
              clearError('current_educator_id');
              setValue('course', '');
            }}
          >
            {entityEducators.map((educator: EntityUser) => (
              <MenuItem key={educator.id} value={educator.id}>
                {`${educator.name} ${educator.surname} ${educator.surname2}`}
              </MenuItem>
            ))}
          </SelectController>
          <SelectController
            name="course"
            label={tKey('Curso')}
            schema={ChangeEntityEducatorSchema}
            control={control}
            errors={errors}
            onClick={() => {
              clearError('course');
            }}
          >
            {academicYears.map((year: { academic_year: string }) => (
              <MenuItem key={year.academic_year} value={year.academic_year}>
                {year.academic_year}
              </MenuItem>
            ))}
          </SelectController>
          <Button variant="contained" startIcon={<Search />} color="primary" onClick={onSearch}>
            {tKey('buscar')}
          </Button>
        </div>
        <div className={style.changeContainer}>
          <SelectController
            name="new_educator_id"
            label={tKey('nuevo-educador')}
            defaultValue={watch('new_educator_id') || defaultValue.newEduacatorId}
            schema={ChangeEntityEducatorSchema}
            control={control}
            errors={errors}
            onClick={() => {
              clearError('new_educator_id');
            }}
          >
            {entityEducators.map((educator: EntityUser) => (
              <MenuItem key={educator.id} value={educator.id}>
                {`${educator.name} ${educator.surname} ${educator.surname2}`}
              </MenuItem>
            ))}
          </SelectController>
          <Button variant="contained" startIcon={<ChangeHistory />} color="primary" onClick={onChange}>
            {tKey('cambiar')}
          </Button>
        </div>
      </FormContainer>
      <TableWithEdit
        key={0}
        tableData={{ ...tableData, isLoading: loadingTable }}
        onSelectionChange={changeEducator}
        filtering={false}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar): void =>
    dispatch(showSnackbar(message, severity, undefined, 2000)),
});

export default connect(null, mapDispatchToProps)(ChangeEntityEducator);
