import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import LayoutLogin from '../../components/Layout/LayoutLogin';
import { tKey } from '../../helpers/translate';
import styles from './help.module.scss';

const Help: React.FC = () => {
  const history = useHistory();

  return (
    <LayoutLogin subtitle={tKey('Ayuda')}>
      <div className={styles.container}>
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec maximus non ex ut iaculis. Mauris rhoncus
          mollis magna, ac porta augue efficitur in. Pellentesque lacinia orci purus, non imperdiet ante dignissim ac.
          Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nam convallis
          vulputate risus, non varius lacus viverra vitae. Etiam consectetur ligula mi, in viverra quam vestibulum ut.
          Nunc accumsan orci in dolor tincidunt tincidunt. Sed sollicitudin sed sem in ultricies. Maecenas vestibulum ut
          neque sed ullamcorper.
        </span>
        <span>
          Morbi suscipit quam quis tellus cursus, a aliquet ipsum ultricies. Sed sem sem, commodo ut viverra sed,
          fermentum id dolor. Donec et dolor arcu. In imperdiet lorem sit amet dolor porttitor placerat. Sed a massa
          vehicula, interdum orci non, congue diam. Proin luctus dolor non purus faucibus, at fringilla nisl ornare.
          Curabitur ornare arcu sit amet tortor vestibulum, at posuere dui venenatis. Cras imperdiet vestibulum libero,
          vel ornare enim fringilla quis. Praesent augue dui, laoreet ut condimentum non, viverra id lectus. Nunc at
          velit convallis, gravida eros a, accumsan magna.
        </span>
      </div>
      <Button variant="outlined" color="primary" fullWidth onClick={() => history.push('/')}>
        Volver
      </Button>
    </LayoutLogin>
  );
};

export default Help;
