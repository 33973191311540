import React from 'react';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';
import {
  CodeSectionEvent,
  datos_basicos,
  EventData,
  inscritos,
  inscritos_educadores,
  inscritos_jovenes,
  SectionEvent,
  suscritos,
} from '../../../redux/event/definitions';

interface SubmenuEventProps {
  current_section: CodeSectionEvent;
  event: EventData;
  submenu: string;
}

const SubmenuEvent: React.FC<SubmenuEventProps> = ({ current_section, event, submenu }) => {
  const eventSections: SectionEvent[] = [datos_basicos, inscritos, suscritos];
  const eventSubSections: SectionEvent[] = [inscritos_jovenes, inscritos_educadores];

  const submenuEvent: SubmenuItem[] = eventSections.map(section =>
    section.code === 'inscritos'
      ? {
          title: tKey(section.name),
          type: 'items',
          items: eventSubSections.map(subsection => ({
            title: tKey(subsection.name),
            route: `/eventos/detalle/${event.id}/${section.code}/${subsection.code}`,
            type: 'route',
            selected: subsection.code === current_section,
          })),
        }
      : {
          title: tKey(section.name),
          type: 'route',
          route: `/eventos/detalle/${event.id}/${section.code}`,
          selected: section.code === current_section,
        },
  );

  return (
    <Submenu titleMenu={tKey('Datos del evento')} optionsMenu={submenuEvent} defaultOpen defaultOpenSubmenu={submenu} />
  );
};

export default SubmenuEvent;
