/* eslint-disable @typescript-eslint/no-explicit-any */
import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { connect } from 'react-redux';
import { ObjectSchema, Shape } from 'yup';
import FncAccordion from '../../../../components/Accordion/FncAccordion';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetData } from '../../../../redux/scholarship/actions';
import { ScholarshipData } from '../../../../redux/scholarship/definitions';
import {
  selectTotalApproval,
  selectTotalConfirmedScholarship,
  selectTotalMaterialCost,
  selectTotalRequested,
} from '../../../../redux/scholarship/selectors';
import styles from './confirmationForm.module.scss';

interface TotalsConfirmationFormProps<T extends FieldValues> {
  scholarshipData: ScholarshipData;
  readOnly: boolean;
  isNousCims: boolean;
  total_requested: number;
  total_approval: number;
  total_confirmation: number;
  totalMaterialCost: number;
  errors: FieldErrors<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  control: Control<T>;
  setData: (key: string, value: string | number) => void;
}

const TotalsConfirmationForm = <T extends FieldValues>({
  scholarshipData,
  readOnly,
  isNousCims,
  total_requested,
  total_approval,
  total_confirmation,
  totalMaterialCost,
  errors,
  schema,
  control,
  setData,
}: TotalsConfirmationFormProps<T>) => {
  const {
    adjustment_comment,
    adjustment_amount,
    enrollment_cost,
    enrollment_approval,
    enrollment_confirm,
    monthly_enrollment_cost,
    monthly_price_approval,
    monthly_price_confirm,
    materials_approval,
    materials_confirm,
    transportation_cost,
    transport_price_approval,
    transport_price_confirm,
    other_costs_amount,
    other_costs_approval,
    other_costs_confirm,
    able_to_pay,
    young_contrib_approval,
  } = scholarshipData;

  const renderAdjustmentView = (): JSX.Element => {
    return (
      <>
        {isNousCims ? (
          <TextInputController
            control={control}
            schema={schema}
            errors={errors}
            size="100"
            rows="4"
            rowsMax="4"
            disabled
            label={tScholarshipKey('Comentarios sobre ajuste')}
            name="adjustment_comment"
            multiline
            defaultValue={adjustment_comment}
          />
        ) : (
          undefined
        )}
        <TextInput
          type="number"
          disabled
          label={tScholarshipKey('Importe de ajuste')}
          size="100"
          name="adjustment_amount"
          value={adjustment_amount}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      </>
    );
  };

  return (
    <FormContainer
      title={tScholarshipKey('Confirmación de Importes')}
      subtitle={tScholarshipKey('subtitle-confirmacion-importes')}
    >
      <div className={`${styles.columns} ${styles.hasBorder}`}>
        <FncAccordion title={tScholarshipKey('Solicitado')}>
          <div className={styles.columnsWrapper}>
            <TextInput
              type="number"
              disabled
              size="100"
              label={tScholarshipKey('Matriculación')}
              name="enrollment_cost"
              value={enrollment_cost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Importe-mensualidad-hay')}
              size="100"
              name="monthly_enrollment_cost"
              value={monthly_enrollment_cost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />

            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Materiales')}
              size="100"
              name="totalMaterialCost"
              value={totalMaterialCost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Transporte')}
              size="100"
              name="transportation_cost"
              value={transportation_cost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('otros-importer-considerar')}
              size="100"
              name="other_costs_amount"
              value={other_costs_amount}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />

            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Cantidad que puede aportar el/la joven')}
              size="100"
              name="able_to_pay"
              value={able_to_pay}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </div>
        </FncAccordion>
        <FncAccordion title={tScholarshipKey('Aprobado')}>
          <div className={styles.columnsWrapper}>
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Matriculación aprobada')}
              size="100"
              name="enrollment_approval"
              value={enrollment_approval}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />

            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Importe o mensualidad aprobada')}
              size="100"
              name="monthly_price_approval"
              value={monthly_price_approval}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Materiales-aprobación')}
              size="100"
              name="materials_approval"
              value={materials_approval}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />

            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Transporte aprobación')}
              size="100"
              name="transport_price_approval"
              value={transport_price_approval}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Otros importes aprobados')}
              size="100"
              name="other_costs_approval"
              value={other_costs_approval}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </div>
        </FncAccordion>
        <FncAccordion title={tScholarshipKey('Confirmación')}>
          <div className={styles.columnsWrapper}>
            <TextInputController
              control={control}
              schema={schema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Matriculación confirmada')}
              size="100"
              name="enrollment_confirm"
              price
              defaultValue={enrollment_confirm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('enrollment_confirm', e.target.value)}
            />
            <TextInputController
              control={control}
              schema={schema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Importe o mensualidad confirmada')}
              size="100"
              name="monthly_price_confirm"
              price
              defaultValue={monthly_price_confirm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('monthly_price_confirm', e.target.value)}
            />
            <TextInputController
              control={control}
              schema={schema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Materiales confirmados')}
              size="100"
              name="materials_confirm"
              price
              defaultValue={materials_confirm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('materials_confirm', e.target.value)}
            />

            <TextInputController
              control={control}
              schema={schema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Transporte confirmado')}
              size="100"
              name="transport_price_confirm"
              price
              defaultValue={transport_price_confirm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('transport_price_confirm', e.target.value)}
            />
            <TextInputController
              control={control}
              schema={schema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Otros importes confirmados')}
              size="100"
              name="other_costs_confirm"
              price
              defaultValue={other_costs_confirm}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('other_costs_confirm', e.target.value)}
            />

            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Aportación del/la joven aprobada')}
              size="100"
              name="young_contrib_approval"
              value={young_contrib_approval}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </div>
        </FncAccordion>
      </div>
      <div className={`${styles.columns} ${styles.is2Cols}`}>{renderAdjustmentView()}</div>
      <div className={`${styles.columns}`}>
        <TextInput
          type="number"
          disabled
          label={tScholarshipKey('Total solicitado')}
          size="100"
          name="total_cost"
          value={total_requested.toFixed(2)}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
        <TextInput
          type="number"
          disabled
          label={tScholarshipKey('Total aprobado')}
          size="100"
          name="total_approval"
          value={total_approval.toFixed(2)}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
        <TextInput
          type="number"
          disabled
          label={tScholarshipKey('Total confirmación')}
          size="100"
          name="total_confirmation"
          value={total_confirmation.toFixed(2)}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      </div>
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  total_requested: selectTotalRequested(state),
  total_approval: selectTotalApproval(state),
  total_confirmation: selectTotalConfirmedScholarship(state),
  isNousCims: selectIsNousCims(state),
  totalMaterialCost: selectTotalMaterialCost(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalsConfirmationForm);
