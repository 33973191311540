import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styles from './modalProfile.module.scss';

interface ModalProfileProps {
  open: boolean;
  children: JSX.Element | JSX.Element[];
  title: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  onClose: () => void;
}

const ModalProfile: React.FC<ModalProfileProps> = ({ open, onClose, children, title, maxWidth }) => (
  <Dialog open={open} onClose={onClose} maxWidth={maxWidth} className={styles.root}>
    <IconButton onClick={onClose} aria-label="delete" className={styles.buttonClose} size="small">
      <CloseIcon fontSize="inherit" />
    </IconButton>
    <DialogContent className={styles.content}>{children}</DialogContent>
  </Dialog>
);

export default ModalProfile;
