import { ButtonProps } from '@material-ui/core';
import React, { Fragment } from 'react';
import styles from './formWithMenu.module.scss';
import ActionsButtons from './Menus/ActionsMenu/ActionsMenu';

interface EntitiesDataFormProps {
  children: JSX.Element | JSX.Element[];
  actionsButtons?: ButtonProps[];
  hiddenButtons?: boolean;
}

const EntitiesDataForm: React.FC<EntitiesDataFormProps> = ({ children, actionsButtons, hiddenButtons = true }) => {
  return (
    <Fragment>
      <div className={hiddenButtons ? styles.containerWithMenu : undefined}>{children}</div>
      {hiddenButtons && (
        <div className={styles.menuRight}>
          <ActionsButtons actionsButtons={actionsButtons} />
        </div>
      )}
    </Fragment>
  );
};

export default EntitiesDataForm;
