import { useState } from 'react';
import { PaymentInfo, PaymentSettingsData } from '../../../../redux/project/definitions';
import { ScholarshipPayment } from '../../../../redux/scholarship/definitions';

type UseScholarshipPaymentInfoType = {
  showPaymentModal: boolean;
  paymentData: PaymentInfo;
  isStudentTransfer: boolean;
  closePaymentModal: () => void;
  setPaymentInfo: (scholarshipPayment: ScholarshipPayment, paymentSettings: PaymentSettingsData) => void;
};

const useScholarshipPaymentInfo = (): UseScholarshipPaymentInfoType => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentData, setPaymentData] = useState({} as PaymentInfo);
  const [isStudentTransfer, setIsStudentTransfer] = useState(false);

  const setPaymentInfo = (scholarshipPayment: ScholarshipPayment, paymentSettings: PaymentSettingsData) => {
    setPaymentData({
      id: scholarshipPayment.scholarship_id,
      account_number: scholarshipPayment.account_number || '',
      name: scholarshipPayment.student_name,
      provider_code: scholarshipPayment.provider_code,
      amount: scholarshipPayment.payment_amount,
      payment_date: scholarshipPayment.payment_date,
      document_number: scholarshipPayment.document_number,
      agreement_number: scholarshipPayment.agreement_number,
      payment_field: scholarshipPayment.payment_field,
      concept: paymentSettings.concept,
      description_project: paymentSettings.description,
      center_cost_area_code: paymentSettings.center_cost_area_code,
      center_cost_channel_code: paymentSettings.center_cost_channel_code,
      center_cost_delegation_code: paymentSettings.center_cost_delegation_code,
      center_cost_project_code: paymentSettings.center_cost_project_code,
    } as PaymentInfo);
    setShowPaymentModal(true);
    setIsStudentTransfer(scholarshipPayment.is_student_transfer);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  return {
    showPaymentModal,
    paymentData,
    isStudentTransfer,
    closePaymentModal,
    setPaymentInfo,
  };
};

export default useScholarshipPaymentInfo;
