import { ButtonProps } from '@material-ui/core';
import { useState } from 'react';
import isObjKey from '../../helpers/guardas/isObjKey';

export interface GenericObj {
  [key: string]: {
    [prop: string]: any;
  };
}

export default function useButtons(buttons: { [b: string]: ButtonProps }) {
  const [stateButtons, setStateButtons] = useState(buttons);

  const setButtonProperty = (payload: GenericObj) => {
    setStateButtons(prevButtons => {
      Object.keys(payload).forEach(key => {
        if (isObjKey(key, stateButtons)) {
          prevButtons[key] = { ...prevButtons[key], ...payload[key] };
        }
      });
      return { ...prevButtons };
    });
  };
  return { stateButtons, setButtonProperty };
}
