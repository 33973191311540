import { ButtonProps } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import React from 'react';
import Developing from '../../components/DevelopingFeedback/DevelopingFeedback';
import Layout from '../../components/Layout/Layout';
import ActionsMenu from '../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { tKey } from '../../helpers/translate';
import ZingNetworkSubmenu from './ZingNetworkSubMenu';

const Events: React.FC = () => {
  const buttonsActionMenu: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: () => console.log('guardar'),
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
      fullWidth: true,
      disabled: true,
      startIcon: <Save />,
    },
  ];

  return (
    <Layout
      leftSubmenu={<ZingNetworkSubmenu selected="events" />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonsActionMenu} />}
    >
      <Developing />
    </Layout>
  );
};

export default Events;
