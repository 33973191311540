import * as yup from 'yup';

export const paymentSettingsForm = {
  center_cost_channel_code: yup.string(),
  center_cost_delegation_code: yup.string(),
  center_cost_area_code: yup.string(),
  center_cost_project_code: yup.string(),
  agreement_number: yup.string().matches(/^[A-Za-z0-9]{9,12}$/, 'Debe ser un código alfanumérico de 9 a 12 caracteres'),
  description: yup.string(),
  concept: yup.string(),
};
