/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';

import { unsubscribeParticipantFromApi } from '../../../../api/InternalProject/Participants/unsubscribeParticipant';
import errorMessage from '../../../../helpers/errorMessage';
import { showSnackbar } from '../../../FeedbackAPI/actions';
import { unSubscribeParticipantKo, unSubscribeParticipantOk } from './action';
import { UnSubscribeParticipantConst } from './action_types';
import { UnSubscribeParticipantAction } from './definition';

function* unsubscribeParticipantFromSaga(action: UnSubscribeParticipantAction): Generator<any, void, any> {
  try {
    const { internal_project_id, participantId } = action;
    const unsubscribed_at = yield unsubscribeParticipantFromApi(internal_project_id, participantId);
    yield put(unSubscribeParticipantOk(internal_project_id, participantId, unsubscribed_at));
    yield put(showSnackbar('Se ha dado de baja el participante', 'success', undefined, 3000));
  } catch (e) {
    yield put(showSnackbar('No se ha podido dar de baja el participante', 'error', undefined, 1500));
    yield put(unSubscribeParticipantKo(errorMessage(e)));
  }
}

export default takeLatest<UnSubscribeParticipantAction>(
  UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT,
  unsubscribeParticipantFromSaga,
);
