import colorPalette from '../helpers/colorPalette';

export default {
  primary: {
    main: colorPalette.primary,
  },
  secondary: {
    color: colorPalette.secondary,
    main: colorPalette.secondary,
  },
};
