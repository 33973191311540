/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import FormContainer from '../../../components/Forms/FormContainer';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Title from '../../../components/Title/Title';
import * as DateConstants from '../../../constants/date';
import { getLastItem } from '../../../helpers/getLastItem';
import { tFormKey, tKey } from '../../../helpers/translate';
import { getInternalProjectSettings, updateInternalProjectSettings } from '../../../redux/internal-projects/actions';
import { InternalProjectSettings } from '../../../redux/internal-projects/definitions';
import { selectInternalProjectSettings, selectIpLoading } from '../../../redux/internal-projects/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { selectLookupCategory } from '../../../redux/translations/selectors';
import SubmenuInternalProjects from '../../Configuration/InternalProjects/SubmenuInternalProjects';

interface InternalProjectsSettingsProps extends RouteComponentProps {
  disabled?: boolean;
  settings: InternalProjectSettings;
  canIconfigInternalProjects: boolean;
  updateSettings: (data: InternalProjectSettings) => void;
  getSettings: () => void;
}
const InternalProjectsSettings: React.FC<InternalProjectsSettingsProps> = ({
  disabled,
  settings,
  canIconfigInternalProjects,
  match,
  updateSettings,
  getSettings,
}) => {
  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const { errors, register, handleSubmit } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
  });

  const history = useHistory();
  const [endDate, setEndDate] = useState<any | null>(null);
  const { path } = match;
  const selectedSection = getLastItem(path);
  const assingDateValue = (date: string | number | null) => {
    return date ? moment(date, DateConstants.DATE_FORMAT) : null;
  };

  const changeDates = (date: moment.Moment | null) => {
    if (date && date.isValid()) {
      const course_end_date = date.format(DateConstants.DATE_FORMAT);
      setEndDate(course_end_date);
    }
  };

  const handleSave = () => {
    updateSettings({ id: settings.id, course_end_date: endDate });
  };

  const actionButton: ButtonProps[] = [
    {
      children: 'Guardar',
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
      fullWidth: true,
      type: 'submit',
      onClick: handleSubmit(handleSave),
    },
  ];

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuInternalProjects
          canConfigure={canIconfigInternalProjects}
          selected={selectedSection}
          submenu={selectedSection}
        />
      }
      rightSubmenu={
        <ActionsMenu
          actionsButtons={actionButton}
          goBack
          onGoBack={() => history.push('/configuracion/proyectos-internos')}
        />
      }
    >
      <Title>{tFormKey('Configuración fecha fin de curso')}</Title>
      <form onSubmit={handleSubmit(handleSave)}>
        <FormContainer title={tKey('Fecha fin de curso')}>
          <KeyboardDatePicker
            name="creation_date"
            label={tFormKey('Fecha fin de curso')}
            error={!!errors.creation_date}
            format={DateConstants.DATE_FORMAT}
            variant="inline"
            inputVariant="outlined"
            inputRef={register}
            helperText={errors.creation_date}
            value={
              !!assingDateValue(endDate) ? assingDateValue(endDate) || '' : assingDateValue(settings.course_end_date)
            }
            onChange={(date: MaterialUiPickersDate) => {
              changeDates(date);
            }}
            autoOk
            disabled={disabled}
          />
        </FormContainer>
      </form>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIpLoading(state),
  settings: selectInternalProjectSettings(state),
  lookUpSubArea: selectLookupCategory(state),
  canIconfigInternalProjects: selectUserCan(state)('route_internal_projects'),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateSettings: (data: InternalProjectSettings) => dispatch(updateInternalProjectSettings(data)),
  getSettings: (): void => dispatch(getInternalProjectSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectsSettings);
