const SPANISH_ACCOUNT_NUMBER = /([a-zA-Z]{2})(\d{2})(\d{4})(\d{4})(\d{2})(\d{10})/;
function getnumIBAN(letra: string) {
  const ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return ls_letras.search(letra) + 10;
}
function modulo97(iban: string) {
  const parts = Math.ceil(iban.length / 7);
  let remainer = '';

  for (let i = 1; i <= parts; i++) {
    remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
  }

  return remainer;
}
function validationAccountNumber(IBAN: string): boolean {
  if (!SPANISH_ACCOUNT_NUMBER.test(IBAN)) return false;

  IBAN = IBAN.toUpperCase();
  IBAN = IBAN.trim();
  IBAN = IBAN.replace(/\s/g, '');
  let isbanaux;
  if (IBAN.length !== 24) {
    return false;
  }

  const letra1 = IBAN.substring(0, 1);
  const letra2 = IBAN.substring(1, 2);
  const num1 = getnumIBAN(letra1);
  const num2 = getnumIBAN(letra2);
  isbanaux = String(num1) + String(num2) + IBAN.substring(2);
  isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

  const resto = modulo97(isbanaux);
  if (resto === '1') {
    return true;
  } else {
    return false;
  }
}

export default validationAccountNumber;
