import * as H from 'history';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../../components/Loading/Loading';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { exportToExcel } from '../../../../helpers/exportToXslx';
import { tKey } from '../../../../helpers/translate';
import { getAreas } from '../../../../redux/area/action';
import { SubAreaInfo } from '../../../../redux/area/definitions';
import { selectSubAreasList } from '../../../../redux/area/selectors';
import { EventData, EventDataDispatch } from '../../../../redux/event/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { UserData } from '../../../../redux/user/definitions';
import SubmenuStudent from '../../SubmenuStudent/SubmenuStudent';
import styles from './scholarshipList.module.scss';

interface EventProps {
  id: string;
  readOnly: boolean;
  loading: boolean;
  subList: SubAreaInfo[];
  location: H.Location<{ needsValidation: boolean }>;
  avatar: string;
  changeAvatar: boolean;
  profileData: UserData;
  getAreas: () => void;
  handleSetImageById: (image: string) => void;
  onSelectComponent: (componentId: number) => void;
}

const EventList: React.FC<EventProps> = ({
  avatar,
  profileData,
  loading,
  subList,
  getAreas,
  location,
  changeAvatar,
  handleSetImageById,
  onSelectComponent,
}) => {
  const [subareas, setSubareas] = useState<SubAreaInfo[]>([]);
  const history = useHistory();

  useEffect(() => {
    getAreas();
  }, [getAreas]);

  useEffect(() => {
    if (subList.length > 0) {
      setSubareas(subList);
    }
  }, [subList]);

  const initialData = profileData.events.map(event => {
    typeof event.assistance === 'number'
      ? (event.assistance_label = event.assistance ? tKey('Sí') : tKey('No'))
      : (event.assistance_label = '-');
    event.eventType_label = event.eventType === 'subscription' ? tKey('Subscrito') : tKey('Registrado');
    event.manual_label = event.manual ? tKey('Sí') : tKey('No');
    return event;
  });

  const eventDetails = (rowData: EventData) => {
    const { id } = rowData;
    history.push({
      pathname: `/eventos/detalle/${id}/datos_basicos`,
      state: { id },
    });
  };

  const tableData: MaterialTableProps<EventData> = {
    title: tKey('Lista de Eventos'),
    columns: [
      {
        title: tKey('Fecha inicio'),
        field: 'start_date',
        render: (rowData: EventData) => {
          if (typeof rowData.start_date === 'string') return rowData.start_date.split(' ')[0];
        },
      },
      { title: tKey('Título'), field: 'title' },
      { title: tKey('Provincia'), field: 'province' },
      { title: tKey('Tipo'), field: 'type' },
      {
        title: tKey('Subárea'),
        field: 'subarea_id',
        render: (rowData: EventData): any => {
          const obj = subareas.find(sub => sub.id === rowData.subarea_id);
          return obj?.name || 'Ninguna subárea asignada';
        },
      },
      {
        title: tKey('Aforo máximo'),
        field: 'max_capacity',
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        title: tKey('Año académico'),
        field: 'academic_year',
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        title: tKey('Manual'),
        field: 'manual_label',
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
      },
      {
        title: tKey('Tipo inscripción'),
        field: 'eventType_label',
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
        headerStyle: {
          textAlign: 'center',
        },
      },
      {
        title: tKey('Asistencia'),
        field: 'assistance_label',
        cellStyle: rowData => ({
          textAlign: 'center',
        }),
      },
    ],
    data: initialData,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Eventos`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu />}
      leftSubmenu={
        <SubmenuStudent
          selected="events"
          avatar={avatar}
          loading={loading}
          onSelectComponent={onSelectComponent}
          setImage={changeAvatar ? handleSetImageById : undefined}
        />
      }
    >
      <React.Fragment>
        {!loading ? (
          <div className={styles.list}>
            <TableWithEdit tableData={{ ...tableData, isLoading: loading }} onRowClick={eventDetails} />
          </div>
        ) : (
          <Loading big />
        )}
      </React.Fragment>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  subList: selectSubAreasList(state),
});

const mapDispatchToProps = (dispatch: EventDataDispatch | any) => ({
  getAreas: (): void => dispatch(getAreas()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventList);
