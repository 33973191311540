import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { zeroToFiveOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import Title from '../../../../components/Title/Title';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import { tScholarshipKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetInterviewInfoData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import { ScholarshipData } from '../../../../redux/scholarship/definitions';
import { selectGetScholarshipDocumentationPerCode } from '../../../../redux/scholarship/selectors';
import { scholarshipStateTransitionAction } from '../../../../redux/scholarship/transitions/actions';
import { interviewSchema } from '../../../../validations/formSchema';
import styles from './interviewForm.module.scss';

interface InterviewFormProps {
  scholarshipData: ScholarshipData;
  documentation: DocumentationItem[];
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  buttonActionSendInterview: ButtonProps;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
  sendInterview: (id: number, scholarshipData: ScholarshipData) => void;
}

const InterviewForm: React.FC<InterviewFormProps> = ({
  scholarshipData,
  documentation,
  readOnly,
  buttonActionSave,
  buttonActionSendInterview,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  setData,
  saveData,
  sendInterview,
}) => {
  const actions = ['checked', 'upload', 'download', 'view', 'remove'];
  const {
    id,
    interview_assessment,
    interview_comment,
    motivation_assessment,
    motivation_comment,
    itinerary_assessment,
    itinerary_comment,
    capacities_assessment,
    capacities_comment,
  } = scholarshipData;

  const { register, control, errors, handleSubmit, clearError } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: interviewSchema,
  });

  const saveInterviewInfo = () => {
    clearError();
    saveData(scholarshipData);
  };

  const sendInterviewInfo = () => {
    clearError();
    sendInterview(id, scholarshipData);
  };

  // const buttonActionSave = getButtonType(KeyButton.SAVE);
  buttonActionSave.hidden = readOnly;

  // const buttonActionSendInterview = getButtonType(KeyButton.SEND_INTERVIEW);

  useButtonAction(buttonActionSave, saveInterviewInfo);
  useButtonAction(buttonActionSendInterview, handleSubmit(sendInterviewInfo));

  return (
    <div className={styles.containerWithMenu}>
      <div className={styles.container}>
        <Title>{tScholarshipKey('Entrevista')}</Title>
        <form>
          <FormContainer title="">
            <RadioButtonGroup
              name="interview_assessment"
              errors={errors.interview_assessment}
              disabled={readOnly}
              questionText={tScholarshipKey('¿Qué valoración haces de la entrevista al joven?')}
              options={zeroToFiveOptions}
              value={interview_assessment?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('interview_assessment', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Comentario')}
              size="100"
              rows="4"
              rowsMax="10"
              schema={interviewSchema}
              multiline
              name="interview_comment"
              defaultValue={interview_comment}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('interview_comment', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            <RadioButtonGroup
              name="motivation_assessment"
              errors={errors.motivation_assessment}
              disabled={readOnly}
              questionText={tScholarshipKey('Motivación General')}
              options={zeroToFiveOptions}
              value={motivation_assessment?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('motivation_assessment', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Comentario')}
              size="100"
              rows="4"
              rowsMax="10"
              schema={interviewSchema}
              multiline
              name="motivation_comment"
              defaultValue={motivation_comment}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('motivation_comment', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            <RadioButtonGroup
              name="itinerary_assessment"
              errors={errors.itinerary_assessment}
              disabled={readOnly}
              questionText={tScholarshipKey('Itinerario')}
              options={zeroToFiveOptions}
              value={itinerary_assessment?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('itinerary_assessment', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Comentario')}
              size="100"
              rows="4"
              rowsMax="10"
              schema={interviewSchema}
              multiline
              name="itinerary_comment"
              defaultValue={itinerary_comment}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('itinerary_comment', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            <RadioButtonGroup
              name="capacities_assessment"
              errors={errors.capacities_assessment}
              disabled={readOnly}
              questionText={tScholarshipKey('Competencias')}
              options={zeroToFiveOptions}
              value={capacities_assessment?.toString() || '0'}
              register={register}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('capacities_assessment', e.target.value);
              }}
            />
            <TextInputController
              control={control}
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Comentario')}
              size="100"
              rows="4"
              rowsMax="10"
              schema={interviewSchema}
              multiline
              name="capacities_comment"
              defaultValue={capacities_comment}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('capacities_comment', e.target.value)}
            />
          </FormContainer>
          <FormContainer title="">
            {actions && (
              <FncAddDocuments
                title={tScholarshipKey('Documentos')}
                documents={documentation}
                getFile={getFile}
                actions={actions}
                disabled={readOnly}
                upload={uploadDocument}
                genericId={id}
                download={downloadDocument}
                remove={deleteDocument}
              />
            )}
          </FormContainer>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  documentation: selectGetScholarshipDocumentationPerCode(state, ['ENT']),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetInterviewInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
  sendInterview: (id: number, scholarshipData: ScholarshipData): void =>
    dispatch(scholarshipStateTransitionAction(id, 's04s05', '/entrevistas/lista', scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewForm);
