import { put, takeLatest } from '@redux-saga/core/effects';
import {
  createScholarshipJustificationsFromApi,
  getScholarshipJustificationsFromApi,
  removeScholarshipJustificationsFromApi,
} from '../../../api/Scholarship/justifications';
import { Await } from '../../../api/api';
import errorMessage from '../../../helpers/errorMessage';
import { Justification } from './Justification';
import { ScholarshipJustificationsConst } from './action_types';
import {
  addScholarshipJustificationsActionOk,
  deleteScholarshipJustificationsActionOk,
  getScholarshipJustificationsActionOk,
  scholarshipJustificationsActionKo,
} from './actions';
import { AddJustificationsAction, DeleteJustificationsAction, GetJustificationsAction } from './definitions.d';

function* getScholarshipJustificationsFromSaga(action: GetJustificationsAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const data = (yield getScholarshipJustificationsFromApi(id)) as Await<Justification[]>;
    yield put(getScholarshipJustificationsActionOk(data));
  } catch (e) {
    yield put(scholarshipJustificationsActionKo(errorMessage(e)));
  }
}

function* addScholarshipJustificationsFromSaga(action: AddJustificationsAction): Generator<any, void, any> {
  try {
    const { justification } = action;
    yield createScholarshipJustificationsFromApi(justification);
    yield put(addScholarshipJustificationsActionOk(justification));
  } catch (e) {
    yield put(scholarshipJustificationsActionKo(errorMessage(e)));
  }
}

function* deleteScholarshipJustificationsFromSaga(action: DeleteJustificationsAction): Generator<any, void, any> {
  try {
    const { justification } = action;
    yield removeScholarshipJustificationsFromApi(justification);
    yield put(deleteScholarshipJustificationsActionOk(justification));
  } catch (e) {
    yield put(scholarshipJustificationsActionKo(errorMessage(e)));
  }
}

export default [
  takeLatest<GetJustificationsAction>(
    ScholarshipJustificationsConst.GET_SCHOLARSHIP_JUSTIFICATIONS,
    getScholarshipJustificationsFromSaga,
  ),
  takeLatest<AddJustificationsAction>(
    ScholarshipJustificationsConst.ADD_SCHOLARSHIP_JUSTIFICATIONS,
    addScholarshipJustificationsFromSaga,
  ),
  takeLatest<DeleteJustificationsAction>(
    ScholarshipJustificationsConst.DELETE_SCHOLARSHIP_JUSTIFICATIONS,
    deleteScholarshipJustificationsFromSaga,
  ),
];
