import { ButtonProps, InputAdornment, InputLabel, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import FormContainer from '../../../../components/Forms/FormContainer';
import InfoLimitChip from '../../../../components/InfoChip/InfoLimitChip';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import SelectController from '../../../../components/Select/SelectController';
import Title from '../../../../components/Title/Title';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tScholarshipKey } from '../../../../helpers/translate';
import { validateFields } from '../../../../helpers/validator';
import { AuthState } from '../../../../redux/auth/reducers';
import { selectAuth, selectIsNousCims } from '../../../../redux/auth/selectors';
import { DocumentationItem, IdNamePair } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetCostInfoData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import {
  ScholarshipData,
  ScholarshipMetadata,
  ScholarshipSteps,
  Transportation,
} from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentationPerCode,
  selectGetScholarshipMetadata,
  selectScholarshipValidationErrors,
  selectTotalCost,
  selectTotalEnrollmentCost,
} from '../../../../redux/scholarship/selectors';
import RoleCode from '../../../../types/Roles/roleCode';
import { scholarshipCostSchema } from '../../../../validations/scholarshipSchema';
import MaterialsView from '../../Materials/MaterialsView';
import styles from './requestForms.module.scss';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions } from '../../../../components/RadioButtons/RadioButtonOptions';

interface ScholarshipCostFormProps {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  documentation: DocumentationItem[];
  readOnly: boolean;
  auth: AuthState;
  buttonActionSave: ButtonProps;
  validationErrors: APIValidationError | null;
  total_enrollment_cost: number;
  total_cost: number;
  isNousCims: boolean;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
}

const ScholarshipCostForm: React.FC<ScholarshipCostFormProps> = ({
  scholarshipData,
  scholarshipMetadata,
  documentation,
  readOnly,
  auth,
  buttonActionSave,
  validationErrors,
  total_enrollment_cost,
  total_cost,
  isNousCims,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
  setData,
  saveData,
}) => {
  const {
    id,
    enrollment_cost,
    monthly_enrollment_cost,
    transportation_id,
    transportation_cost,
    other_costs_amount,
    transport_type_quantity,
    other_costs,
    able_to_pay,
    how_to_pay,
    other_transportation_options,
    isEntity,
    state_scholarship,
    why_not_renewal,
  } = scholarshipData;

  const { transports } = scholarshipMetadata;

  const roleCode = new RoleCode(auth.userInfo.role_code);
  const isCustomer = roleCode.isStudent() || roleCode.isEducador() || roleCode.isResponsableEntidad();

  const { handleSubmit, register, errors, setError, control } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: scholarshipCostSchema,
  });

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  useValidatorAPI(validationErrors, setError);

  const saveScholarshipCostInfo = () => {
    saveData(scholarshipData);
  };

  useButtonAction(buttonActionSave, handleSubmit(saveScholarshipCostInfo));

  const validatorInfo = {
    data: scholarshipData,
    step: 'scholarshipCost' as keyof ScholarshipSteps,
  };

  const transportCostDisabled = (): boolean => {
    const trans = transports.find((t: Transportation) => t.id === transportation_id);
    if (trans && trans.cost) {
      return parseFloat(trans.cost.toString()) !== 0;
    }
    return false;
  };

  const renderTotalCosts = (): JSX.Element | undefined => {
    if (isNousCims) {
      return (
        <TextInput
          type="number"
          disabled
          label={tScholarshipKey('Importe total de los estudios')}
          size="50"
          name="total_cost"
          value={total_cost.toFixed(2)}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      );
    }
    return undefined;
  };

  const renderDocuments = (): JSX.Element | undefined => {
    if (documentation.length > 0) {
      const actions = ['checked', 'upload', 'download', 'view', 'remove'];
      return (
        <>
          <FncAddDocuments
            title={tScholarshipKey('Documentos')}
            documents={documentation}
            getFile={getFile}
            actions={actions}
            disabled={readOnly}
            upload={uploadDocument}
            genericId={id}
            download={downloadDocument}
            remove={deleteDocument}
            required
            addDoc={addDoc}
            multidocument
          />
          <MessageInfo>{tScholarshipKey('info-relevante-beca')}</MessageInfo>
        </>
      );
    }
    return undefined;
  };

  const renderStateScholarshipView = (): JSX.Element | undefined => {
    if (!isEntity) {
      return undefined;
    }

    return (
      <FormContainer title="">
        <RadioButtonGroup
          name="state_scholarship"
          disabled={readOnly}
          questionText={tScholarshipKey('solicitado-beca')}
          options={yesNoOptions}
          register={register}
          value={state_scholarship.toString()}
          validator={validateFields(scholarshipData, 'state_scholarship', validatorInfo.step)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setData('state_scholarship', e.target.value === '1' ? 1 : 0);
          }}
        />
        <div className={styles.compoundItem}>
          <InputLabel>{tScholarshipKey('justifica-no-solicitud')}</InputLabel>
          <TextInputController
            control={control}
            schema={scholarshipCostSchema}
            errors={errors}
            disabled={!!state_scholarship || readOnly}
            size="100"
            multiline
            rows="4"
            rowsMax="10"
            name="why_not_renewal"
            defaultValue={why_not_renewal}
            validator={validateFields(scholarshipData, 'why_not_renewal', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('why_not_renewal', e.target.value)}
          />
        </div>
        {renderDocuments()}
      </FormContainer>
    );
  };

  return (
    <form onSubmit={e => e.preventDefault()} className={styles.form}>
      <Title>{tScholarshipKey('SOLICITUD DE BECA - IMPORTE DE LA BECA')}</Title>

      <FormContainer title="">
        <TextInputController
          control={control}
          schema={scholarshipCostSchema}
          type="number"
          errors={errors}
          disabled={readOnly}
          label={tScholarshipKey('Importe matrícula')}
          size="50"
          name="enrollment_cost"
          price
          defaultValue={enrollment_cost}
          validator={validateFields(scholarshipData, 'enrollment_cost', validatorInfo.step)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('enrollment_cost', e.target.value)}
        />
        {isCustomer ? (
          <></>
        ) : (
          <InfoLimitChip
            text={tScholarshipKey('Importe medio de matrícula del año anterior:')}
            input={enrollment_cost}
            limit={scholarshipData.enrollment_avg}
            deviation={scholarshipData.material_dev}
            currency
          />
        )}
        <TextInputController
          control={control}
          schema={scholarshipCostSchema}
          type="number"
          errors={errors}
          disabled={readOnly}
          label={tScholarshipKey('importe-mensualidades-privados')}
          size="50"
          name="monthly_enrollment_cost"
          price
          defaultValue={monthly_enrollment_cost}
          validator={validateFields(scholarshipData, 'monthly_enrollment_cost', validatorInfo.step)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('monthly_enrollment_cost', e.target.value)}
        />
        {isCustomer ? (
          <></>
        ) : (
          <InfoLimitChip
            text={tScholarshipKey('Importe medio de mensualidades del año anterior:')}
            input={monthly_enrollment_cost}
            limit={scholarshipData.monthly_price_avg}
            deviation={scholarshipData.monthly_price_dev}
            currency
          />
        )}

        <TextInput
          type="number"
          disabled
          label={tScholarshipKey('Importe matriculación Total')}
          size="50"
          name="total_enrollment_cost"
          value={total_enrollment_cost.toFixed(2)}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      </FormContainer>
      <MaterialsView
        scholarshipData={scholarshipData}
        readOnly={readOnly}
        isCustomer={isCustomer}
        uploadDocument={uploadDocument}
        downloadDocument={downloadDocument}
        getFile={getFile}
        deleteDocument={deleteDocument}
        addDoc={addDoc}
      />

      <FormContainer title={tScholarshipKey('Transporte')}>
        <SelectController
          control={control}
          errors={errors}
          schema={scholarshipCostSchema}
          size="50"
          name="transportation_id"
          label={tScholarshipKey('Transporte')}
          defaultValue={transportation_id}
          validator={validateFields(scholarshipData, 'transportation_id', validatorInfo.step)}
          disabled={readOnly}
          onClick={e => handleSelectChange(e.target)}
        >
          {transports.map((element: IdNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          errors={errors}
          schema={scholarshipCostSchema}
          size="50"
          name="transport_type_quantity"
          label={tScholarshipKey('Número de tickets necesarios')}
          defaultValue={transport_type_quantity}
          validator={validateFields(scholarshipData, 'transport_type_quantity', validatorInfo.step)}
          disabled={readOnly}
          onClick={e => handleSelectChange(e.target)}
        >
          {[1, 2, 3].map((element: number) => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>

        {transportCostDisabled() || readOnly ? (
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Importe del transporte')}
            size="50"
            name="transportation_cost"
            value={transportation_cost || 0.0}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        ) : (
          <TextInputController
            control={control}
            schema={scholarshipCostSchema}
            type="number"
            errors={errors}
            disabled={transportCostDisabled() || readOnly}
            label={tScholarshipKey('Importe del transporte')}
            size="50"
            name="transportation_cost"
            price
            defaultValue={transportation_cost || 0.0}
            validator={validateFields(scholarshipData, 'transportation_cost', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('transportation_cost', e.target.value)}
          />
        )}
        {isCustomer ? (
          <></>
        ) : (
          <InfoLimitChip
            text={tScholarshipKey('Importe medio del transporte del año anterior:')}
            input={transportation_cost}
            limit={scholarshipData.transport_avg}
            deviation={scholarshipData.transport_dev}
            currency
          />
        )}

        <div className={styles.compoundItem}>
          <InputLabel>{tScholarshipKey('¿Otras opciones? Cantidad y justificación')}</InputLabel>
          <TextInputController
            control={control}
            schema={scholarshipCostSchema}
            errors={errors}
            disabled={transportCostDisabled() || readOnly}
            size="100"
            multiline
            rows="4"
            rowsMax="10"
            name="other_transportation_options"
            defaultValue={other_transportation_options}
            validator={validateFields(scholarshipData, 'other_transportation_options', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('other_transportation_options', e.target.value)}
          />
        </div>
      </FormContainer>
      <FormContainer title={tScholarshipKey('Otros importes a considerar')}>
        <TextInputController
          control={control}
          schema={scholarshipCostSchema}
          type="number"
          errors={errors}
          disabled={readOnly}
          label={tScholarshipKey('Otros importes a considerar')}
          size="50"
          name="other_costs_amount"
          price
          defaultValue={other_costs_amount}
          validator={validateFields(scholarshipData, 'other_costs_amount', validatorInfo.step)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('other_costs_amount', e.target.value)}
        />
        <div className={styles.compoundItem}>
          <InputLabel>{tScholarshipKey('¿Qué otros importes?')}</InputLabel>
          <TextInputController
            control={control}
            schema={scholarshipCostSchema}
            errors={errors}
            disabled={!other_costs_amount || readOnly}
            size="100"
            multiline
            rows="4"
            rowsMax="10"
            name="other_costs"
            defaultValue={other_costs}
            validator={validateFields(scholarshipData, 'other_costs', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('other_costs', e.target.value)}
          />
        </div>
        {renderTotalCosts()}
        <TextInputController
          control={control}
          schema={scholarshipCostSchema}
          type="number"
          errors={errors}
          disabled={readOnly}
          label={tScholarshipKey('cantidad-aporta-familia')}
          size="50"
          name="able_to_pay"
          price
          defaultValue={able_to_pay}
          validator={validateFields(scholarshipData, 'able_to_pay', validatorInfo.step)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('able_to_pay', e.target.value)}
        />
        <div className={styles.compoundItem}>
          <InputLabel>{tScholarshipKey('¿Cómo lo podrían asumir?')}</InputLabel>
          <TextInputController
            control={control}
            schema={scholarshipCostSchema}
            errors={errors}
            disabled={!able_to_pay || readOnly}
            size="100"
            multiline
            rows="4"
            rowsMax="10"
            name="how_to_pay"
            defaultValue={how_to_pay}
            validator={validateFields(scholarshipData, 'how_to_pay', validatorInfo.step)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('how_to_pay', e.target.value)}
          />
        </div>
      </FormContainer>
      {renderStateScholarshipView()}
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  auth: selectAuth(state),
  documentation: selectGetScholarshipDocumentationPerCode(state, ['RES']),
  validationErrors: selectScholarshipValidationErrors(state),
  total_enrollment_cost: selectTotalEnrollmentCost(state),
  total_cost: selectTotalCost(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetCostInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScholarshipCostForm);
