import React from 'react';
import { Controller, FieldError } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../../helpers/customHooks/useIsRequired';
import FncDatePicker from './FncDatePicker';

interface Props<T extends FieldValues> {
  name: string;
  control: Control<T>;
  errors: FieldErrors<T>;
  label: string;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  defaultValue?: string;
  disabled?: boolean;
  customClass?: string;
}

const FncDatePickerController = <T extends FieldValues>({
  name,
  label,
  errors,
  schema,
  defaultValue,
  disabled,
  control,
  customClass,
  ...props
}: Props<T>) => {
  const hasError = errors[name] as FieldError;
  const required = useIsRequired(name, schema);
  return (
    <>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        customClass={customClass}
        as={
          <FncDatePicker
            label={label}
            errorMessage={hasError && hasError.message ? (hasError.message as string) : ''}
            required={required}
            disabled={disabled}
            {...props}
          />
        }
      />
    </>
  );
};

export default FncDatePickerController;
