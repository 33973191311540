import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { tKey } from '../../helpers/translate';
import { selectIsNousCims } from '../../redux/auth/selectors';
import { AppState } from '../../redux/root-reducer';
import {
  getSignatureDocumentsAction,
  uploadDocument,
  removeDocument,
  downloadDocument,
  sendSignatureDocument,
  cancelSignatureDocument,
} from '../../redux/signature-document/actions';
import { SignatureDocument, SignatureDocumentType } from '../../redux/signature-document/definitions';
import {
  selectSignatureDocumentError,
  selectSignatureDocumentMessage,
  selectSignatureDocuments,
  selectSignatureDocumentSending,
} from '../../redux/signature-document/selectors';
import FormContainer from '../Forms/FormContainer';
import SignatureDocumentRow from './SignatureDocumentRow';
import styles from './signatureDocuments.module.scss';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import { UUID } from '../../types/Shared/standard';
import { generateUuid } from '../../helpers/uuidGenerator';
import { Button } from '@material-ui/core';
import TransitionsModal from '../Modal/TransitionModal';

interface SignatureDocumentsProps {
  title: string;
  documents: SignatureDocument[];
  type: SignatureDocumentType;
  type_id: number;
  info_message: string | null;
  error_message: string | null;
  isNousCims: boolean;
  getSignatureDocuments: (source_type: SignatureDocumentType, type_id: number) => void;
  onUploadFile: (file: File, uuid: UUID, type_id: number, type: SignatureDocumentType) => void;
  onRemoveDocument: (id: string) => void;
  onDownloadDocument: (file_id: number, name: string) => void;
  onSendDocument: (id: string) => void;
  sending: UUID | null;
  onCancelDocument: (id: string) => void;
}

const SignatureDocuments: React.FC<SignatureDocumentsProps> = ({
  title,
  documents,
  type,
  type_id,
  info_message,
  error_message,
  isNousCims,
  getSignatureDocuments,
  onUploadFile,
  onRemoveDocument,
  onDownloadDocument,
  onSendDocument,
  sending,
  onCancelDocument,
}) => {
  useEffect(() => {
    getSignatureDocuments(type, type_id);
  }, [getSignatureDocuments, type, type_id]);

  const isMobile = useWindowSize().IsMobile;
  const [disableAreaModal, setDisableAreaModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<UUID | null>(null);

  const handleDeleteClick = (id: UUID) => {
    setSelectedRow(id);
    setDisableAreaModal(true);
  };
  const handleDisableArea = () => {
    if (selectedRow) onRemoveDocument(selectedRow);
    setDisableAreaModal(false);
  };

  const Notice = () => {
    return (
      <div className={styles.noticeContainer}>
        <div className={styles.noticeQuestion}>
          <p>{tKey('seguro-quieres-borrar')}</p>
        </div>
      </div>
    );
  };

  const renderNewDocument = () => {
    if (isNousCims) {
      return (
        <div className={styles.documentNew}>
          {' '}
          <Button variant="outlined" color="primary" component="label" disabled={false}>
            {tKey('uploadFile')}
            <input
              onChange={e => {
                if (e && e.target) {
                  const formats = e.target.value.split('.');
                  const fileFormat = formats[formats.length - 1];
                  const file = e.target.files ? e.target.files[0] : null;
                  if (file && fileFormat) {
                    onUploadFile(file, generateUuid(), type_id, type);
                  }
                }
              }}
              onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                const element = event.target as HTMLInputElement;
                element.value = '';
              }}
              type="file"
              accept="application/pdf"
              id={'add'}
              key={'add'}
              name="document"
              className={styles.view_file}
            />
          </Button>
        </div>
      );
    }
    return undefined;
  };

  const renderDocuments = () => {
    if (documents.length > 0) {
      return documents.map((document: SignatureDocument) => {
        return (
          <SignatureDocumentRow
            key={document.id}
            signatureDocument={document}
            isNousCims={isNousCims}
            onViewDocument={(file_id: number, name: string) => onDownloadDocument(file_id, name)}
            onRemoveDocument={(signature_document_id: string) => handleDeleteClick(signature_document_id)}
            onSendDocument={(signature_document_id: string) => onSendDocument(signature_document_id)}
            onCancelDocument={(signature_document_id: string) => onCancelDocument(signature_document_id)}
            sending={sending}
          />
        );
      });
    }
    return (
      <tr className={styles.documentTableRow}>
        <td colSpan={4} className={`${styles.documentCellRow} ${styles.documentEmptyCellRow}`}>
          {tKey('No hay documentos')}
        </td>
      </tr>
    );
  };

  const renderMessages = () => {
    if (error_message) {
      return <span>{error_message}</span>;
    }
    if (info_message) {
      return <span>{info_message}</span>;
    }
    return undefined;
  };

  return (
    <>
      <FormContainer title={title}>
        <div className={styles.documentContainer}>
          {renderNewDocument()}
          <table className={styles.documentTable}>
            <caption className={styles.documentTableCaption}>{tKey('signature.requirements')}</caption>
            <thead className={styles.documentTableHeader}>
              <tr>
                <th className={styles.documentCellHeader}>{tKey('signature.document')}</th>
                <th className={styles.documentCellHeader}>{tKey('signature.status')}</th>
                {!isMobile && <th className={styles.documentCellHeader}>{tKey('signature.date')}</th>}
                <th className={styles.documentCellHeader}></th>
              </tr>
            </thead>
            <tbody>{renderDocuments()}</tbody>
          </table>
          <div className={styles.documentMessageArea}>{renderMessages()}</div>
        </div>
      </FormContainer>
      <TransitionsModal
        view={disableAreaModal}
        handleClose={() => setDisableAreaModal(false)}
        title={tKey('area-aviso')}
        bodyModal={<Notice />}
        buttonOk={tKey('Eliminar')}
        buttonKo={tKey('Cancelar')}
        handleYes={handleDisableArea}
      />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  isNousCims: selectIsNousCims(state),
  documents: selectSignatureDocuments(state),
  error_message: selectSignatureDocumentError(state),
  info_message: selectSignatureDocumentMessage(state),
  sending: selectSignatureDocumentSending(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getSignatureDocuments: (source_type: SignatureDocumentType, type_id: number): void =>
    dispatch(getSignatureDocumentsAction(source_type, type_id)),
  onUploadFile: (file: File, uuid: UUID, type_id: number, type: SignatureDocumentType) =>
    dispatch(uploadDocument(uuid, type_id, type, file)),
  onRemoveDocument: (id: string): void => dispatch(removeDocument(id)),
  onDownloadDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  onSendDocument: (id: string): void => dispatch(sendSignatureDocument(id)),
  onCancelDocument: (id: string): void => dispatch(cancelSignatureDocument(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureDocuments);
