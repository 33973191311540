import { Button, MenuItem } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import ValidatedSelect from '../../../../components/Select/ValidatedSelect/ValidatedSelect';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import { tKey } from '../../../../helpers/translate';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { getListMails, setCategoryFilter } from '../../../../redux/translations/actions';
import { ListMails } from '../../../../redux/translations/definitions';
import {
  selectCategory,
  selectListMailsByCategory,
  selectLoadingListMails,
  selectLookupCategory,
} from '../../../../redux/translations/selectors';
import { CategorySchema } from '../../../../validations/formSchema';
import SubmenuTranslations from '../SubmenuTranslations';
import styles from './mailTranslations.module.scss';

interface MailTranslationsProps extends RouteComponentProps {
  loading: boolean;
  canIconfigTranslations: boolean;
  canIconfigMailTranslations: boolean;
  lookupCategory: { [k: string]: string };
  mailCategory: string[];
  categoryFilter: string;
  listAllMails: () => void;
  listMails: (category?: string) => ListMails[];
  setCategoryFilter: (category: string) => void;
}

const MailTranslations: React.FC<MailTranslationsProps> = ({
  match,
  loading,
  canIconfigTranslations,
  canIconfigMailTranslations,
  lookupCategory,
  mailCategory,
  categoryFilter,
  listAllMails,
  listMails,
  setCategoryFilter,
}) => {
  const history = useHistory();
  const { path } = match;
  const selectedSection = path.substring(path.lastIndexOf('/') + 1);

  useEffect(() => {
    listAllMails();
  }, [listAllMails]);

  const { errors, register } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: CategorySchema,
  });
  const tableData: MaterialTableProps<ListMails> = {
    title: tKey('Lista de Tipos de Mails'),
    columns: [
      {
        title: tKey('Descripción'),
        field: 'description',
      },
      {
        title: tKey('Categoría'),
        field: 'category',
        lookup: lookupCategory,
        //@ts-ignore
        width: '200px',
      },
      {
        title: tKey('Orden'),
        field: 'order',
        //@ts-ignore
        width: '200px',
      },
    ],
    data: listMails(categoryFilter),
    options: {
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'fixed',
      filtering: false,
    },
  };
  const details = (rowData: ListMails) => {
    const { mail_code } = rowData;
    history.push({
      pathname: `/translation_mails/item/${mail_code}`,
      state: { mail_code },
    });
  };

  const setFilter = (key: any, e: any) => setCategoryFilter(e.target.value);
  const resetFilter = () => setCategoryFilter('');

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuTranslations
          canConfigure={canIconfigTranslations || canIconfigMailTranslations}
          selected={selectedSection}
          submenu={selectedSection}
          onClickOption={resetFilter}
        />
      }
      rightSubmenu={<ActionsMenu goBack onGoBack={() => history.push('/configuracion/traducciones')} />}
    >
      <div className={styles.filter}>
        <ValidatedSelect
          name={'category'}
          errors={errors}
          schema={CategorySchema}
          register={register}
          label={tKey('Categoría')}
          size="20"
          value={categoryFilter}
          handleChange={setFilter}
        >
          {mailCategory.map(category => (
            <MenuItem key={category} value={category}>
              {tKey(category)}
            </MenuItem>
          ))}
        </ValidatedSelect>
        <Button color="primary" variant="contained" onClick={resetFilter}>
          {tKey('Vaciar')}
        </Button>
      </div>
      <TableWithEdit tableData={{ ...tableData, isLoading: loading }} onRowClick={details} filtering />
    </LayoutForm>
  );
};

const mapStateProps = (state: AppState) => ({
  listMails: selectListMailsByCategory(state),
  lookupCategory: selectLookupCategory(state),
  mailCategory: selectCategory(state),
  loading: selectLoadingListMails(state),
  categoryFilter: state.translationReducer.categoyFilter,
  canIconfigTranslations: selectUserCan(state)('allow_translation_config'),
  canIconfigMailTranslations: selectUserCan(state)('allow_mail_translation_config'),
});

const mapDispatchToProps = (dispatch: any) => ({
  listAllMails: (): void => dispatch(getListMails()),
  setCategoryFilter: (category: string): void => dispatch(setCategoryFilter(category)),
});

export default connect(mapStateProps, mapDispatchToProps)(MailTranslations);
