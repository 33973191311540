import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { DocumentationItem } from '../../redux/common/definitions';
import DetailsEntity from './generalData/DetailsEntity';
import EditEntity from './generalData/EditEntity';
import MyEntity from './generalData/MyEntity';
import ValidationEntity from './generalData/ValidationEntity';

interface SwitchEntityProps {
  edit: boolean;
  details: boolean;
  myEntity: boolean;
  validation: boolean;
  documentation: DocumentationItem[];
  buttonActionSave: ButtonProps;
  buttonActionResponsible: ButtonProps;
  additionalButtons: ButtonProps[];
}

const SwitchEntity: React.FC<SwitchEntityProps> = ({
  edit,
  details,
  myEntity,
  validation,
  documentation,
  additionalButtons,
  buttonActionResponsible,
  buttonActionSave,
}) => {
  switch (true) {
    case edit:
      return <EditEntity documentation={documentation} buttonActionSave={buttonActionSave} />;
    case details:
      return <DetailsEntity documentation={documentation} />;
    case myEntity:
      return (
        <MyEntity
          documentation={documentation}
          buttonActionSave={buttonActionSave}
          buttonActionResponsible={buttonActionResponsible}
        />
      );
    case validation:
      return <ValidationEntity documentation={documentation} additionalButtons={additionalButtons} />;
    default:
      return <span></span>;
  }
};

export default SwitchEntity;
