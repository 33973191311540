import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { tFormKey } from '../../../helpers/translate';
import webStyle from '../../../helpers/webStyle';
import { AppState } from '../../../redux/root-reducer';
import { signLopd } from '../../../redux/user/actions';
import { UpdateUserFormFields, UserData } from '../../../redux/user/definitions';
import { selectProfile } from '../../../redux/user/selectors';
import LayoutLogin from '../../Layout/LayoutLogin';
import Loading from '../../Loading/Loading';
import styles from '../LoginForm/loginForm.module.scss';
import { UserStates } from '../../../redux/common/definitions';

interface LoginFormProps {
  userProfile: UserData;
  signLopd: (data: UpdateUserFormFields) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ userProfile, signLopd }) => {
  const { handleSubmit, clearError } = useForm<UpdateUserFormFields>({
    mode: 'onSubmit',
    submitFocusError: true,
  });

  const [accepted, setAccepted] = useState(false);
  const [view, setView] = useState(0);

  const onSubmit = () => {
    clearError();
    signLopd({
      ...userProfile,
      privacy_policy: accepted ? 1 : 0,
    });
    setView(1);
  };

  const handleChecked = (event: { target: { checked: React.SetStateAction<boolean> } }) => {
    setAccepted(event.target.checked);
  };

  useEffect(() => {
    if (userProfile.state === UserStates.CREATED) {
      setView(2);
    }
  }, [userProfile]);

  if (view === 1) {
    return (
      <LayoutLogin subtitle="Redirecting home">
        <Loading />
      </LayoutLogin>
    );
  } else if (view === 2) {
    return <Redirect to="/home" />;
  } else {
    return (
      <LayoutLogin subtitle="Aceptación de términos de la LOPD">
        <form onSubmit={handleSubmit(onSubmit)} className={styles[`login_form_${webStyle}`]}>
          <div className={styles[`lopd-paragraph`]}>
            Antes de proseguir debes decidir si aceptas que Nous Cims almacene tus datos personales con el único fin de
            prestar los servicios proporcionados por esta aplicación.
          </div>
          <div className={styles[`lopd-paragraph`]}>
            Puedes consultar los detalles concretos de las condiciones en el siguiente enlace:
          </div>
          <div className={styles[`lopd-paragraph`]}>
            <a href="https://www.boe.es/buscar/pdf/2018/BOE-A-2018-16673-consolidado.pdf">Condiciones generales LOPD</a>
          </div>
          <FormControlLabel
            control={<Checkbox checked={accepted} onChange={handleChecked} name="privacy_policy"></Checkbox>}
            label={tFormKey('Acepto las condiciones de la LOPD')}
          />

          <Button variant="contained" color="primary" type="submit" disableElevation>
            {tFormKey('Enviar')}
          </Button>
        </form>
      </LayoutLogin>
    );
  }
};

const mapStateToProps = (state: AppState) => ({
  userProfile: selectProfile(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  signLopd: (data: UpdateUserFormFields): void => dispatch(signLopd(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
