import { MaterialTableProps } from 'material-table';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import SelectCheckboxController, {
  ListSelectCheckbox,
} from '../../../components/Select/SelectCheckboxController/SelectCheckboxController';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tKey } from '../../../helpers/translate';
import { ColumnOrderData, OrderDirection } from '../../../redux/common/definitions';
import { entityGetList, setColumnOrder, setSearchText } from '../../../redux/entity/actions';
import {
  EntityGetAllDispatch,
  EntityListItem,
  EntityTypes,
  ListEntitiesState,
} from '../../../redux/entity/definitions';
import {
  selectColumnOrder,
  selectEntityList,
  selectIsLoading,
  selectSearchText,
} from '../../../redux/entity/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuEntities from '../SubmenuList/SubmenuEntities';
import styles from './entities.module.scss';
import { EntityTabs } from './EntitiesTabs';
import WhatType from './WhatType';

interface EntitiesConfirmedPageProps {
  loading: boolean;
  entityList: EntityListItem[];
  searchText: string;
  columnOrder: ColumnOrderData;
  entityTypes: EntityTypes[];
  setSearchText: (searchText: string) => void;
  setColumnOrder: (columnOrder: ColumnOrderData) => void;
  getEntitiesConfirmed: () => void;
}

const EntitiesConfirmed: React.FC<EntitiesConfirmedPageProps> = ({
  loading,
  entityList,
  entityTypes,
  searchText,
  columnOrder,
  setSearchText,
  setColumnOrder,
  getEntitiesConfirmed,
}) => {
  const history = useHistory();

  useEffect(() => {
    getEntitiesConfirmed();
  }, [getEntitiesConfirmed]);

  const getCities = (entityData?: EntityListItem[]) => {
    const cities: Record<string, string> = {};
    entityData?.map(ele => (ele.city ? (cities[ele.city] = `${ele.city}`) : null));
    return cities;
  };

  const tableData: MaterialTableProps<EntityListItem> = {
    title: tKey('Entidades confirmadas'),
    columns: [
      {
        title: tKey('CIF'),
        field: 'cif',
        //@ts-ignore
        width: '150px',
      },
      {
        title: tKey('Nombre'),
        field: 'name',
      },
      {
        title: tKey('Ciudad'),
        field: 'city',
        lookup: getCities(entityList),
        //@ts-ignore
        width: '300px',
      },
      {
        title: tKey('Teléfono'),
        field: 'phone',
        //@ts-ignore
        width: '150px',
      },
      {
        title: tKey('Email Responsable'),
        field: 'email',
      },
      {
        title: tKey('Tipo Entidad'),
        field: 'types_entity', //forced
        render: (rowData: EntityListItem) => <WhatType rowData={rowData} />,
        customFilterAndSearch: (types, rowData) => types.every((type: string) => rowData.types_entity.includes(type)),
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <SelectCheckboxController
            list={entityTypes as ListSelectCheckbox[]}
            valueSend="code"
            valueLabel="name"
            columnDef={columnDef}
            onFilterChanged={onFilterChanged}
          />
        ),

        //@ts-ignore
        width: '150px',
      },
      {
        title: 'joined',
        field: 'joined_49_2020',
        hidden: true,
      },
    ],
    data: entityList,
    isLoading: loading,
    options: {
      searchText,
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  const details = (rowData: EntityListItem) => {
    history.push({
      pathname: `/entidad/confirmada/${rowData.id}`,
      state: { id: rowData.id },
    });
  };

  return (
    <Layout leftSubmenu={<SubmenuEntities selected="entidades-confirmadas" submenu={tKey('Entidades')} />}>
      <div className={styles.container}>
        {EntityTabs(2)}
        <TableWithEdit
          tableData={tableData}
          getTextSearch={(textSearch: string) => setSearchText(textSearch)}
          getOrderColumns={(orderedColumnId: number, orderDirection: OrderDirection) =>
            setColumnOrder({ orderedColumnId, orderDirection })
          }
          columnOrder={columnOrder}
          filtering
          onRowClick={details}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  entityList: selectEntityList(state),
  columnOrder: selectColumnOrder(state),
  searchText: selectSearchText(state),
  entityTypes: state.entityReducer.entityTypes,
});

const mapDispatchToProps = (dispatch: EntityGetAllDispatch) => ({
  getEntitiesConfirmed: (): void => dispatch(entityGetList(ListEntitiesState.CONFIRMADAS)),
  setSearchText: (searchText: string): void => dispatch(setSearchText(searchText)),
  setColumnOrder: (data: ColumnOrderData): void => dispatch(setColumnOrder(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesConfirmed);
