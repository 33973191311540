import React from 'react';
import FncIconButton from '../../../components/FncIconButton/FncIconButton';
import { tKey } from '../../../helpers/translate';
import { ReactComponent as IconBack } from '../../../images/icons/iconBack.svg';
import { ReactComponent as IconHelp } from '../../../images/icons/iconHelp.svg';
import { KeyButton } from '../../../redux/common/definitions';
import { getMoreInfoButtonType } from '../ButtonActions/MoreInfoButtonActions';
import styles from './backMobileMenu.module.scss';

interface Props {
  onChangeMenu: (menuId: number) => void;
}

const BackMobileMenu = ({ onChangeMenu }: Props) => {
  const buttonHelp = getMoreInfoButtonType(KeyButton.HELP);
  return (
    <div className={styles.root}>
      <FncIconButton
        customClass={styles.button}
        icon={<IconBack />}
        onClick={() => {
          onChangeMenu(-1);
        }}
      >
        {tKey('Volver')}
      </FncIconButton>
      <FncIconButton customClass={styles.button} icon={<IconHelp />} disableElevation {...buttonHelp}>
        {buttonHelp.children}
      </FncIconButton>
    </div>
  );
};

export default BackMobileMenu;
