import { Action } from 'redux';
import { InternalProjectId, Participant } from '../Participant';
import { GetParticipantsConst } from './action_types';

export interface GetParticipantsAction extends Action {
  type: GetParticipantsConst.GET_PARTICIPANTS;
  id: InternalProjectId;
}

export interface GetParticipantsOkAction extends Action {
  type: GetParticipantsConst.GET_PARTICIPANTS_OK;
  data: Participant[];
}

export interface GetParticipantsKoAction extends Action {
  type: GetParticipantsConst.GET_PARTICIPANTS_KO;
  error: string;
}

export interface CancelGetParticipantsAction extends Action {
  type: GetParticipantsConst.CANCEL_GET_PARTICIPANTS;
}

export type GetParticipantsDataAction = GetParticipantsAction | GetParticipantsOkAction | GetParticipantsKoAction;

export const initialState = {
  loading: false,
  error: null as string | null,
  data: [] as Participant[],
};
export type GetParticipantsState = typeof initialState;
