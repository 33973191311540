import { IDate } from '../calendar/definitions';
import { AppState } from '../root-reducer';
import { StudentCalendarState } from './definitions';

export const selectCalendar = (state: AppState): StudentCalendarState => state.calendarInterviewReducer;

export const selectDates = (state: AppState): any => selectCalendar(state).datesTime;

export const selectIsLoadingTimes = (state: AppState): boolean => selectCalendar(state).loadingTimes;
export const selectIsLoadingDates = (state: AppState): boolean => selectCalendar(state).loadingDates;

export const selectSelectedDate = (state: AppState): IDate => selectCalendar(state).selectedDate;

export const selectIdScholarship = (state: AppState): number => selectCalendar(state).idScholarship;
