import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { MaterialTableProps } from 'material-table';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormContainer from '../../../../../../components/Forms/FormContainer';
import TextInput from '../../../../../../components/Inputs/TextInput';
import TableWithEdit from '../../../../../../components/Tables/TableWithEdit';
import { tFormKey, tKey, tProjectKey } from '../../../../../../helpers/translate';
import { IdNamePair } from '../../../../../../redux/common/definitions';
import {
  removeInternalProjectTemplateGeneralFormRoleData,
  setInternalProjectTemplateGeneralFormRoleData,
  updateInternalProjectTemplateGeneralFormRoles,
} from '../../../../../../redux/internal-project-templates/actions';
import {
  GeneralFormData,
  InternalProjectTemplateDispatch,
  RoleWithPermission,
} from '../../../../../../redux/internal-project-templates/definitions';
import styles from './internalProjectTemplateGeneralFormRoles.module.scss';

interface InternalProjectTemplateGeneralFormRolesProps {
  roles: IdNamePair[];
  formData: GeneralFormData;
  setGeneralFormRoleData: (formId: number, role: RoleWithPermission) => void;
  removeGeneralFormRoleData: (formId: number, roleId: number) => void;
  saveInternalProjectTemplateGeneralFormRoles: (
    internalProjectTemplateId: number,
    formId: number,
    roles: RoleWithPermission[],
  ) => void;
  handleClose: () => void;
}

const InternalProjectTemplateGeneralFormRoles: React.FC<InternalProjectTemplateGeneralFormRolesProps> = ({
  roles,
  formData,
  setGeneralFormRoleData,
  removeGeneralFormRoleData,
  saveInternalProjectTemplateGeneralFormRoles,
  handleClose,
}) => {
  const history = useHistory();
  const { name: formName, roles: formRoles } = formData;
  const [roleSelected, setRoleSelected] = useState<number | null>(null);
  const [allowEditSelected, setAllowEditSelected] = useState<boolean>(false);

  const tableData: MaterialTableProps<RoleWithPermission> = {
    title: '',
    columns: [
      {
        title: tFormKey('Rol'),
        field: 'name',
      },
      {
        title: tFormKey('Permitir edición'),
        field: 'allow_edit',
        type: 'boolean',
        render: (rowData: RoleWithPermission) => (
          <Checkbox
            color="primary"
            checked={Boolean(rowData.allow_edit)}
            onChange={() => setGeneralFormRoleData(formData.id, { ...rowData, allow_edit: !rowData.allow_edit })}
          />
        ),
      },
    ],
    options: {
      selection: false,
      pageSize: 3,
      tableLayout: 'auto',
      showSelectAllCheckbox: true,
      paging: false,
      minBodyHeight: 330,
      maxBodyHeight: 330,
    },
    actions: [
      (rowData: RoleWithPermission) => ({
        icon: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
        onClick: () => removeGeneralFormRoleData(formData.id, rowData.id),
        tooltip: tKey('Borrar'),
      }),
    ],
    data: formRoles,
  };

  const handleAddRole = () => {
    const selectedRole = roles.find((e: IdNamePair) => e.id === roleSelected);
    if (selectedRole) {
      const roleExists = formRoles.find((e: RoleWithPermission) => e.id === selectedRole.id);
      if (!roleExists) setGeneralFormRoleData(formData.id, { ...selectedRole, allow_edit: allowEditSelected });
    }
  };

  const handleSave = () => {
    saveInternalProjectTemplateGeneralFormRoles(formData.internal_project_template_id, formData.id, formRoles);
    handleClose();
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{tProjectKey('Editar pantalla general')}</DialogTitle>
        <IconButton aria-label="delete" size="small" onClick={handleClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        <form onSubmit={e => e.preventDefault()} className={styles.form}>
          <FormContainer title="">
            <TextInput label={tKey('Nombre de la plantilla')} defaultValue={formName} size="100" disabled />

            <div className={styles.select_role}>
              <FormControl
                variant="outlined"
                classes={{
                  root: styles.select_wrapper,
                }}
              >
                <InputLabel id="rol">{tKey('Rol')}</InputLabel>
                <Select
                  id="roles"
                  name="roles"
                  value={roleSelected || ''}
                  label={tFormKey('Rol')}
                  onChange={event => {
                    if (typeof event.target.value !== 'number') return;
                    setRoleSelected(event.target.value);
                  }}
                >
                  {roles &&
                    roles.map((role: IdNamePair) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div className={styles.select_edition}>
                <FormControlLabel
                  label={tKey('Permitir edición')}
                  control={
                    <Checkbox
                      color="primary"
                      checked={allowEditSelected}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setAllowEditSelected(event.target.checked)
                      }
                    />
                  }
                />
              </div>
              <Button color="primary" variant="outlined" onClick={handleAddRole}>
                {tKey('Añadir')}
              </Button>
            </div>
          </FormContainer>

          <div className={styles.table_role}>
            <TableWithEdit key="roles" customContainer tableData={tableData}></TableWithEdit>
          </div>

          <div className={styles.btn_create}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                history.push({
                  pathname: `/proyecto-interno/plantilla/asistente-formularios/${formData.internal_project_template_id}/${formData.section}`,
                  state: {
                    formId: formData.id,
                  },
                });
              }}
            >
              {tKey('Editar pantalla')}
            </Button>
            <Button color="primary" variant="outlined" onClick={handleSave}>
              {tKey('Guardar')}
            </Button>
            <Button color="primary" variant="outlined" onClick={handleClose}>
              {tKey('Cancelar')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  setGeneralFormRoleData: (formId: number, role: RoleWithPermission): void =>
    dispatch(setInternalProjectTemplateGeneralFormRoleData(formId, role)),
  removeGeneralFormRoleData: (formId: number, roleId: number): void =>
    dispatch(removeInternalProjectTemplateGeneralFormRoleData(formId, roleId)),
  saveInternalProjectTemplateGeneralFormRoles: (
    internalProjectTemplateId: number,
    formId: number,
    roles: RoleWithPermission[],
  ): void => dispatch(updateInternalProjectTemplateGeneralFormRoles(internalProjectTemplateId, formId, roles)),
});

export default connect(null, mapDispatchToProps)(InternalProjectTemplateGeneralFormRoles);
