import { ButtonProps } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';
import FncSubtitle from '../../../components/FncSubtitle/FncSubtitle';
import FncSelectController from '../../../components/Forms/FncSelect/FncSelectController';
import GenericFormCustomFields from '../../../components/GenericForm/GenericFormCustomFields';
import { Fields } from '../../../components/GenericForm/GenericFormRefactor';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Submenu from '../../../components/Layout/Menus/Submenu/Submenu';
import Loading from '../../../components/Loading/Loading';
import Title from '../../../components/Title/Title';
import * as DateConstants from '../../../constants/date';
import { tFormKey, tKey } from '../../../helpers/translate';
import { IdNamePair } from '../../../redux/common/definitions';
import { getIpStudentEvaluation, sendIpStudentEvaluation } from '../../../redux/internal-projects/actions';
import { InternalProjectDispatch, StudentEvaluationFieldsData } from '../../../redux/internal-projects/definitions';
import { selectIpLoading, selectStudentEvaluationFields } from '../../../redux/internal-projects/selectors';
import { CustomFieldElement } from '../../../redux/kpis/definitions';
import { AppState } from '../../../redux/root-reducer';
import { addFieldsToSchemaWithRequired } from '../../Project/generateSchemaGenericForm';
import styles from './internalProjectStudentEvaluation.module.scss';

interface InternalProjectStudentEvaluationProps extends RouteComponentProps<{ project_key: string }> {
  loading: boolean;
  internal_project_id: number;
  enrollments: IdNamePair[];
  fields: Fields;
  getStudentEvaluation: (project_key: string) => void;
  sendStudentEvaluation: (student_evaluation: StudentEvaluationFieldsData) => void;
}

const InternalProjectStudentEvaluation: React.FC<InternalProjectStudentEvaluationProps> = ({
  match,
  loading,
  internal_project_id,
  enrollments,
  fields,
  getStudentEvaluation,
  sendStudentEvaluation,
}) => {
  const {
    params: { project_key },
  } = match;

  const [customFields, setCustomFields] = useState<CustomFieldElement[]>([]);
  useEffect(() => {
    getStudentEvaluation(project_key);
  }, [getStudentEvaluation, project_key]);

  useEffect(() => {
    if (fields) {
      const { elements } = fields;
      const sortedFields = elements && elements.sort((a, b) => (a.position < b.position ? -1 : 1));
      setCustomFields(sortedFields);
    }
  }, [fields]);

  const schema: yup.ObjectSchema<yup.Shape<object, any>> = yup.object().shape(
    addFieldsToSchemaWithRequired({
      elements: customFields,
    }),
  );

  const formSchema = schema.concat(
    yup.object().shape({
      enrollment_id: yup.number().typeError('Debes seleccionar un nombre'),
    }),
  );

  const { control, errors, clearError, handleSubmit } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: formSchema,
  });

  const onSend = (data: any) => {
    Object.keys(data).forEach(element => {
      if (moment.isMoment(data[element])) {
        if (data[element].isValid()) {
          data[element] = moment(data[element]).format(DateConstants.DATE_FORMAT);
        }
      }
    });

    const enrollment_id = data.enrollment_id;

    delete data['enrollment_id'];

    const studentEvaluation = {
      internal_project_id,
      enrollment_id,
      fieldsData: data,
    } as StudentEvaluationFieldsData;
    sendStudentEvaluation(studentEvaluation);
  };

  const buttonSend: ButtonProps = {
    children: tKey('Enviar'),
    startIcon: <SendIcon />,
    color: 'primary',
    variant: 'contained',
    fullWidth: true,
    disableElevation: true,
    onClick: handleSubmit(onSend),
  };

  if (loading || !internal_project_id) return <Loading big />;

  const optionsEnrollmentsToSelect = enrollments.map(enrollment => {
    return { label: enrollment.name, value: enrollment.id };
  });

  return (
    <LayoutForm
      layoutDisabled
      leftSubmenu={<Submenu defaultOpen optionsMenu={[]} />}
      rightSubmenu={<ActionsMenu actionsButtons={[buttonSend]} goBack={false} />}
    >
      <Title>{tFormKey('Evaluación del taller')}</Title>
      <FncSubtitle>{tFormKey('Participante')}</FncSubtitle>

      <FncSelectController
        label={tFormKey('Nombre')}
        key="users"
        control={control}
        errors={errors}
        name={'enrollment_id'}
        schema={formSchema}
        options={optionsEnrollmentsToSelect}
        customClass={`${styles.field} ${styles.select}`}
      />

      <GenericFormCustomFields
        customFields={customFields}
        control={control}
        errors={errors}
        schema={schema}
        clearError={clearError}
        setCustomFields={setCustomFields}
      />
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIpLoading(state),
  studentEvaluation: state.internalProjectReducer.studentEvaluationData,
  internal_project_id: state.internalProjectReducer.studentEvaluationData.internal_project_id,
  enrollments: state.internalProjectReducer.studentEvaluationData.enrollments,
  fields: selectStudentEvaluationFields(state),
});

const mapDispatchToProps = (dispatch: InternalProjectDispatch) => ({
  getStudentEvaluation: (project_key: string): void => dispatch(getIpStudentEvaluation(project_key)),
  sendStudentEvaluation: (student_evaluation: StudentEvaluationFieldsData): void =>
    dispatch(sendIpStudentEvaluation(student_evaluation)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectStudentEvaluation);
