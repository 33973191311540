import { Button, InputAdornment } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { Material } from '../../../../redux/scholarship/definitions';
import { MaterialsSchema } from '../FormSchema';
import styles from './materialsTableModal.module.scss';

interface ModalCreateProps {
  material: Material;
  onSave: (data: Material) => void;
  closeModal: () => void;
}

const MaterialsTableModal: React.FC<ModalCreateProps> = ({ material, onSave, closeModal }) => {
  const materialSchema = MaterialsSchema();

  const { id, scholarship_id, name, amount, file_id, filename } = material;

  const { errors, control, handleSubmit } = useForm<Material>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: materialSchema,
  });

  const onSubmit = (data: Material) => {
    onSave(data);
    closeModal();
  };

  const renderHiddenFields = (): JSX.Element => {
    return (
      <>
        <TextInputController
          type="hidden"
          control={control}
          key="id"
          name="id"
          errors={errors}
          schema={materialSchema}
          defaultValue={id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="scholarship_id"
          name="scholarship_id"
          errors={errors}
          schema={materialSchema}
          defaultValue={scholarship_id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="file_id"
          name="file_id"
          errors={errors}
          schema={materialSchema}
          defaultValue={file_id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="filename"
          name="filename"
          errors={errors}
          schema={materialSchema}
          defaultValue={filename}
          visible={false}
        />
      </>
    );
  };

  return (
    <div>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {renderHiddenFields()}
        <div className={styles.newcontact}>
          <TextInputController
            size="Large"
            label={tScholarshipKey('material_name')}
            name="name"
            defaultValue={name}
            control={control}
            errors={errors}
            schema={materialSchema}
          />
        </div>
        <div className={styles.newcontact}>
          <TextInputController
            type="number"
            size="Large"
            label={tScholarshipKey('material_amount')}
            name="amount"
            defaultValue={amount}
            control={control}
            errors={errors}
            schema={materialSchema}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </div>
        <div className={styles.btn_create}>
          <Button color="primary" variant="outlined" onClick={handleSubmit(onSubmit)}>
            {tKey('Guardar')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default MaterialsTableModal;
