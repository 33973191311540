import { Button, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { tFormKey, tKey } from '../../../helpers/translate';
import { NewDocumentSchema } from '../../../validations/formSchema';
import TextInputController from '../../Inputs/TextInputController/TextInputController';
import styles from './NewDocumentModal.module.scss';

interface NewDocumentModalProps {
  addDoc: (document: File, description: string) => void;
  handleClose: () => void;
}
const NewDocumentModal: React.FC<NewDocumentModalProps> = ({ addDoc, handleClose }) => {
  const { register, errors, handleSubmit, watch, control } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: NewDocumentSchema,
  });
  const disabled = !watch('document')?.[0];
  const hasDescription = watch('description');
  const handleSend = (data: { description: string; document: FileList }) => {
    addDoc(data.document[0], data.description);
    handleClose();
  };

  return (
    <Fragment>
      <div className={styles.modal}>
        <div className={styles.containerAddDocument}>
          <div className={styles.descriptionDocument}>
            <TextInputController
              name="description"
              errors={errors}
              schema={NewDocumentSchema}
              size="50"
              label={tFormKey('Nombre del recurso')}
              control={control}
              classes={{ root: styles.description }}
              visible={disabled}
            />
          </div>
          <div className={disabled ? styles.button : styles.buttonDisabled}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              component="label"
              disabled={!hasDescription}
              startIcon={<AddIcon />}
            >
              {tKey('Elegir Documento')}
              <input ref={register} type="file" id={'add'} key={'add'} name="document" className={styles.view_file} />
            </Button>
          </div>
        </div>
        {!disabled && (
          <div className={styles.responseDiv}>
            <TextField
              style={{ width: '100%', color: '#666666' }}
              disabled={true}
              value={watch('description')}
              label={tFormKey('Descripción del Archivo')}
              variant="outlined"
            />
            <TextField
              style={{ width: '100%', color: '#666666' }}
              disabled={true}
              value={watch('document')?.[0].name}
              label={tFormKey('Nombre del Archivo')}
              variant="outlined"
            />
          </div>
        )}
      </div>
      <div className={!disabled ? styles.button : styles.buttonDisabled}>
        <Button variant="contained" color="primary" disableElevation onClick={handleSubmit(handleSend)}>
          {tFormKey('subir-documento')}
        </Button>
      </div>
    </Fragment>
  );
};

export default NewDocumentModal;
