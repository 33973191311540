import { Button } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import { tKey } from '../../helpers/translate';
import {
  GroupsScholarshipTransversalCompetencesData,
  ScholarshipTransversalCompetencesData,
  ScholarshipTransversalCompetencesSendData,
  TransversalCompetencesQuestions,
} from '../../redux/scholarship/definitions';
import { SurveyTransversalCompetencesSchema } from '../../validations/formSchema';
import FormContainer from '../Forms/FormContainer';
import RadioButtonGroupWithController from '../RadioButtons/RadioButtonGroupWithController';
import styles from './SurveyForm.module.scss';

interface SurveyFormProps {
  surveyData: ScholarshipTransversalCompetencesData;
  onSubmitForm: (data: ScholarshipTransversalCompetencesSendData) => void;
}

const SurveyForm = ({ surveyData, onSubmitForm }: SurveyFormProps): ReactElement => {
  const { control, errors, handleSubmit } = useForm<TransversalCompetencesQuestions>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: SurveyTransversalCompetencesSchema,
  });

  const onSubmit = (data: TransversalCompetencesQuestions) => {
    onSubmitForm({
      scholarship_id: surveyData.scholarship_id,
      role_code: surveyData.role_code,
      period: surveyData.period,
      questions: data,
    });
  };

  const isMobile = useWindowSize().IsMobile;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {surveyData.groups.map((group: GroupsScholarshipTransversalCompetencesData) => {
        return (
          <FormContainer key={group.title} title={group.title}>
            {group.questions.map(question => (
              <div key={question.name} className={styles.radioGroupRow}>
                <RadioButtonGroupWithController
                  name={question.name}
                  options={surveyData.options}
                  control={control}
                  valueDefault={question.value}
                  questionText={question.title}
                  errors={errors}
                  row={!isMobile}
                />
              </div>
            ))}
          </FormContainer>
        );
      })}

      <Button color="primary" variant="outlined" type="submit" className={styles.buttonSave}>
        {tKey('Guardar')}
      </Button>
    </form>
  );
};

export default SurveyForm;
