import { useEffect, useState } from 'react';

export const useApiFetch = (cb: () => any, listener?: string | number, cleaner?: () => void) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    cb().then(() => setLoading(false));
    if (cleaner) {
      return cleaner;
    }
  }, [listener]); // eslint-disable-line react-hooks/exhaustive-deps
  return { loading };
};
