import React from 'react';
import { ReactComponent as IconCheck } from '../../images/icons/iconCheck.svg';
import { ReactComponent as IconClose } from '../../images/icons/iconClose.svg';
import { ReactComponent as IconDelete } from '../../images/icons/iconDelete.svg';
import { ReactComponent as IconDownload } from '../../images/icons/iconDownload.svg';
import { ReactComponent as IconSearch } from '../../images/icons/iconFileSearch.svg';
import { ReactComponent as IconUpload } from '../../images/icons/iconUpload.svg';
import { ActionsDocument, DocumentationItem } from '../../redux/common/definitions';
import styles from './addDocumentsActions.module.scss';

interface Props {
  document: DocumentationItem;
  actions: ActionsDocument[];
  disabled: boolean;
  addRow: boolean;
  multiDocument: boolean;
  required: boolean;
  requiredMultiDocument: boolean;
  addMoreDocuments: boolean;
  onUpload: (fileFormat: string, file: File, rowData: DocumentationItem) => void;
  onOpenModal: (rowData: DocumentationItem) => void;
  onDownload: (rowData: DocumentationItem) => void;
  onRemoveFile: (rowData: DocumentationItem) => void;
  onGetFile: (rowData: DocumentationItem) => void;
  setShowModal: (open: boolean) => void;
}

const ShowOptionUpload = ({ document, actions, disabled, onUpload }: Props) => {
  if (actions.includes('upload')) {
    return (
      <li className={styles.item}>
        <label htmlFor={document.name} className={`${styles.button} ${disabled && styles.disabled}`}>
          <IconUpload className={styles.icon} />
          <input
            type="file"
            id={document.name}
            key={document.documentation_id}
            name="document"
            disabled={disabled}
            className={styles.view_file}
            onChange={e => {
              if (e && e.target) {
                const formats = e.target.value.split('.');
                const fileFormat = formats[formats.length - 1];
                const file = e.target.files ? e.target.files[0] : null;
                if (file && fileFormat) {
                  onUpload(fileFormat, file, document);
                }
              }
            }}
          />
        </label>
      </li>
    );
  }
  return <></>;
};

const ShowOptionChecked = ({ document, actions }: Props) => {
  if (actions.includes('checked')) {
    return (
      <li className={styles.item}>
        {document.file_id !== null ? <IconCheck className={styles.icon} /> : <IconClose className={styles.icon} />}
      </li>
    );
  }
  return <></>;
};
const ShowOptionDownload = ({ document, actions, onDownload }: Props) => {
  if (actions.includes('download')) {
    return (
      <li className={styles.item}>
        <button
          className={styles.button}
          disabled={document.file_id === null}
          onClick={() => {
            document.file_id !== null && onDownload(document);
          }}
        >
          <IconDownload className={styles.icon} />
        </button>
      </li>
    );
  }
  return <></>;
};
const ShowOptionView = ({ actions, document, onGetFile }: Props) => {
  if (actions.includes('view')) {
    return (
      <li className={styles.item}>
        <button
          className={styles.button}
          disabled={document.file_id === null}
          onClick={() => {
            document.file_id !== null && onGetFile(document);
          }}
        >
          <IconSearch className={styles.icon} />
        </button>
      </li>
    );
  }
  return <></>;
};
const ShowOptionRemove = ({ actions, document, disabled, onRemoveFile }: Props) => {
  if (actions.includes('remove')) {
    return (
      <li className={styles.item}>
        <button
          className={styles.button}
          disabled={document.file_id === null || disabled}
          onClick={() => {
            document.file_id !== null && !disabled && onRemoveFile(document);
          }}
        >
          <IconDelete className={styles.icon} />
        </button>
      </li>
    );
  }
  return <></>;
};
const AddDocumentActionsMobile = (props: Props) => {
  return (
    <ul className={styles.list}>
      <ShowOptionChecked {...props} />
      <ShowOptionUpload {...props} />
      <ShowOptionDownload {...props} />
      <ShowOptionView {...props} />
      <ShowOptionRemove {...props} />
    </ul>
  );
};

export default AddDocumentActionsMobile;
