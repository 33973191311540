import {
  createEventSubscription,
  getEventData,
  getEventSubscriptions,
  removeSubscription,
  updateInscriptionData,
} from '../../../redux/event/actions';
import { AppState } from '../../../redux/root-reducer';
import { connect } from 'react-redux';

import { selectUserCan } from '../../../redux/permissions/selectors';
import { selectLoading } from '../../../redux/project/selectors';
import { selectEventData, selectEventSubscriptionList } from '../../../redux/event/selectors';
import { EventDataDispatch, EventRegistrationData, EventSubscriptionData } from '../../../redux/event/definitions';

import { EventContacts } from './EventContacts';

const mapStateToProps = (state: AppState) => ({
  event: selectEventData(state),
  loading: selectLoading(state),
  eventContactsList: selectEventSubscriptionList(state),
  registration: false,
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: EventDataDispatch) => ({
  getEvent: (id: string) => dispatch(getEventData(id)),
  getEventContacts: (eventId: number): void => dispatch(getEventSubscriptions(eventId)),
  createSubscription: (data: EventRegistrationData) => dispatch(createEventSubscription(data)),
  updateInscriptionData: (eventId: number, eventRegistrationsList: EventSubscriptionData[]): void =>
    dispatch(updateInscriptionData(eventId, eventRegistrationsList)),
  removeRegister: (eventId: number, registrationId: number): void =>
    dispatch(removeSubscription(eventId, registrationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventContacts);
