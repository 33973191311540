import validationAccountNumber from './validationAccountNumber';
import { nif_validator, passport_validator } from './validatorUser';

const validate = {
  accountNumber(number: string, country: string) {
    if (country !== 'España') return true;
    if (number.length === 0) return true;
    return validationAccountNumber(number);
  },

  documentIdentification({
    documentType,
    documentNumber,
    country,
  }: {
    documentType: string;
    documentNumber: string;
    country?: string | null;
  }) {
    if (documentNumber.length === 0) return true;
    if (country && country !== 'España') return true;
    switch (documentType) {
      case 'DNI':
        return nif_validator(documentNumber);
      case 'NIF':
        return nif_validator(documentNumber);
      case 'NIE':
        return true;
      case 'Pasaporte':
        return passport_validator(documentNumber);
      default:
        return true;
    }
  },
};

export default validate;
