import { ButtonProps } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import * as H from 'history';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import AvatarImagev2 from '../../../components/MenuImageRound/MenuImageRound';
import TransitionModal from '../../../components/Modal/TransitionModal';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import Title from '../../../components/Title/Title';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tKey } from '../../../helpers/translate';
import { selectUserLang } from '../../../redux/auth/selectors';
import { DocumentationItem, IdCodeNamePair } from '../../../redux/common/definitions';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import {
  acceptUserEducator,
  downloadDocument,
  getDocument,
  rejectUserEducator,
  removeDocument,
  setAvatarById,
  updateProfileUserTable,
  uploadDocument,
  userLinkAcademyAction,
} from '../../../redux/user/actions';
import { UpdateUserFormFields, UserData, UserDataDispatch } from '../../../redux/user/definitions';
import { selectError, selectUserByRoleGroup, selectUserValidationErrors } from '../../../redux/user/selectors';
import { EditUserSchema } from '../../../validations/formSchema';
import styles from '../editUser.module.scss';
import AccessDataForm from '../FormLayouts/AccessDataForm';
import PersonalDataForm from '../FormLayouts/PersonalDataForm';

interface EditUserProps {
  readOnly: boolean;
  id: string;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  documents: DocumentationItem[];
  location: H.Location<{ needsValidation: boolean; type: string }>;
  profileData: UserData;
  canISeeAcademy: boolean;
  roles: IdCodeNamePair[];
  validationErrors: APIValidationError | null;
  genericMetadata: GenericMetadata;
  defaultLang: string;
  acceptEducator: (id: number) => void;
  rejectEducator: (id: number) => void;
  updateTableUser: (data: UpdateUserFormFields, id: number) => void;
  uploadUserDocument: (document_id: number, data: File) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
  handleSetImageById: (image: string) => void;
  userLinkAcademy: (userId: number) => void;
}

const EditUser: React.FC<EditUserProps> = ({
  readOnly,
  avatar,
  profileData,
  loading,
  documents,
  location,
  roles,
  canISeeAcademy,
  id,
  validationErrors,
  changeAvatar,
  genericMetadata,
  defaultLang,
  handleSetImageById,
  userLinkAcademy,
  updateTableUser,
  uploadUserDocument,
  downloadUserDocument,
  removeUserDocument,
  getUserDocument,
  acceptEducator,
  rejectEducator,
}) => {
  const { errors, handleSubmit, watch, clearError, setValue, setError, triggerValidation, control } = useForm<
    UpdateUserFormFields
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EditUserSchema,
  });
  const actions = ['checked', 'download', 'view', 'remove'];
  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: 'Ayuda',
    body: <div className={styles.modalHelp}>{tKey('Todos usuarios de la entidad')}</div>,
    startIcon: <HelpOutline />,
  });
  useValidatorAPI(validationErrors, setError);

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('ACTUALIZAR DATOS'),
      onClick: handleSubmit((data: UpdateUserFormFields) => {
        if (!Object.keys(errors).length) {
          updateTableUser(data, +id);
        } else {
          for (const e of Object.keys(errors)) {
            // @ts-ignore
            setError(e, errors[e].types, errors[e].message);
          }
        }
      }),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      hidden: readOnly,
    },
    {
      children: tKey('VALIDAR EDUCADOR/A'),
      disableElevation: true,
      onClick: () => acceptEducator(+id),
      startIcon: <CheckIcon />,
      hidden: location.state.needsValidation && profileData.state !== 'DISABLED' ? false : true,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
    },
    {
      children: tKey('RECHAZAR EDUCADOR/A'),
      disableElevation: true,
      onClick: () => rejectEducator(+id),
      startIcon: <CancelIcon />,
      hidden: location.state.needsValidation && profileData.state !== 'DISABLED' ? false : true,
      color: 'secondary',
      variant: 'contained',
      fullWidth: true,
    },
    {
      children: tKey('u.vincular_academia'),
      disableElevation: true,
      onClick: () => userLinkAcademy(+id),
      startIcon: <CancelIcon />,
      hidden: location.state.type === 'academy' && profileData.state !== 'DISABLED' ? false : true && canISeeAcademy,
      color: 'secondary',
      disabled: profileData.is_academy_staff,
      variant: 'contained',
      fullWidth: true,
    },
  ];

  const moreInfoButtons: ButtonProps[] = [
    {
      children: tKey('AYUDA'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      onClick: () =>
        setCreatedModal(prevState => ({
          ...prevState,
          view: true,
        })),
      startIcon: <HelpOutline />,
      hidden: location.state.needsValidation && profileData.state !== 'DISABLED' ? false : true,
    },
  ];

  const title = readOnly ? tKey('DETALLES  DE USUARIO') : tKey('EDICIÓN DE USUARIO');
  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} moreInfoButtons={moreInfoButtons} />}
      leftSubmenu={
        <AvatarImagev2 imageLink={avatar} loading={loading} setImage={changeAvatar ? handleSetImageById : undefined} />
      }
    >
      <React.Fragment>
        {!loading ? (
          <form>
            <Title>{title}</Title>
            <AccessDataForm
              profileData={profileData}
              control={control}
              schema={EditUserSchema}
              errors={errors}
              readOnly={readOnly}
            />
            <PersonalDataForm
              setError={setError}
              clearError={clearError}
              profileData={profileData}
              genericMetadata={genericMetadata}
              roles={roles}
              errors={errors}
              setValue={setValue}
              schema={EditUserSchema}
              readOnly={readOnly}
              triggerValidation={triggerValidation}
              control={control}
              watch={watch}
              defaultLang={defaultLang}
            />
            {documents?.length > 0 && (
              <FncAddDocuments
                documents={documents}
                actions={actions}
                title={tKey('Documentos')}
                upload={uploadUserDocument}
                download={downloadUserDocument}
                remove={removeUserDocument}
                getFile={getUserDocument}
              />
            )}
          </form>
        ) : (
          <Loading big />
        )}
      </React.Fragment>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        maxWidth={true}
        bodyModal={createdModal.body}
      >
        {createdModal.children}
      </TransitionModal>
    </LayoutForm>
  );
};
interface OwnProps {
  location: H.Location<{ type: string }>;
}

const mapStateToProps = (
  state: AppState,
  {
    location: {
      state: { type },
    },
  }: OwnProps,
) => ({
  error: selectError(state),
  validationErrors: selectUserValidationErrors(state),
  genericMetadata: selectGenericMetadata(state),
  roles: selectUserByRoleGroup(state, type),
  canISeeAcademy: selectUserCan(state)('get_academy_users'),
  defaultLang: selectUserLang(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  updateTableUser: (data: UpdateUserFormFields, id: number): void => dispatch(updateProfileUserTable(data, id)),
  uploadUserDocument: (document_id: number, data: File): void => dispatch(uploadDocument(document_id, data)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
  acceptEducator: (id: number): void => dispatch(acceptUserEducator(id)),
  rejectEducator: (id: number): void => dispatch(rejectUserEducator(id)),
  setAvatarById: (userAvatar: string, id: number) => dispatch(setAvatarById(userAvatar, id)),
  userLinkAcademy: (userId: number): void => dispatch(userLinkAcademyAction(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
