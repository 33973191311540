import React from 'react';
import { connect } from 'react-redux';
import { permissionType } from '../../../../constants/permissions_definitions';
import { AppRoute } from '../../../../constants/routes';
import filteredRoutes from '../../../../helpers/filteredRoutes';

import { selectPermissionsList } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { BoxMenuItem } from './BoxMenuItem';
import styles from './menuBottom.module.scss';

const fillRowArray = (array: AppRoute[], filteredOptions: AppRoute[], initial: number) => {
  array = new Array(6);
  for (let i = initial; i < initial + 6; i++) {
    if (filteredOptions[i]) {
      array.push(filteredOptions[i]);
    } else {
      array.push({
        name: `empty${i}`,
        path: '',
        empty: true,
        platform: 'common',
      });
    }
  }
  return array;
};
interface MenuBottomProps {
  permissions?: permissionType[];
  isHome?: boolean;
}
const MenuBottom: React.FC<MenuBottomProps> = ({ permissions, isHome = false }) => {
  let firstRow: AppRoute[] = [];
  let secondRow: AppRoute[] = [];
  let thirdRow: AppRoute[] = [];

  const filteredOptions = filteredRoutes(permissions).filter(
    option => option.display === 'menubottom', //&& option.rol?.includes(userRol),
  );

  firstRow = fillRowArray(firstRow, filteredOptions, 0);

  if (filteredOptions.length > 6) {
    secondRow = fillRowArray(secondRow, filteredOptions, 6);
  }

  if (filteredOptions.length > 12) {
    thirdRow = fillRowArray(thirdRow, filteredOptions, 12);
  }
  return (
    <div role="presentation" className={styles.root}>
      <div className={styles.list}>
        {firstRow.map(item => (
          <BoxMenuItem key={item.name} item={item} />
        ))}
        {secondRow && secondRow.map(item => <BoxMenuItem key={item.name} item={item} />)}
        {thirdRow && thirdRow.map(item => <BoxMenuItem key={item.name} item={item} />)}
      </div>
      {!isHome && <div className={styles.space_fabButton} />}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  permissions: selectPermissionsList(state),
});

export default connect(mapStateToProps, null)(MenuBottom);
