import { Button, ButtonProps } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styles from './modal.module.scss';

interface ModalProps {
  open: boolean;
  children: JSX.Element | JSX.Element[];
  buttons: ButtonProps[];
  title: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  className?: string;
  onClose: () => void;
}
const Modal: React.FC<ModalProps> = ({ open, onClose, children, buttons, title, maxWidth, className }) => (
  <Dialog open={open} onClose={onClose} maxWidth={maxWidth} className={className}>
    <div className={styles.title}>
      <DialogTitle className={styles.titleRed}>{title}</DialogTitle>
      <IconButton onClick={onClose} aria-label="delete" className={styles.buttonClose} size="small">
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </div>
    <DialogContent className={styles.content}>{children}</DialogContent>
    <DialogActions className={styles.actions}>
      {buttons.map(({ children, ...props }, i) => (
        <Button key={i} disableElevation {...props}>
          {children}
        </Button>
      ))}
    </DialogActions>
  </Dialog>
);

export default Modal;
