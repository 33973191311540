import React from 'react';
import { tKey } from '../../helpers/translate';
import { ReactComponent as IconBullet } from '../../images/icons/iconBullet.svg';
import { ActionsDocument, DocumentationItem } from '../../redux/common/definitions';
import AddDocumentActionsMobile from './AddDocumentActionsMobile';
import styles from './filesListMobile.module.scss';

interface Props {
  documents: DocumentationItem[];
  actions: ActionsDocument[];
  disabled: boolean;
  addRow: boolean;
  multiDocument: boolean;
  required: boolean;
  requiredMultiDocument: boolean;
  addMoreDocuments: boolean;
  onUpload: (fileFormat: string, file: File, rowData: DocumentationItem) => void;
  onOpenModal: (rowData: DocumentationItem) => void;
  onDownload: (rowData: DocumentationItem) => void;
  onRemoveFile: (rowData: DocumentationItem) => void;
  onGetFile: (rowData: DocumentationItem) => void;
  setShowModal: (open: boolean) => void;
}

const FilesListMobile = (props: Props) => {
  const { documents, required } = props;

  return (
    <table className={styles.root}>
      <thead className={styles.thead}>
        <tr>
          <th className={styles.th}>
            <p className={styles.heading}>{tKey('Nombre')}</p>
          </th>
          <th className={styles.th}>
            <p className={styles.heading}>{tKey('Actualizado')}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {documents.map((rowData: DocumentationItem, index) => {
          let bullet;
          if (required && rowData.mandatory) {
            bullet = <IconBullet className={`${styles.bullet} ${rowData.file_id ? styles.isOK : styles.isKO}`} />;
          }
          return (
            <>
              <tr className={styles.row} key={`${rowData.file_id}_${index}`}>
                <td className={styles.td}>
                  {bullet}
                  <p className={styles.fileName}>{rowData.name}</p>
                </td>
                <td className={styles.td}>
                  {rowData.update && <p className={styles.fileLastUpdate}>{rowData.update}</p>}
                </td>
              </tr>
              <tr>
                <td className={`${styles.td} ${styles.isSingleCell}`} colSpan={2}>
                  <AddDocumentActionsMobile document={rowData} {...props} />
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
};

export default FilesListMobile;
