import { CreateFamilyIncomeConst } from './action_types';
import { CreateFamilyIncomeDataAction, CreateFamilyIncomeState } from './definitions.d';
import assertUnreachable from '../../../../helpers/assertUnreachable';
import { FamilyIncomesInitialState } from '../FamilyIncome.d';

const createFamilyIncomeReducer = (
  state = FamilyIncomesInitialState,
  action: CreateFamilyIncomeDataAction,
): CreateFamilyIncomeState => {
  switch (action.type) {
    case CreateFamilyIncomeConst.ADD_FAMILY_INCOME:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CreateFamilyIncomeConst.ADD_FAMILY_INCOME_OK:
      const { income } = action;
      state.list.push(income);
      return {
        ...state,
        loading: false,
      };
    case CreateFamilyIncomeConst.ADD_FAMILY_INCOME_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      assertUnreachable(action);
      return state;
  }
};

export default createFamilyIncomeReducer;
