class PaymentType {
  static RECARGA = 'Recarga';
  static PAGO_CENTRO = 'Pago centro';
  static PAGO_BECADO = 'Pago becado';
  static PAGO_BECADO_CENTRO = 'Pago becado (Centro)';

  constructor(private code: string) {}

  public isRecarga(): boolean {
    return this.code === PaymentType.RECARGA;
  }

  public isPagoCentro(): boolean {
    return this.code === PaymentType.PAGO_CENTRO;
  }

  public isPagoBecado(): boolean {
    return this.code === PaymentType.PAGO_BECADO;
  }

  public isPagoBecadoCentro(): boolean {
    return this.code === PaymentType.PAGO_BECADO_CENTRO;
  }
}

export default PaymentType;
