import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { userActivationStart } from '../../redux/auth/actions';
import { AuthDispatch } from '../../redux/auth/definitions';
import { selectIsActivating, selectIsLoggedIn } from '../../redux/auth/selectors';
import { AppState } from '../../redux/root-reducer';

interface RegisterSuccessfullProps {
  userActivationStart: () => void;
  activating: boolean;
  loggedIn: boolean;
}

const RegisterSuccessfull: React.FC<RegisterSuccessfullProps> = ({ userActivationStart, activating, loggedIn }) => {
  useEffect(() => {
    if (!activating && !loggedIn) {
      userActivationStart();
    }
  }, [activating, loggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Loading big />;
};

const mapStateToProps = (state: AppState) => ({
  activating: selectIsActivating(state),
  loggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = (dispatch: AuthDispatch) => {
  const token = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
  return {
    userActivationStart: (): void => dispatch(userActivationStart(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterSuccessfull);
