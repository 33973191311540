/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';

import errorMessage from '../../helpers/errorMessage';
import { getSubareaInternalProjectType } from '../../api/SubareaInternalProjectType/getSubareaInternalProjectType';
import { getSubareaInternalProjectTypeKo, getSubareaInternalProjectTypeOk } from './action';
import { SubareaInternalProjectTypeConst } from './action_types';
import { GetSubareaInternalProjectTypeAction } from './definition';

function* getSubareaInternalProjectTypeListFromSaga(): Generator<any, void, any> {
  try {
    const subareaInternalProjectTypeData = yield getSubareaInternalProjectType();
    yield put(getSubareaInternalProjectTypeOk(subareaInternalProjectTypeData));
  } catch (e) {
    yield put(getSubareaInternalProjectTypeKo(errorMessage(e)));
  }
}

export default [
  takeLatest<GetSubareaInternalProjectTypeAction>(
    SubareaInternalProjectTypeConst.GET_SUBAREAINTERNALPROJECTTYPE,
    getSubareaInternalProjectTypeListFromSaga,
  ),
];
