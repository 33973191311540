import { ButtonProps } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { tKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { changeState } from '../../../redux/entity/actions';
import { ChangeStateEntity, EntityInfo } from '../../../redux/entity/definitions';
import { selectGetOneEntity, selectIsLoading, selectValidationErrors } from '../../../redux/entity/selectors';
import { AppState } from '../../../redux/root-reducer';
import { entityFormSchema } from '../../../validations/Entities/entityFormSchema';
import EntityForm from './EntityForm';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { APIValidationError } from '../../../api/api';

interface ValidationEntityProps {
  loading: boolean;
  entityData: EntityInfo;
  documentation: DocumentationItem[];
  additionalButtons: ButtonProps[];
  validationErrors: APIValidationError | null;
}

const ValidationEntity: React.FC<ValidationEntityProps> = ({
  loading,
  documentation,
  entityData,
  validationErrors,
}) => {
  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: tKey('Motivo del rechazo.'),
  });

  const entitySchema = entityFormSchema(!!entityData.is_visa_provider, !!entityData.no_payments_entity);

  const { register, setValue, watch, errors, triggerValidation, control, setError, clearError } = useForm<EntityInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: entitySchema,
  });

  useValidatorAPI(validationErrors, setError);

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <EntityForm
          title={tKey('Validación de Entidades')}
          useForm={{ register, setValue, watch, errors, triggerValidation, control, setError, clearError }}
          entitySchema={entitySchema}
          documentation={documentation}
          disabled
        />
      )}

      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
      >
        {createdModal.children}
      </TransitionModal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  entityData: selectGetOneEntity(state),
  validationErrors: selectValidationErrors(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  changeState: (id: number, states: ChangeStateEntity, comments?: string): void =>
    dispatch(changeState(id, states, comments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationEntity);
