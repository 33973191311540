import React, { Suspense, useState } from 'react';
import { AppState } from '../../../redux/root-reducer';
import { selectGetScholarship } from '../../../redux/scholarship/selectors';
import { tKey } from '../../../helpers/translate';
import { connect } from 'react-redux';
import { ScholarshipData } from '../../../redux/scholarship/definitions';
import TrackingModal from '../../../components/Modal/TrackingModal/TrackingModal';
import { KeyButton } from '../../../redux/common/definitions';
import { getMoreInfoButtonType } from '../ButtonActions/MoreInfoButtonActions';

interface Props {
  scholarshipData: ScholarshipData;
}
const TrackingScholarshipModal = ({ scholarshipData }: Props) => {
  const [modal, setModal] = useState(false);
  const buttonTracking = getMoreInfoButtonType(KeyButton.TRACKING);
  buttonTracking.onClick = () => {
    setModal(true);
  };
  return (
    <Suspense fallback={<></>}>
      <TrackingModal
        view={modal}
        resource_id={scholarshipData.id}
        resource_type={'Scholarship'}
        title={tKey('Beca').concat(
          ' ',
          scholarshipData.student_name || '',
          ' ',
          scholarshipData.student_surname || '',
          ' ',
          scholarshipData.student_surname2 || '',
        )}
        handleClose={() => setModal(false)}
      />
    </Suspense>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipData: selectGetScholarship(state),
});

export default connect(mapStateToProps)(TrackingScholarshipModal);
