import { put, takeLatest } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { APIBadPlatformError, Await, setToken } from '../../api/api';
import { authUserChangeRole, getUserAvatar, login, logoutApi, setUserAvatar } from '../../api/auth';
import { activationEntity } from '../../api/Entity/entity';
import { accountActivated } from '../../api/verifyToken';
import assertUnreachable from '../../helpers/assertUnreachable';
import { getNotificationsStartPolling, getNotificationsStopPolling } from '../notification/actions';
import { getGenericMetadata } from '../metadata/actions';
import { permissionsList } from '../permissions/actions';
import {
  getPlatformTranslationKo,
  getPlatformTranslationOk,
  activationEntityKo,
  activationEntityOk,
  getUserProfileAvatarKo,
  getUserProfileAvatarOk,
  loginKO,
  loginOk,
  loginRedirect,
  logoutActionKO,
  logoutActionOk,
  setUserProfileAvatarKo,
  setUserProfileAvatarOk,
  userActivationKO,
  userActivationOK,
} from './actions';
import { AuthorizationConsts } from './action_types';
import {
  PlatformTranslation,
  AuthLoginAction,
  AuthLogoutAction,
  AuthUserAvatarAction,
  AuthUserChangeRole,
  AuthUserGetAvatarAction,
  EntityActivationAction,
  UserActivationAction,
} from './definitions';
import webStyle, { alternativeURL } from '../../helpers/webStyle';
import { getCategoriesTkeysOk } from '../translations/actions';
import { getTranslationKeys } from '../../api/translationKeys';
import { getProfileFromSaga } from '../user/sagas';

function* getPlatformTranslationSaga(action: PlatformTranslation): Generator<any, void, any> {
  try {
    const tKeysData = (yield getTranslationKeys(action.languageCode)) as Await<ReturnType<typeof getTranslationKeys>>;
    yield put(getCategoriesTkeysOk(tKeysData));
    yield put(getPlatformTranslationOk());
  } catch (e) {
    yield put(getPlatformTranslationKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loginSaga(action: AuthLoginAction): Generator<any, void, any> {
  try {
    const result = (yield login({
      email: action.email,
      password: action.password,
    })) as Await<ReturnType<typeof login>>;

    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        const { role_platform, language, token } = data;
        const tKeysData = (yield getTranslationKeys(language)) as Await<ReturnType<typeof getTranslationKeys>>;
        yield put(getCategoriesTkeysOk(tKeysData));
        if (role_platform === webStyle || role_platform === 'all') {
          yield setToken(token);
          yield put(getNotificationsStartPolling());
          yield put(permissionsList());
          yield getProfileFromSaga();
          yield put(loginOk(data));
          yield put(getGenericMetadata());
        } else {
          yield put(loginRedirect(new APIBadPlatformError(alternativeURL(), token)));
        }
        return;
      case 'validation-error':
        const error = result.value;
        yield put(loginKO(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(loginKO(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* logoutSaga(): Generator<any, void, any> {
  try {
    yield setToken(null);
    yield logoutApi();
    yield put(logoutActionOk());
    yield put(getNotificationsStopPolling());
  } catch (e) {
    yield put(logoutActionKO());
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* setUserAvatarSaga(action: AuthUserAvatarAction): Generator<any, void, any> {
  try {
    yield setUserAvatar(action.image);
    yield put(setUserProfileAvatarOk(action.image));
  } catch (e) {
    yield put(setUserProfileAvatarKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getUserAvatarSaga(): Generator<any, void, any> {
  try {
    const { data } = (yield getUserAvatar()) as Await<ReturnType<typeof getUserAvatar>>;
    yield put(getUserProfileAvatarOk(data));
  } catch (e) {
    yield put(getUserProfileAvatarKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* entityActivationFromSaga(action: EntityActivationAction): Generator<any, void, any> {
  try {
    const data = (yield activationEntity(action.token)) as Await<ReturnType<typeof activationEntity>>;
    const tKeysData = (yield getTranslationKeys(data.language)) as Await<ReturnType<typeof getTranslationKeys>>;
    yield put(getCategoriesTkeysOk(tKeysData));
    setToken(data.token);
    yield put(permissionsList());
    yield put(activationEntityOk(data));
  } catch (e) {
    yield put(activationEntityKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* userActivationFromSaga(action: UserActivationAction): Generator<any, void, any> {
  try {
    const { data } = (yield accountActivated(action.token)) as Await<ReturnType<typeof accountActivated>>;
    const tKeysData = (yield getTranslationKeys(data.language)) as Await<ReturnType<typeof getTranslationKeys>>;
    yield put(getCategoriesTkeysOk(tKeysData));
    setToken(data.token);
    yield put(userActivationOK(data));
  } catch (e) {
    yield put(userActivationKO(e));
    yield put(push('/'));
  }
}

function* userChangeRoleFromSaga(action: AuthUserChangeRole): Generator<any, void, any> {
  try {
    const result = (yield authUserChangeRole(action.user_id, action.role_id)) as Await<
      ReturnType<typeof authUserChangeRole>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        const { role_platform, language, token } = data;
        const tKeysData = (yield getTranslationKeys(language)) as Await<ReturnType<typeof getTranslationKeys>>;
        yield put(getCategoriesTkeysOk(tKeysData));
        if (role_platform === webStyle || role_platform === 'all') {
          yield setToken(token);
          yield put(getNotificationsStartPolling());
          yield put(permissionsList());
          yield getProfileFromSaga();
          yield put(loginOk(data));
          yield put(getGenericMetadata());
        } else {
          yield put(loginRedirect(new APIBadPlatformError(alternativeURL(), token)));
        }
        return;
      case 'validation-error':
        const error = result.value;
        yield put(loginKO(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(loginKO(e));
  }
}

export default [
  takeLatest<PlatformTranslation>(AuthorizationConsts.PLATFORM_TRANSLATION, getPlatformTranslationSaga),
  takeLatest<AuthLoginAction>(AuthorizationConsts.AUTH_LOGIN, loginSaga),
  takeLatest<AuthLogoutAction>(AuthorizationConsts.AUTH_LOGOUT, logoutSaga),
  takeLatest<AuthUserAvatarAction>(AuthorizationConsts.AUTH_SET_USER_AVATAR, setUserAvatarSaga),
  takeLatest<AuthUserGetAvatarAction>(AuthorizationConsts.AUTH_GET_USER_AVATAR, getUserAvatarSaga),
  takeLatest<UserActivationAction>(AuthorizationConsts.AUTH_USER_ACTIVATION, userActivationFromSaga),
  takeLatest<EntityActivationAction>(AuthorizationConsts.ACTIVATION_ENTITY, entityActivationFromSaga),
  takeLatest<AuthUserChangeRole>(AuthorizationConsts.AUTH_USER_CHANGE_ROLE, userChangeRoleFromSaga),
];
