import assertUnreachable from '../../../helpers/assertUnreachable';
import { ScholarshipDataState } from '../definitions';
import { ScholarshipTransitionsConst } from './action_types';
import { ScholarshipTransitionDataAction } from './definitions';

const scholarshipTransitionReducer = (
  state: ScholarshipDataState,
  action: ScholarshipTransitionDataAction,
): ScholarshipDataState => {
  switch (action.type) {
    case ScholarshipTransitionsConst.SCHOLARSHIP_LEAVE:
      return {
        ...state,
        loading: true,
        loadingScholarshipUser: true,
        error: null,
      };
    case ScholarshipTransitionsConst.SCHOLARSHIP_STATE_TRANSITION:
      return {
        ...state,
        error: null,
      };
    case ScholarshipTransitionsConst.SCHOLARSHIP_LEAVE_OK: {
      const { state_id, state_code, state_name } = action.state;
      return {
        ...state,
        loading: false,
        loadingScholarshipUser: false,
        error: null,
        scholarshipData: {
          ...state.scholarshipData,
          state_id,
          state_code,
          state: state_name,
        },
      };
    }
    case ScholarshipTransitionsConst.SCHOLARSHIP_STATE_TRANSITION_OK:
      return {
        ...state,
        loading: false,
        loadingScholarshipUser: false,
        error: null,
        state_transition: action.states,
      };
    case ScholarshipTransitionsConst.SCHOLARSHIP_TRANSITION_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        loadingScholarshipUser: false,
        error,
      };
    case ScholarshipTransitionsConst.SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION:
      return {
        ...state,
        loading: true,
        loadingScholarshipUser: false,
        error: null,
      };
    case ScholarshipTransitionsConst.SCHOLARSHIP_PENDING_JUSTIFICATION_TRANSITION_OK: {
      const { state_id, state_code, state_name } = action.state;
      return {
        ...state,
        loading: false,
        loadingScholarshipUser: false,
        error: null,
        scholarshipData: {
          ...state.scholarshipData,
          state_id,
          state_code,
          state: state_name,
        },
      };
    }
    default:
      assertUnreachable(action);
      return state;
  }
};

export default scholarshipTransitionReducer;
