// Components
import React, { useEffect, useRef, useState } from 'react';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import './wysiwyg.css';
import styles from './wysiwyg.module.scss';
import './wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

interface WYSIWYGProps {
  defaultValue?: string;
  setData?: (data: string) => void;
}

const WYSIWYG: React.FC<WYSIWYGProps> = ({ defaultValue, setData }) => {
  const refValue = useRef<string>();

  const [editorState, setEditorState] = useState<EditorState>();
  useEffect(() => {
    if (!refValue.current) {
      refValue.current = defaultValue;
      const blocksFromHTML = convertFromHTML(defaultValue || '');
      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      setEditorState(EditorState.createWithContent(state));
    }
  }, [defaultValue]);

  const onEditorStateChange = (data: EditorState) => {
    setEditorState(data);
    const value = draftToHtml(convertToRaw(data.getCurrentContent()));
    setData && setData(value);
  };

  return (
    <div className={styles.editorContainer}>
      <Editor
        editorState={editorState}
        wrapperClassName="wrapper-class"
        editorClassName={styles.editor}
        toolbarClassName={styles.toolbar}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: ['inline', 'list', 'textAlign', 'colorPicker', 'fontSize', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          textAlign: {
            options: ['left', 'center', 'justify'],
          },
        }}
      />
    </div>
  );
};

export default React.memo(WYSIWYG);
