import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/root-reducer';
import { ScholarshipData } from '../../../redux/scholarship/definitions';
import { FamilyIncome } from '../../../redux/scholarship/familyIncomes/FamilyIncome';
import { addNewFamilyIncome } from '../../../redux/scholarship/familyIncomes/create/actions';
import { removeFamilyIncome } from '../../../redux/scholarship/familyIncomes/delete/actions';
import {
  selectFamilyIncomesList,
  selectFamilyIncomesLoading,
} from '../../../redux/scholarship/familyIncomes/list/selectors';
import { updateFamilyIncome, updateFamilyIncomeOk } from '../../../redux/scholarship/familyIncomes/update/actions';
import FamilyIncomesTable from './FamilyIncomesTable/FamilyIncomesTable';
import { tErrorKey } from '../../../helpers/translate';

interface FamilyIncomesViewProps {
  scholarshipData: ScholarshipData;
  readOnly: boolean;
  data: FamilyIncome[];
  loading: boolean;
  addFamilyIncome: (data: FamilyIncome) => void;
  deleteFamilyIncome: (data: FamilyIncome) => void;
  updateFamilyIncome: (data: FamilyIncome) => void;
  setFamilyIncome: (data: FamilyIncome) => void;
}

const FamilyIncomesView: React.FC<FamilyIncomesViewProps> = ({
  scholarshipData,
  readOnly,
  data,
  loading,
  addFamilyIncome,
  deleteFamilyIncome,
  updateFamilyIncome,
  setFamilyIncome,
}) => {
  const validateFamilyIncomes = (): string | undefined => {
    const {
      steps: { economicalValoration },
    } = scholarshipData;
    if (economicalValoration.errorFields?.includes('family_incomes')) {
      return tErrorKey('family_incomes.required');
    }
  };

  return (
    <FamilyIncomesTable
      scholarshipId={scholarshipData.id}
      familyIncomes={data}
      disabled={readOnly}
      loading={loading}
      error_message={validateFamilyIncomes()}
      addFamilyIncome={addFamilyIncome}
      deleteFamilyIncome={deleteFamilyIncome}
      updateFamilyIncome={updateFamilyIncome}
      setFamilyIncome={setFamilyIncome}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  data: selectFamilyIncomesList(state),
  loading: selectFamilyIncomesLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  addFamilyIncome: (data: FamilyIncome): void => dispatch(addNewFamilyIncome(data)),
  deleteFamilyIncome: (data: FamilyIncome): void => dispatch(removeFamilyIncome(data)),
  updateFamilyIncome: (data: FamilyIncome): void => dispatch(updateFamilyIncome(data)),
  setFamilyIncome: (data: FamilyIncome): void => dispatch(updateFamilyIncomeOk(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FamilyIncomesView);
