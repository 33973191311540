import CheckIcon from '@material-ui/icons/Check';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { useState } from 'react';
import colorPalette from '../../../helpers/colorPalette';
import { tKey } from '../../../helpers/translate';
import webStyle from '../../../helpers/webStyle';
import styles from './multiDocumentModalPicker.module.scss';

interface MultiDocumentModalPickerProps {
  addDoc: (document: File, description: string, genericId?: any, documentationId?: number, fieldName?: string) => void;
  disabled?: boolean;
  handleClose: () => void;
}

const MultiDocumentModalPicker: React.FC<MultiDocumentModalPickerProps> = ({ disabled, addDoc, handleClose }) => {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [uploadReady, setUploadReady] = useState(false);
  const [documentsReady, setDocumentsReady] = useState<File[]>();

  const [highlight, setHighligh] = useState(false);
  const openFileDialog = () => {
    if (disabled) return;
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileListToArray = (list: FileList) => {
    const values = Object.values(list);
    values.forEach(e => {
      addDoc(e, '');
    });
    setDocumentsReady(values);
    setUploadReady(true);
  };

  const onFilesAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return;
    if (event.target) {
      const files: any = event.target.files;
      fileListToArray(files);
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) return;

    setHighligh(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighligh(false);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (disabled) return;

    if (event.dataTransfer) {
      const files = event.dataTransfer.files;
      fileListToArray(files);
      setHighligh(false);
    }
  };

  return (
    <div
      className={highlight ? styles.Highlight : !disabled ? styles.Dropzone : styles.disabled}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onClick={openFileDialog}
      style={{
        cursor: disabled ? 'default' : 'pointer',
      }}
    >
      <React.Fragment>
        <input
          ref={fileInputRef}
          className={styles.FileInput}
          type="file"
          multiple
          onChange={onFilesAdded}
          accept="application/pdf,image/*,.xlsx,.docx,.doc,.xls"
        />
        {documentsReady && uploadReady ? (
          <div className={disabled ? styles.itemListDisabled : styles[`itemList_${webStyle}`]}>
            <span>{tKey('Archivos Adjuntados Correctamente')}</span>
            {documentsReady.map(e => (
              <div className={styles.item} key={e.name}>
                <CheckIcon style={{ color: colorPalette.greenBubble }} />
                <span>{e.name}</span>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles[`uploadContainer_${webStyle}`]}>
            <CloudUploadIcon />
            <span style={{ color: colorPalette.secondary80 }}>
              <span className={styles[`here_${webStyle}`]}>{tKey('Click Aquí')}</span>{' '}
              {tKey('o Arrastra para subir archivos')}
            </span>
          </div>
        )}
      </React.Fragment>
    </div>
  );
};

export default MultiDocumentModalPicker;
