import {
  AccountingSettings,
  ExpenseAccount,
  InvoiceData,
  InvoiceDetail,
  ForecastItem,
  InvoiceListItem,
  ScholarshipSetting,
  PendingInvoiceData,
} from '../redux/accounting/definitions';
import { APIResponse, FileItem } from '../redux/common/definitions';
import { CreditCardsFile } from '../routes/Accounting/MassiveUpload/MassiveUpload';
import { isForecastItem } from './accountingGuards';
import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from './api';

const baseURL = apiBaseUrl;

interface SuccessResult {
  data: string;
}

export const getExpenseAccountListFromApi = async (): Promise<ExpenseAccount[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/expense_account`, init);
  return data;
};

export const createExpenseAccountApi = async (
  data: ExpenseAccount,
): Promise<Result<{ data: ExpenseAccount }, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };
  return validatedApiCall<{ data: ExpenseAccount }>(`${baseURL}/expense_account`, init);
};

export const deleteExpenseAccountByIdApi = async (id: number): Promise<any> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<any>(`${baseURL}/expense_account/${id}`, init);
  return data;
};

export const getOneExpenseAccountByIdApi = async (id: number): Promise<{ data: ExpenseAccount }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: ExpenseAccount }>(`${baseURL}/expense_account/${id}`, init);
};

export const editExpenseAccountApi = async (
  expenseAccount: ExpenseAccount,
  id: number,
): Promise<Result<{ data: ExpenseAccount }, APIValidationError> | any> => {
  const sentData = Object.assign(expenseAccount, { id: id });
  const init = {
    method: 'PUT',
    body: JSON.stringify(sentData),
    auth: true,
  };
  return validatedApiCall<{ data: ExpenseAccount }>(`${baseURL}/expense_account`, init);
};

export const getAccountingSettingsFromApi = async (): Promise<AccountingSettings> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/accounting_setting`, init);
  return data;
};

export const editAccountingSettingsApi = async (accountingSettings: AccountingSettings): Promise<boolean> => {
  const sentData = Object.assign(accountingSettings);
  const init = {
    method: 'PUT',
    body: JSON.stringify(sentData),
    auth: true,
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/accounting_setting`, init);

  return data === 'OK';
};

export const uploadInvoiceDocumentFromApi = async (file: File, invoice_id: number): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  dataForm.append('invoice_id', invoice_id.toString());
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/invoice/file/upload`, init);

  return data;
};

export const deleteInvoiceDocumentFromApi = async (file_id: number): Promise<string> => {
  const init = {
    method: 'POST',
    body: JSON.stringify({ file_id }),
    auth: true,
  };
  const { data } = await apiCall<SuccessResult>(`${baseURL}/invoice/file/remove`, init);
  return data;
};

export const downloadInvoiceDocumentFromApi = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCallBlob(`${baseURL}/invoice/file/download/${file_id}`, init);
};

export const createInvoiceFromApi = async (entity_id: number): Promise<InvoiceData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ entity_id }),
  };
  const { data } = await apiCall<{ data: InvoiceData }>(`${baseURL}/invoice`, init);
  return data;
};

export const getInvoiceFromApi = async (id: number): Promise<InvoiceData> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: InvoiceData }>(`${baseURL}/invoice/${id}`, init);
  return data;
};

export const getActiveInvoicesFromApi = async (): Promise<InvoiceListItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: InvoiceListItem[] }>(`${baseURL}/invoice/role/list`, init);
  return data;
};

export const getPaidInvoicesFromApi = async (): Promise<InvoiceListItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: InvoiceListItem[] }>(`${baseURL}/invoice/paid/list`, init);
  return data;
};

export const getAuthorizeInvoicesFromApi = async (): Promise<InvoiceListItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: InvoiceListItem[] }>(`${baseURL}/invoice/authorized/list`, init);
  return data;
};

export const editInvoiceFromApi = async (
  invoice: InvoiceData,
): Promise<Result<{ data: InvoiceData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(invoice),
  };
  return await validatedApiCall<{ data: InvoiceData }>(`${baseURL}/invoice`, init);
};

export const getInvoiceDetailFromApi = async (id: number): Promise<InvoiceDetail> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: InvoiceDetail }>(`${baseURL}/invoice/detail/${id}`, init);
  return data;
};

export const getInvoicesDetailListFromApi = async (
  invoice_id: number,
): Promise<Result<{ data: InvoiceDetail[] }, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return await validatedApiCall<{ data: InvoiceDetail[] }>(`${baseURL}/invoice/${invoice_id}/detail`, init);
};

export const createNewInvoiceDetailFromApi = async (
  newInvoiceDetail: InvoiceDetail,
): Promise<Result<{ data: InvoiceDetail[] }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(newInvoiceDetail),
  };
  return await validatedApiCall<{ data: InvoiceDetail[] }>(`${baseURL}/invoice/detail`, init);
};

export const editInvoiceDetailFromApi = async (
  invoiceDetail: InvoiceDetail,
): Promise<Result<{ data: InvoiceDetail[] }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(invoiceDetail),
  };
  return await validatedApiCall<{ data: InvoiceDetail[] }>(`${baseURL}/invoice/detail`, init);
};

export const deleteInvoiceDetailFromApi = async (invoiceDetail: InvoiceDetail): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<SuccessResult>(`${baseURL}/invoice/detail/${invoiceDetail.id}`, init);
  return data;
};

export const deleteInvoiceFromApi = async (id: number): Promise<Result<{ data: InvoiceData }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  return await validatedApiCall<{ data: InvoiceData }>(`${baseURL}/invoice/${id}`, init);
};

export const getForecast = async (): Promise<ForecastItem> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: ForecastItem }>(`${baseURL}/payment/export/forecast`, init);

  if (isForecastItem(data)) {
    return data;
  }
  throw Error('Error!');
};

export const doTransitionBackInvoice = async (
  invoiceId: number,
  comment: string,
  state: string,
): Promise<{ data: InvoiceData }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id: invoiceId, comment }),
  };
  return await apiCall<{ data: InvoiceData }>(`${baseURL}/invoice/${state}`, init);
};

export const sendInvoiceFromApi = async (
  invoice: InvoiceData,
): Promise<Result<{ data: InvoiceData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(invoice),
  };
  return await validatedApiCall<{ data: InvoiceData }>(`${baseURL}/invoice/send`, init);
};

export const acceptInvoiceFromApi = async (
  invoice: InvoiceData,
): Promise<Result<{ data: InvoiceData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(invoice),
  };
  return await validatedApiCall<{ data: InvoiceData }>(`${baseURL}/invoice/accept`, init);
};

export const confirmInvoiceFromApi = async (invoice: InvoiceData): Promise<{ data: InvoiceData }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(invoice),
  };
  return apiCall<{ data: InvoiceData }>(`${baseURL}/invoice/confirm`, init);
};

export const toPayInvoiceFromApi = async (data: { id: number }): Promise<InvoiceData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  return await apiCall<InvoiceData>(`${baseURL}/invoice/to_pay`, init);
};

export const sendInvoicesToPay = async (): Promise<string> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/invoice/to_pay/all`, init);
  return data;
};

export const payInvoices = async (): Promise<{ messages: string[]; paid_invoices: number }> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  const { data } = await apiCall<{ data: { messages: string[]; paid_invoices: number } }>(
    `${baseURL}/invoice/pay/all`,
    init,
  );
  return data;
};

export const getScholarshipSetting = async (): Promise<ScholarshipSetting> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: ScholarshipSetting }>(`${baseURL}/scholarship_setting/`, init);
  return data;
};

export const updateScholarshipSetting = async (
  value: ScholarshipSetting,
): Promise<Result<{ data: ScholarshipSetting }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(value),
  };

  return await validatedApiCall<{ data: ScholarshipSetting }>(`${baseURL}/scholarship_setting/`, init);
};

export const uploadScholarshipCreditCardsByDocument = async (file: CreditCardsFile[]) => {
  const init = {
    method: 'POST',
    body: JSON.stringify({ data: file }),
    auth: true,
  };
  return apiCall<{ data: any }>(`${baseURL}/user/card_numbers`, init);
};
export const getPendingToSendInvoicesFromApi = async (): Promise<{ data: InvoiceListItem[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: InvoiceListItem[] }>(`${baseURL}/invoice/paid/review`, init);
};

export const removePendingInvoiceFromApi = async (id: number): Promise<{ data: InvoiceListItem[] }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return await apiCall<{ data: InvoiceListItem[] }>(`${baseURL}/invoice/paid/review/invoice/${id}`, init);
};

export const getOnePendingInvoiceFromApi = async (id: number): Promise<{ data: PendingInvoiceData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: PendingInvoiceData }>(`${baseURL}/invoice/paid/review/invoice/${id}`, init);
};

export const updatePendingInvoiceFromApi = async (
  invoice: PendingInvoiceData,
): Promise<Result<{ data: InvoiceListItem[] }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(invoice),
  };

  return await validatedApiCall<{ data: InvoiceListItem[] }>(`${baseURL}/invoice/paid/review/invoice`, init);
};

export const markInvoiceAsPaidApi = async (id: number): Promise<InvoiceData> => {
  const init = {
    method: 'POST',
    body: JSON.stringify({ id }),
    auth: true,
  };
  const { data } = await apiCall<{ data: InvoiceData }>(`${baseURL}/invoice/pay/force_paid`, init);
  return data;
};

export const authorizeInvoiceFromApi = async (invoice_id: number): Promise<InvoiceData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ invoice_id }),
  };
  const { data } = await apiCall<{ data: InvoiceData }>(`${baseURL}/invoice/authorize`, init);
  return data;
};

export const closeInvoicesFromApi = async (closing_year: number): Promise<number> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ closing_year }),
  };
  const { data } = await apiCall<{ data: { invoices_closed: number } }>(`${baseURL}/invoice/close_year/all`, init);
  return data.invoices_closed;
};

export const moveBackInvoiceFromApi = async (invoice_id: number): Promise<InvoiceData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id: invoice_id }),
  };
  const { data } = await apiCall<{ data: InvoiceData }>(`${baseURL}/invoice/return_to_accepted`, init);
  return data;
};
