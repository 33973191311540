import { Action } from 'redux';
import { FeedbackAPIErrorConsts } from './action_types';

export const initialStateFeedbackAPI = {
  isSnackbarShowing: false as boolean,
  message: null as string | null,
  severity: 'error' as SeveritySnackbar | undefined,
  redirect: undefined as string | undefined,
  autoHideDuration: 6000 as number | undefined,
};
export type SeveritySnackbar = 'error' | 'warning' | 'info' | 'success';
export type FeedbackAPIState = typeof initialStateFeedbackAPI;

export interface ShowSnackbarAction extends Action {
  type: FeedbackAPIErrorConsts.SHOW_SNACKBAR;
  message: string;
  severity: SeveritySnackbar;
  redirect?: string;
  autoHideDuration?: number;
}
export interface HideSnackbarAction extends Action {
  type: FeedbackAPIErrorConsts.HIDE_SNACKBAR;
}

export type FeedbackAPIAction = ShowSnackbarAction | HideSnackbarAction;

export type FeedbackAPIActionDispatch = (action: FeedbackAPIAction) => void;
