import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { tKey } from '../../../../helpers/translate';
import { getNotificationsUpdate } from '../../../../redux/notification/actions';
import { AppState } from '../../../../redux/root-reducer';
import styles from './menuRightNotifications.module.scss';

interface MenuRightNotifications {
  unread_pending_tasks: number;
  unread_alerts: number;
  unread_message_threads: number;
  onClose: () => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  getNotifications: () => void;
}

const MenuRightNotifications: React.FC<MenuRightNotifications> = ({
  unread_pending_tasks,
  unread_alerts,
  unread_message_threads,
  onClose,
  getNotifications,
}) => {
  useEffect(() => {
    getNotifications();
  });

  const Message = () => (
    <Fragment>
      <Link to="/mensajes" className={styles.pendingWrapper}>
        <div className={styles.tag}>{tKey('Mensaje')}</div>
        {tKey('Tienes')} <strong>{unread_message_threads}</strong>{' '}
        {unread_message_threads > 1 ? tKey('mensajes nuevos') : tKey('mensaje nuevo')}
      </Link>
    </Fragment>
  );

  const Alert = () => (
    <Fragment>
      <Link to="/alertas" className={styles.pendingWrapper}>
        <div className={styles.tag}>{tKey('Alerta')}</div>
        {tKey('Tienes')} <strong>{unread_alerts}</strong>{' '}
        {unread_alerts > 1 ? tKey('alertas') : tKey('Alerta').toLocaleLowerCase()}
      </Link>
    </Fragment>
  );

  const PendingTask = () => (
    <Fragment>
      <Link to="/tareas-pendientes" className={styles.pendingWrapper}>
        <div className={styles.tag}>{tKey('Tarea pendiente')}</div>
        {tKey('Tienes')} <strong>{unread_pending_tasks}</strong>{' '}
        {unread_pending_tasks > 1 ? tKey('tareas pendiente/s') : tKey('tareas pendiente/s')}
      </Link>
    </Fragment>
  );

  const NoNotifications = () => (
    <Fragment>
      <div className={styles.no_notifications}>{tKey('No tienes notificaciones pendientes')}</div>
    </Fragment>
  );

  return (
    <div className={styles.wrapper}>
      <h3 className={styles.mainTitle}>
        <NotificationImportantIcon className={styles.notification_icon} />
        {tKey('Últimas actualizaciones')}
        <IconButton aria-label="delete" size="small" className={styles.close_icon} onClick={onClose()}>
          <CloseIcon />
        </IconButton>
      </h3>
      {unread_message_threads > 0 && <Message />}
      {unread_alerts > 0 && <Alert />}
      {unread_pending_tasks > 0 && <PendingTask />}
      {unread_pending_tasks === 0 && unread_message_threads === 0 && unread_alerts === 0 && <NoNotifications />}

      <Link to="/alertas" className={`${styles.see_all} ${styles.pendingWrapper}`}>
        {tKey('Ver todas las notificaciones')}
      </Link>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  getNotifications: (): void => dispatch(getNotificationsUpdate()),
});
export default connect(mapStateToProps, mapDispatchToProps)(MenuRightNotifications);
