import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { tFormKey } from '../../../../../helpers/translate';
import { InformationForm, InternalProject } from '../../../../../redux/internal-projects/definitions';
import { AppState } from '../../../../../redux/root-reducer';
import { InformationSchema } from '../../../../../validations/formSchema';
import formStyles from '../../../../../components/GenericForm/genericFormCustomField.module.scss';
import styles from './information.module.scss';
import FncInputTextController from '../../../../../components/Forms/FncInputs/FncInputTextController';
import { useWindowSize } from '../../../../../helpers/customHooks/useHookMethod';

interface InformationProps {
  internalProject: InternalProject;
}

const Information: React.FC<InformationProps> = ({ internalProject }) => {
  const { errors, control } = useForm<InformationForm>({
    validationSchema: InformationSchema,
  });
  const isMobile = useWindowSize().IsMobile;

  return (
    <div className={`${styles.root}`}>
      <FncInputTextController
        control={control}
        errors={errors}
        name="name"
        schema={InformationSchema}
        label={!isMobile ? tFormKey('ip.nombre_proyecto') : ''}
        placeholder={isMobile ? tFormKey('ip.nombre_proyecto') : ''}
        disabled
        defaultValue={internalProject.name}
        customClass={`${formStyles.field} ${formStyles.inputText} ${formStyles.Medium}`}
      />
      <FncInputTextController
        control={control}
        errors={errors}
        name="entity"
        schema={InformationSchema}
        label={!isMobile ? tFormKey('ip.entidad') + ' / ' + tFormKey('ip.centro_educativo') : ''}
        placeholder={isMobile ? tFormKey('ip.entidad') + ' / ' + tFormKey('ip.centro_educativo') : ''}
        disabled
        defaultValue={internalProject.academy_name ?? internalProject.entity_name}
        customClass={`${formStyles.field} ${formStyles.inputText} ${formStyles.Medium}`}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  internalProject: state.internalProjectReducer.internalProjectData,
});

export default connect(mapStateToProps)(Information);
