import { PaymentInfo } from '../../redux/project/definitions';
import { ChangeDateValues, ScholarshipReloadData } from '../../redux/scholarship/definitions';
import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

export const changeScholarshipPaymentDateFromApi = async (changeDateValues: ChangeDateValues): Promise<boolean> => {
  const { scholarship_id, payment_field, payment_date, payment_type } = changeDateValues;
  const init = {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify({ scholarship_id, payment_field, payment_date, payment_type }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/scholarship/payment/change/date`, init);
  return data === 'OK';
};

export const sendScholarshipPaymentDataFromApi = async (paymentData: PaymentInfo): Promise<boolean> => {
  const { id: scholarship_id, payment_field, account_number } = paymentData;
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ scholarship_id, payment_field, account_number }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/scholarship_agreement/scholarship/payment`, init);
  return data === 'OK';
};

export const sendScholarshipReloadDataFromApi = async (reloadData: ScholarshipReloadData): Promise<boolean> => {
  const { id: scholarship_id, payment_field } = reloadData;
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({
      scholarship_id,
      payment_field,
    }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/scholarship_agreement/scholarship/reload`, init);
  return data === 'OK';
};

export const sendScholarshipStudentTransferDataFromApi = async (paymentData: PaymentInfo): Promise<boolean> => {
  const { id: scholarship_id, payment_field } = paymentData;
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({
      scholarship_id,
      payment_field,
    }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/scholarship_agreement/scholarship/student`, init);
  return data === 'OK';
};
