import { Button, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import { tKey } from '../../../helpers/translate';
import { AcademyContactData } from '../../../redux/academy/definitions';
import { AcademyContactSchema } from '../../../validations/formSchema';
import styles from './modalCreate.module.scss';

interface ModalCreateResponsibleProps {
  loading: boolean;
  contactData: AcademyContactData;
  academyId: number;
  setData: (key: string, value: string | number) => void;
  onSave: (contactData: any, academy_id: number) => void;
  closeContactModal: () => void;
  deleteContactData: () => void;
}

const staffRoles = {
  HEADMASTER: 'Director/a',
  HEAD_OF_STUDIES: 'Jefe de estudios',
  TEACHER: 'Profesor/a',
  COORDINATOR: 'Coordinador/a pedagogía',
  ADMINISTRATION: 'Persona departamento administración',
  SECRETARY: 'Personal secretaría',
};

const UnconnectedGenericModalResponsible: React.FC<ModalCreateResponsibleProps> = ({
  loading,
  contactData,
  academyId,
  setData,
  onSave,
  closeContactModal,
  deleteContactData,
}) => {
  const { staff_role, name, surname, email, phone, description } = contactData;
  const { errors, handleSubmit, control } = useForm<AcademyContactData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AcademyContactSchema,
  });

  const onSubmit = () => {
    onSave(contactData, academyId);
    deleteContactData();
    closeContactModal();
  };

  if (loading)
    return (
      <div style={{ minHeight: '430px' }}>
        <Loading big />
      </div>
    );

  return (
    <div>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.newcontact}>
          <SelectController
            control={control}
            size="Large"
            key="staff_role"
            name="staff_role"
            defaultValue={staff_role}
            errors={errors}
            schema={AcademyContactSchema}
            label={tKey('Cargo')}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('staff_role', e.target.value)}
          >
            {Object.values(staffRoles).map((value: string) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectController>
        </div>
        <div className={styles.newcontact}>
          <TextInputController
            size="50"
            label={tKey('Nombre')}
            name="name"
            defaultValue={name}
            control={control}
            errors={errors}
            schema={AcademyContactSchema}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('name', e.target.value)}
          />
          <TextInputController
            size="50"
            label={tKey('Apellidos')}
            name="surname"
            defaultValue={surname}
            control={control}
            errors={errors}
            schema={AcademyContactSchema}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('surname', e.target.value)}
          />
        </div>
        <div className={styles.newcontact}>
          <TextInputController
            size="50"
            label={tKey('Email')}
            name="email"
            defaultValue={email}
            control={control}
            errors={errors}
            schema={AcademyContactSchema}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('email', e.target.value)}
          />
          <TextInputController
            size="50"
            label={tKey('Teléfono móvil')}
            name="phone"
            defaultValue={phone}
            control={control}
            errors={errors}
            schema={AcademyContactSchema}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('phone', e.target.value)}
          />
        </div>
        <div className={styles.newcontact}>
          <TextInputController
            size="Large"
            label={tKey('Comentarios')}
            name="description"
            defaultValue={description}
            control={control}
            errors={errors}
            schema={AcademyContactSchema}
            rowsMax={5}
            rows={5}
            multiline
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('description', e.target.value)}
          />
        </div>

        <div className={styles.btn_create}>
          <Button color="primary" variant="outlined" onClick={handleSubmit(onSubmit)}>
            {tKey('Agregar')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UnconnectedGenericModalResponsible;
