import { ButtonProps } from '@material-ui/core';
import { Save, Send } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RouteComponentProps } from 'react-router-dom';
import { APIValidationError } from '../../../api/api';
import Layout from '../../../components/Layout/Layout';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import Loading from '../../../components/Loading/Loading';
import AvatarImagev2 from '../../../components/MenuImageRound/MenuImageRound';
import TransitionModal from '../../../components/Modal/TransitionModal';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { documentationFilter } from '../../../helpers/docFilter';
import { tFormKey, tKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { EntityInfo, EntityStateCodes } from '../../../redux/entity/definitions';
import { entityFormSchema } from '../../../validations/Entities/entityFormSchema';
import EntityMenuRight from '../EntityMenuRight/EntityMenuRight';
import styles from '../entityMenu.module.scss';
import EntityForm from './EntityForm';

interface CreatePreEntityProps extends RouteComponentProps<{ id: string }> {
  entityData: EntityInfo;
  avatar: string | null;
  loading: boolean;
  isExternal: boolean;
  documentation: DocumentationItem[];
  validationErrors: APIValidationError | null;
  creationSended: boolean;
  countries: string[];
  sendUserEntity: (data: EntityInfo) => void;
  setAvatarPreEntity: (avatar: string) => void;
  getPreEntityUserData: () => void;
  getEntityData: (id: number) => void;
  updateEntity: (preEntityData: EntityInfo) => void;
  logoutApp: () => void;
  getMetadata: () => void;
}

interface ModalProps {
  view: boolean;
  body: string;
  title: string;
  yes?: () => void;
  ok?: string;
  children?: JSX.Element;
}

export const CreateEntityGeneric: React.FC<CreatePreEntityProps> = ({
  match,
  entityData,
  avatar,
  loading,
  isExternal,
  documentation,
  validationErrors,
  creationSended,
  countries,
  sendUserEntity,
  setAvatarPreEntity,
  getPreEntityUserData,
  getEntityData,
  updateEntity,
  logoutApp,
  getMetadata,
}) => {
  const {
    params: { id },
  } = match;

  const refMain = useRef(null);
  const resetScroll = (ref: any) => ref.current.parentElement.scroll(0, 0);
  const [idComponent, setIdComponent] = useState(0);
  const [createdModal, setCreatedModal] = React.useState<ModalProps>({
    view: false,
    body: '',
    title: '',
  });

  const entitySchema = entityFormSchema(!!entityData.is_visa_provider, !!entityData.no_payments_entity);

  const { register, handleSubmit, setError, setValue, watch, errors, triggerValidation, control, clearError } = useForm<
    EntityInfo
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    reValidateMode: 'onChange',
    validationSchema: entitySchema,
  });

  useEffect(() => {
    if (countries && countries.length === 0) {
      getMetadata();
    }
  }, [getMetadata, countries]);

  const buttonsPreEntity: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: () => {
        triggerValidation().then(ok => {
          if (ok) {
            clearError();
            updateEntity(entityData);
          }
        });
      },
      startIcon: <Save />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled:
        entityData.state_code !== EntityStateCodes.SOLICITUD_ENTIDAD &&
        entityData.state_code !== EntityStateCodes.SOLICITUD_FNC,
    },
    {
      children: tKey('Enviar'),
      onClick: () => {
        triggerValidation().then(ok => {
          if (ok) {
            clearError();
            sendUserEntity(entityData);
          }
        });
      },
      startIcon: <Send />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled:
        !entityData.required?.complete ||
        (entityData.state_code !== EntityStateCodes.SOLICITUD_ENTIDAD &&
          entityData.state_code !== EntityStateCodes.SOLICITUD_FNC),
    },
  ];

  if (isExternal) {
    buttonsPreEntity.push({
      children: tKey('Salir'),
      onClick: () => {
        logoutApp();
      },
      startIcon: <ExitToAppIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
    });
  }

  useValidatorAPI(validationErrors, setError);

  const handleComponentId = (componentId: number) => {
    !!refMain.current && resetScroll(refMain);
    setIdComponent(componentId);
  };

  useEffect(() => {
    if (isExternal) {
      getPreEntityUserData();
    } else {
      getEntityData(+id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isExternal && creationSended)
      setCreatedModal(prevState => ({
        ...prevState,
        view: true,
        body: tKey('Su solicitud se ha enviado con éxito'),
        title: tKey('Enhorabuena'),
        ok: 'Ok',
        yes: () => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
          getPreEntityUserData();
        },
      }));
  }, [creationSended]); // eslint-disable-line react-hooks/exhaustive-deps

  const optionsMenu: SubmenuItem[] = [
    {
      title: tKey('Datos generales'),
      componentId: 0,
      type: 'component',
      selected: idComponent === 0 ? true : false,
    },
  ];

  if (loading) return <Loading big />;

  const genDocs = ['NIF', 'CIF', 'ESC', 'APO', 'CTA', 'OTR', 'L49', 'ATL'];
  if (entityData.natural_person) {
    genDocs.push('ETR', 'FIP', 'IIP', 'SPA');
  }
  const docs = documentationFilter(documentation, genDocs);

  return (
    <Fragment>
      <Layout
        leftSubmenu={
          <Submenu
            titleMenu={tKey('DATOS DE ENTIDAD')}
            defaultOpen
            onSelectComponentId={handleComponentId}
            optionsMenu={optionsMenu}
            sectionAvatar={<AvatarImagev2 imageLink={avatar} setImage={setAvatarPreEntity} />}
          />
        }
        rightSubmenu={<EntityMenuRight actionButtons={buttonsPreEntity} goBack={true} />}
      >
        <div ref={refMain} className={styles.container}>
          <EntityForm
            title={tFormKey('Creación de Entidades')}
            entitySchema={entitySchema}
            useForm={{
              register,
              setValue,
              watch,
              errors,
              handleSubmit,
              triggerValidation,
              control,
              setError,
              clearError,
            }}
            userInfo={
              entityData.state_code !== EntityStateCodes.SOLICITUD_ENTIDAD &&
              entityData.state_code !== EntityStateCodes.SOLICITUD_FNC
                ? 'Su entidad está siendo procesada'
                : undefined
            }
            disabled={
              entityData.state_code !== EntityStateCodes.SOLICITUD_ENTIDAD &&
              entityData.state_code !== EntityStateCodes.SOLICITUD_FNC
            }
            responsible={isExternal}
            documentation={docs}
          />
        </div>
      </Layout>

      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        bodyModal={createdModal.body}
        handleYes={createdModal.yes}
        buttonOk={createdModal.ok}
      >
        {createdModal.children}
      </TransitionModal>
    </Fragment>
  );
};
