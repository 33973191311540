/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import {
  createInternalProjectTemplateFollowUpFromApi,
  deleteInternalProjectTemplateFollowUpOfArea,
  deleteInternalProjectTemplateOfArea,
  getInternalProjectTemplateInfoOfArea,
  getInternalProjectTemplatesInfoOfArea,
  publishInternalProjectTemplateOfArea,
  saveInternalProjectTemplateFollowUpRolesOfArea,
  saveInternalProjectTemplateGeneralFormRolesOfArea,
  saveInternalProjectTemplateOfArea,
} from '../../api/internalProjectTemplates';
import errorMessage from '../../helpers/errorMessage';
import { tKey } from '../../helpers/translate';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  createInternalProjectTemplateFollowUpKo,
  createInternalProjectTemplateFollowUpOk,
  deleteInternalProjectTemplateFollowUpKo,
  deleteInternalProjectTemplateFollowUpOk,
  deleteInternalProjectTemplateKo,
  deleteInternalProjectTemplateOk,
  getInternalProjectTemplateInfoKo,
  getInternalProjectTemplateInfoOk,
  getInternalProjectTemplatesInfoKo,
  getInternalProjectTemplatesInfoOk,
  publishInternalProjectTemplateKo,
  publishInternalProjectTemplateOk,
  updateInternalProjectTemplateFollowUpRolesKo,
  updateInternalProjectTemplateFollowUpRolesOk,
  updateInternalProjectTemplateGeneralFormRolesKo,
  updateInternalProjectTemplateGeneralFormRolesOk,
  updateInternalProjectTemplateKo,
  updateInternalProjectTemplateOk,
} from './actions';
import { InternalProjectTemplatesConst } from './action_types';
import {
  CreateInternalProjectTemplateFollowUp,
  DeleteInternalProjectTemplate,
  DeleteInternalProjectTemplateFollowUp,
  GetInternalProjectTemplateInfo,
  GetInternalProjectTemplatesInfo,
  PublishInternalProjectTemplate,
  UpdateInternalProjectTemplate,
  UpdateInternalProjectTemplateFollowUpRoles,
  UpdateInternalProjectTemplateGeneralFormRoles,
} from './definitions';

function* getInternalProjectTemplatesInfoFromSaga(): Generator<any, void, any> {
  try {
    const data = yield getInternalProjectTemplatesInfoOfArea();
    yield put(getInternalProjectTemplatesInfoOk(data));
  } catch (error) {
    yield put(getInternalProjectTemplatesInfoKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* deleteInternalProjectTemplateFromSaga(action: DeleteInternalProjectTemplate): Generator<any, void, any> {
  try {
    const data = yield deleteInternalProjectTemplateOfArea(action.internalProjectTemplateId);
    yield put(deleteInternalProjectTemplateOk(data));
  } catch (error) {
    yield put(deleteInternalProjectTemplateKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* getInternalProjectTemplateInfoFromSaga(action: GetInternalProjectTemplateInfo): Generator<any, void, any> {
  try {
    const data = yield getInternalProjectTemplateInfoOfArea(action.id);
    yield put(getInternalProjectTemplateInfoOk(data));
  } catch (error) {
    yield put(getInternalProjectTemplateInfoKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* updateInternalProjectTemplateFromSaga(action: UpdateInternalProjectTemplate): Generator<any, void, any> {
  try {
    yield saveInternalProjectTemplateOfArea(action.id, action.data);
    yield put(updateInternalProjectTemplateOk(action.data));
    yield put(showSnackbar(tKey('La plantilla se ha guardado correctamente'), 'success', undefined, 1500));
  } catch (error) {
    yield put(updateInternalProjectTemplateKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* updateInternalProjectTemplateGeneralFormRolesFromSaga(
  action: UpdateInternalProjectTemplateGeneralFormRoles,
): Generator<any, void, any> {
  try {
    yield saveInternalProjectTemplateGeneralFormRolesOfArea(
      action.internalProjectTemplateId,
      action.formId,
      action.roles,
    );
    yield put(updateInternalProjectTemplateGeneralFormRolesOk(action.formId, action.roles));
    yield put(
      showSnackbar(tKey('Los roles de la pantalla general se han guardado correctamente'), 'success', undefined, 1500),
    );
  } catch (error) {
    yield put(updateInternalProjectTemplateGeneralFormRolesKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* createInternalProjectTemplateFollowUpFromSaga(
  action: CreateInternalProjectTemplateFollowUp,
): Generator<any, void, any> {
  try {
    const data = yield createInternalProjectTemplateFollowUpFromApi(action.followUp);
    yield put(createInternalProjectTemplateFollowUpOk(data));
  } catch (error) {
    yield put(createInternalProjectTemplateFollowUpKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* updateInternalProjectTemplateFollowUpRolesFromSaga(
  action: UpdateInternalProjectTemplateFollowUpRoles,
): Generator<any, void, any> {
  try {
    yield saveInternalProjectTemplateFollowUpRolesOfArea(
      action.internalProjectTemplateId,
      action.followUpId,
      action.roles,
    );
    yield put(updateInternalProjectTemplateFollowUpRolesOk(action.followUpId, action.roles));
    yield put(
      showSnackbar(tKey('Los roles del seguimiento se han guardado correctamente'), 'success', undefined, 1500),
    );
  } catch (error) {
    yield put(updateInternalProjectTemplateFollowUpRolesKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* deleteInternalProjectTemplateFollowUpFromSaga(
  action: DeleteInternalProjectTemplateFollowUp,
): Generator<any, void, any> {
  try {
    const data = yield deleteInternalProjectTemplateFollowUpOfArea(action.internalProjectTemplateId, action.followUpId);
    yield put(deleteInternalProjectTemplateFollowUpOk(data));
  } catch (error) {
    yield put(deleteInternalProjectTemplateFollowUpKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

function* publishInternalProjectTemplateFromSaga(action: PublishInternalProjectTemplate): Generator<any, void, any> {
  try {
    yield publishInternalProjectTemplateOfArea(action.id);
    yield put(publishInternalProjectTemplateOk());
    yield put(showSnackbar(tKey('La plantilla se ha publicado correctamente'), 'success', undefined, 1500));
  } catch (error) {
    yield put(publishInternalProjectTemplateKo(errorMessage(error)));
    yield put(showSnackbar(errorMessage(error), 'error', undefined, 1500));
  }
}

export default [
  takeLatest<GetInternalProjectTemplatesInfo>(
    InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO,
    getInternalProjectTemplatesInfoFromSaga,
  ),
  takeLatest<DeleteInternalProjectTemplate>(
    InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE,
    deleteInternalProjectTemplateFromSaga,
  ),
  takeLatest<GetInternalProjectTemplateInfo>(
    InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO,
    getInternalProjectTemplateInfoFromSaga,
  ),
  takeLatest<UpdateInternalProjectTemplate>(
    InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE,
    updateInternalProjectTemplateFromSaga,
  ),
  takeLatest<UpdateInternalProjectTemplateGeneralFormRoles>(
    InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES,
    updateInternalProjectTemplateGeneralFormRolesFromSaga,
  ),
  takeLatest<CreateInternalProjectTemplateFollowUp>(
    InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP,
    createInternalProjectTemplateFollowUpFromSaga,
  ),
  takeLatest<UpdateInternalProjectTemplateFollowUpRoles>(
    InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES,
    updateInternalProjectTemplateFollowUpRolesFromSaga,
  ),
  takeLatest<DeleteInternalProjectTemplateFollowUp>(
    InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP,
    deleteInternalProjectTemplateFollowUpFromSaga,
  ),
  takeLatest<PublishInternalProjectTemplate>(
    InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE,
    publishInternalProjectTemplateFromSaga,
  ),
];
