import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteElementCenterCostApi, getCenterCostListByCodeApi } from '../../../api/CenterCost';
import TableLayout from '../../../components/Layout/TableLayout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tErrorKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import AccountingSubmenu from '../AccountingSubmenu';
import { CostCenter, CostCenterCode, sections } from './SwitchCostCenter';

interface CostCenterTable {
  section: CostCenterCode;
}

const CostCenterTable: React.FC<CostCenterTable> = ({ section }) => {
  const [modal, setModal] = React.useState(false);
  const [id, setId] = React.useState<number>(0);
  const [list, setList] = useState<CostCenter[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      const { data } = await getCenterCostListByCodeApi(section);
      setList(data);
      setLoading(false);
    })();
  }, [section]);

  const history = useHistory();

  const showColumn = () => {
    switch (section) {
      case 'delegacion':
        return {
          field: 'channel_code',
          title: tKey('Canal'),
        };
      case 'departamento':
        return {
          field: 'delegation_code',
          title: tKey('Delegación'),
        };
      case 'proyecto':
        return {
          field: 'area_code',
          title: tKey('Departamento'),
        };
      case 'canal': {
        return {
          field: '',
          title: '',
          hidden: true,
        };
      }
      case undefined:
        return {
          field: 'sasd',
          title: '',
          hidden: true,
        };
      default:
        return {
          field: 'asd',
          title: '',
          hidden: true,
        };
    }
  };

  const tableData: MaterialTableProps<CostCenter> = {
    title: sections.find(_section => _section.code === section)?.name || tKey('Centro de coste'),
    columns: [
      {
        field: 'code',
        title: tKey('Código'),
      },
      {
        field: 'name',
        title: tKey('Descripción'),
      },
      showColumn(),
    ],
    data: list,
    isLoading: loading,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
  };
  const onEditOne = (event: React.MouseEvent<Element, globalThis.MouseEvent>, rowData: any) => {
    history.push(`/contabilidad/centro-coste/${section}/editar/${rowData.id}`);
  };

  const idItem = (id: number) => {
    setId(id);
    setModal(true);
  };

  const goToNew = () => {
    history.push(`/contabilidad/centro-coste/${section}/crear/0`);
  };

  const onRemoveOne = async (id: number) => {
    setLoading(true);
    try {
      const { data } = await deleteElementCenterCostApi(id, section);
      setList(data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      showSnackbar(tErrorKey('No se ha podido eliminar'), 'error', undefined, 1000);
    }
  };

  return (
    <TableLayout leftSubmenu={<AccountingSubmenu selected={section} submenu={tKey('Centro de coste')} />}>
      <Fragment>
        <TableWithEdit
          tableData={tableData}
          key={JSON.stringify(tableData.data)}
          onEditOne={onEditOne}
          deleteFunc={(rowData: CostCenter) => idItem(rowData.id)}
          optionsNew={{
            newButtonText: tKey('Nuevo'),
            onNew: goToNew,
          }}
          permission={true}
        />
      </Fragment>
      <TransitionModal
        view={modal}
        handleClose={() => {
          setModal(false);
        }}
        bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        handleYes={() => {
          onRemoveOne(id);
          setModal(false);
        }}
      />
    </TableLayout>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});
export default connect(undefined, mapDispatchToProps)(CostCenterTable);
