import { ButtonProps } from '@material-ui/core';
import { Payment } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getAuthorizeInvoicesFromApi } from '../../../api/accounting';
import Layout from '../../../components/Layout/Layout';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { formatNumber } from '../../../helpers/formatNumber';
import { tFormKey, tKey } from '../../../helpers/translate';
import { downloadInvoiceDocument, payInvoices } from '../../../redux/accounting/actions';
import { InvoiceListItem } from '../../../redux/accounting/definitions';
import { selectLoading } from '../../../redux/accounting/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import EntityMenuRight from '../../Entities/EntityMenuRight/EntityMenuRight';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './invoicesTable.module.scss';

interface InvoicesPaymentProps extends RouteComponentProps<{ id: string }> {
  accountingLoading: boolean;
  can_pay: boolean;
  downloadInvoice: (file_id: number, filename: string) => void;
  payInvoices: () => void;
}

const InvoicesPayment: React.FC<InvoicesPaymentProps> = ({
  downloadInvoice,
  payInvoices,
  accountingLoading,
  can_pay,
}) => {
  const [invoiceList, setInvoiceList] = useState<InvoiceListItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    if (!accountingLoading) {
      (async () => {
        setLoading(true);
        setInvoiceList(await getAuthorizeInvoicesFromApi());
        setLoading(false);
      })();
    }
  }, [accountingLoading]);

  const tableData: MaterialTableProps<InvoiceListItem> = {
    title: tKey('FACTURAS'),
    columns: [
      {
        field: 'reference_number',
        title: tKey('Factura'),
        //@ts-ignore
        width: '120px',
      },
      {
        field: 'entity_name',
        title: tKey('Proveedor'),
        //@ts-ignore
        width: '200px',
      },
      {
        field: 'invoice_date',
        title: tFormKey('Fecha'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) => <div style={{ textAlign: 'center' }}>{rowData.invoice_date}</div>,
        //@ts-ignore
        width: '120px',
      },
      {
        field: 'due_date',
        title: tFormKey('Vencimiento'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) => <div style={{ textAlign: 'center' }}>{rowData.due_date}</div>,
        //@ts-ignore
        width: '120px',
      },
      {
        field: 'total_amount',
        title: tKey('Total'),
        type: 'numeric',
        render: (rowData: InvoiceListItem) => <div>{formatNumber({ value: rowData.total_amount }) + ' €'}</div>,
        //@ts-ignore
        width: '100px',
      },
      {
        field: 'center_cost',
        title: tKey('Centro de coste'),
        //@ts-ignore
        width: '200px',
      },
      {
        field: 'state',
        title: tKey('Estado'),
        //@ts-ignore
        width: '100px',
      },
      {
        field: 'file_id',
        title: tFormKey('Documento'),
        disableClick: true,
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) =>
          rowData.file_id ? (
            <button
              className={styles.downloadButton}
              onClick={e => {
                e.preventDefault();
                downloadInvoice(rowData.file_id || 0, rowData.filename || '');
              }}
            >
              <DescriptionIcon />
            </button>
          ) : (
            undefined
          ),
        //@ts-ignore
        width: '100px',
      },
    ],
    data: invoiceList,
    options: {
      tableLayout: 'auto',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
  };
  tableData.isLoading = loading || accountingLoading;

  const goToInvoiceEdit = (rowData: InvoiceListItem) => {
    const { id } = rowData;
    history.push({
      pathname: `/contabilidad/facturas/editar/${id}`,
      state: { id },
    });
  };

  const button: ButtonProps[] = [
    {
      children: tKey('Generar fichero'),
      onClick: payInvoices,
      startIcon: <Payment />,
      fullWidth: true,
      variant: 'contained',
      color: 'primary',
      hidden: !can_pay,
    },
  ];

  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="invoice" submenu={tKey('Pagos')} />}
      rightSubmenu={<EntityMenuRight actionButtons={button} />}
    >
      <TableWithEdit
        onRowClick={goToInvoiceEdit}
        tableData={{ ...tableData }}
        filtering
        key={JSON.stringify(tableData.data)}
        permission={true}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  accountingLoading: selectLoading(state),
  can_pay: selectUserCan(state)('allow_send_invoice_pending_to_sage'),
});

const mapDispatchToProps = (dispatch: any) => ({
  payInvoices: () => dispatch(payInvoices()),
  downloadInvoice: (file_id: number, filename: string) => dispatch(downloadInvoiceDocument(file_id, filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPayment);
