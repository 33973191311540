import React from 'react';
import { tKey } from '../../helpers/translate';
import dev from '../../images/dev.gif';
import styles from './developingFeedback.module.scss';

const Events: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={dev} alt="dev" />
      <span className={styles.message}>{tKey('Estamos trabajando en esta sección, disculpen las molestias.')}</span>
    </div>
  );
};

export default Events;
