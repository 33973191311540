import { Action } from 'redux';
import { APIError, APIValidationError } from '../../api/api';
import { Fields, GenericFromRequest } from '../../components/GenericForm/GenericFormRefactor';
import {
  DocumentationItem,
  IdCodeNamePair,
  IdNamePair,
  ResourceImageData,
  Uuid,
  UuidCodeNamePair,
  UuidNamePair,
} from '../common/definitions';
import { CustomFieldDocument } from '../kpis/definitions';
import { GenericData } from '../project/definitions';
import { InternalProjectsConst } from './action_types';
import { tKey } from '../../helpers/translate';

export enum InternalProjectSections {
  INFORMACION_GENERAL = 'pi_info_general',
  INFORMACION_GENERAL_ADICIONAL = 'pi_info_general_adicional',
  SEGUIMIENTO_INICIAL = 'pi_seguimiento_inicial',
  SEGUIMIENTO = 'pi_seguimiento',
  SEGUIMIENTO_ADICIONAL = 'pi_seguimiento_adicional',
  EVALUACION_SEGUIMIENTO = 'pi_evaluacion_seguimiento',
  EVALUACION_SEGUIMIENTO_ADICIONAL = 'pi_evaluacion_adicional',
  SEGUIMIENTO_FINAL = 'pi_seguimiento_final',
  SEGUIMIENTO_FINAL_JOVEN = 'pi_seguimiento_final_entidad',
  SEGUIMIENTO_FINAL_ENTIDAD = 'pi_seguimiento_final_joven',
}

export type CodeSectionInternalProject =
  | InternalProjectSections.INFORMACION_GENERAL
  | InternalProjectSections.INFORMACION_GENERAL_ADICIONAL
  | InternalProjectSections.SEGUIMIENTO_INICIAL
  | InternalProjectSections.SEGUIMIENTO
  | InternalProjectSections.SEGUIMIENTO_ADICIONAL
  | InternalProjectSections.EVALUACION_SEGUIMIENTO
  | InternalProjectSections.EVALUACION_SEGUIMIENTO_ADICIONAL
  | InternalProjectSections.SEGUIMIENTO_FINAL
  | InternalProjectSections.SEGUIMIENTO_FINAL_ENTIDAD
  | InternalProjectSections.SEGUIMIENTO_FINAL_JOVEN;

export type SectionInternalProject = { name: string; code: CodeSectionInternalProject };

const pi_info_general = {
  name: tKey('pi_info_general'),
  code: InternalProjectSections.INFORMACION_GENERAL,
} as SectionInternalProject;
const pi_info_general_adicional = {
  name: tKey('pi_info_general_adicional'),
  code: InternalProjectSections.INFORMACION_GENERAL_ADICIONAL,
} as SectionInternalProject;
const pi_seguimiento_inicial = {
  name: tKey('pi_seguimiento_inicial'),
  code: InternalProjectSections.SEGUIMIENTO_INICIAL,
} as SectionInternalProject;
const pi_seguimiento = { name: tKey('pi_seguimiento'), code: 'pi_seguimiento' } as SectionInternalProject;
const pi_seguimiento_adicional = {
  name: tKey('pi_seguimiento_adicional'),
  code: InternalProjectSections.SEGUIMIENTO_ADICIONAL,
} as SectionInternalProject;
const pi_evaluacion_seguimiento = {
  name: tKey('pi_evaluacion_seguimiento'),
  code: InternalProjectSections.EVALUACION_SEGUIMIENTO,
} as SectionInternalProject;
const pi_evaluacion_adicional = {
  name: tKey('pi_evaluacion_adicional'),
  code: InternalProjectSections.EVALUACION_SEGUIMIENTO_ADICIONAL,
} as SectionInternalProject;
const pi_seguimiento_final = {
  name: tKey('pi_seguimiento_final'),
  code: InternalProjectSections.SEGUIMIENTO_FINAL,
} as SectionInternalProject;
const pi_seguimiento_final_entidad = {
  name: tKey('pi_seguimiento_final_entidad'),
  code: InternalProjectSections.SEGUIMIENTO_FINAL_ENTIDAD,
} as SectionInternalProject;
const pi_seguimiento_final_joven = {
  name: tKey('pi_seguimiento_final_joven'),
  code: InternalProjectSections.SEGUIMIENTO_FINAL_JOVEN,
} as SectionInternalProject;

export const internalProjectsectionField: SectionInternalProject[] = [
  pi_info_general,
  pi_info_general_adicional,
  pi_seguimiento_inicial,
  pi_seguimiento,
  pi_seguimiento_adicional,
  pi_evaluacion_seguimiento,
  pi_evaluacion_adicional,
  pi_seguimiento_final,
  pi_seguimiento_final_entidad,
  pi_seguimiento_final_joven,
];

export const FormAssistantGeneralFormSections: SectionInternalProject[] = [pi_info_general, pi_info_general_adicional];

export const FormAssistantFollowUpSections: SectionInternalProject[] = [
  pi_seguimiento_inicial,
  pi_seguimiento,
  pi_seguimiento_adicional,
  pi_evaluacion_seguimiento,
  pi_evaluacion_adicional,
  pi_seguimiento_final,
  pi_seguimiento_final_entidad,
  pi_seguimiento_final_joven,
];

export type InternalProjectsData = {
  id: number;
  name: string;
  internal_project_template_id: number;
  template_name: string;
  subarea_code: string;
  subarea: string;
  created_at: string;
  created: string;
  updated_at: string;
  entity_id: number | null;
  academy_id: number | null;
  academy_name: string | null;
  owner_name: string;
  entity_name: string | null;
  academyOrEntityName: string | null;
  images: ResourceImageData[];
  subarea_internal_project_type_id: string;
  subarea_internal_project_type: string;
  subarea_internal_project_type_name: string;
  has_daily_follow_up: boolean;
};

export type ParticipantesFollowUpList = {
  follow_up_final_id: number;
  name: string;
  surname: string;
  surname2: string;
  follow_up_six_months_id: number;
  follow_up_six_months_done: boolean;
  follow_up_final_done: boolean;
  edit_six_months_enabled: boolean;
  edit_final_enabled: boolean;
  internal_project_enrollment_id: number;
};

export interface FollowUpSixMonthsFormMetadata {
  id: number;
  value: string;
  order: number;
}

export interface FollowUpSixMonthsForm {
  id: number;
  name: string;
  surname: string;
  surname2: string | null;
  enrolled_plan_id: number | null;
  has_gotten_zing_grant: boolean;
  training_brings_closer_to_vocation: boolean;
  training_type_id: number | null;
  training: string | null;
  is_vocational_job: boolean;
  job: string | null;
  reason_no_study_or_job_id: number | null;
  another_reason_no_study_or_job: number | null;
  internal_project_enrollment_id: number;
  enrolled_plans: FollowUpSixMonthsFormMetadata[];
  training_types: FollowUpSixMonthsFormMetadata[];
  reasons_no_study_or_job: FollowUpSixMonthsFormMetadata[];
  follow_up_six_months_done: boolean;
}

type spanishLevelOptions = {
  id: 'ALTO' | 'MEDIO' | 'BAJO';
  value: 'Alto' | 'Medio' | 'Bajo';
};

type enrolledPlansOptions = {
  id: number;
  value: 'Estudiar' | 'Trabajar' | 'Estudiar y trabajar' | 'Ni trabajar, ni estudiar';
  order: number;
};

type trainingTypesOptions = {
  id: number;
  value:
    | 'PFI/FP Básica'
    | 'GES'
    | 'CFGM'
    | 'CFGS'
    | 'BAT'
    | 'UNIV'
    | 'CAM'
    | 'CAS'
    | 'Otros/No reglados'
    | 'No lo tiene claro';
  order: number;
};
export type ParticipantesFinalFollowUpForm = {
  id: number;
  name: string;
  surname: string;
  surname2: string;
  birthdate: string;
  gender: string;
  spanish_level: string;
  spanish_levels: spanishLevelOptions[];
  awareness: number;
  auto_knowledge: number;
  confidence: number;
  motivation: number;
  follow_up_final_done: number;
  enrolled_plan_id: number | null;
  training_type_id1: number | null;
  training_type_id2: number | null;
  training_type_option1: string | null;
  training_type_option2: string | null;
  internal_project_enrollment_id: number;
  enrolled_plans: enrolledPlansOptions[];
  training_types: trainingTypesOptions[];
  created_at: string | null;
  updated_at: string | null;
};
export type InternalProjectsTemplate = {
  id: number;
  name: string;
  subarea_id: number;
};

export interface InternalProjectCreate {
  name: InternalProjectsData['name'];
  template_id: number;
  subarea_internal_project_type_id: string;
  entity_id?: number;
  academy_id?: number;
  select_id: number;
}

export interface Member {
  id: number;
  user_id: number;
  name: string;
  email: string;
  origin: string;
  phone: string | null;
  is_nouscims: boolean;
}

export interface ProjectAllowedUser {
  id: number;
  name: string;
  email: string;
  origin: string;
  is_nouscims: boolean;
}

export interface InformationForm {
  id: number;
  name: string;
  subarea_name: string;
  template_name: string;
  entity_name?: string;
  academy_name?: string;
  owner_id: number;
  start_date: string;
  end_date: string;
  owner_name: string;
  has_daily_follow_up: boolean;
  has_enrollments: boolean;
  has_survey: boolean;
}

export interface GeneralDataForm {
  id: number;
  academy_name: string;
  academy_nature: string;
  municipality: string;
  line_number: string;
  entity_name: string;
  entity_type: string;
  cif: string;
  responsible: string;
  responsible_dni: string;
  director: string;
  study_chief: string;
  secretary: string;
  province: string;
  email: string;
  web: string;
  xxss: string;
  responsible_name: string;
  document_number: string;
  responsible_phone: string;
  documentations_agreement: DocumentationItem[];
  documentations: DocumentationItem[];
  pi_info_general: {
    fields: Fields;
    participante: GenericData;
  };
  pi_info_general_adicional: {
    fields: Fields;
    participante: GenericData;
  };
}

export interface Follows {
  id: number;
  section: string;
  name: string;
  internal_project_id: number;
  follow_date: string | null;
  completed: boolean;
  completed_label: string;
  template_follow_id: number;
}
export interface DailyFollowsTable {
  id: number;
  follow_date: string;
  modality_name: string;
  tipology_name: string;
  profesionals_involveds_names: string;
  owner_name: string;
  note: string | null;
  course: string | null;
}

export interface DailyFollowsForms {
  follow_date: string;
  id: number;
  internal_project_modalities_id: number;
  internal_project_modalities_group_id: string;
  internal_project_modalities_group_code: string;
  professionals_involveds: ProfessionalInvolved[];
  note: string;
  internal_project_typologies_id: number;
  internal_project_id: number;
  participants: number;
  owner_id: number;
  created_at: string;
  updated_at: string;
  documentations: DocumentationItem[];
}

export interface InternalProjectEnrollment {
  id: number;
  user_id: number;
  name: string;
  surnames: string;
  document_type: string;
  document_number: string;
  keep_going: number;
  link_provided: number;
  photos_videos_accepted: number;
  whatsapp_group_accepted: number;
  assisted_field1: number;
  assisted_field2: number;
  assisted_field3: number;
  assisted_field4: number;
  assisted_field5: number;
  assisted_field6: number;
  finishes_workshop: number;
  reason_unfinished_id: number | null;
  enrolled_plan_id: number | null;
  training_type_id1: number | null;
  training_type_option1: string | null;
  training_type_id2: number | null;
  training_type_option2: string | null;
  deserves_grant: number;
  is_top: number;
  reason_grant_approved_rejected: string | null;
  isEditMode: boolean;
  has_evaluation: boolean;
}

export interface InternalProjectEnrollmentMetadata {
  unfinished_reasons: IdNamePair[];
  enrolled_plan: IdNamePair[];
  training_types: IdNamePair[];
}

export interface NewEnrollment {
  name: string;
  surname: string;
  surname2: string;
  document_type: string;
  document_number: string;
  email: string;
  user_id: number | null;
  internal_project_id: number;
}

export interface BaseCampForm {
  project_key: string;
  pi_seguimiento_inicial: {
    fields: Fields;
    participante: GenericData;
  };
}

export interface StudentEvaluationInfo {
  fields: Fields;
  participante: GenericData;
}

export interface StudentEvaluationForm {
  internal_project_id: number;
  enrollments: IdNamePair[];
  pi_seguimiento_final_joven: {
    fields: Fields;
    participante: GenericData;
  };
}

export interface StudentEvaluationFieldsData {
  enrollment_id: number;
  internal_project_id: number;
  fieldsData: any;
}

export interface Modality extends UuidNamePair {
  group_id: Uuid;
}

export interface ProfessionalInvolved extends IdCodeNamePair {
  subarea_internal_project_type_id: Uuid;
}

export interface MetadataDailyFollow {
  tipologies: IdCodeNamePair[];
  professionals_involved: ProfessionalInvolved[];
  modalities: Modality[];
  modalities_groups: UuidCodeNamePair[];
}

export type DailyFollows = DailyFollowsTable & DailyFollowsForms;

export type InternalProjectForms = Partial<
  InformationForm | InternalProjectsData | GeneralDataForm | DailyFollows | Follows
>;

export interface InternalProjectSettings {
  id: number;
  course_end_date: string;
}

export interface InternalProjectSubareaType {
  id: string;
  type: number;
  name: string;
  subarea_id: number;
}

export const initialState = {
  loading: false,
  loadingAlt: false,
  loadingImages: false,
  error: null as string | null,
  latestInternalProjectSubArea: '' as string,
  internalProjectSubAreas: [] as IdCodeNamePair[],
  internalProjects: [] as InternalProjectsData[],
  internalProjectData: {} as InternalProject,
  latestInternalProjectSection: [] as IdNamePair[],
  templates: [] as InternalProjectsTemplate[],
  validationError: null as APIValidationError | null,
  projectAllowedUsers: [] as ProjectAllowedUser[],
  documentations_agreement: [] as DocumentationItem[],
  documentations_daily_follow: [] as DocumentationItem[],
  documentations: [] as DocumentationItem[],
  dailyFollows: [] as DailyFollowsTable[],
  dailyFollow: {} as DailyFollowsForms,
  follows: [] as Follows[],
  metadata: {
    tipologies: [],
    professionals_involved: [],
    modalities: [],
    modalities_groups: [],
  } as MetadataDailyFollow,
  fields: {} as Fields,
  participante: {} as GenericData,
  participantesFollowUpList: [] as ParticipantesFollowUpList[],
  followUpSixMonthsData: {} as FollowUpSixMonthsForm,
  internalProjectSettings: {} as InternalProjectSettings,
  baseCampData: {} as BaseCampForm,
  studentEvaluationData: {} as StudentEvaluationForm,
  studentEvaluationInfo: {} as StudentEvaluationInfo,
  enrollments: [] as InternalProjectEnrollment[],
  participanteFinalForm: {} as ParticipantesFinalFollowUpForm,
  enrollmentMetadata: {
    unfinished_reasons: [],
    training_types: [],
    enrolled_plan: [],
  } as InternalProjectEnrollmentMetadata,
  internalProjectSubareaTypes: [] as InternalProjectSubareaType[],
};

export type InternalProject = InformationForm &
  InternalProjectsData & { members: Member[] } & GeneralDataForm &
  DailyFollows;

export interface InternalProjectFollowUpAlert {
  id: number;
  user_id: number;
  start_date: string;
  expiration: number;
}

export interface GetInternalProjectSubareas extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS;
}

export interface GetInternalProjectSubareasOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS_OK;
  internalProjectSubAreas: IdCodeNamePair[];
}

export interface GetInternalProjectSubareasKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS_KO;
  error: string;
}

export interface SetLatestArea extends Action {
  type: InternalProjectsConst.SET_LATEST_SUBAREA;
  setterData: string;
}

export interface GetInternalProjects extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS;
}

export interface GetInternalProjectsOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_OK;
  internalProject: InternalProjectsData[];
}

export interface GetInternalProjectsKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_KO;
  error: string;
}

export interface SetLatestInternalProjectSection extends Action {
  type: InternalProjectsConst.SET_LATEST_INTERNAL_PROJECT_SECTION;
  internal_project_id: number;
  section: string;
}

export interface GetInternalProjectsTemplateList extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE;
  project_type_id: string;
}

export interface GetInternalProjectsTemplateListOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE_OK;
  templates: InternalProjectsTemplate[];
}

export interface GetInternalProjectsTemplateListKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE_KO;
  error: string;
}

export interface SetInternalProjectData extends Action {
  type: InternalProjectsConst.SET_INTERNAL_PROJECT;
  key: keyof InternalProjectsData;
  value: InternalProjectsData[keyof InternalProjectsData];
}

export interface CreateInternalProjects extends Action {
  type: InternalProjectsConst.CREATE_INTERNAL_PROJECTS;
  internalProject: InternalProjectCreate;
}

export interface CreateInternalProjectsOk extends Action {
  type: InternalProjectsConst.CREATE_INTERNAL_PROJECTS_OK;
  internalProject: InternalProject;
}

export interface CreateInternalProjectsKo extends Action {
  type: InternalProjectsConst.CREATE_INTERNAL_PROJECTS_KO;
  error: string;
}

export interface CreateInternalProjectValidationErrorsAction extends Action {
  type: InternalProjectsConst.CREATE_INTERNAL_PROJECT_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

export interface UpdateInternalProjects extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT;
  internalProject: InternalProjectForms;
  id: number;
}

export interface UpdateInternalProjectsOk extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_OK;
  internalProject: InternalProject;
}

export interface UpdateInternalProjectsKo extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_KO;
  error: string;
}
export interface GetInternalProject extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT;
  id: number;
}

export interface GetInternalProjectOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_OK;
  internalProjectData: InternalProject;
}

export interface GetInternalProjectKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_KO;
  error: string;
}

export interface AddMembers extends Action {
  type: InternalProjectsConst.ADD_MEMBERS;
  user_id: number;
  internal_project_id: number;
}

export interface AddMembersOk extends Action {
  type: InternalProjectsConst.ADD_MEMBERS_OK;
  member: Member;
}

export interface AddMembersKo extends Action {
  type: InternalProjectsConst.ADD_MEMBERS_KO;
  error: string;
}

export interface GetUsersCanBeMembers extends Action {
  type: InternalProjectsConst.GET_PROJECT_ALLOWED_USERS;
  internal_project_id: number;
}

export interface GetUsersCanBeMembersOk extends Action {
  type: InternalProjectsConst.GET_PROJECT_ALLOWED_USERS_OK;
  projectAllowedUsers: ProjectAllowedUser[];
}

export interface DeleteMembers extends Action {
  type: InternalProjectsConst.DELETE_MEMBERS;
  user_id: number;
  internal_project_id: number;
}

export interface DeleteMembersOk extends Action {
  type: InternalProjectsConst.DELETE_MEMBERS_OK;
  user_id: number;
}

export interface DeleteMembersKo extends Action {
  type: InternalProjectsConst.DELETE_MEMBERS_KO;
  error: string;
}

export interface UploadDocumentInternalProjectAction extends Action {
  type: InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT;
  data: File;
  internalProjectId: number;
}
export interface UploadDocumentInternalProjectOkAction extends Action {
  type: InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT_OK;
  documents: DocumentationItem[];
}
export interface UploadDocumentInternalProjectKoAction extends Action {
  type: InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT_KO;
  error: string;
}

export interface DownloadDocumentInternalProjecttAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT;
  file_id: number;
  name: string;
  internal_project_id: number;
  get: boolean;
}
export interface DownloadDocumentOkInternalProjecttAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT_OK;
  data: Blob;
  get: boolean;
  name: string;
}
export interface DownloadDocumentKoInternalProjecttAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT_KO;
  error: string;
}

export interface RemoveDocumentInternalProjectAction extends Action {
  type: InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT;
  file_id: number;
  internal_project_id: number;
}
export interface RemoveDocumentOkInternalProjectAction extends Action {
  type: InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentKoInternalProjectAction extends Action {
  type: InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT_KO;
  error: string;
}

export interface GetDocumentstInternalProjectAction extends Action {
  type: InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT;
  internal_project_id: number;
}
export interface GetDocumentstOkInternalProjectAction extends Action {
  type: InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT_OK;
  documents: DocumentationItem[];
}
export interface GetDocumentstKoInternalProjectAction extends Action {
  type: InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT_KO;
  error: string;
}

export interface UploadDocumentstInternalProjectAction extends Action {
  type: InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT;
  internal_project_id: number;
  description: string;
  file: File;
  code: string;
}
export interface UploadDocumentstOkInternalProjectAction extends Action {
  type: InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT_OK;
  documents: DocumentationItem[];
}
export interface UploadDocumentstOkFollowUpAction extends Action {
  type: InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT_OK;
  documents: DocumentationItem[];
}
export interface UploadDocumentstKoInternalProjectAction extends Action {
  type: InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT_KO;
  error: string;
}

export interface DeleteDocumentstInternalProjectAction extends Action {
  type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT;
  internal_project_id: number;
  file_id: number;
  follow_up_id?: number;
  inputName?: string;
}
export interface DeleteDocumentstOkInternalProjectAction extends Action {
  type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_OK;
  documents: DocumentationItem[];
}

export interface DeleteDocumentstOkFollowUp extends Action {
  type: InternalProjectsConst.DELETE_DOCUMENTS_FOLLOW_UP_OK;
  internal_project_id: number;
  file_id: number;
  follow_up_id?: number;
  inputName?: string;
}
export interface DeleteDocumentstKoInternalProjectAction extends Action {
  type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_KO;
  error: string;
}

export interface DownloadDocumentstInternalProjectAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT;
  file_id: number;
  name: string;
  internal_project_id: number;
  get: boolean;
  follow_up_id?: number;
}
export interface DownloadDocumentstOkInternalProjectAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_OK;
  data: Blob;
  get: boolean;
  name: string;
}
export interface DownloadDocumentstKoInternalProjectAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_KO;
  error: string;
}

export interface GetInternalProjectDailyFollows extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS;
  internal_project_id: number;
}

export interface GetInternalProjectDailyFollowsOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS_OK;
  dailyFollows: DailyFollowsTable[];
}

export interface GetInternalProjectDailyFollowsKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS_KO;
  error: string;
}

export interface GetInternalProjectsExcel extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL;
}

export interface GetInternalProjectsExcelOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL_OK;
  data: Blob;
}

export interface GetInternalProjectsExcelKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL_KO;
  error: string;
}
export interface GetInternalProjectDailyFollow extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW;
  dailyFollowsId: number;
}

export interface GetInternalProjectDailyFollowOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_OK;
  dailyFollow: DailyFollowsForms;
}

export interface GetInternalProjectDailyFollowKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_KO;
  error: string;
}

export interface AddDocumentInternalProjectDailyFollowAction extends Action {
  type: InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW;
  data: File;
  dailyFollowsId: number;
  description: string;
  dailyFollow?: DailyFollows;
}
export interface AddDocumentInternalProjectDailyFollowOkAction extends Action {
  type: InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK;
  documents: DocumentationItem[];
}
export interface AddDocumentInternalProjectDailyFollowKoAction extends Action {
  type: InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO;
  error: string;
}

export interface DownloadDocumentInternalDailyFollowProjecttAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW;
  file_id: number;
  name: string;
  dailyFollowsId: number;
  get: boolean;
}
export interface DownloadDocumentInternalProjectDailyFollowOkAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK;
  data: Blob;
  get: boolean;
  name: string;
}
export interface DownloadDocumentInternalProjectDailyFollowKoAction extends Action {
  type: InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO;
  error: string;
}

export interface RemoveDocumentInternalProjectDailyFollowAction extends Action {
  type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW;
  file_id: number;
  dailyFollowsId: number;
}
export interface RemoveDocumentInternalProjectDailyFollowOkAction extends Action {
  type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentInternalProjectDailyFollowKoAction extends Action {
  type: InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO;
  error: string;
}

export interface GetMetadata extends Action {
  type: InternalProjectsConst.GET_METADATA;
  subarea_internal_project_type_id: string | null;
}

export interface GetMetadataOk extends Action {
  type: InternalProjectsConst.GET_METADATA_OK;
  metadata: MetadataDailyFollow;
}

export interface GetMetadataKo extends Action {
  type: InternalProjectsConst.GET_METADATA_KO;
  error: string;
}

export interface StoreDailyFollow extends Action {
  type: InternalProjectsConst.STORE_DAILY_FOLLOW;
  dailyFollow: DailyFollows;
}

export interface StoreDailyFollowOk extends Action {
  type: InternalProjectsConst.STORE_DAILY_FOLLOW_OK;
  dailyFollowsId: number;
  dailyFollows: DailyFollowsTable[];
}

export interface StoreDailyFollowKo extends Action {
  type: InternalProjectsConst.STORE_DAILY_FOLLOW_KO;
  error: string;
}

export interface ClearDailyFollow extends Action {
  type: InternalProjectsConst.CLEAR_DAILY_FOLLOW;
}

export interface DeleteDailyFollow extends Action {
  type: InternalProjectsConst.DELETE_DAILY_FOLLOW;
  dailyFollowId: number;
}

export interface DeleteDailyFollowOk extends Action {
  type: InternalProjectsConst.DELETE_DAILY_FOLLOW_OK;
  dailyFollowId: number;
}

export interface DeleteDailyFollowKo extends Action {
  type: InternalProjectsConst.DELETE_DAILY_FOLLOW_KO;
  error: string;
}

export interface DeleteInternalProject extends Action {
  type: InternalProjectsConst.DELETE_INTERNAL_PROJECT;
  projectId: number;
}

export interface DeleteInternalProjectOk extends Action {
  type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_OK;
  internalProject: InternalProjectsData[];
}

export interface DeleteInternalProjectKo extends Action {
  type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_KO;
  error: string;
}

export interface GetInternalProjectDailyFollowsExcel extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL;
  internalProjectId: number;
}

export interface GetInternalProjectDailyFollowsExcelOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_OK;
  data: Blob;
}

export interface GetInternalProjectDailyFollowsExcelKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_KO;
  error: string;
}

export interface GetInternalProjectsAcademy extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY;
}

export interface GetInternalProjectsAcademyOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY_OK;
  internalProject: InternalProjectsData[];
}

export interface GetInternalProjectsAcademyKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY_KO;
  error: string;
}

export interface GetInternalProjectAcademy extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY;
  id: number;
}

export interface GetInternalProjectAcademyOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY_OK;
  internalProjectData: InternalProject;
}

export interface GetInternalProjectAcademyKo extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY_KO;
  error: string;
}

export interface GetInternalProjectFollows extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_FOLLOWS;
  internalProjectId: number;
}

export interface GetInternalProjectFollowsOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_FOLLOWS_OK;
  follows: Follows[];
}

export interface UpdateFollowUpDate extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOW_UP_DATE;
  internalProjectFollowId: number;
  follow_date: string;
}

export interface UpdateFollowUpDateOk extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOW_UP_DATE_OK;
  internalProjectFollowId: number;
  follow_date: string;
}

export interface UpdateFollowUpDateKo extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOW_UP_DATE_KO;
  error: string;
}

export interface GetSectionTemplate extends Action {
  type: InternalProjectsConst.GET_SECTION_TEMPLATE;
  internalProjectId: number;
  section: string;
  followUpId: number;
}

export interface GetSectionTemplateOk extends Action {
  type: InternalProjectsConst.GET_SECTION_TEMPLATE_OK;
  fields: Fields;
  participante: GenericData;
}

export interface GetSectionTemplateKo extends Action {
  type: InternalProjectsConst.GET_SECTION_TEMPLATE_KO;
  error: string;
}

export interface UpdateFollowUp extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOW_UP;
  data: GenericFromRequest;
}

export interface GetParticipantesFollowUpList extends Action {
  type: InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST;
  internalProjectId: number;
}
export interface GetParticipantesFollowUpListOk extends Action {
  type: InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST_OK;
  participantesFollowUpList: ParticipantesFollowUpList[];
}
export interface GetParticipantesFollowUpListKo extends Action {
  type: InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST_KO;
  error: string;
}

export interface GetFollowUpSixMonthsForm extends Action {
  type: InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM;
  enrollmentId: number;
}

export interface GetFollowUpSixMonthsFormOk extends Action {
  type: InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM_OK;
  followUpSixMonthsData: FollowUpSixMonthsForm;
}

export interface GetFollowUpSixMonthsFormKo extends Action {
  type: InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM_KO;
  error: string;
}

export interface UpdateFollowUpSixMonthsForm extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM;
  enrollmentId: number;
  followUpSixMonthsData: FollowUpSixMonthsForm;
}

export interface UpdateFollowUpSixMonthsFormOk extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM_OK;
  followUpSixMonthsData: FollowUpSixMonthsForm;
}

export interface UpdateFollowUpSixMonthsFormKo extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM_KO;
  error: string;
}

export interface UpdateFollowUpOk extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOW_UP_OK;
  fields: Fields;
  participante: GenericData;
}

export interface UpdateFollowUpKo extends Action {
  type: InternalProjectsConst.UPDATE_FOLLOW_UP_KO;
  error: string;
}

export interface SendFollowUp extends Action {
  type: InternalProjectsConst.SEND_FOLLOW_UP;
  data: GenericFromRequest;
}

export interface SendFollowUpOk extends Action {
  type: InternalProjectsConst.SEND_FOLLOW_UP_OK;
  follow_up_id: number;
}

export interface SendFollowUpKo extends Action {
  type: InternalProjectsConst.SEND_FOLLOW_UP_KO;
  error: string;
}

export interface AddDocumentFollowUp extends Action {
  type: InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP;
  internal_project_id: number;
  follow_up_id: number;
  file: File;
  description: string;
  custom_field_name: string;
  section: string;
}

export interface AddDocumentFollowUpOk extends Action {
  type: InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP_OK;
  documents: CustomFieldDocument[];
  custom_field_name: string;
}

export interface AddDocumentFollowUpKo extends Action {
  type: InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP_KO;
  error: string;
}

export interface AddNewGenericTableRowAction extends Action {
  type: InternalProjectsConst.ADD_NEW_REGISTER;
  setterData: any;
}

export interface SetRegisterTableAction extends Action {
  type: InternalProjectsConst.SET_REGISTER;
  setterData: any;
}

export interface GetInternalProjectSettings extends Action {
  type: InternalProjectsConst.GET_SETTINGS;
}
export interface GetInternalProjectSettingsOk extends Action {
  type: InternalProjectsConst.GET_SETTINGS_OK;
  internalProjectSettings: InternalProjectSettings;
}
export interface GetInternalProjectSettingsKo extends Action {
  type: InternalProjectsConst.GET_SETTINGS_KO;
  error: string;
}
export interface UpdateInternalProjectSettings extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS;
  settings: InternalProjectSettings;
}

export interface UpdateInternalProjectSettingsOk extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS_OK;
  settings: InternalProjectSettings;
}

export interface UpdateInternalProjectSettingsKo extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS_KO;
  error: string;
}

export interface GetParticipantesFinalFollowUpForm extends Action {
  type: InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM;
  internal_project_enrollment_id: number;
}

export interface GetParticipantesFinalFollowUpFormOk extends Action {
  type: InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK;
  participanteFinalForm: ParticipantesFinalFollowUpForm;
}

export interface GetParticipantesFinalFollowUpFormKo extends Action {
  type: InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO;
  error: string;
}
export interface DeleteRowParticipante extends Action {
  type: InternalProjectsConst.DELETE_ROW_PARTICIPANTE;
  idRow: number | null;
  section: string;
  idGenerated: number | null;
}

export interface DeleteRowParticipanteOk extends Action {
  type: InternalProjectsConst.DELETE_ROW_PARTICIPANTE_OK;
  idRow: number | null;
  idGenerated: number | null;
}

export interface GetProjectBaseCampOk extends Action {
  type: InternalProjectsConst.GET_BASE_CAMP_OK;
  baseCamp: BaseCampForm;
}

export interface DeleteRowParticipanteKo extends Action {
  type: InternalProjectsConst.DELETE_ROW_PARTICIPANTE_KO;
  error: string;
}

export interface GetProjectBaseCamp extends Action {
  type: InternalProjectsConst.GET_BASE_CAMP;
  project_id: number;
  follow_up_id: number;
}

export interface UpdateEnrollment extends Action {
  type: InternalProjectsConst.UPDATE_ENROLLMENT;
  enrollment: InternalProjectEnrollment;
}

export interface UpdateEnrollmentOk extends Action {
  type: InternalProjectsConst.UPDATE_ENROLLMENT_OK;
  enrollment: InternalProjectEnrollment;
}
export interface UpdateParticipantesFinalFollowUpForm extends Action {
  type: InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM;
  participanteFinalFormData: Partial<ParticipantesFinalFollowUpForm>;
  internal_project_enrollment_id: number;
}

export interface UpdateParticipantesFinalFollowUpFormOk extends Action {
  type: InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK;
  participanteFinalFormData: ParticipantesFinalFollowUpForm;
  internal_project_enrollment_id: number;
}

export interface UpdateParticipantesFinalFollowUpFormKo extends Action {
  type: InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO;
  error: string;
}
export interface DeleteInternalProjectEnrollment extends Action {
  type: InternalProjectsConst.DELETE_ENROLLMENT;
  enrollment_id: number;
}

export interface DeleteInternalProjectEnrollmentOk extends Action {
  type: InternalProjectsConst.DELETE_ENROLLMENT_OK;
  enrollment_id: number;
}

export interface SetNewInternalProjectEnrollment extends Action {
  type: InternalProjectsConst.SET_NEW_ENROLLMENT;
  new_enrollment: InternalProjectEnrollment;
}

export interface GetInternalProjectEnrollment extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_ENROLLMENTS;
  internal_project_id: number;
}

export interface GetInternalProjectEnrollmentOk extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_ENROLLMENTS_OK;
  enrollments: InternalProjectEnrollment[];
  metadata: InternalProjectEnrollmentMetadata;
}

export interface GetIpStudentEvaluation extends Action {
  type: InternalProjectsConst.GET_IP_STUDENT_EVALUATION;
  project_key: string;
}

export interface GetIpStudentEvaluationOk extends Action {
  type: InternalProjectsConst.GET_IP_STUDENT_EVALUATION_OK;
  student_evaluation: StudentEvaluationForm;
}

export interface SendIpStudentEvaluation extends Action {
  type: InternalProjectsConst.SEND_IP_STUDENT_EVALUATION;
  data: StudentEvaluationFieldsData;
}
export interface GenericKo extends Action {
  type: InternalProjectsConst.GENERIC_KO;
  apiError: APIError;
}

export interface SetEnrollmentTextData extends Action {
  type: InternalProjectsConst.SET_ENROLLMENT_TEXT_DATA;
  enrollment_id: number;
  property: string;
  value: string | null;
}

export interface SetEnrollmentNumericData extends Action {
  type: InternalProjectsConst.SET_ENROLLMENT_NUMERIC_DATA;
  enrollment_id: number;
  property: string;
  value: number | null;
}

export interface SetEnrollmentDropDownData extends Action {
  type: InternalProjectsConst.SET_ENROLLMENT_DROP_DOWN_DATA;
  enrollment_id: number;
  property: string;
  value: number;
}

export interface UpdateInternalProjectEnrollments extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS;
  internal_project_id: number;
  enrollments: InternalProjectEnrollment[];
}

export interface UpdateInternalProjectEnrollmentsOk extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS_OK;
  enrollments: InternalProjectEnrollment[];
}

export interface UpdateInternalProjectEnrollmentsKo extends Action {
  type: InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS_KO;
  error: string;
}

export interface GetStudentEvaluationInfo extends Action {
  type: InternalProjectsConst.GET_STUDENT_EVALUATION_INFO;
  project_id: number;
  follow_up_id: number;
}

export interface GetStudentEvaluationInfoOk extends Action {
  type: InternalProjectsConst.GET_STUDENT_EVALUATION_INFO_OK;
  studentEvaluationInfo: StudentEvaluationInfo;
}

export interface GetInternalProjectImagesAction extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_IMAGES;
  id: number;
}

export interface GetInternalProjectImagesOkAction extends Action {
  type: InternalProjectsConst.GET_INTERNAL_PROJECT_IMAGES_OK;
  resourceImages: ResourceImageData[];
}

export interface AddInternalProjectImageAction extends Action {
  type: InternalProjectsConst.ADD_INTERNAL_PROJECT_IMAGE;
  id: number;
  name: string;
  description: string;
  image: string;
  userId: number;
}

export interface AddInternalProjectImageOkAction extends Action {
  type: InternalProjectsConst.ADD_INTERNAL_PROJECT_IMAGE_OK;
  resourceImage: ResourceImageData;
}

export interface EditInternalProjectImageAction extends Action {
  type: InternalProjectsConst.EDIT_INTERNAL_PROJECT_IMAGE;
  id: number;
  resourceImage: ResourceImageData;
  name: string;
  description: string;
  newImage: string;
  userId: number;
}

export interface EditInternalProjectImageOkAction extends Action {
  type: InternalProjectsConst.EDIT_INTERNAL_PROJECT_IMAGE_OK;
  resourceImage: ResourceImageData;
}

export interface DeleteInternalProjectImageAction extends Action {
  type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_IMAGE;
  resourceImage: ResourceImageData;
}

export interface DeleteInternalProjectImageOkAction extends Action {
  type: InternalProjectsConst.DELETE_INTERNAL_PROJECT_IMAGE_OK;
  projectImageId: number;
}

export interface GetProjectTypeBySubareaCodeAction extends Action {
  type: InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE;
  subAreaCode: string;
}

export interface GetProjectTypeBySubareaCodeOkAction extends Action {
  type: InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE_OK;
  internalProjectSubareaTypes: InternalProjectSubareaType[];
}

export interface GetProjectTypeBySubareaCodeKoAction extends Action {
  type: InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE_KO;
  error: string;
}

export interface ApplyConfigTemplateAction extends Action {
  type: InternalProjectsConst.APPLY_CONFIG_TEMPLATE;
  internalProjectId: number;
}

export interface ApplyConfigTemplateActionFinish extends Action {
  type: InternalProjectsConst.APPLY_CONFIG_TEMPLATE_OK;
}

export type InternalProjectDataAction =
  | GetInternalProjectSubareas
  | GetInternalProjectSubareasOk
  | GetInternalProjectSubareasKo
  | SetLatestArea
  | GetInternalProjects
  | GetInternalProjectsOk
  | GetInternalProjectsKo
  | GetInternalProjectsTemplateList
  | GetInternalProjectsTemplateListOk
  | GetInternalProjectsTemplateListKo
  | SetInternalProjectData
  | CreateInternalProjects
  | CreateInternalProjectsOk
  | CreateInternalProjectsKo
  | CreateInternalProjectValidationErrorsAction
  | UpdateInternalProjects
  | UpdateInternalProjectsOk
  | UpdateInternalProjectsKo
  | GetInternalProject
  | GetInternalProjectOk
  | GetInternalProjectKo
  | SetLatestInternalProjectSection
  | AddMembers
  | AddMembersOk
  | AddMembersKo
  | GetUsersCanBeMembers
  | GetUsersCanBeMembersOk
  | DeleteMembers
  | DeleteMembersOk
  | DeleteMembersKo
  | UploadDocumentInternalProjectAction
  | UploadDocumentInternalProjectOkAction
  | UploadDocumentInternalProjectKoAction
  | DownloadDocumentInternalProjecttAction
  | DownloadDocumentOkInternalProjecttAction
  | DownloadDocumentKoInternalProjecttAction
  | RemoveDocumentInternalProjectAction
  | RemoveDocumentOkInternalProjectAction
  | RemoveDocumentKoInternalProjectAction
  | GetDocumentstInternalProjectAction
  | GetDocumentstOkInternalProjectAction
  | GetDocumentstKoInternalProjectAction
  | UploadDocumentstInternalProjectAction
  | UploadDocumentstOkInternalProjectAction
  | UploadDocumentstKoInternalProjectAction
  | DeleteDocumentstInternalProjectAction
  | DeleteDocumentstOkInternalProjectAction
  | DeleteDocumentstKoInternalProjectAction
  | DownloadDocumentstInternalProjectAction
  | DownloadDocumentstOkInternalProjectAction
  | DownloadDocumentstKoInternalProjectAction
  | GetInternalProjectDailyFollows
  | GetInternalProjectDailyFollowsOk
  | GetInternalProjectDailyFollowsKo
  | GetInternalProjectsExcel
  | GetInternalProjectsExcelOk
  | GetInternalProjectsExcelKo
  | GetInternalProjectDailyFollow
  | GetInternalProjectDailyFollowOk
  | GetInternalProjectDailyFollowKo
  | AddDocumentInternalProjectDailyFollowAction
  | AddDocumentInternalProjectDailyFollowOkAction
  | AddDocumentInternalProjectDailyFollowKoAction
  | DownloadDocumentInternalDailyFollowProjecttAction
  | DownloadDocumentInternalProjectDailyFollowOkAction
  | DownloadDocumentInternalProjectDailyFollowKoAction
  | RemoveDocumentInternalProjectDailyFollowAction
  | RemoveDocumentInternalProjectDailyFollowOkAction
  | RemoveDocumentInternalProjectDailyFollowKoAction
  | GetMetadata
  | GetMetadataOk
  | GetMetadataKo
  | StoreDailyFollow
  | StoreDailyFollowOk
  | StoreDailyFollowKo
  | ClearDailyFollow
  | DeleteDailyFollow
  | DeleteDailyFollowOk
  | DeleteDailyFollowKo
  | DeleteInternalProject
  | DeleteInternalProjectOk
  | DeleteInternalProjectKo
  | GetInternalProjectDailyFollowsExcel
  | GetInternalProjectDailyFollowsExcelOk
  | GetInternalProjectDailyFollowsExcelKo
  | GetInternalProjectsAcademy
  | GetInternalProjectsAcademyOk
  | GetInternalProjectsAcademyKo
  | GetInternalProjectAcademy
  | GetInternalProjectAcademyOk
  | GetInternalProjectAcademyKo
  | GetInternalProjectFollows
  | GetInternalProjectFollowsOk
  | UpdateFollowUpDate
  | UpdateFollowUpDateOk
  | GetSectionTemplate
  | GetSectionTemplateOk
  | GetSectionTemplateKo
  | UpdateFollowUp
  | UpdateFollowUpOk
  | UpdateFollowUpKo
  | SendFollowUp
  | SendFollowUpOk
  | SendFollowUpKo
  | AddDocumentFollowUp
  | AddDocumentFollowUpOk
  | AddDocumentFollowUpKo
  | DeleteDocumentstOkFollowUp
  | AddNewGenericTableRowAction
  | SetRegisterTableAction
  | GetInternalProjectSettings
  | GetInternalProjectSettingsOk
  | GetInternalProjectSettingsKo
  | UpdateInternalProjectSettings
  | UpdateInternalProjectSettingsOk
  | UpdateInternalProjectSettingsKo
  | DeleteRowParticipante
  | DeleteRowParticipanteOk
  | DeleteRowParticipanteKo
  | GetParticipantesFollowUpList
  | GetParticipantesFollowUpListOk
  | GetParticipantesFollowUpListKo
  | GetFollowUpSixMonthsForm
  | GetFollowUpSixMonthsFormOk
  | GetFollowUpSixMonthsFormKo
  | UpdateFollowUpSixMonthsForm
  | UpdateFollowUpSixMonthsFormOk
  | UpdateFollowUpSixMonthsFormKo
  | GetProjectBaseCamp
  | GetProjectBaseCampOk
  | UpdateEnrollment
  | UpdateEnrollmentOk
  | DeleteInternalProjectEnrollment
  | DeleteInternalProjectEnrollmentOk
  | SetNewInternalProjectEnrollment
  | GetInternalProjectEnrollment
  | GetInternalProjectEnrollmentOk
  | GenericKo
  | GetParticipantesFinalFollowUpForm
  | GetParticipantesFinalFollowUpFormKo
  | GetParticipantesFinalFollowUpFormOk
  | UpdateParticipantesFinalFollowUpFormKo
  | UpdateParticipantesFinalFollowUpFormOk
  | UpdateParticipantesFinalFollowUpForm
  | GetIpStudentEvaluation
  | GetIpStudentEvaluationOk
  | SetEnrollmentTextData
  | SetEnrollmentNumericData
  | SetEnrollmentDropDownData
  | UpdateInternalProjectEnrollments
  | UpdateInternalProjectEnrollmentsOk
  | UpdateInternalProjectEnrollmentsKo
  | SendIpStudentEvaluation
  | GetStudentEvaluationInfo
  | GetStudentEvaluationInfoOk
  | GetInternalProjectImagesAction
  | GetInternalProjectImagesOkAction
  | AddInternalProjectImageAction
  | AddInternalProjectImageOkAction
  | EditInternalProjectImageAction
  | EditInternalProjectImageOkAction
  | DeleteInternalProjectImageAction
  | DeleteInternalProjectImageOkAction
  | GetProjectTypeBySubareaCodeAction
  | GetProjectTypeBySubareaCodeOkAction
  | GetProjectTypeBySubareaCodeKoAction
  | ApplyConfigTemplateAction
  | ApplyConfigTemplateActionFinish;

export type InternalProjectDispatch = (action: InternalProjectDataAction) => void;
