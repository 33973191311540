import React from 'react';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tProjectKey } from '../../../helpers/translate';
import { CodeSectionProject, FormAssistantSections } from '../../../redux/kpis/definitions';

interface SubmenuFormsAssistant {
  id_project: string;
  current_section: CodeSectionProject;
  onClickOption: () => void;
}
const SubmenuFormsAssistant: React.FC<SubmenuFormsAssistant> = ({ id_project, current_section, onClickOption }) => {
  const submenu: SubmenuItem[] = FormAssistantSections.map(section => ({
    title: tProjectKey(section.name),
    type: 'route',
    route: `/proyecto/asistente-formularios/${id_project}/${section.code}`,
    selected: section.code === current_section,
  }));

  return <Submenu optionsMenu={submenu} defaultOpen onClickOption={onClickOption} />;
};

export default SubmenuFormsAssistant;
