import React, { Suspense, useState } from 'react';
import { AppState } from '../../../redux/root-reducer';
import { selectGetScholarship } from '../../../redux/scholarship/selectors';
import { tKey } from '../../../helpers/translate';
import { connect } from 'react-redux';
import { ScholarshipData } from '../../../redux/scholarship/definitions';
import { KeyButton } from '../../../redux/common/definitions';
import TransversalCompetencesModal from '../Components/TransversalCompetencesModal/TransversalCompetencesModal';
import { getMoreInfoButtonType } from '../ButtonActions/MoreInfoButtonActions';

interface Props {
  scholarshipData: ScholarshipData;
}
const TransversalCompetencesScholarshipModal = ({ scholarshipData }: Props) => {
  const [modal, setModal] = useState(false);
  const buttonTrasnversalCompetences = getMoreInfoButtonType(KeyButton.TRANSVERSAL_COMPETENCES);
  buttonTrasnversalCompetences.onClick = () => {
    setModal(true);
  };
  return (
    <Suspense fallback={<></>}>
      <TransversalCompetencesModal
        view={modal}
        scholarship_id={scholarshipData.id}
        title={tKey('Beca').concat(
          ' ',
          scholarshipData.student_name || '',
          ' ',
          scholarshipData.student_surname || '',
          ' ',
          scholarshipData.student_surname2 || '',
        )}
        handleClose={() => setModal(false)}
      />
    </Suspense>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipData: selectGetScholarship(state),
});

export default connect(mapStateToProps)(TransversalCompetencesScholarshipModal);
