import * as yup from 'yup';
import React from 'react';
import { connect } from 'react-redux';
import TransitionModal from '../../../../../../../../components/Modal/TransitionModal';
import { tKey } from '../../../../../../../../helpers/translate';
import FormContainer from '../../../../../../../../components/Forms/FormContainer';
import { useForm } from 'react-hook-form';
import styles from './newEducator.module.scss';
import ButtonGroup from '../../../../../../../../components/ButtonGroup/ButtonGroup';
import { Button } from '@material-ui/core';
import { generateUuid } from '../../../../../../../../helpers/uuidGenerator';
import { Educator } from '../../../../../../../../redux/internal-projects/educators/Educator';
import { requiredText } from '../../../../../../../../validations/formSchema';
import FncInputTextController from '../../../../../../../../components/Forms/FncInputs/FncInputTextController';
import { ViewModelType } from './NewEditEducatorViewModel';

export const addEducatorSchema = yup.object().shape({
  user_name: yup.string().required(requiredText),
  email: yup
    .string()
    .email()
    .required(requiredText),
});

interface Props {
  internal_project_id: number;
  showModal: boolean;
  educator: Educator | null;
  onAddNewEducator: () => void;
  onCloseModal: () => void;
  viewModel: ViewModelType;
}

const NewEducator = ({
  internal_project_id,
  showModal,
  educator,
  onAddNewEducator,
  onCloseModal,
  viewModel,
}: Props) => {
  const { save, update } = viewModel();
  const { control, errors, handleSubmit } = useForm({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: addEducatorSchema,
  });
  const onSubmit = (data: any) => {
    educator
      ? update(educator.id, { ...data, internal_project_id })
      : save({ ...data, id: generateUuid(), internal_project_id });
    onAddNewEducator();
  };

  return (
    <>
      <TransitionModal
        view={showModal}
        handleClose={onCloseModal}
        title={educator ? tKey('Modificar educador') : tKey('Añadir educador')}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer title={''}>
            <div className={styles.selectors}>
              <FncInputTextController
                label={tKey('Nombre educador')}
                name="user_name"
                schema={addEducatorSchema}
                errors={errors}
                control={control}
                defaultValue={educator?.user_name}
              />
            </div>
            <div className={styles.selectors}>
              <FncInputTextController
                label={tKey('Email')}
                name="email"
                schema={addEducatorSchema}
                errors={errors}
                control={control}
                defaultValue={educator?.email}
              />
            </div>
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  {educator ? tKey('Modificar') : tKey('Añadir')}
                </Button>
              </ButtonGroup>
            </div>
          </FormContainer>
        </form>
      </TransitionModal>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewEducator);
