import moment from 'moment';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_DB = 'YYYY-MM-DD';
export const DATE_FORMAT_AMERICAN = 'YYYY/MM/DD';
export const TIME_FORMAT = 'HH:mm';
export const DATE_HOUR_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_HOUR_FORMAT_FILE = 'DD/MM/YYYY_HH:mm';
export const MONTH_FORMAT = 'MMMM';

export const today = () => {
  //Date today in miliseconds
  return Math.floor(moment().valueOf() / 1000);
};
