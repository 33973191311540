import { SignatureDocument, SignatureDocumentType } from '../../redux/signature-document/definitions';
import { apiBaseUrl, apiCall, apiCallBlob } from '../api';
import { UUID } from '../../types/Shared/standard';

const baseURL = apiBaseUrl;

interface SuccessResult {
  data: string;
}

interface SignedResult {
  signature_id: UUID;
}

export const getSignatureDocumentsFromApi = async (
  type: SignatureDocumentType,
  type_id: number,
): Promise<SignatureDocument[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: SignatureDocument[] }>(
    `${baseURL}/signature_document/${type}/${type_id}`,
    init,
  );
  return data;
};

export const uploadDocumentation = async (
  id: UUID,
  file: File,
  type_id: number,
  document_type: SignatureDocumentType,
): Promise<SignatureDocument[]> => {
  const dataForm = new FormData();
  dataForm.append('documento', file, file.name);
  dataForm.append('id', id.toString());
  dataForm.append('type_id', type_id.toString());
  dataForm.append('type', document_type.toString());
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: SignatureDocument[] }>(`${baseURL}/signature_document/upload`, init);
  return data;
};

export const removeSignatureDocumentFromApi = async (id: string): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<SuccessResult>(`${baseURL}/signature_document/${id}`, init);
  return data;
};

export const downloadSignatureDocumentFromApi = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/file/download/${file_id}`, init);
};

export const sendSignatureDocumentFromApi = async (id: string): Promise<SignedResult> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id }),
  };
  const { data } = await apiCall<{ data: SignedResult }>(`${baseURL}/signature_document/send/`, init);
  return data;
};

export const cancelSignatureDocumentFromApi = async (id: string): Promise<SignedResult> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id }),
  };
  const { data } = await apiCall<{ data: SignedResult }>(`${baseURL}/signature_document/cancel/`, init);
  return data;
};
