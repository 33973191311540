import Button from '@material-ui/core/Button/Button';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { tErrorKey, tKey } from '../../helpers/translate';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { AppState } from '../../redux/root-reducer';
import { sendImportTranslations } from '../../redux/translations/actions';
import { selectImportResult } from '../../redux/translations/selectors';
import Loading from '../Loading/Loading';
import styles from './uploadLocalFile.module.scss';

interface UploadLocalFileProps {
  source: 'keys' | 'custom_fields';
  importFile: (source: 'keys' | 'custom_fields', importObj: any) => void;
}

type JSONFormat = { [k: string]: string };

const UploadLocalFile: React.FC<UploadLocalFileProps> = ({ importFile, source }) => {
  const [fileLoading, setFileLoad] = useState(false);
  const [fileJson, setFileJson] = useState([{}]);
  const [fileName, setFileName] = useState('');

  const readFile = (jsonExcel: any) => {
    const f = jsonExcel.file;
    const reader = new FileReader();
    reader.onload = evt => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target?.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      //@ts-ignore
      const data = XLSX.utils.sheet_to_json(ws);

      /* Update state */
      if (Array.isArray(data)) setFileJson(data);
    };
    //@ts-ignore
    reader.readAsBinaryString(f);
  };

  const filePathset = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    setFileName(file.name);

    setFileLoad(true);
    try {
      readFile({ file });
      setFileLoad(false);
    } catch (e) {
      showSnackbar(tErrorKey('Ha ocurrido un error, no se ha podido leer el archivo'), 'error', undefined, 1500);
      setFileLoad(false);
    }
  };

  const readyToSend = () => {
    importFile(source, fileJson);
  };

  return (
    <div>
      <input type="file" id="file" onChange={filePathset} style={{ display: 'none' }} />
      {fileName !== '' && (
        <div className={styles.fileNameContainer}>
          <div className={styles.placeHolder}>{tKey('Nombre de archivo')}: </div> <div>{fileName}</div>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <label htmlFor="file">
          <Button variant="contained" color="secondary" component="span" fullWidth disabled={fileLoading}>
            {tKey('Examinar archivo')}
          </Button>
        </label>
        {fileLoading === true ? (
          <Loading small />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            disabled={fileLoading}
            fullWidth
            onClick={() => {
              readyToSend();
            }}
          >
            {tKey('Confirmar importación')}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  responseImport: selectImportResult(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  importFile: (source: 'keys' | 'custom_fields', importObj: any): void =>
    dispatch(sendImportTranslations(source, importObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadLocalFile);
