import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import { tFormKey, tKey } from '../../../helpers/translate';
import { selectAuth } from '../../../redux/auth/selectors';
import { CodeNamePair } from '../../../redux/common/definitions';
import { selectLanguages } from '../../../redux/metadata/selectors';
import { AppState } from '../../../redux/root-reducer';
import { getMetadataTranslation } from '../../../redux/translations/actions';
import { TranslationMetadata } from '../../../redux/translations/definitions';
import { selectLoading, selectTranslationMetadata } from '../../../redux/translations/selectors';
import { TranslationSchema } from '../../../validations/translationSchema';
import styles from './filterTranslation.module.scss';

interface FilterTranslationProps {
  test?: string;
  user_lang: string;
  lang: CodeNamePair[];
  metadata: TranslationMetadata;
  loading: boolean;
  getMetadata: () => void;
  onSubmit: (data: any) => void;
  onChangeLang: (lang: string) => void;
}

const Translations: React.FC<FilterTranslationProps> = ({
  lang,
  metadata,
  user_lang,
  loading,
  onSubmit,
  onChangeLang,
  getMetadata,
}) => {
  useEffect(() => {
    getMetadata();
  }, [getMetadata]);

  const showOne = (lang: string, typeInput: string) => {
    if (!lang) {
      lang = user_lang;
    }
    if (lang === 'ca' && typeInput === 'value_ca') {
      return true;
    } else if ((lang === 'es' || lang === '') && typeInput === 'value_es') {
      return true;
    } else if (lang === 'en' && typeInput === 'value_en') {
      return true;
    } else if (lang === 'fr' && typeInput === 'value_fr') {
      return true;
    } else {
      return false;
    }
  };

  const showRealLang = (lan: string) => {
    const language = lang.find((l: CodeNamePair) => l.code === lan);
    return language ? language.name?.toLowerCase() : '';
  };

  const { errors, control, setValue, watch, handleSubmit } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: TranslationSchema,
  });

  const onThisSubmit = (data: any) => {
    onSubmit(data);
    setValue('value_es', '');
    setValue('value_ca', '');
    setValue('value_en', '');
    setValue('value_fr', '');
  };

  const handleLang = (e: any) => {
    onChangeLang(e.target.value);
  };

  return (
    <div className={styles.container}>
      {Object.keys(metadata).length > 0 && !loading ? (
        <form className={styles.form_container} onSubmit={handleSubmit(onThisSubmit)}>
          <div className={styles.itemContainer}>
            <SelectController
              label={tFormKey('Ambito')}
              schema={TranslationSchema}
              name={'category'}
              size={'100'}
              errors={errors}
              control={control}
              defaultValue={'General'}
              variant="outlined"
              fullWidth
              className={styles.selectTranslation}
            >
              {metadata.categories.map(element => (
                <MenuItem key={element} value={element} data-name={element}>
                  {element}
                </MenuItem>
              ))}
            </SelectController>
          </div>
          <div className={styles.langContainer}>
            <SelectController
              label={tFormKey('Idioma')}
              schema={TranslationSchema}
              name={'language'}
              defaultValue={user_lang}
              size={'100'}
              errors={errors}
              control={control}
              variant="outlined"
              fullWidth
              className={styles.selectTranslation}
              onClick={e => handleLang(e)}
            >
              {lang.map(element => (
                <MenuItem key={element.code} value={element.code} data-name={element.name}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
          </div>
          <div className={styles.textTranslate}>
            <div className={styles.mainTextArea}>
              <div className={styles.textInput}>
                {tKey('Texto')} {showRealLang(watch('language', user_lang))}
              </div>
              {showOne(watch('language', user_lang), 'value_es') && (
                <TextInputController
                  disabled={false}
                  schema={TranslationSchema}
                  name={'value_es'}
                  errors={errors}
                  control={control}
                  label={''}
                  size={'100'}
                />
              )}
              {showOne(watch('language'), 'value_ca') && (
                <TextInputController
                  disabled={false}
                  schema={TranslationSchema}
                  name={'value_ca'}
                  errors={errors}
                  control={control}
                  label=""
                  size={'100'}
                />
              )}
              {showOne(watch('language'), 'value_fr') && (
                <TextInputController
                  disabled={false}
                  schema={TranslationSchema}
                  name={'value_fr'}
                  errors={errors}
                  control={control}
                  label=""
                  size={'100'}
                />
              )}
              {showOne(watch('language'), 'value_en') && (
                <TextInputController
                  disabled={false}
                  schema={TranslationSchema}
                  name={'value_en'}
                  errors={errors}
                  control={control}
                  label=""
                  size={'100'}
                />
              )}
            </div>

            <div className={styles.buttonContainer}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleSubmit(onSubmit)}
                startIcon={<SearchIcon />}
              >
                {tKey('Buscar')}
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <Loading big />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  lang: selectLanguages(state),
  metadata: selectTranslationMetadata(state),
  loading: selectLoading(state),
  user_lang: selectAuth(state).language,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMetadata: (): void => dispatch(getMetadataTranslation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Translations);
