import { useState } from 'react';
import { normalizeString } from '../normalizeString';

type TableDataItem<T extends object> = T;

interface UseTableWithSearchProps<T extends object> {
  initialData: TableDataItem<T>[];
  propertyExtractor: (item: TableDataItem<T>) => string; // Function to extract filterable string
}

export function useTableWithSearch<T extends object>({ initialData, propertyExtractor }: UseTableWithSearchProps<T>) {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredData = initialData.filter(
    item =>
      normalizeString(propertyExtractor(item)) // Remove diacritics from the extracted string
        .toLowerCase()
        .includes(normalizeString(searchQuery.toLowerCase())), // Remove diacritics from the search query
  );

  const handleSearch = (searchTerm: string) => {
    setSearchQuery(searchTerm);
  };

  return {
    filteredData,
    handleSearch,
  };
}
