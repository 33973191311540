import { ButtonProps } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableWithEdit from '../../../../../components/Tables/TableWithEdit';
import Title from '../../../../../components/Title/Title';
import { tFormKey, tKey } from '../../../../../helpers/translate';
import {
  deleteDailyFollow,
  getInternalProjectDailyFollows,
  getInternalProjectDailyFollowsExcel,
  getMetadata,
} from '../../../../../redux/internal-projects/actions';
import { DailyFollowsTable, InternalProject } from '../../../../../redux/internal-projects/definitions';
import { AppState } from '../../../../../redux/root-reducer';
import CreateDailyFollow from '../../../Modals/CreateDailyFollow/CreateDailyFollow';
import InternalProjectLayoutForm from '../../InternalProjectLayoutForm';
import InformationMinimal from '../Information/InformationMinimal';
import { selectInternalProject } from '../../../../../redux/internal-projects/selectors';
import { useWindowSize } from '../../../../../helpers/customHooks/useHookMethod';
import MobileDailyFollowsView from './MobileDailyFollowsView';

interface DailyFollowsProps {
  id: number;
  disabled?: boolean;
  loading: boolean;
  dailyFollows: DailyFollowsTable[];
  buttonImageGallery: ButtonProps;
  handleGoBack?: () => void;
  internalProject: InternalProject;
  handleChangeSection: (componentId: string) => void;
  getInternalProjectDailyFollows: (internal_project_id: number) => void;
  getMetadata: (subarea_internal_project_type_id: string | null) => void;
  deleteDailyFollow: (dailyFollowId: number) => void;
  getInternalProjectDailyFollowsExcel: (internal_project_id: number) => void;
}

const DailyFollows: React.FC<DailyFollowsProps> = ({
  id,
  disabled,
  internalProject,
  loading,
  dailyFollows,
  buttonImageGallery,
  handleGoBack,
  handleChangeSection,
  getInternalProjectDailyFollows,
  getMetadata,
  deleteDailyFollow,
  getInternalProjectDailyFollowsExcel,
}) => {
  const isMobile = useWindowSize().IsMobile;

  const [addDailyFollowsModal, setAddDailyFollowsModa] = useState({ view: false, dailyFollowId: 0 });

  useEffect(() => {
    if (dailyFollows.length === 0) {
      getInternalProjectDailyFollows(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getMetadata(internalProject.subarea_internal_project_type_id);
  }, [getMetadata, internalProject]);

  const tableData: MaterialTableProps<DailyFollowsTable> = {
    title: tKey('ip.seguimientos_diarios'),
    columns: [
      {
        title: tFormKey('ip.curso'),
        field: 'course',
      },
      {
        title: tFormKey('ip.fecha'),
        field: 'follow_date',
        //@ts-ignore
        width: '120px',
      },
      {
        title: tFormKey('ip.modalidad'),
        field: 'modality_name',
        //@ts-ignore
      },
      {
        title: tFormKey('ip.component'),
        field: 'tipology_name',
      },
      {
        title: tFormKey('ip.profesionalesImplicados'),
        field: 'profesionals_involveds_names',
      },
      {
        title: tFormKey('ip.notas'),
        field: 'note',
      },
      {
        title: tFormKey('ip.creador'),
        field: 'owner_name',
      },
    ],
    data: dailyFollows,
    isLoading: loading,
    options: {
      exportButton: true,
      exportCsv: () => {
        getInternalProjectDailyFollowsExcel(id);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'fixed',
    },
  };

  const goToNew = () => setAddDailyFollowsModa({ view: true, dailyFollowId: 0 });

  const handleDeleteMember = (rowData: DailyFollowsTable | DailyFollowsTable[]) => {
    if (!Array.isArray(rowData)) deleteDailyFollow(rowData.id);
  };

  const handleEditMember = (e: any, rowData: DailyFollowsTable | DailyFollowsTable[]) => {
    if (!Array.isArray(rowData)) setAddDailyFollowsModa({ view: true, dailyFollowId: rowData.id });
  };

  const handleCloseCreateDailyFollow = () => {
    setAddDailyFollowsModa({ view: false, dailyFollowId: 0 });
  };

  function getCreateDailyFollow() {
    return (
      <CreateDailyFollow
        view={addDailyFollowsModal.view}
        dailyFollowId={addDailyFollowsModal.dailyFollowId}
        handleClose={handleCloseCreateDailyFollow}
      />
    );
  }

  return (
    <InternalProjectLayoutForm
      sectionSelected={'seguimientos_diarios'}
      handleChangeSection={handleChangeSection}
      handleGoBack={handleGoBack}
      actionsButtons={[buttonImageGallery]}
    >
      <>
        {addDailyFollowsModal.view && isMobile && getCreateDailyFollow()}
        {!addDailyFollowsModal.view && isMobile && (
          <MobileDailyFollowsView
            createNew={goToNew}
            tableData={tableData}
            dailyFollows={dailyFollows}
            addDailyFollowsModal={addDailyFollowsModal}
            handleEditMember={handleEditMember}
          />
        )}
        {!isMobile && (
          <Fragment>
            <Title>{tKey('ip.seguimientos_diarios')}</Title>
            <InformationMinimal />
            <TableWithEdit
              tableData={tableData}
              filtering
              permission={!disabled}
              optionsNew={{
                newButtonText: tKey('ip.nuevo_seguimiento'),
                onNew: goToNew,
              }}
              deleteFunc={disabled ? undefined : handleDeleteMember}
              onEditOne={disabled ? undefined : handleEditMember}
              exportButton
              customContainer
            />
            {addDailyFollowsModal.view && getCreateDailyFollow()}
          </Fragment>
        )}
      </>
    </InternalProjectLayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectReducer.loadingAlt,
  internalProject: selectInternalProject(state),
  dailyFollows: state.internalProjectReducer.dailyFollows,
});

const mapDispatchToProps = (dispatch: any) => ({
  getInternalProjectDailyFollows: (internal_project_id: number): void =>
    dispatch(getInternalProjectDailyFollows(internal_project_id)),
  getMetadata: (subarea_internal_project_type_id: string | null) => {
    dispatch(getMetadata(subarea_internal_project_type_id));
  },
  deleteDailyFollow: (dailyFollowId: number) => dispatch(deleteDailyFollow(dailyFollowId)),
  getInternalProjectDailyFollowsExcel: (internal_project_id: number): void =>
    dispatch(getInternalProjectDailyFollowsExcel(internal_project_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DailyFollows);
