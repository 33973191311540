import { put, takeLatest } from 'redux-saga/effects';
import { ParticipantParticularitiesConst } from './action_types';
import { getInternalProjectParticipantParticularities } from '../../../../api/InternalProject/participantParticularities';
import { GetParticipantParticularities, ParticipantParticularitiesType } from './definitions';
import errorMessage from '../../../../helpers/errorMessage';
import { getParticipantParticularitiesKO, getParticipantParticularitiesOk } from './actions';

function* getParticipantParticularitiesFromSaga() {
  try {
    const response = yield getInternalProjectParticipantParticularities();
    const data: ParticipantParticularitiesType[] = Object.values(response.data).map(
      value => value as ParticipantParticularitiesType,
    );
    yield put(getParticipantParticularitiesOk(data));
  } catch (error) {
    yield put(getParticipantParticularitiesKO(errorMessage(error)));
  }
}

export function* watchFetchParticipantParticularities() {
  yield takeLatest<GetParticipantParticularities>(
    ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES,
    getParticipantParticularitiesFromSaga,
  );
}

export default watchFetchParticipantParticularities();
