import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ButtonProps } from '@material-ui/core';
import { GetApp as GetAppIcon, Payment } from '@material-ui/icons';
import EntitiesScholarshipPaymentContainer from './EntitiesScholarshipPaymentContainer';
import { exportScholarshipsEntityToPayFromApi } from '../../../../api/Scholarship/scholarship';
import { getDocs } from '../../../../helpers/getDocs';
import { tKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import { selectPaymentAcademicYear } from '../../../../redux/scholarship/selectors';
import Layout from '../../../../components/Layout/Layout';
import AccountingSubmenu from '../../AccountingSubmenu';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { KeyButton } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { retrievePayment, paySage as paySageApi } from '../../../../api/Entity/entity';
import { PaymentInfo } from '../../../../redux/project/definitions';
import PaymentModal from '../../../Project/Modal/PaymentModal/PaymentModal';

interface Props {
  selectedAcademicYear: string;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const EntitiesScholarshipPayments = ({ selectedAcademicYear, showSnackbar }: Props) => {
  const [selectedEntity, setSelectedEntity] = useState<number>(0);
  const [payModal, setPayModal] = useState({ view: false, modal: {} as PaymentInfo });

  const exportExcel = async () => {
    const file = await exportScholarshipsEntityToPayFromApi(selectedAcademicYear, selectedEntity);
    const fileName = `pagos_entidad_becas_entidad_${selectedAcademicYear.replace('/', '_')}.xlsx`;
    getDocs(file, true, fileName);
  };

  const paymentModal = async () => {
    try {
      if (selectedEntity === 0) {
        showSnackbar('Se debe seleccionar una entidad', 'warning', undefined, 1500);
        return;
      }
      const { data } = await retrievePayment({
        id: selectedEntity,
        academic_year: selectedAcademicYear,
      });
      setPayModal({ view: true, modal: data });
    } catch (error) {
      const e = error as ErrorEvent;
      showSnackbar(e.message, 'error', undefined, 1500);
    }
  };

  const doPayment = async (dataForm: PaymentInfo) => {
    try {
      const data = {
        id: selectedEntity,
        account_number: dataForm.account_number,
        academic_year: selectedAcademicYear,
        payment_date: dataForm.payment_date,
      };
      await paySageApi(data);
      showSnackbar(tKey('Pago realizado'), 'success', undefined, 1500);
    } catch (error) {
      const e = error as ErrorEvent;
      showSnackbar(e.message, 'error', undefined, 1500);
    }
  };

  const buttonExport = {
    children: tKey('Exportar'),
    disableElevation: true,
    fullWidth: true,
    color: 'primary',
    variant: 'contained',
    startIcon: <GetAppIcon />,
    onClick: () => exportExcel(),
    disabled: selectedEntity === 0,
  } as ButtonProps;

  const buttonPay = {
    children: tKey('Pagar'),
    disableElevation: true,
    key: KeyButton.PAY,
    fullWidth: true,
    color: 'primary',
    variant: 'contained',
    startIcon: <Payment />,
    disabled: selectedEntity === 0,
    onClick: () => paymentModal(),
  } as ButtonProps;

  const actionButtons: ButtonProps[] = [buttonExport, buttonPay];

  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="entity" submenu={tKey('Pagos')} />}
      rightSubmenu={<ActionsMenu actionsButtons={actionButtons} goBack />}
    >
      <EntitiesScholarshipPaymentContainer
        showEntities
        selectedEntity={(selectedEntityId: number) => setSelectedEntity(selectedEntityId)}
      />
      <PaymentModal
        view={payModal.view}
        onClose={() => setPayModal(prevModal => ({ ...prevModal, view: false }))}
        onSubmit={doPayment}
        inputsData={payModal.modal}
      />
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectPaymentAcademicYear(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesScholarshipPayments);
