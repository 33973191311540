import { getDocs } from '../../helpers/getDocs';
import { ServiceDataConsts } from './action_types';
import { initialState, ServiceDataAction, ServiceRequestData } from './definitions';

export type ServiceDataState = typeof initialState;

const serviceRequestReducer = (state: ServiceRequestData, action: ServiceDataAction): ServiceRequestData => {
  switch (action.type) {
    case ServiceDataConsts.SET_SERVICES_ASSISTANCE:
      return {
        ...state,
        assistance: action.assistance,
      };

    case ServiceDataConsts.SET_SERVICES_REQUESTED:
      const { key, value, id } = action;

      if (state.id === id) {
        return {
          ...state,
          [key]: value,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

const serviceReducer = (state = initialState, action: ServiceDataAction): ServiceDataState => {
  switch (action.type) {
    case ServiceDataConsts.UPDATE_SERVICE:
    case ServiceDataConsts.GET_SERVICE_DATA:
    case ServiceDataConsts.GET_SERVICES:
    case ServiceDataConsts.GET_SERVICES_REQUESTED:
    case ServiceDataConsts.ADD_USER_TO_SERVICE:
      return {
        ...state,
        loading: true,
        validationErrors: null,
      };

    case ServiceDataConsts.GET_SERVICE_DATA_OK:
      const { serviceData } = action;

      return {
        ...state,
        loading: false,
        serviceData: serviceData,
      };

    case ServiceDataConsts.GET_SERVICES_OK:
      return {
        ...state,
        loading: false,
        error: null,
        subareas: action.subareas,
        servicesList: action.serviceList,
      };
    case ServiceDataConsts.GET_USER_SERVICES_OK:
      return {
        ...state,
        loading: false,
        servicesUserList: action.servicesUserList,
      };

    case ServiceDataConsts.UPDATE_SERVICE_OK:
      const { serviceData: _serviceData } = action;
      return {
        ...state,
        loading: false,
        serviceData: {
          ..._serviceData,
          documents: state.serviceData.documents,
        },
      };

    case ServiceDataConsts.ADD_USER_TO_SERVICE_OK:
      state.serviceRequests.push(action.serviceData);
      return {
        ...state,
        loading: false,
      };

    case ServiceDataConsts.SERVICE_CREATION_DATA:
      const { key: ass_key, value: ass_value } = action;
      return {
        ...state,
        serviceData: {
          ...state.serviceData,
          [ass_key]: ass_value,
        },
      };

    case ServiceDataConsts.UPDATE_SERVICE_KO:
    case ServiceDataConsts.GET_USER_SERVICES_KO:
    case ServiceDataConsts.GET_SERVICE_DATA_KO:
    case ServiceDataConsts.GET_SERVICES_KO:
    case ServiceDataConsts.ADD_USER_TO_SERVICE_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    case ServiceDataConsts.GET_SERVICES_REQUESTED_OK:
      return {
        ...state,
        serviceRequests: action.serviceRequests,
        loading: false,
      };
    case ServiceDataConsts.UPDATE_SERVICE_ASSISTANCE:
    case ServiceDataConsts.REMOVE_SERVICE_REQUESTED:
    case ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE:
    case ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE:
    case ServiceDataConsts.REMOVE_DOCUMENT_SERVICE:
      return { ...state };
    case ServiceDataConsts.REMOVE_SERVICE_REQUESTED_OK:
      return {
        ...state,
        serviceRequests: state.serviceRequests.filter(s => s.id !== action.requestedId),
      };
    case ServiceDataConsts.GET_SERVICES_REQUESTED_KO:
    case ServiceDataConsts.UPDATE_SERVICE_ASSISTANCE_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case ServiceDataConsts.SET_SERVICES_REQUESTED:
    case ServiceDataConsts.SET_SERVICES_ASSISTANCE:
      return {
        ...state,
        serviceRequests: state.serviceRequests.map(s => serviceRequestReducer(s, action)),
      };
    case ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE_OK:
    case ServiceDataConsts.REMOVE_DOCUMENT_SERVICE_OK:
      const { documents } = action;
      return {
        ...state,
        serviceData: {
          ...state.serviceData,
          documents,
        },
      };
    case ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE_OK:
      const { data, name, get } = action;
      getDocs(data, get, name);
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default serviceReducer;
