import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';
import { tKey } from '../../../helpers/translate';
import sortHeadStyles from './tableHeadSortLabe.module.scss';
const BORDER_RIGT_END = '3px solid #6666661a';

interface SortTableHeadProps {
  order: any;
  orderBy: any;
  onRequestSort: any;
  columns: any[];
  option: any;
  actions: boolean;
}

const SortTableHead: React.FC<SortTableHeadProps> = ({ order, orderBy, onRequestSort, columns, option, actions }) => {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };
  return (
    <TableRow>
      {columns.map((headCell: any, index: number) => (
        <TableCell
          className={index <= option?.fixedColumn?.num ? sortHeadStyles.fixed : sortHeadStyles.tableHeadCell}
          style={{
            left: index <= option?.fixedColumn?.num ? option.fixedColumn.left * index : undefined,
            borderRight: index === option?.fixedColumn?.num ? BORDER_RIGT_END : undefined,
            width: index > option?.fixedColumn?.num ? (headCell.width ? headCell.width : '106px') : '',
          }}
          key={headCell.name}
          align={headCell.align}
          sortDirection={orderBy === headCell.name ? order : false}
        >
          <TableSortLabel
            style={{ width: headCell.width ? headCell.width : '', textAlign: 'center' }}
            active={orderBy === headCell.name}
            direction={orderBy === headCell.name ? order : 'asc'}
            onClick={createSortHandler(headCell.name)}
          >
            <span>{headCell.title}</span>
          </TableSortLabel>
        </TableCell>
      ))}
      {actions && (
        <TableCell align="center" className={sortHeadStyles.tableHeadCell} style={{ width: '106px' }}>
          {tKey('Opciones')}
        </TableCell>
      )}
    </TableRow>
  );
};

export default SortTableHead;
