import assertUnreachable from '../../helpers/assertUnreachable';
import { AreasConst } from './action_types';
import { AreasAction, AreasState, initialState } from './definitions';

const areasReducer = (state = initialState, action: AreasAction): AreasState => {
  switch (action.type) {
    case AreasConst.DISABLE_AREA:
    case AreasConst.ADD_ROLES_LIST:
    case AreasConst.GET_ROLES_LIST:
    case AreasConst.GET_ROLES_AREA:
    case AreasConst.GET_AREAS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case AreasConst.GET_ROLES_LIST_OK:
      const { roleList } = action;
      return {
        ...state,
        loading: false,
        error: null,
        roleList,
      };
    case AreasConst.GET_ROLES_AREA_OK:
      const { areasRoleList } = action;
      return {
        ...state,
        loading: false,
        error: null,
        areasRoleList,
      };
    case AreasConst.GET_AREAS_OK:
      const { areasDataList } = action;

      return {
        ...state,
        loading: false,
        error: null,
        areasDataList,
        subAreaList: areasDataList
          .flatMap(areasDataList => areasDataList.subareas)
          .map(subAreas => {
            subAreas.codeArea = areasDataList.find(area => area.id === subAreas.area_id)?.code;
            return subAreas;
          }),
      };
    case AreasConst.GET_ROLES_AREA_KO:
    case AreasConst.GET_AREAS_KO:
    case AreasConst.ADD_ROLES_LIST_KO:
    case AreasConst.REMOVE_ROLES_AREA:
    case AreasConst.DELETE_SUB_AREA:
      return {
        ...state,
        loading: true,
      };
    case AreasConst.DELETE_SUB_AREA_OK:
      const newList = state.areasDataList.map(ele => ({
        ...ele,
        subareas: ele.subareas.filter(ulu => ulu.id !== action.id),
      }));
      return {
        ...state,
        loading: false,
        areasDataList: newList,
      };
    case AreasConst.REMOVE_ROLES_AREA_OK:
      const { area_id, role_id } = action;
      const newAreaRoleList = state.areasRoleList.filter(item => item.role_id !== role_id || item.area_id !== area_id);

      return {
        ...state,
        loading: false,
        areasRoleList: newAreaRoleList,
      };
    case AreasConst.GET_ROLES_LIST_KO:
    case AreasConst.REMOVE_ROLES_AREA_KO:
    case AreasConst.DELETE_SUB_AREA_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AreasConst.ADD_SUBAREA:
      return {
        ...state,
        loading: true,
      };
    case AreasConst.ADD_ROLES_LIST_OK:
      return {
        ...state,
        loading: false,
        areasRoleList: action.data,
        error: null,
        modalView: false,
      };
    case AreasConst.ADD_SUBAREA_OK:
      state.areasDataList.filter(ele => ele.id === action.responseAdd.area_id)[0].subareas.push(action.responseAdd);
      return {
        ...state,
        loading: false,
      };
    case AreasConst.DISABLE_AREA_KO:
    case AreasConst.EDIT_AREA_KO:
    case AreasConst.ADD_SUBAREA_KO:
    case AreasConst.STORE_AREAS_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AreasConst.EDIT_AREA:
    case AreasConst.EDIT_SUBAREA:
      return {
        ...state,
        loading: true,
      };
    case AreasConst.EDIT_SUBAREA_OK:
      const editList = state.areasDataList.map(ele => {
        ele.subareas.forEach(ulu => {
          if (ulu.id === action.id) {
            ulu.name = action.data;
          }
        });
        return { ...ele };
      });
      return {
        ...state,
        loading: false,
        areasDataList: editList,
      };
    case AreasConst.EDIT_AREA_OK:
      const { id, data } = action;
      const editedAreaList = state.areasDataList.map(area => {
        if (area.id === id) {
          area = { ...area, ...data };
        }
        return area;
      });
      return {
        ...state,
        loading: false,
        areasDataList: editedAreaList,
      };
    case AreasConst.DISABLE_AREA_OK:
      const { a_id } = action;
      const newAreaList = state.areasDataList.filter(area => area.id !== a_id);
      return {
        ...state,
        loading: false,
        areasDataList: newAreaList,
      };
    case AreasConst.EDIT_SUBAREA_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AreasConst.CANCEL_GET_AREAS:
    case AreasConst.STORE_AREAS:
      return state;
    case AreasConst.STORE_AREAS_OK:
      const { areasData } = action;
      areasData.subareas = [];
      return { ...state, areasDataList: state.areasDataList.concat(areasData) };

    case AreasConst.OPEN_AREA_ROLES_MODAL:
      return {
        ...state,
        modalView: true,
      };
    case AreasConst.CLOSE_AREA_ROLES_MODAL:
      return {
        ...state,
        modalView: false,
      };
  }
  assertUnreachable(action);
  return state;
};

export default areasReducer;
