import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import SimpleModal from '../../components/Modal/SimpleModal';
import styles from './privacy_policy.module.scss';

const PrivacyPolicy: React.FC<{}> = () => {
  const [createdModal, setCreatedModal] = React.useState(false);
  const [id, setId] = React.useState<number>(0);

  const children = (id: number): JSX.Element => {
    switch (id) {
      case 0:
        return (
          <>
            <p>
              Como responsable del Sitio web, de conformidad con lo que dispone el Reglamento (UE) 2016/679 de 27 de
              abril de 2016 (RGPD), Ley Orgánica 3/2018 de 5 de diciembre (LOPDGDD) y demás normativa legal vigente en
              materia de protección de datos personales, y por la Ley 34/2002, de 11 de julio, de Servicios de la
              Sociedad de la Información y del Comercio Electrónico (LSSICE), le informa de que tiene implementadas las
              medidas de seguridad necesarias, de índole técnica y organizativas, para garantizar y proteger la
              confidencialidad, integridad y disponibilidad de los datos introducidos.
            </p>
          </>
        );
      case 1:
        return (
          <>
            <p>
              Puede revocar en cualquier momento su consentimiento remitiendo un escrito con el asunto “Baja” a
              info@nouscims.com.
            </p>
            <p>
              De acuerdo con la LSSICE, <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> no realiza prácticas de SPAM, por lo
              tanto, no envía correos comerciales por e-mail que no hayan sido previamente solicitados o autorizados por
              el Usuario. En consecuencia, en todas las comunicaciones que recibirá del prestador, el usuario tiene la
              posibilidad de cancelar su consentimiento expreso para recibir nuestras comunicaciones.
            </p>
            <p>
              No trataremos sus datos personales para ninguna otra finalidad de las descritas salvo por obligación legal
              o requerimiento judicial.
            </p>
          </>
        );
      case 2:
        return (
          <>
            <p>
              La base legal para el tratamiento de los datos es su consentimiento otorgado para llevar a cabo las
              finalidades anteriormente descritas, que se solicitará en el momento de marcado de la casilla
              correspondiente a la hora de recoger sus datos.
            </p>
            <p>
              El no facilitar los datos personales solicitados o el no aceptar la presente política de protección de
              datos supone la imposibilidad de subscribirse, registrarse o recibir información de los productos y
              servicios del Prestador.
            </p>
            <p>
              En los casos en que exista una previa relación contractual entre las partes, la legitimación para el
              desarrollo de las obligaciones administrativas, fiscales, contables y laborales que sean necesarias en
              virtud de la legislación vigente será la existencia previa de la relación comercial establecida entre las
              partes.
            </p>
          </>
        );
      case 3:
        return (
          <>
            <p>
              <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> no lleva a cabo ninguna cesión o comunicación de datos salvo
              que exista necesidad razonable para cumplir con un procedimiento judicial, obligación legal o previa
              obtención del consentimiento del usuario.
            </p>
            <p>
              Tampoco se realizarán transferencias internacionales de sus datos de carácter personal sin su
              consentimiento previo sin perjuicio de poder bloquear o cancelar su cuenta si puede haber indicios de la
              comisión de algún delito por parte del usuario. La información facilitada será solamente la que disponga
              en este momento el prestador.
            </p>
            <p>
              <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> no cederá los datos recogidos a terceros salvo que exista
              necesidad razonable para cumplir con un procedimiento judicial, obligación legal o previa obtención del
              consentimiento del usuario.
            </p>
            <p></p>
          </>
        );
      case 4:
        return (
          <>
            <p>
              Como usuario-interesado, puede solicitar el ejercicio de los siguientes derechos ante{' '}
              <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> presentando un escrito a la dirección postal del
              encabezamiento o enviando un correo electrónico a info@nouscims.com, indicando como Asunto: “PROTECCIÓN DE
              DATOS, EJERCICIO DERECHOS”, y adjuntando fotocopia de su DNI o cualquier medio análogo en derecho, tal y
              como indica la ley.
            </p>
            <p>
              <strong>Derechos</strong>
            </p>
            <ul>
              <li>
                <p>
                  <strong>Derecho de acceso: </strong>
                  permite al interesado conocer y obtener información sobre sus datos de carácter personal sometidos a
                  tratamiento.
                </p>
              </li>
              <li>
                <p>
                  <strong>Derecho de rectificación o supresión: </strong>permite corregir errores y modificar los datos
                  que resulten ser inexactos o incompletos.
                </p>
              </li>
              <li>
                <p>
                  <strong>Derecho de cancelación: </strong>permite que se supriman los datos que resulten ser
                  inadecuados o excesivos.
                </p>
              </li>
              <li>
                <p>
                  <strong>Derecho de oposición: </strong>derecho del interesado a que no se lleve a cabo el tratamiento
                  de sus datos de carácter personal o se cese en el mismo.
                </p>
              </li>
              <li>
                <p>
                  <strong>Limitación del tratamiento: </strong>conlleva el marcado de los datos personales conservados,
                  con la finalidad de limitar su futuro tratamiento.
                </p>
              </li>
              <li>
                <p>
                  <strong>Portabilidad de los datos: </strong>facilitación de los datos objeto de tratamiento al
                  interesado, a fin de que éste pueda transmitirlos a otro responsable, sin impedimentos.
                </p>
              </li>
              <li>
                <p>
                  <strong>
                    Derecho a no ser objeto de decisiones individuales automatizadas (incluida la elaboración de
                    perfiles):{' '}
                  </strong>
                  derecho a no ser objeto de una decisión basada en el tratamiento automatizado que produzca efectos o
                  afecte significativamente.
                </p>
              </li>
            </ul>
            <p>
              Como usuario, tiene derecho a retirar el consentimiento prestado en cualquier momento. La retirada del
              consentimiento no afectará a la licitud del tratamiento efectuado antes de la retirada del consentimiento.
            </p>
            <p>
              También tiene derecho a presentar una reclamación ante la autoridad de control si considera que pueden
              haberse visto vulnerados sus derechos con relación a la protección de sus datos (www.aepd.es).
            </p>
          </>
        );
      case 5:
        return (
          <>
            <p>
              Sus datos se conservarán mientras dure la relación comercial con nosotros o ejercite su derecho de
              cancelación u oposición, o limitación al tratamiento. Sin embargo, conservaremos determinados datos
              personales identificativos y del tráfico durante el plazo máximo de 2 años para el caso de que fuera
              requerido por los Jueces y Tribunales o para incoar acciones internas derivadas del uso indebido de la
              página web.
            </p>
            <p>
              No será objeto de decisiones basadas en tratamientos automatizados que produzcan efectos sobre sus datos.
            </p>
          </>
        );
      case 6:
        return (
          <>
            <p>
              <strong>MEDIDAS DE SEGURIDAD</strong>
            </p>
            <p>
              Los datos que nos facilite se tratarán de forma confidencial. El Prestador ha adoptado todas las medidas
              técnicas y organizativas y todos los niveles de protección necesarios para garantizar la seguridad en el
              tratamiento de los datos y evitar su alteración, pérdida, robo, tratamiento o acceso no autorizado, de
              acuerdo el estado de la tecnología y naturaleza de los datos almacenados. Así mismo, se garantiza también
              que el tratamiento y registro en ficheros, programas, sistemas o equipos, locales y centros cumplen con
              los requisitos y condiciones de integridad y seguridad establecidas en la normativa vigente.
            </p>
            <p>
              <strong>IDIOMA</strong>
            </p>
            <p>
              El idioma aplicable a esta Política de Privacidad es el español. Por tanto, en caso de que hubiera alguna
              contradicción en alguna de las versiones facilitadas en otros idiomas, prevalecerá la versión en español.
            </p>
            <p>
              <strong>INFORMACIÓN QUE RECABAMOS:</strong>
            </p>
            <p>Los datos recogidos por parte del responsable son los siguientes:</p>
            <p>
              - Aquellos que los usuarios suministren a través de los diferentes servicios ofrecidos en la página web
            </p>
            <p>- Aquellos incluidos en los diferentes formularios previstos en la página web</p>
            <p>
              - Datos recogidos a través de las “cookies” para la mejora de la experiencia de la navegación según se
              informa en la política de cookies.
            </p>
            <p>
              La negativa a suministrar los datos obligatorios supondrá la imposibilidad de atender la concreta
              solicitud de que se trate. Usted manifiesta que la información y los datos que nos facilita son exactos,
              actuales y veraces. Le rogamos que en caso de modificación, comunique inmediatamente la misma a fin de que
              la información en tratamiento esté en todo momento actualizada y no contenga errores.
            </p>
            <p>
              Si contrata el servicio/compra el producto mediante nuestra página web www.nouscims.com le solicitaremos
              que nos proporcione información sobre usted, incluidos su nombre, datos de contacto e información sobre
              una tarjeta de crédito o de débito.
            </p>
            <p>
              A través de esta Política de Privacidad le informamos que las fotografías que estén colgadas en la web son
              propiedad de <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong>, incluyendo las de los menores, en las que, para
              la obtención de estas, se ha obtenido el consentimiento previo de los padres, tutores o representantes
              legales mediante la firma de los formularios realizados al efecto por los centros en los cuales los
              menores forman parte. Sin embargo, los padres, tutores o representantes de los menores, como titulares del
              ejercicio de los derechos de estos, y siempre previo requerimiento formal por escrito, pueden indicar la
              negativa al uso de la imagen del menor; en este caso, la imagen se mostrará pixelada.
            </p>
            <p>
              <strong>REDES SOCIALES</strong>
            </p>
            <p>
              Le informamos que <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> puede tener presencia en redes sociales. El
              tratamiento de los datos que se lleve a cabo de las personas que se hagan seguidoras en las redes sociales
              (y/o realicen cualquier vínculo o acción de conexión a través de las redes sociales) de las páginas
              oficiales de
              <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> se regirá por este apartado, así como por aquellas condiciones
              de uso, políticas de privacidad y normativas de acceso que pertenezcan a la red social que proceda en cada
              caso y aceptadas previamente por el usuario.
            </p>
            <p>
              FUNDACIÓ PRIVADA NOUS CIMS tratará sus datos con las finalidades de administrar correctamente su presencia
              en la red social, informándole de actividades, productos o servicios del prestador, así como para
              cualquier otra finalidad que las normativas de las Redes Sociales permitan.
            </p>
            <p>Queda prohibida la publicación de contenidos:</p>
            <p>
              - Que sean presuntamente ilícitos por la normativa nacional, comunitaria o internacional o que realicen
              actividades presuntamente ilícitas o contravengan los principios de la buena fe.
            </p>
            <p>
              - Que atenten contra los derechos fundamentales de las personas, falten a la cortesía en la red, molesten
              o puedan generar opiniones negativas en nuestros usuarios o terceros y en general cualesquiera sean los
              contenidos que <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> considere inapropiados.
            </p>
            <p>
              - Y en general que contravengan los principios de legalidad, honradez, responsabilidad, protección de la
              dignidad humana, protección de menores, protección del orden público, la protección de la vida privada, la
              protección del consumidor y los derechos de propiedad intelectual e industrial.
            </p>
            <p>
              Asimismo, FUNDACIÓ PRIVADA NOUS CIMS se reserva la potestad de retirar, sin previo aviso del sitio web o
              de la red social corporativa aquellos contenidos que se consideren inapropiados.
            </p>
            <p>
              Las comunicaciones remitidas a través de las redes sociales serán incorporadas a un archivo propiedad de{' '}
              <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong>, pudiéndole enviar información de su interés.
            </p>
            <p>
              Puede acceder a más información, así como consultar las empresas que forman parte de FUNDACIÓ PRIVADA NOUS
              CIMS, mediante el siguiente enlace: www.nouscims.com/politicadeprivacidad.
            </p>
            <p>
              En todo caso, si Vd. remite información personal a través de la red social, FUNDACIÓ PRIVADA NOUS CIMS
              quedará exento de responsabilidad en relación a las medidas de seguridad aplicables a la presente
              plataforma, debiendo el usuario en caso de querer conocerlas, consultar las correspondientes condiciones
              particulares de la red en cuestión.
            </p>
            <p>
              <strong>ENVÍO CV</strong>
            </p>
            <p>
              En el supuesto de que el usuario envíe su CV a través de nuestra web, le informamos que los datos
              aportados serán tratados para hacerlo partícipe de los procesos de selección que pueda haber, llevando a
              cabo un análisis del perfil del solicitante con el objetivo de seleccionar el mejor candidato para el
              lugar vacante del Responsable. Lo informamos que este es el único procedimiento oficial para aceptar su
              currículum, de forma que no se aceptarán los currículums remitidos por otro procedimiento diferente. En
              caso de producirse alguna modificación en los datos, le rogamos nos lo comunique por escrito lo más ante
              posible, con objeto de mantener sus datos debidamente actualizados.
            </p>
            <p>
              Los datos se conservarán durante un plazo máximo de un año, transcurrido el cual se procederá a la
              supresión de los datos garantizándole un total respecto a la confidencialidad tanto en el tratamiento como
              en su posterior destrucción. En este sentido, transcurrido el mencionado plazo, y si desea continuar
              participando en los procesos de selección del Responsable, le rogamos nos remita nuevamente su currículum.
            </p>
            <p>
              Los datos se podrán tratar y/o comunicar a las empresas integrantes de nuestro grupo durante el tiempo de
              conservación de su currículum y para los mismos fines antes informados.
            </p>
            <p>
              <strong>SUBSCRIPCIÓN AL BLOG</strong>
            </p>
            <p>
              En el supuesto de que el usuario se subscriba al blog, le informamos que los datos aportados serán
              tratados para gestionar su suscripción al blog informativo con aviso de actualización y que se conservarán
              mientras haya un interés mutuo para mantener el fin del tratamiento. Cuando ya no sea necesario para tal
              fin, se suprimirán con medidas de seguridad adecuadas para garantizar la seudonimización de los datos o
              destrucción total de los mismos. No se comunicarán los datos a terceros, excepto obligación legal.
            </p>
            <p>
              <strong>PUBLICACIÓN DE SU TESTIMONIO</strong>
            </p>
            <p>
              En el supuesto de que el usuario quiera publicar su opinión en la web, le informamos que los datos
              aportados serán tratados serán para atender las sugerencias propuestas, experiencias u opiniones respecto
              de los productos y/o servicios para ser publicadas en la página web y así poder ayudar a otros usuarios.
              Los datos se conservarán mientras haya un interés mutuo para mantener el fin del tratamiento y cuando ya
              no sea necesario para tal fin, se suprimirán con medidas de seguridad adecuadas para garantizar la
              seudonimización de los datos o destrucción total de los mismos. Los testigos se publicarán en nuestra
              página web. El único dato personal que se publicará del testigo será su nombre.
            </p>
            <p>
              <strong>CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD</strong>
            </p>
            <p>
              <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> se reserva el derecho a modificar la presente política para
              adaptarla a novedades legislativas o jurisprudenciales.
            </p>
            <p>
              <strong>REVOCABILIDAD</strong>
            </p>
            <p>
              El consentimiento prestado, tanto para el tratamiento como para la cesión de los datos de los interesados,
              es revocable en cualquier momento comunicándolo a FUNDACIÓ PRIVADA NOUS CIMS en los términos establecidos
              en esta Política para el ejercicio de los derechos. Esta revocación en ningún caso tendrá carácter
              retroactivo.
            </p>
            <p>LEGISLACIÓN</p>
            <p>
              A todos los efectos las relaciones entre <strong>FUNDACIÓ PRIVADA NOUS CIMS</strong> con los Usuarios de
              sus servicios telemáticos, presentes en esta Web, están sometidos a la legislación y jurisdicción española
              a la que se someten expresamente las partes, siendo competentes por la resolución de todos los conflictos
              derivados o relacionados con su uso los Juzgados y Tribunales de Barcelona.
            </p>
          </>
        );
      default:
        return <>Sin información</>;
    }
  };

  const openModal = (id: number) => {
    setId(id);
    setCreatedModal(true);
  };
  return (
    <Layout layoutDisabled={true}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            POLÍTICA <br /> DE PRIVACIDAD
          </h1>
          <section className={styles.content}>
            <p className={styles.subtitles}>INTRODUCCIÓN</p>
            <p>
              Su privacidad es importante para nosotros, así que mediante este documento le explicamos qué datos
              recopilamos de los usuarios, como los utilizamos y para qué, entre otras indicaciones. Los mayores de 14
              años podrán registrarse como usuarios sin el previo consentimiento de sus padres, tutores o representantes
              legales. En caso de menores de 14 años o identificados como &quot;incapaces legalmente&quot;, siempre
              necesitaremos del consentimiento paterno, o del tutor/a o representante legal.
            </p>
            <p>
              Para su información, nunca solicitaremos más información de la que realmente necesitaremos para los
              servicios requeridos; nunca la compartiremos con terceros, excepto por obligación legal o con su
              autorización previa; y nunca utilizaremos sus datos para otra finalidad no indicada previamente.
            </p>
            <p>
              Como usuario, deberá leer atentamente esta política de privacidad de manera regular, y siempre que
              introduzca sus datos personales, ya que este documento puede sufrir modificaciones pues el prestador puede
              modificar cualquier tipo de información que pudiera aparecer en la web para cumplir con la normativa o
              actualizar la política, y sin que exista la obligación de pre-avisar o poner en conocimiento de los
              usuarios estas obligaciones, siendo suficientes la publicación en el sitio web del prestador.
            </p>
            <section className={styles.table}>
              <section className={styles.tableRow}>
                <p className={styles.rowTitle}>RESPONSABLE</p>
                <p className={styles.rowSubject}>FUNDACIÓ PRIVADA NOUS CIMS</p>
                <p className={styles.rowInfo} onClick={() => openModal(0)}>
                  + info
                </p>
              </section>
              <section className={styles.tableRow}>
                <p className={styles.rowTitle}>FINALIDAD</p>
                <p className={styles.rowSubject}>
                  <ul>
                    <li>- Realizar las gestiones administrativas necesarias con los usuarios de la web</li>
                    <li>
                      - Remitir las comunicaciones comerciales publicitarias por email, SMS, redes sociales o cualquier
                      otro medio electrónico o físico (si ha aceptado previamente)
                    </li>
                    <li>- Responder a las consultas y/o proporcionar informaciones requeridas por el usuario</li>
                    <li>- Realizar las prestaciones de servicios subscritos por el Usuario Gestión de</li>
                    <li>- Currículum Vítae</li>
                  </ul>
                </p>
                <p className={styles.rowInfo} onClick={() => openModal(1)}>
                  + info
                </p>
              </section>
              <section className={styles.tableRow}>
                <p className={styles.rowTitle}>LEGITIMACIÓN</p>
                <p className={styles.rowSubject}>Consentimiento del interesado.</p>
                <p className={styles.rowInfo} onClick={() => openModal(2)}>
                  + info
                </p>
              </section>
              <section className={styles.tableRow}>
                <p className={styles.rowTitle}>DESTINATARIOS</p>
                <p className={styles.rowSubject}>
                  Sus datos no se cederán a terceros excepto por obligación legal. No se harán transferencias
                  internacionales sin su consentimiento previo.
                </p>
                <p className={styles.rowInfo} onClick={() => openModal(3)}>
                  + info
                </p>
              </section>
              <section className={styles.tableRow}>
                <p className={styles.rowTitle}>DERECHOS</p>
                <p className={styles.rowSubject}>
                  Acceder, rectificar, y cancelar los datos, así como los otros derechos indicados en la información
                  adicional.
                </p>
                <p className={styles.rowInfo} onClick={() => openModal(4)}>
                  + info
                </p>
              </section>
              <section className={styles.tableRow}>
                <p className={styles.rowTitle}>CONSERVACIÓN</p>
                <p className={styles.rowSubject}>
                  Sus datos se mantendrán en los registros de tratamiento de nuestra titularidad mientras su
                  conservación sea necesaria para atender la finalidad que motivó su recogida, así como durante el plazo
                  de atención de posibles obligaciones legales.
                </p>
                <p className={styles.rowInfo} onClick={() => openModal(5)}>
                  + info
                </p>
              </section>
              <section className={styles.tableRow}>
                <p className={styles.rowTitle}>
                  INFORMACIÓN <br /> ADICIONAL
                </p>
                <p className={styles.rowSubject}>
                  Puede consultar la información adicional y detallada sobre Protección de Datos en nuestra página web:
                  <ul>
                    <li>
                      - Política de privacidad:
                      <Link to="politica-de-privacidad"> http://plataforma.nouscims.com/politica-privacidad</Link>
                    </li>
                    <li>
                      - Aviso Legal: <Link to="/aviso-legal">http://plataforma.nouscims.com/aviso-legal</Link>
                    </li>
                    <li>
                      - Política de cookies:{' '}
                      <Link to="/politica-de-cookies">http://plataforma.nouscims.com/politica-de-cookies</Link>
                    </li>
                    <li>
                      - Ley Orgánica de Protección de Datos: <Link to="lopd">http://plataforma.nouscims.com/lopd</Link>
                    </li>
                  </ul>
                </p>
                <p className={styles.rowInfo} onClick={() => openModal(6)}>
                  + info
                </p>
              </section>
            </section>
          </section>
        </div>
      </div>
      {children && (
        <SimpleModal view={createdModal} title="Información" handleClose={() => setCreatedModal(false)}>
          {children(id)}
        </SimpleModal>
      )}
    </Layout>
  );
};

export default PrivacyPolicy;
