import React, { ReactElement } from 'react';
import { FieldValues } from 'react-hook-form/dist/types';
import FormContainer from '../../../../../components/Forms/FormContainer';
import { tScholarshipKey } from '../../../../../helpers/translate';
import AcademicResultGradesDoc from './AcademicResultGradesDoc';
import AcademicResultGradesView from './AcademicResultGradesView';
import AcademicResultProps from './AcademicResultProps';
import AcademicResultReasonsView from './AcademicResultReasonsView';
import AcademicResultSituationView from './AcademicResultSituationView';

const AcademicResultView = <T extends FieldValues>({
  scholarshipData,
  scholarshipMetadata,
  documentationUN,
  readOnly,
  control,
  errors,
  scholarshipType,
  scholarshipFollow,
  followUpCode,
  schema,
  register,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
  setDataFollow,
}: AcademicResultProps<T>): ReactElement => {
  const { id } = scholarshipData;
  const { keep_studying, scholarship_future_actions_id, id: follow_id } = scholarshipFollow;

  const gradeDocumentsRequired = (): boolean => {
    if (followUpCode.isLastFollowUp()) {
      return scholarship_future_actions_id !== 4;
    }
    return !!keep_studying;
  };

  const renderSituationView = (): JSX.Element | undefined => {
    if (followUpCode.isLastFollowUp()) {
      return undefined;
    }
    return (
      <AcademicResultSituationView
        scholarshipFollow={scholarshipFollow}
        scholarshipMetadata={scholarshipMetadata}
        readOnly={readOnly}
        control={control}
        errors={errors}
        scholarshipType={scholarshipType}
        isEntity={scholarshipData.isEntity}
        followUpCode={followUpCode}
        schema={schema}
        register={register}
        setDataFollow={setDataFollow}
      />
    );
  };

  return (
    <FormContainer title={tScholarshipKey('Resultados académicos')}>
      <AcademicResultReasonsView
        scholarshipFollow={scholarshipFollow}
        scholarshipMetadata={scholarshipMetadata}
        readOnly={readOnly}
        errors={errors}
        control={control}
        scholarshipType={scholarshipType}
        followUpCode={followUpCode}
        schema={schema}
        register={register}
        setDataFollow={setDataFollow}
      />
      <AcademicResultGradesDoc
        scholarship_id={id}
        follow_up_id={follow_id}
        documentationUN={documentationUN}
        readOnly={readOnly}
        required={gradeDocumentsRequired()}
        followUpCode={followUpCode}
        downloadDocument={downloadDocument}
        getFile={getFile}
        deleteDocument={deleteDocument}
        addDoc={addDoc}
      />
      <AcademicResultGradesView
        scholarshipFollow={scholarshipFollow}
        readOnly={readOnly}
        control={control}
        errors={errors}
        followUpCode={followUpCode}
        schema={schema}
        setDataFollow={setDataFollow}
      />
      {renderSituationView()}
    </FormContainer>
  );
};

export default AcademicResultView;
