import { Button, ButtonProps } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useWindowSize } from '../../../../helpers/customHooks/useHookMethod';
import { tKey } from '../../../../helpers/translate';
import TransitionModal from '../../../Modal/TransitionModal';
import ActionMenuGoBack from './ActionMenuGoBack';
import styles from './actionsMenu.module.scss';
import MoreInfoButton from './MoreInfoButton';
import OptionsWrapper from './OptionsWrapper';
import TitleActionsMenu from './TitleActionsMenu';
import FncButton from '../../../FncButton/FncButton';

interface ActionsMenu {
  title?: string;
  actionsButtons?: ButtonProps[];
  moreInfoButtons?: ButtonProps[];
  goBack?: boolean;
  isModal?: {
    title: string;
    children?: JSX.Element;
    buttonText: string;
    startIcon?: React.ReactNode;
    handleYes?: (element?: any) => any;
    bodyModal?: string;
    buttonOk?: string;
    buttonKo?: string;
    disabled?: boolean;
  };
  forceCloseModal?: boolean;
  onGoBack?: any;
}

const ActionsMenu: React.FC<ActionsMenu> = ({
  title = tKey('Acciones'),
  actionsButtons = [],
  moreInfoButtons = [],
  goBack = true,
  isModal,
  forceCloseModal,
  onGoBack,
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (forceCloseModal) {
      setShowModal(false);
    }
  }, [forceCloseModal]);

  const IsMobile = useWindowSize().IsMobile;

  return (
    <div className={styles.container}>
      {actionsButtons.length > 0 && (
        <Fragment>
          {/* Si es Desktop pone el titulo en el menu derecho */}
          {!IsMobile && <TitleActionsMenu>{title}</TitleActionsMenu>}
          <OptionsWrapper>
            <Fragment>
              {actionsButtons.map(({ children, hidden, ...props }, i) => {
                if (!hidden) {
                  if (IsMobile) {
                    return (
                      <FncButton key={i} {...props} fullWidth={IsMobile}>
                        {children}
                      </FncButton>
                    );
                  }
                  return (
                    <Button key={i} fullWidth size="large" disableElevation {...props}>
                      {children}
                    </Button>
                  );
                }
                return undefined;
              })}
              {isModal && (
                <Fragment>
                  <Button
                    disableElevation
                    fullWidth
                    color="primary"
                    variant="contained"
                    onClick={() => setShowModal(true)}
                    startIcon={isModal.startIcon}
                    disabled={isModal.disabled}
                  >
                    {isModal.buttonText}
                  </Button>
                  <TransitionModal
                    view={showModal}
                    handleYes={() => {
                      isModal.handleYes && isModal.handleYes();
                      setShowModal(false);
                    }}
                    documentMode
                    buttonKo={isModal.buttonKo}
                    buttonOk={isModal.buttonOk}
                    handleClose={() => {
                      setShowModal(false);
                    }}
                    title={isModal.title}
                    bodyModal={isModal.bodyModal}
                  >
                    {isModal.children}
                  </TransitionModal>
                </Fragment>
              )}
            </Fragment>
          </OptionsWrapper>
        </Fragment>
      )}
      <MoreInfoButton moreInfoButtons={moreInfoButtons} />
      {goBack && <ActionMenuGoBack onGoBack={onGoBack} />}
    </div>
  );
};

export default ActionsMenu;
