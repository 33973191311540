import { Action } from 'redux';
import { UpdateParticipantConst } from './action_types';
import { Participant } from '../Participant';

export interface UpdateParticipantAction extends Action {
  type: UpdateParticipantConst.UPDATE_PARTICIPANT;
  participant: Participant;
}

export interface UpdateParticipantOkAction extends Action {
  type: UpdateParticipantConst.UPDATE_PARTICIPANT_OK;
  updatedParticipant: Participant;
}

export interface UpdateParticipantKoAction extends Action {
  type: UpdateParticipantConst.UPDATE_PARTICIPANT_KO;
  error: string;
}

export type UpdateParticipantDataAction =
  | UpdateParticipantAction
  | UpdateParticipantOkAction
  | UpdateParticipantKoAction;

export const initialState = {
  loading: false,
  error: null as string | null,
  data: [] as Participant[],
};

export type UpdateParticipantState = typeof initialState;
