import { ButtonProps } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import { debounce } from '../../helpers/debounce';
import { tKey } from '../../helpers/translate';
import { setPagination } from '../../redux/directory/action';
import { ProfileInfo } from '../../redux/directory/definitions';
import { selectLoading } from '../../redux/directory/selectors';
import { AppState } from '../../redux/root-reducer';
import DirectoryHeader from './DirectoryHeader/DirectoryHeader';
import DirectoryFilter from './Filter/DirectoryFilter';
import ListZing from './ListZing/ListZing';
import ProfileZing from './Modal/ProfileZing';
import styles from './directory.module.scss';

interface DirectoryPageProps {
  loading: boolean;
  setPagination: () => void;
}

const Directory: React.FC<DirectoryPageProps> = ({ loading, setPagination }) => {
  const [modal, setModal] = useState<{ view: boolean; detail: ProfileInfo }>({
    view: false,
    detail: {} as ProfileInfo,
  });

  const modalButtons: ButtonProps[] = [
    {
      children: tKey('Contáctame'),
      disableElevation: true,
      onClick: () => alert,
      color: 'primary',
      variant: 'outlined',
      fullWidth: true,
      disabled: true, // disabled until works
    },
  ];

  const onDetailModal = (detail: ProfileInfo) => setModal({ detail, view: true });

  const debounceHandleNextpage = useCallback(
    debounce(() => {
      setPagination();
    }, 500),
    [],
  );

  return (
    <Layout>
      <div className={styles.root}>
        <DirectoryHeader title={tKey('dir.estudiantes')}>
          <DirectoryFilter />
        </DirectoryHeader>
        <ListZing onDetailModal={onDetailModal} debounceHandleNextpage={debounceHandleNextpage} />
        <ProfileZing
          profile={modal.detail}
          open={modal.view}
          onClose={() => setModal(prev => ({ ...prev, view: false }))}
          buttons={modalButtons}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setPagination: (): void => dispatch(setPagination()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Directory);
