import { ArrowForward } from '@material-ui/icons';
import React from 'react';
import Loading from '../../../components/Loading/Loading';
import { getAge } from '../../../helpers/getAge';
import { tKey } from '../../../helpers/translate';
import defaultImage from '../../../images/default-directory-avatar.png';
import { ProfileInfo } from '../../../redux/directory/definitions';
import styles from './listItemZing.module.scss';

interface DetailsZingProps {
  data: ProfileInfo[];
  modal?: boolean;
  loading: boolean;
  onDetailModal: (data: ProfileInfo) => void;
}

const DetailsZing: React.FC<DetailsZingProps> = ({ data, modal, onDetailModal, loading }) => {
  return (
    <React.Fragment>
      {data?.map((item, index) => {
        const { name, avatar_file, birthdate, city, last_scholarship_data } = item;
        return (
          <li
            key={index}
            className={`${styles.root} ${modal ? styles.isModal : ''}`}
            onClick={() => onDetailModal && onDetailModal(item as ProfileInfo)}
          >
            <img className={styles.media} src={avatar_file || defaultImage} alt="avatar" />
            <div className={styles.wrapper}>
              <h3 className={`${styles.title} ${styles.studentName}`} title="name">
                <strong>{name}</strong>
              </h3>

              <p className={styles.text} title="age">
                {birthdate ? `${getAge(birthdate)} ${tKey('años')}` : 'No hay datos'}
              </p>
              <p className={styles.text} title="city">
                {city}
              </p>
              {last_scholarship_data?.educational_type ? (
                <>
                  <h4 className={`${styles.title} ${styles.workingTitle}`}>
                    <strong>{tKey('¿Estudia?')}</strong> {tKey('Sí')}
                  </h4>
                  <ul className={`${styles.list}`}>
                    <li className={styles.text} title="academic_year">
                      {last_scholarship_data?.academic_year}
                    </li>
                    <li className={styles.text} title="educational_type">
                      {last_scholarship_data?.educational_type}
                    </li>
                    <li className={styles.text} title="course">
                      {last_scholarship_data?.course}
                    </li>
                    {loading ? (
                      <li>
                        <Loading big />
                      </li>
                    ) : null}
                  </ul>
                </>
              ) : null}
              {last_scholarship_data?.company_name ? (
                <>
                  <h4 className={styles.title}>
                    <strong>{tKey('¿Trabaja?')}</strong> {tKey('Sí')}
                  </h4>
                  <ul className={`${styles.list}`}>
                    <li className={styles.name} title="company_name">
                      {last_scholarship_data?.company_name}
                    </li>
                    <li className={styles.name} title="role">
                      {last_scholarship_data?.company_role}
                    </li>
                  </ul>
                </>
              ) : null}
              <ArrowForward className={styles.arrow} />
            </div>
          </li>
        );
      })}
    </React.Fragment>
  );
};

export default DetailsZing;
