import { InternalProjectId, ParticipantId } from '../../../redux/internal-projects/participants/delete/Participant';
import { apiBaseUrl, apiCall } from '../../api';

const baseURL = apiBaseUrl;

export const unsubscribeParticipantFromApi = async (
  id: InternalProjectId,
  participantId: ParticipantId,
): Promise<string> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  const {
    data: { unsubscribed_at },
  } = await apiCall<{ data: { unsubscribed_at: string } }>(
    `${baseURL}/internal_project/${id}/participants/unsubscribe/${participantId}`,
    init,
  );
  return unsubscribed_at;
};
