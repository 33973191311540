import { AuthUserDataLogin } from '../redux/auth/definitions';
import { apiBaseUrl, apiCall } from './api';

const baseURL = apiBaseUrl;

export const accountActivated = async (token: string): Promise<{ data: AuthUserDataLogin }> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(token),
  };

  return await apiCall<{ data: AuthUserDataLogin }>(`${baseURL}/user/verify/${token}`, init);
};
