import React from 'react';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { tScholarshipKey } from '../../../helpers/translate';
import PaymentAdjustmentForm from './PaymentAdjustmentForm';

interface PaymentAdjustmentFormModalProps {
  open: boolean;
  isEntity?: boolean;
  isInstitute?: boolean;
  scholarship_id: number;
  handleClose: () => void;
}

const PaymentAdjustmentFormModal: React.FC<PaymentAdjustmentFormModalProps> = ({
  scholarship_id,
  open,
  isEntity,
  isInstitute,
  handleClose,
}) => {
  return (
    <TransitionModal
      view={open}
      handleClose={() => {
        handleClose();
      }}
      title={tScholarshipKey('Ajustes de pago')}
      bodyModal=" "
      ultraWidth
    >
      <PaymentAdjustmentForm
        handleClose={() => {
          handleClose();
        }}
        isInstitute={isInstitute}
        isEntity={isEntity}
        scholarshipID={scholarship_id}
      />
    </TransitionModal>
  );
};

export default PaymentAdjustmentFormModal;
