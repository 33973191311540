import { ButtonProps } from '@material-ui/core';
import { AddCircle, Check, Payment, Save, Send, Warning } from '@material-ui/icons';
import CheckBox from '@material-ui/icons/CheckBox';
import React from 'react';
import { tKey } from '../../../../helpers/translate';
import EditIcon from '@material-ui/icons/Edit';

const buttonOnSave: ButtonProps = {
  children: tKey('GUARDAR'),
  startIcon: <Save />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnSend: ButtonProps = {
  children: tKey('ENVIAR'),
  startIcon: <Send />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnAccept: ButtonProps = {
  children: tKey('ACEPTAR'),
  startIcon: <Check />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnReject: ButtonProps = {
  children: tKey('RECHAZAR'),
  startIcon: <Warning />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnMoveBack: ButtonProps = {
  children: tKey('RETROCEDER'),
  startIcon: <Warning />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnReview: ButtonProps = {
  children: tKey('REVISAR'),
  startIcon: <Warning />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnConfirm: ButtonProps = {
  children: tKey('CONFIRMAR'),
  startIcon: <Check />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnCreate: ButtonProps = {
  children: tKey('NUEVA FACTURA'),
  startIcon: <AddCircle />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnPay: ButtonProps = {
  children: tKey('A PAGAR'),
  startIcon: <Payment />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonOnTracking: ButtonProps = {
  children: tKey('TRACKING'),
  startIcon: <CheckBox />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonAuthorize: ButtonProps = {
  children: tKey('AUTORIZAR'),
  startIcon: <Check />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

const buttonModify: ButtonProps = {
  children: tKey('MODIFICAR'),
  startIcon: <EditIcon />,
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
  hidden: true,
};

export interface InvoiceButtons {
  buttonOnSave: ButtonProps;
  buttonOnSend: ButtonProps;
  buttonOnAccept: ButtonProps;
  buttonOnReject: ButtonProps;
  buttonOnMoveBack: ButtonProps;
  buttonOnReview: ButtonProps;
  buttonOnConfirm: ButtonProps;
  buttonOnCreate: ButtonProps;
  buttonOnPay: ButtonProps;
  buttonOnTracking: ButtonProps;
  buttonAuthorize: ButtonProps;
  buttonModify: ButtonProps;
}

export const buttonsInvoice = {
  buttonOnSave,
  buttonOnSend,
  buttonOnAccept,
  buttonOnReject,
  buttonOnMoveBack,
  buttonOnReview,
  buttonOnConfirm,
  buttonOnCreate,
  buttonOnPay,
  buttonOnTracking,
  buttonAuthorize,
  buttonModify,
};

export type ButtonsInvoiceType = typeof buttonsInvoice;
