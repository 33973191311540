import { Drawer, Fab } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import defaultImage from '../../../images/default-avatar.png';
import { applicationShowMenu } from '../../../redux/application/actions';
import { ApplicationDispatch } from '../../../redux/application/definitions';
import { selectShowMenu } from '../../../redux/application/selectors';
import { getUserProfileAvatar } from '../../../redux/auth/actions';
import { AuthDispatch } from '../../../redux/auth/definitions';
import { selectUserAvatar } from '../../../redux/auth/selectors';
import { AppState } from '../../../redux/root-reducer';
import LeftSideMenu from '../Menus/LeftSideMenu/LeftSide';
import styles from './fabButtons.module.scss';

interface FabButtonProfile {
  avatar: string;
  showMenu: boolean;
  getAvatar: () => void;
  applicationShowMenu: (show: boolean) => void;
}

const FabButtonProfile: React.FC<FabButtonProfile> = ({ avatar, showMenu, getAvatar, applicationShowMenu }) => {
  useEffect(() => {
    if (!avatar) {
      getAvatar();
    }
  }, [avatar, getAvatar]);

  return (
    <React.Fragment>
      <Fab color="secondary" onClick={() => applicationShowMenu(true)} className={`${styles.top} ${styles.left}`}>
        <img className={styles.avatar} src={avatar || defaultImage} alt="profile" />
      </Fab>

      <Drawer key="profile" open={showMenu} onClose={() => applicationShowMenu(false)} anchor="left">
        <LeftSideMenu />
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  avatar: selectUserAvatar(state),
  showMenu: selectShowMenu(state),
});

const mapDispatchToProps = (dispatch: AuthDispatch | ApplicationDispatch | any) => ({
  getAvatar: (): void => dispatch(getUserProfileAvatar()),
  applicationShowMenu: (show: boolean): void => dispatch(applicationShowMenu(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FabButtonProfile);
