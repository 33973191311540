import { apiBaseUrl, apiCall } from '../api';
import { Justification } from '../../redux/scholarship/justifications/Justification';

const baseURL = apiBaseUrl;

export const getScholarshipJustificationsFromApi = async (scholarshipId: number): Promise<Justification[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: Justification[] }>(
    `${baseURL}/scholarships/${scholarshipId}/justifications`,
    init,
  );
  return data;
};

export const createScholarshipJustificationsFromApi = async (justification: Justification): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(justification),
  };
  await apiCall(`${baseURL}/scholarships/${justification.scholarship_id}/justifications`, init);
};

export const removeScholarshipJustificationsFromApi = async (justification: Justification): Promise<void> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  return apiCall(`${baseURL}/scholarships/${justification.scholarship_id}/justifications/${justification.id}`, init);
};
