export enum SignatureDocumentDataConsts {
  SIGNATURE_DOCUMENTS_GET = 'SIGNATURE:DOCUMENTS:GET',
  SIGNATURE_DOCUMENTS_GET_OK = 'SIGNATURE:DOCUMENTS:GET:OK',
  SIGNATURE_DOCUMENTS_GET_KO = 'SIGNATURE:DOCUMENTS:GET:KO',
  UPLOAD_SIGNATURE_DOCUMENT = 'UPLOAD:SIGNATURE:DOCUMENT',
  UPLOAD_SIGNATURE_DOCUMENT_OK = 'UPLOAD:SIGNATURE:DOCUMENT:OK',
  UPLOAD_SIGNATURE_DOCUMENT_KO = 'UPLOAD:SIGNATURE:DOCUMENT:KO',
  REMOVE_SIGNATURE_DOCUMENT = 'REMOVE:SIGNATURE:DOCUMENT',
  REMOVE_SIGNATURE_DOCUMENT_OK = 'REMOVE:SIGNATURE:DOCUMENT:OK',
  REMOVE_SIGNATURE_DOCUMENT_KO = 'REMOVE:SIGNATURE:DOCUMENT:KO',
  DOWNLOAD_SIGNATURE_DOCUMENT = 'DOWNLOAD:SIGNATURE:DOCUMENT',
  DOWNLOAD_SIGNATURE_DOCUMENT_OK = 'DOWNLOAD:SIGNATURE:DOCUMENT:OK',
  DOWNLOAD_SIGNATURE_DOCUMENT_KO = 'DOWNLOAD:SIGNATURE:DOCUMENT:KO',
  SEND_SIGNATURE_DOCUMENT = 'SEND:SIGNATURE:DOCUMENT',
  SEND_SIGNATURE_DOCUMENT_OK = 'SEND:SIGNATURE:DOCUMENT:OK',
  SEND_SIGNATURE_DOCUMENT_KO = 'SEND:SIGNATURE:DOCUMENT:KO',
  CANCEL_SIGNATURE_DOCUMENT = 'CANCEL:SIGNATURE:DOCUMENT',
  CANCEL_SIGNATURE_DOCUMENT_OK = 'CANCEL:SIGNATURE:DOCUMENT:OK',
  CANCEL_SIGNATURE_DOCUMENT_KO = 'CANCEL:SIGNATURE:DOCUMENT:KO',
}
