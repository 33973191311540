import React, { useEffect, useState } from 'react';
import styles from './poblacionCustomField.module.scss';
import { CustomFieldElement } from '../../../../redux/kpis/definitions';
import FncSelectController from '../../../Forms/FncSelect/FncSelectController';
import { OptionSelect } from '../../../Forms/FncSelect/FncSelect';
import { Control, FieldErrors } from 'react-hook-form/dist/types';
import { ObjectSchema } from 'yup';
import { findZipCode } from '../../../../api/ZipCodes/zipcode';
import FncInputText from '../../../Forms/FncInputs/FncInputText';
import { tProjectKey } from '../../../../helpers/translate';

interface PoblacionProps {
  customField: CustomFieldElement;
  disabled?: boolean;
  control: Control<any>;
  errors: FieldErrors<any>;
  schema: ObjectSchema<any>;
  setValue?: (name: string, value: any) => void;
}

const PoblacionCustomField: React.FC<PoblacionProps> = ({
  customField,
  disabled,
  control,
  errors,
  schema,
  setValue,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [zipCode, setZipCode] = useState<string>('');
  const [poblaciones, setPoblaciones] = useState<OptionSelect[]>([]);
  const [prevZipCode, setPrevZipCode] = useState<string>('');

  const placeholder = () => {
    return customField.value && zipCode.length === 0
      ? customField.value?.toString()
      : isLoading
      ? tProjectKey('custom_field_loading')
      : isError
      ? tProjectKey('custom_field_poblacion_error')
      : tProjectKey('custom_field_poblacion_placeholder');
  };

  useEffect(() => {
    if (zipCode.length === 0) {
      setIsLoading(false);
      setPoblaciones([]);
      setIsError(false);
    }

    if (zipCode.length === 5 && (prevZipCode === '' || zipCode !== prevZipCode)) {
      setIsLoading(true);
      findZipCode(zipCode).then(data => {
        setIsError(data.town === null);
        const selectOptions: OptionSelect[] = (data.towns ?? [data.town]).map(poblacion => ({
          value: poblacion,
          label: poblacion,
        }));
        setPoblaciones(selectOptions);
        setPrevZipCode(zipCode);
        setIsLoading(false);

        if (setValue && selectOptions.length === 1) {
          setValue(customField.name, selectOptions[0].value);
        }
      });
    }
  }, [zipCode, prevZipCode, setValue, customField.name]);

  function handleChange(event: any) {
    setZipCode(event.target.value);
  }

  return (
    <div key={customField.id} className={styles.poblacionContainer}>
      <FncInputText
        customClass={`${styles.zipCode}`}
        disabled={disabled}
        label={`Código Postal`}
        maxLength={5}
        onChange={handleChange}
        type={`number`}
      />
      <FncSelectController
        key={customField.id}
        name={customField.name}
        schema={schema}
        control={control}
        errors={errors}
        label={customField.label}
        disabled={isLoading || isError ? true : disabled || (!disabled && customField.read_only)}
        placeholder={placeholder()}
        options={poblaciones}
        customClass={`${styles.city}`}
        defaultValue={customField.value?.toString()}
      />
    </div>
  );
};

export default PoblacionCustomField;
