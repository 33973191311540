import { ApplicationConsts } from './action_types';
import { ApplicationAction, initialState } from './definitions';

export type ApplicationState = typeof initialState;

const AppStateReducer = (state = initialState, action: ApplicationAction): ApplicationState => {
  switch (action.type) {
    case ApplicationConsts.SHOW_MENU:
      return {
        ...state,
        showMenu: action.show,
        showMenuMobile: false,
      };
    case ApplicationConsts.SHOW_MENU_MOBILE:
      return {
        ...state,
        showMenuMobile: action.show,
        showMenu: false,
      };
  }
  return state;
};

export default AppStateReducer;
