import React from 'react';
import styles from './selectAutocompleteItem.module.scss';

interface SelectAutocompleteItemProps {
  active: boolean;
  text: string;
  onClick: () => void;
}

const SelectAutocompleteItem: React.FC<SelectAutocompleteItemProps> = ({ active, text, onClick }) => {
  return (
    <li className={styles.root} onClick={onClick}>
      {text}
    </li>
  );
};

export default SelectAutocompleteItem;
