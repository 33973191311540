import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import React from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import { tErrorKey } from '../../helpers/translate';
import requiredStyle from '../../helpers/validator.module.scss';
import styles from './selectInput.module.scss';
import { BaseSelectInputProps } from './types';

interface SelectProps extends BaseSelectInputProps {
  name: string;
  error?: FieldError | any;
  label: React.ReactNode;
  setValue?: (name: any, value: any, shouldValidate?: boolean) => void | Promise<boolean>;
  MenuProps?: {};
  validator?: boolean;
}

const SelectInput: React.FC<SelectProps> = ({
  name,
  value,
  error,
  label,
  children,
  register,
  handleChange,
  disabled,
  size,
  onlyRead,
  MenuProps,
  validator,
}) => (
  <FormControl
    key={name}
    error={!!error}
    className={[size && styles[`size_${size}`], disabled ? styles[`disabledColor`] : undefined].join(' ')}
    variant="outlined"
    classes={{
      root: !!validator ? requiredStyle.required : '',
    }}
  >
    <InputLabel id={name}>{label}</InputLabel>
    <Select
      MenuProps={MenuProps}
      id={name}
      inputRef={() => register({ name })}
      name={name}
      onChange={e => handleChange(name, e)}
      value={value || ''}
      error={!!error}
      disabled={disabled}
      inputProps={{ readOnly: onlyRead ? true : false }}
      label={label}
    >
      {children}
    </Select>
    {!!error && <FormHelperText>{tErrorKey(error?.message as string)}</FormHelperText>}
  </FormControl>
);

export default SelectInput;
