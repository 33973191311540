import { Checkbox, ButtonProps } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { Save, CheckBox, CheckBoxOutlineBlank, Delete } from '@material-ui/icons';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tKey } from '../../../helpers/translate';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import {
  getServiceRequestedData,
  removeServiceRequested,
  setServiceAssistance,
  setServiceRequested,
  updateServiceAssistanceData,
} from '../../../redux/service/actions';
import { ServiceData, ServiceDataDispatch, ServiceRequestData } from '../../../redux/service/definitions';
import { selectLoading, selectServiceData, selectServiceRequestData } from '../../../redux/service/selectors';
import SubmenuService from '../CreateService/SubmenuService';
import styles from './service.module.scss';

const AddNewApplication = React.lazy(() => import('../Modal/CreateApplication/AddNewApplication'));

interface ServiceApplicantsProps extends RouteComponentProps<{ id: string }> {
  service: ServiceData;
  loading: boolean;
  serviceRequests: ServiceRequestData[];
  removeRequested: (requestedId: number) => void;
  setServiceAssistance: (assistance: string) => void;
  updateServiceAssistanceData: (data: ServiceRequestData[], id: number) => void;
  userCan: (permission: permissionType) => boolean | undefined;
  getServiceRequestedData: (id: number) => void;
  setServiceRequestedData: (key: keyof ServiceRequestData, value: string | number | boolean, id: number) => void;
}

const UnConnectedServiceApplicants: React.FC<ServiceApplicantsProps> = ({
  service,
  serviceRequests,
  loading,
  match,
  removeRequested,
  userCan,
  setServiceAssistance,
  updateServiceAssistanceData,
  getServiceRequestedData,
  setServiceRequestedData,
}) => {
  const history = useHistory();

  const {
    params: { id },
  } = match;

  useEffect(() => {
    getServiceRequestedData(+id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [create, setCreate] = useState({
    view: false,
  });
  const action_pma = userCan('action_pma');
  const action_dir = userCan('action_dir');
  const goToNew = () => {
    setCreate({ view: true });
  };
  const columnList = [
    { title: tKey('Nombre'), field: 'name' },
    { title: tKey('Apellidos'), field: 'surname' },
    { title: tKey('Identificación'), field: 'document_number' },
    { title: tKey('Fecha de nacimiento'), field: 'birth_date' },
    { title: tKey('Ciudad'), field: 'city' },
    { title: tKey('Entidad'), field: 'entity' },
    { title: tKey('Instituto'), field: 'academy' },
    { title: tKey('Teléfono'), field: 'phone' },
    { title: tKey('Email'), field: 'email' },
    { title: tKey('LOPD'), field: 'lopd_label' },
    { title: tKey('Manual'), field: 'manual_label' },
    { title: tKey('Fecha alta'), field: 'discharge_date' },
    { title: tKey('Hora alta'), field: 'discharge_hour' },
    {
      title: tKey('Asistencia'),
      field: 'assistance',
      disableClick: true,
      render: (rowData: ServiceRequestData) => (
        <>
          <span>{rowData.assistance}</span>
          <Checkbox
            className={styles.list__check}
            checked={rowData.assistance !== 'No'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setServiceRequestedData('assistance', e.target.checked ? 'Sí' : 'No', rowData.id)
            }
          />
        </>
      ),
    },
  ];

  const actionIcons = {
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };
  const tableData: MaterialTableProps<ServiceRequestData> = {
    title: tKey('Listado de solicitantes'),
    columns: columnList,
    data: serviceRequests,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, tKey('Listado de solicitantes'));
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    actions: [
      (rowData: ServiceRequestData) => ({
        icon: actionIcons.Delete,
        onClick: () => removeRequested(rowData.id),
        tooltip: 'borrar',
      }),
    ],
  };

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('ACTUALIZAR DATOS'),
      onClick: () => updateServiceAssistanceData(serviceRequests, +id),
      startIcon: <Save />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
    },
    {
      children: tKey('MARCAR TODO'),
      onClick: () => setServiceAssistance('Sí'),
      startIcon: <CheckBox />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
    },
    {
      children: tKey('DESMARCAR TODO'),
      onClick: () => setServiceAssistance('No'),
      startIcon: <CheckBoxOutlineBlank />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
    },
  ];

  const goUserDetail = (rowData: ServiceRequestData) => {
    const { user_id } = rowData;
    if (user_id)
      history.push({
        pathname: `/usuario/detalles/${user_id}`,
        state: { id: user_id },
      });
  };

  return (
    <LayoutForm
      leftSubmenu={<SubmenuService service={service} current_section="listado_solicitantes" service_id={id} />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} />}
    >
      <TableWithEdit
        tableData={{ ...tableData, isLoading: loading }}
        permission={action_pma || action_dir}
        optionsNew={{ newButtonText: tKey('Nuevos solicitantes'), onNew: goToNew }}
        onRowClick={goUserDetail}
        filtering
      />
      <Suspense fallback={<></>}>
        {create.view && (
          <AddNewApplication
            service_id={+id}
            view={create.view}
            title={tKey('Suscribir nuevo alum')}
            inputLabel={tKey('Selecciona alumno')}
            buttonLabel={tKey('Suscribir')}
            handleClose={() => setCreate({ view: false })}
            optionLabel="info"
          />
        )}
      </Suspense>
    </LayoutForm>
  );
};

const ServiceApplicants = connect(
  (state: AppState) => ({
    service: selectServiceData(state),
    loading: selectLoading(state),
    serviceRequests: selectServiceRequestData(state),
    userCan: selectUserCan(state),
  }),
  (dispatch: ServiceDataDispatch) => ({
    getServiceRequestedData: (id: number): void => dispatch(getServiceRequestedData(id)),
    setServiceRequestedData: (key: keyof ServiceRequestData, value: string | number | boolean, id: number): void =>
      dispatch(setServiceRequested(key, value, id)),
    setServiceAssistance: (assistance: string): void => dispatch(setServiceAssistance(assistance)),
    updateServiceAssistanceData: (data: ServiceRequestData[], id: number): void =>
      dispatch(updateServiceAssistanceData(data, id)),
    removeRequested: (requestedId: number): void => dispatch(removeServiceRequested(requestedId)),
  }),
)(UnConnectedServiceApplicants);

export default ServiceApplicants;
