import assertUnreachable from '../../helpers/assertUnreachable';
import { CalendarInterviewsConst } from './action_types';
import { CalendarScholarshipAction, initialState, StudentCalendarState } from './definitions';

const calendarInterviewReducer = (state = initialState, action: CalendarScholarshipAction): StudentCalendarState => {
  switch (action.type) {
    case CalendarInterviewsConst.GET_INTERVIEWS_DATES:
    case CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS:
      return {
        ...state,
        loadingDates: true,
        loadingTimes: true,
        error: null,
      };

    case CalendarInterviewsConst.GET_INTERVIEWS_DATES_OK:
      const { datesTime } = action;
      const selectedDate = datesTime.dates.find(ele => !!ele.selected)!;
      return {
        ...state,
        loadingDates: false,
        loadingTimes: false,
        error: null,
        datesTime: datesTime,
        selectedDate,
      };
    case CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS_OK:
      return {
        ...state,
        loadingDates: false,
        loadingTimes: false,
        error: null,
      };

    case CalendarInterviewsConst.GET_INTERVIEWS_DATES_KO:
    case CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS_KO:
      return {
        ...state,
        loadingDates: false,
        loadingTimes: false,
        error: action.error,
      };
  }
  assertUnreachable(action);
  return state;
};

export default calendarInterviewReducer;
