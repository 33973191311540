import { FamilyIncome } from '../FamilyIncome';
import { CreateFamilyIncomeConst } from './action_types';
import { AddFamilyIncomeAction, AddFamilyIncomeActionKo, AddFamilyIncomeActionOk } from './definitions';

export function addNewFamilyIncome(income: FamilyIncome): AddFamilyIncomeAction {
  return {
    type: CreateFamilyIncomeConst.ADD_FAMILY_INCOME,
    income,
  };
}

export function addNewFamilyIncomeOk(income: FamilyIncome): AddFamilyIncomeActionOk {
  return {
    type: CreateFamilyIncomeConst.ADD_FAMILY_INCOME_OK,
    income,
  };
}

export function addNewFamilyIncomeKo(error: string): AddFamilyIncomeActionKo {
  return {
    type: CreateFamilyIncomeConst.ADD_FAMILY_INCOME_KO,
    error,
  };
}
