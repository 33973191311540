import { put, putResolve, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import { getTranslationKeys } from '../../api/translationKeys';
import {
  addUserRoleFromapi,
  changeUserLanguageFromApi,
  createEducatorApi,
  createUserApi,
  deleteDocumentation,
  deleteEducatorDocumentation,
  disableUserbyId,
  downloadDocumentation,
  getDocumentation,
  getDocumentationEducator,
  getForeingAvatar,
  getProfile,
  getRolePermissionsFromApi,
  getUserData,
  getUserRolesFromApi,
  getUsers,
  linkUserWithAcademy,
  rejectEducator,
  removeUserRoleFromapi,
  setForeingAvatar,
  setUserActiveRole,
  setUserPermissionFromApi,
  signLopd,
  updateProfile,
  updateProfileTableUser,
  uploadDocumentation,
  uploadDocumentationEducator,
  userChangePassword,
  userMetadataEntities,
  validateEducator,
} from '../../api/user';
import assertUnreachable from '../../helpers/assertUnreachable';
import errorMessage from '../../helpers/errorMessage';
import { tKey } from '../../helpers/translate';
import { showSnackbar } from '../FeedbackAPI/actions';
import { updateUserState } from '../auth/actions';
import { getGenericMetadata } from '../metadata/actions';
import { permissionsList } from '../permissions/actions';
import { getCategoriesTkeysOk } from '../translations/actions';
import { UserDataConsts } from './action_types';
import {
  acceptUserEducatorKo,
  acceptUserEducatorOk,
  getUserDocumentation as actionGetUserDocumentation,
  confirmUserActiveRoleOk,
  createUserEducatorOk,
  createUserOk,
  disableUserOk,
  downloadDocumentOk,
  getAvatarByIdKo,
  getAvatarByIdOk,
  getDocumentOk,
  getProfileOk,
  getUserDataOk,
  getUserDocumentationKo,
  getUserDocumentationOk,
  getUserEntitiesMetadataKo,
  getUserEntitiesMetadataOk,
  getUserRolesConfigOk,
  getUsersKo,
  getUsersOk,
  initNewUserOk,
  rejectUserEducatorKo,
  rejectUserEducatorOk,
  removeDocumentOk,
  removeEducatorDocumentOk,
  setAvatarByIdKo,
  setAvatarByIdOk,
  setUserPermissionOk,
  signLopdOk,
  updateProfileEducatorOk,
  updateProfileOk,
  updateProfileUserTableOk,
  uploadDocumentOk,
  uploadEducatorDocumentOk,
  userAddRoleOk,
  userChangeLanguageOk,
  userGenericKo,
  userLinkAcademyKo,
  userLinkAcademyOk,
  userRemoveRoleOk,
  userRoleActiveOk,
  userValidationErrors,
} from './actions';
import {
  AcceptUserEducatorAction,
  ChangePasswordAction,
  ConfirmUserActiveRoleAction,
  CreateUserAction,
  CreateUserEducatorAction,
  DisableUserAction,
  DownloadDocumentAction,
  GetAvatarUserByIdAction,
  GetDocumentAction,
  GetUserDataAction,
  GetUserDocumentationAction,
  GetUserEntitiesMetadata,
  GetUserRolesConfigAction,
  GetUsersAction,
  InitNewUserAction,
  RejectUserEducatorAction,
  RemoveDocumentAction,
  RemoveEducatorDocumentAction,
  SetAvatarUserByIdAction,
  SetUserPermissionAction,
  SignLopdAction,
  UpdateProfileEducator,
  UpdateProfileUserTableAction,
  UpdateUserProfileAction,
  UploadDocumentAction,
  UploadEducatorDocumentAction,
  UserAddRoleAction,
  UserChangeLanguageAction,
  UserLinkAcademyAction,
  UserProfileAction,
  UserRemoveRoleAction,
  UserRoleActiveAction,
} from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getProfileFromSaga(): Generator<any, void, any> {
  try {
    const info = (yield getProfile()) as Await<ReturnType<typeof getProfile>>;
    yield put(getProfileOk(info.data));
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getUserDataFromSaga(action: GetUserDataAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const { data } = (yield getUserData(id)) as Await<ReturnType<typeof getUserData>>;
    yield put(actionGetUserDocumentation(id));
    yield put(getUserDataOk(data));
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

function* getUsersFromSaga(): Generator<any, void, any> {
  try {
    const data = yield getUsers();
    yield put(getUsersOk(data));
  } catch (e) {
    yield put(getUsersKo(errorMessage(e)));
  }
}

function* getUserAvatarFromSaga(action: GetAvatarUserByIdAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const { data } = yield getForeingAvatar(id);
    yield put(getAvatarByIdOk(data));
  } catch (e) {
    yield put(getAvatarByIdKo(errorMessage(e)));
  }
}

function* signLopdFromSaga(action: SignLopdAction): Generator<any, void, any> {
  try {
    const result = (yield signLopd(action.data)) as Await<ReturnType<typeof signLopd>>;
    if (result.type === 'ok') {
      yield put(signLopdOk(result.value.data));
      yield put(permissionsList());
      yield getProfileFromSaga();
      yield put(updateUserState(result.value.data.state, result.value.data.phone));
    }
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(userGenericKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* updateProfileFromSaga(action: UpdateUserProfileAction): Generator<any, void, any> {
  try {
    const result = (yield updateProfile(action.data)) as Await<ReturnType<typeof updateProfile>>;
    switch (result.type) {
      case 'ok':
        const info = result.value;

        yield put(updateProfileOk(info));
        yield put(updateUserState(info.data.state, info.data.phone));
        yield put(showSnackbar('Se han modificado los datos correctamente', 'success', undefined, 1000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(userValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(userGenericKo(e));
  }
}

function* updateProfileEducatorFromSaga(action: UpdateProfileEducator): Generator<any, void, any> {
  try {
    const result = (yield updateProfile(action.data)) as Await<ReturnType<typeof updateProfile>>;
    switch (result.type) {
      case 'ok':
        const info = result.value;
        yield put(updateProfileEducatorOk(info));
        yield put(updateUserState(info.data.state, info.data.phone));
        yield put(showSnackbar('Se han modificado los datos correctamente', 'success', '/pendiente-educador'));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(userValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar('No se han podido modificar los datos', 'error'));
    yield put(userGenericKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* uploadDocumentFromSaga(action: UploadDocumentAction): Generator<any, void, any> {
  try {
    const { documentation_id, data, force_add } = action;
    const documents = yield uploadDocumentation(documentation_id, data, force_add);
    yield put(uploadDocumentOk(documents));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
  }
}

function* uploadDocumentEducatorFromSaga(action: UploadEducatorDocumentAction): Generator<any, void, any> {
  try {
    const { data, documentation_id, fakeMultiUpload } = action;
    const documents = yield uploadDocumentationEducator(data);
    yield put(uploadEducatorDocumentOk(documents, documentation_id, fakeMultiUpload));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* removeDocumentFromSaga(action: RemoveDocumentAction): Generator<any, void, any> {
  try {
    const { file_id } = action;
    const documents = yield deleteDocumentation(file_id);
    yield put(removeDocumentOk(documents));
    yield put(showSnackbar('El documento se ha eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('Ha ocurrido algún problema al eliminar el documento', 'error', undefined, 1500));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* downloadDocumentFromSaga(action: DownloadDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, name } = action;
    const data = yield downloadDocumentation(file_id);
    yield put(downloadDocumentOk(data, name));
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('Ha ocurrido algún problema al descargar el documento', 'error', undefined, 1500));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getDocumentFromSaga(action: GetDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, name } = action;
    const data = yield downloadDocumentation(file_id);
    yield put(getDocumentOk(data, name));
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* updateProfileUserTable(action: UpdateProfileUserTableAction): Generator<any, void, any> {
  try {
    const result = (yield updateProfileTableUser(action.data, action.id)) as Await<
      ReturnType<typeof updateProfileTableUser>
    >;
    switch (result.type) {
      case 'ok':
        const info = result.value;
        yield put(updateProfileUserTableOk(info));
        yield put(showSnackbar('Usuario editado correctamente', 'success'));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(userValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('El usuario no se ha podido editar', 'error'));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getUserDocumentation(action: GetUserDocumentationAction): Generator<any, void, any> {
  try {
    const info = (yield getDocumentation(action.id)) as Await<ReturnType<typeof getDocumentation>>;
    yield put(getUserDocumentationOk(info));
  } catch (e) {
    yield put(getUserDocumentationKo(e));
  }
}

// ----------- CREATE USER

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* createUserSagas(action: CreateUserAction): Generator<any, void, any> {
  try {
    const result = (yield createUserApi(action.data)) as Await<ReturnType<typeof createUserApi>>;
    switch (result.type) {
      case 'ok':
        yield put(createUserOk());
        yield put(showSnackbar('Usuario creado correctamente', 'success'));
        return;

      case 'validation-error':
        const error = result.value;
        yield put(userValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('El usuario no se ha podido crear', 'error'));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* createUserEducatorSagas(action: CreateUserEducatorAction): Generator<any, void, any> {
  try {
    const result = (yield createEducatorApi(action.data)) as Await<ReturnType<typeof createUserApi>>;

    switch (result.type) {
      case 'ok':
        yield put(createUserEducatorOk());
        yield put(showSnackbar('Usuario creado correctamente', 'success'));
        return;

      case 'validation-error':
        const error = result.value;
        yield put(userValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('El usuario no se ha podido crear', 'error'));
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* initUserSagas(): Generator<any, void, any> {
  try {
    const documentation = (yield getDocumentationEducator()) as Await<ReturnType<typeof getDocumentationEducator>>;
    yield put(initNewUserOk(documentation));
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* changePassword(action: ChangePasswordAction): Generator<any, void, any> {
  try {
    const { data } = action;

    const result = (yield userChangePassword(data)) as Await<ReturnType<typeof userChangePassword>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield userChangePassword(data);
        yield put(showSnackbar('Contraseña cambiada correctamente', 'success'));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(userValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(showSnackbar('No se ha podido cambiar la contraseña', 'error'));
    yield put(userGenericKo(e));
  }
}

function* getUserEntitiesMetadataFromSaga(action: GetUserEntitiesMetadata): Generator<any, void, any> {
  try {
    const { data: entitiesMetadata } = yield userMetadataEntities(action.entity_type);
    yield put(getUserEntitiesMetadataOk(entitiesMetadata));
  } catch (e) {
    yield put(getUserEntitiesMetadataKo(errorMessage(e)));
  }
}

function* acceptUserEducatorFromSaga(action: AcceptUserEducatorAction): Generator<any, void, any> {
  try {
    const info = yield validateEducator(action.id);
    yield put(acceptUserEducatorOk(info));
    yield put(showSnackbar('Educador Validado Correctamente', 'success', '/usuarios', 2000));
  } catch (e) {
    yield put(showSnackbar('No se ha podido cambiar la contraseña', 'error'));
    yield put(acceptUserEducatorKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* rejectUserEducatorFromSaga(action: RejectUserEducatorAction): Generator<any, void, any> {
  try {
    const info = yield rejectEducator(action.id);
    yield put(rejectUserEducatorOk(info));
    yield put(showSnackbar('Educador Rechazado Correctamente', 'success', '/usuarios', 2000));
  } catch (e) {
    yield put(showSnackbar('No se ha podido cambiar la contraseña', 'error'));
    yield put(rejectUserEducatorKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* setUserAvatarSaga(action: SetAvatarUserByIdAction): Generator<any, void, any> {
  try {
    yield setForeingAvatar(action.id, action.userAvatar);
    yield put(setAvatarByIdOk(action.userAvatar));
  } catch (e) {
    yield put(setAvatarByIdKo(errorMessage(e)));
  }
}

function* disableUser(action: DisableUserAction): Generator<any, void, any> {
  try {
    yield disableUserbyId(action.id);
    yield put(disableUserOk());
    yield put(showSnackbar(`Usuario habilitado/deshabilitado correctamente`, 'success', '/usuarios', 100));
  } catch (e) {
    yield put(showSnackbar(`Error al habilitar/deshabilitar el usuario`, 'error', undefined, 100));
    yield put(userGenericKo(e));
  }
}

function* removeEducatorDocumentFromSaga(action: RemoveEducatorDocumentAction): Generator<any, void, any> {
  try {
    const { file_id } = action;
    yield deleteEducatorDocumentation(file_id);

    yield put(removeEducatorDocumentOk(file_id));
    yield put(showSnackbar('El documento se ha eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar('Ha ocurrido algún problema al eliminar el documento', 'error', undefined, 1500));
  }
}

function* userLinkAcademyFromSaga(action: UserLinkAcademyAction): Generator<any, void, any> {
  try {
    const { userId } = action;
    yield linkUserWithAcademy(userId);
    yield put(userLinkAcademyOk());
    yield put(showSnackbar(tKey('u.vincular_success'), 'success', '/usuarios/academy', 1500));
  } catch (e) {
    yield put(userLinkAcademyKo(e));
    yield put(showSnackbar(tKey(errorMessage(e) || 'u.vincular_error'), 'warning', undefined, 2000));
  }
}

function* getUserRolesConfigActionFromSaga(action: GetUserRolesConfigAction): Generator<any, void, any> {
  try {
    const { user_id } = action;
    const roleConfig = yield getUserRolesFromApi(user_id);
    yield put(getUserRolesConfigOk(roleConfig));
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

function* confirmUserActiveRoleActionFromSaga(action: ConfirmUserActiveRoleAction): Generator<any, void, any> {
  try {
    const { user_id, role_id } = action;
    yield setUserActiveRole(user_id, role_id);
    yield put(confirmUserActiveRoleOk());
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 2000));
  }
}

function* addUserRoleFromSaga(action: UserAddRoleAction): Generator<any, void, any> {
  try {
    const { user_id, role } = action;
    const ok = yield addUserRoleFromapi(user_id, role.id);
    if (ok) {
      yield put(userAddRoleOk(role));
    }
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

function* removeUserRoleFromSaga(action: UserRemoveRoleAction): Generator<any, void, any> {
  try {
    const { user_id, role_id } = action;
    const ok = yield removeUserRoleFromapi(user_id, role_id);
    if (ok) {
      yield put(userRemoveRoleOk(role_id));
    }
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

function* setUserActiveRoleFromSaga(action: UserRoleActiveAction): Generator<any, void, any> {
  try {
    const { is_active, role_id } = action;
    const permissions = yield getRolePermissionsFromApi(role_id);
    yield put(userRoleActiveOk(role_id, is_active, permissions));
  } catch (e) {
    yield put(userGenericKo(e));
  }
}

function* setUserPermissionFromSaga(action: SetUserPermissionAction): Generator<any, void, any> {
  try {
    const { user_id, permission_id, value } = action;
    const ok = yield setUserPermissionFromApi(user_id, permission_id, value);
    if (ok) {
      yield put(setUserPermissionOk(permission_id, value));
    }
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 2000));
  }
}

function* changeUserLanguageFromSaga(action: UserChangeLanguageAction): Generator<any, void, any> {
  try {
    const { language } = action;
    const ok = yield changeUserLanguageFromApi(language);
    if (ok) {
      const tKeysData = (yield getTranslationKeys(language)) as Await<ReturnType<typeof getTranslationKeys>>;
      yield putResolve(getCategoriesTkeysOk(tKeysData));
      yield put(userChangeLanguageOk(language));
      yield putResolve(getGenericMetadata());
    }
  } catch (e) {
    yield put(userGenericKo(e));
    yield put(showSnackbar(errorMessage(e), 'error', undefined, 2000));
  }
}

export default [
  takeLatest<UserProfileAction>(UserDataConsts.USER_PROFILE, getProfileFromSaga),
  takeLatest<GetUserDataAction>(UserDataConsts.GET_USER_DATA, getUserDataFromSaga),
  takeLatest<GetUsersAction>(UserDataConsts.GET_USERS, getUsersFromSaga),
  takeLatest<UpdateUserProfileAction>(UserDataConsts.UPDATE_USER_PROFILE, updateProfileFromSaga),
  takeLatest<UploadDocumentAction>(UserDataConsts.UPLOAD_USER_DOCUMENT, uploadDocumentFromSaga),
  takeLatest<RemoveDocumentAction>(UserDataConsts.REMOVE_USER_DOCUMENT, removeDocumentFromSaga),
  takeLatest<RemoveEducatorDocumentAction>(UserDataConsts.REMOVE_EDUCATOR_DOCUMENT, removeEducatorDocumentFromSaga),
  takeLatest<DownloadDocumentAction>(UserDataConsts.DOWNLOAD_USER_DOCUMENT, downloadDocumentFromSaga),
  takeLatest<GetDocumentAction>(UserDataConsts.GET_USER_DOCUMENT, getDocumentFromSaga),
  takeLatest<UpdateProfileUserTableAction>(UserDataConsts.UPDATE_PROFILE_USER_TABLE, updateProfileUserTable),
  takeLatest<CreateUserAction>(UserDataConsts.CREATE_USER, createUserSagas),
  takeLatest<GetUserDocumentationAction>(UserDataConsts.GET_USER_DOCUMENTATION, getUserDocumentation),
  takeLatest<InitNewUserAction>(UserDataConsts.INIT_NEW_USER, initUserSagas),
  takeLatest<ChangePasswordAction>(UserDataConsts.CHANGE_PASSWORD, changePassword),
  takeLatest<AcceptUserEducatorAction>(UserDataConsts.ACCEPT_USER_EDUCATOR, acceptUserEducatorFromSaga),
  takeLatest<RejectUserEducatorAction>(UserDataConsts.REJECT_USER_EDUCATOR, rejectUserEducatorFromSaga),
  takeLatest<GetUserEntitiesMetadata>(UserDataConsts.USER_ENTITIES_METADATA, getUserEntitiesMetadataFromSaga),
  takeLatest<UpdateProfileEducator>(UserDataConsts.UPDATE_PROFILE_EDUCATOR, updateProfileEducatorFromSaga),
  takeLatest<CreateUserEducatorAction>(UserDataConsts.CREATE_USER_EDUCATOR, createUserEducatorSagas),
  takeLatest<SetAvatarUserByIdAction>(UserDataConsts.SET_FOREING_AVATAR, setUserAvatarSaga),
  takeLatest<DisableUserAction>(UserDataConsts.DISABLE_USER, disableUser),
  takeLatest<UploadEducatorDocumentAction>(UserDataConsts.UPLOAD_EDUCATOR_DOCUMENT, uploadDocumentEducatorFromSaga),
  takeLatest<GetAvatarUserByIdAction>(UserDataConsts.GET_FOREING_AVATAR, getUserAvatarFromSaga),
  takeLatest<SignLopdAction>(UserDataConsts.SIGN_LOPD_ACTION, signLopdFromSaga),
  takeLatest<UserLinkAcademyAction>(UserDataConsts.USER_LINK_ACADEMY, userLinkAcademyFromSaga),
  takeLatest<GetUserRolesConfigAction>(UserDataConsts.GET_USER_ROLES_CONFIG, getUserRolesConfigActionFromSaga),
  takeLatest<ConfirmUserActiveRoleAction>(UserDataConsts.CONFIRM_USER_ACTIVE_ROLE, confirmUserActiveRoleActionFromSaga),
  takeLatest<UserAddRoleAction>(UserDataConsts.USER_ADD_ROLE, addUserRoleFromSaga),
  takeLatest<UserRemoveRoleAction>(UserDataConsts.USER_REMOVE_ROLE, removeUserRoleFromSaga),
  takeLatest<UserRoleActiveAction>(UserDataConsts.USER_ROLE_ACTIVE, setUserActiveRoleFromSaga),
  takeLatest<SetUserPermissionAction>(UserDataConsts.SET_USER_PERMISSION, setUserPermissionFromSaga),
  takeLatest<UserChangeLanguageAction>(UserDataConsts.USER_CHANGE_LANGUAGE, changeUserLanguageFromSaga),
];
