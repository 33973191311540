import { Participant } from '../Participant';
import { AddParticipantConst } from './action_types';
import { Action } from 'redux';

export interface AddParticipantAction extends Action {
  type: AddParticipantConst.ADD_PARTICIPANT;
  participant: Participant;
}

export interface AddParticipantActionOk extends Action {
  type: AddParticipantConst.ADD_PARTICIPANT_OK;
}

export interface AddParticipantActionKo extends Action {
  type: AddParticipantConst.ADD_PARTICIPANT_KO;
  error: string;
}

export type CreateParticipantDataAction = AddParticipantAction | AddParticipantActionOk | AddParticipantActionKo;

export const initialState = {
  loading: false,
  error: null as string | null,
  data: {} as Participant,
};

export type CreateParticipantState = typeof initialState;
