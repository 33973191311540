import { ButtonProps, InputAdornment } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { AtoCLabelOptions, yesNoOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import Title from '../../../../components/Title/Title';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetApprovalInfoData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import { ScholarshipData } from '../../../../redux/scholarship/definitions';
import { selectTotalApproval, selectTotalCost, selectTotalMaterialCost } from '../../../../redux/scholarship/selectors';
import { scholarshipStateTransitionAction } from '../../../../redux/scholarship/transitions/actions';
import { approvalInfoSchema } from '../../../../validations/approvalSchema';
import styles from './approvalForm.module.scss';
import ApprovalModal from './approvalModal';

interface ApprovalFormProps {
  scholarshipData: ScholarshipData;
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  buttonActionApprove: ButtonProps;
  totalCost: number;
  totalApproval: number;
  totalMaterialCost: number;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
  sendApprovement: (id: number, redirect: string, scholarshipData?: ScholarshipData) => void;
}

const ApprovalForm: React.FC<ApprovalFormProps> = ({
  scholarshipData,
  readOnly,
  buttonActionSave,
  buttonActionApprove,
  totalCost,
  totalApproval,
  totalMaterialCost,
  setData,
  saveData,
  sendApprovement,
}) => {
  const {
    id,
    enrollment_cost,
    enrollment_approval,
    monthly_enrollment_cost,
    monthly_price_approval,
    materials_approval,
    transportation_cost,
    transport_price_approval,
    other_costs_amount,
    other_costs_approval,
    young_contrib_approval,
    approval_comments,
    approval_confirm_comments,
    confirmed_with_diff,
    scholarship_mentoring,
    scholarship_mentoring_approval,
    scholarship_mentoring_finished,
    scholarship_mentoring_quality,
    scholarship_mentoring_comm_approval,
  } = scholarshipData;

  const [showModal, setShowModal] = React.useState(false);

  const { control, errors, register, handleSubmit, clearError } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: approvalInfoSchema,
  });

  const saveApprovalInfo = () => {
    clearError();
    saveData(scholarshipData);
  };

  const sendApprovalInfo = () => {
    clearError();
    setShowModal(true);
  };

  buttonActionSave.hidden = readOnly;

  useButtonAction(buttonActionSave, handleSubmit(saveApprovalInfo));
  useButtonAction(buttonActionApprove, handleSubmit(sendApprovalInfo));

  let approvalModal;
  if (showModal) {
    approvalModal = (
      <TransitionModal
        view={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
        title={tKey('Mensaje de aprobación')}
        bodyModal=" "
      >
        <ApprovalModal
          handleClose={() => {
            setShowModal(false);
          }}
          handleSend={(message = tKey('Sin especificar')) => {
            scholarshipData.comments = message;
            sendApprovement(id, id.toString(), scholarshipData);
          }}
        />
      </TransitionModal>
    );
  }
  return (
    <div className={styles.containerWithMenu}>
      <div className={styles.container}>
        <Title>{tScholarshipKey('Aprobación')}</Title>
        <form>
          <div className={styles.totalTitle}>{tScholarshipKey('TOTAL ESTUDIOS')}</div>
          <div className={styles.total}>{totalCost} €</div>
          <FormContainer title="">
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Matriculación')}
              size="50"
              name="total_cost"
              value={enrollment_cost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInputController
              control={control}
              schema={approvalInfoSchema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Matriculación-aprobación')}
              size="50"
              name="enrollment_approval"
              price
              defaultValue={enrollment_approval}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('enrollment_approval', e.target.value)}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Importe-mensualidad-hay')}
              size="50"
              name="monthly_enrollment_cost"
              value={monthly_enrollment_cost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInputController
              control={control}
              schema={approvalInfoSchema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Importe-mensualidad-aprobacion')}
              size="50"
              name="monthly_price_approval"
              price
              defaultValue={monthly_price_approval}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('monthly_price_approval', e.target.value)}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Materiales')}
              size="50"
              name="totalMaterialCost"
              value={totalMaterialCost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInputController
              control={control}
              schema={approvalInfoSchema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Materiales-aprobación')}
              size="50"
              name="materials_approval"
              price
              defaultValue={materials_approval}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('materials_approval', e.target.value)}
            />
            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('Transporte')}
              size="50"
              name="transportation_cost"
              value={transportation_cost}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInputController
              control={control}
              schema={approvalInfoSchema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('Transporte aprobación')}
              size="50"
              name="transport_price_approval"
              price
              defaultValue={transport_price_approval}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('transport_price_approval', e.target.value)}
            />

            <TextInput
              type="number"
              disabled
              label={tScholarshipKey('otros-importer-considerar')}
              size="50"
              name="other_costs_amount"
              value={other_costs_amount}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
            <TextInputController
              control={control}
              schema={approvalInfoSchema}
              type="number"
              errors={errors}
              disabled={readOnly}
              label={tScholarshipKey('otros-importer-considerar')}
              size="50"
              name="other_costs_approval"
              price
              defaultValue={other_costs_approval}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('other_costs_approval', e.target.value)}
            />
          </FormContainer>

          <FormContainer title="">
            <div className={styles.title_textInput}>{tScholarshipKey('importe-formación-jovén')}</div>
            <TextInputController
              control={control}
              schema={approvalInfoSchema}
              type="number"
              errors={errors}
              disabled={readOnly}
              size="50"
              name="young_contrib_approval"
              price
              defaultValue={young_contrib_approval}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('young_contrib_approval', e.target.value)}
            />
            <TextInputController
              control={control}
              schema={approvalInfoSchema}
              errors={errors}
              fullWidth
              type="text"
              rows="4"
              rowsMax="10"
              disabled={readOnly}
              label={tScholarshipKey('como-asumir')}
              name="aproval_comments"
              multiline
              defaultValue={approval_comments}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('aproval_comments', e.target.value)}
            />
            <div className={styles.totalTitle}>{tScholarshipKey('TOTAL BECA')}</div>
            <div className={styles.total}>{totalApproval.toFixed(2)}€</div>
          </FormContainer>
          {scholarship_mentoring === 1 ? (
            <FormContainer title="">
              <div className={styles.mentorSection}>
                <RadioButtonGroup
                  name="scholarship_mentoring_approval"
                  errors={errors.scholarship_mentoring_approval}
                  disabled={readOnly}
                  register={register}
                  questionText={tScholarshipKey('¿Mentoría aprobada?')}
                  options={yesNoOptions}
                  value={scholarship_mentoring_approval?.toString() || null}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setData('scholarship_mentoring_approval', e.target.value);
                  }}
                />
                <RadioButtonGroup
                  name="scholarship_mentoring_finished"
                  errors={errors.scholarship_mentoring_finished}
                  disabled={readOnly}
                  register={register}
                  questionText={tScholarshipKey('¿Mentoría finalizada?')}
                  options={yesNoOptions}
                  value={scholarship_mentoring_finished?.toString() || null}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (value === '0') {
                      setData('scholarship_mentoring_quality', '');
                      clearError('scholarship_mentoring_quality');
                    }
                    setData('scholarship_mentoring_finished', value);
                  }}
                />
                <RadioButtonGroup
                  name="scholarship_mentoring_quality"
                  errors={errors.scholarship_mentoring_quality}
                  disabled={readOnly}
                  register={register}
                  questionText={tScholarshipKey('Calidad de la mentoría')}
                  options={AtoCLabelOptions}
                  value={scholarship_mentoring_quality?.toString() || null}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setData('scholarship_mentoring_quality', e.target.value);
                  }}
                />
              </div>
              <TextInputController
                control={control}
                schema={approvalInfoSchema}
                errors={errors}
                fullWidth
                rows="4"
                rowsMax="10"
                disabled={readOnly}
                label={tScholarshipKey('Comentario sobre mentoría')}
                name="scholarship_mentoring_comm_approval"
                multiline
                defaultValue={scholarship_mentoring_comm_approval}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                  setData('scholarship_mentoring_comm_approval', e.target.value)
                }
              />
            </FormContainer>
          ) : null}
          {confirmed_with_diff ? (
            <FormContainer title="">
              <TextInputController
                control={control}
                schema={approvalInfoSchema}
                errors={errors}
                fullWidth
                rows="4"
                rowsMax="10"
                disabled={readOnly}
                label={tScholarshipKey('Comentario sobre aprobación')}
                name="approval_confirm_comments"
                multiline
                defaultValue={approval_confirm_comments}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('approval_confirm_comments', e.target.value)}
              />
            </FormContainer>
          ) : null}
        </form>

        {approvalModal}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  totalCost: selectTotalCost(state),
  totalApproval: selectTotalApproval(state),
  totalMaterialCost: selectTotalMaterialCost(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetApprovalInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
  sendApprovement: (id: number, redirect: string, scholarshipData?: ScholarshipData): void =>
    dispatch(scholarshipStateTransitionAction(id, 's08s06', redirect, scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalForm);
