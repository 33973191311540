import React from 'react';
import ImageIcon from '@material-ui/icons/Image';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as excelIcon } from '../../images/icons/excel.svg';
import { ReactComponent as pdfIcon } from '../../images/icons/pdf.svg';
import { ReactComponent as wordIcon } from '../../images/icons/word.svg';

interface FileIconsProp {
  filename: string;
}

const FileIcon: React.FC<FileIconsProp> = ({ filename }) => {
  const res = filename.substr(filename.lastIndexOf('.') + 1);
  switch (res) {
    case 'pdf':
      return <SvgIcon component={pdfIcon} viewBox="0 0 600 476.6" />;
    case 'docx':
      return <SvgIcon component={wordIcon} viewBox="0 0 600 476.6" />;
    case 'xlsx':
      return <SvgIcon component={excelIcon} viewBox="0 0 600 476.6" />;
    default:
      return <ImageIcon />;
  }
};

export default FileIcon;
