import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import DescriptionIcon from '@material-ui/icons/Description';
import { EntityContract, EntityInfo, EntityStateCodes } from '../../../../redux/entity/definitions';
import { MaterialTableProps } from 'material-table';
import { tFormKey, tKey } from '../../../../helpers/translate';
import Contract from '../Contract';
import FormContainer from '../../../../components/Forms/FormContainer';
import SelectController from '../../../../components/Select/SelectController';
import { MenuItem } from '@material-ui/core';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import { AppState } from '../../../../redux/root-reducer';
import { selectContractModalView, selectGetOneEntity, selectLoadingContract } from '../../../../redux/entity/selectors';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import {
  closeContractModal,
  deleteContract,
  downloadContractDocument,
  entitySetData,
  openContractModal,
  resetContract,
} from '../../../../redux/entity/actions';
import styles from './providerEntityForm.module.scss';

interface ProviderEntityFormProps {
  useForm: any;
  entitySchema: any;
  entity: EntityInfo;
  disabled?: boolean;
  isNousCims: boolean;
  loadingContract: boolean;
  contractModalView: boolean;
  canEditRequestedEntity: boolean;
  setData: (key: string, value: string | number) => void;
  deleteContract: (contractData: EntityContract) => void;
  resetContract: () => void;
  downloadContractDocument: (file_id: number, filename: string) => void;
  openContractModal: () => void;
  closeContractModal: () => void;
}

const ProviderEntityForm: React.FC<ProviderEntityFormProps> = ({
  useForm,
  entitySchema,
  entity,
  disabled,
  isNousCims,
  loadingContract,
  contractModalView,
  canEditRequestedEntity,
  setData,
  deleteContract,
  resetContract,
  downloadContractDocument,
  openContractModal,
  closeContractModal,
}) => {
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [hardContract, setHardContract] = React.useState({} as EntityContract);

  const { id, entity_ref_user_id, state_code, entity_contracts, user_list } = entity;

  const { errors, control } = useForm;

  const tableData: MaterialTableProps<EntityContract> = {
    title: tFormKey('Contratos'),
    columns: [
      {
        field: 'contract_number',
        title: tFormKey('Número de contrato'),
      },
      {
        field: 'start_date',
        title: tFormKey('Fecha inicio'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: EntityContract) => (
          <div style={{ textAlign: 'center' }}>{moment.utc(rowData.start_date || '', 'X').format('DD/MM/YYYY')}</div>
        ),
        //@ts-ignore
        width: '150px',
      },
      {
        field: 'end_date',
        title: tFormKey('Fecha fin'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: EntityContract) => (
          <div style={{ textAlign: 'center' }}>{moment.utc(rowData.end_date || '', 'X').format('DD/MM/YYYY')}</div>
        ),
        //@ts-ignore
        width: '150px',
      },
      {
        field: 'file_id',
        title: tFormKey('Documento'),
        disableClick: true,
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: EntityContract) =>
          rowData.file_id && (
            <button
              className={styles.downloadButton}
              onClick={e => {
                e.preventDefault();
                downloadContractDocument(rowData.file_id || 0, rowData.filename || '');
              }}
            >
              <DescriptionIcon />
            </button>
          ),
        //@ts-ignore
        width: '115px',
      },
    ],
    data: entity_contracts,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  const [contractModal, setContractModal] = React.useState({
    view: contractModalView,
    children: <Contract entityId={0} />,
    title: '',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const onAddContractOne = (data: EntityContract) => {
    setContractModal({
      ...contractModal,
      children: <Contract entityId={data.id} />,
      title: tFormKey('Nuevo contrato'),
    });
    openContractModal();
  };

  const onEditContractOne = (data: EntityContract) => {
    setContractModal({
      ...contractModal,
      children: <Contract entityId={id} contractId={data.id} />,
      title: tFormKey('Editar contrato'),
    });
    openContractModal();
  };

  const goToNew = () => {
    resetContract();
    onAddContractOne({ id } as EntityContract);
  };

  const itemSetter = (contractData: EntityContract) => {
    setHardContract(contractData);
    setModal({ view: true, body: [] });
  };

  const onRemoveOne = (contractData: EntityContract) => {
    deleteContract(contractData);
  };

  return (
    <FormContainer title={tKey('Datos proveedor')}>
      <div className={styles.entity_ref_user_id}>
        {isNousCims && user_list && user_list.length > 0 && (
          <SelectController
            name={'entity_ref_user_id'}
            disabled={
              canEditRequestedEntity && state_code === EntityStateCodes.PENDIENTE_RESPONSABLE ? false : disabled
            }
            control={control}
            errors={errors}
            schema={entitySchema}
            label={tFormKey('Referente de Nous Cims')}
            size={'100'}
            defaultValue={entity_ref_user_id}
            onClick={e => handleSelectChange(e.target)}
          >
            {user_list.map((element, index) => (
              <MenuItem key={index} value={element.id}>
                {element.name || tFormKey('Nombre de responsable no disponible')}
              </MenuItem>
            ))}
          </SelectController>
        )}
      </div>
      <TableWithEdit
        tableData={{ ...tableData, isLoading: loadingContract }}
        deleteFunc={!disabled ? (rowData: EntityContract) => itemSetter(rowData) : undefined}
        key={JSON.stringify(tableData.data)}
        onEditOne={
          !disabled
            ? (event: React.MouseEvent, rowData: EntityContract | EntityContract[]) =>
                onEditContractOne(rowData as EntityContract)
            : undefined
        }
        optionsNew={
          !disabled
            ? {
                newButtonText: tFormKey('Nuevo contrato'),
                onNew: goToNew,
              }
            : undefined
        }
        permission={true}
        customContainer
      />
      <TransitionModal
        view={contractModalView}
        handleClose={() => {
          closeContractModal();
        }}
        title={contractModal.title}
        bodyModal=" "
      >
        {contractModal.children}
      </TransitionModal>
      <TransitionModal
        view={contractModalView}
        handleClose={() => {
          closeContractModal();
        }}
        title={contractModal.title}
        bodyModal=" "
      >
        {contractModal.children}
      </TransitionModal>
      <TransitionModal
        view={modal.view}
        handleClose={() => {
          setModal({ view: false, body: [] });
        }}
        bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        title={tKey('¡Atención!')}
        handleYes={() => {
          onRemoveOne(hardContract);
          setModal({ view: false, body: [] });
        }}
      />
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  entity: selectGetOneEntity(state),
  isNousCims: selectIsNousCims(state),
  loadingContract: selectLoadingContract(state),
  contractModalView: selectContractModalView(state),
  canEditRequestedEntity: selectUserCan(state)('transition_e03e04'),
});

const mapDispatchToProps = (dispatch: any) => ({
  openContractModal: () => dispatch(openContractModal()),
  closeContractModal: () => dispatch(closeContractModal()),
  setData: (key: string, value: string | number) => dispatch(entitySetData(key, value)),
  deleteContract: (contractData: EntityContract) => dispatch(deleteContract(contractData)),
  resetContract: () => dispatch(resetContract()),
  downloadContractDocument: (file_id: number, filename: string) =>
    dispatch(downloadContractDocument(file_id, filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderEntityForm);
