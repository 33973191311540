import React, { useEffect } from 'react';
import { AppState } from '../../../redux/root-reducer';
import { connect } from 'react-redux';
import { Button, Chip } from '@material-ui/core';
import FileIcon from '../../../components/FileIcon/FileIcon';
import styles from './invoiceForm.module.scss';
import { tKey } from '../../../helpers/translate';
import {
  downloadInvoiceDocument,
  getInvoiceData,
  removeInvoiceDocument,
  uploadInvoiceDocument,
} from '../../../redux/accounting/actions';
import { selectInvoice } from '../../../redux/accounting/selectors';
import { InvoiceData, InvoiceStates } from '../../../redux/accounting/definitions';
import { FieldErrors } from 'react-hook-form/dist/types';
import { permissionType } from '../../../constants/permissions_definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';

interface Props {
  readOnly: boolean;
  errors: FieldErrors<any>;
  invoice_id: number;
  invoiceData: InvoiceData;
  uploadInvoiceDocument: (file: File, invoice_id: number) => void;
  removeInvoiceDocument: (file_id: number) => void;
  downloadInvoiceDocument: (file_id: number, filename: string) => void;
  userCan: (permission: permissionType) => boolean | undefined;
  getInvoiceData: (id: number) => void;
}

const UploadFileInvoice = ({
  readOnly,
  errors,
  invoice_id,
  invoiceData,
  downloadInvoiceDocument,
  uploadInvoiceDocument,
  removeInvoiceDocument,
  userCan,
  getInvoiceData,
}: Props) => {
  const { file_id, filename } = invoiceData;
  const canChangeInvoices = userCan('allowInvoiceChanges') && !invoiceData.closing_date;

  const canAttachInvoice =
    canChangeInvoices ||
    invoiceData.state_code === InvoiceStates.CREACION ||
    invoiceData.state_code === InvoiceStates.PENDIENTE;
  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      uploadInvoiceDocument(file, invoice_id);
    }
  };

  useEffect(() => {
    if (invoice_id !== 0 && invoiceData.id !== invoice_id) {
      getInvoiceData(invoice_id);
    }
  }, [getInvoiceData, invoiceData.id, invoice_id]);

  return (
    <>
      {filename && file_id && (
        <Chip
          icon={<FileIcon filename={filename} />}
          size="medium"
          label={filename}
          clickable
          onClick={() => downloadInvoiceDocument(file_id, filename)}
          onDelete={!readOnly || canChangeInvoices ? () => removeInvoiceDocument(file_id) : undefined}
          className={styles.chip}
        />
      )}
      {canAttachInvoice && !file_id && (
        <div className={styles.fileNameContainer}>
          <input
            id="file"
            className={styles.FileInput}
            type="file"
            multiple
            onChange={onUpload}
            accept="application/pdf,image/*,.xlsx,.docx,.doc,.xls"
            style={{ display: 'none' }}
          />
          <div className={styles.buttonUpload}>
            <label htmlFor="file">
              <Button variant="contained" color="secondary" component="span" fullWidth>
                {tKey('Adjuntar factura')}
              </Button>
            </label>
          </div>
          {errors && errors.file_id && <label className={styles.helperText}>{errors.file_id.message}</label>}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  invoiceData: selectInvoice(state),
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  uploadInvoiceDocument: (file: File, invoice_id: number) => dispatch(uploadInvoiceDocument(file, invoice_id)),
  removeInvoiceDocument: (file_id: number) => dispatch(removeInvoiceDocument(file_id)),
  downloadInvoiceDocument: (file_id: number, filename: string) => dispatch(downloadInvoiceDocument(file_id, filename)),
  getInvoiceData: (id: number) => dispatch(getInvoiceData(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileInvoice);
