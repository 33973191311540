import { ButtonProps } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { sendCustomFieldsFilterData } from '../../../api/Configuration/configuration';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import Title from '../../../components/Title/Title';
import UploadLocalFile from '../../../components/UploadLocalFile/UploadLocalFile';
import { tKey } from '../../../helpers/translate';
import { CodeNamePair } from '../../../redux/common/definitions';
import { selectLanguages } from '../../../redux/metadata/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { getFileTranslations } from '../../../redux/translations/actions';
import { selectImportResult } from '../../../redux/translations/selectors';
import FilterCfTranslation from './FilterCfTranslation';
import FilterList from './FilterList/FilterList';
import SubmenuTranslations from './SubmenuTranslations';
import styles from './translations.module.scss';

interface FilterData {
  category: string;
  language: string;
  value_ca: string;
  value_en: string;
  value_es: string;
  value_fr: string;
}

interface CustomFieldsTranslationsProps extends RouteComponentProps {
  forceCloseModal: boolean;
  languages: CodeNamePair[];
  canIconfigTranslations: boolean;
  exportExcel: () => void;
}

const CustomFieldsTranslations: React.FC<CustomFieldsTranslationsProps> = ({
  match,
  forceCloseModal,
  languages,
  canIconfigTranslations,
  exportExcel,
}) => {
  const history = useHistory();
  const [filteredTranslations, setFilteredTranslations] = useState<any>([]);
  const [lang, setLang] = useState('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const { path } = match;
  const selectedSection = path.substring(path.lastIndexOf('/') + 1);

  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: tKey('Ayuda'),
    body: (
      <div className={styles.modalHelp}>
        {tKey('En el caso de que no existiera texto en el idioma escogido, por defecto se usará el español')}
      </div>
    ),
    startIcon: <HelpOutline />,
  });

  const handleSubmit = (data: FilterData) => {
    (async function fetchSelectData() {
      setLoading(true);
      const responseData = await sendCustomFieldsFilterData(data);
      setFilteredTranslations(responseData);
      setLang(data.language);
      setLoading(false);
    })();
  };

  const showRealLang = (lan: string) => {
    const language = languages.find((l: CodeNamePair) => l.code === lan);
    return language ? language.name?.toLowerCase() : '';
  };

  const moreInfoButtons: ButtonProps[] = [
    {
      children: tKey('Ayuda'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      onClick: () =>
        setCreatedModal(prevState => ({
          ...prevState,
          view: true,
        })),
      startIcon: <HelpOutline />,
    },
  ];

  const buttons: ButtonProps[] = [
    {
      children: tKey('Exportar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      startIcon: <GetAppIcon />,
      onClick: () => exportExcel(),
    },
  ];

  const buttonModal = {
    title: tKey('Importar'),
    children: <UploadLocalFile source="custom_fields" />,
    buttonText: tKey('Importar'),
    startIcon: <PublishIcon />,
  };

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuTranslations
          canConfigure={canIconfigTranslations}
          selected={selectedSection}
          submenu={selectedSection}
        />
      }
      rightSubmenu={
        <ActionsMenu
          actionsButtons={buttons}
          isModal={buttonModal}
          forceCloseModal={forceCloseModal}
          moreInfoButtons={moreInfoButtons}
          goBack
          onGoBack={() => history.push('/configuracion/traducciones')}
        />
      }
    >
      <Title>{tKey('Campos configurados')}</Title>
      <FilterCfTranslation onSubmit={handleSubmit} onChangeLang={lang => setLang(lang)} />
      {loading ? (
        <Loading />
      ) : (
        <FilterList lang={lang} data={filteredTranslations} langLabel={showRealLang(lang)} source="custom_fields" />
      )}
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        maxWidth={true}
        bodyModal={createdModal.body}
      >
        {createdModal.children}
      </TransitionModal>
    </LayoutForm>
  );
};

const mapStateProps = (state: AppState) => ({
  forceCloseModal: selectImportResult(state),
  languages: selectLanguages(state),
  canIconfigTranslations: selectUserCan(state)('allow_translation_config'),
});

const mapDispatchToProps = (dispatch: any) => ({
  exportExcel: (): void => dispatch(getFileTranslations('custom_fields')),
});

export default connect(mapStateProps, mapDispatchToProps)(CustomFieldsTranslations);
