import { ButtonProps, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { MaterialTableProps } from 'material-table';
import moment from 'moment';
import React, { Fragment, Suspense, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import KeyboardDatePickerController from '../../../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../../components/RadioButtons/RadioButtonGroup';
import SelectController from '../../../../../components/Select/SelectController';
import TableWithEdit from '../../../../../components/Tables/TableWithEdit';
import Title from '../../../../../components/Title/Title';
import * as DateConstants from '../../../../../constants/date';
import { tFormKey, tKey } from '../../../../../helpers/translate';
import { selectIsNousCims } from '../../../../../redux/auth/selectors';
import { IdNamePair } from '../../../../../redux/common/definitions';
import {
  applyConfigTemplate,
  deleteMembers,
  updateInternalProject,
} from '../../../../../redux/internal-projects/actions';
import {
  InformationForm,
  InternalProject,
  InternalProjectForms,
  Member,
} from '../../../../../redux/internal-projects/definitions';
import { selectNouscimsUsers } from '../../../../../redux/internal-projects/selectors';
import { AppState } from '../../../../../redux/root-reducer';
import { InformationSchema } from '../../../../../validations/formSchema';
import InternalProjectLayoutForm from '../../InternalProjectLayoutForm';
import styles from './information.module.scss';
import SendIcon from '@material-ui/icons/Send';

const AddNewMemberModal = React.lazy(() => import('../../../Modals/AddNewMemberModal/AddNewMemberModal'));
interface InformationProps {
  id: number;
  disabled?: boolean;
  buttonImageGallery: ButtonProps;
  internalProject: InternalProject;
  members: Member[];
  nouscimsUsers: IdNamePair[];
  isNousCims: boolean;
  handleGoBack?: () => void;
  handleChangeSection: (componentId: string) => void;
  deleteMember: (id: number, internal_project_id: number) => void;
  updateInternalProject: (internalProject: InternalProjectForms, id: number) => void;
  applyConfigTemplate: (internalProjectId: number) => void;
}

const entityOptions = [
  { value: '0', label: 'none', hiddenT: true, hidden: true },
  { value: '1', label: tFormKey('Entidad') },
];

const academyCenterOptions = [
  { value: '0', label: 'none', hiddenT: true, hidden: true },
  { value: '1', label: tFormKey('Centro educativo') },
];

const Information: React.FC<InformationProps> = ({
  id,
  disabled,
  internalProject,
  members,
  nouscimsUsers,
  isNousCims,
  buttonImageGallery,
  handleGoBack,
  handleChangeSection,
  deleteMember,
  updateInternalProject,
  applyConfigTemplate,
}) => {
  const [addMemberModal, setAddMemberModal] = useState(false);

  const { errors, control, handleSubmit, watch, setError, clearError } = useForm<InformationForm>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InformationSchema,
  });

  const tableData: MaterialTableProps<Member> = {
    title: tKey('ip.equipo_proyecto'),
    columns: [
      {
        title: tKey('ip.nombre_complete'),
        field: 'name',
        //@ts-ignore
      },
      {
        title: tKey('ip.email'),
        field: 'email',
        //@ts-ignore
      },
      {
        title: tKey('Teléfono'),
        field: 'phone',
        //@ts-ignore
      },
      {
        title: tKey('ip.procedencia'),
        field: 'origin',
        //@ts-ignore
      },
    ],
    data: members,
    options: {
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  const goToNew = () => setAddMemberModal(true);

  const handleDeleteMember = (rowData: any) => {
    deleteMember(rowData.user_id, id);
  };

  const handleEdit = (data: InformationForm) => {
    const { start_date, end_date, name, owner_id } = data;
    updateInternalProject(
      {
        end_date: moment(end_date).format('DD/MM/YYYY'),
        start_date: moment(start_date).format('DD/MM/YYYY'),
        name,
        owner_id,
      },
      id,
    );
  };

  const buttonProps: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: !isNousCims,
      onClick: handleSubmit(handleEdit),
    },
    {
      children: tKey('Aplicar plantilla'),
      startIcon: <SendIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: false,
      onClick: async () => {
        applyConfigTemplate(internalProject.id);
      },
      hidden: false,
    },
    buttonImageGallery,
  ];

  const validateDates = (name: keyof InformationForm) => (
    date: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isValid = moment(date.target.value, DateConstants.DATE_FORMAT, true).isValid();
    if (!isValid) {
      setError(name, 'date', tKey('Fecha invalida'));
      return;
    } else {
      clearError(name);
    }
  };

  return (
    <InternalProjectLayoutForm
      sectionSelected={'informacion'}
      actionsButtons={buttonProps}
      handleChangeSection={handleChangeSection}
      handleGoBack={handleGoBack}
    >
      <Fragment>
        <Title>{tKey('Información')}</Title>
        <FormContainer title="">
          <TextInputController
            size="100"
            label={tFormKey('ip.nombre_proyecto')}
            name="name"
            control={control}
            disabled={disabled}
            defaultValue={internalProject.name}
            errors={errors}
            schema={InformationSchema}
          />
          <TextInputController
            size="50"
            label={tFormKey('ip.subarea')}
            name="subarea"
            control={control}
            disabled
            defaultValue={internalProject.subarea_name}
            errors={errors}
            schema={InformationSchema}
          />
          <TextInputController
            size="50"
            label={tFormKey('ip.plantilla')}
            name="template"
            control={control}
            defaultValue={internalProject.template_name}
            disabled
            errors={errors}
            schema={InformationSchema}
          />
          <div className={styles.selectors}>
            <div className={styles.selectors__sub}>
              <RadioButtonGroup
                name="is_entity"
                questionText=""
                options={entityOptions}
                value={internalProject.entity_id ? '1' : '0'}
                disabled
              />
              <RadioButtonGroup
                name="is_academyCenter"
                disabled
                questionText=""
                options={academyCenterOptions}
                value={internalProject.academy_id ? '1' : '0'}
              />
            </div>
          </div>
          <TextInputController
            size="50"
            label={tFormKey('ip.entidad') + ' / ' + tFormKey('ip.centro_educativo')}
            name="entity"
            control={control}
            disabled
            defaultValue={internalProject.academy_name ?? internalProject.entity_name}
            errors={errors}
            schema={InformationSchema}
            hidden
          />
          <div className={styles.space_input}></div>
          <SelectController
            name="owner_id"
            control={control}
            errors={errors}
            defaultValue={internalProject.owner_id}
            schema={InformationSchema}
            label={tFormKey('ip.responsable_interno')}
            disabled={disabled}
            size="50"
          >
            {nouscimsUsers.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </SelectController>
          <KeyboardDatePickerController
            label={tFormKey('ip.fecha_inicio')}
            name="start_date"
            control={control}
            schema={InformationSchema}
            errors={errors}
            defaultValue={internalProject.start_date}
            disabled={disabled}
            size="50"
            onBlur={validateDates('start_date')}
          />
          <KeyboardDatePickerController
            label={tFormKey('ip.fecha_fin')}
            name="end_date"
            control={control}
            schema={InformationSchema}
            errors={errors}
            disabled={disabled}
            defaultValue={internalProject.end_date}
            minDate={watch('start_date')}
            size="50"
            onBlur={validateDates('end_date')}
          />
          <TableWithEdit
            customContainer
            tableData={{ ...tableData }}
            filtering
            permission={!disabled}
            optionsNew={
              isNousCims
                ? {
                    newButtonText: tKey('Nuevo miembro'),
                    onNew: goToNew,
                  }
                : undefined
            }
            deleteFunc={disabled ? undefined : handleDeleteMember}
          />
        </FormContainer>
        <Suspense fallback={<></>}>
          {addMemberModal && (
            <AddNewMemberModal
              internal_project_id={id}
              view={addMemberModal}
              title={tKey('ip.new_member_modal')}
              inputLabel={tKey('ip.new_member_modal')}
              buttonLabel={tKey('Añadir')}
              handleClose={() => setAddMemberModal(false)}
              optionLabel="info"
            />
          )}
        </Suspense>
      </Fragment>
    </InternalProjectLayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  internalProject: state.internalProjectReducer.internalProjectData,
  members: state.internalProjectReducer.internalProjectData.members,
  nouscimsUsers: selectNouscimsUsers(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  updateInternalProject: (internalProject: InternalProjectForms, id: number): void =>
    dispatch(updateInternalProject(internalProject, id)),
  deleteMember: (id: number, internal_project_id: number): void => dispatch(deleteMembers(id, internal_project_id)),
  applyConfigTemplate: (internalProjectId: number) => dispatch(applyConfigTemplate(internalProjectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Information);
