export enum CustomFieldsConst {
  GET_CUSTOMFIELDS_LIST = 'GET:CUSTOMFIELDS:LIST',
  GET_CUSTOMFIELDS_LIST_OK = 'GET:CUSTOMFIELDS:LIST:OK',

  GET_ONE_CUSTOMFIELDS = 'GET:ONE:CUSTOMFIELDS',
  GET_ONE_CUSTOMFIELDS_OK = 'GET:ONE:CUSTOMFIELDS:OK',

  CREATE_CUSTOMFIELD = 'CREATE:CUSTOMFIELD',
  CREATE_CUSTOMFIELD_OK = 'CREATE:CUSTOMFIELD:OK',

  EDIT_CUSTOMFIELD = 'EDIT:CUSTOMFIELD',
  EDIT_CUSTOMFIELD_OK = 'EDIT:CUSTOMFIELD:OK',

  DELETE_CUSTOMFIELD = 'DELETE:CUSTOMFIELD',
  DELETE_CUSTOMFIELD_OK = 'DELETE:CUSTOMFIELD:OK',
  DELETE_CUSTOMFIELD_DEACTIVATE = 'DELETE:CUSTOMFIELD:DEACTIVATE',

  CLEAR_CUSTOMFIELD_DEACTIVATION = 'CLEAR:CUSTOMFIELD:DEACTIVATION',

  DEACTIVATE_CUSTOMFIELD = 'DEACTIVATE:CUSTOMFIELD',
  DEACTIVATE_CUSTOMFIELD_OK = 'DEACTIVATE:CUSTOMFIELD:OK',
  DEACTIVATE_CUSTOMFIELD_KO = 'DEACTIVATE:CUSTOMFIELD:KO',

  CUSTOMFIELD_VALIDATION_ERROR = 'CUSTOMFIELD:VALIDATION:ERROR',

  ASSIGMENTS = 'ASSIGMENTS',
  ASSIGMENTS_OK = 'ASSIGMENTS:OK',

  UNASSIGMENTS = 'UNASSIGMENTS',
  UNASSIGMENTS_OK = 'UNASSIGMENTS:OK',

  GET_KPI_INIDICATORS = 'GET:KPI:INDICATORS',
  GET_KPI_INIDICATORS_OK = 'GET:KPI:INDICATORS:OK',

  GET_KPI_INIDICATOR = 'GET:KPI:INDICATOR',
  GET_KPI_INIDICATOR_OK = 'GET:KPI:INDICATOR:OK',

  UPDATE_KPI_INIDICATOR = 'UPDATE:KPI:INDICATOR',
  UPDATE_KPI_INIDICATOR_OK = 'UPDATE:KPI:INDICATOR:OK',

  CREATE_KPI_INIDICATOR = 'CREATE:KPI:INDICATOR',
  CREATE_KPI_INIDICATOR_OK = 'CREATE:KPI:INDICATOR:OK',

  REMOVE_KPI_INIDICATOR = 'REMOVE:KPI:INDICATOR',
  REMOVE_KPI_INIDICATOR_OK = 'REMOVE:KPI:INDICATOR:OK',

  SET_KPI_INIDICATOR_DATA = 'SET:KPI:INDICATOR_DATA',
  ADD_KPI_INIDICATOR = 'ADD:KPI:INDICATOR',
  CLOSE_MODAL = 'CLOSE:MODAL',

  CUSTOMFIELDS_GENERIC_KO = 'CUSTOMFIELDS:GENERIC:KO',
}
