import { MaterialTableProps } from 'material-table';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TableLayout from '../../../components/Layout/TableLayout';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tKey } from '../../../helpers/translate';
import { deleteExpenseAccount, getExpenseAccountList } from '../../../redux/accounting/actions';
import { ExpenseAccount } from '../../../redux/accounting/definitions';
import { selectExpensesList, selectLoading } from '../../../redux/accounting/selectors';
import { AppState } from '../../../redux/root-reducer';
import AccountingSubmenu from '../AccountingSubmenu';

interface ExpenseAccountProps {
  loading: boolean;
  expenseAccount: ExpenseAccount[];
  getListExpenses: () => void;
  deleteExpenseAccount: (id: number) => void;
}

const ExpenseAccounts: React.FC<ExpenseAccountProps> = ({
  loading,
  expenseAccount,
  getListExpenses,
  deleteExpenseAccount,
}) => {
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [id, setId] = React.useState<number>(0);
  useEffect(() => {
    getListExpenses();
  }, [getListExpenses]);

  const tableData: MaterialTableProps<ExpenseAccount> = {
    title: tKey('CUENTAS DE GASTO'),
    columns: [
      {
        field: 'code',
        title: tKey('Código'),
        //@ts-ignore
        width: '200px',
      },
      {
        field: 'description',
        title: tKey('Descripción'),
        //@ts-ignore
        width: '200px',
      },
      {
        field: 'active',
        title: tKey('Estado'),
        render: (rowData: ExpenseAccount) =>
          rowData.active ? <span>{tKey('Activo')}</span> : <span>{tKey('Inactivo')}</span>,
        //@ts-ignore
        width: '200px',
      },
    ],
    data: expenseAccount,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
  };

  const history = useHistory();

  const idItem = (id: number) => {
    setId(id);
    setModal({ view: true, body: [] });
  };
  const onEditOne = (event: React.MouseEvent<Element, globalThis.MouseEvent>, rowData: any) =>
    history.push(`/contabilidad/cuentas-de-gasto/editar/${rowData.id}`);

  const goToNew = () => {
    history.push(`/contabilidad/cuentas-de-gasto/crear`);
  };

  const goToExpenseAccountDetail = (rowData: ExpenseAccount) => {
    const { id } = rowData;
    history.push({
      pathname: `/contabilidad/cuentas-de-gasto/detalle/${id}`,
      state: { id },
    });
  };

  return (
    <TableLayout leftSubmenu={<AccountingSubmenu selected="expenses" />}>
      <TableWithEdit
        onRowClick={goToExpenseAccountDetail}
        tableData={{ ...tableData, isLoading: loading }}
        key={JSON.stringify(tableData.data)}
        onEditOne={onEditOne}
        deleteFunc={(rowData: ExpenseAccount) => idItem(rowData.id)}
        optionsNew={{
          newButtonText: tKey('Nueva cuenta de gasto'),
          onNew: goToNew,
        }}
        permission={true}
      />
      <TransitionModal
        view={modal.view}
        handleClose={() => {
          setModal({ view: false, body: [] });
        }}
        bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        title={tKey('¡Atención!')}
        handleYes={() => {
          deleteExpenseAccount(id);
          setModal({ view: false, body: [] });
        }}
      />
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  expenseAccount: selectExpensesList(state),
  loading: selectLoading(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getListExpenses: (): void => dispatch(getExpenseAccountList()),
  deleteExpenseAccount: (id: number): void => dispatch(deleteExpenseAccount(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseAccounts);
