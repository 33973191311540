import { AppState } from '../root-reducer';
import { AreasData, AreasState, SubAreaInfo } from './definitions';

export const selectAreas = (state: AppState): AreasState => state.areasReducer;

export const selectIsLoading = (state: AppState): boolean => selectAreas(state).loading;

export const selectIsError = (state: AppState): string | null => selectAreas(state).error;

export const selectAreasList = (state: AppState): AreasData[] => selectAreas(state).areasDataList;

export const selectInternalProjectsAreasList = (state: AppState): AreasData[] =>
  selectAreas(state).areasDataList.filter(area => area.reserved === 1);

export const selectSubAreasList = (state: AppState): SubAreaInfo[] => selectAreas(state).subAreaList;
