import { connect } from 'react-redux';
import {
  addAcademyIban,
  downloadIbanDocument,
  removeIbanDocument,
  setAcademyIbanData,
  uploadIbanDocument,
} from '../../../redux/academy/actions';
import { AcademyIban } from '../../../redux/academy/definitions';
import { AppState } from '../../../redux/root-reducer';
import UnConnectedGenericFormIbanModal from './FormGenericIbanModal';

const FormIbanModal = connect(
  (state: AppState) => ({
    loading: state.academyReducer.loadingModal,
    academyIban: state.academyReducer.ibanData,
    validationErrors: state.academyReducer.validationErrors,
  }),
  (dispatch: any) => ({
    setData: (key: string, value: string | number) => dispatch(setAcademyIbanData(key, value)),
    onUploadFile: (file: File) => dispatch(uploadIbanDocument(file)),
    onRemoveFile: (file_id: number) => dispatch(removeIbanDocument(file_id)),
    onDownloadFile: (file_id: number, filename: string) => dispatch(downloadIbanDocument(file_id, filename)),
    onSave: (academyId: number, academyIban: AcademyIban) => dispatch(addAcademyIban(academyId, academyIban)),
  }),
)(UnConnectedGenericFormIbanModal);
export default FormIbanModal;
