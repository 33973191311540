import { OptionalAddressNames, OptionalAddressSizes } from '../redux/common/definitions';

export const optionalNames: OptionalAddressNames = {
  address_type_name: 'student_address_type',
  address_name: 'student_address',
  flat_name: 'student_flat',
  floor_name: 'student_floor',
  room_name: 'student_room',
  staircase_name: 'student_staircase',
  zip_code_name: 'student_zip_code',
  city_name: 'student_city',
  province_name: 'student_province',
  country_name: 'student_country',
};

export const optionalSizes: OptionalAddressSizes = {
  address_type_size: '50',
  address_size: '50',
  flat_size: '10',
  floor_size: '10',
  room_size: '10',
  staircase_size: '10',
  zip_code_size: '10',
  city_size: '30',
  province_size: '30',
  country_size: '30',
};
