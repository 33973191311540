import React from 'react';
import { connect } from 'react-redux';
import LayoutLogin from '../../components/Layout/LayoutLogin';
import Loading from '../../components/Loading/Loading';
import Subtitle from '../../components/SubTitle/Subtitle';
import { tKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import { selectIsLoading } from '../../redux/auth/selectors';
import { AppState } from '../../redux/root-reducer';
import styles from './educatorPending.module.scss';
import { logoutAction } from '../../redux/auth/actions';

interface EntityActivationProps {
  loading: boolean;
  logoutApp: () => void;
}

const EducadorPending: React.FC<EntityActivationProps> = ({ loading, logoutApp }) => {
  return (
    <LayoutLogin dis_space subtitle="">
      <div>
        {!loading && <Subtitle>{tKey('Registro de educador/a')}</Subtitle>}{' '}
        {loading ? (
          <Loading big />
        ) : (
          <div className={styles.container}>
            <p className={styles.parraf}>{tKey('Solicitud pendiente de validar. ')}</p>
            <p className={styles.parraf}>
              {tKey('Para ir a login haz clic')}{' '}
              <a
                href="/#"
                className={styles[`access_${webStyle}`]}
                onClick={() => {
                  logoutApp();
                }}
              >
                {' '}
                {tKey('aquí')}{' '}
              </a>
            </p>
          </div>
        )}
      </div>
    </LayoutLogin>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectIsLoading(state),
});

const mapDispatchToProps = (dispacth: any) => ({
  logoutApp: (): void => dispacth(logoutAction()),
});

export default connect(mapStateProps, mapDispatchToProps)(EducadorPending);
