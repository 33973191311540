import { UpdateFamilyIncomeConst } from './action_types';
import { UpdateFamilyIncomeAction, UpdateFamilyIncomeActionKo, UpdateFamilyIncomeActionOk } from './definitions';
import { FamilyIncome } from '../FamilyIncome';

export function updateFamilyIncome(income: FamilyIncome): UpdateFamilyIncomeAction {
  return {
    type: UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME,
    income,
  };
}

export function updateFamilyIncomeOk(income: FamilyIncome): UpdateFamilyIncomeActionOk {
  return {
    type: UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME_OK,
    income,
  };
}

export function updateFamilyIncomeKo(error: string): UpdateFamilyIncomeActionKo {
  return {
    type: UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME_KO,
    error,
  };
}
