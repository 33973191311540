import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import GenericFormRefactor, { Fields } from '../../../../../../../components/GenericForm/GenericFormRefactor';
import { tKey } from '../../../../../../../helpers/translate';
import {
  CustomFieldDataTypes,
  CustomFieldDocument,
  CustomFieldElement,
} from '../../../../../../../redux/kpis/definitions';
import { ConfiguratorData } from '../../../../../../../redux/project/definitions';
import styles from './customFieldsPreview.module.scss';

interface CustomFieldsPreviewProps {
  view: boolean;
  formData: ConfiguratorData[];
  handleClose: () => void;
}

const CustomFieldsPreview: React.FC<CustomFieldsPreviewProps> = ({ view, formData, handleClose }) => {
  const fields: Fields = { documents: [] as CustomFieldDocument[], elements: [] as CustomFieldElement[] };
  formData.forEach((e: ConfiguratorData) => {
    const commonProps = {
      ...e,
      options: e.options || [{ id: 1, label: '', name: '', value: '', checked: false }],
      label: e.description,
      name: e.code,
      value: e.description,
      read_only: true,
    } as Partial<CustomFieldDocument | CustomFieldElement>;
    if (e.field_data_types === CustomFieldDataTypes.ADJUNTO) {
      fields.documents.push({ ...commonProps, value_file: [] } as CustomFieldDocument);
    } else {
      fields.elements.push({ ...commonProps, selectedValues: [] } as CustomFieldElement);
    }
  });

  return (
    <Dialog
      open={view}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{tKey('Vista previa')}</DialogTitle>
        <IconButton aria-label="delete" size="small" onClick={() => handleClose()}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        <form className={styles.form}>
          <GenericFormRefactor
            idData={Number(null)}
            fields={fields}
            disabled={true}
            showProjectInfo={false}
            actions={[]}
          />
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CustomFieldsPreview;
