import * as yup from 'yup';
import { requiredNumber, requiredText } from '../../../validations/formSchema';

export const MaterialsSchema = () =>
  yup.object().shape({
    name: yup.string().required(requiredText),
    amount: yup
      .number()
      .required(requiredNumber)
      .typeError(requiredNumber),
  });
