import { Button } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import TextInput from '../../../../components/Inputs/TextInput';
import { updateTranslatedData } from '../../../../redux/translations/actions';
import {
  TranslateData,
  TranslationCategories,
  TranslationKeyDispatch,
} from '../../../../redux/translations/definitions';
import { TranslationSchema } from '../../../../validations/translationSchema';
import styles from './filterItem.module.scss';
import { FilteredData } from './FilterList';

type DataType = TranslateData;

type OptionalParams = {
  category: TranslationCategories;
  key_label: string;
};
interface FilterItemProps {
  lang: string;
  item: FilteredData;
  source: 'keys' | 'custom_fields';
  updated: <T extends DataType>(source: 'keys' | 'custom_fields', data: T, opt: OptionalParams) => void;
}

export const UnconnectedFilterItem: React.FC<FilterItemProps> = ({ item, lang, updated, source }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);
  const { register, handleSubmit } = useForm<TranslateData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: TranslationSchema,
  });
  const onSubmit = (data: TranslateData) => {
    (function updateSelectData() {
      setLoading(true);
      try {
        data.value = data[`value_${lang}`];
        const opt = {
          category: item.category,
          key_label: item.key_label,
        } as OptionalParams;
        updated(source, data, opt);
        item[`value_${lang}`] = data[`value_${lang}`];
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    })();
    setExpanded(!expanded);
  };

  const substring = (text: string) => {
    if (text && text.length > 30) {
      return text.substring(0, 30) + '...';
    } else {
      return text;
    }
  };

  return (
    <div className={styles.containerItem}>
      <div
        className={styles.notExpanded}
        onClick={event => {
          if (!expanded) {
            setExpanded(true);
          }
        }}
      >
        <div className={styles.notExpandedItem}>{substring(item.value_es)}</div>
        {!expanded && <div className={styles.notExpandedItemTextfield}>{substring(item[`value_${lang}`])}</div>}
        {!!expanded && !loading && (
          <form className={styles.form_container} onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label=""
              name={`value_${lang}`}
              defaultValue={item[`value_${lang}`]}
              disabled={false}
              size="100"
              rows="5"
              register={register}
              rowsMax="5"
              multiline
              autoFocus
            />
            <TextInput
              label=""
              name="id"
              defaultValue={item.id}
              disabled={false}
              size="40"
              rows="5"
              register={register}
              visible={false}
            />
            <div className={styles.relativeContainer}>
              <Button variant="text" onClick={() => setExpanded(!expanded)}>
                <ClearIcon style={{ color: 'red' }} className={styles.iconButton} />
              </Button>
              <Button variant="text" type="submit">
                <CheckIcon style={{ color: ' #3a9f1b' }} className={styles.iconButton} />
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

const FilterItem = connect(null, (dispatch: TranslationKeyDispatch) => ({
  updated: (source: 'keys' | 'custom_fields', data: TranslateData, opt: OptionalParams): void =>
    dispatch(updateTranslatedData(source, data, opt)),
}))(UnconnectedFilterItem);

export default FilterItem;
