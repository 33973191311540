import { FeedbackAPIErrorConsts } from './action_types';
import { HideSnackbarAction, SeveritySnackbar, ShowSnackbarAction } from './definitions';

export function showSnackbar(
  message: string,
  severity: SeveritySnackbar,
  redirect?: string,
  autoHideDuration?: number,
): ShowSnackbarAction {
  return {
    type: FeedbackAPIErrorConsts.SHOW_SNACKBAR,
    message,
    severity,
    redirect,
    autoHideDuration,
  };
}

export function hideSnackbar(): HideSnackbarAction {
  return {
    type: FeedbackAPIErrorConsts.HIDE_SNACKBAR,
  };
}
