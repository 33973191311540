import React from 'react';
import { tKey, tScholarshipKey } from '../../helpers/translate';
import styles from './stepperMenu.module.scss';

interface Props {
  activeStep: number;
  state: string;
  totalSteps: number;
  isRenewal?: number;
}

const StepperMenu: React.FC<Props> = ({ activeStep, state, totalSteps, isRenewal }) => (
  <div className={styles.root}>
    <h4 className={styles.title}>
      {tKey('Paso')} <span className={styles.stepNumber}>{activeStep ? activeStep + 1 : 0 + 1}</span> {tKey('de')}{' '}
      <span className={styles.stepNumber}>{totalSteps}</span>&nbsp;- &nbsp;
      <span className={`${styles.status}`}>
        {!!isRenewal ? tScholarshipKey('Renovación') : tScholarshipKey('Nueva beca')}
      </span>
    </h4>
    <div className={styles.stepper}>
      {[...Array(totalSteps)].map((_e, i) => (
        <div key={i} className={activeStep >= i ? `${styles.step} ${styles.filled}` : styles.step}></div>
      ))}
    </div>
    <p className={`${styles.status} ${styles.isDesktop}`}>{tScholarshipKey(state)}</p>
  </div>
);

export default StepperMenu;
