import React from 'react';
import FncLabel from '../FncLabel/FncLabel';
import styles from './fncInputText.module.scss';
import FncControlErrorMessage from '../FncControlErrorMessage/FncControlErrorMessage';

export interface FncInputTextProps extends React.ComponentPropsWithoutRef<'input'> {
  customClass?: string;
  label?: string;
  required?: boolean;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
}

const FncInputText = ({
  customClass,
  label,
  required = false,
  errorMessage,
  disabled = false,
  placeholder,
  ...props
}: FncInputTextProps) => {
  props.id = props?.id || props?.name || `Input_${Math.random()}`;
  return (
    <div className={`${styles.root} ${customClass ? customClass : ''} ${errorMessage ? styles.hasError : ''}`}>
      {label && (
        <FncLabel required={required} htmlFor={props.id}>
          {label}
        </FncLabel>
      )}
      <input className={styles.input} placeholder={placeholder} disabled={disabled} {...props} />
      {errorMessage && <FncControlErrorMessage message={errorMessage} />}
    </div>
  );
};
export default FncInputText;
