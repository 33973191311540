import React, { useState } from 'react';
import FncInputText from '../../Forms/FncInputs/FncInputText';
import styles from './inputSearch.module.scss';
import { tKey } from '../../../helpers/translate';

interface SearchComponentProps {
  onSearch: (searchTerm: string) => void;
}

const InputSearch: React.FC<SearchComponentProps> = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    onSearch(newQuery);
  };

  return (
    <div className={`${styles.root}`}>
      <div className={`${styles.search}`}>
        <FncInputText
          customClass={`${styles.field} ${styles.inputText}`}
          disabled={false}
          maxLength={10}
          onChange={handleSearch}
          type={`search`}
          value={query}
          placeholder={tKey('Buscar')}
        />
        <button type="submit" disabled={true}>
          Search
        </button>
      </div>
    </div>
  );
};

export default InputSearch;
