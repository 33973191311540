import assertUnreachable from '../../helpers/assertUnreachable';
import { CalendarConsts } from './action_types';
import { CalendarState, initialState, InterviewDataAction } from './definitions';

const entityReducer = (state = initialState, action: InterviewDataAction): CalendarState => {
  switch (action.type) {
    case CalendarConsts.GET_ALL_DATES:
      return {
        ...state,
        loadingDates: true,
        loadingTimes: true,
        error: null,
      };
    case CalendarConsts.GET_ALL_DATES_OK:
      const { datesTime } = action;
      const selectedDate = datesTime.dates.find(ele => !!ele.selected)!;

      return {
        ...state,
        loadingDates: false,
        loadingTimes: false,
        error: null,
        datesTime: datesTime,
        selectedDate,
      };
    case CalendarConsts.GET_ALL_DATES_KO:
      return {
        ...state,
        loadingDates: false,
        loadingTimes: false,
        error: action.error,
      };
    case CalendarConsts.SET_TIME_TO_NOT_AVAILABLE:
      return {
        ...state,
        loadingTimes: true,
      };
    case CalendarConsts.SET_TIME_TO_NOT_AVAILABLE_OK:
      state.datesTime?.times.map(ele => {
        if (ele === state.selectedTime) {
          ele.selected = 'not available';
          ele.channel = null;
        }
      });
      return {
        ...state,
        loadingTimes: false,
        responseSetNotAvailable: action.responseSetNotAvailable,
      };
    case CalendarConsts.SET_TIME_TO_NOT_AVAILABLE_KO:
      return {
        ...state,
        loadingTimes: false,
        error: action.error,
      };
    case CalendarConsts.SET_TIME_TO_FREE:
      return {
        ...state,
        loadingTimes: true,
      };
    case CalendarConsts.SET_TIME_TO_FREE_OK:
      state.datesTime?.times.map(ele => {
        if (ele === state.selectedTime) {
          ele.selected = 'free';
          switch (action.channel) {
            case 'online':
              ele.channel = 'online';
              break;
            case 'presencial':
              ele.channel = 'presencial';
              break;
            case 'ambas':
              ele.channel = 'ambas';
              break;
          }
        }
      });
      return {
        ...state,
        loadingTimes: false,
        responseSetFree: action.responseSetFree,
      };
    case CalendarConsts.SET_TIME_TO_FREE_KO:
      return {
        ...state,
        loadingTimes: false,
        error: action.error,
      };
    case CalendarConsts.SET_SELECTED_TIME:
      const { selectedTime } = action;
      return {
        ...state,
        selectedTime,
      };
  }
  assertUnreachable(action);
  return state;
};

export default entityReducer;
