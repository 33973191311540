class AcademicYear {
  constructor(private code: string) {}

  public startYear(): number {
    const years = this.code.split('/');
    return Number(years[0]);
  }

  public finishYear(): number {
    const years = this.code.split('/');
    return Number(years[1]);
  }

  public value(): string {
    return this.code;
  }

  public isBeyond2023(): boolean {
    return this.startYear() > 2023;
  }

  public static beyond2023(): AcademicYear {
    return new AcademicYear('2024/2025');
  }

  public greatherThan(academic_year: AcademicYear): boolean {
    return this.startYear() > academic_year.startYear();
  }

  public lessThan(academic_year: AcademicYear): boolean {
    return this.startYear() < academic_year.startYear();
  }

  public equals(academic_year: AcademicYear): boolean {
    return this.startYear() === academic_year.startYear() && this.finishYear() === academic_year.finishYear();
  }
}

export default AcademicYear;
