import { Info } from '@material-ui/icons';
import React from 'react';
import { tKey } from '../../helpers/translate';
import styles from './messageInfo.module.scss';

interface MessageInfoRawProps {
  children: string;
}

const MessageInfoRaw: React.FC<MessageInfoRawProps> = ({ children }) => (
  <div className={styles.message_raw__container}>
    <Info className={styles.icon} color="primary" /> <p className={styles.message_raw__text}>{tKey(children)}</p>
  </div>
);

export default MessageInfoRaw;
