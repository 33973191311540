import { Delete, Description, Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tFormKey, tKey } from '../../../helpers/translate';
import {
  clearIbanData,
  closeIbanModal,
  downloadIbanDocument,
  getAcademyIban,
  listAcademyIbans,
  openIbanModal,
  removeAcademyIban,
  setAcademyIban,
} from '../../../redux/academy/actions';
import { AcademyDataDispatch, AcademyIban } from '../../../redux/academy/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import FormEditIbanModal from '../Modals/FormEditIbanModal';
import FormNewIbanModal from '../Modals/FormNewIbanModal';
import styles from './ibanlist.module.scss';
interface IbanListProps {
  loading: boolean;
  academyIbans: AcademyIban[];
  academy_id: number;
  ibanModalView: boolean;
  loadingModal: boolean;
  userCan: (permission: permissionType) => boolean | undefined;
  setAcademyIban: (id: number, key: keyof AcademyIban, value: AcademyIban[keyof AcademyIban]) => void;
  listAcademyIbans: (id: number) => void;
  downloadIbanDocument: (file_id: number, name: string) => void;
  openIbanModal: () => void;
  closeIbanModal: () => void;
  getAcademyIban: (academy_id: number, academyIban_id: number) => void;
  clearIbanData: () => void;
  ibanDelete: (academy_id: number, academyIban_id: number) => void;
}

const IbanList: React.FC<IbanListProps> = ({
  loading,
  academyIbans,
  academy_id,
  ibanModalView,
  loadingModal,
  userCan,
  listAcademyIbans,
  downloadIbanDocument,
  openIbanModal,
  closeIbanModal,
  getAcademyIban,
  clearIbanData,
  ibanDelete,
}) => {
  useEffect(() => {
    listAcademyIbans(academy_id);
  }, [academy_id, listAcademyIbans, ibanModalView]);

  const [ibanModal, setIbanModal] = React.useState({
    view: ibanModalView,
    children: <Fragment></Fragment>,
    title: '',
  });

  const edit_iban_action = userCan('allow_edit_academy_iban');

  const onAddIbanOne = () => {
    setIbanModal({
      ...ibanModal,
      children: <FormNewIbanModal academyId={academy_id} isEditing={false} />,
      title: tFormKey('Añadir IBAN'),
    });
    openIbanModal();
  };

  const onEditIbanOne = (rowData: AcademyIban) => {
    getAcademyIban(academy_id, rowData.id);
    setIbanModal({
      ...ibanModal,
      children: <FormEditIbanModal academyId={academy_id} isEditing={true} />,
      title: tFormKey('Editar IBAN'),
    });
    openIbanModal();
  };

  const actionIcons = {
    Edit: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };

  const getActiveOptions = () => {
    const options: Record<string, string> = {
      0: tKey('Inactivo'),
      1: tKey('Activo'),
    };
    return options;
  };

  const tableData: MaterialTableProps<AcademyIban> = {
    title: tKey('Datos contables'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'alias',
      },
      {
        title: tKey('IBAN'),
        field: 'iban',
      },

      {
        field: 'file_id',
        title: tKey('Documento'),
        filtering: false,
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: AcademyIban) =>
          rowData.file_id ? (
            <button
              className={styles.list__downloadButton}
              onClick={e => {
                e.preventDefault();
                if (rowData.file_id) downloadIbanDocument(rowData.file_id, rowData.alias);
              }}
            >
              <Description />
            </button>
          ) : (
            undefined
          ),
      },
      {
        title: tKey('Activo'),
        field: 'active',
        lookup: getActiveOptions(),
        //@ts-ignore
        width: '180px',
        filtering: true,
        render: (rowData: AcademyIban) => (rowData?.active ? tKey('Activado') : tKey('Desactivado')),
      },
    ],
    data: academyIbans,
    isLoading: loading,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Ibans`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    actions: [
      (rowData: AcademyIban) => ({
        icon: actionIcons.Edit,
        onClick: () => onEditIbanOne(rowData),
        tooltip: tKey('editar'),
        disabled: !edit_iban_action,
      }),
      (rowData: AcademyIban) => ({
        icon: actionIcons.Delete,
        onClick: () => ibanDelete(academy_id, rowData.id),
        tooltip: tKey('borrar'),
        disabled: !edit_iban_action,
      }),
    ],
  };

  const goToNew = () => {
    onAddIbanOne();
  };
  if (loading) return <Loading big />;
  return (
    <Fragment>
      <TableWithEdit
        tableData={tableData}
        exportButton
        filtering
        optionsNew={{
          newButtonText: tKey('Añadir IBAN'),
          onNew: goToNew,
        }}
        permission={edit_iban_action}
      />
      {loadingModal ? (
        <Loading big />
      ) : (
        <TransitionModal
          view={ibanModalView}
          handleClose={() => {
            clearIbanData();
            closeIbanModal();
          }}
          title={ibanModal.title}
          bodyModal={ibanModal.children}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.academyReducer.loadingIbanTab,
  loadingModal: state.academyReducer.loadingModal,
  academyIbans: state.academyReducer.academyIbans,
  ibanModalView: state.academyReducer.ibanModalView,
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: AcademyDataDispatch | any) => ({
  listAcademyIbans: (id: number): void => dispatch(listAcademyIbans(id)),
  downloadIbanDocument: (file_id: number, name: string): void => dispatch(downloadIbanDocument(file_id, name)),
  openIbanModal: () => dispatch(openIbanModal()),
  closeIbanModal: () => dispatch(closeIbanModal()),
  setAcademyIban: (id: number, key: keyof AcademyIban, value: AcademyIban[keyof AcademyIban]): void =>
    dispatch(setAcademyIban(id, key, value)),
  getAcademyIban: (academy_id: number, academyIban_id: number) => dispatch(getAcademyIban(academy_id, academyIban_id)),
  clearIbanData: () => dispatch(clearIbanData()),
  ibanDelete: (academy_id: number, academyIban_id: number) => dispatch(removeAcademyIban(academy_id, academyIban_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IbanList);
