import { FormControl, FormHelperText, InputLabel } from '@material-ui/core';
import Select, { SelectProps } from '@material-ui/core/Select';
import React, { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../helpers/customHooks/useIsRequired';
import { tErrorKey } from '../../helpers/translate';
import requiredStyle from '../../helpers/validator.module.scss';
import ClipBoardConnected from '../ClipBoard/ClipBoardConnected';
import { InputSize } from '../Inputs/types';
import styles from './selectInput.module.scss';

export interface SelectControllerProps<T extends FieldValues> extends SelectProps {
  name: string;
  errors: FieldErrors<T>;
  label?: string;
  control: Control<T>;
  children: JSX.Element | JSX.Element[];
  defaultValue?: string | number | null;
  size?: InputSize;
  disabled?: boolean;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  validator?: boolean;
  clipBoard?: boolean;
  onClipBoard?: (value: any) => string;
}

const SelectController = <T extends FieldValues>({
  name,
  errors,
  label,
  control,
  children,
  defaultValue,
  disabled,
  size,
  schema,
  validator,
  clipBoard,
  fullWidth,
  onClipBoard,
  ...props
}: SelectControllerProps<T>): ReactElement => {
  const error = errors[name] as FieldError;
  const required = useIsRequired(name, schema);
  const labelRequired = required && !validator ? `${label} \u00a0*` : label;

  return (
    <React.Fragment>
      <FormControl
        key={name}
        error={!!error}
        variant="outlined"
        classes={{
          root: `${!!validator ? requiredStyle.required : ''} ${!!clipBoard ? styles.hasClipBoard : ''}`,
        }}
        disabled={disabled}
        className={[size && styles[`size_${size}`], disabled ? styles[`disabledColor`] : undefined].join(' ')}
        fullWidth={fullWidth}
      >
        {clipBoard && (
          <div className={styles.clipBoard}>
            <ClipBoardConnected value={defaultValue as string} disabled={!defaultValue} onClipBoard={onClipBoard} />
          </div>
        )}
        <InputLabel id={name}>{labelRequired}</InputLabel>
        <Controller
          as={
            <Select label={labelRequired} fullWidth={fullWidth} {...props}>
              {children}
            </Select>
          }
          name={name}
          control={control}
          defaultValue={defaultValue === null || defaultValue === undefined ? '' : defaultValue}
        />
        {!!error && <FormHelperText>{tErrorKey(error?.message as string)}</FormHelperText>}
      </FormControl>
    </React.Fragment>
  );
};

export default SelectController;
