import { CalendarConsts } from './action_types';
import {
  DatesData,
  GetAllDatesAction,
  GetAllDatesKoAction,
  GetAllDatesOkAction,
  ITime,
  SetSelectedTime,
  SetTimeToFreeAction,
  SetTimeToFreeKoAction,
  SetTimeToFreeOkAction,
  SetTimeToNotAvailableAction,
  SetTimeToNotAvailableKoAction,
  SetTimeToNotAvailableOkAction,
} from './definitions';

export function getAllDates(data: number): GetAllDatesAction {
  return {
    type: CalendarConsts.GET_ALL_DATES,
    data,
  };
}

export function getAllDatesOk(data: DatesData): GetAllDatesOkAction {
  return {
    type: CalendarConsts.GET_ALL_DATES_OK,
    datesTime: data,
  };
}

export function getAllDatesKo(error: string): GetAllDatesKoAction {
  return {
    type: CalendarConsts.GET_ALL_DATES_KO,
    error,
  };
}

export function setTimeToNotAvailable(data: number): SetTimeToNotAvailableAction {
  return {
    type: CalendarConsts.SET_TIME_TO_NOT_AVAILABLE,
    data,
  };
}

export function setTimeToNotAvailableOk(responseSetNotAvailable: string): SetTimeToNotAvailableOkAction {
  return {
    type: CalendarConsts.SET_TIME_TO_NOT_AVAILABLE_OK,
    responseSetNotAvailable,
  };
}

export function setTimeToNotAvailableKo(error: string): SetTimeToNotAvailableKoAction {
  return {
    type: CalendarConsts.SET_TIME_TO_NOT_AVAILABLE_KO,
    error,
  };
}

export function setTimeToFree(data: number, channel: string): SetTimeToFreeAction {
  return {
    type: CalendarConsts.SET_TIME_TO_FREE,
    data,
    channel,
  };
}

export function setTimeToFreeOk(responseSetFree: string, channel: string): SetTimeToFreeOkAction {
  return {
    type: CalendarConsts.SET_TIME_TO_FREE_OK,
    responseSetFree,
    channel,
  };
}

export function setTimeToFreeKo(error: string): SetTimeToFreeKoAction {
  return {
    type: CalendarConsts.SET_TIME_TO_FREE_KO,
    error,
  };
}

export function setSelectedTime(selectedTime: ITime): SetSelectedTime {
  return {
    type: CalendarConsts.SET_SELECTED_TIME,
    selectedTime,
  };
}
