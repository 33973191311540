import { ButtonProps } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import * as H from 'history';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { APIValidationError } from '../../api/api';
import LayoutForm from '../../components/Layout/LayoutForm';
import ActionsMenu from '../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../components/Loading/Loading';
import AvatarImagev2 from '../../components/MenuImageRound/MenuImageRound';
import Title from '../../components/Title/Title';
import useValidatorAPI from '../../helpers/customHooks/useValidatorAPI';
import { tFormKey } from '../../helpers/translate';
import testimage from '../../images/testImage.png';
import { selectUserLang, selectUserRoleCode } from '../../redux/auth/selectors';
import { DocumentationItem, IdCodeNamePair } from '../../redux/common/definitions';
import { GenericMetadata } from '../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../redux/metadata/selectors';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import {
  createUser,
  createUserEducator,
  downloadDocument,
  getDocument,
  initNewUser,
  removeEducatorDocument,
  uploadEducatorDocument,
} from '../../redux/user/actions';
import { CreateUserFields, UserData, UserDataDispatch } from '../../redux/user/definitions';
import {
  selectDocumentation,
  selectIsLoading,
  selectProfile,
  selectUserByRoleGroup,
  selectUserCreated,
  selectUserValidationErrors,
} from '../../redux/user/selectors';
import { CreateUserSchema } from '../../validations/formSchema';
import AccessDataForm from './FormLayouts/AccessDataForm';
import PersonalDataForm from './FormLayouts/PersonalDataForm';
import styles from './users.module.scss';
import RoleCode from '../../types/Roles/roleCode';

interface CreateUserProps {
  loading: boolean;
  profileData: UserData;
  userCreated: boolean;
  roleEditable: boolean;
  genericMetadata: GenericMetadata;
  roles: IdCodeNamePair[];
  defaultLang: string;
  documents: DocumentationItem[];
  validationErrors: APIValidationError | null;
  roleCode: RoleCode;
  initNewUser: () => void;
  createUserApi: (data: CreateUserFields) => void;
  createUserEducator: (data: CreateUserFields) => void;
  uploadUserDocument: (data: File, documentation_id: number, fakeMultiUpload?: boolean) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeEducatorDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
}

const CreateUser: React.FC<CreateUserProps> = ({
  profileData,
  loading,
  roles,
  genericMetadata,
  userCreated,
  validationErrors,
  documents,
  roleEditable,
  defaultLang,
  roleCode,
  createUserApi,
  initNewUser,
  createUserEducator,
  uploadUserDocument,
  downloadUserDocument,
  removeEducatorDocument,
  getUserDocument,
}) => {
  const { errors, handleSubmit, setValue, setError, clearError, triggerValidation, control, watch } = useForm<
    CreateUserFields
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: CreateUserSchema,
  });

  const history = useHistory();

  useEffect(() => {
    initNewUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useValidatorAPI(validationErrors, setError);

  useEffect(() => {
    userCreated && history.push('/usuarios');
  }, [userCreated]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = () => {
    const resArr = [];

    if (documents) {
      documents.forEach(ele => {
        //@ts-ignore
        if (ele.mandatory === 1) {
          if (ele.file_id == null) {
            resArr.push(ele.file_id);
          }
        }
      });
    }

    if (resArr.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (data: CreateUserFields) => {
    if (!Object.keys(errors).length) {
      if (roleCode.isResponsableEntidad()) {
        data.documents = documents;
        createUserEducator(data);
        return;
      }
      createUserApi(data);
    } else {
      for (const e of Object.keys(errors)) {
        // @ts-ignore
        setError(e, errors[e].types, errors[e].message);
      }
    }
  };

  const actionButtons: ButtonProps[] = [
    {
      children: 'Crear usuario',
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
      fullWidth: true,
      startIcon: <PersonIcon />,
      type: 'submit',
      onClick: handleSubmit(onSubmit),
      disabled: isDisabled(),
    },
  ];

  const addDocument = (document: File, description: string, genericId?: number, documentation_id?: number) => {
    if (documentation_id) {
      uploadUserDocument(document, documentation_id, true);
    }
  };

  const actions = ['upload', 'checked', 'download', 'remove'];
  return (
    <LayoutForm
      rightSubmenu={<ActionsMenu actionsButtons={actionButtons} />}
      leftSubmenu={<AvatarImagev2 imageLink={testimage} />}
    >
      <React.Fragment>
        {loading ? (
          <Loading big />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className={loading ? styles.hidden : undefined}>
            <Title>{tFormKey('CREAR USUARIO')}</Title>
            <AccessDataForm
              profileData={profileData}
              control={control}
              errors={errors}
              createMode
              schema={CreateUserSchema}
            />
            <PersonalDataForm
              setError={setError}
              clearError={clearError}
              profileData={profileData}
              genericMetadata={genericMetadata}
              errors={errors}
              roles={roles}
              setValue={setValue}
              schema={CreateUserSchema}
              createMode
              triggerValidation={triggerValidation}
              control={control}
              watch={watch}
              uploadDocument={uploadUserDocument}
              downloadDocument={downloadUserDocument}
              removeDocument={removeEducatorDocument}
              actions={actions}
              getDocument={getUserDocument}
              documentation={documents}
              addDoc={addDocument}
              roleEditable={roleEditable}
              defaultLang={defaultLang}
            />
          </form>
        )}
      </React.Fragment>
    </LayoutForm>
  );
};

interface OwnProps {
  location: H.Location<{ type: string }>;
}

const mapStateToProps = (
  state: AppState,
  {
    location: {
      state: { type },
    },
  }: OwnProps,
) => ({
  loading: selectIsLoading(state),
  validationErrors: selectUserValidationErrors(state),
  roleCode: selectUserRoleCode(state),
  profileData: selectProfile(state),
  roles: selectUserByRoleGroup(state, type),
  genericMetadata: selectGenericMetadata(state),
  userCreated: selectUserCreated(state),
  documents: type !== 'academy' ? selectDocumentation(state) : [],
  roleEditable: selectUserCan(state)('get_staff_users'),
  defaultLang: selectUserLang(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  initNewUser: (): void => dispatch(initNewUser()),
  createUserApi: (data: CreateUserFields): void => dispatch(createUser(data)),
  createUserEducator: (data: CreateUserFields): void => dispatch(createUserEducator(data)),
  uploadUserDocument: (data: File, documentation_id: number, fakeMultiUpload?: boolean): void =>
    dispatch(uploadEducatorDocument(data, documentation_id, fakeMultiUpload)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeEducatorDocument: (file_id: number): void => dispatch(removeEducatorDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
