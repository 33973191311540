import { GetInterviewsCalendar } from '../../redux/calendar-scholarship/definitions';
import { DocumentationItem } from '../../redux/common/definitions';
import { InterviewEntity, InterviewTableRow } from '../../redux/interviews/definitions';
import { apiBaseUrl, apiCall, apiCallBlob } from '../api';

const baseURL = apiBaseUrl;

interface DocumentsData {
  documents: DocumentationItem[];
}

interface DocumentsResponse {
  data: DocumentsData;
}

export const getInterviewTableData = async (): Promise<{ data: InterviewTableRow[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<{ data: InterviewTableRow[] }>(`${baseURL}/interviewer/pending_interviews`, init);
};

export const getInterviewFinishedTableData = async (): Promise<{ data: InterviewTableRow[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<{ data: InterviewTableRow[] }>(`${baseURL}/interviewer/finished_interviews`, init);
};

// DOWNLOAD DOCUMENT

export const downloadInterviewDocument = async (documentation_id: number, scholarshipId: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/scholarship/document/download/${scholarshipId}/${documentation_id}`, init);
};

// DELETE DOCUMENT

export const deleteInterviewDocument = async (scholarshipId: number, file_id: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(
    `${baseURL}/scholarship/document/${scholarshipId}/${file_id}`,
    init,
  );
  return data;
};

export const getInterviewEntitiesFromApi = async (zip_code: string): Promise<InterviewEntity[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: InterviewEntity[] }>(`${baseURL}/interview/entities/${zip_code}`, init);
  return data;
};

export const getFreeInterviewSlotsFromApi = async (
  scholarship_id: number,
  date: number,
): Promise<GetInterviewsCalendar[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: GetInterviewsCalendar[] }>(
    `${baseURL}/interview/scholarships/${scholarship_id}/free/${date}`,
    init,
  );
  return data;
};
