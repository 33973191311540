import assertUnreachable from '../../helpers/assertUnreachable';
import { SearchConsts } from './action_types';
import { initialState, SearchAction, SearchState } from './definitions';

const searchReducer = (state = initialState, action: SearchAction): SearchState => {
  switch (action.type) {
    case SearchConsts.SEARCH_GET_DATA:
    case SearchConsts.SEARCH_GET_RELATIONAL_DATA:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case SearchConsts.SEARCH_GET_DATA_KO:
    case SearchConsts.SEARCH_GET_RELATIONAL_DATA_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SearchConsts.SEARCH_GET_RELATIONAL_DATA_OK:
      return {
        ...state,
        searchRelationalData: action.searchRelationalData,
        loading: false,
      };
    case SearchConsts.SEARCH_GET_DATA_OK:
      return {
        ...state,
        searchResponse: action.searchResponse,
        loading: false,
      };
  }
  assertUnreachable(action);
  return state;
};

export default searchReducer;
