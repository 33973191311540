import { MaterialTableProps } from 'material-table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TransitionsModal from '../../../../../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../../../../../components/Tables/TableWithEdit';
import Title from '../../../../../../../components/Title/Title';
import { tKey } from '../../../../../../../helpers/translate';
import {
  Educator,
  EducatorId,
  InternalProjectId,
} from '../../../../../../../redux/internal-projects/educators/Educator';
import { deleteEducator, getEducators } from '../../../../../../../redux/internal-projects/educators/action';
import {
  selectEducatorLoading,
  selectEducatorsList,
  selectEducatorById,
} from '../../../../../../../redux/internal-projects/educators/selectors';
import { AppState } from '../../../../../../../redux/root-reducer';
import NewEducator from './Educators/NewEducator';
import { NewEditEducatorViewModel } from './Educators/NewEditEducatorViewModel';
import styles from './seguimientoInicialKoaForm.module.scss';
import { ActionIcons } from '../../../../../../../components/Icons/ActionIcons';
interface OwnProps {
  internal_project_id: InternalProjectId;
}

interface StateProps {
  loading: boolean;
  educators: Educator[];
  getEducatorById: (id: EducatorId) => Educator | null;
}

interface DispatchProps {
  getEducators: (id: InternalProjectId) => void;
  deleteEducator: (id: InternalProjectId, educatorId: EducatorId) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const SeguimientoInicialKoaEducadores = ({
  loading,
  internal_project_id,
  educators,
  getEducators,
  deleteEducator,
  getEducatorById,
}: Props) => {
  const [showModalNew, setShowModalNew] = useState(false);

  useEffect(() => {
    getEducators(internal_project_id);
  }, [getEducators, internal_project_id]);

  const [modal, setModal] = useState(false);
  const [id, setId] = useState<EducatorId>('');
  const [selectedEducator, setSelectedEducator] = useState<Educator | null>(null);

  const delItem = (id: EducatorId) => {
    setId(id);
    setModal(true);
  };

  const tableData: MaterialTableProps<Educator> = {
    title: tKey('Listado de educadores'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'user_name',
      },
      {
        title: tKey('Email'),
        field: 'email',
      },
    ],
    actions: [
      (rowData: Educator) => ({
        icon: ActionIcons.EditIcon,
        tooltip: tKey('Editar'),
        onClick: () => {
          setSelectedEducator(getEducatorById(rowData.id));
          setShowModalNew(true);
        },
      }),
      (rowData: Educator) => ({
        icon: ActionIcons.DeleteIcon,
        tooltip: tKey('Eliminar'),
        onClick: () => delItem(rowData.id),
      }),
    ],
    data: educators,
    isLoading: loading,
    options: {
      selection: false,
      paging: false,
      tableLayout: 'fixed',
      exportButton: true,
    },
  };

  const addNewEducator = () => {
    setSelectedEducator(null);
    setShowModalNew(true);
  };

  return (
    <div className={styles.koa_table_container}>
      <Title>{tKey('Educadores')}</Title>
      <TableWithEdit
        customContainer
        permission
        tableData={tableData}
        filtering
        optionsNew={{
          newButtonText: tKey('Añadir'),
          onNew: addNewEducator,
        }}
      />
      <>
        <TransitionsModal
          view={modal}
          title="Eliminar educador"
          handleClose={() => {
            setModal(false);
          }}
          bodyModal={tKey('¿Seguro que deseas eliminar el Educador?')}
          buttonOk={tKey('Sí')}
          buttonKo={tKey('No')}
          handleYes={() => {
            deleteEducator(internal_project_id, id);
            setModal(false);
          }}
        />
        <NewEducator
          internal_project_id={internal_project_id}
          showModal={showModalNew}
          onCloseModal={() => {
            setShowModalNew(false);
          }}
          onAddNewEducator={() => {
            setShowModalNew(false);
          }}
          educator={selectedEducator}
          viewModel={NewEditEducatorViewModel}
        />
      </>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  loading: selectEducatorLoading(state),
  educators: selectEducatorsList(state),
  getEducatorById: (id: EducatorId) => selectEducatorById(state, id),
});

const mapDispatchToProps = (dispatch: any) => ({
  getEducators: (id: InternalProjectId) => dispatch(getEducators(id)),
  deleteEducator: (internalProjectId: InternalProjectId, educatorId: EducatorId) =>
    dispatch(deleteEducator(internalProjectId, educatorId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeguimientoInicialKoaEducadores);
