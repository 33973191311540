import { ButtonProps, InputLabel, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtons from '../../../../components/RadioButtons/RadioButtons';
import SelectController from '../../../../components/Select/SelectController';
import Title from '../../../../components/Title/Title';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { documentationFilter } from '../../../../helpers/docFilter';
import { tScholarshipKey } from '../../../../helpers/translate';
import { validateFields } from '../../../../helpers/validator';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import {
  scholarshipSetMotivationalInfoData,
  scholarshipSubmitInformation,
} from '../../../../redux/scholarship/actions';
import { ScholarshipData, ScholarshipMetadata, ScholarshipSteps } from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentation,
  selectGetScholarshipMetadata,
  selectScholarshipValidationErrors,
} from '../../../../redux/scholarship/selectors';
import { entityMotivationalValoration } from '../../../../validations/scholarshipSchema';
import styles from './requestForms.module.scss';

interface MotivationalValorationFormProps {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  documentation: DocumentationItem[];
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  validationErrors: APIValidationError | null;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
}

const MotivationalValorationForm: React.FC<MotivationalValorationFormProps> = ({
  scholarshipData,
  scholarshipMetadata,
  documentation,
  readOnly,
  buttonActionSave,
  validationErrors,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
  setData,
  saveData,
}) => {
  const {
    educator_reason,
    linked_periods,
    last_year_info,
    previous_year_highlights,
    academic_return,
    linked_periods_comments,
    is_renewal,
    has_academic_return,
    isEntity,
    scholarshipType,
  } = scholarshipData;
  const { t } = useTranslation();
  const actions = ['checked', 'upload', 'download', 'view', 'remove'];

  const { handleSubmit, errors, setError, control } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: entityMotivationalValoration,
  });

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const renderMotivationalInfo = (): JSX.Element => {
    return (
      <div className={styles.motivational_info}>
        <span>{`* ${tScholarshipKey('carta-motivación-manuscríta')}`}</span>
      </div>
    );
  };
  useValidatorAPI(validationErrors, setError);

  const saveMotivationalValoration = () => {
    saveData(scholarshipData);
  };

  useButtonAction(buttonActionSave, handleSubmit(saveMotivationalValoration));

  const validatorInfo = {
    data: scholarshipData,
    step: 'motivationalInformation' as keyof ScholarshipSteps,
  };

  const yesNoOptions = [
    { value: '0', label: tScholarshipKey('No') },
    { value: '1', label: tScholarshipKey('Si') },
  ];
  const documentCodes = ['MOT'];
  let entityFields;
  let renewalFields;
  let documentsInfo;

  if (isEntity) {
    entityFields = (
      <>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey('propuesta-zinger')}
          </InputLabel>
          <TextInputController
            control={control}
            errors={errors}
            defaultValue={educator_reason}
            disabled={readOnly}
            schema={entityMotivationalValoration}
            validator={validateFields(scholarshipData, 'educator_reason', validatorInfo.step)}
            name="educator_reason"
            size="100"
            multiline
            rows="4"
            rowsMax="10"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('educator_reason', e.target.value)}
          />
        </div>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey('vinculación-entidad')}
          </InputLabel>
          <SelectController
            control={control}
            name="linked_periods"
            defaultValue={linked_periods}
            size="50"
            schema={entityMotivationalValoration}
            validator={validateFields(scholarshipData, 'linked_periods', validatorInfo.step)}
            errors={errors}
            disabled={readOnly}
            onClick={e => handleSelectChange(e.target)}
          >
            {scholarshipMetadata.linkedPeriods.map((element: string) => (
              <MenuItem key={element} value={element}>
                {element}
              </MenuItem>
            ))}
          </SelectController>
        </div>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey('¿De qué forma está vinculado/a a vuestra entidad?')}
          </InputLabel>
          <TextInputController
            errors={errors}
            defaultValue={linked_periods_comments}
            disabled={readOnly}
            schema={entityMotivationalValoration}
            control={control}
            validator={validateFields(scholarshipData, 'linked_periods_comments', validatorInfo.step)}
            name="linked_periods_comments"
            multiline
            rows="4"
            rowsMax="10"
            size="100"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('linked_periods_comments', e.target.value)}
          />
        </div>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey('cursado-último-curso')}
          </InputLabel>
          <TextInputController
            errors={errors}
            defaultValue={last_year_info}
            disabled={readOnly}
            schema={entityMotivationalValoration}
            control={control}
            validator={validateFields(scholarshipData, 'last_year_info', validatorInfo.step)}
            name="last_year_info"
            multiline
            rows="4"
            rowsMax="10"
            size="100"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('last_year_info', e.target.value)}
          />
        </div>
      </>
    );
  } else {
    documentCodes.push('CRI');
  }

  if (is_renewal) {
    renewalFields = (
      <React.Fragment>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey(
              tScholarshipKey(
                t(`scholarship.${scholarshipType}.movitational_valoration_form.previous_year_highlights`),
              ),
            )}
          </InputLabel>
          <TextInputController
            errors={errors}
            defaultValue={previous_year_highlights}
            disabled={readOnly}
            schema={entityMotivationalValoration}
            control={control}
            validator={validateFields(scholarshipData, 'previous_year_highlights', validatorInfo.step)}
            name="previous_year_highlights"
            multiline
            rows="4"
            rowsMax="10"
            size="100"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('previous_year_highlights', e.target.value)}
          />
        </div>
        <div className={styles.compoundItem}>
          <RadioButtons
            name="has_academic_return"
            disabled={readOnly}
            questionText={tScholarshipKey(
              tScholarshipKey(t(`scholarship.${scholarshipType}.movitational_valoration_form.has_academic_return`)),
            )}
            defaultValue={has_academic_return?.toString()}
            options={yesNoOptions}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setData('has_academic_return', +e.target.value)}
          />
        </div>
        <div className={styles.compoundItem}>
          <InputLabel className={`${styles.margin_top} ${styles.label}`}>
            {tScholarshipKey(
              tScholarshipKey(t(`scholarship.${scholarshipType}.movitational_valoration_form.academic_return`)),
            )}
          </InputLabel>
          <TextInputController
            errors={errors}
            defaultValue={academic_return}
            schema={entityMotivationalValoration}
            control={control}
            validator={validateFields(scholarshipData, 'academic_return', validatorInfo.step)}
            name="academic_return"
            multiline
            rows="4"
            rowsMax="10"
            size="100"
            disabled={readOnly || !(has_academic_return || false)}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('academic_return', e.target.value)}
          />
        </div>
      </React.Fragment>
    );
  }

  const documentationFiltered = documentationFilter(documentation, documentCodes);

  if (documentationFiltered.length > 0) {
    documentsInfo = (
      <>
        <FncAddDocuments
          title={tScholarshipKey('Documentos')}
          documents={documentationFiltered}
          getFile={getFile}
          actions={actions}
          disabled={readOnly}
          upload={uploadDocument}
          genericId={scholarshipData.id}
          download={downloadDocument}
          remove={deleteDocument}
          multidocument
          addDoc={addDoc}
          required
        />
        {renderMotivationalInfo()}
      </>
    );
  }

  return (
    <form onSubmit={e => e.preventDefault()} className={styles.form}>
      <Title>{tScholarshipKey('solicitud-motivacional')}</Title>
      {entityFields}
      {renewalFields}
      {documentsInfo}
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  documentation: selectGetScholarshipDocumentation(state),
  validationErrors: selectScholarshipValidationErrors(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetMotivationalInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MotivationalValorationForm);
