import { ParticipantsAssistanceConst } from './action_types';
import { ParticipantsAssistanceDataAction, ParticipantsAssistanceState, initialState } from './definitions.d';
import assertUnreachable from '../../../helpers/assertUnreachable';

const participantsAssistanceReducer = (
  state = initialState,
  action: ParticipantsAssistanceDataAction,
): ParticipantsAssistanceState => {
  switch (action.type) {
    case ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE:
      return {
        ...state,
        loading: true,
        error: null,
        assistance: [],
      };
    case ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE_OK:
      const { infoUpdate } = action;

      const assistance = state.assistance.map(assistance => {
        if (assistance.internal_project_participant_id === infoUpdate.internal_project_participant_id) {
          assistance.assistance = infoUpdate.assistance;
          assistance.reasons = infoUpdate.reasons;
          assistance.other_reasons = infoUpdate.other_reasons;
        }
        return { ...assistance };
      });

      return {
        ...state,
        loading: false,
        assistance,
      };
    case ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE_OK: {
      const { assistance } = action;

      return {
        ...state,
        loading: false,
        error: null,
        assistance,
      };
    }
    case ParticipantsAssistanceConst.GET_PARTICIPANTS_ASSISTANCE_KO:
    case ParticipantsAssistanceConst.UPDATE_PARTICIPANTS_ASSISTANCE_KO: {
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      assertUnreachable(action);
      return state;
  }
};

export default participantsAssistanceReducer;
