import { Checkbox, Switch } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import requiredStyle from '../../helpers/validator.module.scss';
import styles from './checkBoxCompo.module.scss';

interface CheckboxProps {
  questionText: string;
  name: string;
  register?: (instance: any) => void;
  disabled?: boolean;
  defaultValue?: string | number | null;
  validator?: boolean;
  form?: boolean;
  type?: 'switch' | 'checkbox';
  onChange?: (value: boolean) => void;
}

const CheckboxCompo: React.FC<CheckboxProps> = ({
  questionText,
  name,
  disabled,
  register,
  defaultValue,
  type = 'switch',
  validator,
  form,
  onChange,
  ...props
}) => {
  const [checked, setChecked] = React.useState(
    defaultValue === 1 || defaultValue === '1' || defaultValue === 'true' || defaultValue ? true : false,
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    setChecked(value);
    onChange && onChange(value);
  };
  return (
    <div className={form ? styles.checkForm : styles.checkContainer}>
      <FormControlLabel
        inputRef={register}
        disabled={disabled}
        classes={{
          label: styles.label,
        }}
        // control={<Checkbox name={name} checked={checked} onChange={handleChange} />}
        control={
          type === 'switch' ? (
            <Switch checked={checked} onChange={handleChange} name={name} color="primary" />
          ) : (
            <Checkbox name={name} checked={checked} onChange={handleChange} color="primary" />
          )
        }
        label={
          !!validator ? (
            <React.Fragment>
              <span className={requiredStyle.requiredCheckbox}>* </span>
              {questionText}
            </React.Fragment>
          ) : (
            <span>{questionText}</span>
          )
        }
        {...props}
      />
    </div>
  );
};

export default CheckboxCompo;
