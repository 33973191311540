import assertUnreachable from '../../helpers/assertUnreachable';
import { CustomFieldsConst } from './action_types';
import { CustomField, CustomFieldAction, CustomFieldState, initialState, KpiIndicatorType } from './definitions';

const emptyKpiType = { id: 0, description: '' } as KpiIndicatorType;

const customFieldReducer = (state = initialState, action: CustomFieldAction): CustomFieldState => {
  switch (action.type) {
    case CustomFieldsConst.GET_CUSTOMFIELDS_LIST:
    case CustomFieldsConst.DELETE_CUSTOMFIELD:
    case CustomFieldsConst.GET_KPI_INIDICATORS:
    case CustomFieldsConst.CREATE_KPI_INIDICATOR:
    case CustomFieldsConst.UPDATE_KPI_INIDICATOR:
    case CustomFieldsConst.REMOVE_KPI_INIDICATOR:
    case CustomFieldsConst.CREATE_CUSTOMFIELD:
    case CustomFieldsConst.EDIT_CUSTOMFIELD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CustomFieldsConst.ASSIGMENTS:
    case CustomFieldsConst.ASSIGMENTS_OK:
    case CustomFieldsConst.UNASSIGMENTS_OK:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CustomFieldsConst.GET_ONE_CUSTOMFIELDS:
      return {
        ...state,
        customFieldLoading: true,
        error: null,
      };
    case CustomFieldsConst.GET_CUSTOMFIELDS_LIST_OK:
      const { listCustomFields } = action;
      return {
        ...state,
        listCustomFields,
        loading: false,
        error: null,
        customField: {} as CustomField,
      };
    case CustomFieldsConst.CREATE_CUSTOMFIELD_OK:
    case CustomFieldsConst.EDIT_CUSTOMFIELD_OK:
      const { data } = action;
      return {
        ...state,
        loading: false,
        customField: data,
      };
    case CustomFieldsConst.DELETE_CUSTOMFIELD_OK:
      return {
        ...state,
        loading: false,
        listCustomFields: state.listCustomFields.filter(customField => customField.id !== +action.id),
      };
    case CustomFieldsConst.GET_ONE_CUSTOMFIELDS_OK:
      const { customField } = action;
      return {
        ...state,
        customField: {
          ...customField,
          kpi_name: state.kpiTypes.find(kpi => kpi.id === customField.kpi_indicator_type_id)?.description,
          /*           ref: customField?.ref?.description,
          refTotal: customField?.refTotal?.description, */
        },
        customFieldLoading: false,
        error: null,
      };
    case CustomFieldsConst.CUSTOMFIELDS_GENERIC_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    case CustomFieldsConst.CUSTOMFIELD_VALIDATION_ERROR:
      return {
        ...state,
        loading: false,
        validationErrors: action.validationErrors,
      };
    case CustomFieldsConst.UNASSIGMENTS:
      return {
        ...state,
      };
    case CustomFieldsConst.UPDATE_KPI_INIDICATOR_OK:
    case CustomFieldsConst.CREATE_KPI_INIDICATOR_OK:
    case CustomFieldsConst.GET_KPI_INIDICATORS_OK:
      const { kpiTypes } = action;
      return {
        ...state,
        loading: false,
        kpiTypes,
        currentKpiType: emptyKpiType,
        showModal: false,
      };
    case CustomFieldsConst.GET_KPI_INIDICATOR:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CustomFieldsConst.ADD_KPI_INIDICATOR:
      return {
        ...state,
        currentKpiType: emptyKpiType,
        showModal: true,
        error: null,
      };
    case CustomFieldsConst.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        loading: false,
        error: null,
      };
    case CustomFieldsConst.GET_KPI_INIDICATOR_OK:
      return {
        ...state,
        loading: false,
        showModal: true,
        currentKpiType: action.kpiType,
      };
    case CustomFieldsConst.REMOVE_KPI_INIDICATOR_OK:
      const kpiTypesAfterRemove = state.kpiTypes.filter((e: KpiIndicatorType) => e.id !== action.id);
      return {
        ...state,
        kpiTypes: kpiTypesAfterRemove,
        loading: false,
      };
    case CustomFieldsConst.SET_KPI_INIDICATOR_DATA:
      return {
        ...state,
        currentKpiType: {
          ...state.currentKpiType,
          [action.key]: action.value,
        },
      };
    case CustomFieldsConst.DELETE_CUSTOMFIELD_DEACTIVATE:
      return {
        ...state,
        loading: false,
        error: null,
        idCustomFieldDeactivating: action.id,
      };
    case CustomFieldsConst.CLEAR_CUSTOMFIELD_DEACTIVATION:
      return {
        ...state,
        idCustomFieldDeactivating: 0,
      };
    case CustomFieldsConst.DEACTIVATE_CUSTOMFIELD:
      return {
        ...state,
        loading: true,
        idCustomFieldDeactivating: 0,
      };
    case CustomFieldsConst.DEACTIVATE_CUSTOMFIELD_OK:
      return {
        ...state,
        loading: false,
        idCustomFieldDeactivating: 0,
        listCustomFields: state.listCustomFields.filter(customField => customField.id !== +action.id),
      };
    case CustomFieldsConst.DEACTIVATE_CUSTOMFIELD_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
        idCustomFieldDeactivating: 0,
      };
  }
  assertUnreachable(action);
  return state;
};

export default customFieldReducer;
