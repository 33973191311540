import React from 'react';
import LayoutForm from '../../components/Layout/LayoutForm';
import webStyle from '../../helpers/webStyle';
import styles from './page404.module.scss';

const page404: React.FC = () => {
  return (
    <LayoutForm layoutDisabled>
      <h1 className={styles[`title_${webStyle}`]}>Página no encontrada</h1>
    </LayoutForm>
  );
};

export default page404;
