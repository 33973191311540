export enum CalendarInterviewsConst {
  GET_INTERVIEWS_DATES = 'GET:INTERVIEWS:DATES',
  GET_INTERVIEWS_DATES_OK = 'GET:INTERVIEWS:DATES:OK',
  GET_INTERVIEWS_DATES_KO = 'GET:INTERVIEWS:XDATES:KO',

  SCHOLARSHIP_ASK_INTERVIEWS = 'SCHOLARSHIP:ASK:INTERVIEWS',
  SCHOLARSHIP_ASK_INTERVIEWS_OK = 'SCHOLARSHIP:ASK:INTERVIEWS:OK',
  SCHOLARSHIP_ASK_INTERVIEWS_KO = 'SCHOLARSHIP:INTERVIEWS:ASK:KO',

  ID_SCHOLARSHIP_GET = 'ID:SCHOLARSHIP:STUDENT:GET',
  ID_SCHOLARSHIP_GET_OK = 'ID:SCHOLARSHIP:STUDENT:GET:OK',
  ID_SCHOLARSHIP_GET_KO = 'ID:SCHOLARSHIP:STUDENT:GET:KO',
}
