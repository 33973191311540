import React from 'react';
import { connect } from 'react-redux';
import { permissionType } from '../../../constants/permissions_definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import ProjectList from './ProjectList';

interface ProjectPageProps {
  userCan: (permission: permissionType) => boolean | undefined;
}

const Projects: React.FC<ProjectPageProps> = ({ userCan }) => {
  const action_pma = userCan('action_pma');
  const action_dir = userCan('action_dir');

  return <ProjectList permission={!!(action_pma || action_dir)} />;
};
const mapStateToProps = (state: AppState) => ({
  userCan: selectUserCan(state),
});

export default connect(mapStateToProps, undefined)(Projects);
