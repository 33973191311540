export type WebStyle = 'zing' | 'fnc';

const computeWebStyle = (): WebStyle => {
  switch (document.location.hostname) {
    case 'plataforma.zingprogramme.com':
    case 'test.plataforma.zingprogramme.com':
    case 'develop.plataforma.zingprogramme.com':
    case 'development.env.plataforma.zingprogramme.com':
    case 'staging.env.plataforma.zingprogramme.com':
    case 'zing.nouscims.local':
    case 'plataforma.zing.local':
      return 'zing';
    default:
      return 'fnc';
  }
};

export const alternativeHost = (): string => {
  switch (document.location.hostname) {
    case 'plataforma.zingprogramme.com':
      return 'plataforma.nouscims.com';
    case 'plataforma.nouscims.com':
      return 'plataforma.zingprogramme.com';
    case 'test.plataforma.zingprogramme.com':
      return 'test.plataforma.nouscims.com';
    case 'test.plataforma.nouscims.com':
      return 'test.plataforma.zingprogramme.com';
    case 'develop.plataforma.zingprogramme.com':
      return 'develop.plataforma.nouscims.com';
    case 'develop.plataforma.nouscims.com':
      return 'develop.plataforma.zingprogramme.com';
    case 'development.env.plataforma.zingprogramme.com':
      return 'development.env.plataforma.nouscims.com';
    case 'development.env.plataforma.nouscims.com':
      return 'development.env.plataforma.zingprogramme.com';
    case 'staging.env.plataforma.zingprogramme.com':
      return 'staging.env.plataforma.nouscims.com';
    case 'staging.env.plataforma.nouscims.com':
      return 'staging.env.plataforma.zingprogramme.com';
    case 'zing.nouscims.local':
      return 'frontend.nouscims.local:3000';
    default:
      return 'zing.nouscims.local:3000';
  }
};

export const alternativeURL = (): string => {
  const host = alternativeHost();
  if (host.includes('local')) {
    return 'http://'.concat(host);
  } else {
    return 'https://'.concat(host);
  }
};

export default computeWebStyle();
