import React from 'react';
import { DateRangeOutlined, SettingsOutlined, CropSquareOutlined } from '@material-ui/icons';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';

interface SubmenuInternalProjectsProps {
  canConfigure: boolean;
  selected: string;
  submenu: string;
  onClickOption?: () => void;
}

const SubmenuInternalProjects: React.FC<SubmenuInternalProjectsProps> = ({
  canConfigure,
  selected,
  submenu,
  onClickOption,
}) => {
  const optionsMenu: SubmenuItem[] = [];
  if (canConfigure) {
    optionsMenu.push({
      title: tKey('Configuración fecha fin de curso'),
      icon: <DateRangeOutlined />,
      type: 'route',
      route: '/configuracion/proyectos-internos/fecha-fin-curso',
      selected: selected === 'fecha-fin-curso',
    });
    optionsMenu.push({
      title: tKey('Configuración de campos'),
      icon: <SettingsOutlined />,
      type: 'route',
      route: '/configuracion/proyectos-internos/configuracion-campos/pi_info_general/config',
      selected: selected === 'config',
    });
    optionsMenu.push({
      title: tKey('Plantillas'),
      icon: <CropSquareOutlined />,
      type: 'route',
      route: '/configuracion/proyectos-internos/plantillas',
      selected: selected === 'plantillas',
    });
  }

  return (
    <Submenu
      titleMenu={tKey('PROYECTOS INTERNOS')}
      optionsMenu={optionsMenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
      onClickOption={onClickOption}
    />
  );
};

export default SubmenuInternalProjects;
