// import Logo from '../images/logos/logo.png';
import headerImg from '../images/header/header-bg-2.png';
import LogoZingBlack from '../images/logos/logo_zing.png';
import LogoZingWhite from '../images/logos/logo_zing_white.png';
import LogoFncBlack from '../images/logos/logo-rojo.png';
import LogoFncWhite from '../images/logos/logo.png';
import assertUnreachable from './assertUnreachable';
import webStyle from './webStyle';

export const getImageHeader = (imageType: 'scholarship' | 'project' | 'general') => {
  switch (imageType) {
    case 'general':
      return headerImg;
    case 'project':
      return headerImg;
    case 'scholarship':
      return headerImg;
  }
  assertUnreachable(imageType);
};

export const getLogo = (imageType: 'scholarship' | 'project' | 'general', color: 'white' | 'black') => {
  switch (imageType) {
    case 'general':
      if (webStyle === 'fnc') {
        return color === 'black' ? LogoFncBlack : LogoFncWhite;
      } else {
        // Zing programme always white
        return LogoZingWhite;
        // return color === 'black' ? LogoZingBlack : LogoZingWhite;
      }
    case 'project':
      return color === 'black' ? LogoFncBlack : LogoFncWhite;
    case 'scholarship':
      return color === 'black' ? LogoZingBlack : LogoZingWhite;
  }
  assertUnreachable(imageType);
};

export const convertFileToBase64 = async (image: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};

export const convertBase64ImageToFile = (image: string, imageName: string): File => {
  const imgSplited = image.split(',');
  let mime = imgSplited[0].match(/:(.*?);/) || '';
  if (mime) mime = mime[1];
  const bstr = atob(imgSplited[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) u8arr[n] = bstr.charCodeAt(n);
  return new File([u8arr], imageName, { type: mime });
};

export const convertBase64ImageToBlob = (image: string): Blob => {
  const pos = image.indexOf(';base64,');
  const imageType = image.substring(5, pos);
  const b64 = image.substr(pos + 8);
  const imageContent = atob(b64);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);
  // decoded base64
  for (let n = 0; n < imageContent.length; n++) view[n] = imageContent.charCodeAt(n);
  return new Blob([buffer], { type: imageType });
};

export const convertBlobToFile = (blob: Blob, fileName: string): Blob => {
  return new File([blob], fileName, { lastModified: new Date().getTime(), type: blob.type });
};
