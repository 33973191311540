import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as DateConstants from '../../../../../../../constants/date';
import {
  InternalProjectId,
  Participant,
  ParticipantId,
} from '../../../../../../../redux/internal-projects/participants/Participant';
import { AppState } from '../../../../../../../redux/root-reducer';
import TableWithEdit from '../../../../../../../components/Tables/TableWithEdit';
import { tKey, tProjectKey } from '../../../../../../../helpers/translate';
import { Column, MaterialTableProps } from 'material-table';
import Title from '../../../../../../../components/Title/Title';
import TransitionsModal from '../../../../../../../components/Modal/TransitionModal';
import { deleteParticipant } from '../../../../../../../redux/internal-projects/participants/delete/action';
import {
  selectParticipantById,
  selectParticipantsList,
  selectParticipantsLoading,
} from '../../../../../../../redux/internal-projects/participants/list/selectors';
import { getParticipants } from '../../../../../../../redux/internal-projects/participants/list/action';
import { Tooltip } from '@material-ui/core';
import styles from './seguimientoInicialKoaForm.module.scss';
import NewParticipant from './Participants/NewParticipant';
import { NewEditParticipantViewModel } from './Participants/NewEditParticipantViewModel';
import MessageInfo from '../../../../../../../components/MessageInfo/MessageInfo';
import { ActionIcons } from '../../../../../../../components/Icons/ActionIcons';
import { unSubscribeParticipant } from '../../../../../../../redux/internal-projects/participants/unsubscribe/action';
interface OwnProps {
  internal_project_id: InternalProjectId;
}

interface StateProps {
  loading: boolean;
  participants: Participant[];
  getParticipantById: (id: ParticipantId) => Participant | null;
}

interface DispatchProps {
  getParticipants: (id: InternalProjectId) => void;
  deleteParticipant: (id: InternalProjectId, participantId: ParticipantId) => void;
  unSubscribeParticipant: (internalProjectId: InternalProjectId, participantId: ParticipantId) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

const SeguimientoInicialKoaParticipantes = ({
  loading,
  internal_project_id,
  participants,
  getParticipants,
  deleteParticipant,
  getParticipantById,
  unSubscribeParticipant,
}: Props) => {
  const [showModalNew, setShowModalNew] = useState(false);

  useEffect(() => {
    getParticipants(internal_project_id);
  }, [getParticipants, internal_project_id]);

  const unsubscribeModalDefaultState = {
    show: false,
    participant: '',
    participantId: '',
  };
  const [modal, setModal] = useState(false);
  const [unsubscribeModal, setUnsubscribeModal] = useState(unsubscribeModalDefaultState);

  const [id, setId] = useState<ParticipantId>('');
  const [selectedParticipant, setSelectedParticipant] = useState<Participant | null>(null);

  const delItem = (id: ParticipantId) => {
    setId(id);
    setModal(true);
  };

  const closeUnsubscribeModal = () => {
    setUnsubscribeModal(unsubscribeModalDefaultState);
  };

  const unsubscribeParticipant = (participant: Participant) => {
    setUnsubscribeModal({ show: true, participant: participant.user_name, participantId: participant.id });
  };

  const disabledCell: CSSProperties = {
    color: '#00000074',
  };

  const normalCell: CSSProperties = {};

  const customizeColumns = (): Column<Participant>[] => {
    return [
      {
        title: tKey('Nombre'),
        field: 'user_name',
        //@ts-ignore
        width: '40%',
        cellStyle: (participants: Participant[], rowData: Participant) =>
          rowData.unsubscribed_at ? disabledCell : normalCell,
      },
      {
        title: tKey('LOPD'),
        field: 'lopd',
        //@ts-ignore
        width: '10%',
        render: (rowData: Participant) => {
          return rowData.lopd ? 'Si' : 'No';
        },
        cellStyle: (participants: Participant[], rowData: Participant) =>
          rowData.unsubscribed_at ? disabledCell : normalCell,
      },
      {
        title: tKey('Particularidades'),
        field: 'particularities',
        //@ts-ignore
        width: '35%',
        render: (rowData: Participant) => (
          <Tooltip title={rowData.particularities}>
            <span>{rowData.particularities}</span>
          </Tooltip>
        ),
        cellStyle: (participants: Participant[], rowData: Participant) =>
          rowData.unsubscribed_at ? disabledCell : normalCell,
      },
      {
        title: tKey('Otros'),
        field: 'additional_info',
        //@ts-ignore
        width: '15%',
        cellStyle: (participants: Participant[], rowData: Participant) =>
          rowData.unsubscribed_at ? disabledCell : normalCell,
      },
    ];
  };

  const tableData: MaterialTableProps<Participant> = {
    title: tKey('Listado de participantes'),
    columns: customizeColumns(),
    actions: [
      (rowData: Participant) => ({
        icon: ActionIcons.EditIcon,
        tooltip: tKey('Editar'),
        onClick: () => {
          setSelectedParticipant(getParticipantById(rowData.id));
          setShowModalNew(true);
        },
      }),
      (rowData: Participant) => ({
        icon: !rowData.unsubscribed_at ? ActionIcons.BlockIcon : ActionIcons.BlockIconDis,
        tooltip: !rowData.unsubscribed_at
          ? tKey('Dar de baja')
          : `${tKey('Baja')}: ${moment(rowData.unsubscribed_at).format(DateConstants.DATE_FORMAT)}`,
        onClick: () => (!rowData.unsubscribed_at ? unsubscribeParticipant(rowData) : undefined),
      }),
      (rowData: Participant) => ({
        icon: ActionIcons.DeleteIcon,
        tooltip: tKey('Eliminar'),
        onClick: () => delItem(rowData.id),
      }),
    ],
    data: participants,
    isLoading: loading,
    options: {
      selection: false,
      paging: false,
      tableLayout: 'fixed',
      exportButton: true,
    },
  };

  const addNewParticipant = () => {
    setSelectedParticipant(null);
    setShowModalNew(true);
  };

  const minParticipantsView = () => {
    return participants.length < 8 ? <MessageInfo>{tProjectKey('pi.aviso_participantes')}</MessageInfo> : null;
  };

  return (
    <div className={styles.koa_table_container}>
      <Title>{tKey('Participantes')}</Title>
      {minParticipantsView()}
      <TableWithEdit
        customContainer
        permission
        tableData={tableData}
        filtering
        deleteFunc={(rowData: Participant) => delItem(rowData.id)}
        optionsNew={{
          newButtonText: tKey('Añadir'),
          onNew: addNewParticipant,
        }}
        onEditOne={(event: React.MouseEvent<Element, globalThis.MouseEvent>, rowData: Participant | Participant[]) => {
          setSelectedParticipant(getParticipantById((rowData as Participant).id));
          setShowModalNew(true);
        }}
      />
      <>
        <TransitionsModal
          view={modal}
          title="Eliminar participante"
          handleClose={() => {
            setModal(false);
          }}
          bodyModal={tKey('¿Seguro que deseas eliminar el participante?')}
          buttonOk={tKey('Sí')}
          buttonKo={tKey('No')}
          handleYes={() => {
            deleteParticipant(internal_project_id, id);
            setModal(false);
            getParticipants(internal_project_id);
          }}
        />
        <TransitionsModal
          view={unsubscribeModal.show}
          title="Baja de participante"
          handleClose={() => {
            closeUnsubscribeModal();
          }}
          bodyModal={`Vas a dar de baja a: ${unsubscribeModal.participant} ¿Estás seguro/a?`}
          buttonOk={tKey('Sí')}
          buttonKo={tKey('No')}
          handleYes={() => {
            unSubscribeParticipant(internal_project_id, unsubscribeModal.participantId);
            closeUnsubscribeModal();
            getParticipants(internal_project_id);
          }}
        />
        <NewParticipant
          internal_project_id={internal_project_id}
          showModal={showModalNew}
          onCloseModal={() => {
            setShowModalNew(false);
          }}
          onAddNewParticipant={() => {
            setShowModalNew(false);
            getParticipants(internal_project_id);
          }}
          participant={selectedParticipant}
          viewModel={NewEditParticipantViewModel}
        />
      </>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  loading: selectParticipantsLoading(state),
  participants: selectParticipantsList(state),
  getParticipantById: (id: ParticipantId) => selectParticipantById(state, id),
});

const mapDispatchToProps = (dispatch: any) => ({
  getParticipants: (id: InternalProjectId) => dispatch(getParticipants(id)),
  deleteParticipant: (internalProjectId: InternalProjectId, participantId: ParticipantId) =>
    dispatch(deleteParticipant(internalProjectId, participantId)),
  unSubscribeParticipant: (internalProjectId: InternalProjectId, participantId: ParticipantId) =>
    dispatch(unSubscribeParticipant(internalProjectId, participantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SeguimientoInicialKoaParticipantes);
