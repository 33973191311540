import { ButtonProps, Checkbox, TextField } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ClipBoardConnected from '../../../../../../components/ClipBoard/ClipBoardConnected';
import { Fields, GenericFromRequest, GenericRows } from '../../../../../../components/GenericForm/GenericFormRefactor';
import SubTitle from '../../../../../../components/SubTitle/Subtitle';
import TableWithEdit from '../../../../../../components/Tables/TableWithEdit';
import { useButtonAction } from '../../../../../../helpers/customHooks/useHookMethod';
import { tFormKey, tKey } from '../../../../../../helpers/translate';
import { selectIsNousCims } from '../../../../../../redux/auth/selectors';
import {
  deleteInternalProjectEnrollment,
  getProjectBaseCamp,
  sendFollowUp,
  updateEnrollment,
  updateFollowUp,
} from '../../../../../../redux/internal-projects/actions';
import {
  InternalProjectDispatch,
  InternalProjectEnrollment,
} from '../../../../../../redux/internal-projects/definitions';
import { selectBaseCampData, selectProjectLink } from '../../../../../../redux/internal-projects/selectors';
import { GenericData } from '../../../../../../redux/project/definitions';
import { AppState } from '../../../../../../redux/root-reducer';
import NewEnrollmentModal from '../../../../Modals/NewEnrollmentModal/NewEnrollmentModal';
import InternalProjectGeneric from '../../../InternalProjectGeneric/InternalProjectGeneric';
import InformationMinimal from '../../Information/InformationMinimal';
import styles from '../internalProjectFollows.module.scss';

interface BaseCampFormProps {
  loading: boolean;
  internal_project_id: number;
  section: string;
  follow_up_id: number;
  buttonSave: ButtonProps;
  buttonSend: ButtonProps;
  disabled: boolean;
  fields: Fields;
  participante: GenericData;
  setterData: any;
  setData: any;
  isNousCims: boolean;
  follow_date: string | null;
  completed: boolean;
  enrollments: InternalProjectEnrollment[];
  project_link: string;
  getProjectBaseCamp: (internal_project_id: number, follow_up_id: number) => void;
  updateFollowUp: (data: GenericFromRequest) => void;
  sendFollowUp: (data: GenericFromRequest) => void;
  updateEnrollment: (enrollment: InternalProjectEnrollment) => void;
  deleteEnrollment: (enrollment_id: number) => void;
}

const BaseCampForm: React.FC<BaseCampFormProps> = ({
  loading,
  internal_project_id,
  section,
  follow_up_id,
  buttonSave,
  buttonSend,
  disabled,
  fields,
  participante,
  setterData,
  setData,
  isNousCims,
  follow_date,
  completed,
  project_link,
  enrollments,
  getProjectBaseCamp,
  updateFollowUp,
  sendFollowUp,
  updateEnrollment,
  deleteEnrollment,
}) => {
  const [showCreateEnrollment, setShowCreateEnrollment] = useState(false);
  const actionIcons = {
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };
  useEffect(() => {
    getProjectBaseCamp(internal_project_id, follow_up_id);
  }, [getProjectBaseCamp, internal_project_id, follow_up_id]);

  const checkKeepGoing = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateEnrollment({
      id: parseInt(event.target.id),
      keep_going: event.target.checked ? 1 : 0,
    } as InternalProjectEnrollment);
  };

  const removeEnrollment = (enrollment_id: number) => {
    deleteEnrollment(enrollment_id);
  };

  const tableData: MaterialTableProps<InternalProjectEnrollment> = {
    title: tKey('Participantes'),
    columns: [
      {
        title: tFormKey('Nombre'),
        field: 'name',
      },
      {
        title: tFormKey('Apellidos'),
        field: 'surnames',
      },
      {
        title: tFormKey('Tipo doc.'),
        field: 'document_type',
      },
      {
        title: tFormKey('Nº documento'),
        field: 'document_number',
      },
      {
        title: tFormKey('Seguirá el taller'),
        field: 'keep_going',
        type: 'boolean',
        disableClick: true,
        render: (rowData: InternalProjectEnrollment) => (
          <>
            <Checkbox
              id={rowData.id.toString()}
              className={styles.list__check}
              checked={rowData.keep_going === 1}
              onChange={checkKeepGoing}
              color="primary"
            />
          </>
        ),
      },
    ],
    data: enrollments,
    options: {
      selection: false,
      pageSize: 10,
      tableLayout: 'auto',
      showSelectAllCheckbox: true,
      paging: false,
    },
    actions: [
      (rowData: InternalProjectEnrollment) => ({
        icon: actionIcons.Delete,
        onClick: () => removeEnrollment(rowData.id),
        tooltip: tKey('Borrar'),
      }),
    ],
  };
  const handleEditFollow = (data: any, rows?: GenericRows[]) => {
    const _data = {
      fields: data,
      internal_project_id,
      internal_project_follow_up_id: follow_up_id,
      section,
      rows: rows || [],
    };
    updateFollowUp(_data);
  };

  const handleSendFollow = (data: any, rows?: GenericRows[]) => {
    const _data = {
      fields: data,
      internal_project_id,
      internal_project_follow_up_id: follow_up_id,
      section,
      rows: rows || [],
    };
    sendFollowUp(_data);
  };

  useButtonAction(buttonSave, undefined, { hidden: false });
  useButtonAction(buttonSend, undefined, {
    hidden: !isNousCims,
    disabled: completed || !follow_date,
  });

  return (
    <Fragment>
      <InformationMinimal />
      <SubTitle>{tFormKey('ip.base_camp_title')}</SubTitle>
      <InternalProjectGeneric
        id={internal_project_id}
        buttonSave={buttonSave}
        buttonSend={buttonSend}
        fieldsData={fields}
        section={section}
        disabled={disabled}
        onSave={handleEditFollow}
        onSend={handleSendFollow}
        loadingFields={loading}
        participante={participante}
        setSetterData={setData}
        setterData={setterData}
        showProjectInfo={false}
      />
      <SubTitle>{tFormKey('ip.base_camp_list')}</SubTitle>
      <div className={styles.tableContainer}>
        <TableWithEdit
          customContainer
          key={JSON.stringify(tableData.data)}
          tableData={tableData}
          permission={true}
          optionsNew={{ newButtonText: tKey('NUEVO'), onNew: () => setShowCreateEnrollment(true) }}
        />
      </div>
      <SubTitle>{tFormKey('ip.base_camp_link')}</SubTitle>
      <TextField
        variant="outlined"
        name="project_link"
        disabled={disabled}
        fullWidth
        value={project_link}
        InputProps={{
          endAdornment: <ClipBoardConnected value={project_link as string} disabled={disabled} />,
        }}
      />
      <Suspense fallback={<></>}>
        {showCreateEnrollment && (
          <NewEnrollmentModal
            view={showCreateEnrollment}
            internal_project_id={internal_project_id}
            handleClose={() => setShowCreateEnrollment(false)}
          />
        )}
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectReducer.loadingAlt,
  fields: selectBaseCampData(state).pi_seguimiento_inicial.fields,
  participante: selectBaseCampData(state).pi_seguimiento_inicial.participante,
  enrollments: state.internalProjectReducer.enrollments,
  project_link: selectProjectLink(state),
  isNousCims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: InternalProjectDispatch) => ({
  getProjectBaseCamp: (internal_project_id: number, follow_up_id: number): void =>
    dispatch(getProjectBaseCamp(internal_project_id, follow_up_id)),
  updateFollowUp: (data: GenericFromRequest) => dispatch(updateFollowUp(data)),
  sendFollowUp: (data: GenericFromRequest) => dispatch(sendFollowUp(data)),
  updateEnrollment: (enrollment: InternalProjectEnrollment) => dispatch(updateEnrollment(enrollment)),
  deleteEnrollment: (enrollment_id: number) => dispatch(deleteInternalProjectEnrollment(enrollment_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BaseCampForm);
