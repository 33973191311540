import { Button, Checkbox, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { tErrorKey, tKey } from '../../helpers/translate';
import { AreasData } from '../../redux/area/definitions';
import { IdNamePair } from '../../redux/common/definitions';
import { AreasEditionSchema } from '../../validations/areasEditionSchema';
import FormContainer from '../Forms/FormContainer';
import TextInput from '../Inputs/TextInput';
import TextInputController from '../Inputs/TextInputController/TextInputController';
import TransitionsModal from '../Modal/TransitionModal';
import ItemWithIcon from './itemWithIcon';
import styles from './simpleList.module.scss';

export interface SimpleListProps {
  name: string;
  id: number;
  code: string;
  reserved: boolean;
  editArea: (id: number, data: AreasData) => void;
  deleteFunc: (id: number, code?: string) => void;
  disableArea: (id: number) => void;
  addFunc?: (id: number, data: string) => void;
  addFuncCode?: (code: string, data: string) => void;
  editFunc: (id: number, data: string, code?: string) => void;
  itemsList: IdNamePair[];
}
export interface AreaProps {
  view: boolean;
  name: string;
  code: string;
  reserved: boolean;
}
const SimpleList: React.FC<SimpleListProps> = ({
  itemsList,
  name,
  reserved,
  id,
  code,
  deleteFunc,
  addFunc,
  editFunc,
  editArea,
  addFuncCode,
  disableArea,
}) => {
  const [value, setValue] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [areaModal, setAreaModal] = useState<AreaProps>({
    view: false,
    name: '',
    code: '',
    reserved: false,
  });
  const [disableAreaModal, setDisableAreaModal] = useState<boolean>(false);
  const { errors, control, handleSubmit } = useForm<AreasData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AreasEditionSchema,
  });
  const onClickAdd = (id: number, value: string) => {
    if (value === '') {
      setError(true);
      setErrorText(tErrorKey('El campo no puede estar en blanco'));
    } else {
      setError(false);
      setErrorText('');
      addFunc && addFunc(id, value);
      addFuncCode && code && addFuncCode(code, value);
      setTimeout(() => setValue(''), 1500);
    }
  };
  const setGeneral = (
    event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    let areaModalProp = {};
    if (event.target.type === 'text') areaModalProp = { name: event.target.value };
    else areaModalProp = { reserved: !(event.target as HTMLInputElement).checked };
    setAreaModal(prevState => ({ ...prevState, ...areaModalProp }));
  };
  const handleChangeArea = () => {
    setAreaModal(() => ({
      view: true,
      name: name,
      code: code,
      reserved: reserved,
    }));
  };
  const handleCloseAreaModal = () => {
    setAreaModal(prevState => ({
      ...prevState,
      view: false,
    }));
  };
  const handleYes = (data: AreasData) => {
    const totalData = { ...data, reserved: areaModal.reserved };
    editArea(id, totalData);
    handleCloseAreaModal();
  };

  const handleDisableArea = () => {
    disableArea(id);
    setDisableAreaModal(false);
  };

  const Notice = () => {
    return (
      <div className={styles.noticeContainer}>
        <p>{tKey('accion-no-recuèrable')}</p>
        <p>{tKey('informaion-area')}</p>
        <div className={styles.noticeQuestion}>
          <p>{tKey('seguro-quieres-borrar')}</p>
        </div>
      </div>
    );
  };

  const EditAreas = () => {
    return (
      <form>
        <FormContainer title="" lowMargin={true}>
          <TextInputController
            name="name"
            label={tKey('Nombre')}
            size="100"
            control={control}
            errors={errors}
            onBlur={event => setGeneral(event)}
            defaultValue={areaModal.name}
            schema={AreasEditionSchema}
          />
          <div className={styles.codeContainer}>
            <TextInput type="text" label={tKey('area-codigo')} defaultValue={areaModal.code} size="35" disabled />
            <div className={styles.generalContainer}>
              <Checkbox
                checked={!areaModal.reserved}
                name="reserved"
                color="primary"
                onChange={event => setGeneral(event)}
              />
              <span>{tKey('area-general')}</span>
            </div>
          </div>
        </FormContainer>
      </form>
    );
  };
  return (
    <div className={styles.containerList}>
      <Typography variant="h6" className={styles.title}>
        <div className={styles.titleContent}>
          {name}
          <div className={styles.titleButtons}>
            <Button className={styles.buttons} onClick={handleChangeArea}>
              <EditIcon color="primary" fontSize="default" />
            </Button>
            <Button className={styles.buttons}>
              <DeleteIcon color="primary" fontSize="default" onClick={() => setDisableAreaModal(true)} />
            </Button>
          </div>
        </div>
      </Typography>
      <div className={styles.list}>
        <div className={styles.addContainer}>
          <TextField
            name={'addSubArea'}
            variant="standard"
            error={error}
            placeholder={tKey('Nuevo')}
            helperText={errorText}
            value={value}
            onChange={event => setValue(event.target.value)}
            InputProps={{
              classes: {
                input: styles.textfield,
              },
            }}
          />
          <Button
            variant="contained"
            disableElevation
            color="primary"
            onClick={() => onClickAdd(id, value)}
            startIcon={<AddIcon />}
          >
            {tKey('Añadir')}
          </Button>
        </div>
        {itemsList.map(item => (
          <ItemWithIcon key={item.id} item={item} code={code} deleteFunc={deleteFunc} editFunc={editFunc} />
        ))}
      </div>
      <TransitionsModal
        view={areaModal.view}
        handleClose={handleCloseAreaModal}
        title={tKey('edicion-areas')}
        bodyModal={<EditAreas />}
        buttonOk={tKey('Guardar')}
        buttonKo={tKey('Cancelar')}
        handleYes={handleSubmit(handleYes)}
      />
      <TransitionsModal
        view={disableAreaModal}
        handleClose={() => setDisableAreaModal(false)}
        title={tKey('area-aviso')}
        bodyModal={<Notice />}
        buttonOk={tKey('Eliminar')}
        buttonKo={tKey('Cancelar')}
        handleYes={handleDisableArea}
      />
    </div>
  );
};

export default SimpleList;
