import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { tProjectKey } from '../../../helpers/translate';
import { changeCreateModalVisible, getListFollowUps } from '../../../redux/project/action';
import { FollowUp, ProjectDispatch } from '../../../redux/project/definitions';
import { selectFollowUpList, selectModalCreateView } from '../../../redux/project/selectors';
import { AppState } from '../../../redux/root-reducer';
import CreateEditFollowUp from './CreateEditFollowUp';
import FollowUpList from './FollowUpList';
import styles from './followUpList.module.scss';

interface FollowUpProps {
  changeCreateVisible: (createView: boolean) => void;
  followUpsList: FollowUp[];
  createModalView: boolean;
  getFollowUpsList: (id: number) => void;
  projectID: number;
}

const FollowUpCompo: React.FC<FollowUpProps> = ({
  changeCreateVisible,
  createModalView,
  followUpsList,
  getFollowUpsList,
  projectID,
}) => {
  useEffect(() => {
    getFollowUpsList(projectID);
  }, [getFollowUpsList]); // eslint-disable-line react-hooks/exhaustive-deps
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createdModal, setCreatedModal] = React.useState({
    view: createModalView,
    children: <CreateEditFollowUp projectID={projectID} />,
    title: tProjectKey('Nueva Nota de Seguimiento'),
  });
  return (
    <div className={styles.followUpContainer}>
      <FollowUpList followUpsList={followUpsList} />
      <TransitionModal
        view={createModalView}
        handleClose={() => {
          changeCreateVisible(false);
        }}
        title={createdModal.title}
        bodyModal=" "
      >
        {createdModal.children}
      </TransitionModal>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  createModalView: selectModalCreateView(state),
  followUpsList: selectFollowUpList(state),
});

const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  changeCreateVisible: (createView: boolean): void => dispatch(changeCreateModalVisible(createView)),
  getFollowUpsList: (id: number) => dispatch(getListFollowUps(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpCompo);
