import { ButtonProps, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FncAccordion from '../../../../components/Accordion/FncAccordion';
import KeyboardDatePickerController from '../../../../components/DatePicker/KeyboardDatePickerController';
import FncButton from '../../../../components/FncButton/FncButton';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import LayoutMobile from '../../../../components/Layout/LayoutMobile';
import Loading from '../../../../components/Loading/Loading';
import Modal from '../../../../components/Modal/Modal';
import GenericMultiSelectController, {
  OptionsGenericMultiselect,
} from '../../../../components/Select/GenericMultiSelectController/GenericMultiSelectController';
import SelectController from '../../../../components/Select/SelectController';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { useWindowSize } from '../../../../helpers/customHooks/useHookMethod';
import { dateAmericanFormat } from '../../../../helpers/dateHelper';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import {
  addDocumentDailyFollow,
  clearDailyFollow,
  downloadDocumentDailyFollow,
  getInternalProjectDailyFollow,
  removeDocumentInternalProjectDailyFollow,
  storeDailyFollow,
} from '../../../../redux/internal-projects/actions';
import {
  DailyFollows,
  DailyFollowsForms,
  InternalProject,
  MetadataDailyFollow,
  Modality,
} from '../../../../redux/internal-projects/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { InternalProjectDailyFollowSchema } from '../../../../validations/formSchema';
import InformationMinimal from '../../InternalProjectEdition/Forms/Information/InformationMinimal';
import styles from './createDailyFollow.module.scss';

interface CreateDailyFollowProps {
  view: boolean;
  dailyFollow: DailyFollowsForms;
  dailyFollowId: number;
  loadingAlt: boolean;
  documentations: DocumentationItem[];
  metadata: MetadataDailyFollow;
  internalProject: InternalProject;
  storeDailyFollow: (dailyFollow: DailyFollows) => void;
  getInternalProjectDailyFollow: (dailyFollowsId: number) => void;
  handleClose: () => void;
  addDocument: (data: File, description: string, dailyFollowsId: number, dailyFollow?: DailyFollows) => void;
  removeDocument: (file_id: number, internalProjectId: number) => void;
  downloadDocument: (file_id: number, name: string, internal_project_id: number, get?: boolean) => void;
  clearDailyFollow: () => void;
}
const actions = ['checked', 'download', 'view', 'remove'];

const CreateDailyFollow: React.FC<CreateDailyFollowProps> = ({
  view,
  dailyFollow,
  dailyFollowId,
  loadingAlt,
  documentations,
  metadata,
  internalProject,
  storeDailyFollow,
  clearDailyFollow,
  getInternalProjectDailyFollow,
  handleClose,
  addDocument,
  removeDocument,
  downloadDocument,
}) => {
  const isMobile = useWindowSize().IsMobile;
  const [modalities, setModalities] = useState<Modality[]>([]);

  useEffect(() => {
    if (dailyFollowId > 0) getInternalProjectDailyFollow(dailyFollowId);
    else clearDailyFollow();
  }, [dailyFollowId, getInternalProjectDailyFollow, clearDailyFollow]);

  useEffect(() => {
    const filtered = metadata.modalities.filter(
      modality => modality.group_id === dailyFollow.internal_project_modalities_group_id,
    );
    setModalities(filtered);
  }, [dailyFollow.internal_project_modalities_group_id, metadata.modalities]);

  const { errors, control, handleSubmit, getValues } = useForm<DailyFollows>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InternalProjectDailyFollowSchema,
  });

  const handleGetDocument = (fileId: number, name: string, internalProjectId: number) => {
    downloadDocument(fileId, name, internalProjectId, false);
  };

  const handlePayload = (data: DailyFollows) => {
    const sendData = { ...data, internal_project_id: internalProject.id };
    if (dailyFollowId || dailyFollow.id) {
      sendData.id = dailyFollowId || dailyFollow.id;
    }
    return sendData;
  };

  const handleCreate = (data: DailyFollows) => {
    const sendData = handlePayload(data);
    storeDailyFollow(sendData);
    handleClose();
  };

  const handleAddDocument = async (document: File, description: string, genericId?: any) => {
    const sendData = genericId ? undefined : handlePayload(getValues());
    addDocument(document, description, genericId, sendData);
  };

  const optionsMultiSelect: OptionsGenericMultiselect[] = metadata.professionals_involved.map(profesional => {
    return {
      id: profesional.id,
      name: profesional.name,
      value: '',
      checked: false,
    } as OptionsGenericMultiselect;
  });

  let defaultValueProfessionalInvolveds: number[] = [];

  if (dailyFollow && dailyFollow.professionals_involveds) {
    defaultValueProfessionalInvolveds = dailyFollow.professionals_involveds.map(professional => {
      return professional.id;
    });
  }

  const buttons: ButtonProps[] = [
    {
      onClick: handleClose,
      children: tKey('Cancelar'),
      variant: 'contained',
      color: 'secondary',
      disableElevation: true,
    },
    {
      children: tKey('Añadir'),
      onClick: handleSubmit(handleCreate),
      color: 'primary',
      variant: 'contained',
      disableElevation: true,
    },
  ];

  const elementComponente = (
    <SelectController
      name="internal_project_typologies_id"
      control={control}
      errors={errors}
      schema={InternalProjectDailyFollowSchema}
      label={tFormKey('ip.component')}
      defaultValue={dailyFollow.internal_project_typologies_id}
      size="50"
    >
      {metadata.tipologies.map(tipology => (
        <MenuItem key={tipology.id} value={tipology.id}>
          {tFormKey(tipology.name)}
        </MenuItem>
      ))}
    </SelectController>
  );

  const loadModalidad = (e: any) => {
    setModalities(metadata.modalities.filter(modality => modality.group_id === e.target.value));
  };

  const elementModalidadesGroup = () => {
    if (metadata.modalities_groups.length > 0) {
      return (
        <SelectController
          name="internal_project_modalities_group_id"
          control={control}
          errors={errors}
          size="50"
          schema={InternalProjectDailyFollowSchema}
          label={tFormKey('Grupo modalidad')}
          defaultValue={dailyFollow.internal_project_modalities_group_id}
          onClick={loadModalidad}
        >
          {metadata.modalities_groups.map(group => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))}
        </SelectController>
      );
    }
  };

  const elementModalidades = () => {
    return (
      <SelectController
        name="internal_project_modalities_id"
        control={control}
        errors={errors}
        defaultValue={dailyFollow.internal_project_modalities_id}
        size="50"
        schema={InternalProjectDailyFollowSchema}
        label={tFormKey('ip.modalidad')}
      >
        {modalities.map(modality => (
          <MenuItem key={modality.id} value={modality.id}>
            {tFormKey(modality.name)}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const elementNotas = (
    <TextInputController
      size="50"
      label={tFormKey('ip.notas')}
      name="note"
      defaultValue={dailyFollow.note}
      control={control}
      errors={errors}
      schema={InternalProjectDailyFollowSchema}
      rows="3"
      rowsMax="3"
      multiline
    />
  );
  const form = (
    <form className={styles.form}>
      {loadingAlt ? (
        <Loading big />
      ) : (
        <div className={styles.content}>
          <div>
            <InformationMinimal />
            <KeyboardDatePickerController
              label={tFormKey('ip.fecha')}
              name="follow_date"
              control={control}
              schema={InternalProjectDailyFollowSchema}
              errors={errors}
              defaultValue={dailyFollow.follow_date}
              size="50"
              minDate={dateAmericanFormat(internalProject.start_date)}
              maxDate={dateAmericanFormat(internalProject.end_date)}
            />
          </div>
          {isMobile && (
            <FncAccordion title={tFormKey('ip.modalidad') + '*'}>
              <div className={styles.columnsWrapper}>
                <div className={styles.selectors}>{elementModalidadesGroup()}</div>
                <div className={styles.selectors}>{elementModalidades()}</div>
              </div>
            </FncAccordion>
          )}
          {!isMobile && (
            <div className={styles.selectors}>
              {elementModalidadesGroup()}
              {elementModalidades()}
              {elementComponente}
            </div>
          )}
          <div className={styles.selectors}>
            {isMobile && elementComponente}
            <GenericMultiSelectController
              label={tFormKey('ip.profesionalesImplicados')}
              name="professionals_involveds"
              options={optionsMultiSelect}
              control={control}
              errors={errors}
              required
              defaultValue={defaultValueProfessionalInvolveds}
            />
            <TextInputController
              size="50"
              label={tFormKey('ip.participants')}
              name="participants"
              defaultValue={dailyFollow.participants}
              control={control}
              errors={errors}
              type="number"
              schema={InternalProjectDailyFollowSchema}
            />
            {isMobile && elementNotas}
          </div>
          {!isMobile && elementNotas}
          <div className={styles.containerAddDocumentBtn}>
            <FncAddDocuments
              documents={documentations}
              actions={actions}
              addRow
              title={tFormKey('ip.documentacion_basica')}
              originalName
              genericId={dailyFollow.id}
              addDoc={handleAddDocument}
              remove={removeDocument}
              download={downloadDocument}
              getFile={handleGetDocument}
            />
          </div>
          {isMobile && (
            <div>
              <FncButton key={Math.random()} onClick={handleSubmit(handleCreate)} fullWidth={isMobile}>
                {tKey('Guardar')}
              </FncButton>
              <FncButton buttonStyle="cancel" key="goBackButton" onClick={handleClose} fullWidth={isMobile}>
                {tKey('Cancelar')}
              </FncButton>
            </div>
          )}
        </div>
      )}
    </form>
  );

  return isMobile ? (
    <LayoutMobile onBack={handleClose} title={`${tKey('Nuevo')} ${tKey('ip.seguimiento_diario')}`}>
      {form}
    </LayoutMobile>
  ) : (
    <Modal
      title={`${tKey('Nuevo')} ${tKey('ip.seguimiento_diario')}`}
      onClose={handleClose}
      open={view}
      buttons={buttons}
      maxWidth="xl"
    >
      {form}
    </Modal>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadingAlt: state.internalProjectReducer.loadingAlt,
  dailyFollow: state.internalProjectReducer.dailyFollow,
  internalProject: state.internalProjectReducer.internalProjectData,
  documentations: state.internalProjectReducer.documentations_daily_follow,
  metadata: state.internalProjectReducer.metadata,
});

const mapDispatchToProps = (dispatch: any) => ({
  getInternalProjectDailyFollow: (dailyFollowsId: number): void =>
    dispatch(getInternalProjectDailyFollow(dailyFollowsId)),
  addDocument: (data: File, description: string, dailyFollowsId: number, dailyFollow?: DailyFollows): void =>
    dispatch(addDocumentDailyFollow(data, dailyFollowsId, description, dailyFollow)),
  removeDocument: (file_id: number, dailyFollowsId: number): void =>
    dispatch(removeDocumentInternalProjectDailyFollow(file_id, dailyFollowsId)),
  downloadDocument: (file_id: number, name: string, dailyFollowsId: number, get?: boolean): void =>
    dispatch(downloadDocumentDailyFollow(file_id, name, dailyFollowsId, get)),
  clearDailyFollow: (): void => dispatch(clearDailyFollow()),
  storeDailyFollow: (dailyFollow: DailyFollows): void => dispatch(storeDailyFollow(dailyFollow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDailyFollow);
