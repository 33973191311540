import { RouteComponentProps } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Title from '../../../components/Title/Title';
import { tFormKey } from '../../../helpers/translate';
import styles from './ScholarshipFreeTransversalCompetences.module.scss';
import LayoutForm from '../../../components/Layout/LayoutForm';
import {
  getScholarshipTransversalCompetencesFormApi,
  setScholarshipTransversalCompetencesApi,
} from '../../../api/Scholarship/scholarship';
import {
  ScholarshipTransversalCompetencesData,
  ScholarshipTransversalCompetencesSendData,
} from '../../../redux/scholarship/definitions';
import Loading from '../../../components/Loading/Loading';
import SurveyForm from '../../../components/Survey/SurveyForm';

const ScholarshipFreeTransversalCompetences: React.FC<RouteComponentProps<{ survey_code: string }>> = ({ match }) => {
  const [survey, setSurvey] = useState<ScholarshipTransversalCompetencesData>({
    scholarship_id: 0,
    role_code: '',
    period: '',
    options: [],
    groups: [],
  });
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const {
    params: { survey_code },
  } = match;

  const onSubmit = (data: ScholarshipTransversalCompetencesSendData) => {
    // enviamos la encuesta
    setLoading(true);
    setScholarshipTransversalCompetencesApi(data)
      .then(() => {
        setMessage('La encuesta se guardó correctamente');
      })
      .catch(() => {
        setMessage('La encuesta no pudo guardarse correctamente');
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // recogemos los datos de la encuesta
    setLoading(true);
    getScholarshipTransversalCompetencesFormApi(survey_code)
      .then((data: ScholarshipTransversalCompetencesData) => {
        setSurvey(data);
      })
      .catch(() => {
        setMessage('La encuesta no está activa');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [survey_code]);

  return (
    <>
      <LayoutForm layoutDisabled>
        <>
          {loading && <Loading big />}
          {message && (
            <div className={styles.titleError}>
              <Title>{tFormKey(message)}</Title>
            </div>
          )}
          {!message && !loading && (
            <>
              <Title>{tFormKey('Competencias transversales')}</Title>
              <SurveyForm surveyData={survey} onSubmitForm={onSubmit} />
            </>
          )}
        </>
      </LayoutForm>
    </>
  );
};

export default ScholarshipFreeTransversalCompetences;
