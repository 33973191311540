import { Checkbox } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableWithEdit from '../../../../../components/Tables/TableWithEdit';
import Title from '../../../../../components/Title/Title';
import { tKey } from '../../../../../helpers/translate';
import {
  getParticipantesFinalFollowUpForm,
  getParticipantesFollowUpList,
} from '../../../../../redux/internal-projects/actions';
import { ParticipantesFollowUpList } from '../../../../../redux/internal-projects/definitions';
import { selectKeepGoingParticipantesFollowUpList } from '../../../../../redux/internal-projects/selectors';
import { AppState } from '../../../../../redux/root-reducer';
import InternalProjectLayoutForm from '../../InternalProjectLayoutForm';
import EndCourseFollow from '../EndCourseFollows/EndCourseFollows';
import InformationMinimal from '../Information/InformationMinimal';
import SixMonthsFollows from '../SixMonthsFollows/SixMonthsFollows';
import styles from './entityFollows.module.scss';

enum FormType {
  EndCourse = 1,
  SixMonths = 2,
}

interface EntityFollowsProps {
  loading: boolean;
  projectId: number;
  participantesFollowUpList: ParticipantesFollowUpList[];
  handleGoBack?: () => void;
  handleChangeSection: (componentId: string) => void;
  getParticipantesFollowUpList: (id: number) => void;
}

const EntityFollows: React.FC<EntityFollowsProps> = ({
  loading,
  projectId,
  participantesFollowUpList,
  handleGoBack,
  handleChangeSection,
  getParticipantesFollowUpList,
}) => {
  const [followUpEvaluationModal, setFollowUpEvaluationModal] = useState({
    userFormId: 0,
    formType: 0,
    view: false,
  });
  useEffect(() => {
    getParticipantesFollowUpList(projectId);
  }, [getParticipantesFollowUpList, projectId]);

  const handleEditForm = (userFormId: number, formType: number, view: boolean) => {
    setFollowUpEvaluationModal({ userFormId, formType, view });
  };

  const tableData: MaterialTableProps<ParticipantesFollowUpList> = {
    title: tKey('Participantes'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'name',
        //@ts-ignore
        width: '20%',
      },
      {
        title: tKey('1er Apellido'),
        field: 'surname',
        //@ts-ignore
        width: '20%',
      },
      {
        title: tKey('2do Apellido'),
        field: 'surname2',
        //@ts-ignore
        width: '20%',
      },
      {
        title: tKey('Seguimiento final'),
        field: 'follow_up_final_done',
        //@ts-ignore
        width: '20%',
        type: 'boolean',
        disableClick: true,
        render: (rowData: ParticipantesFollowUpList) => (
          <div className={styles.tableIcons}>
            <Checkbox checked={rowData.follow_up_final_done} disabled={true} />
            <Edit
              style={{ cursor: 'pointer' }}
              color={rowData.edit_final_enabled ? 'inherit' : 'disabled'}
              onClick={() =>
                rowData.edit_final_enabled && handleEditForm(rowData.follow_up_final_id, FormType.EndCourse, true)
              }
            />
          </div>
        ),
      },
      {
        title: tKey('Seguimiento 6 meses'),
        field: 'follow_up_six_months_done',
        //@ts-ignore
        width: '20%',
        type: 'boolean',
        disableClick: true,
        render: (rowData: ParticipantesFollowUpList) => (
          <div className={styles.tableIcons}>
            <Checkbox checked={rowData.follow_up_six_months_done} disabled={true} />
            <Edit
              style={{ cursor: 'pointer' }}
              color={rowData.edit_six_months_enabled ? 'inherit' : 'disabled'}
              onClick={() =>
                rowData.edit_six_months_enabled &&
                handleEditForm(rowData.follow_up_six_months_id, FormType.SixMonths, true)
              }
            />
          </div>
        ),
      },
    ],
    data: participantesFollowUpList,
    options: {
      selection: false,
      paging: false,
      exportButton: true,
    },
  };

  return (
    <InternalProjectLayoutForm
      sectionSelected={'evaluacion_entidad'}
      handleChangeSection={handleChangeSection}
      handleGoBack={handleGoBack}
    >
      <Fragment>
        <Title>{tKey('ip.seguimientos_entidad')}</Title>
        <InformationMinimal />
        <TableWithEdit customContainer tableData={tableData}></TableWithEdit>
        <Suspense fallback={<></>}>
          {followUpEvaluationModal.view && followUpEvaluationModal.formType === FormType.EndCourse ? (
            <EndCourseFollow
              enrollmentId={followUpEvaluationModal.userFormId}
              view={followUpEvaluationModal.view}
              handleClose={() => setFollowUpEvaluationModal({ userFormId: 0, formType: 0, view: false })}
            ></EndCourseFollow>
          ) : followUpEvaluationModal.view && followUpEvaluationModal.formType === FormType.SixMonths ? (
            <SixMonthsFollows
              form_id={followUpEvaluationModal.userFormId}
              view={followUpEvaluationModal.view}
              handleClose={() => setFollowUpEvaluationModal({ userFormId: 0, formType: 0, view: false })}
            />
          ) : null}
        </Suspense>
      </Fragment>
    </InternalProjectLayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectReducer.loadingAlt,
  projectId: state.internalProjectReducer.internalProjectData.id,
  participantesFollowUpList: selectKeepGoingParticipantesFollowUpList(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getParticipantesFollowUpList: (id: number) => dispatch(getParticipantesFollowUpList(id)),
  getFinalFormOptions: (id: number) => dispatch(getParticipantesFinalFollowUpForm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntityFollows);
