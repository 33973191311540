import { Button, InputAdornment, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { deleteFileFromApi, uploadFileFromApi } from '../../../../api/file';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import UploadFileButton from '../../../../components/UploadFileButton/UploadFileButton';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { FamilyIncome, FamilyMember, IncomeType } from '../../../../redux/scholarship/familyIncomes/FamilyIncome';
import { FamilyIncomeSchema } from '../FormSchema';
import styles from './familyIncomeTableModal.module.scss';
import errorMessage from '../../../../helpers/errorMessage';

interface ModalCreateProps {
  familyIncome: FamilyIncome;
  onSave: (data: FamilyIncome) => void;
  onDownloadFile: (file_id: number, filename: string) => void;
  setFamilyIncome: (data: FamilyIncome) => void;
  closeModal: () => void;
}

const FamilyIncomeTableModal: React.FC<ModalCreateProps> = ({
  familyIncome,
  onSave,
  onDownloadFile,
  setFamilyIncome,
  closeModal,
}) => {
  const familyMembers: FamilyMember[] = ['Zinger', 'Padre', 'Madre', 'Hermano/a', 'Otros familiares'];
  const incomeType: IncomeType[] = ['Salario', 'Pensión', 'Rentas', 'Paro', 'Ingresos alternativos'];

  const { id, scholarship_id, family_member, income_type, amount, file_id, filename } = familyIncome;

  const [fileId, setFileId] = React.useState<number | null>(file_id);

  const familyIncomeSchema = FamilyIncomeSchema();

  const { errors, control, setError, setValue, watch, handleSubmit } = useForm<FamilyIncome>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: familyIncomeSchema,
  });

  const onSubmit = (data: FamilyIncome) => {
    onSave(data);
    closeModal();
  };

  const uploadFile = async (file: File) => {
    try {
      const fileItem = await uploadFileFromApi(file);
      setFileId(fileItem.id);
      setValue('file_id', fileItem.id);
      setValue('filename', fileItem.original_name);
    } catch (error) {
      setError('file_id', errorMessage(error));
    }
  };

  const deleteFile = async (file_id: number) => {
    const deleted = await deleteFileFromApi(file_id);
    if (deleted) {
      setFileId(null);
      setValue('file_id', null);
      setValue('filename', null);
      setFamilyIncome({ ...familyIncome, file_id: null, filename: null });
    }
  };

  const renderUploadButton = () => {
    return (
      <div className={styles.uploadFileButton}>
        <UploadFileButton
          file_id={fileId}
          filename={watch('filename') || filename}
          errors={errors}
          readOnly={false}
          onUploadFile={uploadFile}
          downloadFile={onDownloadFile}
          removeFile={deleteFile}
          setValue={setValue}
        />
      </div>
    );
  };

  const renderHiddenFields = (): JSX.Element => {
    return (
      <>
        <TextInputController
          type="hidden"
          control={control}
          key="id"
          name="id"
          errors={errors}
          schema={familyIncomeSchema}
          defaultValue={id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="scholarship_id"
          name="scholarship_id"
          errors={errors}
          schema={familyIncomeSchema}
          defaultValue={scholarship_id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="file_id"
          name="file_id"
          errors={errors}
          schema={familyIncomeSchema}
          defaultValue={file_id}
          visible={false}
        />
        <TextInputController
          type="hidden"
          control={control}
          key="filename"
          name="filename"
          errors={errors}
          schema={familyIncomeSchema}
          defaultValue={filename}
          visible={false}
        />
      </>
    );
  };

  return (
    <div>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {renderHiddenFields()}
        <div className={styles.newcontact}>
          <SelectController
            control={control}
            size="Large"
            key="family_member"
            name="family_member"
            defaultValue={family_member}
            errors={errors}
            schema={familyIncomeSchema}
            label={tScholarshipKey('family_incomes_family_member')}
          >
            {Object.values(familyMembers).map((value: string) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectController>
        </div>
        <div className={styles.newcontact}>
          <SelectController
            control={control}
            size="Large"
            key="income_type"
            name="income_type"
            defaultValue={income_type}
            errors={errors}
            schema={familyIncomeSchema}
            label={tScholarshipKey('family_incomes_income_type')}
          >
            {Object.values(incomeType).map((value: string) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </SelectController>
        </div>
        <div className={styles.newcontact}>
          <TextInputController
            type="number"
            size="Large"
            label={tScholarshipKey('family_incomes_amount')}
            name="amount"
            defaultValue={amount}
            control={control}
            errors={errors}
            schema={familyIncomeSchema}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </div>
        {renderUploadButton()}
        <div className={styles.btn_create}>
          <Button color="primary" variant="outlined" onClick={handleSubmit(onSubmit)}>
            {tKey('Guardar')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FamilyIncomeTableModal;
