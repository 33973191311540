import { ButtonProps, Tooltip } from '@material-ui/core';
import { Payment, Receipt } from '@material-ui/icons';
import DescriptionIcon from '@material-ui/icons/Description';
import { MaterialTableProps } from 'material-table';
import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getActiveInvoicesFromApi, getPaidInvoicesFromApi } from '../../../api/accounting';
import TableLayout from '../../../components/Layout/TableLayout';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { formatNumber } from '../../../helpers/formatNumber';
import { tFormKey, tKey } from '../../../helpers/translate';
import { downloadInvoiceDocument, markInvoiceAsPaid, sendInvoicesToPay } from '../../../redux/accounting/actions';
import { InvoiceListItem, InvoiceStates, ListInvoicesStates } from '../../../redux/accounting/definitions';
import { selectLoading } from '../../../redux/accounting/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import EntityMenuRight from '../../Entities/EntityMenuRight/EntityMenuRight';
import AccountingSubmenu from '../AccountingSubmenu';

const TransitionModal = React.lazy(() => import('../../../components/Modal/TransitionModal'));
interface InvoicesTableProps {
  showToPay: boolean;
  accountingLoading: boolean;
  canMarkPaid: boolean;
  paidInvoices: boolean | undefined;
  sendToPay: () => void;
  markAsPaid: (invoice_id: number) => void;
  downloadInvoice: (file_id: number, filename: string) => void;
}

const InvoicesTable: React.FC<InvoicesTableProps> = ({
  showToPay,
  accountingLoading,
  canMarkPaid,
  paidInvoices,

  markAsPaid,
  downloadInvoice,
  sendToPay,
}) => {
  const [invoiceList, setInvoiceList] = useState<InvoiceListItem[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [modal, setModal] = React.useState({ view: false, invoice_id: 0, text: '' });
  const history = useHistory();
  const stateFilters = {} as any;

  if (!!Object.entries(ListInvoicesStates).length) {
    Object.values(ListInvoicesStates).forEach(element => (stateFilters[element] = tKey(element)));
  }

  useEffect(() => {
    if (!accountingLoading) {
      (async () => {
        setLoading(true);
        if (paidInvoices) {
          setInvoiceList(await getPaidInvoicesFromApi());
        } else {
          setInvoiceList(await getActiveInvoicesFromApi());
        }
        setLoading(false);
      })();
    }
  }, [accountingLoading, paidInvoices]);

  const actionIcons = {
    Document: () => <DescriptionIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    ForcePay: () => <Receipt style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };

  const actions = [
    (rowData: InvoiceListItem) => ({
      icon: actionIcons.Document,
      tooltip: tFormKey('Documento'),
      onClick: () => downloadInvoice(rowData.file_id || 0, rowData.filename || ''),
    }),
  ];

  if (canMarkPaid) {
    actions.push((rowData: InvoiceListItem) => ({
      icon: actionIcons.ForcePay,
      tooltip: tFormKey('Marcar como pagada'),
      onClick: () => setModal({ view: true, invoice_id: rowData.id, text: rowData.entity_name }),
      hidden: rowData.state_code !== InvoiceStates.AUTORIZADA,
    }));
  }

  const tableData: MaterialTableProps<InvoiceListItem> = {
    title: tKey('FACTURAS'),
    columns: [
      {
        field: 'reference_number',
        title: tKey('Factura'),
        render: rowData => (
          <Tooltip title={rowData.reference_number}>
            <span>{rowData.reference_number}</span>
          </Tooltip>
        ),
        //@ts-ignore
        width: '100px',
      },
      {
        field: 'entity_name',
        title: tKey('Proveedor'),
        render: rowData => (
          <Tooltip title={rowData.entity_name}>
            <span>{rowData.entity_name}</span>
          </Tooltip>
        ),
        //@ts-ignore
        width: '240px',
      },
      {
        field: 'invoice_date',
        title: tFormKey('Fecha'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) => <div style={{ textAlign: 'center' }}>{rowData.invoice_date}</div>,
        //@ts-ignore
        width: '80px',
      },
      {
        field: 'due_date',
        title: tFormKey('Vencimiento'),
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: InvoiceListItem) => <div style={{ textAlign: 'center' }}>{rowData.due_date}</div>,
        //@ts-ignore
        width: '80px',
      },
      {
        field: 'total_amount',
        title: tKey('Total'),
        type: 'numeric',
        render: (rowData: InvoiceListItem) => <div>{formatNumber({ value: rowData.total_amount }) + ' €'}</div>,
        //@ts-ignore
        width: '80px',
      },
      {
        field: 'center_cost',
        title: tKey('Centro de coste'),
        render: rowData => (
          <Tooltip title={rowData.center_cost}>
            <span>{rowData.center_cost}</span>
          </Tooltip>
        ),
        //@ts-ignore
        width: '90px',
      },
      {
        field: 'state',
        title: tKey('Estado'),
        //@ts-ignore
        width: '80px',
        lookup: stateFilters,
        //defaultFilter: !showToPay ? ['Pendiente', 'Aceptada'] : undefined,
      },
    ],
    actions,
    data: invoiceList,
    options: {
      tableLayout: 'fixed',
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
  };

  tableData.isLoading = loading || accountingLoading;

  const goToInvoiceEdit = (rowData: InvoiceListItem) => {
    const { id } = rowData;
    history.push({
      pathname: `/contabilidad/facturas/editar/${id}`,
      state: { id },
    });
  };

  const buttons: ButtonProps[] = [];
  if (showToPay && !paidInvoices) {
    buttons.push({
      children: tKey('A PAGAR'),
      onClick: sendToPay,
      startIcon: <Payment />,
      fullWidth: true,
      variant: 'contained',
      color: 'primary',
    });
  }

  const rightSubmenu = <EntityMenuRight actionButtons={buttons} />;

  return (
    <TableLayout
      leftSubmenu={
        <AccountingSubmenu
          selected={paidInvoices ? 'paid_invoices' : 'current_invoices'}
          submenu={tKey('Facturas servicios')}
        />
      }
      rightSubmenu={rightSubmenu}
    >
      <TableWithEdit
        onRowClick={goToInvoiceEdit}
        tableData={{ ...tableData }}
        filtering
        key={JSON.stringify(tableData.data)}
        permission={true}
      />
      <Suspense fallback={<></>}>
        <TransitionModal
          view={modal.view}
          handleClose={() => {
            setModal({ view: false, invoice_id: 0, text: '' });
          }}
          bodyModal={tFormKey('ask-invoice-paid1').concat(' ', modal.text, ' ', tFormKey('ask-invoice-paid2'))}
          buttonOk={tKey('Sí')}
          buttonKo={tKey('No')}
          title={tFormKey('Marcar como pagada')}
          handleYes={() => {
            markAsPaid(modal.invoice_id);
            setModal({ view: false, invoice_id: 0, text: '' });
          }}
        />
      </Suspense>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  accountingLoading: selectLoading(state),
  showToPay: selectUserCan(state)('allow_to_pay_invoice'),
  canMarkPaid: selectUserCan(state)('allow_mark_invoice_as_paid'),
});

const mapDispatchToProps = (dispatch: any) => ({
  sendToPay: () => dispatch(sendInvoicesToPay()),
  downloadInvoice: (file_id: number, filename: string) => dispatch(downloadInvoiceDocument(file_id, filename)),
  markAsPaid: (invoice_id: number) => dispatch(markInvoiceAsPaid(invoice_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTable);
