import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { tKey } from '../../helpers/translate';
import { RadioOptions } from './RadioButtonOptions';
import styles from './radioButtons.module.scss';
import { Control, FieldErrors } from 'react-hook-form/dist/types';

interface RadioButtonGroupProps {
  name: string;
  options: RadioOptions[];
  control: Control;
  valueDefault: string | number;
  errors: FieldErrors<any>;
  questionText?: string;
  optionModal?: boolean;
  row?: boolean;
}

const RadioButtonGroupWithController: React.FC<RadioButtonGroupProps> = ({
  questionText,
  name,
  options,
  control,
  valueDefault,
  optionModal,
  row,
  errors,
}) => {
  const [value, setValue] = useState(valueDefault.toString());
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={valueDefault}
      as={
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <div className={optionModal ? styles.optionsModal : styles.questionText}>
              {questionText}
              {errors[name] && <FormHelperText error>{errors[name]?.message}</FormHelperText>}
            </div>
          </FormLabel>
          <RadioGroup
            row={!!row}
            name={name}
            value={value}
            onChange={event => {
              setValue(event.target.value);
            }}
          >
            {options.map(option => (
              <FormControlLabel
                key={`${name}_${option.label}`}
                label={tKey(option.label)}
                labelPlacement={option.labelPlacement || 'end'}
                control={<Radio name={name} value={option.value.toString()} />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      }
    />
  );
};

export default RadioButtonGroupWithController;
