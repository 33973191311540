import getLookUp from '../../helpers/getLookUp';
import { AppState } from '../root-reducer';
import { ListMails, MailDetails, TranslationCategory, TranslationMetadata, TranslationState } from './definitions';

const searchCategory = (tCategory: TranslationCategory, key: string): string => {
  if (tCategory.keys && key) {
    const s_key = key.length > 50 ? key.substr(0, 50) : key;
    const trad = tCategory.keys.get(s_key);
    if (trad) {
      return trad;
    }
  }
  return key;
};

export const selectTranslations = (state: AppState): TranslationState => state.translationReducer;
export const selectTranslationsLoaded = (state: AppState): boolean => state.translationReducer.loaded;
export const selectGenericKeys = (state: AppState): TranslationCategory => selectTranslations(state).generic;
export const selectScholarshipKeys = (state: AppState): TranslationCategory => selectTranslations(state).scholarship;
export const selectProjectKeys = (state: AppState): TranslationCategory => selectTranslations(state).project;
export const selectFormtKeys = (state: AppState): TranslationCategory => selectTranslations(state).forms;
export const selectErrorKeys = (state: AppState): TranslationCategory => selectTranslations(state).errors;

export const selectGenericKey = (state: AppState, key: string): string => {
  if (state) {
    const genericTC = selectGenericKeys(state);
    return searchCategory(genericTC, key);
  }
  return key;
};

export const selectScholarshipKey = (state: AppState, key: string): string => {
  if (state) {
    const genericTC = selectScholarshipKeys(state);
    return searchCategory(genericTC, key);
  }
  return key;
};

export const selectProjectKey = (state: AppState, key: string): string => {
  if (state) {
    const genericTC = selectProjectKeys(state);
    return searchCategory(genericTC, key);
  }
  return key;
};

export const selectFormtKey = (state: AppState, key: string): string => {
  if (state) {
    const genericTC = selectFormtKeys(state);
    return searchCategory(genericTC, key);
  }
  return key;
};

export const selectErrorKey = (state: AppState, key: string): string => {
  if (state) {
    const genericTC = selectErrorKeys(state);
    return searchCategory(genericTC, key);
  }
  return key;
};

export const selectImportResult = (state: AppState): boolean => selectTranslations(state).responseImport;
export const selectListMails = (state: AppState): ListMails[] => selectTranslations(state).listMails;
export const selectLoadingListMails = (state: AppState): boolean => selectTranslations(state).loadingMailList;
export const selectDetailsMail = (state: AppState): MailDetails => selectTranslations(state).mailDetails;
export const selectLoading = (state: AppState): boolean => selectTranslations(state).loading;
export const selectTranslationMetadata = (state: AppState): TranslationMetadata =>
  selectTranslations(state).translationMetadata;

export const selectLookupCategory = (state: AppState) => getLookUp(state.translationReducer.listMails, 'category');
export const selectCategory = (state: AppState) =>
  Array.from(new Set(state.translationReducer.listMails.map(mail => mail.category)));

export const selectListMailsByCategory = (state: AppState) => (category?: string): ListMails[] => {
  if (category) return selectListMails(state).filter(mail => mail.category === category);
  return selectListMails(state);
};
