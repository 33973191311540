import { ResourceTracking } from '../redux/tracking/definitions';
import { apiBaseUrl, apiCall } from './api';

const baseURL = apiBaseUrl;

export const getResourceTrackingsFromApi = async (id: number, type: string): Promise<ResourceTracking> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id, type }),
  };

  const { data } = await apiCall<{ data: ResourceTracking }>(`${baseURL}/state_change_log/tracking`, init);
  return data;
};
