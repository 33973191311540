import { UpdateParticipantConst } from './action_types';
import { UpdateParticipantAction, UpdateParticipantKoAction, UpdateParticipantOkAction } from './definitions';
import { Participant } from '../Participant';

export function updateParticipant(participant: Participant): UpdateParticipantAction {
  return {
    type: UpdateParticipantConst.UPDATE_PARTICIPANT,
    participant,
  };
}

export function updateParticipantOk(updatedParticipant: Participant): UpdateParticipantOkAction {
  return {
    type: UpdateParticipantConst.UPDATE_PARTICIPANT_OK,
    updatedParticipant,
  };
}

export function updateParticipantKO(error: string): UpdateParticipantKoAction {
  return {
    type: UpdateParticipantConst.UPDATE_PARTICIPANT_KO,
    error,
  };
}
