import * as yup from 'yup';
import { tErrorKey } from '../helpers/translate';

const requiredNumber = tErrorKey('Este campo debe ser numérico');
const requiredText = tErrorKey('Este campo es obligatorio');
const oneMillionMax = tErrorKey('max-millon');

export const approvalInfoSchema = yup.object().shape({
  enrollment_approval: yup
    .number()
    .max(999999.99, oneMillionMax)
    .typeError(requiredNumber),
  monthly_price_approval: yup
    .number()
    .max(999999.99, oneMillionMax)
    .typeError(requiredNumber),
  materials_approval: yup
    .number()
    .max(999999.99, oneMillionMax)
    .typeError(requiredNumber),
  transport_price_approval: yup
    .number()
    .max(999999.99, oneMillionMax)
    .typeError(requiredNumber),
  other_costs_approval: yup
    .number()
    .max(999999.99, oneMillionMax)
    .typeError(requiredNumber),
  young_contrib_approval: yup
    .number()
    .max(999999.99, oneMillionMax)
    .typeError(requiredNumber),
  aproval_comments: yup.string(),
  scholarship_mentoring_approval: yup
    .string()
    .nullable()
    .matches(/^[0-1]{1}$/),
  scholarship_mentoring_finished: yup
    .string()
    .nullable()
    .matches(/(^$|^[0-1]{1}$)/),
  scholarship_mentoring_quality: yup.string().when('scholarship_mentoring_finished', {
    is: '1',
    then: yup
      .string()
      .matches(/^[A-B-C]{1}$/, requiredText)
      .typeError(requiredText),
  }),
});
