import { Tooltip } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React from 'react';
import { connect } from 'react-redux';
import { tKey } from '../../../helpers/translate';
import { AcademyDataList } from '../../../redux/academy/definitions';
import { selectTypeList } from '../../../redux/academy/selectors';
import { IdCodeColor } from '../../../redux/common/definitions';
import { AppState } from '../../../redux/root-reducer';

interface WhatTypeProps {
  educationalTypes: IdCodeColor[];
  rowData: AcademyDataList;
}

const WhatType: React.FC<WhatTypeProps> = ({ rowData, educationalTypes }) => {
  if (!rowData.educational_types.length) return <span>{tKey('No hay datos')}</span>;

  return (
    <>
      {educationalTypes?.map(
        (types, index) =>
          rowData.educational_types.includes(types.code) && (
            <Tooltip key={index} title={types.name}>
              <Brightness1Icon style={{ color: types.color, height: '15px', verticalAlign: 'text-bottom' }} />
            </Tooltip>
          ),
      )}
    </>
  );
};

export default connect((state: AppState) => ({
  educationalTypes: selectTypeList(state),
}))(WhatType);
