import moment from 'moment';
import { EventDataState } from '../event/reducers';
import { AreaSubArea } from '../project/definitions';
import { AppState } from '../root-reducer';
import { selectUsersByRole } from '../user/selectors';
import { EventData, EventSubscriptionData, RegistrationIntervalData, UserRegistrationData } from './definitions';

export const selectEvent = (state: AppState): EventDataState => state.eventReducer;
export const selectEventSubscriptionList = (state: AppState) => (tipo: string) => selectEvent(state).eventSubscriptions;
export const selectEventRegistrationList = (state: AppState) => (tipo: string) => {
  return selectEvent(state).eventRegistrations.filter(register => {
    if (tipo === 'jovenes') {
      return register.student === 'Sí' || register.manual === 'No';
    } else {
      return register.student === 'No';
    }
  });
};
export const selectEventData = (state: AppState): EventData => selectEvent(state).eventData;
export const selectEventsList = (state: AppState): EventData[] => {
  const eventListData = selectEvent(state).eventsList;
  eventListData.map(item => {
    return (item.start_date = moment(item.start_date).format('DD/MM/YYYY'));
  });

  return eventListData;
};
export const selectAreasSubAreas = (state: AppState): AreaSubArea[] => selectEvent(state).areasSubareasList;
export const selectLoading = (state: AppState): boolean => selectEvent(state).loading;
export const selectConnectionUrl = (state: AppState): RegistrationIntervalData =>
  selectEvent(state).registrationIntervalData;
export const selectUserRegistrationData = (state: AppState): UserRegistrationData =>
  selectEvent(state).userRegistrationData;

export const selectEventUsersList = (
  state: AppState,
  roles: string[],
  userAttends: EventSubscriptionData[],
): { id: number; info: string; role_code: string }[] | [] =>
  selectUsersByRole(state, roles)
    .filter(user => !userAttends.some(userRequest => user.id === userRequest.user_id))
    .map(user => ({
      id: user.id,
      info: `${user.name} ${user.surname} (${user.email}) (${user.document_number})`,
      role_code: user.role_code,
    }));
