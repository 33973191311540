import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import SimpleList from '../../../components/Lists/SimpleList';
import Loading from '../../../components/Loading/Loading';
import Title from '../../../components/Title/Title';
import { tKey } from '../../../helpers/translate';
import {
  addSubarea,
  deleteSubArea,
  disableArea,
  editArea,
  editSubArea,
  getAreas,
  storeAreas,
} from '../../../redux/area/action';
import { AreasData, AreasDispatch } from '../../../redux/area/definitions';
import { selectAreasList } from '../../../redux/area/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from './areasConfig.module.scss';
import SubmenuManagementArea from './SubmenuManagementArea';

const TextInputModal = React.lazy(() => import('../../../components/Modal/TextInputModal/TextInputModal'));

interface AreasConfigProps extends RouteComponentProps {
  canIconfigAreas: boolean;
  areasList: AreasData[];
  getAreas: () => void;
  disableArea: (id: number) => void;
  deleteSubArea: (id: number) => void;
  addSubArea: (id: number, data: string) => void;
  editSubArea: (id: number, data: string) => void;
  editArea: (id: number, data: AreasData) => void;
  storeArea: (name: string) => void;
}

const AreasConfig: React.FC<AreasConfigProps> = ({
  match,
  canIconfigAreas,
  areasList,
  getAreas,
  editArea,
  disableArea,
  deleteSubArea,
  addSubArea,
  editSubArea,
  storeArea,
}) => {
  const history = useHistory();
  const { path } = match;
  const selectedSection = path.substring(path.lastIndexOf('/') + 1);
  const [showModal, setShowModal] = useState<'open' | 'close'>('close');

  useEffect(() => {
    getAreas();
  }, [getAreas]);

  const handleOpenModal = () => setShowModal('open');
  const handleCloseModal = () => setShowModal('close');

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuManagementArea canConfigure={canIconfigAreas} selected={selectedSection} submenu={selectedSection} />
      }
      rightSubmenu={<ActionsMenu goBack onGoBack={() => history.push('/configuracion/gestion-areas')} />}
    >
      <div className={styles.config}>
        <div className={styles.config__header}>
          <Title>{tKey('Areas')}</Title>
          <Button color="primary" variant="contained" onClick={handleOpenModal} startIcon={<AddIcon />}>
            {tKey('Nueva área')}
          </Button>
        </div>
        <div className={styles.containerConfig}>
          {!areasList ? (
            <Loading big />
          ) : (
            areasList.map(area => (
              <SimpleList
                key={area.id}
                id={area.id}
                code={area.code}
                reserved={area.reserved as boolean}
                name={tKey(area.name)}
                itemsList={area.subareas}
                deleteFunc={deleteSubArea}
                editFunc={editSubArea}
                addFunc={addSubArea}
                editArea={editArea}
                disableArea={disableArea}
              />
            ))
          )}
        </div>
      </div>

      <Suspense fallback={<Fragment />}>
        {showModal === 'open' && (
          <TextInputModal
            view={showModal === 'open'}
            title={tKey('Nueva área')}
            buttonLabelAccept={tKey('Crear')}
            buttonLabelCancel={tKey('Cancelar')}
            handleClose={handleCloseModal}
            sendTextAreaData={storeArea}
          />
        )}
      </Suspense>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  canIconfigAreas: selectUserCan(state)('action_areas_config'),
  areasList: selectAreasList(state),
});

const mapDispatchToProps = (dispatch: AreasDispatch) => ({
  getAreas: (): void => dispatch(getAreas()),
  editArea: (id: number, data: AreasData): void => dispatch(editArea(id, data)),
  disableArea: (id: number): void => dispatch(disableArea(id)),
  deleteSubArea: (id: number): void => dispatch(deleteSubArea(id)),
  addSubArea: (id: number, data: string): void => dispatch(addSubarea(id, data)),
  editSubArea: (id: number, data: string): void => dispatch(editSubArea(id, data)),
  storeArea: (name: string): void => dispatch(storeAreas(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AreasConfig);
