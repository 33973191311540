import React from 'react';
import FncLabel from '../FncLabel/FncLabel';
import styles from './fncSelect.module.scss';
import FncControlErrorMessage from '../FncControlErrorMessage/FncControlErrorMessage';

export interface OptionSelect {
  value: string | number;
  label: string;
}

export interface FncSelectProps extends React.ComponentPropsWithoutRef<'select'> {
  options: OptionSelect[];
  label?: string;
  customClass?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
}

const FncSelect = ({
  options,
  placeholder,
  disabled,
  customClass,
  label,
  required = false,
  errorMessage,
  ...props
}: FncSelectProps) => {
  props.id = props?.id || props?.name || `Select_${Math.random()}`;
  return (
    <div className={`${styles.root} ${customClass ? customClass : ''} ${errorMessage && styles.hasError}`}>
      {label && (
        <FncLabel required={required} htmlFor={props.id}>
          {label}
        </FncLabel>
      )}
      <select className={styles.select} disabled={disabled} {...props}>
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {errorMessage && <FncControlErrorMessage message={errorMessage} />}
    </div>
  );
};

export default FncSelect;
