import React from 'react';
import { connect } from 'react-redux';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import ProjectList from './ProjectList';

interface ProjectPageProps {
  userCan: boolean;
}

const MyProjects: React.FC<ProjectPageProps> = ({ userCan }) => {
  return <ProjectList permission={!!userCan} />;
};
const mapStateToProps = (state: AppState) => ({
  userCan: selectUserCan(state)('action_nouscims'),
});

export default connect(mapStateToProps, undefined)(MyProjects);
