import { InputAdornment } from '@material-ui/core';
import React from 'react';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import { tScholarshipKey } from '../../../../helpers/translate';

interface EconomicJustificationTotalsProps {
  totalConfirmed: number;
  totalPaid: number;
  totalJustified: number;
  remainingAmount: number;
}

const EconomicJustificationTotals: React.FC<EconomicJustificationTotalsProps> = ({
  totalConfirmed,
  totalPaid,
  totalJustified,
  remainingAmount,
}) => {
  return (
    <FormContainer title={tScholarshipKey('justification_totals')}>
      <TextInput
        type="number"
        disabled
        label={tScholarshipKey('justification_total_confirmed')}
        value={totalConfirmed}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
      <TextInput
        type="number"
        disabled
        label={tScholarshipKey('justification_total_paid')}
        value={totalPaid}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
      <TextInput
        type="number"
        disabled
        label={tScholarshipKey('justification_total_justified')}
        value={totalJustified}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
      <TextInput
        type="number"
        disabled
        label={tScholarshipKey('justification_total_remaining')}
        value={remainingAmount}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
      />
    </FormContainer>
  );
};

export default EconomicJustificationTotals;
