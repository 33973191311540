import { Action } from 'redux';
import { DatesData, IDate, ITime } from '../calendar/definitions';
import { CalendarInterviewsConst } from './action_types';

export interface GetInterviewsCalendar {
  dates: IDate[];
  times: ITime[];
  next: number;
  prev: number;
}

export const initialState = {
  loading: false,
  loadingDates: false,
  loadingTimes: false,
  error: null as string | null,
  datesTime: {} as DatesData | null,
  selectedDate: {} as IDate,
  selectedTime: {} as ITime,
  responseSetNotAvailable: null as string | null,
  responseSetFree: null as string | null,
  idScholarship: 0 as number,
};

export type StudentCalendarState = typeof initialState;

export interface GetFreeInterviewDatesAction extends Action {
  type: CalendarInterviewsConst.GET_INTERVIEWS_DATES;
  scholarship_id: number;
  date: number;
}

export interface GetFreeInterviewDatesOkAction extends Action {
  type: CalendarInterviewsConst.GET_INTERVIEWS_DATES_OK;
  datesTime: DatesData;
}

export interface GetFreeInterviewDatesKoAction extends Action {
  type: CalendarInterviewsConst.GET_INTERVIEWS_DATES_KO;
  error: string;
}

export interface ScholarshipAskInterviewsAction extends Action {
  type: CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS;
  date: number;
  id: number;
  redirect: string;
  channel: string;
}

export interface ScholarshipAskInterviewsOkAction extends Action {
  type: CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS_OK;
}

export interface ScholarshipAskInterviewsKoAction extends Action {
  type: CalendarInterviewsConst.SCHOLARSHIP_ASK_INTERVIEWS_KO;
  error: string;
}

export type CalendarScholarshipAction =
  | GetFreeInterviewDatesAction
  | GetFreeInterviewDatesOkAction
  | GetFreeInterviewDatesKoAction
  | ScholarshipAskInterviewsAction
  | ScholarshipAskInterviewsOkAction
  | ScholarshipAskInterviewsKoAction;

export type CalendarScholarshipDispatch = (action: CalendarScholarshipAction) => void;
