import { APIError } from '../../api/api';
import { UserStates } from '../common/definitions';
import { AppState } from '../root-reducer';
import { Notification, NotificationsState } from './definitions';

export const selectNotificationsReducer = (state: AppState): NotificationsState => state.notificationsReducer;

export const selectIsPolling = (state: AppState) => selectNotificationsReducer(state).polling;
export const selectIsLoading = (state: AppState): boolean => selectNotificationsReducer(state).loading;
export const selectError = (state: AppState): APIError | null => selectNotificationsReducer(state).error;
export const selectNotificationsList = (state: AppState): Notification =>
  selectNotificationsReducer(state).notifications;
export const selectUnreadMessageThreadsNumber = (state: AppState): number =>
  selectNotificationsReducer(state).unread_message_threads;
export const selectUnreadAlertsNumber = (state: AppState): number => selectNotificationsReducer(state).unread_alerts;
export const selectUnreadPendingTasksNumber = (state: AppState): number =>
  selectNotificationsReducer(state).unread_pending_tasks;
export const selectUnreadNotificationsNumber = (state: AppState): number =>
  selectUnreadMessageThreadsNumber(state) + selectUnreadAlertsNumber(state) + selectUnreadPendingTasksNumber(state);
export const mustReceiveNotificacions = (state: AppState): boolean =>
  (state.authReducer.userInfo.state === UserStates.CREATED || state.authReducer.userInfo.state === null) &&
  state.notificationsReducer.version === 0;
export const selectVersion = (state: AppState): number => selectNotificationsReducer(state).version || 0;
export const selectUserChannels = (state: AppState): string[] => selectNotificationsReducer(state).channels;
