import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { tProjectKey } from '../../../../helpers/translate';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { PaymentSettingsData } from '../../../../redux/project/definitions';
import { agreementSchema } from '../../../../validations/tracingProjectSchema';
import styles from './paymentSettingsForm.module.scss';

interface PaymentSettingsFormProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  control: Control<T>;
  paymentSettingsMetadata?: any[];
  paymentSettingsData: PaymentSettingsData;
  genericMetadata: GenericMetadata;
  disabled?: boolean;
  seat?: boolean;
  watch: any;
}

const PaymentSettingsForm = <T extends FieldValues>({
  errors,
  control,
  paymentSettingsData,
  genericMetadata,
  disabled,
  seat,
  watch,
}: PaymentSettingsFormProps<T>): ReactElement => {
  let center_cost_channel_code: string | null = null;
  let center_cost_delegation_code: string | null = null;
  let center_cost_area_code: string | null = null;
  let center_cost_project_code: string | null = null;
  let agreement_number = null;
  let description = null;
  let concept = null;

  if (paymentSettingsData) {
    center_cost_channel_code = paymentSettingsData.center_cost_channel_code;
    center_cost_delegation_code = paymentSettingsData.center_cost_delegation_code;
    center_cost_area_code = paymentSettingsData.center_cost_area_code;
    center_cost_project_code = paymentSettingsData.center_cost_project_code;
    agreement_number = paymentSettingsData.agreement_number;
    description = paymentSettingsData.description;
    concept = paymentSettingsData.concept;
  }

  const { areas, channels, delegations, projects } = genericMetadata;

  return (
    <div className={styles.paymentsSetting}>
      <section className={styles.cost}>
        {channels ? (
          <SelectController
            name={'center_cost_channel_code'}
            control={control}
            errors={errors}
            schema={agreementSchema}
            disabled={disabled}
            label={tProjectKey('Canal')}
            size="50"
            defaultValue={center_cost_channel_code}
          >
            {channels.map(element => (
              <MenuItem key={'ch'.concat(element.code || '')} value={element.code}>
                {element.name}
              </MenuItem>
            ))}
          </SelectController>
        ) : null}

        {delegations ? (
          <SelectController
            name={'center_cost_delegation_code'}
            control={control}
            errors={errors}
            schema={agreementSchema}
            disabled={disabled}
            label={tProjectKey('Delegación')}
            size="50"
            defaultValue={center_cost_delegation_code}
          >
            {delegations
              ?.filter(
                e =>
                  e.channel_code === watch('center_cost_channel_code', center_cost_channel_code) ||
                  e.channel_code === null,
              )
              .map(element => (
                <MenuItem key={'de'.concat(element.code || '')} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
          </SelectController>
        ) : null}

        {areas ? (
          <SelectController
            name={'center_cost_area_code'}
            control={control}
            errors={errors}
            schema={agreementSchema}
            disabled={disabled}
            label={tProjectKey('Departamento')}
            size="50"
            defaultValue={center_cost_area_code}
          >
            {areas
              ?.filter(
                e =>
                  e.delegation_code === watch('center_cost_delegation_code', center_cost_delegation_code) ||
                  e.delegation_code === null,
              )
              .map(element => (
                <MenuItem key={'ar'.concat(element.code || '')} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
          </SelectController>
        ) : (
          areas
        )}

        {projects ? (
          <SelectController
            name={'center_cost_project_code'}
            control={control}
            errors={errors}
            schema={agreementSchema}
            disabled={disabled}
            label={tProjectKey('Proyecto')}
            size="50"
            defaultValue={center_cost_project_code}
          >
            {projects
              ?.filter(
                e => e.area_code === watch('center_cost_area_code', center_cost_area_code) || e.area_code === null,
              )
              .map(element => (
                <MenuItem key={'pr'.concat(element.code || '')} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
          </SelectController>
        ) : null}
        <TextInputController
          schema={agreementSchema}
          size="50"
          label={tProjectKey('Número de convenio')}
          name={'agreement_number'}
          errors={errors}
          disabled
          control={control}
          defaultValue={agreement_number}
        ></TextInputController>

        <TextInputController
          schema={agreementSchema}
          size="50"
          label={tProjectKey('Concepto')}
          name={'concept'}
          errors={errors}
          control={control}
          disabled={seat || disabled}
          defaultValue={concept}
        ></TextInputController>
      </section>

      <TextInputController
        className={styles.textArea}
        schema={agreementSchema}
        size="100"
        label={tProjectKey('Descripcion')}
        name={'description'}
        errors={errors}
        control={control}
        defaultValue={description}
        rows="4"
        rowsMax="10"
        multiline
        disabled={seat || disabled}
      ></TextInputController>
    </div>
  );
};

export default PaymentSettingsForm;
