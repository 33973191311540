/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, Checkbox } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getCustomFieldsAreaApi } from '../../../api/CustomField/customField';
import ComboClick from '../../../components/ComboClick/ComboClick';
import FormContainer from '../../../components/Forms/FormContainer';
import FormWithMenu from '../../../components/Layout/FormWithMenu';
import Layout from '../../../components/Layout/Layout';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SelectController from '../../../components/Select/SelectController';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import Title from '../../../components/Title/Title';
import errorMessage from '../../../helpers/errorMessage';
import { tFormKey, tKey } from '../../../helpers/translate';
import { getAreas } from '../../../redux/area/action';
import { AreasData, SubAreaInfo } from '../../../redux/area/definitions';
import { selectAreasList, selectSubAreasList } from '../../../redux/area/selectors';
import { IdCodeNamePair } from '../../../redux/common/definitions';
import { internalProjectsectionField } from '../../../redux/internal-projects/definitions';
import { assigment, unassigment } from '../../../redux/kpis/action';
import { CustomFieldADispatch, Point, sectionField } from '../../../redux/kpis/definitions';
import { selectLoading } from '../../../redux/kpis/selectors';
import { AppState } from '../../../redux/root-reducer';
import { AreaSchema, SubareaSchema } from '../../../validations/formSchema';
import InternalProjectsConfigurationFields from '../../InternalProjects/InternalProjectsConfigurationFields/InternalProjectsConfigurationFields';
import SubmenuConfCustomField from '../SubmenuConfCustomField';
import styles from './kpis.module.scss';

interface CustomFieldByArea {
  id: number;
  code: string;
  description: string;
  description_public: string;
  active: boolean;
  project_default: boolean;
  areas: IdCodeNamePair[];
  subareas: IdCodeNamePair[];
}
interface AssigmentProps extends RouteComponentProps<{ id: string; code: string; section: string }> {
  loading: boolean;
  areasList: AreasData[];
  subAreaList: SubAreaInfo[];
  getAreas: () => void;
  assigment: (point: Point, data: any) => void;
  unassigment: (point: Point, data: any, section: string) => void;
}

const AssigmentForm: React.FC<AssigmentProps> = ({
  match,
  loading,
  areasList,
  subAreaList,
  getAreas,
  assigment,
  unassigment,
}) => {
  const [, , config_type, , section, source] = match.url.split('/');
  const [filtered, setFiltered] = useState<any>([]);
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [data, setData] = React.useState<CustomFieldByArea | null>();
  const [valuesList, setValuesList] = React.useState<CustomFieldByArea[]>([]);

  const area = 'area';
  const { handleSubmit, errors, control, watch, setValue } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: area === source ? AreaSchema : SubareaSchema,
  });

  useEffect(() => {
    getAreas();
    setValuesList([]);
    setValue(area === source ? 'idArea' : 'idSubArea', '');
  }, [section, source]); // eslint-disable-line react-hooks/exhaustive-deps

  const isProjectConfig = config_type === 'proyectos';

  const filteredAreas = areasList.filter((area_item: AreasData) => area_item.reserved === (isProjectConfig ? 0 : 1));

  const detailsArea = (code: string, data: CustomFieldByArea[]) => {
    const filterArr = data.filter(field => !!field.areas.filter(fil => fil.code === code).length);
    setValuesList((prevArray: any) => [...prevArray, ...filterArr]);
  };
  const detailsSubArea = (code: string, data: CustomFieldByArea[]) => {
    const filterArr = data.filter(field => !!field.subareas.filter(fil => fil.code === code).length);
    setValuesList((prevArray: any) => [...prevArray, ...filterArr]);
  };

  const getCustomFieldsByArea = async (
    section: string,
    code_group: string,
    group: string,
  ): Promise<CustomFieldByArea[]> => {
    const data = await getCustomFieldsAreaApi<CustomFieldByArea>(section, code_group, group);
    setValuesList([]);
    return data;
  };

  const filterArea = async (section: string, code_group: string, group: string) => {
    try {
      const data = await getCustomFieldsByArea(section, code_group, group);
      setFiltered(
        data.filter(field => {
          const subArea = subAreaList.filter(subArea => subArea.codeArea === group).map(code => code.code);
          return (
            !field.areas.filter(fil => fil.code === group).length &&
            !field.subareas.filter(subarea => subArea.includes(subarea.code)).length
          );
        }),
      );
      detailsArea(group, data);
    } catch (error) {
      console.log(errorMessage(error));
    }
  };
  const filterSubArea = async (section: string, code_group: string, group: string) => {
    try {
      const data = await getCustomFieldsByArea(section, code_group, group);
      setFiltered(
        data.filter(field => {
          const area = subAreaList.find(subArea => subArea.code === group)?.codeArea;
          return (
            !field.areas.filter(fil => fil.code === area).length &&
            !field.subareas.filter(fil => fil.code === group).length
          );
        }),
      );
      detailsSubArea(group, data);
    } catch (error) {
      console.log(errorMessage(error));
    }
  };

  const handleDelete = (row: any) => {
    const id = row.id;
    setValuesList((prevArray: any) => [...prevArray.filter((prev: any) => prev.id !== id)]);
  };

  const idItem = (data: CustomFieldByArea) => {
    setData(data);
    setModal({ view: true, body: [] });
  };

  const onDelete = (data: any) => {
    const dataDefault =
      area === source
        ? { customFieldId: data.id, idArea: watch('idArea') }
        : { customFieldId: data.id, idSubArea: watch('idSubArea') };
    unassigment('areas', dataDefault, section);
    handleDelete(data);
  };

  const handleAddAreas = (data: any) => {
    if (data) {
      data.area = filteredAreas.find(area => area.id === watch('idArea'))?.name;
      !valuesList.find((values: any) => values.id === data.id) &&
        setValuesList((prevArray: any) => [...prevArray, { ...data }]);
    }
  };
  const handleAddSubAreas = (data: any) => {
    if (data) {
      data.subarea = subAreaList.find(area => area.id === watch('idSubArea'))?.name;
      !valuesList.find((values: any) => values.id === data.id) &&
        setValuesList((prevArray: any) => [...prevArray, data]);
    }
  };

  const onSubmitAreas = (data: any) => {
    const inputs = valuesList.map((value: any) => ({
      id: value.id,
      project_default: value.project_default,
    }));
    data.inputs = inputs;
    delete data.kpi;
    assigment(source === area ? 'areas' : 'subareas', data);
  };

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSubmitAreas),
      startIcon: <Save />,
      disableElevation: true,
      fullWidth: true,
      variant: 'contained',
      color: 'primary',
    },
  ];
  const tableData = {
    options: {
      actionsColumnIndex: -1,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
    title: tKey('Listado de campos'),
    columns: [
      {
        field: 'code',
        title: tKey('Código'),
        //@ts-ignore
        width: '80px',
      },
      {
        field: 'description',
        title: tKey('Descripción'),
        //@ts-ignore
        width: '330px',
      },
      {
        field: 'field_data_types',
        title: tKey('Tipo de dato'),
        //@ts-ignore
        width: '150px',
      },
      {
        field: 'project_default',
        title: tKey('Campo por defecto'),
        //@ts-ignore
        width: '150px',
        render: (rowData: CustomFieldByArea) => (
          <Checkbox
            checked={rowData.project_default}
            style={{ width: 50 }}
            onClick={() => {
              setValuesList((prevArray: any) => {
                const index = prevArray.findIndex(
                  (prev: any) => prev.id === rowData.id && prev.description === rowData.description,
                );
                prevArray[index].project_default = +!rowData.project_default;
                return [...prevArray];
              });
            }}
          />
        ),
      },
    ],
    data: valuesList,
  };

  const title = sectionField?.find(sectionItem => sectionItem.code === section)?.name;
  const title_ip = internalProjectsectionField?.find(sectionItem => sectionItem.code === section)?.name;

  return (
    <Layout
      leftSubmenu={
        isProjectConfig ? (
          <SubmenuConfCustomField submenu={tKey(title || '')} selected={source} />
        ) : (
          <InternalProjectsConfigurationFields submenu={tKey(title_ip || '')} selected={source} />
        )
      }
    >
      {loading ? (
        <Loading big />
      ) : (
        <FormWithMenu actionsButtons={buttonsEdit}>
          <div className={styles.containerConfig}>
            <Title>{`${area === source ? tKey('Áreas') : tKey('Subáreas')} - ${tKey(title || title_ip || '')}`}</Title>
            <FormContainer title="" /* className={styles.form} */>
              <SelectController
                size="50"
                control={control}
                errors={errors}
                schema={area === source ? AreaSchema : SubareaSchema}
                name={area === source ? 'idArea' : 'idSubArea'}
                label={area === source ? tKey('Áreas') : tKey('Subareas')}
                onClick={(event: any) => {
                  area === source
                    ? filterArea(
                        section,
                        source,
                        filteredAreas.find(area => area.id === event.target?.value)?.code || '',
                      )
                    : filterSubArea(
                        section,
                        source,
                        subAreaList.find(subarea => subarea.id === event.target?.value)?.code || '',
                      );
                }}
              >
                {area === source
                  ? filteredAreas.map((type: AreasData) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))
                  : subAreaList.map((type: SubAreaInfo) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
              </SelectController>

              <ComboClick
                options={filtered}
                control={control}
                error={errors.kpi}
                label={tFormKey('Campos')}
                optionLabel="description_public"
                fieldToSend="code"
                name="kpi"
                onChange={area === source ? handleAddAreas : handleAddSubAreas}
                disabled={area === source ? !watch('idArea') : !watch('idSubArea')}
              />
            </FormContainer>
            <TableWithEdit
              tableData={tableData}
              key={JSON.stringify(tableData.data)}
              deleteFunc={(rowData: CustomFieldByArea) => idItem(rowData)}
            />
          </div>
          <TransitionModal
            view={modal.view}
            handleClose={() => {
              setData(null);
              setModal({ view: false, body: [] });
            }}
            bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
            buttonOk={tKey('Si')}
            buttonKo={tKey('No')}
            title={tKey('¡Atención!')}
            handleYes={() => {
              onDelete(data);
              setModal({ view: false, body: [] });
            }}
          />
        </FormWithMenu>
      )}
    </Layout>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectLoading(state),
  areasList: selectAreasList(state),
  subAreaList: selectSubAreasList(state),
});

const mapDispatchProps = (dispatch: CustomFieldADispatch) => ({
  getAreas: (): void => dispatch(getAreas()),
  assigment: (point: Point, data: any): void => dispatch(assigment(point, data)),
  unassigment: (point: Point, data: any, section: string): void => dispatch(unassigment(point, data, section)),
});

export default connect(mapStateProps, mapDispatchProps)(AssigmentForm);
