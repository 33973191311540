import { InternalProjectId, Educator } from '../../../redux/internal-projects/educators/Educator';
import { apiBaseUrl, apiCall } from '../../api';

const baseURL = apiBaseUrl;

export interface EducatorsResponse {
  data: Educator[];
}

export const getEducatorsFromApi = async (id: InternalProjectId): Promise<EducatorsResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return (await apiCall)<EducatorsResponse>(`${baseURL}/internal_project/${id}/participants/educators`, init);
};
