import { Action } from 'redux';
import { APIValidationError } from '../../api/api';
import { permissionType } from '../../constants/permissions_definitions';
import { tProjectKey } from '../../helpers/translate';
import { GetAreasAction } from '../area/definitions';
import { DocumentationItem, IdCodeNamePair } from '../common/definitions';
import { ProjectData, StateProject } from '../project/definitions';
import { CustomFieldsConst } from './action_types';

export interface CustomField {
  radio_title_left_position: number;
  radio_vertically: number;
  radio_value_label_position: string;
  id: number;
  section: string;
  field: string;
  code: string;
  mandatory: number | string;
  position: number;
  size: string;
  description: string;
  description_es: string;
  description_ca: string;
  description_fr: string;
  description_en: string;
  area_id: string;
  kpi_indicator_type_id: number;
  kpi_name?: string;
  field_data_types: string;
  value_list: IdCodeNamePair[];
  values?: string;
  refTotal: number;
  ref: number;
  custom_field_type: string | null;
  options: any;
  areas: any[];
  subareas: any[];
  project_default: boolean;
  auto_assign: boolean;
  custom_fields: IdCodeNamePair[];
  origin_custom_field_id: number | null;
  origin_condition: string | null;
  origin_value: string | null;
}

//export type TypesSection = 'kpi';

export type Group = 'area' | 'subarea';

export enum SectionEnum {
  KPI = 'kpis',
}

export enum CustomFieldDataTypes {
  NUMERICO = 'Numerico',
  PORCENTAJE = 'Porcentaje',
  ADJUNTO = 'Adjunto',
  LISTA_DE_VALORES = 'Lista_de_Valores',
  TEXTO = 'Texto',
  FECHA = 'Fecha',
  BLOQUE_DE_TEXTO = 'Bloque_de_texto',
  ETIQUETA = 'Etiqueta',
  LINK = 'Link',
  RADIO = 'Radio_Button',
  MULTISELECT = 'Multiselect',
  SUBTITULO = 'SubTitulo',
  POBLACION = 'Poblacion',
}

export type CustomFieldChildDependency = {
  id: number;
  name: string;
  condition: string;
  value: string | null;
  mandatory: boolean;
};

export type CustomFieldOption = {
  id: number;
  label: string;
  name: string;
  value: string;
  checked: boolean;
};

export type CustomFieldDocumentOption = {
  id: number;
  label: string;
  name: string;
  value: string;
};

export type SizeConfiguratorData = 'Large' | 'Medium' | 'Small';

export type CustomFieldElement = {
  custom_field_type: string;
  field_data_types: string;
  id: number;
  label: string;
  mandatory: boolean;
  name: string;
  options: CustomFieldOption[];
  radio_title_left_position: number;
  radio_vertically: number;
  radio_value_label_position: string;
  selectedValues: number[];
  position: number;
  project_default: number;
  size: SizeConfiguratorData;
  value: string | null;
  read_only: boolean;
  origin_custom_field_id: number | null;
  origin_condition: string | null;
  origin_value: string | null;
  child_dependencies: CustomFieldChildDependency[];
};

export type CustomFieldDocument = {
  custom_field_type: string;
  field_data_types: string;
  id: number;
  label: string;
  mandatory: boolean;
  name: string;
  options: CustomFieldDocumentOption[];
  position: number;
  project_default: number;
  size: SizeConfiguratorData;
  value: string | null;
  value_file: DocumentationItem[];
  read_only: boolean;
};

export type Point = 'areas' | 'subareas' | 'types';
export type Type = 'head' | 'line';

export interface KpiIndicatorType {
  id: number;
  description: string;
}

export type CodeSectionProject =
  | 'logica_proyecto'
  | 'kpis'
  | 'kpis_gf'
  | 'kpis_rvw'
  | 'new_kpis'
  | 'informe_seguimiento'
  | 'evaluacion_proyecto'
  | 'evaluacion_personal'
  | 'datos_basicos'
  | 'asistente_convenios'
  | 'calendario'
  | 'firma_convenio'
  | 'firma_seguimiento'
  | 'ficha_personal'
  | 'ficha_seguimiento'
  | 'seguimiento_pago'
  | 'aspectos_clave';

export type SectionProject = { name: string; code: CodeSectionProject };

const datos_basicos = { name: 'Datos básicos', code: 'datos_basicos' } as SectionProject;
const logica_proyecto = {
  name: 'Lógica de proyecto',
  code: 'logica_proyecto',
} as SectionProject;
const kpis = { name: 'Indicadores de seguimiento', code: 'kpis' } as SectionProject;
const kpis_gf = { name: 'Indicadores de seguimiento', code: 'kpis_gf' } as SectionProject;
const ev_proyecto = {
  name: 'Evaluación del proyecto',
  code: 'evaluacion_proyecto',
} as SectionProject;
const ev_personal = {
  name: 'Evaluación personal',
  code: 'evaluacion_personal',
} as SectionProject;
const asistente_convenios = {
  name: 'Asistente de convenios',
  code: 'asistente_convenios',
} as SectionProject;
const calendario = { name: 'Calendario', code: 'calendario' } as SectionProject;
const firma_convenio = { name: 'Firma de convenio', code: 'firma_convenio' } as SectionProject;
const informe_seguimiento = {
  name: 'Informe de Seguimiento',
  code: 'informe_seguimiento',
} as SectionProject;
const seguimiento_pago = {
  name: 'Seguimiento y pagos',
  code: 'seguimiento_pago',
} as SectionProject;
const aspectos_clave = {
  name: 'Aspectos clave',
  code: 'aspectos_clave',
} as SectionProject;

export const sectionField: SectionProject[] = [
  logica_proyecto,
  aspectos_clave,
  kpis,
  kpis_gf,
  informe_seguimiento,
  ev_proyecto,
  ev_personal,
];

export const FormAssistantSections: SectionProject[] = [
  logica_proyecto,
  aspectos_clave,
  informe_seguimiento,
  ev_proyecto,
  ev_personal,
];

export const ProjectSections = ({
  project,
  isNouscims,
}: {
  project: ProjectData;
  user_id: number | undefined;
  permissions: permissionType[] | undefined;
  isNouscims: boolean | undefined;
}) => {
  const projectSections: SectionProject[] = [datos_basicos, logica_proyecto, aspectos_clave];

  const convenio = isNouscims ? asistente_convenios : firma_convenio;

  switch (project.state_code) {
    case StateProject.EVALUATION_1:
    case StateProject.EVALUATION_2:
      if (isNouscims) {
        if (!project.untracked_project) projectSections.push(kpis_gf);
      }
      break;
    case StateProject.AGREEMENT:
    case StateProject.AGREEMENT_REVIEW:
    case StateProject.AGREEMENT_SIGNING:
      if (isNouscims) {
        projectSections.push(asistente_convenios);
        projectSections.push(calendario);
      }
      break;
    case StateProject.SIGNATURE_PENDING:
      projectSections.push(convenio);
      projectSections.push(calendario);
      break;
    case StateProject.KICK_OFF:
      projectSections.push(convenio);
      projectSections.push(calendario);
      break;
    case StateProject.TRACKING:
    case StateProject.TRACKING_VALIDATION:
      projectSections.push(convenio);
      projectSections.push(calendario);
      projectSections.push(kpis);

      if (isNouscims && project.state_code === StateProject.TRACKING_VALIDATION) {
        projectSections.push(informe_seguimiento);
      }
      break;
    case StateProject.TRACKING_FNC:
      projectSections.push(convenio);
      projectSections.push(calendario);
      if (isNouscims) {
        projectSections.push(kpis);
      }
      break;
    case StateProject.TRACKING_FINISHED:
      projectSections.push(convenio);
      projectSections.push(calendario);
      break;
    case StateProject.PAYMENT:
      projectSections.push(convenio);
      projectSections.push(calendario);
      if (isNouscims) {
        projectSections.push({
          ...seguimiento_pago,
          name: project.untracked_project ? tProjectKey('Pagos ') : seguimiento_pago.name,
        });
      }
      break;
    case StateProject.FOLLOWUP_EVALUATION_2:
    case StateProject.FOLLOWUP_EVALUATION_3:
    case StateProject.FOLLOWUP_EVALUATION_4:
      projectSections.push(convenio);
      projectSections.push(calendario);
      if (isNouscims) {
        projectSections.push(ev_personal);
        projectSections.push(ev_proyecto);
      }
      break;
    case StateProject.END:
      projectSections.push(convenio);
      projectSections.push(calendario);
      if (isNouscims) {
        projectSections.push(ev_personal);
        projectSections.push(ev_proyecto);
      }
      break;
    default:
      break;
  }

  return projectSections;
};

export const initialState = {
  loading: false,
  customFieldLoading: false,
  error: null as string | null,
  customField: {} as CustomField,
  listCustomFields: [] as CustomField[],
  kpiTypes: [] as KpiIndicatorType[],
  currentKpiType: {} as KpiIndicatorType,
  inputsList: [] as IdCodeNamePair[],
  sections: [] as IdCodeNamePair[],
  sizes: [] as IdCodeNamePair[],
  customType: [] as IdCodeNamePair[],
  validationErrors: null as APIValidationError | null,
  showModal: false as boolean,
  idCustomFieldDeactivating: 0 as number,
};

export type CustomFieldState = typeof initialState;

//GET ALL CUSTOMFIELDS
export interface GetCustomFieldList extends Action {
  type: CustomFieldsConst.GET_CUSTOMFIELDS_LIST;
  code: string;
}

export interface GetCustomFieldListOk extends Action {
  type: CustomFieldsConst.GET_CUSTOMFIELDS_LIST_OK;
  listCustomFields: CustomField[];
}

//GET ONE CUSTOMFIELDS
export interface GetOneCustomField extends Action {
  type: CustomFieldsConst.GET_ONE_CUSTOMFIELDS;
  code: string;
  id: string;
}

export interface GetOneCustomFieldOk extends Action {
  type: CustomFieldsConst.GET_ONE_CUSTOMFIELDS_OK;
  customField: CustomField;
}

//CREATE CUSTOMFIELD
export interface CreateCustomfield extends Action {
  type: CustomFieldsConst.CREATE_CUSTOMFIELD;
  data: CustomField;
}

export interface CreateCustomfieldOk extends Action {
  type: CustomFieldsConst.CREATE_CUSTOMFIELD_OK;
  data: CustomField;
}

//EDIT CUSTOMFIELD

export interface EditCustomfield extends Action {
  type: CustomFieldsConst.EDIT_CUSTOMFIELD;
  data: CustomField;
  id: string;
}

export interface EditCustomfieldOk extends Action {
  type: CustomFieldsConst.EDIT_CUSTOMFIELD_OK;
  data: CustomField;
}

//DELETE CUSTOMFIELD

export interface DeleteCustomfieldAction extends Action {
  type: CustomFieldsConst.DELETE_CUSTOMFIELD;
  id: number;
}

export interface DeleteCustomfieldActionOk extends Action {
  type: CustomFieldsConst.DELETE_CUSTOMFIELD_OK;
  id: number;
}

export interface DeleteCustomfieldDeactivateAction extends Action {
  type: CustomFieldsConst.DELETE_CUSTOMFIELD_DEACTIVATE;
  id: number;
}

//API VALIDATION ERRORS
export interface CustomFieldValidationErrorsAction extends Action {
  type: CustomFieldsConst.CUSTOMFIELD_VALIDATION_ERROR;
  validationErrors: APIValidationError;
}

//ASSIGMENT
export interface Assigment extends Action {
  type: CustomFieldsConst.ASSIGMENTS;
  point: Point;
  data: any;
}

export interface AssigmentOk extends Action {
  type: CustomFieldsConst.ASSIGMENTS_OK;
}

//UNASSIGMENT
export interface Unassigment extends Action {
  type: CustomFieldsConst.UNASSIGMENTS;
  point: Point;
  data: any;
  section: string;
}

export interface UnassigmentOk extends Action {
  type: CustomFieldsConst.UNASSIGMENTS_OK;
}

export interface GetKpiIndicatorsAction extends Action {
  type: CustomFieldsConst.GET_KPI_INIDICATORS;
}

export interface GetKpiIndicatorsActionOk extends Action {
  type: CustomFieldsConst.GET_KPI_INIDICATORS_OK;
  kpiTypes: KpiIndicatorType[];
}

export interface GetKpiIndicatorAction extends Action {
  type: CustomFieldsConst.GET_KPI_INIDICATOR;
  id: number;
}

export interface GetKpiIndicatorActionOk extends Action {
  type: CustomFieldsConst.GET_KPI_INIDICATOR_OK;
  kpiType: KpiIndicatorType;
}

export interface UpdateKpiIndicatorAction extends Action {
  type: CustomFieldsConst.UPDATE_KPI_INIDICATOR;
  kpiType: KpiIndicatorType;
}

export interface UpdateKpiIndicatorActionOk extends Action {
  type: CustomFieldsConst.UPDATE_KPI_INIDICATOR_OK;
  kpiTypes: KpiIndicatorType[];
}

export interface CreateKpiIndicatorAction extends Action {
  type: CustomFieldsConst.CREATE_KPI_INIDICATOR;
  kpiType: KpiIndicatorType;
}

export interface CreateKpiIndicatorActionOk extends Action {
  type: CustomFieldsConst.CREATE_KPI_INIDICATOR_OK;
  kpiTypes: KpiIndicatorType[];
}

export interface RemoveKpiIndicatorAction extends Action {
  type: CustomFieldsConst.REMOVE_KPI_INIDICATOR;
  id: number;
}

export interface RemoveKpiIndicatorActionOk extends Action {
  type: CustomFieldsConst.REMOVE_KPI_INIDICATOR_OK;
  id: number;
}

export interface AddKpiIndicatorAction extends Action {
  type: CustomFieldsConst.ADD_KPI_INIDICATOR;
}

export interface CloseModalAction extends Action {
  type: CustomFieldsConst.CLOSE_MODAL;
}

export interface SetKpiIndicatorDataAction extends Action {
  type: CustomFieldsConst.SET_KPI_INIDICATOR_DATA;
  key: string;
  value: string;
}

export interface CustomFieldGenericActionKo extends Action {
  type: CustomFieldsConst.CUSTOMFIELDS_GENERIC_KO;
  error: string;
}

export interface ClearCustomFieldDeactivationAction extends Action {
  type: CustomFieldsConst.CLEAR_CUSTOMFIELD_DEACTIVATION;
}

export interface DeactivateCustomFieldAction extends Action {
  type: CustomFieldsConst.DEACTIVATE_CUSTOMFIELD;
  id: number;
}

export interface DeactivateCustomFieldActionOk extends Action {
  type: CustomFieldsConst.DEACTIVATE_CUSTOMFIELD_OK;
  id: number;
}

export interface DeactivateCustomFieldActionKo extends Action {
  type: CustomFieldsConst.DEACTIVATE_CUSTOMFIELD_KO;
  error: string;
}

export type CustomFieldAction =
  | GetCustomFieldList
  | GetCustomFieldListOk
  | GetOneCustomField
  | GetOneCustomFieldOk
  | CreateCustomfield
  | CreateCustomfieldOk
  | EditCustomfield
  | EditCustomfieldOk
  | DeleteCustomfieldAction
  | DeleteCustomfieldActionOk
  | DeleteCustomfieldDeactivateAction
  | CustomFieldValidationErrorsAction
  | Assigment
  | AssigmentOk
  | Unassigment
  | UnassigmentOk
  | GetKpiIndicatorsAction
  | GetKpiIndicatorsActionOk
  | GetKpiIndicatorAction
  | GetKpiIndicatorActionOk
  | UpdateKpiIndicatorAction
  | UpdateKpiIndicatorActionOk
  | CreateKpiIndicatorAction
  | CreateKpiIndicatorActionOk
  | RemoveKpiIndicatorAction
  | RemoveKpiIndicatorActionOk
  | AddKpiIndicatorAction
  | CloseModalAction
  | SetKpiIndicatorDataAction
  | CustomFieldGenericActionKo
  | ClearCustomFieldDeactivationAction
  | DeactivateCustomFieldAction
  | DeactivateCustomFieldActionOk
  | DeactivateCustomFieldActionKo;

export type CustomFieldADispatch = (action: CustomFieldAction | GetAreasAction) => void;
