import { useDispatch, useSelector } from 'react-redux';
import { Participant } from '../../../../../../../../redux/internal-projects/participants/Participant';
import { addNewParticipant } from '../../../../../../../../redux/internal-projects/participants/create/actions';
import { updateParticipant } from '../../../../../../../../redux/internal-projects/participants/update/actions';
import {
  selectCreateParticipantError,
  selectCreateParticipantLoading,
} from '../../../../../../../../redux/internal-projects/participants/create/selectors';
import { UUID } from '../../../../../../../../types/Shared/standard';

export function NewEditParticipantViewModel() {
  const dispatch = useDispatch();

  function _save(participant: Participant): void {
    dispatch(addNewParticipant(participant));
  }
  function _update(id: UUID, data: Participant): void {
    dispatch(
      updateParticipant({
        ...data,
        id,
      }),
    );
  }

  const saving: boolean = useSelector(selectCreateParticipantLoading);
  const newServerErrors: string | null = useSelector(selectCreateParticipantError);

  return {
    save: _save,
    update: _update,
    saving: saving,
    serverErrors: newServerErrors,
  };
}

export type ViewModelType = typeof NewEditParticipantViewModel;
