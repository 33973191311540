import { ScholarshipPayment } from '../../../../redux/scholarship/definitions';
import PaymentType from '../../../../types/Accounting/Scholarship/PaymentType';

export class ScholarshipPaymentsDateFilter {
  constructor(
    private scholarshipPaymentList: ScholarshipPayment[],
    private paymentDate: string | null,
    private reloadDate: string | null,
  ) {}

  private getListWithPaymentDate(): ScholarshipPayment[] {
    return this.scholarshipPaymentList.filter(
      (payment: ScholarshipPayment) =>
        payment.payment_date === this.paymentDate && payment.payment_type === PaymentType.PAGO_CENTRO,
    );
  }

  private getListWithReloadDate(): ScholarshipPayment[] {
    return this.scholarshipPaymentList.filter(
      (payment: ScholarshipPayment) =>
        payment.payment_date === this.reloadDate &&
        (payment.payment_type === PaymentType.RECARGA || PaymentType.PAGO_BECADO),
    );
  }

  private getListWithBothDates(): ScholarshipPayment[] {
    return this.scholarshipPaymentList.filter(
      (payment: ScholarshipPayment) =>
        (payment.payment_date === this.reloadDate &&
          (payment.payment_type === PaymentType.RECARGA || PaymentType.PAGO_BECADO)) ||
        (payment.payment_date === this.paymentDate && payment.payment_type === PaymentType.PAGO_CENTRO),
    );
  }
  getFiltered(): ScholarshipPayment[] {
    if (this.paymentDate && (!this.reloadDate || this.reloadDate === '')) {
      return this.getListWithPaymentDate();
    }
    if (this.reloadDate && (!this.paymentDate || this.paymentDate === '')) {
      return this.getListWithReloadDate();
    }
    if (this.reloadDate && this.paymentDate) {
      return this.getListWithBothDates();
    }
    return this.scholarshipPaymentList;
  }
}
