import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { useTranslation } from 'react-i18next';
import { ObjectSchema, Shape } from 'yup';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../../components/RadioButtons/RadioButtonGroup';
import { keepStudyingOptions } from '../../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../../helpers/translate';
import { IdNamePair } from '../../../../../redux/common/definitions';
import {
  ScholarshipAbandonmentReasonsType,
  ScholarshipFollow,
  ScholarshipMetadata,
} from '../../../../../redux/scholarship/definitions';
import FollowUpCode from '../../../../../types/Scholarships/followUpCode';
import styles from '../followForm.module.scss';
import { useAcademicResultViewModel } from './useAcademicResultViewModel';

interface AcademicResultReasonsViewProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  scholarshipType: string;
  followUpCode: FollowUpCode;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  register?: ReturnType<typeof useForm>['register'];
  setDataFollow: (subKey: string, value: string | number) => void;
}

const AcademicResultReasonsView = <T extends FieldValues>({
  scholarshipFollow,
  scholarshipMetadata,
  readOnly,
  errors,
  control,
  scholarshipType,
  followUpCode,
  schema,
  register,
  setDataFollow,
}: AcademicResultReasonsViewProps<T>): ReactElement => {
  const { t } = useTranslation();

  const {
    keep_studying,
    scholarship_abandoment_reasons_id,
    scholarship_future_actions_id,
    scholarship_abandoment_reasons_others,
  } = scholarshipFollow;
  const { scholarshipAbandonmentReasons, decisionsStudyEnd } = scholarshipMetadata;

  const {
    filterScholarshipAbandonmentReasonsByType,
    scholarshipAbandonmentReasonsForKeepStudying,
  } = useAcademicResultViewModel();

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  const scholarshipAbandonmentReasonsFiltered = followUpCode.isLastFollowUp()
    ? filterScholarshipAbandonmentReasonsByType(scholarshipAbandonmentReasons, ScholarshipAbandonmentReasonsType.NO)
    : scholarshipAbandonmentReasonsForKeepStudying(scholarshipAbandonmentReasons, keep_studying);

  const futureActionsSelector = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        name="scholarship_future_actions_id"
        label={tScholarshipKey('¿Ha aprobado el curso?')}
        defaultValue={scholarship_future_actions_id}
        disabled={readOnly}
        schema={schema}
        validator={!readOnly}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {decisionsStudyEnd.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const keepStudyingRadioButtons = (): JSX.Element => {
    return (
      <RadioButtonGroup
        name="keep_studying"
        errors={errors.keep_studying}
        disabled={readOnly}
        questionText={
          !followUpCode.isBiannual()
            ? tScholarshipKey(t(`scholarship.${scholarshipType}.follow_form.keep_studying`))
            : tScholarshipKey(t(`scholarship.${scholarshipType}.follow_form.keep_studying_sem`))
        }
        options={keepStudyingOptions}
        value={keep_studying?.toString() || ''}
        register={register}
        onChange={e => {
          setDataFollow('keep_studying', +e.target.value);
        }}
      />
    );
  };

  const renderFirstQuestion = (): JSX.Element => {
    if (followUpCode.isLastFollowUp()) {
      return futureActionsSelector();
    }
    return keepStudyingRadioButtons();
  };

  const reasonsSelectorDisabled = (): boolean => {
    if (readOnly) {
      return true;
    }
    if (followUpCode.isLastFollowUp()) {
      return (
        scholarship_future_actions_id === null ||
        (scholarship_future_actions_id !== 4 && scholarship_future_actions_id !== 3)
      );
    }
    return keep_studying === 1 || keep_studying === null;
  };

  const reasonsSelectorValidator = (): boolean => {
    if (readOnly) {
      return false;
    }
    if (followUpCode.isLastFollowUp()) {
      return scholarship_future_actions_id === 4 || scholarship_future_actions_id === 3;
    }
    return keep_studying === 0;
  };

  const renderReasonsSelector = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        name="scholarship_abandoment_reasons_id"
        label={tScholarshipKey('no-continua-estudios')}
        defaultValue={scholarship_abandoment_reasons_id}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
        disabled={reasonsSelectorDisabled()}
        validator={reasonsSelectorValidator()}
      >
        {scholarshipAbandonmentReasonsFiltered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {tScholarshipKey(element.name)}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderAbandonmentReasonsOthers = (): JSX.Element | undefined => {
    if (scholarship_abandoment_reasons_id === 9) {
      return (
        <div className={styles.containerFullGridRow}>
          <TextInputController
            control={control}
            name="scholarship_abandoment_reasons_others"
            label={tScholarshipKey('Explica el caso')}
            defaultValue={scholarship_abandoment_reasons_others}
            fullWidth
            schema={schema}
            errors={errors}
            multiline
            rows={3}
            validator={!readOnly}
            rowsMax={3}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              setDataFollow('scholarship_abandoment_reasons_others', e.target.value)
            }
          />
        </div>
      );
    }
    return undefined;
  };

  return (
    <div className={styles.container}>
      {renderFirstQuestion()}
      {renderReasonsSelector()}
      {renderAbandonmentReasonsOthers()}
    </div>
  );
};

export default AcademicResultReasonsView;
