import assertUnreachable from '../../helpers/assertUnreachable';
import { getDocs } from '../../helpers/getDocs';
import { DocumentationItem, DocumentationTypes, ResourceImageData } from '../common/definitions';
import { ProjectConst } from './action_type';
import {
  FollowUp,
  ProjectAction,
  ProjectListItem,
  ProjectState,
  SchedulePeriods,
  ScheduledAppointmentItem,
  ScheduledAppointmentState,
  StateProject,
  initialState,
} from './definitions';

const projectReducer = (state = initialState, action: ProjectAction): ProjectState => {
  let newProjectImages;
  switch (action.type) {
    case ProjectConst.GET_ONE_PROJECT:
    case ProjectConst.CREATE_PROJECT:
    case ProjectConst.EDIT_PROJECT:
    case ProjectConst.DELETE_PROJECT:
    case ProjectConst.GET_DOCUMENTATION:
    case ProjectConst.PROJECT_CHANGE_STATE:
    case ProjectConst.PROJECT_CANCEL_AGREEMENT:
      return {
        ...state,
        loadingSchedules: true,
        loading: true,
        loadingFields: true,
        error: null,
      };
    case ProjectConst.GET_PROJECTS:
      return {
        ...state,
        loading: true,
        error: null,
        projectData: initialState.projectData,
        documentation: initialState.documentation,
      };
    case ProjectConst.ADD_DOCUMENT:
    case ProjectConst.DOWNLOAD_DOCUMENT:
    case ProjectConst.GENERIC_FORM_DOWNLOAD_FILE:
    case ProjectConst.DELETE_DOCUMENT:
    case ProjectConst.GET_DOCUMENT:
    case ProjectConst.GENERIC_FORM_GET_FILE:
    case ProjectConst.UPLOAD_AGREEMENT:
    case ProjectConst.DELETE_AGREEMENT:
    case ProjectConst.DOWNLOAD_FOLLOW_UP:
    case ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT:
    case ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT:
    case ProjectConst.UPLOAD_PAYMENT_FILE:
    case ProjectConst.REMOVE_PAYMENT_FILE:
    case ProjectConst.ADD_PROJECT_IMAGE:
    case ProjectConst.EDIT_PROJECT_IMAGE:
    case ProjectConst.DELETE_PROJECT_IMAGE:
      return { ...state };
    case ProjectConst.GET_PROJECT_IMAGES:
      return { ...state, projectData: { ...state.projectData, loadingImages: true, images: [] } };
    case ProjectConst.PROJECT_CANCEL_AGREEMENT_OK:
      const documentationUpdated = state.documentation.map((documentationItem: DocumentationItem) => {
        if (documentationItem.type === DocumentationTypes.CONVENIO) {
          documentationItem.file_id = null;
        }
        return documentationItem;
      });
      return {
        ...state,
        loadingFields: true,
        loading: false,
        loadingSchedules: false,
        projectData: {
          ...state.projectData,
          state_code: action.state_code,
          state_name: action.state_name,
        },
        documentation: documentationUpdated,
      };
    case ProjectConst.PROJECT_CHANGE_STATE_OK:
      return {
        ...state,
        loadingFields: true,
        loading: false,
        loadingSchedules: false,
        projectData: { ...state.projectData, state_code: action.state_code, state_name: action.state_name },
      };
    case ProjectConst.GET_DOCUMENT_OK:
    case ProjectConst.GENERIC_FORM_GET_FILE_OK:
      const { data: download } = action;
      getDocs(download, false);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ProjectConst.GENERIC_FORM_DOWNLOAD_FILE_OK:
    case ProjectConst.DOWNLOAD_DOCUMENT_OK:
    case ProjectConst.DOWNLOAD_FOLLOW_UP_OK:
      const { data, name } = action;
      getDocs(data, true, name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ProjectConst.ADD_DOCUMENT_OK:
    case ProjectConst.GET_DOCUMENTATION_OK:
    case ProjectConst.DELETE_DOCUMENT_OK:
    case ProjectConst.UPLOAD_AGREEMENT_OK:
    case ProjectConst.DELETE_AGREEMENT_OK:
      const { documentation } = action;
      return {
        ...state,
        loading: false,
        error: null,
        documentation,
      };
    case ProjectConst.GET_ONE_PROJECT_OK:
    case ProjectConst.CREATE_PROJECT_OK:
    case ProjectConst.EDIT_PROJECT_OK:
      const { projectData } = action;
      return {
        ...state,
        loading: false,
        error: null,
        loadingSchedules: false,
        projectData: projectData,
        schedulesModalView: false,
      };
    case ProjectConst.DELETE_PROJECT_OK:
      const { id } = action;
      return {
        ...state,
        loading: false,
        error: null,
        projectsList: state.projectsList.filter((project: ProjectListItem) => project.id !== id),
      };
    case ProjectConst.GET_PROJECTS_OK:
      return {
        ...state,
        loading: false,
        error: null,
        states: action.states,
        subareas: action.subareas,
        projectsList: action.projectsList,
      };
    case ProjectConst.UPLOAD_PAYMENT_FILE_OK:
      const flData = action.fileData;
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          file_id: flData.id,
          file_name: flData.original_name,
        },
      };
    case ProjectConst.REMOVE_PAYMENT_FILE_OK:
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          file_id: null,
          file_name: null,
        },
      };
    case ProjectConst.EDIT_PROJECT_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
        projectData: { ...state.projectData, ...action.projectData },
      };
    case ProjectConst.PROJECT_CHANGE_STATE_KO:
    case ProjectConst.PROJECT_CANCEL_AGREEMENT_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
        loadingSchedules: false,
        loadingFields: true,
      };
    case ProjectConst.GET_ONE_PROJECT_KO:
    case ProjectConst.GET_PROJECTS_KO:
    case ProjectConst.CREATE_PROJECT_KO:
    case ProjectConst.DELETE_PROJECT_KO:
    case ProjectConst.ADD_DOCUMENT_KO:
    case ProjectConst.DELETE_DOCUMENT_KO:
    case ProjectConst.GET_DOCUMENTATION_KO:
    case ProjectConst.GENERIC_FORM_DOWNLOAD_FILE_KO:
    case ProjectConst.DOWNLOAD_DOCUMENT_KO:
    case ProjectConst.GET_DOCUMENT_KO:
    case ProjectConst.GENERIC_FORM_GET_FILE_KO:
    case ProjectConst.UPLOAD_AGREEMENT_KO:
    case ProjectConst.DELETE_AGREEMENT_KO:
    case ProjectConst.DOWNLOAD_FOLLOW_UP_KO:
    case ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT_KO:
    case ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT_KO:
    case ProjectConst.UPLOAD_PAYMENT_FILE_KO:
    case ProjectConst.REMOVE_PAYMENT_FILE_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
        loadingSchedules: false,
      };
    case ProjectConst.PROJECT_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        validationErrors: action.validationErrors,
      };
    case ProjectConst.GET_AREAS_SUBAREAS:
      return {
        ...state,
      };
    case ProjectConst.GET_AREAS_SUBAREAS_OK:
      const { areasSubareasList } = action;
      return {
        ...state,
        areasSubareasList: areasSubareasList,
      };
    case ProjectConst.GET_AREAS_SUBAREAS_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ProjectConst.GET_FOLLOWUP:
      return {
        ...state,
        loadingFollowUp: true,
      };
    case ProjectConst.GET_FOLLOWUP_OK:
      return {
        ...state,
        loadingFollowUp: false,
        followUp: action.followUp,
      };
    case ProjectConst.GET_FOLLOWUP_KO:
      return {
        ...state,
        loadingFollowUp: false,
        error: action.error,
      };
    case ProjectConst.ADD_FOLLOWUP:
      return {
        ...state,
        loadingFollowUp: true,
      };
    case ProjectConst.ADD_FOLLOWUP_OK:
      state.followUpsList.push(action.followUp);
      state.followUpsList.sort((a, b) => b.created_at - a.created_at);
      return {
        ...state,
        loadingFollowUp: false,
        followUpsList: [...state.followUpsList],
        followUp: action.followUp,
        projectData: { ...state.projectData, trackings: state.projectData.trackings + 1 },
      };
    case ProjectConst.ADD_FOLLOWUP_KO:
      return {
        ...state,
        loadingFollowUp: false,
        error: action.error,
      };
    case ProjectConst.CHANGE_CREATE_MODAL_VISIBLE:
      const resetFollowup = {};
      return {
        ...state,
        createView: action.createView,
        followUp: resetFollowup as FollowUp,
      };
    case ProjectConst.CHANGE_EDITDELETE_MODAL_VISIBLE:
      return {
        ...state,
        editDeleteView: action.editDeleteView,
      };
    case ProjectConst.GET_LIST_FOLLOWUPS:
      return {
        ...state,
        loadingListFollowUp: true,
      };
    case ProjectConst.GET_LIST_FOLLOWUPS_OK:
      return {
        ...state,
        loadingListFollowUp: false,
        followUpsList: action.followUpsList,
      };
    case ProjectConst.GET_LIST_FOLLOWUPS_KO:
      return {
        ...state,
        loadingListFollowUp: false,
        error: action.error,
      };
    case ProjectConst.EDIT_FOLLOWUP:
      return {
        ...state,
        loadingFollowUp: true,
      };
    case ProjectConst.EDIT_FOLLOWUP_OK:
      const editList = state.followUpsList.map(ele => {
        if (ele.id === action.followUp.id) {
          ele = action.followUp;
        }
        return { ...ele };
      });
      return {
        ...state,
        followUpsList: editList,
        loadingFollowUp: false,
      };
    case ProjectConst.EDIT_FOLLOWUP_KO:
      return {
        ...state,
        error: action.error,
      };
    case ProjectConst.DELETE_FOLLOWUP:
      return {
        ...state,
        loadingDelete: true,
      };

    case ProjectConst.DELETE_FOLLOWUP_OK:
      state.followUpsList.splice(
        state.followUpsList.findIndex(el => el.id === action.id),
        1,
      );

      return {
        ...state,
        followUpsList: [...state.followUpsList],
        loadingDelete: false,
        projectData: { ...state.projectData, trackings: state.projectData.trackings - 1 },
      };
    case ProjectConst.DELETE_FOLLOWUP_KO:
      return {
        ...state,
        error: action.error,
      };
    case ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT:
      return {
        ...state,
        loading: true,
      };
    case ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT_OK:
      state.documentsArr.push({ id: action.data.id, name: action.data.original_name });
      return {
        ...state,
        loading: false,
        documentsArr: [...state.documentsArr],
      };
    case ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT_KO:
      return {
        ...state,
        loading: false,
      };
    case ProjectConst.RESET_DOCUMENTS:
      return {
        ...state,
        documentsArr: [],
      };
    case ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT:
      return {
        ...state,
      };
    case ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT_OK:
      getDocs(action.data, true, action.name);
      return {
        ...state,
        loading: false,
      };
    case ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT_KO:
      return {
        ...state,
        error: action.error,
      };
    case ProjectConst.DELETE_FOLLOWUP_DOCUMENT:
      return {
        ...state,
        loadingFollowUp: true,
      };
    case ProjectConst.DELETE_FOLLOWUP_DOCUMENT_OK:
      //folowuplist
      state.followUp.documents.splice(
        state.followUp.documents.findIndex(el => el.file_id === action.file_id),
        1,
      );
      return {
        ...state,
        loadingFollowUp: false,
      };
    case ProjectConst.DELETE_FOLLOWUP_DOCUMENT_KO:
      return {
        ...state,
        loading: false,
      };
    case ProjectConst.UPLOAD_SCHEDULE_DOCUMENT:
      return {
        ...state,
      };
    case ProjectConst.ADD_SCHEDULE:
      const { project_id, user_id } = action;
      return {
        ...state,
        newScheduleData: {
          project_id,
          file_id: null,
          name: '',
          type: 'EXT',
          amount: 0,
          user_id,
          new_tracking_end_date: null,
          tracking_payment_date: null,
        },
      };
    case ProjectConst.UPLOAD_SCHEDULE_DOCUMENT_OK:
      const { fileInfo } = action;
      return {
        ...state,
        paymentData: {
          ...state.paymentData,
          file_id: fileInfo.id,
          file_name: fileInfo.filename,
        },
        newScheduleData: {
          ...state.newScheduleData,
          file_id: fileInfo.id,
          name: fileInfo.filename,
        },
      };
    case ProjectConst.CREATE_NEW_SCHEDULE_KO:
    case ProjectConst.UPLOAD_SCHEDULE_DOCUMENT_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ProjectConst.SET_SCHEDULE_DATA:
      const { key: c_key, value: c_value } = action;
      return {
        ...state,
        newScheduleData: {
          ...state.newScheduleData,
          [c_key]: c_value,
        },
      };
    case ProjectConst.SET_PAYMENT_DATA:
      const paymentInfo = action.data;
      return {
        ...state,
        paymentData: {
          ...paymentInfo,
        },
      };
    case ProjectConst.CREATE_NEW_SCHEDULE:
      return {
        ...state,
        loading: true,
      };
    case ProjectConst.CREATE_NEW_SCHEDULE_OK:
      return {
        ...state,
        loading: false,
      };

    case ProjectConst.CONFIRM_CONFIGURATOR:
      return {
        ...state,
        loading: true,
      };
    case ProjectConst.CONFIRM_CONFIGURATOR_OK:
      return {
        ...state,
        projectData: { ...state.projectData, forms_done: 1 },
        loading: false,
      };
    case ProjectConst.CONFIRM_CONFIGURATOR_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ProjectConst.RESET_CONFIGURATOR_DATA:
      return {
        ...state,
        configuratorNotSelectedData: [],
        configuratorSelectedData: [],
        configuratorData: [],
      };
    case ProjectConst.SAVE_CUSTOM_FIELDS_FORM:
      return {
        ...state,
      };
    case ProjectConst.ADD_NEW_GENERIC_TABLE_ROW:
      const generateID = () => {
        return Math.floor(100000000 + Math.random() * 900000000);
      };

      const obj = { id: null, id_generated: generateID(), isEditMode: true };
      state.fieldsData.participante.columns.forEach(element => {
        //@ts-ignore
        obj[element.name] = '';
      });

      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          participante: {
            ...state.fieldsData.participante,
            rows: [...state.fieldsData.participante.rows, obj],
          },
        },
      };
    case ProjectConst.SET_SETTER_GENERIC_TABLE_ROW:
      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          participante: {
            ...state.fieldsData.participante,
            rows: action.finalStep ? [...action.setterData] : action.setterData,
          },
        },
      };
    case ProjectConst.ASSIGN_DOCS_TO_ROW:
      return {
        ...state,
        loadingFollowUp: true,
      };
    case ProjectConst.ASSIGN_DOCS_TO_ROW_OK:
      const newTableData = state.fieldsData.participante.rows.map(e => {
        if (e.id === action.tableDocsData.id_row && e.id !== null) {
          e[action.tableDocsData.fieldName] = action.tableDocs;
          return e;
        } else if (e.id_generated === action.tableDocsData.id_generated && !e.id) {
          e[action.tableDocsData.fieldName] = action.tableDocs;
          return e;
        } else {
          return e;
        }
      });

      return {
        ...state,
        loadingFollowUp: false,
        tableDocsData: action.tableDocs,
        fieldsData: {
          ...state.fieldsData,
          participante: {
            ...state.fieldsData.participante,
            rows: newTableData,
          },
        },
      };
    case ProjectConst.ASSIGN_DOCS_TO_ROW_KO:
      return {
        ...state,
        loadingFollowUp: false,
        error: action.error,
      };
    case ProjectConst.DELETE_DOCS_ROW:
      return {
        ...state,
        loadingDelete: true,
      };
    case ProjectConst.DELETE_DOCS_ROW_OK:
      const newTable = state.fieldsData.participante.rows.map(e => {
        if (e.id === action.tableDocsData.id_row) {
          e[action.tableDocsData.fieldName].splice(
            e[action.tableDocsData.fieldName].findIndex((f: any) => f.file_id === action.tableDocsData.file_id),
            1,
          );
          return e;
        } else if (e.id_generated === action.tableDocsData.id_generated) {
          e[action.tableDocsData.fieldName].splice(
            e[action.tableDocsData.fieldName].findIndex((f: any) => f.file_id === action.tableDocsData.file_id),
            1,
          );
        }
        return e;
      });
      return {
        ...state,
        loadingDelete: false,
        fieldsData: {
          ...state.fieldsData,
          participante: {
            ...state.fieldsData.participante,
            rows: newTable,
          },
        },
      };
    case ProjectConst.DELETE_DOCS_ROW_KO:
      return {
        ...state,
        loadingDelete: false,
        error: action.error,
      };
    case ProjectConst.DELETE_ROW_TABLE:
      return {
        ...state,
      };
    case ProjectConst.DELETE_ROW_TABLE_OK:
      let newTableAfterDelete;

      if (action.generatedId && !action.idStandard) {
        newTableAfterDelete = state.fieldsData.participante.rows.filter(e => e.id_generated !== action.generatedId);
      } else if (action.idStandard) {
        newTableAfterDelete = state.fieldsData.participante.rows.filter(e => e.id !== action.idStandard);
      } else {
        newTableAfterDelete = state.fieldsData.participante.rows;
      }

      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          participante: {
            ...state.fieldsData.participante,
            rows: newTableAfterDelete,
          },
        },
      };
    case ProjectConst.DELETE_ROW_TABLE_KO:
    case ProjectConst.GENERIC_FORM_UPLOAD_FIELD:
    case ProjectConst.CREATE_PROJECT_SCHEDULE:
      return {
        ...state,
      };
    case ProjectConst.GENERIC_FORM_UPLOAD_FIELD_OK:
      const newDataDoc = state.fieldsData.fields.documents.map((e: any) => {
        if (e.name === action.fieldName) {
          e.value_file = action.documentation;
          return e;
        }
        return e;
      });
      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          fields: {
            ...state.fieldsData.fields,
            documents: newDataDoc,
          },
        },
      };
    case ProjectConst.GENERIC_FORM_UPLOAD_FIELD_KO:
      return {
        ...state,
      };
    case ProjectConst.GENERIC_FORM_GET_FIELDS:
      return {
        ...state,
        loadingFields: true,
      };
    case ProjectConst.GENERIC_FORM_GET_FIELDS_OK:
      return {
        ...state,
        fieldsData: action.formFields,
        projectData: {
          ...state.projectData,
          steps: action.formFields.steps,
        },
        loadingFields: false,
      };
    case ProjectConst.GENERIC_FORM_GET_FIELDS_KO:
      return {
        ...state,
        loadingFields: false,
      };
    case ProjectConst.GENERIC_FORM_DELETE_FILE:
      return {
        ...state,
      };
    case ProjectConst.GENERIC_FORM_DELETE_FILE_OK:
      const fileId = action.dataF.fileId;
      const fieldName = action.dataF.fieldName;
      const newTableDelete = state.fieldsData.fields.documents.map((e: any) => {
        if (e.name === fieldName) {
          e.value_file.splice(
            e.value_file.findIndex((f: any) => f.file_id === fileId),
            1,
          );
          return e;
        }
        return e;
      });

      return {
        ...state,
        fieldsData: {
          ...state.fieldsData,
          fields: {
            ...state.fieldsData.fields,
            documents: newTableDelete,
          },
        },
      };
    case ProjectConst.GENERIC_FORM_DELETE_FILE_KO:
      return {
        ...state,
      };
    case ProjectConst.GET_DIRECTORY_DATA_FILTER:
      return {
        ...state,
      };
    case ProjectConst.GET_DIRECTORY_DATA_FILTER_OK:
      return {
        ...state,
        usersDataFiltered: action.filterResponse.usersData,
      };
    case ProjectConst.GET_DIRECTORY_DATA_FILTER_KO:
      return {
        ...state,
      };

    case ProjectConst.GET_ACTORS_METADATA:
      return {
        ...state,
        loading: true,
      };
    case ProjectConst.GET_ACTORS_METADATA_OK:
      return {
        ...state,
        actorsDefault: action.projectActors.owners,
        actorsFiltered: action.projectActors.candidates,
      };
    case ProjectConst.SEND_ACTOR:
    case ProjectConst.DELETE_ACTOR:
      return {
        ...state,
      };
    case ProjectConst.SEND_ACTOR_OK:
    case ProjectConst.DELETE_ACTOR_OK:
      return {
        ...state,
        actorsFiltered: action.allowedUsersAndCandidates.candidates,
        projectData: {
          ...state.projectData,
          allowed_users: action.allowedUsersAndCandidates.allowed_users,
        },
      };
    case ProjectConst.DELETE_PROJECT_SCHEDULE:
      return { ...state };
    case ProjectConst.PROJECT_PAYMENT:
    case ProjectConst.LINK_PAYMENT:
      return {
        ...state,
        loading: true,
        loadingSchedules: true,
      };
    case ProjectConst.GET_PROJECT_SCHEDULES:
    case ProjectConst.UPDATE_PROJECT_SCHEDULE:
    case ProjectConst.UPDATE_PROJECT_CALENDAR:
      return {
        ...state,
        loadingSchedules: true,
      };
    case ProjectConst.GET_PROJECT_SCHEDULES_OK:
      const { schedules } = action;
      return {
        ...state,
        loadingSchedules: false,
        projectData: {
          ...state.projectData,
          state_code: StateProject.TRACKING_FINISHED,
          scheduled_appointments: schedules,
        },
      };
    case ProjectConst.SET_PROJECT_DATA:
      const { key, value } = action;
      return {
        ...state,
        projectData: {
          ...state.projectData,
          [key]: value,
        },
      };
    case ProjectConst.SET_PROJECT_SCHEDULE_DATA:
      const { key: sch_key, value: sch_value } = action;
      const { scheduled_appointments } = state.projectData;

      switch (sch_key) {
        case 'kick_off':
          const kickOffIndex = scheduled_appointments.findIndex(
            (sch: ScheduledAppointmentItem) => sch.state === ScheduledAppointmentState.KICK_OFF,
          );
          if (kickOffIndex >= 0) {
            scheduled_appointments[kickOffIndex].tracking_date = sch_value as string;
          }
          return {
            ...state,
            projectData: {
              ...state.projectData,
              scheduled_appointments,
            },
          };
        case 'date_end_project':
          const finishIndex = scheduled_appointments.findIndex(
            (sch: ScheduledAppointmentItem) => sch.state === ScheduledAppointmentState.FINISH,
          );
          if (finishIndex >= 0) {
            scheduled_appointments[finishIndex].tracking_date = sch_value as string;
          }
          return {
            ...state,
            projectData: {
              ...state.projectData,
              scheduled_appointments,
            },
          };
        default:
          return {
            ...state,
            currentProjectScheduleItem: {
              ...state.currentProjectScheduleItem,
              [sch_key]: sch_value,
            },
          };
      }
    case ProjectConst.SELECT_PROJECT_SCHEDULE_ITEM:
      if (action.id > 0) {
        const { scheduled_appointments } = state.projectData;
        if (scheduled_appointments) {
          const psis = scheduled_appointments.filter((e: ScheduledAppointmentItem) => e.id === action.id);
          if (psis.length > 0) {
            return {
              ...state,
              currentProjectScheduleItem: psis[0],
            };
          }
        }
      }
      return { ...state };
    case ProjectConst.EDIT_PROJECT_SCHEDULE_ITEM:
      let projectScheduleItem = {
        id: 0,
        project_id: state.projectData.id,
        state: state.projectData.untracked_project
          ? ScheduledAppointmentState.PAYMENT
          : ScheduledAppointmentState.CHECK,
        tracking_date: null,
        partial_amount: 0,
        has_payment: 0,
        complete: 0,
        period: SchedulePeriods.NONE,
      } as ScheduledAppointmentItem;

      if (action.id > 0) {
        const { scheduled_appointments } = state.projectData;
        const psis = scheduled_appointments.filter((e: ScheduledAppointmentItem) => e.id === action.id);
        if (psis.length > 0) {
          projectScheduleItem = psis[0];
        }
      }

      return {
        ...state,
        schedulesModalView: true,
        currentProjectScheduleItem: projectScheduleItem,
      };
    case ProjectConst.EDIT_PROJECT_SCHEDULE_ITEM_OK:
      const { areasTemplates } = action;
      return {
        ...state,
        areasTemplates,
      };
    case ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT_OK:
      const { fileData } = action;
      return {
        ...state,
        currentProjectScheduleItem: {
          ...state.currentProjectScheduleItem,
          file_id: fileData.id,
          filename: fileData.original_name,
        },
      };
    case ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT_OK:
      return {
        ...state,
        currentProjectScheduleItem: {
          ...state.currentProjectScheduleItem,
          file_id: null,
          filename: null,
        },
      };
    case ProjectConst.GET_PROJECT_IMAGES_OK:
      return { ...state, projectData: { ...state.projectData, loadingImages: false, images: action.projectImages } };
    case ProjectConst.ADD_PROJECT_IMAGE_OK:
      newProjectImages = state.projectData.images.concat(action.projectImage);
      return { ...state, projectData: { ...state.projectData, images: newProjectImages } };
    case ProjectConst.EDIT_PROJECT_IMAGE_OK:
      newProjectImages = state.projectData.images.map((e: ResourceImageData) => {
        if (e.id === action.projectImage.id) return action.projectImage;
        return e;
      });
      return { ...state, projectData: { ...state.projectData, images: newProjectImages } };
    case ProjectConst.DELETE_PROJECT_IMAGE_OK:
      newProjectImages = state.projectData.images.filter((e: ResourceImageData) => e.id !== action.projectImageId);
      return { ...state, projectData: { ...state.projectData, images: newProjectImages } };
    case ProjectConst.OPEN_PROJECT_SCHEDULE:
      return {
        ...state,
        schedulesModalView: true,
      };
    case ProjectConst.CLOSE_PROJECT_SCHEDULE:
      return {
        ...state,
        schedulesModalView: false,
        loadingSchedules: false,
        validationErrors: null,
      };
    case ProjectConst.PROJECT_IMAGES_KO:
      return { ...state, error: action.error, projectData: { ...state.projectData, loadingImages: false } };
    case ProjectConst.PROJECT_GENERIC_KO:
      const { error } = action;
      return {
        ...state,
        error,
        loading: false,
      };
  }
  assertUnreachable(action);
  return state;
};

export default projectReducer;
