import { Button, ButtonProps, MenuItem } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import CheckboxCompo from '../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../components/Forms/FormContainer';
import Loading from '../../../components/Loading/Loading';
import SelectController from '../../../components/Select/SelectController';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import { tFormKey, tKey } from '../../../helpers/translate';
import { EntityInfo } from '../../../redux/entity/definitions';
import { AppState } from '../../../redux/root-reducer';
import { getScholarshipAcademiesMetadata } from '../../../redux/scholarship/actions';
import { ScholarshipAcademy, ScholarshipGetAllDispatch } from '../../../redux/scholarship/definitions';
import { ConfigScholarshipSchema, JointEntitySchema } from '../../../validations/formSchema';
import styles from '../entityData.module.scss';

interface ScholarshipConfigProps {
  leftButtons?: boolean;
  entity: EntityInfo;
  disabled?: boolean;
  scholarshipAcademies: ScholarshipAcademy[];
  loading?: boolean;
  buttonActionSave: ButtonProps;
  onSave: (data: EntityInfo) => void;
  goBack?: () => void;
  setData: (key: string, value: string | number) => void;
  getScholarshipAcademiesMetadata: (code: string | 'all') => void;
}

const ScholarshipConfig: React.FC<ScholarshipConfigProps> = ({
  leftButtons,
  entity,
  disabled,
  loading,
  scholarshipAcademies,
  getScholarshipAcademiesMetadata,
  buttonActionSave,
  onSave,
  goBack,
  setData,
}) => {
  useEffect(() => {
    getScholarshipAcademiesMetadata('all');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { register, handleSubmit, control, errors } = useForm<EntityInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ConfigScholarshipSchema,
  });

  useButtonAction(buttonActionSave, handleSubmit(onSave));

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <form className={styles.form}>
          <FormContainer title={tKey('Configuración becas')}>
            <div className={styles.form__container}>
              <CheckboxCompo
                name="allow_scholarship_year_renew"
                questionText={tFormKey('Permitir renovar becas el mismo curso')}
                defaultValue={entity.allow_scholarship_year_renew}
                register={register}
                disabled={disabled}
                onChange={(value: boolean) => setData('allow_scholarship_year_renew', value ? 1 : 0)}
              />
              <CheckboxCompo
                name="study_center"
                questionText={tFormKey('Centro de estudios')}
                defaultValue={+entity.study_center}
                register={register}
                disabled={disabled}
                onChange={(value: boolean) => setData('study_center', value ? 1 : 0)}
              />
              {!!entity.study_center && (
                <div className={styles.form__container__select}>
                  <SelectController
                    size="40"
                    control={control}
                    errors={errors}
                    schema={JointEntitySchema}
                    defaultValue={entity.scholarship_academy_id}
                    name={'scholarship_academy_id'}
                    label={tFormKey('Institutos asociados')}
                    onBlur={e => {
                      setData(e.target.name, e.target.value);
                    }}
                  >
                    {scholarshipAcademies.map(type => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </SelectController>
                </div>
              )}
            </div>
          </FormContainer>

          {!leftButtons && (
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" startIcon={<ArrowBack />} color="primary" fullWidth onClick={goBack}>
                  {tKey('VOLVER')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  color="primary"
                  fullWidth
                  onClick={handleSubmit(onSave)}
                >
                  {tKey('Guardar')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default connect(
  (state: AppState) => ({
    scholarshipAcademies: state.scholarshipReducer.scholarshipAcademies.filter(academy => academy.fnc_institute),
  }),
  (dispatch: ScholarshipGetAllDispatch) => ({
    getScholarshipAcademiesMetadata: (code: string | 'all'): void => dispatch(getScholarshipAcademiesMetadata(code)),
  }),
)(ScholarshipConfig);
