import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { tKey } from '../../../helpers/translate';
import CostCenterDefaults from './CostCenterDefaults';
import CostCenterTable from './CostCenterTable';

export type CostCenterCode = 'canal' | 'delegacion' | 'departamento' | 'proyecto' | 'ceco';

export interface CostCenter {
  id: number;
  code: string;
  name: string;
  delegation_code: string | null | undefined;
  channel_code: string | null | undefined;
  area_code: string | null | undefined;
}

export const sections: { code: CostCenterCode; name: string }[] = [
  { code: 'canal', name: tKey('Canal') },
  { code: 'delegacion', name: tKey('Delegación') },
  { code: 'departamento', name: tKey('Departamento') },
  { code: 'proyecto', name: tKey('Proyecto') },
  { code: 'ceco', name: tKey('centercost.becas') },
];

const SwitchCostCenter: React.FC<RouteComponentProps<{ section: CostCenterCode }>> = ({ match }) => {
  const {
    params: { section },
  } = match;

  switch (section) {
    case 'ceco':
      return <CostCenterDefaults section={section} />;
    default:
      return <CostCenterTable section={section} />;
  }
};

export default SwitchCostCenter;
