import { Button, MenuItem } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../components/Select/SelectController';
import { tKey } from '../../helpers/translate';
import { IdNamePair } from '../../redux/common/definitions';
import { RejectScholarshipData } from '../../redux/scholarship/definitions';
import { RejectScholarshipModalSchema } from '../../validations/formSchema';
import styles from './rejectScholarshipModal.module.scss';

interface RejectScholarshipModalProps {
  title: string;
  rejectData: RejectScholarshipData;
  rows?: number;
  path?: string;
  handleSend: (data: RejectScholarshipData) => void;
  handleClose: () => void;
}

const RejectScholarshipModal: React.FC<RejectScholarshipModalProps> = ({
  title,
  rejectData,
  rows,
  path,
  handleSend,
  handleClose,
}) => {
  const history = useHistory();

  const { reject_reason_id, reasons } = rejectData;

  const { errors, handleSubmit, watch, control } = useForm<RejectScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: RejectScholarshipModalSchema,
  });

  const sendDisabled = !watch('reject_reason_id');

  const onSubmit = async (data: RejectScholarshipData) => {
    handleSend({ ...rejectData, ...data });
    handleClose();
    path && history.push(path);
  };

  const renderComment = (
    <TextInputController
      name="renounce_comment"
      placeholder={`${tKey('Comentarios')} ...`}
      multiline
      rows={rows || '3'}
      rowsMax={rows || '5'}
      control={control}
      errors={errors}
      schema={RejectScholarshipModalSchema}
    />
  );

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <SelectController
        label={title}
        key="reject_reason_id"
        name="reject_reason_id"
        defaultValue={reject_reason_id || ''}
        schema={RejectScholarshipModalSchema}
        control={control}
        errors={errors}
      >
        {reasons?.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      {renderComment}
      <div className={styles.btn}>
        <ButtonGroup>
          <Button
            variant="contained"
            startIcon={<SendIcon />}
            disabled={sendDisabled}
            color="primary"
            type="submit"
            fullWidth
          >
            {tKey('Ok')}
          </Button>
        </ButtonGroup>
      </div>
    </form>
  );
};

export default RejectScholarshipModal;
