import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import SelectCheckboxController, {
  ListSelectCheckbox,
} from '../../../components/Select/SelectCheckboxController/SelectCheckboxController';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tKey } from '../../../helpers/translate';
import { ColumnOrderData, OrderDirection, ResponseData } from '../../../redux/common/definitions';
import { entityGetList, setColumnOrder, setSearchText } from '../../../redux/entity/actions';
import {
  EntityGetAllDispatch,
  EntityListItem,
  EntityTypes,
  ListEntitiesState,
} from '../../../redux/entity/definitions';
import {
  selectColumnOrder,
  selectEntityList,
  selectIsLoading,
  selectSearchText,
} from '../../../redux/entity/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuEntities from '../SubmenuList/SubmenuEntities';
import styles from './entities.module.scss';
import { EntityTabs } from './EntitiesTabs';
import WhatType from './WhatType';

interface EntitiesCreatedPageProps {
  loading: boolean;
  entityList: EntityListItem[];
  responseData: ResponseData;
  searchText: string;
  entityTypes: EntityTypes[];
  columnOrder: ColumnOrderData;
  canCreate: boolean;
  setSearchText: (searchText: string) => void;
  setColumnOrder: (columnOrder: ColumnOrderData) => void;
  getEntitiesCreated: () => void;
}

const AddNewEntityModal = React.lazy(() => import('../Modal/ModalCreateEntity'));

const EntitiesCreated: React.FC<EntitiesCreatedPageProps> = ({
  loading,
  entityList,
  searchText,
  entityTypes,
  columnOrder,
  canCreate,
  setSearchText,
  setColumnOrder,
  getEntitiesCreated,
}) => {
  const history = useHistory();

  const [create, setCreate] = React.useState({
    view: false,
  });

  useEffect(() => {
    getEntitiesCreated();
  }, [getEntitiesCreated]);

  const getCities = (entityData?: EntityListItem[]) => {
    const cities: Record<string, string> = {};
    entityData?.map(ele => (ele.city ? (cities[ele.city] = `${ele.city}`) : null));
    return cities;
  };

  const goToNewEntity = () => {
    setCreate({
      view: true,
    });
  };

  const details = (rowData: EntityListItem) => {
    history.push({
      pathname: `/entidad/nueva/${rowData.id}`,
      state: { id: rowData.id },
    });
  };

  const tableData: MaterialTableProps<EntityListItem> = {
    title: tKey('Entidades en creación'),
    columns: [
      {
        title: tKey('CIF'),
        field: 'cif',
        //@ts-ignore
        width: '150px',
      },
      {
        title: tKey('Nombre'),
        field: 'name',
      },
      {
        title: tKey('Ciudad'),
        field: 'city',
        lookup: getCities(entityList),
        //@ts-ignore
        width: '300px',
      },
      {
        title: tKey('Teléfono'),
        field: 'phone',
        //@ts-ignore
        width: '150px',
      },
      {
        title: tKey('Email Responsable'),
        field: 'email',
      },
      {
        title: tKey('Tipo Entidad'),
        field: 'types_entity', //forced
        render: (rowData: EntityListItem) => <WhatType rowData={rowData} />,
        customFilterAndSearch: (types, rowData) => types.every((type: string) => rowData.types_entity.includes(type)),
        filterComponent: ({ columnDef, onFilterChanged }) => (
          <SelectCheckboxController
            list={entityTypes as ListSelectCheckbox[]}
            valueSend="code"
            valueLabel="name"
            columnDef={columnDef}
            onFilterChanged={onFilterChanged}
          />
        ),

        //@ts-ignore
        width: '150px',
      },
      {
        title: 'joined',
        field: 'joined_49_2020',
        hidden: true,
      },
    ],
    data: entityList,
    isLoading: loading,
    options: {
      searchText,
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  return (
    <Layout leftSubmenu={<SubmenuEntities selected="entidades-nuevas" submenu={tKey('Entidades')} />}>
      <div className={styles.container}>
        {EntityTabs(3)}
        <TableWithEdit
          tableData={tableData}
          getTextSearch={(textSearch: string) => setSearchText(textSearch)}
          getOrderColumns={(orderedColumnId: number, orderDirection: OrderDirection) =>
            setColumnOrder({ orderedColumnId, orderDirection })
          }
          columnOrder={columnOrder}
          permission={canCreate}
          filtering
          optionsNew={{ newButtonText: tKey('Nueva Entidad'), onNew: goToNewEntity }}
          onRowClick={details}
        />

        <Suspense fallback={<></>}>
          {create.view ? (
            <AddNewEntityModal view={create.view} handleClose={() => setCreate({ view: false })} />
          ) : (
            <Fragment />
          )}
        </Suspense>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  entityList: selectEntityList(state),
  columnOrder: selectColumnOrder(state),
  searchText: selectSearchText(state),
  entityTypes: state.entityReducer.entityTypes,
  canCreate: selectUserCan(state)('route_entity_create'),
});

const mapDispatchToProps = (dispatch: EntityGetAllDispatch) => ({
  getEntitiesCreated: (): void => dispatch(entityGetList(ListEntitiesState.EN_CREACION)),
  setSearchText: (searchText: string): void => dispatch(setSearchText(searchText)),
  setColumnOrder: (data: ColumnOrderData): void => dispatch(setColumnOrder(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EntitiesCreated);
