import { Fab, Link, Tooltip } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/HelpOutlineRounded';
import React from 'react';
import { tKey } from '../../../helpers/translate';
import styles from './fabButtons.module.scss';

const FabButtonGenially: React.FC<{}> = () => {
  return (
    <Fab color="primary" className={`${styles.bottom} ${styles.right}`}>
      <Link
        className={styles.genially_link}
        href={`https://view.genial.ly/62710ac7ce64fd0011b3e6d9/guide-guia-interactiva-beques`}
        target="_blank"
        underline="hover"
      >
        <Tooltip disableFocusListener title={tKey('Preguntas frecuentes')}>
          <HelpIcon className={styles.genially} />
        </Tooltip>
      </Link>
    </Fab>
  );
};

export default FabButtonGenially;
