import { Action } from 'redux';
import { APIError } from '../../api/api';
import { NewMessage } from './actions';
import { NotificationsConsts } from './action_types';

// This file contains initialState + definitions for notifications

// INITIAL STATE
export const initialState = {
  loading: false,
  error: null as APIError | null,
  notifications: {
    alerts: [],
    pending_tasks: [],
    message_threads: [],
  } as Notification,
  version: 0 as number,
  unread_alerts: 0 as number,
  unread_message_threads: 0 as number,
  unread_pending_tasks: 0 as number,
  channels: [] as string[],
  polling: false as boolean,
};

export type NotificationsState = typeof initialState;

// TYPESCRIPT DEFINTIONS FOR STORING INFORMATION

export interface Alert {
  id: number;
  title: string;
  description: string;
  read: number;
  date: number;
  important: boolean;
}

export interface ThreadItem {
  from: number;
  date: number;
  content: string;
  read: boolean;
}

interface Member {
  id: number;
  name: string;
  avatar: string;
}
export interface MessageThread {
  id: number;
  channel: string;
  external_user: { id: number; name: string };
  members: Member[];
  thread: ThreadItem[];
  date: number;
  read: boolean;
}

/*
  This contants must match with backend/app/Enums/PendingTasks.php
*/

export type PendingTaskTypes =
  | 'User'
  | 'Project'
  | 'Entity'
  | 'Scholarship-Entity'
  | 'Scholarship-Institute'
  | 'Invoice'
  | 'Internal-Project'
  | 'Entity-IBAN';
export type PendingTaskActions =
  | 'Pay'
  | 'Validate'
  | 'Approve'
  | 'Check'
  | 'Confirm'
  | 'Check-Follow-up-alert'
  | 'Allow';

export interface PendingTaskItem {
  id: number;
  notifications_pending_task_id: number;
  description: string;
  foreing_id: number;
  user_id: number;
  link_id: number;
}

export interface PendingTask {
  type: PendingTaskTypes;
  action: PendingTaskActions;
  count: number;
  items: PendingTaskItem[];
}

export interface Notification {
  alerts: Alert[];
  message_threads: MessageThread[];
  pending_tasks: PendingTask[];
}

export interface NotificationsUpdatePayload {
  additions: {
    alerts: Alert[];
    message_threads: MessageThread[];
  };

  updates: {
    alerts: Alert[];
    message_threads: MessageThread[];
  };

  deletes: {
    alerts: number[];
    message_threads: number[];
    pending_tasks: number[];
  };
  pending_tasks: PendingTask[];
  version: number;
  channels: string[];
  unread_alerts: number;
  unread_message_threads: number;
  unread_pending_tasks: number;
}

// TYPESCRIPT DEFINITONS FOR HANDLING ACTIONS

export interface NotificationsUpdateAction extends Action {
  type: NotificationsConsts.NOTIFICATIONS_UPDATE;
}

export interface NotificationsUpdateOkAction extends Action {
  type: NotificationsConsts.NOTIFICATIONS_UPDATE_OK;
  updates: NotificationsUpdatePayload;
}

export interface NotificationsUpdateKoAction extends Action {
  type: NotificationsConsts.NOTIFICATIONS_UPDATE_KO;
  error: APIError;
}

export interface NotificationsStartPollingAction extends Action {
  type: NotificationsConsts.NOTIFICATIONS_START_POLLING;
  interval?: number;
}

export interface NotificationsStopPollingAction extends Action {
  type: NotificationsConsts.NOTIFICATIONS_STOP_POLLING;
}

export interface NotificationsRead {
  notifications: { id: number; read: boolean }[];
  type: 'alerts' | 'message_threads';
}

export interface MarkNotificationsAsReadAction extends Action {
  type: NotificationsConsts.READ_NOTIFICATION;
  notifications: NotificationsRead;
}

export interface MarkNotificationsAsReadOkAction extends Action {
  type: NotificationsConsts.READ_NOTIFICATION_OK;
  notifications: NotificationsRead;
}

export interface MarkNotificationsAsReadKoAction extends Action {
  type: NotificationsConsts.READ_NOTIFICATION_KO;
  error: APIError;
}

export interface NewMessageAction extends Action {
  type: NotificationsConsts.NEW_MESSAGE;
  new_message: NewMessage;
}

export interface NewMessageActionOkAction extends Action {
  type: NotificationsConsts.NEW_MESSAGE_OK;
  message_thread: MessageThread;
  isNewThread: boolean;
}

export interface NewMessageActionKoAction extends Action {
  type: NotificationsConsts.NEW_MESSAGE_KO;
  error: APIError;
}

export interface RemovePendingTaskAction extends Action {
  type: NotificationsConsts.REMOVE_PENDING_TASK | NotificationsConsts.REMOVE_PENDING_TASK_OK;
  notification_id: number;
}

export interface RemovePendingTaskKoAction extends Action {
  type: NotificationsConsts.REMOVE_PENDING_TASK_KO;
  error: APIError;
}

export type NotificationAction =
  | NotificationsUpdateAction
  | NotificationsUpdateOkAction
  | NotificationsUpdateKoAction
  | NotificationsStartPollingAction
  | NotificationsStopPollingAction
  | MarkNotificationsAsReadAction
  | MarkNotificationsAsReadOkAction
  | MarkNotificationsAsReadKoAction
  | NewMessageAction
  | NewMessageActionOkAction
  | NewMessageActionKoAction
  | RemovePendingTaskAction
  | RemovePendingTaskKoAction;

// TYPESCRIPT DEFINITIONS FOR DISPACHING EVENTS

export type NotificationDispatch = (action: NotificationAction) => void;
