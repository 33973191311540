import { ButtonProps, MenuItem } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import Title from '../../../../components/Title/Title';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { tScholarshipKey } from '../../../../helpers/translate';
import { validateFields } from '../../../../helpers/validator';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetSituationInfoData, scholarshipSubmitInformation } from '../../../../redux/scholarship/actions';
import {
  ScholarshipCurrentSituationCodes,
  ScholarshipData,
  ScholarshipMetadata,
  ScholarshipSteps,
} from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentationPerCode,
  selectGetScholarshipMetadata,
  selectScholarshipValidationErrors,
} from '../../../../redux/scholarship/selectors';
import { schoolCurrentSituation, schoolCurrentWorkingSituation } from '../../../../validations/scholarshipSchema';
import styles from './requestForms.module.scss';

interface CurrentSituationFormProps {
  scholarshipData: ScholarshipData;
  genericMetadata: GenericMetadata;
  scholarshipMetadata: ScholarshipMetadata;
  documentation: DocumentationItem[];
  readOnly: boolean;
  buttonActionSave: ButtonProps;
  validationErrors: APIValidationError | null;
  isNousCims?: boolean;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
  setData: (key: string, value: string | number) => void;
  saveData: (data: ScholarshipData) => void;
}

const CurrentSituationForm: React.FC<CurrentSituationFormProps> = ({
  scholarshipData,
  genericMetadata,
  scholarshipMetadata,
  documentation,
  readOnly,
  buttonActionSave,
  validationErrors,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
  setData,
  saveData,
}) => {
  const {
    current_situation,
    company_name,
    employee_role,
    current_incomes,
    contract_type,
    current_situation_id,
    scholarshipType,
  } = scholarshipData;
  const { currentSituation } = scholarshipMetadata;
  const { incomesTypes, contractTypes } = genericMetadata;

  const isWorking = current_situation === 'TRA' || current_situation === 'EYT';

  const actions = ['checked', 'upload', 'download', 'view', 'remove'];
  const { handleSubmit, control, errors, setValue, setError } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: isWorking ? schoolCurrentWorkingSituation : schoolCurrentSituation,
  });

  const { t } = useTranslation();

  const isReadOnly = !isWorking || readOnly;

  useValidatorAPI(validationErrors, setError);

  const saveCurrentSituation = () => {
    saveData(scholarshipData);
  };

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const handleCurrentSituationChange = (target: any) => {
    handleSelectChange(target);
    const cs_code = currentSituation.find(cs => cs.id === target.value)?.code;
    setData('current_situation', cs_code || '');
    if (cs_code === ScholarshipCurrentSituationCodes.NINI || cs_code === ScholarshipCurrentSituationCodes.ESTUDIA) {
      setValue('company_name', '');
      setValue('employee_role', '');
      setValue('current_incomes', '');
      setValue('contract_type', '');
    }
  };

  useButtonAction(buttonActionSave, handleSubmit(saveCurrentSituation));

  const validatorInfo = {
    data: scholarshipData,
    step: 'currentSituation' as keyof ScholarshipSteps,
  };

  let documentsInfo;

  if (documentation.length > 0 && isWorking) {
    documentsInfo = (
      <FncAddDocuments
        title={tScholarshipKey('Documentos')}
        documents={documentation}
        getFile={getFile}
        actions={actions}
        disabled={readOnly}
        upload={uploadDocument}
        genericId={scholarshipData.id}
        download={downloadDocument}
        remove={deleteDocument}
        multidocument
        addDoc={addDoc}
        required
      />
    );
  }

  return (
    <form onSubmit={e => e.preventDefault()} className={styles.form}>
      <Title>{tScholarshipKey('SOLICITUD DE BECA - SITUACIÓN ACTUAL')}</Title>

      <FormContainer title="">
        <SelectController
          control={control}
          name="current_situation_id"
          label={tScholarshipKey('Situación actual')}
          defaultValue={current_situation_id}
          size="50"
          schema={schoolCurrentSituation}
          validator={validateFields(scholarshipData, 'current_situation_id', validatorInfo.step)}
          errors={errors}
          disabled={readOnly}
          onClick={e => handleCurrentSituationChange(e.target)}
        >
          {currentSituation.map(element => (
            <MenuItem key={element.code} value={element.id}>
              {tScholarshipKey(
                t(`scholarship.${scholarshipType}.current_situation_form.current_situation.${element.code}`),
              )}
            </MenuItem>
          ))}
        </SelectController>
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={company_name}
          schema={schoolCurrentWorkingSituation}
          validator={validateFields(scholarshipData, 'company_name', validatorInfo.step)}
          label={tScholarshipKey('Nombre de la empresa')}
          name="company_name"
          size="50"
          disabled={isReadOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('company_name', e.target.value)}
        />
        <TextInputController
          control={control}
          errors={errors}
          defaultValue={employee_role}
          schema={schoolCurrentWorkingSituation}
          validator={validateFields(scholarshipData, 'employee_role', validatorInfo.step)}
          label={tScholarshipKey('Posición dentro de la empresa')}
          name="employee_role"
          size="50"
          disabled={isReadOnly}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('employee_role', e.target.value)}
        />
        <SelectController
          control={control}
          name="current_incomes"
          label={tScholarshipKey('Nivel actual de ingresos')}
          defaultValue={current_incomes}
          size="50"
          schema={schoolCurrentWorkingSituation}
          validator={validateFields(scholarshipData, 'current_incomes', validatorInfo.step)}
          errors={errors}
          disabled={isReadOnly}
          onClick={e => handleSelectChange(e.target)}
        >
          {incomesTypes.map(element => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          name="contract_type"
          label={tScholarshipKey('Tipo de contrato')}
          defaultValue={contract_type}
          size="50"
          schema={schoolCurrentWorkingSituation}
          validator={validateFields(scholarshipData, 'contract_type', validatorInfo.step)}
          errors={errors}
          disabled={isReadOnly}
          onClick={e => handleSelectChange(e.target)}
        >
          {contractTypes.map(element => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))}
        </SelectController>
        {documentsInfo}
      </FormContainer>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericMetadata: selectGenericMetadata(state),
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  documentation: selectGetScholarshipDocumentationPerCode(state, ['NOM']),
  validationErrors: selectScholarshipValidationErrors(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetSituationInfoData(key, value)),
  saveData: (scholarshipData: ScholarshipData): void => dispatch(scholarshipSubmitInformation(scholarshipData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentSituationForm);
