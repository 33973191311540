import { ButtonProps } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EuroIcon from '@material-ui/icons/Euro';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { exportPaymentFromApi, sendToSageFromApi } from '../../../api/Payment/Payment';
import { getForecast } from '../../../api/accounting';
import CheckboxStyle from '../../../components/Checkbox/CheckboxStyle';
import Layout from '../../../components/Layout/Layout';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import ModalDatePicker from '../../../components/Modal/ModalDatePicker/ModalDatePicker';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { formatNumber } from '../../../helpers/formatNumber';
import { getDocs } from '../../../helpers/getDocs';
import { tErrorKey, tKey } from '../../../helpers/translate';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { SendToSAGEFields } from '../../../redux/accounting/definitions';
import AccountingSubmenu from '../AccountingSubmenu';
import styles from './sendToSAGE.module.scss';

interface SendToSAGE {
  showSnackbar: (message: string, severity: SeveritySnackbar, redirect?: string, autoHideDuration?: number) => void;
}
const SendToSAGE: React.FC<SendToSAGE> = ({ showSnackbar }) => {
  const [checkboxItems, setCheckboxItems] = useState<
    {
      name: string;
      label: string;
      defaultValue: boolean;
      altLabel: number | string | boolean | undefined;
    }[]
  >([
    {
      name: 'providers',
      label: 'Proveedores',
      defaultValue: true,
      altLabel: undefined,
    },
    {
      name: 'instituteScholarship',
      label: 'Becas instituto',
      defaultValue: true,
      altLabel: undefined,
    },
    {
      name: 'entityScholarship',
      label: 'Becas entidad',
      defaultValue: true,
      altLabel: undefined,
    },
    {
      name: 'projects',
      label: 'Proyectos',
      defaultValue: true,
      altLabel: undefined,
    },
    {
      name: 'invoices',
      label: 'Otros servicios',
      defaultValue: true,
      altLabel: undefined,
    },
  ]);

  const [showSendSageModal, setShowSendSageModal] = useState(false);
  const [dateModal, setDateModal] = useState<{ show: boolean; action: 'export' | 'send' }>({
    show: false,
    action: 'export',
  });

  const [sageFields, setSageFields] = useState<{
    data: SendToSAGEFields;
    dateToSend: string | null;
  }>({
    data: {} as SendToSAGEFields,
    dateToSend: null,
  });

  const { control, handleSubmit, watch } = useForm<SendToSAGEFields>({
    mode: 'onSubmit',
    submitFocusError: true,
  });

  const clearData = () => {
    setSageFields({ data: {} as SendToSAGEFields, dateToSend: null });
  };

  const cancelSend = () => {
    setShowSendSageModal(false);
    clearData();
  };

  const sendDataToSage = async () => {
    try {
      await sendToSageFromApi(sageFields.data, sageFields.dateToSend);
      showSnackbar(tKey('Se ha enviado correctamente'), 'success');
    } catch (e) {
      showSnackbar(tErrorKey('Ha ocurrido un error en el envío'), 'error');
    }
    cancelSend();
  };

  const exporDataToExcel = async (data: SendToSAGEFields, dateToSend: string | null) => {
    const csv = await exportPaymentFromApi(data, dateToSend);
    const fileName = `sage_export_${moment().format('DDMMYYYY_HHmm')}.xlsx`;
    getDocs(csv, true, fileName);
  };

  const onSend = (data: SendToSAGEFields) => {
    setSageFields({ data, dateToSend: null });
    if (data.invoices) {
      setDateModal({ show: true, action: 'send' });
    } else {
      setShowSendSageModal(true);
    }
  };

  const closeAndContinue = (dateToSend: string | null) => {
    setDateModal({ ...dateModal, show: false });
    if (dateModal.action === 'send') {
      setShowSendSageModal(true);
    } else if (dateModal.action === 'export') {
      exporDataToExcel(sageFields.data, dateToSend);
    }
  };

  const closeDateModal = () => {
    closeAndContinue(null);
  };

  const onSubmitChangeDate = (date: string) => {
    let dateToSend = null;
    if (date) {
      dateToSend = moment(date).format('YYYY-MM-DD');
    }
    setSageFields({ ...sageFields, dateToSend });
    closeAndContinue(dateToSend);
  };

  const onExport = async (data: SendToSAGEFields) => {
    setSageFields({ data, dateToSend: null });
    if (data.invoices) {
      setDateModal({ show: data.invoices, action: 'export' });
    } else {
      exporDataToExcel(data, null);
    }
  };

  const onForecast = async () => {
    getForecast().then(data =>
      setCheckboxItems(prevState =>
        prevState.map(prev => {
          if (prev.name !== 'providers') {
            prev.altLabel = data[prev.name as keyof SendToSAGEFields];
          }
          return prev;
        }),
      ),
    );
  };

  const disabledButtons =
    watch('entityScholarship') === false &&
    watch('instituteScholarship') === false &&
    watch('projects') === false &&
    watch('providers') === false &&
    watch('invoices') === false;

  const buttonsActionMenu: ButtonProps[] = [
    {
      children: tKey('send-to-sage'),
      onClick: handleSubmit(onSend),
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
      fullWidth: true,
      startIcon: <SendIcon />,
      disabled: disabledButtons,
    },
    {
      children: tKey('Previsiones'),
      onClick: handleSubmit(onForecast),
      variant: 'contained',
      color: 'primary',
      disableElevation: true,
      fullWidth: true,
      startIcon: <EuroIcon />,
      disabled: disabledButtons,
    },
    {
      children: tKey('Exportar como archivo de excel'),
      onClick: handleSubmit(onExport),
      variant: 'contained',
      color: 'secondary',
      disableElevation: true,
      fullWidth: true,
      startIcon: <CloudDownloadIcon />,
      disabled: disabledButtons,
    },
  ];

  return (
    <Layout
      leftSubmenu={<AccountingSubmenu selected="sage" />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonsActionMenu} />}
    >
      <form className={styles.form}>
        {checkboxItems.map(({ name, label, altLabel, defaultValue }) => (
          <div className={styles.form__checkbox} key={label}>
            <CheckboxStyle
              key={name}
              control={control}
              name={name}
              label={tKey(label)}
              altLabel={altLabel !== undefined && formatNumber({ value: altLabel }) + ' €'}
              defaultValue={defaultValue}
            />
          </div>
        ))}
      </form>
      <TransitionModal
        view={showSendSageModal}
        handleClose={cancelSend}
        handleYes={sendDataToSage}
        buttonOk={tKey('ok')}
        buttonKo={tKey('cancel')}
        title={tKey('send-to-sage')}
        bodyModal=""
      >
        <p style={{ padding: '0 10px' }}>{tKey('confirmation-msg-send-sage')}</p>
      </TransitionModal>
      <ModalDatePicker
        view={dateModal.show}
        handleClose={closeDateModal}
        label={tKey('Fecha de vencimiento')}
        title={tKey('Fecha de vencimiento de las facturas')}
        buttonLabelOk={tKey('Ok')}
        buttonLabelKo={tKey('Cancelar')}
        onApplying={onSubmitChangeDate}
      />
    </Layout>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, redirect?: string, autoHideDuration?: number): void =>
    dispatch(showSnackbar(message, severity, redirect, autoHideDuration)),
});

export default connect(null, mapDispatchToProps)(SendToSAGE);
