import React from 'react';
import styles from './avatarImage.module.scss';

interface AvatarImageProps {
  image: string;
  size: 'big' | 'medium' | 'small';
}

const AvatarImage: React.FC<AvatarImageProps> = ({ image, size }) => (
  <img alt="avatar" src={image} className={styles[`avatar_${size}`]} />
);

export default AvatarImage;
