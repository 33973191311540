/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import { deleteEntity } from '../../api/Entity/deleteEntity';
import {
  approveEntity,
  changeResponsibleApi,
  confirmEntity,
  createEntityContactFromApi,
  createEntityFnc,
  createIbanContactFromApi,
  createNewContractFromApi,
  deleteContractDocumentFromApi,
  deleteContractFromApi,
  deleteDocumentation,
  downloadContractDocument,
  downloadDocumentation,
  downloadIbanDocumentFromApi,
  editContractFromApi,
  entityFncSend,
  entityGetDocuments,
  entityUpdateContactFromApi,
  entityUpdateIbanFromApi,
  getAvatarEntity,
  getContractFromApi,
  getEntityContactsList,
  getEntityForUser,
  getEntityIbansListFromApi,
  getEntityList,
  getEntityOneContact,
  getEntityRoleList,
  getIbanOneContactFromApi,
  getOneEntity,
  makeProvider as makeProviderApi,
  rejectExternalEntity,
  rejectFromDirector,
  rejectInternalEntity,
  removeContactEntity,
  removeIbanEntityDocumentFromApi,
  removeIbanEntityFromApi,
  setAvatarEntity,
  updateEntityData,
  uploadContractDocumentFromApi,
  uploadDocumentation,
  uploadIbanEntityDocumentFromApi,
} from '../../api/Entity/entity';
import {
  changeState,
  createEntityUser,
  deletePreEntityDocumentation,
  downloadPreEntityDocumentation,
  getAvatarPreEntity,
  getPreCreatedEntity,
  getPreCreatedEntityByUser,
  getPreEntityDocuments,
  setPreEntityAvatar,
  updatePreEntity,
  uploadPreEntityDocumentation,
} from '../../api/Entity/preentity';
import assertUnreachable from '../../helpers/assertUnreachable';
import errorMessage from '../../helpers/errorMessage';
import { tKey } from '../../helpers/translate';
import { downloadIbanDocumentKo, downloadIbanDocumentOk } from '../academy/actions';
import { showSnackbar } from '../FeedbackAPI/actions';
import { getNotificationsUpdate } from '../notification/actions';
import { delay } from '../notification/sagas';
import {
  approveEntityDirectorKo,
  approveEntityDirectorOk,
  changeResponsibleKo,
  changeStateKo,
  changeStateOk,
  confirmEntityKo,
  confirmEntityOk,
  createEntityContactKo,
  createEntityContactOk,
  createEntityIbanKo,
  createEntityIbanOk,
  createEntityUserKo,
  createEntityUserOk,
  createNewContractKo,
  createNewContractOk,
  deleteContractKo,
  deleteContractOk,
  deleteOneKo,
  deleteOneOk,
  downloadContractDocumentKo,
  downloadContractDocumentOk,
  downloadDocumentKo,
  downloadDocumentOk,
  downloadPreEntityDocumentKo,
  downloadPreEntityDocumentOk,
  editContractKo,
  editContractOk,
  entityContactRolesKo,
  entityContactRolesOk,
  entityContactsListKo,
  entityContactsListOk,
  entityFncSendKo,
  entityFncSendOk,
  entityGetForUserKo,
  entityGetForUserOk,
  entityGetListKo,
  entityGetListOk,
  entityGetOneKo,
  entityGetOneOk,
  entityOneContactKo,
  entityOneContactOk,
  entityOneIbanKo,
  entityOneIbanOk,
  entityUpdateContactKo,
  entityUpdateContactOk,
  entityUpdateIbanKo,
  entityUpdateIbanOk,
  entityValidationErrors,
  getAvatarEntityKo,
  getAvatarEntityOk,
  getAvatarPreEntityKo,
  getAvatarPreEntityOk,
  getContractKo,
  getContractOk,
  getDocumentKo,
  getDocumentOk,
  getEntitiesPreCreatedUserKo,
  getEntitiesPreCreatedUserOk,
  getEntityDocumentsKo,
  getEntityDocumentsOk,
  getEntityPreCreatedIdActionKo,
  getEntityPreCreatedIdActionOk,
  getPreEntityDocumentKo,
  getPreEntityDocumentOk,
  getterAvatarEntity,
  getterAvatarPreEntity,
  listEntityIbansKo,
  listEntityIbansOk,
  makeProviderKo,
  makeProviderOk,
  preEntityUpdateKo,
  preEntityUpdateOk,
  rejectEntityDirectorKo,
  rejectEntityDirectorOk,
  rejectExternalEntityKo,
  rejectExternalEntityOk,
  rejectInternalEntityKo,
  rejectInternalEntityOk,
  removeContactEntityKo,
  removeContactEntityOk,
  removeContractDocumentKo,
  removeContractDocumentOk,
  removeDocumentKo,
  removeDocumentOk,
  removeIbanEntityDocumentKo,
  removeIbanEntityDocumentOk,
  removeIbanEntityko,
  removeIbanEntityOk,
  setAvatarEntityKo,
  setAvatarEntityOk,
  setAvatarPreEntityKo,
  setAvatarPreEntityOk,
  updateEntityKo,
  updateEntityOk,
  uploadContractDocumentKo,
  uploadContractDocumentOk,
  uploadDocumentKo,
  uploadDocumentOk,
  uploadIbanEntityDocumentKo,
  uploadIbanEntityDocumentOk,
  uploadPreEntityDocumentKo,
  uploadPreEntityDocumentOk,
} from './actions';
import { EntityDataConsts } from './action_types';
import {
  ApproveEntityDirectorAction,
  ChangeResponsibleAction,
  ConfirmEntityAction,
  CreateEntityContactAction,
  CreateEntityFncAction,
  CreateEntityIbantAction,
  CreateNewContractAction,
  DeleteContractAction,
  DownloadContractDocumentAction,
  DownloadDocumentEntityAction,
  DownloadDocumentPreEntityAction,
  DownloadIbanDocumentAction,
  EditContractAction,
  EntityChangeStateAction,
  EntityContactRolesAction,
  EntityContactsListAction,
  EntityDeleteOneAction,
  EntityFncSendAction,
  EntityGetDocumentsAction,
  EntityGetForUser,
  EntityGetListAction,
  EntityGetOneAction,
  EntityGetPreCreatedUserAction,
  EntityOneContactAction,
  EntityOneIbanAction,
  EntityUpdateAction,
  EntityUpdateContactAction,
  EntityUpdateIbanAction,
  EntityUserCreateAction,
  GetAvatarEntityAction,
  GetAvatarPreEntityAction,
  GetContractAction,
  GetDocumentEntityAction,
  GetDocumentPreEntityAction,
  GetEntityPreCreatedIdAction,
  ListEntityIbansAction,
  MakeProvider,
  PreEntityUpdateAction,
  RejectEntityDirectorAction,
  RejectExternalEntityAction,
  RejectInternalEntityAction,
  RemoveContactEntityAction,
  RemoveContractDocumentAction,
  RemoveDocumentEntityAction,
  RemoveDocumentPreEntityAction,
  RemoveIbanEntityAction,
  RemoveIbanEntityDocumentAction,
  SetAvatarEntityAction,
  SetAvatarPreEntityAction,
  UploadContractDocumentAction,
  UploadDocumentEntityAction,
  UploadDocumentPreEntityAction,
  UploadIbanEntityDocumentAction,
} from './definitions';
import { logoutAction } from '../auth/actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* sendFncEntityFromSaga(action: EntityFncSendAction): Generator<any, void, any> {
  try {
    const result = (yield entityFncSend(action.entityOneData)) as Await<ReturnType<typeof entityFncSend>>;
    switch (result.type) {
      case 'ok':
        const redirect = `/entidades/nuevas`;
        yield put(entityFncSendOk(result.value.data));
        yield put(showSnackbar(tKey('Los datos se han guardado correctamente'), 'success', redirect, 1500));
        return;

      case 'validation-error':
        yield put(entityValidationErrors(result.value));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(entityFncSendKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* createEntityFncFormSaga(action: CreateEntityFncAction): Generator<any, void, any> {
  try {
    const result = (yield createEntityFnc(action.entityOneData)) as Await<ReturnType<typeof createEntityFnc>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        const redirect = `/entidad/nueva/${data}`;
        yield put(showSnackbar(tKey('Los datos se han guardado correctamente'), 'success', redirect, 1500));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(entityValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* sendUserEntityFromSaga(action: EntityUserCreateAction): Generator<any, void, any> {
  try {
    const result = (yield createEntityUser(action.entityOneData)) as Await<ReturnType<typeof createEntityUser>>;
    switch (result.type) {
      case 'ok':
        yield put(createEntityUserOk(result.value.data));
        return;

      case 'validation-error':
        yield put(entityValidationErrors(result.value));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(createEntityUserKo(errorMessage(e)));
  }
}

function* PreEntityUpdateFormSaga(action: PreEntityUpdateAction): Generator<any, void, any> {
  try {
    const result = (yield updatePreEntity(action.entityOneData)) as Await<ReturnType<typeof updatePreEntity>>;
    switch (result.type) {
      case 'ok':
        const info = result.value;
        yield put(preEntityUpdateOk(info.data));
        yield put(showSnackbar(tKey('Los datos se han guardado correctamente'), 'success'));
        return;

      case 'validation-error':
        const error = result.value;
        yield put(entityValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(preEntityUpdateKo(e));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* getEntitiesListFromSaga(action: EntityGetListAction): Generator<any, void, any> {
  try {
    const { data } = yield getEntityList(action.state);
    yield put(entityGetListOk(data));
  } catch (e) {
    yield put(entityGetListKo(errorMessage(e)));
  }
}

function* updateEntityFromSaga(action: any): Generator<any, void, any> {
  try {
    const result = (yield updateEntityData(action.data)) as Await<ReturnType<typeof updateEntityData>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(updateEntityOk(data));
        yield put(showSnackbar(tKey('Los datos se han guardado correctamente'), 'success'));
        return;

      case 'validation-error':
        const error = result.value;
        yield put(entityValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(updateEntityKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* getOneFromSaga(action: EntityGetOneAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const { data } = yield getOneEntity(id);
    yield put(getterAvatarEntity(data.id));
    const documents = yield entityGetDocuments(id);

    // const { data: avatar } = yield getAvatarEntity(id);
    yield put(entityGetOneOk(data, documents));
  } catch (e) {
    yield put(entityGetOneKo(errorMessage(e)));
  }
}

function* deleteOneFromSaga(action: EntityDeleteOneAction): Generator<any, void, any> {
  try {
    const info = yield deleteEntity(action.id);
    yield put(deleteOneOk(info));
  } catch (e) {
    yield put(deleteOneKo(errorMessage(e)));
  }
}

function* getPreEntityResponsableFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getPreCreatedEntityByUser();
    yield put(getterAvatarPreEntity(data.id));
    const { data: documentation } = yield getPreEntityDocuments(data.id);
    yield put(getEntitiesPreCreatedUserOk(data, documentation));
  } catch (e) {
    yield put(getEntitiesPreCreatedUserKo(e));
  }
}

function* uploadPreEntityDocumentFromSaga(action: UploadDocumentPreEntityAction): Generator<any, void, any> {
  try {
    const { documentation_id, data, force_add } = action;
    const documents = yield uploadPreEntityDocumentation(documentation_id, data, force_add);
    yield put(uploadPreEntityDocumentOk(documents));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(uploadPreEntityDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
  }
}

function* removePreEntityDocumentFromSaga(action: RemoveDocumentPreEntityAction): Generator<any, void, any> {
  try {
    const { file_id, entityId } = action;

    const info = yield deletePreEntityDocumentation(file_id, entityId);
    yield put(removeDocumentOk(info));
    yield put(showSnackbar('El documento se ha eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(removeDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
  }
}

function* downloadPreEntityDocumentFromSaga(action: DownloadDocumentPreEntityAction): Generator<any, void, any> {
  try {
    const { file_id, name, entityId } = action;
    const info = yield downloadPreEntityDocumentation(file_id, entityId);
    yield put(downloadPreEntityDocumentOk(info, name));
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadPreEntityDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al descargar el documento', 'error', undefined, 1500));
  }
}

function* getPreEntityDocumentFromSaga(action: GetDocumentPreEntityAction): Generator<any, void, any> {
  try {
    const { file_id, entityId, name } = action;

    const info = yield downloadPreEntityDocumentation(file_id, entityId);
    yield put(getPreEntityDocumentOk(info, name));
  } catch (e) {
    yield put(getPreEntityDocumentKo(errorMessage(e)));
  }
}

function* getEntityPreCreatedIdFromSaga(action: GetEntityPreCreatedIdAction): Generator<any, void, any> {
  try {
    const { data } = yield getPreCreatedEntity(action.id);
    yield put(getterAvatarPreEntity(data.id));
    const { data: documentation } = yield getPreEntityDocuments(data.id);
    // const { data: avatar } = yield getAvatarPreEntity(data.id);
    yield put(getEntityPreCreatedIdActionOk(data, documentation));
  } catch (e) {
    yield put(getEntityPreCreatedIdActionKo(errorMessage(e)));
  }
}

function* getEntityForUserFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getEntityForUser();
    yield put(getterAvatarEntity(data.id));
    const documentation = yield entityGetDocuments(data.id);
    //const { data: avatar } = yield getAvatarEntity(data.id);
    yield put(entityGetForUserOk(data, documentation));
  } catch (e) {
    yield put(entityGetForUserKo(errorMessage(e)));
  }
}

function* uploadDocumentFromSaga(action: UploadDocumentEntityAction): Generator<any, void, any> {
  try {
    const { documentation_id, data, entityId, force_add } = action;
    const documents = yield uploadDocumentation(documentation_id, data, entityId, force_add);
    yield put(uploadDocumentOk(documents));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(uploadDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
  }
}

function* removeDocumentFromSaga(action: RemoveDocumentEntityAction): Generator<any, void, any> {
  try {
    const { file_id, entityId } = action;

    const info = yield deleteDocumentation(entityId, file_id);
    yield put(removeDocumentOk(info));
    yield put(showSnackbar('El documento se ha eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(removeDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al eliminar el documento', 'error', undefined, 1500));
  }
}

function* downloadDocumentFromSaga(action: DownloadDocumentEntityAction): Generator<any, void, any> {
  try {
    const { file_id, name, entityId } = action;
    const info = yield downloadDocumentation(entityId, file_id);
    yield put(downloadDocumentOk(info, name));
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(downloadDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al descargar el documento', 'error', undefined, 1500));
  }
}

function* getDocumentFromSaga(action: GetDocumentEntityAction): Generator<any, void, any> {
  try {
    const { file_id, entityId, name } = action;
    const info = yield downloadDocumentation(entityId, file_id);
    yield put(getDocumentOk(info, name));
  } catch (e) {
    yield put(getDocumentKo(errorMessage(e)));
  }
}

function* entityGetDocumentsFromSaga(action: EntityGetDocumentsAction): Generator<any, void, any> {
  try {
    const { entityId } = action;
    const documents = yield entityGetDocuments(entityId);
    yield put(getEntityDocumentsOk(documents));
  } catch (e) {
    yield put(getEntityDocumentsKo(errorMessage(e)));
  }
}

function* setAvatarPreEntityFromSaga(action: SetAvatarPreEntityAction): Generator<any, void, any> {
  try {
    yield setPreEntityAvatar(action.avatar);
    yield put(setAvatarPreEntityOk(action.avatar));
  } catch (e) {
    yield put(setAvatarPreEntityKo(errorMessage(e)));
  }
}

function* setAvatarEntityFromSaga(action: SetAvatarEntityAction): Generator<any, void, any> {
  try {
    yield setAvatarEntity(action.avatar);
    yield put(setAvatarEntityOk(action.avatar));
  } catch (e) {
    yield put(setAvatarEntityKo(errorMessage(e)));
  }
}

function* getAvatarPreEntityFromSaga(action: GetAvatarPreEntityAction): Generator<any, void, any> {
  try {
    const { data: avatar } = yield getAvatarPreEntity(action.id);

    yield put(getAvatarPreEntityOk(avatar));
  } catch (e) {
    yield put(getAvatarPreEntityKo(errorMessage(e)));
  }
}

function* getAvatarEntityFromSaga(action: GetAvatarEntityAction): Generator<any, void, any> {
  try {
    const { data: avatar } = yield getAvatarEntity(action.id);
    yield put(getAvatarEntityOk(avatar));
  } catch (e) {
    yield put(getAvatarEntityKo(errorMessage(e)));
  }
}

const eventChangeState = (state: string) => {
  switch (state) {
    case 'e04e01':
      return 'rechazada';
    case 'e04e05':
      return 'aceptada';
    case 'e04e06':
      return 'eliminada';
  }
};

function* changeStateFromSaga(action: EntityChangeStateAction): Generator<any, void, any> {
  try {
    const { id, states } = action;
    yield changeState(id, states, action?.comments);
    yield put(changeStateOk());
    yield put(getNotificationsUpdate());
    yield put(
      showSnackbar(`La entidad ha sido ${eventChangeState(states)}`, 'success', '/entidades/solicitadas', 3000),
    );
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(changeStateKo(errorMessage(e)));
  }
}

function* changeResponsibleFromSaga(action: ChangeResponsibleAction): Generator<any, void, any> {
  try {
    const { id } = action;
    yield changeResponsibleApi(id);
    yield put(showSnackbar('Responsable cambiado correctamente', 'success', undefined, 3000));
    yield call(delay, 3000);
    yield put(logoutAction());
  } catch (e) {
    yield put(showSnackbar(errorMessage(e), 'error'));
    yield put(changeResponsibleKo(errorMessage(e)));
  }
}

function* makeProviderFromSaga(action: MakeProvider): Generator<any, void, any> {
  try {
    const { data } = yield makeProviderApi(action.id);
    yield put(makeProviderOk(data));
    yield put(showSnackbar('Asignaciones realizadas correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(makeProviderKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* createNewContract(action: CreateNewContractAction): Generator<any, void, any> {
  try {
    const result = (yield createNewContractFromApi(action.newContract)) as Await<
      ReturnType<typeof createNewContractFromApi>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(createNewContractOk(data));
        yield put(showSnackbar('Contrato guardado correctamente', 'success', undefined, 3000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(entityValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(createNewContractKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* getContractFromSaga(action: GetContractAction): Generator<any, void, any> {
  try {
    const entityContract = yield getContractFromApi(action.contractId);
    yield put(getContractOk(entityContract));
  } catch (e) {
    yield put(getContractKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* editContractFromSaga(action: EditContractAction): Generator<any, void, any> {
  try {
    const result = (yield editContractFromApi(action.contractData)) as Await<ReturnType<typeof editContractFromApi>>;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(editContractOk(data));
        yield put(showSnackbar('Contrato editado correctamente', 'success', undefined, 3000));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(entityValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(editContractKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* deleteContractFromSaga(action: DeleteContractAction): Generator<any, void, any> {
  try {
    yield deleteContractFromApi(action.contractData.id);
    yield put(deleteContractOk(action.contractData));
    yield put(showSnackbar('Contrato eliminado correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(deleteContractKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* uploadContractDocumentFromSaga(action: UploadContractDocumentAction): Generator<any, void, any> {
  try {
    const { file } = action;
    const fileData = yield uploadContractDocumentFromApi(file);
    yield put(uploadContractDocumentOk(fileData));
    yield put(showSnackbar('Documento subido correctamente', 'success'));
  } catch (e) {
    yield put(uploadContractDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el archivo', 'error'));
  }
}

function* removeContractDocumentFromSaga(action: RemoveContractDocumentAction): Generator<any, void, any> {
  try {
    const { file_id } = action;
    yield deleteContractDocumentFromApi(file_id);
    yield put(removeContractDocumentOk());
  } catch (e) {
    yield put(removeContractDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el archivo', 'error'));
  }
}

function* downloadContractDocumentFromSaga(action: DownloadContractDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, filename } = action;
    const fileData = yield downloadContractDocument(file_id);
    yield put(downloadContractDocumentOk(fileData, filename));
  } catch (e) {
    yield put(downloadContractDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el archivo', 'error'));
  }
}

function* getEntityContactsListFromSaga(action: EntityContactsListAction): Generator<any, void, any> {
  try {
    const { entityId } = action;
    const data = yield getEntityContactsList(entityId);
    yield put(entityContactsListOk(data));
  } catch (e) {
    yield put(entityContactsListKo(errorMessage(e)));
  }
}

function* getEntityOneContactFromSaga(action: EntityOneContactAction): Generator<any, void, any> {
  try {
    const { entity_id, contact_id } = action;
    const data = yield getEntityOneContact(entity_id, contact_id);
    yield put(entityOneContactOk(data));
  } catch (e) {
    yield put(entityOneContactKo(errorMessage(e)));
  }
}

function* getEntityOneIbanFromSaga(action: EntityOneIbanAction): Generator<any, void, any> {
  try {
    const { entity_id, iban_id } = action;
    const data = yield getIbanOneContactFromApi(entity_id, iban_id);
    yield put(entityOneIbanOk(data));
  } catch (e) {
    yield put(entityOneIbanKo(errorMessage(e)));
  }
}

function* removeContactEntityFromSaga(action: RemoveContactEntityAction): Generator<any, void, any> {
  try {
    const { entity_id, contact_id } = action;
    yield removeContactEntity(entity_id, contact_id);
    yield put(removeContactEntityOk(contact_id));
    yield put(showSnackbar('Contacto suprimido', 'success'));
  } catch (e) {
    yield put(removeContactEntityKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al suprimir el contacto', 'error'));
  }
}
function* removeIbanEntityFromSaga(action: RemoveIbanEntityAction): Generator<any, void, any> {
  try {
    const { entity_id, iban_id } = action;
    yield removeIbanEntityFromApi(entity_id, iban_id);
    yield put(removeIbanEntityOk(iban_id));
    yield put(showSnackbar('Iban suprimido', 'success'));
  } catch (e) {
    yield put(removeIbanEntityko(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al suprimir el iban', 'error'));
  }
}

function* getEntityRolesListFromSaga(): Generator<any, void, any> {
  try {
    const data = yield getEntityRoleList();
    yield put(entityContactRolesOk(data));
  } catch (e) {
    yield put(entityContactRolesKo(errorMessage(e)));
  }
}

function* createEntityContactFromSaga(action: CreateEntityContactAction): Generator<any, void, any> {
  try {
    const { contactData, entity_id } = action;
    const data = yield createEntityContactFromApi(contactData, entity_id);
    yield put(createEntityContactOk(data));
    yield put(showSnackbar('Contacto añadido', 'success'));
  } catch (e) {
    yield put(createEntityContactKo(errorMessage(e)));
    yield put(showSnackbar('El nuevo contacto no se ha podido añadir', 'error'));
  }
}

function* updateEntityyContactFromSaga(action: EntityUpdateContactAction): Generator<any, void, any> {
  try {
    const { contactInfo, entity_id } = action;
    const data = yield entityUpdateContactFromApi(contactInfo, entity_id);
    yield put(entityUpdateContactOk(data));
    yield put(showSnackbar('Contacto actualizado', 'success'));
  } catch (e) {
    yield put(entityUpdateContactKo(errorMessage(e)));
    yield put(showSnackbar('El contacto no se ha podido actualizar', 'error'));
  }
}

function* getListEntityIbansFromSaga(action: ListEntityIbansAction): Generator<any, void, any> {
  try {
    const { entityId } = action;
    const data = yield getEntityIbansListFromApi(entityId);
    yield put(listEntityIbansOk(data));
  } catch (e) {
    yield put(listEntityIbansKo(errorMessage(e)));
  }
}

function* createEntityIbanFromSaga(action: CreateEntityIbantAction): Generator<any, void, any> {
  const default_error_message = 'El nuevo iban no se ha podido añadir';
  try {
    const { ibanData, entity_id } = action;

    const result = (yield createIbanContactFromApi(ibanData, entity_id)) as Await<
      ReturnType<typeof createIbanContactFromApi>
    >;
    switch (result.type) {
      case 'ok':
        const { data } = result.value;
        yield put(createEntityIbanOk(data));
        yield put(showSnackbar(tKey('IBAN añadido correctamente'), 'success', undefined, 1500));
        return;
      case 'validation-error':
        const error = result.value.fields[0].message || default_error_message;
        yield put(createEntityIbanKo(errorMessage(error)));
        yield put(showSnackbar(error as string, 'error'));
        return;
    }
  } catch (e) {
    yield put(createEntityIbanKo(errorMessage(e)));
    yield put(showSnackbar(default_error_message, 'error'));
  }
}

function* updateEntityIbanFromSaga(action: EntityUpdateIbanAction): Generator<any, void, any> {
  try {
    const { ibanInfo, entity_id } = action;
    const data = yield entityUpdateIbanFromApi(ibanInfo, entity_id);
    yield put(entityUpdateIbanOk(data));
    yield put(showSnackbar('IBAN actualizado', 'success'));
  } catch (e) {
    yield put(entityUpdateIbanKo(errorMessage(e)));
    yield put(showSnackbar('El IBAN no se ha podido actualizar', 'error'));
  }
}

function* downloadIbanDocumentFromSaga(action: DownloadIbanDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, filename } = action;
    const data = yield downloadIbanDocumentFromApi(file_id);
    yield put(downloadIbanDocumentOk(data, filename));
  } catch (e) {
    yield put(downloadIbanDocumentKo(errorMessage(e)));
  }
}

function* uploadIbanDocumentFromSaga(action: UploadIbanEntityDocumentAction): Generator<any, void, any> {
  try {
    const { file } = action;
    const fileData = yield uploadIbanEntityDocumentFromApi(file);
    yield put(uploadIbanEntityDocumentOk(fileData));
    yield put(showSnackbar('Documento IBAN subido correctamente', 'success'));
  } catch (e) {
    yield put(uploadIbanEntityDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al subir el documento', 'error'));
  }
}

function* removeIbanDocumentFromSaga(action: RemoveIbanEntityDocumentAction): Generator<any, void, any> {
  try {
    yield removeIbanEntityDocumentFromApi(action.file_id);
    yield put(removeIbanEntityDocumentOk());
    yield put(showSnackbar('Documento IBAN borrado correctamente', 'success'));
  } catch (e) {
    yield put(removeIbanEntityDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al borrar el archivo', 'error'));
  }
}

function* rejectInternalFromSaga(action: RejectInternalEntityAction): Generator<any, void, any> {
  try {
    const { entityData } = yield rejectInternalEntity({
      id: action.id,
      comment: action.comment,
    });
    yield put(rejectInternalEntityOk(entityData));
    yield put(showSnackbar('Solicitud de entidad rechazada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(rejectInternalEntityKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* rejectExternalFromSaga(action: RejectExternalEntityAction): Generator<any, void, any> {
  try {
    const { entityData } = yield rejectExternalEntity({
      id: action.id,
      comment: action.comment,
    });
    yield put(rejectExternalEntityOk(entityData));
    yield put(showSnackbar('Solicitud de entidad rechazada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(rejectExternalEntityKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* confirmEntityFromSaga(action: ConfirmEntityAction): Generator<any, void, any> {
  try {
    const { entityData } = yield confirmEntity({
      id: action.id,
    });
    yield put(confirmEntityOk(entityData));
    yield put(showSnackbar('Entidad confirmada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(confirmEntityKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* approveEntityFromSaga(action: ApproveEntityDirectorAction): Generator<any, void, any> {
  try {
    const { entityData } = yield approveEntity({
      id: action.id,
    });
    yield put(approveEntityDirectorOk(entityData));
    yield put(showSnackbar('Entidad aprobada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(approveEntityDirectorKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

function* rejectEntityDirectorFromSaga(action: RejectEntityDirectorAction): Generator<any, void, any> {
  try {
    const { entityData } = yield rejectFromDirector({
      id: action.id,
      comment: action.comment,
    });
    yield put(rejectEntityDirectorOk(entityData));
    yield put(showSnackbar('Entidad rechazada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(rejectEntityDirectorKo(errorMessage(e)));
    yield put(showSnackbar(errorMessage(e), 'error'));
  }
}

export default [
  takeLatest<EntityGetListAction>(EntityDataConsts.ENTITY_GET_LIST, getEntitiesListFromSaga),
  takeLatest<EntityContactsListAction>(EntityDataConsts.GET_ENTITY_CONTACTS, getEntityContactsListFromSaga),
  takeLatest<EntityOneContactAction>(EntityDataConsts.GET_ENTITY_ONE_CONTACT, getEntityOneContactFromSaga),
  takeLatest<RemoveContactEntityAction>(EntityDataConsts.REMOVE_ENTITY_CONTACT, removeContactEntityFromSaga),
  takeLatest<EntityContactRolesAction>(EntityDataConsts.GET_ENTITY_ROLES, getEntityRolesListFromSaga),
  takeLatest<CreateEntityContactAction>(EntityDataConsts.ADD_ENTITY_CONTACT, createEntityContactFromSaga),
  takeLatest<EntityUpdateContactAction>(EntityDataConsts.UPDATE_ENTITY_CONTACT, updateEntityyContactFromSaga),
  takeLatest<ListEntityIbansAction>(EntityDataConsts.GET_ENTITY_IBAN, getListEntityIbansFromSaga),
  takeLatest<EntityOneIbanAction>(EntityDataConsts.GET_ENTITY_ONE_IBAN, getEntityOneIbanFromSaga),
  takeLatest<RemoveIbanEntityAction>(EntityDataConsts.REMOVE_ENTITY_IBAN, removeIbanEntityFromSaga),
  takeLatest<CreateEntityIbantAction>(EntityDataConsts.ADD_ENTITY_IBAN, createEntityIbanFromSaga),
  takeLatest<EntityUpdateIbanAction>(EntityDataConsts.UPDATE_ENTITY_IBAN, updateEntityIbanFromSaga),
  takeLatest<DownloadIbanDocumentAction>(EntityDataConsts.DOWNLOAD_ENTITY_IBAN_DOCUMENT, downloadIbanDocumentFromSaga),
  takeLatest<UploadIbanEntityDocumentAction>(EntityDataConsts.UPLOAD_ENTITY_IBAN_DOCUMENT, uploadIbanDocumentFromSaga),
  takeLatest<RemoveIbanEntityDocumentAction>(EntityDataConsts.REMOVE_ENTITY_IBAN_DOCUMENT, removeIbanDocumentFromSaga),
  takeLatest<EntityFncSendAction>(EntityDataConsts.ENTITY_FNC_SEND, sendFncEntityFromSaga),
  takeLatest<EntityUserCreateAction>(EntityDataConsts.PRE_ENTITY_SEND, sendUserEntityFromSaga),
  takeLatest<EntityUpdateAction>(EntityDataConsts.ENTITY_UPDATE, updateEntityFromSaga),
  takeLatest<EntityGetOneAction>(EntityDataConsts.ENTITY_GET_ONE, getOneFromSaga),
  takeLatest<EntityDeleteOneAction>(EntityDataConsts.ENTITY_DELETE_ONE, deleteOneFromSaga),
  takeLatest<CreateEntityFncAction>(EntityDataConsts.CREATE_ENTITY_FNC, createEntityFncFormSaga),
  takeLatest<PreEntityUpdateAction>(EntityDataConsts.PRE_ENTITY_UPDATE, PreEntityUpdateFormSaga),
  takeLatest<EntityGetPreCreatedUserAction>(
    EntityDataConsts.ENTITY_GET_PRE_CREATED_USER,
    getPreEntityResponsableFromSaga,
  ),
  takeLatest<GetEntityPreCreatedIdAction>(
    EntityDataConsts.ENTITY_GET_PRE_CREATED_USER_ID,
    getEntityPreCreatedIdFromSaga,
  ),
  takeLatest<EntityGetForUser>(EntityDataConsts.ENTITY_GET_USER, getEntityForUserFromSaga),
  takeLatest<UploadDocumentEntityAction>(EntityDataConsts.UPLOAD_ENTITY_DOCUMENT, uploadDocumentFromSaga),
  takeLatest<RemoveDocumentEntityAction>(EntityDataConsts.REMOVE_ENTITY_DOCUMENT, removeDocumentFromSaga),
  takeLatest<DownloadDocumentEntityAction>(EntityDataConsts.DOWNLOAD_ENTITY_DOCUMENT, downloadDocumentFromSaga),
  takeLatest<GetDocumentEntityAction>(EntityDataConsts.GET_ENTITY_DOCUMENT, getDocumentFromSaga),
  takeLatest<EntityGetDocumentsAction>(EntityDataConsts.ENTITY_GET_DOCUMENTS, entityGetDocumentsFromSaga),
  takeLatest<UploadDocumentPreEntityAction>(
    EntityDataConsts.PREENTITY_UPLOAD_DOCUMENT,
    uploadPreEntityDocumentFromSaga,
  ),
  takeLatest<RemoveDocumentPreEntityAction>(
    EntityDataConsts.PREENTITY_REMOVE_DOCUMENT,
    removePreEntityDocumentFromSaga,
  ),
  takeLatest<DownloadDocumentPreEntityAction>(
    EntityDataConsts.PREENTITY_DOWNLOAD_DOCUMENT,
    downloadPreEntityDocumentFromSaga,
  ),
  takeLatest<GetDocumentPreEntityAction>(EntityDataConsts.PREENTITY_GET_DOCUMENT, getPreEntityDocumentFromSaga),
  takeLatest<SetAvatarPreEntityAction>(EntityDataConsts.PREENTITY_SET_AVATAR, setAvatarPreEntityFromSaga),
  takeLatest<SetAvatarEntityAction>(EntityDataConsts.ENTITY_SET_AVATAR, setAvatarEntityFromSaga),
  takeLatest<EntityChangeStateAction>(EntityDataConsts.CHANGE_STATE_ENTITY, changeStateFromSaga),
  takeLatest<GetAvatarPreEntityAction>(EntityDataConsts.PREENTITY_GET_AVATAR, getAvatarPreEntityFromSaga),
  takeLatest<GetAvatarEntityAction>(EntityDataConsts.ENTITY_GET_AVATAR, getAvatarEntityFromSaga),
  takeLatest<ChangeResponsibleAction>(EntityDataConsts.ENTITY_CHANGE_RESPONSIBLE, changeResponsibleFromSaga),
  takeLatest<MakeProvider>(EntityDataConsts.MAKE_PROVIDER, makeProviderFromSaga),
  takeLatest<CreateNewContractAction>(EntityDataConsts.CREATE_NEW_CONTRACT, createNewContract),
  takeLatest<GetContractAction>(EntityDataConsts.GET_CONTRACT, getContractFromSaga),
  takeLatest<EditContractAction>(EntityDataConsts.EDIT_CONTRACT, editContractFromSaga),
  takeLatest<DeleteContractAction>(EntityDataConsts.DELETE_CONTRACT, deleteContractFromSaga),
  takeLatest<UploadContractDocumentAction>(EntityDataConsts.UPLOAD_CONTRACT_DOCUMENT, uploadContractDocumentFromSaga),
  takeLatest<RemoveContractDocumentAction>(EntityDataConsts.REMOVE_CONTRACT_DOCUMENT, removeContractDocumentFromSaga),
  takeLatest<DownloadContractDocumentAction>(
    EntityDataConsts.DOWNLOAD_CONTRACT_DOCUMENT,
    downloadContractDocumentFromSaga,
  ),
  takeLatest<RejectInternalEntityAction>(EntityDataConsts.REJECT_INTERNAL_ENTITY, rejectInternalFromSaga),
  takeLatest<RejectExternalEntityAction>(EntityDataConsts.REJECT_EXTERNAL_ENTITY, rejectExternalFromSaga),
  takeLatest<ConfirmEntityAction>(EntityDataConsts.CONFIRM_ENTITY, confirmEntityFromSaga),
  takeLatest<RejectEntityDirectorAction>(EntityDataConsts.REJECT_ENTITY_DIRECTOR, rejectEntityDirectorFromSaga),
  takeLatest<ApproveEntityDirectorAction>(EntityDataConsts.APPROVE_ENTITY_DIRECTOR, approveEntityFromSaga),
];
