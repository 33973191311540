import { CheckCircle, WarningSharp } from '@material-ui/icons';
import React from 'react';
import { formatNumber } from '../../helpers/formatNumber';
import styles from './infoLimitChip.module.scss';

interface InfoLimitChipProps {
  text: string;
  input: number;
  limit: number;
  deviation: number;
  currency: boolean;
}

const InfoLimitChip: React.FC<InfoLimitChipProps> = ({ text, input, limit, deviation, currency }) => {
  return (
    <div className={`${styles.root} ${input > limit ? styles.isSuperior : ''}`}>
      {input < limit + deviation ? (
        <CheckCircle className={`${styles.infoChipIcon} ${styles.isCheck}`} />
      ) : (
        <WarningSharp className={`${styles.infoChipIcon} ${styles.isWarning}`} />
      )}
      <p className={styles.infoChipText}>
        {text + ' '}
        <strong>
          {formatNumber({ value: limit })}
          {!currency || ` €`}
        </strong>
      </p>
    </div>
  );
};

export default InfoLimitChip;
