import IconButton from '@material-ui/core/IconButton';
import { FileCopy } from '@material-ui/icons';
import React from 'react';
import { tKey } from '../../helpers/translate';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';

interface ClipBoardProps {
  disabled?: boolean;
  value: string | undefined;
  onClipBoard?: (value: any) => string;
  showSnackbar: (message: string, severity: SeveritySnackbar, redirect?: string, autoHideDuration?: number) => void;
}

const ClipBoard: React.FC<ClipBoardProps> = ({ disabled, value, onClipBoard, showSnackbar }) => {
  const handleClick = () => {
    if (onClipBoard) {
      value = onClipBoard(value);
    }
    navigator.clipboard
      .writeText(value || '')
      .then(() => showSnackbar(`${value} ${tKey('copiado al portapapeles')}`, 'info', undefined, 1500))
      .catch(() => showSnackbar(tKey('Ha ocurrido un error'), 'warning'));
  };

  return (
    <IconButton disabled={disabled} onClick={handleClick}>
      <FileCopy />
    </IconButton>
  );
};

export default ClipBoard;
