import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import TableWithEdit from '../../../../../../components/Tables/TableWithEdit';
import { tFormKey, tKey } from '../../../../../../helpers/translate';
import { IdNamePair } from '../../../../../../redux/common/definitions';
import {
  removeInternalProjectTemplateRoleData,
  setInternalProjectTemplateRoleData,
} from '../../../../../../redux/internal-project-templates/actions';
import {
  InternalProjectTemplateData,
  InternalProjectTemplateDispatch,
} from '../../../../../../redux/internal-project-templates/definitions';
import { selectIPTState } from '../../../../../../redux/internal-project-templates/selectors';
import { AppState } from '../../../../../../redux/root-reducer';
import styles from './internalProjectTemplateRoles.module.scss';

interface InternalProjectTemplateRolesProps {
  roles: IdNamePair[];
  internalProjectTemplateData: InternalProjectTemplateData;
  setRoleData: (role: IdNamePair) => void;
  removeRoleData: (roleId: number) => void;
}

const InternalProjectTemplateRoles: React.FC<InternalProjectTemplateRolesProps> = ({
  roles,
  internalProjectTemplateData,
  setRoleData,
  removeRoleData,
}) => {
  const [roleSelected, setRoleSelected] = useState<number | null>(null);
  const tableData: MaterialTableProps<IdNamePair> = {
    title: tKey('Roles asignados'),
    columns: [
      {
        title: tFormKey('Rol'),
        field: 'name',
      },
    ],
    options: {
      selection: false,
      pageSize: 3,
      tableLayout: 'auto',
      showSelectAllCheckbox: true,
      paging: false,
      minBodyHeight: 260,
      maxBodyHeight: 260,
    },
    actions: [
      (rowData: IdNamePair) => ({
        icon: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
        onClick: () => removeRoleData(rowData.id),
        tooltip: tKey('Borrar'),
      }),
    ],
    data: internalProjectTemplateData.roles,
  };

  const handleAddRole = () => {
    const selectedRole = roles.find((e: IdNamePair) => e.id === roleSelected);
    if (selectedRole) {
      const roleExists = internalProjectTemplateData.roles.find((e: IdNamePair) => e.id === selectedRole.id);
      if (!roleExists) setRoleData(selectedRole);
    }
  };

  return (
    <>
      <h1 className={styles.title}>{tKey('Roles')}</h1>
      <div className={styles.select_role}>
        <FormControl
          variant="outlined"
          classes={{
            root: styles.select_wrapper,
          }}
          size="medium"
        >
          <InputLabel id="rol">{tKey('Rol')}</InputLabel>
          <Select
            id="roles"
            name="roles"
            value={roleSelected || ''}
            label={tFormKey('Rol')}
            onChange={event => {
              if (typeof event.target.value !== 'number') return;
              setRoleSelected(event.target.value);
            }}
          >
            {roles.map((role: IdNamePair) => (
              <MenuItem key={role.id} value={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button color="primary" variant="outlined" onClick={handleAddRole}>
          {tKey('Añadir')}
        </Button>
      </div>

      <TableWithEdit key="roles" tableData={tableData}></TableWithEdit>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  internalProjectTemplateData: selectIPTState(state).internalProjectTemplateData,
});

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  setRoleData: (role: IdNamePair): void => dispatch(setInternalProjectTemplateRoleData(role)),
  removeRoleData: (roleId: number): void => dispatch(removeInternalProjectTemplateRoleData(roleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectTemplateRoles);
