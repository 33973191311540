import React from 'react';
import { connect } from 'react-redux';
import TransitionModal from '../../../../components/Modal/TransitionModal';
import { tKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import { replaceScholarshipPaymentDates } from '../../../../redux/scholarship/actions';
import { PaymentDatesReplace } from '../../../../redux/scholarship/definitions';
import { selectPaymentAcademicYear } from '../../../../redux/scholarship/selectors';
import { ScholarshipPaymentReplaceDates } from '../hooks/useScholarshipPaymentReplaceDates';

interface ReplaceDatesModalProps {
  show: boolean;
  isEntity?: boolean;
  selectedAcademicYear: string;
  replaceDates: ScholarshipPaymentReplaceDates;
  replaceScholarshipPaymentDates: (paymentDates: PaymentDatesReplace) => void;
  closeReplaceModal: () => void;
}

const ReplaceDatesModal: React.FC<ReplaceDatesModalProps> = ({
  show,
  isEntity,
  replaceDates,
  selectedAcademicYear,
  replaceScholarshipPaymentDates,
  closeReplaceModal,
}) => {
  const findAndReplace = async (replace: boolean) => {
    const replaceData = {
      academic_year: selectedAcademicYear,
      replace_paid: replace,
    } as PaymentDatesReplace;
    if (isEntity) {
      replaceData['entity'] = {
        dateSearch: replaceDates.find?.format('YYYY-MM-DD'),
        dateReplace: replaceDates.replace?.format('YYYY-MM-DD'),
      };
    } else {
      replaceData['institute'] = {
        dateSearch: replaceDates.find?.format('YYYY-MM-DD'),
        dateReplace: replaceDates.replace?.format('YYYY-MM-DD'),
      };
    }
    replaceScholarshipPaymentDates(replaceData);
  };

  return (
    <TransitionModal
      documentMode
      view={show}
      handleClose={() => {
        closeReplaceModal();
      }}
      title={tKey('Actualización de fechas de pago')}
      bodyModal={<div style={{ padding: '20px' }}>{tKey('modify_payment_dates')}</div>}
      buttonOk={tKey('Sí')}
      buttonKo={tKey('No')}
      handleYes={() => {
        findAndReplace(true);
        closeReplaceModal();
      }}
      handleNo={() => {
        findAndReplace(false);
        closeReplaceModal();
      }}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  selectedAcademicYear: selectPaymentAcademicYear(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  replaceScholarshipPaymentDates: (paymentDates: PaymentDatesReplace): void =>
    dispatch(replaceScholarshipPaymentDates(paymentDates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReplaceDatesModal);
