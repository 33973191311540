import { UserItemList, UserMailing } from '../../redux/user/definitions';

export const mapUserMailingToUserItemList = function(userMailing: UserMailing): UserItemList {
  return {
    id: userMailing.id,
    email: userMailing.email,
    name: userMailing.name,
    surname: userMailing.surname,
    surname2: '',
    phone: '',
    state: '',
    document_number: '',
    document_type: undefined,
    role: userMailing.role,
    role_code: '',
    subscribed: userMailing.subscribed,
  };
};
