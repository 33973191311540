import * as yup from 'yup';

const requiredText = 'Fecha requerida';

export const FollowUpScholarship = yup.object().shape({
  t1_tracking_date: yup.mixed().required(requiredText),
  t2_tracking_date: yup.mixed().required(requiredText),
  tfinal_tracking_date: yup.mixed().required(requiredText),
  s1_tracking_date: yup.mixed().required(requiredText),
  sfinal_tracking_date: yup.mixed().required(requiredText),
});
