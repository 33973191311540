import { APIError, APIValidationError } from '../../api/api';
import { Tracking } from '../area/definitions';
import { DocumentationItem, FileItem, ResourceImageData } from '../common/definitions';
import { CodeSectionProject } from '../kpis/definitions';
import { ProjectConst } from './action_type';
import {
  AddDocumentAction,
  AddDocumentKoAction,
  AddDocumentOkAction,
  AddFollowUpAction,
  AddFollowUpKoAction,
  AddFollowUpOkAction,
  AddNewGenericTableRowAction,
  AddNewScheduleAction,
  AddProjectImageAction,
  AddProjectImageOkAction,
  AreaSubArea,
  AssignDocsToRowAction,
  AssignDocsToRowKoAction,
  AssignDocsToRowOkAction,
  ChangeCreateModalVisible,
  ChangeEditDeleteModalVisible,
  CloseProjectScheduleAction,
  ConfiguratorData,
  ConfirmConfiguratorAction,
  ConfirmConfiguratorKoAction,
  ConfirmConfiguratorOkAction,
  CreateNewScheduleProjectAction,
  CreateNewScheduleProjectKoAction,
  CreateNewScheduleProjectOkAction,
  CreateProjectAction,
  CreateProjectKoAction,
  CreateProjectOkAction,
  CreateProjectScheduleAction,
  CustomFieldsForm,
  DeleteActorAction,
  DeleteActorOkAction,
  DeleteAgreementAction,
  DeleteAgreementKoAction,
  DeleteAgreementOkAction,
  DeleteFileAction,
  DeleteFileKoAction,
  DeleteFileOkAction,
  DeleteFollowUpAction,
  DeleteFollowUpKoAction,
  DeleteFollowUpOkAction,
  DeleteGenericFileAction,
  DeleteGenericFileKoAction,
  DeleteGenericFileOkAction,
  DeleteProjectAction,
  DeleteProjectImageAction,
  DeleteProjectImageOkAction,
  DeleteProjectKoAction,
  DeleteProjectOkAction,
  DeleteProjectScheduleAction,
  DeleteRowTableAction,
  DeleteRowTableKoAction,
  DeleteRowTableOkAction,
  DownloadDocumentFollowupAction,
  DownloadDocumentFollowupKoAction,
  DownloadDocumentFollowupOkAction,
  DownloadFileAction,
  DownloadFileKoAction,
  DownloadFileOkAction,
  DownloadFollowUpDocumentAction,
  DownloadFollowUpDocumentKoAction,
  DownloadFollowUpDocumentOkAction,
  DownloadGenericFileAction,
  DownloadGenericFileKoAction,
  DownloadGenericFileOkAction,
  EditFollowUpAction,
  EditFollowUpKoAction,
  EditFollowUpOkAction,
  EditProjectAction,
  EditProjectImageAction,
  EditProjectImageOkAction,
  EditProjectKoAction,
  EditProjectOkAction,
  EditProjectScheduleItemAction,
  EditProjectScheduleItemOkAction,
  FollowUp,
  GenericData,
  GenericFieldUploadAction,
  GenericFieldUploadKoAction,
  GenericFieldUploadOkAction,
  GetActorsMetadataAction,
  GetActorsMetadataOkAction,
  GetAreasSubAreasAction,
  GetAreasSubAreasKoAction,
  GetAreasSubAreasOkAction,
  GetDirectoryDataFilterAction,
  GetDirectoryDataFilterKoAction,
  GetDirectoryDataFilterOkAction,
  GetDocumentationAction,
  GetDocumentationKoAction,
  GetDocumentationOkAction,
  GetFileAction,
  GetFileKoAction,
  GetFileOkAction,
  GetFollowUpAction,
  GetFollowUpKoAction,
  GetFollowUpOkAction,
  GetGenericFieldsAction,
  GetGenericFieldsKoAction,
  GetGenericFieldsOkAction,
  GetGenericFileAction,
  GetGenericFileKoAction,
  GetGenericFileOkAction,
  GetListFollowUpsAction,
  GetListFollowUpsKoAction,
  GetListFollowUpsOkAction,
  GetOneProjectAction,
  GetOneProjectKoAction,
  GetOneProjectOkAction,
  GetProjectImagesAction,
  GetProjectImagesOkAction,
  GetProjectsAction,
  GetProjectSchedulesAction,
  GetProjectSchedulesOkAction,
  GetProjectsKoAction,
  GetProjectsOkAction,
  LinkPaymentAction,
  LookUpState,
  LookUpSubarea,
  NewScheduleProject,
  OpenProjectScheduleAction,
  OwnersCandidatesResponse,
  PaymentInfo,
  ProjectCancelAgreementAction,
  ProjectCancelAgreementKoAction,
  ProjectCancelAgreementOkAction,
  ProjectChangeStateAction,
  ProjectChangeStateKo,
  ProjectChangeStateKoAction,
  ProjectChangeStateOk,
  ProjectChangeStateOkAction,
  ProjectData,
  ProjectGenericKoAction,
  ProjectImagesKoAction,
  ProjectListItem,
  ProjectPaymentAction,
  ProjectValidationErrorsAction,
  RemoveDocsTableAction,
  RemoveDocsTableKoAction,
  RemoveDocsTableOkAction,
  RemoveFollowUpDocumentAction,
  RemoveFollowupDocumentAction,
  RemoveFollowUpDocumentKoAction,
  RemoveFollowupDocumentKoAction,
  RemoveFollowUpDocumentOkAction,
  RemoveFollowupDocumentOkAction,
  RemovePaymentFileAction,
  RemovePaymentFileKoAction,
  RemovePaymentFileOkAction,
  ResetConfiguratorDataAction,
  ResetDocumentsAction,
  SaveCustomFieldsFormAction,
  ScheduledAppointmentItem,
  SelectProjectScheduleItemAction,
  SendActorAction,
  SendActorOkAction,
  SetPaymentData,
  SetProjectDataAction,
  SetProjectScheduleDataAction,
  SetScheduleDataAction,
  SetterGenericTableRowAction,
  StateProject,
  UpdateProjectCalendarAction,
  UpdateProjectScheduleAction,
  UploadAgreementAction,
  UploadAgreementKoAction,
  UploadAgreementOkAction,
  UploadDocumentationAction,
  UploadDocumentationKoAction,
  UploadDocumentationOkAction,
  UploadFollowUpDocumentAction,
  UploadFollowUpDocumentKoAction,
  UploadFollowUpDocumentOkAction,
  UploadPaymentFileAction,
  UploadPaymentFileKoAction,
  UploadPaymentFileOkAction,
  UploadScheduleDocumentAction,
  UploadScheduleDocumentKoAction,
  UploadScheduleDocumentOkAction,
} from './definitions';

// GETPROJECTS

export function getProjects(): GetProjectsAction {
  return {
    type: ProjectConst.GET_PROJECTS,
  };
}

export function getProjectsOk(
  projectsList: ProjectListItem[],
  states: LookUpState,
  subareas: LookUpSubarea,
): GetProjectsOkAction {
  return {
    type: ProjectConst.GET_PROJECTS_OK,
    projectsList,
    states,
    subareas,
  };
}

export function getProjectsKo(error: string): GetProjectsKoAction {
  return {
    type: ProjectConst.GET_PROJECTS_KO,
    error,
  };
}

// GET ONE PROJECT

export function getOneProject(id: string): GetOneProjectAction {
  return {
    type: ProjectConst.GET_ONE_PROJECT,
    id,
  };
}

export function getOneProjectOk(projectData: ProjectData): GetOneProjectOkAction {
  return {
    type: ProjectConst.GET_ONE_PROJECT_OK,
    projectData,
  };
}

export function getOneProjectKo(error: string): GetOneProjectKoAction {
  return {
    type: ProjectConst.GET_ONE_PROJECT_KO,
    error,
  };
}

// GET ONE PROJECT

export function createProject(projectData: ProjectData): CreateProjectAction {
  return {
    type: ProjectConst.CREATE_PROJECT,
    projectData,
  };
}

export function createProjectOk(projectData: ProjectData): CreateProjectOkAction {
  return {
    type: ProjectConst.CREATE_PROJECT_OK,
    projectData,
  };
}

export function createProjectKo(error: string): CreateProjectKoAction {
  return {
    type: ProjectConst.CREATE_PROJECT_KO,
    error,
  };
}

// EDIT PROJECT

export function editProject(projectData: ProjectData, idProject: number): EditProjectAction {
  return {
    type: ProjectConst.EDIT_PROJECT,
    projectData,
    idProject,
  };
}

export function editProjectOk(projectData: ProjectData): EditProjectOkAction {
  return {
    type: ProjectConst.EDIT_PROJECT_OK,
    projectData,
  };
}

export function editProjectKo(error: string, projectData: ProjectData): EditProjectKoAction {
  return {
    type: ProjectConst.EDIT_PROJECT_KO,
    error,
    projectData,
  };
}

// DELETE PROJECT

export function deleteProject(id: number): DeleteProjectAction {
  return {
    type: ProjectConst.DELETE_PROJECT,
    id,
  };
}

export function deleteProjectOk(id: number): DeleteProjectOkAction {
  return {
    type: ProjectConst.DELETE_PROJECT_OK,
    id,
  };
}

export function deleteProjectKo(error: string): DeleteProjectKoAction {
  return {
    type: ProjectConst.DELETE_PROJECT_KO,
    error,
  };
}

// VALIDATIONS ERRORS

export function projectValidationErrors(validationErrors: APIValidationError): ProjectValidationErrorsAction {
  return {
    type: ProjectConst.PROJECT_VALIDATION_ERRORS,
    validationErrors,
  };
}

// GET AREAS SUBAREAS

export function getAreasSubAreas(): GetAreasSubAreasAction {
  return {
    type: ProjectConst.GET_AREAS_SUBAREAS,
  };
}

export function getAreasSubAreasOk({ data }: { data: AreaSubArea[] }): GetAreasSubAreasOkAction {
  return {
    type: ProjectConst.GET_AREAS_SUBAREAS_OK,
    areasSubareasList: data,
  };
}

export function getAreasSubAreasKo(error: string): GetAreasSubAreasKoAction {
  return {
    type: ProjectConst.GET_AREAS_SUBAREAS_KO,
    error,
  };
}

// ADD DOCUMENT

export function addDocument(file: File, description: string, idProject: number): AddDocumentAction {
  return {
    type: ProjectConst.ADD_DOCUMENT,
    file,
    description,
    idProject,
  };
}

export function addDocumentOk(documentation: DocumentationItem[]): AddDocumentOkAction {
  return {
    type: ProjectConst.ADD_DOCUMENT_OK,
    documentation,
  };
}

export function addDocumentKo(error: string): AddDocumentKoAction {
  return {
    type: ProjectConst.ADD_DOCUMENT_KO,
    error,
  };
}

// GET DOCUMENTATION

export function getDocumentation(id: number): GetDocumentationAction {
  return {
    type: ProjectConst.GET_DOCUMENTATION,
    id,
  };
}

export function getDocumentationOk(documentation: DocumentationItem[]): GetDocumentationOkAction {
  return {
    type: ProjectConst.GET_DOCUMENTATION_OK,
    documentation,
  };
}

export function getDocumentationKo(error: string): GetDocumentationKoAction {
  return {
    type: ProjectConst.GET_DOCUMENTATION_KO,
    error,
  };
}

// GET PROJECT SCHEDULES
export function getProjectSchedules(id: number): GetProjectSchedulesAction {
  return {
    type: ProjectConst.GET_PROJECT_SCHEDULES,
    id,
  };
}

export function getProjectSchedulesOk(schedules: ScheduledAppointmentItem[]): GetProjectSchedulesOkAction {
  return {
    type: ProjectConst.GET_PROJECT_SCHEDULES_OK,
    schedules,
  };
}

export function createProjectSchedule(schedule: ScheduledAppointmentItem): CreateProjectScheduleAction {
  return {
    type: ProjectConst.CREATE_PROJECT_SCHEDULE,
    schedule,
  };
}

export function updateProjectSchedule(schedule: ScheduledAppointmentItem): UpdateProjectScheduleAction {
  return {
    type: ProjectConst.UPDATE_PROJECT_SCHEDULE,
    schedule,
  };
}

export function deleteProjectSchedule(id: number, project_id: number): DeleteProjectScheduleAction {
  return {
    type: ProjectConst.DELETE_PROJECT_SCHEDULE,
    id,
    project_id,
  };
}

// DELETE DOCUMENT

export function deleteDocument(file_id: number, id: number): DeleteFileAction {
  return {
    type: ProjectConst.DELETE_DOCUMENT,
    id,
    file_id,
  };
}

export function deleteDocumentOk(documentation: DocumentationItem[]): DeleteFileOkAction {
  return {
    type: ProjectConst.DELETE_DOCUMENT_OK,
    documentation,
  };
}

export function deleteDocumentKo(error: string): DeleteFileKoAction {
  return {
    type: ProjectConst.DELETE_DOCUMENT_KO,
    error,
  };
}

// DOWNLOAD DOCUMENT

export function downloadDocument(file_id: number, name: string, id: number): DownloadFileAction {
  return {
    type: ProjectConst.DOWNLOAD_DOCUMENT,
    file_id,
    id,
    name,
  };
}

export function downloadDocumentOk(data: Blob, name: string): DownloadFileOkAction {
  return {
    type: ProjectConst.DOWNLOAD_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadDocumentKo(error: string): DownloadFileKoAction {
  return {
    type: ProjectConst.DOWNLOAD_DOCUMENT_KO,
    error,
  };
}

// DOWNLOAD DOCUMENT

export function getDocument(file_id: number, name: string, id: number): GetFileAction {
  return {
    type: ProjectConst.GET_DOCUMENT,
    file_id,
    name,
    id,
  };
}

export function getDocumentOk(data: Blob): GetFileOkAction {
  return {
    type: ProjectConst.GET_DOCUMENT_OK,
    data,
  };
}

export function getDocumentKo(error: string): GetFileKoAction {
  return {
    type: ProjectConst.GET_DOCUMENT_KO,
    error,
  };
}

//CHANGE STATE

export function changeState(
  id: number,
  states: ProjectChangeStateOk | ProjectChangeStateKo,
  comments?: string,
): ProjectChangeStateAction {
  return {
    type: ProjectConst.PROJECT_CHANGE_STATE,
    states,
    id,
    comments,
  };
}

export function changeStateOk(state_code: StateProject, state_name: string): ProjectChangeStateOkAction {
  return {
    type: ProjectConst.PROJECT_CHANGE_STATE_OK,
    state_code,
    state_name,
  };
}

export function changeStateKo(error: string): ProjectChangeStateKoAction {
  return {
    type: ProjectConst.PROJECT_CHANGE_STATE_KO,
    error,
  };
}

// GET ONE FOLLOWUP

export function getOneFollowUp(id: number): GetFollowUpAction {
  return {
    type: ProjectConst.GET_FOLLOWUP,
    id,
  };
}

export function getOneFollowUpOk({ data }: { data: FollowUp }): GetFollowUpOkAction {
  return {
    type: ProjectConst.GET_FOLLOWUP_OK,
    followUp: data,
  };
}

export function getOneFollowUpKo(error: string): GetFollowUpKoAction {
  return {
    type: ProjectConst.GET_FOLLOWUP_KO,
    error,
  };
}

// ADD ONE FOLLOWUP

export function addOneFollowUp(followUpData: FollowUp): AddFollowUpAction {
  return {
    type: ProjectConst.ADD_FOLLOWUP,
    followUpData,
  };
}

export function addOneFollowUpOk({ data }: { data: FollowUp }): AddFollowUpOkAction {
  return {
    type: ProjectConst.ADD_FOLLOWUP_OK,
    followUp: data,
  };
}

export function addOneFollowUpKo(error: string): AddFollowUpKoAction {
  return {
    type: ProjectConst.ADD_FOLLOWUP_KO,
    error,
  };
}

// GET LIST FOLLOWUPS

export function getListFollowUps(projectId: number): GetListFollowUpsAction {
  return {
    type: ProjectConst.GET_LIST_FOLLOWUPS,
    projectId,
  };
}

export function getListFollowUpsOk({ data }: { data: FollowUp[] }): GetListFollowUpsOkAction {
  return {
    type: ProjectConst.GET_LIST_FOLLOWUPS_OK,
    followUpsList: data,
  };
}

export function getListFollowUpsKo(error: string): GetListFollowUpsKoAction {
  return {
    type: ProjectConst.GET_LIST_FOLLOWUPS_KO,
    error,
  };
}
// CHANGE MODAL BOOLEAN

export function changeCreateModalVisible(createView: boolean): ChangeCreateModalVisible {
  return {
    type: ProjectConst.CHANGE_CREATE_MODAL_VISIBLE,
    createView,
  };
}

// CHANGE MODAL EDIT DELETE
export function changeEditDeleteModalVisible(editDeleteView: boolean): ChangeEditDeleteModalVisible {
  return {
    type: ProjectConst.CHANGE_EDITDELETE_MODAL_VISIBLE,
    editDeleteView,
  };
}

// EDIT FOLLOWUP

export function editFollowup(followUpData: FollowUp, id: number): EditFollowUpAction {
  return {
    type: ProjectConst.EDIT_FOLLOWUP,
    followUpData,
    id,
  };
}

export function editFollowupOk({ data }: { data: FollowUp }): EditFollowUpOkAction {
  return {
    type: ProjectConst.EDIT_FOLLOWUP_OK,
    followUp: data,
  };
}

export function editFollowupKo(error: string): EditFollowUpKoAction {
  return {
    type: ProjectConst.EDIT_FOLLOWUP_KO,
    error,
  };
}

// DELETE FOLLOWUP

export function deleteFollowUp(id: number): DeleteFollowUpAction {
  return {
    type: ProjectConst.DELETE_FOLLOWUP,
    id,
  };
}

export function deleteFollowUpOk(id: number): DeleteFollowUpOkAction {
  return {
    type: ProjectConst.DELETE_FOLLOWUP_OK,
    id,
  };
}

export function deleteFollowUpKo(error: string): DeleteFollowUpKoAction {
  return {
    type: ProjectConst.DELETE_FOLLOWUP_KO,
    error,
  };
}

// UPLOAD DOCUMENT

export function newDocument(file: File, name: string, actor: string): UploadDocumentationAction {
  return {
    type: ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT,
    file,
    name,
    actor,
  };
}

export function newDocumentOk(data: FileItem, name: string): UploadDocumentationOkAction {
  return {
    type: ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT_OK,
    data,
    name,
  };
}

export function newDocumentKo(error: APIError): UploadDocumentationKoAction {
  return {
    type: ProjectConst.UPLOAD_FOLLOWUP_DOCUMENT_KO,
    error,
  };
}

export function resetDocumentsToSend(): ResetDocumentsAction {
  return {
    type: ProjectConst.RESET_DOCUMENTS,
  };
}

//DOWNLOAD FOLLOWUP DOCUMENT

export function downloadFollowupDocument(
  project_id: number,
  name: string,
  file_id: number,
  typeTable: boolean,
): DownloadDocumentFollowupAction {
  return {
    type: ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT,
    project_id,
    name,
    file_id,
    typeTable,
  };
}

export function downloadFollowupDocumentOk(data: Blob, name: string): DownloadDocumentFollowupOkAction {
  return {
    type: ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadFollowupDocumentKo(error: string): DownloadDocumentFollowupKoAction {
  return {
    type: ProjectConst.DOWNLOAD_FOLLOWUP_DOCUMENT_KO,
    error,
  };
}

// DELETE FOLLOWUP DOCUMENT

export function deleteFollowupDocument(project_id: number, file_id: number): RemoveFollowupDocumentAction {
  return {
    type: ProjectConst.DELETE_FOLLOWUP_DOCUMENT,
    project_id,
    file_id,
  };
}

export function deleteFollowupDocumentOk(file_id: number): RemoveFollowupDocumentOkAction {
  return {
    type: ProjectConst.DELETE_FOLLOWUP_DOCUMENT_OK,
    file_id,
  };
}

export function deleteFollowupDocumentKo(error: string): RemoveFollowupDocumentKoAction {
  return {
    type: ProjectConst.DELETE_FOLLOWUP_DOCUMENT_KO,
    error,
  };
}

// UPLOAD AGREEMENT

export function uploadAgreement(documentation_id: number, data: File, project_id: number): UploadAgreementAction {
  return {
    type: ProjectConst.UPLOAD_AGREEMENT,
    documentation_id,
    data,
    project_id,
  };
}

export function uploadAgreementOk(documentation: DocumentationItem[]): UploadAgreementOkAction {
  return {
    type: ProjectConst.UPLOAD_AGREEMENT_OK,
    documentation,
  };
}

export function uploadAgreementKo(error: string): UploadAgreementKoAction {
  return {
    type: ProjectConst.UPLOAD_AGREEMENT_KO,
    error,
  };
}

// DELETE AGREEMENT

export function deleteAgreement(documentation_id: number, project_id: number): DeleteAgreementAction {
  return {
    type: ProjectConst.DELETE_AGREEMENT,
    documentation_id,
    project_id,
  };
}

export function deleteAgreementOk(documentation: DocumentationItem[]): DeleteAgreementOkAction {
  return {
    type: ProjectConst.DELETE_AGREEMENT_OK,
    documentation,
  };
}

export function deleteAgreementKo(error: string): DeleteAgreementKoAction {
  return {
    type: ProjectConst.DELETE_AGREEMENT_KO,
    error,
  };
}

export function confirmConfigurator(
  id_project: number,
  section: string,
  data: ConfiguratorData[],
): ConfirmConfiguratorAction {
  return {
    type: ProjectConst.CONFIRM_CONFIGURATOR,
    id_project,
    section,
    data,
  };
}

export function confirmConfiguratorOk(): ConfirmConfiguratorOkAction {
  return {
    type: ProjectConst.CONFIRM_CONFIGURATOR_OK,
  };
}
export function confirmConfiguratorKo(error: string): ConfirmConfiguratorKoAction {
  return {
    type: ProjectConst.CONFIRM_CONFIGURATOR_KO,
    error,
  };
}

export function resetConfiguratorData(): ResetConfiguratorDataAction {
  return {
    type: ProjectConst.RESET_CONFIGURATOR_DATA,
  };
}

export function saveCustomFieldsForm(
  project_id: number,
  project_schedule_id: number,
  section: CodeSectionProject,
  fields: Record<string, string>,
): SaveCustomFieldsFormAction {
  return {
    type: ProjectConst.SAVE_CUSTOM_FIELDS_FORM,
    project_id,
    project_schedule_id,
    section,
    fields,
  };
}

export function addNewGenericTableRow(setterData?: any): AddNewGenericTableRowAction {
  return {
    type: ProjectConst.ADD_NEW_GENERIC_TABLE_ROW,
    setterData,
  };
}

export function setterGenericTableRow(setterData: any, finalStep: boolean): SetterGenericTableRowAction {
  return {
    type: ProjectConst.SET_SETTER_GENERIC_TABLE_ROW,
    setterData,
    finalStep,
  };
}

// ASSIGN DOCS TO ROW

export function assignDocsToRow(tableDocsData: FollowUp): AssignDocsToRowAction {
  return {
    type: ProjectConst.ASSIGN_DOCS_TO_ROW,
    tableDocsData,
  };
}

export function assignDocsToRowOk(data: DocumentationItem[], tableDocsData: any): AssignDocsToRowOkAction {
  return {
    type: ProjectConst.ASSIGN_DOCS_TO_ROW_OK,
    tableDocs: data,
    tableDocsData,
  };
}

export function assignDocsToRowKo(error: string): AssignDocsToRowKoAction {
  return {
    type: ProjectConst.ASSIGN_DOCS_TO_ROW_KO,
    error,
  };
}

// REMOVE DOCS TABLE

export function removeDocsTable(tableDocsData: any): RemoveDocsTableAction {
  return {
    type: ProjectConst.DELETE_DOCS_ROW,
    tableDocsData,
  };
}

export function removeDocsTableOk(data: DocumentationItem[], tableDocsData: any): RemoveDocsTableOkAction {
  return {
    type: ProjectConst.DELETE_DOCS_ROW_OK,
    tableDocsData,
    tableDocs: data,
  };
}

export function removeDocsTableKo(error: string): RemoveDocsTableKoAction {
  return {
    type: ProjectConst.DELETE_DOCS_ROW_KO,
    error,
  };
}

// REMOVE ROW FROM TABLE

export interface DeleteData {
  project_id: number;
  section: string;
  id_row: number;
  id_generated: number;
}

export function deleteRowTable(deleteData: DeleteData): DeleteRowTableAction {
  return {
    type: ProjectConst.DELETE_ROW_TABLE,
    deleteData,
  };
}

export function deleteRowTableOk(
  { data }: { data: GenericData },
  generatedId?: number,
  idStandard?: number,
): DeleteRowTableOkAction {
  return {
    type: ProjectConst.DELETE_ROW_TABLE_OK,
    responseData: data,
    generatedId,
    idStandard,
  };
}

export function deleteRowTableKo(error: string): DeleteRowTableKoAction {
  return {
    type: ProjectConst.DELETE_ROW_TABLE_KO,
    error,
  };
}

// UPLOAD MULTI FILE

export interface GenericFieldUpload {
  document: File;
  project_id: number;
  fieldName: string;
  project_schedule_id?: number | null;
}

export function uploadGenericField(uploadData: GenericFieldUpload): GenericFieldUploadAction {
  return {
    type: ProjectConst.GENERIC_FORM_UPLOAD_FIELD,
    uploadData,
  };
}

export function uploadGenericFieldOk(
  documentation: DocumentationItem[],
  fieldName: string,
): GenericFieldUploadOkAction {
  return {
    type: ProjectConst.GENERIC_FORM_UPLOAD_FIELD_OK,
    documentation,
    fieldName,
  };
}

export function uploadGenericFieldKo(error: string): GenericFieldUploadKoAction {
  return {
    type: ProjectConst.GENERIC_FORM_UPLOAD_FIELD_KO,
    error,
  };
}

//DOWNLOAD ONLY FILE ID

export function download(file_id: number, name: string): DownloadGenericFileAction {
  return {
    type: ProjectConst.GENERIC_FORM_DOWNLOAD_FILE,
    file_id,
    name,
  };
}

export function downloadOk(data: Blob, name: string): DownloadGenericFileOkAction {
  return {
    type: ProjectConst.GENERIC_FORM_DOWNLOAD_FILE_OK,
    data,
    name,
  };
}

export function downloadKo(error: string): DownloadGenericFileKoAction {
  return {
    type: ProjectConst.GENERIC_FORM_DOWNLOAD_FILE_KO,
    error,
  };
}

//GET ONLY FILE ID

export function getFile(file_id: number): GetGenericFileAction {
  return {
    type: ProjectConst.GENERIC_FORM_GET_FILE,
    file_id,
  };
}

export function getFileOk(data: Blob): GetGenericFileOkAction {
  return {
    type: ProjectConst.GENERIC_FORM_GET_FILE_OK,
    data,
  };
}

export function getFileKo(error: string): GetGenericFileKoAction {
  return {
    type: ProjectConst.GENERIC_FORM_GET_FILE_KO,
    error,
  };
}

//REMOVE FILE

export interface RemoveDocData {
  project_id: number;
  fieldName: string;
  fileId: number;
}

export function deleteFile(data: RemoveDocData): DeleteGenericFileAction {
  return {
    type: ProjectConst.GENERIC_FORM_DELETE_FILE,
    data,
  };
}

export function deleteFileOk(dataF: RemoveDocData): DeleteGenericFileOkAction {
  return {
    type: ProjectConst.GENERIC_FORM_DELETE_FILE_OK,
    dataF,
  };
}

export function deleteFileKo(error: string): DeleteGenericFileKoAction {
  return {
    type: ProjectConst.GENERIC_FORM_DELETE_FILE_KO,
    error,
  };
}

// get fields

export function getGenericFields(
  project_id: number,
  project_schedule_id: number,
  section: CodeSectionProject,
): GetGenericFieldsAction {
  return {
    type: ProjectConst.GENERIC_FORM_GET_FIELDS,
    project_id,
    project_schedule_id,
    section,
  };
}
export function getGenericFieldsOk(data: CustomFieldsForm): GetGenericFieldsOkAction {
  return {
    type: ProjectConst.GENERIC_FORM_GET_FIELDS_OK,
    formFields: data,
  };
}

export function getGenericFieldsKo(error: string): GetGenericFieldsKoAction {
  return {
    type: ProjectConst.GENERIC_FORM_GET_FIELDS_KO,
    error,
  };
}

export function editProjectScheduleItem(id: number, code: string): EditProjectScheduleItemAction {
  return {
    type: ProjectConst.EDIT_PROJECT_SCHEDULE_ITEM,
    id,
    code,
  };
}

export function editProjectScheduleItemOk(areasTemplates: Tracking[]): EditProjectScheduleItemOkAction {
  return {
    type: ProjectConst.EDIT_PROJECT_SCHEDULE_ITEM_OK,
    areasTemplates,
  };
}

export function selectProjectScheduleItem(id: number): SelectProjectScheduleItemAction {
  return {
    type: ProjectConst.SELECT_PROJECT_SCHEDULE_ITEM,
    id,
  };
}

export interface ItemMetadata {
  id: number;
  code: string;
  value: string;
}
export interface DirectoryMetadata {
  academic_years: [
    {
      id: string;
      code: string;
      value: string;
    },
  ];
  courses: ItemMetadata[];
  levels: ItemMetadata[];
  poblacion: ItemMetadata[];
  profesional: ItemMetadata[];
  provinces: ItemMetadata[];
}

// GET DIRECTORY DATA FILTER

export interface FilterData {
  poblation: string;
  province: string;
  academic_course: string;
  category: string;
  family_pro: string;
  study_name: string;
  age: number[];
  page: number;
  total: number;
}

export interface FilterResponseData {
  more: boolean;
  page: number;
  usersData: {}[];
}

export function getDirectoryDataFilter(filterData?: FilterData | undefined): GetDirectoryDataFilterAction {
  return {
    type: ProjectConst.GET_DIRECTORY_DATA_FILTER,
    filterData,
  };
}

export function getDirectoryDataFilterOk({ data }: { data: FilterResponseData }): GetDirectoryDataFilterOkAction {
  return {
    type: ProjectConst.GET_DIRECTORY_DATA_FILTER_OK,
    filterResponse: data,
  };
}

export function getDirectoryDataFilterKo(error: string): GetDirectoryDataFilterKoAction {
  return {
    type: ProjectConst.GET_DIRECTORY_DATA_FILTER_KO,
    error,
  };
}

// GET ACTORS METADATA

export function getActorsMetadata(project_id: number): GetActorsMetadataAction {
  return {
    type: ProjectConst.GET_ACTORS_METADATA,
    project_id,
  };
}

export function getActorsMetadataOk(projectActors: OwnersCandidatesResponse): GetActorsMetadataOkAction {
  return {
    type: ProjectConst.GET_ACTORS_METADATA_OK,
    projectActors,
  };
}

// SEND ACTOR

export interface AllowedUsersAndCandidates {
  candidates: {
    id: number;
    name: string;
  }[];
  allowed_users: {
    allowed_user_id: number;
    allowed_user_name: string;
  }[];
}

export function sendActor(project_id: number, user_id: number): SendActorAction {
  return {
    type: ProjectConst.SEND_ACTOR,
    project_id,
    user_id,
  };
}

export function sendActorOk({ data }: { data: AllowedUsersAndCandidates }): SendActorOkAction {
  return {
    type: ProjectConst.SEND_ACTOR_OK,
    allowedUsersAndCandidates: data,
  };
}

// DELETE ACTOR

export function deleteActor(project_id: number, user_id: number): DeleteActorAction {
  return {
    type: ProjectConst.DELETE_ACTOR,
    project_id,
    user_id,
  };
}

export function deleteActorOk({ data }: { data: AllowedUsersAndCandidates }): DeleteActorOkAction {
  return {
    type: ProjectConst.DELETE_ACTOR_OK,
    allowedUsersAndCandidates: data,
  };
}

export function projectSetData(key: string, value: string | number): SetProjectDataAction {
  return {
    type: ProjectConst.SET_PROJECT_DATA,
    key,
    value,
  };
}

export function projectScheduleSetData(key: string, value: string | number): SetProjectScheduleDataAction {
  return {
    type: ProjectConst.SET_PROJECT_SCHEDULE_DATA,
    key,
    value,
  };
}

export function updateProjectCalendar(
  project_id: number,
  schedules: ScheduledAppointmentItem[],
): UpdateProjectCalendarAction {
  return {
    type: ProjectConst.UPDATE_PROJECT_CALENDAR,
    project_id,
    schedules,
  };
}

export function projectGenericKo(error: string): ProjectGenericKoAction {
  return {
    type: ProjectConst.PROJECT_GENERIC_KO,
    error,
  };
}

export function uploadFollowUpDocument(file: File): UploadFollowUpDocumentAction {
  return {
    type: ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT,
    file,
  };
}

export function uploadFollowUpDocumentOk(fileData: FileItem): UploadFollowUpDocumentOkAction {
  return {
    type: ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT_OK,
    fileData,
  };
}

export function uploadFollowUpDocumentKo(error: string): UploadFollowUpDocumentKoAction {
  return {
    type: ProjectConst.UPLOAD_FOLLOW_UP_DOCUMENT_KO,
    error,
  };
}

export function removeFollowUpDocument(file_id: number): RemoveFollowUpDocumentAction {
  return {
    type: ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT,
    file_id,
  };
}

export function removeFollowUpDocumentOk(): RemoveFollowUpDocumentOkAction {
  return {
    type: ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT_OK,
  };
}

export function removeFollowUpDocumentKo(error: string): RemoveFollowUpDocumentKoAction {
  return {
    type: ProjectConst.REMOVE_FOLLOW_UP_DOCUMENT_KO,
    error,
  };
}

export function downloadFollowUpDocument(file_id: number, filename: string): DownloadFollowUpDocumentAction {
  return {
    type: ProjectConst.DOWNLOAD_FOLLOW_UP,
    file_id,
    filename,
  };
}

export function downloadFollowUpDocumentOk(data: Blob, name: string): DownloadFollowUpDocumentOkAction {
  return {
    type: ProjectConst.DOWNLOAD_FOLLOW_UP_OK,
    data,
    name,
  };
}

export function downloadFollowUpDocumentKo(error: string): DownloadFollowUpDocumentKoAction {
  return {
    type: ProjectConst.DOWNLOAD_FOLLOW_UP_KO,
    error,
  };
}
export function setScheduleData(key: string, value: string | number): SetScheduleDataAction {
  return {
    type: ProjectConst.SET_SCHEDULE_DATA,
    key,
    value,
  };
}

export function addNewSchedule(project_id: number, user_id: number): AddNewScheduleAction {
  return {
    type: ProjectConst.ADD_SCHEDULE,
    project_id,
    user_id,
  };
}

// UPLOAD SCHEDULE DOCUMENT

export function uploadScheduleDocument(file: File, code: string): UploadScheduleDocumentAction {
  return {
    type: ProjectConst.UPLOAD_SCHEDULE_DOCUMENT,
    file,
    code,
  };
}

export function uploadScheduleDocumentOk(fileInfo: FileItem): UploadScheduleDocumentOkAction {
  return {
    type: ProjectConst.UPLOAD_SCHEDULE_DOCUMENT_OK,
    fileInfo,
  };
}

export function uploadScheduleDocumentKo(error: string): UploadScheduleDocumentKoAction {
  return {
    type: ProjectConst.UPLOAD_SCHEDULE_DOCUMENT_KO,
    error,
  };
}

//NEW SCHEDULE

export function createNewScheduleProject(newScheduleData: NewScheduleProject): CreateNewScheduleProjectAction {
  return {
    type: ProjectConst.CREATE_NEW_SCHEDULE,
    newScheduleData,
  };
}

export function createNewScheduleProjectOk(scheduleData: NewScheduleProject): CreateNewScheduleProjectOkAction {
  return {
    type: ProjectConst.CREATE_NEW_SCHEDULE_OK,
    scheduleData,
  };
}

export function createNewScheduleProjectKo(error: string): CreateNewScheduleProjectKoAction {
  return {
    type: ProjectConst.CREATE_NEW_SCHEDULE_KO,
    error,
  };
}

export function projectPayment(
  project_id: number,
  project_schedule_id: number,
  account_number: string,
): ProjectPaymentAction {
  return {
    type: ProjectConst.PROJECT_PAYMENT,
    project_id,
    project_schedule_id,
    account_number,
  };
}

export function linkPayment(
  project_id: number,
  project_schedule_id: number,
  invoice_id: number,
  invoice_file_id: number,
): LinkPaymentAction {
  return {
    type: ProjectConst.LINK_PAYMENT,
    project_id,
    project_schedule_id,
    invoice_id,
    invoice_file_id,
  };
}

export function openProjectScheduleModal(): OpenProjectScheduleAction {
  return {
    type: ProjectConst.OPEN_PROJECT_SCHEDULE,
  };
}

export function closeProjectScheduleModal(): CloseProjectScheduleAction {
  return {
    type: ProjectConst.CLOSE_PROJECT_SCHEDULE,
  };
}

// SET PAYMENT DATA
export function setPaymentData(data: PaymentInfo): SetPaymentData {
  return {
    type: ProjectConst.SET_PAYMENT_DATA,
    data,
  };
}

// UPLOAD PAYMENT OR INVOICE FILE
export function uploadPaymentFile(file: File): UploadPaymentFileAction {
  return {
    type: ProjectConst.UPLOAD_PAYMENT_FILE,
    file,
  };
}

export function uploadPaymentFileOk(fileData: FileItem): UploadPaymentFileOkAction {
  return {
    type: ProjectConst.UPLOAD_PAYMENT_FILE_OK,
    fileData,
  };
}

export function uploadPaymentFileKo(error: string): UploadPaymentFileKoAction {
  return {
    type: ProjectConst.UPLOAD_PAYMENT_FILE_KO,
    error,
  };
}

// REMOVE PAYMENT OR INVOICE FILE
export function removePaymentFile(fileId: number): RemovePaymentFileAction {
  return {
    type: ProjectConst.REMOVE_PAYMENT_FILE,
    fileId,
  };
}

export function removePaymentFileOk(): RemovePaymentFileOkAction {
  return {
    type: ProjectConst.REMOVE_PAYMENT_FILE_OK,
  };
}

export function removePaymentFileKo(error: string): RemovePaymentFileKoAction {
  return {
    type: ProjectConst.REMOVE_PAYMENT_FILE_KO,
    error,
  };
}

export function getProjectImages(projectId: number): GetProjectImagesAction {
  return {
    type: ProjectConst.GET_PROJECT_IMAGES,
    projectId,
  };
}

export function getProjectImagestOk(projectImages: ResourceImageData[]): GetProjectImagesOkAction {
  return {
    type: ProjectConst.GET_PROJECT_IMAGES_OK,
    projectImages,
  };
}

export function addProjectImage(
  projectId: number,
  name: string,
  description: string,
  image: string,
  userId: number,
): AddProjectImageAction {
  return {
    type: ProjectConst.ADD_PROJECT_IMAGE,
    projectId,
    name,
    description,
    image,
    userId,
  };
}

export function addProjectImageOk(projectImage: ResourceImageData): AddProjectImageOkAction {
  return {
    type: ProjectConst.ADD_PROJECT_IMAGE_OK,
    projectImage,
  };
}

export function editProjectImage(
  projectId: number,
  projectImage: ResourceImageData,
  name: string,
  description: string,
  newImage: string,
  userId: number,
): EditProjectImageAction {
  return {
    type: ProjectConst.EDIT_PROJECT_IMAGE,
    projectId,
    projectImage,
    name,
    description,
    newImage,
    userId,
  };
}

export function editProjectImageOk(projectImage: ResourceImageData): EditProjectImageOkAction {
  return {
    type: ProjectConst.EDIT_PROJECT_IMAGE_OK,
    projectImage,
  };
}

export function deleteProjectImage(projectId: number, projectImage: ResourceImageData): DeleteProjectImageAction {
  return {
    type: ProjectConst.DELETE_PROJECT_IMAGE,
    projectId,
    projectImage,
  };
}

export function deleteProjectImageOk(projectImageId: number): DeleteProjectImageOkAction {
  return {
    type: ProjectConst.DELETE_PROJECT_IMAGE_OK,
    projectImageId,
  };
}

export function ProjectImagestKo(error: string): ProjectImagesKoAction {
  return {
    type: ProjectConst.PROJECT_IMAGES_KO,
    error,
  };
}

export function projectCancelAgreement(project_id: number): ProjectCancelAgreementAction {
  return {
    type: ProjectConst.PROJECT_CANCEL_AGREEMENT,
    project_id,
  };
}

export function projectCancelAgreementOk(state_code: StateProject, state_name: string): ProjectCancelAgreementOkAction {
  return {
    type: ProjectConst.PROJECT_CANCEL_AGREEMENT_OK,
    state_code,
    state_name,
  };
}

export function projectCancelAgreementKo(error: string): ProjectCancelAgreementKoAction {
  return {
    type: ProjectConst.PROJECT_CANCEL_AGREEMENT_KO,
    error,
  };
}
