import React from 'react';
import styles from './fncButton.module.scss';

export type ButtonColor = 'secondary' | 'cancel';

export interface FncButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  buttonStyle?: ButtonColor;
  customClass?: string;
  fullWidth?: boolean;
}

const colorPicker = (color?: ButtonColor): string => {
  switch (color) {
    case 'secondary':
      return styles.isSecondary;
    case 'cancel':
      return styles.isCancel;
    default:
      return '';
  }
};

const FncButton: React.FC<FncButtonProps> = ({ children, buttonStyle, customClass, fullWidth, ...props }) => (
  <button
    className={`
    ${styles.root}  
  ${colorPicker(buttonStyle)}
  ${fullWidth ? styles.fullWidth : ''} 
  ${customClass ? customClass : ''}`}
    {...props}
  >
    {children}
  </button>
);

export default FncButton;
