import AssignmentIcon from '@material-ui/icons/Assignment';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import { connect } from 'react-redux';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../helpers/translate';
import {
  selectUnreadAlertsNumber,
  selectUnreadMessageThreadsNumber,
  selectUnreadPendingTasksNumber,
} from '../../redux/notification/selectors';
import { AppState } from '../../redux/root-reducer';

interface SubmenuNotificationsProps {
  unread_message_threads?: number;
  unread_alerts?: number;
  unread_pending_tasks?: number;
  selected: 'alerts' | 'messages' | 'pending-tasks';
}

const SubmenuNotifications: React.FC<SubmenuNotificationsProps> = ({
  unread_message_threads,
  unread_alerts,
  unread_pending_tasks,
  selected,
}) => {
  const optionsSubmenuNotifications: SubmenuItem[] = [
    {
      title: tKey('Alertas'),
      icon: <WarningIcon />,
      route: '/alertas',
      count: unread_alerts,
      type: 'route',
      selected: selected === 'alerts',
    },
    {
      title: tKey('Tareas pendientes'),
      icon: <AssignmentIcon />,
      route: '/tareas-pendientes',
      count: unread_pending_tasks,
      type: 'route',
      selected: selected === 'pending-tasks',
    },
  ];
  return <Submenu optionsMenu={optionsSubmenuNotifications} />;
};

const mapStateToProps = (state: AppState) => ({
  unread_message_threads: selectUnreadMessageThreadsNumber(state),
  unread_alerts: selectUnreadAlertsNumber(state),
  unread_pending_tasks: selectUnreadPendingTasksNumber(state),
});

export default connect(mapStateToProps, null)(SubmenuNotifications);
