import * as yup from 'yup';
import { tErrorKey } from '../helpers/translate';
import { ParticipantParticularitiesTypeEnum } from '../redux/internal-projects/participants/particularities/definitions';

//const SPANISH_ACCOUNT_NUMBER = /([a-zA-Z]{2})(\d{2})(\d{4})(\d{4})(\d{2})(\d{10})/;

export const requiredText = tErrorKey('Este campo es obligatorio');
export const requiredFile = tErrorKey('file.required');
const requiredName = tErrorKey('El nombre es obligatorio');
export const requiredNumber = tErrorKey('Este campo debe ser numérico');
const incorrectFormat = tErrorKey('Formato incorrecto');
const requiredEmail = tErrorKey('La dirección de correo es obligatoria');
const requiredPassword = tErrorKey('La contraseña es obligatoria');
const passwordsShouldBeMatched = tErrorKey('Las contraseñas tienen que ser iguales');
const requiredSurname = tErrorKey('El apellido es obligatorio');
const requiredMinThreeCharacters = tErrorKey('Este campo debe tener mínimo 3 caracteres');
const requiredMaxThreeCharacters = tErrorKey('Este campo debe tener máximo 3 caracteres');
const requiredMaxThreeNumbers = tErrorKey('Este campo debe tener máximo 3 dígitos');
const phoneMax15digits = tErrorKey('El teléfono debe tener máximo 15 dígitos');
const requiredMinEightCharactersInName = tErrorKey('El nombre debe tener mínimo 8 carácteres');
const requiredMessage = tErrorKey('El mensaje es obligatorio');
const requiredReason = tErrorKey('motivo-obligatorio');
const requiredOneOption = tErrorKey('Debe Seleccionar una opción');
const maxTwoThousandCharacters = tErrorKey('Máximo 2000 caracteres');
const accountingCodeMaxLength = tErrorKey('El código tiene un máximo de 10 carácteres');
const accountingDescriptionMaxLength = tErrorKey('La descripción tiene un máximo de 255 carácteres');
const maxOneHundredCharacters = tErrorKey('Máximo 100 caracteres');
const maxTenCharacters = tErrorKey('Máximo 10 carácteres');
const maxTwentyCharacters = tErrorKey('Máximo 20 carácteres');
const maxTwoHundredCharacters = tErrorKey('Máximo 200 caracteres');
const requiredNumbersFrame = tErrorKey('Debe ser un número entre el 1 y el 31');
const maxNineDigits = tErrorKey('El número no puede tener más de 9 dígitos');
const expenceAccountDescriptionMaxLength = tErrorKey('La descripción tiene un máximo de 60 carácteres');
const accountingNoteDescriptionMaxLength = tErrorKey('La descripción tiene un máximo de 30 carácteres');
const requiredDigitsOrLetters = tErrorKey('Este campo sólo admite un dígito');
const dniValidation = tErrorKey('DNIs incorrecto');
const nieValidation = tErrorKey('NIE incorrecto');
const nifValidation = tErrorKey('NIF incorrecto');
const passportValidation = tErrorKey('Pasaporte incorrecto');
const oneMillionMax = tErrorKey('max-millon');
const max_length = tErrorKey('max_length');
const password_min = tErrorKey('pass-validation-length');
const password_case = tErrorKey('pass-validation-case');
const password_number = tErrorKey('pass-validation-number');
const password_symbols = tErrorKey('pass-validation-symbols');

export const emailSchema = {
  email: yup
    .string()
    .email(incorrectFormat)
    .required(requiredEmail),
};

export const phoneSchema = {
  phone: yup.string().test('phone', phoneMax15digits, val => {
    if (val && val.length > 0) return val.length <= 15;
    return true;
  }),
};

const password = {
  password: yup
    .string()
    .required(requiredPassword)
    .min(8, password_min)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, password_case)
    .matches(/^(?=.*[0-9])/, password_number)
    .matches(/^(?=.*[!@#$%^&*])/, password_symbols),
};

const password_simple = {
  password: yup.string().required(requiredPassword),
};

const confirmPassword = {
  password2: yup.string().oneOf([yup.ref('password'), null], passwordsShouldBeMatched),
};

export const nameSchema = {
  name: yup.string().required(requiredName),
};

const surname = {
  surname: yup.string().required(requiredSurname),
};

const document_user = {
  document_type: yup.string(),
  document_number: yup
    .string()
    .required(requiredText)
    .when(['document_type'], {
      is: document_type => document_type === 'DNI',
      then: yup.string().matches(/^(\d{8})([A-Z])$/, dniValidation),
    })
    .when(['document_type'], {
      is: document_type => document_type === 'NIE',
      then: yup.string().matches(/^[XYZ]\d{7,8}[A-Z]$/, nieValidation),
    })
    .when(['document_type'], {
      is: document_type => document_type === 'NIF',
      then: yup.string().matches(/^(\d{8})([A-Z])$/, nifValidation),
    })
    .when(['document_type'], {
      is: document_type => document_type === 'Pasaporte',
      then: yup.string().max(20, passportValidation),
    }),
};

const address = {
  address: yup
    .string()
    .min(3, requiredMinThreeCharacters)
    .required(requiredText),
};

const addressData = {
  address_type: yup.string().required(requiredText),
  ...address,
  flat: yup.string(),
  floor: yup.string(),
  room: yup.string(),
  zip_code: yup.string().required(requiredText),
  staircase: yup.string(),
};

export const LoginSchema = yup.object().shape({
  ...emailSchema,
  ...password_simple,
});

export const AddressSchema = yup.object().shape({
  ...addressData,
});

export const ForgotPasswordSchema = yup.object().shape({
  ...emailSchema,
});

export const CategorySchema = yup.object().shape({
  category: yup.string(),
});

export const NewPasswordSchema = yup.object().shape({
  ...password,
  ...confirmPassword,
});

export const RegisterSchema = yup.object().shape({
  ...emailSchema,
  ...password,
  ...confirmPassword,
});

export const EditUserSchema = yup.object().shape({
  ...emailSchema,
  ...nameSchema,
  ...surname,
  surname2: yup.string(),
  ...document_user,
  email2: yup
    .string()
    .email(tErrorKey('Formato incorrecto'))
    .nullable(),
  city: yup.string().required(requiredText),
  phone: yup.string().required(requiredText),
});

export const EducatorUserSchema = yup.object().shape({
  ...emailSchema,
  ...nameSchema,
  ...surname,
  surname2: yup.string(),
  entity_id: yup.string().required(requiredText),
  ...document_user,
  city: yup.string().required(requiredText),
  phone: yup.string().required(requiredText),
});

export const StudentUserSchema = yup.object().shape({
  company_name: yup.string(),
  employee_role: yup.string(),
  current_incomes: yup.string(),
  contract_type: yup.string(),
  instagram: yup.string(),
  twitter: yup.string(),
  facebook: yup.string(),
  linkedin: yup.string(),
  youtube: yup.string(),
  other_social_networks: yup.string(),
  cv_agreement: yup.boolean(),
});

export const CreateUserSchema = yup.object().shape({
  ...emailSchema,
  ...nameSchema,
  ...surname,
  surname2: yup.string(),
  ...addressData,
  ...document_user,
  ...phoneSchema,
  ...document_user,
  gender: yup.string().required(requiredText),
});

export const AcademyContactSchema = yup.object().shape({
  name: yup.string().required(requiredText),
  surname: yup.string().required(requiredText),
  staff_role: yup.string().required(requiredText),
  ...emailSchema,
  ...phoneSchema,
  description: yup.string().max(255, accountingDescriptionMaxLength),
});

export const ChangeResponsibleSchema = yup.object().shape({
  newResponsible: yup.string(),
});

export const ContractSchema = yup.object().shape({
  contract_number: yup.string().required(requiredText),
  start_date: yup.number().nullable(),
  end_date: yup
    .string()
    .nullable()
    .required(requiredText),
  file_id: yup.number().nullable(),
});

export const EventSchema = yup.object().shape({
  title: yup.string().min(8, 'El nombre debe tener mínimo 8 carácteres'),
  subarea_id: yup.string().required(requiredText),
});

export const ServiceSchema = yup.object().shape({
  name: yup.string().min(8, 'El nombre debe tener mínimo 8 carácteres'),
  subarea_id: yup.string().required(requiredText),
});

export const CopyEventSchema = yup.object().shape({
  title: yup.string().min(8, 'El nombre debe tener mínimo 8 carácteres'),
});

export const EventSubscriptionSchema = yup.object().shape({
  assistance: yup.boolean(),
  user_id: yup.number(),
});

export const ServiceBaseSchema = yup.object().shape({
  name: yup.string().min(8, 'El nombre debe tener mínimo 8 carácteres'),
  start_date: yup.mixed().required(requiredText),
  end_date: yup.mixed().required(requiredText),

  main_contact: yup.string().required(requiredText),
  main_contact_email: yup
    .string()
    .email(incorrectFormat)
    .required(requiredText),
  main_contact_phone: yup.string().test('phone', phoneMax15digits, val => {
    if (val.length > 0) return val.length <= 15;
    return true;
  }),

  provider_contact: yup.string().required(requiredText),
  provider_contact_email: yup
    .string()
    .email(incorrectFormat)
    .required(requiredText),
  provider_contact_phone: yup.string().test('phone', phoneMax15digits, val => {
    if (val.length > 0) return val.length <= 15;
    return true;
  }),

  max_capacity: yup
    .number()
    .typeError(requiredNumber)
    .min(0, 'El valor debe ser positivo')
    .max(999999999, maxNineDigits)
    .required(requiredNumber),
  subarea_id: yup.number(),

  unidentified: yup
    .number()
    .notRequired()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? 0 : value)),

  total_assistants: yup.string().notRequired(),
  registration_link: yup.string().url(incorrectFormat),
});

export const EventBaseSchema = yup.object().shape({
  title: yup.string().min(8, 'El nombre debe tener mínimo 8 carácteres'),
  start_date: yup.mixed().required(requiredText),
  end_date: yup.mixed().required(requiredText),
  closing_date: yup.mixed().required(requiredText),
  access_link: yup.string(),
  main_contact: yup.string().required(requiredText),
  main_contact_email: yup
    .string()
    .email(incorrectFormat)
    .required(requiredText),
  main_contact_phone: yup.string().test('main_contact_phone', phoneMax15digits, val => {
    if (val.length > 0) return val.length <= 15;
    return true;
  }),
  max_capacity: yup
    .number()
    .typeError(requiredNumber)
    .min(0, 'El valor debe ser positivo')
    .max(999999999, maxNineDigits)
    .required(requiredNumber),
  subarea_id: yup.number(),
  address: yup.string(),
  city: yup.string(),
  province: yup.string().required(requiredText),
  unidentified: yup
    .number()
    .notRequired()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? 0 : value)),
  total_number_attendees: yup.number().notRequired(),
  academic_year: yup
    .string()
    .nullable()
    .required(requiredText),
  total_assistants: yup.string().notRequired(),
  subscribed_number: yup.string().notRequired(),
  registered_number: yup.string().notRequired(),
  registration_link: yup.string().url(incorrectFormat),
  connection_link: yup.string().url(incorrectFormat),
});

export const ProjectSchema = yup.object().shape({
  name: yup
    .string()
    .min(8, 'El nombre debe tener mínimo 8 caracteres')
    .max(60, 'El nombre debe tener un máximo de 60 caracteres'),
  subarea_id: yup.string().required(requiredText),
  entity_id: yup.string().required(requiredText),
  description: yup.string().required(requiredText),
  entity_owner_id: yup.number(),
});

export const CopyProjectSchema = yup.object().shape({
  name: yup.string().min(8, requiredMinEightCharactersInName),
  description: yup.string().required(requiredText),
});

export const ProjectBaseSchema = yup.object().shape({
  name: yup.string().min(8, requiredMinEightCharactersInName),
  description: yup.string(),
});

export const NewDocumentSchema = yup.object().shape({
  description: yup.string().required(requiredText),
});

export const interviewSchema = yup.object().shape({
  interview_comment: yup.string().required(requiredText),
  motivation_comment: yup.string().required(requiredText),
  itinerary_comment: yup.string().required(requiredText),
  capacities_comment: yup.string().required(requiredText),
  capacities_assessment: yup.string().matches(/^[1-5]{1}$/, requiredText),
  interview_assessment: yup.string().matches(/^[1-5]{1}$/, requiredText),
  itinerary_assessment: yup.string().matches(/^[1-5]{1}$/, requiredText),
  motivation_assessment: yup.string().matches(/^[1-5]{1}$/, requiredText),
});

export const validations = {
  ...emailSchema,
  required: yup.string().required(requiredText),
  optional: yup.string(),
};

export const confirmationSchema = (validateIBAN: boolean) =>
  yup.object().shape({
    enrollment_confirm: yup
      .number()
      .max(999999.99, oneMillionMax)
      .typeError(requiredNumber),
    monthly_price_confirm: yup
      .number()
      .max(999999.99, oneMillionMax)
      .typeError(requiredNumber),
    materials_confirm: yup
      .number()
      .max(999999.99, oneMillionMax)
      .typeError(requiredNumber),
    transport_price_confirm: yup
      .number()
      .max(999999.99, oneMillionMax)
      .typeError(requiredNumber),
    other_costs_confirm: yup
      .number()
      .max(999999.99, oneMillionMax)
      .typeError(requiredNumber),
    scholarship_academy_confirm: yup
      .string()
      .typeError(requiredText)
      .required(requiredText),
    student_account_number: validateIBAN
      ? yup
          .string()
          .required(requiredText)
          .matches(
            /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/g,
            tErrorKey('Debes introducir un IBAN válido'),
          )
      : yup.string(),
  });

export const HistorySchema = yup.object().shape({
  scholarship_years: yup.number(),
  status: yup.boolean(),
  top: yup.boolean(),
  mentor: yup.boolean(),
  mentorships: yup.boolean(),
});

export type ValidationType = keyof typeof validations;

export const NewMessageSchema = yup.object().shape({
  new_message: yup.string().required(requiredText),
  channel: yup.string(),
});

export const RejectScholarshipModalSchema = yup.object().shape({
  reject_reason_id: yup.number().typeError(requiredReason),
});

export const RefuseEntityModalSchema = yup.object().shape({
  message: yup.string().required(requiredMessage),
});

export const ChangeScholarshipStateModalSchema = yup.object().shape({
  next_state_code: yup.string().required(requiredText),
});

export const SkipInterviewModalSchema = yup.object().shape({
  needs_interview: yup.string().required(requiredOneOption),
  interview_entity: yup.string().required(requiredText),
});

export const ApprovalModalSchema = yup.object().shape({
  message: yup.string(),
});

export const aboutUsEditionSchema = yup.object().shape({
  ...nameSchema,
  description: yup.string().required(requiredText),
  about_us_type_id: yup.number().required(requiredText),
});

export const ProjectDataFormSchema = yup.object().shape({
  ...nameSchema,
  description: yup.string().required(requiredText),
  zone: yup.string().required(requiredText),
  country: yup.string().required(requiredText),
  region: yup.string().required(requiredText),
});

export const EntitiesDataFormSchema = yup.object().shape({
  name_entity: yup.string().required(requiredText),
  name_project: yup.string().required(requiredText),
});

export const FNCDataFormSchema = yup.object().shape({
  area_name: yup.string().required(requiredText),
  sub_area_name: yup.string().required(requiredText),
  id_responsible: yup.string().required(requiredText),
  id_member: yup.string().required(requiredText),
});

export const EconomicsDataFormSchema = yup.object().shape({
  total_cost: yup.number().required(requiredText),
  grant_request: yup.number().required(requiredText),
  grant_request_percent: yup.number().required(requiredText),
});

export const AnalisisFNCSchema = yup.object().shape({
  fnc_analisys_dafo_1: yup.string().max(2000, maxTwoThousandCharacters),
  fnc_analisys_dafo_2: yup.string().max(2000, maxTwoThousandCharacters),
  fnc_analisys_dafo_3: yup.string().max(2000, maxTwoThousandCharacters),
  fnc_analisys_dafo_4: yup.string().max(2000, maxTwoThousandCharacters),
  fnc_support: yup.string().max(2000, maxTwoThousandCharacters),
});
export const AccountingDataSchema = yup.object().shape({
  expense_account: yup
    .string()
    .nullable()
    .max(9, max_length),
  provider_code: yup
    .string()
    .nullable()
    .max(6, max_length),
  payable_account: yup
    .string()
    .nullable()
    .max(9, max_length),
  supplier_code: yup
    .string()
    .nullable()
    .max(6, max_length),
  supplier_payable_account: yup
    .string()
    .nullable()
    .max(9, max_length),
  retention_code: yup.string().nullable(),
  transaction_code: yup.string().nullable(),
  payment_type_code: yup.string().nullable(),
  remaining_payment: yup.number().typeError(requiredNumber),
  needs_347: yup.boolean(),
});

export const EconomicDataEntitySchema = yup.object().shape({
  economic_data_external_accounting_audit_text: yup.string().max(2000, maxTwoThousandCharacters),
  economic_data_real_state_text: yup.string().max(2000, maxTwoThousandCharacters),
  economic_data_salary_table_text: yup.string().max(2000, maxTwoThousandCharacters),
  economic_data_salary_retributions_text: yup.string().max(2000, maxTwoThousandCharacters),
});

export const InstitutionalCapacityEntitySchema = yup.object().shape({
  institutional_capacity_members: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_strategy_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_director_plan_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_territorial_implantation: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_social_base: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_evolution: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_curriculum_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_work_organization_chart_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_laboral_policy_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_laboral_external_policy_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_internal_policy_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_sexual_harassment_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_internal_formation_plan_text: yup.string().max(2000, maxTwoThousandCharacters),
  institutional_capacity_volunteering_text: yup.string().max(2000, maxTwoThousandCharacters),
});

export const JointEntitySchema = yup.object().shape({
  joint_belongs_other_organization_text: yup.string().max(2000, maxTwoThousandCharacters),
  joint_belongs_other_next_text: yup.string().max(2000, maxTwoThousandCharacters),
  joint_relation_other_organization: yup.string().max(2000, maxTwoThousandCharacters),
  joint_relation_internatinal_institutions: yup.string().max(2000, maxTwoThousandCharacters),
  joint_relation_other_institutions: yup.string().max(2000, maxTwoThousandCharacters),
  joint_educational_collaboration: yup.string().max(2000, maxTwoThousandCharacters),
});

export const ChangeEntityEducatorSchema = yup.object().shape({
  current_educator_id: yup.number().required(requiredText),
  course: yup.string().required(requiredText),
  new_educator_id: yup.number().required(requiredText),
});

export const ConfigScholarshipSchema = yup.object().shape({
  allow_scholarship_year_renew: yup.string(),
  study_center: yup.bool(),
  scholarship_academy_id: yup
    .string()
    .when('study_center', {
      is: study_center => study_center,
      then: yup.string().required(tErrorKey(requiredText)),
    })
    .nullable(),
});

/*eslint-disable no-self-compare */
export const KPISSchema = yup.object().shape({
  description_es: yup.string().required(requiredText),
  section: yup.string().required(requiredText),
  custom_field_type: yup.string().required(requiredText),
  code: yup.string(),
  area_ids: yup.array(),
  subarea_ids: yup.array(),
  subareaInternalProjectType_ids: yup.array(),
  mandatory: yup.boolean(),
  position: yup
    .string()
    .max(100, maxOneHundredCharacters)
    .nullable()
    .matches(/^\d+(\.\d{1,2})?$/, requiredNumber),
  size: yup.string(),
  kpi_indicator_type_id: yup.string() /* .required(requiredText) */,
  field_data_types: yup.string().required(requiredText),
  origin_custom_field_id: yup
    .number()
    .nullable(true)
    .transform((_, val) => (val === val ? val : null)),
  origin_condition: yup.string().when('origin_custom_field_id', {
    is: origin_custom_field_id => origin_custom_field_id && origin_custom_field_id !== 0,
    then: yup.string().required(tErrorKey(requiredText)),
  }),
  origin_value: yup
    .string()
    .nullable(true)
    .when(['origin_custom_field_id', 'origin_condition'], {
      is: (origin_custom_field_id, origin_condition) =>
        origin_custom_field_id !== 0 && origin_condition === 'Es igual a',
      then: yup.string().required(tErrorKey(requiredText)),
    }),
});

export const TemplateSchema = yup.object().shape({
  title: yup
    .string()
    .required(requiredText)
    .max(200, maxTwoHundredCharacters),
  message: yup.string().required(requiredText),
  area_id: yup.string().required(requiredText),
});

export const AreaSchema = yup.object().shape({
  idArea: yup.number().required(requiredText),
});

export const SubareaSchema = yup.object().shape({
  idSubArea: yup.number().required(requiredText),
});

export const areaRoleSchema = yup.object().shape({
  area: yup.string().required(requiredText),
  rol: yup.string(),
});

export const TypeSchema = yup.object().shape({
  internal_project_type: yup.string().required(requiredText),
});

export const PaymentModalSchema = yup.object().shape({
  agreement_number: yup
    .string()
    .nullable()
    .required(requiredText),
  document_number: yup
    .string()
    .nullable()
    .required(requiredText),
  concept: yup
    .string()
    .nullable()
    .required(requiredText),
  account_number: yup
    .string()
    .nullable()
    .required(requiredText),
  provider_code: yup
    .number()
    .nullable()
    .required(requiredText),
  name: yup
    .string()
    .nullable()
    .required(requiredText),
  center_cost_area_code: yup
    .string()
    .nullable()
    .required(requiredText),
  amount: yup
    .number()
    .nullable()
    .required(requiredText),
  center_cost_channel_code: yup
    .string()
    .nullable()
    .required(requiredText),
  center_cost_delegation_code: yup
    .string()
    .nullable()
    .required(requiredText),
  payment_date: yup
    .string()
    .nullable()
    .required(requiredText),
  description_project: yup
    .string()
    .nullable()
    .required(requiredText),
});

export const TaxRetentionSchema = yup.object().shape({
  code: yup
    .string()
    .max(3, requiredMaxThreeCharacters)
    .typeError(requiredNumber)
    .required(requiredText),
  name: yup
    .string()
    .max(60, expenceAccountDescriptionMaxLength)
    .required(requiredText),
  percentage: yup
    .number()
    .typeError(requiredNumber)
    .max(100, incorrectFormat)
    .min(0, incorrectFormat)
    .required(requiredNumber),
  tax_type: yup.string().required(requiredText),
  payment_account: yup
    .string()
    .max(35, accountingNoteDescriptionMaxLength)
    .required(requiredText),
  transaction_code: yup
    .string()
    .max(3)
    .required(requiredText),
});

export const TransactionCodeSchema = yup.object().shape({
  code: yup
    .number()
    .typeError(requiredNumber)
    .max(999, requiredMaxThreeNumbers)
    .required(requiredNumber),
  name: yup
    .string()
    .max(60, expenceAccountDescriptionMaxLength)
    .required(requiredText),
  invoice_type: yup
    .string()
    .nullable()
    .max(10, maxTenCharacters),
  default_op_key: yup
    .string()
    .max(1, requiredMaxThreeNumbers)
    .required(requiredText),
  serial: yup
    .string()
    .max(10, maxTenCharacters)
    .required(requiredText),
});

export const OperationKeySchema = yup.object().shape({
  op_key: yup.string().matches(/^[A-Z0-9_]$/, requiredDigitsOrLetters),
  description: yup
    .string()
    .max(60, expenceAccountDescriptionMaxLength)
    .required(requiredText),
});

export const CostCenterSchema = yup.object().shape({
  code: yup
    .string()
    .max(10, accountingCodeMaxLength)
    .required(requiredText),
  name: yup
    .string()
    .max(255, accountingDescriptionMaxLength)
    .required(requiredText),
});

export const FilterSchema = yup.object().shape({
  code: yup.string(),
  name: yup.string(),
});

export const ExpenseAccountsSchema = yup.object().shape({
  code: yup.string().required(requiredText),
  description: yup
    .string()
    .max(60, expenceAccountDescriptionMaxLength)
    .required(requiredText),
  active: yup.boolean(),
});

export const AccountSettingsSchema = yup.object().shape({
  payment_control_day: yup
    .number()
    .typeError(requiredNumber)
    .min(1, requiredNumbersFrame)
    .max(31, requiredNumbersFrame)
    .required(requiredNumber),
  payment_day: yup
    .number()
    .min(1, requiredNumbersFrame)
    .max(31, requiredNumbersFrame)
    .required(requiredNumber),
});

export const AccoutingDefaultCenterCostSchema = yup.object().shape({
  ent_center_cost_channel_code: yup.string(),
  ent_center_cost_delegation_code: yup.string(),
  ent_center_cost_area_code: yup.string(),
  ent_center_cost_project_code: yup.string(),
  ins_center_cost_channel_code: yup.string(),
  ins_center_cost_delegation_code: yup.string(),
  ins_center_cost_area_code: yup.string(),
  ins_center_cost_project_code: yup.string(),
  adm_man_center_cost_channel_code: yup.string(),
  adm_man_center_cost_delegation_code: yup.string(),
  adm_man_center_cost_area_code: yup.string(),
  adm_man_center_cost_project_code: yup.string(),
  allow_create_entity_scholarships: yup.boolean(),
  allow_create_institute_scholarships: yup.boolean(),
  allow_renew_entity_scholarships: yup.boolean(),
  allow_renew_institute_scholarships: yup.boolean(),
  start_follow_up_day: yup.number(),
  start_follow_up_month: yup.number(),
  start_course_day: yup.number(),
  start_course_month: yup.number(),
});

const InvoiceSchemaRules = {
  reference_number: yup
    .string()
    .max(20, maxTwentyCharacters)
    .nullable()
    .required(requiredText),
  invoice_date: yup.string().nullable(),
  contract_number: yup.string().required(requiredText),
  description: yup
    .string()
    .max(100, maxOneHundredCharacters)
    .required(requiredText),
  total_amount: yup
    .number()
    .typeError(requiredNumber)
    .max(999999999, maxNineDigits),
  payment_type_code: yup.string(),
  //file_name: yup.string().required(requiredText),
};

const InvoiceFncSchemaRules = {
  center_cost_channel_code: yup.string().required(requiredText),
  center_cost_delegation_code: yup.string().required(requiredText),
  center_cost_area_code: yup.string().required(requiredText),
  center_cost_project_code: yup.string(),
};

export const InvoiceSchema = yup.object().shape({
  ...InvoiceSchemaRules,
});

export const InvoiceFncSchema = yup.object().shape({
  ...InvoiceSchemaRules,
  ...InvoiceFncSchemaRules,
  account_number: yup.string(),
});

export const PendingInvoiceSchema = (isVisaProvider: boolean) =>
  yup.object().shape({
    ...InvoiceFncSchemaRules,
    account_number: yup.string().when([], {
      is: () => isVisaProvider,
      then: yup.string(),
      otherwise: yup.string().required(requiredText),
    }),
  });

export const ModifyInvoiceSchema = yup.object().shape({
  due_date: yup.string().nullable(),
  description: yup.string().max(255, accountingNoteDescriptionMaxLength),
  ...InvoiceFncSchemaRules,
});

const InvoiceDetailsSchemaRules = {
  id: yup.number(),
  invoice_id: yup.number(),
  description: yup
    .string()
    .max(30, accountingNoteDescriptionMaxLength)
    .required(requiredText),
  expense_account: yup.string(),
  center_cost_channel_code: yup.string(),
  center_cost_delegation_code: yup.string(),
  center_cost_area_code: yup.string(),
  center_cost_project_code: yup.string(),
};

const bodyInvoiceDetailsSchemaRules = {
  base_amount: yup
    .number()
    .typeError(requiredNumber)
    .max(999999999, maxNineDigits)
    .required(requiredNumber),
  tax_retention_code: yup.string().required(requiredText),
  tax_retention_amount: yup.number(),
  tax_retention_percentage: yup.number(),
  order: yup.number(),
};

export const AllInvoiceDetailsSchema = yup.object().shape({
  ...InvoiceDetailsSchemaRules,
  ...bodyInvoiceDetailsSchemaRules,
});

export const BaseInvoiceDetailsSchema = yup.object().shape({
  ...InvoiceDetailsSchemaRules,
});

export const AcademySchema = yup.object().shape({
  alias: yup
    .string()
    .max(30, accountingNoteDescriptionMaxLength)
    .required(requiredText),
  iban: yup
    .string()
    .required(requiredText)
    .matches(
      /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/g,
      tErrorKey('Debes introducir un IBAN válido'),
    ),
  file_id: yup
    .number()
    .required(requiredText)
    .typeError(requiredText),
});

export const InternalProjectSchema = yup.object().shape({
  name: yup.string().required(requiredText),
  subarea_internal_project_type_id: yup.string().required(requiredText),
  select_id: yup
    .number()
    .typeError(requiredText)
    .required(requiredText),
  internal_project_template_id: yup
    .number()
    .typeError(requiredText)
    .required(requiredText),
});

export const InformationSchema = yup.object().shape({
  start_date: yup.string().typeError(requiredText),
  end_date: yup.string().typeError(requiredText),
});

export const InternalProjectDailyFollowSchema = yup.object().shape({
  follow_date: yup.string().typeError(requiredText),
  internal_project_modalities_id: yup
    .number()
    .typeError(requiredText)
    .required(requiredText),
  internal_project_typologies_id: yup
    .number()
    .typeError(requiredText)
    .required(requiredText),
  professionals_involveds: yup
    .array()
    .of(yup.number().typeError(requiredText))
    .required(requiredText),
  participants: yup
    .number()
    .typeError(requiredText)
    .required(requiredText),
  note: yup.string(),
  course: yup.string(),
});

export const InternalProjectNewEnrollmentSchema = yup.object().shape({
  name: yup
    .string()
    .max(15, max_length)
    .required(requiredText),
  surname: yup
    .string()
    .max(15, max_length)
    .required(requiredText),
  surname2: yup.string().max(15, max_length),
  document_type: yup.string().required(requiredText),
  document_number: yup
    .string()
    .max(15, max_length)
    .required(requiredText),
  ...emailSchema,
});

export const InternalProjectTemplateSchema = yup.object().shape({
  template_name: yup.string().required(requiredText),
  subarea_id: yup
    .number()
    .required(requiredText)
    .typeError(requiredText),
  subarea_internal_project_type_id: yup
    .string()
    .required(requiredText)
    .typeError(requiredText),
});

export const InternalProjectTemplateFollowUpSchema = yup.object().shape({
  name: yup.string().required(requiredText),
  section: yup.string().required(requiredText),
});

export const NewParticipantSchema = yup.object().shape({
  user_name: yup.string().required(requiredText),
  particularities: yup
    .array()
    .required(requiredText)
    .of(
      yup
        .string()
        .oneOf(Object.values(ParticipantParticularitiesTypeEnum), 'Una de las particularidades no es válida.'),
    ),
  lopd: yup.boolean(),
  additional_info: yup.string().when('particularities', {
    is: particularities => particularities.includes('Otro'),
    then: yup.string().required(requiredText),
    otherwise: yup.string(),
  }),
});

export const SurveyTransversalCompetencesSchema = yup.object().shape({
  question_A: yup.string().matches(/[1-4]/, requiredText),
  question_B: yup.string().matches(/[1-4]/, requiredText),
  question_C: yup.string().matches(/[1-4]/, requiredText),
  question_D: yup.string().matches(/[1-4]/, requiredText),
  question_E: yup.string().matches(/[1-4]/, requiredText),
  question_F: yup.string().matches(/[1-4]/, requiredText),
  question_G: yup.string().matches(/[1-4]/, requiredText),
  question_H: yup.string().matches(/[1-4]/, requiredText),
  question_I: yup.string().matches(/[1-4]/, requiredText),
  question_J: yup.string().matches(/[1-4]/, requiredText),
  question_K: yup.string().matches(/[1-4]/, requiredText),
  question_L: yup.string().matches(/[1-4]/, requiredText),
  question_M: yup.string().matches(/[1-4]/, requiredText),
  question_N: yup.string().matches(/[1-4]/, requiredText),
  question_O: yup.string().matches(/[1-4]/, requiredText),
  question_P: yup.string().matches(/[1-4]/, requiredText),
  question_Q: yup.string().matches(/[1-4]/, requiredText),
  question_R: yup.string().matches(/[1-4]/, requiredText),
  question_S: yup.string().matches(/[1-4]/, requiredText),
  question_T: yup.string().matches(/[1-4]/, requiredText),
  question_U: yup.string().matches(/[1-4]/, requiredText),
});

export const AddNewScholarshipModalSchema = yup.object().shape({
  ...emailSchema,
  phone: yup
    .string()
    .concat(phoneSchema.phone)
    .required(requiredText),
});
