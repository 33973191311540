import React from 'react';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';
import { ServiceData } from '../../../redux/service/definitions';

interface SubmenuEventProps {
  current_section: string;
  service: ServiceData;
  service_id?: string;
}

const SubmenuService: React.FC<SubmenuEventProps> = ({ current_section, service, service_id }) => {
  const menuService: SubmenuItem[] = [
    {
      title: tKey('Datos Globales'),
      type: 'route',
      route: `/servicios/detalle/${service.id || service_id}/datos_basicos`,
      selected: 'datos_basicos' === current_section,
    },
    {
      title: tKey('Listado Solicitantes'),
      type: 'route',
      route: `/servicios/detalle/${service.id || service_id}/solicitantes`,
      selected: 'listado_solicitantes' === current_section,
    },
  ];

  return <Submenu titleMenu={tKey('Datos del servicio')} optionsMenu={menuService} defaultOpen />;
};

export default SubmenuService;
