import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { tKey } from '../../helpers/translate';
import styles from './styleGuideNC.module.scss';

const StyleGuideNC: React.FC = () => (
  <div className={styles.container}>
    <h1>{tKey('Guía de estilos')}</h1>
    <h2>{tKey('Botones')}</h2>
    <Button variant="contained" color="primary" disableElevation>
      {tKey('Primary')}
    </Button>
    <Button variant="contained" color="primary" disableElevation disabled>
      {tKey('Primary Disable')}
    </Button>
    <Button variant="contained" color="secondary" disableElevation>
      {tKey('Secondary')}
    </Button>
    <Button variant="contained" color="secondary" disableElevation disabled>
      {tKey('Secondary disable')}
    </Button>
    <Button color="primary">Primary Text</Button>
    <Button color="primary" disabled>
      {tKey('Primary Text Disable')}
    </Button>
    <Button variant="contained" color="primary" disableElevation startIcon={<DeleteIcon />}>
      {tKey('Icon')}
    </Button>
    <Button variant="contained" color="secondary" disableElevation startIcon={<DeleteIcon />}>
      {tKey('Icon Secondary')}
    </Button>
    <Button color="primary" startIcon={<DeleteIcon />}>
      {tKey('Icon Text')}
    </Button>
    <Button color="primary" startIcon={<DeleteIcon />} disabled>
      {tKey('Icon Text Disable')}
    </Button>
    <hr />
    <ul>
      <li>
        {tKey('StyleGuideList1')}{' '}
        <Button variant="contained" color="primary" disableElevation>
          {tKey('Primary')}
        </Button>
      </li>
      <li>
        {tKey('StyleGuideList2')}{' '}
        <Button variant="contained" color="secondary" disableElevation>
          {tKey('Secondary')}
        </Button>
      </li>
      <li>
        {tKey('StyleGuideList3')}
        <ul>
          <li>{tKey('StyleGuideList4')}</li>
          <li>{tKey('StyleGuideList5')}</li>
        </ul>
      </li>
    </ul>
  </div>
);

export default StyleGuideNC;
