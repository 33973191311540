import React, { ReactElement } from 'react';
import { FieldErrors, FieldName, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../../helpers/customHooks/useIsRequired';
import TextInput from '../TextInput';
import { BaseTextInputProps } from '../types';

interface ValidatedTextInput<T extends FieldValues> extends BaseTextInputProps {
  errors: FieldErrors<T>;
  name: FieldName<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  rows?: string;
  rowsMax?: string;
  validator?: boolean;
  visible?: boolean;
}

const ValidatedTextInput = <T extends FieldValues>({
  name,
  errors,
  schema,
  label,
  visible,
  rows = '1',
  rowsMax = '1',
  validator = false,
  ...props
}: ValidatedTextInput<T>): ReactElement => {
  const error = errors[name];

  const required = useIsRequired(name, schema);

  return (
    <TextInput
      visible={visible}
      name={name}
      errors={error}
      validator={validator}
      rows={rows}
      rowsMax={rowsMax}
      // We change the label directly intead of passing the required prop
      // because we don't want Material's validation stuff to kick in.
      label={
        label && required && !validator ? (
          <React.Fragment>
            {label} {'\u00a0*'}
          </React.Fragment>
        ) : (
          label
        )
      }
      {...props}
    />
  );
};

export default ValidatedTextInput;
