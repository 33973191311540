import { MenuItem } from '@material-ui/core';
import DragIndicator from '@material-ui/icons/Apps';
import React, { Fragment, ReactElement } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import * as yup from 'yup';
import { tProjectKey } from '../../../helpers/translate';
import webStyle from '../../../helpers/webStyle';
import { CodeNamePair } from '../../../redux/common/definitions';
import { SizeConfiguratorData } from '../../../redux/kpis/definitions';
import { ConfiguratorData } from '../../../redux/project/definitions';
import SelectController from '../../Select/SelectController';
import style from './dragAndDropCustomFields.module.scss';

interface DraggableElementProps<T extends FieldValues> {
  field: ConfiguratorData;
  name: {
    size?: string;
    custom_field_type?: string;
    field_data_types?: string;
    mandatory?: string;
  };
  customFields_sizes: CodeNamePair[];
  readonly?: boolean;
  control: Control<T>;
  errors: FieldErrors<T>;
  setFieldValue: (value: SizeConfiguratorData) => void;
}

const DraggableElement = <T extends FieldValues>({
  field,
  name,
  control,
  readonly,
  customFields_sizes,
  errors,
  setFieldValue,
}: DraggableElementProps<T>): ReactElement => {
  return (
    <div className={style.containerDraggableElement}>
      <Fragment>
        <div className={style[`rightContainer_${webStyle}`]}>
          <DragIndicator />
        </div>
        <div className={style.leftContainer}>
          <div className={style.descSizeContainer}>
            <div className={style[`description_${webStyle}`]}>{field.description}</div>
          </div>
          <div className={style[`otherData_${webStyle}`]}>
            <SelectController
              label={tProjectKey('Tamaño')}
              name={name.size || ''}
              defaultValue={field.size}
              size="35"
              fullWidth
              disabled={readonly}
              control={control}
              errors={errors}
              schema={yup.object().shape({})}
              onClick={(evt: React.MouseEvent<HTMLInputElement>) => {
                const value = (evt.target as HTMLInputElement).value;
                if (value) setFieldValue(value as SizeConfiguratorData);
              }}
            >
              {customFields_sizes.map((type: CodeNamePair) => (
                <MenuItem key={type.code} value={type.code}>
                  {type.name || '-'}
                </MenuItem>
              ))}
            </SelectController>
            <div className={style.otherData_wrapper}>
              <div className={style.singleOtherData}>
                <span className={style.otherDataTitle}>{tProjectKey('Tipo de Dato')}:</span>
                <span className={style.resultData}>{field.field_data_types}</span>
              </div>
              <div className={style.singleOtherData}>
                <span className={style.otherDataTitle}>{tProjectKey('Ámbito de Actuación')}:</span>
                <span className={style.resultData}>{field.custom_field_type}</span>
              </div>
              <div className={style.singleOtherData}>
                <span className={style.otherDataTitle}>{tProjectKey('Requerido')}:</span>
                <span className={style.resultData}>{field.mandatory ? tProjectKey('Si') : tProjectKey('No')}</span>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default DraggableElement;
