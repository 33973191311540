import { Button, ButtonProps, InputLabel } from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import ButtonGroup from '../../../components/ButtonGroup/ButtonGroup';
import CompoundComponent from '../../../components/CompoundComponent/CompoundComponent';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInput from '../../../components/Inputs/TextInput';
import Loading from '../../../components/Loading/Loading';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import { documentationFilter } from '../../../helpers/docFilter';
import { tFormKey, tKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { EntityInfo } from '../../../redux/entity/definitions';
import { InstitutionalCapacityEntitySchema } from '../../../validations/formSchema';
import styles from '../entityData.module.scss';
import projectStyles from './projectForm.module.scss';

interface InstitutionalCapacityProps {
  leftButtons?: boolean;
  documentation: DocumentationItem[];
  disabled?: boolean;
  id: number;
  entity: EntityInfo;
  buttonActionSave: ButtonProps;
  loading?: boolean;
  goBack?: () => void;
  onSave: (data: EntityInfo) => void;
  upload: (document_id: number, data: File, entity_id: any, force_add?: boolean | false) => void;
  download: (file_id: number, name: string, entityId: number) => void;
  remove: (file_id: number, entityId: number) => void;
  getFile: (file_id: number, name: string, entityId: number) => void;
  addDoc?: (document: File, description: string, genericId?: any, documentationId?: number) => void;
  setData: (key: string, value: string | number) => void;
}

const InstitutionalCapacity: React.FC<InstitutionalCapacityProps> = ({
  leftButtons,
  documentation,
  id,
  entity,
  buttonActionSave,
  disabled,
  loading,
  onSave,
  upload,
  download,
  remove,
  getFile,
  goBack,
  addDoc,
  setData,
}) => {
  const actions = ['checked', 'upload', 'download', 'view', 'remove'];
  const { register, handleSubmit, errors, control } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InstitutionalCapacityEntitySchema,
  });

  useButtonAction(buttonActionSave, handleSubmit(onSave));

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <form className={styles.form}>
          <FormContainer space title={tKey('Capacidad Institucional')}>
            <div className={projectStyles.compoundItem}>
              <InputLabel>{tFormKey('Funcionamiento Institución')}</InputLabel>
              <TextInput
                label=""
                register={register}
                name="institutional_capacity_operation"
                size="100"
                disabled={disabled}
                rows="5"
                defaultValue={entity.institutional_capacity_operation}
                rowsMax="5"
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <FncAddDocuments
              documents={documentationFilter(documentation, ['EFO'])}
              actions={actions}
              upload={upload}
              disabled={disabled}
              genericId={id}
              download={download}
              remove={remove}
              getFile={getFile}
              subtitle={tFormKey('Estructura Organizativa')}
            />
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('Organo Directivo')}</InputLabel>
              <TextInput
                label=""
                errors={errors.institutional_capacity_members}
                register={register}
                name="institutional_capacity_members"
                disabled={disabled}
                size="100"
                defaultValue={entity.institutional_capacity_members}
                rows="5"
                rowsMax="5"
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('Estrategia')}
                disabled={disabled}
                errors={errors}
                register={register}
                genericId={id}
                radioButtonsValue={entity.institutional_capacity_strategy}
                textAreaValue={entity.institutional_capacity_strategy_text}
                radioButtonsName="institutional_capacity_strategy"
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_strategy_text"
                docs={documentationFilter(documentation, ['EEI'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('Planes directores/operativos')}
                disabled={disabled}
                register={register}
                errors={errors}
                genericId={id}
                radioButtonsValue={entity.institutional_capacity_director_plan}
                textAreaValue={entity.institutional_capacity_director_plan_text}
                radioButtonsName="institutional_capacity_director_plan"
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_director_plan_text"
                docs={documentationFilter(documentation, ['EPD'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('Implantación territorial')}</InputLabel>
              <TextInput
                disabled={disabled}
                label=""
                errors={errors.institutional_capacity_territorial_implantation}
                register={register}
                name="institutional_capacity_territorial_implantation"
                size="100"
                defaultValue={entity.institutional_capacity_territorial_implantation}
                rows="5"
                rowsMax="5"
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('Descripción Base Social')}</InputLabel>
              <TextInput
                disabled={disabled}
                label=""
                errors={errors.institutional_capacity_social_base}
                register={register}
                name="institutional_capacity_social_base"
                defaultValue={entity.institutional_capacity_social_base}
                size="100"
                rows="5"
                rowsMax="5"
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className={projectStyles.compound}>
              <InputLabel>{tFormKey('Resuma la aparición y la evolución de su entidad.')}</InputLabel>
              <TextInput
                disabled={disabled}
                label=""
                register={register}
                errors={errors.institutional_capacity_evolution}
                defaultValue={entity.institutional_capacity_evolution}
                name="institutional_capacity_evolution"
                size="100"
                rows="5"
                rowsMax="5"
                multiline
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  setData(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className={projectStyles.compoundDoc}>
              <InputLabel>{tFormKey('Memorias actividades')}</InputLabel>
            </div>

            <FncAddDocuments
              upload={upload}
              disabled={disabled}
              genericId={id}
              download={download}
              remove={remove}
              getFile={getFile}
              documents={documentationFilter(documentation, ['EMM'])}
              actions={actions}
            />

            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('CV Institucional')}
                disabled={disabled}
                register={register}
                genericId={id}
                errors={errors}
                radioButtonsName="institutional_capacity_curriculum"
                radioButtonsValue={entity.institutional_capacity_curriculum}
                textAreaValue={entity.institutional_capacity_curriculum_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_curriculum_text"
                docs={documentationFilter(documentation, ['ECI'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('Organigrama Laboral')}
                register={register}
                disabled={disabled}
                errors={errors}
                genericId={id}
                radioButtonsName="institutional_capacity_work_organization_chart"
                radioButtonsValue={entity.institutional_capacity_work_organization_chart}
                textAreaValue={entity.institutional_capacity_work_organization_chart_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_work_organization_chart_text"
                docs={documentationFilter(documentation, ['EOL'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('¿Dispone de política laboral y de reclutamiento?')}
                register={register}
                errors={errors}
                disabled={disabled}
                genericId={id}
                radioButtonsName="institutional_capacity_laboral_policy"
                radioButtonsValue={entity.institutional_capacity_laboral_policy}
                textAreaValue={entity.institutional_capacity_laboral_policy_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_laboral_policy_text"
                docs={documentationFilter(documentation, ['EPL'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('compras y contratación externa')}
                register={register}
                disabled={disabled}
                errors={errors}
                genericId={id}
                radioButtonsName="institutional_capacity_laboral_external_policy"
                radioButtonsValue={entity.institutional_capacity_laboral_external_policy}
                textAreaValue={entity.institutional_capacity_laboral_external_policy_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_laboral_external_policy_text"
                docs={documentationFilter(documentation, ['EPC'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('Manual Procedimientos')}
                disabled={disabled}
                register={register}
                errors={errors}
                genericId={id}
                radioButtonsName="institutional_capacity_internal_policy"
                radioButtonsValue={entity.institutional_capacity_internal_policy}
                textAreaValue={entity.institutional_capacity_internal_policy_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_internal_policy_text"
                docs={documentationFilter(documentation, ['EMP'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('Política Género')}
                disabled={disabled}
                register={register}
                genericId={id}
                errors={errors}
                radioButtonsName="institutional_capacity_sexual_harassment"
                radioButtonsValue={entity.institutional_capacity_sexual_harassment}
                textAreaValue={entity.institutional_capacity_sexual_harassment_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_sexual_harassment_text"
                docs={documentationFilter(documentation, ['EPI'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('programa interno o facilidades')}
                register={register}
                errors={errors}
                disabled={disabled}
                genericId={id}
                radioButtonsName="institutional_capacity_internal_formation_plan"
                radioButtonsValue={entity.institutional_capacity_internal_formation_plan}
                textAreaValue={entity.institutional_capacity_internal_formation_plan_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_internal_formation_plan_text"
                docs={documentationFilter(documentation, ['EPF'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
            <div className={projectStyles.container}>
              <CompoundComponent
                control={control}
                schema={InstitutionalCapacityEntitySchema}
                label={tFormKey('¿Trabaja con voluntariado habitualmente?')}
                register={register}
                disabled={disabled}
                errors={errors}
                genericId={id}
                radioButtonsName="institutional_capacity_volunteering"
                radioButtonsValue={entity.institutional_capacity_volunteering}
                textAreaValue={entity.institutional_capacity_volunteering_text}
                textAreaLabel={tFormKey('Descripción')}
                textAreaName="institutional_capacity_volunteering_text"
                docs={documentationFilter(documentation, ['EPV'])}
                actions={actions}
                uploadDocument={upload}
                downloadDocument={download}
                deleteDocument={remove}
                getFile={getFile}
                addDoc={addDoc}
                setData={setData}
              />
            </div>
          </FormContainer>
          {!leftButtons && (
            <div className={styles.btns}>
              <ButtonGroup>
                <Button variant="contained" startIcon={<ArrowBack />} color="primary" fullWidth onClick={goBack}>
                  {tKey('VOLVER')}
                </Button>
                <Button
                  variant="contained"
                  startIcon={<Save />}
                  color="primary"
                  fullWidth
                  onClick={handleSubmit(onSave)}
                >
                  {tKey('Guardar')}
                </Button>
              </ButtonGroup>
            </div>
          )}
        </form>
      )}
    </>
  );
};

export default InstitutionalCapacity;
