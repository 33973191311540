import { Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIBadPlatformError, APIError } from '../../../api/api';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import webStyle from '../../../helpers/webStyle';
import { login } from '../../../redux/auth/actions';
import { AuthDispatch } from '../../../redux/auth/definitions';
import { selectError, selectIsLoading, selectToken } from '../../../redux/auth/selectors';
import { AppState } from '../../../redux/root-reducer';
import { LoginSchema } from '../../../validations/formSchema';
import Feedback from '../../Feedback/Feedback';
import TextInputController from '../../Inputs/TextInputController/TextInputController';
import Loading from '../../Loading/Loading';
import styles from './loginForm.module.scss';

export interface LoginFields {
  email: string;
  password: string;
}

interface LoginFormProps {
  error: APIError | null;
  login: (data: LoginFields) => void;
  loading: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({ login, loading, error }) => {
  const { handleSubmit, errors, clearError, control } = useForm<LoginFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: LoginSchema,
  });

  const onSubmit = (data: LoginFields) => {
    clearError();
    login(data);
  };

  let feedback;
  if (error && error instanceof APIBadPlatformError) {
    let urlOnclick;
    if (error.message.includes(tErrorKey('Haga click aquí'))) {
      urlOnclick = error.url;
    }
    urlOnclick = error.alternativePlatform;

    feedback = (
      <Feedback
        backroundColor="red"
        text={error.message}
        color="white"
        urlOnclick={urlOnclick}
        icon={<ErrorOutlineIcon />}
      />
    );
  }
  if (error) {
    let urlOnclick;
    const message = (error as Error).message;
    if (error instanceof APIBadPlatformError) {
      if (error.message.includes(tErrorKey('Haga click aquí'))) {
        urlOnclick = error.url;
      }
      if ('alternativePlatform' in error) {
        urlOnclick = error.alternativePlatform;
      }
    }
    feedback = (
      <Feedback backroundColor="red" text={message} color="white" urlOnclick={urlOnclick} icon={<ErrorOutlineIcon />} />
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles[`login_form_${webStyle}`]}>
      <React.Fragment>
        {feedback}
        {!loading && (
          <React.Fragment>
            <TextInputController
              className={styles.inputs}
              control={control}
              schema={LoginSchema}
              errors={errors}
              name="email"
              label={tFormKey('Email')}
              fullWidth
            />
            <TextInputController
              className={styles.inputs}
              schema={LoginSchema}
              errors={errors}
              name="password"
              control={control}
              label={tFormKey('Contraseña')}
              type="password"
              fullWidth
            />
          </React.Fragment>
        )}

        {loading && (
          <div className={styles.loading}>
            <Loading big text={tKey('Por favor, espere...')} />
          </div>
        )}
        {!loading && (
          <Button variant="contained" color="primary" type="submit" disableElevation>
            {tFormKey('Entrar')}
          </Button>
        )}
      </React.Fragment>
    </form>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  error: selectError(state),
  token: selectToken(state),
});

const mapDispatchToProps = (dispatch: AuthDispatch) => ({
  login: (data: LoginFields): void => dispatch(login(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
