import React from 'react';
import { FilterInputTextField } from '../../../../redux/directory/definitions';
import styles from './filterInput.module.scss';

interface FilterInputProps {
  label: string;
  name: string;
  value: string;
  onChangeValue: (key: keyof FilterInputTextField, value: string) => void;
}

const FilterInput: React.FC<FilterInputProps> = ({ label, name, value, onChangeValue }) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(name as keyof FilterInputTextField, e.target.value);
  };

  return (
    <div className={styles.root}>
      <input name={name} className={styles.input} placeholder={label} value={value} onChange={handleOnChange} />
    </div>
  );
};

export default FilterInput;
