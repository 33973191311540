import { MaterialTableProps } from 'material-table';
import React, { Fragment, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Layout from '../../../components/Layout/Layout';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { tFormKey, tKey } from '../../../helpers/translate';
import { addKpiIndicator, getKpiIndicator, getKpiIndicators, removeKpiIndicator } from '../../../redux/kpis/action';
import { CustomFieldADispatch, KpiIndicatorType } from '../../../redux/kpis/definitions';
import { selectKpiTypes, selectLoading, selectShowModal } from '../../../redux/kpis/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuConfCustomField from '../SubmenuConfCustomField';

const KPIType = React.lazy(() => import('./KPIType'));
interface KPISTypesProps extends RouteComponentProps<{ code: string }> {
  loading: boolean;
  kpiTypes: KpiIndicatorType[];
  showModal: boolean;
  getKpiTypes: () => void;
  addKpiIndicator: () => void;
  deleteKpiIndicator: (id: number) => void;
  getKpiIndicator: (id: number) => void;
}

const KPISTypes: React.FC<KPISTypesProps> = ({
  loading,
  kpiTypes,
  showModal,
  getKpiTypes,
  addKpiIndicator,
  deleteKpiIndicator,
  getKpiIndicator,
}) => {
  const [modal, setModal] = React.useState({ view: false, body: [''] });
  const [id, setId] = React.useState<number>(0);

  useEffect(() => {
    getKpiTypes();
  }, [getKpiTypes]);

  const title = tFormKey('kpis.indicator.types');
  const tableData: MaterialTableProps<KpiIndicatorType> = {
    title: title,
    columns: [
      {
        field: 'description',
        title: tKey('Descripción'),
      },
    ],
    data: kpiTypes,
    options: {
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    isLoading: loading,
  };

  return (
    <Layout
      leftSubmenu={<SubmenuConfCustomField submenu={title} selected={'kpis_types'} />}
      rightSubmenu={<ActionsMenu />}
    >
      <TableWithEdit
        tableData={tableData}
        key={JSON.stringify(tableData.data)}
        onEditOne={(
          event: React.MouseEvent<Element, globalThis.MouseEvent>,
          rowData: KpiIndicatorType | KpiIndicatorType[],
        ) => {
          if (!Array.isArray(rowData) && rowData) {
            getKpiIndicator(rowData.id);
          }
        }}
        permission={true}
        deleteFunc={(rowData: KpiIndicatorType) => {
          setId(rowData.id);
          setModal({ view: true, body: [] });
        }}
        optionsNew={{
          newButtonText: tKey('Nuevo tipo'),
          onNew: addKpiIndicator,
        }}
      />
      <TransitionModal
        view={modal.view}
        handleClose={() => {
          setId(0);
          setModal({ view: false, body: [] });
        }}
        bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
        buttonOk={tKey('Sí')}
        buttonKo={tKey('No')}
        title={tKey('Eliminar')}
        handleYes={() => {
          deleteKpiIndicator(id);
          setModal({ view: false, body: [] });
        }}
      />
      <Suspense fallback={<></>}>{showModal ? <KPIType /> : <Fragment />}</Suspense>
    </Layout>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectLoading(state),
  kpiTypes: selectKpiTypes(state),
  showModal: selectShowModal(state),
});

const mapDispatchToProps = (dispatch: CustomFieldADispatch) => ({
  getKpiTypes: (): void => dispatch(getKpiIndicators()),
  addKpiIndicator: (): void => dispatch(addKpiIndicator()),
  getKpiIndicator: (id: number) => dispatch(getKpiIndicator(id)),
  deleteKpiIndicator: (id: number): void => dispatch(removeKpiIndicator(id)),
});

export default connect(mapStateProps, mapDispatchToProps)(KPISTypes);
