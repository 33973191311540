import { Button, SvgIcon } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import ImageIcon from '@material-ui/icons/Image';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import DropdownUpload from '../../../components/DropdownUpload/DrowpDownUpload';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { tKey } from '../../../helpers/translate';
import { tProjectKey } from '../../../helpers/translate';
import { ReactComponent as excelIcon } from '../../../images/icons/excel.svg';
import { ReactComponent as pdfIcon } from '../../../images/icons/pdf.svg';
import { ReactComponent as wordIcon } from '../../../images/icons/word.svg';
import { AuthUserData } from '../../../redux/auth/definitions';
import { selectUserInfo } from '../../../redux/auth/selectors';
import { DocumentationItem } from '../../../redux/common/definitions';
import {
  addOneFollowUp,
  assignDocsToRow,
  deleteFollowUp,
  deleteFollowupDocument,
  downloadFollowupDocument,
  editFollowup,
  getOneFollowUp,
  removeDocsTable,
  setterGenericTableRow,
} from '../../../redux/project/action';
import { DocumentsFollowup, FollowUp, ProjectDispatch } from '../../../redux/project/definitions';
import {
  selectDocumentsArr,
  selectFollowUp,
  selectLoading,
  selectLoadingDelete,
  selectLoadingFollowUp,
} from '../../../redux/project/selectors';
import { AppState } from '../../../redux/root-reducer';
import { followUpSchema } from '../../../validations/followUpSchema';
import styles from './createEditFollowUp.module.scss';

interface CreateFollowUp {
  followUp: FollowUp;
  followUpSelected?: FollowUp;
  createdId?: number;
  projectID?: number;
  loadingFollowUp: boolean;
  loadingDelete: boolean;
  userInfo: AuthUserData;
  editMode?: boolean;
  isTableMode?: boolean;
  rowTable?: any;
  fieldName?: string;
  idRow?: number;
  loading: boolean;
  documentsToSend: DocumentsFollowup[];
  tableData?: any;
  addFollowUp: (data: FollowUp) => void;
  addDocsToTable: (data: FollowUp) => void;
  editFollowUp: (data: FollowUp, id: number) => void;
  deleteFollowUp: (id: number) => void;
  downloadFollowupDocumentApi: (project_id: number, name: string, file_id: number, typeTable: boolean) => void;
  deleteFolloupDocumentApi: (project_id: number, file_id: number) => void;
  deleteDoc: (data: any) => void;
  getFollowupApi: (id: number) => void;
  handleCloseModal?: () => void;
  setSetterGenericTableRow: (setterData: any, finalStep: boolean) => void;
}

interface CreateFollowUpFields {
  project_id?: number;
  documentsIDs: number[];
  title: string;
  description: string;
  documents: DocumentationItem[];
  created_at: number;
  fieldName?: string;
  id_row?: number;
  id_generated?: number;
}

const CreateFollowUp: React.FC<CreateFollowUp> = ({
  projectID,
  loadingFollowUp,
  userInfo,
  followUpSelected,
  documentsToSend,
  createdId,
  followUp,
  loadingDelete,
  loading,
  isTableMode,
  fieldName,
  idRow,
  rowTable,
  tableData,
  addFollowUp,
  editFollowUp,
  deleteFollowUp,
  downloadFollowupDocumentApi,
  deleteFolloupDocumentApi,
  getFollowupApi,
  addDocsToTable,
  handleCloseModal,
  deleteDoc,
  setSetterGenericTableRow,
}) => {
  useEffect(() => {
    if (createdId) {
      getFollowupApi(createdId);
    }
  }, [getFollowupApi]); // eslint-disable-line react-hooks/exhaustive-deps

  const tableDocuments = () => {
    if (rowTable && fieldName) {
      return rowTable[fieldName];
    }
  };

  const [isDeleteMode, setIsDeleteMode] = useState(false);

  const { setError, errors, getValues, control } = useForm<CreateFollowUpFields>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: followUpSchema,
  });

  const [deleteFileModal, setDeleteFileModal] = React.useState({
    view: false,
    children: <div style={{ padding: '20px', color: '#666666' }}>¿Seguro que desea eliminar el archivo?</div>,
    id: 0,
    title: tProjectKey('¡Atención!'),
  });

  const [deleteModal, setDeleteModal] = React.useState({
    view: false,
    children: (
      <div style={{ padding: '20px', color: '#666666' }}>¿Seguro que desea eliminar la nota de seguimiento actual?</div>
    ),
    title: tProjectKey('¡Atención!'),
  });

  const itemSubst = (string: string, length: number) => {
    return string.length > length ? string.substring(0, length) + '...' : string;
  };

  const getValuesAndId = () => {
    const arrIds: Array<number> = [];
    documentsToSend.map(e => {
      return arrIds.push(e.id);
    });
    const data = getValues();

    data.project_id = projectID;
    data.documents = [];
    data.documentsIDs = arrIds;

    if (isTableMode) {
      data.fieldName = fieldName;
      data.id_row = idRow;
      if (idRow === null) {
        data.id_generated = rowTable.id_generated;
      }
    }
    return data;
  };

  const prepareToDelete = (item: any) => {
    const data = {
      project_id: projectID,
      id_row: idRow,
      custom_field_id: item.custom_field_id,
      file_id: item.file_id,
      temp_row_id: null,
      row_id: null,
      fieldName: fieldName,
    };

    if (item.row_id === null) {
      data.temp_row_id = item.temp_row_id;
    } else {
      data.row_id = item.row_id;
    }

    return data;
  };

  const checkIfRequired = async () => {
    const data = getValues();
    if (!isTableMode) {
      if (data.title === '' || data.description === '') {
        if (data.title === '') {
          setError('title', 'required', tProjectKey('Debes rellenar este campo'));
        }
        if (data.description === '') {
          setError('description', 'required', tProjectKey('Debes rellenar este campo'));
        }
        return;
      }
    }

    if (isTableMode) {
      if (!isDeleteMode) {
        await setSetterGenericTableRow(tableData, true);
      }
      await addDocsToTable(getValuesAndId());
      if (handleCloseModal) {
        setTimeout(() => handleCloseModal(), 1000);
      }
    } else {
      addFollowUp(getValuesAndId());
    }
  };

  const sendEditFollowUp = () => {
    const data = getValues();

    if (isTableMode) {
      const arrIds: Array<number> = [];
      documentsToSend.map(e => {
        return arrIds.push(e.id);
      });
      data.documentsIDs = arrIds;
    }

    if (followUpSelected?.id) {
      const arrIds: Array<number> = [];
      documentsToSend.map(e => {
        return arrIds.push(e.id);
      });
      data.documentsIDs = arrIds;
      if (data.title === '' || data.description === '') {
        if (data.title === '') {
          setError('title', 'required', tProjectKey('Debes rellenar este campo'));
        }
        if (data.description === '') {
          setError('description', 'required', tProjectKey('Debes rellenar este campo'));
        }
      } else {
        editFollowUp(data, followUpSelected.id);
      }
    }
  };

  const sendDeleteFollowUp = () => {
    if (followUpSelected?.id) {
      deleteFollowUp(followUpSelected.id);
    }
  };

  const colorize = (name: string) => {
    const res = name.substr(name.lastIndexOf('.') + 1);

    switch (res) {
      case 'pdf':
        return <SvgIcon component={pdfIcon} viewBox="0 0 600 476.6" />;
      case 'docx':
        return <SvgIcon component={wordIcon} viewBox="0 0 600 476.6" />;
      case 'xlsx':
        return <SvgIcon component={excelIcon} viewBox="0 0 600 476.6" />;
      default:
        return <ImageIcon />;
    }
  };

  const isDisabled = !isTableMode && followUpSelected && userInfo.user_id !== followUpSelected.user_id;

  return (
    <div className={styles.createEditContainer}>
      {!loadingFollowUp ? (
        <React.Fragment>
          <div className={styles.followUpCreateContainer}>
            <div className={styles.followUpLeft}>
              {!isTableMode && (
                <TextInputController
                  schema={followUpSchema}
                  control={control}
                  size="100"
                  label={tProjectKey('Título')}
                  name="title"
                  defaultValue={followUpSelected?.title}
                  disabled={isDisabled}
                  errors={errors}
                />
              )}
              <DropdownUpload
                disabled={isDisabled}
                documentsUpload={documentsToSend}
                isTableMode={isTableMode}
                helperText
              />
            </div>
            {!isTableMode && (
              <div className={styles.followUpRight}>
                <TextInputController
                  schema={followUpSchema}
                  control={control}
                  multiline
                  rows="12"
                  rowsMax="12"
                  size="100"
                  label={tProjectKey('Descripción')}
                  name="description"
                  defaultValue={followUpSelected?.description}
                  disabled={isDisabled}
                  errors={errors}
                />
              </div>
            )}
          </div>
          {!loadingFollowUp && Object.keys(followUp).length > 0 && followUp.documents.length && !tableDocuments() ? (
            <div className={styles.listUploads}>
              <div className={styles.uploadsTitles}>
                <div>{tProjectKey('Nombre Archivo')}</div>
              </div>
              <div className={styles.uploadsFiles}>
                {followUp.documents.map(e => (
                  <div key={e.file_id || 0} className={styles.documentItem}>
                    <div>
                      <Chip
                        icon={colorize(e.original_name)}
                        size="medium"
                        label={itemSubst(e.original_name, 20)}
                        clickable
                        onClick={() =>
                          downloadFollowupDocumentApi(
                            followUp.id ? followUp.id : 0,
                            e.original_name,
                            e.file_id || 0,
                            false,
                          )
                        }
                        {...(!isDisabled
                          ? {
                              onDelete: () =>
                                setDeleteFileModal(prevState => ({ ...prevState, view: true, id: e.file_id || 0 })),
                            }
                          : {})}
                        className={styles.chip}
                      />
                    </div>
                    <TransitionModal
                      view={deleteFileModal.view}
                      handleClose={() => setDeleteFileModal(prevState => ({ ...prevState, view: false }))}
                      title={deleteFileModal.title}
                      buttonOk={tProjectKey('Eliminar')}
                      bodyModal=" "
                      handleYes={() => {
                        deleteFolloupDocumentApi(followUp.id ? followUp.id : 0, deleteFileModal.id);
                        setDeleteFileModal(prevState => ({ ...prevState, view: false }));
                      }}
                    >
                      {deleteFileModal.children}
                    </TransitionModal>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {tableDocuments() && (
            <div className={styles.listUploads}>
              <div className={styles.uploadsTitles}>
                <div>{tProjectKey('Nombre Archivo')}</div>
              </div>
              <div className={styles.uploadsFiles}>
                {loadingDelete ? (
                  <div style={{ margin: 'auto' }}>
                    <Loading big />
                  </div>
                ) : (
                  tableDocuments().map((e: any) => (
                    <div key={e.file_id} className={styles.documentItem}>
                      <div>
                        <Chip
                          icon={colorize(e.original_name)}
                          size="medium"
                          label={itemSubst(e.original_name, 20)}
                          clickable
                          onClick={() =>
                            downloadFollowupDocumentApi(followUp.id ? followUp.id : 0, e.original_name, e.file_id, true)
                          }
                          {...(!isDisabled
                            ? {
                                onDelete: () => {
                                  setDeleteFileModal(prevState => ({ ...prevState, view: true, id: e.file_id }));
                                  setIsDeleteMode(true);
                                },
                              }
                            : {})}
                          className={styles.chip}
                        />
                      </div>
                      <TransitionModal
                        view={deleteFileModal.view}
                        handleClose={() => setDeleteFileModal(prevState => ({ ...prevState, view: false }))}
                        title={deleteFileModal.title}
                        buttonOk={tProjectKey('Eliminar')}
                        bodyModal=" "
                        handleYes={() => {
                          // deleteFolloupDocumentApi(followUp.id ? followUp.id : 0, deleteFileModal.id);
                          // prepareToDelete(e);
                          deleteDoc(prepareToDelete(e));
                          setDeleteFileModal(prevState => ({ ...prevState, view: false }));
                        }}
                      >
                        {deleteFileModal.children}
                      </TransitionModal>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
          {!followUpSelected && !isTableMode && (
            <div className={styles.buttonContainer}>
              <Button variant="outlined" color="primary" disabled={loading} onClick={() => checkIfRequired()}>
                {tKey('Guardar')}
              </Button>
            </div>
          )}
          {followUpSelected && userInfo.user_id === followUpSelected.user_id && !isTableMode && (
            <div className={styles.buttonGroupContainer}>
              <Button
                variant="outlined"
                color="primary"
                style={{ width: '120px' }}
                onClick={() => setDeleteModal(prevState => ({ ...prevState, view: true }))}
              >
                {tProjectKey('ELIMINAR')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: '10px', width: '120px' }}
                onClick={() => sendEditFollowUp()}
                disabled={loading}
              >
                {tKey('Guardar')}
              </Button>
              <TransitionModal
                view={deleteModal.view}
                handleClose={() => setDeleteModal(prevState => ({ ...prevState, view: false }))}
                title={deleteModal.title}
                buttonOk={tProjectKey('Eliminar')}
                bodyModal=" "
                handleYes={() => {
                  sendDeleteFollowUp();
                }}
              >
                {!loadingDelete ? deleteModal.children : <Loading small />}
              </TransitionModal>
            </div>
          )}
          {isTableMode && (
            <div className={styles.buttonGroupContainer}>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginLeft: '10px', width: '120px' }}
                onClick={() => checkIfRequired()}
                disabled={loading}
              >
                {tKey('Guardar')}
              </Button>
            </div>
          )}
        </React.Fragment>
      ) : (
        <Loading big />
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadingFollowUp: selectLoadingFollowUp(state),
  loading: selectLoading(state),
  userInfo: selectUserInfo(state),
  documentsToSend: selectDocumentsArr(state),
  followUp: selectFollowUp(state),
  loadingDelete: selectLoadingDelete(state),
});

const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  addFollowUp: (data: FollowUp): void => dispatch(addOneFollowUp(data)),
  addDocsToTable: (data: FollowUp): void => dispatch(assignDocsToRow(data)),
  editFollowUp: (data: FollowUp, id: number) => dispatch(editFollowup(data, id)),
  deleteFollowUp: (id: number) => dispatch(deleteFollowUp(id)),
  downloadFollowupDocumentApi: (project_id: number, name: string, file_id: number, typeTable: boolean): void =>
    dispatch(downloadFollowupDocument(project_id, name, file_id, typeTable)),
  deleteFolloupDocumentApi: (project_id: number, file_id: number): void =>
    dispatch(deleteFollowupDocument(project_id, file_id)),
  getFollowupApi: (id: number): void => dispatch(getOneFollowUp(id)),
  deleteDoc: (data: any): void => dispatch(removeDocsTable(data)),
  setSetterGenericTableRow: (setterData: any, finalStep: boolean) =>
    dispatch(setterGenericTableRow(setterData, finalStep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateFollowUp);
