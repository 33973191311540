import { ButtonProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { deleteTracking, getTrackingsAreas } from '../../../api/Areas/areas';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import TableLayout from '../../../components/Layout/TableLayout';
import CanaryTable, { ColumnTableProps } from '../../../components/Tables/TableEditableRow/TableEditableRow';
import { tKey } from '../../../helpers/translate';
import { Tracking } from '../../../redux/area/definitions';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuManagementArea from '../Areas/SubmenuManagementArea';
import styles from './template.module.scss';

interface TemplateProps extends RouteComponentProps {
  canIconfigAreas: boolean;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const Template: React.FC<TemplateProps> = ({ match: { path }, canIconfigAreas, showSnackbar }) => {
  const history = useHistory();
  const selectedSection = path.substring(path.lastIndexOf('/') + 1);
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState([] as Tracking[]);

  const columns = [
    { title: tKey('Titulo'), name: 'title', editable: 'never', align: 'left' },
    { title: tKey('Descripción'), name: 'message', editable: 'never' },
    { title: tKey('Area'), name: 'area_name', editable: 'never' },
  ] as ColumnTableProps[];

  useEffect(() => {
    setLoading(true);
    getTrackingsAreas()
      .then((res: Tracking[]) => {
        setRows(res);
        setLoading(false);
      })
      .catch(error => {
        showSnackbar((error as Error).message, 'error', undefined, 1500);
        setLoading(false);
      });
  }, [showSnackbar]);

  const buttonsTable: ButtonProps[] = [
    {
      onClick: () => history.push('/configuracion/gestion-areas/plantillas/crear'),
      children: tKey('Nueva plantilla'),
      disableElevation: true,
      variant: 'contained',
      color: 'primary',
    },
  ];

  const handleDeleteRow = async (row: any) => {
    try {
      const trackings = await deleteTracking(row.id);
      setRows(trackings);
    } catch (error) {
      showSnackbar((error as Error).message, 'error', undefined, 1500);
    }
  };
  const handleEdit = (row: any) => history.push(`/configuracion/gestion-areas/plantillas/editar/${row.id}`);
  const handleDetail = (row: any) => history.push(`/configuracion/gestion-areas/plantillas/detalle/${row.id}`);

  return (
    <TableLayout
      leftSubmenu={
        <SubmenuManagementArea canConfigure={canIconfigAreas} selected={selectedSection} submenu={selectedSection} />
      }
      rightSubmenu={<ActionsMenu goBack onGoBack={() => history.push('/configuracion/gestion-areas')} />}
    >
      <div className={styles.container}>
        <CanaryTable
          isLoading={loading}
          columns={columns}
          title={tKey('Plantillas')}
          rows={rows}
          setRows={setRows}
          buttons={buttonsTable}
          handleDeleteClick={handleDeleteRow}
          handleEditClick={handleEdit}
          onRowClick={handleDetail}
          option={{ filter: true }}
        />
      </div>
    </TableLayout>
  );
};

const mapStateToProps = (state: AppState) => ({
  canIconfigAreas: selectUserCan(state)('action_areas_config'),
});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Template);
