import { put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import { getInterviewFinishedTableData, getInterviewTableData } from '../../api/Interview/interview';
import errorMessage from '../../helpers/errorMessage';
import {
  getInterviewsDataKo,
  getInterviewsDataOk,
  getInterviewsFinishedDataKo,
  getInterviewsFinishedDataOk,
} from './actions';
import { InterviewsConsts } from './action_types';
import { GetInterviewsDataAction, GetInterviewsFinishedDataAction } from './definitions';

function* getTableDataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = (yield getInterviewTableData()) as Await<ReturnType<typeof getInterviewTableData>>;
    yield put(getInterviewsDataOk(data));
  } catch (e) {
    yield put(getInterviewsDataKo(errorMessage(e)));
  }
}

// GET INTERVIEWS FINISHED

function* getTableDataFinishedFromSaga(): Generator<any, void, any> {
  try {
    const { data } = (yield getInterviewFinishedTableData()) as Await<ReturnType<typeof getInterviewFinishedTableData>>;
    yield put(getInterviewsFinishedDataOk(data));
  } catch (e) {
    yield put(getInterviewsFinishedDataKo(errorMessage(e)));
  }
}

export default [
  takeLatest<GetInterviewsDataAction>(InterviewsConsts.GET_INTERVIEWS, getTableDataFromSaga),
  takeLatest<GetInterviewsFinishedDataAction>(InterviewsConsts.GET_INTERVIEWS_FINISHED, getTableDataFinishedFromSaga),
];
