import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import React from 'react';
import { FieldError } from 'react-hook-form';
import requiredStyle from '../../helpers/validator.module.scss';
import { RadioOptions } from './RadioButtonOptions';
import styles from './radioButtons.module.scss';

interface RadioButtonsProps {
  questionText?: string;
  name: string;
  options: RadioOptions[];
  register?: (instance: any) => void;
  disabled?: boolean;
  defaultValue?: string | number | null;
  validator?: boolean;
  optionModal?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: FieldError;
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  row?: boolean;
  checked?: boolean;
  classes?: { root: any };
}

const RadioButtons: React.FC<RadioButtonsProps> = ({
  questionText,
  name,
  options,
  disabled,
  register,
  defaultValue,
  validator,
  optionModal,
  errors,
  onChange,
  ...props
}) => {
  const [value, setValue] = React.useState<number | null | string | undefined>(defaultValue || undefined);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl
      component="fieldset"
      // classes={{
      //   root: requiredStyle.requiredRadio,
      // }}
    >
      <FormLabel component="legend">
        <div className={optionModal ? styles.optionsModal : styles.questionText}>
          {!!validator && (
            <FiberManualRecordSharpIcon fontSize="small" classes={{ root: requiredStyle.requiredRadio }} />
          )}
          {questionText}
          {errors && <FormHelperText error>{errors.message}</FormHelperText>}
        </div>
      </FormLabel>
      <RadioGroup
        aria-label="position"
        name={name}
        value={value}
        onChange={event => {
          handleChange(event);
          onChange && onChange(event);
        }}
        row
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            className={optionModal ? styles.optionsModal : styles.options}
            checked={option.checked || value?.toString() === option.value.toString()}
            disabled={disabled}
            inputRef={register}
            control={<Radio style={{ display: option.hiddenT ? 'none' : '' }} color={option.color || 'primary'} />}
            label={option.hiddenT ? '' : option.label}
            labelPlacement={option.labelPlacement || 'end'}
            {...props}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;
