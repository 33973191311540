import {
  AdjustOutlined,
  AssignmentOutlined,
  BookmarkBorderOutlined,
  CastOutlined,
  CropSquareOutlined,
  LibraryBooks,
  LiveHelpOutlined,
  SaveOutlined,
} from '@material-ui/icons';
import React from 'react';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';
import { AboutUsMetada, AboutUsTypesMetadata } from '../../../redux/aboutUs/definitions';

interface SubmenuAboutUsProps {
  canConfigure: boolean;
  selected: string;
  submenu: string;
  metadata: AboutUsMetada;
  onClickOption?: () => void;
}

const SubmenuAboutUs: React.FC<SubmenuAboutUsProps> = ({
  canConfigure,
  selected,
  submenu,
  metadata,
  onClickOption,
}) => {
  const defaultIcons: { icon: JSX.Element }[] = [
    { icon: <AssignmentOutlined /> },
    { icon: <AdjustOutlined /> },
    { icon: <SaveOutlined /> },
    { icon: <CropSquareOutlined /> },
    { icon: <LibraryBooks /> },
    { icon: <LiveHelpOutlined /> },
    { icon: <AssignmentOutlined /> },
    { icon: <BookmarkBorderOutlined /> },
    { icon: <BookmarkBorderOutlined /> },
    { icon: <BookmarkBorderOutlined /> },
    { icon: <CastOutlined /> },
  ];

  const optionsMenu: SubmenuItem[] = [];
  if (canConfigure) {
    metadata.aboutUsTypes.forEach((e: AboutUsTypesMetadata, index: number) =>
      optionsMenu.push({
        title: e.name,
        icon: index < defaultIcons.length ? defaultIcons[index].icon : <LibraryBooks />,
        type: 'route',
        route: `/configuracion/sobre-nosotros/${e.code}`,
        selected: selected === e.code,
      }),
    );
  }

  return (
    <Submenu
      titleMenu={tKey('SOBRE NOSOTROS')}
      optionsMenu={optionsMenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
      onClickOption={onClickOption}
    />
  );
};

export default SubmenuAboutUs;
