import { useDispatch, useSelector } from 'react-redux';
import {
  selectUnreadAlertsNumber,
  selectUnreadMessageThreadsNumber,
  selectUnreadPendingTasksNumber,
} from '../../redux/notification/selectors';
import { getNotificationsStartPolling } from '../../redux/notification/actions';

interface UserNotificationsUpdate {
  unread_pending_tasks: number;
  unread_alerts: number;
  unread_message_threads: number;
}

function useUserNotificationsUpdate(): UserNotificationsUpdate {
  const dispatch = useDispatch();
  dispatch(getNotificationsStartPolling());

  return {
    unread_pending_tasks: useSelector(selectUnreadPendingTasksNumber),
    unread_alerts: useSelector(selectUnreadAlertsNumber),
    unread_message_threads: useSelector(selectUnreadMessageThreadsNumber),
  };
}
export default useUserNotificationsUpdate;
