import { Uuid } from '../../common/definitions';

export type InternalProjectId = number;
export type ParticipantId = Uuid;
export type InternalProjectType = 'VALUE1' | 'VALUE2' | 'VALUE3' | 'VALUE4' | 'VALUE5';

export enum ParticularitiesEnum {
  SITUACIONES_DE_NEGLIGENCIA_FAMILIAR = 'Situaciones de negligencia familiar',
  SITUACIONES_SOCIOECONOMICAS_DESFAVORECIDAS = 'Situaciones socioeconómicas desfavorecidas',
  FALTA_DE_REFERENTES_POSITIVOS = 'Falta de referentes positivos en el entorno más inmediato de los jóvenes',
  ENTORNO_FRUSTRANTE = 'Entorno frustrante',
  FRACASO_ESCOLAR = 'Fracaso escolar',
  BAJA_AUTOESTIMA = 'Baja autoestima',
  DIFICULTADES_DE_GESTION_EMOCIONAL = 'Dificultades de gestión emocional',
  IMPULSIVIDAD = 'Impulsividad',
  DIFICULTADES_PARA_PEDIR_AYUDA = 'Dificultades para pedir ayuda',
  DIFICULTADES_PARA_ESTABLECER_VINCULOS_AFECTIVOS = 'Dificultades para establecer vínculos afectivos',
  DIFICULTAD_DE_COMUNICACION = 'Dificultad de comunicación',
  FALTA_DE_ESTRATEGIAS_PARA_RESOLVER_CONFLICTOS = 'Falta de estrategias para resolver conflictos',
  ENTORNOS_FAMILIARES_DISFUNCIONALES = 'Entorno familiar disfuncional',
  FALTA_DE_MODELO_REFERENTE_ADULTO = 'Falta de modelo o referente adulto sano y estable a lo largo de la vida',
  MALTRATO = 'Maltrato',
  RECHAZO_DEL_ENTORNO = 'Rechazo del entorno',
  MENORES_EN_RIESGO_DE_EXCLUSION_SOCIAL = 'Menores en riesgo de exclusión social',
  ABANDONOS = 'Abandonos',
  JOVEN_VINCULADO_A_ENTIDADES_RECURSOS_SOCIALES = 'Joven vinculado a las entidades o recursos sociales educativos o de protección de la infancia',
  OTRO = 'Otro',
}

export enum ParticipantType {
  PARTICIPANT = 'participant',
  EDUCATOR = 'educator',
}

export type Participant = {
  id: ParticipantId;
  internal_project_id: InternalProjectId;
  lopd: boolean;
  additional_info: string;
  user_name: string;
  type: ParticipantType;
  particularities: string;
  unsubscribed_at: string | null;
};

export enum MotivoAusencia {
  FAMILIAR = 'Familiar',
  OCIO = 'Ocio personal',
  ESCOLAR = 'Escolar',
  ENFERMEDAD = 'Enfermedad',
  LABORAL = 'Ausencia laboral en el centro socioeducativo',
  INCOMPATIBILIDAD = 'Incompatibilidad formación',
  OTRO = 'Otro',
}

export type ReasonsTypes =
  | MotivoAusencia.FAMILIAR
  | MotivoAusencia.OCIO
  | MotivoAusencia.ESCOLAR
  | MotivoAusencia.ENFERMEDAD
  | MotivoAusencia.LABORAL
  | MotivoAusencia.INCOMPATIBILIDAD
  | MotivoAusencia.OTRO;

export type Assistance = {
  internal_project_participant_id: ParticipantId;
  internal_project_follow_up_id: number;
  type: ParticipantType;
  user_name: string;
  assistance: boolean | null;
  reasons: ReasonsTypes | null;
  other_reasons: string | null;
};
