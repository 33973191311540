import React from 'react';
import Layout, { LayoutProps } from './Layout';
import styles from './layoutForm.module.scss';

type LayoutForm = Pick<
  LayoutProps,
  'children' | 'leftSubmenu' | 'rightSubmenu' | 'footerMenuDisabled' | 'layoutDisabled'
> & { fakeMenu?: boolean }; //TODO. Quitar el fakemenu!!!!!

const LayoutForm: React.FC<LayoutForm> = ({ children, fakeMenu, ...layoutProps }) => (
  <Layout {...layoutProps}>
    <div className={fakeMenu ? styles.containerMenuRight : styles.container}>{children}</div>
  </Layout>
);

export default LayoutForm;
