import assertUnreachable from '../../helpers/assertUnreachable';
import { MetadataConst } from './action_types';
import { initialState, MetadataAction, MetadataState } from './definitions';

const metadataReducer = (state = initialState, action: MetadataAction): MetadataState => {
  switch (action.type) {
    case MetadataConst.GET_GENERIC:
    case MetadataConst.GET_PROJECT_METADATA:
    case MetadataConst.GET_RESPONSIBLES:
    case MetadataConst.GET_TEAM:
    case MetadataConst.GET_ACCOUNTING_METADATA:
    case MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA:
    case MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA:
      return {
        ...state,
      };
    case MetadataConst.GET_SCHOLARSHIP_METADATA:
      return {
        ...state,
      };
    case MetadataConst.GET_GENERIC_KO:
    case MetadataConst.GET_PROJECT_METADATA_KO:
    case MetadataConst.GET_TEAM_KO:
    case MetadataConst.GET_RESPONSIBLES_KO:
    case MetadataConst.GET_SCHOLARSHIP_METADATA_KO:
    case MetadataConst.GET_ACCOUNTING_METADATA_KO:
    case MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA_KO:
    case MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA_KO:
      const { error } = action;
      return {
        ...state,
        error,
      };
    case MetadataConst.GET_GENERIC_OK:
      const { genericData } = action;
      genericData.areas.unshift({ code: undefined, name: '------------' });
      genericData.projects.unshift({ code: undefined, name: '------------' });
      genericData.loaded = true;
      return {
        ...state,
        generic: genericData,
      };
    case MetadataConst.GET_PROJECT_METADATA_OK:
      const { projectData } = action;
      projectData.loaded = true;
      return {
        ...state,
        project: projectData,
      };
    case MetadataConst.GET_TEAM_OK:
      const { team } = action;
      return {
        ...state,
        team,
      };
    case MetadataConst.GET_RESPONSIBLES_OK:
      const { responsibles } = action;
      return {
        ...state,
        responsibles,
      };
    case MetadataConst.GET_ACCOUNTING_METADATA_OK:
      const { accountingData } = action;
      accountingData.loaded = true;
      return {
        ...state,
        accounting: accountingData,
      };
    case MetadataConst.GET_SCHOLARSHIP_METADATA_OK:
      const { scholarshipData } = action;
      scholarshipData.loaded = true;
      return {
        ...state,
        scholarship: scholarshipData,
      };
    case MetadataConst.GET_SCHOLARSHIP_ACADEMIES_METADATA_OK:
      const { academies } = action;
      return {
        ...state,
        scholarship: {
          ...state.scholarship,
          academies,
        },
      };
    case MetadataConst.GET_SCHOLARSHIP_UNIVERSITIES_METADATA_OK:
      const { universities } = action;
      return {
        ...state,
        scholarship: {
          ...state.scholarship,
          universities,
        },
      };
  }
  assertUnreachable(action);
  return state;
};

export default metadataReducer;
