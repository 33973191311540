import * as yup from 'yup';
import { tErrorKey } from '../../helpers/translate';
import { emailSchema, nameSchema, phoneSchema, requiredText } from '../formSchema';

const phoneMax15digits = tErrorKey('El teléfono debe tener máximo 15 dígitos');
const accountingDescriptionMaxLength = tErrorKey('La descripción tiene un máximo de 255 carácteres');
const accountingNoteDescriptionMaxLength = tErrorKey('La descripción tiene un máximo de 30 carácteres');
const dniValidation = tErrorKey('DNIs incorrecto');
const nifValidation = tErrorKey('NIF incorrecto');
const passportValidation = tErrorKey('Pasaporte incorrecto');

export const entityFormSchema = (isVisaProvider: boolean, noPaymentEntity: boolean) =>
  yup.object().shape({
    email: yup
      .string()
      .concat(emailSchema.email)
      .notRequired()
      .when([], {
        is: () => isVisaProvider,
        otherwise: emailSchema.email,
      }),
    cif: yup.string(),
    ...nameSchema,
    ...phoneSchema,
    country: yup.string(),
    address_type: yup.string(),
    address: yup.string(),
    flat: yup.string(),
    floor: yup.string(),
    room: yup.string(),
    staircase: yup.string(),
    zip_code: yup.string(),
    city: yup.string(),
    province: yup.string(),
    creation_date: yup.string(),
    legal_nature: yup.string(),
    has_project: yup.number(),
    has_scholarship: yup.number(),
    is_provider: yup.number(),
    is_visa_provider: yup.number(),
    natural_person: yup.number(),
    legal_person: yup.number(),
    no_payments_entity: yup.number(),
    account_number: yup.string().when('country', {
      is: country => country === 'España' && !noPaymentEntity && !isVisaProvider,
      then: yup
        .string()
        .required(requiredText)
        .matches(/^ES\d{22}$/, tErrorKey('Debes introducir un IBAN válido')),
      otherwise: yup.string(),
    }),
    responsible_name: yup.string().when([], {
      is: () => isVisaProvider,
      then: yup.string(),
      otherwise: yup.string().required(requiredText),
    }),
    responsible_surname: yup.string(),
    responsible_city: yup.string().when([], {
      is: () => isVisaProvider,
      then: yup.string(),
      otherwise: yup.string().required(requiredText),
    }),
    responsible_phone: yup
      .string()
      .when([], {
        is: () => isVisaProvider,
        then: yup.string(),
        otherwise: yup.string().required(requiredText),
      })
      .test('phone', phoneMax15digits, val => {
        if (val && val.length > 0) return val.length <= 15;
        return true;
      }),
    responsible_document_type: yup.string().when([], {
      is: () => isVisaProvider,
      then: yup.string(),
      otherwise: yup.string().required(requiredText),
    }),
    responsible_document_number: yup.string().when([], {
      is: () => isVisaProvider,
      then: yup.string(),
      otherwise: yup
        .string()
        .required(requiredText)
        .when(['responsible_document_type'], {
          is: responsible_document_type => responsible_document_type === 'DNI',
          then: yup.string().matches(/^(\d{8})([A-Z])$/, dniValidation),
        })
        .when(['responsible_document_type'], {
          is: responsible_document_type => responsible_document_type === 'NIE',
          then: yup.string().matches(/^[XYZ]\d{7,8}[A-Z]$/, nifValidation),
        })
        .when(['responsible_document_type'], {
          is: responsible_document_type => responsible_document_type === 'Pasaporte',
          then: yup.string().max(20, passportValidation),
        }),
    }),
  });

export const IbanEntitySchema = yup.object().shape({
  ibanCountryCode: yup.string(),
  alias: yup
    .string()
    .max(30, accountingNoteDescriptionMaxLength)
    .required(requiredText),
  iban: yup.string().when('ibanCountryCode', {
    is: ibanCountryCode => ibanCountryCode === 'ES',
    then: yup
      .string()
      .required(requiredText)
      .matches(/^ES\d{22}$/, tErrorKey('Debes introducir un IBAN válido')),
    otherwise: yup.string().required(requiredText),
  }),

  file_id: yup
    .number()
    .required(requiredText)
    .typeError(requiredText),
});

export const EntityContactSchema = yup.object().shape({
  ...emailSchema,
  id: yup.number(),
  name: yup.string().required(requiredText),
  surname: yup.string().required(requiredText),
  contact_role_code: yup.string().required(requiredText),
  phone: yup.string().test('phone', phoneMax15digits, val => {
    if (val.length > 0) return val.length <= 15;
    return true;
  }),
  description: yup.string().max(255, accountingDescriptionMaxLength),
});

export const EntityTransitionCommentSchema = yup.object().shape({
  comment: yup.string().required(),
});

export const EntityLegalSchema = yup.object().shape({
  creation_date: yup.string(),
  legal_nature: yup.string(),
});
