import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styles from './modaltool.module.scss';

interface PaymentModalProps {
  details: any[];
  view: boolean;
  onClose: () => void;
}

const PaymentModal: React.FC<PaymentModalProps> = ({ view, onClose, details }) => {
  const typeOfInput = (type: string) => {
    if (type === 'price') return ' €';
    return '';
  };

  const typeOfSize = (type: string) => {
    if (type === 'large') return styles.large;
  };
  return (
    <Dialog
      open={view}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="lg"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">Desglose</DialogTitle>
        <IconButton aria-label="delete" className={styles.buttonClose} size="small" onClick={() => onClose()}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        {details.map((detail: any, index: number) => (
          <TextField
            key={index}
            className={typeOfSize(detail.size)}
            style={{ color: detail?.color ? detail.color(detail.row) : undefined }}
            label={detail.title}
            defaultValue={detail.value ? detail.value + typeOfInput(detail.type) : 'No hay datos'}
            variant="outlined"
            disabled
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
