import { Badge, Tooltip } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { tKey } from '../../../../helpers/translate';
import webStyle from '../../../../helpers/webStyle';
import { ColorStepElement, SubmenuItemTypeOption } from '../../../../redux/scholarship/definitions';
import styles from './submenu.module.scss';

interface OptionSubmenuBase {
  title: string;
  icon?: JSX.Element;
  iconMobile?: JSX.Element;
  count?: number;
  selected?: boolean;
  code?: string[];
  hidden?: boolean;
  component?: SubmenuItemTypeOption;
  readOnly?: boolean;
}

interface SubmenuRoute extends OptionSubmenuBase {
  route: string;
  type: 'route';
}

export interface SubmenuComponentId extends OptionSubmenuBase {
  componentId: number;
  type: 'component';
  colorStyle?: ColorStepElement;
}

interface SubmenuComponentCode<T = {}> extends OptionSubmenuBase {
  componentCode: T;
  type: 'component_code';
}

interface SubmenuItems extends OptionSubmenuBase {
  items: (SubmenuRoute | SubmenuComponentId)[];
  type: 'items';
  route?: string;
}

export type SubmenuItem<T = {}> = SubmenuRoute | SubmenuComponentId | SubmenuItems | SubmenuComponentCode<T>;

interface SubmenuProps {
  titleMenu?: string;
  defaultOpen?: boolean;
  optionsMenu: SubmenuItem[];
  position?: 'right' | 'left';
  defaultOpenSubmenu?: string;
  sectionAvatar?: JSX.Element;
  onSelectComponentId?: (componentId: number) => void;
  onSelectComponentCode?: (componentCode: string) => void;
  onClickOption?: () => void;
  showIcon?: boolean;
}

const Submenu: React.FC<SubmenuProps> = ({
  titleMenu,
  defaultOpen,
  optionsMenu,
  position = 'left',
  defaultOpenSubmenu = '',
  sectionAvatar,
  onSelectComponentId,
  onSelectComponentCode,
  onClickOption,
  showIcon = true,
}) => {
  const [open, setOpen] = useState(!!defaultOpen);
  const [submenuListOpen, setSubmenuListOpen] = useState(defaultOpenSubmenu);

  const history = useHistory();
  const goToRouteOrSendId = (option: SubmenuRoute | SubmenuComponentId | SubmenuComponentCode) => {
    if (onClickOption) {
      onClickOption();
    }
    if (option.type === 'route') {
      history.push(option.route);
    }
    if (option.type === 'component') {
      if (onSelectComponentId) {
        onSelectComponentId(option.componentId);
      }
    }
    if (option.type === 'component_code') {
      if (onSelectComponentCode && typeof option.componentCode === 'string') {
        onSelectComponentCode(option.componentCode);
      }
    }
  };

  const handleClick = (title: string) => {
    title === submenuListOpen ? setSubmenuListOpen('') : setSubmenuListOpen(title);
  };

  const setOpenMenu = (open: boolean) => {
    setOpen(open);
    handleClick('');
  };

  const SubmenuItemsList = ({ option, showIcon }: { option: SubmenuItems; showIcon: boolean }) => (
    <React.Fragment>
      <ListItem className={styles.listItem} button onClick={() => handleClick(option.title)}>
        {option.icon && showIcon && (
          <Tooltip key={option.title} title={option.title}>
            <ListItemIcon
              classes={{
                root: styles.icon,
              }}
            >
              {option.icon}
            </ListItemIcon>
          </Tooltip>
        )}
        <ListItemText
          classes={{
            root: styles.text,
          }}
          primary={option.title}
        />
        {submenuListOpen === option.title ? (
          <ExpandLess className={styles.iconDropDown} />
        ) : (
          <ExpandMore className={styles.iconDropDown} />
        )}
      </ListItem>
      <Collapse in={submenuListOpen === option.title} timeout="auto" unmountOnExit>
        <List disablePadding dense>
          {option.items.map(item => (
            <ListItem
              button
              key={item.title}
              onClick={() => goToRouteOrSendId(item)}
              className={styles.listItem_items}
              selected={item.selected}
            >
              <ListItemText primary={tKey(item.title)} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );

  const SubmenuListRouteComponent = ({
    option,
    showIcon,
  }: {
    option: SubmenuRoute | SubmenuComponentId | SubmenuComponentCode;
    showIcon: boolean;
  }) => (
    <ListItem
      classes={{
        root: styles.listItem,
      }}
      button
      key={option.title}
      selected={option.selected}
      onClick={() => goToRouteOrSendId(option)}
    >
      {option.icon && showIcon && (
        <Tooltip key={option.title} title={option.title}>
          <ListItemIcon
            classes={{
              root: styles.icon,
            }}
          >
            {!open && option.count && option.count > 0 ? (
              <Badge color="primary" badgeContent={option.count} variant="dot">
                {option.icon}
              </Badge>
            ) : (
              option.icon
            )}
          </ListItemIcon>
        </Tooltip>
      )}
      <ListItemText
        classes={{
          root: styles.text,
        }}
        primary={open ? option.title : ''}
      />
      {option.count && option.count > 0 ? <div className={styles.big_badge}>{option.count}</div> : null}
    </ListItem>
  );

  return (
    <Drawer
      anchor={position}
      variant="permanent"
      className={open ? styles.drawerOpen : styles.drawerClose}
      classes={{
        root: styles.drawer,
        paper: open ? styles.drawerOpen : styles.drawerClose,
      }}
    >
      <div className={`${styles.buttons} ${styles[position]} ${!open ? styles.close : ''}`}>
        {!open && (
          <IconButton color="inherit" aria-label="open drawer" onClick={() => setOpenMenu(true)}>
            <MenuIcon />
          </IconButton>
        )}
        {open && (
          <React.Fragment>
            {titleMenu && open && <h3 className={styles[`title_${webStyle}`]}>{titleMenu}</h3>}
            <IconButton onClick={() => setOpenMenu(false)}>
              {position === 'left' && <ChevronLeftIcon />}
              {position === 'right' && <ChevronRightIcon />}
            </IconButton>
          </React.Fragment>
        )}
      </div>
      <Divider />
      {sectionAvatar && open && <div className={styles.section}>{sectionAvatar}</div>}
      <List>
        {optionsMenu?.map(
          option =>
            !option.hidden && (
              <Fragment key={option.title}>
                {option.type === 'items' ? (
                  <SubmenuItemsList showIcon={showIcon} key={Math.random()} option={option} />
                ) : (
                  <SubmenuListRouteComponent showIcon={showIcon} key={Math.random()} option={option} />
                )}
              </Fragment>
            ),
        )}
      </List>
    </Drawer>
  );
};
export default Submenu;
