import { ButtonProps, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import { EntityUser, myEntityUsers } from '../../../api/Entity/entity';
import Loading from '../../../components/Loading/Loading';
import MessageInfo from '../../../components/MessageInfo/MessageInfo';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SelectController from '../../../components/Select/SelectController';
import { useButtonAction } from '../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { DocumentationItem, ModalProps } from '../../../redux/common/definitions';
import { changeResponsible, updateEntity } from '../../../redux/entity/actions';
import { EntityInfo, EntityStateCodes, EntityUpdateDispatch } from '../../../redux/entity/definitions';
import { selectGetOneEntity, selectIsLoading, selectValidationErrors } from '../../../redux/entity/selectors';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { ChangeResponsibleSchema } from '../../../validations/formSchema';
import styles from '../entityData.module.scss';
import EntityForm from './EntityForm';
import { entityFormSchema } from '../../../validations/Entities/entityFormSchema';

let users: EntityUser[] = [];

interface MyEntityProps {
  entity: EntityInfo;
  loading: boolean;
  validationErrors: APIValidationError | null;
  documentation: DocumentationItem[];
  canEditMyEntity: boolean;
  buttonActionSave: ButtonProps;
  buttonActionResponsible: ButtonProps;
  canIChangeResponsible: boolean;
  updateEntity: (data: EntityInfo) => void;
  changeResponsible: (id: number) => void;
}

const MyEntity: React.FC<MyEntityProps> = ({
  validationErrors,
  canEditMyEntity,
  loading,
  entity,
  documentation,
  buttonActionSave,
  buttonActionResponsible,
  canIChangeResponsible,
  updateEntity,
  changeResponsible,
}) => {
  const { t } = useTranslation();

  const entitySchema = entityFormSchema(!!entity.is_visa_provider, !!entity.no_payments_entity);

  const { register, handleSubmit, clearError, setValue, watch, errors, setError, triggerValidation, control } = useForm<
    EntityInfo
  >({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: entitySchema,
  });

  const {
    control: controlResponsible,
    errors: errorsResponsible,
    getValues: getValuesResponsible,
    setError: setErrorResponsible,
    clearError: clearErrorResponsible,
  } = useForm<{
    newResponsible: number;
  }>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ChangeResponsibleSchema,
  });

  useEffect(() => {
    myEntityUsers().then(({ data }) => {
      users = data;
    });
  }, []);

  const [changeResponsibleModal, setChangeResponsibleModal] = React.useState<ModalProps>({
    view: false,
    body: '',
    title: '',
  });

  const saveEntity = () => {
    clearError();
    updateEntity(entity);
  };

  const handleChangeResponsible = () => {
    clearErrorResponsible();
    const { newResponsible } = getValuesResponsible();
    if (newResponsible) {
      changeResponsible(newResponsible);
      setChangeResponsibleModal(prevState => ({ ...prevState, view: false }));
    } else {
      setErrorResponsible('newResponsible', tErrorKey('Debes seleccionar un responsable'));
    }
  };

  useValidatorAPI(validationErrors, setError);

  useButtonAction(buttonActionSave, handleSubmit(saveEntity));

  useButtonAction(
    buttonActionResponsible,
    () =>
      setChangeResponsibleModal(prevState => ({
        ...prevState,
        view: true,
        ok: tKey('Cambiar'),
        ko: tKey('Cancelar'),
        title: tKey('Cambio de Responsable'),
      })),
    { hidden: !canIChangeResponsible || entity.state_code !== EntityStateCodes.REGISTRADA },
  );

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <EntityForm
          entitySchema={entitySchema}
          title={tKey('Mi Entidad')}
          useForm={{ register, setValue, watch, errors, triggerValidation, control, setError, clearError }}
          disabled={!canEditMyEntity || entity.state_code !== EntityStateCodes.REGISTRADA}
          responsableData
          documentation={documentation}
        />
      )}
      <TransitionModal
        view={changeResponsibleModal.view}
        handleClose={() => {
          setChangeResponsibleModal(prevState => ({ ...prevState, view: false }));
        }}
        title={changeResponsibleModal.title}
        bodyModal={changeResponsibleModal.body}
        handleYes={() => {
          handleChangeResponsible();
        }}
        handleNo={() => {
          setChangeResponsibleModal(prevState => ({ ...prevState, view: false }));
        }}
        buttonKo={changeResponsibleModal.ko}
        buttonOk={changeResponsibleModal.ok}
        helperMode={true}
      >
        <div className={styles.responsibleContainer}>
          <MessageInfo style={styles.messageInfo}>{tKey('GuardarCambiosEntidad')}</MessageInfo>
          <MessageInfo style={styles.messageInfo}>{tKey('CambioResponsable')}</MessageInfo>
          <p className={styles.label}>{tKey('Selecciona al nuevo responsable de la entidad')}</p>
          <SelectController
            errors={errorsResponsible}
            name="newResponsible"
            label={tFormKey('Responsable')}
            control={controlResponsible}
            schema={ChangeResponsibleSchema}
            fullWidth
          >
            {users
              .filter(user => user.email !== entity.email)
              .map(user => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.name} ${user.surname} (${t(`user.position.${user.position}`)})`}
                </MenuItem>
              ))}
          </SelectController>
        </div>
      </TransitionModal>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  entity: selectGetOneEntity(state),
  validationErrors: selectValidationErrors(state),
  canEditMyEntity: selectUserCan(state)('crud_my_entity'),
  canIChangeResponsible: selectUserCan(state)('action_change_responsible'),
});

const mapDispatchToProps = (dispatch: EntityUpdateDispatch) => ({
  updateEntity: (data: EntityInfo) => dispatch(updateEntity(data)),
  changeResponsible: (id: number) => dispatch(changeResponsible(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyEntity);
