import { Action } from 'redux';
import { APIError, APIValidationError } from '../../api/api';
import { AddressType, ColumnOrderData, DocumentationItem, ResponseData } from '../common/definitions';
import { AreaSubArea, LookUpSubarea } from '../project/definitions';
import { EventDataConsts } from './action_types';

export interface SuccessInterface {
  success: boolean;
}

export const initialState = {
  loading: false,
  eventCreated: false,
  eventsList: [] as EventData[],
  eventsUserList: [] as EventsUser[],
  error: null as string | null,
  validationErrors: null as APIValidationError | null,
  areasSubareasList: [] as AreaSubArea[],
  pageSize: 5 as number,
  searchText: null as string | null,
  columnOrder: { orderDirection: 'asc', orderedColumnId: 1 } as ColumnOrderData,
  responseDeleteOne: null as any | null,
  responseData: null as ResponseData | null,
  isAllSuccess: false as boolean,
  responseValidation: {} as SuccessInterface,
  subareas: {} as LookUpSubarea,
  eventData: {} as EventData,
  eventSubscriptions: [] as EventSubscriptionData[],
  eventRegistrations: [] as EventSubscriptionData[],
  eventRegistrationData: {} as EventRegistrationData,
  registrationIntervalData: {} as RegistrationIntervalData,
  userRegistrationData: {} as UserRegistrationData,
};

export interface RegistrationIntervalData {
  url: string;
  desktopFile: number;
  startDate: string;
  endDate: string;
  full: boolean;
}

export interface CopyEvent {
  id: number;
  title?: string;
  event_id?: string;
}

export interface EventsUser {
  address: string;
  start_date: string;
  title: string;
  type: string;
  connection_link: string;
}

export interface EventData {
  id: number;
  event_id: string;
  start_date: number | string;
  end_date: number | null;
  created_at: string;
  closing_date?: string;

  time?: string;
  title: string;
  address?: AddressType;
  city?: string;
  province?: string;

  unidentified: number | null;

  registration_link?: string;
  connection_link?: string;

  main_contact?: string;
  main_contact_phone?: string;
  main_contact_email?: string;

  reminder: number | null;
  subarea_id?: number;
  subarea?: string;

  cancelled: number;
  max_capacity?: string;
  academic_year: number | null;

  start_registration_date: number | null;
  end_registration_date: number | null;

  type: string | null;

  registered_number: number | null;
  subscribed_number: number | null;
  assistant_number: number | null;
  total_assistants: number | null;

  documents: DocumentationItem[];

  desktopFile: number;
}

export interface EventSubscriptionData extends EventRegistrationData {
  event_id: number;
  zing_network: boolean;
  name: string;
  surname: string;
  email: string;
  document_number?: string;
  city: string;
  entity: string;
  phone: number;
  lopd: boolean | number;
  birth_date?: string;
  academy?: string;
  education_type?: string;
  academic_year?: string;
  education_description?: string;
  created_at: string;
}

export interface EventRegistrationData {
  id: number;
  user_id: number;
  manual: string;
  assistance?: string;
  student?: string;
  lopd: boolean | number;
}

export interface UserRegistrationData {
  entity: string;
  academic_year: string;
  academy: string;
  education_type: string;
  education_description: string;

  [key: string]: string;
}

export type UpdateEventFormFields = Pick<
  EventData,
  | 'id'
  | 'event_id'
  | 'start_date'
  | 'end_date'
  | 'created_at'
  | 'closing_date'
  | 'time'
  | 'title'
  | 'address'
  | 'city'
  | 'province'
  | 'unidentified'
  | 'registration_link'
  | 'connection_link'
  | 'main_contact'
  | 'main_contact_phone'
  | 'main_contact_email'
  | 'reminder'
  | 'subarea'
  | 'subarea_id'
  | 'cancelled'
  | 'max_capacity'
  | 'academic_year'
  | 'start_registration_date'
  | 'end_registration_date'
  | 'type'
  | 'registered_number'
  | 'subscribed_number'
  | 'assistant_number'
  | 'total_assistants'
>;

export type CodeSectionEvent = 'datos_basicos' | 'suscritos' | 'inscritos' | 'jovenes' | 'educadores';

export type SectionEvent = { name: string; code: CodeSectionEvent };

export const datos_basicos = { name: 'Datos básicos', code: 'datos_basicos' } as SectionEvent;
export const suscritos = { name: 'Lista de suscritos', code: 'suscritos' } as SectionEvent;
export const inscritos = { name: 'Lista de inscritos', code: 'inscritos' } as SectionEvent;
export const inscritos_jovenes = { name: 'Jóvenes', code: 'jovenes' } as SectionEvent;
export const inscritos_educadores = { name: 'Educadores', code: 'educadores' } as SectionEvent;

// UPDATE EVENT TABLE
export interface UpdateEventTableAction extends Action {
  type: EventDataConsts.UPDATE_EVENT_TABLE;
  data: UpdateEventFormFields;
  id: number;
}

export interface UpdateEventTableOkAction extends Action {
  type: EventDataConsts.UPDATE_EVENT_TABLE_OK;
  eventData: EventData;
}

export interface UpdateEventTableKoAction extends Action {
  type: EventDataConsts.UPDATE_EVENT_TABLE_KO;
  error: string;
}

export interface EventCreationDataAction extends Action {
  type: EventDataConsts.EVENT_CREATION_DATA;
  key: string;
  value: string | number | null;
}

// GET SINGLE EVENT
export interface GetEventDataAction extends Action {
  type: EventDataConsts.GET_EVENT_DATA;
  id: string;
}

export interface GetEventDataOkAction extends Action {
  type: EventDataConsts.GET_EVENT_DATA_OK;
  eventData: EventData;
}

export interface GetEventDataKoAction extends Action {
  type: EventDataConsts.GET_EVENT_DATA_KO;
  error: string;
}

//GET ALL USER EVENTS
export interface GetUserEventsAction extends Action {
  type: EventDataConsts.GET_USER_EVENTS;
}

export interface GetUserEventsOkAction extends Action {
  type: EventDataConsts.GET_USER_EVENTS_OK;
  eventsUserList: EventsUser[];
}

export interface GetUserEventsKoAction extends Action {
  type: EventDataConsts.GET_USER_EVENTS_KO;
  error: string;
}

//GET ALL EVENTS
export interface GetEventsAction extends Action {
  type: EventDataConsts.GET_EVENTS;
  eventData: EventData;
  role?: string;
}

export interface GetEventsOkAction extends Action {
  type: EventDataConsts.GET_EVENTS_OK;
  eventsList: EventData[];
  subareas: LookUpSubarea;
  role?: string;
}

export interface GetEventsKoAction extends Action {
  type: EventDataConsts.GET_EVENTS_KO;
  error: string;
}

// GET EVENT SUBSCRIPTION
export interface GetEventSubscriptionsAction extends Action {
  type: EventDataConsts.GET_EVENT_SUBSCRIPTIONS;
  eventId: number;
}

export interface GetEventSubscriptionsOkAction extends Action {
  type: EventDataConsts.GET_EVENT_SUBSCRIPTIONS_OK;
  eventSubscriptionsList: EventSubscriptionData[];
}

export interface GetEventSubscriptionsKoAction extends Action {
  type: EventDataConsts.GET_EVENT_SUBSCRIPTIONS_KO;
  error: string;
}

// GET EVENT REGISTRATION
export interface GetEventRegistrationsAction extends Action {
  type: EventDataConsts.GET_EVENT_REGISTRATIONS;
  eventId: number;
}

export interface GetEventRegistrationsOkAction extends Action {
  type: EventDataConsts.GET_EVENT_REGISTRATIONS_OK;
  eventRegistrationsList: EventSubscriptionData[];
}

export interface GetEventRegistrationsKoAction extends Action {
  type: EventDataConsts.GET_EVENT_REGISTRATIONS_KO;
  error: string;
}

// CREATE EVENT REGISTRATION
export interface CreateEventRegistrationAction extends Action {
  type: EventDataConsts.CREATE_EVENT_REGISTRATION;
  eventRegistrationData: EventRegistrationData;
}

export interface CreateEventRegistrationOkAction extends Action {
  type: EventDataConsts.CREATE_EVENT_REGISTRATION_OK;
  eventRegistrationData: EventRegistrationData;
}

export interface CreateEventRegistrationKoAction extends Action {
  type: EventDataConsts.CREATE_EVENT_REGISTRATION_KO;
  error: string;
}

// CREATE EVENT SUBSCRIPTION
export interface CreateEventSubscriptionAction extends Action {
  type: EventDataConsts.CREATE_EVENT_SUBSCRIPTION;
  eventSubscriptionData: EventRegistrationData;
}

export interface CreateEventSubscriptionOkAction extends Action {
  type: EventDataConsts.CREATE_EVENT_SUBSCRIPTION_OK;
  eventSubscriptionData: EventRegistrationData;
}

export interface CreateEventSubscriptionKoAction extends Action {
  type: EventDataConsts.CREATE_EVENT_SUBSCRIPTION_KO;
  error: string;
}

//DELETE EVENT
export interface DeleteEventAction extends Action {
  type: EventDataConsts.DELETE_EVENT;
  id: number;
}

export interface DeleteEventOkAction extends Action {
  type: EventDataConsts.DELETE_EVENT_OK;
  id: number;
}

export interface DeleteEventKoAction extends Action {
  type: EventDataConsts.DELETE_EVENT_KO;
  error: string;
}

export interface ResetConfiguratorDataAction extends Action {
  type: EventDataConsts.RESET_CONFIGURATOR_DATA;
}

// VALIDATIONS ERRORS
export interface EventValidationErrorsAction extends Action {
  type: EventDataConsts.EVENT_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

export interface SetEventRegistrationDataAction extends Action {
  type: EventDataConsts.SET_EVENT_REGISTRATION_DATA;
  key: string;
  value: string | number | boolean | null;
  id: number;
}

export interface SetEventAssistanceAction extends Action {
  type: EventDataConsts.SET_EVENT_ASSISTANCE;
  attended: string;
  code: string;
}

// UPDATE INSCRIPTION DATA
export interface UpdateEventSubscriptionDataAction extends Action {
  type: EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA;
  eventRegistrationsList: EventSubscriptionData[];
  eventId: number;
}

export interface UpdateEventSubscriptionDataOkAction extends Action {
  type: EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA_OK;
}

export interface UpdateEventSubscriptionDataKoAction extends Action {
  type: EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA_KO;
  error: APIValidationError;
}

// REMOVE REGISTRATION
export interface RemoveRegistrationAction extends Action {
  type: EventDataConsts.REMOVE_REGISTRATION;
  eventId: number;
  registrationId: number;
}

export interface RemoveRegistrationOkAction extends Action {
  type: EventDataConsts.REMOVE_REGISTRATION_OK;
  registrationId: number;
}

export interface RemoveRegistrationKoAction extends Action {
  type: EventDataConsts.REMOVE_REGISTRATION_KO;
  error: string;
}

// REMOVE SUBSCRIPTION
export interface RemoveSubscriptionAction extends Action {
  type: EventDataConsts.REMOVE_SUBSCRIPTION;
  eventId: number;
  registrationId: number;
}

export interface RemoveSubscriptionOkAction extends Action {
  type: EventDataConsts.REMOVE_SUBSCRIPTION_OK;
  registrationId: number;
}

export interface RemoveSubscriptionKoAction extends Action {
  type: EventDataConsts.REMOVE_SUBSCRIPTION_KO;
  error: string;
}

// GET CONNECTION URL
export interface GetConnectionUrlAction extends Action {
  type: EventDataConsts.GET_CONNECTION_URL;
  eventId: number;
  eventType: string;
}

export interface GetConnectionUrlOkAction extends Action {
  type: EventDataConsts.GET_CONNECTION_URL_OK;
  url: string;
  desktopFile: number;
  startDate: string;
  endDate: string;
  full: boolean;
}

export interface GetConnectionUrlKoAction extends Action {
  type: EventDataConsts.GET_CONNECTION_URL_KO;
  error: string;
}

// GET USER REGISTRATION DATA
export interface GetUserRegistrationDataAction extends Action {
  type: EventDataConsts.GET_USER_REGISTRATION_DATA;
  eventId: number;
  userId: number;
}

export interface GetUserRegistrationDataOkAction extends Action {
  type: EventDataConsts.GET_USER_REGISTRATION_DATA_OK;
  registrationData: UserRegistrationData;
}

export interface GetUserRegistrationDataKoAction extends Action {
  type: EventDataConsts.GET_USER_REGISTRATION_DATA_KO;
  error: string;
}

//UPLOAD DOCUMENT

export interface UploadDocumentEventAction extends Action {
  type: EventDataConsts.UPLOAD_DOCUMENT_EVENT;
  documentationId: number;
  eventId: number;
  data: File;
}

export interface UploadDocumentEventOkAction extends Action {
  type: EventDataConsts.UPLOAD_DOCUMENT_EVENT_OK;
  documents: DocumentationItem[];
}

export interface UploadDocumentEventKoAction extends Action {
  type: EventDataConsts.UPLOAD_DOCUMENT_EVENT_KO;
  error: APIError;
}

//DOWNLOAD DOCUMENT

export interface DownloadDocumentEventAction extends Action {
  type: EventDataConsts.DOWNLOAD_DOCUMENT_EVENT;
  fileId: number;
  name: string;
  eventId: number;
  get: boolean;
}
export interface DownloadDocumentOkEventAction extends Action {
  type: EventDataConsts.DOWNLOAD_DOCUMENT_EVENT_OK;
  data: Blob;
  get: boolean;
  name: string;
}
export interface DownloadDocumentKoEventAction extends Action {
  type: EventDataConsts.DOWNLOAD_DOCUMENT_EVENT_KO;
  error: APIError;
}

//GET DOCUMENT

export interface GetDocumentEventAction extends Action {
  type: EventDataConsts.GET_DOCUMENT_EVENT;
  fileId: number;
  name: string;
  eventId: number;
}
export interface GetDocumentOkEventAction extends Action {
  type: EventDataConsts.GET_DOCUMENT_EVENT_OK;
  data: Blob;
  name: string;
}
export interface GetDocumentKoEventAction extends Action {
  type: EventDataConsts.GET_DOCUMENT_EVENT_KO;
  error: APIError;
}

//REMOVE DOCUMENT

export interface RemoveDocumentEventAction extends Action {
  type: EventDataConsts.REMOVE_DOCUMENT_EVENT;
  fileId: number;
  eventId: number;
}
export interface RemoveDocumentOkEventAction extends Action {
  type: EventDataConsts.REMOVE_DOCUMENT_EVENT_OK;
  documents: DocumentationItem[];
}
export interface RemoveDocumentKoEventAction extends Action {
  type: EventDataConsts.REMOVE_DOCUMENT_EVENT_KO;
  error: APIError;
}

export type EventDataAction =
  | GetEventDataAction
  | GetEventDataOkAction
  | GetEventDataKoAction
  | GetEventSubscriptionsAction
  | GetEventSubscriptionsOkAction
  | GetEventSubscriptionsKoAction
  | GetEventRegistrationsAction
  | GetEventRegistrationsOkAction
  | GetEventRegistrationsKoAction
  | GetEventsAction
  | GetEventsOkAction
  | GetEventsKoAction
  | DeleteEventAction
  | DeleteEventOkAction
  | DeleteEventKoAction
  | UpdateEventTableAction
  | UpdateEventTableOkAction
  | UpdateEventTableKoAction
  | EventCreationDataAction
  | ResetConfiguratorDataAction
  | EventValidationErrorsAction
  | SetEventRegistrationDataAction
  | SetEventAssistanceAction
  | UpdateEventSubscriptionDataAction
  | UpdateEventSubscriptionDataOkAction
  | UpdateEventSubscriptionDataKoAction
  | CreateEventRegistrationAction
  | CreateEventRegistrationOkAction
  | CreateEventRegistrationKoAction
  | CreateEventSubscriptionAction
  | CreateEventSubscriptionOkAction
  | CreateEventSubscriptionKoAction
  | RemoveRegistrationAction
  | RemoveRegistrationOkAction
  | RemoveRegistrationKoAction
  | RemoveSubscriptionAction
  | RemoveSubscriptionOkAction
  | RemoveSubscriptionKoAction
  | GetConnectionUrlAction
  | GetConnectionUrlOkAction
  | GetConnectionUrlKoAction
  | GetUserRegistrationDataAction
  | GetUserRegistrationDataOkAction
  | GetUserRegistrationDataKoAction
  | RemoveSubscriptionKoAction
  | UploadDocumentEventAction
  | UploadDocumentEventOkAction
  | UploadDocumentEventKoAction
  | DownloadDocumentEventAction
  | DownloadDocumentOkEventAction
  | DownloadDocumentKoEventAction
  | RemoveDocumentEventAction
  | RemoveDocumentOkEventAction
  | RemoveDocumentKoEventAction
  | GetUserEventsAction
  | GetUserEventsOkAction
  | GetUserEventsKoAction;

export type EventDataDispatch = (action: EventDataAction) => void;
