import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SnackBar from '../../components/SnackBar/Snackbar';
import { AuthUserData } from '../../redux/auth/definitions';
import { selectIsUserCreated, selectUserInfo } from '../../redux/auth/selectors';
import { hideSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import {
  selectIsAutoHideDuration,
  selectIsRedirect,
  selectIsSnackbarShowing,
  selectMessageSnackbar,
  selectSeveritySnackbar,
} from '../../redux/FeedbackAPI/selectors';
import { selectUserCan } from '../../redux/permissions/selectors';
import { AppState } from '../../redux/root-reducer';
import Header from '../Header/Header';
import Footer from './Footer/Footer';
import styles from './layout.module.scss';
import { selectUserLanguage } from '../../redux/user/selectors';

export interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  leftSubmenu?: JSX.Element | JSX.Element[];
  rightSubmenu?: JSX.Element | JSX.Element[];
  footerMenuDisabled?: boolean;
  isSnackbarShowing: boolean;
  messageSnackbar: string | null;
  severitySnackbar: SeveritySnackbar | undefined;
  redirect?: string;
  isUserCreated?: boolean;
  autoHideDuration?: number;
  userInfo: AuthUserData;
  layoutDisabled?: boolean;
  showGeniallyButton: boolean;
  userLanguage: string;
  closeSnackbar: () => void;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  leftSubmenu,
  rightSubmenu,
  footerMenuDisabled = false,
  isSnackbarShowing,
  messageSnackbar,
  isUserCreated,
  severitySnackbar,
  redirect,
  autoHideDuration,
  userInfo,
  layoutDisabled,
  showGeniallyButton,
  userLanguage,
  closeSnackbar,
}) => {
  const history = useHistory();

  const closeSnackbarAndRedirect = () => {
    closeSnackbar();
    history.push(`${redirect}`);
  };

  const disabled = (): boolean => {
    if (layoutDisabled) return false;
    if (isUserCreated) return true;
    return false;
  };

  return (
    <div key={userLanguage} className={styles.container}>
      <Header isUserCreated={disabled()} />
      <div className={styles.main_menus}>
        {leftSubmenu ? leftSubmenu : null}
        <main>{children}</main>
        {rightSubmenu ? rightSubmenu : null}
      </div>
      <Footer
        menuDisabled={footerMenuDisabled}
        isUserCreated={disabled()}
        role={userInfo.role}
        showGeniallyFabButton={showGeniallyButton}
      />
      <SnackBar
        open={isSnackbarShowing}
        handleClose={() => {
          redirect ? closeSnackbarAndRedirect() : closeSnackbar();
        }}
        severity={severitySnackbar}
        message={messageSnackbar || 'Ha ocurrido un error'}
        autoHideDuration={autoHideDuration}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  isSnackbarShowing: selectIsSnackbarShowing(state),
  messageSnackbar: selectMessageSnackbar(state),
  severitySnackbar: selectSeveritySnackbar(state),
  redirect: selectIsRedirect(state),
  autoHideDuration: selectIsAutoHideDuration(state),
  isUserCreated: selectIsUserCreated(state),
  userInfo: selectUserInfo(state),
  showGeniallyButton: selectUserCan(state)('show_genially_info'),
  userLanguage: selectUserLanguage(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  closeSnackbar: (): void => dispatch(hideSnackbar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
