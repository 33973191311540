/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, Checkbox } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { Save } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { getCustomFieldsTypeApi } from '../../../api/CustomField/customField';
import ComboClick from '../../../components/ComboClick/ComboClick';
import FormContainer from '../../../components/Forms/FormContainer';
import FormWithMenu from '../../../components/Layout/FormWithMenu';
import Layout from '../../../components/Layout/Layout';
import Loading from '../../../components/Loading/Loading';
import TransitionModal from '../../../components/Modal/TransitionModal';
import SelectController from '../../../components/Select/SelectController';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import Title from '../../../components/Title/Title';
import errorMessage from '../../../helpers/errorMessage';
import { tFormKey, tKey } from '../../../helpers/translate';
import { internalProjectsectionField } from '../../../redux/internal-projects/definitions';
import { assigment, unassigment } from '../../../redux/kpis/action';
import { CustomFieldADispatch, Point, sectionField } from '../../../redux/kpis/definitions';
import { selectLoading } from '../../../redux/kpis/selectors';
import { AppState } from '../../../redux/root-reducer';
import { TypeSchema } from '../../../validations/formSchema';
import InternalProjectsConfigurationFields from '../../InternalProjects/InternalProjectsConfigurationFields/InternalProjectsConfigurationFields';
import styles from './kpis.module.scss';
import { InternalProjectType, InternalProjectTypesEnum } from '../../../types/InternalProjects/InternalProjectTypes.d';
import { UUID } from '../../../types/Shared/standard';

interface CustomFieldByType {
  id: number;
  code: string;
  description: string;
  description_public: string;
  active: boolean;
  project_default: boolean;
  types: { id: UUID; code: string }[];
}
interface AssigmentProps extends RouteComponentProps<{ id: string; code: string; section: string }> {
  loading: boolean;
  assigment: (point: Point, data: any) => void;
  unassigment: (point: Point, data: any, section: string) => void;
}

const AssigmentType: React.FC<AssigmentProps> = ({ match, loading, assigment, unassigment }) => {
  const [, , , , section, source] = match.url.split('/');
  const [modal, setModal] = useState({ view: false, body: [''] });
  const [valuesList, setValuesList] = useState<CustomFieldByType[]>([]);
  const [reserved, setReserved] = useState<CustomFieldByType[]>([]);
  const [data, setData] = useState<CustomFieldByType | null>();

  const { handleSubmit, errors, control, watch } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: TypeSchema,
  });

  useEffect(() => {
    setValuesList([]);
  }, [section]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomFieldsByType = async (code: InternalProjectType): Promise<CustomFieldByType[]> => {
    const data = await getCustomFieldsTypeApi<CustomFieldByType>(section, code);
    setValuesList(data);
    return data;
  };

  const filterReserved = async (code: InternalProjectType) => {
    try {
      const data = await getCustomFieldsByType(code);
      setReserved(
        data.filter(
          customFieldByType =>
            customFieldByType.types.length && customFieldByType.types.filter(type => type.code === code).length > 0,
        ),
      );
      const filterArr = data.filter(
        customFieldByType => customFieldByType.types.filter(type => type.code === code).length === 0,
      );
      setValuesList(filterArr);
    } catch (error) {
      console.log(errorMessage(error));
    }
  };

  const handleDelete = (row: any) => {
    const id = row.id;
    setReserved((prevArray: any) => [...prevArray.filter((prev: any) => prev.id !== id)]);
  };

  const idItem = (data: CustomFieldByType) => {
    setData(data);
    setModal({ view: true, body: [] });
  };

  const onDelete = (data: any) => {
    const dataDefault = { customFieldId: data.id, subareaInternalProjectType: watch('internal_project_type') };
    unassigment('types', dataDefault, section);
    handleDelete(data);
  };

  const handleAddType = (data: CustomFieldByType) => {
    if (data) {
      valuesList.find((values: CustomFieldByType) => values.id === data.id) &&
        setReserved((prevArray: CustomFieldByType[]) => [...prevArray, { ...data }]);

      const filterArr = valuesList.filter(row => !!(row.id === data.id));
      setValuesList((prevArray: any) => [...prevArray, ...filterArr]);
    }
  };

  const onSubmitAreas = (data: any) => {
    data.inputs = reserved;
    assigment('types', data);
  };

  const buttonsEdit: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: handleSubmit(onSubmitAreas),
      startIcon: <Save />,
      disableElevation: true,
      fullWidth: true,
      variant: 'contained',
      color: 'primary',
    },
  ];
  const tableData = {
    options: {
      actionsColumnIndex: -1,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
    },
    title: tKey('Listado de campos'),
    columns: [
      {
        field: 'code',
        title: tKey('Código'),
        //@ts-ignore
        width: '80px',
      },
      {
        field: 'description',
        title: tKey('Descripción'),
        //@ts-ignore
        width: '330px',
      },
      {
        field: 'field_data_types',
        title: tKey('Tipo de dato'),
        //@ts-ignore
        width: '150px',
      },
      {
        field: 'project_default',
        title: tKey('Campo por defecto'),
        //@ts-ignore
        width: '150px',
        render: (rowData: CustomFieldByType) => (
          <Checkbox
            checked={rowData.project_default}
            style={{ width: 50 }}
            onClick={() => {
              setReserved((prevArray: any) => {
                const index = prevArray.findIndex(
                  (prev: any) => prev.id === rowData.id && prev.description === rowData.description,
                );
                prevArray[index].project_default = +!rowData.project_default;
                return [...prevArray];
              });
            }}
          />
        ),
      },
    ],
    data: reserved,
  };

  const title = sectionField?.find(sectionItem => sectionItem.code === section)?.name;
  const title_ip = internalProjectsectionField?.find(sectionItem => sectionItem.code === section)?.name;

  return (
    <Layout leftSubmenu={<InternalProjectsConfigurationFields submenu={tKey(title_ip || '')} selected={source} />}>
      {loading ? (
        <Loading big />
      ) : (
        <FormWithMenu actionsButtons={buttonsEdit}>
          <div className={styles.containerConfig}>
            <Title>{`${tKey('Tipo de proyecto')} - ${tKey(title || title_ip || '')}`}</Title>
            <FormContainer title="" /* className={styles.form} */>
              <SelectController
                size="50"
                control={control}
                errors={errors}
                schema={TypeSchema}
                name="internal_project_type"
                label={tKey('Tipo de proyecto')}
                onClick={(event: any) => {
                  filterReserved(event.target?.value);
                }}
              >
                {Object.entries(InternalProjectTypesEnum).map(element => (
                  <MenuItem key={element[0]} value={element[0]}>
                    {element[1]}
                  </MenuItem>
                ))}
              </SelectController>

              <ComboClick
                options={valuesList}
                control={control}
                error={errors.kpi}
                label={tFormKey('Campos')}
                optionLabel="description_public"
                fieldToSend="code"
                name="kpi"
                onChange={handleAddType}
              />
            </FormContainer>
            <TableWithEdit
              tableData={tableData}
              key={JSON.stringify(tableData.data)}
              deleteFunc={(rowData: CustomFieldByType) => idItem(rowData)}
            />
          </div>
          <TransitionModal
            view={modal.view}
            handleClose={() => {
              setData(null);
              setModal({ view: false, body: [] });
            }}
            bodyModal={tKey('¿Seguro que desea eliminar este campo?')}
            buttonOk={tKey('Si')}
            buttonKo={tKey('No')}
            title={tKey('¡Atención!')}
            handleYes={() => {
              onDelete(data);
              setModal({ view: false, body: [] });
            }}
          />
        </FormWithMenu>
      )}
    </Layout>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectLoading(state),
});

const mapDispatchProps = (dispatch: CustomFieldADispatch) => ({
  assigment: (point: Point, data: any): void => dispatch(assigment(point, data)),
  unassigment: (point: Point, data: any, section: string): void => dispatch(unassigment(point, data, section)),
});

export default connect(mapStateProps, mapDispatchProps)(AssigmentType);
