import { SearchConsts } from './action_types';
import {
  SearchGetDataAction,
  SearchGetDataKoAction,
  SearchGetDataOkAction,
  SearchGetRelationalDataAction,
  SearchGetRelationalDataKoAction,
  SearchGetRelationalDataOkAction,
  SearchItem,
} from './definitions';

export function getSearchData(query: string): SearchGetDataAction {
  return {
    type: SearchConsts.SEARCH_GET_DATA,
    query,
  };
}

export function getSearchDataOk(searchResponse: SearchItem[]): SearchGetDataOkAction {
  return {
    type: SearchConsts.SEARCH_GET_DATA_OK,
    searchResponse,
  };
}

export function getSearchDataKo(error: string): SearchGetDataKoAction {
  return {
    type: SearchConsts.SEARCH_GET_DATA_KO,
    error,
  };
}

export function getRelationalData(queryType: string, id: number): SearchGetRelationalDataAction {
  return {
    type: SearchConsts.SEARCH_GET_RELATIONAL_DATA,
    queryType,
    id,
  };
}

export function getRelationalDataOk(searchRelationalData: SearchItem[]): SearchGetRelationalDataOkAction {
  return {
    type: SearchConsts.SEARCH_GET_RELATIONAL_DATA_OK,
    searchRelationalData,
  };
}

export function getRelationalDataKo(error: string): SearchGetRelationalDataKoAction {
  return {
    type: SearchConsts.SEARCH_GET_RELATIONAL_DATA_KO,
    error,
  };
}
