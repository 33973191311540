export enum EventDataConsts {
  CREATE_EVENT = 'CREATE_EVENT',
  CREATE_EVENT_OK = 'CREATE_EVENT_OK',
  CREATE_EVENT_KO = 'CREATE_EVENT_KO',

  EVENT_VALIDATION_ERRORS = 'EVENT_VALIDATION_ERRORS',

  GET_EVENT_DATA = 'GET_EVENT_DATA',
  GET_EVENT_DATA_OK = 'GET_EVENT_DATA_OK',
  GET_EVENT_DATA_KO = 'GET_EVENT_DATA_KO',

  GET_EVENTS = 'GET_EVENTS',
  GET_EVENTS_OK = 'GET_EVENTS_OK',
  GET_EVENTS_KO = 'GET_EVENTS_KO',

  GET_USER_EVENTS = 'GET_USER_EVENTS',
  GET_USER_EVENTS_OK = 'GET_USER_EVENTS_OK',
  GET_USER_EVENTS_KO = 'GET_USER_EVENTS_KO',

  DELETE_EVENT = 'DELETE_EVENT',
  DELETE_EVENT_OK = 'DELETE_EVENT_OK',
  DELETE_EVENT_KO = 'DELETE_EVENT_KO',

  UPDATE_EVENT_TABLE = 'UPDATE_EVENT_TABLE',
  UPDATE_EVENT_TABLE_OK = 'UPDATE_EVENT_TABLE_OK',
  UPDATE_EVENT_TABLE_KO = 'UPDATE_EVENT_TABLE_KO',

  GET_EVENT_SUBSCRIPTIONS = 'GET_EVENT_SUBSCRIPTIONS',
  GET_EVENT_SUBSCRIPTIONS_OK = 'GET_EVENT_SUBSCRIPTIONS_OK',
  GET_EVENT_SUBSCRIPTIONS_KO = 'GET_EVENT_SUBSCRIPTIONS_KO',

  GET_EVENT_REGISTRATIONS = 'GET_EVENT_REGISTRATIONS',
  GET_EVENT_REGISTRATIONS_OK = 'GET_EVENT_REGISTRATIONS_OK',
  GET_EVENT_REGISTRATIONS_KO = 'GET_EVENT_REGISTRATIONS_KO',

  UPDATE_EVENT_SUBSCRIPTION_DATA = 'UPDATE_EVENT_SUBSCRIPTION_DATA',
  UPDATE_EVENT_SUBSCRIPTION_DATA_OK = 'UPDATE_EVENT_SUBSCRIPTION_DATA_OK',
  UPDATE_EVENT_SUBSCRIPTION_DATA_KO = 'UPDATE_EVENT_SUBSCRIPTION_DATA_KO',

  RESET_CONFIGURATOR_DATA = 'RESET_CONFIGURATOR_DATA',
  EVENT_CREATION_DATA = 'EVENT_CREATION_DATA',

  SET_EVENT_REGISTRATION_DATA = 'SET_EVENT_REGISTRATION_DATA',
  SET_EVENT_ASSISTANCE = 'SET_EVENT_ASSISTANCE',

  CREATE_EVENT_REGISTRATION = 'CREATE_EVENT_REGISTRATION',
  CREATE_EVENT_REGISTRATION_OK = 'CREATE_EVENT_REGISTRATION_OK',
  CREATE_EVENT_REGISTRATION_KO = 'CREATE_EVENT_REGISTRATION_KO',

  CREATE_EVENT_SUBSCRIPTION = 'CREATE_EVENT_SUBSCRIPTION',
  CREATE_EVENT_SUBSCRIPTION_OK = 'CREATE_EVENT_SUBSCRIPTION_OK',
  CREATE_EVENT_SUBSCRIPTION_KO = 'CREATE_EVENT_SUBSCRIPTION_KO',

  REMOVE_REGISTRATION = 'REMOVE_REGISTRATION',
  REMOVE_REGISTRATION_OK = 'REMOVE_REGISTRATION_OK',
  REMOVE_REGISTRATION_KO = 'REMOVE_REGISTRATION_KO',

  REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION',
  REMOVE_SUBSCRIPTION_OK = 'REMOVE_SUBSCRIPTION_OK',
  REMOVE_SUBSCRIPTION_KO = 'REMOVE_SUBSCRIPTION_KO',

  UPLOAD_DOCUMENT_EVENT = 'UPLOAD_DOCUMENT_EVENT',
  UPLOAD_DOCUMENT_EVENT_OK = 'UPLOAD_DOCUMENT_EVENT_OK',
  UPLOAD_DOCUMENT_EVENT_KO = 'UPLOAD_DOCUMENT_EVENT_KO',

  DOWNLOAD_DOCUMENT_EVENT = 'DOWNLOAD_DOCUMENT_EVENT',
  DOWNLOAD_DOCUMENT_EVENT_OK = 'DOWNLOAD_DOCUMENT_EVENT_OK',
  DOWNLOAD_DOCUMENT_EVENT_KO = 'DOWNLOAD_DOCUMENT_EVENT_KO',

  GET_DOCUMENT_EVENT = 'GET_DOCUMENT_EVENT',
  GET_DOCUMENT_EVENT_OK = 'GET_DOCUMENT_EVENT_OK',
  GET_DOCUMENT_EVENT_KO = 'GET_DOCUMENT_EVENT_KO',

  REMOVE_DOCUMENT_EVENT = 'REMOVE_DOCUMENT_EVENT',
  REMOVE_DOCUMENT_EVENT_OK = 'REMOVE_DOCUMENT_EVENT_OK',
  REMOVE_DOCUMENT_EVENT_KO = 'REMOVE_DOCUMENT_EVENT_KO',

  GET_CONNECTION_URL = 'GET:CONNECTION:URL',
  GET_CONNECTION_URL_OK = 'GET:CONNECTION:URL:OK',
  GET_CONNECTION_URL_KO = 'GET:CONNECTION:URL:KO',

  GET_USER_REGISTRATION_DATA = 'GET:USER:REGISTRATION:DATA',
  GET_USER_REGISTRATION_DATA_OK = 'GET:USER:REGISTRATION:DATA:OK',
  GET_USER_REGISTRATION_DATA_KO = 'GET:USER:REGISTRATION:DATA:KO',
}
