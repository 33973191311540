import { APIError, APIValidationError } from '../../api/api';
import { ColumnOrderData, DocumentationItem, IdNamePair } from '../common/definitions';
import { TypeLang } from '../translations/definitions';
import { UserDataConsts } from './action_types';
import {
  AcceptUserEducatorAction,
  AcceptUserEducatorKoAction,
  AcceptUserEducatorOkAction,
  ChangePasswordAction,
  ChangePasswordData,
  ConfirmUserActiveRoleAction,
  ConfirmUserActiveRoleOkAction,
  CreateUserAction,
  CreateUserActionOk,
  CreateUserEducatorAction,
  CreateUserEducatorActionOk,
  CreateUserFields,
  DisableUserAction,
  DisableUserOkAction,
  DownloadDocumentAction,
  DownloadDocumentOkAction,
  GetAvatarUserByIdAction,
  GetAvatarUserByIdKoAction,
  GetAvatarUserByIdOkAction,
  GetDocumentAction,
  GetDocumentOkAction,
  GetUserDataAction,
  GetUserDataOkAction,
  GetUserDocumentationAction,
  GetUserDocumentationKoAction,
  GetUserDocumentationOkAction,
  GetUserEntitiesMetadata,
  GetUserEntitiesMetadataKo,
  GetUserEntitiesMetadataOk,
  GetUserRolesConfigAction,
  GetUserRolesConfigOkAction,
  GetUsersAction,
  GetUsersKoAction,
  GetUsersOkAction,
  InitNewUserAction,
  InitNewUserOkAction,
  RejectUserEducatorAction,
  RejectUserEducatorKoAction,
  RejectUserEducatorOkAction,
  RemoveDocumentAction,
  RemoveDocumentOkAction,
  RemoveEducatorDocumentAction,
  RemoveEducatorDocumentOkAction,
  RolePermission,
  SetAvatarUserByIdAction,
  SetAvatarUserByIdKoAction,
  SetAvatarUserByIdOkAction,
  SetUserCreatedAction,
  SetUserPermissionAction,
  SetUserPermissionOkAction,
  SignLopdAction,
  SignLopdOkAction,
  SuccessInterface,
  UpdateProfileEducator,
  UpdateProfileEducatorOk,
  UpdateProfileUserTableAction,
  UpdateProfileUserTableOkAction,
  UpdateUserFormFields,
  UpdateUserProfileAction,
  UpdateUserProfileOkAction,
  UploadDocumentAction,
  UploadDocumentOkAction,
  UploadEducatorDocumentAction,
  UploadEducatorDocumentOkAction,
  UserAddRoleAction,
  UserAddRoleOkAction,
  UserChangeLanguageAction,
  UserChangeLanguageOkAction,
  UserData,
  UserGenericKoAction,
  UserItemList,
  UserLinkAcademyAction,
  UserLinkAcademyKoAction,
  UserLinkAcademyOkAction,
  UserProfileAction,
  UserProfileOkAction,
  UserRemoveRoleAction,
  UserRemoveRoleOkAction,
  UserRole,
  UserRoleActiveAction,
  UserRoleActiveOkAction,
  UserRoleConfig,
  UserSetColumnOrderTableAction,
  UserSetDataAction,
  UserSetPageSizeTableAction,
  UserSetSearchTextTableAction,
  UserValidationErrorsAction,
} from './definitions';

// MY PROFILE GET

export function getProfile(): UserProfileAction {
  return {
    type: UserDataConsts.USER_PROFILE,
  };
}

export function getProfileOk(profileData: UserData): UserProfileOkAction {
  return {
    type: UserDataConsts.USER_PROFILE_OK,
    profileData,
  };
}

// USER PROFILE GET TABLE

export function getUserData(id: number): GetUserDataAction {
  return {
    type: UserDataConsts.GET_USER_DATA,
    id,
  };
}

export function getUserDataOk(profileData: UserData): GetUserDataOkAction {
  return {
    type: UserDataConsts.GET_USER_DATA_OK,
    profileData,
  };
}

export function signLopd(data: UpdateUserFormFields): SignLopdAction {
  return {
    type: UserDataConsts.SIGN_LOPD_ACTION,
    data,
  };
}

export function signLopdOk(profileData: UserData): SignLopdOkAction {
  return {
    type: UserDataConsts.SIGN_LOPD_ACTION_OK,
    profileData,
  };
}

export function updateProfile(data: UpdateUserFormFields): UpdateUserProfileAction {
  return {
    type: UserDataConsts.UPDATE_USER_PROFILE,
    data,
  };
}

export function updateProfileOk({ data }: { data: UserData }): UpdateUserProfileOkAction {
  return {
    type: UserDataConsts.UPDATE_USER_PROFILE_OK,
    profileData: data,
  };
}

// USERS LIST

export function getUsers(): GetUsersAction {
  return {
    type: UserDataConsts.GET_USERS,
  };
}

export function getUsersOk(usersList: UserItemList[]): GetUsersOkAction {
  return {
    type: UserDataConsts.GET_USERS_OK,
    usersList,
  };
}

export function getUsersKo(error: string): GetUsersKoAction {
  return {
    type: UserDataConsts.GET_USERS_KO,
    error,
  };
}

// DOCUMENTATION
export function uploadDocument(documentation_id: number, data: File, force_add?: boolean): UploadDocumentAction {
  return {
    type: UserDataConsts.UPLOAD_USER_DOCUMENT,
    documentation_id,
    data,
    force_add,
  };
}

export function uploadDocumentOk({ documents }: { documents: DocumentationItem[] }): UploadDocumentOkAction {
  return {
    type: UserDataConsts.UPLOAD_USER_DOCUMENT_OK,
    documents,
  };
}

export function uploadEducatorDocument(
  data: File,
  documentation_id: number,
  fakeMultiUpload?: boolean,
): UploadEducatorDocumentAction {
  return {
    type: UserDataConsts.UPLOAD_EDUCATOR_DOCUMENT,
    documentation_id,
    data,
    fakeMultiUpload,
  };
}

export function uploadEducatorDocumentOk(
  document: DocumentationItem,
  documentation_id: number,
  fakeMultiUpload?: boolean,
): UploadEducatorDocumentOkAction {
  return {
    type: UserDataConsts.UPLOAD_EDUCATOR_DOCUMENT_OK,
    documentation_id,
    document,
    fakeMultiUpload,
  };
}

export function removeDocument(file_id: number): RemoveDocumentAction {
  return {
    type: UserDataConsts.REMOVE_USER_DOCUMENT,
    file_id,
  };
}

export function removeDocumentOk({ documents }: { documents: DocumentationItem[] }): RemoveDocumentOkAction {
  return {
    type: UserDataConsts.REMOVE_USER_DOCUMENT_OK,
    documents,
  };
}

// REMOVE FOR EDUCATOR

export function removeEducatorDocument(file_id: number): RemoveEducatorDocumentAction {
  return {
    type: UserDataConsts.REMOVE_EDUCATOR_DOCUMENT,
    file_id,
  };
}

export function removeEducatorDocumentOk(file_id: number): RemoveEducatorDocumentOkAction {
  return {
    type: UserDataConsts.REMOVE_EDUCATOR_DOCUMENT_OK,
    file_id,
  };
}

export function downloadDocument(file_id: number, name: string): DownloadDocumentAction {
  return {
    type: UserDataConsts.DOWNLOAD_USER_DOCUMENT,
    file_id,
    name,
  };
}

export function downloadDocumentOk(data: Blob, name: string): DownloadDocumentOkAction {
  return {
    type: UserDataConsts.DOWNLOAD_USER_DOCUMENT_OK,
    data,
    name,
  };
}

export function getDocument(file_id: number, name: string): GetDocumentAction {
  return {
    type: UserDataConsts.GET_USER_DOCUMENT,
    file_id,
    name,
  };
}

export function getDocumentOk(dataDoc: Blob, name: string): GetDocumentOkAction {
  return {
    type: UserDataConsts.GET_USER_DOCUMENT_OK,
    dataDoc,
    name,
  };
}

// USER SET PAGE

export function setPageSize(pageSize: number): UserSetPageSizeTableAction {
  return {
    type: UserDataConsts.USER_SET_PAGESIZE_TABLE_OPTIONS,
    pageSize,
  };
}

export function setSearchText(searchText: string): UserSetSearchTextTableAction {
  return {
    type: UserDataConsts.USER_SET_SEARCHTEXT_TABLE_OPTIONS,
    searchText,
  };
}

export function setColumnOrder(columnOrder: ColumnOrderData): UserSetColumnOrderTableAction {
  return {
    type: UserDataConsts.USER_SET_COLUMNORDER_TABLE_OPTIONS,
    columnOrder,
  };
}

// UPDATE PROFILE USER TABLE

export function updateProfileUserTable(data: UpdateUserFormFields, id: number): UpdateProfileUserTableAction {
  return {
    type: UserDataConsts.UPDATE_PROFILE_USER_TABLE,
    id,
    data,
  };
}

export function updateProfileUserTableOk({ data }: { data: UserData }): UpdateProfileUserTableOkAction {
  return {
    type: UserDataConsts.UPDATE_PROFILE_USER_TABLE_OK,
    profileData: data,
  };
}

// CREATE USER

export function createUser(data: CreateUserFields): CreateUserAction {
  return {
    type: UserDataConsts.CREATE_USER,
    data,
  };
}

export function createUserOk(): CreateUserActionOk {
  return {
    type: UserDataConsts.CREATE_USER_OK,
  };
}

export function userValidationErrors(validationErrors: APIValidationError): UserValidationErrorsAction {
  return {
    type: UserDataConsts.USER_VALIDATION_ERRORS,
    validationErrors,
  };
}

// GET DOCUMENTATION

export function getUserDocumentation(id: number): GetUserDocumentationAction {
  return {
    type: UserDataConsts.GET_USER_DOCUMENTATION,
    id,
  };
}

export function getUserDocumentationOk(documentation: DocumentationItem[]): GetUserDocumentationOkAction {
  return {
    type: UserDataConsts.GET_USER_DOCUMENTATION_OK,
    documentation,
  };
}

export function getUserDocumentationKo(error: APIError): GetUserDocumentationKoAction {
  return {
    type: UserDataConsts.GET_USER_DOCUMENTATION_KO,
    error,
  };
}

// GET NEW USER

export function initNewUser(): InitNewUserAction {
  return {
    type: UserDataConsts.INIT_NEW_USER,
  };
}

export function initNewUserOk(documentation: DocumentationItem[]): InitNewUserOkAction {
  return {
    type: UserDataConsts.INIT_NEW_USER_OK,
    documentation,
  };
}

export function changePassword(data: ChangePasswordData): ChangePasswordAction {
  return {
    type: UserDataConsts.CHANGE_PASSWORD,
    data,
  };
}

// ACCEPT USER EDUCATOR

export function acceptUserEducator(id: number): AcceptUserEducatorAction {
  return {
    type: UserDataConsts.ACCEPT_USER_EDUCATOR,
    id,
  };
}

export function acceptUserEducatorOk(data: SuccessInterface): AcceptUserEducatorOkAction {
  return {
    type: UserDataConsts.ACCEPT_USER_EDUCATOR_OK,
    responseValidation: data,
  };
}

export function acceptUserEducatorKo(error: APIError): AcceptUserEducatorKoAction {
  return {
    type: UserDataConsts.ACCEPT_USER_EDUCATOR_KO,
    error,
  };
}

export function getUserEntitiesMetadata(entity_type: 'project' | 'scholarship' | 'all'): GetUserEntitiesMetadata {
  return {
    type: UserDataConsts.USER_ENTITIES_METADATA,
    entity_type,
  };
}

export function getUserEntitiesMetadataOk(entitiesMetadata: IdNamePair[]): GetUserEntitiesMetadataOk {
  return {
    type: UserDataConsts.USER_ENTITIES_METADATA_OK,
    entitiesMetadata,
  };
}

export function getUserEntitiesMetadataKo(error: string): GetUserEntitiesMetadataKo {
  return {
    type: UserDataConsts.USER_ENTITIES_METADATA_KO,
    error,
  };
}

// REJECT USER EDUCATOR

export function rejectUserEducator(id: number): RejectUserEducatorAction {
  return {
    type: UserDataConsts.REJECT_USER_EDUCATOR,
    id,
  };
}

export function rejectUserEducatorOk(data: SuccessInterface): RejectUserEducatorOkAction {
  return {
    type: UserDataConsts.REJECT_USER_EDUCATOR_OK,
    responseValidation: data,
  };
}

export function rejectUserEducatorKo(error: APIError): RejectUserEducatorKoAction {
  return {
    type: UserDataConsts.REJECT_USER_EDUCATOR_KO,
    error,
  };
}

export function updateProfileEducator(data: UserData): UpdateProfileEducator {
  return {
    type: UserDataConsts.UPDATE_PROFILE_EDUCATOR,
    data,
  };
}

export function updateProfileEducatorOk({ data }: { data: UserData }): UpdateProfileEducatorOk {
  return {
    type: UserDataConsts.UPDATE_PROFILE_EDUCATOR_OK,
    data,
  };
}

export function createUserEducator(data: CreateUserFields): CreateUserEducatorAction {
  return {
    type: UserDataConsts.CREATE_USER_EDUCATOR,
    data,
  };
}

export function createUserEducatorOk(): CreateUserEducatorActionOk {
  return {
    type: UserDataConsts.CREATE_USER_EDUCATOR_OK,
  };
}

export function getAvatarById(id: number): GetAvatarUserByIdAction {
  return {
    type: UserDataConsts.GET_FOREING_AVATAR,
    id,
  };
}

export function getAvatarByIdOk(userAvatar: string): GetAvatarUserByIdOkAction {
  return {
    type: UserDataConsts.GET_FOREING_AVATAR_OK,
    userAvatar,
  };
}

export function getAvatarByIdKo(error: string): GetAvatarUserByIdKoAction {
  return {
    type: UserDataConsts.GET_FOREING_AVATAR_KO,
    error,
  };
}

export function setAvatarById(userAvatar: string, id: number): SetAvatarUserByIdAction {
  return {
    type: UserDataConsts.SET_FOREING_AVATAR,
    userAvatar,
    id,
  };
}

export function setAvatarByIdOk(userAvatar: string): SetAvatarUserByIdOkAction {
  return {
    type: UserDataConsts.SET_FOREING_AVATAR_OK,
    userAvatar,
  };
}

export function setAvatarByIdKo(error: string): SetAvatarUserByIdKoAction {
  return {
    type: UserDataConsts.SET_FOREING_AVATAR_KO,
    error,
  };
}

export function userSetData(key: string, value: string | number | boolean): UserSetDataAction {
  return {
    type: UserDataConsts.USER_SET_DATA,
    key,
    value,
  };
}

export function setUserCreated(userCreated: boolean): SetUserCreatedAction {
  return {
    type: UserDataConsts.USER_SET_USER_CREATED,
    userCreated,
  };
}
//DISABLE USERS

export function disableUser(id: number): DisableUserAction {
  return {
    type: UserDataConsts.DISABLE_USER,
    id,
  };
}

export function disableUserOk(): DisableUserOkAction {
  return {
    type: UserDataConsts.DISABLE_USER_OK,
  };
}

export function userLinkAcademyAction(userId: number): UserLinkAcademyAction {
  return {
    type: UserDataConsts.USER_LINK_ACADEMY,
    userId,
  };
}

export function userLinkAcademyOk(): UserLinkAcademyOkAction {
  return {
    type: UserDataConsts.USER_LINK_ACADEMY_OK,
  };
}

export function userLinkAcademyKo(error: APIError): UserLinkAcademyKoAction {
  return {
    type: UserDataConsts.USER_LINK_ACADEMY_KO,
    error,
  };
}

export function getUserRolesConfig(user_id: number): GetUserRolesConfigAction {
  return {
    type: UserDataConsts.GET_USER_ROLES_CONFIG,
    user_id,
  };
}

export function getUserRolesConfigOk(userRolesConfig: UserRoleConfig): GetUserRolesConfigOkAction {
  return {
    type: UserDataConsts.GET_USER_ROLES_CONFIG_OK,
    userRolesConfig,
  };
}

export function confirmUserActiveRole(user_id: number, role_id: number): ConfirmUserActiveRoleAction {
  return {
    type: UserDataConsts.CONFIRM_USER_ACTIVE_ROLE,
    user_id,
    role_id,
  };
}

export function confirmUserActiveRoleOk(): ConfirmUserActiveRoleOkAction {
  return {
    type: UserDataConsts.CONFIRM_USER_ACTIVE_ROLE_OK,
  };
}

export function userGenericKo(error: APIError): UserGenericKoAction {
  return {
    type: UserDataConsts.USER_GENERIC_KO,
    error,
  };
}

export function userAddRole(user_id: number, role: UserRole): UserAddRoleAction {
  return {
    type: UserDataConsts.USER_ADD_ROLE,
    user_id,
    role,
  };
}

export function userAddRoleOk(role: UserRole): UserAddRoleOkAction {
  return {
    type: UserDataConsts.USER_ADD_ROLE_OK,
    role,
  };
}

export function userRoleActive(role_id: number, is_active: boolean): UserRoleActiveAction {
  return {
    type: UserDataConsts.USER_ROLE_ACTIVE,
    role_id,
    is_active,
  };
}

export function userRoleActiveOk(
  role_id: number,
  is_active: boolean,
  rolePermissions: RolePermission[],
): UserRoleActiveOkAction {
  return {
    type: UserDataConsts.USER_ROLE_ACTIVE_OK,
    role_id,
    is_active,
    rolePermissions,
  };
}

export function userRemoveRole(user_id: number, role_id: number): UserRemoveRoleAction {
  return {
    type: UserDataConsts.USER_REMOVE_ROLE,
    user_id,
    role_id,
  };
}

export function userRemoveRoleOk(role_id: number): UserRemoveRoleOkAction {
  return {
    type: UserDataConsts.USER_REMOVE_ROLE_OK,
    role_id,
  };
}

export function setUserPermission(user_id: number, permission_id: number, value: boolean): SetUserPermissionAction {
  return {
    type: UserDataConsts.SET_USER_PERMISSION,
    user_id,
    permission_id,
    value,
  };
}

export function setUserPermissionOk(permission_id: number, value: boolean): SetUserPermissionOkAction {
  return {
    type: UserDataConsts.SET_USER_PERMISSION_OK,
    permission_id,
    value,
  };
}

export function userChangeLanguage(language: TypeLang): UserChangeLanguageAction {
  return {
    type: UserDataConsts.USER_CHANGE_LANGUAGE,
    language,
  };
}

export function userChangeLanguageOk(language: TypeLang): UserChangeLanguageOkAction {
  return {
    type: UserDataConsts.USER_CHANGE_LANGUAGE_OK,
    language,
  };
}
