import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LayoutLogin from '../../components/Layout/LayoutLogin';
import ForgotPasswordForm from '../../components/Login/ForgotPasswordForm/ForgotPasswordForm';
import LoginForm from '../../components/Login/LoginForm/LoginForm';
import { tFormKey, tKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import { clearError } from '../../redux/auth/actions';
import styles from './login.module.scss';
import Language from '../Language/Language';
import { AppState } from '../../redux/root-reducer';
import { selectRoles } from '../../redux/register/selectors';
import { getRoles, RolesProps } from '../../redux/register/actions';

interface LoginProps {
  roles: RolesProps[];
  clearError: () => void;
  getRoles: () => void;
}

const Login: React.FC<LoginProps> = ({ roles, getRoles, clearError }) => {
  const [step, setStep] = useState('LOGIN' as 'LOGIN' | 'FORGOT');

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const goToLogin = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearError();
    setStep('LOGIN');
  };

  const goToForgot = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    clearError();
    setStep('FORGOT');
  };

  const Actions = () => (
    <div className={styles[`actions_${webStyle}`]}>
      {webStyle === 'zing' && roles.length > 0 && (
        <Link onClick={() => clearError()} to="/registro">
          {tFormKey('Registro')}
        </Link>
      )}

      {step !== 'LOGIN' && (
        <a href="/#" onClick={e => goToLogin(e)}>
          {tKey('Iniciar sesión')}
        </a>
      )}
      {step !== 'FORGOT' && (
        <a href="/#" onClick={e => goToForgot(e)}>
          {tKey('Recuperar datos de acceso')}
        </a>
      )}
      {/* <a href="/ayuda">Ayuda</a> */}
    </div>
  );

  return (
    <LayoutLogin subtitle={tKey('Acceso usuarios registrados')}>
      <>
        <div className={styles.login}>
          {step === 'LOGIN' && <LoginForm />}
          {step === 'FORGOT' && <ForgotPasswordForm />}
          <Actions />
        </div>
        {step === 'LOGIN' && <Language />}
      </>
    </LayoutLogin>
  );
};

const mapStateToProps = (state: AppState) => ({
  roles: selectRoles(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  clearError: (): void => dispatch(clearError()),
  getRoles: () => dispatch(getRoles(false)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
