import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNearScreen } from '../../../helpers/customHooks/useNearScreen';
import { tKey } from '../../../helpers/translate';
import { ProfileInfo } from '../../../redux/directory/definitions';
import { AppState } from '../../../redux/root-reducer';
import styles from '../ListZing/listZing.module.scss';
import ListItemZing from './ListItemZing';

interface ListZingProps {
  details: ProfileInfo[];
  loading: boolean;
  onDetailModal: (detail: ProfileInfo) => void;
  debounceHandleNextpage: () => void;
}

const ListZing: React.FC<ListZingProps> = ({ details, loading, onDetailModal, debounceHandleNextpage }) => {
  const externalRef = useRef(null);

  const { isNearScreen } = useNearScreen({
    externalRef: externalRef,
    once: false,
    distance: '100px',
    root: 'main',
  });

  useEffect(() => {
    if (isNearScreen) debounceHandleNextpage();
  }, [debounceHandleNextpage, isNearScreen]);

  if (loading && !details.length) return <p className={styles.noResultsText}>{tKey('Buscando...')}</p>;

  if (!details.length) return <p className={styles.noResultsText}>{tKey('No hay resultados')}</p>;

  return (
    <div>
      <ul className={styles.list}>
        <ListItemZing onDetailModal={onDetailModal} data={details} loading={loading} />
      </ul>
      <div id="visor" ref={externalRef}></div>
    </div>
  );
};

const listZingToProps = (state: AppState) => ({
  details: state.directoryReducer.userData,
  loading: state.directoryReducer.loading,
});

export default connect(listZingToProps)(ListZing);
