import { AppState } from '../root-reducer';
import { SignatureDocument, SignatureDocumentState } from './definitions';

const selectSignatureDocument = (state: AppState): SignatureDocumentState => state.signatureDocumentReducer;
export const selectSignatureDocuments = (state: AppState): SignatureDocument[] =>
  selectSignatureDocument(state).signatureDocuments;
export const selectSignatureDocumentLoading = (state: AppState): boolean => selectSignatureDocument(state).loading;
export const selectSignatureDocumentError = (state: AppState): string | null => selectSignatureDocument(state).error;
export const selectSignatureDocumentMessage = (state: AppState): string | null =>
  selectSignatureDocument(state).infoMessage;
export const selectSignatureDocumentSending = (state: AppState): string | null =>
  selectSignatureDocument(state).sending;
