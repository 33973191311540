import {
  selectErrorKey,
  selectFormtKey,
  selectGenericKey,
  selectProjectKey,
  selectScholarshipKey,
} from '../redux/translations/selectors';
import storeProvider from '../storeProvider';

export const tKey = (key: string) => {
  const state = storeProvider.getState();
  if (state) {
    return selectGenericKey(state, key);
  }
  return key;
};

export const tScholarshipKey = (key: string) => {
  const state = storeProvider.getState();
  if (state) {
    return selectScholarshipKey(state, key);
  }
  return key;
};

export const tProjectKey = (key: string) => {
  const state = storeProvider.getState();
  if (state) {
    return selectProjectKey(state, key);
  }
  return key;
};

export const tFormKey = (key: string) => {
  const state = storeProvider.getState();
  if (state) {
    return selectFormtKey(state, key);
  }
  return key;
};

export const tErrorKey = (key: string) => {
  const state = storeProvider.getState();
  if (state) {
    return selectErrorKey(state, key);
  }
  return key;
};
