import { ApplicationConsts } from './action_types';
import { ApplicationShowMenuAction, ApplicationShowMenuMobileAction } from './definitions';

export function applicationShowMenu(show: boolean): ApplicationShowMenuAction {
  return {
    type: ApplicationConsts.SHOW_MENU,
    show,
  };
}

export function applicationShowMenuMobile(show: boolean): ApplicationShowMenuMobileAction {
  return {
    type: ApplicationConsts.SHOW_MENU_MOBILE,
    show,
  };
}
