/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, putResolve, takeLatest } from '@redux-saga/core/effects';
import {
  cancelSignatureDocumentFromApi,
  downloadSignatureDocumentFromApi,
  getSignatureDocumentsFromApi,
  removeSignatureDocumentFromApi,
  sendSignatureDocumentFromApi,
} from '../../api/SignatureDocument/signatureDocument';
import errorMessage from '../../helpers/errorMessage';
import { SignatureDocumentDataConsts } from './action_types';
import {
  cancelSignatureDocumentKo,
  cancelSignatureDocumentOk,
  downloadDocumentKo,
  downloadDocumentOk,
  getSignatureDocumentsKoAction,
  getSignatureDocumentsOkAction,
  removeDocumentKo,
  removeDocumentOk,
  sendSignatureDocumentKo,
  sendSignatureDocumentOk,
  uploadDocumentKo,
  uploadDocumentOk,
} from './actions';

import {
  GetSignatureDocumentsAction,
  UploadSignatureDocumentAction,
  RemoveSignatureDocumentAction,
  DownloadSignatureDocumentAction,
  SendSignatureDocumentAction,
  CancelSignatureDocumentAction,
} from './definitions';
import { showSnackbar } from '../FeedbackAPI/actions';
import { uploadDocumentation } from '../../api/SignatureDocument/signatureDocument';

function* getSignatureDocumentFromSaga(action: GetSignatureDocumentsAction): Generator<any, void, any> {
  try {
    const documents = yield getSignatureDocumentsFromApi(action.source_type, action.type_id);
    yield putResolve(getSignatureDocumentsOkAction(documents));
  } catch (e) {
    const error = e as ErrorEvent;
    yield put(getSignatureDocumentsKoAction(errorMessage(error.message)));
  }
}

function* uploadSignatureDocumentFromSaga(action: UploadSignatureDocumentAction): Generator<any, void, any> {
  try {
    const { uuid, file, type_id, document_type } = action;
    const fileData = yield uploadDocumentation(uuid, file, type_id, document_type);
    yield put(uploadDocumentOk(fileData));
    yield put(showSnackbar('El documento se ha subido correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(uploadDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha ocurrido algún problema al subir el documento', 'error', undefined, 1500));
  }
}

function* removeSignatureDocumentFromSaga(action: RemoveSignatureDocumentAction): Generator<any, void, any> {
  try {
    yield removeSignatureDocumentFromApi(action.id);
    yield put(removeDocumentOk(action.id));
    yield put(showSnackbar('Documento borrado correctamente', 'success'));
  } catch (e) {
    yield put(removeDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al borrar el archivo', 'error'));
  }
}

function* downloadDocumentFromSaga(action: DownloadSignatureDocumentAction): Generator<any, void, any> {
  try {
    const { file_id, name } = action;
    const data = yield downloadSignatureDocumentFromApi(file_id);
    yield put(downloadDocumentOk(data, name));
  } catch (e) {
    yield put(downloadDocumentKo(errorMessage(e)));
  }
}

function* sendSignatureDocumentFromSaga(action: SendSignatureDocumentAction): Generator<any, void, any> {
  try {
    const { signature_id } = yield sendSignatureDocumentFromApi(action.id);
    yield put(sendSignatureDocumentOk(action.id, signature_id));
    yield put(showSnackbar('Documento enviado correctamente', 'success'));
  } catch (e) {
    yield put(sendSignatureDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al enviar el documento', 'error'));
  }
}

function* cancelSignatureDocumentFromSaga(action: CancelSignatureDocumentAction): Generator<any, void, any> {
  try {
    const { signature_id } = yield cancelSignatureDocumentFromApi(action.id);
    yield put(cancelSignatureDocumentOk(action.id, signature_id));
    yield put(showSnackbar('Documento cancelado correctamente', 'success'));
  } catch (e) {
    yield put(cancelSignatureDocumentKo(errorMessage(e)));
    yield put(showSnackbar('Ha habido un error al cancelar el documento', 'error'));
  }
}

export default [
  takeLatest<GetSignatureDocumentsAction>(
    SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET,
    getSignatureDocumentFromSaga,
  ),
  takeLatest<UploadSignatureDocumentAction>(
    SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT,
    uploadSignatureDocumentFromSaga,
  ),
  takeLatest<RemoveSignatureDocumentAction>(
    SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT,
    removeSignatureDocumentFromSaga,
  ),
  takeLatest<DownloadSignatureDocumentAction>(
    SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT,
    downloadDocumentFromSaga,
  ),
  takeLatest<SendSignatureDocumentAction>(
    SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT,
    sendSignatureDocumentFromSaga,
  ),
  takeLatest<CancelSignatureDocumentAction>(
    SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT,
    cancelSignatureDocumentFromSaga,
  ),
];
