import { useState } from 'react';
import errorMessage from '../../../../helpers/errorMessage';
import { tErrorKey } from '../../../../helpers/translate';

export type ScholarshipPaymentReplaceDates = {
  find: moment.Moment | null;
  find_error: string | null;
  replace: moment.Moment | null;
  replace_error: string | null;
};

type useScholarshipPaymentReplaceDatesType = {
  replaceModal: boolean;
  replaceDates: ScholarshipPaymentReplaceDates;
  openReplaceModal: () => void;
  closeReplaceModal: () => void;
  setFindDate: (date: moment.Moment | null) => void;
  setReplaceDate: (date: moment.Moment | null) => void;
};

const useScholarshipPaymentReplaceDates = (): useScholarshipPaymentReplaceDatesType => {
  const error_message = tErrorKey('La fecha no es correcta');
  const [replaceModal, setReplaceModal] = useState(false);
  const [replaceDates, setReplaceDates] = useState<ScholarshipPaymentReplaceDates>({
    find: null,
    find_error: null,
    replace: null,
    replace_error: null,
  });

  const checkDate = (date: moment.Moment | null): moment.Moment | null => {
    if (date) {
      if (date.isValid()) {
        return date;
      }
      throw Error(error_message);
    }
    return null;
  };
  const openReplaceModal = () => {
    setReplaceModal(true);
  };

  const closeReplaceModal = () => {
    setReplaceModal(false);
  };

  const setFindDate = (date: moment.Moment | null) => {
    try {
      const date_checked = checkDate(date);
      setReplaceDates({ ...replaceDates, find: date_checked, find_error: null });
    } catch (e) {
      setReplaceDates({ ...replaceDates, find_error: errorMessage(e) });
    }
  };

  const setReplaceDate = (date: moment.Moment | null) => {
    try {
      const date_checked = checkDate(date);
      setReplaceDates({ ...replaceDates, replace: date_checked, replace_error: null });
    } catch (e) {
      setReplaceDates({ ...replaceDates, replace_error: errorMessage(e) });
    }
  };

  return {
    replaceModal,
    replaceDates,
    openReplaceModal,
    closeReplaceModal,
    setFindDate,
    setReplaceDate,
  };
};

export default useScholarshipPaymentReplaceDates;
