import { AppState } from '../../../root-reducer';
import { Participant, ParticipantId } from '../Participant';
import { GetParticipantsState } from './definitions';

const selectParticipants = (state: AppState): GetParticipantsState => state.getParticipantsReducer;

export const selectParticipantsLoading = (state: AppState): boolean => selectParticipants(state).loading;

export const selectParticipantsError = (state: AppState): string | null => selectParticipants(state).error;

export const selectParticipantsList = (state: AppState): Participant[] => selectParticipants(state).data;

export const selectParticipantById = (state: AppState, id: ParticipantId): Participant =>
  selectParticipants(state).data.filter(item => item.id === id)[0];
