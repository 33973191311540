import { MaterialTableProps } from 'material-table';
import React from 'react';
import { tKey, tProjectKey } from '../../../../../helpers/translate';
import { DailyFollowsTable } from '../../../../../redux/internal-projects/definitions';
import InformationMinimal from '../Information/InformationMinimal';
import { useHistory } from 'react-router-dom';
import { useTableWithSearch } from '../../../../../helpers/customHooks/useTableWithSearch';
import InputSearch from '../../../../../components/Inputs/InputSearch/InputSearch';
import MobileTable from '../../../../../components/Tables/MobileTable';
import FncButton from '../../../../../components/FncButton/FncButton';
import LayoutMobile from '../../../../../components/Layout/LayoutMobile';

interface MobileDailyFollowsViewProps {
  tableData: MaterialTableProps<DailyFollowsTable>;
  dailyFollows: DailyFollowsTable[];
  addDailyFollowsModal: any;
  createNew: () => void;
  handleEditMember: (e: any, rowData: DailyFollowsTable | DailyFollowsTable[]) => void;
}

const MobileDailyFollowsView: React.FC<MobileDailyFollowsViewProps> = ({
  tableData,
  dailyFollows,
  createNew,
  handleEditMember,
}) => {
  const history = useHistory();

  const propertyExtractor = (item: DailyFollowsTable) => {
    return `${item.modality_name.toLowerCase()} ${item.follow_date.toLowerCase()}`;
  };

  const { filteredData, handleSearch } = useTableWithSearch<DailyFollowsTable>({
    initialData: dailyFollows,
    propertyExtractor: propertyExtractor,
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleMobileEditMember = (rowData: DailyFollowsTable | DailyFollowsTable[]) => {
    handleEditMember(null, rowData);
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function handleClickNew() {
    createNew();
  }

  return (
    <LayoutMobile onBack={() => history.push('/proyectos-internos')} title={tKey('ip.seguimientos_diarios')}>
      <InformationMinimal />
      <FncButton onClick={handleClickNew} fullWidth={true}>
        {tProjectKey('Nuevo seguimiento')}
      </FncButton>
      <InputSearch onSearch={handleSearch} />
      <MobileTable
        tableData={{ ...tableData, data: filteredData }}
        columnsFilter={['follow_date', 'modality_name']}
        onRowClick={handleMobileEditMember}
      />
    </LayoutMobile>
  );
};

export default MobileDailyFollowsView;
