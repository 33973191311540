export enum EducatorConst {
  ADD_EDUCATOR = 'ADD:EDUCATOR',
  ADD_EDUCATOR_OK = 'ADD:EDUCATOR:OK',
  ADD_EDUCATOR_KO = 'ADD:EDUCATOR:KO',

  GET_EDUCATORS = 'GET:IP:EDUCATORS',
  GET_EDUCATORS_OK = 'GET:IP:EDUCATORS:OK',
  GET_EDUCATORS_KO = 'GET:IP:EDUCATORS:KO',

  DELETE_EDUCATORS = 'DELETE:EDUCATORS',
  DELETE_EDUCATORS_OK = 'DELETE:EDUCATORS:OK',
  DELETE_EDUCATORS_KO = 'DELETE:EDUCATORS:KO',

  UPDATE_EDUCATOR = 'UPDATE:EDUCATOR',
  UPDATE_EDUCATOR_OK = 'UPDATE:EDUCATOR:OK',
  UPDATE_EDUCATOR_KO = 'UPDATE:EDUCATOR:KO',
}
