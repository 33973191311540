import { Event, SettingsApplications } from '@material-ui/icons';
import React from 'react';
import Submenu, { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../helpers/translate';

interface ZingNetworkSubmenuProps {
  selected: 'services' | 'events';
}

const ZingNetworkSubmenu: React.FC<ZingNetworkSubmenuProps> = ({ selected }) => {
  const optionsZingNetworkSubmenu: SubmenuItem[] = [
    {
      title: tKey('Eventos'),
      icon: <Event />,
      route: '/zing-network/eventos',
      type: 'route',
      selected: selected === 'events',
    },
    {
      title: tKey('Servicios'),
      route: '/zing-network/servicios',
      type: 'route',
      icon: <SettingsApplications />,
      selected: selected === 'services',
    },
  ];
  return <Submenu titleMenu="Zing Network" optionsMenu={optionsZingNetworkSubmenu} defaultOpen />;
};

export default ZingNetworkSubmenu;
