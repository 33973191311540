import { ButtonProps } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import React from 'react';
import ModalProfile from '../../../components/Modal/ModalProfile/ModalProfile';
import { getAge } from '../../../helpers/getAge';
import { tKey } from '../../../helpers/translate';
import { ProfileInfo } from '../../../redux/directory/definitions';
import styles from './profileZing.module.scss';
import defaultImage from '../../../images/default-directory-avatar.png';

interface ModalProfileZingProps {
  profile: ProfileInfo;
  open: boolean;
  buttons: ButtonProps[];
  onClose: () => void;
}

const ModalProfileZing: React.FC<ModalProfileZingProps> = ({ profile, open, buttons, onClose }) => {
  const { avatar_file, name, city, country_origin, last_scholarship_data, professional_info, birthdate } = profile;
  const age = birthdate ? `${getAge(birthdate)} ${tKey('años')}` : 'No hay datos';

  return (
    <ModalProfile open={open} onClose={onClose} title="">
      {profile && (
        <div className={styles.profile}>
          <img className={styles.media} src={avatar_file || defaultImage} alt="avatar" />

          <div className={styles.data}>
            <h3 className={styles.name} title="name">
              {name}
            </h3>
            <ul className={styles.list}>
              <li className={styles.listItem} title="age">
                <strong>{tKey('Edad')}:</strong> {age}
              </li>
              <li className={styles.listItem} title="city">
                <strong>{tKey('Reside en')}:</strong> {city}
              </li>
              <li className={styles.listItem} title="country_origin">
                <strong>{tKey('Origen')}:</strong> {country_origin}
              </li>
            </ul>
            <h4 className={styles.title}>{tKey('Formación')}</h4>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                {tKey('¿Actualmente estudia?')} <strong>{tKey('Sí')}</strong>
              </li>
              {last_scholarship_data?.educational_type ? (
                <>
                  <li className={styles.listItem}>
                    Últimos estudios realizados:{' '}
                    <ul className={styles.list}>
                      <li className={styles.listItem} title="academic_year">
                        <strong>{last_scholarship_data?.academic_year}</strong>
                      </li>
                      <li className={styles.listItem} title="leve">
                        <strong>{last_scholarship_data?.level}</strong>
                      </li>
                      <li className={styles.listItem} title="educational_type">
                        <strong>{last_scholarship_data?.educational_type}</strong>
                      </li>
                      <li className={styles.listItem} title="course">
                        <strong>{last_scholarship_data?.course}</strong>
                      </li>
                    </ul>
                  </li>
                </>
              ) : null}
            </ul>
            {professional_info?.length ? (
              <>
                <h4 className={styles.title}>{tKey('Información Profesional')}</h4>
                <ul className={styles.list}>
                  {professional_info?.map((info, index) => (
                    <>
                      <li className={styles.listItem}>
                        <ul className={styles.list}>
                          <li key={index} className={styles.listItem}>
                            {info.academic_year}
                          </li>
                          <li key={index} className={styles.listItem}>
                            {info.company_name ? tKey('zing.empresa').concat(': ') : ''}
                            <strong>{info.company_name}</strong>
                          </li>
                          <li key={index} className={styles.listItem}>
                            {info.employee_role ? tKey('zing.cargo').concat(': ', info.employee_role) : ''}
                          </li>
                        </ul>
                      </li>
                    </>
                  ))}
                </ul>
              </>
            ) : null}
            {last_scholarship_data?.scholarship_academy ? (
              <>
                <h4 className={styles.title}>{tKey('Otros')}</h4>
                <ul className={styles.list}>
                  <li className={styles.listItem}>
                    {tKey('Entidad/Insitituo')}: {last_scholarship_data?.scholarship_academy}
                  </li>
                </ul>
              </>
            ) : null}
            <DialogActions className={styles.actions}>
              {buttons.map(({ children, ...props }, i) => (
                <Button key={i} disableElevation {...props}>
                  {children}
                </Button>
              ))}
            </DialogActions>
          </div>
        </div>
      )}
    </ModalProfile>
  );
};

export default ModalProfileZing;
