import React from 'react';
import styles from './buttonsActionsMobile.module.scss';
import FncButton, { FncButtonProps } from '../../../components/FncButton/FncButton';
import { tKey } from '../../../helpers/translate';
import { useHistory } from 'react-router-dom';

interface Props {
  butonsActions: FncButtonProps[];
  onGoBack?: () => void;
}

const ButtonsActionsMobile = ({ butonsActions, onGoBack }: Props) => {
  const history = useHistory();
  return (
    <ul className={styles.root}>
      {butonsActions.map((button: FncButtonProps) => {
        if (!button.hidden) {
          return (
            <li key={Math.random()} className={styles.item}>
              <FncButton
                key={Math.random()}
                customClass={styles.button}
                buttonStyle={button.buttonStyle}
                onClick={button.onClick}
                disabled={button.disabled}
                hidden={button.hidden}
              >
                {button.children}
              </FncButton>
            </li>
          );
        }
        return <></>;
      })}
      <li className={styles.item}>
        <FncButton
          customClass={styles.button}
          buttonStyle="cancel"
          key="goBackButton"
          onClick={() => (onGoBack ? onGoBack() : history.go(-1))}
        >
          {tKey('Cancelar')}
        </FncButton>
      </li>
    </ul>
  );
};
export default ButtonsActionsMobile;
