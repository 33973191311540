import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../../../constants/routes';
import { tKey } from '../../../../helpers/translate';

interface Props {
  item: AppRoute;
  onClick?: () => void;
}

const MenuItem: React.FC<Props> = ({ item, onClick }) => (
  <Link to={item.path} onClick={onClick}>
    {item.icon}
    <span>{tKey(item.title || '')}</span>
  </Link>
);

export default MenuItem;
