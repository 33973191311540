import { Delete, Edit, FileCopy } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import TransitionModal from '../../../../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../../../../components/Tables/TableWithEdit';
import { tFormKey, tKey } from '../../../../../../helpers/translate';
import { CodeNamePair, IdNamePair } from '../../../../../../redux/common/definitions';
import { deleteInternalProjectTemplateFollowUp } from '../../../../../../redux/internal-project-templates/actions';
import {
  FollowUpData,
  InternalProjectTemplateDispatch,
} from '../../../../../../redux/internal-project-templates/definitions';
import { AppState } from '../../../../../../redux/root-reducer';
import styles from './internalProjectTemplateFollowUps.module.scss';
import InternalProjectTemplateFollowUpsEdition from './InternalProjectTemplateFollowUpsEdition';
import DuplicateFollowUpModal from './DuplicateFollowUpModal';

interface InternalProjectTemplateFollowUpsProps {
  loading: boolean;
  followUpTypes: CodeNamePair[];
  followUps: FollowUpData[];
  reloadInternalProjectTemplateInfo: () => void;
  previousFollowUpSelected: FollowUpData | undefined;
  roles: IdNamePair[];
  deleteInternalProjectTemplateFollowUp: (internalProjectTemplateId: number, followUpId: number) => void;
}

const InternalProjectTemplateFollowUps: React.FC<InternalProjectTemplateFollowUpsProps> = ({
  loading,
  followUpTypes,
  followUps,
  previousFollowUpSelected,
  roles,
  reloadInternalProjectTemplateInfo,
  deleteInternalProjectTemplateFollowUp,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [followUpModal, setFollowUpModal] = useState<{ edit: boolean; delete: boolean; data: FollowUpData | null }>({
    edit: Boolean(previousFollowUpSelected),
    delete: false,
    data: previousFollowUpSelected ? previousFollowUpSelected : null,
  });
  const [followUpToDuplicate, setFollowUpToDuplicate] = useState<FollowUpData | null>(null);
  const [openModalToDuplicate, setOpenModalToDuplicate] = useState<boolean>(false);
  const tableData: MaterialTableProps<FollowUpData> = {
    title: '',
    columns: [
      {
        title: tFormKey('Seguimiento'),
        field: 'name',
      },
      {
        title: tFormKey('Rol'),
        field: 'role_names',
      },
      {
        title: tFormKey('Tipo'),
        field: 'type',
      },
    ],
    options: {
      selection: false,
      pageSize: 3,
      tableLayout: 'fixed',
      showSelectAllCheckbox: true,
      paging: false,
    },
    actions: [
      (rowData: FollowUpData) => ({
        icon: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
        onClick: () => setFollowUpModal({ edit: true, delete: false, data: rowData }),
        tooltip: tKey('Editar'),
      }),
      (rowData: FollowUpData) => ({
        icon: () => <FileCopy style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
        onClick: () => {
          setFollowUpToDuplicate(rowData);
          setOpenModalToDuplicate(true);
        },
        tooltip: tKey('Duplicar'),
      }),
      (rowData: FollowUpData) => ({
        icon: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
        onClick: () => setFollowUpModal({ edit: false, delete: true, data: rowData }),
        tooltip: tKey('Borrar'),
      }),
    ],
    isLoading: loading,
    data: followUps,
  };

  return (
    <>
      <h1 className={styles.title}>{tKey('Seguimientos')}</h1>
      <TableWithEdit
        key="follow_ups"
        tableData={tableData}
        permission={true}
        optionsNew={{
          onNew: () => setFollowUpModal({ edit: true, delete: false, data: null }),
          newButtonText: tKey('Nuevo seguimiento'),
        }}
      ></TableWithEdit>

      {followUpModal.edit ? (
        <InternalProjectTemplateFollowUpsEdition
          followUpTypes={followUpTypes}
          followUpData={followUpModal.data}
          roles={roles}
          handleClose={() => {
            history.replace({ ...location, state: undefined });
            setFollowUpModal({ edit: false, delete: false, data: null });
          }}
        />
      ) : followUpModal.delete ? (
        <TransitionModal
          view={followUpModal.delete}
          handleClose={() => setFollowUpModal({ edit: false, delete: false, data: null })}
          title={tKey('¡Atención!')}
          buttonOk={tKey('Eliminar')}
          buttonKo={tKey('Cancelar')}
          bodyModal=" "
          handleYes={() => {
            if (followUpModal.data)
              deleteInternalProjectTemplateFollowUp(
                followUpModal.data.internal_project_template_id,
                followUpModal.data.id,
              );
            setFollowUpModal({ edit: false, delete: false, data: null });
          }}
          handleNo={() => setFollowUpModal({ edit: false, delete: false, data: null })}
        >
          <div style={{ padding: '20px', color: '#666666' }}>{tKey('¿Seguro que desea eliminar este campo?')}</div>
        </TransitionModal>
      ) : null}
      {followUpToDuplicate && (
        <DuplicateFollowUpModal
          followUp={followUpToDuplicate}
          openModal={openModalToDuplicate}
          onCloseModal={() => {
            setOpenModalToDuplicate(false);
          }}
          onSuccess={() => {
            reloadInternalProjectTemplateInfo();
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.internalProjectTemplateReducer.loadingAlt,
});

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  deleteInternalProjectTemplateFollowUp: (internalProjectTemplateId: number, followUpId: number): void =>
    dispatch(deleteInternalProjectTemplateFollowUp(internalProjectTemplateId, followUpId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectTemplateFollowUps);
