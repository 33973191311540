import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react';
import { useWindowSize } from '../../../../helpers/customHooks/useHookMethod';
import { formatNumber } from '../../../../helpers/formatNumber';
import { Material } from '../../../../redux/scholarship/definitions';
import styles from './materialsTable.module.scss';

interface MaterialsRowProps {
  material: Material;
  disabled: boolean;
  onRemove: (material: Material) => void;
  onEdit: (material: Material) => void;
}

const MaterialsRow: React.FC<MaterialsRowProps> = ({ material, disabled, onRemove, onEdit }) => {
  const iconColor = (disabled: boolean) => {
    const opacity = disabled ? 0.2 : 0.54;
    return { color: `rgba(0, 0, 0, ${opacity})` };
  };

  const isMobile = useWindowSize().IsMobile;

  const canEditRow = !disabled && isMobile;

  const renderActions = () => {
    return (
      <td className={`${styles.materialsCellRow}  ${styles.actionCell}`}>
        {!isMobile ? (
          <>
            <Tooltip title="Editar">
              <IconButton aria-label="Editar">
                <Edit style={iconColor(disabled)} onClick={() => (disabled ? undefined : onEdit(material))} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          undefined
        )}
        <Tooltip title="Eliminar">
          <IconButton aria-label="eliminar">
            <Delete style={iconColor(disabled)} onClick={() => (disabled ? undefined : onRemove(material))} />
          </IconButton>
        </Tooltip>
      </td>
    );
  };

  return (
    <tr className={styles.materialsTableRow}>
      <td className={`${styles.materialsCellRow}  ${styles.materialNameCell}`}>{material.name}</td>
      <td
        className={`${styles.materialsCellRow}  ${styles.materialAmountCell}`}
        onClick={() => (canEditRow ? onEdit(material) : undefined)}
      >{`${formatNumber({
        value: material.amount,
      })} €`}</td>
      {renderActions()}
    </tr>
  );
};

export default MaterialsRow;
