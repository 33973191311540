import { ButtonProps } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../../components/Loading/Loading';
import SelectController from '../../../../components/Select/SelectController';
import SubTitle from '../../../../components/SubTitle/Subtitle';
import Title from '../../../../components/Title/Title';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { CodeNamePair } from '../../../../redux/common/definitions';
import { selectLanguages } from '../../../../redux/metadata/selectors';
import { AppState } from '../../../../redux/root-reducer';
import {
  getMailDetails,
  setData,
  setDataMail,
  testTranslationMail,
  updateMailDetails,
} from '../../../../redux/translations/actions';
import { MailDetails, TypeLang, TypeSetData } from '../../../../redux/translations/definitions';
import { selectDetailsMail, selectLoading } from '../../../../redux/translations/selectors';
import { TranslationSchema } from '../../../../validations/translationSchema';
import SubmenuConfiguration from '../../SubmenuConfiguration';
import styles from './mailTranslationsDetails.module.scss';
import WYSIWYGEditor from './Wysiwyg';
import { selectAuth } from '../../../../redux/auth/selectors';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';

interface MailTranslationsDetailsProps extends RouteComponentProps<{ mail_code: string }> {
  loading: boolean;
  mailDetails: MailDetails;
  lang: CodeNamePair[];
  user_lang: string;
  setDataMail: (key: string, value: string | number) => void;
  setDataTranslate: (text: string, setType: TypeSetData, lang: TypeLang, id?: number) => void;
  updateMailDetails: () => void;
  getDetailMail: (mailCode: string) => void;
  testMail: (mail_code: string, lang: TypeLang) => void;
}

const MailTranslationsDetails: React.FC<MailTranslationsDetailsProps> = ({
  loading,
  match,
  mailDetails,
  lang,
  user_lang,
  getDetailMail,
  setDataMail,
  setDataTranslate,
  updateMailDetails,
  testMail,
}) => {
  const {
    params: { mail_code },
  } = match;
  useEffect(() => {
    getDetailMail(mail_code);
  }, [getDetailMail, mail_code]);

  const [langSettled, setLang] = useState<any>(user_lang);

  const { errors, control, setValue } = useForm<any>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: TranslationSchema,
  });

  useEffect(() => {
    if (langSettled === undefined) {
      setLang(() => user_lang);
      setValue('language', user_lang);
    }
  }, [langSettled, setLang, setValue, user_lang]);

  const { description, order } = mailDetails;

  const handleonKeyPressInput = (e: any, isButton?: boolean, id?: number) => {
    if (isButton) {
      setDataTranslate(e.target.value, 'section', langSettled, id);
    } else {
      setDataTranslate(e.target.value, 'general', langSettled);
    }
  };

  const showRealLang = (lan: string) => {
    const language = lang.find((l: CodeNamePair) => l.code === lan);
    return language ? language.name?.toLowerCase() : '';
  };

  const buttons: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      startIcon: <SaveIcon />,
      onClick: () => updateMailDetails(),
    },
    {
      children: tKey('Probar'),
      disableElevation: true,
      fullWidth: true,
      color: 'primary',
      variant: 'contained',
      startIcon: <SendIcon />,
      onClick: () => testMail(mail_code, langSettled),
    },
  ];

  return (
    <LayoutForm
      leftSubmenu={<SubmenuConfiguration selected={''} submenu={tFormKey('Gestión de areas')} />}
      rightSubmenu={<ActionsMenu actionsButtons={buttons} />}
    >
      {loading && Object.keys(mailDetails).length === 0 ? (
        <Loading big />
      ) : (
        <form className={styles.form_container}>
          <Title>{description}</Title>
          <span className={styles.idioma}>{tFormKey('Selecciona Idioma:')}</span>
          <div className={styles.selectIdiomaContainer}>
            <SelectController
              label={tFormKey('Idioma')}
              schema={TranslationSchema}
              name={'language'}
              defaultValue={user_lang}
              errors={errors}
              control={control}
              size="40"
              variant="outlined"
              onClick={(e: any) => {
                setLang(() => e.target.value);
              }}
            >
              {lang.map(element => (
                <MenuItem key={element.code} value={element.code} data-name={element.name}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
            <TextInputController
              label={tFormKey('Orden')}
              schema={TranslationSchema}
              name="order"
              size="10"
              defaultValue={order}
              errors={errors}
              control={control}
              type="number"
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setDataMail('order', e.target.value)}
            />
          </div>
          <SubTitle>{tKey('Asunto')}</SubTitle>
          <div className={styles.containerTextArea}>
            <div className={styles.labelsContainer}>
              <div className={styles.esLabel}>{tKey('Texto origen')}</div>
              <div className={styles.langLabel}>
                {tKey('Texto')} {showRealLang(langSettled)}
              </div>
            </div>
            <div className={styles.containerTextAreaCol}>
              <div className={styles.contentEs} dangerouslySetInnerHTML={{ __html: mailDetails[`subject_es`] }} />
              <Controller
                as={
                  <WYSIWYGEditor
                    defaultValue={mailDetails[`subject_${langSettled}`]}
                    id={-1}
                    lang={langSettled}
                    customOptions={mailDetails.wildcards}
                    onlyCustom
                    isNotHtmlText
                  />
                }
                name={`content_${langSettled}`}
                control={control}
                defaultValue={mailDetails[`subject_${langSettled}`]}
              />
            </div>
          </div>

          <SubTitle>{tKey('Título')}</SubTitle>
          {mailDetails.sections &&
            mailDetails.sections
              .filter(e => e.content_type === '0')
              .map(section => (
                <div className={styles.containerTextArea} key={section.id}>
                  <div className={styles.labelsContainer}>
                    <div className={styles.esLabel}>{tKey('Texto origen')}</div>
                    <div className={styles.langLabel}>
                      {tKey('Texto')} {showRealLang(langSettled)}
                    </div>
                  </div>
                  <div className={styles.containerTextAreaCol}>
                    <div className={styles.contentEs} dangerouslySetInnerHTML={{ __html: section.content_es }} />
                    <Controller
                      key={section.id}
                      as={
                        <WYSIWYGEditor
                          defaultValue={section[`content_${langSettled}`]}
                          id={section.id}
                          lang={langSettled}
                          customOptions={mailDetails.wildcards}
                        />
                      }
                      name={`content_${langSettled}`}
                      control={control}
                      defaultValue={section[`content_${langSettled}`]}
                    />
                  </div>
                </div>
              ))}
          <SubTitle>{tKey('Secciones')}</SubTitle>
          {mailDetails.sections &&
            mailDetails.sections
              .filter(e => e.content_type === '1')
              .map(section => (
                <div className={styles.containerTextArea} key={section.id}>
                  <div className={styles.labelsContainer}>
                    <div className={styles.esLabel}>{tKey('Texto origen')}</div>
                    <div className={styles.langLabel}>
                      {tKey('Texto')} {showRealLang(langSettled)}
                    </div>
                  </div>
                  <div className={styles.containerTextAreaCol}>
                    <div className={styles.contentEs} dangerouslySetInnerHTML={{ __html: section.content_es }} />
                    <Controller
                      key={section.id}
                      as={
                        <WYSIWYGEditor
                          defaultValue={section[`content_${langSettled}`]}
                          id={section.id}
                          lang={langSettled}
                          customOptions={mailDetails.wildcards}
                        />
                      }
                      name={`content_${langSettled}`}
                      control={control}
                      defaultValue={section[`content_${langSettled}`]}
                    />
                  </div>
                </div>
              ))}
          {mailDetails.sections && mailDetails.sections.filter(e => e.content_type === '2').length > 0 && (
            <SubTitle>{tKey('Botón')}</SubTitle>
          )}
          {mailDetails.sections &&
            mailDetails.sections
              .filter(e => e.content_type === '2')
              .map(section => (
                <div className={styles.subjectContainer} key={section.id}>
                  <div className={styles.labelsContainer}>
                    <div className={styles.esLabel}>{tKey('Texto origen')}</div>
                    <div className={styles.langLabel}>
                      {tKey('Texto')} {showRealLang(langSettled)}
                    </div>
                  </div>
                  <div className={styles.containerTextAreaCol}>
                    <div className={styles.subjectSpanish}>
                      <Controller
                        as={<input type="text" disabled={true} />}
                        name={`content_es`}
                        control={control}
                        defaultValue={section[`content_es`]}
                      />
                    </div>
                    <div className={styles.subjectLang}>
                      <Controller
                        as={<input type="text" onKeyUp={e => handleonKeyPressInput(e, true, section.id)} />}
                        name={`section.content_${langSettled}`}
                        control={control}
                        defaultValue={section[`content_${langSettled}`]}
                      />
                    </div>
                  </div>
                </div>
              ))}
        </form>
      )}
    </LayoutForm>
  );
};

const mapStateProps = (state: AppState) => ({
  loading: selectLoading(state),
  mailDetails: selectDetailsMail(state),
  lang: selectLanguages(state),
  user_lang: selectAuth(state).language,
});

const mapDispatchToProps = (dispatch: any) => ({
  getDetailMail: (mailCode: string): void => dispatch(getMailDetails(mailCode)),
  updateMailDetails: (): void => dispatch(updateMailDetails()),
  testMail: (mail_code: string, lang: TypeLang): void => dispatch(testTranslationMail(mail_code, lang)),
  setDataTranslate: (text: string, setType: TypeSetData, lang: TypeLang, id?: number): void =>
    dispatch(setData(text, setType, lang, id)),
  setDataMail: (key: string, value: string | number): void => dispatch(setDataMail(key, value)),
});

export default connect(mapStateProps, mapDispatchToProps)(MailTranslationsDetails);
