import React from 'react';
import CheckboxCompo from '../../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../../components/Forms/FormContainer';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { EntityTypeRadios, typeName } from '../../Hooks/useEntityTypes';
import styles from './entityType.module.scss';

interface EntityTypeProps {
  typeRadios: EntityTypeRadios;
  showVisaProvider: boolean;
  disabled?: boolean;
  errorMessage?: string;
  setValue: (name: typeName, value: boolean) => void;
}

const EntityType: React.FC<EntityTypeProps> = ({
  typeRadios,
  showVisaProvider,
  disabled = false,
  errorMessage,
  setValue,
}) => {
  const naturalPersonOptions = [
    { value: '0', label: 'none', hiddenT: true, hidden: true },
    { value: '1', label: tFormKey('Persona física') },
  ];

  const legalPersonOptions = [
    { value: '0', label: 'none', hiddenT: true, hidden: true },
    { value: '1', label: tFormKey('Persona jurídica') },
  ];

  const entityTypeMessage = () => {
    if (errorMessage) {
      return <label className={styles.helperText}>{errorMessage}</label>;
    }
    return undefined;
  };

  const renderVisaProvider = () => {
    if (showVisaProvider || disabled) {
      return (
        <CheckboxCompo
          name="is_visa_provider"
          key={`is_visa_provider${typeRadios.is_visa_provider}`}
          disabled={!typeRadios.is_provider || disabled}
          questionText={tFormKey('Proveedor Visa')}
          defaultValue={typeRadios.is_visa_provider}
          type="checkbox"
          onChange={(value: boolean) => setValue('is_visa_provider', value)}
        />
      );
    }
    return undefined;
  };

  return (
    <>
      <FormContainer title={tKey('Tipo de entidad')}>
        <div className={styles.entity_types_contanier}>
          <div className={styles.entity_type}>
            <CheckboxCompo
              name="has_scholarship"
              questionText={tFormKey('Becas')}
              defaultValue={typeRadios.has_scholarship}
              type="checkbox"
              disabled={disabled}
              onChange={(value: boolean) => setValue('has_scholarship', value)}
            />
            <CheckboxCompo
              name="has_project"
              questionText={tFormKey('Proyectos')}
              defaultValue={typeRadios.has_project}
              type="checkbox"
              disabled={disabled}
              onChange={(value: boolean) => setValue('has_project', value)}
            />
            <CheckboxCompo
              name="is_provider"
              questionText={tFormKey('Proveedor')}
              defaultValue={typeRadios.is_provider}
              type="checkbox"
              disabled={disabled}
              onChange={(value: boolean) => setValue('is_provider', value)}
            />
          </div>
          <div className={styles.entity_type}>
            {renderVisaProvider()}
            <CheckboxCompo
              name="no_payments_entity"
              questionText={tFormKey('Entidad sin pago')}
              defaultValue={typeRadios.no_payments_entity}
              type="checkbox"
              disabled={disabled}
              onChange={(value: boolean) => setValue('no_payments_entity', value)}
            />
          </div>
        </div>
      </FormContainer>
      <div className={styles.entity_type_provider}>
        <div className={styles.type_person}>
          <RadioButtonGroup
            name="natural_person"
            disabled={!typeRadios.is_provider || disabled}
            questionText=""
            options={naturalPersonOptions}
            value={typeRadios.natural_person.toString()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue('natural_person', e.target.value === '1');
            }}
          />
          <RadioButtonGroup
            name="legal_person"
            disabled={!typeRadios.is_provider || disabled}
            questionText=""
            options={legalPersonOptions}
            value={typeRadios.legal_person.toString()}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue('legal_person', e.target.value === '1');
            }}
          />
        </div>
        {entityTypeMessage()}
      </div>
    </>
  );
};

export default EntityType;
