export enum InternalProjectsConst {
  GET_INTERNAL_PROJECT_SUBAREAS = 'GET:INTERNAL:PROJECT:SUBAREAS',
  GET_INTERNAL_PROJECT_SUBAREAS_OK = 'GET:INTERNAL:PROJECT:SUBAREAS:OK',
  GET_INTERNAL_PROJECT_SUBAREAS_KO = 'GET:INTERNAL:PROJECT:SUBAREAS:KO',

  SET_LATEST_SUBAREA = 'SET:LATEST:SUBAREA',

  GET_INTERNAL_PROJECTS = 'GET:INTERNAL:PROJECTS',
  GET_INTERNAL_PROJECTS_OK = 'GET:INTERNAL:PROJECTS:OK',
  GET_INTERNAL_PROJECTS_KO = 'GET:INTERNAL:PROJECTS:KO',

  SET_LATEST_INTERNAL_PROJECT_SECTION = 'SET:LATEST:INTERNAL:PROJECT:SECTION',

  GET_INTERNAL_PROJECTS_TEMPLATE = 'GET:INTERNAL:PROJECTS:TEMPLATE',
  GET_INTERNAL_PROJECTS_TEMPLATE_OK = 'GET:INTERNAL:PROJECTS:TEMPLATE:OK',
  GET_INTERNAL_PROJECTS_TEMPLATE_KO = 'GET:INTERNAL:PROJECTS:TEMPLATE:KO',

  SET_INTERNAL_PROJECT = 'SET:INTERNAL:PROJECT',

  CREATE_INTERNAL_PROJECTS = 'CREATE:INTERNAL:PROJECTS',
  CREATE_INTERNAL_PROJECTS_OK = 'CREATE:INTERNAL:PROJECTS:OK',
  CREATE_INTERNAL_PROJECTS_KO = 'CREATE:INTERNAL:PROJECTS:KO',

  CREATE_INTERNAL_PROJECT_VALIDATION_ERRORS = 'CREATE:INTERNAL:PROJECT:VALIDATION:ERRORS',

  UPDATE_INTERNAL_PROJECT = 'UPDATE:INTERNAL:PROJECT',
  UPDATE_INTERNAL_PROJECT_OK = 'UPDATE:INTERNAL:PROJECT:OK',
  UPDATE_INTERNAL_PROJECT_KO = 'UPDATE:INTERNAL:PROJECT:KO',

  GET_PARTICIPANTES_FOLLOWUP_LIST = 'GET:PARTICIPANTES:FOLLOWUP:LIST',
  GET_PARTICIPANTES_FOLLOWUP_LIST_OK = 'GET:PARTICIPANTES:FOLLOWUP:LIST:OK',
  GET_PARTICIPANTES_FOLLOWUP_LIST_KO = 'GET:PARTICIPANTES:FOLLOWUP:LIST:KO',

  GET_FOLLOWUP_SIX_MONTHS_FORM = 'GET:FOLLOWUP:SIX:MONTHS:FORM',
  GET_FOLLOWUP_SIX_MONTHS_FORM_OK = 'GET:FOLLOWUP:SIX:MONTHS:FORM:OK',
  GET_FOLLOWUP_SIX_MONTHS_FORM_KO = 'GET:FOLLOWUP:SIX:MONTHS:FORM:KO',

  UPDATE_FOLLOWUP_SIX_MONTHS_FORM = 'UPDATE:FOLLOWUP:SIX:MONTHS:FORM',
  UPDATE_FOLLOWUP_SIX_MONTHS_FORM_OK = 'UPDATE:FOLLOWUP:SIX:MONTHS:FORM:OK',
  UPDATE_FOLLOWUP_SIX_MONTHS_FORM_KO = 'UPDATE:FOLLOWUP:SIX:MONTHS:FORM:KO',

  GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM = 'GET:PARTICIPANTES:FINAL:FOLLOWUP:FORM',
  GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK = 'GET:PARTICIPANTES:FINAL:FOLLOWUP:FORM:OK',
  GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO = 'GET:PARTICIPANTES:FINAL:FOLLOWUP:FORM:KO',

  UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM = 'UPDATE:PARTICIPANTES:FINAL:FOLLOWUP:FORM',
  UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK = 'UPDATE:PARTICIPANTES:FINAL:FOLLOWUP:FORM:OK',
  UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO = 'UPDATE:PARTICIPANTES:FINAL:FOLLOWUP:FORM:KO',

  GET_MEMBERS = 'GET:MEMBERS',
  GET_MEMBERS_OK = 'GET:MEMBERS:OK',
  GET_MEMBERS_KO = 'GET:MEMBERS:KO',

  GET_INTERNAL_PROJECT = 'GET:INTERNAL:PROJECT',
  GET_INTERNAL_PROJECT_OK = 'GET:INTERNAL:PROJECT:OK',
  GET_INTERNAL_PROJECT_KO = 'GET:INTERNAL:PROJECT:KO',

  ADD_MEMBERS = 'ADD:MEMBERS',
  ADD_MEMBERS_OK = 'ADD:MEMBERS:OK',
  ADD_MEMBERS_KO = 'ADD:MEMBERS:KO',

  GET_PROJECT_ALLOWED_USERS = 'GET:PROJECT:ALLOWED:USERS',
  GET_PROJECT_ALLOWED_USERS_OK = 'GET:PROJECT:ALLOWED:USERS:OK',

  DELETE_MEMBERS = 'DELETE:MEMBERS',
  DELETE_MEMBERS_OK = 'DELETE:MEMBERS:OK',
  DELETE_MEMBERS_KO = 'DELETE:MEMBERS:KO',

  UPLOAD_INTERNAL_PROJECT_DOCUMENT = 'UPLOAD:INTERNAL_PROJECT:DOCUMENT',
  UPLOAD_INTERNAL_PROJECT_DOCUMENT_OK = 'UPLOAD:INTERNAL_PROJECT:DOCUMENT:OK',
  UPLOAD_INTERNAL_PROJECT_DOCUMENT_KO = 'UPLOAD:INTERNAL_PROJECT:DOCUMENT:KO',

  DOWNLOAD_DOCUMENT_AGREEMENT = 'DOWNLOAD_DOCUMENT_AGREEMENT',
  DOWNLOAD_DOCUMENT_AGREEMENT_OK = 'DOWNLOAD_DOCUMENT_AGREEMENT_OK',
  DOWNLOAD_DOCUMENT_AGREEMENT_KO = 'DOWNLOAD_DOCUMENT_AGREEMENT_KO',

  REMOVE_DOCUMENT_AGREEMENT = 'REMOVE_DOCUMENT_AGREEMENT',
  REMOVE_DOCUMENT_AGREEMENT_OK = 'REMOVE_DOCUMENT_AGREEMENT_OK',
  REMOVE_DOCUMENT_AGREEMENT_KO = 'REMOVE_DOCUMENT_AGREEMENT_KO',

  GET_DOCUMENTS_INTERNAL_PROJECT = 'GET:DOCUMENTS_INTERNAL:PROJECT',
  GET_DOCUMENTS_INTERNAL_PROJECT_OK = 'GET:DOCUMENTS_INTERNAL:PROJECT:OK',
  GET_DOCUMENTS_INTERNAL_PROJECT_KO = 'GET:DOCUMENTS_INTERNAL:PROJECT:KO',

  UPLOAD_DOCUMENTS_INTERNAL_PROJECT = 'UPLOAD:DOCUMENTS_INTERNAL:PROJECT',
  UPLOAD_DOCUMENTS_INTERNAL_PROJECT_OK = 'UPLOAD:DOCUMENTS_INTERNAL:PROJECT:OK',
  UPLOAD_DOCUMENTS_INTERNAL_PROJECT_KO = 'UPLOAD:DOCUMENTS_INTERNAL:PROJECT:KO',

  DELETE_DOCUMENTS_INTERNAL_PROJECT = 'DELETE:DOCUMENTS_INTERNAL:PROJECT',
  DELETE_DOCUMENTS_INTERNAL_PROJECT_OK = 'DELETE:DOCUMENTS_INTERNAL:PROJECT:OK',
  DELETE_DOCUMENTS_INTERNAL_PROJECT_KO = 'DELETE:DOCUMENTS_INTERNAL:PROJECT:KO',

  DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT = 'DOWNLOAD:DOCUMENTS_INTERNAL:PROJECT',
  DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_OK = 'DOWNLOAD:DOCUMENTS_INTERNAL:PROJECT:OK',
  DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_KO = 'DOWNLOAD:DOCUMENTS_INTERNAL:PROJECT:KO',

  GET_INTERNAL_PROJECT_DAILY_FOLLOWS = 'GET:INTERNAL:PROJECT:DAILY:FOLLOWS',
  GET_INTERNAL_PROJECT_DAILY_FOLLOWS_OK = 'GET:INTERNAL:PROJECT:DAILY:FOLLOWS:OK',
  GET_INTERNAL_PROJECT_DAILY_FOLLOWS_KO = 'GET:INTERNAL:PROJECT:DAILY:FOLLOWS:KO',

  GET_INTERNAL_PROJECTS_EXCEL = 'GET:INTERNAL:PROJECTS:EXCEL',
  GET_INTERNAL_PROJECTS_EXCEL_OK = 'GET:INTERNAL:PROJECTS:EXCEL:OK',
  GET_INTERNAL_PROJECTS_EXCEL_KO = 'GET:INTERNAL:PROJECTS:EXCEL:KO',

  GET_INTERNAL_PROJECT_DAILY_FOLLOW = 'GET:INTERNAL:PROJECT:DAILY:FOLLOW',
  GET_INTERNAL_PROJECT_DAILY_FOLLOW_OK = 'GET:INTERNAL:PROJECT:DAILY:FOLLOW:OK',
  GET_INTERNAL_PROJECT_DAILY_FOLLOW_KO = 'GET:INTERNAL:PROJECT:DAILY:FOLLOW:KO',

  ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW = 'ADD:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY',
  ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK = 'ADD:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY:OK',
  ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO = 'ADD:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY:KO',

  DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW = 'DELETE:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY',
  DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK = 'DELETE:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY:OK',
  DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO = 'DELETE:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY:KO',

  DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW = 'DOWNLOAD:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY',
  DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK = 'DOWNLOAD:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY:OK',
  DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO = 'DOWNLOAD:DOCUMENTS:INTERNAL:PROJECT:PROJECT:DAILY:KO',

  GET_METADATA = 'GET:METADATA',
  GET_METADATA_OK = 'GET:METADATA:OK',
  GET_METADATA_KO = 'GET:METADATA:KO',

  STORE_DAILY_FOLLOW = 'STORE:DAILY:FOLLOW',
  STORE_DAILY_FOLLOW_OK = 'STORE:DAILY:FOLLOW:OK',
  STORE_DAILY_FOLLOW_KO = 'STORE:DAILY:FOLLOW:KO',

  CLEAR_DAILY_FOLLOW = 'CLEAR:DAILY:FOLLOW',

  DELETE_DAILY_FOLLOW = 'DELETE:DAILY:FOLLOW',
  DELETE_DAILY_FOLLOW_OK = 'DELETE:DAILY:FOLLOW:OK',
  DELETE_DAILY_FOLLOW_KO = 'DELETE:DAILY:FOLLOW:KO',

  DELETE_INTERNAL_PROJECT = 'DELETE:INTERNAL:PROJECT',
  DELETE_INTERNAL_PROJECT_OK = 'DELETE:INTERNAL:PROJECT:OK',
  DELETE_INTERNAL_PROJECT_KO = 'DELETE:INTERNAL:PROJECT:KO',

  GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL = 'GET:INTERNAL:PROJECT:DAILY:FOLLOW:EXCEL',
  GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_OK = 'GET:INTERNAL:PROJECT:DAILY:FOLLOW:EXCEL:OK',
  GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_KO = 'GET:INTERNAL:PROJECT:DAILY:FOLLOW:EXCEL:KO',

  GET_INTERNAL_PROJECTS_ACADEMY = 'GET:INTERNAL:PROJECTS:ACADEMY',
  GET_INTERNAL_PROJECTS_ACADEMY_OK = 'GET:INTERNAL:PROJECTS:ACADEMY:OK',
  GET_INTERNAL_PROJECTS_ACADEMY_KO = 'GET:INTERNAL:PROJECTS:ACADEMY:KO',

  GET_INTERNAL_PROJECT_ACADEMY = 'GET:INTERNAL:PROJECT:ACADEMY',
  GET_INTERNAL_PROJECT_ACADEMY_OK = 'GET:INTERNAL:PROJECT:ACADEMY:OK',
  GET_INTERNAL_PROJECT_ACADEMY_KO = 'GET:INTERNAL:PROJECT:ACADEMY:KO',

  GET_INTERNAL_PROJECT_FOLLOWS = 'GET:INTERNAL:PROJECT:FOLLOWS',
  GET_INTERNAL_PROJECT_FOLLOWS_OK = 'GET:INTERNAL:PROJECT:FOLLOWS:OK',

  UPDATE_FOLLOW_UP_DATE = 'UPDATE:FOLLOW:UP:DATE',
  UPDATE_FOLLOW_UP_DATE_OK = 'UPDATE:FOLLOW:UP:DATE:Ok',
  UPDATE_FOLLOW_UP_DATE_KO = 'UPDATE:FOLLOW:UP:DATE:KO',

  GET_SECTION_TEMPLATE = 'GET:SECTION:TEMPLATE',
  GET_SECTION_TEMPLATE_OK = 'GET:SECTION:TEMPLATE:OK',
  GET_SECTION_TEMPLATE_KO = 'GET:SECTION:TEMPLATE:KO',

  UPDATE_FOLLOW_UP = 'UPDATE:FOLLOW:UP',
  UPDATE_FOLLOW_UP_OK = 'UPDATE:FOLLOW:UP:OK',
  UPDATE_FOLLOW_UP_KO = 'UPDATE:FOLLOW:UP:KO',

  SEND_FOLLOW_UP = 'SEND:FOLLOW:UP',
  SEND_FOLLOW_UP_OK = 'SEND:FOLLOW:UP:OK',
  SEND_FOLLOW_UP_KO = 'SEND:FOLLOW:UP:KO',

  ADD_DOCUMENT_FOLLOW_UP = 'ADD:DOCUMENT:FOLLOW:UP',
  ADD_DOCUMENT_FOLLOW_UP_OK = 'ADD:DOCUMENT:FOLLOW:UP:OK',
  ADD_DOCUMENT_FOLLOW_UP_KO = 'ADD:DOCUMENT:FOLLOW:UP:KO',

  DELETE_DOCUMENTS_FOLLOW_UP_OK = 'DELETE:DOCUMENTS:FOLLOW:UP:OK',

  ADD_NEW_REGISTER = 'ADD:NEW:REGISTER',
  SET_REGISTER = 'SET:REGISTER',

  GET_SETTINGS = 'GET:SETTINGS',
  GET_SETTINGS_OK = 'GET:SETTINGS:OK',
  GET_SETTINGS_KO = 'GET:SETTINGS:KO',

  UPDATE_INTERNAL_PROJECT_SETTINGS = 'UPDATE:INTERNAL:PROJECT:SETTINGS',
  UPDATE_INTERNAL_PROJECT_SETTINGS_OK = 'UPDATE:INTERNAL:PROJECT:SETTINGS:OK',
  UPDATE_INTERNAL_PROJECT_SETTINGS_KO = 'UPDATE:INTERNAL:PROJECT:SETTINGS:KO',

  DELETE_ROW_PARTICIPANTE = 'DELETE:ROW:PARTICIPANTE',
  DELETE_ROW_PARTICIPANTE_OK = 'DELETE:ROW:PARTICIPANTE:OK',
  DELETE_ROW_PARTICIPANTE_KO = 'DELETE:ROW:PARTICIPANTE:KO',

  GET_BASE_CAMP = 'GET:BASE:CAMP',
  GET_BASE_CAMP_OK = 'GET:BASE:CAMP:OK',

  UPDATE_ENROLLMENT = 'UPDATE:ENROLLMENT',
  UPDATE_ENROLLMENT_OK = 'UPDATE:ENROLLMENT:OK',

  DELETE_ENROLLMENT = 'DELETE:ENROLLMENT',
  DELETE_ENROLLMENT_OK = 'DELETE:ENROLLMENT:OK',

  SET_NEW_ENROLLMENT = 'SET:NEW:ENROLLMENT',

  GET_INTERNAL_PROJECT_ENROLLMENTS = 'GET:INTERNAL:PROJECT:ENROLLMENTS',
  GET_INTERNAL_PROJECT_ENROLLMENTS_OK = 'GET:INTERNAL:PROJECT:ENROLLMENTS:OK',

  GET_IP_STUDENT_EVALUATION = 'GET:IP:STUDENT:EVALUATION',
  GET_IP_STUDENT_EVALUATION_OK = 'GET:IP:STUDENT:EVALUATION:OK',

  SEND_IP_STUDENT_EVALUATION = 'SEND:IP:STUDENT:EVALUATION',

  SET_ENROLLMENT_TEXT_DATA = 'SET:ENROLLMENT:TEXT:DATA',

  SET_ENROLLMENT_NUMERIC_DATA = 'SET:ENROLLMENT:NUMERIC:DATA',

  SET_ENROLLMENT_DROP_DOWN_DATA = 'SET:ENROLLMENT:DROP_DOWN:DATA',

  UPDATE_INTERNAL_PROJECT_ENROLLMENTS = 'UPDATE:INTERNAL:PROJECT:ENROLLMENTS',
  UPDATE_INTERNAL_PROJECT_ENROLLMENTS_OK = 'UPDATE:INTERNAL:PROJECT:ENROLLMENTS:OK',
  UPDATE_INTERNAL_PROJECT_ENROLLMENTS_KO = 'UPDATE:INTERNAL:PROJECT:ENROLLMENTS:KO',

  GET_STUDENT_EVALUATION_INFO = 'GET:STUDENT:EVALUATION:INFO',
  GET_STUDENT_EVALUATION_INFO_OK = 'GET:STUDENT:EVALUATION:INFO:OK',

  GET_INTERNAL_PROJECT_IMAGES = 'GET:INTERNAL:PROJECT:IMAGES',
  GET_INTERNAL_PROJECT_IMAGES_OK = 'GET:INTERNAL:PROJECT:IMAGES:OK',

  ADD_INTERNAL_PROJECT_IMAGE = 'ADD:INTERNAL::PROJECT:IMAGE',
  ADD_INTERNAL_PROJECT_IMAGE_OK = 'ADD:INTERNAL:PROJECT:IMAGE:OK',

  EDIT_INTERNAL_PROJECT_IMAGE = 'EDIT:INTERNAL:PROJECT:IMAGE',
  EDIT_INTERNAL_PROJECT_IMAGE_OK = 'EDIT:INTERNAL:PROJECT:IMAGE:OK',

  DELETE_INTERNAL_PROJECT_IMAGE = 'DELETE:INTERNAL:PROJECT:IMAGE',
  DELETE_INTERNAL_PROJECT_IMAGE_OK = 'DELETE:INTERNAL:PROJECT:IMAGE:OK',

  GET_PROJECT_TYPE_BY_SUBAREA_CODE = 'GET:PROJECT:TYPE:BY:SUBAREA:CODE',
  GET_PROJECT_TYPE_BY_SUBAREA_CODE_OK = 'GET:PROJECT:TYPE:BY:SUBAREA:CODE:OK',
  GET_PROJECT_TYPE_BY_SUBAREA_CODE_KO = 'GET:PROJECT:TYPE:BY:SUBAREA:CODE:KO',

  GENERIC_KO = 'INTERNAL:PROJECTS:GENERIC:KO',

  APPLY_CONFIG_TEMPLATE = 'APPLY_CONFIG_TEMPLATE',
  APPLY_CONFIG_TEMPLATE_OK = 'APPLY_CONFIG_TEMPLATE_OK',
}
