/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { connect } from 'react-redux';
import { ObjectSchema, Shape } from 'yup';
import ComboSearch from '../../../../components/ComboSearch/ComboSearch';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { CodeNamePair, IdCodeNamePair, IdNamePair, University } from '../../../../redux/common/definitions';
import { Academy } from '../../../../redux/metadata/definitions';
import { selectMetadataScholarshipProvinces } from '../../../../redux/metadata/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetData } from '../../../../redux/scholarship/actions';
import { ScholarshipData, ScholarshipMetadata } from '../../../../redux/scholarship/definitions';
import {
  selectAcademyInstitute,
  selectGetScholarshipMetadata,
  selectProvinceConfirmInstituteCode,
  selectProvinceInstituteCode,
  selectUniversity,
} from '../../../../redux/scholarship/selectors';
import styles from './confirmationForm.module.scss';
import StudyCenterNature from '../../../../types/Scholarships/StudyCenterNature';

interface StudiesConfirmationFormProps<T extends FieldValues> {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  provinces: CodeNamePair[];
  readOnly: boolean;
  universities: University[];
  provinceInstitute?: string;
  provinceInstituteConfirm?: string;
  academies: Academy[];
  errors: FieldErrors<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  control: Control<T>;
  setValue: (name: any, value: any, shouldValidate?: boolean) => void;
  setData: (key: string, value: string | number) => void;
}

const StudiesConfirmationForm = <T extends FieldValues>({
  scholarshipData,
  scholarshipMetadata,
  provinces,
  readOnly,
  universities,
  provinceInstitute,
  provinceInstituteConfirm,
  academies,
  errors,
  schema,
  control,
  setValue,
  setData,
}: StudiesConfirmationFormProps<T>) => {
  const {
    educational_type_confirm_code,
    professional_group_id_confirm,
    educational_level_id_confirm,
    educational_type_id_confirm,
    educational_type,
    educational_type_code,
    professional_group,
    professional_group_id,
    course_name,
    scholarship_course_id_confirm,
    educational_level,
    scholarship_academy_confirm,
    scholarship_academy_confirm_code,
    isEntity,
    scholarship_academy_confirm_nature,
  } = scholarshipData;

  const { educationalLevels, educationalTypes, professionalGroups, courses } = scholarshipMetadata;
  const [provinceAcademy, setProvinceAcademy] = useState(provinceInstituteConfirm ?? provinceInstitute);
  const codeProvincesCat = ['08', '17', '25', '43'];

  const [inputAcademy, setInputAcademy] = useState<boolean>(
    codeProvincesCat.some(arrVal => arrVal === provinceInstitute),
  );
  const [provinceUniversity, setProvinceUniversity] = useState<string>();
  const [inputUniversity, setInputUniversity] = useState<boolean>();

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
      if (target.name === 'educational_type_id_confirm') {
        const et_code = educationalTypes.find((et: IdCodeNamePair) => et.id === target.value)?.code;
        setData('educational_type_confirm_code', et_code || '');
      }
    }
  };

  const professional_group_filtered = professionalGroups.filter(
    (pg: IdCodeNamePair) =>
      pg.code === (educational_type_confirm_code ? educational_type_confirm_code : educational_type_code),
  );

  const courses_filtered = courses.filter(
    (pg: IdCodeNamePair) =>
      pg.code?.toString() ===
      (professional_group_id_confirm ? professional_group_id_confirm?.toString() : professional_group_id?.toString()),
  );

  const universityProvinceCodes = universities.reduce((acc: string[], uni: University) => {
    if (!acc.includes(uni.province_code.toString())) {
      acc.push(uni.province_code.toString());
    }
    return acc;
  }, []);

  const universityList = universities.filter(({ province_code }) => province_code === provinceUniversity);

  const academiesFiltered = academies.filter(({ code }) => code.slice(0, 2) === provinceAcademy);

  let academyInfo;
  let universityInfo;
  if (educational_type_confirm_code === 'UNI') {
    universityInfo = (
      <SelectController
        control={control}
        errors={errors}
        schema={schema}
        size="50"
        name="province_academy"
        defaultValue={provinceAcademy}
        label={'Provincia de la universidad'}
        disabled={readOnly}
        onClick={(e: any) => {
          setProvinceUniversity(e.target.value);
          setData('province_academy', e.target.value);
          setData('scholarship_academy_confirm_nature', '');
          setValue('scholarship_academy_confirm_nature', '');
          universityProvinceCodes.some(arrVal => arrVal === e.target.value)
            ? setInputUniversity(true)
            : setInputUniversity(false);
        }}
      >
        {isEntity
          ? provinces.map((element: CodeNamePair) => (
              <MenuItem key={element.code} value={element.code}>
                {element.name}
              </MenuItem>
            ))
          : provinces
              .filter(({ code }) => universityProvinceCodes.includes(code as string))
              .map((element: CodeNamePair) => (
                <MenuItem key={element.code} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
      </SelectController>
    );
  } else {
    academyInfo = (
      <SelectController
        control={control}
        errors={errors}
        schema={schema}
        size="50"
        name="province_academy"
        defaultValue={provinceAcademy}
        label={'Provincia Instituto'}
        disabled={readOnly}
        onClick={(e: any) => {
          setProvinceAcademy(e.target.value);
          setData('province_academy', e.target.value);
          setData('scholarship_academy_confirm_nature', '');
          setValue('scholarship_academy_confirm_nature', '');
          codeProvincesCat.some(arrVal => arrVal === e.target.value) ? setInputAcademy(true) : setInputAcademy(false);
        }}
      >
        {isEntity
          ? provinces.map((element: CodeNamePair) => (
              <MenuItem key={element.code} value={element.code}>
                {element.name}
              </MenuItem>
            ))
          : provinces
              .filter(({ code }) => codeProvincesCat.includes(code as string))
              .map((element: CodeNamePair) => (
                <MenuItem key={element.code} value={element.code}>
                  {element.name}
                </MenuItem>
              ))}
      </SelectController>
    );
  }
  let academyInfoCenter;
  let universityInfoCenter;
  if (educational_type_confirm_code === 'UNI') {
    universityInfoCenter =
      inputUniversity && !readOnly ? (
        <ComboSearch
          options={universityList}
          control={control}
          label={tScholarshipKey('Centro de estudios final')}
          optionLabel="name"
          fieldToSend="name"
          setValue={setValue}
          defaultValue={scholarship_academy_confirm as string}
          onChange={data => {
            if (data != null && data.name) {
              setData('scholarship_academy_confirm', data.name);
              setData('scholarship_academy_confirm_nature', data.nature);
              setData('scholarship_academy_confirm_code', '');
              setValue('scholarship_academy_confirm_nature', data.nature);
            }
          }}
          disabled={!provinceUniversity}
          error={errors.scholarship_academy_confirm}
          name="scholarship_academy_confirm"
          clipBoard
        />
      ) : (
        <TextInputController
          control={control}
          errors={errors}
          schema={schema}
          size="50"
          name="scholarship_academy_confirm"
          disabled={readOnly || !provinceAcademy}
          defaultValue={scholarship_academy_confirm}
          label={tScholarshipKey('Centro de estudios final')}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            setData('scholarship_academy_confirm', e.target.value);
            setData('scholarship_academy_confirm_nature', '');
            setValue('scholarship_academy_confirm_nature', '');
          }}
        />
      );
  } else {
    academyInfoCenter = inputAcademy ? (
      <ComboSearch
        options={academiesFiltered}
        control={control}
        label={tScholarshipKey('Centro de estudios final')}
        optionLabel="name"
        fieldToSend="code"
        setValue={setValue}
        defaultValue={scholarship_academy_confirm_code as string}
        onChange={data => {
          if (data != null && data.name && data.code) {
            setData('scholarship_academy_confirm', data.name);
            setData('scholarship_academy_confirm_code', data.code);
            setData('scholarship_academy_confirm_nature', data.nature);
            setValue('scholarship_academy_confirm_nature', data.nature);
          }
        }}
        disabled={readOnly || !provinceAcademy}
        error={errors.scholarship_academy_confirm}
        name="scholarship_academy_confirm"
      />
    ) : (
      <TextInputController
        control={control}
        errors={errors}
        schema={schema}
        size="50"
        name="scholarship_academy_confirm"
        disabled={readOnly || !provinceAcademy}
        defaultValue={scholarship_academy_confirm}
        label={tScholarshipKey('Centro de estudios final')}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          setData('scholarship_academy_confirm', e.target.value);
          setData('scholarship_academy_confirm_code', '');
          setData('scholarship_academy_confirm_nature', '');
          setValue('scholarship_academy_confirm_nature', '');
        }}
      />
    );
  }

  const renderStudyCenterNature = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        errors={errors}
        schema={schema}
        size="50"
        name="scholarship_academy_confirm_nature"
        defaultValue={scholarship_academy_confirm_nature || ''}
        label={tScholarshipKey('¿Cuál es la titularidad del centro?')}
        disabled
      >
        {[...StudyCenterNature.toArray(), ''].map(nature => (
          <MenuItem key={nature} value={nature}>
            {tScholarshipKey(nature)}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  return (
    <FormContainer
      title={tScholarshipKey('Confirmación de Estudios')}
      subtitle={tScholarshipKey('subtitle-confirmacion-estudios')}
      className={styles.containerWithRadio}
    >
      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Curso previsto a realizar')}
        name="educational_level"
        defaultValue={educational_level}
      />
      <SelectController
        control={control}
        errors={errors}
        size="50"
        schema={schema}
        key="educational_level_id_confirm"
        name="educational_level_id_confirm"
        disabled={readOnly}
        defaultValue={educational_level_id_confirm}
        label={tScholarshipKey('Curso realizado')}
        onClick={e => handleSelectChange(e.target)}
      >
        {educationalLevels.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>

      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Categoría de curso prevista')}
        name="educational_type"
        defaultValue={educational_type}
      />

      <SelectController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        key="educational_type_id_confirm"
        name="educational_type_id_confirm"
        defaultValue={educational_type_id_confirm}
        label={tScholarshipKey('Categoría de curso realizada')}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {educationalTypes.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Familia profesional prevista')}
        name="professional_group"
        defaultValue={professional_group}
      />
      <SelectController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        key="professional_group_id_confirm"
        name="professional_group_id_confirm"
        defaultValue={professional_group_id_confirm}
        label={tScholarshipKey('Familia profesional realizada')}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {professional_group_filtered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Nombre de los estudios que desea cursar')}
        name="course_name"
        defaultValue={course_name}
      />
      <SelectController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        key="scholarship_course_id_confirm"
        name="scholarship_course_id_confirm"
        defaultValue={scholarship_course_id_confirm}
        label={tScholarshipKey('nombre-estudios-realizados')}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {courses_filtered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      {universityInfo}
      {academyInfo}
      {universityInfoCenter}
      {academyInfoCenter}
      {renderStudyCenterNature()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  provinces: selectMetadataScholarshipProvinces(state),
  provinceInstitute: selectProvinceInstituteCode(state),
  provinceInstituteConfirm: selectProvinceConfirmInstituteCode(state),
  academies: selectAcademyInstitute(state),
  universities: selectUniversity(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudiesConfirmationForm);
