import { ButtonProps } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import ImageGalleryModal from '../../../components/Modal/ImageGalleryModal/ImageGalleryModal';
import { tKey } from '../../../helpers/translate';
import { selectIsNousCims, selectUserInfo, selectUserIsAcademy } from '../../../redux/auth/selectors';
import { ResourceImageData } from '../../../redux/common/definitions';
import { EventDataDispatch } from '../../../redux/event/definitions';
import {
  addInternalProjectImage,
  deleteInternalProjectImage,
  editInternalProjectImage,
  getInternalProject,
  getInternalProjectAcademy,
  getInternalProjectEnrollment,
  getInternalProjectFollows,
  getInternalProjectDailyFollows,
  getInternalProjectImages,
  getUsersCanBeMembers,
  setLatestInternalProjectSection,
} from '../../../redux/internal-projects/actions';
import { InternalProjectEnrollment } from '../../../redux/internal-projects/definitions';
import { selectHasEnrollments, selectIpLatestSection } from '../../../redux/internal-projects/selectors';
import { AppState } from '../../../redux/root-reducer';
import DailyFollows from './Forms/DailyFollows/DailyFollows';
import EntityFollows from './Forms/EntityFollows/EntityFollows';
import Follows from './Forms/Follows/Follows';
import GeneralData from './Forms/GeneralData/GeneralData';
import Information from './Forms/Information/Information';
import StudentFollows from './Forms/StudentFollows/StudentFollows';

interface InternalProjectEditionProps extends RouteComponentProps<{ id: string; section: string }> {
  hasEnrollments: boolean;
  is_academy: boolean;
  enrollments: InternalProjectEnrollment[];
  loadingImages: boolean;
  resourceImages: ResourceImageData[];
  isNousCims: boolean;
  user_id: number;
  getInternalProject: (id: number) => void;
  getInternalProjectAcademy: (id: number) => void;
  getEnrollments: (internal_project_id: number) => void;
  getUsersMembers: (internal_project_id: number) => void;
  getInternalProjectFollows: (internal_project_id: number) => void;
  getInternalProjectDailyFollows: (internal_project_id: number) => void;
  latestInternalProjectSectionCode: (internal_project_id: number) => string | null;
  setLatestInternalProjectSection: (internal_project_id: number, section: string) => void;
  getInternalProjectImages: (id: number) => void;
  addInternalProjectImage: (id: number, name: string, description: string, image: string, userId: number) => void;
  editInternalProjectImage: (
    id: number,
    resourceImage: ResourceImageData,
    name: string,
    description: string,
    newImage: string,
    userId: number,
  ) => void;
  deleteInternalProjectImage: (resourceImage: ResourceImageData) => void;
}

const InternalProjectEdition: React.FC<InternalProjectEditionProps> = ({
  match,
  hasEnrollments,
  enrollments,
  is_academy,
  loadingImages,
  resourceImages,
  isNousCims,
  user_id,
  getInternalProject,
  getInternalProjectAcademy,
  getEnrollments,
  getUsersMembers,
  getInternalProjectFollows,
  getInternalProjectDailyFollows,
  latestInternalProjectSectionCode,
  setLatestInternalProjectSection,
  getInternalProjectImages,
  addInternalProjectImage,
  editInternalProjectImage,
  deleteInternalProjectImage,
}) => {
  const {
    params: { id, section },
    path,
  } = match;

  const [setterData, setSetterData] = useState([]);
  const history = useHistory();
  const isNotEdit = !path.includes('editar');
  const [showImageGalleryModal, setShowImageGalleryModal] = useState<boolean>(false);

  useEffect(() => {
    is_academy ? getInternalProjectAcademy(+id) : getInternalProject(+id);
  }, [is_academy, id, getInternalProject, getInternalProjectAcademy]);

  useEffect(() => {
    if (hasEnrollments) {
      getEnrollments(+id);
    }
  }, [hasEnrollments, id, getEnrollments]);

  useEffect(() => {
    getUsersMembers(+id);
  }, [id, getUsersMembers]);

  useEffect(() => {
    getInternalProjectFollows(+id);
  }, [id, getInternalProjectFollows]);

  useEffect(() => {
    getInternalProjectDailyFollows(+id);
  }, [id, getInternalProjectDailyFollows]);

  const [codeSection, setcodeSection] = useState(latestInternalProjectSectionCode(+id) || section || 'informacion');

  const [handleGoBack, setHandleGoBack] = useState(() => () => history.go(-1));
  const goParentPage = () => {
    history.push('/proyectos-internos');
  };

  const buttonImageGallery = {
    children: tKey('Galería de imágenes'),
    startIcon: <ImageIcon />,
    variant: 'contained',
    color: 'secondary',
    fullWidth: true,
    disableElevation: true,
    disabled: isNotEdit,
    hidden: !isNousCims,
    onClick: () => {
      getInternalProjectImages(+id);
      setShowImageGalleryModal(true);
    },
  } as ButtonProps;

  const handleComponentId = (componentCode: string) => {
    setcodeSection(componentCode);
    setLatestInternalProjectSection(+id, componentCode);
    history.push(match.url.replace(section, componentCode));
  };

  const commonProperties = {
    id: +id,
    disabled: isNotEdit,
    handleChangeSection: handleComponentId,
    handleGoBack: goParentPage,
  };

  let imageGalleryModal;

  if (showImageGalleryModal) {
    imageGalleryModal = (
      <ImageGalleryModal
        open={true}
        loading={loadingImages}
        title={tKey('Galería de imágenes')}
        images={resourceImages}
        canAddImage={isNousCims}
        handleAddImage={(name: string, description: string, image: string) =>
          addInternalProjectImage(+id, name, description, image, user_id)
        }
        handleEditImage={(projectImgId: number, name: string, description: string, newImage: string) => {
          const currentProjectImage = resourceImages.find((e: ResourceImageData) => e.id === projectImgId);
          if (currentProjectImage)
            editInternalProjectImage(+id, currentProjectImage, name, description, newImage, user_id);
        }}
        handleDeleteImage={(projectImgId: number) => {
          const currentProjectImage = resourceImages.find((e: ResourceImageData) => e.id === projectImgId);
          if (currentProjectImage) deleteInternalProjectImage(currentProjectImage);
        }}
        handleClose={() => setShowImageGalleryModal(false)}
      />
    );
  }

  return codeSection === 'informacion' ? (
    <>
      <Information {...commonProperties} buttonImageGallery={buttonImageGallery} />
      {imageGalleryModal}
    </>
  ) : codeSection === 'datos_generales' ? (
    <>
      <GeneralData {...commonProperties} buttonImageGallery={buttonImageGallery} />
      {imageGalleryModal}
    </>
  ) : codeSection === 'seguimientos' ? (
    <>
      <Follows
        {...commonProperties}
        handleGoBack={handleGoBack}
        setData={setSetterData}
        setterData={setterData}
        setHandleGoBack={setHandleGoBack}
        buttonImageGallery={buttonImageGallery}
      />
      {imageGalleryModal}
    </>
  ) : codeSection === 'seguimientos_diarios' ? (
    <>
      <DailyFollows {...commonProperties} buttonImageGallery={buttonImageGallery} />
      {imageGalleryModal}
    </>
  ) : codeSection === 'panel_control' ? (
    <StudentFollows {...commonProperties} enrollments={enrollments} />
  ) : codeSection === 'evaluacion_entidad' ? (
    <EntityFollows handleChangeSection={handleComponentId} handleGoBack={goParentPage} />
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  hasEnrollments: selectHasEnrollments(state),
  is_academy: selectUserIsAcademy(state),
  isNousCims: selectIsNousCims(state),
  enrollments: state.internalProjectReducer.enrollments,
  loadingImages: state.internalProjectReducer.loadingImages,
  resourceImages: state.internalProjectReducer.internalProjectData.images,
  user_id: selectUserInfo(state).user_id,
  latestInternalProjectSectionCode: selectIpLatestSection(state),
});

const mapDispatchToProps = (dispatch: EventDataDispatch | any) => ({
  getInternalProject: (id: number): void => dispatch(getInternalProject(id)),
  getInternalProjectAcademy: (id: number): void => dispatch(getInternalProjectAcademy(id)),
  getEnrollments: (internal_project_id: number): void => dispatch(getInternalProjectEnrollment(internal_project_id)),
  getUsersMembers: (internal_project_id: number): void => dispatch(getUsersCanBeMembers(internal_project_id)),
  getInternalProjectFollows: (internal_project_id: number): void =>
    dispatch(getInternalProjectFollows(internal_project_id)),
  getInternalProjectDailyFollows: (internal_project_id: number): void =>
    dispatch(getInternalProjectDailyFollows(internal_project_id)),
  setLatestInternalProjectSection: (internal_project_id: number, section: string) =>
    dispatch(setLatestInternalProjectSection(internal_project_id, section)),

  getInternalProjectImages: (id: number) => dispatch(getInternalProjectImages(id)),
  addInternalProjectImage: (id: number, name: string, description: string, image: string, userId: number) =>
    dispatch(addInternalProjectImage(id, name, description, image, userId)),
  editInternalProjectImage: (
    id: number,
    resourceImage: ResourceImageData,
    name: string,
    description: string,
    newImage: string,
    userId: number,
  ) => dispatch(editInternalProjectImage(id, resourceImage, name, description, newImage, userId)),
  deleteInternalProjectImage: (resourceImage: ResourceImageData) => dispatch(deleteInternalProjectImage(resourceImage)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectEdition);
