import { useEffect, useState } from 'react';
import { getInterviewEntitiesFromApi } from '../../../../api/Interview/interview';
import { InterviewEntity } from '../../../../redux/interviews/definitions';

type UseInterviewEntitiesType = {
  interviewEntities: InterviewEntity[];
  loading: boolean;
  defaultInterviewEntityId: string | null;
};

const useInterviewEntities = (zip_code: string): UseInterviewEntitiesType => {
  const [interviewEntities, setInterviewEntities] = useState([] as InterviewEntity[]);
  const [defaultInterviewEntityId, setDefaultInterviewEntityId] = useState(null as string | null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      const interviewEntities = await getInterviewEntitiesFromApi(zip_code);
      const defaultEntity = interviewEntities.find((entity: InterviewEntity) => entity.default);
      setDefaultInterviewEntityId(defaultEntity ? defaultEntity.id : null);
      setInterviewEntities(interviewEntities);
      setLoading(false);
    })();
  }, [zip_code]);

  return {
    interviewEntities,
    loading,
    defaultInterviewEntityId,
  };
};

export default useInterviewEntities;
