import { SignatureDocumentDataConsts } from './action_types';
import {
  GetSignatureDocumentsKoAction,
  GetSignatureDocumentsOkAction,
  GetSignatureDocumentsAction,
  SignatureDocument,
  SignatureDocumentType,
  UploadSignatureDocumentAction,
  UploadSignatureDocumentOkAction,
  UploadSignatureDocumentKoAction,
  RemoveSignatureDocumentAction,
  RemoveSignatureDocumentOkAction,
  RemoveSignatureDocumentKoAction,
  DownloadSignatureDocumentAction,
  DownloadSignatureDocumentOkAction,
  DownloadSignatureDocumentKoAction,
  SendSignatureDocumentOkAction,
  SendSignatureDocumentAction,
  SendSignatureDocumentKoAction,
  CancelSignatureDocumentAction,
  CancelSignatureDocumentOkAction,
  CancelSignatureDocumentKoAction,
} from './definitions';
import { UUID } from '../../types/Shared/standard';

export function getSignatureDocumentsAction(
  source_type: SignatureDocumentType,
  type_id: number,
): GetSignatureDocumentsAction {
  return {
    type: SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET,
    source_type,
    type_id,
  };
}

export function getSignatureDocumentsOkAction(signatureDocuments: SignatureDocument[]): GetSignatureDocumentsOkAction {
  return {
    type: SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET_OK,
    signatureDocuments,
  };
}

export function getSignatureDocumentsKoAction(error: string): GetSignatureDocumentsKoAction {
  return {
    type: SignatureDocumentDataConsts.SIGNATURE_DOCUMENTS_GET_KO,
    error,
  };
}

export function uploadDocument(
  uuid: UUID,
  type_id: number,
  type: SignatureDocumentType,
  file: File,
): UploadSignatureDocumentAction {
  return {
    type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT,
    uuid,
    type_id,
    document_type: type,
    file,
  };
}

export function uploadDocumentOk(document: SignatureDocument[]): UploadSignatureDocumentOkAction {
  return {
    type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_OK,
    document,
  };
}

export function uploadDocumentKo(error: string): UploadSignatureDocumentKoAction {
  return {
    type: SignatureDocumentDataConsts.UPLOAD_SIGNATURE_DOCUMENT_KO,
    error,
  };
}

export function removeDocument(id: string): RemoveSignatureDocumentAction {
  return {
    type: SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT,
    id,
  };
}

export function removeDocumentOk(id: string): RemoveSignatureDocumentOkAction {
  return {
    type: SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT_OK,
    id,
  };
}

export function removeDocumentKo(error: string): RemoveSignatureDocumentKoAction {
  return {
    type: SignatureDocumentDataConsts.REMOVE_SIGNATURE_DOCUMENT_KO,
    error,
  };
}

export function downloadDocument(file_id: number, name: string): DownloadSignatureDocumentAction {
  return {
    type: SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT,
    file_id,
    name,
  };
}

export function downloadDocumentOk(data: Blob, name: string): DownloadSignatureDocumentOkAction {
  return {
    type: SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadDocumentKo(error: string): DownloadSignatureDocumentKoAction {
  return {
    type: SignatureDocumentDataConsts.DOWNLOAD_SIGNATURE_DOCUMENT_KO,
    error,
  };
}

export function sendSignatureDocument(id: UUID): SendSignatureDocumentAction {
  return {
    type: SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT,
    id,
  };
}

export function sendSignatureDocumentOk(id: UUID, signature_id: UUID): SendSignatureDocumentOkAction {
  return {
    type: SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT_OK,
    id,
    signature_id,
  };
}

export function sendSignatureDocumentKo(error: UUID): SendSignatureDocumentKoAction {
  return {
    type: SignatureDocumentDataConsts.SEND_SIGNATURE_DOCUMENT_KO,
    error,
  };
}

export function cancelSignatureDocument(id: UUID): CancelSignatureDocumentAction {
  return {
    type: SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT,
    id,
  };
}

export function cancelSignatureDocumentOk(id: UUID, signature_id: UUID): CancelSignatureDocumentOkAction {
  return {
    type: SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT_OK,
    id,
    signature_id,
  };
}

export function cancelSignatureDocumentKo(error: UUID): CancelSignatureDocumentKoAction {
  return {
    type: SignatureDocumentDataConsts.CANCEL_SIGNATURE_DOCUMENT_KO,
    error,
  };
}
