import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './actions.module.scss';
import webStyle from '../../helpers/webStyle';
import { tFormKey, tKey } from '../../helpers/translate';

const Actions: React.FC = () => {
  const [step, setStep] = useState('REGISTRO' as 'REGISTRO' | 'LOGIN');

  const goToLogin = () => {
    setStep('LOGIN');
  };

  const goToRegister = () => {
    setStep('REGISTRO');
  };

  return (
    <div className={styles[`actions__${webStyle}`]}>
      {step !== 'REGISTRO' && (
        <Link to="/registro" onClick={() => goToRegister()}>
          {tFormKey('Registro')}
        </Link>
      )}
      {step !== 'LOGIN' && (
        <Link to="/acceso" onClick={() => goToLogin()}>
          {tKey('Iniciar sesión')}
        </Link>
      )}
      {/* <a href="/ayuda">Ayuda</a> */}
    </div>
  );
};

export default Actions;
