import React, { useEffect, useRef, useState } from 'react';
import './wysiwyg.css';
import styles from './containerWYSIWYG.module.scss';
import WYSIWYG from './Wysiwyg';

interface ContainerWYSIWYGProps {
  defaultValue?: string;
  label_origin: string;
  label: string;
  updated?: boolean;
  setData: (data: string) => void;
}

const ContainerWYSIWYG: React.FC<ContainerWYSIWYGProps> = ({
  defaultValue,
  label,
  label_origin,
  children,
  updated,
  setData,
}) => {
  const originalValue = useRef<string>();
  const [value, setValue] = useState<string>();
  useEffect(() => {
    if (!originalValue.current) {
      originalValue.current = defaultValue;
      setValue(defaultValue);
    }
  }, [defaultValue, setData]);

  useEffect(() => {
    setValue(defaultValue);
  }, [updated, defaultValue]);

  return (
    <>
      {children}
      <div className={styles.containerTextArea}>
        <div className={styles.labelsContainer}>
          <div className={styles.esLabel}>{label_origin}</div>
          <div className={styles.langLabel}>{label}</div>
        </div>
        <div className={styles.containerTextAreaCol}>
          <div className={styles.contentEs} dangerouslySetInnerHTML={{ __html: value || '' }} />
          <WYSIWYG defaultValue={defaultValue} setData={setData} />
        </div>
      </div>
    </>
  );
};

export default ContainerWYSIWYG;
