import { UUID } from '../../../types/Shared/standard';

export type JustificationId = UUID;
export type ScholarshipId = number;
export type JustificationType = 'Matrícula' | 'Mensualidad' | 'Materiales' | 'Transporte' | 'Otros';
export const justificationTypesArray = (): JustificationType[] => [
  'Matrícula',
  'Mensualidad',
  'Materiales',
  'Transporte',
  'Otros',
];
export interface Justification {
  id: JustificationId;
  scholarship_id: ScholarshipId;
  type: JustificationType;
  description: string | null;
  amount: number;
  file_id: number | null;
  filename: string | null;
}

export const JustificationsInitialState = {
  loading: false as boolean,
  error: null as string | null,
  list: [] as Justification[],
};
