import { put, takeLatest } from 'redux-saga/effects';
import { UpdateParticipantAction } from './definitions';
import { apiUpdateParticipant } from '../../../../api/InternalProject/Participants/updateParticipant';
import errorMessage from '../../../../helpers/errorMessage';
import { updateParticipantKO, updateParticipantOk } from './actions';
import { UpdateParticipantConst } from './action_types';
import { showSnackbar } from '../../../FeedbackAPI/actions';
import { tProjectKey } from '../../../../helpers/translate';

function* updateParticipantFromSaga(action: UpdateParticipantAction) {
  try {
    const { participant } = action;

    yield apiUpdateParticipant(participant);
    yield put(updateParticipantOk(participant));
    yield put(showSnackbar(tProjectKey('pi.participantes.participante_modificado'), 'success'));
  } catch (error) {
    yield put(updateParticipantKO(errorMessage(error)));
  }
}

export function* watchUpdateParticipant() {
  yield takeLatest<UpdateParticipantAction>(UpdateParticipantConst.UPDATE_PARTICIPANT, updateParticipantFromSaga);
}

export default watchUpdateParticipant();
