import assertUnreachable from '../../../helpers/assertUnreachable';
import { Justification, JustificationsInitialState } from './Justification.d';
import { ScholarshipJustificationsConst } from './action_types';
import { ScholarshipJustificationsDataAction, ScholarshipJustificationsState } from './definitions';

const scholarshipJustificationsReducer = (
  state = JustificationsInitialState,
  action: ScholarshipJustificationsDataAction,
): ScholarshipJustificationsState => {
  switch (action.type) {
    case ScholarshipJustificationsConst.GET_SCHOLARSHIP_JUSTIFICATIONS:
      return {
        ...state,
        loading: true,
        error: null,
        list: [],
      };
    case ScholarshipJustificationsConst.ADD_SCHOLARSHIP_JUSTIFICATIONS:
    case ScholarshipJustificationsConst.DELETE_SCHOLARSHIP_JUSTIFICATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ScholarshipJustificationsConst.GET_SCHOLARSHIP_JUSTIFICATIONS_OK:
      const { list } = action;
      return {
        ...state,
        loading: false,
        error: null,
        list,
      };
    case ScholarshipJustificationsConst.ADD_SCHOLARSHIP_JUSTIFICATIONS_OK:
      state.list.push(action.justification);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case ScholarshipJustificationsConst.DELETE_SCHOLARSHIP_JUSTIFICATIONS_OK:
      const { justification } = action;
      const listResumed = state.list.filter((item: Justification) => item.id !== justification.id);
      return {
        ...state,
        loading: false,
        list: listResumed,
        error: null,
      };
    case ScholarshipJustificationsConst.SCHOLARSHIP_JUSTIFICATIONS_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default scholarshipJustificationsReducer;
