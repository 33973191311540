import { Action } from 'redux';
import { ParticipantParticularitiesConst } from './action_types';

export enum ParticipantParticularitiesTypeEnum {
  SITUACIONES_DE_NEGLIGENCIA_FAMILIAR = 'Situaciones de negligencia familiar',
  SITUACIONES_SOCIOECONÓMICAS_DESFAVORECIDAS = 'Situaciones socioeconómicas desfavorecidas',
  FALTA_DE_REFERENTES_POSITIVOS_EN_EL_ENTORNO_MÁS_INMEDIATO_DE_LOS_JÓVENES = 'Falta de referentes positivos en el entorno más inmediato de los jóvenes',
  ENTORNO_FRUSTRANTE = 'Entorno frustrante',
  FRACASO_ESCOLAR = 'Fracaso escolar',
  BAJA_AUTOESTIMA = 'Baja autoestima',
  DIFICULTADES_DE_GESTIÓN_EMOCIONAL = 'Dificultades de gestión emocional',
  IMPULSIVIDAD = 'Impulsividad',
  DIFICULTADES_PARA_PEDIR_AYUDA = 'Dificultades para pedir ayuda',
  DIFICULTADES_PARA_ESTABLECER_VÍNCULOS_AFECTIVOS = 'Dificultades para establecer vínculos afectivos',
  DIFICULTAD_DE_COMUNICACIÓN = 'Dificultad de comunicación',
  FALTA_DE_ESTRATEGIAS_PARA_RESOLVER_CONFLICTOS = 'Falta de estrategias para resolver conflictos',
  ENTORNOS_FAMILIARES_DISFUNCIONALES = 'Entorno familiar disfuncional',
  FALTA_DE_MODELO_O_REFERENTE_ADULTO_SANO_Y_ESTABLE_A_LO_LARGO_DE_LA_VIDA = 'Falta de modelo o referente adulto sano y estable a lo largo de la vida',
  MALTRATO = 'Maltrato',
  RECHAZO_DEL_ENTORNO = 'Rechazo del entorno',
  MENORES_EN_RIESGO_DE_EXCLUSIÓN_SOCIAL = 'Menores en riesgo de exclusión social',
  ABANDONOS = 'Abandonos',
  JOVEN_VINCULADO_A_LAS_ENTIDADES_O_RECURSOS_SOCIALES_EDUCATIVOS_O_DE_PROTECCIÓN_DE_LA_INFANCIA = 'Joven vinculado a las entidades o recursos sociales educativos o de protección de la infancia',
  OTRO = 'Otro',
}

export type ParticipantParticularitiesType =
  | 'Situaciones de negligencia familiar'
  | 'Situaciones socioeconómicas desfavorecidas'
  | 'Falta de referentes positivos en el entorno más inmediato de los jóvenes'
  | 'Entorno frustrante'
  | 'Fracaso escolar'
  | 'Baja autoestima'
  | 'Dificultades de gestión emocional'
  | 'Impulsividad'
  | 'Dificultades para pedir ayuda'
  | 'Dificultades para establecer vínculos afectivos'
  | 'Dificultad de comunicación'
  | 'Falta de estrategias para resolver conflictos'
  | 'Entorno familiar disfuncional'
  | 'Falta de modelo o referente adulto sano y estable a lo largo de la vida'
  | 'Maltrato'
  | 'Rechazo del entorno'
  | 'Menores en riesgo de exclusión social'
  | 'Abandonos'
  | 'Joven vinculado a las entidades o recursos sociales educativos o de protección de la infancia'
  | 'Otro';

export interface ResponseParticipantParticularitiesTypeApi {
  [key: number]: ParticipantParticularitiesType;
}

export interface GetParticipantParticularities extends Action {
  type: ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES;
}

export interface GetParticipantParticularitiesOk extends Action {
  type: ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES_OK;
  data: ParticipantParticularitiesType[];
}

export interface GetParticipantParticularitiesKo extends Action {
  type: ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES_KO;
  error: string;
}

export type ParticipantParticularitiesDataAction =
  | GetParticipantParticularities
  | GetParticipantParticularitiesOk
  | GetParticipantParticularitiesKo;

export const initialState = {
  loading: false,
  data: [] as ParticipantParticularitiesType[],
  error: null as string | null,
};

export type ParticipantParticularitiesState = typeof initialState;
