import Button from '@material-ui/core/Button';
import React from 'react';
import { useForm } from 'react-hook-form';
import { APIValidationError } from '../../../api/api';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import SwitchController from '../../../components/SwitchController/SwitchController';
import UploadFileButton from '../../../components/UploadFileButton/UploadFileButton';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../../helpers/translate';
import { AcademyIban } from '../../../redux/academy/definitions';
import { AcademySchema } from '../../../validations/formSchema';
import styles from './formGenericIbanModal.module.scss';

interface FormGenericIbanModalProps {
  loading: boolean;
  isEditing: boolean;
  academyId: number;
  academyIban: AcademyIban;
  validationErrors: APIValidationError | null;
  setData: (key: string, value: string | number) => void;
  onUploadFile: (file: File) => void;
  onRemoveFile: (file_id: number) => void;
  onDownloadFile: (file_id: number, filename: string) => void;
  onSave: (academyId: number, academyIban: AcademyIban) => void;
}

const UnConnectedGenericFormIbanModal: React.FC<FormGenericIbanModalProps> = ({
  loading,
  isEditing,
  academyId,
  academyIban,
  validationErrors,
  setData,
  onUploadFile,
  onRemoveFile,
  onDownloadFile,
  onSave,
}) => {
  const { handleSubmit, errors, control, setValue, setError } = useForm<AcademyIban>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AcademySchema,
  });

  useValidatorAPI(validationErrors, setError);

  const onSubmit = () => {
    onSave(academyId, academyIban);
  };

  if (loading) return <Loading big />;
  return (
    <div className={styles.modalContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer title="" className="modalFormContainer">
          <div className={styles.modalRow}>
            <TextInputController
              size="100"
              control={control}
              errors={errors}
              type="text"
              name="alias"
              schema={AcademySchema}
              defaultValue={academyIban.alias}
              label={tFormKey('Nombre')}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('alias', e.target.value)}
            />
            <TextInputController
              size="100"
              control={control}
              errors={errors}
              type="text"
              name="iban"
              label={tFormKey('IBAN')}
              schema={AcademySchema}
              disabled={isEditing}
              defaultValue={academyIban.iban}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                const ibanInputValue = e.target.value.replace(/\s/g, '');
                setData('iban', ibanInputValue);
                setValue('iban', ibanInputValue);
              }}
            />
          </div>
          <div className={`${styles.modalRow} ${styles.modalRowTwoCol}`}>
            <UploadFileButton
              file_id={academyIban.file_id}
              filename={academyIban.filename}
              errors={errors}
              readOnly={isEditing}
              onUploadFile={onUploadFile}
              downloadFile={onDownloadFile}
              removeFile={onRemoveFile}
              setValue={setValue}
            />
            <TextInputController
              control={control}
              errors={errors}
              type="number"
              name="file_id"
              schema={AcademySchema}
              defaultValue={academyIban.file_id}
              visible={false}
            />
            <SwitchController
              control={control}
              name="active"
              label={!!academyIban.active || academyIban.active === undefined ? tKey('Activado') : tKey('Desactivado')}
              defaultValue={!!academyIban.active || academyIban.active === undefined}
              checked={!!academyIban.active || academyIban.active === undefined}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData('active', +e.target.checked);
              }}
            />
          </div>
          <div className={`${styles.modalRow} ${styles.modalRowFooter}`}>
            <Button variant="outlined" color="primary" style={{ marginLeft: '10px', width: '120px' }} type="submit">
              {tFormKey('Guardar')}
            </Button>
          </div>
        </FormContainer>
      </form>
    </div>
  );
};

export default UnConnectedGenericFormIbanModal;
