import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Delete, CloudDownload, Edit } from '@material-ui/icons';
import React from 'react';
import { useWindowSize } from '../../../../helpers/customHooks/useHookMethod';
import { formatNumber } from '../../../../helpers/formatNumber';
import { FamilyIncome } from '../../../../redux/scholarship/familyIncomes/FamilyIncome';
import styles from './familyIncomesTable.module.scss';
import { tKey } from '../../../../helpers/translate';

interface FamilyIncomesRowProps {
  familyIncome: FamilyIncome;
  disabled: boolean;
  onRemove: (familyIncome: FamilyIncome) => void;
  onDownload: (file_id: number, filename: string) => void;
  onEdit: (familyIncome: FamilyIncome) => void;
}

const FamilyIncomesRow: React.FC<FamilyIncomesRowProps> = ({
  familyIncome,
  disabled,
  onRemove,
  onDownload,
  onEdit,
}) => {
  const iconColor = (disabled: boolean) => {
    const opacity = disabled ? 0.2 : 0.54;
    return { color: `rgba(0, 0, 0, ${opacity})` };
  };

  const isMobile = useWindowSize().IsMobile;

  const disableDownload = !familyIncome.file_id;

  const canEditRow = !disabled && isMobile;

  const downloadTitle = familyIncome.filename || tKey('Descargar');
  const renderActions = () => {
    return (
      <td className={`${styles.familyIncomeCellRow}  ${styles.actionCell}`}>
        {!isMobile ? (
          <>
            <Tooltip title="Editar">
              <IconButton aria-label="Editar">
                <Edit style={iconColor(disabled)} onClick={() => (disabled ? undefined : onEdit(familyIncome))} />
              </IconButton>
            </Tooltip>
            <Tooltip title={downloadTitle}>
              <IconButton aria-label={downloadTitle}>
                <CloudDownload
                  style={iconColor(disableDownload)}
                  onClick={() =>
                    disableDownload ? undefined : onDownload(familyIncome.file_id || 0, familyIncome.filename || '')
                  }
                />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          undefined
        )}
        <Tooltip title="Eliminar">
          <IconButton aria-label="eliminar">
            <Delete style={iconColor(disabled)} onClick={() => (disabled ? undefined : onRemove(familyIncome))} />
          </IconButton>
        </Tooltip>
      </td>
    );
  };

  return (
    <tr className={styles.familyIncomeTableRow}>
      <td className={`${styles.familyIncomeCellRow}  ${styles.incomeTypeCell}`}>{familyIncome.income_type}</td>
      <td className={`${styles.familyIncomeCellRow}  ${styles.familyMemberCell}`}>{familyIncome.family_member}</td>
      <td
        className={`${styles.familyIncomeCellRow}  ${styles.amountCell}`}
        onClick={() => (canEditRow ? onEdit(familyIncome) : undefined)}
      >{`${formatNumber({
        value: familyIncome.amount,
      })} €`}</td>
      {renderActions()}
    </tr>
  );
};

export default FamilyIncomesRow;
