import { Tooltip } from '@material-ui/core';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { UserItemList } from '../../redux/user/definitions';
import { AppState } from '../../redux/root-reducer';

interface UserSubscriptionTypeTableColumnRenderProps {
  rowData: UserItemList;
}

const UserSubscriptionTypeTableColumnRender: React.FC<UserSubscriptionTypeTableColumnRenderProps> = ({ rowData }) => {
  const color = rowData.subscribed ? 'green' : 'red';
  return (
    <Fragment>
      <Tooltip key={rowData.id} title={rowData.subscribed ? 'Sí' : 'No'}>
        <Brightness1Icon style={{ color: `${color}`, height: '15px', verticalAlign: 'text-bottom' }} />
      </Tooltip>
    </Fragment>
  );
};

export default connect((state: AppState) => ({
  entityTypes: state.entityReducer.entityTypes,
}))(UserSubscriptionTypeTableColumnRender);
