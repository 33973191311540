import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../../helpers/translate';
import { ScholarshipFollow } from '../../../../../redux/scholarship/definitions';
import FollowUpCode from '../../../../../types/Scholarships/followUpCode';
import styles from '../followForm.module.scss';

interface AcademicResultGradesViewProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  readOnly: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  followUpCode: FollowUpCode;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  setDataFollow: (subKey: string, value: string | number) => void;
}

const AcademicResultGradesView = <T extends FieldValues>({
  scholarshipFollow,
  readOnly,
  control,
  errors,
  followUpCode,
  schema,
  setDataFollow,
}: AcademicResultGradesViewProps<T>): ReactElement => {
  const e20 = Array.from(Array(21).keys());
  const { average_grade, number_subject_studying, number_failed_subject, number_approved_subject } = scholarshipFollow;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  return (
    <div className={styles.container}>
      <TextInputController
        control={control}
        type="number"
        name="average_grade"
        label={
          !followUpCode.isBiannual()
            ? tScholarshipKey('¿Qué nota media ha obtenido este trimestre?')
            : tScholarshipKey('¿Qué nota media ha obtenido este semestre?')
        }
        defaultValue={average_grade}
        disabled={readOnly}
        validator={!readOnly && average_grade === null}
        schema={schema}
        errors={errors}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => setDataFollow('average_grade', e.target.value)}
      />
      <SelectController
        control={control}
        name="number_subject_studying"
        label={tScholarshipKey('¿Cuántas asignaturas/módulos ha cursado?')}
        defaultValue={number_subject_studying}
        validator={!readOnly && number_subject_studying === null}
        disabled={readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {e20
          .map((element: number) => (
            <MenuItem key={element} value={element}>
              {element}
            </MenuItem>
          ))
          .slice(1)}
      </SelectController>
      <SelectController
        control={control}
        name="number_approved_subject"
        label={tScholarshipKey('¿Cuántas asignaturas/módulos ha aprobado?')}
        validator={!readOnly && number_approved_subject === null}
        defaultValue={number_approved_subject}
        disabled={readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {e20.map((element: number) => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name="number_failed_subject"
        validator={!readOnly && number_failed_subject === null}
        label={tScholarshipKey('¿Cuántas asignaturas/módulos ha suspendido?')}
        defaultValue={number_failed_subject}
        disabled={readOnly}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
      >
        {e20.map((element: number) => (
          <MenuItem key={element} value={element}>
            {element}
          </MenuItem>
        ))}
      </SelectController>
    </div>
  );
};

export default AcademicResultGradesView;
