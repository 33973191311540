import React from 'react';
import { connect } from 'react-redux';
import { checkScholarshipRemainder } from '../../../api/Scholarship/scholarship';
import { APIValidationError } from '../../../api/api';
import { documentationFilter } from '../../../helpers/docFilter';
import { selectIsNousCims } from '../../../redux/auth/selectors';
import { DocumentationItem, KeyButton } from '../../../redux/common/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import { downloadDocument, getDocument, removeDocument, uploadDocument } from '../../../redux/scholarship/actions';
import {
  CheckScholarshipRemainderData,
  KeepStudyingOption,
  RejectScholarshipData,
  ScholarshipAgreementData,
  ScholarshipData,
  ScholarshipMetadata,
  ScholarshipStateChange,
  ScholarshipStateCode,
  SubmenuItemTypeOption,
} from '../../../redux/scholarship/definitions';
import {
  selectGetScholarship,
  selectGetScholarshipAgreement,
  selectGetScholarshipDocumentation,
  selectGetScholarshipMetadata,
  selectScholarshipValidationErrors,
} from '../../../redux/scholarship/selectors';
import { scholarshipStateTransitionAction } from '../../../redux/scholarship/transitions/actions';
import FollowUpCode from '../../../types/Scholarships/followUpCode';
import { getButtonType } from '../ButtonActions/ScholarshipButtonActions';
import ScholarshipRemainderModal from '../Modals/ScholarshipRemainder/ScholarshipRemainderModal';
import ApprovalForm from '../formLayouts/approvalForm/ApprovalForm';
import AssessmentForm from '../formLayouts/assessmentForm/AssessmentForm';
import ConfirmationForm from '../formLayouts/confirmationForm/ConfirmationForm';
import EconomicJustificationForm from '../formLayouts/economicJustificationForm/EconomicJustificationForm';
import FollowUp1Form from '../formLayouts/followForm/FollowUp1Form';
import FollowUp2Form from '../formLayouts/followForm/FollowUp2Form';
import FollowUp3Form from '../formLayouts/followForm/FollowUp3Form';
import InterviewForm from '../formLayouts/interviewForm/InterviewForm';
import PaymentForm from '../formLayouts/paymentForm/PaymentForm';
import CurrentSituationForm from '../formLayouts/requestForms/CurrentSituationForm';
import EconomicalValorationForm from '../formLayouts/requestForms/EconomicalValorationForm';
import EducationalValorationForm from '../formLayouts/requestForms/EducationalValoration/EducationalValorationForm';
import MotivationalValorationForm from '../formLayouts/requestForms/MotivationalValorationForm';
import PersonalInformationForm from '../formLayouts/requestForms/PersonalInformationForm';
import ScholarshipCostForm from '../formLayouts/requestForms/ScholarshipCostForm';
import useRemainderModalData from './useRemainderModalData';

interface Props {
  componentType: SubmenuItemTypeOption;
  isEdit: boolean;
  isSemestral: boolean;
  readOnly: boolean;
  documentation: DocumentationItem[];
  scholarshipData: ScholarshipData;
  scholarshipAgreementData: ScholarshipAgreementData;
  scholarshipMetadata: ScholarshipMetadata;
  validationErrors: APIValidationError | null;
  isPartner: boolean;
  isNouscims: boolean;
  isTecBec: boolean;
  uploadDocument: (documentation_id: number, data: File, scholarship_id: number, multiDocument?: boolean) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  removeDocument: (documentation_id: number, scholarship_id: number) => void;
  scholarshipStateTransition: (
    id: number,
    states: ScholarshipStateChange,
    redirect?: string,
    data?: ScholarshipData | RejectScholarshipData,
  ) => void;
}
const SubmenuItemWithComponent = ({
  componentType,
  isEdit,
  isSemestral,
  readOnly,
  documentation,
  scholarshipData,
  scholarshipAgreementData,
  scholarshipMetadata,
  validationErrors,
  isPartner,
  isNouscims,
  isTecBec,
  uploadDocument,
  downloadDocument,
  getDocument,
  removeDocument,
  scholarshipStateTransition,
}: Props): JSX.Element => {
  const { showModal, remainderAmount, stateChange, setRemainderModalData, closeModal } = useRemainderModalData();
  const onlyReadMode =
    scholarshipData?.state_code !== ScholarshipStateCode.CREATION ||
    (scholarshipData?.state_code === ScholarshipStateCode.CREATION && (isNouscims || isPartner));

  const isPendingInfo = scholarshipData?.state_code === ScholarshipStateCode.PENDING_INFO && isTecBec;
  const addDocument = (document: File, description: string, genericId?: number, documentation_id?: number) => {
    if (documentation_id) {
      uploadDocument(documentation_id, document, scholarshipData.id, true);
    }
  };

  const isFirstFollowUpToComplete = (): boolean => {
    return (
      [ScholarshipStateCode.FOLLOW_T1, ScholarshipStateCode.FOLLOW_S1].includes(scholarshipData.state_code) &&
      scholarshipData.follow_t1?.keep_studying === KeepStudyingOption.FIN
    );
  };

  const isT2FollowUpToComplete = (): boolean => {
    return (
      scholarshipData.state_code === ScholarshipStateCode.FOLLOW_T2 &&
      scholarshipData.follow_t2?.keep_studying === KeepStudyingOption.FIN
    );
  };

  const isLastFollowUpToComplete = (): boolean => {
    return [ScholarshipStateCode.FOLLOW_T3, ScholarshipStateCode.FOLLOW_S2].includes(scholarshipData.state_code);
  };

  const handleSendFollowUp = async (states: ScholarshipStateChange) => {
    // Si se completa la beca, se pregunta por el remanente
    if (isFirstFollowUpToComplete() || isT2FollowUpToComplete() || isLastFollowUpToComplete()) {
      const remainderData: CheckScholarshipRemainderData = await checkScholarshipRemainder(scholarshipData.id);
      if (scholarshipData.isEntity && remainderData.has_remainder) {
        setRemainderModalData(remainderData.remainder_amount, states);
        return;
      }
    }
    scholarshipStateTransition(scholarshipData.id, states, undefined, scholarshipData);
  };

  const documentationUN = (): DocumentationItem[] => {
    return documentationFilter(documentation, ['UN']);
  };

  const closeRemainderModal = (): void => {
    closeModal();
  };

  const confirmRemainderModal = (remainingAmount: number, remainderRemarks: string): void => {
    closeModal();
    scholarshipStateTransition(scholarshipData.id, stateChange as ScholarshipStateChange, undefined, {
      ...scholarshipData,
      remainder_amount: remainderAmount,
      remainder_remarks: remainderRemarks,
    });
  };

  const renderRemainderModal = (): JSX.Element => {
    return (
      <ScholarshipRemainderModal
        show={showModal}
        remainingAmount={remainderAmount}
        onClose={closeRemainderModal}
        onConfirm={confirmRemainderModal}
      />
    );
  };

  const renderfollow1Form = (): JSX.Element => {
    return (
      <>
        <FollowUp1Form
          scholarshipMetadata={scholarshipMetadata}
          scholarshipData={scholarshipData}
          readOnly={isEdit ? false : readOnly}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          addDoc={addDocument}
          getFile={getDocument}
          documentationUN={documentationUN()}
          validationErrors={validationErrors}
          followUpCode={FollowUpCode.create(1, isSemestral)}
          onChangeState={handleSendFollowUp}
        />
        {renderRemainderModal()}
      </>
    );
  };

  const renderfollow2Form = (): JSX.Element => {
    return (
      <>
        <FollowUp2Form
          scholarshipMetadata={scholarshipMetadata}
          scholarshipData={scholarshipData}
          readOnly={isEdit ? false : readOnly}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          addDoc={addDocument}
          getFile={getDocument}
          documentationUN={documentationUN()}
          validationErrors={validationErrors}
          followUpCode={FollowUpCode.create(2, isSemestral)}
          onChangeState={handleSendFollowUp}
        />
        {renderRemainderModal()}
      </>
    );
  };

  const renderfollow3Form = (): JSX.Element => {
    return (
      <>
        <FollowUp3Form
          scholarshipMetadata={scholarshipMetadata}
          scholarshipData={scholarshipData}
          readOnly={isEdit ? false : readOnly}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          addDoc={addDocument}
          getFile={getDocument}
          documentationUN={documentationUN()}
          validationErrors={validationErrors}
          followUpCode={FollowUpCode.create(3, isSemestral)}
          onChangeState={handleSendFollowUp}
        />
        {renderRemainderModal()}
      </>
    );
  };
  const buttonSave = getButtonType(KeyButton.SAVE);

  switch (componentType) {
    case SubmenuItemTypeOption.DATOS_JOVEN:
      return (
        <PersonalInformationForm
          scholarshipData={scholarshipData}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          getFile={getDocument}
          addDoc={addDocument}
          buttonActionSave={buttonSave}
          readOnly={isEdit ? false : onlyReadMode}
        />
      );
    case SubmenuItemTypeOption.VALORACION_MOTIVACIONAL:
      return (
        <MotivationalValorationForm
          scholarshipData={scholarshipData}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          getFile={getDocument}
          addDoc={addDocument}
          buttonActionSave={buttonSave}
          readOnly={isPendingInfo || isEdit ? false : onlyReadMode}
        />
      );
    case SubmenuItemTypeOption.SITUACION_ACTUAL:
      return (
        <CurrentSituationForm
          scholarshipData={scholarshipData}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          getFile={getDocument}
          addDoc={addDocument}
          buttonActionSave={buttonSave}
          readOnly={isPendingInfo || isEdit ? false : onlyReadMode}
        />
      );
    case SubmenuItemTypeOption.VALORACION_EDUCATIVA:
      return (
        <EducationalValorationForm
          scholarshipData={scholarshipData}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          getFile={getDocument}
          addDoc={addDocument}
          buttonActionSave={buttonSave}
          readOnly={isPendingInfo || isEdit ? false : onlyReadMode}
        />
      );
    case SubmenuItemTypeOption.VALORACION_ECONOMICA:
      return (
        <EconomicalValorationForm
          scholarshipData={scholarshipData}
          buttonActionSave={buttonSave}
          readOnly={isPendingInfo || isEdit ? false : onlyReadMode}
        />
      );
    case SubmenuItemTypeOption.IMPORTE_BECA:
      return (
        <ScholarshipCostForm
          scholarshipData={scholarshipData}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          deleteDocument={removeDocument}
          getFile={getDocument}
          addDoc={addDocument}
          buttonActionSave={buttonSave}
          readOnly={isPendingInfo || isEdit ? false : onlyReadMode}
        />
      );
    case SubmenuItemTypeOption.ENTREVISTA:
      return (
        <InterviewForm
          scholarshipData={scholarshipData}
          readOnly={readOnly}
          buttonActionSave={buttonSave}
          buttonActionSendInterview={getButtonType(KeyButton.SEND_INTERVIEW)}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          getFile={getDocument}
          deleteDocument={removeDocument}
        />
      );
    case SubmenuItemTypeOption.VALORACION_FORM:
      return (
        <AssessmentForm
          scholarshipData={scholarshipData}
          readOnly={readOnly}
          buttonActionSave={buttonSave}
          buttonActionSendAssessment={getButtonType(KeyButton.SEND_ASSESSMENT)}
        />
      );
    case SubmenuItemTypeOption.APROBACION:
      return (
        <ApprovalForm
          scholarshipData={scholarshipData}
          readOnly={readOnly}
          buttonActionSave={buttonSave}
          buttonActionApprove={getButtonType(KeyButton.APPROVE)}
        />
      );
    case SubmenuItemTypeOption.CONFIRMACION:
      return (
        <ConfirmationForm
          scholarshipData={scholarshipData}
          readOnly={readOnly}
          buttonActionSave={buttonSave}
          buttonActionSendConfirmation={getButtonType(KeyButton.CONFIRM)}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          getFile={getDocument}
          deleteDocument={removeDocument}
          addDoc={addDocument}
        />
      );
    case SubmenuItemTypeOption.PAGOS:
      return (
        <PaymentForm
          scholarshipData={scholarshipData}
          scholarshipAgreementData={scholarshipAgreementData}
          readOnly={readOnly}
          buttonActionEdit={getButtonType(KeyButton.EDIT)}
          buttonActionSave={buttonSave}
          buttonActionSaveAgreement={getButtonType(KeyButton.SAVE_AGREEMENT)}
          buttonActionAdjustment={getButtonType(KeyButton.PAYMENT_ADJUSTMENT)}
          buttonActionSendProvider={getButtonType(KeyButton.SEND_PROVIDER)}
          buttonCancelAgreement={getButtonType(KeyButton.CANCEL_AGREEMENT)}
          uploadDocument={uploadDocument}
          downloadDocument={downloadDocument}
          getFile={getDocument}
          deleteDocument={removeDocument}
        />
      );
    case SubmenuItemTypeOption.JUSTIFICACION_ECONOMICA:
      return (
        <EconomicJustificationForm
          readOnly={readOnly || scholarshipData.state_code === ScholarshipStateCode.ABANDONMENT}
          scholarshipId={scholarshipData.id}
          scholarshipStateCode={scholarshipData.state_code}
          totalPaid={scholarshipData.total_paid}
          buttonActionSendJustifications={getButtonType(KeyButton.SEND_JUSTIFICATIONS)}
        />
      );
    case SubmenuItemTypeOption.FOLLOW_T1:
      return renderfollow1Form();
    case SubmenuItemTypeOption.FOLLOW_T2:
      return renderfollow2Form();
    case SubmenuItemTypeOption.FOLLOW_T3:
      return renderfollow3Form();
    default:
      return <></>;
  }
};

const mapStateToProps = (state: AppState) => ({
  scholarshipData: selectGetScholarship(state),
  scholarshipAgreementData: selectGetScholarshipAgreement(state),
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  validationErrors: selectScholarshipValidationErrors(state),
  documentation: selectGetScholarshipDocumentation(state),
  isTecBec: selectUserCan(state)('action_scholarships_users'),
  isPartner: selectUserCan(state)('action_scholarships_partners'),
  isNouscims: selectIsNousCims(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  uploadDocument: (documentation_id: number, data: File, scholarship_id: number, multiDocument?: boolean) =>
    dispatch(uploadDocument(documentation_id, data, scholarship_id, multiDocument)),
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(downloadDocument(documentation_id, name, scholarship_id)),
  getDocument: (documentation_id: number, name: string, scholarship_id: number) =>
    dispatch(getDocument(documentation_id, name, scholarship_id)),
  removeDocument: (documentation_id: number, scholarship_id: number) =>
    dispatch(removeDocument(documentation_id, scholarship_id)),
  scholarshipStateTransition: (
    id: number,
    states: ScholarshipStateChange,
    redirect?: string,
    data?: ScholarshipData | RejectScholarshipData,
  ): void => dispatch(scholarshipStateTransitionAction(id, states, redirect, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmenuItemWithComponent);
