class StudyCenterNature {
  static PRIVAT = 'Privat';
  static PUBLIC = 'Públic';
  static CONCERTAT = 'Concertat';

  constructor(private code: string) {}

  public isPublic(): boolean {
    return this.code === StudyCenterNature.PUBLIC;
  }

  public static toArray(): string[] {
    return [StudyCenterNature.PRIVAT, StudyCenterNature.PUBLIC, StudyCenterNature.CONCERTAT];
  }
}

export default StudyCenterNature;
