import { UUID } from '../Shared/standard';

export enum InternalProjectTypesEnum {
  'BIEKOM' = 'KOMTU',
  'BIESAL' = 'KOA',
  'BIEONC' = 'DOMUM',
  'VOCCOA' = 'Coaching Individual',
  'VOCGRU' = 'Taller grupal',
}

export type InternalProjectType = 'BIESAL' | 'VOCCOA' | 'BIEKOM' | 'VOCGRU' | 'BIEONC';

export type InternalProjectTypeData = {
  id: UUID;
  name: InternalProjectType;
  subarea_id: number;
};
