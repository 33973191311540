import { APIValidationError } from '../../api/api';
import { CustomFieldsConst } from './action_types';
import {
  AddKpiIndicatorAction,
  Assigment,
  AssigmentOk,
  ClearCustomFieldDeactivationAction,
  CloseModalAction,
  CreateCustomfield,
  CreateCustomfieldOk,
  CreateKpiIndicatorAction,
  CreateKpiIndicatorActionOk,
  CustomField,
  CustomFieldGenericActionKo,
  CustomFieldValidationErrorsAction,
  DeactivateCustomFieldAction,
  DeactivateCustomFieldActionKo,
  DeactivateCustomFieldActionOk,
  DeleteCustomfieldAction,
  DeleteCustomfieldActionOk,
  DeleteCustomfieldDeactivateAction,
  EditCustomfield,
  EditCustomfieldOk,
  GetCustomFieldList,
  GetCustomFieldListOk,
  GetKpiIndicatorAction,
  GetKpiIndicatorActionOk,
  GetKpiIndicatorsAction,
  GetKpiIndicatorsActionOk,
  GetOneCustomField,
  GetOneCustomFieldOk,
  KpiIndicatorType,
  Point,
  RemoveKpiIndicatorAction,
  RemoveKpiIndicatorActionOk,
  SetKpiIndicatorDataAction,
  Unassigment,
  UnassigmentOk,
  UpdateKpiIndicatorAction,
  UpdateKpiIndicatorActionOk,
} from './definitions';

//GET ALL CUSTOMFIELDS

export function getCustomFieldList(code: string): GetCustomFieldList {
  return {
    type: CustomFieldsConst.GET_CUSTOMFIELDS_LIST,
    code,
  };
}

export function getCustomFieldListOk(listCustomFields: CustomField[]): GetCustomFieldListOk {
  return {
    type: CustomFieldsConst.GET_CUSTOMFIELDS_LIST_OK,
    listCustomFields,
  };
}

//GET ONE CUSTOMFIELDS

export function getOneCustomField(code: string, id: string): GetOneCustomField {
  return {
    type: CustomFieldsConst.GET_ONE_CUSTOMFIELDS,
    code,
    id,
  };
}

export function getOneCustomFieldOK(customField: CustomField): GetOneCustomFieldOk {
  return {
    type: CustomFieldsConst.GET_ONE_CUSTOMFIELDS_OK,
    customField,
  };
}

//CREATE CUSTOMFIELD

export function createCustomfield(data: CustomField): CreateCustomfield {
  return {
    type: CustomFieldsConst.CREATE_CUSTOMFIELD,
    data,
  };
}

export function createCustomfieldOk(data: CustomField): CreateCustomfieldOk {
  return {
    type: CustomFieldsConst.CREATE_CUSTOMFIELD_OK,
    data,
  };
}

//EDIT CUSTOMFIELD

export function editCustomfield(data: CustomField, id: string): EditCustomfield {
  return {
    type: CustomFieldsConst.EDIT_CUSTOMFIELD,
    data,
    id,
  };
}

export function editCustomfieldOk(data: CustomField): EditCustomfieldOk {
  return {
    type: CustomFieldsConst.EDIT_CUSTOMFIELD_OK,
    data,
  };
}

//DELETE CUSTOMFIELD

export function deleteCustomfield(id: number): DeleteCustomfieldAction {
  return {
    type: CustomFieldsConst.DELETE_CUSTOMFIELD,
    id,
  };
}

export function deleteCustomfieldOk(id: number): DeleteCustomfieldActionOk {
  return {
    type: CustomFieldsConst.DELETE_CUSTOMFIELD_OK,
    id,
  };
}

export function deleteCustomfieldDeactivate(id: number): DeleteCustomfieldDeactivateAction {
  return {
    type: CustomFieldsConst.DELETE_CUSTOMFIELD_DEACTIVATE,
    id,
  };
}

export function deactivateCustomField(id: number): DeactivateCustomFieldAction {
  return {
    type: CustomFieldsConst.DEACTIVATE_CUSTOMFIELD,
    id,
  };
}

export function deactivateCustomFieldOk(id: number): DeactivateCustomFieldActionOk {
  return {
    type: CustomFieldsConst.DEACTIVATE_CUSTOMFIELD_OK,
    id,
  };
}

export function deactivateCustomFieldKo(error: string): DeactivateCustomFieldActionKo {
  return {
    type: CustomFieldsConst.DEACTIVATE_CUSTOMFIELD_KO,
    error,
  };
}

export function clearCustomFieldDeactivation(): ClearCustomFieldDeactivationAction {
  return {
    type: CustomFieldsConst.CLEAR_CUSTOMFIELD_DEACTIVATION,
  };
}

// VALIDATIONS ERRORS

export function customFieldValidationErrors(validationErrors: APIValidationError): CustomFieldValidationErrorsAction {
  return {
    type: CustomFieldsConst.CUSTOMFIELD_VALIDATION_ERROR,
    validationErrors,
  };
}

//ASSIGMENT
export function assigment(point: Point, data: any): Assigment {
  return {
    type: CustomFieldsConst.ASSIGMENTS,
    point,
    data,
  };
}

export function assigmentOk(): AssigmentOk {
  return {
    type: CustomFieldsConst.ASSIGMENTS_OK,
  };
}

//UNASSIGMENT
export function unassigment(point: Point, data: any, section: string): Unassigment {
  return {
    type: CustomFieldsConst.UNASSIGMENTS,
    point,
    data,
    section,
  };
}

export function unassigmentOk(): UnassigmentOk {
  return {
    type: CustomFieldsConst.UNASSIGMENTS_OK,
  };
}

export function getKpiIndicators(): GetKpiIndicatorsAction {
  return {
    type: CustomFieldsConst.GET_KPI_INIDICATORS,
  };
}

export function getKpiIndicatorsOk(kpiTypes: KpiIndicatorType[]): GetKpiIndicatorsActionOk {
  return {
    type: CustomFieldsConst.GET_KPI_INIDICATORS_OK,
    kpiTypes,
  };
}

export function getKpiIndicator(id: number): GetKpiIndicatorAction {
  return {
    type: CustomFieldsConst.GET_KPI_INIDICATOR,
    id,
  };
}

export function getKpiIndicatorOk(kpiType: KpiIndicatorType): GetKpiIndicatorActionOk {
  return {
    type: CustomFieldsConst.GET_KPI_INIDICATOR_OK,
    kpiType,
  };
}

export function updateKpiIndicator(kpiType: KpiIndicatorType): UpdateKpiIndicatorAction {
  return {
    type: CustomFieldsConst.UPDATE_KPI_INIDICATOR,
    kpiType,
  };
}

export function updateKpiIndicatorOk(kpiTypes: KpiIndicatorType[]): UpdateKpiIndicatorActionOk {
  return {
    type: CustomFieldsConst.UPDATE_KPI_INIDICATOR_OK,
    kpiTypes,
  };
}

export function createKpiIndicator(kpiType: KpiIndicatorType): CreateKpiIndicatorAction {
  return {
    type: CustomFieldsConst.CREATE_KPI_INIDICATOR,
    kpiType,
  };
}

export function createKpiIndicatorOk(kpiTypes: KpiIndicatorType[]): CreateKpiIndicatorActionOk {
  return {
    type: CustomFieldsConst.CREATE_KPI_INIDICATOR_OK,
    kpiTypes,
  };
}

export function removeKpiIndicator(id: number): RemoveKpiIndicatorAction {
  return {
    type: CustomFieldsConst.REMOVE_KPI_INIDICATOR,
    id,
  };
}

export function removeKpiIndicatorOk(id: number): RemoveKpiIndicatorActionOk {
  return {
    type: CustomFieldsConst.REMOVE_KPI_INIDICATOR_OK,
    id,
  };
}

export function addKpiIndicator(): AddKpiIndicatorAction {
  return {
    type: CustomFieldsConst.ADD_KPI_INIDICATOR,
  };
}

export function closeModalKpiIndicator(): CloseModalAction {
  return {
    type: CustomFieldsConst.CLOSE_MODAL,
  };
}

export function setKpiIndicatorData(key: string, value: string): SetKpiIndicatorDataAction {
  return {
    type: CustomFieldsConst.SET_KPI_INIDICATOR_DATA,
    key,
    value,
  };
}

export function customFieldsGenericKo(error: string): CustomFieldGenericActionKo {
  return {
    type: CustomFieldsConst.CUSTOMFIELDS_GENERIC_KO,
    error,
  };
}
