import { Button, ButtonProps, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../components/Forms/FormContainer';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../components/Layout/LayoutForm';
import Loading from '../../components/Loading/Loading';
import TransitionModal from '../../components/Modal/TransitionModal';
import SelectController from '../../components/Select/SelectController';
import FncAddDocuments from '../../components/UploadFile/FncAddDocuments';
import TableWithEdit from '../../components/Tables/TableWithEdit';
import Title from '../../components/Title/Title';
import { useHookMethod } from '../../helpers/customHooks/useHookMethod';
import { tProjectKey } from '../../helpers/translate';
import { selectIsNousCims, selectUserInfo } from '../../redux/auth/selectors';
import { DocumentationItem, IdNamePair, KeyButton } from '../../redux/common/definitions';
import {
  addDocument,
  createProject,
  deleteActor,
  deleteDocument,
  downloadDocument,
  editProject,
  getDocument,
  projectSetData,
  sendActor,
} from '../../redux/project/action';
import { ProjectData, ProjectDispatch } from '../../redux/project/definitions';
import {
  selectActorsDefault,
  selectActorsFiltered,
  selectDocumentation,
  selectLoading,
  selectProjectData,
} from '../../redux/project/selectors';
import { AppState } from '../../redux/root-reducer';
import { ProjectBaseSchema } from '../../validations/formSchema';
import styles from './createProject.module.scss';
import ProjectMenuRight from './ProjectMenuRight/ProjectMenuRight';
import SubmenuProject from './SubmenuProject/SubmenuProject';

interface CreateProjectProps {
  project: ProjectData;
  documentation: DocumentationItem[];
  disabled?: boolean;
  buttons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  isNouscims: boolean;
  defaultActors: IdNamePair[];
  filteredActors: IdNamePair[];
  loading: boolean;
  createProject: (data: ProjectData) => void;
  editProject: (data: ProjectData, idProject: number) => void;
  deleteDocument: (id: number, file_id: number) => void;
  downloadDocument: (id: number, name: string, file_id: number) => void;
  getDocument: (id: number, name: string, file_id: number) => void;
  addDocument: (document: File, description: string, genericId: number) => void;
  sendActor: (id_project: number, user_id: number) => void;
  deleteActor: (id_project: number, user_id: number) => void;
  setData: (key: string, value: string | number) => void;
}

const CreateProject: React.FC<CreateProjectProps> = ({
  project,
  buttons,
  documentation,
  disabled,
  defaultActors,
  filteredActors,
  isNouscims,
  moreInfoButtons,
  loading,
  sendActor,
  deleteActor,
  createProject,
  addDocument,
  deleteDocument,
  downloadDocument,
  getDocument,
  editProject,
  setData,
}) => {
  const [selectState, setSelectState] = useState(-1);
  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: tProjectKey('Motivo del rechazo.'),
  });
  const [followUpNotesModal, setFollowUpNotesModal] = React.useState({
    view: false,
    children: <span />,
    title: tProjectKey('Notas de Seguimiento'),
  });
  const { errors, handleSubmit, control } = useForm<ProjectData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ProjectBaseSchema,
  });

  const handleSave = () => {
    editProject(project, project.id);
  };

  const handleCreate = (data: ProjectData) => createProject(data);

  useHookMethod(buttons, [KeyButton.SAVE, KeyButton.CREATE], [handleSubmit(handleSave), handleSubmit(handleCreate)]);

  interface AssignToProject {
    allowed_user_name: string;
    allowed_user_id: number;
  }

  const tableData: MaterialTableProps<AssignToProject> = {
    title: tProjectKey('Equipo del proyecto'),
    columns: [
      {
        title: tProjectKey('Nombre'),
        field: 'allowed_user_name',
        //@ts-ignore
        // width: '100px',
      },
    ],
    data: project.allowed_users,
    options: {
      tableLayout: 'auto',
      exportButton: false,
      selection: false,
      paging: false,
      search: false,
    },
  };

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const onChangeSelect = (event: any) => {
    setSelectState(event.target.value);
  };

  const handleClickAdd = () => {
    sendActor(project.id, selectState);
    setSelectState(-1);
  };

  let documentationView;
  let nouscimsView;
  const modals = (
    <>
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        bodyModal=" "
      >
        {createdModal.children}
      </TransitionModal>
      <TransitionModal
        view={followUpNotesModal.view}
        handleClose={() => {
          setFollowUpNotesModal(prevState => ({ ...prevState, view: false }));
        }}
        title={followUpNotesModal.title}
        bodyModal=" "
        followUpMode
      >
        {followUpNotesModal.children}
      </TransitionModal>
    </>
  );

  if (!!documentation?.length) {
    const actions = ['checked', 'download', 'view', 'remove'];
    documentationView = (
      <FncAddDocuments
        documents={documentation.filter(document => document.type !== 'CON')}
        genericId={project.id}
        actions={actions}
        title={tProjectKey('Documentos')}
        addDoc={addDocument}
        getFile={getDocument}
        download={downloadDocument}
        remove={deleteDocument}
        disabled={disabled && !!project.id}
        addRow={!disabled}
      />
    );
  }

  if (project.id && isNouscims) {
    nouscimsView = (
      <>
        <FormContainer title={tProjectKey('Participantes')}>
          <SelectController
            name={'owner_id'}
            defaultValue={project && project.owner_id} //TODO project.owner_name
            control={control}
            errors={errors}
            size="50"
            disabled={disabled && !!project.id}
            schema={ProjectBaseSchema}
            label={tProjectKey('Responsable del proyecto (Nous Cims)')}
            onClick={e => handleSelectChange(e.target)}
          >
            {defaultActors.map((element: any) => (
              <MenuItem key={element.id} value={element.id} data-name={element.name}>
                {element.name}
              </MenuItem>
            ))}
          </SelectController>
          <SelectController
            size="50"
            control={control}
            errors={errors}
            disabled={disabled && !!project.id}
            defaultValue={project.entity_owner_id}
            schema={ProjectBaseSchema}
            name={'entity_owner_id'}
            label={tProjectKey('Responsable del proyecto (Entidad Social)')}
            onClick={e => handleSelectChange(e.target)}
          >
            {project.responsibles_projects?.map((element: IdNamePair, index: number) => (
              <MenuItem key={index} value={element.id}>
                {element.name}
              </MenuItem>
            ))}
          </SelectController>
          <div className={styles.containerActors}>
            <FormControl
              variant="outlined"
              style={{
                width: '100%',
              }}
            >
              <InputLabel id={'project_ppl'}>{tProjectKey('Miembros del equipo')}</InputLabel>
              <Select
                label={tProjectKey('Miembros del equipo')}
                name={'project_ppl'}
                defaultValue=""
                onChange={(e: any) => {
                  onChangeSelect(e);
                }}
                disabled={disabled}
              >
                {filteredActors.map((element: any) => (
                  <MenuItem key={element.id} value={element.id} data-name={element.name}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              color="primary"
              style={{ maxHeight: '50px', marginLeft: '10px', minWidth: '100px' }}
              onClick={handleClickAdd}
              disabled={disabled}
            >
              {tProjectKey('AÑADIR')}
            </Button>
          </div>
          <TableWithEdit
            customContainer
            tableData={tableData}
            key={JSON.stringify(tableData.data)}
            {...(!disabled
              ? {
                  deleteFunc: (rowData: any) => deleteActor(project.id, rowData.allowed_user_id),
                }
              : {})}
          />
        </FormContainer>
      </>
    );
  }

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuProject
          project={project}
          current_section="datos_basicos"
          buttons={buttons}
          onClickOption={handleSubmit(handleSave)}
        />
      }
      rightSubmenu={<ProjectMenuRight actionButtons={buttons} moreInfoButtons={moreInfoButtons} project={project} />}
    >
      <Fragment>
        <Title>{tProjectKey('Requerimentos Proyecto')}</Title>
        {loading || !project.id ? (
          <Loading big />
        ) : (
          <>
            <FormContainer title={tProjectKey('Datos generales')}>
              <TextInputController
                control={control}
                errors={errors}
                defaultValue={project.name}
                schema={ProjectBaseSchema}
                label={tProjectKey('Nombre del proyecto')}
                name="name"
                size="50"
                disabled={disabled && !!project.id}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('name', e.target.value)}
              />
              <TextInputController
                control={control}
                errors={errors}
                schema={ProjectBaseSchema}
                defaultValue={`${project.subarea} - ${project.area}`}
                label={tProjectKey('Área')}
                name="subarea"
                size="50"
                disabled
              />
              <TextInputController
                control={control}
                errors={errors}
                schema={ProjectBaseSchema}
                defaultValue={project.entity_name}
                label={tProjectKey('Entidad')}
                name="entity_name"
                size="50"
                disabled
              />
              <TextInputController
                control={control}
                errors={errors}
                defaultValue={project.description}
                schema={ProjectBaseSchema}
                label={tProjectKey('Descripción del proyecto')}
                name="description"
                size="50"
                multiline
                rows="1"
                rowsMax="2"
                disabled={disabled && !!project.id}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('description', e.target.value)}
              />
              <TextInputController
                control={control}
                errors={errors}
                defaultValue={project.vulnerability_situation}
                schema={ProjectBaseSchema}
                label={tProjectKey('Descripción de situación')}
                name="vulnerability_situation"
                size="50"
                multiline
                rows="1"
                rowsMax="2"
                disabled={disabled && !!project.id}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('vulnerability_situation', e.target.value)}
              />
              <TextInputController
                control={control}
                errors={errors}
                defaultValue={project.structural_reasons}
                schema={ProjectBaseSchema}
                label={tProjectKey('Razones estructurales')}
                name="structural_reasons"
                size="50"
                multiline
                rows="1"
                rowsMax="2"
                disabled={disabled && !!project.id}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('structural_reasons', e.target.value)}
              />
              <TextInputController
                control={control}
                errors={errors}
                defaultValue={project.place}
                schema={ProjectBaseSchema}
                label={tProjectKey('Tipo de población')}
                name="place"
                size="50"
                multiline
                rows="1"
                rowsMax="2"
                disabled={disabled && !!project.id}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('place', e.target.value)}
              />
              <TextInputController
                control={control}
                errors={errors}
                defaultValue={project.roles_map}
                schema={ProjectBaseSchema}
                label={tProjectKey('Actores')}
                name="roles_map"
                size="50"
                multiline
                rows="1"
                rowsMax="2"
                disabled={disabled && !!project.id}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('roles_map', e.target.value)}
              />
            </FormContainer>
            {nouscimsView}
            {documentationView}
            {modals}
          </>
        )}
      </Fragment>
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectLoading(state),
  documentation: selectDocumentation(state),
  user_id: selectUserInfo(state).user_id,
  defaultActors: selectActorsDefault(state),
  filteredActors: selectActorsFiltered(state),
  isNouscims: selectIsNousCims(state),
  project: selectProjectData(state),
});

const mapDispatchToProps = (dispatch: ProjectDispatch | any) => ({
  setData: (key: string, value: string | number) => dispatch(projectSetData(key, value)),
  createProject: (data: ProjectData) => dispatch(createProject(data)),
  editProject: (data: ProjectData, idProject: number) => dispatch(editProject(data, idProject)),
  addDocument: (document: File, description: string, projectId: number): void =>
    dispatch(addDocument(document, description, projectId)),
  downloadDocument: (id: number, name: string, file_id: number): void => dispatch(downloadDocument(id, name, file_id)),
  getDocument: (id: number, name: string, file_id: number): void => dispatch(getDocument(id, name, file_id)),
  deleteDocument: (id: number, file_id: number): void => dispatch(deleteDocument(id, file_id)),
  sendActor: (id_project: number, user_id: number) => dispatch(sendActor(id_project, user_id)),
  deleteActor: (id_project: number, user_id: number) => dispatch(deleteActor(id_project, user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject);
