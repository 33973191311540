import React, { Fragment, useState } from 'react';
import { tKey } from '../../helpers/translate';
import defaultImage from '../../images/default-avatarNew.svg';
import cameraIcon from '../../images/icons/iconCamera.svg';
import CropImage from '../CropImage/CropImage';
import TransitionModal from '../Modal/TransitionModal';
import SwipeableTabs from '../Tabs/SwipeableTabs';
import styles from './menuImageRound.module.scss';

interface ImageProps {
  imageLink: string | null;
  loading?: boolean;
  isOpenAvatar?: boolean;
  isMobile?: boolean;
  setImage?: (image: string) => void;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuImageRound: React.FC<ImageProps> = ({ imageLink, loading, setImage, isOpenAvatar, setIsOpen, isMobile }) => {
  const [imageModalView, setImageModalView] = useState(false);

  const onClickImage = () => {
    if (setImage) {
      if (setIsOpen) setIsOpen(prevState => !prevState);
      setImageModalView(!imageModalView);
    }
  };

  const onSetImage = (image: string) => {
    if (setImage) {
      setImage(image);
    }
  };

  return (
    <div className={styles.container}>
      {!loading && (
        <>
          <Fragment>
            {!isOpenAvatar && setImage && (
              <Fragment>
                <div className={styles.avatarImage}>
                  <img
                    className={styles.avatarImage_image}
                    onClick={() => {
                      onClickImage();
                    }}
                    alt="Avatar"
                    src={imageLink || defaultImage}
                  />
                  <div
                    className={styles.medioCirculo}
                    onClick={() => {
                      onClickImage();
                    }}
                  >
                    <img className={styles.buttonImg} src={cameraIcon} alt="Camera icon" />
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        </>
      )}

      {/* Si es Desktop y el useState  imageModalView esta en true enseña modal */}
      {!isMobile && imageModalView && (
        <TransitionModal view={imageModalView} handleClose={onClickImage}>
          <SwipeableTabs
            handleClose={onClickImage}
            imageMode
            tabOne={<CropImage avatar={imageLink} deleteMode />}
            tabOneTitle={tKey('Imagen Actual')}
            tabTwoTitle={{ title: tKey('Cambiar Imagen') }}
            tabTwo={
              <CropImage
                handleChange={onSetImage}
                avatar={imageLink}
                onClickImage={onClickImage}
                title={null}
                handleClose={onClickImage}
              />
            }
          ></SwipeableTabs>
        </TransitionModal>
      )}

      {/* Si es Mobile y el useState isOpenAvatar es true enseña en layout central */}
      {isMobile && isOpenAvatar && (
        <>
          <div className={styles.imageUserTitle}>Imagen de usuario</div>
          <CropImage
            handleChange={onSetImage}
            avatar={imageLink}
            onClickImage={onClickImage}
            title={null}
            handleClose={onClickImage}
            isMobile={isMobile}
          />
        </>
      )}
    </div>
  );
};

export default MenuImageRound;
