import { initialState, UpdateParticipantDataAction, UpdateParticipantState } from './definitions';
import { UpdateParticipantConst } from './action_types';
import assertUnreachable from '../../../../helpers/assertUnreachable';

const updateParticipantReducer = (
  state = initialState,
  action: UpdateParticipantDataAction,
): UpdateParticipantState => {
  switch (action.type) {
    case UpdateParticipantConst.UPDATE_PARTICIPANT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UpdateParticipantConst.UPDATE_PARTICIPANT_OK: {
      const { updatedParticipant } = action;

      const participants = state.data.map(participant => {
        if (
          participant.internal_project_id === updatedParticipant.internal_project_id &&
          participant.id === updatedParticipant.id
        ) {
          participant.user_name = updatedParticipant.user_name;
          participant.lopd = updatedParticipant.lopd;
          participant.particularities = updatedParticipant.particularities;
          participant.additional_info = updatedParticipant.additional_info;
        }
        return { ...participant };
      });
      return {
        ...state,
        loading: false,
        data: participants,
      };
    }
    case UpdateParticipantConst.UPDATE_PARTICIPANT_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default updateParticipantReducer;
