import moment from 'moment';
import * as DateConstants from '../../constants/date';
import assertUnreachable from '../../helpers/assertUnreachable';
import { getDocs } from '../../helpers/getDocs';
import { DocumentationItem } from '../common/definitions';
import { UserDataConsts } from './action_types';
import { initialState, UserData, UserDataAction, UserPermission, UserRole } from './definitions';

export type UserDataState = typeof initialState;

const userReducer = (state = initialState, action: UserDataAction): UserDataState => {
  switch (action.type) {
    case UserDataConsts.CHANGE_PASSWORD:
    case UserDataConsts.UPDATE_USER_PROFILE:
    case UserDataConsts.SIGN_LOPD_ACTION:
    case UserDataConsts.UPDATE_PROFILE_USER_TABLE:
    case UserDataConsts.GET_USER_DOCUMENTATION:
    case UserDataConsts.UPLOAD_USER_DOCUMENT:
    case UserDataConsts.UPLOAD_EDUCATOR_DOCUMENT:
    case UserDataConsts.REMOVE_USER_DOCUMENT:
    case UserDataConsts.REMOVE_EDUCATOR_DOCUMENT:
    case UserDataConsts.DOWNLOAD_USER_DOCUMENT:
    case UserDataConsts.GET_USER_DOCUMENT:
    case UserDataConsts.USER_ENTITIES_METADATA:
    case UserDataConsts.UPDATE_PROFILE_EDUCATOR:
    case UserDataConsts.SET_FOREING_AVATAR:
    case UserDataConsts.USER_LINK_ACADEMY:
    case UserDataConsts.USER_LINK_ACADEMY_OK:
    case UserDataConsts.USER_ADD_ROLE:
    case UserDataConsts.USER_REMOVE_ROLE:
    case UserDataConsts.SET_USER_PERMISSION:
    case UserDataConsts.USER_CHANGE_LANGUAGE:
      return { ...state };
    case UserDataConsts.GET_USERS:
    case UserDataConsts.DISABLE_USER:
    case UserDataConsts.CONFIRM_USER_ACTIVE_ROLE:
      return { ...state, loading: true, loadingRolePermissions: true };
    case UserDataConsts.GET_USER_ROLES_CONFIG:
      return { ...state, loading: true };
    case UserDataConsts.USER_ROLE_ACTIVE:
      return { ...state, loadingRolePermissions: true };
    case UserDataConsts.GET_USER_DOCUMENTATION_OK:
      const { documentation } = action;
      return {
        ...state,
        documentation,
      };
    case UserDataConsts.GET_USER_DOCUMENTATION_KO:
    case UserDataConsts.USER_ENTITIES_METADATA_KO:
    case UserDataConsts.USER_LINK_ACADEMY_KO:
      return { ...state };
    case UserDataConsts.USER_ENTITIES_METADATA_OK:
      const { entitiesMetadata } = action;
      return {
        ...state,
        entitiesMetadata,
      };
    case UserDataConsts.USER_PROFILE:
    case UserDataConsts.GET_USER_DATA:
      return {
        ...state,
        loading: true,
        validationErrors: null,
      };
    case UserDataConsts.USER_CHANGE_LANGUAGE_OK:
      return {
        ...state,
        profileData: {
          ...state.profileData,
          language: action.language,
        },
      };
    case UserDataConsts.UPDATE_PROFILE_EDUCATOR_OK:
      return {
        ...state,
        loading: false,
        profileData: action.data,
      };
    case UserDataConsts.GET_USER_DOCUMENT_OK:
      const { dataDoc } = action;
      getDocs(dataDoc, false, action.name);
      return {
        ...state,
        error: null,
      };
    case UserDataConsts.UPLOAD_USER_DOCUMENT_OK:
    case UserDataConsts.REMOVE_USER_DOCUMENT_OK:
      const { documents } = action;
      return {
        ...state,
        documentation: documents,
      };
    case UserDataConsts.REMOVE_EDUCATOR_DOCUMENT_OK:
      const { file_id } = action;
      // @ts-ignore
      const documentationAfterDelete: DocumentationItem[] = state.documentation.map((e: DocumentationItem) => {
        if (e.file_id === file_id && e.code !== 'multi') {
          return {
            ...e,
            file_id: null,
            update: null,
            filename: null,
            name: e.name,
          };
        }
        return e;
      });

      const documentationAfterDeleteMulti: DocumentationItem[] = documentationAfterDelete.filter(
        (s: DocumentationItem) => s.file_id !== file_id,
      );

      return {
        ...state,
        documentation: documentationAfterDeleteMulti,
      };
    case UserDataConsts.DOWNLOAD_USER_DOCUMENT_OK:
      const { data, name } = action;
      getDocs(data, true, name);
      return {
        ...state,
        error: null,
      };
    case UserDataConsts.USER_PROFILE_OK:
      return {
        ...state,
        profileData: action.profileData,
        loading: false,
      };
    case UserDataConsts.GET_USER_DATA_OK:
      const { profileData } = action;
      return {
        ...state,
        profileData,
        loading: false,
      };
    case UserDataConsts.SET_FOREING_AVATAR_OK:
      const { userAvatar } = action;
      return {
        ...state,
        userAvatar,
      };
    case UserDataConsts.SET_FOREING_AVATAR_KO:
      return {
        ...state,
      };
    case UserDataConsts.SIGN_LOPD_ACTION_OK:
    case UserDataConsts.UPDATE_USER_PROFILE_OK:
      return {
        ...state,
        profileData: action.profileData,
      };
    case UserDataConsts.DISABLE_USER_OK:
      return {
        ...state,
        loading: false,
      };
    case UserDataConsts.USER_SET_PAGESIZE_TABLE_OPTIONS:
      return {
        ...state,
        pageSize: action.pageSize,
      };
    case UserDataConsts.USER_SET_SEARCHTEXT_TABLE_OPTIONS:
      return {
        ...state,
        searchText: action.searchText,
      };
    case UserDataConsts.USER_SET_COLUMNORDER_TABLE_OPTIONS:
      return {
        ...state,
        columnOrder: action.columnOrder,
      };
    case UserDataConsts.UPDATE_PROFILE_USER_TABLE_OK:
      return {
        ...state,
        loading: false,
        profileData: action.profileData,
      };
    case UserDataConsts.CREATE_USER:
    case UserDataConsts.CREATE_USER_EDUCATOR:
      return {
        ...state,
        loading: true,
      };
    case UserDataConsts.CREATE_USER_OK:
    case UserDataConsts.CREATE_USER_EDUCATOR_OK:
      return {
        ...state,
        loading: false,
        userCreated: true,
      };
    case UserDataConsts.USER_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        validationErrors: action.validationErrors,
      };
    case UserDataConsts.INIT_NEW_USER:
      return {
        ...state,
        loading: true,
      };
    case UserDataConsts.INIT_NEW_USER_OK:
      const newUserData = initialState.profileData;

      newUserData.id = 0;
      newUserData.name = null;
      newUserData.surname = null;
      newUserData.surname2 = null;

      // @ts-ignore
      newUserData.email = null;
      // @ts-ignore
      newUserData.avatar_file_id = null;
      newUserData.birthdate = null;
      // @ts-ignore
      newUserData.document_number = null;
      newUserData.phone = null;
      newUserData.address = null;
      newUserData.flat = null;
      newUserData.floor = null;
      newUserData.room = null;
      newUserData.staircase = null;
      newUserData.zip_code = null;
      newUserData.city = null;
      newUserData.province = null;
      newUserData.country = null;
      newUserData.account_number = null;
      newUserData.phone2 = null;
      newUserData.email2 = null;
      newUserData.facebook = null;
      newUserData.twitter = null;
      newUserData.linkedin = null;
      newUserData.instagram = null;
      newUserData.youtube = null;
      newUserData.other_social_networks = null;
      newUserData.country_origin = null;
      newUserData.nationality = null;
      newUserData.whats_app = null;
      newUserData.meet_link = null;
      newUserData.documents = [];
      newUserData.document_type = 'DNI';
      newUserData.address_type = 'Calle';
      newUserData.gender = 'No contesta';

      return {
        ...state,
        loading: false,
        profileData: {} as UserData,
        documentation: action.documentation,
        userCreated: false,
      };
    case UserDataConsts.ACCEPT_USER_EDUCATOR:
      return {
        ...state,
        loading: true,
      };
    case UserDataConsts.ACCEPT_USER_EDUCATOR_OK:
      return {
        ...state,
        loading: false,
        responseValidation: action.responseValidation,
      };
    case UserDataConsts.ACCEPT_USER_EDUCATOR_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserDataConsts.REJECT_USER_EDUCATOR:
      return {
        ...state,
        loading: true,
      };
    case UserDataConsts.REJECT_USER_EDUCATOR_OK:
      return {
        ...state,
        loading: false,
        responseValidation: action.responseValidation,
      };
    case UserDataConsts.REJECT_USER_EDUCATOR_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserDataConsts.USER_SET_DATA:
      const { key, value } = action;

      return {
        ...state,
        profileData: {
          ...state.profileData,
          [key]: value,
        },
      };
    case UserDataConsts.UPLOAD_EDUCATOR_DOCUMENT_OK:
      const { documentation_id, document } = action;
      let doc_to_add = null;
      let doc_index = 0;
      const newDocumentation: DocumentationItem[] = state.documentation.map((e: DocumentationItem, index) => {
        if (e.documentation_id === documentation_id) {
          if (!e.file_id) {
            return {
              ...e,
              file_id: document.id,
              update: moment().format(DateConstants['DATE_HOUR_FORMAT']),
              filename: document.original_name,
              name: e.name,
            };
          } else {
            doc_index = index + 1;
            doc_to_add = {
              id: document.id,
              code: 'multi',
              documentation_id: documentation_id,
              file_id: document.id,
              update: moment().format(DateConstants['DATE_HOUR_FORMAT']),
              filename: document.original_name,
              name: e.name,
            } as DocumentationItem;
          }
        }
        return e;
      });

      if (doc_to_add) {
        newDocumentation.splice(doc_index, 0, doc_to_add);
      }
      return {
        ...state,
        documentation: newDocumentation,
      };
    case UserDataConsts.GET_FOREING_AVATAR:
      return {
        ...state,
        userAvatar: '',
      };
    case UserDataConsts.GET_FOREING_AVATAR_OK:
      return {
        ...state,
        userAvatar: action.userAvatar,
      };
    case UserDataConsts.GET_FOREING_AVATAR_KO:
      return { ...state };
    case UserDataConsts.USER_SET_USER_CREATED:
      const { userCreated } = action;
      return {
        ...state,
        userCreated,
      };
    case UserDataConsts.GET_USERS_OK:
      return {
        ...state,
        loading: false,
        usersList: action.usersList,
      };
    case UserDataConsts.CONFIRM_USER_ACTIVE_ROLE_OK:
      return {
        ...state,
        loading: false,
      };
    case UserDataConsts.GET_USER_ROLES_CONFIG_OK:
      const { userRolesConfig } = action;
      return {
        ...state,
        loading: false,
        loadingRolePermissions: false,
        userRolesConfig,
      };
    case UserDataConsts.USER_REMOVE_ROLE_OK:
      const { role_id } = action;
      const updateRoleList = state.userRolesConfig.user_roles.filter(e => e.id !== role_id);
      return {
        ...state,
        userRolesConfig: {
          ...state.userRolesConfig,
          user_roles: updateRoleList,
        },
      };
    case UserDataConsts.USER_ADD_ROLE_OK:
      const { role } = action;
      const newRole = state.userRolesConfig.user_roles.filter(e => e.id === role.id);
      if (newRole.length > 0) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          userRolesConfig: {
            ...state.userRolesConfig,
            user_roles: [...state.userRolesConfig.user_roles, role],
          },
        };
      }
    case UserDataConsts.USER_ROLE_ACTIVE_OK:
      const { is_active, rolePermissions } = action;
      const user_roles: UserRole[] = state.userRolesConfig.user_roles.map(e => {
        if (is_active) {
          e.is_active = false;
        }
        if (e.id === action.role_id && is_active) {
          e.is_active = is_active;
        } else if (e.id === action.role_id && !is_active) {
          e.is_active = true;
        }
        return e;
      });
      return {
        ...state,
        loadingRolePermissions: false,
        userRolesConfig: {
          ...state.userRolesConfig,
          user_roles,
          active_role_permissions: rolePermissions,
        },
      };
    case UserDataConsts.SET_USER_PERMISSION_OK:
      const { permission_id } = action;

      const user_permissions: UserPermission[] = state.userRolesConfig.user_permissions.map(e => {
        if (e.id === permission_id) {
          e.selected = action.value;
        }
        return e;
      });

      return {
        ...state,
        loadingRolePermissions: false,
        userRolesConfig: {
          ...state.userRolesConfig,
          user_permissions,
        },
      };
    case UserDataConsts.USER_GENERIC_KO:
    case UserDataConsts.GET_USERS_KO:
      return { ...state, error: action.error, loading: false };
  }

  assertUnreachable(action);
  return state;
};

export default userReducer;
