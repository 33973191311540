import { Participant } from '../Participant';
import { UnSubscribeParticipantConst } from './action_types';
import { initialState, UnSubscribeParticipantState, UnSubscribeParticipantActions } from './definition.d';

const unSubscribeParticipantReducer = (
  state = initialState,
  action: UnSubscribeParticipantActions,
): UnSubscribeParticipantState => {
  switch (action.type) {
    case UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT_OK: {
      const { internal_project_id, participantId, unsubscribed_at } = action;
      const participants = state.data.map((participant: Participant) => {
        if (participant.internal_project_id === internal_project_id && participant.id === participantId) {
          participant.unsubscribed_at = unsubscribed_at;
        }
        return participant;
      });
      return {
        ...state,
        loading: false,
        data: participants,
        error: null,
      };
    }
    case UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT_KO: {
      const { error } = action;

      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};

export default unSubscribeParticipantReducer;
