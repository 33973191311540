import React from 'react';
import { tFormKey } from '../../helpers/translate';
import webStyle from '../../helpers/webStyle';
import FncAccordion from '../Accordion/FncAccordion';
import MessageInfo from '../MessageInfo/MessageInfo';
import styles from './formContainer.module.scss';

interface FormContainerProps {
  children?: any;
  title: string;
  smallTitle?: string;
  space?: boolean;
  subtitle?: string;
  className?: string;
  lowMargin?: boolean;
}

const FormContainer: React.FC<FormContainerProps> = ({
  lowMargin,
  children,
  title,
  space,
  subtitle,
  className,
  smallTitle,
}) => {
  return (
    <div className={`${styles.root}`}>
      <FncAccordion disabledDesktop title={tFormKey(title)}>
        <div
          className={`${styles.content} ${title === '' ? styles.noTitle : ''} ${
            space ? styles.container_input_space : className || styles.container_input
          }`}
        >
          {smallTitle && <div className={styles[`smallTitle_${webStyle}`]}>{tFormKey(smallTitle)}</div>}
          {subtitle && <MessageInfo>{tFormKey(subtitle)}</MessageInfo>}
          {children ? children : null}
        </div>
      </FncAccordion>
    </div>
  );
};

export default FormContainer;
