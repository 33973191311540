import { Material } from '../../redux/scholarship/definitions';
import { apiBaseUrl, apiCall, apiCallNoResponse } from '../api';

const baseURL = apiBaseUrl;

export const addScholarshipMaterial = async (material: Material): Promise<Material> => {
  const { name, amount, file_id, filename } = material;
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ name, amount, file_id, filename }),
  };

  const { data } = await apiCall<{
    data: Material;
  }>(`${baseURL}/scholarships/${material.scholarship_id}/materials`, init);
  return data;
};

export const removeScholarshipMaterial = async (scholarship_id: number, material_id: number): Promise<void> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  await apiCallNoResponse(`${baseURL}/scholarships/${scholarship_id}/materials/${material_id}`, init);
};

export const updateScholarshipMaterial = async (material: Material): Promise<void> => {
  const { name, amount, file_id, filename } = material;
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ name, amount, file_id, filename }),
  };
  await apiCallNoResponse(`${baseURL}/scholarships/${material.scholarship_id}/materials/${material.id}`, init);
};
