import moment from 'moment';
import XLSX from 'xlsx';

const DATE_HOUR_FORMAT_FILE = 'DD/MM/YYYY_HH:mm';

const isNumber = (value: any) => !isNaN(value) && !isNaN(parseFloat(value)) + value;

export const exportToExcel = (columns: any, data: any, title?: string) => {
  // determining header labels
  const columnInfo = columns.reduce(
    (acc: any, column: any) => {
      const headerLabel = column.title || column.name;
      acc.header.push(headerLabel);
      acc.map[column.name] = headerLabel;
      acc.type[column.name] = column.type;
      if (column?.modal) {
        column.modal.forEach((ele: any) => {
          acc.header.push(ele.title);
          acc.map[ele.name] = ele.title;
          acc.type[ele.name] = ele.type;
        });
      }
      return acc;
    },
    { map: {}, header: [], type: {} },
  );

  const mappedData = data.map((row: any) =>
    Object.entries(row).reduce((acc: any, [key, value]: any) => {
      if (columnInfo.map[key]) {
        if (columnInfo.type[key] === 'date') {
          acc[columnInfo.map[key]] = value ? moment.utc(value, 'X').format('DD/MM/YYYY') : '';
        } else {
          acc[columnInfo.map[key]] = isNumber(value) ? +value : value;
        }
      }
      return acc;
    }, {}),
  );

  // https://github.com/SheetJS/sheetjs/issues/817
  const ws = XLSX.utils.json_to_sheet(mappedData, {
    header: columnInfo.header, // garanties the column order same as in material-table
  });

  /* add to workbook */
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* generate an XLSX file and triggers the browser to generate the download*/

  XLSX.writeFile(wb, `${title ? title : 'reporte'}_${moment().format(DATE_HOUR_FORMAT_FILE)}.xlsx`);
};
