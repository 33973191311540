import { APIValidationError } from '../../api/api';
import { DocumentationItem } from '../common/definitions';
import { ScholarshipDataConsts } from './action_types';
import {
  CancelScholarshipAgreementAction,
  CancelScholarshipAgreementKoAction,
  CancelScholarshipAgreementOkAction,
  CenterCost,
  CleanScholarshipDataAction,
  EntitiesScholarships,
  EntityScholarshipPayments,
  ExportScholarshipReportsAction,
  ExportScholarshipReportsKoAction,
  ExportScholarshipReportsOkAction,
  GetConfigurationFollowupAction,
  GetConfigurationFollowupKoAction,
  GetConfigurationFollowupOkAction,
  GetDocumentScholarshipAction,
  GetDocumentScholarshipKoAction,
  GetDocumentScholarshipOkAction,
  GetEntitiesWithScholarshipsPaymentAction,
  GetEntitiesWithScholarshipsPaymentOkAction,
  GetEntitiesWithScholarshipsPaymentsKoAction,
  GetEntitiesWithScholarshipsToPayAction,
  GetEntitiesWithScholarshipsToPayKoAction,
  GetEntitiesWithScholarshipsToPayOkAction,
  GetPaymentAdjustmentAction,
  GetPaymentAdjustmentDocumentsAction,
  GetPaymentAdjustmentDocumentsKoAction,
  GetPaymentAdjustmentDocumentsOkAction,
  GetPaymentAdjustmentKoAction,
  GetPaymentAdjustmentOkAction,
  GetScholarshipAgreementAction,
  GetScholarshipAgreementKoAction,
  GetScholarshipAgreementOkAction,
  GetScholarshipsToPayEntityAction,
  GetScholarshipsToPayEntityKoAction,
  GetScholarshipsToPayEntityOkAction,
  GetScholarshipsToPayInstituteAction,
  GetScholarshipsToPayInstituteKoAction,
  GetScholarshipsToPayInstituteOkAction,
  Material,
  PaymentAdjustmentsData,
  PaymentDatesReplace,
  PaymentDatesReplaceResult,
  RemoveDocumentScholarshipAction,
  RemoveDocumentScholarshipKoAction,
  RemoveDocumentScholarshipOkAction,
  ReplaceScholarshipPaymentDatesAction,
  ReplaceScholarshipPaymentDatesKoAction,
  ReplaceScholarshipPaymentDatesOkAction,
  RunConfigurationFollowupAction,
  RunConfigurationFollowupKoAction,
  RunConfigurationFollowupOkAction,
  SaveAgreementAction,
  SaveAgreementKoAction,
  SaveAgreementOkAction,
  SaveFollowUpAction,
  ScholarshipAcademiesMetadataAction,
  ScholarshipAcademiesMetadataKoAction,
  ScholarshipAcademiesMetadataOkAction,
  ScholarshipAcademy,
  ScholarshipAddMaterialAction,
  ScholarshipAddMaterialOkAction,
  ScholarshipAgreementData,
  ScholarshipAgreementSetDataAction,
  ScholarshipApiPayment,
  ScholarshipData,
  ScholarshipDownloadDocumentAction,
  ScholarshipDownloadDocumentKoAction,
  ScholarshipDownloadDocumentOkAction,
  ScholarshipEntityPaymentApi,
  ScholarshipFollow,
  ScholarshipGetAction,
  ScholarshipGetDocumentationAction,
  ScholarshipGetDocumentationKoAction,
  ScholarshipGetDocumentationOkAction,
  ScholarshipGetKoAction,
  ScholarshipGetMetadataAction,
  ScholarshipGetMetadataKoAction,
  ScholarshipGetMetadataOkAction,
  ScholarshipGetOkAction,
  ScholarshipMaterialKoAction,
  ScholarshipMetadata,
  ScholarshipPaymentAdjustmentSetDataAction,
  ScholarshipProviderData,
  ScholarshipRemoveMaterialAction,
  ScholarshipRemoveMaterialOkAction,
  ScholarshipSetApprovalInfoDataAction,
  ScholarshipSetAssessmentInfoDataAction,
  ScholarshipSetCostInfoDataAction,
  ScholarshipSetDataAction,
  ScholarshipSetDataFollowAction,
  ScholarshipSetEconomicalInfoDataAction,
  ScholarshipSetEducationalInfoDataAction,
  ScholarshipSetInterviewInfoDataAction,
  ScholarshipSetMotivationalInfoDataAction,
  ScholarshipSetPersonalInfoDataAction,
  ScholarshipSetSituationInfoDataAction,
  ScholarshipSubmitInformationAction,
  ScholarshipSubmitInformationKoAction,
  ScholarshipSubmitInformationOkAction,
  ScholarshipUpdateMaterialAction,
  ScholarshipUpdateMaterialOkAction,
  ScholarshipValidationErrorsAction,
  SendConfigurationFollowupAction,
  SendConfigurationFollowupKoAction,
  SendConfigurationFollowupOkAction,
  SendPaymentAdjustmentAction,
  SendPaymentAdjustmentKoAction,
  SendPaymentAdjustmentOkAction,
  SendProviderInfoAction,
  SendProviderInfoKoAction,
  SendProviderInfoOkAction,
  SetEntityPaymentDateFilterAction,
  SetPaymentsAcademicYear,
  SetScholarshipStepAction,
  SwitchBlockPaymentAction,
  SwitchBlockPaymentKoAction,
  SwitchBlockPaymentOkAction,
  UpdateAgreementAction,
  UpdateAgreementKoAction,
  UpdateAgreementOkAction,
  UploadDocumentAction,
  UploadDocumentKoAction,
  UploadDocumentOkAction,
  UploadDocumentTrackingAction,
} from './definitions';

export function scholarshipGet(id: number): ScholarshipGetAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET,
    id,
  };
}

export function scholarshipGetOk(
  scholarshipData: ScholarshipData,
  metadata: ScholarshipMetadata | null,
): ScholarshipGetOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_OK,
    scholarshipData,
    metadata,
  };
}

export function scholarshipGetKo(error: string): ScholarshipGetKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_KO,
    error,
  };
}

export function scholarshipValidationErrors(validationErrors: APIValidationError): ScholarshipValidationErrorsAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_VALIDATION_ERRORS,
    validationErrors,
  };
}

export function scholarshipGetMetadata(): ScholarshipGetMetadataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA,
  };
}

export function scholarshipGetMetadataOk(data: ScholarshipMetadata): ScholarshipGetMetadataOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_OK,
    scholarshipMetadata: data,
  };
}

export function scholarshipGetMetadataKo(error: string): ScholarshipGetMetadataKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_METADATA_KO,
    error,
  };
}

export function scholarshipSubmitInformation(data: ScholarshipData): ScholarshipSubmitInformationAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION,
    data,
  };
}

export function scholarshipSubmitInformationOk(scholarshipData: ScholarshipData): ScholarshipSubmitInformationOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_OK,
    scholarshipData,
  };
}

export function scholarshipSubmitInformationKo(error: string): ScholarshipSubmitInformationKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SUBMIT_INFORMATION_KO,
    error,
  };
}

export function uploadDocument(
  documentation_id: number,
  data: File,
  scholarshipId: number,
  multiDocument?: boolean,
): UploadDocumentAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT,
    documentation_id,
    data,
    scholarshipId,
    multiDocument,
  };
}

export function uploadDocumentOk({ documents }: { documents: DocumentationItem[] }): UploadDocumentOkAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_OK,
    documents,
  };
}

export function uploadDocumentKo(error: string): UploadDocumentKoAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_KO,
    error,
  };
}

export function getScholarshipDocumentation(id: number): ScholarshipGetDocumentationAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION,
    id,
  };
}

export function getScholarshipDocumentationOk(documentation: DocumentationItem[]): ScholarshipGetDocumentationOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_OK,
    documentation,
  };
}

export function getScholarshipDocumentationKo(error: string): ScholarshipGetDocumentationKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_GET_DOCUMENTATION_KO,
    error,
  };
}

export function downloadDocument(
  file_id: number,
  name: string,
  scholarshipId: number,
): ScholarshipDownloadDocumentAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT,
    file_id,
    name,
    scholarshipId,
  };
}

export function downloadDocumentOk(data: Blob, name: string): ScholarshipDownloadDocumentOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadDocumentKo(error: string): ScholarshipDownloadDocumentKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_DOWNLOAD_DOCUMENT_KO,
    error,
  };
}

export function getDocument(file_id: number, name: string, scholarshipId: number): GetDocumentScholarshipAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT,
    file_id,
    scholarshipId,
    name,
  };
}

export function getDocumentOk(dataDoc: Blob, name: string): GetDocumentScholarshipOkAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_OK,
    dataDoc,
    name,
  };
}

export function getDocumentKo(error: string): GetDocumentScholarshipKoAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_DOCUMENT_KO,
    error,
  };
}

export function removeDocument(file_id: number, scholarshipId: number): RemoveDocumentScholarshipAction {
  return {
    type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT,
    file_id,
    scholarshipId,
  };
}

export function removeDocumentOk({ documents }: { documents: DocumentationItem[] }): RemoveDocumentScholarshipOkAction {
  return {
    type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_OK,
    documents,
  };
}

export function removeDocumentKo(error: string): RemoveDocumentScholarshipKoAction {
  return {
    type: ScholarshipDataConsts.REMOVE_SCHOLARSHIP_DOCUMENT_KO,
    error,
  };
}

export function cleanScholarshipData(): CleanScholarshipDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_CLEAN_DATA,
  };
}

export function scholarshipSetData(key: string, value: string | number): ScholarshipSetDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA,
    key,
    value,
  };
}

export function scholarshipSetPersonalInfoData(
  key: string,
  value: string | number,
): ScholarshipSetPersonalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_PERSONAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetMotivationalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetMotivationalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_MOTIVATIONAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetSituationInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetSituationInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_SITUATION_INFO,
    key,
    value,
  };
}

export function scholarshipSetEducationalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetEducationalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_EDUCATIONAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetEconomicalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetEconomicalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ECONOMICAL_INFO,
    key,
    value,
  };
}

export function scholarshipSetCostInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetCostInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_COST_INFO,
    key,
    value,
  };
}

export function scholarshipSetInterviewInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetInterviewInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_INTERVIEW_INFO,
    key,
    value,
  };
}

export function scholarshipSetAssessmentInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetAssessmentInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_ASSESSMENT_INFO,
    key,
    value,
  };
}

export function scholarshipSetApprovalInfoData(
  key: string,
  value: string | number | null,
): ScholarshipSetApprovalInfoDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_APPROVAL_INFO,
    key,
    value,
  };
}

export function scholarshipAgreementSetData(
  key: string,
  value: string | number | null,
): ScholarshipAgreementSetDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_AGREEMENT_SET_DATA,
    key,
    value,
  };
}

export function scholarshipPaymentAdjustmentSetData(
  key: string,
  value: string | number | null,
): ScholarshipPaymentAdjustmentSetDataAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_PAYMENT_ADJUSTMENT_SET_DATA,
    key,
    value,
  };
}

export function scholarshipSetDataFollowAction(
  key: string,
  subKey: string,
  value: string | number,
): ScholarshipSetDataFollowAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_SET_DATA_FOLLOW,
    key,
    value,
    subKey,
  };
}

export function getScholarshiptAgreement(id: number): GetScholarshipAgreementAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_AGREEMENT,
    id,
  };
}

export function getScholarshiptAgreementOk(
  scholarshipAgreementData: ScholarshipAgreementData,
): GetScholarshipAgreementOkAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_AGREEMENT_OK,
    scholarshipAgreementData,
  };
}

export function getScholarshiptAgreementKo(error: string): GetScholarshipAgreementKoAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_AGREEMENT_KO,
    error,
  };
}

export function saveAgreement(id: number, data: ScholarshipAgreementData): SaveAgreementAction {
  return {
    type: ScholarshipDataConsts.SAVE_AGREEMENT,
    id,
    data,
  };
}

export function saveAgreementOk(): SaveAgreementOkAction {
  return {
    type: ScholarshipDataConsts.SAVE_AGREEMENT_OK,
  };
}

export function saveAgreementKo(error: string): SaveAgreementKoAction {
  return {
    type: ScholarshipDataConsts.SAVE_AGREEMENT_KO,
    error,
  };
}

export function updateAgreementCalendar(id: number, data: ScholarshipAgreementData): UpdateAgreementAction {
  return {
    type: ScholarshipDataConsts.UPDATE_AGREEMENT_CALENDAR,
    id,
    data,
  };
}

export function updateAgreementCalendarOk(): UpdateAgreementOkAction {
  return {
    type: ScholarshipDataConsts.UPDATE_AGREEMENT_CALENDAR_OK,
  };
}

export function updateAgreementCalendarKo(error: string): UpdateAgreementKoAction {
  return {
    type: ScholarshipDataConsts.UPDATE_AGREEMENT_CALENDAR_KO,
    error,
  };
}

export function sendProviderInfo(
  scholarshipID: number,
  rententionCode: string,
  centerCostData: CenterCost,
): SendProviderInfoAction {
  return {
    type: ScholarshipDataConsts.SEND_PROVIDER_INFO,
    scholarshipID,
    retentionCode: rententionCode,
    centerCostData,
  };
}

export function sendProviderInfoOk(providerData: ScholarshipProviderData): SendProviderInfoOkAction {
  return {
    type: ScholarshipDataConsts.SEND_PROVIDER_INFO_OK,
    providerData,
  };
}

export function sendProviderInfoKo(error: string): SendProviderInfoKoAction {
  return {
    type: ScholarshipDataConsts.SEND_PROVIDER_INFO_KO,
    error,
  };
}

// SEND CONFIGURATION FOLLOWUP

export function sendConfigurationFollowup(data: any): SendConfigurationFollowupAction {
  return {
    type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP,
    data,
  };
}

export function sendConfigurationFollowupOk(): SendConfigurationFollowupOkAction {
  return {
    type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_OK,
  };
}

export function sendConfigurationFollowupKo(error: string): SendConfigurationFollowupKoAction {
  return {
    type: ScholarshipDataConsts.SEND_CONFIGURATION_FOLLOWUP_KO,
    error,
  };
}

// RUN MANUALLY CONFIGURATION FOLLOWUP

export function runConfigurationFollowup(): RunConfigurationFollowupAction {
  return {
    type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP,
  };
}

export function runConfigurationFollowupOk(): RunConfigurationFollowupOkAction {
  return {
    type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_OK,
  };
}

export function runConfigurationFollowupKo(error: string): RunConfigurationFollowupKoAction {
  return {
    type: ScholarshipDataConsts.RUN_CONFIGURATION_FOLLOWUP_KO,
    error,
  };
}

//GET PAYMENT ADJUSTMENT
export function getPaymentAdjustment(id: number): GetPaymentAdjustmentAction {
  return {
    type: ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT,
    id,
  };
}

export function getPaymentAdjustmentOk({ data }: { data: PaymentAdjustmentsData }): GetPaymentAdjustmentOkAction {
  return {
    type: ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_OK,
    dataAdjustmentPayment: data,
  };
}

export function getPaymentAdjustmentKo(error: string): GetPaymentAdjustmentKoAction {
  return {
    type: ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_KO,
    error,
  };
}

// SEND PAYMENT ADJUSTMENT

export function sendPaymentAdjustment(data: PaymentAdjustmentsData): SendPaymentAdjustmentAction {
  return {
    type: ScholarshipDataConsts.SEND_PAYMENT_ADJUSTMENT,
    data,
  };
}

export function sendPaymentAdjustmentOk({ data }: { data: PaymentAdjustmentsData }): SendPaymentAdjustmentOkAction {
  return {
    type: ScholarshipDataConsts.SEND_PAYMENT_ADJUSTMENT_OK,
    responseData: data,
  };
}

export function sendPaymentAdjustmentKo(error: string): SendPaymentAdjustmentKoAction {
  return {
    type: ScholarshipDataConsts.SEND_PAYMENT_ADJUSTMENT_KO,
    error,
  };
}

// GET CONFIGURATION FOLLOWUP

export function getConfigurationFollowup(): GetConfigurationFollowupAction {
  return {
    type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP,
  };
}

export function getConfigurationFollowupOk({ data }: { data: any }): GetConfigurationFollowupOkAction {
  return {
    type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_OK,
    configurationFollowupData: data,
  };
}

export function getConfigurationFollowupKo(error: string): GetConfigurationFollowupKoAction {
  return {
    type: ScholarshipDataConsts.GET_CONFIGURATION_FOLLOWUP_KO,
    error,
  };
}

// GET PAYMENT DOCUMENTS

export function getPaymentAdjustmentDocuments(id: number): GetPaymentAdjustmentDocumentsAction {
  return {
    type: ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_DOCUMENTS,
    id,
  };
}

export function getPaymentAdjustmentDocumentsOk(
  adjustmentDocumentation: DocumentationItem[],
): GetPaymentAdjustmentDocumentsOkAction {
  return {
    type: ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_DOCUMENTS_OK,
    adjustmentDocumentation,
  };
}

export function getPaymentAdjustmentDocumentsKo(error: string): GetPaymentAdjustmentDocumentsKoAction {
  return {
    type: ScholarshipDataConsts.GET_PAYMENT_ADJUSTMENT_DOCUMENTS_KO,
    error,
  };
}

// UPDATE FOLLOW UP
export function saveFollowUp(follow_up: ScholarshipFollow): SaveFollowUpAction {
  return {
    type: ScholarshipDataConsts.SAVE_FOLLOW_UP,
    follow_up,
  };
}

// SWITCH BLOCK PAYMENT

export function switchBlockPayment(id: number): SwitchBlockPaymentAction {
  return {
    type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT,
    id,
  };
}

export function switchBlockPaymentOk(response: boolean): SwitchBlockPaymentOkAction {
  return {
    type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_OK,
    response,
  };
}

export function switchBlockPaymentKo(error: string): SwitchBlockPaymentKoAction {
  return {
    type: ScholarshipDataConsts.SWITCH_BLOCK_PAYMENT_KO,
    error,
  };
}

// ADD/REMOVE MATERIALS

export function addScholarshipMaterial(material: Material): ScholarshipAddMaterialAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL,
    material,
  };
}

export function addScholarshipMaterialOk(material: Material): ScholarshipAddMaterialOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_ADD_MATERIAL_OK,
    material,
  };
}

export function updateScholarshipMaterial(material: Material): ScholarshipUpdateMaterialAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL,
    material,
  };
}

export function updateScholarshipMaterialOk(material: Material): ScholarshipUpdateMaterialOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_UPDATE_MATERIAL_OK,
    material,
  };
}

export function removeScholarshipMaterial(
  scholarship_id: number,
  material_id: number,
): ScholarshipRemoveMaterialAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL,
    scholarship_id,
    material_id,
  };
}

export function removeScholarshipMaterialOk(
  scholarship_id: number,
  material_id: number,
): ScholarshipRemoveMaterialOkAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_REMOVE_MATERIAL_OK,
    scholarship_id,
    material_id,
  };
}

export function scholarshipMaterialKo(error: string): ScholarshipMaterialKoAction {
  return {
    type: ScholarshipDataConsts.SCHOLARSHIP_MATERIAL_KO,
    error,
  };
}

// GET SCHOLARSHIPS TO PAY

export function getScholarshipsToPayInstitute(academic_year: string): GetScholarshipsToPayInstituteAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_INSTITUTE,
    academic_year,
  };
}

export function getScholarshipsToPayInstituteOk(
  academic_year: string,
  scholarshipApiPayments: ScholarshipApiPayment[],
): GetScholarshipsToPayInstituteOkAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_INSTITUTE_OK,
    academic_year,
    scholarshipApiPayments,
  };
}

export function getScholarshipsToPayInstituteKo(error: string): GetScholarshipsToPayInstituteKoAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_INSTITUTE_KO,
    error,
  };
}

export function getScholarshipsToPayEntity(id: number, academic_year: string): GetScholarshipsToPayEntityAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_ENTITY,
    id,
    academic_year,
  };
}

export function getScholarshipsToPayEntityOk(
  scholarshipsToPayEntity: EntityScholarshipPayments,
): GetScholarshipsToPayEntityOkAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_ENTITY_OK,
    scholarshipsToPayEntity,
  };
}

export function getScholarshipsToPayEntityKo(error: string): GetScholarshipsToPayEntityKoAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIPS_TO_PAY_ENTITY_KO,
    error,
  };
}

export function getEntitiesWithScholarshipsToPay(
  academic_year: string,
  payment_date: string | null,
): GetEntitiesWithScholarshipsToPayAction {
  return {
    type: ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY,
    academic_year,
    payment_date,
  };
}

export function getEntitiesWithScholarshipsPayments(
  academic_year: string,
  entity_id: number | null,
): GetEntitiesWithScholarshipsPaymentAction {
  return {
    type: ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT,
    academic_year,
    entity_id,
  };
}

export function exportScholarshipsReports(
  source: 'committee' | 'zing',
  scholarship_type: 'entity' | 'institute' | 'study_center',
  academic_year: string,
): ExportScholarshipReportsAction {
  return {
    type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS,
    source,
    scholarship_type,
    academic_year,
  };
}

export function getEntitiesWithScholarshipsPaymentOk(
  academic_year: string,
  scholarships: ScholarshipEntityPaymentApi[],
): GetEntitiesWithScholarshipsPaymentOkAction {
  return {
    type: ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_OK,
    academic_year,
    scholarships,
  };
}

export function getEntitiesWithScholarshipsToPayOk(
  getEntitiesToPay: EntitiesScholarships[],
): GetEntitiesWithScholarshipsToPayOkAction {
  return {
    type: ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_OK,
    getEntitiesToPay,
  };
}

export function getEntitiesWithScholarshipsToPayKo(error: string): GetEntitiesWithScholarshipsToPayKoAction {
  return {
    type: ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_TO_PAY_KO,
    error,
  };
}

export function getEntitiesWithScholarshipsPaymentKo(error: string): GetEntitiesWithScholarshipsPaymentsKoAction {
  return {
    type: ScholarshipDataConsts.GET_ENTITIES_WITH_SCHOLARSHIPS_PAYMENT_KO,
    error,
  };
}

export function exportScholarshipsReportsOk(
  source: 'committee' | 'zing',
  scholarship_type: 'entity' | 'institute' | 'study_center',
  data: Blob,
): ExportScholarshipReportsOkAction {
  return {
    type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_OK,
    source,
    scholarship_type,
    data,
  };
}

export function exportScholarshipsReportsKo(error: string): ExportScholarshipReportsKoAction {
  return {
    type: ScholarshipDataConsts.EXPORT_SCHOLARSHIP_REPORTS_KO,
    error,
  };
}

export function uploadDocumentTracking(
  documentation_id: number,
  data: File,
  scholarshipId: number,
  trackingId: number,
): UploadDocumentTrackingAction {
  return {
    type: ScholarshipDataConsts.UPLOAD_SCHOLARSHIP_DOCUMENT_TRACKING,
    documentation_id,
    data,
    trackingId,
    scholarshipId,
  };
}

export function setPaymentsAcademicYear(value: string): SetPaymentsAcademicYear {
  return {
    type: ScholarshipDataConsts.SET_PAYMENTS_ACADEMIC_YEAR,
    value,
  };
}

export function getScholarshipAcademiesMetadata(code: string | 'all'): ScholarshipAcademiesMetadataAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_ACADEMIES_METADATA,
    code,
  };
}

export function getScholarshipAcademiesMetadataOk(
  scholarshipAcademies: ScholarshipAcademy[],
): ScholarshipAcademiesMetadataOkAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_METADATA_ACADEMIES_OK,
    scholarshipAcademies,
  };
}

export function getScholarshipAcademiesMetadataKo(error: string): ScholarshipAcademiesMetadataKoAction {
  return {
    type: ScholarshipDataConsts.GET_SCHOLARSHIP_METADATA_ACADEMIES_KO,
    error,
  };
}

export function setEntityPaymentDateFilter(paymentDate: moment.Moment | null): SetEntityPaymentDateFilterAction {
  return {
    type: ScholarshipDataConsts.SET_ENTITY_PAYMENT_DATE_FILTER,
    paymentDate,
  };
}

export function cancelScholarshipAgreement(scholarship_id: number, redirect: string): CancelScholarshipAgreementAction {
  return {
    type: ScholarshipDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT,
    scholarship_id,
    redirect,
  };
}

export function cancelScholarshipAgreementOk(scholarshipData: ScholarshipData): CancelScholarshipAgreementOkAction {
  return {
    type: ScholarshipDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_OK,
    scholarshipData,
  };
}

export function cancelScholarshipAgreementKo(error: string): CancelScholarshipAgreementKoAction {
  return {
    type: ScholarshipDataConsts.CANCEL_SCHOLARSHIP_AGREEMENT_KO,
    error,
  };
}

export function replaceScholarshipPaymentDates(
  paymentDates: PaymentDatesReplace,
): ReplaceScholarshipPaymentDatesAction {
  return {
    type: ScholarshipDataConsts.REPLACE_SCHOLARSHIPS_PAYMENT_DATES,
    paymentDates,
  };
}

export function replaceScholarshipPaymentDatesOk(
  paymentDatesResult: PaymentDatesReplaceResult,
): ReplaceScholarshipPaymentDatesOkAction {
  return {
    type: ScholarshipDataConsts.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_OK,
    paymentDatesResult,
  };
}

export function replaceScholarshipPaymentDatesKo(error: string): ReplaceScholarshipPaymentDatesKoAction {
  return {
    type: ScholarshipDataConsts.REPLACE_SCHOLARSHIPS_PAYMENT_DATES_KO,
    error,
  };
}

export function setScholarshipStep(step: number): SetScholarshipStepAction {
  return {
    type: ScholarshipDataConsts.SET_SCHOLARSHIP_STEP,
    step,
  };
}
