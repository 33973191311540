import { Button } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import LayoutLogin from '../../../components/Layout/LayoutLogin';
import SnackBar from '../../../components/SnackBar/Snackbar';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { getConnectionUrl } from '../../../redux/event/actions';
import { RegistrationIntervalData } from '../../../redux/event/definitions';
import { selectConnectionUrl } from '../../../redux/event/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from './typeform.module.scss';

interface EventLoginProps extends RouteComponentProps<{ type: string; id: string }> {
  registrationIntervalData: RegistrationIntervalData;
  getConnectionUrl: (id: number, type: string) => void;
}

const EventLogin: React.FC<EventLoginProps> = ({ match, registrationIntervalData, getConnectionUrl }) => {
  const {
    params: { type, id },
  } = match;

  const typeMap: {
    [key: string]: string;
  } = {
    servicios: 'services',
    eventos: 'events',
  };

  useEffect(() => {
    getConnectionUrl(+id, typeMap[type]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [view, setView] = useState(false);
  const singularType = type.substr(0, type.length - 1);

  const startDate = moment(registrationIntervalData.startDate, 'YYYY-MM-DD').toDate();
  const endDate = moment(registrationIntervalData.endDate, 'YYYY-MM-DD').toDate();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (today < startDate) {
    return (
      <LayoutLogin subtitle={tFormKey('Formulario de registro')}>
        <div className={styles.container}>
          {tKey(`El periodo registro para este ${singularType} no se ha abierto todavía`)}
        </div>
      </LayoutLogin>
    );
  } else if (today > endDate) {
    return (
      <LayoutLogin subtitle={tFormKey('Formulario de registro')}>
        <div className={styles.container}>
          {tKey(`El periodo de registro para este ${singularType} ya está cerrado`)}
        </div>
      </LayoutLogin>
    );
  } else if (registrationIntervalData.full) {
    return (
      <LayoutLogin subtitle={tFormKey('Formulario de registro')}>
        <div className={styles.container}>{tKey(`El aforo para este ${singularType} esta completo`)}</div>
      </LayoutLogin>
    );
  } else {
    return (
      <LayoutLogin subtitle={tFormKey('Formulario de registro')}>
        <Fragment>
          <div className={styles.container}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="button"
              onClick={() => {
                registrationIntervalData.url ? window.location.replace(`/${type}/${id}/typeform/auth`) : setView(true);
              }}
              disableElevation
            >
              {tFormKey('Ya soy usuario de la plataforma')}
            </Button>
            <div className={styles.marginOk}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="button"
                onClick={() => {
                  registrationIntervalData.url
                    ? window.location.replace(`${registrationIntervalData.url}#hid=${id}&horigin=external`)
                    : setView(true);
                }}
                disableElevation
              >
                {tFormKey('No soy usuario de la plataforma')}
              </Button>
            </div>
          </div>
          <SnackBar
            open={view}
            handleClose={() => {
              setView(false);
            }}
            message={tErrorKey('Este evento está todavía en construcción')}
            severity="error"
          />
        </Fragment>
      </LayoutLogin>
    );
  }
};

const mapStateToProps = (state: AppState) => ({
  registrationIntervalData: selectConnectionUrl(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  getConnectionUrl: (id: number, type: string): void => dispatch(getConnectionUrl(id, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventLogin);
