import { ButtonProps } from '@material-ui/core';
import React from 'react';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import { ScholarshipData } from '../../../redux/scholarship/definitions';
import styles from './scholarshipMenuRight.module.scss';
import StateMenu from './StateMenu';

interface Props {
  actionButtons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  scholarshipData: ScholarshipData;
}
const ScholarshipMenuRight: React.FC<Props> = ({ actionButtons, moreInfoButtons, scholarshipData }) => (
  <div className={styles.container}>
    <StateMenu scholarshipData={scholarshipData} />
    <ActionsMenu actionsButtons={actionButtons} moreInfoButtons={moreInfoButtons} />
  </div>
);

export default ScholarshipMenuRight;
