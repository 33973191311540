import React from 'react';
import { ItemMetadata } from '../../../../redux/directory/definitions';
import SelectAutocompleteResultListItem from './SelectAutocompleteListItem';
import styles from './selectAutocompleteResultList.module.scss';

interface SelectAutocompleteResultListProps {
  metadata: ItemMetadata[];
  searchData: string;
  propertyToSend: keyof ItemMetadata;
  onSelect: (value: string, propertyToSend: string | number) => void;
}

const SelectAutocompleteResultList: React.FC<SelectAutocompleteResultListProps> = ({
  metadata,
  searchData,
  propertyToSend,
  onSelect,
}) => {
  return (
    <ul className={styles.root}>
      {metadata
        .filter(meta => meta.value.toLocaleLowerCase().includes(searchData.toLocaleLowerCase()))
        .map((item, index) => (
          <SelectAutocompleteResultListItem
            key={index}
            text={item.value}
            active={true}
            onClick={() => {
              onSelect(item.value, item[propertyToSend]);
            }}
          />
        ))}
    </ul>
  );
};

export default SelectAutocompleteResultList;
