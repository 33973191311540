import { Action } from 'redux';
import { APIValidationError } from '../../api/api';
import { RegisterConsts } from './action_types';

export interface RegisterFields {
  password: string;
  password2: string;
  email: string;
  role_id: number;
  language: string;
}

export interface RolesProps {
  name: string;
  id: number;
}

export interface RequestRolesAction extends Action {
  type: RegisterConsts.REQUEST_ROLES;
  entity: boolean;
}

export interface RequestRolesOkAction extends Action {
  type: RegisterConsts.REQUEST_ROLES_OK;
  roles: RolesProps[];
}

export interface RequestRolesKoAction extends Action {
  type: RegisterConsts.REQUEST_ROLES_KO;
  error: string;
}

export interface RegisterSubmitAction extends Action {
  type: RegisterConsts.REGISTER_SUBMIT;
  registerFields: RegisterFields;
}

export interface RegisterSubmitOkAction extends Action {
  type: RegisterConsts.REGISTER_SUBMIT_OK;
  data: any;
}

export interface RegisterSubmitValidationErrorsAction extends Action {
  type: RegisterConsts.REGISTER_SUBMIT_VALIDATION_ERRORS;
  validationErrors: APIValidationError;
}

export interface RegisterSubmitKoAction extends Action {
  type: RegisterConsts.REGISTER_SUBMIT_KO;
  errorAPI: string;
}

export interface SetRoleAction extends Action {
  type: RegisterConsts.SET_ROLE;
  roleId: number;
}

export type RegisterAction =
  | RequestRolesAction
  | RequestRolesOkAction
  | RequestRolesKoAction
  | RegisterSubmitAction
  | RegisterSubmitOkAction
  | RegisterSubmitValidationErrorsAction
  | RegisterSubmitKoAction
  | SetRoleAction;

export type RegisterDispatch = (action: RegisterAction) => void;

export function getRoles(entity: boolean): RequestRolesAction {
  return {
    type: RegisterConsts.REQUEST_ROLES,
    entity,
  };
}

export function getRolesOk(roles: RolesProps[]): RequestRolesOkAction {
  return {
    type: RegisterConsts.REQUEST_ROLES_OK,
    roles,
  };
}

export function getRolesKo(error: string): RequestRolesKoAction {
  return {
    type: RegisterConsts.REQUEST_ROLES_KO,
    error,
  };
}

export function registerSubmit(registerFields: RegisterFields): RegisterSubmitAction {
  return {
    type: RegisterConsts.REGISTER_SUBMIT,
    registerFields,
  };
}

export function registerSubmitOk(data: any): RegisterSubmitOkAction {
  return {
    type: RegisterConsts.REGISTER_SUBMIT_OK,
    data,
  };
}

export function registerSubmitValidationErrors(
  validationErrors: APIValidationError,
): RegisterSubmitValidationErrorsAction {
  return {
    type: RegisterConsts.REGISTER_SUBMIT_VALIDATION_ERRORS,
    validationErrors,
  };
}

export function registerSubmitKo(errorAPI: string): RegisterSubmitKoAction {
  return {
    type: RegisterConsts.REGISTER_SUBMIT_KO,
    errorAPI,
  };
}

export function setRole(roleId: number): SetRoleAction {
  return {
    type: RegisterConsts.SET_ROLE,
    roleId,
  };
}
