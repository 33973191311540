import RoleCode from '../../types/Roles/roleCode';
import { AppState } from '../root-reducer';
import { selectUsersByRole } from '../user/selectors';
import { ServiceData, ServiceRequestData } from './definitions';
import { ServiceDataState } from './reducers';

export const selectService = (state: AppState): ServiceDataState => state.serviceReducer;
export const selectServicesList = (state: AppState): ServiceData[] =>
  selectService(state).servicesList.map(service => {
    return {
      ...service,
      start_date: typeof service.start_date === 'string' ? service.start_date.split(' ')[0] : service.start_date,
      end_date: typeof service.end_date === 'string' ? service.end_date.split(' ')[0] : service.end_date,
    };
  });
export const selectServiceData = (state: AppState): ServiceData => selectService(state).serviceData;
export const selectLoading = (state: AppState): boolean => selectService(state).loading;
export const selectServiceUsersList = (state: AppState): { id: number; info: string }[] | [] =>
  selectUsersByRole(state, [RoleCode.JOVEN_ENTIDAD, RoleCode.JOVEN_INSTITUTO])
    .filter(user => !state.serviceReducer.serviceRequests.some(userRequest => user.id === userRequest.user_id))
    .map(user => ({
      id: user.id,
      info: `${user.name} ${user.surname} (${user.email}) (${user.document_number})`,
    }));
export const selectServiceRequestData = (state: AppState): ServiceRequestData[] =>
  state.serviceReducer.serviceRequests.map(request => ({
    lopd_label: request.lopd ? 'Sì' : 'No',
    manual_label: request.manual ? 'Sì' : 'No',
    ...request,
  }));
