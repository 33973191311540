import assertUnreachable from '../../helpers/assertUnreachable';
import { FeedbackAPIErrorConsts } from './action_types';
import { FeedbackAPIAction, FeedbackAPIState, initialStateFeedbackAPI } from './definitions';

const feedbackAPIReducer = (state = initialStateFeedbackAPI, action: FeedbackAPIAction): FeedbackAPIState => {
  switch (action.type) {
    case FeedbackAPIErrorConsts.SHOW_SNACKBAR:
      return {
        ...state,
        isSnackbarShowing: true,
        message: action.message,
        severity: action.severity,
        redirect: action.redirect,
        autoHideDuration: action.autoHideDuration,
      };

    case FeedbackAPIErrorConsts.HIDE_SNACKBAR:
      return {
        ...state,
        isSnackbarShowing: false,
      };
  }

  assertUnreachable(action);
  return state;
};

export default feedbackAPIReducer;
