import { ButtonProps, MenuItem } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import moment, { isMoment } from 'moment';
import React, { FocusEvent, Fragment, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ClipBoard from '../../../components/ClipBoard/ClipBoard';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import RadioButtonGroup from '../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions } from '../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../components/Select/SelectController';
import FncAddDocuments from '../../../components/UploadFile/FncAddDocuments';
import Title from '../../../components/Title/Title';
import * as DateConstants from '../../../constants/date';
import { unixToDateAmericanFormat } from '../../../helpers/dateHelper';
import { tErrorKey, tFormKey, tKey } from '../../../helpers/translate';
import { getAreas } from '../../../redux/area/action';
import { selectSubAreasList } from '../../../redux/area/selectors';
import { showSnackbar } from '../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../redux/FeedbackAPI/definitions';
import { GenericMetadata } from '../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../redux/metadata/selectors';
import { AreaSubArea } from '../../../redux/project/definitions';
import { AppState } from '../../../redux/root-reducer';
import {
  downloadDocumentService,
  getServiceData,
  removeDocumentService,
  serviceCreationData,
  updateService,
  uploadDocumentService,
} from '../../../redux/service/actions';
import {
  ServiceData,
  ServiceDataDates,
  ServiceDataDatesKeys,
  ServiceDataDispatch,
  UpdateServiceFormFields,
} from '../../../redux/service/definitions';
import { selectLoading, selectServiceData } from '../../../redux/service/selectors';
import { ServiceBaseSchema } from '../../../validations/formSchema';
import styles from './createService.module.scss';
import SubmenuService from './SubmenuService';

interface CreateServiceProps extends RouteComponentProps<{ id: string }> {
  service: ServiceData;
  loading: boolean;
  subList: AreaSubArea[];
  disabled?: boolean;
  readOnly: boolean;
  metadata: GenericMetadata;
  getAreas: () => void;
  getService: (id: string) => void;
  setData: (key: string, value: string | number) => void;
  updateService: (data: UpdateServiceFormFields, id: number) => void;
  uploadDocument: (documentationId: number, data: File, eventId: number) => void;
  downloadDocument: (fileId: number, name: string, eventId: number, get?: boolean) => void;
  removeDocument: (fileId: number, eventId: number) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar) => void;
}

export type LabelsError<T> = {
  [K in keyof T]?: string;
};

const CreateService: React.FC<CreateServiceProps> = ({
  match,
  service,
  loading,
  subList,
  disabled,
  metadata,
  readOnly = false,
  getAreas,
  getService,
  setData,
  updateService,
  uploadDocument,
  downloadDocument,
  removeDocument,
  showSnackbar,
}) => {
  const {
    params: { id },
  } = match;

  useEffect(() => {
    getAreas();
    getService(id);
  }, [id, getService, getAreas]);

  const {
    register,
    errors,
    handleSubmit,
    control,
    getValues,
    watch,
    setError,
    clearError,
    triggerValidation,
  } = useForm<ServiceData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ServiceBaseSchema,
  });

  const linkRef = useRef(null);

  const handleSave = (data: UpdateServiceFormFields) => {
    if (!Object.keys(errors).length) {
      updateService(data, +id);
    } else {
      for (const e of Object.keys(errors)) {
        // @ts-ignore
        setError(e, errors[e].types, errors[e].message);
      }
    }
  };

  const handleGetDocument = (fileId: number, name: string, serviceId: number) => {
    downloadDocument(fileId, name, serviceId, false);
  };

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('ACTUALIZAR DATOS'),
      onClick: handleSubmit(handleSave),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      hidden: disabled || readOnly,
    },
  ];

  const buttonModal = {
    title: tKey('Cancelar Servicio'),
    buttonText: tKey('Cancelar Servicio'),
    startIcon: <Close />,
    bodyModal: tKey('¿Está seguro de cancelar el servicio?'),
    handleYes: () => {
      const data = getValues();
      data.cancelled = 1;
      updateService(data, +id);
    },
    buttonOk: tKey('Sí'),
    buttonKo: tKey('No'),
    disabled: !!+service.cancelled,
  };

  const compareDates = (name: ServiceDataDatesKeys, pivot: ServiceDataDatesKeys) => {
    const labels: LabelsError<ServiceDataDates> = {
      start_date: 'Fechas de inicio',
      start_registration_date: 'Fecha inicio inscripciones',
    };
    const _pivot = getValues()[pivot];
    const _name = getValues()[name];
    if (isMoment(_pivot) && isMoment(_name)) {
      if (_name.isBefore(_pivot.startOf('day'))) {
        setError(name, 'date', tKey(`No puede ser anterior a la ${labels[pivot]}`));
      } else {
        clearError(name);
      }
    }
  };

  const validateDates = (name: ServiceDataDatesKeys, pivot?: ServiceDataDatesKeys) => (
    date: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isValid = moment(date.target.value, DateConstants.DATE_FORMAT, true).isValid();
    if (!isValid) {
      setError(name, 'date', tKey('Fecha invalida'));
      return;
    } else {
      clearError(name);
    }
    if (pivot) {
      compareDates(name, pivot);
    }
  };

  return (
    <LayoutForm
      leftSubmenu={<SubmenuService service={service} current_section="datos_basicos" />}
      rightSubmenu={<ActionsMenu actionsButtons={buttonsValidations} isModal={buttonModal} />}
    >
      {loading ? (
        <Loading big />
      ) : (
        <Fragment>
          <Title>{tKey('Datos del servicio')}</Title>
          <FormContainer title={tKey('Datos generales')}>
            <div className={styles.container__subcontainers}>
              <TextInputController
                size="50"
                label={tFormKey('Identificador del servicio')}
                name="id"
                defaultValue={service.service_id}
                control={control}
                disabled
                errors={errors}
                schema={ServiceBaseSchema}
              />
              <KeyboardDatePickerController
                label={tFormKey('Fecha de alta')}
                name="created_at"
                defaultValue={service.created_at}
                control={control}
                schema={ServiceBaseSchema}
                errors={errors}
                disabled
                size="50"
                onBlur={validateDates('created_at')}
              />
            </div>
            <TextInputController
              size="50"
              label={tFormKey('Nombre del servicio')}
              name="name"
              defaultValue={service.name}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
            />
            <div className={styles.container__subcontainers}>
              <KeyboardDatePickerController
                label={tFormKey('Fecha de inicio')}
                name="start_date"
                control={control}
                defaultValue={service.start_date as number}
                schema={ServiceBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('start_date')}
              />
              <KeyboardDatePickerController
                label={tFormKey('Fecha de fin')}
                name="end_date"
                minDate={watch('start_date') || unixToDateAmericanFormat((service.start_date as number) || 0)}
                minDateMessage={tFormKey('No puede ser anterior a la Fecha de inicio')}
                defaultValue={service.end_date}
                control={control}
                schema={ServiceBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('end_date', 'start_date')}
              />
            </div>
            <div className={styles.container__subcontainers}>
              <KeyboardDatePickerController
                label={tFormKey('Fecha inicio inscripciones')}
                name="start_registration_date"
                defaultValue={service.start_registration_date}
                control={control}
                schema={ServiceBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('start_registration_date')}
              />

              <KeyboardDatePickerController
                label={tFormKey('Fecha fin inscripciones')}
                name="end_registration_date"
                minDate={
                  watch('start_registration_date') || unixToDateAmericanFormat(service.start_registration_date || 0)
                }
                minDateMessage={tErrorKey('eve.end_date_validation')}
                defaultValue={service.end_registration_date}
                control={control}
                schema={ServiceBaseSchema}
                errors={errors}
                size="50"
                onBlur={validateDates('end_registration_date', 'start_registration_date')}
              />
            </div>
            <div className={styles.container__subcontainers}>
              <TextInputController
                size="50"
                label={tFormKey('svc.requested_number')}
                name="requested_number"
                defaultValue={service.requested_number || '0'}
                control={control}
                disabled
                errors={errors}
                schema={ServiceBaseSchema}
              />
              <TextInputController
                size="50"
                label={tFormKey('svc.assistant_number')}
                name="assistant_number"
                defaultValue={service.assistant_number || '0'}
                control={control}
                disabled
                errors={errors}
                schema={ServiceBaseSchema}
              />
            </div>
            <div className={styles.container__subcontainers}>
              <TextInputController
                size="50"
                label={tFormKey('svc.unidentified')}
                name="unidentified"
                defaultValue={service.unidentified || '0'}
                control={control}
                disabled={disabled}
                errors={errors}
                schema={ServiceBaseSchema}
              />
              <TextInputController
                size="50"
                label={tFormKey('svc.beneficiaries')}
                name="beneficiaries"
                validator={false}
                defaultValue={service.beneficiaries || '0'}
                control={control}
                disabled
                errors={errors}
                schema={ServiceBaseSchema}
              />
            </div>
            <TextInputController
              size="50"
              inputRef={linkRef}
              label={tFormKey('Link al formulario de TypeForm')}
              name="registration_link"
              defaultValue={service.registration_link}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
              InputProps={{
                endAdornment: (
                  <ClipBoard
                    disabled={!watch('registration_link', service.registration_link)}
                    value={watch('registration_link', service.registration_link) || ''}
                    showSnackbar={showSnackbar}
                  />
                ),
              }}
            />
            <TextInputController
              size="50"
              inputRef={linkRef}
              label={tFormKey('Link de registro')}
              name="local_link"
              defaultValue={`http://${window.location.host}/servicios/${id}/login`}
              control={control}
              disabled
              errors={errors}
              schema={ServiceBaseSchema}
              InputProps={{
                endAdornment: (
                  <ClipBoard
                    value={`http://${window.location.host}/servicios/${id}/login`}
                    showSnackbar={showSnackbar}
                  />
                ),
              }}
            />
            <TextInputController
              size="50"
              label={tFormKey('Nombre del responsable')}
              name="main_contact"
              defaultValue={service.main_contact}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Correo del responsable')}
              name="main_contact_email"
              defaultValue={service.main_contact_email}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Teléfono del responsable')}
              name="main_contact_phone"
              defaultValue={service.main_contact_phone}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
            />
            <div className={styles.container__subcontainers}></div>
            <TextInputController
              size="50"
              label={tFormKey('Nombre del proveedor')}
              name="provider_contact"
              defaultValue={service.provider_contact}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Correo del proveedor')}
              name="provider_contact_email"
              defaultValue={service.provider_contact_email}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
            />
            <TextInputController
              size="50"
              label={tFormKey('Teléfono del proveedor')}
              name="provider_contact_phone"
              defaultValue={service.provider_contact_phone}
              control={control}
              disabled={disabled}
              errors={errors}
              schema={ServiceBaseSchema}
            />
            <div className={styles.container__subcontainers}></div>
            <SelectController
              size="50"
              key="subarea_id"
              name="subarea_id"
              errors={errors}
              schema={ServiceBaseSchema}
              defaultValue={service.subarea_id}
              label={tFormKey('Área-Subárea')}
              disabled={disabled}
              control={control}
            >
              {subList.map((element: { id: number; name: string }) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
            <div className={styles.container__subcontainers}>
              <TextInputController
                type="number"
                size="50"
                label={tFormKey('Aforo máximo')}
                name="max_capacity"
                defaultValue={service.max_capacity || '0'}
                control={control}
                disabled={disabled}
                errors={errors}
                schema={ServiceBaseSchema}
                onBlur={(e: FocusEvent<HTMLInputElement>) => {
                  setData('max_capacity', e.target.value || '0');
                  triggerValidation('max_capacity');
                }}
              />
              <TextInputController
                size="50"
                label={tFormKey('NPS')}
                name="nps"
                defaultValue={service.nps}
                control={control}
                disabled={disabled}
                errors={errors}
                schema={ServiceBaseSchema}
              />
            </div>

            <div className={styles.container__radiobuttons}>
              <RadioButtonGroup
                name="cancelled"
                disabled
                questionText={tFormKey('Servicio cancelado')}
                options={yesNoOptions}
                value={service.cancelled?.toString() || '0'}
                register={register}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('cancelled', e.target.value);
                }}
              />
            </div>
            <FncAddDocuments
              documents={service.documents}
              getFile={handleGetDocument}
              actions={['checked', 'upload', 'download', 'view', 'remove']}
              filesOk={['png', 'jpg']}
              disabled={disabled}
              upload={uploadDocument}
              genericId={+id}
              download={downloadDocument}
              remove={removeDocument}
              originalName
            />
          </FormContainer>
        </Fragment>
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  service: selectServiceData(state),
  loading: selectLoading(state),
  subList: selectSubAreasList(state),
  metadata: selectGenericMetadata(state),
});

const mapDispatchToProps = (dispatch: ServiceDataDispatch | any) => ({
  getAreas: (): void => dispatch(getAreas()),
  setData: (key: string, value: string | number): void => dispatch(serviceCreationData(key, value)),
  updateService: (data: UpdateServiceFormFields, id: number): void => dispatch(updateService(data, id)),
  getService: (id: string) => dispatch(getServiceData(id)),
  uploadDocument: (documentationId: number, data: File, serviceId: number): void =>
    dispatch(uploadDocumentService(documentationId, data, serviceId)),
  downloadDocument: (fileId: number, name: string, serviceId: number, get?: boolean): void =>
    dispatch(downloadDocumentService(fileId, name, serviceId, get)),
  removeDocument: (fileId: number, serviceId: number): void => dispatch(removeDocumentService(fileId, serviceId)),
  showSnackbar: (message: string, severity: SeveritySnackbar): void => dispatch(showSnackbar(message, severity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateService);
