export enum RegisterConsts {
  REQUEST_ROLES = 'REGISTER:REQUEST:ROLES',
  REQUEST_ROLES_OK = 'REGISTER:REQUEST:ROLES:OK',
  REQUEST_ROLES_KO = 'REGISTER:REQUEST:ROLES:KO',
  REGISTER_SUBMIT = 'REGISTER:SUBMIT',
  REGISTER_SUBMIT_OK = 'REGISTER:SUBMIT:OK',
  REGISTER_SUBMIT_VALIDATION_ERRORS = 'REGISTER:SUBMIT:VALIDATION:ERRORS',
  REGISTER_SUBMIT_KO = 'REGISTER:SUBMIT:KO',
  SET_ROLE = 'REGISTER:SET:ROLE',
}
