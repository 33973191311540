import { ScholarshipStateChange, ScholarshipData } from '../../../redux/scholarship/definitions';
import { tScholarshipKey } from '../../../helpers/translate';
import React, { useState } from 'react';
import TransitionModal from '../../../components/Modal/TransitionModal';
import { KeyButton } from '../../../redux/common/definitions';
import RefuseEntityModal from '../../Entities/RefuseEntityModal';
import { getButtonType } from '../ButtonActions/ScholarshipButtonActions';

interface Props {
  scholarshipData: ScholarshipData;
  handleChangeState: (states: ScholarshipStateChange, dataValues?: ScholarshipData | undefined) => void;
}
const GetInfoScholarshipModal = ({ scholarshipData, handleChangeState }: Props) => {
  const [modal, setModal] = useState(false);

  const ButtonGetInfo = getButtonType(KeyButton.GET_INFO);
  ButtonGetInfo.onClick = () => {
    setModal(true);
  };
  return (
    <>
      <TransitionModal
        view={modal}
        handleClose={() => {
          setModal(false);
        }}
        title={tScholarshipKey('Pedir información')}
        helperMode={true}
      >
        <RefuseEntityModal
          handleClose={() => {
            setModal(false);
          }}
          handleSend={(message = tScholarshipKey('Sin especificar')) =>
            handleChangeState('s08s09', { ...scholarshipData, comments: message })
          }
        />
      </TransitionModal>
    </>
  );
};

export default GetInfoScholarshipModal;
