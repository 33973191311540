/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from '@material-ui/core/Button';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import SwitchController from '../../../components/SwitchController/SwitchController';
import UploadFileButton from '../../../components/UploadFileButton/UploadFileButton';
import { permissionType } from '../../../constants/permissions_definitions';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../../helpers/translate';
import {
  createEntityIban,
  deleteIbanData,
  downloadIbanEntityDocument,
  entitySetIbanData,
  entityUpdateIban,
  removeIbanEntityDocument,
  uploadIbanEntityDocument,
} from '../../../redux/entity/actions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { EntityIbanData } from '../../../redux/entity/definitions';
import { selectIbantData } from '../../../redux/entity/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from '../Modal/genericIbanEntityModal.module.scss';
import DownloadFile from '../../../components/DownloadFile/DownloadFile';
import { IbanEntitySchema } from '../../../validations/Entities/entityFormSchema';

interface FormGenericIbanModalProps {
  loading: boolean;
  isUpdating: boolean;
  entityId: number;
  ibanData: EntityIbanData;
  validationErrors: APIValidationError | null;
  userCan: (permission: permissionType) => boolean | undefined;
  setData: (key: string, value: string | number) => void;
  clearIbanData: () => void;
  closeIbanModal: () => void;
  onUploadFile: (file: File) => void;
  onRemoveFile: (file_id: number) => void;
  onDownloadFile: (file_id: number, filename: string) => void;
  onSave: (ibanData: EntityIbanData, entityId: number) => void;
  onUpdate: (ibanData: EntityIbanData, entityId: number) => void;
}

const GenericIbanEntityModal: React.FC<FormGenericIbanModalProps> = ({
  loading,
  isUpdating,
  ibanData,
  entityId,
  validationErrors,
  userCan,
  setData,
  onUpdate,
  onSave,
  clearIbanData,
  closeIbanModal,
  onUploadFile,
  onRemoveFile,
  onDownloadFile,
}) => {
  const { handleSubmit, errors, control, setValue, setError } = useForm<EntityIbanData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: IbanEntitySchema,
  });

  useValidatorAPI(validationErrors, setError);

  const activate_iban_action = userCan('allow_activate_entity_iban');

  const ibanCountryCode = ibanData ? ibanData?.iban?.slice(0, 2) : null;

  const onSubmit = (data: EntityIbanData) => {
    if (isUpdating) {
      const totalInfoUpdate = { ...data, id: ibanData?.id, entity_id: entityId, active: ibanData.active };
      onUpdate(totalInfoUpdate, entityId);
    } else {
      const totalInfo = { ...data, id: ibanData?.id, entity_id: entityId, active: 0 };
      onSave(totalInfo, entityId);
    }
    clearIbanData();
    closeIbanModal();
  };

  return (
    <div className={styles.modalContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {loading ? (
          <div className={styles.loading}>
            <Loading big />
          </div>
        ) : (
          <FormContainer title="">
            <div className={styles.modalRow}>
              <TextInputController
                size="100"
                control={control}
                errors={errors}
                type="text"
                name="alias"
                schema={IbanEntitySchema}
                defaultValue={ibanData?.alias}
                label={tFormKey('Nombre')}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('alias', e.target.value)}
              />
              <TextInputController
                control={control}
                errors={errors}
                type="text"
                name="ibanCountryCode"
                visible={false}
                schema={IbanEntitySchema}
                defaultValue={ibanCountryCode}
                label={tFormKey('ibanCountryCode')}
              />

              <TextInputController
                size="100"
                control={control}
                errors={errors}
                type="text"
                name="iban"
                disabled={isUpdating}
                label={tFormKey('IBAN')}
                schema={IbanEntitySchema}
                defaultValue={ibanData?.iban}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                  const ibanInputValue = e.target.value.replace(/\s/g, '');
                  setData('iban', ibanInputValue);
                  setValue('iban', ibanInputValue);
                }}
              />
            </div>
            <div className={`${styles.modalRow} ${styles.modalRowTwoCol}`}>
              {isUpdating ? (
                <DownloadFile file_id={ibanData?.file_id} filename={ibanData?.filename} downloadFile={onDownloadFile} />
              ) : (
                <UploadFileButton
                  file_id={ibanData?.file_id}
                  filename={ibanData?.filename}
                  errors={errors}
                  downloadFile={onDownloadFile}
                  removeFile={onRemoveFile}
                  onUploadFile={onUploadFile}
                  setValue={setValue}
                />
              )}
              <TextInputController
                control={control}
                errors={errors}
                type="number"
                name="file_id"
                schema={IbanEntitySchema}
                defaultValue={ibanData?.file_id}
                visible={false}
              />
              <SwitchController
                control={control}
                name="active"
                label={!!ibanData?.active ? tKey('Activado') : tKey('Desactivado')}
                defaultValue={!!ibanData?.active || ibanData?.active !== undefined}
                checked={!!ibanData?.active}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData('active', e.target.checked ? 1 : 0);
                }}
                disabled={!activate_iban_action || (activate_iban_action && !isUpdating)}
              />
            </div>
            <div className={`${styles.modalRow} ${styles.modalRowFooter}`}>
              {isUpdating ? (
                <Button variant="outlined" color="primary" style={{ marginLeft: '10px', width: '120px' }} type="submit">
                  {tFormKey('Modificar')}
                </Button>
              ) : (
                <Button variant="outlined" color="primary" style={{ marginLeft: '10px', width: '120px' }} type="submit">
                  {tFormKey('Guardar')}
                </Button>
              )}
            </div>
          </FormContainer>
        )}
      </form>
    </div>
  );
};
const mapStateToProps = (state: AppState) => ({
  loading: state.entityReducer.loadingModal,
  validationErrors: state.entityReducer.validationErrors,
  ibanData: selectIbantData(state),
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(entitySetIbanData(key, value)),
  clearIbanData: () => dispatch(deleteIbanData()),
  onUpdate: (ibanData: any, entity_id: number): void => dispatch(entityUpdateIban(ibanData, entity_id)),
  onSave: (ibanData: any, entity_id: number): void => dispatch(createEntityIban(ibanData, entity_id)),
  onDownloadFile: (file_id: number, filename: string) => dispatch(downloadIbanEntityDocument(file_id, filename)),
  onUploadFile: (file: File) => dispatch(uploadIbanEntityDocument(file)),
  onRemoveFile: (file_id: number) => dispatch(removeIbanEntityDocument(file_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericIbanEntityModal);
