import { put, takeLatest } from '@redux-saga/core/effects';
import errorMessage from '../../../../helpers/errorMessage';
import { addNewParticipantKo, addNewParticipantOk } from './actions';
import { AddParticipantAction } from './definitions';
import { showSnackbar } from '../../../FeedbackAPI/actions';
import { tKey } from '../../../../helpers/translate';
import { apiPostParticipant } from '../../../../api/InternalProject/Participants/postParticipants';
import { AddParticipantConst } from './action_types';

function* createParticipantFromSaga(action: AddParticipantAction) {
  try {
    yield apiPostParticipant(action.participant);
    yield put(showSnackbar(tKey('Nuevo participante añadido.'), 'success', undefined, 3000));
    yield put(addNewParticipantOk());
  } catch (error) {
    yield put(showSnackbar(tKey(errorMessage(error)), 'error', undefined, 1500));
    yield put(addNewParticipantKo(errorMessage(error)));
  }
}

export default takeLatest<AddParticipantAction>(AddParticipantConst.ADD_PARTICIPANT, createParticipantFromSaga);
