import React from 'react';
import Layout from '../../components/Layout/Layout';
import { tKey } from '../../helpers/translate';
import styles from './lopd.module.scss';

const Lopd: React.FC<{}> = () => {
  return (
    <Layout layoutDisabled={true}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>
            {tKey('LEY ORGÁNICA')} <br /> {tKey('DE PROTECCIÓN DE DATOS')}
          </h1>
          <section className={styles.content}>
            <p className={styles.subtitles}>
              <strong>{tKey('LOPDTit1')}</strong>
            </p>
            <br />
            <p>
              {tKey('LOPDP1')} <p style={{ color: 'red' }}>{tKey('LOPDP2')}</p>
            </p>
            <br />
            <p>{tKey('LOPDP3')}</p>
            <br />
            <p>{tKey('LOPDP4')}</p>
            <br />
            <p>{tKey('LOPDP5')}</p>
            <br />
            <p>{tKey('LOPDP6')}</p>
            <br />
            <p>{tKey('LOPDP7')}</p>
            <br />
            <p>{tKey('LOPDP8')}</p>
            <br />
            <p>{tKey('LOPDP9')}</p>
            <br />
            <p>{tKey('LOPDP10')}</p>
            <br />
            <p>{tKey('LOPDP11')}</p>
            <br />
            <p>{tKey('LOPDP12')}</p>
            <br />
            <p>{tKey('LOPDP13')}</p>
            <br />
            <p>{tKey('LOPDP14')}</p>
            <br />
            <p>{tKey('LOPDP15')}</p>
            <br />
            <p>{tKey('LOPDP16')}</p>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Lopd;
