import React from 'react';
import webStyle from '../../helpers/webStyle';
import styles from './title.module.scss';

interface TitleProps {
  children?: string;
}
const Title: React.FC<TitleProps> = ({ children }) => (
  <h1 className={`${styles.root} ${styles[`title_${webStyle}`]}`}>{children}</h1>
);

export default Title;
