import colorPalette from '../helpers/colorPalette';

export default {
  MuiTable: {
    root: {
      tableLayout: 'fixed',
    },
  },
  MuiTableCell: {
    head: {
      fontWeight: 700,
      color: colorPalette.secondary80,
    },
    root: {
      padding: '15px 20px',
    },
    body: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .MuiSelect-icon': {
        color: 'green',
        border: '1px solid pink',
        content: 'hola',
      },
      '& [class*=MuiSelect-icon]': {
        color: colorPalette.secondary20,
      },
      '& div': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  MuiInput: {
    root: {
      fontSize: '0.875rem',
    },
    underline: {
      '&:before': {
        borderBottom: 'none!important',
      },
      '&:after': {
        borderBottom: 'none!important',
      },
    },
  },
  MuiInputAdornment: {
    positionStart: {
      marginLeft: '4px',
    },
  },
  MuiTablePagination: {
    root: {
      color: colorPalette.primary,
    },
  },
  MuiSelect: {
    icon: {
      color: colorPalette.primary,
    },
    root: {
      paddingLeft: '15px',
      borderRadius: '15px!important',
    },
  },
  MuiPagination: {
    root: {
      color: colorPalette.primary,
    },
  },
  MuiTypography: {
    root: {
      color: colorPalette.secondary80,
    },
    caption: {
      color: colorPalette.primary,
    },
    h6: {
      color: colorPalette.primary,
      fontSize: '1.5rem',
    },
  },
  MuiButton: {
    root: {
      backgroundColor: colorPalette.primary,
      color: 'white',
      fontWeight: 'bold',
      opacity: '0.95',
    },
    textPrimary: {
      backgroundColor: 'transparent',
      textTransform: 'none',
      fontWeight: 'normal',
    },
    outlinedPrimary: {
      backgroundColor: colorPalette.primary,
      color: 'white',
      fontWeight: 'bold',
      opacity: '0.85',
      '&:hover': {
        backgroundColor: colorPalette.primary,
        opacity: '1',
      },
    },
    outlinedSecondary: {
      backgroundColor: colorPalette.secondary20,
      color: 'white',
      fontWeight: 'bold',
      opacity: '0.85',
    },
    containedSecondary: {
      color: colorPalette.secondary80,
      backgroundColor: colorPalette.secondary20,
      '&:hover': {
        backgroundColor: colorPalette.secondary40,
      },
      '&.Mui-disabled': {
        opacity: '.5',
        color: colorPalette.secondary80,
        backgroundColor: colorPalette.secondary20,
      },
    },
  },

  MuiTableContainer: {
    root: {
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        border: '2px solid white',
        borderRadius: '4px',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
      },
    },
  },

  Component: {
    horizontalScrollContainer: {
      flexGrow: 1,
    },
  },
  MuiSvgIcon: {
    root: {
      // color: '#dbdada',
    },
  },
  MuiDialogTitle: {
    root: {
      padding: '0',
      display: 'flex',
    },
  },
};
