import { put, takeLatest } from '@redux-saga/core/effects';
import {
  downloadDocument,
  downloadDocumentByCode,
  getAboutUsMetada,
  getOneResourcesById,
  getResourcesByCode,
  newDataResource,
  removeDocument,
  removeDocumentById,
  saveDataResource,
  uploadNewDocument,
} from '../../api/aboutUs';
import { Await } from '../../api/api';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  deleteDocumentByIdOk,
  deleteDocumentKo,
  deleteDocumentOk,
  downloadDocumentByCodeKo,
  downloadDocumentByCodeOk,
  downloadDocumentKo,
  downloadDocumentOk,
  getInitialResourceKo,
  getInitialResourceOk,
  getMetadataKo,
  getMetadataOk,
  getOneResourcesKo,
  getOneResourcesOk,
  getResourcesKo,
  getResourcesOk,
  newDocumentKo,
  newDocumentOk,
  newResourceKo,
  newResourceOk,
  openDocumentKo,
  openDocumentOk,
  saveResourceKo,
  saveResourceOk,
} from './actions';
import { AboutUsConsts } from './action_types';
import {
  DownloadDocumentAction,
  DownloadDocumentByCodeAction,
  GetInitialResourceAction,
  GetMetadataAction,
  GetOneResourcesAction,
  GetResourcesAction,
  NewDocumentAction,
  NewResourceAction,
  OpenDocumentAction,
  RemoveDocumentAction,
  RemoveDocumentByIdAction,
  SaveResourceAction,
} from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getMetadataSaga(): Generator<any, void, any> {
  try {
    const { data } = (yield getAboutUsMetada()) as Await<ReturnType<typeof getAboutUsMetada>>;
    yield put(getMetadataOk(data));
  } catch (e) {
    yield put(showSnackbar('No se ha podido recuperar la información', 'error'));
    yield put(getMetadataKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getResourcesSaga(action: GetResourcesAction): Generator<any, void, any> {
  try {
    const { data } = (yield getResourcesByCode(action.code)) as Await<ReturnType<typeof getResourcesByCode>>;
    yield put(getResourcesOk(data));
  } catch (e) {
    yield put(showSnackbar('No se han podido recuperar los recursos', 'error'));
    yield put(getResourcesKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getOneResource(action: GetOneResourcesAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const metadata = (yield getAboutUsMetada()) as Await<ReturnType<typeof getAboutUsMetada>>;
    const { data } = (yield getOneResourcesById(id)) as Await<ReturnType<typeof getOneResourcesById>>;
    yield put(getMetadataOk(metadata.data));
    yield put(getOneResourcesOk(data));
  } catch (e) {
    yield put(showSnackbar('No se han podido recuperar el recurso', 'error'));
    yield put(getOneResourcesKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getInitialResource(): Generator<any, void, any> {
  try {
    const { data } = (yield getAboutUsMetada()) as Await<ReturnType<typeof getAboutUsMetada>>;
    yield put(getInitialResourceOk(data));
  } catch (e) {
    yield put(showSnackbar('No se han podido recuperar el recurso', 'error'));
    yield put(getInitialResourceKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* newDocument(action: NewDocumentAction): Generator<any, void, any> {
  try {
    const { file, name } = action;
    const { data } = yield uploadNewDocument(file); /* as Await<ReturnType<typeof object>>; */
    yield put(newDocumentOk(data, name));
    yield put(showSnackbar('Documento creado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('No se ha podido crear el documento', 'error', undefined, 1500));
    yield put(newDocumentKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* downloadResourceDocument(action: DownloadDocumentAction): Generator<any, void, any> {
  try {
    const { documentation_id, name, file_id } = action;
    const data = yield downloadDocument(file_id, documentation_id);
    yield put(downloadDocumentOk(data, name));
    yield put(showSnackbar('El documento se ha descargado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('No se ha podido descargar el documento', 'error', undefined, 1500));
    yield put(downloadDocumentKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* downloadResourceByCode(action: DownloadDocumentByCodeAction): Generator<any, void, any> {
  try {
    const { code } = action;
    const data = (yield downloadDocumentByCode(code)) as Await<ReturnType<typeof downloadDocumentByCode>>;
    if (data.size < 3) {
      yield put(showSnackbar('No se ha encontrado el documento', 'warning', undefined, 1500));
    } else {
      yield put(downloadDocumentByCodeOk(data, action.name));
    }
  } catch (e) {
    yield put(showSnackbar('No se ha podido descargar el documento', 'error', undefined, 1500));
    yield put(downloadDocumentByCodeKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* openResourceDocument(action: OpenDocumentAction): Generator<any, void, any> {
  try {
    const { resource_id, file_id } = action;
    const data = yield downloadDocument(file_id, resource_id);
    yield put(openDocumentOk(data));
  } catch (e) {
    yield put(showSnackbar('No se ha podido abrir el documento', 'error'));
    yield put(openDocumentKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* deleteResourceDocument(action: RemoveDocumentAction): Generator<any, void, any> {
  try {
    const { resource_id, file_id } = action;
    const documents = yield removeDocument(file_id, resource_id);
    yield put(deleteDocumentOk(documents.documents));
    yield put(showSnackbar('Documento eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('No se ha podido eliminar el documento', 'error', undefined, 1500));
    yield put(deleteDocumentKo(e));
  }
}

function* deleteResourceDocumentById(action: RemoveDocumentByIdAction): Generator<any, void, any> {
  try {
    const { file_id, documents } = action;
    yield removeDocumentById(file_id);
    const newDocuments = documents.filter(item => item.file_id !== file_id);
    yield put(deleteDocumentByIdOk(newDocuments));
    yield put(showSnackbar('Documento eliminado correctamente', 'success', undefined, 1500));
  } catch (e) {
    yield put(showSnackbar('No se ha podido eliminar el documento', 'error', undefined, 1500));
    yield put(deleteDocumentKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* saveResource(action: SaveResourceAction): Generator<any, void, any> {
  try {
    const { resource, redirect } = action;
    const data = yield saveDataResource(resource);
    yield put(showSnackbar('Recuso actualizado correctamente', 'success', redirect, 1500));
    yield put(saveResourceOk(data));
  } catch (e) {
    yield put(showSnackbar('No se ha podido actualizar el recurso', 'error'));
    yield put(saveResourceKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* newResource(action: NewResourceAction): Generator<any, void, any> {
  try {
    const { resource: request, redirect } = action;
    yield newDataResource(request);
    yield put(showSnackbar('Recuso creado correctamente', 'success', redirect, 1500));
    yield put(newResourceOk());
  } catch (e) {
    yield put(showSnackbar('No se ha podido crear el recurso', 'error', undefined, 1500));
    yield put(newResourceKo(e));
  }
}

export default [
  takeLatest<GetMetadataAction>(AboutUsConsts.ABOUTUS_METADATA, getMetadataSaga),
  takeLatest<GetResourcesAction>(AboutUsConsts.ABOUTUS_RESOURCES, getResourcesSaga),
  takeLatest<GetOneResourcesAction>(AboutUsConsts.ABOUTUS_ONE_RESOURCES, getOneResource),
  takeLatest<NewDocumentAction>(AboutUsConsts.ABOUTUS_NEW_DOCUMENT, newDocument),
  takeLatest<DownloadDocumentAction>(AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT, downloadResourceDocument),
  takeLatest<DownloadDocumentByCodeAction>(AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE, downloadResourceByCode),
  takeLatest<OpenDocumentAction>(AboutUsConsts.ABOUTUS_OPEN_DOCUMENT, openResourceDocument),
  takeLatest<RemoveDocumentAction>(AboutUsConsts.ABOUTUS_DELETE_DOCUMENT, deleteResourceDocument),
  takeLatest<RemoveDocumentByIdAction>(AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID, deleteResourceDocumentById),

  takeLatest<SaveResourceAction>(AboutUsConsts.ABOUTUS_SAVE_RESOURCE, saveResource),
  takeLatest<NewResourceAction>(AboutUsConsts.ABOUTUS_NEW_RESOURCE, newResource),
  takeLatest<GetInitialResourceAction>(AboutUsConsts.ABOUTUS_INITIAL_RESOURCE, getInitialResource),
];
