import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getDirectoryMetadataFromApi } from '../../../api/Directory/directory';
import Loading from '../../../components/Loading/Loading';
import { debounce } from '../../../helpers/debounce';
import { tKey } from '../../../helpers/translate';
import { cleanUserData, getDirectory } from '../../../redux/directory/action';
import {
  DirectoryDataDispatch,
  DirectoryMetadata,
  FilterInputDirectory,
  FilterInputDirectorySend,
  FilterInputTextField,
  InputDirectory,
  ItemMetadata,
  Pagination,
  QueryUsers,
} from '../../../redux/directory/definitions';
import { AppState } from '../../../redux/root-reducer';
import styles from './directoryFilter.module.scss';
import FilterInput from './FilterInput/FilterInput';
import SelectAutocomplete from './SelectAutocomplete/SelectAutocomplete';
import SelectedItems from './SelectedItems/SelectedItems';

export interface FilterInputs {
  poblation: string;
  province: string;
  academic_course: string;
  category: string;
  family_pro: string;
  age: number[];
  name: string;
  country_origin: string;
  educational_level: number;
  educational_type: number;
  entity_id: number;
  scholarship_academy: number;
  scholarship_course: number;
  last_scholarship_academy: number;
  is_studying: boolean;
  company_name: string;
  employee_role: string;
}

interface DirectoryFilterProps {
  pagination: Pagination;
  getDirectory: (data: QueryUsers) => void;
  cleanUserData: () => void;
}

const AddSuggestion = React.lazy(() => import('../Modal/AddSuggestion'));

const DirectoryFilter: React.FC<DirectoryFilterProps> = ({ pagination, getDirectory, cleanUserData }) => {
  const [metadata, setMetadata] = useState({} as DirectoryMetadata);
  const [selectedData, setSelectedData] = useState({} as FilterInputDirectory);
  const [selectedDataInputs, setSelectedDataInputs] = useState({} as FilterInputTextField);
  const [suggestionView, setSuggestionView] = useState(false);
  const [inputsValues, setInputsValues] = useState({} as FilterInputTextField);
  const [inputAgeRange, setInputAgeRange] = useState({} as ItemMetadata[]);

  const getMetadata = () => {
    if (!Object.keys(metadata).length) getDirectoryMetadataFromApi().then(({ data }) => setMetadata(data));
  };

  const ageRange = (min: number, max: number) => {
    const data = [] as ItemMetadata[];
    for (min; min <= max; ++min) {
      data.push({
        id: min,
        code: min.toString(),
        value: min.toString(),
      });
    }
    return data as ItemMetadata[];
  };

  useEffect(() => {
    setInputAgeRange(ageRange(15, 30));
    getMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const arrReduce = (obj: FilterInputDirectory) =>
    Object.entries(obj).reduce((acc, [key, obj]) => {
      if (key && obj) {
        const { fieldToSend } = obj;
        acc[key as InputDirectory] = fieldToSend;
      }
      return acc;
    }, {} as FilterInputDirectorySend);

  useEffect(() => {
    getDirectory({ ...pagination, ...arrReduce(selectedData), ...selectedDataInputs });
  }, [pagination, selectedData, selectedDataInputs,getDirectory]);

  const handleOnSelectData = (key: keyof FilterInputDirectory, value: string, fieldToSend: string | number) => {
    setSelectedData(prevState => ({ ...prevState, [key]: { value, fieldToSend } }));
    cleanUserData();
  };

  const handleOnChangeInputSaved = useCallback(
    debounce((key: keyof FilterInputTextField, value: string) => {
      setSelectedDataInputs(prevState => ({ ...prevState, [key]: value }));
      cleanUserData();
    }, 500),
    [],
  );

  const handleOnChangeInput = (key: keyof FilterInputTextField, value: string) => {
    setInputsValues(prevState => ({ ...prevState, [key]: value }));
    handleOnChangeInputSaved(key, value);
  };

  const handleCleanSelectedItems = (key?: keyof FilterInputDirectory) => {
    cleanUserData();

    if (key) {
      setSelectedData(prevState => {
        const { [key]: remove, ...prev } = prevState;
        return prev;
      });
    } else {
      setSelectedData({});
    }
  };

  const handleCleanAllData = () => {
    setInputsValues({});
    setSelectedDataInputs({});
    setSelectedData({});
    cleanUserData();
  };

  const handleCheckSelected = (key: any) => selectedData.hasOwnProperty(key);

  if (!metadata.poblacion?.length) return <Loading big />;

  return (
    <div className={styles.root}>
      <div className={styles.selectAutocompleteRow}>
        <SelectAutocomplete
          label={tKey('Edad')}
          name="age"
          metadata={inputAgeRange}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('age')}
          propertyToSend={'value'}
        />
        <SelectAutocomplete
          label={tKey('Ciudad')}
          name="poblation"
          metadata={metadata.poblacion}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('poblation')}
          propertyToSend={'value'}
        />
        <SelectAutocomplete
          label={tKey('País de orígen')}
          name="country_origin"
          metadata={metadata.countries}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('country_origin')}
          propertyToSend={'value'}
        />
        <SelectAutocomplete
          label={tKey('Curso académico')}
          name="academic_course"
          metadata={metadata.academic_years}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('academic_course')}
          propertyToSend={'value'}
        />
        <SelectAutocomplete
          label={tKey('Nivel del curso')}
          name="educational_level"
          metadata={metadata.educationals_levels}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('educational_level')}
          propertyToSend={'id'}
        />
        <SelectAutocomplete
          label={tKey('Tipo de curso')}
          name="educational_type"
          metadata={metadata.educational_types}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('educational_type')}
          propertyToSend={'id'}
        />
        <SelectAutocomplete
          label={tKey('Nombre de estudios')}
          name="scholarship_course"
          metadata={metadata.scholarship_courses}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('scholarship_course')}
          propertyToSend={'id'}
        />
        <SelectAutocomplete
          label={tKey('Centro de estudios')}
          name="scholarship_academy"
          metadata={metadata.academies}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('scholarship_academy')}
          propertyToSend={'id'}
        />
        <SelectAutocomplete
          label={tKey('Entidad')}
          name="entity_id"
          metadata={metadata.entities}
          onSelectItem={handleOnSelectData}
          selected={handleCheckSelected('entity_id')}
          propertyToSend={'id'}
        />
      </div>
      <div className={styles.inputsRow}>
        <FilterInput
          value={inputsValues.name || ''}
          name="name"
          label="Nombre Completo"
          onChangeValue={handleOnChangeInput}
        />
        {/*         <FilterInput
          value={inputsValues.company_name || ''}
          name="company_name"
          label="Empresa"
          onChangeValue={handleOnChangeInput}
        />
        <FilterInput
          value={inputsValues.employee_role || ''}
          name="employee_role"
          label="Cargo en la empresa"
          onChangeValue={handleOnChangeInput}
        /> */}
        <div className={styles.inputsRow__buttons}>
          <button className={styles.clearFilterButton} onClick={handleCleanAllData}>
            {tKey('Vaciar filtros')}
          </button>
          <button className={styles.addSuggestion} onClick={() => setSuggestionView(true)}>
            {tKey('Buzón de quejas')}
          </button>
        </div>
      </div>
      <div className={styles.selectedItemsRow}>
        <SelectedItems data={selectedData} onCleanValue={handleCleanSelectedItems} />
      </div>
      <Suspense fallback={<></>}>
        <AddSuggestion
          title={tKey('Buzón de quejas')}
          view={suggestionView}
          buttonLabel={tKey('Enviar')}
          handleClose={() => {
            setSuggestionView(false);
          }}
        />
      </Suspense>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  pagination: state.directoryReducer.pagination,
});

const directoryDispatchToProps = (dispatch: DirectoryDataDispatch) => ({
  getDirectory: (data: QueryUsers): void => dispatch(getDirectory(data)),
  cleanUserData: (): void => dispatch(cleanUserData()),
});

export default connect(mapStateToProps, directoryDispatchToProps)(DirectoryFilter);
