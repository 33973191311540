export class SignatureDocumentStatus {
  private value: string;

  private constructor(value: string) {
    this.value = value;
  }

  static CREADO: SignatureDocumentStatus = new SignatureDocumentStatus('Creado');
  static PENDIENTE: SignatureDocumentStatus = new SignatureDocumentStatus('Pendiente');
  static CANCELADO: SignatureDocumentStatus = new SignatureDocumentStatus('Cancelado');
  static ERROR: SignatureDocumentStatus = new SignatureDocumentStatus('Error');
  static COMPLETADO: SignatureDocumentStatus = new SignatureDocumentStatus('Completado');

  static throwExceptionForInvalidValue(value: string): never {
    throw new Error(`SignatureDocumentStatus is invalid. Value: ${value}`);
  }

  static creado(): SignatureDocumentStatus {
    return SignatureDocumentStatus.CREADO;
  }

  static pendiente(): SignatureDocumentStatus {
    return SignatureDocumentStatus.PENDIENTE;
  }

  static cancelado(): SignatureDocumentStatus {
    return SignatureDocumentStatus.CANCELADO;
  }

  static error(): SignatureDocumentStatus {
    return SignatureDocumentStatus.ERROR;
  }

  static completado(): SignatureDocumentStatus {
    return SignatureDocumentStatus.COMPLETADO;
  }

  isCreado(): boolean {
    return this.value === SignatureDocumentStatus.CREADO.value;
  }

  isCancelado(): boolean {
    return this.value === SignatureDocumentStatus.CANCELADO.value;
  }

  isPendiente(): boolean {
    return this.value === SignatureDocumentStatus.PENDIENTE.value;
  }

  isError(): boolean {
    return this.value === SignatureDocumentStatus.ERROR.value;
  }

  isCompletado(): boolean {
    return this.value === SignatureDocumentStatus.COMPLETADO.value;
  }

  toString(): string {
    return this.value;
  }
}
