import { AppState } from '../../root-reducer';
import { Educator, EducatorId } from './Educator';
import { EducatorState } from './definition.d';

const selectEducator = (state: AppState): EducatorState => state.educatorReducer;

export const selectEducatorLoading = (state: AppState): boolean => selectEducator(state).loading;

export const selectEducatorError = (state: AppState): string | null => selectEducator(state).error;

export const selectEducatorsList = (state: AppState): Educator[] => selectEducator(state).data;

export const selectEducatorById = (state: AppState, id: EducatorId): Educator =>
  selectEducatorsList(state).filter(item => item.id === id)[0];

const selectDeleteEducator = (state: AppState): EducatorState => state.educatorReducer;

export const selectDeleteEducatorLoading = (state: AppState): boolean => selectDeleteEducator(state).loading;

export const selectDeleteEducatorError = (state: AppState): string | null => selectDeleteEducator(state).error;

export const selectUpdateEducator = (state: AppState): EducatorState => state.educatorReducer;

export const selectUpdateEducatorLoading = (state: AppState): boolean => selectUpdateEducator(state).loading;

export const selectUpdateEducatorError = (state: AppState): string | null => selectUpdateEducator(state).error;
