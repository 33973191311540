import React, { ReactElement, useCallback } from 'react';

import { FieldValues } from 'react-hook-form/dist/types';
import FncAddDocuments from '../../../../../components/UploadFile/FncAddDocuments';
import { DocumentationItem } from '../../../../../redux/common/definitions';
import FollowUpCode from '../../../../../types/Scholarships/followUpCode';
import { tScholarshipKey } from '../../../../../helpers/translate';

interface AcademicResultGradesDocProps<T extends FieldValues> {
  scholarship_id: number;
  follow_up_id: number;
  documentationUN: DocumentationItem[];
  readOnly: boolean;
  required: boolean;
  followUpCode: FollowUpCode;
  downloadDocument?: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile?: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument?: (documentation_id: number, scholarship_id: number) => void;
  addDoc?: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
}

const AcademicResultGradesDoc = <T extends FieldValues>({
  scholarship_id,
  follow_up_id,
  documentationUN,
  readOnly,
  required,
  followUpCode,
  downloadDocument,
  getFile,
  deleteDocument,
  addDoc,
}: AcademicResultGradesDocProps<T>): ReactElement => {
  const actions = ['upload', 'checked', 'download', 'view', 'remove'];

  const handleUploadDocument = (documentation_id: number, data: File, scholarshipId: number) => {
    addDoc && addDoc(data, 'description', scholarshipId, documentation_id);
  };

  const filterTrackingDocument = useCallback(() => {
    const trackingDoc = documentationUN.filter(doc => doc?.tracking_id && doc.tracking_id === follow_up_id);
    if (trackingDoc.length) return trackingDoc;
    const currentDoc = documentationUN.filter(doc => !doc?.tracking_id);
    if (currentDoc.length) return currentDoc;
    return [{ ...documentationUN[0], file_id: null, filename: null }];
  }, [documentationUN, follow_up_id]);

  return (
    <FncAddDocuments
      //@ts-ignore
      documents={filterTrackingDocument()}
      actions={actions}
      genericId={scholarship_id}
      getFile={getFile}
      upload={handleUploadDocument}
      download={downloadDocument}
      remove={deleteDocument}
      addDoc={addDoc}
      disabled={readOnly}
      required={required}
      multidocument
      subtitle={!followUpCode.isLastFollowUp ? tScholarshipKey('último-boletín-notas') : undefined}
    />
  );
};

export default AcademicResultGradesDoc;
