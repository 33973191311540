import React, { useState } from 'react';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import { tErrorKey, tKey } from '../../helpers/translate';
import { ActionsDocument, DocumentationItem } from '../../redux/common/definitions';
import FormContainer from '../Forms/FormContainer';
import TransitionModal from '../Modal/TransitionModal';
import SnackBar from '../SnackBar/Snackbar';
import AddDocuments from '../Tables/DocumentationForm/AddDocuments';
import MultiDocumentModalPicker from '../Tables/DocumentationForm/MultiDocumentModalPicker';
import NewDocumentModal from '../Tables/DocumentationForm/NewDocumentModal';
import FilesList from './FilesList';
import styles from './addDocuments.module.scss';

const FILES_ACCEPTED_DEFAULT = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'tiff', 'jpeg', 'odt', 'png', 'jpg'];
const ACTIONS_DEFAULT = ['checked', 'upload', 'download', 'view', 'remove'];

export interface AddDocumentsProps {
  documents: DocumentationItem[];
  actions?: string[];
  filesOk?: string[];
  genericId?: any;
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  upload?: (documentation_id: number, data: File, id?: any, multiDocument?: boolean) => void;
  uploadAnonymous?: (file: File, documentation_id: number) => void;
  download?: (file_id: number, name: string, generic_id?: any) => void;
  remove?: (file_id: number, generic_id?: any, fieldName?: string, documentation_id?: number) => void;
  getFile?: (file_id: number, name: string, generic_id?: any) => void;
  addDoc?: (document: File, description: string, genericId?: any, documentationId?: number, fieldName?: string) => void;
  addRow?: boolean;
  multidocument?: boolean;
  multidocumentLabel?: boolean;
  infoMessage?: string;
  required?: boolean;
  requiredMultiDocument?: boolean;
  originalName?: boolean;
  fieldName?: string;
}

const FncAddDocuments: React.FC<AddDocumentsProps> = props => {
  const {
    documents,
    actions,
    filesOk,
    genericId,
    title,
    subtitle,
    disabled,
    upload,
    uploadAnonymous,
    download,
    remove,
    getFile,
    addDoc,
    addRow,
    multidocument,
    infoMessage,
    required,
    requiredMultiDocument,
    originalName,
    fieldName,
  } = props;
  const [viewError, setViewError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [documentationID, setDocumentationID] = useState(0);
  const filesAccepted = filesOk ? filesOk : FILES_ACCEPTED_DEFAULT;
  const actionsVisibility = actions ? actions : ACTIONS_DEFAULT;

  const onUpload = (fileFormat: string, file: File, rowData: DocumentationItem) => {
    if (filesAccepted.includes(fileFormat)) {
      upload && upload(rowData.documentation_id, file, genericId, multidocument);
      uploadAnonymous && uploadAnonymous(file, rowData.documentation_id);
    } else {
      setViewError(true);
    }
  };

  const onOpenModal = (rowData: DocumentationItem) => {
    setDocumentationID(rowData.documentation_id);
    setShowModal(true);
  };

  const onDownload = (rowData: DocumentationItem) => {
    if (rowData.file_id !== null && download) {
      const fileName = originalName ? rowData.original_name : rowData.filename;
      download(rowData.file_id, fileName, genericId);
    }
  };
  const onRemoveFile = (rowData: DocumentationItem) => {
    if (rowData.file_id !== null && !disabled && remove) {
      remove(rowData.file_id, genericId, fieldName, rowData.documentation_id);
    }
  };
  const onGetFile = (rowData: DocumentationItem) => {
    if (rowData.file_id && getFile) {
      getFile(rowData.file_id, rowData.filename, genericId);
    }
  };

  const onAddDocument = (document: File, description: string) => {
    if (addDoc) {
      addDoc(document, description, genericId, documentationID, fieldName);
    }
  };

  const isMobile = useWindowSize().IsMobile;
  return (
    <>
      {!isMobile ? (
        <AddDocuments {...props} actions={actionsVisibility} />
      ) : (
        <>
          <FormContainer title={title || ''}>
            {subtitle && <p className={styles.description}>{subtitle}</p>}
            <FilesList
              documents={documents}
              actions={actionsVisibility as ActionsDocument[]}
              disabled={!!disabled}
              addRow={!!addRow}
              multiDocument={!!multidocument}
              required={!!required}
              requiredMultiDocument={!!requiredMultiDocument}
              addMoreDocuments={!!addDoc}
              onUpload={onUpload}
              onOpenModal={onOpenModal}
              onDownload={onDownload}
              onRemoveFile={onRemoveFile}
              onGetFile={onGetFile}
              setShowModal={setShowModal}
            />
            <p className={styles.textLegend}>
              {tKey('Los formatos soportados son')} : {filesAccepted.map(str => `${str}`).join(', ')}
            </p>
            {infoMessage && (
              <div className={styles.footnote}>
                <span className={styles.docInfo}>* {infoMessage}</span>
              </div>
            )}
          </FormContainer>
          <TransitionModal
            view={showModal}
            handleClose={() => setShowModal(false)}
            title={tKey('Agregar documento')}
            bodyModal=""
            documentMode
          >
            {!multidocument ? (
              <NewDocumentModal addDoc={onAddDocument} handleClose={() => setShowModal(false)} />
            ) : (
              <MultiDocumentModalPicker addDoc={onAddDocument} handleClose={() => setShowModal(false)} />
            )}
          </TransitionModal>
          <SnackBar
            open={viewError}
            handleClose={() => {
              setViewError(false);
            }}
            message={tErrorKey('Formato de fichero no válido')}
            severity="error"
          />
        </>
      )}
    </>
  );
};
export default FncAddDocuments;
