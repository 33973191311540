import { Button, Chip, SvgIcon } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import KeyboardDatePickerController from '../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../components/Forms/FormContainer';
import TextInputController from '../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../components/Loading/Loading';
import { DATE_FORMAT } from '../../../constants/date';
import useValidatorAPI from '../../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../../helpers/translate';
import { ReactComponent as excelIcon } from '../../../images/icons/excel.svg';
import { ReactComponent as pdfIcon } from '../../../images/icons/pdf.svg';
import { ReactComponent as wordIcon } from '../../../images/icons/word.svg';
import {
  addContract,
  createNewContract,
  downloadContractDocument,
  editContract,
  getContract,
  removeContractDocument,
  setContractData,
  uploadContractDocument,
} from '../../../redux/entity/actions';
import { EntityContract } from '../../../redux/entity/definitions';
import {
  selectContractData,
  selectIsLoading,
  selectLoadingContract,
  selectValidationErrors,
} from '../../../redux/entity/selectors';
import { selectEditDeleteModalView } from '../../../redux/project/selectors';
import { AppState } from '../../../redux/root-reducer';
import { ContractSchema } from '../../../validations/formSchema';
import styles from './contract.module.scss';

interface ContractProps {
  loading: boolean;
  contractData: EntityContract;
  entityId: number;
  contractId?: number;
  loadingContract: boolean;
  readOnly?: boolean;
  validationErrors: APIValidationError | null;
  getContract: (id: number) => void;
  addContract: (entity_id: number) => void;
  createNewContract: (newContract: EntityContract) => void;
  editContract: (data: EntityContract) => boolean;
  setData: (key: string, value: string | number) => void;
  uploadContractDocument: (file: File) => void;
  removeContractDocument: (file_id: number) => void;
  downloadContractDocument: (file_id: number, filename: string) => void;
}

const Contract: React.FC<ContractProps> = ({
  contractData,
  entityId,
  contractId,
  loadingContract,
  readOnly,
  validationErrors,
  addContract,
  getContract,
  editContract,
  createNewContract,
  setData,
  uploadContractDocument,
  removeContractDocument,
  downloadContractDocument,
}) => {
  const { handleSubmit, errors, control, setError } = useForm<EntityContract>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: ContractSchema,
  });

  useValidatorAPI(validationErrors, setError);

  const { contract_number, start_date, end_date, file_id, filename } = contractData;
  const fileInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (contractId) {
      getContract(contractId);
    } else {
      addContract(entityId);
    }
  }, [getContract]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeDate = (name: string, date: moment.Moment | null) => {
    if (date) {
      setData(name, moment.utc(date || '0', DATE_FORMAT).format('X'));
    } else {
      setData(name, 0);
    }
  };

  const asingDateValue = (date: number | null) => {
    return date ? moment.unix(date) : null;
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      const file = e.target.files[0];
      uploadContractDocument(file);
    }
  };

  const onSave = () => {
    if (!contractId) {
      createNewContract(contractData);
    } else {
      editContract(contractData);
    }
  };

  const colorize = (name: string) => {
    const res = name.substr(name.lastIndexOf('.') + 1);

    switch (res) {
      case 'pdf':
        return <SvgIcon component={pdfIcon} viewBox="0 0 600 476.6" />;
      case 'docx':
        return <SvgIcon component={wordIcon} viewBox="0 0 600 476.6" />;
      case 'xlsx':
        return <SvgIcon component={excelIcon} viewBox="0 0 600 476.6" />;
      default:
        return <ImageIcon />;
    }
  };

  let missingFileError;
  if (errors && errors.file_id) {
    missingFileError = <label className={styles.helperText}>{errors.file_id.message}</label>;
  }

  const fileView =
    file_id && filename ? (
      <Chip
        icon={colorize(filename)}
        size="medium"
        label={filename}
        clickable
        onClick={() => downloadContractDocument(file_id, filename)}
        onDelete={() => removeContractDocument(file_id)}
        className={styles.chip}
        disabled={readOnly}
      />
    ) : (
      <div className={styles.fileNameContainer}>
        <input
          id="file"
          ref={fileInputRef}
          className={styles.FileInput}
          type="file"
          multiple
          onChange={onUpload}
          accept="application/pdf,image/*,.xlsx,.docx,.doc,.xls"
          style={{ display: 'none' }}
          disabled={readOnly}
        />
        <div className={styles.buttonUpload}>
          {!file_id && (
            <label htmlFor="file">
              <Button variant="contained" color="secondary" component="span" disabled={readOnly} fullWidth>
                {tKey('Adjuntar archivo')}
              </Button>
            </label>
          )}
        </div>
        {missingFileError}
        {}
      </div>
    );

  let saveButton;
  if (!readOnly) {
    saveButton = (
      <Button variant="outlined" color="primary" style={{ marginLeft: '10px', width: '120px' }} type="submit">
        {tFormKey('Guardar')}
      </Button>
    );
  }

  if (loadingContract) return <Loading big />;

  return (
    <div className={styles.contractContainer}>
      <form onSubmit={handleSubmit(onSave)}>
        <FormContainer title="">
          <TextInputController
            control={control}
            errors={errors}
            type="text"
            defaultValue={contract_number || ''}
            schema={ContractSchema}
            label={tFormKey('Número de contrato')}
            name="contract_number"
            size="50"
            disabled={readOnly}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('contract_number', e.target.value)}
          />
          <div className={styles.dateContainer}>
            <KeyboardDatePicker
              style={{ marginRight: '10px' }}
              error={!!errors.start_date}
              variant="inline"
              inputVariant="outlined"
              name="start_date"
              label={tFormKey('Fecha inicio')}
              placeholder="" // Prevent google to detect this input as a credit card number
              value={asingDateValue(start_date)}
              onChange={date => {
                date && changeDate('start_date', date);
              }}
              autoOk
              disabled={readOnly}
              format={DATE_FORMAT}
            />
            <KeyboardDatePickerController
              label={tFormKey('Fecha fin')}
              name="end_date"
              control={control}
              schema={ContractSchema}
              defaultValue={end_date || null}
              errors={errors}
              minDate={asingDateValue(start_date)}
              onAccept={date => changeDate('end_date', date)}
            />
          </div>
          {fileView}
          {saveButton}
        </FormContainer>
      </form>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  contractData: selectContractData(state),
  loadingContract: selectLoadingContract(state),
  editDeleteView: selectEditDeleteModalView(state),
  validationErrors: selectValidationErrors(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  createNewContract: (newContract: EntityContract) => dispatch(createNewContract(newContract)),
  getContract: (id: number) => dispatch(getContract(id)),
  setData: (key: string, value: string | number) => dispatch(setContractData(key, value)),
  addContract: (entity_id: number) => dispatch(addContract(entity_id)),
  editContract: (data: EntityContract) => dispatch(editContract(data)),
  uploadContractDocument: (file: File) => dispatch(uploadContractDocument(file)),
  removeContractDocument: (file_id: number) => dispatch(removeContractDocument(file_id)),
  downloadContractDocument: (file_id: number, filename: string) =>
    dispatch(downloadContractDocument(file_id, filename)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
