import { ParticipantParticularitiesDataAction, ParticipantParticularitiesState } from './definitions';
import { ParticipantParticularitiesConst } from './action_types';
import assertUnreachable from '../../../../helpers/assertUnreachable';
import { initialState } from './definitions';

const participantParticularitiesReducer = (
  state = initialState,
  action: ParticipantParticularitiesDataAction,
): ParticipantParticularitiesState => {
  switch (action.type) {
    case ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES:
      return {
        ...state,
        loading: true,
      };
    case ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES_OK:
      return { ...state, data: action.data, loading: false, error: null };
    case ParticipantParticularitiesConst.GET_PARTICIPANT_PARTICULARITIES_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default participantParticularitiesReducer;
