import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Loading from '../../../components/Loading/Loading';
import { tFormKey } from '../../../helpers/translate';
import { DocumentationItem } from '../../../redux/common/definitions';
import { EntityInfo } from '../../../redux/entity/definitions';
import { selectGetOneEntity, selectIsLoading } from '../../../redux/entity/selectors';
import { AppState } from '../../../redux/root-reducer';
import { entityFormSchema } from '../../../validations/Entities/entityFormSchema';
import EntityForm from './EntityForm';

interface DetailsEntityPorps {
  loading: boolean;
  documentation: DocumentationItem[];
  entityData: EntityInfo;
}

const DetailsEntity: React.FC<DetailsEntityPorps> = ({ loading, documentation, entityData }) => {
  const entitySchema = entityFormSchema(!!entityData.is_visa_provider, !!entityData.no_payments_entity);
  const { register, setValue, watch, errors, triggerValidation, control, setError, clearError } = useForm<EntityInfo>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: entitySchema,
  });

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <EntityForm
          entitySchema={entitySchema}
          title={tFormKey('Detalle de Entidad')}
          useForm={{ register, setValue, watch, errors, triggerValidation, control, setError, clearError }}
          disabled
          documentation={documentation}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  entityData: selectGetOneEntity(state),
});

export default connect(mapStateToProps)(DetailsEntity);
