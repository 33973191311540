import moment from 'moment';
import { Fields } from '../../components/GenericForm/GenericFormRefactor';
import assertUnreachable from '../../helpers/assertUnreachable';
import errorMessage from '../../helpers/errorMessage';
import { getDocs } from '../../helpers/getDocs';
import { IdNamePair, ResourceImageData } from '../common/definitions';
import { CustomFieldDocument } from '../kpis/definitions';
import { GenericData } from '../project/definitions';
import { InternalProjectsConst } from './action_types';
import {
  DailyFollows,
  DailyFollowsForms,
  DailyFollowsTable,
  Follows,
  InternalProjectDataAction,
  InternalProjectEnrollment,
  ParticipantesFollowUpList,
  initialState,
} from './definitions';

export type InternalProjectsState = typeof initialState;

const internalProjectReducer = (state = initialState, action: InternalProjectDataAction): InternalProjectsState => {
  switch (action.type) {
    case InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY:
    case InternalProjectsConst.CREATE_INTERNAL_PROJECTS:
    case InternalProjectsConst.GET_INTERNAL_PROJECT:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY:
    case InternalProjectsConst.GET_SETTINGS:
    case InternalProjectsConst.DELETE_INTERNAL_PROJECT:
    case InternalProjectsConst.GET_IP_STUDENT_EVALUATION:
      return {
        ...state,
        loading: true,
      };
    case InternalProjectsConst.SET_LATEST_SUBAREA:
      return {
        ...state,
        latestInternalProjectSubArea: action.setterData,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_FOLLOWS:
      return {
        ...state,
        baseCampData: {
          project_key: '',
          pi_seguimiento_inicial: { fields: {} as Fields, participante: {} as GenericData },
        },
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW:
    case InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM:
    case InternalProjectsConst.GET_BASE_CAMP:
    case InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM:
    case InternalProjectsConst.GET_SECTION_TEMPLATE:
    case InternalProjectsConst.GET_STUDENT_EVALUATION_INFO:
    case InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST:
      return {
        ...state,
        loadingAlt: true,
      };
    case InternalProjectsConst.SEND_FOLLOW_UP_OK:
      const { follow_up_id } = action;
      const followUp = state.follows.find((f: Follows) => f.id === follow_up_id);
      if (followUp) {
        followUp.completed = true;
      }
      return { ...state, loadingAlt: false };
    case InternalProjectsConst.UPDATE_FOLLOW_UP_OK:
      return { ...state, loadingAlt: false, participante: { ...state.participante, rows: action.participante.rows } };
    case InternalProjectsConst.GET_SECTION_TEMPLATE_OK:
      return { ...state, fields: action.fields, participante: action.participante, loadingAlt: false };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_FOLLOWS_OK:
      return { ...state, follows: action.follows };
    case InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST_OK:
      return { ...state, loadingAlt: false, participantesFollowUpList: [...action.participantesFollowUpList] };
    case InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM_OK:
      return { ...state, loadingAlt: false, followUpSixMonthsData: action.followUpSixMonthsData };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS_OK:
      return {
        ...state,
        loadingAlt: false,
        dailyFollows: action.dailyFollows,
        error: null,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_OK:
      return {
        ...state,
        loadingAlt: false,
        dailyFollow: action.dailyFollow,
        documentations_daily_follow: action.dailyFollow.documentations,
        error: null,
      };
    case InternalProjectsConst.ADD_NEW_REGISTER:
      const generateID = () => {
        return Math.floor(100000000 + Math.random() * 900000000);
      };

      const obj = { id: null, id_generated: generateID(), isEditMode: true };
      state.participante.columns.forEach(element => {
        //@ts-ignore
        obj[element.name] = '';
      });

      return {
        ...state,
        participante: {
          ...state.participante,
          rows: [...state.participante.rows, obj],
        },
      };
    case InternalProjectsConst.SET_REGISTER:
      return { ...state, participante: { ...state.participante, rows: action.setterData } };

    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_OK:
      return {
        ...state,
        loading: false,
        error: null,
        internalProjectData: action.internalProject,
      };
    case InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE_OK:
      return {
        ...state,
        loading: false,
        error: null,
        internalProjectSubareaTypes: action.internalProjectSubareaTypes,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOWS_KO:
    case InternalProjectsConst.GET_SECTION_TEMPLATE_KO:
    case InternalProjectsConst.SEND_FOLLOW_UP_KO:
    case InternalProjectsConst.UPDATE_FOLLOW_UP_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS_KO:
    case InternalProjectsConst.GET_SETTINGS_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE_KO:
    case InternalProjectsConst.CREATE_INTERNAL_PROJECTS_KO:
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_KO:
    case InternalProjectsConst.GET_PARTICIPANTES_FOLLOWUP_LIST_KO:
    case InternalProjectsConst.GET_FOLLOWUP_SIX_MONTHS_FORM_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY_KO:
    case InternalProjectsConst.ADD_MEMBERS_KO:
    case InternalProjectsConst.DELETE_MEMBERS_KO:
    case InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT_KO:
    case InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP_KO:
    case InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT_KO:
    case InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT_KO:
    case InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT_KO:
    case InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT_KO:
    case InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_KO:
    case InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL_KO:
    case InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_KO:
    case InternalProjectsConst.GET_METADATA_KO:
    case InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO:
    case InternalProjectsConst.STORE_DAILY_FOLLOW_KO:
    case InternalProjectsConst.DELETE_DAILY_FOLLOW_KO:
    case InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_KO:
    case InternalProjectsConst.DELETE_INTERNAL_PROJECT_KO:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_KO:
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS_KO:
    case InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM_KO:
    case InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO:
    case InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_KO:
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS_KO:
    case InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE_KO:
      return {
        ...state,
        loadingAlt: false,
        error: action.error,
      };
    case InternalProjectsConst.DELETE_DOCUMENTS_FOLLOW_UP_OK:
      const _document = state.fields.documents.reduce((acc, curr) => {
        if (curr.name === action.inputName) {
          const value_file = curr.value_file.filter(file => file.file_id !== action.file_id);
          curr.value_file = value_file;
        }
        acc.push(curr);
        return acc;
      }, [] as CustomFieldDocument[]);

      return {
        ...state,
        fields: {
          elements: state.fields.elements,
          documents: _document,
        },
      };
    case InternalProjectsConst.GET_PROJECT_ALLOWED_USERS_OK:
      return {
        ...state,
        projectAllowedUsers: action.projectAllowedUsers,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_SUBAREAS_OK:
      return {
        ...state,
        internalProjectSubAreas: action.internalProjectSubAreas,
        loading: false,
      };
    case InternalProjectsConst.GET_SETTINGS_OK:
      return { ...state, internalProjectSettings: action.internalProjectSettings, loading: false };
    case InternalProjectsConst.UPDATE_FOLLOW_UP_DATE_OK:
      const follows = state.follows.map(follow => {
        if (follow.id === action.internalProjectFollowId) {
          follow.follow_date = action.follow_date;
        }
        return follow;
      });

      return {
        ...state,
        follows,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_ACADEMY_OK:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_OK:
    case InternalProjectsConst.DELETE_INTERNAL_PROJECT_OK:
      return {
        ...state,
        internalProjects: action.internalProject,
        loading: false,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE:
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT:
    case InternalProjectsConst.ADD_MEMBERS:
    case InternalProjectsConst.GET_PROJECT_ALLOWED_USERS:
    case InternalProjectsConst.DELETE_MEMBERS:
    case InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT:
    case InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP:
    case InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT:
    case InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW:
    case InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT:
    case InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT:
    case InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT:
    case InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT:
    case InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT:
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL:
    case InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW:
    case InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW:
    case InternalProjectsConst.STORE_DAILY_FOLLOW:
    case InternalProjectsConst.DELETE_DAILY_FOLLOW:
    case InternalProjectsConst.GET_METADATA:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL:
    case InternalProjectsConst.UPDATE_FOLLOW_UP_DATE:
    case InternalProjectsConst.SEND_FOLLOW_UP:
    case InternalProjectsConst.UPDATE_FOLLOW_UP:
    case InternalProjectsConst.DELETE_ROW_PARTICIPANTE:
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS:
    case InternalProjectsConst.DELETE_ENROLLMENT:
    case InternalProjectsConst.UPDATE_ENROLLMENT:
    case InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM:
    case InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_ENROLLMENTS:
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS:
    case InternalProjectsConst.SEND_IP_STUDENT_EVALUATION:
    case InternalProjectsConst.DELETE_INTERNAL_PROJECT_IMAGE:
    case InternalProjectsConst.GET_PROJECT_TYPE_BY_SUBAREA_CODE:
      return { ...state };
    case InternalProjectsConst.DELETE_ROW_PARTICIPANTE_KO:
      return { ...state, error: action.error };
    case InternalProjectsConst.DELETE_ROW_PARTICIPANTE_OK:
      const rows = state.participante.rows.filter(
        row => row.id !== action.idRow || row.id_generated !== action.idGenerated,
      );

      return { ...state, participante: { ...state.participante, rows } };
    case InternalProjectsConst.STORE_DAILY_FOLLOW_OK:
      const dailyFollow = action.dailyFollows.find(f => f.id === action.dailyFollowsId);
      if (dailyFollow) {
        return { ...state, dailyFollows: action.dailyFollows, dailyFollow: dailyFollow as DailyFollows };
      }
      return { ...state, dailyFollows: action.dailyFollows };
    case InternalProjectsConst.DELETE_DAILY_FOLLOW_OK:
      const dailyFollows = state.dailyFollows.filter(
        (dailyFollow: DailyFollowsTable) => dailyFollow.id !== action.dailyFollowId,
      );
      return { ...state, dailyFollows };
    case InternalProjectsConst.GET_METADATA_OK:
      return { ...state, metadata: action.metadata };
    case InternalProjectsConst.ADD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK:
      return { ...state, documentations_daily_follow: action.documents };
    case InternalProjectsConst.DELETE_MEMBERS_OK:
      const members = state.internalProjectData.members.filter(member => !(member.user_id === action.user_id));
      return {
        ...state,
        internalProjectData: {
          ...state.internalProjectData,
          members,
        },
      };
    case InternalProjectsConst.UPDATE_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK:
      const finalFollowUpForm = action.participanteFinalFormData;
      const followUpList = state.participantesFollowUpList.map((e: ParticipantesFollowUpList) => {
        if (e.follow_up_final_id === finalFollowUpForm.id && finalFollowUpForm.follow_up_final_done) {
          e.follow_up_final_done = true;
          e.edit_final_enabled = true;
        }
        return e;
      });

      return {
        ...state,
        participanteFinalForm: action.participanteFinalFormData,
        participantesFollowUpList: followUpList,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_TEMPLATE_OK:
      return { ...state, templates: action.templates };
    case InternalProjectsConst.SET_INTERNAL_PROJECT:
      return { ...state, [action.key]: action.value };
    case InternalProjectsConst.ADD_MEMBERS_OK:
      const { member } = action;
      return {
        ...state,
        internalProjectData: {
          ...state.internalProjectData,
          members: state.internalProjectData.members.concat(member),
        },
      };
    case InternalProjectsConst.CREATE_INTERNAL_PROJECTS_OK:
      return { ...state, loading: false, error: null, internalProjectData: action.internalProject };
    case InternalProjectsConst.CREATE_INTERNAL_PROJECT_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
        validationError: action.validationErrors,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_OK:
    case InternalProjectsConst.GET_INTERNAL_PROJECT_ACADEMY_OK:
      let addSectionOfInternalProject = state.latestInternalProjectSection;
      const index = addSectionOfInternalProject.findIndex((e: IdNamePair) => e.id === action.internalProjectData.id);
      if (index === -1)
        addSectionOfInternalProject = [
          ...state.latestInternalProjectSection,
          { id: action.internalProjectData.id, name: 'informacion' } as IdNamePair,
        ];

      return {
        ...state,
        loading: false,
        error: null,
        internalProjectData: action.internalProjectData,
        documentations_agreement: action.internalProjectData.documentations_agreement,
        documentations: action.internalProjectData.documentations,
        latestInternalProjectSection: addSectionOfInternalProject,
        dailyFollows: [],
      };
    case InternalProjectsConst.SET_LATEST_INTERNAL_PROJECT_SECTION:
      let updateSectionOfInternalProject = state.latestInternalProjectSection;
      const i = updateSectionOfInternalProject.findIndex((e: IdNamePair) => e.id === action.internal_project_id);
      if (i !== -1) updateSectionOfInternalProject[i].name = action.section;
      else
        updateSectionOfInternalProject = [
          ...updateSectionOfInternalProject,
          { id: action.internal_project_id, name: action.section } as IdNamePair,
        ];

      return {
        ...state,
        latestInternalProjectSection: updateSectionOfInternalProject,
      };
    case InternalProjectsConst.ADD_DOCUMENT_FOLLOW_UP_OK:
      return {
        ...state,
        loading: false,
        error: null,
        fields: {
          elements: state.fields.elements,
          documents: action.documents,
        },
      };
    case InternalProjectsConst.UPLOAD_DOCUMENTS_INTERNAL_PROJECT_OK:
    case InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_OK:
      return { ...state, loading: false, error: null, documentations: action.documents };
    case InternalProjectsConst.UPLOAD_INTERNAL_PROJECT_DOCUMENT_OK:
      return { ...state, loading: false, error: null, documentations_agreement: action.documents };
    case InternalProjectsConst.DOWNLOAD_DOCUMENT_AGREEMENT_OK:
    case InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_OK:
    case InternalProjectsConst.DOWNLOAD_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK:
      const { data, name, get } = action;
      getDocs(data, get, name);
      return { ...state };
    case InternalProjectsConst.REMOVE_DOCUMENT_AGREEMENT_OK:
    case InternalProjectsConst.GET_DOCUMENTS_INTERNAL_PROJECT_OK:
      const { documents } = action;

      return { ...state, documentations_agreement: documents };
    case InternalProjectsConst.GET_INTERNAL_PROJECTS_EXCEL_OK:
      const { data: dataInternalProjects } = action;
      const filename = `proyectos_internos_${moment(new Date()).format('DD/MM/YYYY')}.xlsx`;
      getDocs(dataInternalProjects, true, filename);
      return { ...state };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_DAILY_FOLLOW_EXCEL_OK:
      const { data: dataInternalProjectDailyFollow } = action;
      const _filename = `seguimientos_actividades_${moment(new Date()).format('DD/MM/YYYY')}.xlsx`;
      getDocs(dataInternalProjectDailyFollow, true, _filename);
      return { ...state };
    case InternalProjectsConst.DELETE_DOCUMENTS_INTERNAL_PROJECT_DAILY_FOLLOW_OK:
      return { ...state, documentations_daily_follow: action.documents };
    case InternalProjectsConst.CLEAR_DAILY_FOLLOW:
      return { ...state, dailyFollow: {} as DailyFollowsForms, documentations_daily_follow: [] };
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_SETTINGS_OK:
      return {
        ...state,
        internalProjectSettings: {
          id: state.internalProjectSettings.id,
          course_end_date: action.settings.course_end_date,
        },
      };
    case InternalProjectsConst.GET_BASE_CAMP_OK:
      return {
        ...state,
        loadingAlt: false,
        baseCampData: action.baseCamp,
      };
    case InternalProjectsConst.GET_STUDENT_EVALUATION_INFO_OK:
      return {
        ...state,
        loadingAlt: false,
        studentEvaluationInfo: action.studentEvaluationInfo,
      };
    case InternalProjectsConst.UPDATE_ENROLLMENT_OK:
      const { enrollment } = action;
      const enrollments = state.enrollments.map((e: InternalProjectEnrollment) =>
        e.id === enrollment.id ? enrollment : e,
      );
      return {
        ...state,
        enrollments,
      };
    case InternalProjectsConst.UPDATE_FOLLOWUP_SIX_MONTHS_FORM_OK:
      const sixMonthsFollowUpForm = action.followUpSixMonthsData;
      const followUpListUpdated = state.participantesFollowUpList.map((e: ParticipantesFollowUpList) => {
        if (e.follow_up_six_months_id === sixMonthsFollowUpForm.id && sixMonthsFollowUpForm.follow_up_six_months_done) {
          e.follow_up_six_months_done = true;
          e.edit_six_months_enabled = true;
        }
        return e;
      });
      return {
        ...state,
        followUpSixMonthsData: action.followUpSixMonthsData,
        participantesFollowUpList: followUpListUpdated,
      };
    case InternalProjectsConst.DELETE_ENROLLMENT_OK:
      return {
        ...state,
        enrollments: [...state.enrollments.filter((e: InternalProjectEnrollment) => e.id !== action.enrollment_id)],
      };
    case InternalProjectsConst.SET_NEW_ENROLLMENT:
      return {
        ...state,
        enrollments: [...state.enrollments, action.new_enrollment],
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_ENROLLMENTS_OK:
      return {
        ...state,
        enrollments: action.enrollments,
        enrollmentMetadata: action.metadata,
      };
    case InternalProjectsConst.SET_ENROLLMENT_TEXT_DATA:
    case InternalProjectsConst.SET_ENROLLMENT_NUMERIC_DATA:
      const new_enrollments = state.enrollments.map((e: InternalProjectEnrollment) => {
        if (e.id === action.enrollment_id) {
          return { ...e, [action.property]: action.value };
        }
        return e;
      });

      return {
        ...state,
        enrollments: new_enrollments,
      };
    case InternalProjectsConst.SET_ENROLLMENT_DROP_DOWN_DATA:
      const new_enrollments_dd = state.enrollments.map((e: InternalProjectEnrollment) => {
        if (e.id === action.enrollment_id) {
          return { ...e, [action.property]: action.value !== 0 ? action.value : null };
        }
        return e;
      });
      return {
        ...state,
        enrollments: new_enrollments_dd,
      };
    case InternalProjectsConst.UPDATE_INTERNAL_PROJECT_ENROLLMENTS_OK:
      return {
        ...state,
        enrollments: action.enrollments,
      };

    case InternalProjectsConst.GET_IP_STUDENT_EVALUATION_OK:
      return {
        ...state,
        loading: false,
        studentEvaluationData: action.student_evaluation,
      };
    case InternalProjectsConst.GENERIC_KO:
      const { apiError } = action;
      return {
        ...state,
        loading: false,
        error: errorMessage(apiError),
      };
    case InternalProjectsConst.APPLY_CONFIG_TEMPLATE:
      return {
        ...state,
        loading: true,
      };
    case InternalProjectsConst.APPLY_CONFIG_TEMPLATE_OK:
      return {
        ...state,
        loading: false,
      };
    case InternalProjectsConst.GET_PARTICIPANTES_FINAL_FOLLOWUP_FORM_OK:
      return {
        ...state,
        participanteFinalForm: action.participanteFinalForm,
        loadingAlt: false,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_IMAGES:
      return {
        ...state,
        loadingImages: true,
        internalProjectData: {
          ...state.internalProjectData,
          images: [],
        },
      };
    case InternalProjectsConst.ADD_INTERNAL_PROJECT_IMAGE:
    case InternalProjectsConst.EDIT_INTERNAL_PROJECT_IMAGE:
      return {
        ...state,
        loadingImages: true,
      };
    case InternalProjectsConst.GET_INTERNAL_PROJECT_IMAGES_OK:
      return {
        ...state,
        loadingImages: false,
        internalProjectData: {
          ...state.internalProjectData,
          images: action.resourceImages,
        },
      };
    case InternalProjectsConst.ADD_INTERNAL_PROJECT_IMAGE_OK:
      const newImages = state.internalProjectData.images.concat(action.resourceImage);
      return {
        ...state,
        loadingImages: false,
        internalProjectData: {
          ...state.internalProjectData,
          images: newImages,
        },
      };
    case InternalProjectsConst.EDIT_INTERNAL_PROJECT_IMAGE_OK:
      const resourceImages = state.internalProjectData.images.map((e: ResourceImageData) => {
        if (e.id === action.resourceImage.id) return action.resourceImage;
        return e;
      });

      return {
        ...state,
        loadingImages: false,
        internalProjectData: {
          ...state.internalProjectData,
          images: resourceImages,
        },
      };
    case InternalProjectsConst.DELETE_INTERNAL_PROJECT_IMAGE_OK:
      return {
        ...state,
        internalProjectData: {
          ...state.internalProjectData,
          images: state.internalProjectData.images.filter((e: ResourceImageData) => e.id !== action.projectImageId),
        },
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default internalProjectReducer;
