import { Divider, List } from '@material-ui/core';
import * as H from 'history';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import ActionsMenu from '../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import NestedItem from '../../components/Lists/NestedItem/NestedItem';
import Loading from '../../components/Loading/Loading';
import Title from '../../components/Title/Title';
import { tKey } from '../../helpers/translate';
import { AppState } from '../../redux/root-reducer';
import { getRelationalData } from '../../redux/searchBar/actions';
import { SearchDispatch, SearchItem } from '../../redux/searchBar/definitions';
import { selectRelationalData, selectSearchResponse } from '../../redux/searchBar/selectors';
import styles from './searchResults.module.scss';

interface SearchResultsProps {
  loading: boolean;
  searchData: SearchItem[];
  relationalData: SearchItem[];
  getRelationalData: (type: string, id: number, searchData: SearchItem[]) => void;
  location: H.Location<{ searchText: string }>;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  loading,
  searchData,
  getRelationalData,
  relationalData,
  location,
}) => {
  const [searchResponse, setSearchResponse] = useState<SearchItem[]>([]);
  const [id, setId] = useState<number>(-1);

  useEffect(() => {
    const auxArray = searchData.filter((ele: SearchItem) => ele.detail) as SearchItem[];
    setSearchResponse(auxArray);

    searchResponse.forEach(element => {
      if (element.id === id) {
        element.items = relationalData;
      }
    });
  }, [searchData, relationalData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (id: number, type: string) => {
    setId(id);
    getRelationalData(type, id, searchData);
  };

  return loading ? (
    <Loading />
  ) : (
    <Layout rightSubmenu={<ActionsMenu />}>
      <div className={styles.container}>
        <Title>{tKey('Búsqueda')}</Title>
        <div className={styles.search_subtitle}>
          {tKey(`Se han encontrado ${searchResponse.length} resultados por ${location.state.searchText}`)}
        </div>
        <Divider />
        {searchData && searchResponse && (
          <List disablePadding>
            {searchResponse.map(e => (
              <NestedItem key={`{${e.type}_${e.id}}`} item={e} handleClick={handleClick} />
            ))}
          </List>
        )}
      </div>
    </Layout>
  );
};

const mapStateProps = (state: AppState) => ({
  searchData: selectSearchResponse(state),
  relationalData: selectRelationalData(state),
});

const mapDispatchProps = (dispatch: SearchDispatch) => ({
  getRelationalData: (type: string, id: number): void => dispatch(getRelationalData(type, id)),
});

export default connect(mapStateProps, mapDispatchProps)(SearchResults);
