import moment from 'moment';
import {
  ScholarshipApiPayment,
  ScholarshipApiPaymentItem,
  ScholarshipPayment,
  ScholarshipPaymentTotals,
} from './definitions';
import * as DateConstants from '../../constants/date';

export class ScholarshipPaymentsManager {
  private scholarship_payments: ScholarshipPayment[];
  private next_payment_date: Date | null;
  private total_paid: number;
  private total_pending: number;
  private next_payment_amount: number;

  constructor(private academic_year: string, scholarshipApiPayments: ScholarshipApiPayment[]) {
    this.next_payment_date = null;
    this.total_paid = 0;
    this.total_pending = 0;
    this.scholarship_payments = this.createScholarshipPaymentList(scholarshipApiPayments);
    this.next_payment_amount = this.calcNextPaymentAmount();
  }

  private getDateObject(dateString: string): Date {
    const dateMomentObject = moment(dateString, DateConstants.DATE_FORMAT);
    return dateMomentObject.toDate();
  }

  private getNextPaymentDateToString(): string {
    if (!this.next_payment_date) return '';
    return moment(this.next_payment_date).format(DateConstants.DATE_FORMAT);
  }

  private setNexPaymentDate(
    scholarshipApiPayment: ScholarshipApiPayment,
    scholarshipApiPaymentItem: ScholarshipApiPaymentItem,
  ) {
    if (!scholarshipApiPaymentItem.payment_done_date && !scholarshipApiPayment.disabled) {
      const paymentDate = this.getDateObject(scholarshipApiPaymentItem.payment_date);
      if (!this.next_payment_date) {
        this.next_payment_date = paymentDate;
        return;
      }
      if (this.next_payment_date > paymentDate) {
        this.next_payment_date = paymentDate;
      }
    }
  }

  private addTotalPaid(scholarshipApiPaymentItem: ScholarshipApiPaymentItem) {
    if (scholarshipApiPaymentItem.payment_done_date) {
      this.total_paid += +scholarshipApiPaymentItem.payment_amount;
    }
  }

  private addTotalPending(scholarshipApiPaymentItem: ScholarshipApiPaymentItem) {
    if (!scholarshipApiPaymentItem.payment_done_date) {
      this.total_pending += +scholarshipApiPaymentItem.payment_amount;
    }
  }

  private calcNextPaymentAmount(): number {
    const payments_by_date = this.scholarship_payments.filter(
      (payment: ScholarshipPayment) =>
        payment.payment_date === this.getNextPaymentDateToString() && !payment.payment_done_date && !payment.disabled,
    );
    return +payments_by_date?.reduce((a, b) => a + +b.payment_amount, 0);
  }

  private createScholarshipPaymentList(scholarshipApiPayments: ScholarshipApiPayment[]): ScholarshipPayment[] {
    const scholarship_payments: ScholarshipPayment[] = [];

    scholarshipApiPayments.forEach((scholarshipApiPayment: ScholarshipApiPayment) => {
      this.readScholarshipPayments(scholarship_payments, scholarshipApiPayment);
    });
    return scholarship_payments;
  }

  private readScholarshipPayments(
    scholarship_payments: ScholarshipPayment[],
    scholarshipApiPayment: ScholarshipApiPayment,
  ): void {
    scholarshipApiPayment.payments.forEach((scholarshipApiPaymentItem: ScholarshipApiPaymentItem) => {
      if (scholarshipApiPaymentItem.payment_amount > 0) {
        const scholarshipPayment = this.createScholarshipPayment(scholarshipApiPayment, scholarshipApiPaymentItem);
        scholarship_payments.push(scholarshipPayment);
      }
    });
  }

  private createScholarshipPayment(
    scholarshipApiPayment: ScholarshipApiPayment,
    scholarshipApiPaymentItem: ScholarshipApiPaymentItem,
  ): ScholarshipPayment {
    this.addTotalPaid(scholarshipApiPaymentItem);
    this.addTotalPending(scholarshipApiPaymentItem);
    this.setNexPaymentDate(scholarshipApiPayment, scholarshipApiPaymentItem);
    return {
      id: scholarshipApiPaymentItem.id,
      scholarship_id: scholarshipApiPayment.scholarship_id,
      student_name: scholarshipApiPayment.student_name,
      document_number: scholarshipApiPayment.document_number,
      provider_code: scholarshipApiPayment.provider_code,
      agreement_number: scholarshipApiPayment.agreement_number,
      scholarship_state_id: scholarshipApiPayment.scholarship_state_id,
      scholarship_state: scholarshipApiPayment.scholarship_state,
      entity_name: scholarshipApiPayment.entity_name,
      payment_amount: scholarshipApiPaymentItem.payment_amount,
      payment_date: scholarshipApiPaymentItem.payment_date,
      payment_done_date: scholarshipApiPaymentItem.payment_done_date,
      payment_type: scholarshipApiPaymentItem.payment_type,
      payment_field: scholarshipApiPaymentItem.payment_field,
      account_number: scholarshipApiPaymentItem.account_number,
      disabled: scholarshipApiPayment.disabled,
      disabled_reason: scholarshipApiPayment.disabled_reason,
      scholarship_type: scholarshipApiPayment.scholarship_type,
      paid: scholarshipApiPaymentItem.payment_done_date !== null,
      is_student_transfer: scholarshipApiPaymentItem.is_student_transfer,
    };
  }

  getScholarshipPaymentTotals(): ScholarshipPaymentTotals {
    return {
      academic_year: this.academic_year,
      total_paid: Math.round(this.total_paid * 100) / 100,
      total_pending: Math.round(this.total_pending * 100) / 100,
      next_payment_date: this.getNextPaymentDateToString(),
      next_payment_amount: Math.round(this.next_payment_amount * 100) / 100,
    };
  }

  getScholarshipPaymentList(): ScholarshipPayment[] {
    return this.scholarship_payments;
  }
}
