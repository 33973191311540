import { useMemo } from 'react';
import { ObjectSchema, SchemaDescription } from 'yup';

export default function useIsRequired(name: string, schema: ObjectSchema) {
  return useMemo(() => {
    const description = schema.describe();
    if (name in description.fields) {
      const schemaFields = description.fields as { [k: string]: SchemaDescription };
      const fieldDescription = schemaFields[name];
      return fieldDescription.tests.some(t => t.name === 'required');
    }
    return false;
  }, [schema, name]);
}
