import { APIError } from '../../api/api';
import { IdNamePair } from '../common/definitions';
import { InternalProjectTemplatesConst } from './action_types';
import {
  CreateInternalProjectTemplateFollowUp,
  CreateInternalProjectTemplateFollowUpKo,
  CreateInternalProjectTemplateFollowUpOk,
  DeleteInternalProjectTemplate,
  DeleteInternalProjectTemplateFollowUp,
  DeleteInternalProjectTemplateFollowUpKo,
  DeleteInternalProjectTemplateFollowUpOk,
  DeleteInternalProjectTemplateKo,
  DeleteInternalProjectTemplateOk,
  FollowUpData,
  GenericKo,
  GetInternalProjectTemplateInfo,
  GetInternalProjectTemplateInfoKo,
  GetInternalProjectTemplateInfoOk,
  GetInternalProjectTemplatesInfo,
  GetInternalProjectTemplatesInfoKo,
  GetInternalProjectTemplatesInfoOk,
  GetInternalProjectTemplatesMetadataOk,
  InternalProjectTemplate,
  InternalProjectTemplateData,
  InternalProjectTemplatesMetadata,
  PublishInternalProjectTemplate,
  PublishInternalProjectTemplateKo,
  PublishInternalProjectTemplateOk,
  RemoveInternalProjectTemplateFollowUpRoleData,
  RemoveInternalProjectTemplateGeneralFormRoleData,
  RemoveInternalProjectTemplateRoleData,
  RoleWithPermission,
  SetInternalProjectTemplate,
  SetInternalProjectTemplateData,
  SetInternalProjectTemplateFollowUpRoleData,
  SetInternalProjectTemplateGeneralFormRoleData,
  SetInternalProjectTemplateRoleData,
  UpdateInternalProjectTemplate,
  UpdateInternalProjectTemplateFollowUpRoles,
  UpdateInternalProjectTemplateFollowUpRolesKo,
  UpdateInternalProjectTemplateFollowUpRolesOk,
  UpdateInternalProjectTemplateGeneralFormRoles,
  UpdateInternalProjectTemplateGeneralFormRolesKo,
  UpdateInternalProjectTemplateGeneralFormRolesOk,
  UpdateInternalProjectTemplateKo,
  UpdateInternalProjectTemplateOk,
} from './definitions';

export function internalProjectsGenericKo(apiError: APIError): GenericKo {
  return {
    type: InternalProjectTemplatesConst.GENERIC_KO,
    apiError,
  };
}

export function getMetadataOk(
  internalProjectTemplatesMetadata: InternalProjectTemplatesMetadata,
): GetInternalProjectTemplatesMetadataOk {
  return {
    type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_METADATA_OK,
    internalProjectTemplatesMetadata,
  };
}

export function getInternalProjectTemplatesInfo(): GetInternalProjectTemplatesInfo {
  return {
    type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO,
  };
}

export function getInternalProjectTemplatesInfoOk(
  internalProjectTemplatesData: InternalProjectTemplate[],
): GetInternalProjectTemplatesInfoOk {
  return {
    type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO_OK,
    internalProjectTemplatesData,
  };
}

export function getInternalProjectTemplatesInfoKo(error: string): GetInternalProjectTemplatesInfoKo {
  return {
    type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATES_INFO_KO,
    error,
  };
}

export function setInternalProjectTemplate(
  internalProjectTemplates: InternalProjectTemplate[],
): SetInternalProjectTemplate {
  return {
    type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE,
    internalProjectTemplates,
  };
}

export function deleteInternalProjectTemplate(internalProjectTemplateId: number): DeleteInternalProjectTemplate {
  return {
    type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE,
    internalProjectTemplateId,
  };
}

export function deleteInternalProjectTemplateOk(
  internalProjectTemplates: InternalProjectTemplate[],
): DeleteInternalProjectTemplateOk {
  return {
    type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_OK,
    internalProjectTemplates,
  };
}

export function deleteInternalProjectTemplateKo(error: string): DeleteInternalProjectTemplateKo {
  return {
    type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_KO,
    error,
  };
}

export function getInternalProjectTemplateInfo(id: number): GetInternalProjectTemplateInfo {
  return {
    type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO,
    id,
  };
}

export function getInternalProjectTemplateInfoOk(
  internalProjectTemplateData: InternalProjectTemplateData,
): GetInternalProjectTemplateInfoOk {
  return {
    type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO_OK,
    internalProjectTemplateData,
  };
}

export function getInternalProjectTemplateInfoKo(error: string): GetInternalProjectTemplateInfoKo {
  return {
    type: InternalProjectTemplatesConst.GET_INTERNAL_PROJECT_TEMPLATE_INFO_KO,
    error,
  };
}

export function setInternalProjectTemplateData(
  key: string,
  value: string | number | boolean,
): SetInternalProjectTemplateData {
  return {
    type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_DATA,
    key,
    value,
  };
}

export function updateInternalProjectTemplate(
  id: number,
  data: InternalProjectTemplateData,
): UpdateInternalProjectTemplate {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE,
    id,
    data,
  };
}

export function updateInternalProjectTemplateOk(
  internalProjectTemplateData: InternalProjectTemplateData,
): UpdateInternalProjectTemplateOk {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_OK,
    internalProjectTemplateData,
  };
}

export function updateInternalProjectTemplateKo(error: string): UpdateInternalProjectTemplateKo {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_KO,
    error,
  };
}

export function setInternalProjectTemplateRoleData(role: IdNamePair): SetInternalProjectTemplateRoleData {
  return {
    type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA,
    role,
  };
}

export function removeInternalProjectTemplateRoleData(roleId: number): RemoveInternalProjectTemplateRoleData {
  return {
    type: InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_ROLE_DATA,
    roleId,
  };
}

export function setInternalProjectTemplateGeneralFormRoleData(
  formId: number,
  role: RoleWithPermission,
): SetInternalProjectTemplateGeneralFormRoleData {
  return {
    type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA,
    formId,
    role,
  };
}

export function removeInternalProjectTemplateGeneralFormRoleData(
  formId: number,
  roleId: number,
): RemoveInternalProjectTemplateGeneralFormRoleData {
  return {
    type: InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLE_DATA,
    formId,
    roleId,
  };
}

export function updateInternalProjectTemplateGeneralFormRoles(
  internalProjectTemplateId: number,
  formId: number,
  roles: RoleWithPermission[],
): UpdateInternalProjectTemplateGeneralFormRoles {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES,
    internalProjectTemplateId,
    formId,
    roles,
  };
}

export function updateInternalProjectTemplateGeneralFormRolesOk(
  formId: number,
  roles: RoleWithPermission[],
): UpdateInternalProjectTemplateGeneralFormRolesOk {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_OK,
    formId,
    roles,
  };
}

export function updateInternalProjectTemplateGeneralFormRolesKo(
  error: string,
): UpdateInternalProjectTemplateGeneralFormRolesKo {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_GENERAL_FORM_ROLES_KO,
    error,
  };
}

export function createInternalProjectTemplateFollowUp(followUp: FollowUpData): CreateInternalProjectTemplateFollowUp {
  return {
    type: InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP,
    followUp,
  };
}

export function createInternalProjectTemplateFollowUpOk(
  followUp: FollowUpData[],
): CreateInternalProjectTemplateFollowUpOk {
  return {
    type: InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK,
    followUp,
  };
}

export function createInternalProjectTemplateFollowUpKo(error: string): CreateInternalProjectTemplateFollowUpKo {
  return {
    type: InternalProjectTemplatesConst.CREATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO,
    error,
  };
}

export function setInternalProjectTemplateFollowUpRoleData(
  followUpId: number,
  role: RoleWithPermission,
): SetInternalProjectTemplateFollowUpRoleData {
  return {
    type: InternalProjectTemplatesConst.SET_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA,
    followUpId,
    role,
  };
}

export function removeInternalProjectTemplateFollowUpRoleData(
  followUpId: number,
  roleId: number,
): RemoveInternalProjectTemplateFollowUpRoleData {
  return {
    type: InternalProjectTemplatesConst.REMOVE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLE_DATA,
    followUpId,
    roleId,
  };
}

export function updateInternalProjectTemplateFollowUpRoles(
  internalProjectTemplateId: number,
  followUpId: number,
  roles: RoleWithPermission[],
): UpdateInternalProjectTemplateFollowUpRoles {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES,
    internalProjectTemplateId,
    followUpId,
    roles,
  };
}

export function updateInternalProjectTemplateFollowUpRolesOk(
  followUpId: number,
  roles: RoleWithPermission[],
): UpdateInternalProjectTemplateFollowUpRolesOk {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_OK,
    followUpId,
    roles,
  };
}

export function updateInternalProjectTemplateFollowUpRolesKo(
  error: string,
): UpdateInternalProjectTemplateFollowUpRolesKo {
  return {
    type: InternalProjectTemplatesConst.UPDATE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_ROLES_KO,
    error,
  };
}

export function deleteInternalProjectTemplateFollowUp(
  internalProjectTemplateId: number,
  followUpId: number,
): DeleteInternalProjectTemplateFollowUp {
  return {
    type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP,
    internalProjectTemplateId,
    followUpId,
  };
}

export function deleteInternalProjectTemplateFollowUpOk(
  internalProjectTemplateFollowUps: FollowUpData[],
): DeleteInternalProjectTemplateFollowUpOk {
  return {
    type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_OK,
    internalProjectTemplateFollowUps,
  };
}

export function deleteInternalProjectTemplateFollowUpKo(error: string): DeleteInternalProjectTemplateFollowUpKo {
  return {
    type: InternalProjectTemplatesConst.DELETE_INTERNAL_PROJECT_TEMPLATE_FOLLOW_UP_KO,
    error,
  };
}

export function publishInternalProjectTemplate(id: number): PublishInternalProjectTemplate {
  return {
    type: InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE,
    id,
  };
}

export function publishInternalProjectTemplateOk(): PublishInternalProjectTemplateOk {
  return {
    type: InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE_OK,
  };
}

export function publishInternalProjectTemplateKo(error: string): PublishInternalProjectTemplateKo {
  return {
    type: InternalProjectTemplatesConst.PUBLISH_INTERNAL_PROJECT_TEMPLATE_KO,
    error,
  };
}
