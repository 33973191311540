import assertUnreachable from '../../helpers/assertUnreachable';
import { PermissionsConsts } from './action_types';
import { initialState, PermissionsAction, PermissionsState } from './definitions';

const Permissions = (state = initialState, action: PermissionsAction): PermissionsState => {
  switch (action.type) {
    case PermissionsConsts.PERMISSIONS_LIST:
      return {
        ...state,
        fetchingPermissions: true,
      };
    case PermissionsConsts.PERMISSIONS_LIST_OK:
      return {
        ...state,
        permissions: action.permissions,
        fetchingPermissions: false,
      };
    case PermissionsConsts.PERMISSIONS_LIST_KO:
      return {
        ...state,
        error: action.error,
        fetchingPermissions: false,
      };
  }
  assertUnreachable(action);
  return state;
};

export default Permissions;
