import * as H from 'history';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../api/api';
import { DocumentationItem } from '../../../redux/common/definitions';
import { AppState } from '../../../redux/root-reducer';
import {
  acceptUserEducator,
  downloadDocument,
  getDocument,
  rejectUserEducator,
  removeDocument,
  updateProfileUserTable,
  uploadDocument,
} from '../../../redux/user/actions';
import { UpdateUserFormFields, UserData, UserDataDispatch } from '../../../redux/user/definitions';
import { selectError, selectUserValidationErrors } from '../../../redux/user/selectors';
import EntityInfo from './FormsLayouts/EntityInfo';
import LOPD from './FormsLayouts/LOPD';
import PersonalData from './FormsLayouts/PersonalData';
import ScholarshipList from './FormsLayouts/ScholarshipList';
import EventList from './FormsLayouts/EventsList';
import ServicesList from './FormsLayouts/ServicesList';
import HistoricalData from './FormsLayouts/HistoricalData';

interface EditUserProps {
  readOnly: boolean;
  id: string;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  documents: DocumentationItem[];
  location: H.Location<{ needsValidation: boolean; type: string }>;
  profileData: UserData;
  validationErrors: APIValidationError | null;
  acceptEducator: (id: number) => void;
  rejectEducator: (id: number) => void;
  updateTableUser: (data: UpdateUserFormFields, id: number) => void;
  uploadUserDocument: (document_id: number, data: File) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
  handleSetImageById: (image: string) => void;
}

const EditUser: React.FC<EditUserProps> = ({
  readOnly,
  avatar,
  profileData,
  loading,
  documents,
  location,
  id,
  changeAvatar,
  handleSetImageById,
}) => {
  const [idComponent, setIdComponent] = useState(0);

  const handleComponentId = (componentId: number) => {
    setIdComponent(componentId);
  };

  const componentsList = [
    <PersonalData
      key={0}
      readOnly={readOnly}
      id={id}
      location={location}
      profileData={profileData}
      avatar={avatar}
      documents={documents}
      loading={loading}
      changeAvatar={changeAvatar}
      handleSetImageById={handleSetImageById}
      onSelectComponent={handleComponentId}
    />,
    <EntityInfo
      key={1}
      readOnly={readOnly}
      id={id}
      location={location}
      profileData={profileData}
      avatar={avatar}
      documents={documents}
      loading={loading}
      changeAvatar={changeAvatar}
      handleSetImageById={handleSetImageById}
      onSelectComponent={handleComponentId}
    />,
    <HistoricalData
      key={2}
      readOnly={readOnly}
      id={id}
      avatar={avatar}
      profileData={profileData}
      loading={loading}
      changeAvatar={changeAvatar}
      handleSetImageById={handleSetImageById}
      onSelectComponent={handleComponentId}
    />,
    <ScholarshipList
      key={4}
      readOnly={readOnly}
      location={location}
      profileData={profileData}
      avatar={avatar}
      documents={documents}
      loading={loading}
      changeAvatar={changeAvatar}
      handleSetImageById={handleSetImageById}
      onSelectComponent={handleComponentId}
    />,
    <LOPD
      key={4}
      readOnly={readOnly}
      id={id}
      location={location}
      profileData={profileData}
      avatar={avatar}
      documents={documents}
      loading={loading}
      changeAvatar={changeAvatar}
      handleSetImageById={handleSetImageById}
      onSelectComponent={handleComponentId}
    />,
    <EventList
      key={5}
      readOnly={readOnly}
      id={id}
      avatar={avatar}
      location={location}
      profileData={profileData}
      loading={loading}
      changeAvatar={changeAvatar}
      handleSetImageById={handleSetImageById}
      onSelectComponent={handleComponentId}
    />,
    <ServicesList
      key={6}
      readOnly={readOnly}
      id={id}
      avatar={avatar}
      location={location}
      profileData={profileData}
      loading={loading}
      changeAvatar={changeAvatar}
      handleSetImageById={handleSetImageById}
      onSelectComponent={handleComponentId}
    />,
  ];
  const correctPanel = componentsList[idComponent];

  return <>{correctPanel}</>;
};

const mapStateToProps = (state: AppState) => ({
  error: selectError(state),
  validationErrors: selectUserValidationErrors(state),
});

const mapDispatchToProps = (dispatch: UserDataDispatch) => ({
  updateTableUser: (data: UpdateUserFormFields, id: number): void => dispatch(updateProfileUserTable(data, id)),
  uploadUserDocument: (document_id: number, data: File): void => dispatch(uploadDocument(document_id, data)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
  acceptEducator: (id: number): void => dispatch(acceptUserEducator(id)),
  rejectEducator: (id: number): void => dispatch(rejectUserEducator(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
