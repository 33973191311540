import { MenuItem } from '@material-ui/core';
import React, { FocusEvent, ReactElement } from 'react';
import { Control, FieldErrors, FieldValues, ManualFieldError, ValidateResult } from 'react-hook-form';
import { ObjectSchema, Shape } from 'yup';
import { findZipCode } from '../../api/ZipCodes/zipcode';
import { tErrorKey, tFormKey } from '../../helpers/translate';
import { validateFields } from '../../helpers/validator';
import { AddressData, OptionalAddressNames, OptionalAddressSizes } from '../../redux/common/definitions';
import { GenericMetadata } from '../../redux/metadata/definitions';
import { ScholarshipSteps } from '../../redux/scholarship/definitions';
import TextInputController from '../Inputs/TextInputController/TextInputController';
import SelectController from '../Select/SelectController';

interface AddressProps<T extends FieldValues> {
  errors: FieldErrors<T>;
  disabled?: boolean;
  setError: (
    name: string | ManualFieldError<any>[],
    type?: Record<string, ValidateResult> | string,
    message?: string | undefined,
  ) => void;
  addressData: AddressData | any;
  genericMetadata: GenericMetadata;
  addressSchema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  optionalNames?: OptionalAddressNames;
  optionalSizes?: OptionalAddressSizes;
  validator?: {
    data: any;
    step?: keyof ScholarshipSteps;
  };
  accountNumber?: string;
  control: Control<T>;
  setValue: (name: any, value: any, shouldValidate?: boolean) => void;
  triggerValidation: (payload?: string | string[], shouldRender?: boolean) => Promise<boolean>;
  checkAccountNumber?: (value: string) => void;
  setData?: (key: string, value: string | number) => void;
}

const Address = <T extends FieldValues>({
  accountNumber,
  errors,
  disabled,
  addressData,
  genericMetadata,
  addressSchema,
  validator,
  control,
  optionalNames = {
    address_type_name: 'address_type',
    address_name: 'address',
    flat_name: 'flat',
    floor_name: 'floor',
    room_name: 'room',
    staircase_name: 'staircase',
    zip_code_name: 'zip_code',
    city_name: 'city',
    province_name: 'province',
    country_name: 'country',
  },
  optionalSizes = {
    address_type_size: '50',
    address_size: '50',
    flat_size: '20',
    floor_size: '20',
    room_size: '20',
    staircase_size: '20',
    zip_code_size: '50',
    city_size: '50',
    province_size: '50',
    country_size: '50',
  },
  setValue,
  triggerValidation,
  checkAccountNumber,
  setData,
  setError,
}: AddressProps<T>): ReactElement => {
  const { addressTypes, provinces, countries } = genericMetadata;
  const { address_type, address, flat, floor, room, city, staircase, province, country, zip_code } = addressData;
  const {
    address_type_name,
    address_name,
    flat_name,
    floor_name,
    room_name,
    staircase_name,
    city_name,
    province_name,
    country_name,
    zip_code_name,
  } = optionalNames;

  const {
    address_type_size,
    address_size,
    flat_size,
    floor_size,
    room_size,
    staircase_size,
    city_size,
    province_size,
    country_size,
    zip_code_size,
  } = optionalSizes;

  const handleSelectChange = (target: any) => {
    if (setData && target.name) {
      setData(target.name, target.value);
    }
  };
  const validateZipCode = async (event: React.FocusEvent<HTMLInputElement>) => {
    const code = event.target.value;
    try {
      if (code !== zip_code) {
        const zip_code_response = await findZipCode(code);
        if (zip_code_response) {
          setValue(zip_code_name, zip_code_response.zip_code);
          if (setData) {
            setData(zip_code_name, zip_code_response.zip_code);
          }
          if (zip_code_response.town) {
            setValue(city_name, zip_code_response.town);
            if (setData) {
              setData(city_name, zip_code_response.town);
            }
          }
          if (zip_code_response.province) {
            setValue(province_name, zip_code_response.province);
            if (setData) {
              setData(province_name, zip_code_response.province);
            }
          }
          if (zip_code_response.country) {
            setValue(country_name, zip_code_response.country);
            if (setData) {
              setData(country_name, zip_code_response.country);
            }
          }
        }
      }
    } catch (error) {
      setError(zip_code_name, 'ZipCode', tErrorKey('Formato incorrecto'));
    }
  };

  return (
    <React.Fragment>
      <SelectController
        name={address_type_name}
        control={control}
        errors={errors}
        schema={addressSchema}
        disabled={disabled}
        validator={!!validator && validateFields(validator.data, address_type_name, validator.step)}
        label={tFormKey('Tipo de dirección')}
        size={address_type_size}
        defaultValue={address_type}
        onClick={e => handleSelectChange(e.target)}
      >
        {addressTypes.map(addressType => (
          <MenuItem key={addressType} value={addressType}>
            {tFormKey(addressType)}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        schema={addressSchema}
        size={address_size}
        label={tFormKey('Dirección')}
        name={address_name}
        errors={errors}
        validator={!!validator && validateFields(validator.data, address_name, validator.step)}
        disabled={disabled}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (setData) {
            setData(address_name, e.target.value);
          }
          triggerValidation(address_name);
        }}
        control={control}
        defaultValue={address}
      />
      <TextInputController
        schema={addressSchema}
        size={flat_size}
        label={tFormKey('Número')}
        autoComplete="section-blue shipping address-level2"
        name={flat_name}
        validator={!!validator && validateFields(validator.data, flat_name, validator.step)}
        errors={errors}
        disabled={disabled}
        control={control}
        defaultValue={flat}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (setData) {
            setData(flat_name, e.target.value);
          }
        }}
      />
      <TextInputController
        schema={addressSchema}
        size={floor_size}
        label={tFormKey('Piso')}
        name={floor_name}
        validator={!!validator && validateFields(validator.data, floor_name, validator.step)}
        errors={errors}
        disabled={disabled}
        control={control}
        defaultValue={floor}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (setData) {
            setData(floor_name, e.target.value);
          }
        }}
      />
      <TextInputController
        schema={addressSchema}
        size={room_size}
        label={tFormKey('Puerta')}
        name={room_name}
        validator={!!validator && validateFields(validator.data, room_name, validator.step)}
        errors={errors}
        disabled={disabled}
        control={control}
        defaultValue={room}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (setData) {
            setData(room_name, e.target.value);
          }
        }}
      />
      <TextInputController
        schema={addressSchema}
        size={staircase_size}
        label={tFormKey('Escalera')}
        name={staircase_name}
        validator={!!validator && validateFields(validator.data, staircase_name, validator.step)}
        errors={errors}
        disabled={disabled}
        control={control}
        defaultValue={staircase}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (setData) {
            setData(staircase_name, e.target.value);
          }
        }}
      />
      <TextInputController
        schema={addressSchema}
        label={tFormKey('Código Postal')}
        size={zip_code_size}
        errors={errors}
        name={zip_code_name}
        validator={!!validator && validateFields(validator.data, zip_code_name, validator.step)}
        control={control}
        defaultValue={zip_code}
        disabled={disabled}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => validateZipCode(event)}
      />
      <TextInputController
        label={tFormKey('Población')}
        size={city_size}
        schema={addressSchema}
        errors={errors}
        name={city_name}
        validator={!!validator && validateFields(validator.data, city_name, validator.step)}
        control={control}
        defaultValue={city}
        disabled={disabled}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (setData) {
            setData(city_name, e.target.value);
          }
        }}
      />
      <SelectController
        control={control}
        name={province_name}
        defaultValue={province}
        errors={errors}
        schema={addressSchema}
        disabled={disabled}
        label={tFormKey('Provincia')}
        size={province_size}
        validator={!!validator && validateFields(validator.data, province_name, validator.step)}
        onClick={e => handleSelectChange(e.target)}
      >
        {provinces.map(province => (
          <MenuItem key={province} value={province}>
            {province}
          </MenuItem>
        ))}
      </SelectController>
      <SelectController
        control={control}
        name={country_name}
        errors={errors}
        schema={addressSchema}
        validator={!!validator && validateFields(validator.data, country_name, validator.step)}
        disabled={disabled}
        defaultValue={country}
        label={tFormKey('País')}
        size={country_size}
        onClick={e => handleSelectChange(e.target)}
        onBlur={(_e: FocusEvent<HTMLInputElement>) => {
          checkAccountNumber && accountNumber && checkAccountNumber(accountNumber);
        }}
      >
        {countries.map(country => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </SelectController>
    </React.Fragment>
  );
};

export default Address;
