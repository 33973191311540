export enum EntityDataConsts {
  ENTITY_GET_LIST = 'ENTITY:GET:LIST',
  ENTITY_GET_LIST_OK = 'ENTITY:GET:LIST:OK',
  ENTITY_GET_LIST_KO = 'ENTITY:GET:LIST:KO',

  ENTITY_VALIDATION_ERRORS = 'ENTITY:VALIDATION:ERRORS',

  ENTITY_FNC_SEND = 'ENTITY:FNC:SEND',
  ENTITY_FNC_SEND_OK = 'ENTITY:FNC:SEND:OK',
  ENTITY_FNC_SEND_KO = 'ENTITY:FNC:SEND:KO',

  CREATE_ENTITY_FNC = 'CREATE:ENTITY:FNC',

  PRE_ENTITY_SEND = 'ENTITY:USER:SEND',
  PRE_ENTITY_SEND_OK = 'ENTITY:USER:SEND:OK',
  PRE_ENTITY_SEND_KO = 'ENTITY:USER:SEND:KO',

  PRE_ENTITY_UPDATE = 'ENTITY:USER:SAVE',
  PRE_ENTITY_UPDATE_OK = 'ENTITY:USER:SAVE:OK',
  PRE_ENTITY_UPDATE_KO = 'ENTITY:USER:SAVE:KO',

  GET_ENTITY_CONTACTS = 'GET:ENTITY:CONTACTS',
  GET_ENTITY_CONTACTS_OK = 'GET:ENTITY:CONTACTS:OK',
  GET_ENTITY_CONTACTS_KO = 'GET:ENTITY:CONTACTS:KO',

  GET_ENTITY_ONE_CONTACT = 'GET:ENTITY:CONTACT',
  GET_ENTITY_ONE_CONTACT_OK = 'GET:ENTITY:CONTACT:OK',
  GET_ENTITY_ONE_CONTACT_KO = 'GET:ENTITY:CONTACT:KO',

  REMOVE_ENTITY_CONTACT = 'REMOVE:ENTITY:CONTACT',
  REMOVE_ENTITY_CONTACT_OK = 'REMOVE:ENTITY:CONTACT:OK',
  REMOVE_ENTITY_CONTACT_KO = 'REMOVE:ENTITY:CONTACT:KO',

  GET_ENTITY_ROLES = 'GET:ENTITY:ROLES',
  GET_ENTITY_ROLES_OK = 'GET:ENTITY:ROLES:OK',
  GET_ENTITY_ROLES_KO = 'GET:ENTITY:ROLES:KO',

  ADD_ENTITY_CONTACT = 'ADD:ENTITY:CONTACT',
  ADD_ENTITY_CONTACT_OK = 'ADD:ENTITY:CONTACT:OK',
  ADD_ENTITY_CONTACT_KO = 'ADD:ENTITY:CONTACT:KO',

  UPDATE_ENTITY_CONTACT = 'UPDATE:ENTITY:CONTACT',
  UPDATE_ENTITY_CONTACT_OK = 'UPDATE:ENTITY:CONTACT:OK',
  UPDATE_ENTITY_CONTACT_KO = 'UPDATE:ENTITY:CONTACT:KO',

  ENTITY_UPDATE = 'ENTITY:UPDATE',
  ENTITY_UPDATE_OK = 'ENTITY:UPDATE:OK',
  ENTITY_UPDATE_KO = 'ENTITY:UPDATE:KO',

  ENTITY_GET_ONE = 'ENTITY:GET:ONE',
  ENTITY_GET_ONE_OK = 'ENTITY:GET:ONE:OK',
  ENTITY_GET_ONE_KO = 'ENTITY:GET:ONE:KO',

  ENTITY_DELETE_ONE = 'ENTITY:DELETE:ONE',
  ENTITY_DELETE_ONE_OK = 'ENTITY:DELETE:ONE:OK',
  ENTITY_DELETE_ONE_KO = 'ENTITY:DELETE:ONE:KO',

  ENTITY_RESET_RESPONSEDATA = 'ENTITY:RESET:RESPONSEDATA',
  ENTITY_RESET_ALLSUCCESS = 'ENTITY:RESET:ALLSUCCESS',

  ENTITY_SET_PAGESIZE_TABLE_OPTIONS = 'ENTITY:SET:PAGESIZE:TABLE:OPTIONS',
  ENTITY_SET_SEARCHTEXT_TABLE_OPTIONS = 'ENTITY:SET:SEARCHTEXT:TABLE:OPIONS',
  ENTITY_SET_COLUMNORDER_TABLE_OPTIONS = 'ENTITY:SET:COLUMNORDER:TABLE:OPTIONS',

  ENTITY_GET_CREATE_FORM = 'ENTITY:GET:CREATE:FORM',
  ENTITY_GET_CREATE_FORM_OK = 'ENTITY:GET:CREATE:FORM:OK',
  ENTITY_GET_CREATE_FORM_KO = 'ENTITY:GET:CREATE:FORM_KO',

  ENTITY_GET_PRE_CREATED_USER = 'ENTITY:GET:PRE:CREATED:USER',
  ENTITY_GET_PRE_CREATED_USER_OK = 'ENTITY:GET:PRE:CREATED:USER:OK',
  ENTITY_GET_PRE_CREATED_USER_KO = 'ENTITY:GET:PRE:CREATED:USER:KO',

  ENTITY_GET_PRE_CREATED_USER_ID = 'ENTITY:GET:PRE:CREATED:USER:ID',
  ENTITY_GET_PRE_CREATED_USER_ID_OK = 'ENTITY:GET:PRE:CREATED:USER:ID:OK',
  ENTITY_GET_PRE_CREATED_USER_ID_KO = 'ENTITY:GET:PRE:CREATED:USER:ID:KO',

  CHANGE_STATE_ENTITY = 'CHANGE:STATE:ENTITY',
  CHANGE_STATE_ENTITY_OK = 'CHANGE:STATE:ENTITY:OK',
  CHANGE_STATE_ENTITY_KO = 'CHANGE:STATE:ENTITY:KO',

  ENTITY_GET_USER = 'ENTITY:GET:USER',
  ENTITY_GET_USER_OK = 'ENTITY:GET:USER:OK',
  ENTITY_GET_USER_KO = 'ENTITY:GET:USER:KO',

  ENTITY_CLEAN = 'ENTITY:CLEAN',
  ENTITIES_CLEAN = 'ENTITIES:CLEAN',
  ENTITIES_CHANGE = 'ENTITIES:CHANGE',

  UPLOAD_ENTITY_DOCUMENT = 'UPLOAD:ENTITY:DOCUMENT',
  UPLOAD_ENTITY_DOCUMENT_OK = 'UPLOAD:ENTITY:DOCUMENT:OK',
  UPLOAD_ENTITY_DOCUMENT_KO = 'UPLOAD:ENTITY:DOCUMENT:KO',

  DOWNLOAD_ENTITY_DOCUMENT = 'DOWNLOAD:ENTITY:DOCUMENT',
  DOWNLOAD_ENTITY_DOCUMENT_OK = 'DOWNLOAD:ENTITY:DOCUMENT:OK',
  DOWNLOAD_ENTITY_DOCUMENT_KO = 'DOWNLOAD:ENTITY:DOCUMENT:KO',

  GET_ENTITY_DOCUMENT = 'GET:ENTITY:DOCUMENT',
  GET_ENTITY_DOCUMENT_OK = 'GET:ENTITY:DOCUMENT:OK',
  GET_ENTITY_DOCUMENT_KO = 'GET:ENTITY:DOCUMENT:KO',

  REMOVE_ENTITY_DOCUMENT = 'REMOVE:ENTITY:DOCUMENT',
  REMOVE_ENTITY_DOCUMENT_OK = 'REMOVE:ENTITY:DOCUMENT:OK',
  REMOVE_ENTITY_DOCUMENT_KO = 'REMOVE:ENTITY:DOCUMENT:KO',

  RESET_DOCUMENTS_ENTITY = 'RESET_DOCUMENTS_ENTITY',

  ENTITY_GET_DOCUMENTS = 'ENTITY:GET:DOCUMENTS',
  ENTITY_GET_DOCUMENTS_OK = 'ENTITY:GET:DOCUMENTS:OK',
  ENTITY_GET_DOCUMENTS_KO = 'ENTITY:GET:DOCUMENTS:KO',

  PREENTITY_UPLOAD_DOCUMENT = 'PREENTITY:UPLOAD:DOCUMENT',
  PREENTITY_UPLOAD_DOCUMENT_OK = 'PREENTITY:UPLOAD:DOCUMENT:OK',
  PREENTITY_UPLOAD_DOCUMENT_KO = 'PREENTITY:UPLOAD:DOCUMENT:KO',

  PREENTITY_DOWNLOAD_DOCUMENT = 'PREENTITY:DOWNLOAD:DOCUMENT',
  PREENTITY_DOWNLOAD_DOCUMENT_OK = 'PREENTITY:DOWNLOAD:DOCUMENT:OK',
  PREENTITY_DOWNLOAD_DOCUMENT_KO = 'PREENTITY:DOWNLOAD:DOCUMENT:KO',

  PREENTITY_REMOVE_DOCUMENT = 'PREENTITY:REMOVE:DOCUMENT',
  PREENTITY_REMOVE_DOCUMENT_OK = 'PREENTITY:REMOVE:DOCUMENT:OK',
  PREENTITY_REMOVE_DOCUMENT_KO = 'PREENTITY:REMOVE:DOCUMENT:KO',

  PREENTITY_GET_DOCUMENT = 'PREENTITY:GET:DOCUMENT',
  PREENTITY_GET_DOCUMENT_OK = 'PREENTITY:GET:DOCUMENT:OK',
  PREENTITY_GET_DOCUMENT_KO = 'PREENTITY:GET:DOCUMENT:KO',

  PREENTITY_SET_AVATAR = 'PREENTITY:SET:AVATAR',
  PREENTITY_SET_AVATAR_OK = 'PREENTITY:SET:AVATAR:OK',
  PREENTITY_SET_AVATAR_KO = 'PREENTITY:SET:AVATAR:KO',

  ENTITY_SET_AVATAR = 'ENTITY:SET:AVATAR',
  ENTITY_SET_AVATAR_OK = 'ENTITY:SET:AVATAR:OK',
  ENTITY_SET_AVATAR_KO = 'ENTITY:SET:AVATAR:KO',

  ENTITY_GET_AVATAR = 'ENTITY:GET:AVATAR',
  ENTITY_GET_AVATAR_OK = 'ENTITY:GET:AVATAR:OK',
  ENTITY_GET_AVATAR_KO = 'ENTITY:GET:AVATAR:KO',

  PREENTITY_GET_AVATAR = 'PREENTITY:GET:AVATAR',
  PREENTITY_GET_AVATAR_OK = 'PREENTITY:GET:AVATAR:OK',
  PREENTITY_GET_AVATAR_KO = 'PREENTITY:GET:AVATAR:KO',

  ENTITY_SET_DATA = 'ENTITY:SET:DATA',

  ENTITY_CONTACT_SET_DATA = 'ENTITY:CONTACT:SET:DATA',

  ENTITY_CHANGE_RESPONSIBLE = 'ENTITY:CHANGE:RESPONSIBLE',
  ENTITY_CHANGE_RESPONSIBLE_KO = 'ENTITY:CHANGE:RESPONSIBLE:KO',

  MAKE_PROVIDER = 'MAKE:PROVIDER',
  MAKE_PROVIDER_OK = 'MAKE:PROVIDER:OK',
  MAKE_PROVIDER_KO = 'MAKE:PROVIDER:KO',

  SET_MANDATORY_DOCUMENT = 'SET:MANDATORY:DOCUMENT',

  CREATE_NEW_CONTRACT = 'CREATE:NEW:CONTRACT',
  CREATE_NEW_CONTRACT_OK = 'CREATE:NEW:CONTRACT:OK',
  CREATE_NEW_CONTRACT_KO = 'CREATE:NEW:CONTRACT:KO',

  ADD_CONTRACT = 'ADD:CONTRACT',

  SET_CONTRACT_DATA = 'SET:CONTRACT:DATA',

  GET_CONTRACT = 'GET:CONTRACT',
  GET_CONTRACT_OK = 'GET:CONTRACT:OK',
  GET_CONTRACT_KO = 'GET:CONTRACT:KO',

  EDIT_CONTRACT = 'EDIT:CONTRACT',
  EDIT_CONTRACT_OK = 'EDIT:CONTRACT:OK',
  EDIT_CONTRACT_KO = 'EDIT:CONTRACT:KO',

  DELETE_CONTRACT = 'DELETE:CONTRACT',
  DELETE_CONTRACT_OK = 'DELETE:CONTRACT:OK',
  DELETE_CONTRACT_KO = 'DELETE:CONTRACT:KO',

  RESET_CONTRACT = 'RESET:CONTRACT',

  UPLOAD_CONTRACT_DOCUMENT = 'UPLOAD:CONTRACT:DOCUMENT',
  UPLOAD_CONTRACT_DOCUMENT_OK = 'UPLOAD:CONTRACT:DOCUMENT:OK',
  UPLOAD_CONTRACT_DOCUMENT_KO = 'UPLOAD:CONTRACT:DOCUMENT:KO',

  REMOVE_CONTRACT_DOCUMENT = 'REMOVE:CONTRACT:DOCUMENT',
  REMOVE_CONTRACT_DOCUMENT_OK = 'REMOVE:CONTRACT:DOCUMENT:OK',
  REMOVE_CONTRACT_DOCUMENT_KO = 'REMOVE:CONTRACT:DOCUMENT:KO',

  DOWNLOAD_CONTRACT_DOCUMENT = 'DOWNLOAD:CONTRACT:DOCUMENT',
  DOWNLOAD_CONTRACT_DOCUMENT_OK = 'DOWNLOAD:CONTRACT:DOCUMENT:OK',
  DOWNLOAD_CONTRACT_DOCUMENT_KO = 'DOWNLOAD:CONTRACT:DOCUMENT:KO',
  REJECT_INTERNAL_ENTITY = 'REJECT:INTERNAL:ENTITY',
  REJECT_INTERNAL_ENTITY_OK = 'REJECT:INTERNAL:ENTITY:OK',
  REJECT_INTERNAL_ENTITY_KO = 'REJECT:INTERNAL:ENTITY:KO',

  REJECT_EXTERNAL_ENTITY = 'REJECT:EXTERNAL:ENTITY',
  REJECT_EXTERNAL_ENTITY_OK = 'REJECT:EXTERNAL:ENTITY:OK',
  REJECT_EXTERNAL_ENTITY_KO = 'REJECT:EXTERNAL:ENTITY:KO',

  CONFIRM_ENTITY = 'CONFIRM:ENTITY',
  CONFIRM_ENTITY_OK = 'CONFIRM:ENTITY:OK',
  CONFIRM_ENTITY_KO = 'CONFIRM:ENTITY:KO',
  REJECT_ENTITY_DIRECTOR = 'REJECT:ENTITY:DIRECTOR',
  REJECT_ENTITY_DIRECTOR_OK = 'REJECT:ENTITY:DIRECTOR:OK',
  REJECT_ENTITY_DIRECTOR_KO = 'REJECT:ENTITY:DIRECTOR:KO',
  APPROVE_ENTITY_DIRECTOR = 'APPROVE:ENTITY:DIRECTOR',
  APPROVE_ENTITY_DIRECTOR_OK = 'APPROVE:ENTITY:DIRECTOR:OK',
  APPROVE_ENTITY_DIRECTOR_KO = 'APPROVE:ENTITY:DIRECTOR:KO',
  CLEAR_NAVIGATION = 'CLEAR:NAVIGATION',

  OPEN_CONTRACT_MODAL = 'ENTITY:OPEN:CONTRACT:MODAL',
  CLOSE_CONTRACT_MODAL = 'ENTITY:CLOSE:CONTRACT:MODAL',

  OPEN_CONTACT_MODAL = 'ENTITY:OPEN:CONTACT:MODAL',
  CLOSE_CONTACT_MODAL = 'ENTITY:CLOSE:CONTACT:MODAL',

  DELETE_CONTACT_DATA = 'DELETE:CONTACT:DATA',

  OPEN_IBAN_MODAL = 'OPEN:IBAN:MODAL',
  CLOSE_IBAN_MODAL = 'CLOSE:IBAN:MODAL',
  DELETE_IBAN_DATA = 'DELETE:IBAN:DATA',

  GET_ENTITY_IBAN = 'GET:ENTITY:IBAN',
  GET_ENTITY_IBAN_OK = 'GET:ENTITY:IBAN:OK',
  GET_ENTITY_IBAN_KO = 'GET:ENTITY:IBAN:KO',

  GET_ENTITY_ONE_IBAN = 'GET:ENTITY:ONE:IBAN',
  GET_ENTITY_ONE_IBAN_OK = 'GET:ENTITY:ONE:IBAN:OK',
  GET_ENTITY_ONE_IBAN_KO = 'GET:ENTITY:ONE:IBAN:KO',

  REMOVE_ENTITY_IBAN = 'REMOVE:ENTITY:IBAN',
  REMOVE_ENTITY_IBAN_OK = 'REMOVE:ENTITY:IBAN:OK',
  REMOVE_ENTITY_IBAN_KO = 'REMOVE:ENTITY:IBAN:KO',

  ADD_ENTITY_IBAN = 'ADD:ENTITY:IBAN',
  ADD_ENTITY_IBAN_OK = 'ADD:ENTITY:IBAN:OK',
  ADD_ENTITY_IBAN_KO = 'ADD:ENTITY:IBAN:KO',

  UPDATE_ENTITY_IBAN = 'UPDATE:ENTITY:IBAN',
  UPDATE_ENTITY_IBAN_OK = 'UPDATE:ENTITY:IBAN:OK',
  UPDATE_ENTITY_IBAN_KO = 'UPDATE:ENTITY:IBAN:KO',

  DOWNLOAD_ENTITY_IBAN_DOCUMENT = 'DOWNLOAD:ENTITY:IBAN:DOCUMENT',
  DOWNLOAD_ENTITY_IBAN_DOCUMENT_OK = 'DOWNLOAD:ENTITY:IBAN:DOCUMENT:OK',
  DOWNLOAD_ENTITY_IBAN_DOCUMENT_KO = 'DOWNLOAD:ENTITY:IBAN:DOCUMENT:KO',

  UPLOAD_ENTITY_IBAN_DOCUMENT = 'UPLOAD:ENTITY:IBAN:DOCUMENT',
  UPLOAD_ENTITY_IBAN_DOCUMENT_OK = 'UPLOAD:ENTITY:IBAN:DOCUMENT:OK',
  UPLOAD_ENTITY_IBAN_DOCUMENT_KO = 'UPLOAD:ENTITY:IBAN:DOCUMENT:KO',

  REMOVE_ENTITY_IBAN_DOCUMENT = 'REMOVE:ENTITY:IBAN:DOCUMENT',
  REMOVE_ENTITY_IBAN_DOCUMENT_OK = 'REMOVE:ENTITY:IBAN:DOCUMENT:OK',
  REMOVE_ENTITY_IBAN_DOCUMENT_KO = 'REMOVE:ENTITY:IBAN:DOCUMENT:KO',

  ENTITY_SET_IBAN_DATA = 'ENTITY:SET:IBAN:DATA',
}
