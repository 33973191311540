import { APIResponse, DocumentationItem, IdNamePair } from '../redux/common/definitions';
import { TypeLang } from '../redux/translations/definitions';
import {
  ChangePasswordData,
  CreateUserFields,
  RolePermission,
  UpdateUserFormFields,
  UserData,
  UserItemList,
  UserMailing,
  UserRoleConfig,
  UserType,
  UserZingMember,
} from '../redux/user/definitions';
import RoleCode from '../types/Roles/roleCode';
import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from './api';
import { mapUserMailingToUserItemList } from '../routes/Users/mapUserMailingToUserItemList';

const baseURL = apiBaseUrl;

interface DocumentsData {
  documents: DocumentationItem[];
}

interface DocumentsResponse {
  data: DocumentsData;
}

export const getProfile = async (): Promise<{ data: UserData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: UserData }>(`${baseURL}/user/profile`, init);
};

export const getUserData = async (id: number): Promise<{ data: UserData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: UserData }>(`${baseURL}/user/data/${id || 0}`, init);
};

export const signLopd = async (data: UpdateUserFormFields): Promise<Result<{ data: UserData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: UserData }>(`${baseURL}/user/profile/lopd`, init);
};

export const updateProfile = async (
  data: UpdateUserFormFields,
): Promise<Result<{ data: UserData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: UserData }>(`${baseURL}/user/profile`, init);
};

export const updateProfileTableUser = async (
  data: UpdateUserFormFields,
  id: number,
): Promise<Result<{ data: UserData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: UserData }>(`${baseURL}/user/profile/${id}`, init);
};

export const createUserApi = async (data: CreateUserFields): Promise<Result<{ data: 'OK' }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: 'OK' }>(`${baseURL}/user`, init);
};

export const createEducatorApi = async (
  data: CreateUserFields,
): Promise<Result<{ data: 'OK' }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: 'OK' }>(`${baseURL}/user/educator/send`, init);
};

export const uploadDocumentation = async (
  documentation_id: number,
  file: File,
  force_add?: boolean | false,
): Promise<DocumentsData> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const add = force_add ? 'add/' : '';
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/user/document/${add}${documentation_id}`, init);
  return data;
};

export const uploadDocumentationEducator = async (file: File): Promise<DocumentsData> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name || 'asd');
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/file`, init);
  return data;
};

export const getDocumentationEducator = async (): Promise<any> => {
  const role = RoleCode.EDUCADOR;
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: string }>(`${baseURL}/user/documentation/role/${role}`, init);
};

export const downloadDocumentation = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/user/document/download/${file_id}`, init);
};

export const getUserDocumentation = async (id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/user/documentation/${id}`, init);
};

export const deleteDocumentation = async (file_id: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/user/document/${file_id ? file_id : 0}`, init);
  return data;
};

export const deleteEducatorDocumentation = async (file_id: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/file/${file_id}`, init);
  return data;
};

export const getUsers = async (): Promise<UserItemList[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: UserItemList[] }>(`${baseURL}/users`, init);
  return data;
};

export const getUsersByType = async (user_type: UserType): Promise<{ data: UserItemList[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  if (user_type === 'mailing') {
    const response = await apiCall<{ data: UserMailing[] }>(`${baseURL}/users/mailing`, init);
    const userItemListArray = response.data.map(mapUserMailingToUserItemList);
    return { data: userItemListArray };
  }

  return apiCall<{ data: UserItemList[] }>(`${baseURL}/users/${user_type}`, init);
};

interface UsersData {
  users: number;
}

export const getDocumentation = async (id: number): Promise<DocumentationItem[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: DocumentationItem[] }>(`${baseURL}/user/documentation/${id}`, init);
  return data;
};

export const getForeingAvatar = async (id: number): Promise<{ data: string }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: string }>(`${baseURL}/user/image/${id}`, init);
};

export const setForeingAvatar = async (id: number, image: string): Promise<{ data: string }> => {
  const data = { image, filename: 'avatar.png' };

  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };
  return apiCall<{ data: string }>(`${baseURL}/user/image/${id}`, init);
};

export const userChangePassword = async (
  passwordData: ChangePasswordData,
): Promise<Result<APIResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(passwordData),
  };
  return validatedApiCall<APIResponse>(`${baseURL}/user/change-password`, init);
};

export const validateEducator = async (id: number): Promise<APIResponse> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  return apiCall<APIResponse>(`${baseURL}/user/educator/accept/${id}`, init);
};

export const rejectEducator = async (id: number): Promise<APIResponse> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  return apiCall<APIResponse>(`${baseURL}/user/educator/reject/${id}`, init);
};

export const userMetadataEntities = async (entity_type: 'project' | 'scholarship' | 'all'): Promise<IdNamePair[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<IdNamePair[]>(`${baseURL}/user/metadata/entities/${entity_type}`, init);
};

export const disableUserbyId = async (id: number) => {
  const init = {
    method: 'POST',
    auth: true,
  };

  return apiCall<{ data: UserItemList[] }>(`${baseURL}/user/disable/${id}`, init);
};

export const linkUserWithAcademy = async (userId: number): Promise<APIResponse> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  return apiCall<APIResponse>(`${baseURL}/user/link/academy/${userId}`, init);
};

export const getUserRolesFromApi = async (user_id: number): Promise<UserRoleConfig> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: UserRoleConfig }>(`${baseURL}/user/roles/${user_id}`, init);
  return data;
};

export const setUserActiveRole = async (user_id: number, role_id: number): Promise<APIResponse> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ user_id, role_id }),
  };

  return apiCall<APIResponse>(`${baseURL}/user/role/active`, init);
};

export const getUserByField = async (email: string, document_number: string): Promise<UserData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ email, document_number }),
  };

  const { data } = await apiCall<{ data: UserData }>(`${baseURL}/user/exists`, init);
  return data;
};

export const addUserRoleFromapi = async (user_id: number, role_id: number): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ user_id, role_id }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/user/add/role`, init);

  return data === 'OK';
};

export const removeUserRoleFromapi = async (user_id: number, role_id: number): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ user_id, role_id }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/user/remove/role`, init);

  return data === 'OK';
};

export const getRolePermissionsFromApi = async (role_id: number): Promise<RolePermission[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: RolePermission[] }>(`${baseURL}/user/role/permissions/${role_id}`, init);
  return data;
};

export const setUserPermissionFromApi = async (
  user_id: number,
  permission_id: number,
  value: boolean,
): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ user_id, permission_id }),
  };

  const action = value ? 'add' : 'remove';

  const { data } = await apiCall<{ data: string }>(`${baseURL}/user/${action}/permission`, init);

  return data === 'OK';
};

export const addUserToZingNetworkFromApi = async (
  user_id: number,
  send_welcome_mail: boolean,
): Promise<{ data: string }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ user_id, send_welcome_mail }),
  };

  return await apiCall<{ data: string }>(`${baseURL}/user/add/zing_network`, init);
};

export const removeUserFromZingNetwork = async (user_id: number): Promise<{ data: string }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ user_id }),
  };

  return await apiCall<{ data: string }>(`${baseURL}/user/remove/zing_network`, init);
};

export const getZingMembersApi = async (): Promise<UserZingMember[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: UserZingMember[] }>(`${baseURL}/zing_members`, init);
  return data;
};

export const removeZingMemberApi = async (member_id: number): Promise<boolean> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/zing_members/${member_id}`, init);
  return data === 'OK';
};

export const sendZingMembersApi = async (members: number[]): Promise<UserZingMember[]> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ members }),
  };

  const { data } = await apiCall<{ data: UserZingMember[] }>(`${baseURL}/zing_members`, init);
  return data;
};

export const changeUserLanguageFromApi = async (language: TypeLang): Promise<boolean> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ language }),
  };

  const { data } = await apiCall<{ data: string }>(`${baseURL}/user/change/language`, init);
  return data === 'OK';
};
