import { put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import {
  createEventRegistration,
  createEventSubscription,
  downloadEventDocument,
  getConnectionUrl,
  getEventData,
  getEventRegistrations,
  getEvents,
  getEventSubscriptions,
  getUserEvents,
  getUserRegistrationData,
  removeEventDocument,
  removeRegistration,
  removeSubscription,
  updateEventSubscription,
  updateEventTable,
  uploadEventDocument,
} from '../../api/event';
import assertUnreachable from '../../helpers/assertUnreachable';
import { getAreas } from '../area/action';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  createEventRegistrationKo,
  createEventRegistrationOk,
  createEventSubscriptionKo,
  createEventSubscriptionOk,
  downloadDocumentEventKo,
  downloadDocumentEventOk,
  eventValidationErrors,
  getConnectionUrlKo,
  getConnectionUrlOk,
  getEventDataKo,
  getEventDataOk,
  getEventRegistrationsKo,
  getEventRegistrationsOk,
  getEventsKo,
  getEventsOk,
  getEventSubscriptionsKo,
  getEventSubscriptionsOk,
  getUserEventsKo,
  getUserEventsOk,
  getUserRegistrationDataKo,
  getUserRegistrationDataOk,
  removeDocumentEventKo,
  removeDocumentEventOk,
  removeRegistrationKo,
  removeRegistrationOk,
  removeSubscriptionKo,
  removeSubscriptionOk,
  updateEventTableKo,
  updateEventTableOk,
  updateInscriptionDataKo,
  updateInscriptionDataOk,
  uploadDocumentEventOk,
} from './actions';
import { EventDataConsts } from './action_types';
import {
  CreateEventRegistrationAction,
  CreateEventSubscriptionAction,
  DownloadDocumentEventAction,
  GetConnectionUrlAction,
  GetEventDataAction,
  GetEventRegistrationsAction,
  GetEventsAction,
  GetEventSubscriptionsAction,
  GetUserEventsAction,
  GetUserRegistrationDataAction,
  RemoveDocumentEventAction,
  RemoveRegistrationAction,
  RemoveSubscriptionAction,
  UpdateEventSubscriptionDataAction,
  UpdateEventTableAction,
  UploadDocumentEventAction,
} from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getEventDataFromSaga(action: GetEventDataAction): Generator<any, void, any> {
  try {
    yield put(getAreas());
    const { id } = action;
    const { data } = (yield getEventData(id)) as Await<ReturnType<typeof getEventData>>;
    yield put(getEventDataOk(data));
  } catch (e) {
    yield put(getEventDataKo(e.message));
  }
}

function* getUserEventsDataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getUserEvents();
    yield put(getUserEventsOk(data));
  } catch (e) {
    yield put(getUserEventsKo(e.message));
  }
}

function* getEventsFromSaga(action: GetEventsAction): Generator<any, void, any> {
  try {
    const {
      data: {
        events,
        resume: { subareas },
      },
    } = yield getEvents();
    yield put(getEventsOk(events, subareas, action?.role));
  } catch (e) {
    yield put(getEventsKo(e));
  }
}

function* getEventSubscriptionsFromSaga(action: GetEventSubscriptionsAction): Generator<any, void, any> {
  try {
    const { data } = yield getEventSubscriptions(action.eventId);
    yield put(getEventSubscriptionsOk(data));
  } catch (e) {
    yield put(getEventSubscriptionsKo(e));
  }
}

function* getEventRegistrationsFromSaga(action: GetEventRegistrationsAction): Generator<any, void, any> {
  try {
    const { data } = yield getEventRegistrations(action.eventId);
    yield put(getEventRegistrationsOk(data));
  } catch (e) {
    yield put(getEventRegistrationsKo(e));
  }
}

function* createEventRegistrationFromSaga(action: CreateEventRegistrationAction): Generator<any, void, any> {
  try {
    const result = (yield createEventRegistration(action.eventRegistrationData)) as Await<
      ReturnType<typeof createEventRegistration>
    >;
    switch (result.type) {
      case 'ok':
        const info = result.value.data;
        yield put(createEventRegistrationOk(info));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(eventValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(showSnackbar(e.message, 'error'));
    yield put(createEventRegistrationKo(e));
  }
}

function* createEventSubscriptionFromSaga(action: CreateEventSubscriptionAction): Generator<any, void, any> {
  try {
    const result = (yield createEventSubscription(action.eventSubscriptionData)) as Await<
      ReturnType<typeof createEventSubscription>
    >;
    switch (result.type) {
      case 'ok':
        const info = result.value.data;
        yield put(createEventSubscriptionOk(info));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(eventValidationErrors(error));
        return;
    }
  } catch (e) {
    yield put(showSnackbar(e.message, 'error'));
    yield put(createEventSubscriptionKo(e));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* updateEventTableSaga(action: UpdateEventTableAction): Generator<any, void, any> {
  try {
    const result = (yield updateEventTable(action.data, action.id)) as Await<ReturnType<typeof updateEventTable>>;
    switch (result.type) {
      case 'ok':
        const info = result.value;
        yield put(updateEventTableOk(info));
        yield put(showSnackbar('Evento editado correctamente', 'success'));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(eventValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(updateEventTableKo(e));
    yield put(showSnackbar('El evento no se ha podido editar', 'error'));
  }
}

function* updateEventSubscriptionSaga(action: UpdateEventSubscriptionDataAction): Generator<any, void, any> {
  try {
    const result = (yield updateEventSubscription(action.eventId, action.eventRegistrationsList)) as Await<
      ReturnType<typeof updateEventSubscription>
    >;
    switch (result.type) {
      case 'ok':
        yield put(updateInscriptionDataOk());
        yield put(showSnackbar('Subscripción editada correctamente', 'success'));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(updateInscriptionDataKo(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(updateEventTableKo(e));
    yield put(showSnackbar('La subscripcion al evento no se ha podido editar', 'error'));
  }
}

function* removeRegistrationFromSaga(action: RemoveRegistrationAction): Generator<any, void, any> {
  try {
    const { eventId, registrationId } = action;
    yield removeRegistration(eventId, registrationId);
    yield put(removeRegistrationOk(registrationId));
  } catch (e) {
    yield put(removeRegistrationKo(e));
  }
}

function* removeSubscriptionFromSaga(action: RemoveSubscriptionAction): Generator<any, void, any> {
  try {
    const { eventId, registrationId } = action;
    yield removeSubscription(eventId, registrationId);
    yield put(removeSubscriptionOk(registrationId));
  } catch (e) {
    yield put(removeSubscriptionKo(e));
  }
}

function* getConnectionUrlFromSaga(action: GetConnectionUrlAction): Generator<any, void, any> {
  try {
    const { data } = yield getConnectionUrl(action.eventId, action.eventType);
    yield put(getConnectionUrlOk(data.url, data.startDate, data.endDate, data.desktopFile, data.full));
  } catch (e) {
    yield put(getConnectionUrlKo(e));
  }
}

function* getUserRegistrationDataFromSaga(action: GetUserRegistrationDataAction): Generator<any, void, any> {
  try {
    const { data } = yield getUserRegistrationData(action.eventId, action.userId);
    yield put(getUserRegistrationDataOk(data));
  } catch (e) {
    yield put(getUserRegistrationDataKo(e));
  }
}

function* uploadDocumentFromSaga(action: UploadDocumentEventAction): Generator<any, void, any> {
  try {
    const { documentationId, eventId, data } = action;
    const document = yield uploadEventDocument(documentationId, eventId, data);
    yield put(uploadDocumentEventOk(document));
    yield put(showSnackbar('Plantilla subida correctamente', 'success'));
  } catch (e) {
    yield put(uploadDocumentEventOk(e));
    yield put(showSnackbar('Ha ocurrido un error al subir la plantilla', 'error'));
  }
}

function* downloadDocumentFromSaga(action: DownloadDocumentEventAction): Generator<any, void, any> {
  try {
    const { fileId, eventId, name, get } = action;
    const document = yield downloadEventDocument(fileId, eventId);
    yield put(downloadDocumentEventOk(document, name, get));
  } catch (e) {
    yield put(downloadDocumentEventKo(e));
    yield put(showSnackbar('Ha ocurrido un error al descargar la plantilla', 'error'));
  }
}

function* removeDocumentFromSaga(action: RemoveDocumentEventAction): Generator<any, void, any> {
  try {
    const { fileId, eventId } = action;
    const document = yield removeEventDocument(fileId, eventId);
    yield put(removeDocumentEventOk(document));
    yield put(showSnackbar('Plantilla borrad correctamente', 'success'));
  } catch (e) {
    yield put(removeDocumentEventKo(e));
    yield put(showSnackbar('Ha ocurrido un error al borrar la plantilla', 'error'));
  }
}

export default [
  takeLatest<GetEventDataAction>(EventDataConsts.GET_EVENT_DATA, getEventDataFromSaga),
  takeLatest<GetEventsAction>(EventDataConsts.GET_EVENTS, getEventsFromSaga),
  takeLatest<GetEventSubscriptionsAction>(EventDataConsts.GET_EVENT_SUBSCRIPTIONS, getEventSubscriptionsFromSaga),
  takeLatest<GetEventRegistrationsAction>(EventDataConsts.GET_EVENT_REGISTRATIONS, getEventRegistrationsFromSaga),
  takeLatest<UpdateEventTableAction>(EventDataConsts.UPDATE_EVENT_TABLE, updateEventTableSaga),
  takeLatest<UpdateEventSubscriptionDataAction>(
    EventDataConsts.UPDATE_EVENT_SUBSCRIPTION_DATA,
    updateEventSubscriptionSaga,
  ),
  takeLatest<CreateEventRegistrationAction>(EventDataConsts.CREATE_EVENT_REGISTRATION, createEventRegistrationFromSaga),
  takeLatest<CreateEventSubscriptionAction>(EventDataConsts.CREATE_EVENT_SUBSCRIPTION, createEventSubscriptionFromSaga),
  takeLatest<RemoveRegistrationAction>(EventDataConsts.REMOVE_REGISTRATION, removeRegistrationFromSaga),
  takeLatest<RemoveSubscriptionAction>(EventDataConsts.REMOVE_SUBSCRIPTION, removeSubscriptionFromSaga),
  takeLatest<GetConnectionUrlAction>(EventDataConsts.GET_CONNECTION_URL, getConnectionUrlFromSaga),
  takeLatest<GetUserRegistrationDataAction>(
    EventDataConsts.GET_USER_REGISTRATION_DATA,
    getUserRegistrationDataFromSaga,
  ),
  takeLatest<UploadDocumentEventAction>(EventDataConsts.UPLOAD_DOCUMENT_EVENT, uploadDocumentFromSaga),
  takeLatest<DownloadDocumentEventAction>(EventDataConsts.DOWNLOAD_DOCUMENT_EVENT, downloadDocumentFromSaga),
  takeLatest<RemoveDocumentEventAction>(EventDataConsts.REMOVE_DOCUMENT_EVENT, removeDocumentFromSaga),
  takeLatest<GetUserEventsAction>(EventDataConsts.GET_USER_EVENTS, getUserEventsDataFromSaga),
];
