import React from 'react';
import { ActionsDocument, DocumentationItem } from '../../redux/common/definitions';
import { Check, Close, CloudDownload, CloudUpload, Delete, Pageview } from '@material-ui/icons';
import styles from './addDocuments.module.scss';

const Icons = {
  Visibility: <Pageview style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  VisibilityDis: <Pageview style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  CloudUploadDis: <CloudUpload style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  CloudUpload: <CloudUpload style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  Delete: <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  DeleteDis: <Delete style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  CloudDownload: <CloudDownload style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  CloudDownloadDis: <CloudDownload style={{ color: 'rgba(0, 0, 0, 0.2)' }} />,
  Check: <Check style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  Close: <Close color={'primary'} />,
};

interface Props {
  document: DocumentationItem;
  actions: ActionsDocument[];
  disabled: boolean;
  addRow: boolean;
  multiDocument: boolean;
  required: boolean;
  requiredMultiDocument: boolean;
  addMoreDocuments: boolean;
  onUpload: (fileFormat: string, file: File, rowData: DocumentationItem) => void;
  onOpenModal: (rowData: DocumentationItem) => void;
  onDownload: (rowData: DocumentationItem) => void;
  onRemoveFile: (rowData: DocumentationItem) => void;
  onGetFile: (rowData: DocumentationItem) => void;
  setShowModal: (open: boolean) => void;
}

const ShowOptionUpload = ({ document, actions, disabled, multiDocument, onOpenModal, onUpload }: Props) => {
  if (actions.includes('upload')) {
    if (disabled) {
      return Icons.CloudUploadDis;
    }
    if (multiDocument) {
      return (
        <label
          htmlFor={document.name}
          className={styles.view_file_label}
          onClick={() => {
            onOpenModal(document);
          }}
        >
          {Icons.CloudUpload}
        </label>
      );
    }
    return (
      <label htmlFor={document.name} className={styles.view_file_label}>
        {Icons.CloudUpload}
        <input
          type="file"
          id={document.name}
          key={document.documentation_id}
          name="document"
          className={styles.view_file}
          onChange={e => {
            if (e && e.target) {
              const formats = e.target.value.split('.');
              const fileFormat = formats[formats.length - 1];
              const file = e.target.files ? e.target.files[0] : null;
              if (file && fileFormat) {
                onUpload(fileFormat, file, document);
              }
            }
          }}
        />
      </label>
    );
  }
  return <></>;
};

const ShowOptionChecked = ({ document, actions }: Props) => {
  if (actions.includes('checked')) {
    return document.file_id !== null ? Icons.Check : Icons.Close;
  }
  return <></>;
};
const ShowOptionDownload = ({ document, actions, onDownload }: Props) => {
  if (actions.includes('download')) {
    return document.file_id !== null ? (
      <span
        onClick={() => {
          onDownload(document);
        }}
      >
        {Icons.CloudDownload}
      </span>
    ) : (
      Icons.CloudDownloadDis
    );
  }
  return <></>;
};
const ShowOptionView = ({ actions, document, onGetFile }: Props) => {
  if (actions.includes('view')) {
    return document.file_id !== null ? (
      <span
        onClick={() => {
          onGetFile(document);
        }}
      >
        {Icons.Visibility}
      </span>
    ) : (
      Icons.VisibilityDis
    );
  }
  return <></>;
};
const ShowOptionRemove = ({ actions, document, disabled, onRemoveFile }: Props) => {
  if (actions.includes('remove')) {
    return document.file_id !== null && !disabled ? (
      <span
        onClick={() => {
          onRemoveFile(document);
        }}
      >
        {Icons.Delete}
      </span>
    ) : (
      Icons.DeleteDis
    );
  }
  return <></>;
};
const AddDocumentActions = (props: Props) => {
  return (
    <>
      <ShowOptionChecked {...props} />
      <ShowOptionUpload {...props} />
      <ShowOptionDownload {...props} />
      <ShowOptionView {...props} />
      <ShowOptionRemove {...props} />
    </>
  );
};

export default AddDocumentActions;
