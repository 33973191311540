import { APIError } from '../../api/api';
import { NotificationsConsts } from './action_types';
import {
  MarkNotificationsAsReadAction,
  MarkNotificationsAsReadKoAction,
  MarkNotificationsAsReadOkAction,
  MessageThread,
  NewMessageAction,
  NewMessageActionKoAction,
  NewMessageActionOkAction,
  NotificationsRead,
  NotificationsStartPollingAction,
  NotificationsStopPollingAction,
  NotificationsUpdateAction,
  NotificationsUpdateKoAction,
  NotificationsUpdateOkAction,
  NotificationsUpdatePayload,
  RemovePendingTaskAction,
  RemovePendingTaskKoAction,
} from './definitions';

export function getNotificationsUpdate(): NotificationsUpdateAction {
  return {
    type: NotificationsConsts.NOTIFICATIONS_UPDATE,
  };
}

export function getNotificationsUpdateOk(data: NotificationsUpdatePayload): NotificationsUpdateOkAction {
  return {
    type: NotificationsConsts.NOTIFICATIONS_UPDATE_OK,
    updates: data,
  };
}

export function getNotificationsUpdateKo(error: APIError): NotificationsUpdateKoAction {
  return {
    type: NotificationsConsts.NOTIFICATIONS_UPDATE_KO,
    error,
  };
}

export function getNotificationsStartPolling(interval?: number): NotificationsStartPollingAction {
  return {
    type: NotificationsConsts.NOTIFICATIONS_START_POLLING,
    interval: interval,
  };
}

export function getNotificationsStopPolling(): NotificationsStopPollingAction {
  return {
    type: NotificationsConsts.NOTIFICATIONS_STOP_POLLING,
  };
}

export function markNotificationsAsRead(notifications: NotificationsRead): MarkNotificationsAsReadAction {
  return {
    type: NotificationsConsts.READ_NOTIFICATION,
    notifications,
  };
}

export function markNotificationsAsReadOk(notifications: NotificationsRead): MarkNotificationsAsReadOkAction {
  return {
    type: NotificationsConsts.READ_NOTIFICATION_OK,
    notifications,
  };
}

export function markNotificationsAsReadKo(error: APIError): MarkNotificationsAsReadKoAction {
  return {
    type: NotificationsConsts.READ_NOTIFICATION_KO,
    error,
  };
}

export interface NewMessage {
  channel: string;
  message: string;
  external_user: number;
  isNewThread: boolean;
}
export function newMessage(new_message: NewMessage): NewMessageAction {
  return {
    type: NotificationsConsts.NEW_MESSAGE,
    new_message,
  };
}

export function newMessageOk(message_thread: MessageThread, isNewThread: boolean): NewMessageActionOkAction {
  return {
    type: NotificationsConsts.NEW_MESSAGE_OK,
    message_thread,
    isNewThread,
  };
}

export function newMessageKo(error: APIError): NewMessageActionKoAction {
  return {
    type: NotificationsConsts.NEW_MESSAGE_KO,
    error,
  };
}

export function removePendingTask(notification_id: number): RemovePendingTaskAction {
  return {
    type: NotificationsConsts.REMOVE_PENDING_TASK,
    notification_id,
  };
}

export function removePendingTaskOk(notification_id: number): RemovePendingTaskAction {
  return {
    type: NotificationsConsts.REMOVE_PENDING_TASK_OK,
    notification_id,
  };
}

export function removePendingTaskKo(error: APIError): RemovePendingTaskKoAction {
  return {
    type: NotificationsConsts.REMOVE_PENDING_TASK_KO,
    error,
  };
}
