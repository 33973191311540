import { put, takeLatest } from '@redux-saga/core/effects';
import errorMessage from '../../../../helpers/errorMessage';
import { updateFamilyIncomeKo, updateFamilyIncomeOk } from './actions';
import { UpdateFamilyIncomeAction } from './definitions';
import { showSnackbar } from '../../../FeedbackAPI/actions';
import { tKey } from '../../../../helpers/translate';
import { UpdateFamilyIncomeConst } from './action_types';
import { updateFamilyIncomes } from '../../../../api/Scholarship/familyIncomes';

function* updateFamilyIncomeFromSaga(action: UpdateFamilyIncomeAction) {
  try {
    const { income } = action;
    yield updateFamilyIncomes(income);
    yield put(updateFamilyIncomeOk(income));
    yield put(showSnackbar(tKey('Income Modificado.'), 'success', undefined, 3000));
  } catch (error) {
    yield put(showSnackbar(tKey(errorMessage(error)), 'error', undefined, 1500));
    yield put(updateFamilyIncomeKo(errorMessage(error)));
  }
}

export default takeLatest<UpdateFamilyIncomeAction>(
  UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME,
  updateFamilyIncomeFromSaga,
);
