//  Checks that a expected path description is compatible with the specified destination
//  E.g. src: /bla/blub/:1
//      dest: /bla/blub/blob
//  are compatible

const checkUrl = (src: string, dest: string) => {
  const array_src = src.split('/');
  const array_dest = dest.split('/');
  if (array_src.length !== array_dest.length) {
    return false;
  }
  for (let i = 0; i < array_src.length; i++) {
    if (array_src[i] === array_dest[i]) continue;
    if (array_src[i][0] === ':') continue;
    return false;
  }
  return true;
};

export default checkUrl;
