import { put, takeLatest } from '@redux-saga/core/effects';
import { getUserPermissions } from '../../api/permissions';
import errorMessage from '../../helpers/errorMessage';
import { AuthorizationConsts } from '../auth/action_types';
import { UserActivationOkAction } from '../auth/definitions';
import { permissionsListOk } from '../permissions/actions';
import { permissionsListKo } from './actions';
import { PermissionsConsts } from './action_types';
import { PermissionsListAction } from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getUserPermissionsSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getUserPermissions();
    yield put(permissionsListOk(data));
  } catch (e) {
    yield put(permissionsListKo(errorMessage(e)));
  }
}

export default [
  takeLatest<PermissionsListAction>(PermissionsConsts.PERMISSIONS_LIST, getUserPermissionsSaga),
  takeLatest<UserActivationOkAction>(AuthorizationConsts.AUTH_USER_ACTIVATION_OK, getUserPermissionsSaga),
];
