import { AppState } from '../root-reducer';
import { FeedbackAPIState, SeveritySnackbar } from './definitions';

export const selectFeedbackAPI = (state: AppState): FeedbackAPIState => state.feedbackAPI;

export const selectIsSnackbarShowing = (state: AppState): boolean => selectFeedbackAPI(state).isSnackbarShowing;

export const selectMessageSnackbar = (state: AppState): string | null => selectFeedbackAPI(state).message;

export const selectSeveritySnackbar = (state: AppState): SeveritySnackbar | undefined =>
  selectFeedbackAPI(state).severity;

export const selectIsRedirect = (state: AppState): string | undefined => selectFeedbackAPI(state).redirect;

export const selectIsAutoHideDuration = (state: AppState): number | undefined =>
  selectFeedbackAPI(state).autoHideDuration;
