import { useState } from 'react';
import { paymentFiltersCodes } from '../../../../redux/scholarship/definitions';
import { DATE_FORMAT } from '../../../../constants/date';

export type ScholarshipPaymentValues = {
  paymentDate: string | null;
  reloadDate: string | null;
  show: string;
};

type UseScholarshipPaymentFiltersType = {
  filterValues: ScholarshipPaymentValues;
  applyFilters: (payment: moment.Moment | null, reload: moment.Moment | null, show: string) => void;
};

const useScholarshipPaymentFilters = (): UseScholarshipPaymentFiltersType => {
  const [filterValues, setFilterValues] = useState({
    paymentDate: null,
    reloadDate: null,
    show: paymentFiltersCodes.PENDING,
  } as ScholarshipPaymentValues);

  const applyFilters = (payment: moment.Moment | null, reload: moment.Moment | null, show: string) => {
    let paymentDate = null;
    let reloadDate = null;
    if (payment) {
      paymentDate = payment.format(DATE_FORMAT);
    }
    if (reload) {
      reloadDate = reload.format(DATE_FORMAT);
    }
    setFilterValues({ paymentDate, reloadDate, show });
  };

  return {
    filterValues,
    applyFilters,
  };
};

export default useScholarshipPaymentFilters;
