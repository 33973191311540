import React, { useState } from 'react';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import { ReactComponent as IconArrowOpen } from '../../images/icons/iconAccordeon.svg';
import styles from './FncAccordion.module.scss';

interface Props {
  title?: string;
  children: JSX.Element;
  disabledDesktop?: boolean;
}

const FncAccordion = ({ title, children, disabledDesktop = false }: Props) => {
  const isMobile = useWindowSize().IsMobile;
  const [isOpen, setIsOpen] = useState(true);

  const handleIsOpen = () => {
    if (disabledDesktop && !isMobile) {
      return false;
    }
    setIsOpen(!isOpen);
  };
  return (
    <div className={`${styles.root}  ${!isOpen ? styles.isClose : ''}`}>
      {title && (
        <div
          className={styles.title}
          onClick={() => {
            handleIsOpen();
          }}
        >
          <h2 className={styles.titleText}>{title}</h2>
          <IconArrowOpen className={`${styles.icon}`} />
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.animationWrapper}>{children}</div>
      </div>
    </div>
  );
};

export default FncAccordion;
