import { put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../../api/api';
import {
  addUserToService,
  downloadServiceDocument,
  getServiceData,
  getServiceRequestedData,
  getServices,
  getUserServices,
  removeServiceRequested,
  removeServiceDocument,
  updateService,
  updateServiceAssistanceData,
  uploadServiceDocument,
} from '../../api/service';
import assertUnreachable from '../../helpers/assertUnreachable';
import { getAreas } from '../area/action';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  addUserToServiceKo,
  addUserToServiceOk,
  downloadDocumentServiceKo,
  downloadDocumentServiceOk,
  getServiceDataKo,
  getServiceDataOk,
  getServiceRequestedKo,
  getServiceRequestedOk,
  getServicesKo,
  getServicesOk,
  getUserServicesKo,
  getUserServicesOk,
  removeServiceRequestedKo,
  removeServiceRequestedOk,
  removeDocumentServiceKo,
  removeDocumentServiceOk,
  serviceValidationErrors,
  updateServiceKo,
  updateServiceOk,
  uploadDocumentServiceOk,
} from './actions';
import { ServiceDataConsts } from './action_types';
import {
  AddUserToServiceAction,
  DownloadDocumentServiceAction,
  GetServiceDataAction,
  GetServiceRequestedDataAction,
  GetServicesAction,
  GetUserServicesAction,
  RemoveServiceRequestedDataAction,
  RemoveDocumentServiceAction,
  UpdateServiceAction,
  UpdateServiceAssistanceDataAction,
  UploadDocumentServiceAction,
} from './definitions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getServiceDataFromSaga(action: GetServiceDataAction): Generator<any, void, any> {
  try {
    yield put(getAreas());
    const { id } = action;
    const { data } = (yield getServiceData(id)) as Await<ReturnType<typeof getServiceData>>;
    yield put(getServiceDataOk(data));
  } catch (e) {
    yield put(getServiceDataKo(e.message));
  }
}

function* getUserServicesDataFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getUserServices();
    yield put(getUserServicesOk(data));
  } catch (e) {
    yield put(getUserServicesKo(e.message));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* updateServiceFromSaga(action: UpdateServiceAction): Generator<any, void, any> {
  try {
    const result = (yield updateService(action.data, action.id)) as Await<ReturnType<typeof updateService>>;
    switch (result.type) {
      case 'ok':
        const info = result.value;
        yield put(updateServiceOk(info));
        yield put(showSnackbar('Evento editado correctamente', 'success'));
        return;
      case 'validation-error':
        const error = result.value;
        yield put(serviceValidationErrors(error));
        return;
    }
    assertUnreachable(result);
  } catch (e) {
    yield put(updateServiceKo(e));
    yield put(showSnackbar('El servicio no se ha podido editar', 'error'));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* addUserToServiceFromSaga(action: AddUserToServiceAction): Generator<any, void, any> {
  try {
    const { id, service_id } = action;
    const { data } = yield addUserToService(id, service_id);
    yield put(addUserToServiceOk(data));
    yield put(showSnackbar('Usuario añadido al servicio', 'success'));
  } catch (e) {
    yield put(addUserToServiceKo(e));
    yield put(showSnackbar('El usuario no se ha podido añadir al servicio', 'error'));
  }
}

function* getServicesFromSaga(action: GetServicesAction): Generator<any, void, any> {
  try {
    const {
      data: {
        services,
        resume: { subareas },
      },
    } = yield getServices();
    yield put(getServicesOk(services, subareas, action?.role));
  } catch (e) {
    yield put(getServicesKo(e));
  }
}

function* getServiceRequestDataFromSaga(action: GetServiceRequestedDataAction): Generator<any, void, any> {
  try {
    const { id } = action;
    const { data } = (yield getServiceRequestedData(id)) as Await<ReturnType<typeof getServiceRequestedData>>;
    yield put(getServiceRequestedOk(data));
  } catch (e) {
    yield put(getServiceRequestedKo(e.message));
  }
}

function* updateServiceAssistanceDataFromSaga(action: UpdateServiceAssistanceDataAction): Generator<any, void, any> {
  try {
    const { data, id } = action;
    yield updateServiceAssistanceData(id, data);
    yield put(showSnackbar('Se han actualizado los datos', 'success'));
  } catch (e) {
    yield put(showSnackbar('La operación no ha podido ser procesada', 'error'));

    yield put(getServiceRequestedKo(e.message));
  }
}

function* removeServiceRequestDataFromSaga(action: RemoveServiceRequestedDataAction): Generator<any, void, any> {
  try {
    const { requestedId } = action;
    yield removeServiceRequested(requestedId);
    yield put(removeServiceRequestedOk(requestedId));
    yield put(showSnackbar('Solicitante eliminado con éxito', 'success'));
  } catch (e) {
    yield put(showSnackbar('La operación no ha podido ser procesada', 'error'));
    yield put(removeServiceRequestedKo(e.message));
  }
}
function* uploadDocumentFromSaga(action: UploadDocumentServiceAction): Generator<any, void, any> {
  try {
    const { documentationId, serviceId, data } = action;
    const document = yield uploadServiceDocument(documentationId, serviceId, data);
    yield put(uploadDocumentServiceOk(document));
    yield put(showSnackbar('Plantilla subida correctamente', 'success'));
  } catch (e) {
    yield put(uploadDocumentServiceOk(e));
    yield put(showSnackbar('Ha ocurrido un error al subir la plantilla', 'error'));
  }
}

function* downloadDocumentFromSaga(action: DownloadDocumentServiceAction): Generator<any, void, any> {
  try {
    const { fileId, serviceId, name, get } = action;
    const document = yield downloadServiceDocument(fileId, serviceId);
    yield put(downloadDocumentServiceOk(document, name, get));
  } catch (e) {
    yield put(downloadDocumentServiceKo(e));
    yield put(showSnackbar('Ha ocurrido un error al descargar la plantilla', 'error'));
  }
}

function* removeDocumentFromSaga(action: RemoveDocumentServiceAction): Generator<any, void, any> {
  try {
    const { fileId, serviceId } = action;
    const document = yield removeServiceDocument(fileId, serviceId);
    yield put(removeDocumentServiceOk(document));
    yield put(showSnackbar('Plantilla borrada correctamente', 'success'));
  } catch (e) {
    yield put(removeDocumentServiceKo(e));
    yield put(showSnackbar('Ha ocurrido un error al borrar la plantilla', 'error'));
  }
}

export default [
  takeLatest<GetServicesAction>(ServiceDataConsts.GET_SERVICES, getServicesFromSaga),
  takeLatest<GetServiceDataAction>(ServiceDataConsts.GET_SERVICE_DATA, getServiceDataFromSaga),
  takeLatest<UpdateServiceAction>(ServiceDataConsts.UPDATE_SERVICE, updateServiceFromSaga),
  takeLatest<GetServiceRequestedDataAction>(ServiceDataConsts.GET_SERVICES_REQUESTED, getServiceRequestDataFromSaga),
  takeLatest<AddUserToServiceAction>(ServiceDataConsts.ADD_USER_TO_SERVICE, addUserToServiceFromSaga),
  takeLatest<GetUserServicesAction>(ServiceDataConsts.GET_USER_SERVICES, getUserServicesDataFromSaga),
  takeLatest<UpdateServiceAssistanceDataAction>(
    ServiceDataConsts.UPDATE_SERVICE_ASSISTANCE,
    updateServiceAssistanceDataFromSaga,
  ),
  takeLatest<RemoveServiceRequestedDataAction>(
    ServiceDataConsts.REMOVE_SERVICE_REQUESTED,
    removeServiceRequestDataFromSaga,
  ),
  takeLatest<UploadDocumentServiceAction>(ServiceDataConsts.UPLOAD_DOCUMENT_SERVICE, uploadDocumentFromSaga),
  takeLatest<DownloadDocumentServiceAction>(ServiceDataConsts.DOWNLOAD_DOCUMENT_SERVICE, downloadDocumentFromSaga),
  takeLatest<RemoveDocumentServiceAction>(ServiceDataConsts.REMOVE_DOCUMENT_SERVICE, removeDocumentFromSaga),
];
