import { Menu, MenuItem } from '@material-ui/core';
import { Column } from 'material-table';
import React from 'react';
import { getExportDataFromApi, ResponseExport } from '../../api/exports';
import { exportToExcel } from '../../helpers/exportToXslx';
import { tKey } from '../../helpers/translate';
import styles from './exportList.module.scss';

export interface Exports {
  title: string;
  titleFile: string;
  columns?: Column<any>[];
  data?: any;
  dataBody?: any;
  endpoint?: string;
  onClick?: any;
}

export interface ExportListProps {
  exportsList: Exports[];
}

export interface DataBody {
  academic_year: string;
  type: string;
}

const ExportList: React.FC<ExportListProps> = ({ exportsList }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const callToEndpoint = async (endpoint: string, titleFile: string, dataBody?: DataBody) => {
    if (dataBody && dataBody.academic_year && dataBody.academic_year.includes('-')) {
      const academicYearReplaced = dataBody.academic_year.replace('-', '/');
      const data: ResponseExport = await getExportDataFromApi(endpoint, {
        academic_year: academicYearReplaced,
        type: dataBody.type,
      });
      exportToExcel(data.columns, data.data, titleFile);
    } else {
      const data: ResponseExport = await getExportDataFromApi(endpoint, dataBody);
      exportToExcel(data.columns, data.data, titleFile);
    }
  };
  return (
    <div className={styles.exportListContainer}>
      <div onClick={(event: any) => handleClick(event)}>{tKey('Exportar')}</div>
      {/* <GetAppIcon onClick={(event: any) => handleClick(event)}> Exportar </GetAppIcon> */}
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {exportsList.map((element: Exports) =>
          element.columns ? (
            <MenuItem
              key={element.title}
              onClick={() => {
                exportToExcel(element.columns, element.data, element.title);
                handleClose();
              }}
            >
              {element.title}
            </MenuItem>
          ) : element.onClick ? (
            <MenuItem
              key={element.title}
              onClick={() => {
                element.onClick();
                handleClose();
              }}
            >
              {element.title}
            </MenuItem>
          ) : (
            <MenuItem
              key={element.title}
              onClick={() => {
                element.endpoint && callToEndpoint(element.endpoint, element.titleFile, element.dataBody);
                handleClose();
              }}
            >
              {element.title}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
};

export default ExportList;
