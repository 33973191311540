import { ButtonProps } from '@material-ui/core';
import {
  Check,
  EventNote,
  FindReplace,
  GetApp,
  Image,
  InsertDriveFile,
  InsertLink,
  Save,
  Send,
  Warning,
} from '@material-ui/icons';
import CheckBox from '@material-ui/icons/CheckBox';
import moment from 'moment/moment';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { exportProjectCustomFields } from '../../api/Projects/projects';
import ImageGalleryModal from '../../components/Modal/ImageGalleryModal/ImageGalleryModal';
import TransitionModal from '../../components/Modal/TransitionModal';
import { getDocs } from '../../helpers/getDocs';
import { tErrorKey, tKey, tProjectKey } from '../../helpers/translate';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import { selectIsNousCims, selectUserInfo, selectUserRoleCode } from '../../redux/auth/selectors';
import { DocumentationItem, KeyButton, ResourceImageData } from '../../redux/common/definitions';
import { CodeSectionProject } from '../../redux/kpis/definitions';
import { selectUserCan } from '../../redux/permissions/selectors';
import {
  addProjectImage,
  changeState,
  deleteProjectImage,
  editProjectImage,
  getActorsMetadata,
  getOneProject,
  getProjectImages,
  projectCancelAgreement,
} from '../../redux/project/action';
import {
  ButtonsStateControl,
  ProjectChangeStateKo,
  ProjectChangeStateOk,
  ProjectData,
  ProjectDispatch,
  StateProject,
  buttonsDatas,
} from '../../redux/project/definitions';
import { selectDocumentation, selectHasAgreement, selectProjectData } from '../../redux/project/selectors';
import { AppState } from '../../redux/root-reducer';
import RoleCode from '../../types/Roles/roleCode';
import RefuseEntityModal from '../Entities/RefuseEntityModal';
import CreateProject from './CreateProjectNew';
import FollowUp from './FollowUp/FollowUp';
import AgreementDataForm from './FormProject/AgreementDataForm/AgreementDataForm';
import TracingAndPayment from './FormProject/Calendar/Calendar';
import DefinitionsKPIS from './FormProject/DefinitionKPIS/DefinitionKPIS';
import FollowAndPayment from './FormProject/FollowAndPayment/FollowAndPayment';
import ProjectGenericSection from './ProjectGenericSection';
import ProjectKpisSection from './ProjectKpisSection';

const TrackingModal = React.lazy(() => import('../../components/Modal/TrackingModal/TrackingModal'));

interface ProjectFlowProps
  extends RouteComponentProps<{ id: string; section?: CodeSectionProject; schedule_id?: string }> {
  project: ProjectData;
  documentation: DocumentationItem[];
  roleCode: RoleCode;
  user_id: number;
  isNouscims: boolean;
  loadingImages: boolean;
  projectImages: ResourceImageData[];
  allowProjectModify: boolean;
  hasAgreement: boolean;
  getOneProject: (id: string) => void;
  getActorsMetadata: (id_project: number) => void;
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string) => void;
  getProjectImages: (id: number) => void;
  addProjectImage: (projectId: number, name: string, description: string, image: string, userId: number) => void;
  editProjectImage: (
    projectId: number,
    projectImage: ResourceImageData,
    name: string,
    description: string,
    newImage: string,
    userId: number,
  ) => void;
  deleteProjectImage: (projectId: number, projectImage: ResourceImageData) => void;
  cancelProjectAgreement: (projectId: number) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const ProjectFlow: React.FC<ProjectFlowProps> = ({
  match,
  project,
  user_id,
  documentation,
  roleCode,
  isNouscims,
  loadingImages,
  projectImages,
  allowProjectModify,
  hasAgreement,
  getOneProject,
  getActorsMetadata,
  changeState,
  getProjectImages,
  addProjectImage,
  editProjectImage,
  deleteProjectImage,
  cancelProjectAgreement,
  showSnackbar,
}) => {
  const {
    params: { id, section, schedule_id },
  } = match;

  const project_schedule_id = schedule_id ? parseInt(schedule_id) : 0;

  let disableSection = false;
  const [trackingModal, setTrackingModal] = useState(false);
  const [createdModal, setCreatedModal] = useState({
    view: false,
    children: <Fragment />,
    title: tProjectKey('Motivo del rechazo.'),
  });
  const [followUpNotesModal, setFollowUpNotesModal] = useState({
    view: false,
    children: <span />,
    title: tProjectKey('Notas de Seguimiento'),
  });
  const [showImageGalleryModal, setShowImageGalleryModal] = useState<boolean>(false);

  useEffect(() => {
    getOneProject(id);
  }, [id, getOneProject]);

  useEffect(() => {
    getActorsMetadata(parseInt(id));
  }, [id, getActorsMetadata]);

  const buttonSAVE = {
    children: tProjectKey('Guardar'),
    key: KeyButton.SAVE,
    startIcon: <Save />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'save',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    disableElevation: true,
  } as ButtonProps;

  const buttonPay = {
    children: tProjectKey('Pagar'),
    key: KeyButton.PAY,
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    disableElevation: true,
    startIcon: <Send />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'pay',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
  } as ButtonProps;

  const buttonLinkPayment = {
    children: tProjectKey('Vincular factura'),
    key: KeyButton.LINK_PAYMENT,
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    disableElevation: true,
    startIcon: <InsertLink />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'pay',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
  } as ButtonProps;

  const buttonSend = {
    children: tProjectKey('Enviar'),
    onClick: () => {
      changeState(project.id, buttonsDatas.getTransition(project.state_code, 'send'));
    },
    startIcon: <Send />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'send',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    key: KeyButton.SEND,
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    disableElevation: true,
    disabled: !project?.steps?.complete || !hasAgreement,
  } as ButtonProps;

  const buttonAprove = {
    children: tProjectKey('aprobar'),
    onClick: () => changeState(project.id, buttonsDatas.getTransition(project.state_code, 'aprove')),
    startIcon: <Send />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'aprove',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    key: KeyButton.APPROVE,
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    disableElevation: true,
    disabled: !project?.steps?.complete,
  } as ButtonProps;

  const buttonRefuse = {
    children: tProjectKey('rechazar'),
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setCreatedModal(prevState => ({
        ...prevState,
        view: true,
        children: (
          <RefuseEntityModal
            handleClose={() => {
              setCreatedModal(prevState => ({ ...prevState, view: false }));
            }}
            handleSend={comments =>
              changeState(project.id, buttonsDatas.getTransition(project.state_code, 'refuse'), comments)
            }
          />
        ),
      }));
    },
    key: KeyButton.REFUSE,
    startIcon: <Warning />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'refuse',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    disableElevation: true,
    fullWidth: true,
    variant: 'contained',
    color: 'secondary',
  } as ButtonProps;

  const buttonValidate = {
    children:
      project.state_code === StateProject.FOLLOWUP_EVALUATION_4 ? tProjectKey('finalizar') : tProjectKey('validar'),
    onClick: () => changeState(project.id, buttonsDatas.getTransition(project.state_code, 'validate')),
    startIcon: <Check />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'validate',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    key: KeyButton.VALIDATE,
    disableElevation: true,
    disabled: !project?.steps?.complete,
  } as ButtonProps;

  const buttonReview = {
    children: tProjectKey('revisión'),
    onClick: () => changeState(project.id, buttonsDatas.getTransition(project.state_code, 'review')),
    startIcon: <FindReplace />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'review',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    key: KeyButton.REVIEW,
    disableElevation: true,
    fullWidth: true,
    variant: 'contained',
    color: 'secondary',
  } as ButtonProps;

  const buttonCancelSignature = {
    children: isNouscims ? tProjectKey('Cancelar firma') : tProjectKey('Revisión de cláusulas'),
    onClick: () => cancelProjectAgreement(project.id),
    startIcon: <FindReplace />,
    key: KeyButton.CANCEL_SIGNATURE,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'cancel_signature',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    disableElevation: true,
    fullWidth: true,
    variant: 'contained',
    color: 'secondary',
  } as ButtonProps;

  const buttonFollowNote = {
    children: tProjectKey('Notas de Seguimiento') + ' ' + project.trackings,
    disableElevation: true,
    onClick: () =>
      setFollowUpNotesModal(prevState => ({
        ...prevState,
        children: <FollowUp projectID={project.id} />,
        view: true,
      })),
    startIcon: <InsertDriveFile />,
    hidden: buttonsDatas.showButton(
      project.state_code as keyof ButtonsStateControl,
      'follow_note',
      roleCode.value(),
      user_id,
      project.owner_rol,
      project.owner_id,
    ),
    key: KeyButton.FOLLOW,
    fullWidth: true,
    variant: 'contained',
    color: 'secondary',
  } as ButtonProps;

  const buttonModify = {
    children: tProjectKey('Modificar Proyecto'),
    disableElevation: true,
    startIcon: <EventNote />,
    key: KeyButton.MODIFY,
    fullWidth: true,
    variant: 'contained',
    color: 'secondary',
  } as ButtonProps;

  const buttonImageGallery = {
    children: tProjectKey('Galería de imágenes'),
    disableElevation: true,
    onClick: () => {
      if (!projectImages) getProjectImages(project.id);
      setShowImageGalleryModal(true);
    },
    startIcon: <Image />,
    hidden: true, // de momento lo dejamos oculto en proyectos
    key: KeyButton.IMAGE_GALLERY,
    fullWidth: true,
    variant: 'contained',
    color: 'secondary',
  } as ButtonProps;

  const buttonsActions: ButtonProps[] = [];

  const moreInfoButtons: ButtonProps[] = [];

  if (isNouscims) {
    moreInfoButtons.push({
      children: tKey('TRACKING'),
      startIcon: <CheckBox />,
      fullWidth: true,
      color: 'primary',
      onClick: () => {
        setTrackingModal(true);
      },
    });

    const onExport = async () => {
      try {
        const csv = await exportProjectCustomFields(project.id);
        const fileName = `custom_fields_proyecto_${moment().format('DDMMYYYY')}.xlsx`;
        getDocs(csv, true, fileName);
      } catch (e) {
        showSnackbar(tErrorKey('error_exportar_project_custom_fields'), 'error', undefined, 1000);
      }
    };

    moreInfoButtons.push({
      children: tProjectKey('Histórico de secciones'),
      onClick: onExport,
      startIcon: <GetApp />,
      disableElevation: true,
      color: 'primary',
    });
  }

  switch (project.state_code) {
    case StateProject.ID_2:
      if (isNouscims) {
        buttonsActions.push(buttonSAVE);
        buttonsActions.push(buttonSend);
      }
      disableSection = !isNouscims;
      break;
    case StateProject.EVALUATION_1:
    case StateProject.EVALUATION_2:
      if (isNouscims && section === 'kpis_gf') {
        buttonsActions.push(buttonAprove);
      }
      disableSection = !isNouscims;
      break;
    case StateProject.AGREEMENT:
      if (isNouscims) {
        buttonsActions.push(buttonSAVE);
        if (section === 'asistente_convenios' || section === 'calendario') {
          buttonsActions.push(buttonSend);
        }
      }
      disableSection = !isNouscims;
      break;
    case StateProject.AGREEMENT_REVIEW:
      if (isNouscims && (section === 'asistente_convenios' || section === 'calendario')) {
        buttonsActions.push(buttonAprove);
        buttonsActions.push(buttonRefuse);
      }
      disableSection = true;
      break;
    case StateProject.AGREEMENT_SIGNING:
      if ((isNouscims && section === 'asistente_convenios') || section === 'calendario') {
        buttonsActions.push(buttonRefuse);
        buttonsActions.push(buttonSend);
      }
      disableSection = true;
      break;
    case StateProject.SIGNATURE_PENDING:
      if (section === 'asistente_convenios' || section === 'firma_convenio' || section === 'calendario') {
        buttonsActions.push(buttonCancelSignature);
      }
      disableSection = true;
      break;
    case StateProject.KICK_OFF:
      if (section === 'asistente_convenios' || section === 'calendario') {
        buttonsActions.push(buttonCancelSignature);
      }
      disableSection = true;
      break;
    case StateProject.TRACKING:
      const allowedSections = ['kpis', 'new_kpis', 'kpis_rvw'];
      if (section) {
        if (isNouscims) {
          if (allowedSections.includes(section)) {
            buttonSAVE.hidden = false;
            buttonsActions.push(buttonSAVE);
            if (section === 'kpis') {
              buttonsActions.push(buttonSend);
            }
          }
          if (allowProjectModify && section === 'calendario') {
            buttonsActions.push(buttonModify);
          }
        }
        if (!isNouscims && section === 'kpis') {
          buttonSAVE.hidden = false;
          buttonsActions.push(buttonSAVE);
          buttonsActions.push(buttonSend);
        }
      }
      break;
    case StateProject.TRACKING_VALIDATION:
      if (isNouscims && (section === 'informe_seguimiento' || section === 'new_kpis')) {
        buttonsActions.push(buttonSAVE);
        buttonsActions.push(buttonReview);
        buttonsActions.push(buttonValidate);
      } else if (allowProjectModify && section === 'calendario') {
        buttonsActions.push(buttonModify);
      } else {
        disableSection = true;
      }
      if (isNouscims && section === 'kpis_rvw') {
        buttonSAVE.hidden = false;
        buttonsActions.push(buttonSAVE);
        disableSection = false;
      }
      break;
    case StateProject.TRACKING_FINISHED:
      if (isNouscims && section === 'kpis_rvw') {
        buttonSAVE.hidden = false;
        buttonsActions.push(buttonSAVE);
        disableSection = false;
      } else if (allowProjectModify && section === 'calendario') {
        buttonsActions.push(buttonModify);
      }
      break;
    case StateProject.TRACKING_FNC:
      if (isNouscims && section === 'kpis') {
        buttonsActions.push(buttonSAVE);
        buttonsActions.push(buttonSend);
      } else {
        disableSection = true;
      }
      break;
    case StateProject.PAYMENT:
      if (isNouscims && section === 'seguimiento_pago') {
        buttonsActions.push(buttonReview);
        buttonsActions.push(buttonPay);
        buttonsActions.push(buttonLinkPayment);
      } else if (allowProjectModify && section === 'calendario') {
        buttonsActions.push(buttonModify);
      } else {
        disableSection = true;
      }
      break;
    case StateProject.FOLLOWUP_EVALUATION_2:
      if (
        isNouscims &&
        (section === 'informe_seguimiento' || section === 'evaluacion_personal' || section === 'evaluacion_proyecto')
      ) {
        buttonsActions.push(buttonSAVE);
        buttonsActions.push(buttonSend);
      } else {
        disableSection = true;
      }
      break;
    case StateProject.FOLLOWUP_EVALUATION_3:
    case StateProject.FOLLOWUP_EVALUATION_4:
      if (isNouscims && (section === 'evaluacion_personal' || section === 'evaluacion_proyecto')) {
        buttonsActions.push(buttonSAVE);
        buttonsActions.push(buttonReview);
        buttonsActions.push(buttonValidate);
      } else {
        disableSection = true;
      }
      break;
  }

  if (!disableSection) {
    disableSection = buttonsActions.every(button => button.hidden);
  }

  buttonsActions.push(buttonImageGallery);
  buttonsActions.push(buttonFollowNote);

  const getCorrectPanel = () => {
    switch (section) {
      case undefined:
      case 'datos_basicos':
        return (
          <CreateProject
            buttons={buttonsActions}
            moreInfoButtons={moreInfoButtons}
            disabled={
              buttonsDatas.showButton(
                project.state_code as keyof ButtonsStateControl,
                'save',
                roleCode.value(),
                user_id,
                project.owner_rol,
              ) || disableSection
            }
          />
        );
      case 'calendario':
        return (
          <TracingAndPayment buttons={buttonsActions} moreInfoButtons={moreInfoButtons} disabled={disableSection} />
        );
      case 'firma_convenio':
        return (
          <AgreementDataForm
            readOnly={disableSection}
            buttons={buttonsActions}
            moreInfoButtons={moreInfoButtons}
            documentation={documentation}
          />
        );
      case 'seguimiento_pago':
        return (
          <FollowAndPayment
            readOnly={disableSection}
            buttons={buttonsActions}
            moreInfoButtons={moreInfoButtons}
            project={project}
          />
        );
      case 'kpis_gf':
        if (!project.untracked_project)
          return (
            <DefinitionsKPIS
              section={section}
              buttons={buttonsActions}
              moreInfoButtons={moreInfoButtons}
              project={project}
            />
          );
        return <Redirect to={`/proyecto/detalle/${project.id}/datos_basicos`} />;
      case 'new_kpis':
        if (!project.untracked_project)
          return (
            <DefinitionsKPIS
              section={section}
              buttons={buttonsActions}
              moreInfoButtons={moreInfoButtons}
              project={project}
            />
          );
        return <Redirect to={`/proyecto/detalle/${project.id}/datos_basicos`} />;
      case 'asistente_convenios':
        return (
          <AgreementDataForm
            readOnly={disableSection}
            buttons={buttonsActions}
            moreInfoButtons={moreInfoButtons}
            documentation={documentation}
          />
        );
      case 'kpis_rvw':
      case 'kpis':
        if (!project.untracked_project)
          return (
            <ProjectKpisSection
              section="kpis"
              id_project={parseInt(id)}
              project_schedule_id={project_schedule_id}
              project={project}
              buttons={buttonsActions}
              moreInfoButtons={moreInfoButtons}
              disabled={disableSection}
            />
          );
        return <Redirect to={`/proyecto/detalle/${project.id}/datos_basicos`} />;
      default:
        return (
          <ProjectGenericSection
            section={section}
            id_project={parseInt(id)}
            project_schedule_id={section === 'informe_seguimiento' ? project_schedule_id : 0}
            project={project}
            buttons={buttonsActions}
            moreInfoButtons={moreInfoButtons}
            disabled={disableSection}
          />
        );
    }
  };

  return project.forms_done === 0 ? (
    <Redirect to={`/proyecto/asistente-formularios/${project.id}/logica_proyecto`} />
  ) : (
    <Fragment>
      {getCorrectPanel()}
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        bodyModal=" "
      >
        {createdModal.children}
      </TransitionModal>
      <TransitionModal
        view={followUpNotesModal.view}
        handleClose={() => {
          setFollowUpNotesModal(prevState => ({ ...prevState, view: false }));
        }}
        title={followUpNotesModal.title}
        bodyModal=" "
        followUpMode
      >
        {followUpNotesModal.children}
      </TransitionModal>
      {showImageGalleryModal && (
        <ImageGalleryModal
          open={true}
          loading={loadingImages}
          title={`${project.name} - ${tKey('Imágenes')}`}
          images={projectImages}
          canAddImage={isNouscims}
          handleAddImage={(name: string, description: string, image: string) =>
            addProjectImage(project.id, name, description, image, user_id)
          }
          handleEditImage={(projectImgId: number, name: string, description: string, newImage: string) => {
            const currentProjectImage = projectImages.find((e: ResourceImageData) => e.id === projectImgId);
            if (currentProjectImage)
              editProjectImage(project.id, currentProjectImage, name, description, newImage, user_id);
          }}
          handleDeleteImage={(projectImgId: number) => {
            const currentProjectImage = projectImages.find((e: ResourceImageData) => e.id === projectImgId);
            if (currentProjectImage) deleteProjectImage(project.id, currentProjectImage);
          }}
          handleClose={() => setShowImageGalleryModal(false)}
        />
      )}
      <Suspense fallback={<></>}>
        <TrackingModal
          view={trackingModal}
          resource_id={project.id}
          resource_type={'Project'}
          title={tKey('Proyecto').concat(' ', project.name)}
          handleClose={() => setTrackingModal(false)}
        />
      </Suspense>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  project: selectProjectData(state),
  isNouscims: selectIsNousCims(state),
  documentation: selectDocumentation(state),
  roleCode: selectUserRoleCode(state),
  user_id: selectUserInfo(state).user_id,
  loadingImages: state.projectReducer.projectData.loadingImages,
  projectImages: state.projectReducer.projectData.images,
  allowProjectModify: selectUserCan(state)('action_project_modify'),
  hasAgreement: selectHasAgreement(state),
});

const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  getOneProject: (id: string) => dispatch(getOneProject(id)),
  getActorsMetadata: (id_project: number): void => dispatch(getActorsMetadata(id_project)),
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string): void =>
    dispatch(changeState(id, states, comments)),
  getProjectImages: (projectId: number) => dispatch(getProjectImages(projectId)),
  addProjectImage: (projectId: number, name: string, description: string, image: string, userId: number) =>
    dispatch(addProjectImage(projectId, name, description, image, userId)),
  editProjectImage: (
    projectId: number,
    projectImage: ResourceImageData,
    name: string,
    description: string,
    newImage: string,
    userId: number,
  ) => dispatch(editProjectImage(projectId, projectImage, name, description, newImage, userId)),
  deleteProjectImage: (projectId: number, projectImage: ResourceImageData) =>
    dispatch(deleteProjectImage(projectId, projectImage)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
  cancelProjectAgreement: (projectId: number) => dispatch(projectCancelAgreement(projectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectFlow);
