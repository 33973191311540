import React from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError, FieldErrors, FieldName, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../../helpers/customHooks/useIsRequired';
import FncSelect, { OptionSelect } from './FncSelect';

export interface FncSelectControllerProps<T extends FieldValues> extends React.ComponentPropsWithoutRef<'select'> {
  control: Control<T>;
  errors: FieldErrors<T>;
  name: FieldName<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  customClass?: string;
  label?: string;
  disabled?: boolean;
  options: OptionSelect[];
  defaultValue?: string | number;
  placeholder?: string;
}

const FncSelectController = <T extends FieldValues>({
  options,
  customClass,
  disabled,
  label,
  errors,
  control,
  schema,
  name,
  defaultValue,
  placeholder,
  ...props
}: FncSelectControllerProps<T>) => {
  const hasError = errors[name] as FieldError;
  const required = useIsRequired(name, schema);
  return (
    <Controller
      name={name || `Select_${Math.random()}`}
      control={control}
      defaultValue={defaultValue}
      as={
        <FncSelect
          options={options}
          placeholder={placeholder}
          errorMessage={hasError && hasError.message ? (hasError.message as string) : ''}
          disabled={disabled}
          label={label}
          required={required}
          customClass={customClass}
          {...props}
        />
      }
    />
  );
};

export default FncSelectController;
