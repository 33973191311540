import moment from 'moment';
import { APIValidationError } from '../../api/api';
import { AppState } from '../root-reducer';
import {
  AccountingSettings,
  AccountingState,
  ExpenseAccount,
  InvoiceCode,
  InvoiceData,
  InvoiceDetail,
  ScholarshipSetting,
} from './definitions';

export const selectAccounting = (state: AppState): AccountingState => state.accountingReducer;
export const selectExpensesList = (state: AppState): ExpenseAccount[] => selectAccounting(state).expenseAccountList;
export const selectLoading = (state: AppState): boolean => selectAccounting(state).loading;
export const selectExpense = (state: AppState): ExpenseAccount => selectAccounting(state).expenseAccount;
export const selectInvoice = (state: AppState): InvoiceData => selectAccounting(state).invoiceData;
export const selectValidationErrors = (state: AppState): APIValidationError | null =>
  selectAccounting(state).validationErrors;
export const selectAccountingSettings = (state: AppState): AccountingSettings =>
  selectAccounting(state).accountingSettings;
export const selectInvoiceDetail = (state: AppState): InvoiceDetail => selectAccounting(state).invoiceDetail;
export const selectInvoicesDetailList = (state: AppState): InvoiceDetail[] =>
  selectAccounting(state).invoicesDetailList;

export const selectInvoicesDetailBaseTotal = (state: AppState): number => {
  const details = selectAccounting(state).invoiceData.invoice_details;
  const total = details?.reduce((a, b) => a + Number(b.base_amount), 0);
  return total ? Math.round(total * 100) / 100 : 0;
};

export const selectInvoicesDetailRetentionTotal = (state: AppState): number => {
  const details = selectAccounting(state).invoiceData.invoice_details;
  const total = details?.reduce((a, b) => a + (Number(b.tax_retention_amount) || 0), 0);
  return total ? Math.round(total * 100) / 100 : 0;
};

export const selectInvoicesDetailTotal = (state: AppState): number => {
  const totalBase = selectInvoicesDetailBaseTotal(state);
  const totalRetention = selectInvoicesDetailRetentionTotal(state);
  return Math.round((totalBase + totalRetention) * 100) / 100;
};

export const selectInvoicesRetentionTotal = (state: AppState): number => {
  const totalBase = selectInvoicesDetailBaseTotal(state);
  const retentionPercentage = selectAccounting(state).invoiceData.retention_percentage || 0;
  return Math.round(totalBase * (retentionPercentage / 100) * 100) / 100;
};

export const selectInvoicesCalculatedTotal = (state: AppState): number => {
  const invoice = selectInvoice(state);
  const detailTotal = selectInvoicesDetailTotal(state);
  const totalRetention = selectInvoicesRetentionTotal(state);

  if (invoice.serial === InvoiceCode.ISP || invoice.serial === InvoiceCode.INTRA) {
    return selectInvoicesDetailBaseTotal(state);
  } else {
    return Math.round((detailTotal - totalRetention) * 100) / 100;
  }
};

export const selectScholarshipSetting = (state: AppState): ScholarshipSetting =>
  state.accountingReducer.scholarshipSetting;

export const selectInstitutePaymentDateFilter = (state: AppState): moment.Moment | null =>
  state.accountingReducer.instituteScholarshipPaymentDateFilter;

export const selectInstituteReloadDateFilter = (state: AppState): moment.Moment | null =>
  state.accountingReducer.instituteScholarshipReloadDateFilter;
