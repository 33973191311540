/* eslint-disable @typescript-eslint/no-explicit-any */
import { put, takeLatest } from '@redux-saga/core/effects';
import {
  addAreasRolesFromApi,
  addSubarea,
  deleteSubArea,
  disableAreaFromApi,
  editAreaFromApi,
  editSubarea,
  getAreas,
  getAreasRolesFromApi,
  getRolesFromApi,
  removeAreasRolesFromApi,
  storeArea,
} from '../../api/Areas/areas';
import errorMessage from '../../helpers/errorMessage';
import { showSnackbar } from '../FeedbackAPI/actions';
import {
  addRoleAreaKo,
  addRoleAreaOk,
  addSubareaKo,
  addSubareaOk,
  deleteSubAreaKo,
  deleteSubAreaOk,
  disableAreaKo,
  disableAreaOk,
  editAreaKo,
  editAreaOk,
  editSubAreaKo,
  editSubAreaOk,
  getAreasKo,
  getAreasOk,
  getAreasRolesKo,
  getAreasRolesOk,
  getRolesAreasKo,
  getRolesAreasOk,
  removeAreasRolesOk,
  storeAreasKo,
  storeAreasOk,
} from './action';
import { AreasConst } from './action_types';
import {
  AddRoleAreaAction,
  AddSubareaAction,
  DeleteSubAreaAction,
  DisableAreaAction,
  EditAreaAction,
  EditSubAreaAction,
  GetAreasAction,
  GetAreasRolesAction,
  GetRolesAreasAction,
  RemoveAreasRolesAction,
  StoreAreasAction,
} from './definitions';

function* getAreasListFromSaga(): Generator<any, void, any> {
  try {
    const areasData = yield getAreas();
    yield put(getAreasOk(areasData));
  } catch (e) {
    yield put(getAreasKo(errorMessage(e)));
  }
}

function* getAreasRoleListFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getAreasRolesFromApi();
    yield put(getAreasRolesOk(data));
  } catch (e) {
    yield put(getAreasRolesKo(errorMessage(e)));
  }
}

function* getRoleListFromSaga(): Generator<any, void, any> {
  try {
    const { data } = yield getRolesFromApi();
    yield put(getRolesAreasOk(data));
  } catch (e) {
    yield put(getRolesAreasKo(errorMessage(e)));
  }
}

//REMOVE AREAS ROLE
function* removeAreasRolesFromSaga(action: RemoveAreasRolesAction): Generator<any, void, any> {
  const { area_id, role_id } = action;
  try {
    yield removeAreasRolesFromApi(area_id, role_id);
    yield put(removeAreasRolesOk(area_id, role_id));
  } catch (e) {
    yield put(deleteSubAreaKo(errorMessage(e)));
    yield put(showSnackbar('Esta Subarea no se puede borrar', 'error', undefined, 4000));
  }
}

//ADD AREAS ROLE
function* addAreasRolesFromSaga(action: AddRoleAreaAction): Generator<any, void, any> {
  const { area_id, role_id } = action;
  try {
    const { data } = yield addAreasRolesFromApi(area_id, role_id);
    yield put(addRoleAreaOk(data));
  } catch (e) {
    yield put(addRoleAreaKo(errorMessage(e)));
  }
}
// DELETE SUBAREA

function* deleteSubAreaFromSaga(action: DeleteSubAreaAction): Generator<any, void, any> {
  try {
    yield deleteSubArea(action.id);
    yield put(deleteSubAreaOk(action.id));
    yield put(showSnackbar('Subarea borrada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(deleteSubAreaKo(errorMessage(e)));
    yield put(showSnackbar('Esta Subarea no se puede borrar', 'error', undefined, 4000));
  }
}

function* addSubareaFromSaga(action: AddSubareaAction): Generator<any, void, any> {
  try {
    const info = yield addSubarea(action.id, action.data);
    yield put(addSubareaOk(info));
    yield put(showSnackbar('Subarea Añadida correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(addSubareaKo(errorMessage(e)));
    yield put(showSnackbar('No se ha podido añadir la Subarea', 'error', undefined, 4000));
  }
}

function* editSubAreaFromSaga(action: EditSubAreaAction): Generator<any, void, any> {
  try {
    yield editSubarea(action.id, action.data);
    yield put(editSubAreaOk(action.id, action.data));
    yield put(showSnackbar('Subarea editada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(editSubAreaKo(errorMessage(e)));
    yield put(showSnackbar('Esta Subarea no se ha podido editar', 'error', undefined, 4000));
  }
}

function* storeAreaFromSaga(action: StoreAreasAction): Generator<any, void, any> {
  try {
    const data = yield storeArea(action.name);
    yield put(storeAreasOk(data));
    yield put(showSnackbar('Área creada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(storeAreasKo(errorMessage(e)));
    yield put(showSnackbar('El área no se ha podido crear', 'error', undefined, 4000));
  }
}

function* editAreaFromSaga(action: EditAreaAction): Generator<any, void, any> {
  const { id, data } = action;
  try {
    yield editAreaFromApi(id, data);
    yield put(editAreaOk(id, data));
    yield put(showSnackbar('Área editada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(editAreaKo(errorMessage(e)));
    yield put(showSnackbar('Esta área no se ha podido editar', 'error', undefined, 4000));
  }
}

function* disalbleAreaFromSaga(action: DisableAreaAction): Generator<any, void, any> {
  const { a_id } = action;
  try {
    yield disableAreaFromApi(a_id);
    yield put(disableAreaOk(a_id));
    yield put(showSnackbar('Área eliminada correctamente', 'success', undefined, 3000));
  } catch (e) {
    yield put(disableAreaKo(errorMessage(e)));
    yield put(showSnackbar('Esta área no se ha podido eliminar', 'error', undefined, 4000));
  }
}

export default [
  takeLatest<AddRoleAreaAction>(AreasConst.ADD_ROLES_LIST, addAreasRolesFromSaga),
  takeLatest<GetRolesAreasAction>(AreasConst.GET_ROLES_LIST, getRoleListFromSaga),
  takeLatest<RemoveAreasRolesAction>(AreasConst.REMOVE_ROLES_AREA, removeAreasRolesFromSaga),
  takeLatest<GetAreasRolesAction>(AreasConst.GET_ROLES_AREA, getAreasRoleListFromSaga),
  takeLatest<GetAreasAction>(AreasConst.GET_AREAS, getAreasListFromSaga),
  takeLatest<DeleteSubAreaAction>(AreasConst.DELETE_SUB_AREA, deleteSubAreaFromSaga),
  takeLatest<AddSubareaAction>(AreasConst.ADD_SUBAREA, addSubareaFromSaga),
  takeLatest<EditSubAreaAction>(AreasConst.EDIT_SUBAREA, editSubAreaFromSaga),
  takeLatest<StoreAreasAction>(AreasConst.STORE_AREAS, storeAreaFromSaga),
  takeLatest<EditAreaAction>(AreasConst.EDIT_AREA, editAreaFromSaga),
  takeLatest<DisableAreaAction>(AreasConst.DISABLE_AREA, disalbleAreaFromSaga),
];
