import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import catalanFlag from '../../images/icons/catalan.png';
import englishFlag from '../../images/icons/english.png';
import frenchFlag from '../../images/icons/french.png';
import spanishFlag from '../../images/icons/spanish.png';
import { getPlatformTranslation } from '../../redux/auth/actions';
import { AppState } from '../../redux/root-reducer';
import { LangCodes } from '../../redux/translations/definitions';
import styles from './language.module.scss';

interface LanguageProps {
  language: string;
  getTranslation: (language_code: string) => void;
}

const Language: React.FC<LanguageProps> = ({ language, getTranslation }) => {
  const [langCode, setLangCode] = useState<string>(language);

  useEffect(() => {
    getTranslation(langCode);
  }, [langCode, getTranslation]);

  const handleChangeLanguage = (language_code: string) => {
    setLangCode(language_code);
  };

  return (
    <div className={`${styles.languageContainer} ${styles.hiddenInMobile}`}>
      <div className={styles.language}>
        <label htmlFor="icon-button-file">
          <IconButton
            aria-label="upload picture"
            component="span"
            size="small"
            onClick={() => {
              handleChangeLanguage(LangCodes.ES);
            }}
          >
            <img src={spanishFlag} alt="spanish" className={styles.flag} />
          </IconButton>
        </label>
        <label>
          <IconButton
            aria-label="upload picture"
            component="span"
            size="small"
            onClick={() => {
              handleChangeLanguage(LangCodes.CA);
            }}
          >
            <img src={catalanFlag} alt="catalan" className={styles.flag} />
          </IconButton>
        </label>
        <label htmlFor="icon-button-file" className={styles.flag}>
          <IconButton
            aria-label="upload picture"
            component="span"
            size="small"
            onClick={() => {
              handleChangeLanguage(LangCodes.EN);
            }}
          >
            <img src={englishFlag} alt="english" className={styles.flag} />
          </IconButton>
        </label>
        <label htmlFor="icon-button-file" className={styles.flag}>
          <IconButton
            aria-label="upload picture"
            component="span"
            size="small"
            onClick={() => {
              handleChangeLanguage(LangCodes.FR);
            }}
          >
            <img src={frenchFlag} alt="french" className={styles.flag} />
          </IconButton>
        </label>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.authReducer.loading,
  language: state.authReducer.language,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTranslation: (language_code: string) => dispatch(getPlatformTranslation(language_code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Language);
