import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import { tScholarshipKey } from '../../../../helpers/translate';

interface RenounceViewFormProps {
  renounce_comment: string | null;
  renounce_date: string | null;
  state_code: string;
}

const RenounceViewForm: React.FC<RenounceViewFormProps> = ({ renounce_comment, renounce_date, state_code }) => {
  const { t } = useTranslation();

  const label_text = tScholarshipKey(t(`scholarship.shared.personal_information.renounce_comment_${state_code}`));
  let date_formatted = '';
  if (renounce_date) {
    date_formatted = ` (${moment(renounce_date).format('DD/MM/YYYY')})`;
  }

  return (
    <FormContainer title={`${label_text}${date_formatted}`}>
      <TextInput
        label={tScholarshipKey(t(`scholarship.shared.personal_information.renounce_comment`))}
        defaultValue={renounce_comment}
        multiline
        disabled
        fullWidth
        rows="4"
        rowsMax="8"
        name="renounce_comment"
      />
    </FormContainer>
  );
};

export default RenounceViewForm;
