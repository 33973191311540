import { useEffect, useState, useRef } from 'react';

export const useNearScreen = ({
  distance = '0px',
  externalRef,
  once = true,
  root,
}: {
  distance: string;
  externalRef: any;
  once: boolean;
  root: string;
}) => {
  const [isNearScreen, setShow] = useState(false);

  const fromRef = useRef();

  useEffect(() => {
    const current = externalRef ? externalRef.current : fromRef.current;
    const onChange = (entries: IntersectionObserverEntry | any, observer: IntersectionObserver) => {
      const element = entries[0];
      if (element.isIntersecting) {
        setShow(true);
        once && observer.unobserve(current);
      } else {
        !once && setShow(false);
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: distance,
      root: document.querySelector(root),
    });
    current && observer.observe(current);
    return () => observer && observer.disconnect();
  });
  return { isNearScreen, fromRef };
};
