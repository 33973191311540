import { InternalProjectId, Participant } from '../Participant';
import { GetParticipantsConst } from './action_types';
import {
  CancelGetParticipantsAction,
  GetParticipantsAction,
  GetParticipantsKoAction,
  GetParticipantsOkAction,
} from './definitions.d';

export function getParticipants(id: InternalProjectId): GetParticipantsAction {
  return {
    type: GetParticipantsConst.GET_PARTICIPANTS,
    id,
  };
}

export function getParticipantsOk(data: Participant[]): GetParticipantsOkAction {
  return {
    type: GetParticipantsConst.GET_PARTICIPANTS_OK,
    data: data,
  };
}

export function getParticipantsKo(error: string): GetParticipantsKoAction {
  return {
    type: GetParticipantsConst.GET_PARTICIPANTS_KO,
    error,
  };
}

export function cancelGET_ParticipantsOk(): CancelGetParticipantsAction {
  return {
    type: GetParticipantsConst.CANCEL_GET_PARTICIPANTS,
  };
}
