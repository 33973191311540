import { MenuItem } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import FormContainer from '../../../../components/Forms/FormContainer';
import styles from '../../../../components/Inputs/inputs.module.scss';
import ValidatedSelect from '../../../../components/Select/ValidatedSelect/ValidatedSelect';
import * as DateConstants from '../../../../constants/date';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { validateFields } from '../../../../helpers/validator';
import requiredStyle from '../../../../helpers/validator.module.scss';
import { legalNaturesArray } from '../../../../redux/common/definitions';
import { EntityInfo } from '../../../../redux/entity/definitions';
import { EntityLegalSchema } from '../../../../validations/Entities/entityFormSchema';

interface LegalFormProps {
  entity: EntityInfo;
  disabled?: boolean;
  setData: (key: string, value: string | number) => void;
}

const LegalForm: React.FC<LegalFormProps> = ({ entity, disabled, setData }) => {
  const { creation_date, legal_nature } = entity;

  const { errors, watch, setValue, register } = useForm<{ creation_date: string; legal_nature: string }>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EntityLegalSchema,
  });

  const changeDates = (name: string, date: moment.Moment | null) => {
    if (date && date.isValid()) {
      setData(name, date.format(DateConstants.DATE_FORMAT));
    }
  };

  const asingDateValue = (date: string | number | null) => {
    return date ? moment(date, DateConstants.DATE_FORMAT) : null;
  };

  const handleChangeSelect = (fieldName: any, event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(fieldName, event.target.value as string);
    setData(fieldName, event.target.value as string);
  };

  return (
    <FormContainer title={tKey('Datos Legales')}>
      <KeyboardDatePicker
        name="creation_date"
        label={tFormKey('Fecha de Constitución')}
        error={!!errors.creation_date}
        format={DateConstants.DATE_FORMAT}
        variant="inline"
        inputVariant="outlined"
        helperText={errors.creation_date}
        placeholder="" // Prevent google to detect this input as a credit card number
        className={!!validateFields(entity, 'creation_date') ? requiredStyle.required_50 : styles.size_50}
        value={asingDateValue(creation_date)}
        onChange={(date: MaterialUiPickersDate) => {
          changeDates('creation_date', date);
        }}
        autoOk
        disabled={disabled}
        inputRef={register}
        onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          if (!e.target.value) setData(e.target.name, '');
        }}
      />
      <ValidatedSelect
        errors={errors}
        schema={EntityLegalSchema}
        name="legal_nature"
        value={watch('legal_nature') || legal_nature}
        disabled={disabled}
        register={register}
        label={tFormKey('Naturaleza Jurídica')}
        size="50"
        handleChange={handleChangeSelect}
        validator={validateFields(entity, 'legal_nature')}
      >
        {legalNaturesArray.map(legalNature => (
          <MenuItem key={legalNature} value={legalNature}>
            {legalNature}
          </MenuItem>
        ))}
      </ValidatedSelect>
    </FormContainer>
  );
};

export default LegalForm;
