import { ButtonProps } from '@material-ui/core';
import React, { useRef } from 'react';
import { connect } from 'react-redux';
import DragAndDropCustomFields, {
  DragAndDropCustomFieldsRef,
} from '../../../../components/DragAndDrop/DragAndDropCustomFields/DragAndDropCustomFields';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import { useHookMethod } from '../../../../helpers/customHooks/useHookMethod';
import { tKey } from '../../../../helpers/translate';
import { KeyButton } from '../../../../redux/common/definitions';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { CodeSectionProject } from '../../../../redux/kpis/definitions';
import { changeState } from '../../../../redux/project/action';
import {
  buttonsDatas,
  ProjectChangeStateKo,
  ProjectChangeStateOk,
  ProjectData,
  ProjectDispatch,
} from '../../../../redux/project/definitions';
import ProjectMenuRight from '../../ProjectMenuRight/ProjectMenuRight';
import SubmenuProject from '../../SubmenuProject/SubmenuProject';

interface DefinitionsKPISProps {
  section: CodeSectionProject;
  buttons: ButtonProps[];
  moreInfoButtons: ButtonProps[];
  project: ProjectData;
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const DefinitionsKPIS: React.FC<DefinitionsKPISProps> = ({
  section,
  buttons,
  moreInfoButtons,
  project,
  changeState,
  showSnackbar,
}) => {
  const dragAndDropRef = useRef<DragAndDropCustomFieldsRef>(null);

  const handleSave = () => {
    dragAndDropRef.current?.saveData();
  };

  const handleSaveWithSnackbar = () => {
    handleSave();
    showSnackbar(tKey('La confirmación se ha realizado correctamente'), 'success', undefined, 1500);
  };

  const onApprove = () => {
    try {
      dragAndDropRef.current?.saveData();
      changeState(project.id, buttonsDatas.getTransition(project.state_code, 'aprove'));
    } catch (error) {
      showSnackbar((error as Error).message, 'error', undefined, 1500);
    }
  };

  useHookMethod(buttons, [KeyButton.SAVE, KeyButton.APPROVE], [handleSaveWithSnackbar, onApprove]);

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuProject current_section={section} project={project} buttons={buttons} onClickOption={handleSave} />
      }
      rightSubmenu={<ProjectMenuRight actionButtons={buttons} moreInfoButtons={moreInfoButtons} project={project} />}
    >
      <DragAndDropCustomFields ref={dragAndDropRef} />
    </LayoutForm>
  );
};

const mapDispatchToProps = (dispatch: ProjectDispatch) => ({
  changeState: (id: number, states: ProjectChangeStateOk | ProjectChangeStateKo, comments?: string): void =>
    dispatch(changeState(id, states, comments)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(null, mapDispatchToProps)(DefinitionsKPIS);
