import { AppState } from '../root-reducer';
import { SubareaInternalProjectTypeState } from './definition.d';
import { SubareaInternalProjectType } from './subareaInternalProjectType.d';

export const selectSubareaInternalProjectType = (state: AppState): SubareaInternalProjectTypeState =>
  state.subareaInternalProjectTypeReducer;

export const selectIsLoading = (state: AppState): boolean => selectSubareaInternalProjectType(state).loading;

export const selectIsError = (state: AppState): string | null => selectSubareaInternalProjectType(state).error;

export const selectSubareaInternalProjectTypeList = (state: AppState): SubareaInternalProjectType[] =>
  selectSubareaInternalProjectType(state).data;
