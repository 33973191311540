import { ButtonProps } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import React, { Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import FncAddDocuments from '../../../../../components/UploadFile/FncAddDocuments';
import Title from '../../../../../components/Title/Title';
import { tFormKey, tKey } from '../../../../../helpers/translate';
import { DocumentationItem } from '../../../../../redux/common/definitions';
import {
  applyConfigTemplate,
  deleteDocumentsInternalProject,
  deleteMembers,
  downloadDocumentAgreement,
  downloadDocumentsInternalProject,
  removeDocumentInternalProject,
  updateInternalProject,
  uploadDocument,
  uploadDocumentsInternalProject,
} from '../../../../../redux/internal-projects/actions';
import {
  GeneralDataForm,
  InternalProject,
  InternalProjectForms,
} from '../../../../../redux/internal-projects/definitions';
import { AppState } from '../../../../../redux/root-reducer';
import { InformationSchema } from '../../../../../validations/formSchema';
import InternalProjectGeneric from '../../InternalProjectGeneric/InternalProjectGeneric';
import InternalProjectLayoutForm from '../../InternalProjectLayoutForm';

interface GeneralDataProps {
  id: number;
  disabled?: boolean;
  internalProject: InternalProject;
  isEntity: boolean;
  documentationsAgreement: DocumentationItem[];
  documentations: DocumentationItem[];
  buttonImageGallery: ButtonProps;
  handleGoBack?: () => void;
  handleChangeSection: (componentId: string) => void;
  addDocument: (data: File, description: string, internalProjectId: number, code: string) => void;
  updateInternalProject: (internalProject: InternalProjectForms, id: number) => void;
  uploadDocumentAgreement: (internalProjectId: number, data: File) => void;
  downloadDocumentAgreement: (file_id: number, name: string, internal_project_id: number, get?: boolean) => void;
  removeDocumentAgreement: (fileId: number, eventId: number) => void;
  removeDocument: (file_id: number, internalProjectId: number) => void;
  downloadDocument: (file_id: number, name: string, internal_project_id: number, get?: boolean) => void;
  applyConfigTemplate: (internalProjectId: number) => void;
}

const GeneralData: React.FC<GeneralDataProps> = ({
  id,
  disabled,
  internalProject,
  isEntity,
  documentationsAgreement,
  documentations,
  buttonImageGallery,
  handleGoBack,
  handleChangeSection,
  addDocument,
  uploadDocumentAgreement,
  updateInternalProject,
  downloadDocumentAgreement,
  removeDocumentAgreement,
  removeDocument,
  downloadDocument,
  applyConfigTemplate,
}) => {
  const actionsAgreements = ['checked', 'upload', 'download', 'view', 'remove'];
  const actions = ['checked', 'download', 'view', 'remove'];

  useEffect(() => {
    if (documentationsAgreement?.length) {
      documentationsAgreement.forEach((document: DocumentationItem) => {
        document.mandatory = true;
        document.name = tKey('Documento de Convenio');
      });
    }
  });

  const { errors, control, getValues } = useForm<GeneralDataForm>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InformationSchema,
  });

  const buttonProps: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      startIcon: <SaveIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: disabled,
    },
    {
      children: tKey('Enviar'),
      startIcon: <SendIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: disabled,
      hidden: true,
    },
    {
      children: tKey('Aplicar plantilla'),
      startIcon: <SendIcon />,
      color: 'primary',
      variant: 'contained',
      fullWidth: true,
      disableElevation: true,
      disabled: false,
      onClick: async () => {
        applyConfigTemplate(internalProject.id);
      },
      hidden: false,
    },
    buttonImageGallery,
  ];

  const handleEditInfoGeneral = (_data: any) => {
    const data = { ...getValues(), pi_info_general: { fields: _data, rows: [] } };
    updateInternalProject(data, id);
  };

  const handleEditInfoAdicional = (_data: any) => {
    const data = { ...getValues(), pi_info_general_adicional: { fields: _data, rows: [] } };
    updateInternalProject(data, id);
  };

  const handleUploadAgreement = (a: any, data: File) => {
    uploadDocumentAgreement(id, data);
  };

  let entityInfo;
  if (isEntity) {
    entityInfo = (
      <>
        <TextInputController
          size="100"
          label={tFormKey('ip.nombre_entidad')}
          name="entity_name"
          control={control}
          disabled={disabled}
          defaultValue={internalProject.entity_name}
          errors={errors}
          schema={InformationSchema}
        />
        <TextInputController
          size="50"
          label={tFormKey('ip.tipo_entidad')}
          name="entity_type"
          control={control}
          disabled
          defaultValue={internalProject.entity_type}
          errors={errors}
          schema={InformationSchema}
        />
        <TextInputController
          size="50"
          label={tFormKey('ip.cif')}
          name="cif"
          control={control}
          defaultValue={internalProject.cif}
          disabled
          errors={errors}
          schema={InformationSchema}
        />
      </>
    );
  } else {
    entityInfo = (
      <>
        <TextInputController
          size="100"
          label={tFormKey('ip.nombre_centro')}
          name="academy_name"
          control={control}
          disabled
          defaultValue={internalProject.academy_name}
          errors={errors}
          schema={InformationSchema}
        />
        <TextInputController
          size="50"
          label={tFormKey('ip.tipologia')}
          name="academy_nature"
          control={control}
          disabled
          defaultValue={internalProject.academy_nature}
          errors={errors}
          schema={InformationSchema}
        />
        <TextInputController
          size="50"
          label={tFormKey('ip.numero_lineas')}
          name="line_number"
          control={control}
          defaultValue={internalProject.template_name}
          disabled
          errors={errors}
          schema={InformationSchema}
        />
      </>
    );
  }

  const handleGetDocumentAgreement = (fileId: number, name: string, internalProjectId: number) => {
    downloadDocumentAgreement(fileId, name, internalProjectId, false);
  };

  const handleGetDocument = (fileId: number, name: string, internalProjectId: number) => {
    downloadDocument(fileId, name, internalProjectId, false);
  };

  const handleAddDocument = (document: File, description: string, genericId?: any) => {
    addDocument(document, description, genericId, 'GEN');
  };

  return (
    <InternalProjectLayoutForm
      sectionSelected={'datos_generales'}
      actionsButtons={buttonProps}
      handleChangeSection={handleChangeSection}
      handleGoBack={handleGoBack}
    >
      <Fragment>
        <Title>{tKey('ip.datos_generales')}</Title>
        <FormContainer title="">
          {entityInfo}
          <TextInputController
            size="50"
            label={tFormKey('ip.localidad')}
            name="municipality"
            control={control}
            disabled
            defaultValue={internalProject.municipality}
            errors={errors}
            schema={InformationSchema}
            hidden
          />
          <TextInputController
            size="50"
            label={tFormKey('ip.email')}
            name="email"
            control={control}
            defaultValue={internalProject.email}
            disabled
            errors={errors}
            schema={InformationSchema}
          />
          <InternalProjectGeneric
            id={id}
            buttonSave={buttonProps[0]}
            buttonSend={buttonProps[1]}
            fieldsData={internalProject.pi_info_general?.fields}
            disabled={!!disabled}
            onSave={handleEditInfoGeneral}
            onSend={handleEditInfoGeneral}
            showProjectInfo={false}
          />
          <InternalProjectGeneric
            id={id}
            buttonSave={buttonProps[0]}
            buttonSend={buttonProps[1]}
            fieldsData={internalProject.pi_info_general_adicional?.fields}
            disabled={!!disabled}
            onSave={handleEditInfoAdicional}
            onSend={handleEditInfoAdicional}
            showProjectInfo={false}
          />
          <FncAddDocuments
            documents={documentations}
            actions={actions}
            disabled={disabled}
            genericId={id}
            addRow
            addDoc={handleAddDocument}
            title={tFormKey('ip.documentacion_basica')}
            remove={removeDocument}
            download={downloadDocument}
            originalName
            getFile={handleGetDocument}
          />

          <FncAddDocuments
            documents={documentationsAgreement}
            actions={actionsAgreements}
            disabled={disabled}
            genericId={id}
            title={tFormKey('ip.documento_convenio')}
            upload={handleUploadAgreement}
            download={downloadDocumentAgreement}
            getFile={handleGetDocumentAgreement}
            remove={removeDocumentAgreement}
          />
        </FormContainer>
      </Fragment>
    </InternalProjectLayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  internalProject: state.internalProjectReducer.internalProjectData,
  documentationsAgreement: state.internalProjectReducer.documentations_agreement,
  documentations: state.internalProjectReducer.documentations,
  isEntity: !!state.internalProjectReducer.internalProjectData.entity_id,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateInternalProject: (internalProject: InternalProjectForms, id: number): void =>
    dispatch(updateInternalProject(internalProject, id)),
  deleteMember: (id: number, internal_project_id: number): void => dispatch(deleteMembers(id, internal_project_id)),
  uploadDocumentAgreement: (internalProjectId: number, data: File): void =>
    dispatch(uploadDocument(internalProjectId, data)),
  downloadDocumentAgreement: (file_id: number, name: string, internal_project_id: number, get?: boolean): void =>
    dispatch(downloadDocumentAgreement(file_id, name, internal_project_id, get)),
  removeDocumentAgreement: (fileId: number, eventId: number): void =>
    dispatch(removeDocumentInternalProject(fileId, eventId)),
  addDocument: (data: File, description: string, internalProjectId: number, code: string): void =>
    dispatch(uploadDocumentsInternalProject(internalProjectId, description, data, code)),
  removeDocument: (file_id: number, internal_project_id: number): void =>
    dispatch(deleteDocumentsInternalProject(internal_project_id, file_id)),
  downloadDocument: (file_id: number, name: string, internal_project_id: number, get?: boolean): void =>
    dispatch(downloadDocumentsInternalProject(file_id, name, internal_project_id, get)),
  applyConfigTemplate: (internalProjectId: number) => dispatch(applyConfigTemplate(internalProjectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralData);
