import { Action } from 'redux';
import { TranslationConst } from './action_types';

export enum TranslationCategories {
  GENERAL = 'General',
  BECAS = 'Becas',
  PROYECTOS = 'Proyectos',
  ERRORES = 'Errores',
  FORMS = 'Formularios',
}
export type CategoryKeys = 'generic' | 'scholarship' | 'project' | 'forms' | 'errors' | 'default';
export interface TranslationKeyData {
  category: string;
  code: string;
  value: string;
}

export interface TranslationCategory {
  keys: Map<string, string>;
}

export interface ListMails {
  mail_code: string;
  category: string;
  description: string;
}

export interface TranslationMetadata {
  categories: string[];
  sub_categories: {
    category: string;
    sub_category: string;
  }[];
}

export enum LangCodes {
  ES = 'es',
  CA = 'ca',
  FR = 'fr',
  EN = 'en',
}
export type TypeSetData = 'section' | 'general';
export type TypeLang = LangCodes.ES | LangCodes.CA | LangCodes.FR | LangCodes.EN;

export interface WildCards {
  id: number;
  mail_code: string;
  name: string;
  value: string;
}

export interface TranslateData {
  id: string;
  [value: string]: string;
  value: string;
}

export type SendDataLOPD = {
  [Key in keyof LOPDMail]: LOPDMail[Key];
};

export interface MailDetails {
  mail_code: string;
  category: string;
  order: number;
  description: string;
  subject_es: string;
  subject_ca: string;
  subject_fr: string;
  subject_en: string;
  [key: string]: any;
  sections: {
    id: number;
    mail_code: string;
    content_type: string;
    order: number;
    content_es: string;
    content_ca: string;
    content_fr: string;
    content_en: string;
    [key: string]: string | number;
  }[];
  wildcards: {
    id: number;
    mail_code: string;
    name: string;
    value: string;
  }[];
}

export interface LOPDMail {
  lopd_text_es: string;
  lopd_text_ca: string;
  lopd_text_en: string;
  lopd_text_fr: string;
}

export const initialState = {
  generic: {} as TranslationCategory,
  scholarship: {} as TranslationCategory,
  project: {} as TranslationCategory,
  forms: {} as TranslationCategory,
  errors: {} as TranslationCategory,
  loaded: false as boolean,
  loading: false as boolean,
  error: null as string | null,
  responseImport: false as boolean,
  listMails: [] as ListMails[],
  loadingMailList: false as boolean,
  mailDetails: {} as MailDetails,
  translationMetadata: {} as TranslationMetadata,
  lopdMails: {} as LOPDMail,
  categoyFilter: '',
};

export type TranslationState = typeof initialState;

export interface CategoriesTKeysAction extends Action {
  type: TranslationConst.GET_CATEGORIES_TRANSLATION;
}

export interface CategoriesTKeysOkAction extends Action {
  type: TranslationConst.GET_CATEGORIES_TRANSLATION_OK;
  keyValues: TranslationKeyData[];
}

export interface CategoriesTKeysKoAction extends Action {
  type: TranslationConst.GET_CATEGORIES_TRANSLATION_KO;
  error: string;
}

// GET FILE
export interface GetFileTranslationsAction extends Action {
  type: TranslationConst.GET_FILE_TRANSLATIONS;
  source: 'keys' | 'custom_fields';
}
export interface GetFileTranslationsOkAction extends Action {
  type: TranslationConst.GET_FILE_TRANSLATIONS_OK;
  source: 'keys' | 'custom_fields';
  data: Blob;
}
export interface GetFileTranslationsKoAction extends Action {
  type: TranslationConst.GET_FILE_TRANSLATIONS_KO;
  error: string;
}

export interface SendImportTranslationsAction extends Action {
  type: TranslationConst.SEND_IMPORT_TRANSLATIONS;
  source: 'keys' | 'custom_fields';
  translationObj: any;
}

export interface SendImportTranslationsOkAction extends Action {
  type: TranslationConst.SEND_IMPORT_TRANSLATIONS_OK;
  response: any;
}

export interface SendImportTranslationsKoAction extends Action {
  type: TranslationConst.SEND_IMPORT_TRANSLATIONS_KO;
  error: string;
}

export interface GetListMailsAction extends Action {
  type: TranslationConst.GET_LIST_MAILS;
}

export interface GetListMailsOkAction extends Action {
  type: TranslationConst.GET_LIST_MAILS_OK;
  listMails: ListMails[];
}

export interface GetListMailsKoAction extends Action {
  type: TranslationConst.GET_LIST_MAILS_KO;
  error: string;
}

export interface GetMailDetailsAction extends Action {
  type: TranslationConst.GET_MAIL_DETAILS;
  mailCode: string;
}

export interface GetMailDetailsOkAction extends Action {
  type: TranslationConst.GET_MAIL_DETAILS_OK;
  mailDetails: MailDetails;
}

export interface GetMailDetailsKoAction extends Action {
  type: TranslationConst.GET_MAIL_DETAILS_KO;
  error: string;
}

export interface SetDataAction extends Action {
  type: TranslationConst.SET_DATA;
  text: string;
  setType: TypeSetData;
  lang: TypeLang;
  id?: number;
}

export interface SetDataMailAction extends Action {
  type: TranslationConst.SET_DATA_MAIL;
  key: string;
  value: string | number;
}

export interface UpdateMailDetailsAction extends Action {
  type: TranslationConst.UPDATE_MAIL_DETAILS;
}

export interface UpdateMailDetailsOkAction extends Action {
  type: TranslationConst.UPDATE_MAIL_DETAILS_OK;
}

export interface UpdateMailDetailsKoAction extends Action {
  type: TranslationConst.UPDATE_MAIL_DETAILS_KO;
  error: string;
}

export interface TestTranslationMailAction extends Action {
  type: TranslationConst.TEST_TRANSLATION_MAIL;
  mail_code: string;
  language: TypeLang;
}

export interface UpdateTranslatedDataAction extends Action {
  type: TranslationConst.UPDATE_TRANSLATE_DATA;
  source: 'keys' | 'custom_fields';
  data: TranslateData;
  opt: { category: TranslationCategories; key_label: string };
}

export interface UpdateTranslatedDataOkAction extends Action {
  type: TranslationConst.UPDATE_TRANSLATE_DATA_OK;
  data: TranslateData;
  opt: { category: TranslationCategories; key_label: string };
}

export interface UpdateTranslatedDataKoAction extends Action {
  type: TranslationConst.UPDATE_TRANSLATE_DATA_KO;
  error: string;
}
export interface GetMetadataTranslationAction extends Action {
  type: TranslationConst.GET_METADATA_TRANSLATIONS;
}

export interface GetMetadataTranslationOkAction extends Action {
  type: TranslationConst.GET_METADATA_TRANSLATIONS_OK;
  data: TranslationMetadata;
}

export interface GetMetadataTranslationKoAction extends Action {
  type: TranslationConst.GET_METADATA_TRANSLATIONS_KO;
  error: string;
}

export interface GetLopdTranslationAction extends Action {
  type: TranslationConst.GET_LOPD_TRANSLATION;
}

export interface GetLopdTranslationOkAction extends Action {
  type: TranslationConst.GET_LOPD_TRANSLATION_OK;
  data: LOPDMail;
}

export interface GetLopdTranslationKoAction extends Action {
  type: TranslationConst.GET_LOPD_TRANSLATION_KO;
  error: string;
}

export interface SetLopdTranslationAction extends Action {
  type: TranslationConst.SET_LOPD_TRANSLATION;
  key: keyof LOPDMail;
  value: string;
}

export interface CleanLopdTranslationAction extends Action {
  type: TranslationConst.CLEAN_LOPD_TRANSLATION;
  mails: LOPDMail;
}

export interface UpdateLopdTranslationAction extends Action {
  type: TranslationConst.UPDATE_LOPD_TRANSLATION;
  data: SendDataLOPD;
}

export interface UpdateLopdTranslationOkAction extends Action {
  type: TranslationConst.UPDATE_LOPD_TRANSLATION_OK;
  data: SendDataLOPD;
}

export interface SetCategoryFilter extends Action {
  type: TranslationConst.SET_CATEGORY_FILTER;
  category: string;
}

export type TranslationKeyAction =
  | CategoriesTKeysAction
  | CategoriesTKeysOkAction
  | CategoriesTKeysKoAction
  | GetFileTranslationsAction
  | GetFileTranslationsOkAction
  | GetFileTranslationsKoAction
  | SendImportTranslationsAction
  | SendImportTranslationsOkAction
  | SendImportTranslationsKoAction
  | GetListMailsAction
  | GetListMailsOkAction
  | GetListMailsKoAction
  | GetMailDetailsAction
  | GetMailDetailsOkAction
  | GetMailDetailsKoAction
  | SetDataAction
  | UpdateMailDetailsAction
  | UpdateMailDetailsOkAction
  | UpdateMailDetailsKoAction
  | TestTranslationMailAction
  | UpdateTranslatedDataAction
  | UpdateTranslatedDataOkAction
  | UpdateTranslatedDataKoAction
  | GetMetadataTranslationAction
  | GetMetadataTranslationOkAction
  | SetDataMailAction
  | GetMetadataTranslationKoAction
  | SetLopdTranslationAction
  | CleanLopdTranslationAction
  | GetLopdTranslationAction
  | GetLopdTranslationOkAction
  | GetLopdTranslationKoAction
  | UpdateLopdTranslationAction
  | UpdateLopdTranslationOkAction
  | SetCategoryFilter;

export type TranslationKeyDispatch = (action: TranslationKeyAction) => void;
