import { permissionType } from '../../constants/permissions_definitions';
import { AppState } from '../root-reducer';
import { PermissionsState } from './definitions';

export const selectPermissionState = (state: AppState): PermissionsState => state.permissions;

export const selectPermissionsList = (state: AppState): permissionType[] => selectPermissionState(state).permissions;

export const selectLoadingPermissionsList = (state: AppState): boolean =>
  selectPermissionState(state).fetchingPermissions;

export const selectUserCan = (state: AppState) => (permission: permissionType) => {
  return selectPermissionState(state).permissions.includes(permission);
};
