import { ButtonProps, MenuItem } from '@material-ui/core';
import { HelpOutline, Save } from '@material-ui/icons';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React, { FocusEvent, Fragment, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../api/api';
import ComboSearch from '../../components/ComboSearch/ComboSearch';
import FncButton from '../../components/FncButton/FncButton';
import FormContainer from '../../components/Forms/FormContainer';
import TextInputController from '../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../components/Layout/LayoutForm';
import ActionsMenu from '../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import AvatarImagev2 from '../../components/MenuImageRound/MenuImageRound';
import MessageInfo from '../../components/MessageInfo/MessageInfo';
import TransitionModal from '../../components/Modal/TransitionModal';
import SelectController from '../../components/Select/SelectController';
import Title from '../../components/Title/Title';
import FncAddDocuments from '../../components/UploadFile/FncAddDocuments';
import colorPalette from '../../helpers/colorPalette';
import { useWindowSize } from '../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../helpers/customHooks/useValidatorAPI';
import { tFormKey, tKey } from '../../helpers/translate';
import { setUserProfileAvatar } from '../../redux/auth/actions';
import { selectUserAvatar } from '../../redux/auth/selectors';
import { DocumentationItem, IdNamePair, docTypesArray } from '../../redux/common/definitions';
import { AppState } from '../../redux/root-reducer';
import {
  downloadDocument,
  getDocument,
  getProfile,
  getUserDocumentation,
  getUserEntitiesMetadata,
  removeDocument,
  updateProfileEducator,
  uploadDocument,
} from '../../redux/user/actions';
import { UserData } from '../../redux/user/definitions';
import {
  selectDocumentation,
  selectIsLoading,
  selectMetadataEntity,
  selectProfile,
  selectUserValidationErrors,
} from '../../redux/user/selectors';
import { EducatorUserSchema } from '../../validations/formSchema';
import { validateIdentificationCard } from '../../validations/validatorUser';
import AccessDataForm from '../Users/FormLayouts/AccessDataForm';
import EducatorPending from './EducatorPending';
import styles from './educatorPending.module.scss';
interface RegisterDataProps {
  entitiesMetadata: IdNamePair[];
  loading: boolean;
  profileData: UserData;
  avatar: string | null;
  validationErrors: APIValidationError | null;
  documents: DocumentationItem[];
  getProfileData: () => void;
  setUserProfileAvatar: (image: string) => void;
  updateProfileUser: (data: UserData) => void;
  getUserEntitiesMetadata: () => void;
  getUserDocumentation: (id: number) => void;
  uploadUserDocument: (documentation_id: number, data: File, multiDocument?: boolean) => void;
  downloadUserDocument: (file_id: number, name: string) => void;
  removeUserDocument: (file_id: number) => void;
  getUserDocument: (file_id: number, name: string) => void;
}

const RegisterEducator: React.FC<RegisterDataProps> = ({
  profileData,
  entitiesMetadata,
  avatar,
  validationErrors,
  documents,
  getProfileData,
  updateProfileUser,
  setUserProfileAvatar,
  getUserEntitiesMetadata,
  getUserDocumentation,
  uploadUserDocument,
  downloadUserDocument,
  removeUserDocument,
  getUserDocument,
}) => {
  const [createdModal, setCreatedModal] = React.useState({
    view: false,
    children: <Fragment />,
    title: tKey('Ayuda'),
    body: (
      <div className={styles.modalHelp}>
        <KeyboardArrowRightIcon style={{ color: colorPalette.primary80, verticalAlign: 'top' }} />
        {tKey('RegisterEducator')}
      </div>
    ),
  });
  useEffect(() => {
    getProfileData();
    getUserEntitiesMetadata();
  }, [getProfileData, getUserEntitiesMetadata]);

  const { errors, clearError, watch, triggerValidation, formState, getValues, setError, control } = useForm<UserData>({
    mode: 'onChange',
    submitFocusError: true,
    validationSchema: EducatorUserSchema,
  });

  const addDocument = (document: File, description: string, genericId?: number, documentation_id?: number) => {
    if (documentation_id) {
      uploadUserDocument(documentation_id, document, true);
    }
  };

  const actions = ['checked', 'upload', 'download', 'view', 'remove'];

  useEffect(() => {
    profileData.id > 0 && getUserDocumentation(profileData.id);
  }, [profileData]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = () => {
    return !formState.isValid;
  };

  useValidatorAPI(validationErrors, setError);

  const buttonsValidations: ButtonProps[] = [
    {
      children: tKey('Guardar'),
      onClick: () => {
        triggerValidation().then(ok => {
          if (ok) {
            updateProfileUser(getValues());
          }
        });
      },
      startIcon: <Save />,
      disabled: isDisabled(),
      disableElevation: true,
      color: 'primary',
      variant: 'contained',
    },
  ];

  const moreInfoButtons: ButtonProps[] = [
    {
      children: tFormKey('AYUDA'),
      onClick: () =>
        setCreatedModal(prevState => ({
          ...prevState,
          view: true,
        })),
      startIcon: <HelpOutline />,
      color: 'primary',
      disableElevation: true,
    },
  ];

  const { document_type } = profileData;
  const isMobile = useWindowSize().IsMobile;
  return (
    <Fragment>
      {profileData.document_number === null && profileData.id !== 0 && (
        <LayoutForm
          rightSubmenu={
            <Fragment>
              {!isMobile && (
                <ActionsMenu actionsButtons={buttonsValidations} moreInfoButtons={moreInfoButtons} goBack={false} />
              )}
            </Fragment>
          }
          leftSubmenu={
            <Fragment>{!isMobile && <AvatarImagev2 imageLink={avatar} setImage={setUserProfileAvatar} />}</Fragment>
          }
        >
          <Fragment>
            <Title>{tKey('Registrar datos')}</Title>
            <MessageInfo>{tKey('RegisterEducatorTitle1')}</MessageInfo>
            <Fragment>
              <AccessDataForm profileData={profileData} errors={errors} control={control} schema={EducatorUserSchema} />
              <FormContainer title={tFormKey('Datos personales')}>
                <SelectController
                  size="50"
                  key="document_type"
                  name="document_type"
                  defaultValue={document_type}
                  errors={errors}
                  label={tFormKey('Tipo de Documento')}
                  validator
                  control={control}
                  schema={EducatorUserSchema}
                  onBlur={(_e: FocusEvent<HTMLInputElement>) =>
                    validateIdentificationCard(
                      _e.target.value,
                      watch('document_number') || '',
                      'document_number',
                      setError,
                      clearError,
                    )
                  }
                >
                  {docTypesArray.map(docType => (
                    <MenuItem key={docType} value={docType}>
                      {docType}
                    </MenuItem>
                  ))}
                </SelectController>
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Nº Documento')}
                  name="document_number"
                  value={profileData.document_number}
                  errors={errors}
                  schema={EducatorUserSchema}
                  onBlur={(_e: FocusEvent<HTMLInputElement>) =>
                    validateIdentificationCard(
                      watch('document_type') as string,
                      _e.target.value,
                      'document_number',
                      setError,
                      clearError,
                    )
                  }
                  validator
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Nombre')}
                  name="name"
                  value={profileData.name}
                  errors={errors}
                  schema={EducatorUserSchema}
                  validator
                />
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Primer Apellido')}
                  name="surname"
                  value={profileData.surname}
                  errors={errors}
                  schema={EducatorUserSchema}
                  validator
                />
                <div className={styles.rowComboSearch}>
                  <TextInputController
                    control={control}
                    size="100"
                    label={tFormKey('Segundo Apellido')}
                    name="surname2"
                    value={profileData.surname2}
                    errors={errors}
                    schema={EducatorUserSchema}
                  />
                  <TextInputController
                    control={control}
                    size="100"
                    label={tFormKey('Teléfono')}
                    name="phone"
                    errors={errors}
                    schema={EducatorUserSchema}
                    validator
                  />
                </div>
                <TextInputController
                  control={control}
                  size="50"
                  label={tFormKey('Población')}
                  name="city"
                  errors={errors}
                  schema={EducatorUserSchema}
                  validator
                />
                <ComboSearch
                  options={entitiesMetadata}
                  size="100"
                  control={control}
                  label={tFormKey('Entidad')}
                  optionLabel="name"
                  fieldToSend="id"
                  error={errors.entity_id}
                  name="entity_id"
                  validator
                />
              </FormContainer>
              <FncAddDocuments
                documents={documents}
                actions={actions}
                title={tFormKey('Documentos')}
                upload={uploadUserDocument}
                download={downloadUserDocument}
                remove={removeUserDocument}
                getFile={getUserDocument}
                required
                addDoc={addDocument}
                multidocument
              />

              {isMobile &&
                buttonsValidations.map((button, i) => (
                  <FncButton key={i} {...button} fullWidth={true}>
                    {button.children}
                  </FncButton>
                ))}
            </Fragment>
          </Fragment>
        </LayoutForm>
      )}
      {profileData.document_number !== null && profileData.id !== 0 && <EducatorPending />}
      <TransitionModal
        view={createdModal.view}
        handleClose={() => {
          setCreatedModal(prevState => ({ ...prevState, view: false }));
        }}
        title={createdModal.title}
        maxWidth={true}
        bodyModal={createdModal.body}
      >
        {createdModal.children}
      </TransitionModal>
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIsLoading(state),
  avatar: selectUserAvatar(state),
  profileData: selectProfile(state),
  validationErrors: selectUserValidationErrors(state),
  entitiesMetadata: selectMetadataEntity(state),
  documents: selectDocumentation(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setUserProfileAvatar: (image: string): void => dispatch(setUserProfileAvatar(image)),
  getProfileData: (): void => dispatch(getProfile()),
  updateProfileUser: (data: UserData): void => dispatch(updateProfileEducator(data)),
  getUserEntitiesMetadata: (): void => dispatch(getUserEntitiesMetadata('scholarship')),
  getUserDocumentation: (id: number): void => dispatch(getUserDocumentation(id)),
  uploadUserDocument: (documentation_id: number, data: File, multiDocument?: boolean): void =>
    dispatch(uploadDocument(documentation_id, data, multiDocument)),
  downloadUserDocument: (file_id: number, name: string): void => dispatch(downloadDocument(file_id, name)),
  removeUserDocument: (file_id: number): void => dispatch(removeDocument(file_id)),
  getUserDocument: (file_id: number, name: string): void => dispatch(getDocument(file_id, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterEducator);
