import { DocumentationItem } from '../../redux/common/definitions';
import { ChangeStateEntity, EntityInfo } from '../../redux/entity/definitions';
import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from '../api';

const baseURL = apiBaseUrl;

interface DocumentsResponse {
  data: DocumentsData;
}

interface DocumentsData {
  documents: DocumentationItem[];
}

interface SaveResult {
  data: any;
}

export const updatePreEntity = async (
  entityOneData: EntityInfo,
): Promise<Result<{ data: EntityInfo }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    body: JSON.stringify(entityOneData),
    auth: true,
  };

  return await validatedApiCall<{ data: EntityInfo }>(`${baseURL}/user/entity`, init);
};

export const getPreCreatedEntityByUser = async (): Promise<{ data: EntityInfo }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<{ data: EntityInfo }>(`${baseURL}/user/entity`, init);
};

export const getPreEntityDocuments = async (entityId: number): Promise<SaveResult> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/user/entity/documentation/${entityId}`, init);
};

export const uploadPreEntityDocumentation = async (
  documentation_id: number,
  file: File,
  force_add?: boolean | false,
): Promise<DocumentsData> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const add = force_add ? 'add/' : '';
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/user/entity/document/${add}${documentation_id}`, init);
  return data;
};

export const downloadPreEntityDocumentation = async (file_id: number, entityId: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/user/entity/document/download/${entityId}/${file_id}`, init);
};

export const deletePreEntityDocumentation = async (file_id: number, entityId: number): Promise<DocumentsData> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<DocumentsResponse>(`${baseURL}/user/entity/document/${entityId}/${file_id}`, init);
  return data;
};

export const getPreCreatedEntity = async (id: number): Promise<SaveResult> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SaveResult>(`${baseURL}/user/entity/${id}`, init);
};

export const changeState = async (
  id: number,
  states: ChangeStateEntity,
  comments?: string,
): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ reason: comments }),
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/user/entity/change_state/${states}/${id}`, init);
};

export const getAvatarPreEntity = async (id?: number): Promise<string> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<string>(`${baseURL}/user/entity/image/${id ? id : ''}`, init);
};

export const setPreEntityAvatar = async (image: string): Promise<string> => {
  const data = { image, filename: 'avatar.png' };
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };

  return apiCall<string>(`${baseURL}/user/entity/image`, init);
};

export const createEntityUser = async (data: EntityInfo): Promise<Result<{ data: EntityInfo }, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };

  return await validatedApiCall<{ data: EntityInfo }>(`${baseURL}/user/entity/send/`, init);
};
