/* eslint-disable @typescript-eslint/no-explicit-any */
import { Delete, Description, Edit } from '@material-ui/icons';
import { MaterialTableProps } from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TransitionModal from '../../../components/Modal/TransitionModal';
import TableWithEdit from '../../../components/Tables/TableWithEdit';
import { permissionType } from '../../../constants/permissions_definitions';
import { exportToExcel } from '../../../helpers/exportToXslx';
import { tFormKey, tKey } from '../../../helpers/translate';
import {
  closeIbanModal,
  deleteIbanData,
  downloadIbanEntityDocument,
  entityOneIban,
  listEntityIbans,
  openIbanModal,
  removeIbanEntity,
} from '../../../redux/entity/actions';
import { EntityIbanData } from '../../../redux/entity/definitions';
import { selectUserCan } from '../../../redux/permissions/selectors';
import { AppState } from '../../../redux/root-reducer';
import styles from '../IbanEntityTabs/ibanEntityList.module.scss';
import GenericIbanEntityModal from '../Modal/GenericIbanEntityModal';

interface IbanListProps {
  entityId: number;
  loading: boolean;
  ibanModalView: boolean;
  entityIbans: EntityIbanData[];
  userCan: (permission: permissionType) => boolean | undefined;
  closeIbanModal: () => void;
  clearIbanData: () => void;
  openIbanModal: () => void;
  listEntityIbans: (id: number) => void;
  getEntityIban: (entityId: number, ibanId: number) => void;
  setDelete: (entityId: number, ibanId: number) => void;
  downloadIbanDocument: (file_id: number, file_name: string) => void;
}

const IbanList: React.FC<IbanListProps> = ({
  loading,
  ibanModalView,
  entityId,
  entityIbans,
  userCan,
  closeIbanModal,
  openIbanModal,
  clearIbanData,
  listEntityIbans,
  getEntityIban,
  downloadIbanDocument,
  setDelete,
}) => {
  useEffect(() => {
    listEntityIbans(entityId);
  }, [entityId, listEntityIbans]);

  const [ibanModal, setIbanModal] = useState({
    children: <Fragment></Fragment>,
    title: '',
  });

  const activate_iban_action = userCan('allow_activate_entity_iban');
  const create_iban_action = userCan('allow_create_entity_iban');

  const onAddIbanOne = () => {
    openIbanModal();
    setIbanModal({
      ...ibanModal,
      children: <GenericIbanEntityModal isUpdating={false} closeIbanModal={closeIbanModal} entityId={entityId} />,
      title: tFormKey('Añadir IBAN'),
    });
  };

  const onEditIbanOne = (rowData: EntityIbanData) => {
    openIbanModal();
    getEntityIban(entityId, rowData.id);
    setIbanModal({
      ...ibanModal,
      children: <GenericIbanEntityModal isUpdating={true} closeIbanModal={closeIbanModal} entityId={entityId} />,
      title: tFormKey('Editar IBAN'),
    });
  };

  const actionIcons = {
    Edit: () => <Edit style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
    Delete: () => <Delete style={{ color: 'rgba(0, 0, 0, 0.54)' }} />,
  };

  const getActiveOptions = () => {
    const options: Record<string, string> = {
      0: tKey('Inactivo'),
      1: tKey('Activo'),
    };
    return options;
  };

  const tableData: MaterialTableProps<EntityIbanData> = {
    title: tKey('Datos contables'),
    columns: [
      {
        title: tKey('Nombre'),
        field: 'alias',
      },
      {
        title: tKey('IBAN'),
        field: 'iban',
      },

      {
        field: 'file_id',
        title: tKey('Documento'),
        filtering: false,
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
        render: (rowData: EntityIbanData) =>
          rowData.file_id ? (
            <button
              className={styles.list__downloadButton}
              onClick={e => {
                e.preventDefault();
                if (rowData?.file_id) downloadIbanDocument(rowData.file_id, rowData.alias);
              }}
            >
              <Description />
            </button>
          ) : (
            undefined
          ),
      },
      {
        title: tKey('Activo'),
        field: 'active',
        lookup: getActiveOptions(),
        //@ts-ignore
        width: '180px',
        filtering: true,
        render: (rowData: EntityIbanData) => (rowData.active ? tKey('Activado') : tKey('Desactivado')),
      },
    ],
    data: entityIbans,
    isLoading: loading,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Ibans`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
    actions: [
      (rowData: EntityIbanData) => ({
        icon: actionIcons.Edit,
        onClick: () => onEditIbanOne(rowData),
        tooltip: tKey('editar'),
        disabled: !create_iban_action,
      }),
      (rowData: EntityIbanData) => ({
        icon: actionIcons.Delete,
        onClick: () => setDelete(entityId, rowData.id),
        tooltip: tKey('borrar'),
        hidden: !activate_iban_action,
      }),
    ],
  };

  const goToNew = () => {
    onAddIbanOne();
  };
  //if (loading) return <Loading big />;
  return (
    <Fragment>
      <TableWithEdit
        tableData={tableData}
        exportButton
        filtering
        permission={activate_iban_action || create_iban_action}
        optionsNew={{
          newButtonText: tKey('Añadir IBAN'),
          onNew: goToNew,
        }}
      />

      <TransitionModal
        view={ibanModalView}
        handleClose={() => {
          closeIbanModal();
          clearIbanData();
        }}
        title={ibanModal.title}
        bodyModal={ibanModal.children}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: state.entityReducer.loadingIbanList,
  loadingModal: state.entityReducer.loadingModal,
  entityIbans: state.entityReducer.ibanList,
  ibanModalView: state.entityReducer.ibanModalView,
  userCan: selectUserCan(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  listEntityIbans: (id: number): void => dispatch(listEntityIbans(id)),
  downloadIbanDocument: (file_id: number, file_name: string): void =>
    dispatch(downloadIbanEntityDocument(file_id, file_name)),
  openIbanModal: () => dispatch(openIbanModal()),
  closeIbanModal: () => dispatch(closeIbanModal()),
  clearIbanData: () => dispatch(deleteIbanData()),
  getEntityIban: (entity_id: number, iban_id: number) => dispatch(entityOneIban(entity_id, iban_id)),
  setDelete: (entity_id: number, iban_id: number): void => dispatch(removeIbanEntity(entity_id, iban_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IbanList);
