import { APIValidationError } from '../../api/api';
import assertUnreachable from '../../helpers/assertUnreachable';
import { getDocs } from '../../helpers/getDocs';
import { AccountingConsts } from './action_types';
import { AccountingDataAction, AccountingState, ExpenseAccount, initialState, InvoiceDetail } from './definitions';

const accountingReducer = (state = initialState, action: AccountingDataAction): AccountingState => {
  switch (action.type) {
    case AccountingConsts.GET_EXPENSE_ACCOUNT_LIST:
      return {
        ...state,
        loading: true,
        expenseAccount: {} as ExpenseAccount,
      };
    case AccountingConsts.GET_EXPENSE_ACCOUNT_LIST_OK:
      return {
        ...state,
        loading: false,
        expenseAccountList: action.expenseAccountList,
      };
    case AccountingConsts.REMOVE_PENDING_INVOICE_KO:
    case AccountingConsts.GET_PENDING_INVOICES_KO:
    case AccountingConsts.GET_ONE_PENDING_INVOICE_KO:
    case AccountingConsts.GET_EXPENSE_ACCOUNT_LIST_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.GET_PENDING_INVOICES_OK:
      return {
        ...state,
        loading: false,
        pendingInvoicesList: action.pendingInvoicesList,
      };
    case AccountingConsts.REMOVE_PENDING_INVOICE:
    case AccountingConsts.GET_PENDING_INVOICES:
    case AccountingConsts.GET_ONE_PENDING_INVOICE:
    case AccountingConsts.CREATE_EXPENSE_ACCOUNT:
    case AccountingConsts.CREATE_INVOICE:
    case AccountingConsts.CHANGE_STATE:
    case AccountingConsts.MOVE_BACK_INVOICE:
    case AccountingConsts.MARK_INVOICE_AS_PAID:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.SET_ONE_PENDING_INVOICE:
      const { key_i, value_i } = action;

      return {
        ...state,
        pendingInvoice: {
          ...state.pendingInvoice,
          [key_i]: value_i,
        },
      };
    case AccountingConsts.CREATE_EXPENSE_ACCOUNT_OK:
      return {
        ...state,
        loading: false,
      };
    case AccountingConsts.GET_ONE_PENDING_INVOICE_OK:
      return {
        ...state,
        pendingInvoice: action.pendingInvoice,
        loading: false,
      };
    case AccountingConsts.CREATE_EXPENSE_ACCOUNT_KO:
    case AccountingConsts.CREATE_INVOICE_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.DELETE_EXPENSE_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.DELETE_EXPENSE_ACCOUNT_OK:
      const { id } = action;
      return {
        ...state,
        expenseAccountList: state.expenseAccountList.filter((expense: ExpenseAccount) => expense.id !== id),
        loading: false,
      };
    case AccountingConsts.DELETE_EXPENSE_ACCOUNT_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case AccountingConsts.GET_ONE_EXPENSE_ACCOUNT:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.GET_ONE_EXPENSE_ACCOUNT_OK:
      const { expenseAccount } = action;
      return {
        ...state,
        loading: false,
        expenseAccount: expenseAccount,
      };
    case AccountingConsts.GET_ONE_EXPENSE_ACCOUNT_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.EDIT_EXPENSE_ACCOUNT:
    case AccountingConsts.CLOSE_INVOICES:
      return {
        ...state,
      };
    case AccountingConsts.EDIT_EXPENSE_ACCOUNT_OK:
    case AccountingConsts.CLOSE_INVOICES_OK:
      return {
        ...state,
      };
    case AccountingConsts.EDIT_EXPENSE_ACCOUNT_KO:
    case AccountingConsts.CLOSE_INVOICES_KO:
      return {
        ...state,
        error: action.error,
      };
    case AccountingConsts.GET_ACCOUNTING_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.GET_ACCOUNTING_SETTINGS_OK:
      return {
        ...state,
        loading: false,
        accountingSettings: action.accountingSettings,
      };
    case AccountingConsts.GET_ACCOUNTING_SETTINGS_KO:
    case AccountingConsts.DOWNLOAD_INVOICE_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.EDIT_ACCOUNTING_SETTINGS:
    case AccountingConsts.DOWNLOAD_INVOICE:
      return { ...state };
    case AccountingConsts.EDIT_ACCOUNTING_SETTINGS_OK:
      return {
        ...state,
        loading: false,
      };
    case AccountingConsts.EDIT_ACCOUNTING_SETTINGS_KO:
      return {
        ...state,
      };
    case AccountingConsts.GET_INVOICE_DATA:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.CREATE_INVOICE_OK:
      return {
        ...state,
        loading: false,
        invoiceData: action.invoice,
      };
    case AccountingConsts.GET_INVOICE_DATA_OK:
      return {
        ...state,
        loading: false,
        invoiceData: action.invoice,
      };
    case AccountingConsts.GET_INVOICE_DATA_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.EDIT_INVOICE_DATA:
      return {
        ...state,
        loading: false,
      };
    case AccountingConsts.EDIT_INVOICE_DATA_OK:
      return {
        ...state,
        loading: false,
        invoiceData: action.invoice,
      };
    case AccountingConsts.EDIT_INVOICE_DATA_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.DELETE_INVOICE_DATA:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.DELETE_INVOICE_DATA_OK:
      return {
        ...state,
      };
    case AccountingConsts.DELETE_INVOICE_DATA_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.INVOICE_SET_DATA:
      const { key } = action;
      let { value } = action;

      if (key === 'center_cost_project_code' && value === '0') {
        value = '';
      }
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          [key]: value,
        },
      };
    case AccountingConsts.INVOICE_DETAIL_SET_DATA:
      const { key_detail } = action;
      let { value_detail } = action;
      let { tax_retention_amount } = state.invoiceDetail;

      const value_detail_float = value_detail ? parseFloat(value_detail.toString()) : 0;

      if (key_detail === 'tax_retention_percentage' && value_detail_float >= 0) {
        value_detail = value_detail_float;
        const { base_amount } = state.invoiceDetail;
        const calcul = base_amount * (value_detail_float / 100);
        tax_retention_amount = Math.round(calcul * 100) / 100;
      }
      if (key_detail === 'base_amount') {
        const { tax_retention_percentage } = state.invoiceDetail;
        value_detail = value_detail_float;
        const calcul = value_detail_float * ((tax_retention_percentage as number) / 100);
        tax_retention_amount = Math.round(calcul * 100) / 100;
      }
      return {
        ...state,
        invoiceDetail: {
          ...state.invoiceDetail,
          [key_detail]: value_detail,
          tax_retention_amount: tax_retention_amount || 0,
        },
      };
    case AccountingConsts.INVOICE_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        validationErrors: action.validationErrors,
      };
    case AccountingConsts.DOWNLOAD_INVOICE_OK:
      const { data, name } = action;
      getDocs(data, true, name);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case AccountingConsts.UPLOAD_INVOICE_DOCUMENT_OK:
      const { fileData } = action;
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          file_id: fileData.id,
          filename: fileData.original_name,
        },
      };
    case AccountingConsts.REMOVE_INVOICE_DOCUMENT_OK:
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          file_id: null,
          filename: null,
        },
      };
    case AccountingConsts.REMOVE_INVOICE_DOCUMENT:
    case AccountingConsts.UPLOAD_INVOICE_DOCUMENT:
      return {
        ...state,
      };
    case AccountingConsts.REMOVE_INVOICE_DOCUMENT_KO:
    case AccountingConsts.UPLOAD_INVOICE_DOCUMENT_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.GET_INVOICE_DETAIL:
      return {
        ...state,
        loadingModal: true,
      };
    case AccountingConsts.GET_INVOICE_DETAIL_OK:
      return {
        ...state,
        loadingModal: false,
        invoiceDetail: action.invoiceDetail,
      };

    case AccountingConsts.GET_INVOICE_DETAIL_KO:
      return {
        ...state,
        loadingModal: false,
        error: action.error,
      };
    case AccountingConsts.GET_INVOICES_DETAIL_LIST:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.GET_INVOICES_DETAIL_LIST_OK:
      return {
        ...state,
        loading: false,
        invoicesDetailList: action.invoicesDetailList,
      };
    case AccountingConsts.GET_INVOICES_DETAIL_LIST_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.CREATE_NEW_INVOICE_DETAIL:
    case AccountingConsts.EDIT_INVOICE_DETAIL:
      return {
        ...state,
      };
    case AccountingConsts.CREATE_NEW_INVOICE_DETAIL_OK:
    case AccountingConsts.EDIT_INVOICE_DETAIL_OK:
      return {
        ...state,
        modalView: false,
        invoiceData: {
          ...state.invoiceData,
          invoice_details: action.invoice_details,
        },
        invoiceDetail: {} as InvoiceDetail,
      };
    case AccountingConsts.CREATE_NEW_INVOICE_DETAIL_KO:
    case AccountingConsts.EDIT_INVOICE_DETAIL_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case AccountingConsts.DELETE_INVOICE_DETAIL:
      return {
        ...state,
      };
    case AccountingConsts.DELETE_INVOICE_DETAIL_OK:
      const invoiceDetails = state.invoiceData.invoice_details.filter(
        (invoiceDetail: InvoiceDetail) => invoiceDetail.id !== action.invoiceDetail.id,
      );
      return {
        ...state,
        invoiceData: {
          ...state.invoiceData,
          invoice_details: invoiceDetails,
        },
      };
    case AccountingConsts.CHANGE_STATE_KO:
    case AccountingConsts.MOVE_BACK_INVOICE_KO:
    case AccountingConsts.DELETE_INVOICE_DETAIL_KO:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case AccountingConsts.RESET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: {} as InvoiceDetail,
        validationErrors: null as APIValidationError | null,
      };
    case AccountingConsts.UPDATE_PENDING_INVOICE:
    case AccountingConsts.SEND_INVOICE:
    case AccountingConsts.ACCEPT_INVOICE:
    case AccountingConsts.AUTORIZE_INVOICE:
    case AccountingConsts.CONFIRM_INVOICE:
    case AccountingConsts.TO_PAY_INVOICE:
    case AccountingConsts.PAY_INVOICES:
    case AccountingConsts.TO_PAY_INVOICES:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.SEND_INVOICE_OK:
    case AccountingConsts.CONFIRM_INVOICE_OK:
    case AccountingConsts.CHANGE_STATE_OK:
    case AccountingConsts.MOVE_BACK_INVOICE_OK:
    case AccountingConsts.AUTORIZE_INVOICE_OK:
      const { state_id, state_code, state_name, invoice_number } = action.invoiceData;
      return {
        ...state,
        error: null,
        loading: false,
        transitionChangeSent: true,
        invoiceData: {
          ...state.invoiceData,
          state_id,
          state_code,
          state_name,
          invoice_number,
        },
      };
    case AccountingConsts.TO_PAY_INVOICES_OK:
    case AccountingConsts.PAY_INVOICES_OK:
      return {
        ...state,
        loading: false,
      };
    case AccountingConsts.ACCEPT_INVOICE_OK:
      const {
        state_id: a_state_id,
        state_code: a_state_code,
        state_name: a_state_name,
        due_date,
        invoice_details,
      } = action.invoiceData;
      return {
        ...state,
        error: null,
        loading: false,
        transitionChangeSent: true,
        invoiceData: {
          ...state.invoiceData,
          state_id: a_state_id,
          state_code: a_state_code,
          state_name: a_state_name,
          due_date,
          invoice_details,
        },
      };
    case AccountingConsts.TO_PAY_INVOICE_OK:
      return {
        ...state,
        loading: false,
        invoiceData: { ...action.invoiceData },
      };
    case AccountingConsts.UPDATE_PENDING_INVOICE_KO:
    case AccountingConsts.SEND_INVOICE_KO:
    case AccountingConsts.ACCEPT_INVOICE_KO:
    case AccountingConsts.CONFIRM_INVOICE_KO:
    case AccountingConsts.TO_PAY_INVOICE_KO:
    case AccountingConsts.AUTORIZE_INVOICE_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    case AccountingConsts.OPEN_MODAL:
      return {
        ...state,
        modalView: true,
      };
    case AccountingConsts.CLOSE_MODAL:
      return {
        ...state,
        modalView: false,
        invoiceDetail: {} as InvoiceDetail,
        validationErrors: null as APIValidationError | null,
      };

    case AccountingConsts.OPEN_PAYMENT_MODAL:
      return {
        ...state,
        paymentViewModal: true,
      };
    case AccountingConsts.CLOSE_PAYMENT_MODAL:
      return {
        ...state,
        paymentViewModal: false,
      };
    case AccountingConsts.GET_SCHOLARSHIP_SETTING:
      return {
        ...state,
        loading: true,
      };
    case AccountingConsts.UPDATE_SCHOLARSHIP_SETTING:
      return {
        ...state,
      };
    case AccountingConsts.GET_SCHOLARSHIP_SETTING_OK:
    case AccountingConsts.UPDATE_SCHOLARSHIP_SETTING_OK:
      return {
        ...state,
        loading: false,
        scholarshipSetting: action.value,
      };
    case AccountingConsts.GET_SCHOLARSHIP_SETTING_KO:
    case AccountingConsts.UPDATE_SCHOLARSHIP_SETTING_KO:
    case AccountingConsts.MARK_INVOICE_AS_PAID_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case AccountingConsts.SCHOLARSHIP_SET_DATA_SETTING:
      const { key: __key, value: __value } = action;
      return {
        ...state,
        scholarshipSetting: {
          ...state.scholarshipSetting,
          [__key]: __value,
        },
      };
    case AccountingConsts.SCHOLARSHIP_SETTING_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.validationErrors,
      };
    case AccountingConsts.MARK_INVOICE_AS_PAID_OK:
      return {
        ...state,
        loading: false,
      };
    case AccountingConsts.SET_INS_SCHOLARSHIPS_PAYMENT_DATE_FILTER:
      return {
        ...state,
        instituteScholarshipPaymentDateFilter: action.paymentDate,
        instituteScholarshipReloadDateFilter: action.reloadDate,
      };
  }

  assertUnreachable(action);
  return state;
};

export default accountingReducer;
