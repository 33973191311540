import React, { useEffect, useState } from 'react';
import { downloadDocument, getResourcesView } from '../../../api/aboutUs';
import FncAccordion from '../../../components/Accordion/FncAccordion';
import Layout from '../../../components/Layout/Layout';
import Title from '../../../components/Title/Title';
import { getDocs } from '../../../helpers/getDocs';
import { tKey } from '../../../helpers/translate';
import { ReactComponent as IconDownload } from '../../../images/icons/iconDownload.svg';
import { AboutUsTypesWithResources } from '../../../redux/aboutUs/definitions';
import styles from './aboutUsResources.module.scss';

const AboutUsResources: React.FC = () => {
  const [resources, setResources] = useState<AboutUsTypesWithResources[]>([]);

  useEffect(() => {
    (async function fetchData() {
      const { data } = await getResourcesView();
      setResources(data);
    })();
  }, []);

  const downloadFile = async (file_id: number, resource_id: number, file_name: string) => {
    const doc = await downloadDocument(resource_id, file_id);
    getDocs(doc, true, file_name);
  };

  return (
    <Layout>
      <div className={styles.containerAbout}>
        <Title>{tKey('RECURSOS NOUS CIMS')}</Title>
        {resources.map(type => (
          <>
            <FncAccordion title={type.name}>
              <ul className={styles.list}>
                {type.resources.map(resource => (
                  <li key={resource.id} className={styles.item}>
                    <h3 className={styles.title}>{resource.description}</h3>
                    {resource.files.length > 0 ? (
                      resource.files.map(file => (
                        <div key={file.documentation_id} className={styles.wrapper}>
                          <p className={`${styles.itemText} ${styles.fileName}`}>{file.name}</p>
                          <p className={`${styles.itemText} ${styles.uploader}`}>{file.upload}</p>
                          <p className={`${styles.itemText} ${styles.update}`}>{file.update}</p>

                          <button
                            className={styles.button}
                            onClick={() => {
                              downloadFile(file.file_id || 0, resource.id, file.original_name);
                            }}
                          >
                            <IconDownload className={styles.icon} />
                          </button>
                        </div>
                      ))
                    ) : (
                      <p className={`${styles.itemText} ${styles.message}`}>
                        {tKey('No hay ficheros todavía en esta sección')}
                      </p>
                    )}
                  </li>
                ))}
              </ul>
            </FncAccordion>
          </>
        ))}
      </div>
    </Layout>
  );
};

export default AboutUsResources;
