import { APIError } from '../../api/api';
import { DocumentationItem, FileItem } from '../common/definitions';
import { AboutUsConsts } from './action_types';
import {
  AboutUsMetada,
  AboutUsResource,
  DownloadDocumentAction,
  DownloadDocumentByCodeAction,
  DownloadDocumentByCodeKoAction,
  DownloadDocumentByCodeOkAction,
  DownloadDocumentKoAction,
  DownloadDocumentOkAction,
  GetInitialResourceAction,
  GetInitialResourceKoAction,
  GetInitialResourceOkAction,
  GetMetadataAction,
  GetMetadataKoAction,
  GetMetadataOkAction,
  GetOneResourcesAction,
  GetOneResourcesKoAction,
  GetOneResourcesOkAction,
  GetResourcesAction,
  GetResourcesKoAction,
  GetResourcesOkAction,
  NewDocumentAction,
  NewDocumentKoAction,
  NewDocumentOkAction,
  NewResourceAction,
  NewResourceKoAction,
  NewResourceOkAction,
  OpenDocumentAction,
  OpenDocumentKoAction,
  OpenDocumentOkAction,
  RemoveDocumentAction,
  RemoveDocumentByIdAction,
  RemoveDocumentByIdKoAction,
  RemoveDocumentByIdOkAction,
  RemoveDocumentKoAction,
  RemoveDocumentOkAction,
  SaveResourceAction,
  SaveResourceKoAction,
  SaveResourceOkAction,
} from './definitions';

export function getMetadata(): GetMetadataAction {
  return {
    type: AboutUsConsts.ABOUTUS_METADATA,
  };
}

export function getMetadataOk(metadata: AboutUsMetada): GetMetadataOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_METADATA_OK,
    metadata,
  };
}

export function getMetadataKo(error: APIError): GetMetadataKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_METADATA_KO,
    error,
  };
}

export function getResources(code: string): GetResourcesAction {
  return {
    type: AboutUsConsts.ABOUTUS_RESOURCES,
    code,
  };
}

export function getResourcesOk(resources: AboutUsResource[]): GetResourcesOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_RESOURCES_OK,
    resources,
  };
}

export function getResourcesKo(error: APIError): GetResourcesKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_RESOURCES_KO,
    error,
  };
}

export function getOneResources(id: number): GetOneResourcesAction {
  return {
    type: AboutUsConsts.ABOUTUS_ONE_RESOURCES,
    id,
  };
}

export function getOneResourcesOk(resource: AboutUsResource): GetOneResourcesOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_ONE_RESOURCES_OK,
    resource,
  };
}

export function getOneResourcesKo(error: APIError): GetOneResourcesKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_ONE_RESOURCES_KO,
    error,
  };
}

export function getInitialResource(): GetInitialResourceAction {
  return {
    type: AboutUsConsts.ABOUTUS_INITIAL_RESOURCE,
  };
}

export function getInitialResourceOk(metadata: AboutUsMetada): GetInitialResourceOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_INITIAL_RESOURCE_OK,
    metadata,
  };
}

export function getInitialResourceKo(error: APIError): GetInitialResourceKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_INITIAL_RESOURCE_KO,
    error,
  };
}

export function newDocument(file: File, name: string): NewDocumentAction {
  return {
    type: AboutUsConsts.ABOUTUS_NEW_DOCUMENT,
    file,
    name,
  };
}

export function newDocumentOk(data: FileItem, name: string): NewDocumentOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_NEW_DOCUMENT_OK,
    data,
    name,
  };
}

export function newDocumentKo(error: APIError): NewDocumentKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_NEW_DOCUMENT_KO,
    error,
  };
}

export function downloadDocument(documentation_id: number, name: string, file_id: number): DownloadDocumentAction {
  return {
    type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT,
    documentation_id,
    name,
    file_id,
  };
}

export function downloadDocumentOk(data: Blob, name: string): DownloadDocumentOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_OK,
    data,
    name,
  };
}

export function downloadDocumentKo(error: APIError): DownloadDocumentKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_KO,
    error,
  };
}

export function downloadDocumentByCode(code: string, name: string): DownloadDocumentByCodeAction {
  return {
    type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE,
    code,
    name,
  };
}

export function downloadDocumentByCodeOk(data: Blob, name: string): DownloadDocumentByCodeOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_OK,
    data,
    name,
  };
}

export function downloadDocumentByCodeKo(error: APIError): DownloadDocumentByCodeKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_DOWNLOAD_DOCUMENT_BY_CODE_KO,
    error,
  };
}

export function deleteDocument(resource_id: number, file_id: number): RemoveDocumentAction {
  return {
    type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT,
    resource_id,
    file_id,
  };
}

export function deleteDocumentOk(documents: DocumentationItem[]): RemoveDocumentOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_OK,
    documents,
  };
}

export function deleteDocumentKo(error: APIError): RemoveDocumentKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_KO,
    error,
  };
}

export function openDocument(resource_id: number, name: string, file_id: number): OpenDocumentAction {
  return {
    type: AboutUsConsts.ABOUTUS_OPEN_DOCUMENT,
    resource_id,
    name,
    file_id,
  };
}

export function openDocumentOk(data: Blob): OpenDocumentOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_OPEN_DOCUMENT_OK,
    data,
  };
}

export function openDocumentKo(error: APIError): OpenDocumentKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_OPEN_DOCUMENT_KO,
    error,
  };
}

export function saveResource(resource: AboutUsResource, redirect: string): SaveResourceAction {
  return {
    type: AboutUsConsts.ABOUTUS_SAVE_RESOURCE,
    resource,
    redirect,
  };
}

export function saveResourceOk(resource: AboutUsResource): SaveResourceOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_SAVE_RESOURCE_OK,
    resource,
  };
}

export function saveResourceKo(error: APIError): SaveResourceKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_SAVE_RESOURCE_KO,
    error,
  };
}

export function newResource(resource: AboutUsResource, redirect: string): NewResourceAction {
  return {
    type: AboutUsConsts.ABOUTUS_NEW_RESOURCE,
    resource,
    redirect,
  };
}

export function newResourceOk(): NewResourceOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_NEW_RESOURCE_OK,
  };
}

export function newResourceKo(error: APIError): NewResourceKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_NEW_RESOURCE_KO,
    error,
  };
}

// DELETE DOCUMENT BY ID

export function deleteDocumentById(file_id: number, documents: DocumentationItem[]): RemoveDocumentByIdAction {
  return {
    type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID,
    file_id,
    documents,
  };
}

export function deleteDocumentByIdOk(documents: DocumentationItem[]): RemoveDocumentByIdOkAction {
  return {
    type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID_OK,
    documents,
  };
}

export function deleteDocumentByIdKo(error: APIError): RemoveDocumentByIdKoAction {
  return {
    type: AboutUsConsts.ABOUTUS_DELETE_DOCUMENT_BY_ID_KO,
    error,
  };
}
