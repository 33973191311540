import { InternalProjectId, ParticipantId, Participant } from './Participant';
import { UnSubscribeParticipantConst } from './action_types';
import { Action } from 'redux';

export interface UnSubscribeParticipantAction extends Action {
  type: UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT;
  internal_project_id: InternalProjectId;
  participantId: ParticipantId;
}

export interface UnSubscribeParticipantOkAction extends Action {
  type: UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT_OK;
  internal_project_id: InternalProjectId;
  participantId: ParticipantId;
  unsubscribed_at: string;
}

export interface UnSubscribeParticipantKoAction extends Action {
  type: UnSubscribeParticipantConst.UNSUBSCRIBE_PARTICIPANT_KO;
  error: string;
}

export type UnSubscribeParticipantActions =
  | UnSubscribeParticipantAction
  | UnSubscribeParticipantOkAction
  | UnSubscribeParticipantKoAction;

export const initialState = {
  loading: false,
  error: null as string | null,
  data: [] as Participant[],
};
export type UnSubscribeParticipantState = typeof initialState;
