import { DirectoryDataAction, initialState } from './definitions';
import { DirectoryDataConsts } from './action_types';

export type DirectoryDataState = typeof initialState;

const directoryReducer = (state = initialState, action: DirectoryDataAction): DirectoryDataState => {
  switch (action.type) {
    case DirectoryDataConsts.GET_DIRECTORY_FILTER:
    case DirectoryDataConsts.GET_DIRECTORY:
    case DirectoryDataConsts.SEND_SUGGESTION:
      return { ...state, loading: true };
    case DirectoryDataConsts.GET_DIRECTORY_OK:
    case DirectoryDataConsts.GET_DIRECTORY_FILTER_OK:
      const { directoryData } = action;
      return {
        ...state,
        loading: false,
        userData: [...state.userData, ...directoryData.usersData],
      };
    case DirectoryDataConsts.SEND_SUGGESTION_OK:
      return {
        ...state,
        loading: false,
      };
    case DirectoryDataConsts.GET_DIRECTORY_FILTER_KO:
    case DirectoryDataConsts.GET_DIRECTORY_KO:
    case DirectoryDataConsts.SEND_SUGGESTION_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error: error,
      };
    case DirectoryDataConsts.CLEAN_USER_DATA:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: 1,
        },
        userData: [],
      };
    case DirectoryDataConsts.SET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: state.pagination.page + 1,
        },
      };
    default:
      return state;
  }
};
export default directoryReducer;
