import {
  FollowUpData,
  InternalProjectTemplate,
  InternalProjectTemplateData,
  InternalProjectTemplatesMetadata,
  RoleWithPermission,
} from '../redux/internal-project-templates/definitions';
import { ConfiguratorData } from '../redux/project/definitions';
import { apiBaseUrl, apiCall } from './api';

const baseURL = apiBaseUrl;

export const getInternalProjectTemplatesMetadata = async (): Promise<InternalProjectTemplatesMetadata> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<InternalProjectTemplatesMetadata>(
    `${baseURL}/configuration/internal_project_templates/metadata`,
    init,
  );
};

export const getInternalProjectTemplatesInfoOfArea = async (): Promise<InternalProjectTemplate[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<InternalProjectTemplate[]>(`${baseURL}/configuration/internal_project_templates/info`, init);
};

export const createInternalProjectTemplateOfArea = async (
  internalProjectTemplate: InternalProjectTemplate,
): Promise<InternalProjectTemplate[]> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(internalProjectTemplate),
  };

  const { data } = await apiCall<{ data: InternalProjectTemplate[] }>(
    `${baseURL}/configuration/internal_project_template`,
    init,
  );

  return data;
};

export const deleteInternalProjectTemplateOfArea = async (id: number): Promise<InternalProjectTemplate[]> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: InternalProjectTemplate[] }>(
    `${baseURL}/configuration/internal_project_templates/${id}`,
    init,
  );

  return data;
};

export const getInternalProjectTemplateInfoOfArea = async (id: number): Promise<InternalProjectTemplateData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<InternalProjectTemplateData>(`${baseURL}/configuration/internal_project_template/${id}`, init);
};

export const saveInternalProjectTemplateOfArea = async (
  id: number,
  data: InternalProjectTemplateData,
): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/configuration/internal_project_template/${id}`, init);
};

export const saveInternalProjectTemplateGeneralFormRolesOfArea = async (
  internalProjectTemplateId: number,
  formId: number,
  roles: RoleWithPermission[],
): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ roles: roles }),
  };

  return await apiCall<{ data: 'OK' }>(
    `${baseURL}/configuration/internal_project_template/${internalProjectTemplateId}/form/${formId}/roles`,
    init,
  );
};

export const getCustomFieldsBySection = (
  internalProjectTemplate: number,
  section: string,
): Promise<{ data: ConfiguratorData[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: ConfiguratorData[] }>(
    `${baseURL}/internal_project_template/${internalProjectTemplate}/custom_fields/${section}`,
    init,
  );
};

export const saveCustomFieldsBySection = async (
  internalProjectTemplate: number,
  section: string,
  customFields: ConfiguratorData[],
): Promise<{ data: 'OK' }> => {
  const data = {
    id: internalProjectTemplate,
    section,
    custom_fields: customFields,
  };
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/internal_project_template/custom_fields`, init);
};

export const createInternalProjectTemplateFollowUpFromApi = async (followUp: FollowUpData): Promise<FollowUpData[]> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(followUp),
  };
  const { data } = await apiCall<{ data: FollowUpData[] }>(
    `${baseURL}/configuration/internal_project_template/${followUp.internal_project_template_id}/follow_up`,
    init,
  );

  return data;
};

export const saveInternalProjectTemplateFollowUpRolesOfArea = async (
  internalProjectTemplateId: number,
  followUpId: number,
  roles: RoleWithPermission[],
): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify({ roles: roles }),
  };

  return await apiCall<{ data: 'OK' }>(
    `${baseURL}/configuration/internal_project_template/${internalProjectTemplateId}/follow_up/${followUpId}/roles`,
    init,
  );
};

export const deleteInternalProjectTemplateFollowUpOfArea = async (
  internalProjectTemplateId: number,
  followUpId: number,
): Promise<FollowUpData[]> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: FollowUpData[] }>(
    `${baseURL}/configuration/internal_project_template/${internalProjectTemplateId}/follow_up/${followUpId}`,
    init,
  );

  return data;
};

export const publishInternalProjectTemplateOfArea = async (id: number): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'PUT',
    auth: true,
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/configuration/internal_project_template/${id}/publish`, init);
};

export const duplicateInternalProjectTemplateOfArea = async (
  templateName: string,
  duplicateTemplateId: number,
): Promise<InternalProjectTemplate[]> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({
      template_name: templateName,
      duplicate_template_id: duplicateTemplateId,
    }),
  };

  const { data } = await apiCall<{ data: InternalProjectTemplate[] }>(
    `${baseURL}/configuration/internal_project_template/duplicate`,
    init,
  );

  return data;
};

export const duplicateTemplateFollowUp = async (
  duplicateFollowUpId: number,
  nameNewFollowUp: string,
): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({
      internal_project_template_follow_id: duplicateFollowUpId,
      name: nameNewFollowUp,
    }),
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/internal_project_template_follow_ups/clone`, init);
};
