import { Button, Dialog, DialogContent, DialogTitle, MenuItem } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createInternalProjectTemplateOfArea } from '../../../../api/internalProjectTemplates';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { tKey, tProjectKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { setInternalProjectTemplate } from '../../../../redux/internal-project-templates/actions';
import {
  InternalProjectTemplate,
  InternalProjectTemplateDispatch,
  SubareaData,
} from '../../../../redux/internal-project-templates/definitions';
import { InternalProjectTemplateSchema } from '../../../../validations/formSchema';
import styles from './createInternalProjectTemplate.module.scss';
import useInternalProjectTemplate from './useInternalProjectTemplate';
import {
  InternalProjectTypeData,
  InternalProjectTypesEnum,
} from '../../../../types/InternalProjects/InternalProjectTypes.d';

interface CreateInternalProjectTemplateProps {
  setInternalProjectTemplate: (data: InternalProjectTemplate[]) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  handleClose: () => void;
}

const CreateInternalProjectTemplate: React.FC<CreateInternalProjectTemplateProps> = ({
  setInternalProjectTemplate,
  showSnackbar,
  handleClose,
}) => {
  const history = useHistory();
  const { types, subareas } = useInternalProjectTemplate().metadata;
  const [filteredTypes, setFilteredTypes] = useState([] as InternalProjectTypeData[]);

  const { control, errors, setValue, handleSubmit } = useForm<InternalProjectTemplate>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: InternalProjectTemplateSchema,
  });

  const handleCreate = async (data: InternalProjectTemplate) => {
    try {
      const res = await createInternalProjectTemplateOfArea(data);
      setInternalProjectTemplate(res);
      const template = res[res.length - 1];
      history.push(`/configuracion/proyectos-internos/plantilla/editar/${template.id}`);
    } catch (error) {
      const errorMsg = (error as Error).message;
      if (errorMsg.toLowerCase().includes('ya existe'))
        showSnackbar(tProjectKey('Ya existe una plantilla con el mismo nombre'), 'warning', undefined, 4000);
      else showSnackbar(errorMsg, 'error', undefined, 1500);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{tProjectKey('Nueva Plantilla de Proyecto Interno')}</DialogTitle>
        <IconButton aria-label="delete" size="small" onClick={handleClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        <form onSubmit={e => e.preventDefault()} className={styles.form}>
          <FormContainer title="">
            <TextInputController
              label={tKey('Nombre de la plantilla')}
              name="template_name"
              size="100"
              schema={InternalProjectTemplateSchema}
              control={control}
              errors={errors}
            />
            <SelectController
              key="subarea_id"
              label={tProjectKey('Subárea')}
              name="subarea_id"
              size="50"
              schema={InternalProjectTemplateSchema}
              control={control}
              errors={errors}
              onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                const subarea_id = Number(event.target.value);
                setFilteredTypes(types.filter((e: InternalProjectTypeData) => e.subarea_id === subarea_id));
                setValue('subarea_internal_project_type_id', undefined);
              }}
            >
              {subareas.map((element: SubareaData) => (
                <MenuItem key={element.id} value={element.id}>
                  {element.name}
                </MenuItem>
              ))}
            </SelectController>
            <SelectController
              key="subarea_internal_project_type_id"
              label={tProjectKey('Tipo de proyecto')}
              name="subarea_internal_project_type_id"
              size="50"
              schema={InternalProjectTemplateSchema}
              control={control}
              errors={errors}
            >
              {filteredTypes.map((element: InternalProjectTypeData) => (
                <MenuItem key={element.id} value={element.id}>
                  {InternalProjectTypesEnum[element.name]}
                </MenuItem>
              ))}
            </SelectController>
          </FormContainer>
          <div className={styles.btn_create}>
            <Button color="primary" variant="outlined" onClick={handleSubmit(handleCreate)}>
              {tKey('Crear')}
            </Button>
            <Button color="primary" variant="outlined" onClick={handleClose}>
              {tProjectKey('Cancelar')}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: InternalProjectTemplateDispatch) => ({
  setInternalProjectTemplate: (data: InternalProjectTemplate[]): void => dispatch(setInternalProjectTemplate(data)),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});
export default connect(null, mapDispatchToProps)(CreateInternalProjectTemplate);
