import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DroppableProvided, DropResult } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { getCustomFieldsBySection, saveCustomFieldsBySection } from '../../../api/internalProjectTemplates';
import { getCustomFieldSizes } from '../../../api/metadata';
import { getConfiguratorFieldsApi, sendConfiguratorFieldsApi } from '../../../api/Projects/projects';
import Loading from '../../../components/Loading/Loading';
import { tProjectKey } from '../../../helpers/translate';
import webStyle from '../../../helpers/webStyle';
import { CodeNamePair } from '../../../redux/common/definitions';
import {
  FormAssistantSections as FormAssistantSectionsProject,
  SectionProject,
  SizeConfiguratorData,
} from '../../../redux/kpis/definitions';
import { ConfiguratorData } from '../../../redux/project/definitions';
import styles from './dragAndDropCustomFields.module.scss';
import DraggableElement from './DraggableElement';
import {
  FormAssistantFollowUpSections,
  FormAssistantGeneralFormSections,
  SectionInternalProject,
} from '../../../redux/internal-projects/definitions';

export interface DragAndDropCustomFieldsRef {
  saveData: () => void;
  getData: () => ConfiguratorData[];
}

// eslint-disable-next-line no-empty-pattern
const DragAndDropCustomFields = forwardRef(({}, ref: Ref<DragAndDropCustomFieldsRef>) => {
  const history = useHistory();
  const urlSections = window.location.href.split('/');
  const id = Number(urlSections[urlSections.length - 2]);
  const section = urlSections[urlSections.length - 1];
  const isKPI = section.includes('kpis');
  const isInternalProject = window.location.href.includes('plantilla');
  let FormAssistantSections: SectionInternalProject[] | SectionProject[] = [];
  if (isInternalProject) {
    const isGenralForm = FormAssistantGeneralFormSections.find((fs: SectionInternalProject) => fs.code === section);
    if (isGenralForm) FormAssistantSections = FormAssistantGeneralFormSections;
    else FormAssistantSections = FormAssistantFollowUpSections;
  } else if (!isKPI) FormAssistantSections = FormAssistantSectionsProject; //is general project but no KPI
  const currentSectionIndex = FormAssistantSections.findIndex(
    (fs: SectionInternalProject | SectionProject) => fs.code === section,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [customFieldSizes, setCustomFieldSizes] = useState<CodeNamePair[]>([] as CodeNamePair[]);
  const [selectedList, setSelectedList] = useState<ConfiguratorData[]>([] as ConfiguratorData[]);
  const [unSelectedList, setUnSelectedList] = useState<ConfiguratorData[]>([] as ConfiguratorData[]);

  const { handleSubmit, control, errors } = useForm<{ sizeSelected: Array<string | null> }>();

  useEffect(() => {
    async function fetchCustomFields() {
      let customFieldsData = [] as ConfiguratorData[];

      setLoading(true);

      if (isInternalProject) {
        const res = await getCustomFieldsBySection(id, section);
        customFieldsData = res.data;
      } else {
        const res = await getConfiguratorFieldsApi(id, isKPI ? 'kpis' : section);
        customFieldsData = res.data;
      }

      const selectedCustomFields = [] as ConfiguratorData[];
      const unselectedCustomFields = [] as ConfiguratorData[];
      customFieldsData.forEach((e: ConfiguratorData) => {
        if (e.selected) selectedCustomFields.push(e);
        else unselectedCustomFields.push(e);
      });

      setSelectedList(selectedCustomFields);
      setUnSelectedList(unselectedCustomFields);
      setLoading(false);
    }
    fetchCustomFields();
  }, [id, isInternalProject, isKPI, section]);

  useEffect(() => {
    async function fetchCustomFieldSizes() {
      if (customFieldSizes.length === 0) {
        const metadata = await getCustomFieldSizes();
        setCustomFieldSizes(metadata.data);
      }
    }
    fetchCustomFieldSizes();
  }, [customFieldSizes]);

  const updateSelectedListPosition = () => {
    const selectedListUpdated = selectedList.map((e: ConfiguratorData, index: number) => {
      e.position = index + 1;
      return e;
    });
    setSelectedList(selectedListUpdated);
  };
  const prepareDataToSend = (data: { sizeSelected: Array<string | null> }) => {
    selectedList.forEach((e: ConfiguratorData, index: number) => {
      e.position = index + 1;
      e.size = data?.sizeSelected[index] as SizeConfiguratorData;
    });
    return unSelectedList.concat(selectedList);
  };

  const handleSaveData = (data: { sizeSelected: Array<string | null> }) => {
    const dataToSend = prepareDataToSend(data);
    if (dataToSend.length > 0) {
      if (isInternalProject) saveCustomFieldsBySection(id, section, dataToSend);
      else sendConfiguratorFieldsApi(id, isKPI ? 'kpis_gf' : section, dataToSend);
    }
  };

  useImperativeHandle(ref, () => ({
    saveData: () => {
      const data = { sizeSelected: [] as string[] };
      const obj = control.getValues();
      for (const key in obj) {
        if (key.includes('sizeSelected')) {
          const index = Number(key.split('[')[1].split(']')[0]); //i.e: sizeSelected[0]
          data.sizeSelected[index] = obj[key];
        }
      }
      handleSaveData(data);
    },
    getData: () => {
      return selectedList;
    },
  }));

  if (loading) return <Loading big />;

  const handleNextPage = (data: { sizeSelected: Array<string | null> }) => {
    handleSaveData(data);
    const nextSection = FormAssistantSections[currentSectionIndex + 1];
    const template = isInternalProject ? '-interno/plantilla' : '';
    history.push(`/proyecto${template}/asistente-formularios/${id}/${nextSection.code}`);
  };

  const handlePreviousPage = (data: { sizeSelected: Array<string | null> }) => {
    handleSaveData(data);
    const previousSection = FormAssistantSections[currentSectionIndex - 1];
    const template = isInternalProject ? '-interno/plantilla' : '';
    history.push(`/proyecto${template}/asistente-formularios/${id}/${previousSection.code}`);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    // 4 cases: left-to-left, left-to-right, right-to-right, right-to-left
    if (source.droppableId === 'unSelectedData' && destination.droppableId === 'unSelectedData') {
      // remove and add in same list
      const selectedItem = unSelectedList[source.index];
      unSelectedList.splice(source.index, 1); // remove
      unSelectedList.splice(destination.index, 0, selectedItem); //add
      // note: it is not necesarry to setData because splice already refresh data
    } else if (source.droppableId === 'unSelectedData' && destination.droppableId === 'selectedData') {
      // remove and add in diferent list
      const selectedItem = unSelectedList[source.index];
      unSelectedList.splice(source.index, 1);
      selectedItem.selected = true;
      selectedList.splice(destination.index, 0, selectedItem);
    } else if (source.droppableId === 'selectedData' && destination.droppableId === 'selectedData') {
      // remove and add in same list
      const selectedItem = selectedList[source.index];
      selectedList.splice(source.index, 1); // remove
      selectedList.splice(destination.index, 0, selectedItem);
    } else if (source.droppableId === 'selectedData' && destination.droppableId === 'unSelectedData') {
      // remove and add in diferent list
      const selectedItem = selectedList[source.index];
      if (selectedItem.project_default === 1) return; // avoid dragging
      selectedList.splice(source.index, 1);
      selectedItem.selected = false;
      unSelectedList.splice(destination.index, 0, selectedItem);
    }
    updateSelectedListPosition();
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className={styles.listFields}>
            <div className={styles[`titleFieldList_${webStyle}`]}>{tProjectKey('Lista selección')}</div>
            <Droppable droppableId={'unSelectedData'}>
              {(provided: DroppableProvided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {unSelectedList.map((e: ConfiguratorData, index: number) => (
                    <Draggable draggableId={e.code} key={e.id} index={index}>
                      {provided => (
                        <div
                          className={styles[`field_${webStyle}`]}
                          ref={provided.innerRef}
                          key={e.id}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DraggableElement
                            field={e}
                            name={{ size: `sizeDefault[${index}]` }}
                            customFields_sizes={customFieldSizes}
                            readonly
                            control={control}
                            errors={errors}
                            setFieldValue={(value: SizeConfiguratorData) => {
                              unSelectedList[index].size = value;
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className={styles.listFields}>
            <div className={styles[`titleFieldList_${webStyle}`]}>{tProjectKey('Lista Final')}</div>
            <Droppable droppableId={'selectedData'}>
              {(provided: DroppableProvided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedList.map((e: ConfiguratorData, index: number) => (
                    <Draggable draggableId={e.code} key={e.id} index={index}>
                      {provided => (
                        <div
                          className={
                            e.project_default === 1 ? styles[`fieldRequired_${webStyle}`] : styles[`field_${webStyle}`]
                          }
                          key={e.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DraggableElement
                            field={e}
                            name={{ size: `sizeSelected[${index}]` }}
                            customFields_sizes={customFieldSizes}
                            control={control}
                            errors={errors}
                            setFieldValue={(value: SizeConfiguratorData) => {
                              selectedList[index].size = value;
                            }}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      {!isKPI && (
        <div className={styles.pagination}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit(handlePreviousPage)}
            startIcon={<ArrowBackIcon />}
            disabled={currentSectionIndex < 1}
          >
            {tProjectKey('Anterior')}
          </Button>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={handleSubmit(handleNextPage)}
            endIcon={<ArrowForwardIcon />}
            disabled={currentSectionIndex >= FormAssistantSections.length - 1}
          >
            {tProjectKey('Siguiente')}
          </Button>
        </div>
      )}
    </>
  );
});

export default DragAndDropCustomFields;
