import React from 'react';
import { connect } from 'react-redux';
import { tErrorKey, tKey } from '../../helpers/translate';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import { AppState } from '../../redux/root-reducer';

export interface CopyOnClickProps {
  field: string | null | undefined;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
}

const CopyOnClickTable: React.FC<CopyOnClickProps> = ({ field, showSnackbar }) => {
  const handleClick = () => {
    if (typeof navigator.clipboard == 'undefined') {
      console.log(window.isSecureContext);
    } else {
      navigator.clipboard
        .writeText(field || '')
        .then(() => showSnackbar(`${field} ${tKey('copiado al portapapeles')}`, 'info', undefined, 1500))
        .catch(() =>
          showSnackbar(`${tErrorKey('Algo ha ido mal, no se ha podido copiar')}`, 'warning', undefined, 1500),
        );
    }
  };
  return (
    <div style={{ cursor: 'pointer' }} onClick={handleClick}>
      {field}
    </div>
  );
};

const mapStateProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateProps, mapDispatchToProps)(CopyOnClickTable);
