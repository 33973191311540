/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../../../components/Forms/FormContainer';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../../../components/Loading/Loading';
import {
  zeroToFiveWithLabelOptions,
  zeroToFiveWithoutLabelOptions,
} from '../../../../../components/RadioButtons/RadioButtonOptions';
import RadioButtons from '../../../../../components/RadioButtons/RadioButtons';
import SelectController from '../../../../../components/Select/SelectController';
import Title from '../../../../../components/Title/Title';
import { tFormKey, tKey, tProjectKey } from '../../../../../helpers/translate';
import {
  getParticipantesFinalFollowUpForm,
  updateParticipantesFinalFollowUpForm,
} from '../../../../../redux/internal-projects/actions';
import { ParticipantesFinalFollowUpForm } from '../../../../../redux/internal-projects/definitions';
import { AppState } from '../../../../../redux/root-reducer';
import { EndCourseSchema } from '../../../../../validations/formEndCourseSchema';
import InformationMinimal from '../Information/InformationMinimal';
import styles from './endCourseFollows.module.scss';

interface EndCourseFollowProps {
  view: boolean;
  loadingAlt: boolean;
  enrollmentId: number;
  userFinalForm: ParticipantesFinalFollowUpForm;
  handleClose: () => void;
  getFinalFormOptions: (id: number) => void;
  updateParticipanteFinalForm: (id: number, data: Partial<ParticipantesFinalFollowUpForm>) => void;
}
const EndCourseFollow: React.FC<EndCourseFollowProps> = ({
  view,
  enrollmentId,
  loadingAlt,
  userFinalForm,
  handleClose,
  getFinalFormOptions,
  updateParticipanteFinalForm,
}) => {
  const { handleSubmit, register, control, errors } = useForm<ParticipantesFinalFollowUpForm>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: EndCourseSchema,
  });

  useEffect(() => {
    getFinalFormOptions(enrollmentId);
  }, [getFinalFormOptions, enrollmentId]);
  const handleSaveForm = (data: Partial<ParticipantesFinalFollowUpForm>) => {
    updateParticipanteFinalForm(enrollmentId, data);
    handleClose();
  };
  const handleSendForm = (data: Partial<ParticipantesFinalFollowUpForm>) => {
    updateParticipanteFinalForm(enrollmentId, { ...data, follow_up_final_done: 1 });
  };

  return (
    <Dialog
      open={view}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      closeAfterTransition
      maxWidth="xl"
    >
      <div className={styles.title}>
        <DialogTitle id="alert-dialog-title">{tKey('ip.evaluacion_final_curso')}</DialogTitle>
        <IconButton aria-label="delete" className={styles.buttonClose} size="small" onClick={() => handleClose()}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      <DialogContent className={styles.dialogContent}>
        <form className={styles.form}>
          {loadingAlt && <Loading big />}
          {loadingAlt || (
            <>
              <Title>{tKey('ip.seguimiento_entidades_final_curso')}</Title>
              <InformationMinimal />
              <FormContainer title={tKey('ip.datos_participante')}>
                <div className={styles.form}>
                  <TextInputController
                    size="50"
                    label={tFormKey('ip.nombre')}
                    name="name"
                    control={control}
                    defaultValue={userFinalForm.name}
                    errors={errors}
                    schema={EndCourseSchema}
                    disabled
                  />
                </div>
                <TextInputController
                  size="50"
                  label={tFormKey('ip.1er_apellido')}
                  name="surname"
                  control={control}
                  defaultValue={userFinalForm?.surname}
                  errors={errors}
                  schema={EndCourseSchema}
                  disabled
                />
                <TextInputController
                  size="50"
                  label={tFormKey('ip.2do_apellido')}
                  name="surname2"
                  control={control}
                  defaultValue={userFinalForm.surname2}
                  errors={errors}
                  schema={EndCourseSchema}
                  disabled
                />
                <TextInputController
                  size="50"
                  label={tFormKey('ip.fecha_nacimiento')}
                  name="birthdate"
                  control={control}
                  defaultValue={userFinalForm.birthdate}
                  errors={errors}
                  schema={EndCourseSchema}
                  disabled
                />
                <TextInputController
                  size="50"
                  label={tFormKey('ip.genero')}
                  name="gender"
                  control={control}
                  defaultValue={userFinalForm.gender}
                  errors={errors}
                  schema={EndCourseSchema}
                  disabled
                />
                <div className={styles.formSpanish}>
                  <SelectController
                    control={control}
                    schema={EndCourseSchema}
                    errors={errors}
                    size="50"
                    key="spanish_level"
                    name="spanish_level"
                    defaultValue={userFinalForm.spanish_level}
                    label={tFormKey('ip.nivel_español')}
                  >
                    {userFinalForm?.spanish_levels?.map(level => (
                      <MenuItem key={level.id} value={level.value}>
                        {level.value}
                      </MenuItem>
                    ))}
                  </SelectController>
                </div>

                <FormContainer title={tKey('ip.progreso_joven')} className={styles.radioSpace}>
                  <div className={styles.radioGroupStyle}>
                    <span className={styles.legend}>{tKey('ip.valora_de_1_a_5')}</span>
                    <div className={styles.questionPlacementLabel}>
                      <InputLabel>{tFormKey('ip.conocimiento_itinerario')}</InputLabel>
                      <RadioButtons
                        errors={errors.awareness}
                        name="awareness"
                        options={zeroToFiveWithLabelOptions}
                        defaultValue={userFinalForm.awareness ?? '0'}
                        register={register}
                      />
                    </div>
                    <div className={styles.questionPlacement}>
                      <InputLabel>{tFormKey('ip.autoconocimiento')}</InputLabel>
                      <RadioButtons
                        errors={errors.auto_knowledge}
                        name="auto_knowledge"
                        options={zeroToFiveWithoutLabelOptions}
                        defaultValue={userFinalForm.auto_knowledge ?? '0'}
                        register={register}
                      />
                    </div>
                    <div className={styles.questionPlacement}>
                      <InputLabel>{tFormKey('ip.motivacion')}</InputLabel>
                      <RadioButtons
                        errors={errors.motivation}
                        name="motivation"
                        options={zeroToFiveWithoutLabelOptions}
                        defaultValue={userFinalForm.motivation ?? '0'}
                        register={register}
                      />
                    </div>
                    <div className={styles.questionPlacement}>
                      <InputLabel>{tFormKey('ip.confianza')}</InputLabel>
                      <RadioButtons
                        errors={errors.confidence}
                        name="confidence"
                        options={zeroToFiveWithoutLabelOptions}
                        defaultValue={userFinalForm.confidence ?? '0'}
                        register={register}
                      />
                    </div>
                  </div>
                </FormContainer>
                <span className={styles.questions}>{tKey('ip.planes')}</span>
                <SelectController
                  control={control}
                  schema={EndCourseSchema}
                  errors={errors}
                  size="100"
                  key="planes"
                  name="enrolled_plan_id"
                  defaultValue={userFinalForm.enrolled_plan_id}
                  label={tFormKey('ip.planes_joven')}
                >
                  {userFinalForm.enrolled_plans?.map(plan => (
                    <MenuItem key={plan.id} value={plan.id}>
                      {plan.value}
                    </MenuItem>
                  ))}
                </SelectController>
                <span className={styles.questions}>{tFormKey('ip.estudios_cursar')}</span>
                <SelectController
                  control={control}
                  schema={EndCourseSchema}
                  errors={errors}
                  size="50"
                  key="training_type_id1"
                  name="training_type_id1"
                  defaultValue={userFinalForm.training_type_id1}
                  label={tFormKey('ip.1_opcion')}
                >
                  {userFinalForm.training_types?.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.value}
                    </MenuItem>
                  ))}
                </SelectController>
                <SelectController
                  control={control}
                  schema={EndCourseSchema}
                  errors={errors}
                  size="50"
                  key="training"
                  name="training_type_id2"
                  defaultValue={userFinalForm.training_type_id2}
                  label={tFormKey('ip.2_opcion')}
                >
                  {userFinalForm.training_types?.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.value}
                    </MenuItem>
                  ))}
                </SelectController>
                <span className={styles.questions}>{tKey('ip.estudios_concretos')}</span>
                <TextInputController
                  size="100"
                  label={tFormKey('ip.1_opcion')}
                  name="training_type_option1"
                  control={control}
                  defaultValue={userFinalForm.training_type_option1}
                  errors={errors}
                  schema={EndCourseSchema}
                  rowsMax="2"
                />
                <TextInputController
                  size="100"
                  label={tFormKey('ip.2_opcion')}
                  name="training_type_option2"
                  control={control}
                  defaultValue={userFinalForm.training_type_option2}
                  errors={errors}
                  schema={EndCourseSchema}
                  rowsMax="2"
                />
              </FormContainer>
              <div className={styles.btn_create}>
                <Button color="primary" variant="outlined" onClick={handleSubmit(handleSaveForm)}>
                  {tProjectKey('Guardar')}
                </Button>
                {!userFinalForm?.follow_up_final_done ? (
                  <Button color="primary" variant="outlined" onClick={handleSubmit(handleSendForm)}>
                    {tFormKey('ip.enviar')}
                  </Button>
                ) : null}
                <Button color="primary" variant="outlined" onClick={handleClose}>
                  {tProjectKey('Cancelar')}
                </Button>
              </div>
            </>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  loadingAlt: state.internalProjectReducer.loadingAlt,
  userFinalForm: state.internalProjectReducer.participanteFinalForm,
});

const mapDispatchToProps = (dispatch: any) => ({
  getFinalFormOptions: (enrollmentId: number) => dispatch(getParticipantesFinalFollowUpForm(enrollmentId)),
  updateParticipanteFinalForm: (enrollmentId: number, data: Partial<ParticipantesFinalFollowUpForm>) =>
    dispatch(updateParticipantesFinalFollowUpForm(enrollmentId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndCourseFollow);
