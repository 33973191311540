import { DocumentationItem } from '../redux/common/definitions';
import { ServiceData, ServicesUser, UpdateServiceFormFields, ServiceRequestData } from '../redux/service/definitions';
import { apiBaseUrl, apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from './api';

const baseURL = apiBaseUrl;

export const createService = async (
  service: ServiceData,
): Promise<Result<{ data: ServiceData }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({
      ...service,
      reminder: false,
      cancelled: false,
      max_capacity: 30,
    }),
  };

  return validatedApiCall<{ data: ServiceData }>(`${baseURL}/services/`, init);
};

export const getServices = async (): Promise<ServiceData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<ServiceData[]>(`${baseURL}/services/`, init);
};

export const getUserServices = async (): Promise<ServicesUser[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<ServicesUser[]>(`${baseURL}/services/user/assist`, init);
};

export const getServiceData = async (id: string): Promise<{ data: ServiceData }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ServiceData }>(`${baseURL}/services/${id || 0}`, init);
};

export const updateService = async (
  data: UpdateServiceFormFields,
  id: number,
): Promise<Result<{ data: ServiceData }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: ServiceData }>(`${baseURL}/services/${id}`, init);
};

export const getServiceRequestedData = async (id: number): Promise<{ data: ServiceRequestData[] }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ServiceRequestData[] }>(`${baseURL}/services/requested/${id}`, init);
};

export const addUserToService = async (id: number, service_id: number): Promise<{ data: ServiceRequestData }> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  return apiCall<{ data: ServiceRequestData }>(`${baseURL}/services/${service_id}/requested/${id}`, init);
};

export const updateServiceAssistanceData = async (id: number, data: ServiceRequestData[]): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: 'OK' }>(`${baseURL}/services/${id}/requests`, init);
};

export const removeServiceRequested = async (requestedId: number): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<{ data: 'OK' }>(`${baseURL}/services/requested/${requestedId}`, init);
};
export const uploadServiceDocument = async (
  documentationId: number,
  serviceId: number,
  file: File,
): Promise<DocumentationItem[]> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const {
    data: { documents },
  } = await apiCall<{ data: { documents: DocumentationItem[] } }>(
    `${baseURL}/services/document/${documentationId}/${serviceId}`,
    init,
  );
  return documents;
};

export const downloadServiceDocument = async (fileId: number, serviceId: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const blob = await apiCallBlob(`${baseURL}/services/document/${serviceId}/${fileId}`, init);
  return blob;
};

export const removeServiceDocument = async (fileId: number, serviceId: number): Promise<DocumentationItem[]> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const {
    data: { documents },
  } = await apiCall<{ data: { documents: DocumentationItem[] } }>(
    `${baseURL}/services/document/${serviceId}/${fileId}`,
    init,
  );
  return documents;
};
