import { Checkbox, FormControl, Input, ListItemText, MenuItem, Select } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import React from 'react';
import { IdBooleanNamePair, IdCodeNamePair } from '../../../redux/common/definitions';
import styles from './selectCheckbox.module.scss';

export type ListSelectCheckbox = (IdCodeNamePair | IdBooleanNamePair) & { color?: string };

type KeyValues = keyof Omit<IdCodeNamePair, 'id'>;

export interface SelectCheckboxProps {
  list: ListSelectCheckbox[];
  valueSend: KeyValues;
  valueLabel: KeyValues;
  columnDef: any;
  onFilterChanged: (rowId: string, value: any) => void;
  multiple?: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectCheckbox: React.FC<SelectCheckboxProps> = ({
  list,
  valueSend,
  valueLabel,
  columnDef,
  onFilterChanged,
  multiple,
}) => {
  const [valueArr, setvalueArr] = React.useState<string[]>([]);
  const [selectedArr, setSelectedArr] = React.useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedArr(
      list
        .filter(element => {
          //@ts-ignore
          return event.target.value.includes(element.code);
        })
        .map(element => element.name),
    );
    setvalueArr(event.target.value as string[]);
    onFilterChanged(columnDef.tableData.id, event.target.value);
  };

  const handleClean = () => {
    setvalueArr([]);
    onFilterChanged(columnDef.tableData.id, []);
  };

  return (
    <FormControl variant="outlined" className={styles.select__formControl}>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple={multiple}
        input={<Input />}
        value={valueArr}
        onChange={handleChange}
        renderValue={selected => (selectedArr as string[]).join(', ')}
        MenuProps={MenuProps}
        IconComponent={() =>
          valueArr.length ? <Close className={styles.select__close} onClick={handleClean} /> : null
        }
      >
        {list?.length
          ? list.map(element => (
              <MenuItem key={element.id} value={element[valueSend]}>
                <Checkbox checked={valueArr.indexOf(`${element[valueSend]}`) > -1} />
                <ListItemText primary={element[valueLabel]} />
                <Brightness1Icon style={{ color: element.color, height: '15px', verticalAlign: 'text-bottom' }} />
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
};

export default SelectCheckbox;
