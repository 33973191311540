import { AppRoute, routes_definition } from '../constants/routes';

export const getRoute = (
  route: string,
  params?: {
    [index: string]: string;
  },
): string => {
  const routeFind = routes_definition.find(r => {
    return r.name === route;
  }) as AppRoute;
  let path = routeFind.path || '';
  if (params) {
    Object.keys(params).forEach(parameter => {
      path = path.replace(`:${parameter}`, params[parameter]);
    });
  }
  return path;
};
