import { permissionType } from '../constants/permissions_definitions';
import { apiBaseUrl, apiCall } from './api';

const baseURL = apiBaseUrl;

interface GetUserPermissionsProps {
  data: {
    permissions: permissionType[];
  };
}
export const getUserPermissions = async (): Promise<GetUserPermissionsProps> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<GetUserPermissionsProps>(`${baseURL}/user/permissions`, init);
};
