import React from 'react';
import Layout, { LayoutProps } from './Layout';
import styles from './tableLayout.module.scss';

type TableLayout = Pick<
  LayoutProps,
  'children' | 'leftSubmenu' | 'rightSubmenu' | 'footerMenuDisabled' | 'layoutDisabled'
>;

const TableLayout: React.FC<TableLayout> = ({ children, ...layoutProps }) => (
  <Layout {...layoutProps}>
    <div className={styles.table_container}>{children}</div>
  </Layout>
);
export default TableLayout;
