import { UpdateFamilyIncomeConst } from './action_types';
import { UpdateFamilyIncomeDataAction, UpdateFamilyIncomeState } from './definitions.d';
import assertUnreachable from '../../../../helpers/assertUnreachable';
import { FamilyIncome, FamilyIncomesInitialState } from '../FamilyIncome.d';

const updateFamilyIncomeReducer = (
  state = FamilyIncomesInitialState,
  action: UpdateFamilyIncomeDataAction,
): UpdateFamilyIncomeState => {
  switch (action.type) {
    case UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME_OK:
      const { income } = action;
      const listUpdated = state.list.map((item: FamilyIncome) => {
        if (item.id === income.id) {
          return income;
        }
        return item;
      });
      return {
        ...state,
        list: listUpdated,
        loading: false,
      };
    case UpdateFamilyIncomeConst.UPDATE_FAMILY_INCOME_KO:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      assertUnreachable(action);
      return state;
  }
};

export default updateFamilyIncomeReducer;
