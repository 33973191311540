/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonProps, InputAdornment, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { APIValidationError } from '../../../../api/api';
import CheckboxCompo from '../../../../components/Checkbox/CheckboxCompo';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../../components/Loading/Loading';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';
import SelectController from '../../../../components/Select/SelectController';
import SignatureDocuments from '../../../../components/SignatureDocuments/SignatureDocuments';
import Title from '../../../../components/Title/Title';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { DATE_FORMAT } from '../../../../constants/date';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { documentationFilter } from '../../../../helpers/docFilter';
import { tErrorKey, tScholarshipKey } from '../../../../helpers/translate';
import { listAcademyIbansByCode } from '../../../../redux/academy/actions';
import { selectUserRoleCode } from '../../../../redux/auth/selectors';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { AppState } from '../../../../redux/root-reducer';
import {
  cancelScholarshipAgreement,
  saveAgreement,
  scholarshipAgreementSetData,
  sendProviderInfo,
  setScholarshipStep,
  updateAgreementCalendar,
} from '../../../../redux/scholarship/actions';
import {
  CenterCost,
  ScholarshipAgreementCode,
  ScholarshipAgreementData,
  ScholarshipData,
  ScholarshipStateCode,
} from '../../../../redux/scholarship/definitions';
import {
  selectAcademyIban,
  selectGetScholarshipDocumentationPerCode,
  selectProviderLoading,
  selectScholarshipDisableCancel,
  selectScholarshipValidationErrors,
} from '../../../../redux/scholarship/selectors';
import RoleCode from '../../../../types/Roles/roleCode';
import Documentation from '../../../../types/Scholarships/Documentation';
import { PaymentAcademySchema, PaymentIntituteSchema } from '../../../../validations/scholarshipSchema';
import validate from '../../../../validations/validators';
import PaymentAdjustmentFormModal from '../../Modals/PaymentAdjustmentFormModal';
import styles from './paymentForm.module.scss';

interface PaymentFormProps {
  readOnly: boolean;
  buttonActionSaveAgreement: ButtonProps;
  buttonActionAdjustment: ButtonProps;
  buttonActionSendProvider: ButtonProps;
  buttonActionEdit: ButtonProps;
  buttonActionSave: ButtonProps;
  buttonCancelAgreement: ButtonProps;
  genericMetadata: GenericMetadata;
  scholarshipData: ScholarshipData;
  scholarshipAgreementData: ScholarshipAgreementData;
  documentation: DocumentationItem[];
  validationErrors: APIValidationError | null;
  roleCode: RoleCode;
  loadingAgreement: boolean;
  providerLoading: boolean;
  allowPaymentAdjustment: boolean;
  can_cancel_agreement: boolean;
  disable_cancel_agreement: boolean;
  ibans: { iban: string; alias: string }[];
  listAcademyIbansByCode: (id: string) => void;
  setData: (key: string, value: string | number) => void;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
  sendProvider: (scholarshipId: number, retentionCode: string, centerCost: CenterCost) => void;
  cancelAgreement: (scholarship_id: number) => void;
  setScholarshipStep: (step: number) => void;
  saveAgreement: (id: number, data: ScholarshipAgreementData) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  readOnly,
  buttonActionSaveAgreement,
  buttonActionAdjustment,
  buttonActionSendProvider,
  buttonActionEdit,
  buttonActionSave,
  buttonCancelAgreement,
  genericMetadata,
  scholarshipData,
  scholarshipAgreementData,
  documentation,
  validationErrors,
  loadingAgreement,
  providerLoading,
  allowPaymentAdjustment,
  ibans,
  roleCode,
  can_cancel_agreement,
  disable_cancel_agreement,
  setData,
  listAcademyIbansByCode,
  uploadDocument,
  downloadDocument,
  getFile,
  deleteDocument,
  sendProvider,
  saveAgreement,
  cancelAgreement,
  setScholarshipStep,
}) => {
  const {
    id,
    agreement_state,
    agreement_number,
    scholarship_academy_confirm,
    scholarship_academy_confirm_code,
  } = scholarshipData;

  const {
    student_document_type,
    student_document_number,
    center_cost_channel_code,
    center_cost_delegation_code,
    center_cost_area_code,
    center_cost_project_code,
    retention_code,
    concept,
    description,
    first_reload_amount,
    second_reload_amount,
    third_reload_amount,
    additional_reload_amount,
    first_reload_date,
    second_reload_date,
    third_reload_date,
    additional_reload_date,
    first_payment_amount,
    second_payment_amount,
    third_payment_amount,
    first_payment_date,
    second_payment_date,
    third_payment_date,
    academy_payment,
    last_scholarship_academy,
    academy_contact,
    first_reload_done_date,
    second_reload_done_date,
    third_reload_done_date,
    additional_reload_done_date,
    first_payment_done_date,
    second_payment_done_date,
    third_payment_done_date,
    total_cost,
    provider_code,
    payable_account,
    expense_account,
    available,
    first_payment_account_number,
    second_payment_account_number,
    third_payment_account_number,
    account_number,
    student_account_number,
  } = scholarshipAgreementData;

  const isAluIns = roleCode.isJovenInstituto();
  const studentPayAll = student_account_number === account_number;

  const { control, errors, handleSubmit, register, setError, clearError, watch } = useForm<ScholarshipAgreementData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: academy_payment ? PaymentAcademySchema : PaymentIntituteSchema,
  });

  useValidatorAPI(validationErrors, setError);

  useEffect(() => {
    listAcademyIbansByCode(scholarship_academy_confirm_code || '');
  }, [listAcademyIbansByCode, scholarship_academy_confirm_code]);

  const onChangeDefaultAccountNumber = (value: string) => {
    if (first_payment_account_number === '') {
      setData('first_payment_account_number', value);
    }

    if (second_payment_account_number === '') {
      setData('second_payment_account_number', value);
    }

    if (third_payment_account_number === '') {
      setData('third_payment_account_number', value);
    }
  };

  useEffect(() => {
    if (!account_number && ibans.length) {
      setData('account_number', ibans[0].iban);
    }

    if (ibans.length) {
      onChangeDefaultAccountNumber(account_number ? account_number : ibans[0].iban);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account_number, ibans]);

  const [scholarshipIdToAdjust, setScholarshipIdToAdjust] = React.useState<number>(0);
  const disabled = !(agreement_state === ScholarshipAgreementCode.CONFIRM || !readOnly);

  buttonActionEdit.hidden = true;
  buttonActionSave.hidden = true;
  buttonActionSaveAgreement.hidden = disabled;
  buttonActionSendProvider.hidden = false;
  buttonActionAdjustment.hidden = !allowPaymentAdjustment;
  buttonActionAdjustment.disabled = available !== 1;
  buttonActionSendProvider.disabled =
    !(center_cost_channel_code && center_cost_delegation_code && center_cost_area_code && center_cost_project_code) ||
    disabled;

  const sendProviderInfo = () => {
    const centerCost: CenterCost = {
      center_cost_channel_code,
      center_cost_delegation_code,
      center_cost_area_code,
      center_cost_project_code,
    };
    sendProvider(scholarshipData.id, retention_code, centerCost);
  };

  const checkAccountNumber = (name: string, number: string) => {
    if (validate.accountNumber(number, 'España')) {
      clearError(name);
      return true;
    } else {
      setError(name, 'invalidAccountNumber', tErrorKey('IBAN incorrecto'));
      return false;
    }
  };

  const saveAgreementInfo = (data: ScholarshipAgreementData) => {
    if (checkAccountNumber('account_number', data.account_number)) {
      saveAgreement(scholarshipData.id, data);
    }
  };

  const openAdjustmentModal = () => {
    setScholarshipIdToAdjust(scholarshipData.id);
  };

  const closeAdjustmentModal = async () => {
    setScholarshipIdToAdjust(0);
  };

  const cancelScholarshipAgreement = () => {
    cancelAgreement(id);
    setScholarshipStep(0);
  };

  const emptyOne = [{ alias: ' - ', iban: '0' }];

  buttonCancelAgreement.hidden =
    !can_cancel_agreement &&
    scholarshipData.state_code !== ScholarshipStateCode.ASSIGNED &&
    scholarshipData.state_code !== ScholarshipStateCode.CONFIRMATION;
  buttonCancelAgreement.disabled = disable_cancel_agreement;

  useButtonAction(buttonActionSaveAgreement, handleSubmit(saveAgreementInfo));
  useButtonAction(buttonActionSendProvider, sendProviderInfo);
  useButtonAction(buttonActionAdjustment, openAdjustmentModal);
  useButtonAction(buttonCancelAgreement, cancelScholarshipAgreement);

  if (loadingAgreement) {
    return (
      <div className={styles.main}>
        <Loading big />
      </div>
    );
  }

  const changeDate = (name: string, date: moment.Moment | null) => {
    if (date && date.isValid()) {
      setData(name, date.format(DATE_FORMAT));
    }
  };

  const asingDateValue = (date: string | number | null) => {
    return date ? moment(date, DATE_FORMAT) : null;
  };

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const handleSelectChangeIban = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
      onChangeDefaultAccountNumber(target.value);
    }
  };

  const has_agreement = !!agreement_number;

  const renderAgreementDocument = (): JSX.Element | undefined => {
    if (has_agreement) {
      const documentationConvenio = documentation.filter(e => e.code === Documentation.CONVENIO);
      if (documentationConvenio.length > 0) {
        return (
          <FormContainer className={styles.doc_container} title={tScholarshipKey('Convenio')}>
            <FncAddDocuments
              documents={documentationConvenio}
              actions={['download', 'view']}
              disabled={disabled || !roleCode.isUsuarioAdministracion()}
              genericId={id}
              required
              originalName
              getFile={getFile}
              download={downloadDocument}
            />
          </FormContainer>
        );
      }
    }
    return undefined;
  };

  const renderSignatureDocuments = (): JSX.Element | undefined => {
    if (agreement_state === ScholarshipAgreementCode.CONFIRM) {
      return <SignatureDocuments title={'Addendas convenio'} type={'scholarships'} type_id={id} />;
    }
    return undefined;
  };

  const renderProviderInfo = (): JSX.Element => {
    if (providerLoading) {
      return (
        <div style={{ width: '100%', height: '80px', padding: '40px 0px 0px 0px', boxSizing: 'border-box' }}>
          <Loading small />
        </div>
      );
    }

    return (
      <>
        <TextInputController
          size="30"
          schema={PaymentIntituteSchema}
          control={control}
          name="provider_code"
          errors={errors}
          defaultValue={provider_code}
          label={tScholarshipKey('Código de proveedor')}
          disabled
        />
        <TextInputController
          size="30"
          schema={PaymentIntituteSchema}
          control={control}
          name="payable_account"
          errors={errors}
          defaultValue={payable_account}
          label={tScholarshipKey('Cuenta contable de proveedor')}
          disabled
        />
        <TextInputController
          size="30"
          schema={PaymentIntituteSchema}
          control={control}
          name="expense_account"
          errors={errors}
          defaultValue={expense_account}
          label={tScholarshipKey('Cuenta contable de gasto')}
          disabled
        />
      </>
    );
  };

  const renderAccountingData = (): JSX.Element | undefined => {
    if (!isAluIns) {
      const { channels, delegations, areas, projects, taxRetentions } = genericMetadata;

      return (
        <FormContainer title={tScholarshipKey('Datos contables')}>
          {renderProviderInfo()}

          <SelectController
            fullWidth
            control={control}
            errors={errors}
            schema={PaymentIntituteSchema}
            name="center_cost_channel_code"
            disabled={disabled}
            size="50"
            defaultValue={center_cost_channel_code}
            label={tScholarshipKey('Canal')}
            onClick={e => handleSelectChange(e.target)}
          >
            {channels.map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.name}
              </MenuItem>
            ))}
          </SelectController>
          <SelectController
            fullWidth
            control={control}
            errors={errors}
            schema={PaymentIntituteSchema}
            name="center_cost_delegation_code"
            disabled={disabled}
            size="50"
            defaultValue={center_cost_delegation_code}
            label={tScholarshipKey('Delegación')}
            onClick={e => handleSelectChange(e.target)}
          >
            {delegations
              ?.filter(
                e =>
                  e.channel_code === watch('center_cost_channel_code', center_cost_channel_code) ||
                  e.channel_code === null,
              )
              .map(type => (
                <MenuItem key={type.code} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
          </SelectController>
          <SelectController
            fullWidth
            control={control}
            errors={errors}
            schema={PaymentIntituteSchema}
            name="center_cost_area_code"
            disabled={disabled}
            size="50"
            defaultValue={center_cost_area_code}
            label={tScholarshipKey('Área')}
            onClick={e => handleSelectChange(e.target)}
          >
            {areas
              ?.filter(
                e =>
                  e.delegation_code === watch('center_cost_delegation_code', center_cost_delegation_code) ||
                  e.delegation_code === null,
              )
              .map(type => (
                <MenuItem key={type.code || '0'} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
          </SelectController>
          <SelectController
            fullWidth
            control={control}
            errors={errors}
            schema={PaymentIntituteSchema}
            name="center_cost_project_code"
            disabled={disabled}
            size="50"
            defaultValue={center_cost_project_code}
            label={tScholarshipKey('Proyecto')}
            onClick={e => handleSelectChange(e.target)}
          >
            {projects
              ?.filter(
                e => e.area_code === watch('center_cost_area_code', center_cost_area_code) || e.area_code === null,
              )
              .map(type => (
                <MenuItem key={type.code || '0'} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
          </SelectController>

          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('Número de convenio')}
            size="50"
            name="agreement_number"
            errors={errors}
            defaultValue={agreement_number}
            disabled
            control={control}
          />
          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('Concepto')}
            size="50"
            name="concept"
            errors={errors}
            defaultValue={concept}
            control={control}
            disabled={disabled}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('concept', e.target.value)}
          />
          <SelectController
            fullWidth
            control={control}
            errors={errors}
            schema={PaymentIntituteSchema}
            name="retention_code"
            disabled={disabled}
            size="50"
            defaultValue={retention_code}
            label={tScholarshipKey('Número de retención')}
            onClick={e => handleSelectChange(e.target)}
          >
            {taxRetentions
              ?.filter(e => e.tax_type === 'R')
              .map(type => (
                <MenuItem key={type.code} value={type.code}>
                  {type.name}
                </MenuItem>
              ))}
          </SelectController>
          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('Descripción')}
            size="100"
            rows="8"
            rowsMax="8"
            fullWidth
            multiline
            name="description"
            errors={errors}
            defaultValue={description}
            control={control}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('description', e.target.value)}
          />
        </FormContainer>
      );
    }
    return undefined;
  };

  const renderScholarshipData = (): JSX.Element | undefined => {
    if (!isAluIns && has_agreement) {
      const documentationAllNIF = documentationFilter(documentation, [Documentation.DNI, Documentation.NIF_TUTOR]);

      return (
        <FormContainer title={tScholarshipKey('Datos Beca')}>
          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('Último centro de estudios')}
            size="30"
            name="last_scholarship_academy"
            errors={errors}
            disabled
            defaultValue={last_scholarship_academy}
            control={control}
          />
          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('Centro de estudios')}
            size="30"
            name="scholarship_academy_confirm"
            errors={errors}
            disabled
            defaultValue={scholarship_academy_confirm}
            control={control}
          />
          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('Contacto centro de estudios')}
            size="30"
            name="academy_contact"
            errors={errors}
            disabled={disabled}
            defaultValue={academy_contact}
            control={control}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('academy_contact', e.target.value)}
          />
          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('Tipo de documento')}
            size="50"
            name="student_document_type"
            errors={errors}
            disabled
            defaultValue={student_document_type}
            control={control}
          />
          <TextInputController
            schema={PaymentIntituteSchema}
            label={tScholarshipKey('DNI/NIE/OTROS estudiante')}
            size="50"
            name="student_document_number"
            errors={errors}
            disabled
            defaultValue={student_document_number}
            control={control}
          />
          <FncAddDocuments
            documents={documentationAllNIF}
            actions={['checked', 'download', 'view']}
            disabled={disabled}
            originalName
            download={downloadDocument}
            upload={uploadDocument}
            remove={deleteDocument}
            getFile={getFile}
            genericId={id}
          />
        </FormContainer>
      );
    }
    return undefined;
  };

  const renderTaxDocumentation = (): JSX.Element => {
    return (
      <FormContainer className={styles.doc_container} title={tScholarshipKey('Repositorio IRPF')}>
        <FncAddDocuments
          documents={documentation.filter(e => e.code === Documentation.IRPF)}
          actions={['upload', 'download', 'view', 'remove']}
          originalName
          getFile={getFile}
          download={downloadDocument}
          upload={uploadDocument}
          remove={deleteDocument}
          genericId={id}
        />
      </FormContainer>
    );
  };

  const renderTotalCost = (): JSX.Element => {
    return (
      <FormContainer title={tScholarshipKey('Pago Total')}>
        <TextInput
          type="number"
          label={tScholarshipKey('Coste total de la beca confirmada')}
          size="30"
          name="total_cost"
          disabled
          value={total_cost}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      </FormContainer>
    );
  };

  const renderStudentPaymentsRow = (
    prefix: string,
    amountValue: number,
    dateValue: string | null,
    doneDateValue: string | null,
    dateError: boolean | undefined,
  ): JSX.Element => {
    const amountName = `${prefix}_amount`;
    const dateName = `${prefix}_date`;

    return (
      <>
        <TextInputController
          control={control}
          schema={PaymentIntituteSchema}
          type="number"
          errors={errors}
          label={tScholarshipKey(amountName)}
          size="50"
          name={amountName}
          price
          defaultValue={amountValue}
          disabled={isAluIns || !!doneDateValue || available === 1 || disabled}
          green={!!doneDateValue}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData(amountName, e.target.value)}
        />
        <KeyboardDatePicker
          error={dateError}
          variant="inline"
          inputVariant="outlined"
          name={dateName}
          label={tScholarshipKey(dateName)}
          placeholder="" // Prevent google to detect this input as a credit card number
          value={asingDateValue(dateValue)}
          onChange={(date: MaterialUiPickersDate) => {
            date && changeDate(dateName, date);
          }}
          autoOk
          format={DATE_FORMAT}
          disabled={isAluIns ? true : !!doneDateValue || available === 1 || disabled}
          className={`${styles.size_50} ${doneDateValue && styles.green}`}
        />
      </>
    );
  };

  const renderStudentPayments = (): JSX.Element => {
    return (
      <FormContainer title={tScholarshipKey('Información pagos joven')}>
        <TextInputController
          schema={PaymentIntituteSchema}
          label={tScholarshipKey('student_account_number.title')}
          size="100"
          name={'student_account_number'}
          defaultValue={student_account_number}
          errors={errors}
          control={control}
          disabled={disabled}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            setData('student_account_number', e.target.value);
            checkAccountNumber('student_account_number', e.target.value);
          }}
        />
        {renderStudentPaymentsRow(
          'first_reload',
          first_reload_amount,
          first_reload_date,
          first_reload_done_date,
          !!errors.first_reload_date,
        )}
        {renderStudentPaymentsRow(
          'second_reload',
          second_reload_amount,
          second_reload_date,
          second_reload_done_date,
          !!errors.second_reload_date,
        )}
        {renderStudentPaymentsRow(
          'third_reload',
          third_reload_amount,
          third_reload_date,
          third_reload_done_date,
          !!errors.third_reload_date,
        )}
        {renderStudentPaymentsRow(
          'additional_reload',
          additional_reload_amount,
          additional_reload_date,
          additional_reload_done_date,
          !!errors.additional_reload_date,
        )}
      </FormContainer>
    );
  };

  const renderStudyCenterIbanList = (): JSX.Element => {
    if (studentPayAll) {
      return (
        <TextInputController
          schema={PaymentIntituteSchema}
          label={tScholarshipKey('student_account_number.title')}
          size="50"
          name={'student_account_number'}
          defaultValue={student_account_number}
          errors={errors}
          control={control}
          disabled
        />
      );
    }
    return (
      <>
        {!!ibans.length ? (
          <SelectController
            control={control}
            errors={errors}
            schema={PaymentAcademySchema}
            name="account_number"
            size="30"
            defaultValue={account_number || ibans[0].iban}
            label={tScholarshipKey('IBAN del centro de estudios')}
            onClick={e => handleSelectChangeIban(e.target)}
            disabled={disabled}
          >
            {ibans.map(type => (
              <MenuItem key={type.iban} value={type.iban}>
                {type.alias}
              </MenuItem>
            ))}
          </SelectController>
        ) : (
          <TextInputController
            schema={PaymentAcademySchema}
            label={tScholarshipKey('IBAN del centro de Estudios')}
            size="50"
            name="account_number"
            errors={errors}
            disabled={isAluIns || academy_payment === 0 || disabled}
            defaultValue={account_number || ''}
            control={control}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              setData('account_number', e.target.value);
              checkAccountNumber('account_number', e.target.value);
            }}
          />
        )}
      </>
    );
  };

  const renderStudyCenterPaymentAccountNumber = (
    name: string,
    accountValue: string | null,
    doneDate: string | null,
  ): JSX.Element => {
    if (studentPayAll || !ibans.length) {
      return (
        <TextInputController
          schema={PaymentAcademySchema}
          label={tScholarshipKey(name)}
          size="30"
          name={name}
          errors={errors}
          disabled
          defaultValue={accountValue || ''}
          control={control}
        />
      );
    }
    return (
      <SelectController
        control={control}
        errors={errors}
        schema={PaymentAcademySchema}
        name={name}
        disabled={!!doneDate || disabled}
        size="30"
        defaultValue={accountValue || '0'}
        label={tScholarshipKey(name)}
        onClick={e => handleSelectChange(e.target)}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
          setData(name, e.target.value === '0' || null ? account_number : e.target.value)
        }
      >
        {emptyOne.concat(ibans).map(type => (
          <MenuItem key={type.iban} value={type.iban}>
            {type.alias}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderStudyCenterPaymentRow = (
    prefix: string,
    amountValue: number,
    dateValue: string | null,
    accountValue: string | null,
    doneDateValue: string | null,
    dateError: boolean | undefined,
  ): JSX.Element => {
    const amountName = `${prefix}_amount`;
    const dateName = `${prefix}_date`;
    const accountNumberName = `${prefix}_account_number`;

    return (
      <>
        <TextInputController
          control={control}
          schema={PaymentAcademySchema}
          type="number"
          errors={errors}
          label={tScholarshipKey(amountName)}
          size="30"
          name={amountName}
          price
          defaultValue={amountValue}
          disabled={isAluIns || !!doneDateValue || available === 1 || disabled}
          green={!!doneDateValue}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData(amountName, e.target.value)}
        />
        <KeyboardDatePicker
          label={tScholarshipKey(dateName)}
          error={dateError}
          variant="inline"
          inputVariant="outlined"
          name={dateName}
          placeholder="" // Prevent google to detect this input as a credit card number
          value={asingDateValue(dateValue)}
          onChange={(date: MaterialUiPickersDate) => {
            date && changeDate(dateName, date);
          }}
          autoOk
          format={DATE_FORMAT}
          disabled={isAluIns ? true : !!doneDateValue || available === 1 || disabled}
          className={`${styles.size_30} ${doneDateValue && styles.green}`}
        />
        {renderStudyCenterPaymentAccountNumber(accountNumberName, accountValue, doneDateValue)}
      </>
    );
  };

  const renderStudyCenterPayments = (): JSX.Element | undefined => {
    if (academy_payment) {
      const documentationCTA = documentation.filter(e => e.code === Documentation.DOCUMENTO_BANCO_CUENTA_ENTIDAD);
      return (
        <>
          <div className={styles.containerCombo}>{renderStudyCenterIbanList()}</div>
          {renderStudyCenterPaymentRow(
            'first_payment',
            first_payment_amount,
            first_payment_date,
            first_payment_account_number,
            first_payment_done_date,
            !!errors.first_payment_date,
          )}
          {renderStudyCenterPaymentRow(
            'second_payment',
            second_payment_amount,
            second_payment_date,
            second_payment_account_number,
            second_payment_done_date,
            !!errors.second_payment_date,
          )}
          {renderStudyCenterPaymentRow(
            'third_payment',
            third_payment_amount,
            third_payment_date,
            third_payment_account_number,
            third_payment_done_date,
            !!errors.third_payment_date,
          )}
          <FncAddDocuments
            documents={documentationCTA}
            actions={['upload', 'download', 'view', 'remove']}
            originalName
            required={academy_payment === 0}
            getFile={getFile}
            download={downloadDocument}
            upload={uploadDocument}
            remove={deleteDocument}
            genericId={id}
            disabled={isAluIns}
          />
        </>
      );
    }
    return undefined;
  };

  const renderInstitutePayments = (): JSX.Element | undefined => {
    if (has_agreement) {
      return (
        <FormContainer title={tScholarshipKey('Información pago al instituto')}>
          <CheckboxCompo
            name="academy_payment"
            questionText={tScholarshipKey('¿Hay pago al instituto?')}
            defaultValue={academy_payment || 0}
            register={register}
            disabled={isAluIns || disabled}
            onChange={(value: boolean) => setData('academy_payment', value ? 1 : 0)}
          />
          {renderStudyCenterPayments()}
        </FormContainer>
      );
    }
    return undefined;
  };

  const renderStudentInfo = (): JSX.Element | undefined => {
    if (isAluIns) {
      return (
        <>
          <MessageInfo>{tScholarshipKey('PaymentFormP1')}</MessageInfo>

          <MessageInfo>{tScholarshipKey('PaymentFormP2')}</MessageInfo>

          <MessageInfo>{tScholarshipKey('PaymentFormP3')}</MessageInfo>
        </>
      );
    }
    return undefined;
  };

  return (
    <div className={styles.root}>
      <Title>{tScholarshipKey('PAGOS')}</Title>
      {renderAgreementDocument()}
      {renderSignatureDocuments()}
      {renderAccountingData()}
      {renderScholarshipData()}
      {renderTaxDocumentation()}
      {renderTotalCost()}
      {renderStudentPayments()}
      {renderInstitutePayments()}
      {renderStudentInfo}
      <PaymentAdjustmentFormModal
        open={scholarshipIdToAdjust !== 0}
        handleClose={() => closeAdjustmentModal()}
        scholarship_id={scholarshipIdToAdjust}
        isInstitute
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  genericMetadata: selectGenericMetadata(state),
  allowPaymentAdjustment: selectUserCan(state)('allow_payment_adjustment'),
  roleCode: selectUserRoleCode(state),
  loadingAgreement: state.scholarshipReducer.loadingAgreement,
  providerLoading: selectProviderLoading(state),
  documentation: selectGetScholarshipDocumentationPerCode(state, ['CON', 'NIF', 'CTA', 'TNF', 'IRP']),
  validationErrors: selectScholarshipValidationErrors(state),
  ibans: selectAcademyIban(state),
  can_cancel_agreement: selectUserCan(state)('allowRestartInstituteAgreements'),
  disable_cancel_agreement: selectScholarshipDisableCancel(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipAgreementSetData(key, value)),
  updateSchedule: (id: number, data: ScholarshipAgreementData) => dispatch(updateAgreementCalendar(id, data)),
  sendProvider: (scholarshipId: number, retentionCode: string, centerCost: CenterCost) =>
    dispatch(sendProviderInfo(scholarshipId, retentionCode, centerCost)),
  listAcademyIbansByCode: (code: string): void => dispatch(listAcademyIbansByCode(code)),
  cancelAgreement: (scholarship_id: number): void =>
    dispatch(cancelScholarshipAgreement(scholarship_id, window.location.href)),
  setScholarshipStep: (step: number) => dispatch(setScholarshipStep(step)),
  saveAgreement: (id: number, data: ScholarshipAgreementData) => dispatch(saveAgreement(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
