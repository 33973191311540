import { MaterialTableProps } from 'material-table';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import LayoutForm from '../../../../components/Layout/LayoutForm';
import Loading from '../../../../components/Loading/Loading';
import RadioButtonGroup from '../../../../components/RadioButtons/RadioButtonGroup';
import { yesNoOptions } from '../../../../components/RadioButtons/RadioButtonOptions';
import TableWithEdit from '../../../../components/Tables/TableWithEdit';
import Title from '../../../../components/Title/Title';
import { exportToExcel } from '../../../../helpers/exportToXslx';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { AppState } from '../../../../redux/root-reducer';
import { ScholarshipHistoryEntry, ScholarshipHistoryGlobal } from '../../../../redux/scholarship/definitions';
import { UserData } from '../../../../redux/user/definitions';
import { selectError } from '../../../../redux/user/selectors';
import { HistorySchema } from '../../../../validations/formSchema';
import SubmenuStudent from '../../SubmenuStudent/SubmenuStudent';

interface HistoricalDataProps {
  readOnly: boolean;
  id: string;
  avatar: string;
  changeAvatar: boolean;
  loading: boolean;
  profileData: UserData;
  handleSetImageById: (image: string) => void;
  onSelectComponent: (componentId: number) => void;
}

const HistoricalData: React.FC<HistoricalDataProps> = ({
  readOnly,
  avatar,
  id,
  profileData,
  loading,
  changeAvatar,
  handleSetImageById,
  onSelectComponent,
}) => {
  const initialData = profileData.scholarshipHistory.entries;

  const { errors, control } = useForm<ScholarshipHistoryGlobal>({
    mode: 'onSubmit',
    submitFocusError: true,
  });

  const title = readOnly ? tKey('DETALLES  DE USUARIO') : tKey('EDICIÓN DE USUARIO');

  const tableData: MaterialTableProps<ScholarshipHistoryEntry> = {
    title: tKey('Histórico de becas'),
    columns: [
      { title: tKey('Año académico'), field: 'academic_year' },
      { title: tKey('Curso'), field: 'education_level' },
      { title: tKey('Tipo de estudios'), field: 'education_type' },
      { title: tKey('Nombre estudios'), field: 'course_name' },
      { title: tKey('Centro de estudios'), field: 'academy_name' },
    ],
    data: initialData,
    options: {
      exportButton: true,
      exportCsv: (columns, data) => {
        exportToExcel(columns, data, `Historico de Becas`);
      },
      selection: false,
      pageSize: 100,
      pageSizeOptions: [20, 50, 100],
      tableLayout: 'auto',
    },
  };

  return (
    <LayoutForm
      leftSubmenu={
        <SubmenuStudent
          selected="history"
          avatar={avatar}
          loading={loading}
          onSelectComponent={onSelectComponent}
          setImage={changeAvatar ? handleSetImageById : undefined}
        />
      }
    >
      {!loading ? (
        <>
          <form>
            <Title>{title}</Title>
            <FormContainer title="Datos globales">
              <TextInputController
                disabled
                name="scholarship_years"
                label={tFormKey('Número de años becado')}
                schema={HistorySchema}
                control={control}
                errors={errors}
                size="50"
                defaultValue={profileData.scholarshipHistory.scholarship_years}
              />
              <TextInputController
                disabled
                name="status"
                label={tFormKey('Status')}
                schema={HistorySchema}
                control={control}
                errors={errors}
                size="50"
                defaultValue={profileData.scholarshipHistory.status ? 'Activo' : 'Inactivo'}
              />
              <RadioButtonGroup
                name="top"
                disabled={true}
                questionText={tFormKey('Top student')}
                options={yesNoOptions}
                value={profileData.scholarshipHistory.top ? '1' : '0'}
              />
              <RadioButtonGroup
                name="mentor"
                disabled={true}
                questionText={tFormKey('Mentor')}
                options={yesNoOptions}
                value={profileData.scholarshipHistory.mentor ? '1' : '0'}
              />
              <TextInputController
                disabled
                name="mentorships"
                label={tFormKey('Número de veces que ha sido mentor')}
                schema={HistorySchema}
                control={control}
                errors={errors}
                size="50"
                defaultValue={profileData.scholarshipHistory.mentorship}
              />
            </FormContainer>
          </form>
          <TableWithEdit tableData={{ ...tableData, isLoading: loading }} />
        </>
      ) : (
        <Loading big />
      )}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  error: selectError(state),
});

export default connect(mapStateToProps, null)(HistoricalData);
