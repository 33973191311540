import { ButtonProps } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import LayoutForm from '../../../components/Layout/LayoutForm';
import ActionsMenu from '../../../components/Layout/Menus/ActionsMenu/ActionsMenu';
import Loading from '../../../components/Loading/Loading';
import { selectIsNousCims, selectUserIsEntity, selectUserIsSherpa } from '../../../redux/auth/selectors';
import {
  selectHasDailyFollowUps,
  selectHasEnrollments,
  selectIpLoading,
} from '../../../redux/internal-projects/selectors';
import { AppState } from '../../../redux/root-reducer';
import SubmenuInternalProjectEdition from './SubmenuInternalProjectEdition/SubmenuInternalProjectEdition';
import { useWindowSize } from '../../../helpers/customHooks/useHookMethod';

interface InternalProjectLayoutFormProps {
  children: JSX.Element;
  loading: boolean;
  hasDailyFollowUp: boolean;
  isNousCims: boolean;
  hasEnrollments: boolean;
  is_entity: boolean;
  is_sherpa: boolean;
  sectionSelected: string;
  actionsButtons?: ButtonProps[];
  handleChangeSection: (componentId: string) => void;
  handleGoBack?: any;
}

const InternalProjectLayoutForm: React.FC<InternalProjectLayoutFormProps> = ({
  children,
  loading,
  hasDailyFollowUp,
  isNousCims,
  is_entity,
  is_sherpa,
  hasEnrollments,
  sectionSelected,
  actionsButtons = [],
  handleChangeSection,
  handleGoBack,
}) => {
  const isMobile = useWindowSize().IsMobile;

  return (
    <LayoutForm
      leftSubmenu={
        !isMobile ? (
          <SubmenuInternalProjectEdition
            selected={sectionSelected}
            onSelectComponent={handleChangeSection}
            hasDailyFollowUp={hasDailyFollowUp && isNousCims}
            hasEnrollments={hasEnrollments}
            isNousCims={isNousCims}
            isEntityUser={is_entity}
            isSherpaUser={is_sherpa}
          />
        ) : (
          <></>
        )
      }
      rightSubmenu={!isMobile ? <ActionsMenu actionsButtons={actionsButtons} onGoBack={handleGoBack} /> : undefined}
    >
      {loading ? <Loading big /> : children}
    </LayoutForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectIpLoading(state),
  hasDailyFollowUp: selectHasDailyFollowUps(state),
  isNousCims: selectIsNousCims(state),
  hasEnrollments: selectHasEnrollments(state),
  is_entity: selectUserIsEntity(state),
  is_sherpa: selectUserIsSherpa(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InternalProjectLayoutForm);
