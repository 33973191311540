import { CategoryOutlined, CropSquareOutlined } from '@material-ui/icons';
import React from 'react';
import Submenu, { SubmenuItem } from '../../../components/Layout/Menus/Submenu/Submenu';
import { tKey } from '../../../helpers/translate';

interface SubmenuManagementAreaProps {
  canConfigure: boolean;
  selected: string;
  submenu: string;
  onClickOption?: () => void;
}

const SubmenuManagementArea: React.FC<SubmenuManagementAreaProps> = ({
  canConfigure,
  selected,
  submenu,
  onClickOption,
}) => {
  const optionsMenu: SubmenuItem[] = [];
  if (canConfigure) {
    optionsMenu.push({
      title: tKey('Áreas'),
      icon: <CategoryOutlined />,
      type: 'route',
      route: '/configuracion/gestion-areas/areas',
      selected: selected === 'areas',
    });
    optionsMenu.push({
      title: tKey('Plantillas'),
      icon: <CropSquareOutlined />,
      type: 'route',
      route: '/configuracion/gestion-areas/plantillas',
      selected: selected === 'plantillas',
    });
  }

  return (
    <Submenu
      titleMenu={tKey('GESTIÓN DE ÁREAS')}
      optionsMenu={optionsMenu}
      defaultOpen
      defaultOpenSubmenu={submenu}
      onClickOption={onClickOption}
    />
  );
};

export default SubmenuManagementArea;
