import { apiBaseUrl, apiCall } from '../api';

const baseURL = apiBaseUrl;

export const checkScholarshipFirstFollowUp = async (scholarship_id: number): Promise<string | null> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  const { data } = await apiCall<{ data: { payment_date: string | null } }>(
    `${baseURL}/scholarships/${scholarship_id}/tracking/first/check`,
    init,
  );
  if (data.payment_date) {
    return data.payment_date;
  }
  return null;
};
