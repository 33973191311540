import { permissionType } from '../constants/permissions_definitions';
import { AppRoute } from '../constants/routes';
import routes from '../routes';
import webStyle from './webStyle';

const filteredRoutes = (permissions?: permissionType[] | null): AppRoute[] => {
  return routes.filter(
    route =>
      (!('permission' in route) ||
        (permissions &&
          route.permission?.some(e => {
            return permissions?.includes(e);
          }))) &&
      (route.platform === 'common' || route.platform === webStyle),
  );
};

export default filteredRoutes;
