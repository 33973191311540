import { APIValidationError } from '../../api/api';
import assertUnreachable from '../../helpers/assertUnreachable';
import { RegisterAction, RolesProps } from './actions';
import { RegisterConsts } from './action_types';

const initialState = {
  loading: false,
  roles: [] as RolesProps[] | [],
  error: null as string | null,
  validationErrors: null as APIValidationError | null,
  registered: false,
  roleId: 0 as number,
};

export type RegisterState = typeof initialState;

const registerReducer = (state = initialState, action: RegisterAction): RegisterState => {
  switch (action.type) {
    case RegisterConsts.REGISTER_SUBMIT:
    case RegisterConsts.REQUEST_ROLES:
      return {
        ...state,
        registered: false,
        loading: true,
      };
    case RegisterConsts.REQUEST_ROLES_OK:
      const { roles } = action;
      const roleId = roles.length && roles[0].id;
      return {
        ...state,
        loading: false,
        roles,
        roleId,
      };
    case RegisterConsts.REGISTER_SUBMIT_OK:
      return {
        ...state,
        loading: false,
        registered: true,
        validationErrors: initialState.validationErrors,
      };
    case RegisterConsts.REGISTER_SUBMIT_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        validationErrors: action.validationErrors,
      };
    case RegisterConsts.REGISTER_SUBMIT_KO:
      const { errorAPI } = action;
      return {
        ...state,
        loading: false,
        error: errorAPI,
      };
    case RegisterConsts.REQUEST_ROLES_KO:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
      };
    case RegisterConsts.SET_ROLE:
      return {
        ...state,
        roleId: action.roleId,
      };
  }
  assertUnreachable(action);
  return state;
};

export default registerReducer;
