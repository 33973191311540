import React from 'react';
import { Controller } from 'react-hook-form';
import { Control, FieldError, FieldErrors, FieldName, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import useIsRequired from '../../../helpers/customHooks/useIsRequired';
import FncTextArea from './FncTextArea';

export interface FncTextAreaControllerProps<T extends FieldValues> extends React.ComponentPropsWithoutRef<'textarea'> {
  control: Control<T>;
  errors: FieldErrors<T>;
  name: FieldName<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  customClass?: string;
  defaultValue?: string;
  label?: string;
}

const FncTextAreaController = <T extends FieldValues>({
  customClass,
  label,
  errors,
  control,
  schema,
  name,
  defaultValue,
  ...props
}: FncTextAreaControllerProps<T>) => {
  const hasError = errors[name] as FieldError;
  const required = useIsRequired(name, schema);

  return (
    <Controller
      name={name || `TextArea_${Math.random()}`}
      control={control}
      defaultValue={defaultValue}
      as={
        <FncTextArea
          required={required}
          errorMessage={hasError && hasError.message ? (hasError.message as string) : ''}
          label={label}
          customClass={customClass}
          {...props}
        />
      }
    />
  );
};

export default FncTextAreaController;
