import { Action } from 'redux';
import { TrackingConsts } from './action_types';

export type ResourceTrackingTypes = 'Invoice' | 'Scholarship' | 'Project';

// INITIAL STATE
export const initialState = {
  error: null as string | null,
  resourceTracking: {} as ResourceTracking,
  loading: false as boolean,
};

export interface ResourceTracking {
  type: ResourceTrackingTypes;
  currentState: string;
  nextState: string;
  taskOwner: string;
  trackingList: TrackingItem[];
}

export interface TrackingItem {
  id: number;
  tracking_date: string;
  state_from: string;
  state_to: string;
  user: string;
}

export type TrackingState = typeof initialState;

// TYPESCRIPT DEFINITONS FOR HANDLING ACTIONS

export interface ResourceTrackingsListAction extends Action {
  type: TrackingConsts.RESOURCE_TRACKING_LIST;
  id: number;
  resourceType: string;
}

export interface ResourceTrackingsListOkAction extends Action {
  type: TrackingConsts.RESOURCE_TRACKING_LIST_OK;
  resourceTracking: ResourceTracking;
}

export interface ResourceTrackingsListKoAction extends Action {
  type: TrackingConsts.RESOURCE_TRACKING_LIST_KO;
  error: string;
}

export type TrackingAction =
  | ResourceTrackingsListAction
  | ResourceTrackingsListOkAction
  | ResourceTrackingsListKoAction;

// TYPESCRIPT DEFINITIONS FOR DISPACHING EVENTS

export type TrackingDispatch = (action: TrackingAction) => void;
