import { APIValidationError } from '../../api/api';
import { Tracking } from '../area/definitions';
import { DocumentationItem, DocumentationTypes, IdNamePair } from '../common/definitions';
import { AppState } from '../root-reducer';
import { DirectoryMetadata } from './action';
import {
  AreaSubArea,
  ConfiguratorData,
  DocumentsFollowup,
  FollowUp,
  FormProjectFields,
  GenericData,
  LookUpState,
  LookUpSubarea,
  NewScheduleProject,
  ProjectData,
  ProjectListItem,
  ProjectState,
  ScheduledAppointmentItem,
  ScheduledAppointmentState,
  StateProject,
  UserFilter,
} from './definitions';

export const selectProject = (state: AppState): ProjectState => state.projectReducer;
export const selectLoading = (state: AppState): boolean => selectProject(state).loading;
export const selectError = (state: AppState): string | null => selectProject(state).error;
export const selectNewScheduleData = (state: AppState): NewScheduleProject => selectProject(state).newScheduleData;
export const selectAreasSubAreas = (state: AppState): AreaSubArea[] => selectProject(state).areasSubareasList;
export const selectProjectsList = (state: AppState): ProjectListItem[] => selectProject(state).projectsList;
export const selectDocumentation = (state: AppState): DocumentationItem[] => selectProject(state).documentation;
export const selectProjectData = (state: AppState): ProjectData => selectProject(state).projectData;
export const selectValidationErrors = (state: AppState): APIValidationError | null =>
  selectProject(state).validationErrors;
export const selectFollowUp = (state: AppState): FollowUp => selectProject(state).followUp;
export const selectModalCreateView = (state: AppState): boolean => selectProject(state).createView;
export const selectFollowUpList = (state: AppState): FollowUp[] => selectProject(state).followUpsList;
export const selectLoadingFollowUp = (state: AppState): boolean => selectProject(state).loadingFollowUp;
export const selectLoadingListFollowUp = (state: AppState): boolean => selectProject(state).loadingListFollowUp;
export const selectEditDeleteModalView = (state: AppState): boolean => selectProject(state).editDeleteView;
export const selectDocumentsArr = (state: AppState): DocumentsFollowup[] => selectProject(state).documentsArr;
export const selectLoadingDelete = (state: AppState): boolean => selectProject(state).loadingDelete;
export const selectConfiguratorData = (state: AppState): ConfiguratorData[] => selectProject(state).configuratorData;
export const selectSelectedConfiguratorData = (state: AppState): ConfiguratorData[] =>
  selectProject(state).configuratorSelectedData;
export const selectNotSelectedConfiguratorData = (state: AppState): ConfiguratorData[] =>
  selectProject(state).configuratorNotSelectedData;
export const selectMetadataFieldList = (state: AppState): any => selectProject(state).metadata;
export const selectStateList = (state: AppState): LookUpState => selectProject(state).states;
export const selectSubareaList = (state: AppState): LookUpSubarea => selectProject(state).subareas;
export const selectGenericTableData = (state: AppState): GenericData => selectProject(state).fieldsData.participante;
export const selectFields = (state: AppState): { documents: FormProjectFields[]; elements: FormProjectFields[] } =>
  selectProject(state).fieldsData.fields;
export const selectLoadingFields = (state: AppState): boolean => selectProject(state).loadingFields;
export const selectDirectoryMetadata = (state: AppState): DirectoryMetadata => selectProject(state).directoryMetadata;
export const selectUsersFilteredData = (state: AppState): UserFilter[] => selectProject(state).usersDataFiltered;
export const selectActorsDefault = (state: AppState): IdNamePair[] => selectProject(state).actorsDefault;
export const selectActorsFiltered = (state: AppState): IdNamePair[] => selectProject(state).actorsFiltered;
export const selectProjectAgreement = (state: AppState): DocumentationItem | undefined => {
  return selectProject(state).projectData.documents?.find((docu: DocumentationItem) => docu.type === 'CON');
};
export const selectProjectAmount = (state: AppState): number => {
  const agreement = selectProjectAgreement(state);
  if (agreement) {
    return agreement.amount || 0;
  }
  return 0;
};
export const selectScheduledAmount = (state: AppState): number => {
  const schedules = selectProject(state).projectData.scheduled_appointments;
  const total = schedules ? schedules.reduce((a, b) => a + (Number(b.partial_amount) || 0), 0) : 0;
  return Math.round(total * 100) / 100;
};
export const selectPendingAmount = (state: AppState): number => {
  const amount = selectProjectAmount(state);
  const scheduledAmount = selectScheduledAmount(state);
  const total = amount - scheduledAmount;
  return Math.round(total * 100) / 100;
};
export const selectProjectStartDate = (state: AppState): string | null | undefined => {
  const kickOff = selectProject(state).projectData.scheduled_appointments?.find(
    (sch: ScheduledAppointmentItem) => sch.state === ScheduledAppointmentState.KICK_OFF,
  );
  if (kickOff) {
    return kickOff.tracking_date;
  }
  return undefined;
};
export const selectProjectEndDate = (state: AppState): string | null | undefined => {
  const finish = selectProject(state).projectData.scheduled_appointments?.find(
    (sch: ScheduledAppointmentItem) => sch.state === ScheduledAppointmentState.FINISH,
  );
  if (finish) {
    return finish.tracking_date;
  }
  return undefined;
};

export const selectAreasTemplates = (state: AppState): Tracking[] => selectProject(state).areasTemplates;
export const selectCurrentProjectSchedule = (state: AppState): ScheduledAppointmentItem =>
  selectProject(state).currentProjectScheduleItem;

export const selectProjectScheduleFreeFollowUps = (state: AppState): IdNamePair[] => {
  const schedules = selectProject(state).projectData.scheduled_appointments;
  const result = [] as IdNamePair[];

  schedules.forEach((s: ScheduledAppointmentItem) => {
    if (
      (s.state === ScheduledAppointmentState.CHECK || s.state === ScheduledAppointmentState.FNC_CHECK) &&
      s.complete === 0
    ) {
      result.push({
        id: s.id,
        name: s.tracking_date,
      } as IdNamePair);
    }
  });
  return result;
};

export const selectLoadingSchedules = (state: AppState): boolean => selectProject(state).loadingSchedules;

export const selectProjectPayments = (state: AppState): ScheduledAppointmentItem[] => {
  const schedules = selectProject(state).projectData.scheduled_appointments;
  return schedules
    ? schedules.filter((s: ScheduledAppointmentItem) => s.state === ScheduledAppointmentState.PAYMENT)
    : [];
};

export const selectProjectPaymentFollowUps = (state: AppState): ScheduledAppointmentItem[] => {
  const schedules = selectProject(state).projectData.scheduled_appointments;
  return schedules
    ? schedules.filter(
        (s: ScheduledAppointmentItem) =>
          s.state === ScheduledAppointmentState.CHECK || s.state === ScheduledAppointmentState.FNC_CHECK,
      )
    : [];
};

export const selectSchedulesModalView = (state: AppState): boolean => selectProject(state).schedulesModalView;

export const selectHasAgreement = (state: AppState): boolean => {
  const project = selectProjectData(state);

  if (project.state_code !== StateProject.AGREEMENT_SIGNING) {
    return true;
  }
  const agreementDocument = selectDocumentation(state).filter(
    (documentationItem: DocumentationItem) => documentationItem.type === DocumentationTypes.CONVENIO,
  );

  if (agreementDocument.length > 0) {
    return !!agreementDocument[0].file_id;
  }
  return false;
};
